import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const FacebookOAuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      // Send a message to the parent window
      window.opener.postMessage(
        { type: "FACEBOOK_OAUTH_CALLBACK", code, state },
        window.location.origin
      );
      // Close this window
      window.close();
    } else {
      // Handle error case
      console.error("Missing code or state in OAuth callback");
      window.close();
    }
  }, [location]);

  return <div>Processing Facebook OAuth callback...</div>;
};

export default FacebookOAuthCallback;
