import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  IconButton,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import AddGroupMemberDialog from "./components/AddGroupMemberDialog";
import EditGroupDialog from "./components/EditGroupDialog";
import EditProductGroupDialog from "./components/EditProductGroupDialog";
import AddGroupDialog from "./components/AddGroupDialog";
import decode from "../../../general/util/jwtDecode";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AssignAdminDialog from "./components/AssignAdminDialog";
import AssignLocationDialog from "./components/AssignLocationDialog";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import { GROUP_ADMIN_ROLE_NAME } from "../../../general/constants/constants";
import UpdateGroupBranding from "../../../general/components/branding/UpdateGroupBranding";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function GroupManagementPage() {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [members, setMembers] = useState([]);
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState(false);
  const [openEditProductGroupDialog, setOpenEditProductGroupDialog] =
    useState(false);
  const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [groupAdmins, setGroupAdmins] = useState([]);
  const [groupLocations, setGroupLocations] = useState([]);
  const [openAssignAdminDialog, setOpenAssignAdminDialog] = useState(false);
  const [openAssignLocationDialog, setOpenAssignLocationDialog] =
    useState(false);
  const [openBrandingDialog, setOpenBrandingDialog] = useState(false);
  const [openDefaultGroupDialog, setOpenDefaultGroupDialog] = useState(false);
  const [groupToSetDefault, setGroupToSetDefault] = useState(null);
  const currentUser = decode();

  // Check if the current user is a group admin
  const isGroupAdmin = currentUser.role === GROUP_ADMIN_ROLE_NAME;

  const handleSetDefaultGroup = async (groupId, isCurrentlyDefault) => {
    try {
      if (isCurrentlyDefault) {
        // If removing default status, show confirmation dialog
        setGroupToSetDefault({
          id: groupId,
          isRemoving: true,
          name: groups.find((g) => g.id === groupId)?.name,
        });
      } else {
        // If setting as default, show regular confirmation dialog
        setGroupToSetDefault({
          id: groupId,
          isRemoving: false,
          name: groups.find((g) => g.id === groupId)?.name,
        });
      }
      setOpenDefaultGroupDialog(true);
    } catch (error) {
      console.error("Error handling default group:", error);
    }
  };
  const fetchGroups = async (preserveSelectedGroup = true) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups`,
        { method: "GET" }
      );
      const data = await response.json();
      setGroups(data);

      if (data.length > 0) {
        if (preserveSelectedGroup && selectedGroup) {
          const currentGroup = data.find(
            (group) => group.id === selectedGroup.id
          );
          setSelectedGroup(currentGroup || data[0]);
        } else {
          setSelectedGroup(data[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMembers = async (groupId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/members`,
        { method: "GET" }
      );
      const data = await response.json();
      const membersWithFullName = data.map((member) => ({
        ...member,
        full_name: `${member.first_name} ${member.last_name}`,
      }));

      setMembers(membersWithFullName);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGroupAdmins = async (groupId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/admins`,
        { method: "GET" }
      );
      const data = await response.json();
      setGroupAdmins(data);
    } catch (error) {
      console.error("Error fetching group admins:", error);
    }
  };

  const fetchGroupLocations = async (groupId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/locations`,
        { method: "GET" }
      );
      const data = await response.json();
      setGroupLocations(data);
    } catch (error) {
      console.error("Error fetching group locations:", error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      fetchMembers(selectedGroup.id);
      fetchGroupAdmins(selectedGroup.id);
      fetchGroupLocations(selectedGroup.id);
    }
  }, [selectedGroup]);

  const handleTabChange = (event, newValue) => {
    setSelectedGroup(groups[newValue]);
  };

  const handleAddMember = async (memberIds) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/members`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_ids: memberIds }), // Change to user_ids to match the backend
        }
      );
      fetchMembers(selectedGroup.id);
      setOpenAddMemberDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveMember = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/members/${memberToRemove}`,
        {
          method: "DELETE",
        }
      );
      fetchMembers(selectedGroup.id);
      setOpenRemoveMemberDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmRemoveMember = (userId) => {
    setMemberToRemove(userId);
    setOpenRemoveMemberDialog(true);
  };

  const handleEditGroup = async (name, description, alwaysHandleDuplicates) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            description,
            always_handle_duplicates: alwaysHandleDuplicates,
          }),
        }
      );

      fetchGroups(false);
      setOpenEditGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProductGroup = async (selectedProducts) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/products`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_ids: selectedProducts }),
        }
      );

      fetchGroups();
      setOpenEditProductGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddGroup = async (name, description, alwaysHandleDuplicates) => {
    try {
      await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/groups`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          description,
          always_handle_duplicates: alwaysHandleDuplicates,
        }),
      });
      fetchGroups();
      setOpenAddGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupToDelete}`,
        {
          method: "DELETE",
        }
      );
      fetchGroups();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssignAdmin = async (adminIds) => {
    try {
      // await customFetch(
      //   `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/admins`,
      //   {
      //     method: "PUT",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ tenant_user_ids: adminIds }),
      //   }
      // );
      fetchGroupAdmins(selectedGroup.id);
      setOpenAssignAdminDialog(false);
    } catch (error) {
      console.error("Error assigning admins:", error);
    }
  };

  const handleAssignLocation = async (locationIds) => {
    try {
      // await customFetch(
      //   `/v1/tenantadmin/${currentUser.tenant_id}/groups/${selectedGroup.id}/locations`,
      //   {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ location_ids: locationIds }),
      //   }
      // );
      fetchGroupLocations(selectedGroup.id);
      setOpenAssignLocationDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { field: "full_name", headerName: "Full Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton onClick={() => confirmRemoveMember(params.row.id)}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box p={2}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // left align text
          }}
        >
          Groups Management
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // Center align text
          }}
        >
          Groups allow tenant admins to manage and organize users and products.
          Users will only be able to view and purchase products from groups
          where they are a member.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddGroupDialog(true)}
          disabled={isGroupAdmin} // Disable button if the user is a group admin
        >
          Add New Group
        </Button>
      </Box>
      <Box display="flex" flexGrow={1}>
        <Box flexBasis="20%" display="flex" flexDirection="column">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={
              selectedGroup
                ? groups.findIndex((group) => group.id === selectedGroup.id)
                : 0
            }
            onChange={handleTabChange}
          >
            {groups.map((group, index) => (
              <Tab
                key={group.id}
                label={
                  <Card
                    variant="outlined"
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      boxShadow: 2,
                      position: "relative",
                    }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Typography variant="h6">{group.name}</Typography>
                        {group.default_group ? (
                          <Tooltip title="Remove Default Group Status">
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSetDefaultGroup(group.id, true);
                              }}
                              disabled={isGroupAdmin}
                            >
                              <StarIcon
                                fontSize="small"
                                color="secondary"
                                sx={{ ml: 1 }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          !isGroupAdmin && (
                            <Tooltip title="Set as Default Group">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSetDefaultGroup(group.id, false);
                                }}
                              >
                                <StarBorderIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )
                        )}
                      </Box>
                      <Divider sx={{ my: 0.5 }} variant="inset" />
                      <Typography variant="body2">
                        Members: {group.member_count}
                      </Typography>
                      <Typography variant="body2">
                        Admins: {group.admin_count}
                      </Typography>
                      <Typography variant="body2">
                        GHL Locations: {group.location_count}
                      </Typography>
                      <Typography variant="body2">
                        Products: {group.products.length}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                sx={{ minWidth: "100%", textAlign: "left" }}
              />
            ))}
          </Tabs>
        </Box>
        <Box flexGrow={1} p={1.5}>
          {selectedGroup && (
            <>
              <Card sx={{ boxShadow: 2 }} variant="outlined">
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h5">
                          {selectedGroup.name}
                        </Typography>
                        {selectedGroup.default_group ? (
                          <Tooltip title="Remove Default Group Status">
                            <IconButton
                              onClick={() =>
                                handleSetDefaultGroup(selectedGroup.id, true)
                              }
                              disabled={isGroupAdmin}
                            >
                              <StarIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          !isGroupAdmin && (
                            <Tooltip title="Set as Default Group">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleSetDefaultGroup(selectedGroup.id, false)
                                }
                              >
                                <StarBorderIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )
                        )}
                        {selectedGroup.default_group && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              fontStyle: "italic",
                            }}
                          >
                            (Default group for new user signups)
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" alignItems="center" gap={1} mb={2}>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {selectedGroup.description}
                        </Typography>
                        {selectedGroup.always_handle_duplicates && (
                          <Tooltip title="All duplicate contacts in this group will be marked with a duplicate tag">
                            <Chip
                              label="Auto-Tag Duplicates"
                              size="small"
                              color="primary"
                              variant="outlined"
                              icon={<ContentCopyIcon />}
                              sx={{ ml: 1 }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      <Box mt={2}>
                        <Typography variant="h6">Assigned Products:</Typography>
                        {selectedGroup.products.length > 0 ? (
                          selectedGroup.products.map((product) => (
                            <Chip
                              key={product.id}
                              label={product.name}
                              color="primary"
                              variant="outlined"
                              sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                            />
                          ))
                        ) : (
                          <Typography variant="body2">
                            No assigned products
                          </Typography>
                        )}
                      </Box>
                      
                      {/* Assigned Admins Section */}
                      <Box mt={2}>
                        <Typography variant="h6">Assigned Admins:</Typography>
                        {groupAdmins.length > 0 ? (
                          groupAdmins.map((admin) => (
                            <Chip
                              key={admin.id}
                              label={`${admin.first_name}  ${admin.last_name}`}
                              color="primary"
                              variant="outlined"
                              sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                            />
                          ))
                        ) : (
                          <Typography variant="body2">
                            No assigned admins
                          </Typography>
                        )}
                      </Box>

                      {/* Assigned GHL Locations Section */}
                      <Box mt={2}>
                        <Typography variant="h6">
                          Assigned GHL Locations:
                        </Typography>
                        {groupLocations.length > 0 ? (
                          groupLocations.map((location) => (
                            <Chip
                              key={location.id}
                              label={location.name}
                              color="primary"
                              variant="outlined"
                              sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                            />
                          ))
                        ) : (
                          <Typography variant="body2">
                            No assigned GHL locations
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-end" mt="auto">
                      <Tooltip title="Edit Group">
                        <span>
                          <IconButton
                            onClick={() => setOpenEditGroupDialog(true)}
                            disabled={isGroupAdmin} // Disable icon button if the user is a group admin
                          >
                            <EditIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Assign Products">
                        <span>
                          <IconButton
                            onClick={() => setOpenEditProductGroupDialog(true)}
                            disabled={isGroupAdmin} // Disable icon button if the user is a group admin
                          >
                            <AssignmentIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Assign Admins">
                        <span>
                          <IconButton
                            onClick={() => setOpenAssignAdminDialog(true)}
                            disabled={isGroupAdmin} // Disable icon button if the user is a group admin
                          >
                            <PersonAddIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Assign GHL Locations">
                        <span>
                          <IconButton
                            onClick={() => setOpenAssignLocationDialog(true)}
                            disabled={isGroupAdmin} // Disable icon button if the user is a group admin
                          >
                            <LocationOnIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Update Branding">
                        <span>
                          <IconButton
                            onClick={() => setOpenBrandingDialog(true)}
                            disabled={isGroupAdmin}
                          >
                            <BrandingWatermarkIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Delete Group">
                        <span>
                          <IconButton
                            onClick={() => {
                              setGroupToDelete(selectedGroup.id);
                              setOpenDeleteDialog(true);
                            }}
                            disabled={isGroupAdmin} // Disable icon button if the user is a group admin
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddMemberDialog(true)}
                >
                  Add Group Member
                </Button>
              </Box>
              <Box mt={2}>
                <DataGridPro
                  rows={members}
                  columns={columns}
                  pageSize={15}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 15 },
                    },
                  }}
                  pageSizeOptions={[15, 50, 100]}
                  pagination
                  autoHeight
                />
              </Box>
            </>
          )}
        </Box>
        <AddGroupMemberDialog
          open={openAddMemberDialog}
          onClose={() => setOpenAddMemberDialog(false)}
          onAddMember={handleAddMember}
          groupMembers={members} // Pass the current group members
        />
        <EditGroupDialog
          open={openEditGroupDialog}
          onClose={() => setOpenEditGroupDialog(false)}
          group={selectedGroup}
          onEditGroup={handleEditGroup}
        />
        <EditProductGroupDialog
          open={openEditProductGroupDialog}
          onClose={() => setOpenEditProductGroupDialog(false)}
          group={selectedGroup}
          onEditProductGroup={handleEditProductGroup}
        />
        <AddGroupDialog
          open={openAddGroupDialog}
          onClose={() => setOpenAddGroupDialog(false)}
          onAddGroup={handleAddGroup}
        />
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this group?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmation} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRemoveMemberDialog}
          onClose={() => setOpenRemoveMemberDialog(false)}
        >
          <DialogTitle>Confirm Remove Member</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove this member from the group?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenRemoveMemberDialog(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleRemoveMember} color="primary">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <AssignAdminDialog
        open={openAssignAdminDialog}
        onClose={() => setOpenAssignAdminDialog(false)}
        onAssignAdmin={handleAssignAdmin}
        groupId={selectedGroup?.id}
        currentAdmins={groupAdmins}
      />
      <AssignLocationDialog
        open={openAssignLocationDialog}
        onClose={() => setOpenAssignLocationDialog(false)}
        onAssignLocation={handleAssignLocation}
        groupId={selectedGroup?.id}
        currentLocations={groupLocations}
      />
      <UpdateGroupBranding
        open={openBrandingDialog}
        onClose={() => setOpenBrandingDialog(false)}
        group={selectedGroup}
        fetchGroups={() => fetchGroups(true)}
      />
      <Dialog
        open={openDefaultGroupDialog}
        onClose={() => setOpenDefaultGroupDialog(false)}
      >
        <DialogTitle>
          {groupToSetDefault?.isRemoving
            ? "Remove Default Group"
            : "Set Default Group"}
        </DialogTitle>
        <DialogContent>
          {groupToSetDefault?.isRemoving ? (
            <Typography>
              Are you sure you want to remove "{groupToSetDefault?.name}" as the
              default group? New users will need to be manually assigned to
              groups.
            </Typography>
          ) : (
            <>
              <Typography gutterBottom>
                Are you sure you want to set "{groupToSetDefault?.name}" as the
                default group?
              </Typography>
              <Typography variant="body2" color="text.secondary">
                New user signups will automatically be added to this group and
                will have access to {selectedGroup?.products.length} products
                assigned to this group.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDefaultGroupDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await customFetch(
                `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupToSetDefault.id}/default`,
                {
                  method: "PUT",
                }
              );
              fetchGroups(true);
              setOpenDefaultGroupDialog(false);
            }}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
