import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Switch,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const EditProductDialog = ({
  open,
  onClose,
  tags,
  mappingGroups,
  selectedGroup,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [currentTags, setCurrentTags] = useState(tags);
  const [selectedMappingGroup, setSelectedMappingGroup] =
    useState(selectedGroup);

  useEffect(() => {
    setCurrentTags(tags);
    setSelectedMappingGroup(selectedGroup);
  }, [tags, selectedGroup]);

  const handleAddTag = () => {
    if (inputValue && currentTags.length < 10) {
      setCurrentTags([...currentTags, inputValue]);
      setInputValue("");
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setCurrentTags(currentTags.filter((tag) => tag !== tagToDelete));
  };

  const handleSave = () => {
    onSave(currentTags, selectedMappingGroup);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Product Details</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <TextField
            label="Add Tag"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddTag();
              }
            }}
            fullWidth
          />
          <Button
            onClick={handleAddTag}
            disabled={!inputValue || currentTags.length >= 10}
            sx={{ marginLeft: 2, marginTop: 2 }}
          >
            Add Tag
          </Button>
        </Box>
        <Box display="flex" flexWrap="wrap" mt={2}>
          {currentTags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleDeleteTag(tag)}
              sx={{ margin: 0.5 }}
            />
          ))}
        </Box>
        <Autocomplete
          options={mappingGroups}
          getOptionLabel={(option) => option.name}
          value={selectedMappingGroup}
          onChange={(event, newValue) => setSelectedMappingGroup(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Mapping Group"
              fullWidth
              sx={{ marginTop: 3 }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TenantAdminProductCatalog = () => {
  const currentUser = decode();
  const [catalogItems, setCatalogItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemTags, setSelectedItemTags] = useState([]);
  const [mappingGroups, setMappingGroups] = useState([]);
  const [selectedMappingGroup, setSelectedMappingGroup] = useState(null);

  const fetchCatalogItems = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`
      );
      if (!response.ok) throw new Error("Failed to fetch catalog items");
      const data = await response.json();
      setCatalogItems(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchMappingGroups = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/mappingGroups`
      );
      if (!response.ok) throw new Error("Failed to fetch mapping groups");
      const data = await response.json();
      setMappingGroups(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCatalogItems();
    fetchMappingGroups();
  }, []);

  const handleToggleActive = async (id, isActive) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ is_active: !isActive }),
        }
      );
      if (!response.ok) throw new Error("Failed to update catalog item");
      fetchCatalogItems(); // Refresh the catalog items after update
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditProduct = (id, tags, mappingGroupId) => {
    const selectedGroup = mappingGroups.find(
      (group) => group.id === mappingGroupId
    );
    setSelectedItemId(id);
    setSelectedItemTags(tags);
    setSelectedMappingGroup(selectedGroup);
    setEditDialogOpen(true);
  };

  const handleSaveProductDetails = async (updatedTags, updatedMappingGroup) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog/${selectedItemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            crm_tags: updatedTags,
            mapping_group_id: updatedMappingGroup.id,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to update product details");
      setEditDialogOpen(false);
      fetchCatalogItems(); // Refresh the catalog items after update
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "tenant_name", headerName: "Tenant", flex: .65 },
    { field: "price", headerName: "Price", flex: 0.75 },
    { field: "product_type_name", headerName: "Product Type", flex: 1.4 },
    { field: "daily_cap", headerName: "Daily Cap Min", type: "Number", flex: 1 },
    { field: "order_minimum", headerName: "Order Minimum", type: "Number", flex: 1 },
    {
      field: "tenant_groups",
      headerName: "Tenant Groups",
      flex: 0.75,
      renderCell: (params) => (
        <Tooltip title={params.value.map((group) => group.name).join(", ")}>
          <Chip label={params.value.length} />
        </Tooltip>
      ),
    },
    {
      field: "crm_tags",
      headerName: "CRM Tags",
      flex: 0.75,
      renderCell: (params) => (
        <Tooltip title={params.value.join(", ")}>
          <Chip label={params.value.length} />
        </Tooltip>
      ),
    },
    {
      field: "mapping_group_name",
      headerName: "Mapping Group",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || "No Mapping Group"}>
          <Chip
            label={params.value || "No Mapping Group"}
            onClick={() =>
              handleEditProduct(
                params.id,
                params.row.crm_tags,
                params.row.mapping_group_id
              )
            }
          />
        </Tooltip>
      ),
    },
    {
      field: "is_active",
      headerName: "Is Active",
      width: 80,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() => handleToggleActive(params.id, params.value)}
          color="primary"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          onClick={() =>
            handleEditProduct(
              params.id,
              params.row.crm_tags,
              params.row.mapping_group_id
            )
          }
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading catalog items: {error}</p>;

  return (
    <div style={{ height: 600, width: "100%" }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // Center align text
        }}
      >
        Product Catalog
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2, // Theme spacing unit
          textAlign: "left", // Center align text
        }}
      >
        The product catalog allows tenant admins to view the list of the
        assigned products from your partner administrator. Tenant admins can set
        a product to active or disabled and view the list of groups which have
        access to a given product. Additional products can be added and assigned
        from your partner administrator.
      </Typography>
      <DataGridPro
        rows={catalogItems}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        autoHeight
      />
      <EditProductDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        tags={selectedItemTags}
        mappingGroups={mappingGroups}
        selectedGroup={selectedMappingGroup}
        onSave={handleSaveProductDetails}
      />
    </div>
  );
};

export default TenantAdminProductCatalog;
