export const timezonesList = [
  { value: "US/Alaska", label: "US/Alaska" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles" },
  { value: "US/Arizona", label: "US/Arizona" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/Eastern", label: "US/Eastern" },
  { value: "America/Santo_Domingo", label: "America/Santo_Domingo" },
  { value: "America/Adak", label: "America/Adak" },
  { value: "America/Anchorage", label: "America/Anchorage" },
  { value: "America/Boise", label: "America/Boise" },
  { value: "America/Chicago", label: "America/Chicago" },
  { value: "America/Denver", label: "America/Denver" },
  { value: "America/Detroit", label: "America/Detroit" },
  {
    value: "America/Indiana/Indianapolis",
    label: "America/Indiana/Indianapolis",
  },
  { value: "America/Indiana/Knox", label: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo", label: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City" },
  { value: "America/Indiana/Vevay", label: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac", label: "America/Indiana/Winamac" },
  { value: "America/Juneau", label: "America/Juneau" },
  {
    value: "America/Kentucky/Louisville",
    label: "America/Kentucky/Louisville",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "America/Kentucky/Monticello",
  },
  { value: "America/Los_Angeles", label: "America/Los_Angeles" },
  { value: "America/Menominee", label: "America/Menominee" },
  { value: "America/Metlakatla", label: "America/Metlakatla" },
  { value: "America/New_York", label: "America/New_York" },
  { value: "America/Nome", label: "America/Nome" },
  {
    value: "America/North_Dakota/Beulah",
    label: "America/North_Dakota/Beulah",
  },
  {
    value: "America/North_Dakota/Center",
    label: "America/North_Dakota/Center",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "America/North_Dakota/New_Salem",
  },
  { value: "America/Phoenix", label: "America/Phoenix" },
  { value: "America/Sitka", label: "America/Sitka" },
  { value: "America/Yakutat", label: "America/Yakutat" },
];
