import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Paper,
  Link,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import customFetch from "../../../../general/auth/customFetch";

export default function OverrunOrderCompletion() {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectStatus, setRedirectStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return; // Stripe has not loaded yet, wait until it's available
    }

    setMessage("Processing payment...");

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentIntentId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    const redirectStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    setRedirectStatus(redirectStatus);

    if (!clientSecret || !paymentIntentId) {
      setMessage(
        "Payment (client secret) or payment intent ID is missing. Please contact support."
      );
      setLoading(false);
      return;
    }

    if (!redirectStatus) {
      setMessage("Redirect status not provided, retrieving payment details...");
    } else if (redirectStatus === "succeeded") {
      setMessage("Payment succeeded!");
    } else if (redirectStatus === "failed") {
      setMessage("Payment failed or was canceled.");
      setLoading(false);
      if (paymentIntentId) {
        fetchOrderDetails(paymentIntentId);
      }
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        if (!paymentIntent) {
          setMessage("No payment intent found.");
          setLoading(false);
          return;
        }
        setPaymentStatus(paymentIntent.status);

        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            fetchOrderDetails(paymentIntentId);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            setLoading(false);
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            fetchOrderDetails(paymentIntentId);
            break;
          case "requires_action":
            setMessage("Payment requires additional authentication.");
            setLoading(false);
            break;
          default:
            setMessage("Something went wrong.");
            setLoading(false);
            break;
        }
      })
      .catch((error) => {
        setMessage("Failed to retrieve payment details. Please try again.");
        console.error("PaymentIntent retrieval error: ", error);
        setLoading(false);
      });
  }, [stripe]);

  const fetchOrderDetails = (paymentIntentId) => {
    customFetch(`/v1/orders/overruns/payment-intent/${paymentIntentId}`)
      .then((response) => response.json())
      .then((data) => {
        setOrderDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
        setMessage("Failed to retrieve order details. Please try again.");
        setLoading(false);
      });
  };

  const handleGoToOverruns = () => {
    navigate("/overruns");
  };

  const renderOrderStatusChip = (status) => {
    let color = "default";
    if (status === "paid" || status === "fulfilled") {
      color = "success";
    } else if (status === "failed" || status === "canceled") {
      color = "error";
    } else if (status === "pending") {
      color = "warning";
    }

    return <Chip label={status.toUpperCase()} color={color} />;
  };

  if (loading) {
    return (
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          Retrieving payment and order details...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Overrun Order Status
      </Typography>
      <Typography>{message}</Typography>

      {orderDetails && (
        <Paper
          elevation={3}
          sx={{
            p: 2,
            mt: 4,
            textAlign: "left",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order ID:</strong> {orderDetails.id}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Tokens Purchased:</strong> {orderDetails.total_tokens_ordered}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order Status:</strong>{" "}
            {renderOrderStatusChip(orderDetails.order_status)}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order Date:</strong>{" "}
            {new Date(orderDetails.created_time).toLocaleDateString()}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Total Amount:</strong> ${(orderDetails.total_tokens_ordered * 3).toFixed(2)}
          </Typography>
        </Paper>
      )}
      
      {(redirectStatus === "failed" || paymentStatus === "requires_payment_method") && (
        <Box sx={{ mt: 2 }}>
          <Typography color="error">
            If you encountered any issues, please{" "}
            <Link href="/feedback" underline="hover">
              report the issue here
            </Link>
            .
          </Typography>
        </Box>
      )}
      
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToOverruns}
        sx={{ mt: 4 }}
      >
        View Overrun Orders
      </Button>
    </Box>
  );
}