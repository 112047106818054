// TenantOverrunsDashboard.js

import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";
import CreateOrderButton from "./components/CreateOrderButton";
import CreateClaimButton from "./components/CreateClaimButton";
import OverrunOrdersTable from "./components/OverrunOrdersTable";
import OverrunClaimsTable from "./components/OverrunClaimsTable";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const TenantOverrunsDashboard = () => {
  const [orders, setOrders] = useState([]);
  const [claims, setClaims] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    loadOrders();
    loadClaims();
  }, []);

  const loadOrders = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/orders`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch overrun orders");
      }
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error("Failed to load overrun orders:", error);
    }
  };

  const loadClaims = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/overruns/claims`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch overrun claims");
      }
      const data = await response.json();
      setClaims(data);
    } catch (error) {
      console.error("Failed to load overrun claims:", error);
    }
  };

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Tenant Overruns Dashboard
      </Typography>
      
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h4" gutterBottom>
          Overrun Orders
        </Typography>
        {/* <CreateOrderButton onOrderCreated={loadOrders} /> */}
        <OverrunOrdersTable 
          orders={orders} 
          onOrderUpdated={loadOrders} 
          onOrderDeleted={loadOrders}
        />
      </Box>

      <Box>
        <Typography variant="h4" gutterBottom>
          Overrun Claims
        </Typography>
        {/* <CreateClaimButton onClaimCreated={loadClaims} /> */}
        <OverrunClaimsTable 
          claims={claims} 
          onClaimUpdated={loadClaims} 
          onClaimDeleted={loadClaims}
        />
      </Box>
    </Container>
  );
};

export default TenantOverrunsDashboard;