import React, { useEffect, useState, useCallback } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Paper,
  Link,
  Chip,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import customFetch from "../../../../general/auth/customFetch";

const POLLING_INTERVAL = 3000; // 3 seconds
const MAX_POLLING_ATTEMPTS = 20; // Maximum 1 minute of polling (20 * 3 seconds)

export default function OrderCompletion() {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectStatus, setRedirectStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [pollingCount, setPollingCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const navigate = useNavigate();

  const fetchOrderDetails = useCallback(
    async (paymentIntentId) => {
      try {
        const response = await customFetch(
          `/v1/orders/payment-intent/${paymentIntentId}`
        );
        const data = await response.json();
        setOrderDetails(data);

        // If the order status is still pending and we haven't reached max attempts,
        // continue polling
        if (
          (!data.stripe_charge_id) &&
          pollingCount < MAX_POLLING_ATTEMPTS
        ) {
          return false; // Continue polling
        }

        return true; // Stop polling
      } catch (error) {
        console.error("Error fetching order details:", error);
        return true; // Stop polling on error
      }
    },
    [pollingCount]
  );

  useEffect(() => {
    let pollingTimeout;

    const startPolling = async (paymentIntentId) => {
      setIsPolling(true);
      const shouldStopPolling = await fetchOrderDetails(paymentIntentId);

      if (!shouldStopPolling && pollingCount < MAX_POLLING_ATTEMPTS) {
        setPollingCount((prev) => prev + 1);
        pollingTimeout = setTimeout(
          () => startPolling(paymentIntentId),
          POLLING_INTERVAL
        );
      } else {
        setIsPolling(false);
        setLoading(false);
      }
    };

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentIntentId = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    const redirectStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );

    setRedirectStatus(redirectStatus);

    if (!clientSecret || !paymentIntentId) {
      setMessage("Payment details are missing. Please contact support.");
      setLoading(false);
      return;
    }

    const initializePayment = async () => {
      try {
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        if (!paymentIntent) {
          setMessage("No payment intent found.");
          setLoading(false);
          return;
        }

        setPaymentStatus(paymentIntent.status);

        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            await startPolling(paymentIntentId);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            await startPolling(paymentIntentId);
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            await fetchOrderDetails(paymentIntentId);
            setLoading(false);
            break;
          case "requires_action":
            setMessage("Payment requires additional authentication.");
            await startPolling(paymentIntentId);
            break;
          default:
            setMessage("Something went wrong.");
            setLoading(false);
            break;
        }
      } catch (error) {
        console.error("PaymentIntent retrieval error:", error);
        setMessage("Failed to retrieve payment details. Please try again.");
        setLoading(false);
      }
    };

    initializePayment();

    return () => {
      clearTimeout(pollingTimeout);
    };
  }, [stripe, pollingCount, fetchOrderDetails]);

  const handleGoToOrders = () => {
    navigate("/orders");
  };

  const renderOrderStatusChip = (status) => {
    let color = "default";
    let statusText = status;

    switch (status) {
      case "paid":
        color = "success";
        break;
      case "failed":
      case "canceled":
        color = "error";
        break;
      case "pending":
        color = "warning";
        statusText = "Pending order start";
        break;
      default:
        color = "default";
    }

    return <Chip label={statusText.toUpperCase()} color={color} />;
  };

  if (loading || isPolling) {
    return (
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <CircularProgress />
        <Typography sx={{ mt: 2, mb: 2 }}>
          {loading
            ? "Retrieving payment and order details..."
            : "Waiting for payment confirmation..."}
        </Typography>
        {isPolling && (
          <Box sx={{ width: "300px", margin: "0 auto" }}>
            <LinearProgress
              variant="determinate"
              value={(pollingCount / MAX_POLLING_ATTEMPTS) * 100}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Order Status
      </Typography>
      <Typography>{message}</Typography>

      {orderDetails && (
        <Paper
          elevation={3}
          sx={{
            p: 2,
            mt: 4,
            textAlign: "left",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order ID:</strong> {orderDetails.id}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Leads Ordered:</strong> {orderDetails.num_leads}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order Status:</strong>{" "}
            {renderOrderStatusChip(orderDetails.order_status)}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Order Date:</strong>{" "}
            {new Date(orderDetails.order_date).toLocaleDateString()}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>States:</strong>{" "}
            {orderDetails.states ? orderDetails.states.join(", ") : "N/A"}
          </Typography>
          {orderDetails.stripe_charge_id && (
            <Typography sx={{ mb: 2 }}>
              <strong>Payment Reference:</strong>{" "}
              {orderDetails.stripe_charge_id}
            </Typography>
          )}
        </Paper>
      )}

      {(redirectStatus === "failed" ||
        paymentStatus === "requires_payment_method") && (
        <Box sx={{ mt: 2 }}>
          <Typography color="error">
            If you encountered any issues, please{" "}
            <Link href="/feedback" underline="hover">
              report the issue here
            </Link>
            .
          </Typography>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToOrders}
        sx={{ mt: 4 }}
      >
        View All Orders
      </Button>
    </Box>
  );
}
