import React from "react";
import { Typography } from "@mui/material";

export default function Support() {
  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Support Calendars
      </Typography>
      <div className="table table-striped mt-5 text-center">
        <iframe
          src="https://ln.l2c.in/widget/group/v34zxwgV174AKrcEzaGG"
          style={{
            width: "90%",
            border: "none",
            overflow: "hidden",
            height: "110vh",
          }}
          scrolling="yes"
          id="v34zxwgV174AKrcEzaGG_1681516156743"
        ></iframe>
        <br />
        <script
          src="https://ln.l2c.in/js/form_embed.js"
          type="text/javascript"
        ></script>
      </div>
    </div>
  );
}
