import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  Alert
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const CustomFieldsPage = () => {
  const [customFields, setCustomFields] = useState([]);
  const [facebookFormsData, setFacebookFormsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUnmappedKey, setSelectedUnmappedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    name: "",
    field_key: "",
    facebook_field_remap: [],
  });
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  const currentUser = decode();

  useEffect(() => {
    loadCustomFields();
    loadFacebookFormsData();
  }, []);

  const loadCustomFields = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch custom fields");
      }
      const data = await response.json();
      setCustomFields(data);
    } catch (error) {
      console.error("Failed to load custom fields:", error);
    }
  };

  const loadFacebookFormsData = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields/facebook-forms`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Facebook forms data");
      }
      const data = await response.json();
      setFacebookFormsData(data);
    } catch (error) {
      console.error("Failed to load Facebook forms data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = (field = null) => {
    if (field) {
      setEditMode(true);
      setSelectedField(field);
      setFormValues({
        name: field.name,
        field_key: field.field_key,
        facebook_field_remap: field.facebook_field_remap || [],
      });
    } else {
      setEditMode(false);
      setSelectedField(null);
      setFormValues({
        name: "",
        field_key: "",
        facebook_field_remap: [],
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields/${id}`,
        { method: "DELETE" }
      );
      if (!response.ok) {
        throw new Error("Failed to delete custom field");
      }
      setSnackbarMessage("Custom field deleted successfully");
      loadCustomFields();
    } catch (error) {
      console.error("Failed to delete custom field:", error);
      setSnackbarMessage("Failed to delete custom field");
    }
  };

  const handleSubmit = async () => {
    const method = editMode ? "PUT" : "POST";
    const url = editMode
      ? `/v1/tenantadmin/${currentUser.tenant_id}/customFields/${selectedField.id}`
      : `/v1/tenantadmin/${currentUser.tenant_id}/customFields`;

    const body = {
      name: formValues.name,
      field_key: formValues.field_key,
      facebook_field_remap: formValues.facebook_field_remap,
    };

    try {
      const response = await customFetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Failed to save custom field");
      }
      setSnackbarMessage(
        editMode
          ? "Custom field updated successfully"
          : "Custom field created successfully"
      );
      handleClose();
      loadCustomFields();
    } catch (error) {
      console.error("Failed to save custom field:", error);
      setSnackbarMessage("Failed to save custom field");
    }
  };

  const handleQuickAdd = (item) => {
    setFormValues({
      name: "",
      field_key: "",
      facebook_field_remap: [item.key],
    });
    setOpen(true);
  };

  const handleAddFacebookKey = (key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      facebook_field_remap: [...prevValues.facebook_field_remap, key],
    }));
  };

  const handleRemoveFacebookKey = (keyToRemove) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      facebook_field_remap: prevValues.facebook_field_remap.filter(
        (key) => key !== keyToRemove
      ),
    }));
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "field_key", headerName: "Field Key", flex: 1.2 },
    {
      field: "facebook_field_remap",
      headerName: "Facebook Form Remap Keys",
      flex: 1.5,
      renderCell: (params) => {
        const keys = params.value || [];
        return (
          <Tooltip
            title={
              <div>
                {keys.map((key, index) => (
                  <div key={index}>{key}</div>
                ))}
              </div>
            }
          >
            <Chip
              label={`${keys.length} Form key${keys.length !== 1 ? "s" : ""}`}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const getUnmappedKeys = () => {
    const mappedKeys = new Set(
      customFields.flatMap((field) => field.facebook_field_remap || [])
    );
    const unmappedKeysMap = new Map();

    facebookFormsData.forEach((pageData) => {
      pageData.form.questions.forEach((question) => {
        if (!mappedKeys.has(question.key)) {
          if (!unmappedKeysMap.has(question.key)) {
            unmappedKeysMap.set(question.key, {
              key: question.key,
              label: question.label,
              options: question.options,
              pages: [],
            });
          }
          unmappedKeysMap.get(question.key).pages.push({
            pageName: pageData.page.name,
            formName: pageData.form.name,
          });
        }
      });
    });

    return Array.from(unmappedKeysMap.values());
  };

  const handleQuickLink = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedUnmappedKey(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedUnmappedKey(null);
  };

  const handleLinkToExisting = async (existingField) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/customFields/${existingField.id}/addFacebookKey`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            facebook_key: selectedUnmappedKey.key,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update custom field");
      }
      setSnackbarMessage("Facebook key added to custom field successfully");
      await loadCustomFields();
      await loadFacebookFormsData();
    } catch (error) {
      console.error("Failed to add Facebook key to custom field:", error);
      setSnackbarMessage("Failed to add Facebook key to custom field");
    }
    handleCloseMenu();
  };

  const getCustomFieldsWithoutPlaceholder = () => {
    return customFields.filter((field) => !field.placeholder);
  };

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Manage Custom Fields
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        sx={{ marginBottom: 2 }}
      >
        Add Custom Field
      </Button>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={customFields}
          columns={columns}
          pageSize={10}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
        />
      </div>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Unmapped Facebook Custom Form Fields
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {isLoading
                ? "Loading unmapped fields..."
                : `${getUnmappedKeys().length} Unmapped Fields Available`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {getUnmappedKeys().map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <>
                        {item.label}
                        <Tooltip
                          title={
                            <div>
                              {item.pages.map((page, idx) => (
                                <p key={idx}>
                                  Page: {page.pageName}, Form: {page.formName}
                                </p>
                              ))}
                            </div>
                          }
                        >
                          <IconButton size="small">
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    secondary={
                      <>
                        <Typography component="span" variant="body2">
                          Key: {item.key}
                        </Typography>
                        {item.options && (
                          <Typography
                            component="span"
                            variant="body2"
                            style={{ display: "block" }}
                          >
                            Options:{" "}
                            {item.options.map((opt) => opt.value).join(", ")}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleQuickAdd(item)}>
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={(event) => handleQuickLink(event, item)}
                    >
                      <LinkIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {getCustomFieldsWithoutPlaceholder().map((field) => (
          <MenuItem key={field.id} onClick={() => handleLinkToExisting(field)}>
            {field.name}
          </MenuItem>
        ))}
      </Menu>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {editMode ? "Edit Custom Field" : "Add Custom Field"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Field Key"
            type="text"
            fullWidth
            value={formValues.field_key}
            onChange={(e) =>
              setFormValues({ ...formValues, field_key: e.target.value })
            }
          />
          <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
            Facebook Form Remap Keys:
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              marginBottom: "16px",
            }}
          >
            {formValues.facebook_field_remap.map((key, index) => (
              <Chip
                key={index}
                label={key}
                onDelete={() => handleRemoveFacebookKey(key)}
              />
            ))}
          </div>
          <TextField
            margin="dense"
            label="Add Facebook Form Remap Key"
            type="text"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddFacebookKey(e.target.value);
                e.target.value = "";
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
      <IconButton
        onClick={handleHelpDialogOpen}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          width: 64,
          height: 64,
        }}
      >
        <HelpOutlineIcon sx={{ width: "100%", height: "100%" }} />
      </IconButton>

      <Dialog
        open={helpDialogOpen}
        onClose={handleHelpDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Facebook Form Key Mapping Feature</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h2>Overview</h2>
              This feature allows you to map Facebook form keys to your custom
              field keys in our application. This mapping is crucial for
              correctly processing and storing lead information obtained from
              Facebook Lead Ads forms.

            <h2>Why Map Facebook Form Keys?</h2>
            <ol>
              <li>
                <strong>Data Consistency:</strong> Facebook form fields often
                have different names than your custom fields in Go High Level
                (GHL). Mapping ensures that data from Facebook is correctly
                assigned to the appropriate fields in your system.
              </li>
              <li>
                <strong>Flexibility:</strong> Facebook form field names can vary
                between different ad campaigns or forms. This mapping feature
                allows you to accommodate these variations without changing your
                core data structure.
              </li>
              <li>
                <strong>Custom Field Integration:</strong> By mapping Facebook
                keys to your custom field keys, you ensure that all relevant
                data from Facebook leads is captured in your GHL custom fields,
                maintaining data integrity across platforms.
              </li>
            </ol>

            <h2>How It Works</h2>
            <ol>
              <li>
                <strong>Facebook Form Keys:</strong> These are the identifiers
                used in your Facebook Lead Ads forms. They represent the
                questions or fields in your Facebook forms.
              </li>
              <li>
                <strong>Field Keys:</strong> These are the custom field
                identifiers in our application, which correspond to custom
                fields in Go High Level.
              </li>
              <li>
                <strong>Mapping:</strong> You create a link between a Facebook
                form key and your field key. This tells our system how to
                translate data from Facebook into your custom fields.
              </li>
            </ol>

            <h2>Usage</h2>
            <ol>
              <li>
                <strong>Identify Facebook Form Keys:</strong> Review your
                Facebook Lead Ads forms and note the keys used for each field.
              </li>
              <li>
                <strong>Create Custom Fields:</strong> Ensure you have
                corresponding custom fields set up in Go High Level for each
                piece of data you want to capture.
              </li>
              <li>
                <strong>Map in Our Application:</strong>
                <ul>
                  <li>Navigate to the Custom Fields page.</li>
                  <li>
                    For each custom field, you can add one or more Facebook form
                    keys in the "Facebook Form Remap Keys" section.
                  </li>
                  <li>
                    You can add multiple Facebook keys to a single custom field
                    to accommodate different form variations.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Automatic Processing:</strong> When a new lead comes in
                from Facebook:
                <ul>
                  <li>
                    Our system identifies the Facebook form keys in the lead
                    data.
                  </li>
                  <li>
                    It uses your mapping to translate these keys to your custom
                    field keys.
                  </li>
                  <li>
                    The data is then correctly assigned to the appropriate
                    custom fields in Go High Level.
                  </li>
                </ul>
              </li>
            </ol>

            <Alert severity="info" sx={{ margin: "20px 0px" }}>
              <strong>Best Practices:</strong>
              <ul>
                <li>
                  Regularly review your Facebook forms and update mappings as
                  needed, especially when creating new ad campaigns or forms.
                </li>
                <li>
                  Try to use consistent naming conventions in your Facebook
                  forms to minimize the need for multiple mappings.
                </li>
                <li>
                  Test new mappings by submitting a lead through your Facebook
                  form to ensure data is correctly captured in Go High Level.
                </li>
                <li>
                  Keep a record of your mappings for reference, especially if
                  you have multiple team members managing leads and
                  integrations.
                </li>
              </ul>
            </Alert>

            <Alert severity="warning" sx={{ margin: "20px 0px" }}>
              If you encounter any issues with mapping or data transfer, please
              contact our support team for assistance.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CustomFieldsPage;
