import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const DeleteUser = ({ open, onClose, user, refreshUsers }) => {
  const currentUser = decode();

  const handleDelete = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/${user.id}`,
        {
          method: "DELETE",
        }
      );
      refreshUsers();
      onClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this user?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUser;
