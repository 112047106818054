import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import decode from "../../../general/util/jwtDecode";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import customFetch from "../../../general/auth/customFetch";
import { PARTNER_ADMIN_ROLE_NAME } from "../../../general/constants/constants";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function PartnerTenantUsers() {
  const currentUser = decode();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const fetchData = async () => {
    try {
      const tenantsResponse = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      const tenantsData = await tenantsResponse.json();
      setTenants(tenantsData);

      const usersResponse = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenant-users`
      );
      const usersData = await usersResponse.json();

      const combinedData = usersData.map((user) => ({
        ...user,
        full_name: `${user.first_name} ${user.last_name}`,
        tenant_name: tenantsData.find((tenant) => tenant.id === user.tenant_id)?.name || 'Unknown',
      }));

      setUsers(combinedData);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      window.location = "/login";
    } else if (currentUser && currentUser.partner_role !== PARTNER_ADMIN_ROLE_NAME) {
      window.location = "/";
    } else {
      fetchData();
    }
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: "User Name",
      flex: 0.75,
    },
    { field: "email", headerName: "Email", flex: 1.4 },
    { field: "tenant_name", headerName: "Tenant", flex: 1 },
    { field: "role_name", headerName: "Role", flex: .8 },
    {
      field: "crm_coupon",
      headerName: "CRM Coupon",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "onboarded",
      headerName: "Onboarded",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Partner Tenant Users
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          This page displays all tenant users across all tenants for this partner.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <DataGridPro
            rows={users}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            headerFilters
            pageSizeOptions={[10, 25, 50]}
            pagination
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              headerFilterMenu: null,
            }}
            autoHeight
          />
        </Box>
      </Box>
    </div>
  );
}