import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import decode from "../../../general/util/jwtDecode.js";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch.js";
import CreateIntegration from "./components/CreateIntegration.js";
import IntegrationCard from "../../../general/components/cards/IntegrationCard.js";
import {
  GROUP_ADMIN_ROLE_NAME,
  TENANT_ADMIN_ROLE_NAME,
} from "../../../general/constants/constants.js";
import EditIntegration from "./components/EditIntegration.js";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function Integrations() {
  const [integrations, setIntegrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [integrationToDelete, setIntegrationToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const currentUser = decode();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Add these new state variables
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  // Update the handleEdit function
  const handleEdit = (integration) => {
    setSelectedIntegration(integration);
    setIsEditDialogOpen(true);
  };

  // Add this function to close the edit dialog
  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedIntegration(null);
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.role !== TENANT_ADMIN_ROLE_NAME &&
      currentUser.role !== GROUP_ADMIN_ROLE_NAME
    ) {
      window.location = "/login";
      return;
    }
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/integrations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch integrations");
      }
      const data = await response.json();
      setIntegrations(data.integrations);
    } catch (error) {
      console.error("Error fetching integrations:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (integration) => {
    setIntegrationToDelete(integration);
    setDeleteDialogOpen(true);
    setDeleteError(null); // Clear any previous errors
  };

  const handleConfirmDelete = async () => {
    if (integrationToDelete) {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/integrations/${integrationToDelete.id}`,
          {
            method: "DELETE",
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to delete integration");
        }
        fetchIntegrations(); // Refresh the list after deletion
        setDeleteDialogOpen(false);
        setIntegrationToDelete(null);
      } catch (error) {
        console.error("Error deleting integration:", error);
        setDeleteError(error.message);
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIntegrationToDelete(null);
    setDeleteError(null);
  };

  const handleConnect = async (integration) => {
    console.log("Connecting integration:", integration);
    try {
      const response = await customFetch(
        `/v1/auth/facebook/oauth?integration_id=${integration.id}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to initiate Facebook OAuth");
      }
      const data = await response.json();

      // Store the isReconnection flag in sessionStorage
      sessionStorage.setItem("isReconnection", data.isReconnection);

      // Open the Facebook OAuth URL in a new window
      const authWindow = window.open(
        data.url,
        "FacebookOAuth",
        "width=760,height=960"
      );

      // Set up a listener for the OAuth callback
      window.addEventListener("message", handleOAuthCallback, false);

      // Poll the auth window to check when it's closed
      const pollTimer = setInterval(() => {
        if (authWindow.closed) {
          clearInterval(pollTimer);
          window.removeEventListener("message", handleOAuthCallback);
          // Refresh the integrations list
          fetchIntegrations();
        }
      }, 500);
    } catch (error) {
      console.error("Error connecting integration:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // Update the handleOAuthCallback function
  const handleOAuthCallback = async (event) => {
    // Ensure the message is from the expected origin
    if (event.origin !== window.location.origin) return;

    if (event.data.type === "FACEBOOK_OAUTH_CALLBACK") {
      const { code, state } = event.data;
      try {
        const response = await customFetch("/v1/auth/facebook/callback", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code, state }),
        });

        if (!response.ok) {
          throw new Error("Failed to complete Facebook OAuth");
        }

        const result = await response.json();

        // Get the isReconnection flag from sessionStorage
        const isReconnection =
          sessionStorage.getItem("isReconnection") === "true";

        // Show a success message based on whether it's a new connection or reconnection
        setSnackbar({
          open: true,
          message: isReconnection
            ? "Facebook integration reconnected successfully"
            : "Facebook integration connected successfully",
          severity: "success",
        });

        // Clear the isReconnection flag from sessionStorage
        sessionStorage.removeItem("isReconnection");

        // Fetch the updated integrations
        await fetchIntegrations();
      } catch (error) {
        console.error("Error completing Facebook OAuth:", error);
        setSnackbar({
          open: true,
          message: "Failed to complete Facebook OAuth",
          severity: "error",
        });
      }
    }
  };

  const handleCreateSuccess = (newIntegration) => {
    setIntegrations([...integrations, newIntegration]);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Typography variant="h6">Loading integrations...</Typography>;
    }

    if (integrations.length === 0) {
      return (
        <Typography variant="h6" color="text.secondary">
          No integrations created yet. Click "Add Integration" to get started.
        </Typography>
      );
    }

    return (
      <Grid container spacing={2} justifyContent="left">
        {integrations.map((integration) => (
          <Grid item key={integration.id}>
            <IntegrationCard
              integration={integration}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onConnect={handleConnect}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          mb: 2,
        }}
      >
        Marketplace Integrations
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
        }}
      >
        Integrations allow you to connect your account with various third-party
        services and platforms. These connections enhance your workflow by
        enabling data synchronization, automating tasks, and extending the
        functionality of your account. Manage your integrations here to
        streamline your operations and improve efficiency across your connected
        services.
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          Add Integration
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>{renderContent()}</Box>

      <CreateIntegration
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onCreateSuccess={handleCreateSuccess}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Integration Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this integration? This action cannot
            be undone.
          </DialogContentText>
          {deleteError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {deleteError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <EditIntegration
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        integration={selectedIntegration}
        onUpdateSuccess={fetchIntegrations}
      />
    </Box>
  );
}
