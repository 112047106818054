import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const EditCampaignDialog = ({ open, onClose, campaign, onEdit }) => {
  const [formState, setFormState] = useState({
    custom_name: campaign.custom_name || '',
    description: campaign.description || '',
    industry: campaign.industry || '',
    niche: campaign.niche || '',
    isActive: campaign.isactive,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    onEdit(campaign.id, formState);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Campaign</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Custom Name"
          name="custom_name"
          value={formState.custom_name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={formState.description}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Industry"
          name="industry"
          value={formState.industry}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Niche"
          name="niche"
          value={formState.niche}
          onChange={handleChange}
          fullWidth
        />
        <FormControl fullWidth margin="dense">
        <InputLabel >Is Active</InputLabel>
          <Select
            label="Is Active"
            name="isActive"
            value={formState.isActive}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
          
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCampaignDialog;
