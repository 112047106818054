export const timezoneData = [
  {
    zipcode: "00501",
    city: "Holtsville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "00544",
    city: "Holtsville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "00601",
    city: "Adjuntas",
    state: "PR",
    areacode: 787,
    county: "Adjuntas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00602",
    city: "Aguada",
    state: "PR",
    areacode: 787,
    county: "Aguada",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00603",
    city: "Aguadilla",
    state: "PR",
    areacode: 787,
    county: "Aguadilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00604",
    city: "Aguadilla",
    state: "PR",
    areacode: 787,
    county: "Aguadilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00605",
    city: "Aguadilla",
    state: "PR",
    areacode: 787,
    county: "Aguadilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00606",
    city: "Maricao",
    state: "PR",
    areacode: 787,
    county: "Maricao",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00610",
    city: "Anasco",
    state: "PR",
    areacode: 787,
    county: "Anasco",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00611",
    city: "Angeles",
    state: "PR",
    areacode: 787,
    county: "Utuado",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00612",
    city: "Arecibo",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00613",
    city: "Arecibo",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00614",
    city: "Arecibo",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00616",
    city: "Bajadero",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00617",
    city: "Barceloneta",
    state: "PR",
    areacode: 787,
    county: "Barceloneta",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00622",
    city: "Boqueron",
    state: "PR",
    areacode: 787,
    county: "Cabo Rojo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00623",
    city: "Cabo Rojo",
    state: "PR",
    areacode: 787,
    county: "Cabo Rojo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00624",
    city: "Penuelas",
    state: "PR",
    areacode: 787,
    county: "Penuelas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00627",
    city: "Camuy",
    state: "PR",
    areacode: 787,
    county: "Camuy",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00631",
    city: "Castaner",
    state: "PR",
    areacode: 787,
    county: "Lares",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00636",
    city: "Rosario",
    state: "PR",
    areacode: 787,
    county: "San German",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00637",
    city: "Sabana Grande",
    state: "PR",
    areacode: 787,
    county: "Sabana Grande",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00638",
    city: "Ciales",
    state: "PR",
    areacode: 787,
    county: "Ciales",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00641",
    city: "Utuado",
    state: "PR",
    areacode: 787,
    county: "Utuado",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00646",
    city: "Dorado",
    state: "PR",
    areacode: 787,
    county: "Dorado",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00647",
    city: "Ensenada",
    state: "PR",
    areacode: 787,
    county: "Guanica",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00650",
    city: "Florida",
    state: "PR",
    areacode: 787,
    county: "Florida",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00652",
    city: "Garrochales",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00653",
    city: "Guanica",
    state: "PR",
    areacode: 787,
    county: "Guanica",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00656",
    city: "Guayanilla",
    state: "PR",
    areacode: 787,
    county: "Guayanilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00659",
    city: "Hatillo",
    state: "PR",
    areacode: 787,
    county: "Hatillo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00660",
    city: "Hormigueros",
    state: "PR",
    areacode: 787,
    county: "Hormigueros",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00662",
    city: "Isabela",
    state: "PR",
    areacode: 787,
    county: "Isabela",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00664",
    city: "Jayuya",
    state: "PR",
    areacode: 787,
    county: "Jayuya",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00667",
    city: "Lajas",
    state: "PR",
    areacode: 787,
    county: "Lajas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00669",
    city: "Lares",
    state: "PR",
    areacode: 787,
    county: "Lares",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00670",
    city: "Las Marias",
    state: "PR",
    areacode: 787,
    county: "Las Marias",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00674",
    city: "Manati",
    state: "PR",
    areacode: 787,
    county: "Manati",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00676",
    city: "Moca",
    state: "PR",
    areacode: 787,
    county: "Moca",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00677",
    city: "Rincon",
    state: "PR",
    areacode: 787,
    county: "Rincon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00678",
    city: "Quebradillas",
    state: "PR",
    areacode: 787,
    county: "Quebradillas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00680",
    city: "Mayaguez",
    state: "PR",
    areacode: 787,
    county: "Mayaguez",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00681",
    city: "Mayaguez",
    state: "PR",
    areacode: 787,
    county: "Mayaguez",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00682",
    city: "Mayaguez",
    state: "PR",
    areacode: 787,
    county: "Mayaguez",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00683",
    city: "San German",
    state: "PR",
    areacode: 787,
    county: "San German",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00685",
    city: "San Sebastian",
    state: "PR",
    areacode: 787,
    county: "San Sebastian",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00687",
    city: "Morovis",
    state: "PR",
    areacode: 787,
    county: "Morovis",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00688",
    city: "Sabana Hoyos",
    state: "PR",
    areacode: 787,
    county: "Arecibo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00690",
    city: "San Antonio",
    state: "PR",
    areacode: 787,
    county: "Aguadilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00692",
    city: "Vega Alta",
    state: "PR",
    areacode: 787,
    county: "Vega Alta",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00693",
    city: "Vega Baja",
    state: "PR",
    areacode: 787,
    county: "Vega Baja",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00694",
    city: "Vega Baja",
    state: "PR",
    areacode: 787,
    county: "Vega Alta",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00698",
    city: "Yauco",
    state: "PR",
    areacode: 787,
    county: "Yauco",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00703",
    city: "Aguas Buenas",
    state: "PR",
    areacode: 787,
    county: "Aguas Buenas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00704",
    city: "Aguirre",
    state: "PR",
    areacode: 787,
    county: "Salinas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00705",
    city: "Aibonito",
    state: "PR",
    areacode: 787,
    county: "Aibonito",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00707",
    city: "Maunabo",
    state: "PR",
    areacode: 787,
    county: "Maunabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00714",
    city: "Arroyo",
    state: "PR",
    areacode: 787,
    county: "Arroyo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00715",
    city: "Mercedita",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00716",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00717",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00718",
    city: "Naguabo",
    state: "PR",
    areacode: 787,
    county: "Naguabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00719",
    city: "Naranjito",
    state: "PR",
    areacode: 787,
    county: "Naranjito",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00720",
    city: "Orocovis",
    state: "PR",
    areacode: 787,
    county: "Orocovis",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00721",
    city: "Palmer",
    state: "PR",
    areacode: 787,
    county: "Rio Grande",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00723",
    city: "Patillas",
    state: "PR",
    areacode: 787,
    county: "Patillas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00725",
    city: "Caguas",
    state: "PR",
    areacode: 787,
    county: "Caguas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00726",
    city: "Caguas",
    state: "PR",
    areacode: 787,
    county: "Caguas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00727",
    city: "Caguas",
    state: "PR",
    areacode: 787,
    county: "Caguas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00728",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00729",
    city: "Canovanas",
    state: "PR",
    areacode: 787,
    county: "Canovanas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00730",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00731",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00732",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00733",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00734",
    city: "Ponce",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00735",
    city: "Ceiba",
    state: "PR",
    areacode: 787,
    county: "Ceiba",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00736",
    city: "Cayey",
    state: "PR",
    areacode: 787,
    county: "Cayey",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00737",
    city: "Cayey",
    state: "PR",
    areacode: 787,
    county: "Cayey",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00738",
    city: "Fajardo",
    state: "PR",
    areacode: 787,
    county: "Fajardo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00739",
    city: "Cidra",
    state: "PR",
    areacode: 787,
    county: "Cidra",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00740",
    city: "Puerto Real",
    state: "PR",
    areacode: 787,
    county: "Fajardo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00741",
    city: "Punta Santiago",
    state: "PR",
    areacode: 787,
    county: "Humacao",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00742",
    city: "Roosevelt Roads",
    state: "PR",
    areacode: 787,
    county: "Ceiba",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00744",
    city: "Rio Blanco",
    state: "PR",
    areacode: 787,
    county: "Naguabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00745",
    city: "Rio Grande",
    state: "PR",
    areacode: 787,
    county: "Rio Grande",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00751",
    city: "Salinas",
    state: "PR",
    areacode: 787,
    county: "Salinas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00754",
    city: "San Lorenzo",
    state: "PR",
    areacode: 787,
    county: "San Lorenzo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00757",
    city: "Santa Isabel",
    state: "PR",
    areacode: 787,
    county: "Santa Isabel",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00765",
    city: "Vieques",
    state: "PR",
    areacode: 787,
    county: "Vieques",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00766",
    city: "Villalba",
    state: "PR",
    areacode: 787,
    county: "Villalba",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00767",
    city: "Yabucoa",
    state: "PR",
    areacode: 787,
    county: "Yabucoa",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00769",
    city: "Coamo",
    state: "PR",
    areacode: 787,
    county: "Coamo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00771",
    city: "Las Piedras",
    state: "PR",
    areacode: 787,
    county: "Las Piedras",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00772",
    city: "Loiza",
    state: "PR",
    areacode: 787,
    county: "Loiza",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00773",
    city: "Luquillo",
    state: "PR",
    areacode: 787,
    county: "Luquillo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00775",
    city: "Culebra",
    state: "PR",
    areacode: 787,
    county: "Culebra",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00777",
    city: "Juncos",
    state: "PR",
    areacode: 787,
    county: "Juncos",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00778",
    city: "Gurabo",
    state: "PR",
    areacode: 787,
    county: "Gurabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00780",
    city: "Coto Laurel",
    state: "PR",
    areacode: 787,
    county: "Ponce",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00782",
    city: "Comerio",
    state: "PR",
    areacode: 787,
    county: "Comerio",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00783",
    city: "Corozal",
    state: "PR",
    areacode: 787,
    county: "Corozal",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00784",
    city: "Guayama",
    state: "PR",
    areacode: 787,
    county: "Guayama",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00785",
    city: "Guayama",
    state: "PR",
    areacode: 787,
    county: "Guayanilla",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00786",
    city: "La Plata",
    state: "PR",
    areacode: 787,
    county: "Aibonito",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00791",
    city: "Humacao",
    state: "PR",
    areacode: 787,
    county: "Humacao",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00792",
    city: "Humacao",
    state: "PR",
    areacode: 787,
    county: "Humacao",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00794",
    city: "Barranquitas",
    state: "PR",
    areacode: 787,
    county: "Barranquitas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00795",
    city: "Juana Diaz",
    state: "PR",
    areacode: 787,
    county: "Juana Diaz",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00801",
    city: "St Thomas",
    state: "VI",
    areacode: 340,
    county: "Saint Thomas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00802",
    city: "St Thomas",
    state: "VI",
    areacode: 340,
    county: "Saint Thomas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00803",
    city: "St Thomas",
    state: "VI",
    areacode: 340,
    county: "Saint Thomas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00804",
    city: "St Thomas",
    state: "VI",
    areacode: 340,
    county: "Saint Thomas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00805",
    city: "St Thomas",
    state: "VI",
    areacode: 340,
    county: "Saint Thomas",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00820",
    city: "Christiansted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00821",
    city: "Christiansted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00822",
    city: "Christiansted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00823",
    city: "Christiansted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00824",
    city: "Christiansted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00830",
    city: "St John",
    state: "VI",
    areacode: 340,
    county: "Saint John",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00831",
    city: "St John",
    state: "VI",
    areacode: 340,
    county: "Saint John",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00840",
    city: "Frederiksted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00841",
    city: "Frederiksted",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00850",
    city: "Kingshill",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00851",
    city: "Kingshill",
    state: "VI",
    areacode: 340,
    county: "Saint Croix",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00901",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00902",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00906",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00907",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00908",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00909",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00910",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00911",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00912",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00913",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00914",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00915",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00916",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00917",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00918",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00919",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00920",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00921",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00922",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00923",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00924",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00925",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00926",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00927",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00928",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00929",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00930",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00931",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00933",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00934",
    city: "Fort Buchanan",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00935",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00936",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00937",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00939",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00940",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00949",
    city: "Toa Baja",
    state: "PR",
    areacode: 787,
    county: "Toa Baja",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00950",
    city: "Toa Baja",
    state: "PR",
    areacode: 787,
    county: "Toa Baja",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00951",
    city: "Toa Baja",
    state: "PR",
    areacode: 787,
    county: "Toa Baja",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00952",
    city: "Sabana Seca",
    state: "PR",
    areacode: 787,
    county: "Toa Baja",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00953",
    city: "Toa Alta",
    state: "PR",
    areacode: 787,
    county: "Toa Alta",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00954",
    city: "Toa Alta",
    state: "PR",
    areacode: 787,
    county: "Toa Alta",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00955",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00956",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00957",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00958",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00959",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00960",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00961",
    city: "Bayamon",
    state: "PR",
    areacode: 787,
    county: "Bayamon",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00962",
    city: "Catano",
    state: "PR",
    areacode: 787,
    county: "Catano",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00963",
    city: "Catano",
    state: "PR",
    areacode: 787,
    county: "Catano",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00965",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00966",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00968",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00969",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00970",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00971",
    city: "Guaynabo",
    state: "PR",
    areacode: 787,
    county: "Guaynabo",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00975",
    city: "San Juan",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00976",
    city: "Trujillo Alto",
    state: "PR",
    areacode: 787,
    county: "Trujillo Alto",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00977",
    city: "Trujillo Alto",
    state: "PR",
    areacode: 787,
    county: "Trujillo Alto",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00978",
    city: "Saint Just",
    state: "PR",
    areacode: 787,
    county: "Trujillo Alto",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00979",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00981",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "San Juan",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00982",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00983",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00984",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00985",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00986",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00987",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "00988",
    city: "Carolina",
    state: "PR",
    areacode: 787,
    county: "Carolina",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "01001",
    city: "Agawam",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01002",
    city: "Amherst",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01003",
    city: "Amherst",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01004",
    city: "Amherst",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01005",
    city: "Barre",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01007",
    city: "Belchertown",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01008",
    city: "Blandford",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01009",
    city: "Bondsville",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01010",
    city: "Brimfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01011",
    city: "Chester",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01012",
    city: "Chesterfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01013",
    city: "Chicopee",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01014",
    city: "Chicopee",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01020",
    city: "Chicopee",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01021",
    city: "Chicopee",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01022",
    city: "Chicopee",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01026",
    city: "Cummington",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01027",
    city: "Easthampton",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01028",
    city: "East Longmeadow",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01029",
    city: "East Otis",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01030",
    city: "Feeding Hills",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01031",
    city: "Gilbertville",
    state: "MA",
    areacode: 413,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01032",
    city: "Goshen",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01033",
    city: "Granby",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01034",
    city: "Granville",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01035",
    city: "Hadley",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01036",
    city: "Hampden",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01037",
    city: "Hardwick",
    state: "MA",
    areacode: 413,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01038",
    city: "Hatfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01039",
    city: "Haydenville",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01040",
    city: "Holyoke",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01041",
    city: "Holyoke",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01050",
    city: "Huntington",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01053",
    city: "Leeds",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01054",
    city: "Leverett",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01056",
    city: "Ludlow",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01057",
    city: "Monson",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01059",
    city: "North Amherst",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01060",
    city: "Northampton",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01061",
    city: "Northampton",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01062",
    city: "Florence",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01063",
    city: "Northampton",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01066",
    city: "North Hatfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01068",
    city: "Oakham",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01069",
    city: "Palmer",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01070",
    city: "Plainfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01071",
    city: "Russell",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01072",
    city: "Shutesbury",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01073",
    city: "Southampton",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01074",
    city: "South Barre",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01075",
    city: "South Hadley",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01077",
    city: "Southwick",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01079",
    city: "Thorndike",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01080",
    city: "Three Rivers",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01081",
    city: "Wales",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01082",
    city: "Ware",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01083",
    city: "Warren",
    state: "MA",
    areacode: 413,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01084",
    city: "West Chesterfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01085",
    city: "Westfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01086",
    city: "Westfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01088",
    city: "West Hatfield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01089",
    city: "West Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01090",
    city: "West Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01092",
    city: "West Warren",
    state: "MA",
    areacode: 413,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01093",
    city: "Whately",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01094",
    city: "Wheelwright",
    state: "MA",
    areacode: 413,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01095",
    city: "Wilbraham",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01096",
    city: "Williamsburg",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01097",
    city: "Woronoco",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01098",
    city: "Worthington",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01101",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01102",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01103",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01104",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01105",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01106",
    city: "Longmeadow",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01107",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01108",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01109",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01111",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01115",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01116",
    city: "Longmeadow",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01118",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01119",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01128",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01129",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01138",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01139",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01144",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01151",
    city: "Indian Orchard",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01152",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01199",
    city: "Springfield",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01201",
    city: "Pittsfield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01202",
    city: "Pittsfield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01203",
    city: "Pittsfield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01220",
    city: "Adams",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01222",
    city: "Ashley Falls",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01223",
    city: "Becket",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01224",
    city: "Berkshire",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01225",
    city: "Cheshire",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01226",
    city: "Dalton",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01227",
    city: "Dalton",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01229",
    city: "Glendale",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01230",
    city: "Great Barrington",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01235",
    city: "Hinsdale",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01236",
    city: "Housatonic",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01237",
    city: "Lanesborough",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01238",
    city: "Lee",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01240",
    city: "Lenox",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01242",
    city: "Lenox Dale",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01243",
    city: "Middlefield",
    state: "MA",
    areacode: 413,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01244",
    city: "Mill River",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01245",
    city: "Monterey",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01247",
    city: "North Adams",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01252",
    city: "North Egremont",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01253",
    city: "Otis",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01254",
    city: "Richmond",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01255",
    city: "Sandisfield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01256",
    city: "Savoy",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01257",
    city: "Sheffield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01258",
    city: "South Egremont",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01259",
    city: "Southfield",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01260",
    city: "South Lee",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01262",
    city: "Stockbridge",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01263",
    city: "Stockbridge",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01264",
    city: "Tyringham",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01266",
    city: "West Stockbridge",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01267",
    city: "Williamstown",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01270",
    city: "Windsor",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01301",
    city: "Greenfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01302",
    city: "Greenfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01330",
    city: "Ashfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01331",
    city: "Athol",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01337",
    city: "Bernardston",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01338",
    city: "Buckland",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01339",
    city: "Charlemont",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01340",
    city: "Colrain",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01341",
    city: "Conway",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01342",
    city: "Deerfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01343",
    city: "Drury",
    state: "MA",
    areacode: 413,
    county: "Berkshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01344",
    city: "Erving",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01346",
    city: "Heath",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01347",
    city: "Lake Pleasant",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01349",
    city: "Millers Falls",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01350",
    city: "Monroe Bridge",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01351",
    city: "Montague",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01354",
    city: "Gill",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01355",
    city: "New Salem",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01360",
    city: "Northfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01364",
    city: "Orange",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01366",
    city: "Petersham",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01367",
    city: "Rowe",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01368",
    city: "Royalston",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01370",
    city: "Shelburne Falls",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01373",
    city: "South Deerfield",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01375",
    city: "Sunderland",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01376",
    city: "Turners Falls",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01378",
    city: "Warwick",
    state: "MA",
    areacode: 413,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01379",
    city: "Wendell",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01380",
    city: "Wendell Depot",
    state: "MA",
    areacode: 978,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01420",
    city: "Fitchburg",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01430",
    city: "Ashburnham",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01431",
    city: "Ashby",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01432",
    city: "Ayer",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01434",
    city: "Devens",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01436",
    city: "Baldwinville",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01438",
    city: "East Templeton",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01440",
    city: "Gardner",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01441",
    city: "Westminster",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01450",
    city: "Groton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01451",
    city: "Harvard",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01452",
    city: "Hubbardston",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01453",
    city: "Leominster",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01460",
    city: "Littleton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01462",
    city: "Lunenburg",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01463",
    city: "Pepperell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01464",
    city: "Shirley",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01467",
    city: "Still River",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01468",
    city: "Templeton",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01469",
    city: "Townsend",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01470",
    city: "Groton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01471",
    city: "Groton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01472",
    city: "West Groton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01473",
    city: "Westminster",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01474",
    city: "West Townsend",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01475",
    city: "Winchendon",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01501",
    city: "Auburn",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01503",
    city: "Berlin",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01504",
    city: "Blackstone",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01505",
    city: "Boylston",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01506",
    city: "Brookfield",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01507",
    city: "Charlton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01508",
    city: "Charlton City",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01509",
    city: "Charlton Depot",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01510",
    city: "Clinton",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01515",
    city: "East Brookfield",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01516",
    city: "Douglas",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01518",
    city: "Fiskdale",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01519",
    city: "Grafton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01520",
    city: "Holden",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01521",
    city: "Holland",
    state: "MA",
    areacode: 413,
    county: "Hampden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01522",
    city: "Jefferson",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01523",
    city: "Lancaster",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01524",
    city: "Leicester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01525",
    city: "Linwood",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01526",
    city: "Manchaug",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01527",
    city: "Millbury",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01529",
    city: "Millville",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01531",
    city: "New Braintree",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01532",
    city: "Northborough",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01534",
    city: "Northbridge",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01535",
    city: "North Brookfield",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01536",
    city: "North Grafton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01537",
    city: "North Oxford",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01538",
    city: "North Uxbridge",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01540",
    city: "Oxford",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01541",
    city: "Princeton",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01542",
    city: "Rochdale",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01543",
    city: "Rutland",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01545",
    city: "Shrewsbury",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01546",
    city: "Shrewsbury",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01550",
    city: "Southbridge",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01560",
    city: "South Grafton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01561",
    city: "South Lancaster",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01562",
    city: "Spencer",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01564",
    city: "Sterling",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01566",
    city: "Sturbridge",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01568",
    city: "Upton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01569",
    city: "Uxbridge",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01570",
    city: "Webster",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01571",
    city: "Dudley",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01581",
    city: "Westborough",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01583",
    city: "West Boylston",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01585",
    city: "West Brookfield",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01586",
    city: "West Millbury",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01588",
    city: "Whitinsville",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01590",
    city: "Sutton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01601",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01602",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01603",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01604",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01605",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01606",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01607",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01608",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01609",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01610",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01611",
    city: "Cherry Valley",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01612",
    city: "Paxton",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01613",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01614",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01615",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01653",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01655",
    city: "Worcester",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01701",
    city: "Framingham",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01702",
    city: "Framingham",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01703",
    city: "Framingham",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01704",
    city: "Framingham",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01705",
    city: "Framingham",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01718",
    city: "Acton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01719",
    city: "Boxborough",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01720",
    city: "Acton",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01721",
    city: "Ashland",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01730",
    city: "Bedford",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01731",
    city: "Hanscom AFB",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01740",
    city: "Bolton",
    state: "MA",
    areacode: 978,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01741",
    city: "Carlisle",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01742",
    city: "Concord",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01745",
    city: "Fayville",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01746",
    city: "Holliston",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01747",
    city: "Hopedale",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01748",
    city: "Hopkinton",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01749",
    city: "Hudson",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01752",
    city: "Marlborough",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01754",
    city: "Maynard",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01756",
    city: "Mendon",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01757",
    city: "Milford",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01760",
    city: "Natick",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01770",
    city: "Sherborn",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01772",
    city: "Southborough",
    state: "MA",
    areacode: 508,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01773",
    city: "Lincoln",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01775",
    city: "Stow",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01776",
    city: "Sudbury",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01778",
    city: "Wayland",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01784",
    city: "Woodville",
    state: "MA",
    areacode: 508,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01801",
    city: "Woburn",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01803",
    city: "Burlington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01805",
    city: "Burlington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01810",
    city: "Andover",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01812",
    city: "Andover",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01813",
    city: "Woburn",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01815",
    city: "Woburn",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01821",
    city: "Billerica",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01822",
    city: "Billerica",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01824",
    city: "Chelmsford",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01826",
    city: "Dracut",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01827",
    city: "Dunstable",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01830",
    city: "Haverhill",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01831",
    city: "Haverhill",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01832",
    city: "Haverhill",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01833",
    city: "Georgetown",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01834",
    city: "Groveland",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01835",
    city: "Haverhill",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01840",
    city: "Lawrence",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01841",
    city: "Lawrence",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01842",
    city: "Lawrence",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01843",
    city: "Lawrence",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01844",
    city: "Methuen",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01845",
    city: "North Andover",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01850",
    city: "Lowell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01851",
    city: "Lowell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01852",
    city: "Lowell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01853",
    city: "Lowell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01854",
    city: "Lowell",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01860",
    city: "Merrimac",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01862",
    city: "North Billerica",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01863",
    city: "North Chelmsford",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01864",
    city: "North Reading",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01865",
    city: "Nutting Lake",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01866",
    city: "Pinehurst",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01867",
    city: "Reading",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01876",
    city: "Tewksbury",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01879",
    city: "Tyngsboro",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01880",
    city: "Wakefield",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01885",
    city: "West Boxford",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01886",
    city: "Westford",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01887",
    city: "Wilmington",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01888",
    city: "Woburn",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01889",
    city: "North Reading",
    state: "MA",
    areacode: 978,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01890",
    city: "Winchester",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01899",
    city: "Andover",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01901",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01902",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01903",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01904",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01905",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01906",
    city: "Saugus",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01907",
    city: "Swampscott",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01908",
    city: "Nahant",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01910",
    city: "Lynn",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01913",
    city: "Amesbury",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01915",
    city: "Beverly",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01921",
    city: "Boxford",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01922",
    city: "Byfield",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01923",
    city: "Danvers",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01929",
    city: "Essex",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01930",
    city: "Gloucester",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01931",
    city: "Gloucester",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01936",
    city: "Hamilton",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01937",
    city: "Hathorne",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01938",
    city: "Ipswich",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01940",
    city: "Lynnfield",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01944",
    city: "Manchester",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01945",
    city: "Marblehead",
    state: "MA",
    areacode: 781,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01949",
    city: "Middleton",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01950",
    city: "Newburyport",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01951",
    city: "Newbury",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01952",
    city: "Salisbury",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01960",
    city: "Peabody",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01961",
    city: "Peabody",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01965",
    city: "Prides Crossing",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01966",
    city: "Rockport",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01969",
    city: "Rowley",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01970",
    city: "Salem",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01971",
    city: "Salem",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01982",
    city: "South Hamilton",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01983",
    city: "Topsfield",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01984",
    city: "Wenham",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "01985",
    city: "West Newbury",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02018",
    city: "Accord",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02019",
    city: "Bellingham",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02020",
    city: "Brant Rock",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02021",
    city: "Canton",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02025",
    city: "Cohasset",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02026",
    city: "Dedham",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02027",
    city: "Dedham",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02030",
    city: "Dover",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02032",
    city: "East Walpole",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02035",
    city: "Foxboro",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02038",
    city: "Franklin",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02040",
    city: "Greenbush",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02041",
    city: "Green Harbor",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02043",
    city: "Hingham",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02044",
    city: "Hingham",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02045",
    city: "Hull",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02047",
    city: "Humarock",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02048",
    city: "Mansfield",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02050",
    city: "Marshfield",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02051",
    city: "Marshfield Hills",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02052",
    city: "Medfield",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02053",
    city: "Medway",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02054",
    city: "Millis",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02055",
    city: "Minot",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02056",
    city: "Norfolk",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02059",
    city: "North Marshfield",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02060",
    city: "North Scituate",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02061",
    city: "Norwell",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02062",
    city: "Norwood",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02065",
    city: "Ocean Bluff",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02066",
    city: "Scituate",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02067",
    city: "Sharon",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02070",
    city: "Sheldonville",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02071",
    city: "South Walpole",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02072",
    city: "Stoughton",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02081",
    city: "Walpole",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02090",
    city: "Westwood",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02093",
    city: "Wrentham",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02108",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02109",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02110",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02111",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02112",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02113",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02114",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02115",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02116",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02117",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02118",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02119",
    city: "Roxbury",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02120",
    city: "Roxbury Crossing",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02121",
    city: "Dorchester",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02122",
    city: "Dorchester",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02123",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02124",
    city: "Dorchester Center",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02125",
    city: "Dorchester",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02126",
    city: "Mattapan",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02127",
    city: "South Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02128",
    city: "East Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02129",
    city: "Charlestown",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02130",
    city: "Jamaica Plain",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02131",
    city: "Roslindale",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02132",
    city: "West Roxbury",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02133",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02134",
    city: "Allston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02135",
    city: "Brighton",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02136",
    city: "Hyde Park",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02137",
    city: "Readville",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02138",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02139",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02140",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02141",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02142",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02143",
    city: "Somerville",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02144",
    city: "Somerville",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02145",
    city: "Somerville",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02148",
    city: "Malden",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02149",
    city: "Everett",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02150",
    city: "Chelsea",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02151",
    city: "Revere",
    state: "MA",
    areacode: 781,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02152",
    city: "Winthrop",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02153",
    city: "Medford",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02155",
    city: "Medford",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02156",
    city: "West Medford",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02163",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02169",
    city: "Quincy",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02170",
    city: "Quincy",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02171",
    city: "Quincy",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02176",
    city: "Melrose",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02180",
    city: "Stoneham",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02184",
    city: "Braintree",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02185",
    city: "Braintree",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02186",
    city: "Milton",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02187",
    city: "Milton Village",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02188",
    city: "Weymouth",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02189",
    city: "East Weymouth",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02190",
    city: "South Weymouth",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02191",
    city: "North Weymouth",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02196",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02199",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02201",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02203",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02204",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02205",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02206",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02210",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02211",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02212",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02215",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02217",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02222",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02238",
    city: "Cambridge",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02241",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02269",
    city: "Quincy",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02283",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02284",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02293",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02297",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02298",
    city: "Boston",
    state: "MA",
    areacode: 617,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02301",
    city: "Brockton",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02302",
    city: "Brockton",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02303",
    city: "Brockton",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02304",
    city: "Brockton",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02305",
    city: "Brockton",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02322",
    city: "Avon",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02324",
    city: "Bridgewater",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02325",
    city: "Bridgewater",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02327",
    city: "Bryantville",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02330",
    city: "Carver",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02331",
    city: "Duxbury",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02332",
    city: "Duxbury",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02333",
    city: "East Bridgewater",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02334",
    city: "Easton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02337",
    city: "Elmwood",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02338",
    city: "Halifax",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02339",
    city: "Hanover",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02341",
    city: "Hanson",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02343",
    city: "Holbrook",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02344",
    city: "Middleboro",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02345",
    city: "Manomet",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02346",
    city: "Middleboro",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02347",
    city: "Lakeville",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02348",
    city: "Lakeville",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02349",
    city: "Middleboro",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02350",
    city: "Monponsett",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02351",
    city: "Abington",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02355",
    city: "North Carver",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02356",
    city: "North Easton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02357",
    city: "North Easton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02358",
    city: "North Pembroke",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02359",
    city: "Pembroke",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02360",
    city: "Plymouth",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02361",
    city: "Plymouth",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02362",
    city: "Plymouth",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02364",
    city: "Kingston",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02366",
    city: "South Carver",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02367",
    city: "Plympton",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02368",
    city: "Randolph",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02370",
    city: "Rockland",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02375",
    city: "South Easton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02379",
    city: "West Bridgewater",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02381",
    city: "White Horse Beach",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02382",
    city: "Whitman",
    state: "MA",
    areacode: 781,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02420",
    city: "Lexington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02421",
    city: "Lexington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02445",
    city: "Brookline",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02446",
    city: "Brookline",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02447",
    city: "Brookline Village",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02451",
    city: "Waltham",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02452",
    city: "Waltham",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02453",
    city: "Waltham",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02454",
    city: "Waltham",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02455",
    city: "North Waltham",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02456",
    city: "New Town",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02457",
    city: "Babson Park",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02458",
    city: "Newton",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02459",
    city: "Newton Center",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02460",
    city: "Newtonville",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02461",
    city: "Newton Highlands",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02462",
    city: "Newton Lower Falls",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02464",
    city: "Newton Upper Falls",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02465",
    city: "West Newton",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02466",
    city: "Auburndale",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02467",
    city: "Chestnut Hill",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02468",
    city: "Waban",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02471",
    city: "Watertown",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02472",
    city: "Watertown",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02474",
    city: "Arlington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02475",
    city: "Arlington Heights",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02476",
    city: "Arlington",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02477",
    city: "Watertown",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02478",
    city: "Belmont",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02479",
    city: "Waverley",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02481",
    city: "Wellesley Hills",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02482",
    city: "Wellesley",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02492",
    city: "Needham",
    state: "MA",
    areacode: 781,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02493",
    city: "Weston",
    state: "MA",
    areacode: 781,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02494",
    city: "Needham Heights",
    state: "MA",
    areacode: 617,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02495",
    city: "Nonantum",
    state: "MA",
    areacode: 617,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02532",
    city: "Buzzards Bay",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02534",
    city: "Cataumet",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02535",
    city: "Chilmark",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02536",
    city: "East Falmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02537",
    city: "East Sandwich",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02538",
    city: "East Wareham",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02539",
    city: "Edgartown",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02540",
    city: "Falmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02541",
    city: "Falmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02542",
    city: "Buzzards Bay",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02543",
    city: "Woods Hole",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02552",
    city: "Menemsha",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02553",
    city: "Monument Beach",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02554",
    city: "Nantucket",
    state: "MA",
    areacode: 508,
    county: "Nantucket",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02556",
    city: "North Falmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02557",
    city: "Oak Bluffs",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02558",
    city: "Onset",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02559",
    city: "Pocasset",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02561",
    city: "Sagamore",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02562",
    city: "Sagamore Beach",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02563",
    city: "Sandwich",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02564",
    city: "Siasconset",
    state: "MA",
    areacode: 508,
    county: "Nantucket",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02568",
    city: "Vineyard Haven",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02571",
    city: "Wareham",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02574",
    city: "West Falmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02575",
    city: "West Tisbury",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02576",
    city: "West Wareham",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02584",
    city: "Nantucket",
    state: "MA",
    areacode: 508,
    county: "Nantucket",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02601",
    city: "Hyannis",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02630",
    city: "Barnstable",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02631",
    city: "Brewster",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02632",
    city: "Centerville",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02633",
    city: "Chatham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02634",
    city: "Centerville",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02635",
    city: "Cotuit",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02637",
    city: "Cummaquid",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02638",
    city: "Dennis",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02639",
    city: "Dennis Port",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02641",
    city: "East Dennis",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02642",
    city: "Eastham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02643",
    city: "East Orleans",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02644",
    city: "Forestdale",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02645",
    city: "Harwich",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02646",
    city: "Harwich Port",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02647",
    city: "Hyannis Port",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02648",
    city: "Marstons Mills",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02649",
    city: "Mashpee",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02650",
    city: "North Chatham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02651",
    city: "North Eastham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02652",
    city: "North Truro",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02653",
    city: "Orleans",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02655",
    city: "Osterville",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02657",
    city: "Provincetown",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02659",
    city: "South Chatham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02660",
    city: "South Dennis",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02661",
    city: "South Harwich",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02662",
    city: "South Orleans",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02663",
    city: "South Wellfleet",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02664",
    city: "South Yarmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02666",
    city: "Truro",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02667",
    city: "Wellfleet",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02668",
    city: "West Barnstable",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02669",
    city: "West Chatham",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02670",
    city: "West Dennis",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02671",
    city: "West Harwich",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02672",
    city: "West Hyannisport",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02673",
    city: "West Yarmouth",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02675",
    city: "Yarmouth Port",
    state: "MA",
    areacode: 508,
    county: "Barnstable",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02702",
    city: "Assonet",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02703",
    city: "Attleboro",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02712",
    city: "Chartley",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02713",
    city: "Cuttyhunk",
    state: "MA",
    areacode: 508,
    county: "Dukes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02714",
    city: "Dartmouth",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02715",
    city: "Dighton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02717",
    city: "East Freetown",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02718",
    city: "East Taunton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02719",
    city: "Fairhaven",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02720",
    city: "Fall River",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02721",
    city: "Fall River",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02722",
    city: "Fall River",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02723",
    city: "Fall River",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02724",
    city: "Fall River",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02725",
    city: "Somerset",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02726",
    city: "Somerset",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02738",
    city: "Marion",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02739",
    city: "Mattapoisett",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02740",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02741",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02742",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02743",
    city: "Acushnet",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02744",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02745",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02746",
    city: "New Bedford",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02747",
    city: "North Dartmouth",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02748",
    city: "South Dartmouth",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02760",
    city: "North Attleboro",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02761",
    city: "North Attleboro",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02762",
    city: "Plainville",
    state: "MA",
    areacode: 508,
    county: "Norfolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02763",
    city: "Attleboro Falls",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02764",
    city: "North Dighton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02766",
    city: "Norton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02767",
    city: "Raynham",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02768",
    city: "Raynham Center",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02769",
    city: "Rehoboth",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02770",
    city: "Rochester",
    state: "MA",
    areacode: 508,
    county: "Plymouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02771",
    city: "Seekonk",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02777",
    city: "Swansea",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02779",
    city: "Berkley",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02780",
    city: "Taunton",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02790",
    city: "Westport",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02791",
    city: "Westport Point",
    state: "MA",
    areacode: 508,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02801",
    city: "Adamsville",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02802",
    city: "Albion",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02804",
    city: "Ashaway",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02806",
    city: "Barrington",
    state: "RI",
    areacode: 401,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02807",
    city: "Block Island",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02808",
    city: "Bradford",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02809",
    city: "Bristol",
    state: "RI",
    areacode: 401,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02812",
    city: "Carolina",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02813",
    city: "Charlestown",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02814",
    city: "Chepachet",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02815",
    city: "Clayville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02816",
    city: "Coventry",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02817",
    city: "West Greenwich",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02818",
    city: "East Greenwich",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02822",
    city: "Exeter",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02823",
    city: "Fiskeville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02824",
    city: "Forestdale",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02825",
    city: "Foster",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02826",
    city: "Glendale",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02827",
    city: "Greene",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02828",
    city: "Greenville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02829",
    city: "Harmony",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02830",
    city: "Harrisville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02831",
    city: "Hope",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02832",
    city: "Hope Valley",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02833",
    city: "Hopkinton",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02835",
    city: "Jamestown",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02836",
    city: "Kenyon",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02837",
    city: "Little Compton",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02838",
    city: "Manville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02839",
    city: "Mapleville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02840",
    city: "Newport",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02841",
    city: "Newport",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02842",
    city: "Middletown",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02852",
    city: "North Kingstown",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02857",
    city: "North Scituate",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02858",
    city: "Oakland",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02859",
    city: "Pascoag",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02860",
    city: "Pawtucket",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02861",
    city: "Pawtucket",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02862",
    city: "Pawtucket",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02863",
    city: "Central Falls",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02864",
    city: "Cumberland",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02865",
    city: "Lincoln",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02871",
    city: "Portsmouth",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02872",
    city: "Prudence Island",
    state: "RI",
    areacode: 401,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02873",
    city: "Rockville",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02874",
    city: "Saunderstown",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02875",
    city: "Shannock",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02876",
    city: "Slatersville",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02877",
    city: "Slocum",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02878",
    city: "Tiverton",
    state: "RI",
    areacode: 401,
    county: "Newport",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02879",
    city: "Wakefield",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02880",
    city: "Wakefield",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02881",
    city: "Kingston",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02882",
    city: "Narragansett",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02883",
    city: "Peace Dale",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02885",
    city: "Warren",
    state: "RI",
    areacode: 401,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02886",
    city: "Warwick",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02887",
    city: "Warwick",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02888",
    city: "Warwick",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02889",
    city: "Warwick",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02891",
    city: "Westerly",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02892",
    city: "West Kingston",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02893",
    city: "West Warwick",
    state: "RI",
    areacode: 401,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02894",
    city: "Wood River Junction",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02895",
    city: "Woonsocket",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02896",
    city: "North Smithfield",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02898",
    city: "Wyoming",
    state: "RI",
    areacode: 401,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02901",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02902",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02903",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02904",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02905",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02906",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02907",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02908",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02909",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02910",
    city: "Cranston",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02911",
    city: "North Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02912",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02914",
    city: "East Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02915",
    city: "Riverside",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02916",
    city: "Rumford",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02917",
    city: "Smithfield",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02918",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02919",
    city: "Johnston",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02920",
    city: "Cranston",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02921",
    city: "Cranston",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "02940",
    city: "Providence",
    state: "RI",
    areacode: 401,
    county: "Providence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03031",
    city: "Amherst",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03032",
    city: "Auburn",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03033",
    city: "Brookline",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03034",
    city: "Candia",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03036",
    city: "Chester",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03037",
    city: "Deerfield",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03038",
    city: "Derry",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03040",
    city: "East Candia",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03041",
    city: "East Derry",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03042",
    city: "Epping",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03043",
    city: "Francestown",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03044",
    city: "Fremont",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03045",
    city: "Goffstown",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03046",
    city: "Dunbarton",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03047",
    city: "Greenfield",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03048",
    city: "Greenville",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03049",
    city: "Hollis",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03051",
    city: "Hudson",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03052",
    city: "Litchfield",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03053",
    city: "Londonderry",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03054",
    city: "Merrimack",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03055",
    city: "Milford",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03057",
    city: "Mont Vernon",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03060",
    city: "Nashua",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03061",
    city: "Nashua",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03062",
    city: "Nashua",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03063",
    city: "Nashua",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03064",
    city: "Nashua",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03070",
    city: "New Boston",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03071",
    city: "New Ipswich",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03073",
    city: "North Salem",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03076",
    city: "Pelham",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03077",
    city: "Raymond",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03079",
    city: "Salem",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03082",
    city: "Lyndeborough",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03084",
    city: "Temple",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03086",
    city: "Wilton",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03087",
    city: "Windham",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03101",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03102",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03103",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03104",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03105",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03106",
    city: "Hooksett",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03108",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03109",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03110",
    city: "Bedford",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03111",
    city: "Manchester",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03215",
    city: "Waterville Valley",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03216",
    city: "Andover",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03217",
    city: "Ashland",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03218",
    city: "Barnstead",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03220",
    city: "Belmont",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03221",
    city: "Bradford",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03222",
    city: "Bristol",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03223",
    city: "Campton",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03224",
    city: "Canterbury",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03225",
    city: "Center Barnstead",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03226",
    city: "Center Harbor",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03227",
    city: "Center Sandwich",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03229",
    city: "Contoocook",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03230",
    city: "Danbury",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03231",
    city: "East Andover",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03233",
    city: "Elkins",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03234",
    city: "Epsom",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03235",
    city: "Franklin",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03237",
    city: "Gilmanton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03238",
    city: "Glencliff",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03240",
    city: "Grafton",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03241",
    city: "Hebron",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03242",
    city: "Henniker",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03243",
    city: "Hill",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03244",
    city: "Hillsborough",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03245",
    city: "Holderness",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03246",
    city: "Laconia",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03247",
    city: "Laconia",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03249",
    city: "Gilford",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03251",
    city: "Lincoln",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03252",
    city: "Lochmere",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03253",
    city: "Meredith",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03254",
    city: "Moultonborough",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03255",
    city: "Newbury",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03256",
    city: "New Hampton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03257",
    city: "New London",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03258",
    city: "Chichester",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03259",
    city: "North Sandwich",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03260",
    city: "North Sutton",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03261",
    city: "Northwood",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03262",
    city: "North Woodstock",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03263",
    city: "Pittsfield",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03264",
    city: "Plymouth",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03266",
    city: "Rumney",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03268",
    city: "Salisbury",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03269",
    city: "Sanbornton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03272",
    city: "South Newbury",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03273",
    city: "South Sutton",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03275",
    city: "Suncook",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03276",
    city: "Tilton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03278",
    city: "Warner",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03279",
    city: "Warren",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03280",
    city: "Washington",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03281",
    city: "Weare",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03282",
    city: "Wentworth",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03284",
    city: "Springfield",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03285",
    city: "Thornton",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03287",
    city: "Wilmot",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03289",
    city: "Winnisquam",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03290",
    city: "Nottingham",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03291",
    city: "West Nottingham",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03293",
    city: "Woodstock",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03298",
    city: "Tilton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03299",
    city: "Tilton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03301",
    city: "Concord",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03302",
    city: "Concord",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03303",
    city: "Concord",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03304",
    city: "Bow",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03305",
    city: "Concord",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03307",
    city: "Loudon",
    state: "NH",
    areacode: 603,
    county: "Merrimack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03431",
    city: "Keene",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03435",
    city: "Keene",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03440",
    city: "Antrim",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03441",
    city: "Ashuelot",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03442",
    city: "Bennington",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03443",
    city: "Chesterfield",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03444",
    city: "Dublin",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03445",
    city: "Sullivan",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03446",
    city: "Swanzey",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03447",
    city: "Fitzwilliam",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03448",
    city: "Gilsum",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03449",
    city: "Hancock",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03450",
    city: "Harrisville",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03451",
    city: "Hinsdale",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03452",
    city: "Jaffrey",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03455",
    city: "Marlborough",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03456",
    city: "Marlow",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03457",
    city: "Nelson",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03458",
    city: "Peterborough",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03461",
    city: "Rindge",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03462",
    city: "Spofford",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03464",
    city: "Stoddard",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03465",
    city: "Troy",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03466",
    city: "West Chesterfield",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03467",
    city: "Westmoreland",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03468",
    city: "West Peterborough",
    state: "NH",
    areacode: 603,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03469",
    city: "West Swanzey",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03470",
    city: "Winchester",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03561",
    city: "Littleton",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03570",
    city: "Berlin",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03574",
    city: "Bethlehem",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03575",
    city: "Bretton Woods",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03576",
    city: "Colebrook",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03579",
    city: "Errol",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03580",
    city: "Franconia",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03581",
    city: "Gorham",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03582",
    city: "Groveton",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03583",
    city: "Jefferson",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03584",
    city: "Lancaster",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03585",
    city: "Lisbon",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03586",
    city: "Sugar Hill",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03588",
    city: "Milan",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03589",
    city: "Mount Washington",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03590",
    city: "North Stratford",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03592",
    city: "Pittsburg",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03593",
    city: "Randolph",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03595",
    city: "Twin Mountain",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03597",
    city: "West Stewartstown",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03598",
    city: "Whitefield",
    state: "NH",
    areacode: 603,
    county: "Coos",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03601",
    city: "Acworth",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03602",
    city: "Alstead",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03603",
    city: "Charlestown",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03604",
    city: "Drewsville",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03605",
    city: "Lempster",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03607",
    city: "South Acworth",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03608",
    city: "Walpole",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03609",
    city: "North Walpole",
    state: "NH",
    areacode: 603,
    county: "Cheshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03740",
    city: "Bath",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03741",
    city: "Canaan",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03743",
    city: "Claremont",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03745",
    city: "Cornish",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03746",
    city: "Cornish Flat",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03748",
    city: "Enfield",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03749",
    city: "Enfield Center",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03750",
    city: "Etna",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03751",
    city: "Georges Mills",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03752",
    city: "Goshen",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03753",
    city: "Grantham",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03754",
    city: "Guild",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03755",
    city: "Hanover",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03756",
    city: "Lebanon",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03765",
    city: "Haverhill",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03766",
    city: "Lebanon",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03768",
    city: "Lyme",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03769",
    city: "Lyme Center",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03770",
    city: "Meriden",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03771",
    city: "Monroe",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03773",
    city: "Newport",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03774",
    city: "North Haverhill",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03777",
    city: "Orford",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03779",
    city: "Piermont",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03780",
    city: "Pike",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03781",
    city: "Plainfield",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03782",
    city: "Sunapee",
    state: "NH",
    areacode: 603,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03784",
    city: "West Lebanon",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03785",
    city: "Woodsville",
    state: "NH",
    areacode: 603,
    county: "Grafton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03801",
    city: "Portsmouth",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03802",
    city: "Portsmouth",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03803",
    city: "Portsmouth",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03804",
    city: "Portsmouth",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03809",
    city: "Alton",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03810",
    city: "Alton Bay",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03811",
    city: "Atkinson",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03812",
    city: "Bartlett",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03813",
    city: "Center Conway",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03814",
    city: "Center Ossipee",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03815",
    city: "Center Strafford",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03816",
    city: "Center Tuftonboro",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03817",
    city: "Chocorua",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03818",
    city: "Conway",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03819",
    city: "Danville",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03820",
    city: "Dover",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03821",
    city: "Dover",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03822",
    city: "Dover",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03823",
    city: "Madbury",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03824",
    city: "Durham",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03825",
    city: "Barrington",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03826",
    city: "East Hampstead",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03827",
    city: "East Kingston",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03830",
    city: "East Wakefield",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03832",
    city: "Eaton Center",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03833",
    city: "Exeter",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03835",
    city: "Farmington",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03836",
    city: "Freedom",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03837",
    city: "Gilmanton Iron Works",
    state: "NH",
    areacode: 603,
    county: "Belknap",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03838",
    city: "Glen",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03839",
    city: "Rochester",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03840",
    city: "Greenland",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03841",
    city: "Hampstead",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03842",
    city: "Hampton",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03843",
    city: "Hampton",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03844",
    city: "Hampton Falls",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03845",
    city: "Intervale",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03846",
    city: "Jackson",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03847",
    city: "Kearsarge",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03848",
    city: "Kingston",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03849",
    city: "Madison",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03850",
    city: "Melvin Village",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03851",
    city: "Milton",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03852",
    city: "Milton Mills",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03853",
    city: "Mirror Lake",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03854",
    city: "New Castle",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03855",
    city: "New Durham",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03856",
    city: "Newfields",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03857",
    city: "Newmarket",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03858",
    city: "Newton",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03859",
    city: "Newton Junction",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03860",
    city: "North Conway",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03861",
    city: "Lee",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03862",
    city: "North Hampton",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03864",
    city: "Ossipee",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03865",
    city: "Plaistow",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03866",
    city: "Rochester",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03867",
    city: "Rochester",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03868",
    city: "Rochester",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03869",
    city: "Rollinsford",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03870",
    city: "Rye",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03871",
    city: "Rye Beach",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03872",
    city: "Sanbornville",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03873",
    city: "Sandown",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03874",
    city: "Seabrook",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03875",
    city: "Silver Lake",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03878",
    city: "Somersworth",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03882",
    city: "Effingham",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03883",
    city: "South Tamworth",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03884",
    city: "Strafford",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03885",
    city: "Stratham",
    state: "NH",
    areacode: 603,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03886",
    city: "Tamworth",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03887",
    city: "Union",
    state: "NH",
    areacode: 603,
    county: "Strafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03890",
    city: "West Ossipee",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03894",
    city: "Wolfeboro",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03896",
    city: "Wolfeboro Falls",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03897",
    city: "Wonalancet",
    state: "NH",
    areacode: 603,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03901",
    city: "Berwick",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03902",
    city: "Cape Neddick",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03903",
    city: "Eliot",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03904",
    city: "Kittery",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03905",
    city: "Kittery Point",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03906",
    city: "North Berwick",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03907",
    city: "Ogunquit",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03908",
    city: "South Berwick",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03909",
    city: "York",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03910",
    city: "York Beach",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "03911",
    city: "York Harbor",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04001",
    city: "Acton",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04002",
    city: "Alfred",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04003",
    city: "Bailey Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04004",
    city: "Bar Mills",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04005",
    city: "Biddeford",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04006",
    city: "Biddeford Pool",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04007",
    city: "Biddeford",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04008",
    city: "Bowdoinham",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04009",
    city: "Bridgton",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04010",
    city: "Brownfield",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04011",
    city: "Brunswick",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04013",
    city: "Bustins Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04014",
    city: "Cape Porpoise",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04015",
    city: "Casco",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04016",
    city: "Center Lovell",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04017",
    city: "Chebeague Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04019",
    city: "Cliff Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04020",
    city: "Cornish",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04021",
    city: "Cumberland Center",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04022",
    city: "Denmark",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04024",
    city: "East Baldwin",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04027",
    city: "Lebanon",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04028",
    city: "East Parsonsfield",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04029",
    city: "Sebago",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04030",
    city: "East Waterboro",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04032",
    city: "Freeport",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04033",
    city: "Freeport",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04034",
    city: "Freeport",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04037",
    city: "Fryeburg",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04038",
    city: "Gorham",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04039",
    city: "Gray",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04040",
    city: "Harrison",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04041",
    city: "Hiram",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04042",
    city: "Hollis Center",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04043",
    city: "Kennebunk",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04046",
    city: "Kennebunkport",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04047",
    city: "Parsonsfield",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04048",
    city: "Limerick",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04049",
    city: "Limington",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04050",
    city: "Long Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04051",
    city: "Lovell",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04054",
    city: "Moody",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04055",
    city: "Naples",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04056",
    city: "Newfield",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04057",
    city: "North Bridgton",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04061",
    city: "North Waterboro",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04062",
    city: "Windham",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04063",
    city: "Ocean Park",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04064",
    city: "Old Orchard Beach",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04066",
    city: "Orrs Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04068",
    city: "Porter",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04069",
    city: "Pownal",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04070",
    city: "Scarborough",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04071",
    city: "Raymond",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04072",
    city: "Saco",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04073",
    city: "Sanford",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04074",
    city: "Scarborough",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04076",
    city: "Shapleigh",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04077",
    city: "South Casco",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04078",
    city: "South Freeport",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04079",
    city: "Harpswell",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04082",
    city: "South Windham",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04083",
    city: "Springvale",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04084",
    city: "Standish",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04085",
    city: "Steep Falls",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04086",
    city: "Topsham",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04087",
    city: "Waterboro",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04088",
    city: "Waterford",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04090",
    city: "Wells",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04091",
    city: "West Baldwin",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04092",
    city: "Westbrook",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04093",
    city: "Buxton",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04094",
    city: "West Kennebunk",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04095",
    city: "West Newfield",
    state: "ME",
    areacode: 207,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04096",
    city: "Yarmouth",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04097",
    city: "North Yarmouth",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04098",
    city: "Westbrook",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04101",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04102",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04103",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04104",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04105",
    city: "Falmouth",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04106",
    city: "South Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04107",
    city: "Cape Elizabeth",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04108",
    city: "Peaks Island",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04109",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04110",
    city: "Cumberland Foreside",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04112",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04116",
    city: "South Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04122",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04123",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04124",
    city: "Portland",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04210",
    city: "Auburn",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04211",
    city: "Auburn",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04212",
    city: "Auburn",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04216",
    city: "Andover",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04217",
    city: "Bethel",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04219",
    city: "Bryant Pond",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04220",
    city: "Buckfield",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04221",
    city: "Canton",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04222",
    city: "Durham",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04223",
    city: "Danville",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04224",
    city: "Dixfield",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04225",
    city: "Dryden",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04226",
    city: "East Andover",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04227",
    city: "East Dixfield",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04228",
    city: "East Livermore",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04230",
    city: "East Poland",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04231",
    city: "Stoneham",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04234",
    city: "East Wilton",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04236",
    city: "Greene",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04237",
    city: "Hanover",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04238",
    city: "Hebron",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04239",
    city: "Jay",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04240",
    city: "Lewiston",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04241",
    city: "Lewiston",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04243",
    city: "Lewiston",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04250",
    city: "Lisbon",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04252",
    city: "Lisbon Falls",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04253",
    city: "Livermore",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04254",
    city: "Livermore Falls",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04255",
    city: "Greenwood",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04256",
    city: "Mechanic Falls",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04257",
    city: "Mexico",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04258",
    city: "Minot",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04259",
    city: "Monmouth",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04260",
    city: "New Gloucester",
    state: "ME",
    areacode: 207,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04261",
    city: "Newry",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04262",
    city: "North Jay",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04263",
    city: "Leeds",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04265",
    city: "North Monmouth",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04266",
    city: "North Turner",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04267",
    city: "North Waterford",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04268",
    city: "Norway",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04270",
    city: "Oxford",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04271",
    city: "Paris",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04274",
    city: "Poland",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04275",
    city: "Roxbury",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04276",
    city: "Rumford",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04280",
    city: "Sabattus",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04281",
    city: "South Paris",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04282",
    city: "Turner",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04284",
    city: "Wayne",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04285",
    city: "Weld",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04286",
    city: "West Bethel",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04287",
    city: "Bowdoin",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04288",
    city: "West Minot",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04289",
    city: "West Paris",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04290",
    city: "Peru",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04291",
    city: "West Poland",
    state: "ME",
    areacode: 207,
    county: "Androscoggin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04292",
    city: "Sumner",
    state: "ME",
    areacode: 207,
    county: "Oxford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04294",
    city: "Wilton",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04330",
    city: "Augusta",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04332",
    city: "Augusta",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04333",
    city: "Augusta",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04336",
    city: "Augusta",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04338",
    city: "Augusta",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04341",
    city: "Coopers Mills",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04342",
    city: "Dresden",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04343",
    city: "East Winthrop",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04344",
    city: "Farmingdale",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04345",
    city: "Gardiner",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04346",
    city: "Randolph",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04347",
    city: "Hallowell",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04348",
    city: "Jefferson",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04349",
    city: "Kents Hill",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04350",
    city: "Litchfield",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04351",
    city: "Manchester",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04352",
    city: "Mount Vernon",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04353",
    city: "Whitefield",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04354",
    city: "Palermo",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04355",
    city: "Readfield",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04357",
    city: "Richmond",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04358",
    city: "South China",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04359",
    city: "South Gardiner",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04360",
    city: "Vienna",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04363",
    city: "Windsor",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04364",
    city: "Winthrop",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04401",
    city: "Bangor",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04402",
    city: "Bangor",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04406",
    city: "Abbot",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04408",
    city: "Aurora",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04410",
    city: "Bradford",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04411",
    city: "Bradley",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04412",
    city: "Brewer",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04413",
    city: "Brookton",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04414",
    city: "Brownville",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04415",
    city: "Brownville Junction",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04416",
    city: "Bucksport",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04417",
    city: "Burlington",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04418",
    city: "Greenbush",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04419",
    city: "Carmel",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04420",
    city: "Castine",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04421",
    city: "Castine",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04422",
    city: "Charleston",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04424",
    city: "Danforth",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04426",
    city: "Dover Foxcroft",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04427",
    city: "Corinth",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04428",
    city: "Eddington",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04429",
    city: "Holden",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04430",
    city: "East Millinocket",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04431",
    city: "East Orland",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04434",
    city: "Etna",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04435",
    city: "Exeter",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04438",
    city: "Frankfort",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04441",
    city: "Greenville",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04442",
    city: "Greenville Junction",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04443",
    city: "Guilford",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04444",
    city: "Hampden",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04448",
    city: "Howland",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04449",
    city: "Hudson",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04450",
    city: "Kenduskeag",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04451",
    city: "Kingman",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04453",
    city: "Lagrange",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04454",
    city: "Lambert Lake",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04455",
    city: "Lee",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04456",
    city: "Levant",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04457",
    city: "Lincoln",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04459",
    city: "Mattawamkeag",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04460",
    city: "Medway",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04461",
    city: "Milford",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04462",
    city: "Millinocket",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04463",
    city: "Milo",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04464",
    city: "Monson",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04468",
    city: "Old Town",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04469",
    city: "Orono",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04471",
    city: "Orient",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04472",
    city: "Orland",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04473",
    city: "Orono",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04474",
    city: "Orrington",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04475",
    city: "Passadumkeag",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04476",
    city: "Penobscot",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04478",
    city: "Rockwood",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04479",
    city: "Sangerville",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04481",
    city: "Sebec",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04485",
    city: "Shirley Mills",
    state: "ME",
    areacode: 207,
    county: "Piscataquis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04487",
    city: "Springfield",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04488",
    city: "Stetson",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04489",
    city: "Stillwater",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04490",
    city: "Topsfield",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04491",
    city: "Vanceboro",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04492",
    city: "Waite",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04493",
    city: "West Enfield",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04495",
    city: "Winn",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04496",
    city: "Winterport",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04497",
    city: "Wytopitlock",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04530",
    city: "Bath",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04535",
    city: "Alna",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04537",
    city: "Boothbay",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04538",
    city: "Boothbay Harbor",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04539",
    city: "Bristol",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04541",
    city: "Chamberlain",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04543",
    city: "Damariscotta",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04544",
    city: "East Boothbay",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04547",
    city: "Friendship",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04548",
    city: "Georgetown",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04549",
    city: "Isle of Springs",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04551",
    city: "Bremen",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04553",
    city: "Newcastle",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04554",
    city: "New Harbor",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04555",
    city: "Nobleboro",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04556",
    city: "Edgecomb",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04558",
    city: "Pemaquid",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04562",
    city: "Phippsburg",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04563",
    city: "Cushing",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04564",
    city: "Round Pond",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04565",
    city: "Sebasco Estates",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04568",
    city: "South Bristol",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04570",
    city: "Squirrel Island",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04571",
    city: "Trevett",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04572",
    city: "Waldoboro",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04573",
    city: "Walpole",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04574",
    city: "Washington",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04575",
    city: "West Boothbay Harbor",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04576",
    city: "Southport",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04578",
    city: "Wiscasset",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04579",
    city: "Woolwich",
    state: "ME",
    areacode: 207,
    county: "Sagadahoc",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04605",
    city: "Ellsworth",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04606",
    city: "Addison",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04607",
    city: "Gouldsboro",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04609",
    city: "Bar Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04611",
    city: "Beals",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04612",
    city: "Bernard",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04613",
    city: "Birch Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04614",
    city: "Blue Hill",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04616",
    city: "Brooklin",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04617",
    city: "Brooksville",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04619",
    city: "Calais",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04622",
    city: "Cherryfield",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04623",
    city: "Columbia Falls",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04624",
    city: "Corea",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04625",
    city: "Cranberry Isles",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04626",
    city: "Cutler",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04627",
    city: "Deer Isle",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04628",
    city: "Dennysville",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04629",
    city: "East Blue Hill",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04630",
    city: "East Machias",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04631",
    city: "Eastport",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04634",
    city: "Franklin",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04635",
    city: "Frenchboro",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04637",
    city: "Grand Lake Stream",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04640",
    city: "Hancock",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04642",
    city: "Harborside",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04643",
    city: "Harrington",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04644",
    city: "Hulls Cove",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04645",
    city: "Isle au Haut",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04646",
    city: "Islesford",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04648",
    city: "Jonesboro",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04649",
    city: "Jonesport",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04650",
    city: "Little Deer Isle",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04652",
    city: "Lubec",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04653",
    city: "Bass Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04654",
    city: "Machias",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04655",
    city: "Machiasport",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04657",
    city: "Meddybemps",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04658",
    city: "Milbridge",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04660",
    city: "Mount Desert",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04662",
    city: "Northeast Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04664",
    city: "Sullivan",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04666",
    city: "Pembroke",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04667",
    city: "Perry",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04668",
    city: "Princeton",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04669",
    city: "Prospect Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04671",
    city: "Robbinston",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04672",
    city: "Salsbury Cove",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04673",
    city: "Sargentville",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04674",
    city: "Seal Cove",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04675",
    city: "Seal Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04676",
    city: "Sedgwick",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04677",
    city: "Sorrento",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04679",
    city: "Southwest Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04680",
    city: "Steuben",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04681",
    city: "Stonington",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04683",
    city: "Sunset",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04684",
    city: "Surry",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04685",
    city: "Swans Island",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04686",
    city: "Wesley",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04691",
    city: "Whiting",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04693",
    city: "Winter Harbor",
    state: "ME",
    areacode: 207,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04694",
    city: "Baileyville",
    state: "ME",
    areacode: 207,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04730",
    city: "Houlton",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04732",
    city: "Ashland",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04733",
    city: "Benedicta",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04734",
    city: "Blaine",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04735",
    city: "Bridgewater",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04736",
    city: "Caribou",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04737",
    city: "Clayton Lake",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04738",
    city: "Crouseville",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04739",
    city: "Eagle Lake",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04740",
    city: "Easton",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04741",
    city: "Estcourt Station",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04742",
    city: "Fort Fairfield",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04743",
    city: "Fort Kent",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04744",
    city: "Fort Kent Mills",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04745",
    city: "Frenchville",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04746",
    city: "Grand Isle",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04747",
    city: "Island Falls",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04750",
    city: "Limestone",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04751",
    city: "Limestone",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04756",
    city: "Madawaska",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04757",
    city: "Mapleton",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04758",
    city: "Mars Hill",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04760",
    city: "Monticello",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04761",
    city: "New Limerick",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04762",
    city: "New Sweden",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04763",
    city: "Oakfield",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04764",
    city: "Oxbow",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04765",
    city: "Patten",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04766",
    city: "Perham",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04768",
    city: "Portage",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04769",
    city: "Presque Isle",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04772",
    city: "Saint Agatha",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04773",
    city: "Saint David",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04774",
    city: "Saint Francis",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04775",
    city: "Sheridan",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04776",
    city: "Sherman",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04777",
    city: "Stacyville",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04779",
    city: "Sinclair",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04780",
    city: "Smyrna Mills",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04781",
    city: "Wallagrass",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04783",
    city: "Stockholm",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04785",
    city: "Van Buren",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04786",
    city: "Washburn",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04787",
    city: "Westfield",
    state: "ME",
    areacode: 207,
    county: "Aroostook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04841",
    city: "Rockland",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04843",
    city: "Camden",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04847",
    city: "Hope",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04848",
    city: "Islesboro",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04849",
    city: "Lincolnville",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04850",
    city: "Lincolnville Center",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04851",
    city: "Matinicus",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04852",
    city: "Monhegan",
    state: "ME",
    areacode: 207,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04853",
    city: "North Haven",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04854",
    city: "Owls Head",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04855",
    city: "Port Clyde",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04856",
    city: "Rockport",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04858",
    city: "South Thomaston",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04859",
    city: "Spruce Head",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04860",
    city: "Tenants Harbor",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04861",
    city: "Thomaston",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04862",
    city: "Union",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04863",
    city: "Vinalhaven",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04864",
    city: "Warren",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04865",
    city: "West Rockport",
    state: "ME",
    areacode: 207,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04901",
    city: "Waterville",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04903",
    city: "Waterville",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04910",
    city: "Albion",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04911",
    city: "Anson",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04912",
    city: "Athens",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04915",
    city: "Belfast",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04917",
    city: "Belgrade",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04918",
    city: "Belgrade Lakes",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04920",
    city: "Bingham",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04921",
    city: "Brooks",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04922",
    city: "Burnham",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04923",
    city: "Cambridge",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04924",
    city: "Canaan",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04925",
    city: "Caratunk",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04926",
    city: "China Village",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04927",
    city: "Clinton",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04928",
    city: "Corinna",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04929",
    city: "Detroit",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04930",
    city: "Dexter",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04932",
    city: "Dixmont",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04933",
    city: "East Newport",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04935",
    city: "East Vassalboro",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04936",
    city: "Eustis",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04937",
    city: "Fairfield",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04938",
    city: "Farmington",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04939",
    city: "Garland",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04940",
    city: "Farmington Falls",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04941",
    city: "Freedom",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04942",
    city: "Harmony",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04943",
    city: "Hartland",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04944",
    city: "Hinckley",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04945",
    city: "Jackman",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04947",
    city: "Kingfield",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04949",
    city: "Liberty",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04950",
    city: "Madison",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04951",
    city: "Monroe",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04952",
    city: "Morrill",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04953",
    city: "Newport",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04954",
    city: "New Portland",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04955",
    city: "New Sharon",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04956",
    city: "New Vineyard",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04957",
    city: "Norridgewock",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04958",
    city: "North Anson",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04961",
    city: "New Portland",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04962",
    city: "North Vassalboro",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04963",
    city: "Oakland",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04964",
    city: "Oquossoc",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04965",
    city: "Palmyra",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04966",
    city: "Phillips",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04967",
    city: "Pittsfield",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04969",
    city: "Plymouth",
    state: "ME",
    areacode: 207,
    county: "Penobscot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04970",
    city: "Rangeley",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04971",
    city: "Saint Albans",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04972",
    city: "Sandy Point",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04973",
    city: "Searsmont",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04974",
    city: "Searsport",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04975",
    city: "Shawmut",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04976",
    city: "Skowhegan",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04978",
    city: "Smithfield",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04979",
    city: "Solon",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04981",
    city: "Stockton Springs",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04982",
    city: "Stratton",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04983",
    city: "Strong",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04984",
    city: "Temple",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04985",
    city: "West Forks",
    state: "ME",
    areacode: 207,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04986",
    city: "Thorndike",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04987",
    city: "Troy",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04988",
    city: "Unity",
    state: "ME",
    areacode: 207,
    county: "Waldo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04989",
    city: "Vassalboro",
    state: "ME",
    areacode: 207,
    county: "Kennebec",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "04992",
    city: "West Farmington",
    state: "ME",
    areacode: 207,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05001",
    city: "White River Junction",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05009",
    city: "White River Junction",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05030",
    city: "Ascutney",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05031",
    city: "Barnard",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05032",
    city: "Bethel",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05033",
    city: "Bradford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05034",
    city: "Bridgewater",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05035",
    city: "Bridgewater Corners",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05036",
    city: "Brookfield",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05037",
    city: "Brownsville",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05038",
    city: "Chelsea",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05039",
    city: "Corinth",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05040",
    city: "East Corinth",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05041",
    city: "East Randolph",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05042",
    city: "East Ryegate",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05043",
    city: "East Thetford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05045",
    city: "Fairlee",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05046",
    city: "Groton",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05047",
    city: "Hartford",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05048",
    city: "Hartland",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05049",
    city: "Hartland Four Corners",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05050",
    city: "McIndoe Falls",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05051",
    city: "Newbury",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05052",
    city: "North Hartland",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05053",
    city: "North Pomfret",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05054",
    city: "North Thetford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05055",
    city: "Norwich",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05056",
    city: "Plymouth",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05058",
    city: "Post Mills",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05059",
    city: "Quechee",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05060",
    city: "Randolph",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05061",
    city: "Randolph Center",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05062",
    city: "Reading",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05065",
    city: "Sharon",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05067",
    city: "South Pomfret",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05068",
    city: "South Royalton",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05069",
    city: "South Ryegate",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05070",
    city: "South Strafford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05071",
    city: "South Woodstock",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05072",
    city: "Strafford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05073",
    city: "Taftsville",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05074",
    city: "Thetford",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05075",
    city: "Thetford Center",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05076",
    city: "Topsham",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05077",
    city: "Tunbridge",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05079",
    city: "Vershire",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05081",
    city: "Wells River",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05083",
    city: "West Fairlee",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05084",
    city: "West Hartford",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05085",
    city: "West Newbury",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05086",
    city: "West Topsham",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05088",
    city: "Wilder",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05089",
    city: "Windsor",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05091",
    city: "Woodstock",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05101",
    city: "Bellows Falls",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05141",
    city: "Cambridgeport",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05142",
    city: "Cavendish",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05143",
    city: "Chester",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05146",
    city: "Grafton",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05148",
    city: "Londonderry",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05149",
    city: "Ludlow",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05150",
    city: "North Springfield",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05151",
    city: "Perkinsville",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05152",
    city: "Peru",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05153",
    city: "Proctorsville",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05154",
    city: "Saxtons River",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05155",
    city: "South Londonderry",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05156",
    city: "Springfield",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05158",
    city: "Westminster",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05159",
    city: "Westminster Station",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05161",
    city: "Weston",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05201",
    city: "Bennington",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05250",
    city: "Arlington",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05251",
    city: "Dorset",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05252",
    city: "East Arlington",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05253",
    city: "East Dorset",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05254",
    city: "Manchester",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05255",
    city: "Manchester Center",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05257",
    city: "North Bennington",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05260",
    city: "North Pownal",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05261",
    city: "Pownal",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05262",
    city: "Shaftsbury",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05301",
    city: "Brattleboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05302",
    city: "Brattleboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05303",
    city: "Brattleboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05304",
    city: "Brattleboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05340",
    city: "Bondville",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05341",
    city: "East Dover",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05342",
    city: "Jacksonville",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05343",
    city: "Jamaica",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05344",
    city: "Marlboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05345",
    city: "Newfane",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05346",
    city: "Putney",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05350",
    city: "Readsboro",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05351",
    city: "South Newfane",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05352",
    city: "Stamford",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05353",
    city: "Townshend",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05354",
    city: "Vernon",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05355",
    city: "Wardsboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05356",
    city: "West Dover",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05357",
    city: "West Dummerston",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05358",
    city: "West Halifax",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05359",
    city: "West Townshend",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05360",
    city: "West Wardsboro",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05361",
    city: "Whitingham",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05362",
    city: "Williamsville",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05363",
    city: "Wilmington",
    state: "VT",
    areacode: 802,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05401",
    city: "Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05402",
    city: "Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05403",
    city: "South Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05404",
    city: "Winooski",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05405",
    city: "Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05406",
    city: "Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05407",
    city: "South Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05408",
    city: "Burlington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05439",
    city: "Colchester",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05440",
    city: "Alburgh",
    state: "VT",
    areacode: 802,
    county: "Grand Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05441",
    city: "Bakersfield",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05442",
    city: "Belvidere Center",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05443",
    city: "Bristol",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05444",
    city: "Cambridge",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05445",
    city: "Charlotte",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05446",
    city: "Colchester",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05447",
    city: "East Berkshire",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05448",
    city: "East Fairfield",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05449",
    city: "Colchester",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05450",
    city: "Enosburg Falls",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05451",
    city: "Essex",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05452",
    city: "Essex Junction",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05453",
    city: "Essex Junction",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05454",
    city: "Fairfax",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05455",
    city: "Fairfield",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05456",
    city: "Ferrisburgh",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05457",
    city: "Franklin",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05458",
    city: "Grand Isle",
    state: "VT",
    areacode: 802,
    county: "Grand Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05459",
    city: "Highgate Center",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05460",
    city: "Highgate Springs",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05461",
    city: "Hinesburg",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05462",
    city: "Huntington",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05463",
    city: "Isle La Motte",
    state: "VT",
    areacode: 802,
    county: "Grand Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05464",
    city: "Jeffersonville",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05465",
    city: "Jericho",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05466",
    city: "Jonesville",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05468",
    city: "Milton",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05469",
    city: "Monkton",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05470",
    city: "Montgomery",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05471",
    city: "Montgomery Center",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05472",
    city: "New Haven",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05473",
    city: "North Ferrisburgh",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05474",
    city: "North Hero",
    state: "VT",
    areacode: 802,
    county: "Grand Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05476",
    city: "Richford",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05477",
    city: "Richmond",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05478",
    city: "Saint Albans",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05479",
    city: "Essex Junction",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05481",
    city: "Saint Albans Bay",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05482",
    city: "Shelburne",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05483",
    city: "Sheldon",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05485",
    city: "Sheldon Springs",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05486",
    city: "South Hero",
    state: "VT",
    areacode: 802,
    county: "Grand Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05487",
    city: "Starksboro",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05488",
    city: "Swanton",
    state: "VT",
    areacode: 802,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05489",
    city: "Underhill",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05490",
    city: "Underhill Center",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05491",
    city: "Vergennes",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05492",
    city: "Waterville",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05494",
    city: "Westford",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05495",
    city: "Williston",
    state: "VT",
    areacode: 802,
    county: "Chittenden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05501",
    city: "Andover",
    state: "MA",
    areacode: 978,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05601",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05602",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05603",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05604",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05609",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05620",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05633",
    city: "Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05640",
    city: "Adamant",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05641",
    city: "Barre",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05647",
    city: "Cabot",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05648",
    city: "Calais",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05649",
    city: "East Barre",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05650",
    city: "East Calais",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05651",
    city: "East Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05652",
    city: "Eden",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05653",
    city: "Eden Mills",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05654",
    city: "Graniteville",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05655",
    city: "Hyde Park",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05656",
    city: "Johnson",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05657",
    city: "Lake Elmore",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05658",
    city: "Marshfield",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05660",
    city: "Moretown",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05661",
    city: "Morrisville",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05662",
    city: "Moscow",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05663",
    city: "Northfield",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05664",
    city: "Northfield Falls",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05665",
    city: "North Hyde Park",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05666",
    city: "North Montpelier",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05667",
    city: "Plainfield",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05669",
    city: "Roxbury",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05670",
    city: "South Barre",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05671",
    city: "Waterbury",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05672",
    city: "Stowe",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05673",
    city: "Waitsfield",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05674",
    city: "Warren",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05675",
    city: "Washington",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05676",
    city: "Waterbury",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05677",
    city: "Waterbury Center",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05678",
    city: "Websterville",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05679",
    city: "Williamstown",
    state: "VT",
    areacode: 802,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05680",
    city: "Wolcott",
    state: "VT",
    areacode: 802,
    county: "Lamoille",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05681",
    city: "Woodbury",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05682",
    city: "Worcester",
    state: "VT",
    areacode: 802,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05701",
    city: "Rutland",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05702",
    city: "Rutland",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05730",
    city: "Belmont",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05731",
    city: "Benson",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05732",
    city: "Bomoseen",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05733",
    city: "Brandon",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05734",
    city: "Bridport",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05735",
    city: "Castleton",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05736",
    city: "Center Rutland",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05737",
    city: "Chittenden",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05738",
    city: "Cuttingsville",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05739",
    city: "Danby",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05740",
    city: "East Middlebury",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05741",
    city: "East Poultney",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05742",
    city: "East Wallingford",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05743",
    city: "Fair Haven",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05744",
    city: "Florence",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05745",
    city: "Forest Dale",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05746",
    city: "Gaysville",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05747",
    city: "Granville",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05748",
    city: "Hancock",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05750",
    city: "Hydeville",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05751",
    city: "Killington",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05753",
    city: "Middlebury",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05757",
    city: "Middletown Springs",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05758",
    city: "Mount Holly",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05759",
    city: "North Clarendon",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05760",
    city: "Orwell",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05761",
    city: "Pawlet",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05762",
    city: "Pittsfield",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05763",
    city: "Pittsford",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05764",
    city: "Poultney",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05765",
    city: "Proctor",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05766",
    city: "Ripton",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05767",
    city: "Rochester",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05768",
    city: "Rupert",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05769",
    city: "Salisbury",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05770",
    city: "Shoreham",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05772",
    city: "Stockbridge",
    state: "VT",
    areacode: 802,
    county: "Windsor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05773",
    city: "Wallingford",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05774",
    city: "Wells",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05775",
    city: "West Pawlet",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05776",
    city: "West Rupert",
    state: "VT",
    areacode: 802,
    county: "Bennington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05777",
    city: "West Rutland",
    state: "VT",
    areacode: 802,
    county: "Rutland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05778",
    city: "Whiting",
    state: "VT",
    areacode: 802,
    county: "Addison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05819",
    city: "Saint Johnsbury",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05820",
    city: "Albany",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05821",
    city: "Barnet",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05822",
    city: "Barton",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05823",
    city: "Beebe Plain",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05824",
    city: "Concord",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05825",
    city: "Coventry",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05826",
    city: "Craftsbury",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05827",
    city: "Craftsbury Common",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05828",
    city: "Danville",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05829",
    city: "Derby",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05830",
    city: "Derby Line",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05832",
    city: "East Burke",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05833",
    city: "East Charleston",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05836",
    city: "East Hardwick",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05837",
    city: "East Haven",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05838",
    city: "East Saint Johnsbury",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05839",
    city: "Glover",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05840",
    city: "Granby",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05841",
    city: "Greensboro",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05842",
    city: "Greensboro Bend",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05843",
    city: "Hardwick",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05845",
    city: "Irasburg",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05846",
    city: "Island Pond",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05847",
    city: "Lowell",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05848",
    city: "Lower Waterford",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05849",
    city: "Lyndon",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05850",
    city: "Lyndon Center",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05851",
    city: "Lyndonville",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05853",
    city: "Morgan",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05855",
    city: "Newport",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05857",
    city: "Newport Center",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05858",
    city: "North Concord",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05859",
    city: "North Troy",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05860",
    city: "Orleans",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05861",
    city: "Passumpsic",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05862",
    city: "Peacham",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05863",
    city: "Saint Johnsbury Center",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05866",
    city: "Sheffield",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05867",
    city: "Sutton",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05868",
    city: "Troy",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05871",
    city: "West Burke",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05872",
    city: "West Charleston",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05873",
    city: "West Danville",
    state: "VT",
    areacode: 802,
    county: "Caledonia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05874",
    city: "Westfield",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05875",
    city: "Barton",
    state: "VT",
    areacode: 802,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05901",
    city: "Averill",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05902",
    city: "Beecher Falls",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05903",
    city: "Canaan",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05904",
    city: "Gilman",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05905",
    city: "Guildhall",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05906",
    city: "Lunenburg",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "05907",
    city: "Norton",
    state: "VT",
    areacode: 802,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06001",
    city: "Avon",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06002",
    city: "Bloomfield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06006",
    city: "Windsor",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06010",
    city: "Bristol",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06011",
    city: "Bristol",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06013",
    city: "Burlington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06016",
    city: "Broad Brook",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06018",
    city: "Canaan",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06019",
    city: "Canton",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06020",
    city: "Canton Center",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06021",
    city: "Colebrook",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06022",
    city: "Collinsville",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06023",
    city: "East Berlin",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06024",
    city: "East Canaan",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06025",
    city: "East Glastonbury",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06026",
    city: "East Granby",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06027",
    city: "East Hartland",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06028",
    city: "East Windsor Hill",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06029",
    city: "Ellington",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06030",
    city: "Farmington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06031",
    city: "Falls Village",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06032",
    city: "Farmington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06033",
    city: "Glastonbury",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06034",
    city: "Farmington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06035",
    city: "Granby",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06037",
    city: "Berlin",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06039",
    city: "Lakeville",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06040",
    city: "Manchester",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06041",
    city: "Manchester",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06042",
    city: "Manchester",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06043",
    city: "Bolton",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06045",
    city: "Manchester",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06050",
    city: "New Britain",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06051",
    city: "New Britain",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06052",
    city: "New Britain",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06053",
    city: "New Britain",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06057",
    city: "New Hartford",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06058",
    city: "Norfolk",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06059",
    city: "North Canton",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06060",
    city: "North Granby",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06061",
    city: "Pine Meadow",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06062",
    city: "Plainville",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06063",
    city: "Barkhamsted",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06064",
    city: "Poquonock",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06065",
    city: "Riverton",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06066",
    city: "Vernon Rockville",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06067",
    city: "Rocky Hill",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06068",
    city: "Salisbury",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06069",
    city: "Sharon",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06070",
    city: "Simsbury",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06071",
    city: "Somers",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06072",
    city: "Somersville",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06073",
    city: "South Glastonbury",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06074",
    city: "South Windsor",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06075",
    city: "Stafford",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06076",
    city: "Stafford Springs",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06077",
    city: "Staffordville",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06078",
    city: "Suffield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06079",
    city: "Taconic",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06080",
    city: "Suffield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06081",
    city: "Tariffville",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06082",
    city: "Enfield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06083",
    city: "Enfield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06084",
    city: "Tolland",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06085",
    city: "Unionville",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06088",
    city: "East Windsor",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06089",
    city: "Weatogue",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06090",
    city: "West Granby",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06091",
    city: "West Hartland",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06092",
    city: "West Simsbury",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06093",
    city: "West Suffield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06094",
    city: "Winchester Center",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06095",
    city: "Windsor",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06096",
    city: "Windsor Locks",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06098",
    city: "Winsted",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06101",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06102",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06103",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06104",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06105",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06106",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06107",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06108",
    city: "East Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06109",
    city: "Wethersfield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06110",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06111",
    city: "Newington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06112",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06114",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06115",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06117",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06118",
    city: "East Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06119",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06120",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06123",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06126",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06127",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06128",
    city: "East Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06129",
    city: "Wethersfield",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06131",
    city: "Newington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06132",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06133",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06134",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06137",
    city: "West Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06138",
    city: "East Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06140",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06141",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06142",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06143",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06144",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06145",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06146",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06147",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06150",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06151",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06152",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06153",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06154",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06155",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06156",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06160",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06161",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06167",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06176",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06180",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06183",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06199",
    city: "Hartford",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06226",
    city: "Willimantic",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06230",
    city: "Abington",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06231",
    city: "Amston",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06232",
    city: "Andover",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06233",
    city: "Ballouville",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06234",
    city: "Brooklyn",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06235",
    city: "Chaplin",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06237",
    city: "Columbia",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06238",
    city: "Coventry",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06239",
    city: "Danielson",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06241",
    city: "Dayville",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06242",
    city: "Eastford",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06243",
    city: "East Killingly",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06244",
    city: "East Woodstock",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06245",
    city: "Fabyan",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06246",
    city: "Grosvenor Dale",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06247",
    city: "Hampton",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06248",
    city: "Hebron",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06249",
    city: "Lebanon",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06250",
    city: "Mansfield Center",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06251",
    city: "Mansfield Depot",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06254",
    city: "North Franklin",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06255",
    city: "North Grosvenordale",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06256",
    city: "North Windham",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06258",
    city: "Pomfret",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06259",
    city: "Pomfret Center",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06260",
    city: "Putnam",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06262",
    city: "Quinebaug",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06263",
    city: "Rogers",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06264",
    city: "Scotland",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06265",
    city: "South Willington",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06266",
    city: "South Windham",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06267",
    city: "South Woodstock",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06268",
    city: "Storrs Mansfield",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06269",
    city: "Storrs Mansfield",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06277",
    city: "Thompson",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06278",
    city: "Ashford",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06279",
    city: "Willington",
    state: "CT",
    areacode: 860,
    county: "Tolland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06280",
    city: "Windham",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06281",
    city: "Woodstock",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06282",
    city: "Woodstock Valley",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06320",
    city: "New London",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06330",
    city: "Baltic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06331",
    city: "Canterbury",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06332",
    city: "Central Village",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06333",
    city: "East Lyme",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06334",
    city: "Bozrah",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06335",
    city: "Gales Ferry",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06336",
    city: "Gilman",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06338",
    city: "Mashantucket",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06339",
    city: "Ledyard",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06340",
    city: "Groton",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06349",
    city: "Groton",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06350",
    city: "Hanover",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06351",
    city: "Jewett City",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06353",
    city: "Montville",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06354",
    city: "Moosup",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06355",
    city: "Mystic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06357",
    city: "Niantic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06359",
    city: "North Stonington",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06360",
    city: "Norwich",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06365",
    city: "Preston",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06370",
    city: "Oakdale",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06371",
    city: "Old Lyme",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06372",
    city: "Old Mystic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06373",
    city: "Oneco",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06374",
    city: "Plainfield",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06375",
    city: "Quaker Hill",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06376",
    city: "South Lyme",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06377",
    city: "Sterling",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06378",
    city: "Stonington",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06379",
    city: "Pawcatuck",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06380",
    city: "Taftville",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06382",
    city: "Uncasville",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06383",
    city: "Versailles",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06384",
    city: "Voluntown",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06385",
    city: "Waterford",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06387",
    city: "Wauregan",
    state: "CT",
    areacode: 860,
    county: "Windham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06388",
    city: "West Mystic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06389",
    city: "Yantic",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06390",
    city: "Fishers Island",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06401",
    city: "Ansonia",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06403",
    city: "Beacon Falls",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06404",
    city: "Botsford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06405",
    city: "Branford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06408",
    city: "Cheshire",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06409",
    city: "Centerbrook",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06410",
    city: "Cheshire",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06411",
    city: "Cheshire",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06412",
    city: "Chester",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06413",
    city: "Clinton",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06414",
    city: "Cobalt",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06415",
    city: "Colchester",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06416",
    city: "Cromwell",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06417",
    city: "Deep River",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06418",
    city: "Derby",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06419",
    city: "Killingworth",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06420",
    city: "Salem",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06422",
    city: "Durham",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06423",
    city: "East Haddam",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06424",
    city: "East Hampton",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06426",
    city: "Essex",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06437",
    city: "Guilford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06438",
    city: "Haddam",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06439",
    city: "Hadlyme",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06440",
    city: "Hawleyville",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06441",
    city: "Higganum",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06442",
    city: "Ivoryton",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06443",
    city: "Madison",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06444",
    city: "Marion",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06447",
    city: "Marlborough",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06450",
    city: "Meriden",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06451",
    city: "Meriden",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06455",
    city: "Middlefield",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06456",
    city: "Middle Haddam",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06457",
    city: "Middletown",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06459",
    city: "Middletown",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06460",
    city: "Milford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06461",
    city: "Milford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06467",
    city: "Milldale",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06468",
    city: "Monroe",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06469",
    city: "Moodus",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06470",
    city: "Newtown",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06471",
    city: "North Branford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06472",
    city: "Northford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06473",
    city: "North Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06474",
    city: "North Westchester",
    state: "CT",
    areacode: 860,
    county: "New London",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06475",
    city: "Old Saybrook",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06477",
    city: "Orange",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06478",
    city: "Oxford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06479",
    city: "Plantsville",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06480",
    city: "Portland",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06481",
    city: "Rockfall",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06482",
    city: "Sandy Hook",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06483",
    city: "Seymour",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06484",
    city: "Shelton",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06487",
    city: "South Britain",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06488",
    city: "Southbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06489",
    city: "Southington",
    state: "CT",
    areacode: 860,
    county: "Hartford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06491",
    city: "Stevenson",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06492",
    city: "Wallingford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06493",
    city: "Wallingford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06494",
    city: "Wallingford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06495",
    city: "Wallingford",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06498",
    city: "Westbrook",
    state: "CT",
    areacode: 860,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06501",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06502",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06503",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06504",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06505",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06506",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06507",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06508",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06509",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06510",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06511",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06512",
    city: "East Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06513",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06514",
    city: "Hamden",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06515",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06516",
    city: "West Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06517",
    city: "Hamden",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06518",
    city: "Hamden",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06519",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06520",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06521",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06524",
    city: "Bethany",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06525",
    city: "Woodbridge",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06530",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06531",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06532",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06533",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06534",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06535",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06536",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06537",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06538",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06540",
    city: "New Haven",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06601",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06602",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06604",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06605",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06606",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06607",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06608",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06610",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06611",
    city: "Trumbull",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06612",
    city: "Easton",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06614",
    city: "Stratford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06615",
    city: "Stratford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06673",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06699",
    city: "Bridgeport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06701",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06702",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06703",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06704",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06705",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06706",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06708",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06710",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06712",
    city: "Prospect",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06716",
    city: "Wolcott",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06720",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06721",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06722",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06723",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06724",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06725",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06726",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06749",
    city: "Waterbury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06750",
    city: "Bantam",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06751",
    city: "Bethlehem",
    state: "CT",
    areacode: 203,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06752",
    city: "Bridgewater",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06753",
    city: "Cornwall",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06754",
    city: "Cornwall Bridge",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06755",
    city: "Gaylordsville",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06756",
    city: "Goshen",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06757",
    city: "Kent",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06758",
    city: "Lakeside",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06759",
    city: "Litchfield",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06762",
    city: "Middlebury",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06763",
    city: "Morris",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06770",
    city: "Naugatuck",
    state: "CT",
    areacode: 203,
    county: "New Haven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06776",
    city: "New Milford",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06777",
    city: "New Preston Marble Dale",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06778",
    city: "Northfield",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06779",
    city: "Oakville",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06781",
    city: "Pequabuck",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06782",
    city: "Plymouth",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06783",
    city: "Roxbury",
    state: "CT",
    areacode: 203,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06784",
    city: "Sherman",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06785",
    city: "South Kent",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06786",
    city: "Terryville",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06787",
    city: "Thomaston",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06790",
    city: "Torrington",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06791",
    city: "Harwinton",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06792",
    city: "Torrington",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06793",
    city: "Washington",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06794",
    city: "Washington Depot",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06795",
    city: "Watertown",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06796",
    city: "West Cornwall",
    state: "CT",
    areacode: 860,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06798",
    city: "Woodbury",
    state: "CT",
    areacode: 203,
    county: "Litchfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06801",
    city: "Bethel",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06804",
    city: "Brookfield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06807",
    city: "Cos Cob",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06810",
    city: "Danbury",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06811",
    city: "Danbury",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06812",
    city: "New Fairfield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06813",
    city: "Danbury",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06820",
    city: "Darien",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06824",
    city: "Fairfield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06825",
    city: "Fairfield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06828",
    city: "Fairfield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06829",
    city: "Georgetown",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06830",
    city: "Greenwich",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06831",
    city: "Greenwich",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06836",
    city: "Greenwich",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06838",
    city: "Greens Farms",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06840",
    city: "New Canaan",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06850",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06851",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06852",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06853",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06854",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06855",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06856",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06857",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06858",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06860",
    city: "Norwalk",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06870",
    city: "Old Greenwich",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06875",
    city: "Redding Center",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06876",
    city: "Redding Ridge",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06877",
    city: "Ridgefield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06878",
    city: "Riverside",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06879",
    city: "Ridgefield",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06880",
    city: "Westport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06881",
    city: "Westport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06883",
    city: "Weston",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06888",
    city: "Westport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06889",
    city: "Westport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06890",
    city: "Southport",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06896",
    city: "Redding",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06897",
    city: "Wilton",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06901",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06902",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06903",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06904",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06905",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06906",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06907",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06910",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06911",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06912",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06913",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06914",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06926",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "06927",
    city: "Stamford",
    state: "CT",
    areacode: 203,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07001",
    city: "Avenel",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07002",
    city: "Bayonne",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07003",
    city: "Bloomfield",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07004",
    city: "Fairfield",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07005",
    city: "Boonton",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07006",
    city: "Caldwell",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07007",
    city: "Caldwell",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07008",
    city: "Carteret",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07009",
    city: "Cedar Grove",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07010",
    city: "Cliffside Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07011",
    city: "Clifton",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07012",
    city: "Clifton",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07013",
    city: "Clifton",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07014",
    city: "Clifton",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07015",
    city: "Clifton",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07016",
    city: "Cranford",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07017",
    city: "East Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07018",
    city: "East Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07019",
    city: "East Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07020",
    city: "Edgewater",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07021",
    city: "Essex Fells",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07022",
    city: "Fairview",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07023",
    city: "Fanwood",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07024",
    city: "Fort Lee",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07026",
    city: "Garfield",
    state: "NJ",
    areacode: 973,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07027",
    city: "Garwood",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07028",
    city: "Glen Ridge",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07029",
    city: "Harrison",
    state: "NJ",
    areacode: 973,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07030",
    city: "Hoboken",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07031",
    city: "North Arlington",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07032",
    city: "Kearny",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07033",
    city: "Kenilworth",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07034",
    city: "Lake Hiawatha",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07035",
    city: "Lincoln Park",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07036",
    city: "Linden",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07039",
    city: "Livingston",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07040",
    city: "Maplewood",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07041",
    city: "Millburn",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07042",
    city: "Montclair",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07043",
    city: "Montclair",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07044",
    city: "Verona",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07045",
    city: "Montville",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07046",
    city: "Mountain Lakes",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07047",
    city: "North Bergen",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07050",
    city: "Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07051",
    city: "Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07052",
    city: "West Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07054",
    city: "Parsippany",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07055",
    city: "Passaic",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07057",
    city: "Wallington",
    state: "NJ",
    areacode: 973,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07058",
    city: "Pine Brook",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07059",
    city: "Warren",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07060",
    city: "Plainfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07061",
    city: "Plainfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07062",
    city: "Plainfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07063",
    city: "Plainfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07064",
    city: "Port Reading",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07065",
    city: "Rahway",
    state: "NJ",
    areacode: 732,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07066",
    city: "Clark",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07067",
    city: "Colonia",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07068",
    city: "Roseland",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07069",
    city: "Watchung",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07070",
    city: "Rutherford",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07071",
    city: "Lyndhurst",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07072",
    city: "Carlstadt",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07073",
    city: "East Rutherford",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07074",
    city: "Moonachie",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07075",
    city: "Wood Ridge",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07076",
    city: "Scotch Plains",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07077",
    city: "Sewaren",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07078",
    city: "Short Hills",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07079",
    city: "South Orange",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07080",
    city: "South Plainfield",
    state: "NJ",
    areacode: 908,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07081",
    city: "Springfield",
    state: "NJ",
    areacode: 973,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07082",
    city: "Towaco",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07083",
    city: "Union",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07086",
    city: "Weehawken",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07087",
    city: "Union City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07088",
    city: "Vauxhall",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07090",
    city: "Westfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07091",
    city: "Westfield",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07092",
    city: "Mountainside",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07093",
    city: "West New York",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07094",
    city: "Secaucus",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07095",
    city: "Woodbridge",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07096",
    city: "Secaucus",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07097",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07099",
    city: "Kearny",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07101",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07102",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07103",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07104",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07105",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07106",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07107",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07108",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07109",
    city: "Belleville",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07110",
    city: "Nutley",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07111",
    city: "Irvington",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07112",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07114",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07175",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07184",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07188",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07189",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07191",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07192",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07193",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07195",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07198",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07199",
    city: "Newark",
    state: "NJ",
    areacode: 973,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07201",
    city: "Elizabeth",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07202",
    city: "Elizabeth",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07203",
    city: "Roselle",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07204",
    city: "Roselle Park",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07205",
    city: "Hillside",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07206",
    city: "Elizabethport",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07207",
    city: "Elizabeth",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07208",
    city: "Elizabeth",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07302",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07303",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07304",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07305",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07306",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07307",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07308",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07310",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07311",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07395",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07399",
    city: "Jersey City",
    state: "NJ",
    areacode: 201,
    county: "Hudson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07401",
    city: "Allendale",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07403",
    city: "Bloomingdale",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07405",
    city: "Butler",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07407",
    city: "Elmwood Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07410",
    city: "Fair Lawn",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07416",
    city: "Franklin",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07417",
    city: "Franklin Lakes",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07418",
    city: "Glenwood",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07419",
    city: "Hamburg",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07420",
    city: "Haskell",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07421",
    city: "Hewitt",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07422",
    city: "Highland Lakes",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07423",
    city: "Ho Ho Kus",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07424",
    city: "Little Falls",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07428",
    city: "McAfee",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07430",
    city: "Mahwah",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07432",
    city: "Midland Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07435",
    city: "Newfoundland",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07436",
    city: "Oakland",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07438",
    city: "Oak Ridge",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07439",
    city: "Ogdensburg",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07440",
    city: "Pequannock",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07442",
    city: "Pompton Lakes",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07444",
    city: "Pompton Plains",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07446",
    city: "Ramsey",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07450",
    city: "Ridgewood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07451",
    city: "Ridgewood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07452",
    city: "Glen Rock",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07456",
    city: "Ringwood",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07457",
    city: "Riverdale",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07458",
    city: "Saddle River",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07460",
    city: "Stockholm",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07461",
    city: "Sussex",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07462",
    city: "Vernon",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07463",
    city: "Waldwick",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07465",
    city: "Wanaque",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07470",
    city: "Wayne",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07474",
    city: "Wayne",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07480",
    city: "West Milford",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07481",
    city: "Wyckoff",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07495",
    city: "Mahwah",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07501",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07502",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07503",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07504",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07505",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07506",
    city: "Hawthorne",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07507",
    city: "Hawthorne",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07508",
    city: "Haledon",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07509",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07510",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07511",
    city: "Totowa",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07512",
    city: "Totowa",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07513",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07514",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07522",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07524",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07533",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07538",
    city: "Haledon",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07543",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07544",
    city: "Paterson",
    state: "NJ",
    areacode: 973,
    county: "Passaic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07601",
    city: "Hackensack",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07602",
    city: "Hackensack",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07603",
    city: "Bogota",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07604",
    city: "Hasbrouck Heights",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07605",
    city: "Leonia",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07606",
    city: "South Hackensack",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07607",
    city: "Maywood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07608",
    city: "Teterboro",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07620",
    city: "Alpine",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07621",
    city: "Bergenfield",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07624",
    city: "Closter",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07626",
    city: "Cresskill",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07627",
    city: "Demarest",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07628",
    city: "Dumont",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07630",
    city: "Emerson",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07631",
    city: "Englewood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07632",
    city: "Englewood Cliffs",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07640",
    city: "Harrington Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07641",
    city: "Haworth",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07642",
    city: "Hillsdale",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07643",
    city: "Little Ferry",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07644",
    city: "Lodi",
    state: "NJ",
    areacode: 973,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07645",
    city: "Montvale",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07646",
    city: "New Milford",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07647",
    city: "Northvale",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07648",
    city: "Norwood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07649",
    city: "Oradell",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07650",
    city: "Palisades Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07652",
    city: "Paramus",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07653",
    city: "Paramus",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07656",
    city: "Park Ridge",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07657",
    city: "Ridgefield",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07660",
    city: "Ridgefield Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07661",
    city: "River Edge",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07662",
    city: "Rochelle Park",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07663",
    city: "Saddle Brook",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07666",
    city: "Teaneck",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07670",
    city: "Tenafly",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07675",
    city: "Westwood",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07676",
    city: "Township of Washington",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07677",
    city: "Woodcliff Lake",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07699",
    city: "Teterboro",
    state: "NJ",
    areacode: 201,
    county: "Bergen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07701",
    city: "Red Bank",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07702",
    city: "Shrewsbury",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07703",
    city: "Fort Monmouth",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07704",
    city: "Fair Haven",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07710",
    city: "Adelphia",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07711",
    city: "Allenhurst",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07712",
    city: "Asbury Park",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07715",
    city: "Belmar",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07716",
    city: "Atlantic Highlands",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07717",
    city: "Avon by the Sea",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07718",
    city: "Belford",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07719",
    city: "Belmar",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07720",
    city: "Bradley Beach",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07721",
    city: "Cliffwood",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07722",
    city: "Colts Neck",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07723",
    city: "Deal",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07724",
    city: "Eatontown",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07726",
    city: "Englishtown",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07727",
    city: "Farmingdale",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07728",
    city: "Freehold",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07730",
    city: "Hazlet",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07731",
    city: "Howell",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07732",
    city: "Highlands",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07733",
    city: "Holmdel",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07734",
    city: "Keansburg",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07735",
    city: "Keyport",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07737",
    city: "Leonardo",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07738",
    city: "Lincroft",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07739",
    city: "Little Silver",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07740",
    city: "Long Branch",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07746",
    city: "Marlboro",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07747",
    city: "Matawan",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07748",
    city: "Middletown",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07750",
    city: "Monmouth Beach",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07751",
    city: "Morganville",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07752",
    city: "Navesink",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07753",
    city: "Neptune",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07754",
    city: "Neptune",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07755",
    city: "Oakhurst",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07756",
    city: "Ocean Grove",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07757",
    city: "Oceanport",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07758",
    city: "Port Monmouth",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07760",
    city: "Rumson",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07762",
    city: "Spring Lake",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07763",
    city: "Tennent",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07764",
    city: "West Long Branch",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07765",
    city: "Wickatunk",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07799",
    city: "Eatontown",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07801",
    city: "Dover",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07802",
    city: "Dover",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07803",
    city: "Mine Hill",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07806",
    city: "Picatinny Arsenal",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07820",
    city: "Allamuchy",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07821",
    city: "Andover",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07822",
    city: "Augusta",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07823",
    city: "Belvidere",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07825",
    city: "Blairstown",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07826",
    city: "Branchville",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07827",
    city: "Montague",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07828",
    city: "Budd Lake",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07829",
    city: "Buttzville",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07830",
    city: "Califon",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07831",
    city: "Changewater",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07832",
    city: "Columbia",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07833",
    city: "Delaware",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07834",
    city: "Denville",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07836",
    city: "Flanders",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07837",
    city: "Glasser",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07838",
    city: "Great Meadows",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07839",
    city: "Greendell",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07840",
    city: "Hackettstown",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07842",
    city: "Hibernia",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07843",
    city: "Hopatcong",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07844",
    city: "Hope",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07845",
    city: "Ironia",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07846",
    city: "Johnsonburg",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07847",
    city: "Kenvil",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07848",
    city: "Lafayette",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07849",
    city: "Lake Hopatcong",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07850",
    city: "Landing",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07851",
    city: "Layton",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07852",
    city: "Ledgewood",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07853",
    city: "Long Valley",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07855",
    city: "Middleville",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07856",
    city: "Mount Arlington",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07857",
    city: "Netcong",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07860",
    city: "Newton",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07863",
    city: "Oxford",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07865",
    city: "Port Murray",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07866",
    city: "Rockaway",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07869",
    city: "Randolph",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07870",
    city: "Schooleys Mountain",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07871",
    city: "Sparta",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07874",
    city: "Stanhope",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07875",
    city: "Stillwater",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07876",
    city: "Succasunna",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07877",
    city: "Swartswood",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07878",
    city: "Mount Tabor",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07879",
    city: "Tranquility",
    state: "NJ",
    areacode: 908,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07880",
    city: "Vienna",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07881",
    city: "Wallpack Center",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07882",
    city: "Washington",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07885",
    city: "Wharton",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07890",
    city: "Branchville",
    state: "NJ",
    areacode: 973,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07901",
    city: "Summit",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07902",
    city: "Summit",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07920",
    city: "Basking Ridge",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07921",
    city: "Bedminster",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07922",
    city: "Berkeley Heights",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07924",
    city: "Bernardsville",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07926",
    city: "Brookside",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07927",
    city: "Cedar Knolls",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07928",
    city: "Chatham",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07930",
    city: "Chester",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07931",
    city: "Far Hills",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07932",
    city: "Florham Park",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07933",
    city: "Gillette",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07934",
    city: "Gladstone",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07935",
    city: "Green Village",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07936",
    city: "East Hanover",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07938",
    city: "Liberty Corner",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07939",
    city: "Lyons",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07940",
    city: "Madison",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07945",
    city: "Mendham",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07946",
    city: "Millington",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07950",
    city: "Morris Plains",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07960",
    city: "Morristown",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07961",
    city: "Convent Station",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07962",
    city: "Morristown",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07963",
    city: "Morristown",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07970",
    city: "Mount Freedom",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07974",
    city: "New Providence",
    state: "NJ",
    areacode: 908,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07976",
    city: "New Vernon",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07977",
    city: "Peapack",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07978",
    city: "Pluckemin",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07979",
    city: "Pottersville",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07980",
    city: "Stirling",
    state: "NJ",
    areacode: 908,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07981",
    city: "Whippany",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "07999",
    city: "Whippany",
    state: "NJ",
    areacode: 973,
    county: "Morris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08001",
    city: "Alloway",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08002",
    city: "Cherry Hill",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08003",
    city: "Cherry Hill",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08004",
    city: "Atco",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08005",
    city: "Barnegat",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08006",
    city: "Barnegat Light",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08007",
    city: "Barrington",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08008",
    city: "Beach Haven",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08009",
    city: "Berlin",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08010",
    city: "Beverly",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08011",
    city: "Birmingham",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08012",
    city: "Blackwood",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08014",
    city: "Bridgeport",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08015",
    city: "Browns Mills",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08016",
    city: "Burlington",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08018",
    city: "Cedar Brook",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08019",
    city: "Chatsworth",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08020",
    city: "Clarksboro",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08021",
    city: "Clementon",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08022",
    city: "Columbus",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08023",
    city: "Deepwater",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08025",
    city: "Ewan",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08026",
    city: "Gibbsboro",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08027",
    city: "Gibbstown",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08028",
    city: "Glassboro",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08029",
    city: "Glendora",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08030",
    city: "Gloucester City",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08031",
    city: "Bellmawr",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08032",
    city: "Grenloch",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08033",
    city: "Haddonfield",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08034",
    city: "Cherry Hill",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08035",
    city: "Haddon Heights",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08036",
    city: "Hainesport",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08037",
    city: "Hammonton",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08038",
    city: "Hancocks Bridge",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08039",
    city: "Harrisonville",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08041",
    city: "Jobstown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08042",
    city: "Juliustown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08043",
    city: "Voorhees",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08045",
    city: "Lawnside",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08046",
    city: "Willingboro",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08048",
    city: "Lumberton",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08049",
    city: "Magnolia",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08050",
    city: "Manahawkin",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08051",
    city: "Mantua",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08052",
    city: "Maple Shade",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08053",
    city: "Marlton",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08054",
    city: "Mount Laurel",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08055",
    city: "Medford",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08056",
    city: "Mickleton",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08057",
    city: "Moorestown",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08059",
    city: "Mount Ephraim",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08060",
    city: "Mount Holly",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08061",
    city: "Mount Royal",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08062",
    city: "Mullica Hill",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08063",
    city: "National Park",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08064",
    city: "New Lisbon",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08065",
    city: "Palmyra",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08066",
    city: "Paulsboro",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08067",
    city: "Pedricktown",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08068",
    city: "Pemberton",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08069",
    city: "Penns Grove",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08070",
    city: "Pennsville",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08071",
    city: "Pitman",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08072",
    city: "Quinton",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08073",
    city: "Rancocas",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08074",
    city: "Richwood",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08075",
    city: "Riverside",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08076",
    city: "Riverton",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08077",
    city: "Riverton",
    state: "NJ",
    areacode: 856,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08078",
    city: "Runnemede",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08079",
    city: "Salem",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08080",
    city: "Sewell",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08081",
    city: "Sicklerville",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08083",
    city: "Somerdale",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08084",
    city: "Stratford",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08085",
    city: "Swedesboro",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08086",
    city: "Thorofare",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08087",
    city: "Tuckerton",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08088",
    city: "Vincentown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08089",
    city: "Waterford Works",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08090",
    city: "Wenonah",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08091",
    city: "West Berlin",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08092",
    city: "West Creek",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08093",
    city: "Westville",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08094",
    city: "Williamstown",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08095",
    city: "Winslow",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08096",
    city: "Deptford",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08097",
    city: "Woodbury Heights",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08098",
    city: "Woodstown",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08099",
    city: "Bellmawr",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08101",
    city: "Camden",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08102",
    city: "Camden",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08103",
    city: "Camden",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08104",
    city: "Camden",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08105",
    city: "Camden",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08106",
    city: "Audubon",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08107",
    city: "Oaklyn",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08108",
    city: "Collingswood",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08109",
    city: "Merchantville",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08110",
    city: "Pennsauken",
    state: "NJ",
    areacode: 856,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08201",
    city: "Absecon",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08202",
    city: "Avalon",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08203",
    city: "Brigantine",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08204",
    city: "Cape May",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08205",
    city: "Absecon",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08210",
    city: "Cape May Court House",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08212",
    city: "Cape May Point",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08213",
    city: "Cologne",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08214",
    city: "Dennisville",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08215",
    city: "Egg Harbor City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08217",
    city: "Elwood",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08218",
    city: "Goshen",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08219",
    city: "Green Creek",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08220",
    city: "Leeds Point",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08221",
    city: "Linwood",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08223",
    city: "Marmora",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08224",
    city: "New Gretna",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08225",
    city: "Northfield",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08226",
    city: "Ocean City",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08230",
    city: "Ocean View",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08231",
    city: "Oceanville",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08232",
    city: "Pleasantville",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08234",
    city: "Egg Harbor Township",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08240",
    city: "Pomona",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08241",
    city: "Port Republic",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08242",
    city: "Rio Grande",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08243",
    city: "Sea Isle City",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08244",
    city: "Somers Point",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08245",
    city: "South Dennis",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08246",
    city: "South Seaville",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08247",
    city: "Stone Harbor",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08248",
    city: "Strathmere",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08250",
    city: "Tuckahoe",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08251",
    city: "Villas",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08252",
    city: "Whitesboro",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08260",
    city: "Wildwood",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08270",
    city: "Woodbine",
    state: "NJ",
    areacode: 609,
    county: "Cape May",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08302",
    city: "Bridgeton",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08310",
    city: "Buena",
    state: "NJ",
    areacode: 856,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08311",
    city: "Cedarville",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08312",
    city: "Clayton",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08313",
    city: "Deerfield Street",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08314",
    city: "Delmont",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08315",
    city: "Dividing Creek",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08316",
    city: "Dorchester",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08317",
    city: "Dorothy",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08318",
    city: "Elmer",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08319",
    city: "Estell Manor",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08320",
    city: "Fairton",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08321",
    city: "Fortescue",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08322",
    city: "Franklinville",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08323",
    city: "Greenwich",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08324",
    city: "Heislerville",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08326",
    city: "Landisville",
    state: "NJ",
    areacode: 856,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08327",
    city: "Leesburg",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08328",
    city: "Malaga",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08329",
    city: "Mauricetown",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08330",
    city: "Mays Landing",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08332",
    city: "Millville",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08340",
    city: "Milmay",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08341",
    city: "Minotola",
    state: "NJ",
    areacode: 856,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08342",
    city: "Mizpah",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08343",
    city: "Monroeville",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08344",
    city: "Newfield",
    state: "NJ",
    areacode: 856,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08345",
    city: "Newport",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08346",
    city: "Newtonville",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08347",
    city: "Norma",
    state: "NJ",
    areacode: 856,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08348",
    city: "Port Elizabeth",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08349",
    city: "Port Norris",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08350",
    city: "Richland",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08352",
    city: "Rosenhayn",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08353",
    city: "Shiloh",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08360",
    city: "Vineland",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08361",
    city: "Vineland",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08362",
    city: "Vineland",
    state: "NJ",
    areacode: 856,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08401",
    city: "Atlantic City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08402",
    city: "Margate City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08403",
    city: "Longport",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08404",
    city: "Atlantic City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08405",
    city: "Atlantic City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08406",
    city: "Ventnor City",
    state: "NJ",
    areacode: 609,
    county: "Atlantic",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08501",
    city: "Allentown",
    state: "NJ",
    areacode: 609,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08502",
    city: "Belle Mead",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08504",
    city: "Blawenburg",
    state: "NJ",
    areacode: 609,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08505",
    city: "Bordentown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08510",
    city: "Millstone Township",
    state: "NJ",
    areacode: 609,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08511",
    city: "Cookstown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08512",
    city: "Cranbury",
    state: "NJ",
    areacode: 609,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08514",
    city: "Cream Ridge",
    state: "NJ",
    areacode: 609,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08515",
    city: "Chesterfield",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08518",
    city: "Florence",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08520",
    city: "Hightstown",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08525",
    city: "Hopewell",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08526",
    city: "Imlaystown",
    state: "NJ",
    areacode: 609,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08527",
    city: "Jackson",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08528",
    city: "Kingston",
    state: "NJ",
    areacode: 609,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08530",
    city: "Lambertville",
    state: "NJ",
    areacode: 609,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08533",
    city: "New Egypt",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08534",
    city: "Pennington",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08535",
    city: "Millstone Township",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08536",
    city: "Plainsboro",
    state: "NJ",
    areacode: 609,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08540",
    city: "Princeton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08541",
    city: "Princeton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08542",
    city: "Princeton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08543",
    city: "Princeton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08544",
    city: "Princeton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08550",
    city: "Princeton Junction",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08551",
    city: "Ringoes",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08553",
    city: "Rocky Hill",
    state: "NJ",
    areacode: 609,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08554",
    city: "Roebling",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08555",
    city: "Roosevelt",
    state: "NJ",
    areacode: 609,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08556",
    city: "Rosemont",
    state: "NJ",
    areacode: 609,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08557",
    city: "Sergeantsville",
    state: "NJ",
    areacode: 609,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08558",
    city: "Skillman",
    state: "NJ",
    areacode: 609,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08559",
    city: "Stockton",
    state: "NJ",
    areacode: 609,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08560",
    city: "Titusville",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08561",
    city: "Windsor",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08562",
    city: "Wrightstown",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08601",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08602",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08603",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08604",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08605",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08606",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08607",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08608",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08609",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08610",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08611",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08618",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08619",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08620",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08625",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08628",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08629",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08638",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08640",
    city: "Joint Base MDL",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08641",
    city: "Joint Base MDL",
    state: "NJ",
    areacode: 609,
    county: "Burlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08645",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08646",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08647",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08648",
    city: "Lawrence Township",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08650",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08666",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08690",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08691",
    city: "Robbinsville",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08695",
    city: "Trenton",
    state: "NJ",
    areacode: 609,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08701",
    city: "Lakewood",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08720",
    city: "Allenwood",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08721",
    city: "Bayville",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08722",
    city: "Beachwood",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08723",
    city: "Brick",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08724",
    city: "Brick",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08730",
    city: "Brielle",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08731",
    city: "Forked River",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08732",
    city: "Island Heights",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08733",
    city: "Lakehurst",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08734",
    city: "Lanoka Harbor",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08735",
    city: "Lavallette",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08736",
    city: "Manasquan",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08738",
    city: "Mantoloking",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08739",
    city: "Normandy Beach",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08740",
    city: "Ocean Gate",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08741",
    city: "Pine Beach",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08742",
    city: "Point Pleasant Beach",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08750",
    city: "Sea Girt",
    state: "NJ",
    areacode: 732,
    county: "Monmouth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08751",
    city: "Seaside Heights",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08752",
    city: "Seaside Park",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08753",
    city: "Toms River",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08754",
    city: "Toms River",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08755",
    city: "Toms River",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08756",
    city: "Toms River",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08757",
    city: "Toms River",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08758",
    city: "Waretown",
    state: "NJ",
    areacode: 609,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08759",
    city: "Manchester Township",
    state: "NJ",
    areacode: 732,
    county: "Ocean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08801",
    city: "Annandale",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08802",
    city: "Asbury",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08803",
    city: "Baptistown",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08804",
    city: "Bloomsbury",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08805",
    city: "Bound Brook",
    state: "NJ",
    areacode: 732,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08807",
    city: "Bridgewater",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08808",
    city: "Broadway",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08809",
    city: "Clinton",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08810",
    city: "Dayton",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08812",
    city: "Dunellen",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08816",
    city: "East Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08817",
    city: "Edison",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08818",
    city: "Edison",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08820",
    city: "Edison",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08821",
    city: "Flagtown",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08822",
    city: "Flemington",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08823",
    city: "Franklin Park",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08824",
    city: "Kendall Park",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08825",
    city: "Frenchtown",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08826",
    city: "Glen Gardner",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08827",
    city: "Hampton",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08828",
    city: "Helmetta",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08829",
    city: "High Bridge",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08830",
    city: "Iselin",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08831",
    city: "Monroe Township",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08832",
    city: "Keasbey",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08833",
    city: "Lebanon",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08834",
    city: "Little York",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08835",
    city: "Manville",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08836",
    city: "Martinsville",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08837",
    city: "Edison",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08840",
    city: "Metuchen",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08844",
    city: "Hillsborough",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08846",
    city: "Middlesex",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08848",
    city: "Milford",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08850",
    city: "Milltown",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08852",
    city: "Monmouth Junction",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08853",
    city: "Neshanic Station",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08854",
    city: "Piscataway",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08855",
    city: "Piscataway",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08857",
    city: "Old Bridge",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08858",
    city: "Oldwick",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08859",
    city: "Parlin",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08861",
    city: "Perth Amboy",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08862",
    city: "Perth Amboy",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08863",
    city: "Fords",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08865",
    city: "Phillipsburg",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08867",
    city: "Pittstown",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08868",
    city: "Quakertown",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08869",
    city: "Raritan",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08870",
    city: "Readington",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08871",
    city: "Sayreville",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08872",
    city: "Sayreville",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08873",
    city: "Somerset",
    state: "NJ",
    areacode: 732,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08875",
    city: "Somerset",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08876",
    city: "Somerville",
    state: "NJ",
    areacode: 908,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08879",
    city: "South Amboy",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08880",
    city: "South Bound Brook",
    state: "NJ",
    areacode: 732,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08882",
    city: "South River",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08884",
    city: "Spotswood",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08885",
    city: "Stanton",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08886",
    city: "Stewartsville",
    state: "NJ",
    areacode: 908,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08887",
    city: "Three Bridges",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08888",
    city: "Whitehouse",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08889",
    city: "Whitehouse Station",
    state: "NJ",
    areacode: 908,
    county: "Hunterdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08890",
    city: "Zarephath",
    state: "NJ",
    areacode: 732,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08899",
    city: "Edison",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08901",
    city: "New Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08902",
    city: "North Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08903",
    city: "New Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08904",
    city: "Highland Park",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08906",
    city: "New Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08933",
    city: "New Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "08989",
    city: "New Brunswick",
    state: "NJ",
    areacode: 732,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10001",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10002",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10003",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10004",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10005",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10006",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10007",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10008",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10009",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10010",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10011",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10012",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10013",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10014",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10016",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10017",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10018",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10019",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10020",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10021",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10022",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10023",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10024",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10025",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10026",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10027",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10028",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10029",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10030",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10031",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10032",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10033",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10034",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10035",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10036",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10037",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10038",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10039",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10040",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10041",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10043",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10044",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10045",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10055",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10060",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10065",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10069",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10075",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10080",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10081",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10087",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10090",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10101",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10102",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10103",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10104",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10105",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10106",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10107",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10108",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10109",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10110",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10111",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10112",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10113",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10114",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10115",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10116",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10117",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10118",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10119",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10120",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10121",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10122",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10123",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10124",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10125",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10126",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10128",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10129",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10130",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10131",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10132",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10133",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10138",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10150",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10151",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10152",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10153",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10154",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10155",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10156",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10157",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10158",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10159",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10160",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10162",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10163",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10164",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10165",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10166",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10167",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10168",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10169",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10170",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10171",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10172",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10173",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10174",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10175",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10176",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10177",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10178",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10179",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10185",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10199",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10203",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10211",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10212",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10213",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10242",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10249",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10256",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10258",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10259",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10260",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10261",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10265",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10268",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10269",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10270",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10271",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10272",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10273",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10274",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10275",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10276",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10277",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10278",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10279",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10280",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10281",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10282",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10285",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10286",
    city: "New York",
    state: "NY",
    areacode: 212,
    county: "New York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10301",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10302",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10303",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10304",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10305",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10306",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10307",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10308",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10309",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10310",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10311",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10312",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10313",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10314",
    city: "Staten Island",
    state: "NY",
    areacode: 718,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10451",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10452",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10453",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10454",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10455",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10456",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10457",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10458",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10459",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10460",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10461",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10462",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10463",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10464",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10465",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10466",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10467",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10468",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10469",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10470",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10471",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10472",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10473",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10474",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10475",
    city: "Bronx",
    state: "NY",
    areacode: 718,
    county: "Bronx",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10501",
    city: "Amawalk",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10502",
    city: "Ardsley",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10503",
    city: "Ardsley on Hudson",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10504",
    city: "Armonk",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10505",
    city: "Baldwin Place",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10506",
    city: "Bedford",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10507",
    city: "Bedford Hills",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10509",
    city: "Brewster",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10510",
    city: "Briarcliff Manor",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10511",
    city: "Buchanan",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10512",
    city: "Carmel",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10514",
    city: "Chappaqua",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10516",
    city: "Cold Spring",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10517",
    city: "Crompond",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10518",
    city: "Cross River",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10519",
    city: "Croton Falls",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10520",
    city: "Croton on Hudson",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10521",
    city: "Croton on Hudson",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10522",
    city: "Dobbs Ferry",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10523",
    city: "Elmsford",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10524",
    city: "Garrison",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10526",
    city: "Goldens Bridge",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10527",
    city: "Granite Springs",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10528",
    city: "Harrison",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10530",
    city: "Hartsdale",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10532",
    city: "Hawthorne",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10533",
    city: "Irvington",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10535",
    city: "Jefferson Valley",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10536",
    city: "Katonah",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10537",
    city: "Lake Peekskill",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10538",
    city: "Larchmont",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10540",
    city: "Lincolndale",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10541",
    city: "Mahopac",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10542",
    city: "Mahopac Falls",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10543",
    city: "Mamaroneck",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10545",
    city: "Maryknoll",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10546",
    city: "Millwood",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10547",
    city: "Mohegan Lake",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10548",
    city: "Montrose",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10549",
    city: "Mount Kisco",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10550",
    city: "Mount Vernon",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10551",
    city: "Mount Vernon",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10552",
    city: "Mount Vernon",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10553",
    city: "Mount Vernon",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10560",
    city: "North Salem",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10562",
    city: "Ossining",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10566",
    city: "Peekskill",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10567",
    city: "Cortlandt Manor",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10570",
    city: "Pleasantville",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10573",
    city: "Port Chester",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10576",
    city: "Pound Ridge",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10577",
    city: "Purchase",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10578",
    city: "Purdys",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10579",
    city: "Putnam Valley",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10580",
    city: "Rye",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10583",
    city: "Scarsdale",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10587",
    city: "Shenorock",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10588",
    city: "Shrub Oak",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10589",
    city: "Somers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10590",
    city: "South Salem",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10591",
    city: "Tarrytown",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10594",
    city: "Thornwood",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10595",
    city: "Valhalla",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10596",
    city: "Verplanck",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10597",
    city: "Waccabuc",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10598",
    city: "Yorktown Heights",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10601",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10602",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10603",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10604",
    city: "West Harrison",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10605",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10606",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10607",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10610",
    city: "White Plains",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10701",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10702",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10703",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10704",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10705",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10706",
    city: "Hastings on Hudson",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10707",
    city: "Tuckahoe",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10708",
    city: "Bronxville",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10709",
    city: "Eastchester",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10710",
    city: "Yonkers",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10801",
    city: "New Rochelle",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10802",
    city: "New Rochelle",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10803",
    city: "Pelham",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10804",
    city: "New Rochelle",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10805",
    city: "New Rochelle",
    state: "NY",
    areacode: 914,
    county: "Westchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10901",
    city: "Suffern",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10910",
    city: "Arden",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10911",
    city: "Bear Mountain",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10912",
    city: "Bellvale",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10913",
    city: "Blauvelt",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10914",
    city: "Blooming Grove",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10915",
    city: "Bullville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10916",
    city: "Campbell Hall",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10917",
    city: "Central Valley",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10918",
    city: "Chester",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10919",
    city: "Circleville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10920",
    city: "Congers",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10921",
    city: "Florida",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10922",
    city: "Fort Montgomery",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10923",
    city: "Garnerville",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10924",
    city: "Goshen",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10925",
    city: "Greenwood Lake",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10926",
    city: "Harriman",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10927",
    city: "Haverstraw",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10928",
    city: "Highland Falls",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10930",
    city: "Highland Mills",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10931",
    city: "Hillburn",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10932",
    city: "Howells",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10933",
    city: "Johnson",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10940",
    city: "Middletown",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10941",
    city: "Middletown",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10949",
    city: "Monroe",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10950",
    city: "Monroe",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10952",
    city: "Monsey",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10953",
    city: "Mountainville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10954",
    city: "Nanuet",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10956",
    city: "New City",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10958",
    city: "New Hampton",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10959",
    city: "New Milford",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10960",
    city: "Nyack",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10962",
    city: "Orangeburg",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10963",
    city: "Otisville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10964",
    city: "Palisades",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10965",
    city: "Pearl River",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10968",
    city: "Piermont",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10969",
    city: "Pine Island",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10970",
    city: "Pomona",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10973",
    city: "Slate Hill",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10974",
    city: "Sloatsburg",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10975",
    city: "Southfields",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10976",
    city: "Sparkill",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10977",
    city: "Spring Valley",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10979",
    city: "Sterling Forest",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10980",
    city: "Stony Point",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10981",
    city: "Sugar Loaf",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10982",
    city: "Tallman",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10983",
    city: "Tappan",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10984",
    city: "Thiells",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10985",
    city: "Thompson Ridge",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10986",
    city: "Tomkins Cove",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10987",
    city: "Tuxedo Park",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10988",
    city: "Unionville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10989",
    city: "Valley Cottage",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10990",
    city: "Warwick",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10992",
    city: "Washingtonville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10993",
    city: "West Haverstraw",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10994",
    city: "West Nyack",
    state: "NY",
    areacode: 845,
    county: "Rockland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10996",
    city: "West Point",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10997",
    city: "West Point",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "10998",
    city: "Westtown",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11001",
    city: "Floral Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11002",
    city: "Floral Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11003",
    city: "Elmont",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11004",
    city: "Glen Oaks",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11005",
    city: "Floral Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11010",
    city: "Franklin Square",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11020",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11021",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11022",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11023",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11024",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11026",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11027",
    city: "Great Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11030",
    city: "Manhasset",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11040",
    city: "New Hyde Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11042",
    city: "New Hyde Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11050",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11051",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11052",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11053",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11054",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11055",
    city: "Port Washington",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11096",
    city: "Inwood",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11101",
    city: "Long Island City",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11102",
    city: "Astoria",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11103",
    city: "Astoria",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11104",
    city: "Sunnyside",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11105",
    city: "Astoria",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11106",
    city: "Astoria",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11109",
    city: "Long Island City",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11120",
    city: "Long Island City",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11201",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11202",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11203",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11204",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11205",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11206",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11207",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11208",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11209",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11210",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11211",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11212",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11213",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11214",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11215",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11216",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11217",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11218",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11219",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11220",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11221",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11222",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11223",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11224",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11225",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11226",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11228",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11229",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11230",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11231",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11232",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11233",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11234",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11235",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11236",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11237",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11238",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11239",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11241",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11242",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11243",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11245",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11247",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11249",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11251",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11252",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11256",
    city: "Brooklyn",
    state: "NY",
    areacode: 718,
    county: "Kings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11351",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11352",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11354",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11355",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11356",
    city: "College Point",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11357",
    city: "Whitestone",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11358",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11359",
    city: "Bayside",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11360",
    city: "Bayside",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11361",
    city: "Bayside",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11362",
    city: "Little Neck",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11363",
    city: "Little Neck",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11364",
    city: "Oakland Gardens",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11365",
    city: "Fresh Meadows",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11366",
    city: "Fresh Meadows",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11367",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11368",
    city: "Corona",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11369",
    city: "East Elmhurst",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11370",
    city: "East Elmhurst",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11371",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11372",
    city: "Jackson Heights",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11373",
    city: "Elmhurst",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11374",
    city: "Rego Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11375",
    city: "Forest Hills",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11377",
    city: "Woodside",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11378",
    city: "Maspeth",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11379",
    city: "Middle Village",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11380",
    city: "Elmhurst",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11381",
    city: "Flushing",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11385",
    city: "Ridgewood",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11386",
    city: "Ridgewood",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11405",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11411",
    city: "Cambria Heights",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11412",
    city: "Saint Albans",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11413",
    city: "Springfield Gardens",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11414",
    city: "Howard Beach",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11415",
    city: "Kew Gardens",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11416",
    city: "Ozone Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11417",
    city: "Ozone Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11418",
    city: "Richmond Hill",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11419",
    city: "South Richmond Hill",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11420",
    city: "South Ozone Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11421",
    city: "Woodhaven",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11422",
    city: "Rosedale",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11423",
    city: "Hollis",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11424",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11425",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11426",
    city: "Bellerose",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11427",
    city: "Queens Village",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11428",
    city: "Queens Village",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11429",
    city: "Queens Village",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11430",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11431",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11432",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11433",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11434",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11435",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11436",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11437",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11439",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11451",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11499",
    city: "Jamaica",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11501",
    city: "Mineola",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11507",
    city: "Albertson",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11509",
    city: "Atlantic Beach",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11510",
    city: "Baldwin",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11514",
    city: "Carle Place",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11516",
    city: "Cedarhurst",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11518",
    city: "East Rockaway",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11520",
    city: "Freeport",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11530",
    city: "Garden City",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11531",
    city: "Garden City",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11542",
    city: "Glen Cove",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11545",
    city: "Glen Head",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11547",
    city: "Glenwood Landing",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11548",
    city: "Greenvale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11549",
    city: "Hempstead",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11550",
    city: "Hempstead",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11551",
    city: "Hempstead",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11552",
    city: "West Hempstead",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11553",
    city: "Uniondale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11554",
    city: "East Meadow",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11555",
    city: "Uniondale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11556",
    city: "Uniondale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11557",
    city: "Hewlett",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11558",
    city: "Island Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11559",
    city: "Lawrence",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11560",
    city: "Locust Valley",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11561",
    city: "Long Beach",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11563",
    city: "Lynbrook",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11565",
    city: "Malverne",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11566",
    city: "Merrick",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11568",
    city: "Old Westbury",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11569",
    city: "Point Lookout",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11570",
    city: "Rockville Centre",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11571",
    city: "Rockville Centre",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11572",
    city: "Oceanside",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11575",
    city: "Roosevelt",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11576",
    city: "Roslyn",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11577",
    city: "Roslyn Heights",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11579",
    city: "Sea Cliff",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11580",
    city: "Valley Stream",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11581",
    city: "Valley Stream",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11582",
    city: "Valley Stream",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11590",
    city: "Westbury",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11596",
    city: "Williston Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11598",
    city: "Woodmere",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11599",
    city: "Garden City",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11690",
    city: "Far Rockaway",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11691",
    city: "Far Rockaway",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11692",
    city: "Arverne",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11693",
    city: "Far Rockaway",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11694",
    city: "Rockaway Park",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11695",
    city: "Far Rockaway",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11697",
    city: "Breezy Point",
    state: "NY",
    areacode: 718,
    county: "Queens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11701",
    city: "Amityville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11702",
    city: "Babylon",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11703",
    city: "North Babylon",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11704",
    city: "West Babylon",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11705",
    city: "Bayport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11706",
    city: "Bay Shore",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11707",
    city: "West Babylon",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11709",
    city: "Bayville",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11710",
    city: "Bellmore",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11713",
    city: "Bellport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11714",
    city: "Bethpage",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11715",
    city: "Blue Point",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11716",
    city: "Bohemia",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11717",
    city: "Brentwood",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11718",
    city: "Brightwaters",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11719",
    city: "Brookhaven",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11720",
    city: "Centereach",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11721",
    city: "Centerport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11722",
    city: "Central Islip",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11724",
    city: "Cold Spring Harbor",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11725",
    city: "Commack",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11726",
    city: "Copiague",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11727",
    city: "Coram",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11729",
    city: "Deer Park",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11730",
    city: "East Islip",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11731",
    city: "East Northport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11732",
    city: "East Norwich",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11733",
    city: "East Setauket",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11735",
    city: "Farmingdale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11737",
    city: "Farmingdale",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11738",
    city: "Farmingville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11739",
    city: "Great River",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11740",
    city: "Greenlawn",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11741",
    city: "Holbrook",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11742",
    city: "Holtsville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11743",
    city: "Huntington",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11746",
    city: "Huntington Station",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11747",
    city: "Melville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11749",
    city: "Islandia",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11751",
    city: "Islip",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11752",
    city: "Islip Terrace",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11753",
    city: "Jericho",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11754",
    city: "Kings Park",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11755",
    city: "Lake Grove",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11756",
    city: "Levittown",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11757",
    city: "Lindenhurst",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11758",
    city: "Massapequa",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11760",
    city: "Melville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11762",
    city: "Massapequa Park",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11763",
    city: "Medford",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11764",
    city: "Miller Place",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11765",
    city: "Mill Neck",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11766",
    city: "Mount Sinai",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11767",
    city: "Nesconset",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11768",
    city: "Northport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11769",
    city: "Oakdale",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11770",
    city: "Ocean Beach",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11771",
    city: "Oyster Bay",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11772",
    city: "Patchogue",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11773",
    city: "Melville",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11775",
    city: "Melville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11776",
    city: "Port Jefferson Station",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11777",
    city: "Port Jefferson",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11778",
    city: "Rocky Point",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11779",
    city: "Ronkonkoma",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11780",
    city: "Saint James",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11782",
    city: "Sayville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11783",
    city: "Seaford",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11784",
    city: "Selden",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11786",
    city: "Shoreham",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11787",
    city: "Smithtown",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11788",
    city: "Hauppauge",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11789",
    city: "Sound Beach",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11790",
    city: "Stony Brook",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11791",
    city: "Syosset",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11792",
    city: "Wading River",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11793",
    city: "Wantagh",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11794",
    city: "Stony Brook",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11795",
    city: "West Islip",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11796",
    city: "West Sayville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11797",
    city: "Woodbury",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11798",
    city: "Wyandanch",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11801",
    city: "Hicksville",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11802",
    city: "Hicksville",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11803",
    city: "Plainview",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11804",
    city: "Old Bethpage",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11815",
    city: "Hicksville",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11853",
    city: "Jericho",
    state: "NY",
    areacode: 516,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11901",
    city: "Riverhead",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11930",
    city: "Amagansett",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11931",
    city: "Aquebogue",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11932",
    city: "Bridgehampton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11933",
    city: "Calverton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11934",
    city: "Center Moriches",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11935",
    city: "Cutchogue",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11937",
    city: "East Hampton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11939",
    city: "East Marion",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11940",
    city: "East Moriches",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11941",
    city: "Eastport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11942",
    city: "East Quogue",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11944",
    city: "Greenport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11946",
    city: "Hampton Bays",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11947",
    city: "Jamesport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11948",
    city: "Laurel",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11949",
    city: "Manorville",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11950",
    city: "Mastic",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11951",
    city: "Mastic Beach",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11952",
    city: "Mattituck",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11953",
    city: "Middle Island",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11954",
    city: "Montauk",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11955",
    city: "Moriches",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11956",
    city: "New Suffolk",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11957",
    city: "Orient",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11958",
    city: "Peconic",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11959",
    city: "Quogue",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11960",
    city: "Remsenburg",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11961",
    city: "Ridge",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11962",
    city: "Sagaponack",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11963",
    city: "Sag Harbor",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11964",
    city: "Shelter Island",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11965",
    city: "Shelter Island Heights",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11967",
    city: "Shirley",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11968",
    city: "Southampton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11969",
    city: "Southampton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11970",
    city: "South Jamesport",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11971",
    city: "Southold",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11972",
    city: "Speonk",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11973",
    city: "Upton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11975",
    city: "Wainscott",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11976",
    city: "Water Mill",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11977",
    city: "Westhampton",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11978",
    city: "Westhampton Beach",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "11980",
    city: "Yaphank",
    state: "NY",
    areacode: 631,
    county: "Suffolk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12007",
    city: "Alcove",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12008",
    city: "Alplaus",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12009",
    city: "Altamont",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12010",
    city: "Amsterdam",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12015",
    city: "Athens",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12016",
    city: "Auriesville",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12017",
    city: "Austerlitz",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12018",
    city: "Averill Park",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12019",
    city: "Ballston Lake",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12020",
    city: "Ballston Spa",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12022",
    city: "Berlin",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12023",
    city: "Berne",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12024",
    city: "Brainard",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12025",
    city: "Broadalbin",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12027",
    city: "Burnt Hills",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12028",
    city: "Buskirk",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12029",
    city: "Canaan",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12031",
    city: "Carlisle",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12032",
    city: "Caroga Lake",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12033",
    city: "Castleton on Hudson",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12035",
    city: "Central Bridge",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12036",
    city: "Charlotteville",
    state: "NY",
    areacode: 607,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12037",
    city: "Chatham",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12040",
    city: "Cherry Plain",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12041",
    city: "Clarksville",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12042",
    city: "Climax",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12043",
    city: "Cobleskill",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12045",
    city: "Coeymans",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12046",
    city: "Coeymans Hollow",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12047",
    city: "Cohoes",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12050",
    city: "Columbiaville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12051",
    city: "Coxsackie",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12052",
    city: "Cropseyville",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12053",
    city: "Delanson",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12054",
    city: "Delmar",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12055",
    city: "Dormansville",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12056",
    city: "Duanesburg",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12057",
    city: "Eagle Bridge",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12058",
    city: "Earlton",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12059",
    city: "East Berne",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12060",
    city: "East Chatham",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12061",
    city: "East Greenbush",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12062",
    city: "East Nassau",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12063",
    city: "East Schodack",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12064",
    city: "East Worcester",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12065",
    city: "Clifton Park",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12066",
    city: "Esperance",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12067",
    city: "Feura Bush",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12068",
    city: "Fonda",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12069",
    city: "Fort Hunter",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12070",
    city: "Fort Johnson",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12071",
    city: "Fultonham",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12072",
    city: "Fultonville",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12073",
    city: "Gallupville",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12074",
    city: "Galway",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12075",
    city: "Ghent",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12076",
    city: "Gilboa",
    state: "NY",
    areacode: 607,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12077",
    city: "Glenmont",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12078",
    city: "Gloversville",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12082",
    city: "Grafton",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12083",
    city: "Greenville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12084",
    city: "Guilderland",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12085",
    city: "Guilderland Center",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12086",
    city: "Hagaman",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12087",
    city: "Hannacroix",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12089",
    city: "Hoosick",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12090",
    city: "Hoosick Falls",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12092",
    city: "Howes Cave",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12093",
    city: "Jefferson",
    state: "NY",
    areacode: 607,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12094",
    city: "Johnsonville",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12095",
    city: "Johnstown",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12106",
    city: "Kinderhook",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12107",
    city: "Knox",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12108",
    city: "Lake Pleasant",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12110",
    city: "Latham",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12115",
    city: "Malden Bridge",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12116",
    city: "Maryland",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12117",
    city: "Mayfield",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12118",
    city: "Mechanicville",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12120",
    city: "Medusa",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12121",
    city: "Melrose",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12122",
    city: "Middleburgh",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12123",
    city: "Nassau",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12124",
    city: "New Baltimore",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12125",
    city: "New Lebanon",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12128",
    city: "Newtonville",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12130",
    city: "Niverville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12131",
    city: "North Blenheim",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12132",
    city: "North Chatham",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12133",
    city: "North Hoosick",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12134",
    city: "Northville",
    state: "NY",
    areacode: 518,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12136",
    city: "Old Chatham",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12137",
    city: "Pattersonville",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12138",
    city: "Petersburg",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12139",
    city: "Piseco",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12140",
    city: "Poestenkill",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12141",
    city: "Quaker Street",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12143",
    city: "Ravena",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12144",
    city: "Rensselaer",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12147",
    city: "Rensselaerville",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12148",
    city: "Rexford",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12149",
    city: "Richmondville",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12150",
    city: "Rotterdam Junction",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12151",
    city: "Round Lake",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12153",
    city: "Sand Lake",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12154",
    city: "Schaghticoke",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12155",
    city: "Schenevus",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12156",
    city: "Schodack Landing",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12157",
    city: "Schoharie",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12158",
    city: "Selkirk",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12159",
    city: "Slingerlands",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12160",
    city: "Sloansville",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12161",
    city: "South Bethlehem",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12164",
    city: "Speculator",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12165",
    city: "Spencertown",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12166",
    city: "Sprakers",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12167",
    city: "Stamford",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12168",
    city: "Stephentown",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12169",
    city: "Stephentown",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12170",
    city: "Stillwater",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12172",
    city: "Stottville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12173",
    city: "Stuyvesant",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12174",
    city: "Stuyvesant Falls",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12175",
    city: "Summit",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12176",
    city: "Surprise",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12177",
    city: "Tribes Hill",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12180",
    city: "Troy",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12181",
    city: "Troy",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12182",
    city: "Troy",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12183",
    city: "Troy",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12184",
    city: "Valatie",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12185",
    city: "Valley Falls",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12186",
    city: "Voorheesville",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12187",
    city: "Warnerville",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12188",
    city: "Waterford",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12189",
    city: "Watervliet",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12190",
    city: "Wells",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12192",
    city: "West Coxsackie",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12193",
    city: "Westerlo",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12194",
    city: "West Fulton",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12195",
    city: "West Lebanon",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12196",
    city: "West Sand Lake",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12197",
    city: "Worcester",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12198",
    city: "Wynantskill",
    state: "NY",
    areacode: 518,
    county: "Rensselaer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12201",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12202",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12203",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12204",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12205",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12206",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12207",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12208",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12209",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12210",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12211",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12212",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12214",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12220",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12222",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12223",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12224",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12225",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12226",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12227",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12228",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12229",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12230",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12231",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12232",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12233",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12234",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12235",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12236",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12237",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12238",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12239",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12240",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12241",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12242",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12243",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12244",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12245",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12246",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12247",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12248",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12249",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12250",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12255",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12257",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12260",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12261",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12288",
    city: "Albany",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12301",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12302",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12303",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12304",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12305",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12306",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12307",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12308",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12309",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12325",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12345",
    city: "Schenectady",
    state: "NY",
    areacode: 518,
    county: "Schenectady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12401",
    city: "Kingston",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12402",
    city: "Kingston",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12404",
    city: "Accord",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12405",
    city: "Acra",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12406",
    city: "Arkville",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12407",
    city: "Ashland",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12409",
    city: "Bearsville",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12410",
    city: "Big Indian",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12411",
    city: "Bloomington",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12412",
    city: "Boiceville",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12413",
    city: "Cairo",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12414",
    city: "Catskill",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12416",
    city: "Chichester",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12417",
    city: "Connelly",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12418",
    city: "Cornwallville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12419",
    city: "Cottekill",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12420",
    city: "Cragsmoor",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12421",
    city: "Denver",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12422",
    city: "Durham",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12423",
    city: "East Durham",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12424",
    city: "East Jewett",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12427",
    city: "Elka Park",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12428",
    city: "Ellenville",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12429",
    city: "Esopus",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12430",
    city: "Fleischmanns",
    state: "NY",
    areacode: 845,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12431",
    city: "Freehold",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12432",
    city: "Glasco",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12433",
    city: "Glenford",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12434",
    city: "Grand Gorge",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12435",
    city: "Greenfield Park",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12436",
    city: "Haines Falls",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12438",
    city: "Halcottsville",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12439",
    city: "Hensonville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12440",
    city: "High Falls",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12441",
    city: "Highmount",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12442",
    city: "Hunter",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12443",
    city: "Hurley",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12444",
    city: "Jewett",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12446",
    city: "Kerhonkson",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12448",
    city: "Lake Hill",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12449",
    city: "Lake Katrine",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12450",
    city: "Lanesville",
    state: "NY",
    areacode: 845,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12451",
    city: "Leeds",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12452",
    city: "Lexington",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12453",
    city: "Malden on Hudson",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12454",
    city: "Maplecrest",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12455",
    city: "Margaretville",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12456",
    city: "Mount Marion",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12457",
    city: "Mount Tremper",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12458",
    city: "Napanoch",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12459",
    city: "New Kingston",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12460",
    city: "Oak Hill",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12461",
    city: "Olivebridge",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12463",
    city: "Palenville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12464",
    city: "Phoenicia",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12465",
    city: "Pine Hill",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12466",
    city: "Port Ewen",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12468",
    city: "Prattsville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12469",
    city: "Preston Hollow",
    state: "NY",
    areacode: 518,
    county: "Albany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12470",
    city: "Purling",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12471",
    city: "Rifton",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12472",
    city: "Rosendale",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12473",
    city: "Round Top",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12474",
    city: "Roxbury",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12475",
    city: "Ruby",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12477",
    city: "Saugerties",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12480",
    city: "Shandaken",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12481",
    city: "Shokan",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12482",
    city: "South Cairo",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12483",
    city: "Spring Glen",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12484",
    city: "Stone Ridge",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12485",
    city: "Tannersville",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12486",
    city: "Tillson",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12487",
    city: "Ulster Park",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12489",
    city: "Wawarsing",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12490",
    city: "West Camp",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12491",
    city: "West Hurley",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12492",
    city: "West Kill",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12493",
    city: "West Park",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12494",
    city: "West Shokan",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12495",
    city: "Willow",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12496",
    city: "Windham",
    state: "NY",
    areacode: 518,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12498",
    city: "Woodstock",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12501",
    city: "Amenia",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12502",
    city: "Ancram",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12503",
    city: "Ancramdale",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12504",
    city: "Annandale on Hudson",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12506",
    city: "Bangall",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12507",
    city: "Barrytown",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12508",
    city: "Beacon",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12510",
    city: "Billings",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12511",
    city: "Castle Point",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12512",
    city: "Chelsea",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12513",
    city: "Claverack",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12514",
    city: "Clinton Corners",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12515",
    city: "Clintondale",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12516",
    city: "Copake",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12517",
    city: "Copake Falls",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12518",
    city: "Cornwall",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12520",
    city: "Cornwall on Hudson",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12521",
    city: "Craryville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12522",
    city: "Dover Plains",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12523",
    city: "Elizaville",
    state: "NY",
    areacode: 845,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12524",
    city: "Fishkill",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12525",
    city: "Gardiner",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12526",
    city: "Germantown",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12527",
    city: "Glenham",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12528",
    city: "Highland",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12529",
    city: "Hillsdale",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12530",
    city: "Hollowville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12531",
    city: "Holmes",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12533",
    city: "Hopewell Junction",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12534",
    city: "Hudson",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12537",
    city: "Hughsonville",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12538",
    city: "Hyde Park",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12540",
    city: "Lagrangeville",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12541",
    city: "Livingston",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12542",
    city: "Marlboro",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12543",
    city: "Maybrook",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12544",
    city: "Mellenville",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12545",
    city: "Millbrook",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12546",
    city: "Millerton",
    state: "NY",
    areacode: 518,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12547",
    city: "Milton",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12548",
    city: "Modena",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12549",
    city: "Montgomery",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12550",
    city: "Newburgh",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12551",
    city: "Newburgh",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12552",
    city: "Newburgh",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12553",
    city: "New Windsor",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12555",
    city: "Newburgh",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12561",
    city: "New Paltz",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12563",
    city: "Patterson",
    state: "NY",
    areacode: 845,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12564",
    city: "Pawling",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12565",
    city: "Philmont",
    state: "NY",
    areacode: 518,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12566",
    city: "Pine Bush",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12567",
    city: "Pine Plains",
    state: "NY",
    areacode: 518,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12568",
    city: "Plattekill",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12569",
    city: "Pleasant Valley",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12570",
    city: "Poughquag",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12571",
    city: "Red Hook",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12572",
    city: "Rhinebeck",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12574",
    city: "Rhinecliff",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12575",
    city: "Rock Tavern",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12577",
    city: "Salisbury Mills",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12578",
    city: "Salt Point",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12580",
    city: "Staatsburg",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12581",
    city: "Stanfordville",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12582",
    city: "Stormville",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12583",
    city: "Tivoli",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12584",
    city: "Vails Gate",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12585",
    city: "Verbank",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12586",
    city: "Walden",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12588",
    city: "Walker Valley",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12589",
    city: "Wallkill",
    state: "NY",
    areacode: 845,
    county: "Ulster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12590",
    city: "Wappingers Falls",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12592",
    city: "Wassaic",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12594",
    city: "Wingdale",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12601",
    city: "Poughkeepsie",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12602",
    city: "Poughkeepsie",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12603",
    city: "Poughkeepsie",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12604",
    city: "Poughkeepsie",
    state: "NY",
    areacode: 845,
    county: "Dutchess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12701",
    city: "Monticello",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12719",
    city: "Barryville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12720",
    city: "Bethel",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12721",
    city: "Bloomingburg",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12722",
    city: "Burlingham",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12723",
    city: "Callicoon",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12724",
    city: "Callicoon Center",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12725",
    city: "Claryville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12726",
    city: "Cochecton",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12727",
    city: "Cochecton",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12729",
    city: "Cuddebackville",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12732",
    city: "Eldred",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12733",
    city: "Fallsburg",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12734",
    city: "Ferndale",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12736",
    city: "Fremont Center",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12737",
    city: "Glen Spey",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12738",
    city: "Glen Wild",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12740",
    city: "Grahamsville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12741",
    city: "Hankins",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12742",
    city: "Harris",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12743",
    city: "Highland Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12745",
    city: "Hortonville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12746",
    city: "Huguenot",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12747",
    city: "Hurleyville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12748",
    city: "Jeffersonville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12749",
    city: "Kauneonga Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12750",
    city: "Kenoza Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12751",
    city: "Kiamesha Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12752",
    city: "Lake Huntington",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12754",
    city: "Liberty",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12758",
    city: "Livingston Manor",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12759",
    city: "Loch Sheldrake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12760",
    city: "Long Eddy",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12762",
    city: "Mongaup Valley",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12763",
    city: "Mountain Dale",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12764",
    city: "Narrowsburg",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12765",
    city: "Neversink",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12766",
    city: "North Branch",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12767",
    city: "Obernburg",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12768",
    city: "Parksville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12769",
    city: "Phillipsport",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12770",
    city: "Pond Eddy",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12771",
    city: "Port Jervis",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12775",
    city: "Rock Hill",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12776",
    city: "Roscoe",
    state: "NY",
    areacode: 607,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12777",
    city: "Forestburgh",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12778",
    city: "Smallwood",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12779",
    city: "South Fallsburg",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12780",
    city: "Sparrow Bush",
    state: "NY",
    areacode: 845,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12781",
    city: "Summitville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12783",
    city: "Swan Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12784",
    city: "Thompsonville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12785",
    city: "Westbrookville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12786",
    city: "White Lake",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12787",
    city: "White Sulphur Springs",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12788",
    city: "Woodbourne",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12789",
    city: "Woodridge",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12790",
    city: "Wurtsboro",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12791",
    city: "Youngsville",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12792",
    city: "Yulan",
    state: "NY",
    areacode: 845,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12801",
    city: "Glens Falls",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12803",
    city: "South Glens Falls",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12804",
    city: "Queensbury",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12808",
    city: "Adirondack",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12809",
    city: "Argyle",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12810",
    city: "Athol",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12811",
    city: "Bakers Mills",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12812",
    city: "Blue Mountain Lake",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12814",
    city: "Bolton Landing",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12815",
    city: "Brant Lake",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12816",
    city: "Cambridge",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12817",
    city: "Chestertown",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12819",
    city: "Clemons",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12820",
    city: "Cleverdale",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12821",
    city: "Comstock",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12822",
    city: "Corinth",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12823",
    city: "Cossayuna",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12824",
    city: "Diamond Point",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12827",
    city: "Fort Ann",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12828",
    city: "Fort Edward",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12831",
    city: "Gansevoort",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12832",
    city: "Granville",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12833",
    city: "Greenfield Center",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12834",
    city: "Greenwich",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12835",
    city: "Hadley",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12836",
    city: "Hague",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12837",
    city: "Hampton",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12838",
    city: "Hartford",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12839",
    city: "Hudson Falls",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12841",
    city: "Huletts Landing",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12842",
    city: "Indian Lake",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12843",
    city: "Johnsburg",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12844",
    city: "Kattskill Bay",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12845",
    city: "Lake George",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12846",
    city: "Lake Luzerne",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12847",
    city: "Long Lake",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12848",
    city: "Middle Falls",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12849",
    city: "Middle Granville",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12850",
    city: "Middle Grove",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12851",
    city: "Minerva",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12852",
    city: "Newcomb",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12853",
    city: "North Creek",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12854",
    city: "North Granville",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12855",
    city: "North Hudson",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12856",
    city: "North River",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12857",
    city: "Olmstedville",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12858",
    city: "Paradox",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12859",
    city: "Porter Corners",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12860",
    city: "Pottersville",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12861",
    city: "Putnam Station",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12862",
    city: "Riparius",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12863",
    city: "Rock City Falls",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12864",
    city: "Sabael",
    state: "NY",
    areacode: 518,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12865",
    city: "Salem",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12866",
    city: "Saratoga Springs",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12870",
    city: "Schroon Lake",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12871",
    city: "Schuylerville",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12872",
    city: "Severance",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12873",
    city: "Shushan",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12874",
    city: "Silver Bay",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12878",
    city: "Stony Creek",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12879",
    city: "Newcomb",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12883",
    city: "Ticonderoga",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12884",
    city: "Victory Mills",
    state: "NY",
    areacode: 518,
    county: "Saratoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12885",
    city: "Warrensburg",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12886",
    city: "Wevertown",
    state: "NY",
    areacode: 518,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12887",
    city: "Whitehall",
    state: "NY",
    areacode: 518,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12901",
    city: "Plattsburgh",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12903",
    city: "Plattsburgh",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12910",
    city: "Altona",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12911",
    city: "Keeseville",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12912",
    city: "Au Sable Forks",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12913",
    city: "Bloomingdale",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12914",
    city: "Bombay",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12915",
    city: "Brainardsville",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12916",
    city: "Brushton",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12917",
    city: "Burke",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12918",
    city: "Cadyville",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12919",
    city: "Champlain",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12920",
    city: "Chateaugay",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12921",
    city: "Chazy",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12922",
    city: "Childwold",
    state: "NY",
    areacode: 518,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12923",
    city: "Churubusco",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12924",
    city: "Keeseville",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12926",
    city: "Constable",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12927",
    city: "Cranberry Lake",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12928",
    city: "Crown Point",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12929",
    city: "Dannemora",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12930",
    city: "Dickinson Center",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12932",
    city: "Elizabethtown",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12933",
    city: "Ellenburg",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12934",
    city: "Ellenburg Center",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12935",
    city: "Ellenburg Depot",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12936",
    city: "Essex",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12937",
    city: "Fort Covington",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12939",
    city: "Gabriels",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12941",
    city: "Jay",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12942",
    city: "Keene",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12943",
    city: "Keene Valley",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12944",
    city: "Keeseville",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12945",
    city: "Lake Clear",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12946",
    city: "Lake Placid",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12949",
    city: "Lawrenceville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12950",
    city: "Lewis",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12952",
    city: "Lyon Mountain",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12953",
    city: "Malone",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12955",
    city: "Lyon Mountain",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12956",
    city: "Mineville",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12957",
    city: "Moira",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12958",
    city: "Mooers",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12959",
    city: "Mooers Forks",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12960",
    city: "Moriah",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12961",
    city: "Moriah Center",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12962",
    city: "Morrisonville",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12964",
    city: "New Russia",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12965",
    city: "Nicholville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12966",
    city: "North Bangor",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12967",
    city: "North Lawrence",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12969",
    city: "Owls Head",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12970",
    city: "Paul Smiths",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12972",
    city: "Peru",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12973",
    city: "Piercefield",
    state: "NY",
    areacode: 518,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12974",
    city: "Port Henry",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12975",
    city: "Port Kent",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12976",
    city: "Rainbow Lake",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12977",
    city: "Ray Brook",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12978",
    city: "Redford",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12979",
    city: "Rouses Point",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12980",
    city: "Saint Regis Falls",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12981",
    city: "Saranac",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12983",
    city: "Saranac Lake",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12985",
    city: "Schuyler Falls",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12986",
    city: "Tupper Lake",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12987",
    city: "Upper Jay",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12989",
    city: "Vermontville",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12992",
    city: "West Chazy",
    state: "NY",
    areacode: 518,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12993",
    city: "Westport",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12995",
    city: "Whippleville",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12996",
    city: "Willsboro",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12997",
    city: "Wilmington",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "12998",
    city: "Witherbee",
    state: "NY",
    areacode: 518,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13020",
    city: "Apulia Station",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13021",
    city: "Auburn",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13022",
    city: "Auburn",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13024",
    city: "Auburn",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13026",
    city: "Aurora",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13027",
    city: "Baldwinsville",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13028",
    city: "Bernhards Bay",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13029",
    city: "Brewerton",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13030",
    city: "Bridgeport",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13031",
    city: "Camillus",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13032",
    city: "Canastota",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13033",
    city: "Cato",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13034",
    city: "Cayuga",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13035",
    city: "Cazenovia",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13036",
    city: "Central Square",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13037",
    city: "Chittenango",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13039",
    city: "Cicero",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13040",
    city: "Cincinnatus",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13041",
    city: "Clay",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13042",
    city: "Cleveland",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13043",
    city: "Clockville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13044",
    city: "Constantia",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13045",
    city: "Cortland",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13051",
    city: "Delphi Falls",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13052",
    city: "De Ruyter",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13053",
    city: "Dryden",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13054",
    city: "Durhamville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13056",
    city: "East Homer",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13057",
    city: "East Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13060",
    city: "Elbridge",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13061",
    city: "Erieville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13062",
    city: "Etna",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13063",
    city: "Fabius",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13064",
    city: "Fair Haven",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13065",
    city: "Fayette",
    state: "NY",
    areacode: 315,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13066",
    city: "Fayetteville",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13068",
    city: "Freeville",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13069",
    city: "Fulton",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13071",
    city: "Genoa",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13072",
    city: "Georgetown",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13073",
    city: "Groton",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13074",
    city: "Hannibal",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13076",
    city: "Hastings",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13077",
    city: "Homer",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13078",
    city: "Jamesville",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13080",
    city: "Jordan",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13081",
    city: "King Ferry",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13082",
    city: "Kirkville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13083",
    city: "Lacona",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13084",
    city: "La Fayette",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13087",
    city: "Little York",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13088",
    city: "Liverpool",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13089",
    city: "Liverpool",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13090",
    city: "Liverpool",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13092",
    city: "Locke",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13093",
    city: "Lycoming",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13101",
    city: "McGraw",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13102",
    city: "McLean",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13103",
    city: "Mallory",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13104",
    city: "Manlius",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13107",
    city: "Maple View",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13108",
    city: "Marcellus",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13110",
    city: "Marietta",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13111",
    city: "Martville",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13112",
    city: "Memphis",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13113",
    city: "Meridian",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13114",
    city: "Mexico",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13115",
    city: "Minetto",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13116",
    city: "Minoa",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13117",
    city: "Montezuma",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13118",
    city: "Moravia",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13119",
    city: "Mottville",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13120",
    city: "Nedrow",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13121",
    city: "New Haven",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13122",
    city: "New Woodstock",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13123",
    city: "North Bay",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13124",
    city: "North Pitcher",
    state: "NY",
    areacode: 315,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13126",
    city: "Oswego",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13131",
    city: "Parish",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13132",
    city: "Pennellville",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13134",
    city: "Peterboro",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13135",
    city: "Phoenix",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13136",
    city: "Pitcher",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13137",
    city: "Plainville",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13138",
    city: "Pompey",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13139",
    city: "Poplar Ridge",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13140",
    city: "Port Byron",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13141",
    city: "Preble",
    state: "NY",
    areacode: 315,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13142",
    city: "Pulaski",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13143",
    city: "Red Creek",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13144",
    city: "Richland",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13145",
    city: "Sandy Creek",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13146",
    city: "Savannah",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13147",
    city: "Scipio Center",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13148",
    city: "Seneca Falls",
    state: "NY",
    areacode: 315,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13152",
    city: "Skaneateles",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13153",
    city: "Skaneateles Falls",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13154",
    city: "South Butler",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13155",
    city: "South Otselic",
    state: "NY",
    areacode: 315,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13156",
    city: "Sterling",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13157",
    city: "Sylvan Beach",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13158",
    city: "Truxton",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13159",
    city: "Tully",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13160",
    city: "Union Springs",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13162",
    city: "Verona Beach",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13163",
    city: "Wampsville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13164",
    city: "Warners",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13165",
    city: "Waterloo",
    state: "NY",
    areacode: 315,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13166",
    city: "Weedsport",
    state: "NY",
    areacode: 315,
    county: "Cayuga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13167",
    city: "West Monroe",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13201",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13202",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13203",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13204",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13205",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13206",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13207",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13208",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13209",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13210",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13211",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13212",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13214",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13215",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13217",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13218",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13219",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13220",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13221",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13224",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13225",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13235",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13244",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13250",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13251",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13252",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13261",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13290",
    city: "Syracuse",
    state: "NY",
    areacode: 315,
    county: "Onondaga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13301",
    city: "Alder Creek",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13302",
    city: "Altmar",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13303",
    city: "Ava",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13304",
    city: "Barneveld",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13305",
    city: "Beaver Falls",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13308",
    city: "Blossvale",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13309",
    city: "Boonville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13310",
    city: "Bouckville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13312",
    city: "Brantingham",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13313",
    city: "Bridgewater",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13314",
    city: "Brookfield",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13315",
    city: "Burlington Flats",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13316",
    city: "Camden",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13317",
    city: "Canajoharie",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13318",
    city: "Cassville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13319",
    city: "Chadwicks",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13320",
    city: "Cherry Valley",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13321",
    city: "Clark Mills",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13322",
    city: "Clayville",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13323",
    city: "Clinton",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13324",
    city: "Cold Brook",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13325",
    city: "Constableville",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13326",
    city: "Cooperstown",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13327",
    city: "Croghan",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13328",
    city: "Deansboro",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13329",
    city: "Dolgeville",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13331",
    city: "Eagle Bay",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13332",
    city: "Earlville",
    state: "NY",
    areacode: 315,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13333",
    city: "East Springfield",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13334",
    city: "Eaton",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13335",
    city: "Edmeston",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13337",
    city: "Fly Creek",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13338",
    city: "Forestport",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13339",
    city: "Fort Plain",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13340",
    city: "Frankfort",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13341",
    city: "Franklin Springs",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13342",
    city: "Garrattsville",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13343",
    city: "Glenfield",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13345",
    city: "Greig",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13346",
    city: "Hamilton",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13348",
    city: "Hartwick",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13350",
    city: "Herkimer",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13352",
    city: "Hinckley",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13353",
    city: "Hoffmeister",
    state: "NY",
    areacode: 315,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13354",
    city: "Holland Patent",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13355",
    city: "Hubbardsville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13357",
    city: "Ilion",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13360",
    city: "Inlet",
    state: "NY",
    areacode: 315,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13361",
    city: "Jordanville",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13362",
    city: "Knoxboro",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13363",
    city: "Lee Center",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13364",
    city: "Leonardsville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13365",
    city: "Little Falls",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13367",
    city: "Lowville",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13368",
    city: "Lyons Falls",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13401",
    city: "McConnellsville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13402",
    city: "Madison",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13403",
    city: "Marcy",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13404",
    city: "Martinsburg",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13406",
    city: "Middleville",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13407",
    city: "Mohawk",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13408",
    city: "Morrisville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13409",
    city: "Munnsville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13410",
    city: "Nelliston",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13411",
    city: "New Berlin",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13413",
    city: "New Hartford",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13415",
    city: "New Lisbon",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13416",
    city: "Newport",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13417",
    city: "New York Mills",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13418",
    city: "North Brookfield",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13420",
    city: "Old Forge",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13421",
    city: "Oneida",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13424",
    city: "Oriskany",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13425",
    city: "Oriskany Falls",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13426",
    city: "Orwell",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13428",
    city: "Palatine Bridge",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13431",
    city: "Poland",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13433",
    city: "Port Leyden",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13435",
    city: "Prospect",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13436",
    city: "Raquette Lake",
    state: "NY",
    areacode: 315,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13437",
    city: "Redfield",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13438",
    city: "Remsen",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13439",
    city: "Richfield Springs",
    state: "NY",
    areacode: 315,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13440",
    city: "Rome",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13441",
    city: "Rome",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13442",
    city: "Rome",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13449",
    city: "Rome",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13450",
    city: "Roseboom",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13452",
    city: "Saint Johnsville",
    state: "NY",
    areacode: 518,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13454",
    city: "Salisbury Center",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13455",
    city: "Sangerfield",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13456",
    city: "Sauquoit",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13457",
    city: "Schuyler Lake",
    state: "NY",
    areacode: 315,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13459",
    city: "Sharon Springs",
    state: "NY",
    areacode: 518,
    county: "Schoharie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13460",
    city: "Sherburne",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13461",
    city: "Sherrill",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13464",
    city: "Smyrna",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13465",
    city: "Solsville",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13468",
    city: "Springfield Center",
    state: "NY",
    areacode: 315,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13469",
    city: "Stittville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13470",
    city: "Stratford",
    state: "NY",
    areacode: 315,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13471",
    city: "Taberg",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13472",
    city: "Thendara",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13473",
    city: "Turin",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13475",
    city: "Van Hornesville",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13476",
    city: "Vernon",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13477",
    city: "Vernon Center",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13478",
    city: "Verona",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13479",
    city: "Washington Mills",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13480",
    city: "Waterville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13482",
    city: "West Burlington",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13483",
    city: "Westdale",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13484",
    city: "West Eaton",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13485",
    city: "West Edmeston",
    state: "NY",
    areacode: 315,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13486",
    city: "Westernville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13488",
    city: "Westford",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13489",
    city: "West Leyden",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13490",
    city: "Westmoreland",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13491",
    city: "West Winfield",
    state: "NY",
    areacode: 315,
    county: "Herkimer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13492",
    city: "Whitesboro",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13493",
    city: "Williamstown",
    state: "NY",
    areacode: 315,
    county: "Oswego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13494",
    city: "Woodgate",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13495",
    city: "Yorkville",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13501",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13502",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13503",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13504",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13505",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13599",
    city: "Utica",
    state: "NY",
    areacode: 315,
    county: "Oneida",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13601",
    city: "Watertown",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13602",
    city: "Fort Drum",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13603",
    city: "Watertown",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13605",
    city: "Adams",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13606",
    city: "Adams Center",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13607",
    city: "Alexandria Bay",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13608",
    city: "Antwerp",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13611",
    city: "Belleville",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13612",
    city: "Black River",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13613",
    city: "Brasher Falls",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13614",
    city: "Brier Hill",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13615",
    city: "Brownville",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13616",
    city: "Calcium",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13617",
    city: "Canton",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13618",
    city: "Cape Vincent",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13619",
    city: "Carthage",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13620",
    city: "Castorland",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13621",
    city: "Chase Mills",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13622",
    city: "Chaumont",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13623",
    city: "Chippewa Bay",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13624",
    city: "Clayton",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13625",
    city: "Colton",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13626",
    city: "Copenhagen",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13627",
    city: "Deer River",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13628",
    city: "Deferiet",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13630",
    city: "DeKalb Junction",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13631",
    city: "Denmark",
    state: "NY",
    areacode: 315,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13632",
    city: "Depauville",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13633",
    city: "De Peyster",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13634",
    city: "Dexter",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13635",
    city: "Edwards",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13636",
    city: "Ellisburg",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13637",
    city: "Evans Mills",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13638",
    city: "Felts Mills",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13639",
    city: "Fine",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13640",
    city: "Wellesley Island",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13641",
    city: "Fishers Landing",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13642",
    city: "Gouverneur",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13643",
    city: "Great Bend",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13645",
    city: "Hailesboro",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13646",
    city: "Hammond",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13647",
    city: "Hannawa Falls",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13648",
    city: "Harrisville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13649",
    city: "Helena",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13650",
    city: "Henderson",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13651",
    city: "Henderson Harbor",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13652",
    city: "Hermon",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13654",
    city: "Heuvelton",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13655",
    city: "Hogansburg",
    state: "NY",
    areacode: 518,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13656",
    city: "La Fargeville",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13657",
    city: "Limerick",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13658",
    city: "Lisbon",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13659",
    city: "Lorraine",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13660",
    city: "Madrid",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13661",
    city: "Mannsville",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13662",
    city: "Massena",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13664",
    city: "Morristown",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13665",
    city: "Natural Bridge",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13666",
    city: "Newton Falls",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13667",
    city: "Norfolk",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13668",
    city: "Norwood",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13669",
    city: "Ogdensburg",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13670",
    city: "Oswegatchie",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13671",
    city: "Antwerp",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13672",
    city: "Parishville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13673",
    city: "Philadelphia",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13674",
    city: "Pierrepont Manor",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13675",
    city: "Plessis",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13676",
    city: "Potsdam",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13677",
    city: "Pyrites",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13678",
    city: "Raymondville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13679",
    city: "Redwood",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13680",
    city: "Rensselaer Falls",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13681",
    city: "Richville",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13682",
    city: "Rodman",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13683",
    city: "Rooseveltown",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13684",
    city: "Russell",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13685",
    city: "Sackets Harbor",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13687",
    city: "South Colton",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13690",
    city: "Star Lake",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13691",
    city: "Theresa",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13692",
    city: "Thousand Island Park",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13693",
    city: "Three Mile Bay",
    state: "NY",
    areacode: 315,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13694",
    city: "Waddington",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13695",
    city: "Wanakena",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13696",
    city: "West Stockholm",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13697",
    city: "Winthrop",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13699",
    city: "Potsdam",
    state: "NY",
    areacode: 315,
    county: "Saint Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13730",
    city: "Afton",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13731",
    city: "Andes",
    state: "NY",
    areacode: 845,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13732",
    city: "Apalachin",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13733",
    city: "Bainbridge",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13734",
    city: "Barton",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13736",
    city: "Berkshire",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13737",
    city: "Bible School Park",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13738",
    city: "Blodgett Mills",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13739",
    city: "Bloomville",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13740",
    city: "Bovina Center",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13743",
    city: "Candor",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13744",
    city: "Castle Creek",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13745",
    city: "Chenango Bridge",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13746",
    city: "Chenango Forks",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13747",
    city: "Colliersville",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13748",
    city: "Conklin",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13749",
    city: "Corbettsville",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13750",
    city: "Davenport",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13751",
    city: "Davenport Center",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13752",
    city: "Delancey",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13753",
    city: "Delhi",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13754",
    city: "Deposit",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13755",
    city: "Downsville",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13756",
    city: "East Branch",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13757",
    city: "East Meredith",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13758",
    city: "East Pharsalia",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13760",
    city: "Endicott",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13761",
    city: "Endicott",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13762",
    city: "Endwell",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13763",
    city: "Endicott",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13774",
    city: "Fishs Eddy",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13775",
    city: "Franklin",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13776",
    city: "Gilbertsville",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13777",
    city: "Glen Aubrey",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13778",
    city: "Greene",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13780",
    city: "Guilford",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13782",
    city: "Hamden",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13783",
    city: "Hancock",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13784",
    city: "Harford",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13786",
    city: "Harpersfield",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13787",
    city: "Harpursville",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13788",
    city: "Hobart",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13790",
    city: "Johnson City",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13794",
    city: "Killawog",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13795",
    city: "Kirkwood",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13796",
    city: "Laurens",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13797",
    city: "Lisle",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13801",
    city: "McDonough",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13802",
    city: "Maine",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13803",
    city: "Marathon",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13804",
    city: "Masonville",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13806",
    city: "Meridale",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13807",
    city: "Milford",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13808",
    city: "Morris",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13809",
    city: "Mount Upton",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13810",
    city: "Mount Vision",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13811",
    city: "Newark Valley",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13812",
    city: "Nichols",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13813",
    city: "Nineveh",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13814",
    city: "North Norwich",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13815",
    city: "Norwich",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13820",
    city: "Oneonta",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13825",
    city: "Otego",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13826",
    city: "Ouaquaga",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13827",
    city: "Owego",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13830",
    city: "Oxford",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13832",
    city: "Plymouth",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13833",
    city: "Port Crane",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13834",
    city: "Portlandville",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13835",
    city: "Richford",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13838",
    city: "Sidney",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13839",
    city: "Sidney Center",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13840",
    city: "Smithboro",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13841",
    city: "Smithville Flats",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13842",
    city: "South Kortright",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13843",
    city: "South New Berlin",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13844",
    city: "South Plymouth",
    state: "NY",
    areacode: 607,
    county: "Chenango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13845",
    city: "Tioga Center",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13846",
    city: "Treadwell",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13847",
    city: "Trout Creek",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13848",
    city: "Tunnel",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13849",
    city: "Unadilla",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13850",
    city: "Vestal",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13851",
    city: "Vestal",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13856",
    city: "Walton",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13859",
    city: "Wells Bridge",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13860",
    city: "West Davenport",
    state: "NY",
    areacode: 607,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13861",
    city: "West Oneonta",
    state: "NY",
    areacode: 607,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13862",
    city: "Whitney Point",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13863",
    city: "Willet",
    state: "NY",
    areacode: 607,
    county: "Cortland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13864",
    city: "Willseyville",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13865",
    city: "Windsor",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13901",
    city: "Binghamton",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13902",
    city: "Binghamton",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13903",
    city: "Binghamton",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13904",
    city: "Binghamton",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "13905",
    city: "Binghamton",
    state: "NY",
    areacode: 607,
    county: "Broome",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14001",
    city: "Akron",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14004",
    city: "Alden",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14005",
    city: "Alexander",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14006",
    city: "Angola",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14008",
    city: "Appleton",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14009",
    city: "Arcade",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14010",
    city: "Athol Springs",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14011",
    city: "Attica",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14012",
    city: "Barker",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14013",
    city: "Basom",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14020",
    city: "Batavia",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14021",
    city: "Batavia",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14024",
    city: "Bliss",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14025",
    city: "Boston",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14026",
    city: "Bowmansville",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14027",
    city: "Brant",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14028",
    city: "Burt",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14029",
    city: "Centerville",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14030",
    city: "Chaffee",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14031",
    city: "Clarence",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14032",
    city: "Clarence Center",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14033",
    city: "Colden",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14034",
    city: "Collins",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14035",
    city: "Collins Center",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14036",
    city: "Corfu",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14037",
    city: "Cowlesville",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14038",
    city: "Crittenden",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14039",
    city: "Dale",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14040",
    city: "Darien Center",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14041",
    city: "Dayton",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14042",
    city: "Delevan",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14043",
    city: "Depew",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14047",
    city: "Derby",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14048",
    city: "Dunkirk",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14051",
    city: "East Amherst",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14052",
    city: "East Aurora",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14054",
    city: "East Bethany",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14055",
    city: "East Concord",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14056",
    city: "East Pembroke",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14057",
    city: "Eden",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14058",
    city: "Elba",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14059",
    city: "Elma",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14060",
    city: "Farmersville Station",
    state: "NY",
    areacode: 716,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14061",
    city: "Farnham",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14062",
    city: "Forestville",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14063",
    city: "Fredonia",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14065",
    city: "Freedom",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14066",
    city: "Gainesville",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14067",
    city: "Gasport",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14068",
    city: "Getzville",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14069",
    city: "Glenwood",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14070",
    city: "Gowanda",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14072",
    city: "Grand Island",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14075",
    city: "Hamburg",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14080",
    city: "Holland",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14081",
    city: "Irving",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14082",
    city: "Java Center",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14083",
    city: "Java Village",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14085",
    city: "Lake View",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14086",
    city: "Lancaster",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14091",
    city: "Lawtons",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14092",
    city: "Lewiston",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14094",
    city: "Lockport",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14095",
    city: "Lockport",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14098",
    city: "Lyndonville",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14101",
    city: "Machias",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14102",
    city: "Marilla",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14103",
    city: "Medina",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14105",
    city: "Middleport",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14107",
    city: "Model City",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14108",
    city: "Newfane",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14109",
    city: "Niagara University",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14110",
    city: "North Boston",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14111",
    city: "North Collins",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14112",
    city: "North Evans",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14113",
    city: "North Java",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14120",
    city: "North Tonawanda",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14125",
    city: "Oakfield",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14126",
    city: "Olcott",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14127",
    city: "Orchard Park",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14129",
    city: "Perrysburg",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14130",
    city: "Pike",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14131",
    city: "Ransomville",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14132",
    city: "Sanborn",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14133",
    city: "Sandusky",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14134",
    city: "Sardinia",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14135",
    city: "Sheridan",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14136",
    city: "Silver Creek",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14138",
    city: "South Dayton",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14139",
    city: "South Wales",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14140",
    city: "Spring Brook",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14141",
    city: "Springville",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14143",
    city: "Stafford",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14144",
    city: "Stella Niagara",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14145",
    city: "Strykersville",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14150",
    city: "Tonawanda",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14151",
    city: "Tonawanda",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14166",
    city: "Van Buren Point",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14167",
    city: "Varysburg",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14168",
    city: "Versailles",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14169",
    city: "Wales Center",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14170",
    city: "West Falls",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14171",
    city: "West Valley",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14172",
    city: "Wilson",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14173",
    city: "Yorkshire",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14174",
    city: "Youngstown",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14201",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14202",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14203",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14204",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14205",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14206",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14207",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14208",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14209",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14210",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14211",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14212",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14213",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14214",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14215",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14216",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14217",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14218",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14219",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14220",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14221",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14222",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14223",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14224",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14225",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14226",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14227",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14228",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14231",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14233",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14240",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14241",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14260",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14261",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14263",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14264",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14265",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14267",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14269",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14270",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14272",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14273",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14276",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14280",
    city: "Buffalo",
    state: "NY",
    areacode: 716,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14301",
    city: "Niagara Falls",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14302",
    city: "Niagara Falls",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14303",
    city: "Niagara Falls",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14304",
    city: "Niagara Falls",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14305",
    city: "Niagara Falls",
    state: "NY",
    areacode: 716,
    county: "Niagara",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14410",
    city: "Adams Basin",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14411",
    city: "Albion",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14413",
    city: "Alton",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14414",
    city: "Avon",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14415",
    city: "Bellona",
    state: "NY",
    areacode: 585,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14416",
    city: "Bergen",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14418",
    city: "Branchport",
    state: "NY",
    areacode: 315,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14420",
    city: "Brockport",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14422",
    city: "Byron",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14423",
    city: "Caledonia",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14424",
    city: "Canandaigua",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14425",
    city: "Farmington",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14427",
    city: "Castile",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14428",
    city: "Churchville",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14429",
    city: "Clarendon",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14430",
    city: "Clarkson",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14432",
    city: "Clifton Springs",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14433",
    city: "Clyde",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14435",
    city: "Conesus",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14437",
    city: "Dansville",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14441",
    city: "Dresden",
    state: "NY",
    areacode: 315,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14443",
    city: "East Bloomfield",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14445",
    city: "East Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14449",
    city: "East Williamson",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14450",
    city: "Fairport",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14452",
    city: "Fancher",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14453",
    city: "Fishers",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14454",
    city: "Geneseo",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14456",
    city: "Geneva",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14461",
    city: "Gorham",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14462",
    city: "Groveland",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14463",
    city: "Hall",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14464",
    city: "Hamlin",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14466",
    city: "Hemlock",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14467",
    city: "Henrietta",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14468",
    city: "Hilton",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14469",
    city: "Bloomfield",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14470",
    city: "Holley",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14471",
    city: "Honeoye",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14472",
    city: "Honeoye Falls",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14475",
    city: "Ionia",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14476",
    city: "Kendall",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14477",
    city: "Kent",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14478",
    city: "Keuka Park",
    state: "NY",
    areacode: 315,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14479",
    city: "Knowlesville",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14480",
    city: "Lakeville",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14481",
    city: "Leicester",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14482",
    city: "Le Roy",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14485",
    city: "Lima",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14486",
    city: "Linwood",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14487",
    city: "Livonia",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14488",
    city: "Livonia Center",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14489",
    city: "Lyons",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14502",
    city: "Macedon",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14504",
    city: "Manchester",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14505",
    city: "Marion",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14506",
    city: "Mendon",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14507",
    city: "Middlesex",
    state: "NY",
    areacode: 585,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14508",
    city: "Morton",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14510",
    city: "Mount Morris",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14511",
    city: "Mumford",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14512",
    city: "Naples",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14513",
    city: "Newark",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14514",
    city: "North Chili",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14515",
    city: "North Greece",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14516",
    city: "North Rose",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14517",
    city: "Nunda",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14518",
    city: "Oaks Corners",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14519",
    city: "Ontario",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14520",
    city: "Ontario Center",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14521",
    city: "Ovid",
    state: "NY",
    areacode: 607,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14522",
    city: "Palmyra",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14525",
    city: "Pavilion",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14526",
    city: "Penfield",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14527",
    city: "Penn Yan",
    state: "NY",
    areacode: 315,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14529",
    city: "Perkinsville",
    state: "NY",
    areacode: 585,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14530",
    city: "Perry",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14532",
    city: "Phelps",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14533",
    city: "Piffard",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14534",
    city: "Pittsford",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14536",
    city: "Portageville",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14537",
    city: "Port Gibson",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14538",
    city: "Pultneyville",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14539",
    city: "Retsof",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14541",
    city: "Romulus",
    state: "NY",
    areacode: 315,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14542",
    city: "Rose",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14543",
    city: "Rush",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14544",
    city: "Rushville",
    state: "NY",
    areacode: 585,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14545",
    city: "Scottsburg",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14546",
    city: "Scottsville",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14547",
    city: "Seneca Castle",
    state: "NY",
    areacode: 315,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14548",
    city: "Shortsville",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14549",
    city: "Silver Lake",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14550",
    city: "Silver Springs",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14551",
    city: "Sodus",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14555",
    city: "Sodus Point",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14556",
    city: "Sonyea",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14557",
    city: "South Byron",
    state: "NY",
    areacode: 585,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14558",
    city: "South Lima",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14559",
    city: "Spencerport",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14560",
    city: "Springwater",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14561",
    city: "Stanley",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14563",
    city: "Union Hill",
    state: "NY",
    areacode: 716,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14564",
    city: "Victor",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14568",
    city: "Walworth",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14569",
    city: "Warsaw",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14571",
    city: "Waterport",
    state: "NY",
    areacode: 585,
    county: "Orleans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14572",
    city: "Wayland",
    state: "NY",
    areacode: 585,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14580",
    city: "Webster",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14585",
    city: "West Bloomfield",
    state: "NY",
    areacode: 585,
    county: "Ontario",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14586",
    city: "West Henrietta",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14588",
    city: "Willard",
    state: "NY",
    areacode: 607,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14589",
    city: "Williamson",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14590",
    city: "Wolcott",
    state: "NY",
    areacode: 315,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14591",
    city: "Wyoming",
    state: "NY",
    areacode: 585,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14592",
    city: "York",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14602",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14603",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14604",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14605",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14606",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14607",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14608",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14609",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14610",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14611",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14612",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14613",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14614",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14615",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14616",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14617",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14618",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14619",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14620",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14621",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14622",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14623",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14624",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14625",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14626",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14627",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14638",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14639",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14642",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14643",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14644",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14646",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14647",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14649",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14650",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14651",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14652",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14653",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14692",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14694",
    city: "Rochester",
    state: "NY",
    areacode: 585,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14701",
    city: "Jamestown",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14702",
    city: "Jamestown",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14706",
    city: "Allegany",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14707",
    city: "Allentown",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14708",
    city: "Alma",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14709",
    city: "Angelica",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14710",
    city: "Ashville",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14711",
    city: "Belfast",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14712",
    city: "Bemus Point",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14714",
    city: "Black Creek",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14715",
    city: "Bolivar",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14716",
    city: "Brocton",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14717",
    city: "Caneadea",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14718",
    city: "Cassadaga",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14719",
    city: "Cattaraugus",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14720",
    city: "Celoron",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14721",
    city: "Ceres",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14722",
    city: "Chautauqua",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14723",
    city: "Cherry Creek",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14724",
    city: "Clymer",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14726",
    city: "Conewango Valley",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14727",
    city: "Cuba",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14728",
    city: "Dewittville",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14729",
    city: "East Otto",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14730",
    city: "East Randolph",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14731",
    city: "Ellicottville",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14732",
    city: "Ellington",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14733",
    city: "Falconer",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14735",
    city: "Fillmore",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14736",
    city: "Findley Lake",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14737",
    city: "Franklinville",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14738",
    city: "Frewsburg",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14739",
    city: "Friendship",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14740",
    city: "Gerry",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14741",
    city: "Great Valley",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14742",
    city: "Greenhurst",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14743",
    city: "Hinsdale",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14744",
    city: "Houghton",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14745",
    city: "Hume",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14747",
    city: "Kennedy",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14748",
    city: "Kill Buck",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14750",
    city: "Lakewood",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14751",
    city: "Leon",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14752",
    city: "Lily Dale",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14753",
    city: "Limestone",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14754",
    city: "Little Genesee",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14755",
    city: "Little Valley",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14756",
    city: "Maple Springs",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14757",
    city: "Mayville",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14758",
    city: "Niobe",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14760",
    city: "Olean",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14766",
    city: "Otto",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14767",
    city: "Panama",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14769",
    city: "Portland",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14770",
    city: "Portville",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14772",
    city: "Randolph",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14774",
    city: "Richburg",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14775",
    city: "Ripley",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14777",
    city: "Rushford",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14778",
    city: "Saint Bonaventure",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14779",
    city: "Salamanca",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14781",
    city: "Sherman",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14782",
    city: "Sinclairville",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14783",
    city: "Steamburg",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14784",
    city: "Stockton",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14785",
    city: "Stow",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14786",
    city: "West Clarksville",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14787",
    city: "Westfield",
    state: "NY",
    areacode: 716,
    county: "Chautauqua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14788",
    city: "Westons Mills",
    state: "NY",
    areacode: 716,
    county: "Cattaraugus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14801",
    city: "Addison",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14802",
    city: "Alfred",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14803",
    city: "Alfred Station",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14804",
    city: "Almond",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14805",
    city: "Alpine",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14806",
    city: "Andover",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14807",
    city: "Arkport",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14808",
    city: "Atlanta",
    state: "NY",
    areacode: 585,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14809",
    city: "Avoca",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14810",
    city: "Bath",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14812",
    city: "Beaver Dams",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14813",
    city: "Belmont",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14814",
    city: "Big Flats",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14815",
    city: "Bradford",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14816",
    city: "Breesport",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14817",
    city: "Brooktondale",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14818",
    city: "Burdett",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14819",
    city: "Cameron",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14820",
    city: "Cameron Mills",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14821",
    city: "Campbell",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14822",
    city: "Canaseraga",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14823",
    city: "Canisteo",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14824",
    city: "Cayuta",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14825",
    city: "Chemung",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14826",
    city: "Cohocton",
    state: "NY",
    areacode: 585,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14827",
    city: "Coopers Plains",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14830",
    city: "Corning",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14831",
    city: "Corning",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14836",
    city: "Dalton",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14837",
    city: "Dundee",
    state: "NY",
    areacode: 607,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14838",
    city: "Erin",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14839",
    city: "Greenwood",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14840",
    city: "Hammondsport",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14841",
    city: "Hector",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14842",
    city: "Himrod",
    state: "NY",
    areacode: 607,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14843",
    city: "Hornell",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14845",
    city: "Horseheads",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14846",
    city: "Hunt",
    state: "NY",
    areacode: 585,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14847",
    city: "Interlaken",
    state: "NY",
    areacode: 607,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14850",
    city: "Ithaca",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14851",
    city: "Ithaca",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14852",
    city: "Ithaca",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14853",
    city: "Ithaca",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14854",
    city: "Jacksonville",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14855",
    city: "Jasper",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14856",
    city: "Kanona",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14857",
    city: "Lakemont",
    state: "NY",
    areacode: 607,
    county: "Yates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14858",
    city: "Lindley",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14859",
    city: "Lockwood",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14860",
    city: "Lodi",
    state: "NY",
    areacode: 607,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14861",
    city: "Lowman",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14863",
    city: "Mecklenburg",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14864",
    city: "Millport",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14865",
    city: "Montour Falls",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14867",
    city: "Newfield",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14869",
    city: "Odessa",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14870",
    city: "Painted Post",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14871",
    city: "Pine City",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14872",
    city: "Pine Valley",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14873",
    city: "Prattsburgh",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14874",
    city: "Pulteney",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14876",
    city: "Reading Center",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14877",
    city: "Rexville",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14878",
    city: "Rock Stream",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14879",
    city: "Savona",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14880",
    city: "Scio",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14881",
    city: "Slaterville Springs",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14882",
    city: "Lansing",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14883",
    city: "Spencer",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14884",
    city: "Swain",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14885",
    city: "Troupsburg",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14886",
    city: "Trumansburg",
    state: "NY",
    areacode: 607,
    county: "Tompkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14887",
    city: "Tyrone",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14889",
    city: "Van Etten",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14891",
    city: "Watkins Glen",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14892",
    city: "Waverly",
    state: "NY",
    areacode: 607,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14893",
    city: "Wayne",
    state: "NY",
    areacode: 607,
    county: "Schuyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14894",
    city: "Wellsburg",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14895",
    city: "Wellsville",
    state: "NY",
    areacode: 585,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14897",
    city: "Whitesville",
    state: "NY",
    areacode: 607,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14898",
    city: "Woodhull",
    state: "NY",
    areacode: 607,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14901",
    city: "Elmira",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14902",
    city: "Elmira",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14903",
    city: "Elmira",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14904",
    city: "Elmira",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "14905",
    city: "Elmira",
    state: "NY",
    areacode: 607,
    county: "Chemung",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15001",
    city: "Aliquippa",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15003",
    city: "Ambridge",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15004",
    city: "Atlasburg",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15005",
    city: "Baden",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15006",
    city: "Bairdford",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15007",
    city: "Bakerstown",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15009",
    city: "Beaver",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15010",
    city: "Beaver Falls",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15012",
    city: "Belle Vernon",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15014",
    city: "Brackenridge",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15015",
    city: "Bradfordwoods",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15017",
    city: "Bridgeville",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15018",
    city: "Buena Vista",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15019",
    city: "Bulger",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15020",
    city: "Bunola",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15021",
    city: "Burgettstown",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15022",
    city: "Charleroi",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15024",
    city: "Cheswick",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15025",
    city: "Clairton",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15026",
    city: "Clinton",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15027",
    city: "Conway",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15028",
    city: "Coulters",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15030",
    city: "Creighton",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15031",
    city: "Cuddy",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15032",
    city: "Curtisville",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15033",
    city: "Donora",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15034",
    city: "Dravosburg",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15035",
    city: "East McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15037",
    city: "Elizabeth",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15038",
    city: "Elrama",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15042",
    city: "Freedom",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15043",
    city: "Georgetown",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15044",
    city: "Gibsonia",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15045",
    city: "Glassport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15046",
    city: "Crescent",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15047",
    city: "Greenock",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15049",
    city: "Harwick",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15050",
    city: "Hookstown",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15051",
    city: "Indianola",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15052",
    city: "Industry",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15053",
    city: "Joffre",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15054",
    city: "Langeloth",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15055",
    city: "Lawrence",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15056",
    city: "Leetsdale",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15057",
    city: "McDonald",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15059",
    city: "Midland",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15060",
    city: "Midway",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15061",
    city: "Monaca",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15062",
    city: "Monessen",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15063",
    city: "Monongahela",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15064",
    city: "Morgan",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15065",
    city: "Natrona Heights",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15066",
    city: "New Brighton",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15067",
    city: "New Eagle",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15068",
    city: "New Kensington",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15069",
    city: "New Kensington",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15071",
    city: "Oakdale",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15072",
    city: "Pricedale",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15074",
    city: "Rochester",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15075",
    city: "Rural Ridge",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15076",
    city: "Russellton",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15077",
    city: "Shippingport",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15078",
    city: "Slovan",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15081",
    city: "South Heights",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15082",
    city: "Sturgeon",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15083",
    city: "Sutersville",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15084",
    city: "Tarentum",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15085",
    city: "Trafford",
    state: "PA",
    areacode: 412,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15086",
    city: "Warrendale",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15087",
    city: "Webster",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15088",
    city: "West Elizabeth",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15089",
    city: "West Newton",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15090",
    city: "Wexford",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15091",
    city: "Wildwood",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15095",
    city: "Warrendale",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15096",
    city: "Warrendale",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15101",
    city: "Allison Park",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15102",
    city: "Bethel Park",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15104",
    city: "Braddock",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15106",
    city: "Carnegie",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15108",
    city: "Coraopolis",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15110",
    city: "Duquesne",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15112",
    city: "East Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15116",
    city: "Glenshaw",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15120",
    city: "Homestead",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15122",
    city: "West Mifflin",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15123",
    city: "West Mifflin",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15126",
    city: "Imperial",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15127",
    city: "Ingomar",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15129",
    city: "South Park",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15131",
    city: "McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15132",
    city: "McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15133",
    city: "McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15134",
    city: "McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15135",
    city: "McKeesport",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15136",
    city: "McKees Rocks",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15137",
    city: "North Versailles",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15139",
    city: "Oakmont",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15140",
    city: "Pitcairn",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15142",
    city: "Presto",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15143",
    city: "Sewickley",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15144",
    city: "Springdale",
    state: "PA",
    areacode: 724,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15145",
    city: "Turtle Creek",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15146",
    city: "Monroeville",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15147",
    city: "Verona",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15148",
    city: "Wilmerding",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15201",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15202",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15203",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15204",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15205",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15206",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15207",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15208",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15209",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15210",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15211",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15212",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15213",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15214",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15215",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15216",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15217",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15218",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15219",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15220",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15221",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15222",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15223",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15224",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15225",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15226",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15227",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15228",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15229",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15230",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15231",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15232",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15233",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15234",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15235",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15236",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15237",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15238",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15239",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15240",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15241",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15242",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15243",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15244",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15250",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15251",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15252",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15253",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15254",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15255",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15257",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15258",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15259",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15260",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15261",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15262",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15264",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15265",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15267",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15268",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15270",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15272",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15274",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15275",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15276",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15277",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15278",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15279",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15281",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15282",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15283",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15286",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15289",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15290",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15295",
    city: "Pittsburgh",
    state: "PA",
    areacode: 412,
    county: "Allegheny",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15301",
    city: "Washington",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15310",
    city: "Aleppo",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15311",
    city: "Amity",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15312",
    city: "Avella",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15313",
    city: "Beallsville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15314",
    city: "Bentleyville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15315",
    city: "Bobtown",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15316",
    city: "Brave",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15317",
    city: "Canonsburg",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15320",
    city: "Carmichaels",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15321",
    city: "Cecil",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15322",
    city: "Clarksville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15323",
    city: "Claysville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15324",
    city: "Cokeburg",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15325",
    city: "Crucible",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15327",
    city: "Dilliner",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15329",
    city: "Prosperity",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15330",
    city: "Eighty Four",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15331",
    city: "Ellsworth",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15332",
    city: "Finleyville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15333",
    city: "Fredericktown",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15334",
    city: "Garards Fort",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15336",
    city: "Gastonville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15337",
    city: "Graysville",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15338",
    city: "Greensboro",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15339",
    city: "Hendersonville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15340",
    city: "Hickory",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15341",
    city: "Holbrook",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15342",
    city: "Houston",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15344",
    city: "Jefferson",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15345",
    city: "Marianna",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15346",
    city: "Mather",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15347",
    city: "Meadow Lands",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15348",
    city: "Millsboro",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15349",
    city: "Mount Morris",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15350",
    city: "Muse",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15351",
    city: "Nemacolin",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15352",
    city: "New Freeport",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15353",
    city: "Nineveh",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15357",
    city: "Rices Landing",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15358",
    city: "Richeyville",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15359",
    city: "Rogersville",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15360",
    city: "Scenery Hill",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15361",
    city: "Southview",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15362",
    city: "Spraggs",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15363",
    city: "Strabane",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15364",
    city: "Sycamore",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15365",
    city: "Taylorstown",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15366",
    city: "Van Voorhis",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15367",
    city: "Venetia",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15368",
    city: "Vestaburg",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15370",
    city: "Waynesburg",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15376",
    city: "West Alexander",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15377",
    city: "West Finley",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15378",
    city: "Westland",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15379",
    city: "West Middletown",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15380",
    city: "Wind Ridge",
    state: "PA",
    areacode: 724,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15401",
    city: "Uniontown",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15410",
    city: "Adah",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15411",
    city: "Addison",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15412",
    city: "Allenport",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15413",
    city: "Allison",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15415",
    city: "Brier Hill",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15416",
    city: "Brownfield",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15417",
    city: "Brownsville",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15419",
    city: "California",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15420",
    city: "Cardale",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15421",
    city: "Chalk Hill",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15422",
    city: "Chestnut Ridge",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15423",
    city: "Coal Center",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15424",
    city: "Confluence",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15425",
    city: "Connellsville",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15427",
    city: "Daisytown",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15428",
    city: "Dawson",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15429",
    city: "Denbo",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15430",
    city: "Dickerson Run",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15431",
    city: "Dunbar",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15432",
    city: "Dunlevy",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15433",
    city: "East Millsboro",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15434",
    city: "Elco",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15435",
    city: "Fairbank",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15436",
    city: "Fairchance",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15437",
    city: "Farmington",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15438",
    city: "Fayette City",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15439",
    city: "Gans",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15440",
    city: "Gibbon Glade",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15442",
    city: "Grindstone",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15443",
    city: "Hibbs",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15444",
    city: "Hiller",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15445",
    city: "Hopwood",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15446",
    city: "Indian Head",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15447",
    city: "Isabella",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15448",
    city: "Jacobs Creek",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15449",
    city: "Keisterville",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15450",
    city: "La Belle",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15451",
    city: "Lake Lynn",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15454",
    city: "Leckrone",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15455",
    city: "Leisenring",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15456",
    city: "Lemont Furnace",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15458",
    city: "McClellandtown",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15459",
    city: "Markleysburg",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15460",
    city: "Martin",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15461",
    city: "Masontown",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15462",
    city: "Melcroft",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15463",
    city: "Merrittstown",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15464",
    city: "Mill Run",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15465",
    city: "Mount Braddock",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15466",
    city: "Newell",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15467",
    city: "New Geneva",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15468",
    city: "New Salem",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15469",
    city: "Normalville",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15470",
    city: "Ohiopyle",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15472",
    city: "Oliver",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15473",
    city: "Perryopolis",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15474",
    city: "Point Marion",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15475",
    city: "Republic",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15476",
    city: "Ronco",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15477",
    city: "Roscoe",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15478",
    city: "Smithfield",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15479",
    city: "Smithton",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15480",
    city: "Smock",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15482",
    city: "Star Junction",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15483",
    city: "Stockdale",
    state: "PA",
    areacode: 724,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15484",
    city: "Uledi",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15485",
    city: "Ursina",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15486",
    city: "Vanderbilt",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15488",
    city: "Waltersburg",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15489",
    city: "West Leisenring",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15490",
    city: "White",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15492",
    city: "Wickhaven",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15501",
    city: "Somerset",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15502",
    city: "Hidden Valley",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15510",
    city: "Somerset",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15520",
    city: "Acosta",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15521",
    city: "Alum Bank",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15522",
    city: "Bedford",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15530",
    city: "Berlin",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15531",
    city: "Boswell",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15532",
    city: "Boynton",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15533",
    city: "Breezewood",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15534",
    city: "Buffalo Mills",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15535",
    city: "Clearville",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15536",
    city: "Crystal Spring",
    state: "PA",
    areacode: 814,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15537",
    city: "Everett",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15538",
    city: "Fairhope",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15539",
    city: "Fishertown",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15540",
    city: "Fort Hill",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15541",
    city: "Friedens",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15542",
    city: "Garrett",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15544",
    city: "Gray",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15545",
    city: "Hyndman",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15546",
    city: "Jenners",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15547",
    city: "Jennerstown",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15548",
    city: "Kantner",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15549",
    city: "Listie",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15550",
    city: "Manns Choice",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15551",
    city: "Markleton",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15552",
    city: "Meyersdale",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15553",
    city: "New Baltimore",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15554",
    city: "New Paris",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15555",
    city: "Quecreek",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15557",
    city: "Rockwood",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15558",
    city: "Salisbury",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15559",
    city: "Schellsburg",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15560",
    city: "Shanksville",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15561",
    city: "Sipesville",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15562",
    city: "Springs",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15563",
    city: "Stoystown",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15564",
    city: "Wellersburg",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15565",
    city: "West Salisbury",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15601",
    city: "Greensburg",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15605",
    city: "Greensburg",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15606",
    city: "Greensburg",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15610",
    city: "Acme",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15611",
    city: "Adamsburg",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15612",
    city: "Alverton",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15613",
    city: "Apollo",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15615",
    city: "Ardara",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15616",
    city: "Armbrust",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15617",
    city: "Arona",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15618",
    city: "Avonmore",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15619",
    city: "Bovard",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15620",
    city: "Bradenville",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15621",
    city: "Calumet",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15622",
    city: "Champion",
    state: "PA",
    areacode: 814,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15623",
    city: "Claridge",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15624",
    city: "Crabtree",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15625",
    city: "Darragh",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15626",
    city: "Delmont",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15627",
    city: "Derry",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15628",
    city: "Donegal",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15629",
    city: "East Vandergrift",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15631",
    city: "Everson",
    state: "PA",
    areacode: 724,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15632",
    city: "Export",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15633",
    city: "Forbes Road",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15634",
    city: "Grapeville",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15635",
    city: "Hannastown",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15636",
    city: "Harrison City",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15637",
    city: "Herminie",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15638",
    city: "Hostetter",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15639",
    city: "Hunker",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15640",
    city: "Hutchinson",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15641",
    city: "Hyde Park",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15642",
    city: "Irwin",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15644",
    city: "Jeannette",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15646",
    city: "Jones Mills",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15647",
    city: "Larimer",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15650",
    city: "Latrobe",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15655",
    city: "Laughlintown",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15656",
    city: "Leechburg",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15658",
    city: "Ligonier",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15660",
    city: "Lowber",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15661",
    city: "Loyalhanna",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15662",
    city: "Luxor",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15663",
    city: "Madison",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15664",
    city: "Mammoth",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15665",
    city: "Manor",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15666",
    city: "Mount Pleasant",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15668",
    city: "Murrysville",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15670",
    city: "New Alexandria",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15671",
    city: "New Derry",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15672",
    city: "New Stanton",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15673",
    city: "North Apollo",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15674",
    city: "Norvelt",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15675",
    city: "Penn",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15676",
    city: "Pleasant Unity",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15677",
    city: "Rector",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15678",
    city: "Rillton",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15679",
    city: "Ruffs Dale",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15680",
    city: "Salina",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15681",
    city: "Saltsburg",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15682",
    city: "Schenley",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15683",
    city: "Scottdale",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15684",
    city: "Slickville",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15685",
    city: "Southwest",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15686",
    city: "Spring Church",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15687",
    city: "Stahlstown",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15688",
    city: "Tarrs",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15689",
    city: "United",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15690",
    city: "Vandergrift",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15691",
    city: "Wendel",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15692",
    city: "Westmoreland City",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15693",
    city: "Whitney",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15695",
    city: "Wyano",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15696",
    city: "Youngstown",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15697",
    city: "Youngwood",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15698",
    city: "Yukon",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15701",
    city: "Indiana",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15705",
    city: "Indiana",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15710",
    city: "Alverda",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15711",
    city: "Anita",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15712",
    city: "Arcadia",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15713",
    city: "Aultman",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15714",
    city: "Northern Cambria",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15715",
    city: "Big Run",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15716",
    city: "Black Lick",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15717",
    city: "Blairsville",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15720",
    city: "Brush Valley",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15721",
    city: "Burnside",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15722",
    city: "Carrolltown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15723",
    city: "Chambersville",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15724",
    city: "Cherry Tree",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15725",
    city: "Clarksburg",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15727",
    city: "Clune",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15728",
    city: "Clymer",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15729",
    city: "Commodore",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15730",
    city: "Coolspring",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15731",
    city: "Coral",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15732",
    city: "Creekside",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15733",
    city: "De Lancey",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15734",
    city: "Dixonville",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15736",
    city: "Elderton",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15737",
    city: "Elmora",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15738",
    city: "Emeigh",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15739",
    city: "Ernest",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15741",
    city: "Gipsy",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15742",
    city: "Glen Campbell",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15744",
    city: "Hamilton",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15745",
    city: "Heilwood",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15746",
    city: "Hillsdale",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15747",
    city: "Home",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15748",
    city: "Homer City",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15750",
    city: "Josephine",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15752",
    city: "Kent",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15753",
    city: "La Jose",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15754",
    city: "Lucernemines",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15756",
    city: "McIntyre",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15757",
    city: "Mahaffey",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15758",
    city: "Marchand",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15759",
    city: "Marion Center",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15760",
    city: "Marsteller",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15761",
    city: "Mentcle",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15762",
    city: "Nicktown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15763",
    city: "Northpoint",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15764",
    city: "Oliveburg",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15765",
    city: "Penn Run",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15767",
    city: "Punxsutawney",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15770",
    city: "Ringgold",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15771",
    city: "Rochester Mills",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15772",
    city: "Rossiter",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15773",
    city: "Saint Benedict",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15774",
    city: "Shelocta",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15775",
    city: "Spangler",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15776",
    city: "Sprankle Mills",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15777",
    city: "Starford",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15778",
    city: "Timblin",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15779",
    city: "Torrance",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15780",
    city: "Valier",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15781",
    city: "Walston",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15783",
    city: "West Lebanon",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15784",
    city: "Worthville",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15801",
    city: "Du Bois",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15821",
    city: "Benezett",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15822",
    city: "Brandy Camp",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15823",
    city: "Brockport",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15824",
    city: "Brockway",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15825",
    city: "Brookville",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15827",
    city: "Byrnedale",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15828",
    city: "Clarington",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15829",
    city: "Corsica",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15831",
    city: "Dagus Mines",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15832",
    city: "Driftwood",
    state: "PA",
    areacode: 814,
    county: "Cameron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15834",
    city: "Emporium",
    state: "PA",
    areacode: 814,
    county: "Cameron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15840",
    city: "Falls Creek",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15841",
    city: "Force",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15845",
    city: "Johnsonburg",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15846",
    city: "Kersey",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15847",
    city: "Knox Dale",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15848",
    city: "Luthersburg",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15849",
    city: "Penfield",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15851",
    city: "Reynoldsville",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15853",
    city: "Ridgway",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15856",
    city: "Rockton",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15857",
    city: "Saint Marys",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15860",
    city: "Sigel",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15861",
    city: "Sinnamahoning",
    state: "PA",
    areacode: 814,
    county: "Cameron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15863",
    city: "Stump Creek",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15864",
    city: "Summerville",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15865",
    city: "Sykesville",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15866",
    city: "Troutville",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15868",
    city: "Weedville",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15870",
    city: "Wilcox",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15901",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15902",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15904",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15905",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15906",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15907",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15909",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15915",
    city: "Johnstown",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15920",
    city: "Armagh",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15921",
    city: "Beaverdale",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15922",
    city: "Belsano",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15923",
    city: "Bolivar",
    state: "PA",
    areacode: 724,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15924",
    city: "Cairnbrook",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15925",
    city: "Cassandra",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15926",
    city: "Central City",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15927",
    city: "Colver",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15928",
    city: "Davidsville",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15929",
    city: "Dilltown",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15930",
    city: "Dunlo",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15931",
    city: "Ebensburg",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15934",
    city: "Elton",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15935",
    city: "Hollsopple",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15936",
    city: "Hooversville",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15937",
    city: "Jerome",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15938",
    city: "Lilly",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15940",
    city: "Loretto",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15942",
    city: "Mineral Point",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15943",
    city: "Nanty Glo",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15944",
    city: "New Florence",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15945",
    city: "Parkhill",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15946",
    city: "Portage",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15948",
    city: "Revloc",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15949",
    city: "Robinson",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15951",
    city: "Saint Michael",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15952",
    city: "Salix",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15953",
    city: "Seanor",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15954",
    city: "Seward",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15955",
    city: "Sidman",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15956",
    city: "South Fork",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15957",
    city: "Strongstown",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15958",
    city: "Summerhill",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15959",
    city: "Tire Hill",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15960",
    city: "Twin Rocks",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15961",
    city: "Vintondale",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15962",
    city: "Wilmore",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "15963",
    city: "Windber",
    state: "PA",
    areacode: 814,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16001",
    city: "Butler",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16002",
    city: "Butler",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16003",
    city: "Butler",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16016",
    city: "Boyers",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16017",
    city: "Boyers",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16018",
    city: "Boyers",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16020",
    city: "Boyers",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16021",
    city: "Branchton",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16022",
    city: "Bruin",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16023",
    city: "Cabot",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16024",
    city: "Callery",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16025",
    city: "Chicora",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16027",
    city: "Connoquenessing",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16028",
    city: "East Brady",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16029",
    city: "East Butler",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16030",
    city: "Eau Claire",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16033",
    city: "Evans City",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16034",
    city: "Fenelton",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16035",
    city: "Forestville",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16036",
    city: "Foxburg",
    state: "PA",
    areacode: 724,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16037",
    city: "Harmony",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16038",
    city: "Harrisville",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16039",
    city: "Herman",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16040",
    city: "Hilliards",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16041",
    city: "Karns City",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16045",
    city: "Lyndora",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16046",
    city: "Mars",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16048",
    city: "North Washington",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16049",
    city: "Parker",
    state: "PA",
    areacode: 724,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16050",
    city: "Petrolia",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16051",
    city: "Portersville",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16052",
    city: "Prospect",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16053",
    city: "Renfrew",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16054",
    city: "Saint Petersburg",
    state: "PA",
    areacode: 724,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16055",
    city: "Sarver",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16056",
    city: "Saxonburg",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16057",
    city: "Slippery Rock",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16058",
    city: "Turkey City",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16059",
    city: "Valencia",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16061",
    city: "West Sunbury",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16063",
    city: "Zelienople",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16066",
    city: "Cranberry Township",
    state: "PA",
    areacode: 724,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16101",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16102",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16103",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16105",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16107",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16108",
    city: "New Castle",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16110",
    city: "Adamsville",
    state: "PA",
    areacode: 724,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16111",
    city: "Atlantic",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16112",
    city: "Bessemer",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16113",
    city: "Clark",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16114",
    city: "Clarks Mills",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16115",
    city: "Darlington",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16116",
    city: "Edinburg",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16117",
    city: "Ellwood City",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16120",
    city: "Enon Valley",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16121",
    city: "Farrell",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16123",
    city: "Fombell",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16124",
    city: "Fredonia",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16125",
    city: "Greenville",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16127",
    city: "Grove City",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16130",
    city: "Hadley",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16131",
    city: "Hartstown",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16132",
    city: "Hillsville",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16133",
    city: "Jackson Center",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16134",
    city: "Jamestown",
    state: "PA",
    areacode: 724,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16136",
    city: "Koppel",
    state: "PA",
    areacode: 724,
    county: "Beaver",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16137",
    city: "Mercer",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16140",
    city: "New Bedford",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16141",
    city: "New Galilee",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16142",
    city: "New Wilmington",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16143",
    city: "Pulaski",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16145",
    city: "Sandy Lake",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16146",
    city: "Sharon",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16148",
    city: "Hermitage",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16150",
    city: "Sharpsville",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16151",
    city: "Sheakleyville",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16153",
    city: "Stoneboro",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16154",
    city: "Transfer",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16155",
    city: "Villa Maria",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16156",
    city: "Volant",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16157",
    city: "Wampum",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16159",
    city: "West Middlesex",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16160",
    city: "West Pittsburg",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16161",
    city: "Wheatland",
    state: "PA",
    areacode: 724,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16172",
    city: "New Wilmington",
    state: "PA",
    areacode: 724,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16201",
    city: "Kittanning",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16210",
    city: "Adrian",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16211",
    city: "Beyer",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16212",
    city: "Cadogan",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16213",
    city: "Callensburg",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16214",
    city: "Clarion",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16217",
    city: "Cooksburg",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16218",
    city: "Cowansville",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16220",
    city: "Crown",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16221",
    city: "Curllsville",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16222",
    city: "Dayton",
    state: "PA",
    areacode: 814,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16223",
    city: "Distant",
    state: "PA",
    areacode: 814,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16224",
    city: "Fairmount City",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16225",
    city: "Fisher",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16226",
    city: "Ford City",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16228",
    city: "Ford Cliff",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16229",
    city: "Freeport",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16230",
    city: "Hawthorn",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16232",
    city: "Knox",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16233",
    city: "Leeper",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16234",
    city: "Limestone",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16235",
    city: "Lucinda",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16236",
    city: "McGrann",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16238",
    city: "Manorville",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16239",
    city: "Marienville",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16240",
    city: "Mayport",
    state: "PA",
    areacode: 814,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16242",
    city: "New Bethlehem",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16244",
    city: "Nu Mine",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16245",
    city: "Oak Ridge",
    state: "PA",
    areacode: 814,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16246",
    city: "Plumville",
    state: "PA",
    areacode: 724,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16248",
    city: "Rimersburg",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16249",
    city: "Rural Valley",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16250",
    city: "Sagamore",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16253",
    city: "Seminole",
    state: "PA",
    areacode: 814,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16254",
    city: "Shippenville",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16255",
    city: "Sligo",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16256",
    city: "Smicksburg",
    state: "PA",
    areacode: 814,
    county: "Indiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16257",
    city: "Snydersburg",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16258",
    city: "Strattanville",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16259",
    city: "Templeton",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16260",
    city: "Vowinckel",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16261",
    city: "Widnoon",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16262",
    city: "Worthington",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16263",
    city: "Yatesboro",
    state: "PA",
    areacode: 724,
    county: "Armstrong",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16301",
    city: "Oil City",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16311",
    city: "Carlton",
    state: "PA",
    areacode: 814,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16312",
    city: "Chandlers Valley",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16313",
    city: "Clarendon",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16314",
    city: "Cochranton",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16316",
    city: "Conneaut Lake",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16317",
    city: "Cooperstown",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16319",
    city: "Cranberry",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16321",
    city: "East Hickory",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16322",
    city: "Endeavor",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16323",
    city: "Franklin",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16326",
    city: "Fryburg",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16327",
    city: "Guys Mills",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16328",
    city: "Hydetown",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16329",
    city: "Irvine",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16331",
    city: "Kossuth",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16332",
    city: "Lickingville",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16333",
    city: "Ludlow",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16334",
    city: "Marble",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16335",
    city: "Meadville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16340",
    city: "Pittsfield",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16341",
    city: "Pleasantville",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16342",
    city: "Polk",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16343",
    city: "Reno",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16344",
    city: "Rouseville",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16345",
    city: "Russell",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16346",
    city: "Seneca",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16347",
    city: "Sheffield",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16350",
    city: "Sugar Grove",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16351",
    city: "Tidioute",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16352",
    city: "Tiona",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16353",
    city: "Tionesta",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16354",
    city: "Titusville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16360",
    city: "Townville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16361",
    city: "Tylersburg",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16362",
    city: "Utica",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16364",
    city: "Venus",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16365",
    city: "Warren",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16366",
    city: "Warren",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16367",
    city: "Warren",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16368",
    city: "Warren",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16369",
    city: "Warren",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16370",
    city: "West Hickory",
    state: "PA",
    areacode: 814,
    county: "Forest",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16371",
    city: "Youngsville",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16372",
    city: "Clintonville",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16373",
    city: "Emlenton",
    state: "PA",
    areacode: 724,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16374",
    city: "Kennerdell",
    state: "PA",
    areacode: 814,
    county: "Venango",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16375",
    city: "Lamartine",
    state: "PA",
    areacode: 814,
    county: "Clarion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16388",
    city: "Meadville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16401",
    city: "Albion",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16402",
    city: "Bear Lake",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16403",
    city: "Cambridge Springs",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16404",
    city: "Centerville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16405",
    city: "Columbus",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16406",
    city: "Conneautville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16407",
    city: "Corry",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16410",
    city: "Cranesville",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16411",
    city: "East Springfield",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16412",
    city: "Edinboro",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16413",
    city: "Elgin",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16415",
    city: "Fairview",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16416",
    city: "Garland",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16417",
    city: "Girard",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16420",
    city: "Grand Valley",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16421",
    city: "Harborcreek",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16422",
    city: "Harmonsburg",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16423",
    city: "Lake City",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16424",
    city: "Linesville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16426",
    city: "McKean",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16427",
    city: "Mill Village",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16428",
    city: "North East",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16430",
    city: "North Springfield",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16432",
    city: "Riceville",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16433",
    city: "Saegertown",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16434",
    city: "Spartansburg",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16435",
    city: "Springboro",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16436",
    city: "Spring Creek",
    state: "PA",
    areacode: 814,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16438",
    city: "Union City",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16440",
    city: "Venango",
    state: "PA",
    areacode: 814,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16441",
    city: "Waterford",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16442",
    city: "Wattsburg",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16443",
    city: "West Springfield",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16444",
    city: "Edinboro",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16475",
    city: "Albion",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16501",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16502",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16503",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16504",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16505",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16506",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16507",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16508",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16509",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16510",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16511",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16512",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16514",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16515",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16522",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16530",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16531",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16534",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16538",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16541",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16544",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16546",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16550",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16553",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16563",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16565",
    city: "Erie",
    state: "PA",
    areacode: 814,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16601",
    city: "Altoona",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16602",
    city: "Altoona",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16603",
    city: "Altoona",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16611",
    city: "Alexandria",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16613",
    city: "Ashville",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16616",
    city: "Beccaria",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16617",
    city: "Bellwood",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16619",
    city: "Blandburg",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16620",
    city: "Brisbin",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16621",
    city: "Broad Top",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16622",
    city: "Calvin",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16623",
    city: "Cassville",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16624",
    city: "Chest Springs",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16625",
    city: "Claysburg",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16627",
    city: "Coalport",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16629",
    city: "Coupon",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16630",
    city: "Cresson",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16631",
    city: "Curryville",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16633",
    city: "Defiance",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16634",
    city: "Dudley",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16635",
    city: "Duncansville",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16636",
    city: "Dysart",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16637",
    city: "East Freedom",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16638",
    city: "Entriken",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16639",
    city: "Fallentimber",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16640",
    city: "Flinton",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16641",
    city: "Gallitzin",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16644",
    city: "Glasgow",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16645",
    city: "Glen Hope",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16646",
    city: "Hastings",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16647",
    city: "Hesston",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16648",
    city: "Hollidaysburg",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16650",
    city: "Hopewell",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16651",
    city: "Houtzdale",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16652",
    city: "Huntingdon",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16654",
    city: "Huntingdon",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16655",
    city: "Imler",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16656",
    city: "Irvona",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16657",
    city: "James Creek",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16659",
    city: "Loysburg",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16660",
    city: "McConnellstown",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16661",
    city: "Madera",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16662",
    city: "Martinsburg",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16663",
    city: "Morann",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16664",
    city: "New Enterprise",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16665",
    city: "Newry",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16666",
    city: "Osceola Mills",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16667",
    city: "Osterburg",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16668",
    city: "Patton",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16669",
    city: "Petersburg",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16670",
    city: "Queen",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16671",
    city: "Ramey",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16672",
    city: "Riddlesburg",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16673",
    city: "Roaring Spring",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16674",
    city: "Robertsdale",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16675",
    city: "Saint Boniface",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16677",
    city: "Sandy Ridge",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16678",
    city: "Saxton",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16679",
    city: "Six Mile Run",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16680",
    city: "Smithmill",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16681",
    city: "Smokerun",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16682",
    city: "Sproul",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16683",
    city: "Spruce Creek",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16684",
    city: "Tipton",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16685",
    city: "Todd",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16686",
    city: "Tyrone",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16689",
    city: "Waterfall",
    state: "PA",
    areacode: 814,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16691",
    city: "Wells Tannery",
    state: "PA",
    areacode: 814,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16692",
    city: "Westover",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16693",
    city: "Williamsburg",
    state: "PA",
    areacode: 814,
    county: "Blair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16694",
    city: "Wood",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16695",
    city: "Woodbury",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16698",
    city: "Houtzdale",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16699",
    city: "Cresson",
    state: "PA",
    areacode: 814,
    county: "Cambria",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16701",
    city: "Bradford",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16720",
    city: "Austin",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16724",
    city: "Crosby",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16725",
    city: "Custer City",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16726",
    city: "Cyclone",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16727",
    city: "Derrick City",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16728",
    city: "De Young",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16729",
    city: "Duke Center",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16730",
    city: "East Smethport",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16731",
    city: "Eldred",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16732",
    city: "Gifford",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16733",
    city: "Hazel Hurst",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16734",
    city: "James City",
    state: "PA",
    areacode: 814,
    county: "Elk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16735",
    city: "Kane",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16738",
    city: "Lewis Run",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16740",
    city: "Mount Jewett",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16743",
    city: "Port Allegany",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16744",
    city: "Rew",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16745",
    city: "Rixford",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16746",
    city: "Roulette",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16748",
    city: "Shinglehouse",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16749",
    city: "Smethport",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16750",
    city: "Turtlepoint",
    state: "PA",
    areacode: 814,
    county: "McKean",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16801",
    city: "State College",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16802",
    city: "University Park",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16803",
    city: "State College",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16804",
    city: "State College",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16805",
    city: "State College",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16820",
    city: "Aaronsburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16821",
    city: "Allport",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16822",
    city: "Beech Creek",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16823",
    city: "Bellefonte",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16825",
    city: "Bigler",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16826",
    city: "Blanchard",
    state: "PA",
    areacode: 570,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16827",
    city: "Boalsburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16828",
    city: "Centre Hall",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16829",
    city: "Clarence",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16830",
    city: "Clearfield",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16832",
    city: "Coburn",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16833",
    city: "Curwensville",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16834",
    city: "Drifting",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16835",
    city: "Fleming",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16836",
    city: "Frenchville",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16837",
    city: "Glen Richey",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16838",
    city: "Grampian",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16839",
    city: "Grassflat",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16840",
    city: "Hawk Run",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16841",
    city: "Howard",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16843",
    city: "Hyde",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16844",
    city: "Julian",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16845",
    city: "Karthaus",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16847",
    city: "Kylertown",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16848",
    city: "Lamar",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16849",
    city: "Lanse",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16850",
    city: "Lecontes Mills",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16851",
    city: "Lemont",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16852",
    city: "Madisonburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16853",
    city: "Milesburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16854",
    city: "Millheim",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16855",
    city: "Mineral Springs",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16856",
    city: "Mingoville",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16858",
    city: "Morrisdale",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16859",
    city: "Moshannon",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16860",
    city: "Munson",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16861",
    city: "New Millport",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16863",
    city: "Olanta",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16864",
    city: "Orviston",
    state: "PA",
    areacode: 570,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16865",
    city: "Pennsylvania Furnace",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16866",
    city: "Philipsburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16868",
    city: "Pine Grove Mills",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16870",
    city: "Port Matilda",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16871",
    city: "Pottersdale",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16872",
    city: "Rebersburg",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16873",
    city: "Shawville",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16874",
    city: "Snow Shoe",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16875",
    city: "Spring Mills",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16876",
    city: "Wallaceton",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16877",
    city: "Warriors Mark",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16878",
    city: "West Decatur",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16879",
    city: "Winburne",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16881",
    city: "Woodland",
    state: "PA",
    areacode: 814,
    county: "Clearfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16882",
    city: "Woodward",
    state: "PA",
    areacode: 814,
    county: "Centre",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16901",
    city: "Wellsboro",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16910",
    city: "Alba",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16911",
    city: "Arnot",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16912",
    city: "Blossburg",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16914",
    city: "Columbia Cross Roads",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16915",
    city: "Coudersport",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16917",
    city: "Covington",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16920",
    city: "Elkland",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16921",
    city: "Gaines",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16922",
    city: "Galeton",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16923",
    city: "Genesee",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16925",
    city: "Gillett",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16926",
    city: "Granville Summit",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16927",
    city: "Harrison Valley",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16928",
    city: "Knoxville",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16929",
    city: "Lawrenceville",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16930",
    city: "Liberty",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16932",
    city: "Mainesburg",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16933",
    city: "Mansfield",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16935",
    city: "Middlebury Center",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16936",
    city: "Millerton",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16937",
    city: "Mills",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16938",
    city: "Morris",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16939",
    city: "Morris Run",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16940",
    city: "Nelson",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16941",
    city: "Genesee",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16942",
    city: "Osceola",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16943",
    city: "Sabinsville",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16945",
    city: "Sylvania",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16946",
    city: "Tioga",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16947",
    city: "Troy",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16948",
    city: "Ulysses",
    state: "PA",
    areacode: 814,
    county: "Potter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "16950",
    city: "Westfield",
    state: "PA",
    areacode: 814,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17001",
    city: "Camp Hill",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17002",
    city: "Allensville",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17003",
    city: "Annville",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17004",
    city: "Belleville",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17005",
    city: "Berrysburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17006",
    city: "Blain",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17007",
    city: "Boiling Springs",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17009",
    city: "Burnham",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17010",
    city: "Campbelltown",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17011",
    city: "Camp Hill",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17013",
    city: "Carlisle",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17014",
    city: "Cocolamus",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17015",
    city: "Carlisle",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17016",
    city: "Cornwall",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17017",
    city: "Dalmatia",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17018",
    city: "Dauphin",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17019",
    city: "Dillsburg",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17020",
    city: "Duncannon",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17021",
    city: "East Waterford",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17022",
    city: "Elizabethtown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17023",
    city: "Elizabethville",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17024",
    city: "Elliottsburg",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17025",
    city: "Enola",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17026",
    city: "Fredericksburg",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17027",
    city: "Grantham",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17028",
    city: "Grantville",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17029",
    city: "Granville",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17030",
    city: "Gratz",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17032",
    city: "Halifax",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17033",
    city: "Hershey",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17034",
    city: "Highspire",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17035",
    city: "Honey Grove",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17036",
    city: "Hummelstown",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17037",
    city: "Ickesburg",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17038",
    city: "Jonestown",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17039",
    city: "Kleinfeltersville",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17040",
    city: "Landisburg",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17041",
    city: "Lawn",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17042",
    city: "Lebanon",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17043",
    city: "Lemoyne",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17044",
    city: "Lewistown",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17045",
    city: "Liverpool",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17046",
    city: "Lebanon",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17047",
    city: "Loysville",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17048",
    city: "Lykens",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17049",
    city: "McAlisterville",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17050",
    city: "Mechanicsburg",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17051",
    city: "McVeytown",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17052",
    city: "Mapleton Depot",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17053",
    city: "Marysville",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17054",
    city: "Mattawana",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17055",
    city: "Mechanicsburg",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17056",
    city: "Mexico",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17057",
    city: "Middletown",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17058",
    city: "Mifflin",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17059",
    city: "Mifflintown",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17060",
    city: "Mill Creek",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17061",
    city: "Millersburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17062",
    city: "Millerstown",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17063",
    city: "Milroy",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17064",
    city: "Mount Gretna",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17065",
    city: "Mount Holly Springs",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17066",
    city: "Mount Union",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17067",
    city: "Myerstown",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17068",
    city: "New Bloomfield",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17069",
    city: "New Buffalo",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17070",
    city: "New Cumberland",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17071",
    city: "New Germantown",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17072",
    city: "New Kingstown",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17073",
    city: "Newmanstown",
    state: "PA",
    areacode: 610,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17074",
    city: "Newport",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17075",
    city: "Newton Hamilton",
    state: "PA",
    areacode: 814,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17076",
    city: "Oakland Mills",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17077",
    city: "Ono",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17078",
    city: "Palmyra",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17080",
    city: "Pillow",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17081",
    city: "Plainfield",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17082",
    city: "Port Royal",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17083",
    city: "Quentin",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17084",
    city: "Reedsville",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17085",
    city: "Rexmont",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17086",
    city: "Richfield",
    state: "PA",
    areacode: 717,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17087",
    city: "Richland",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17088",
    city: "Schaefferstown",
    state: "PA",
    areacode: 717,
    county: "Lebanon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17089",
    city: "Camp Hill",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17090",
    city: "Shermans Dale",
    state: "PA",
    areacode: 717,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17093",
    city: "Summerdale",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17094",
    city: "Thompsontown",
    state: "PA",
    areacode: 717,
    county: "Juniata",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17097",
    city: "Wiconisco",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17098",
    city: "Williamstown",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17099",
    city: "Yeagertown",
    state: "PA",
    areacode: 717,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17101",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17102",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17103",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17104",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17105",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17106",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17107",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17108",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17109",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17110",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17111",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17112",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17113",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17120",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17121",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17122",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17123",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17124",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17125",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17126",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17127",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17128",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17129",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17130",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17140",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17177",
    city: "Harrisburg",
    state: "PA",
    areacode: 717,
    county: "Dauphin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17201",
    city: "Chambersburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17202",
    city: "Chambersburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17210",
    city: "Amberson",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17211",
    city: "Artemas",
    state: "PA",
    areacode: 814,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17212",
    city: "Big Cove Tannery",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17213",
    city: "Blairs Mills",
    state: "PA",
    areacode: 717,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17214",
    city: "Blue Ridge Summit",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17215",
    city: "Burnt Cabins",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17217",
    city: "Concord",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17219",
    city: "Doylesburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17220",
    city: "Dry Run",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17221",
    city: "Fannettsburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17222",
    city: "Fayetteville",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17223",
    city: "Fort Littleton",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17224",
    city: "Fort Loudon",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17225",
    city: "Greencastle",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17228",
    city: "Harrisonville",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17229",
    city: "Hustontown",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17231",
    city: "Lemasters",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17232",
    city: "Lurgan",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17233",
    city: "McConnellsburg",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17235",
    city: "Marion",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17236",
    city: "Mercersburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17237",
    city: "Mont Alto",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17238",
    city: "Needmore",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17239",
    city: "Neelyton",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17240",
    city: "Newburg",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17241",
    city: "Newville",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17243",
    city: "Orbisonia",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17244",
    city: "Orrstown",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17246",
    city: "Pleasant Hall",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17247",
    city: "Quincy",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17249",
    city: "Rockhill Furnace",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17250",
    city: "Rouzerville",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17251",
    city: "Roxbury",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17252",
    city: "Saint Thomas",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17253",
    city: "Saltillo",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17254",
    city: "Scotland",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17255",
    city: "Shade Gap",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17256",
    city: "Shady Grove",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17257",
    city: "Shippensburg",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17260",
    city: "Shirleysburg",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17261",
    city: "South Mountain",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17262",
    city: "Spring Run",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17263",
    city: "State Line",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17264",
    city: "Three Springs",
    state: "PA",
    areacode: 814,
    county: "Huntingdon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17265",
    city: "Upperstrasburg",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17266",
    city: "Walnut Bottom",
    state: "PA",
    areacode: 717,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17267",
    city: "Warfordsburg",
    state: "PA",
    areacode: 717,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17268",
    city: "Waynesboro",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17271",
    city: "Willow Hill",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17272",
    city: "Zullinger",
    state: "PA",
    areacode: 717,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17301",
    city: "Abbottstown",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17302",
    city: "Airville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17303",
    city: "Arendtsville",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17304",
    city: "Aspers",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17306",
    city: "Bendersville",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17307",
    city: "Biglerville",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17309",
    city: "Brogue",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17310",
    city: "Cashtown",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17311",
    city: "Codorus",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17312",
    city: "Craley",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17313",
    city: "Dallastown",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17314",
    city: "Delta",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17315",
    city: "Dover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17316",
    city: "East Berlin",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17317",
    city: "East Prospect",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17318",
    city: "Emigsville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17319",
    city: "Etters",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17320",
    city: "Fairfield",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17321",
    city: "Fawn Grove",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17322",
    city: "Felton",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17323",
    city: "Franklintown",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17324",
    city: "Gardners",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17325",
    city: "Gettysburg",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17327",
    city: "Glen Rock",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17329",
    city: "Glenville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17331",
    city: "Hanover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17332",
    city: "Hanover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17333",
    city: "Hanover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17334",
    city: "Hanover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17335",
    city: "Hanover",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17337",
    city: "Idaville",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17339",
    city: "Lewisberry",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17340",
    city: "Littlestown",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17342",
    city: "Loganville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17343",
    city: "McKnightstown",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17344",
    city: "McSherrystown",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17345",
    city: "Manchester",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17347",
    city: "Mount Wolf",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17349",
    city: "New Freedom",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17350",
    city: "New Oxford",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17352",
    city: "New Park",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17353",
    city: "Orrtanna",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17355",
    city: "Railroad",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17356",
    city: "Red Lion",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17358",
    city: "Rossville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17360",
    city: "Seven Valleys",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17361",
    city: "Shrewsbury",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17362",
    city: "Spring Grove",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17363",
    city: "Stewartstown",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17364",
    city: "Thomasville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17365",
    city: "Wellsville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17366",
    city: "Windsor",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17368",
    city: "Wrightsville",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17370",
    city: "York Haven",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17371",
    city: "York New Salem",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17372",
    city: "York Springs",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17375",
    city: "Peach Glen",
    state: "PA",
    areacode: 717,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17401",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17402",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17403",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17404",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17405",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17406",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17407",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17408",
    city: "York",
    state: "PA",
    areacode: 717,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17501",
    city: "Akron",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17502",
    city: "Bainbridge",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17503",
    city: "Bart",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17504",
    city: "Bausman",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17505",
    city: "Bird in Hand",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17506",
    city: "Blue Ball",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17507",
    city: "Bowmansville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17508",
    city: "Brownstown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17509",
    city: "Christiana",
    state: "PA",
    areacode: 610,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17512",
    city: "Columbia",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17516",
    city: "Conestoga",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17517",
    city: "Denver",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17518",
    city: "Drumore",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17519",
    city: "East Earl",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17520",
    city: "East Petersburg",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17521",
    city: "Elm",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17522",
    city: "Ephrata",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17527",
    city: "Gap",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17528",
    city: "Goodville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17529",
    city: "Gordonville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17532",
    city: "Holtwood",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17533",
    city: "Hopeland",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17534",
    city: "Intercourse",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17535",
    city: "Kinzers",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17536",
    city: "Kirkwood",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17537",
    city: "Lampeter",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17538",
    city: "Landisville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17540",
    city: "Leola",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17543",
    city: "Lititz",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17545",
    city: "Manheim",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17547",
    city: "Marietta",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17549",
    city: "Martindale",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17550",
    city: "Maytown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17551",
    city: "Millersville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17552",
    city: "Mount Joy",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17554",
    city: "Mountville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17555",
    city: "Narvon",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17557",
    city: "New Holland",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17560",
    city: "New Providence",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17562",
    city: "Paradise",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17563",
    city: "Peach Bottom",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17564",
    city: "Penryn",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17565",
    city: "Pequea",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17566",
    city: "Quarryville",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17567",
    city: "Reamstown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17568",
    city: "Refton",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17569",
    city: "Reinholds",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17570",
    city: "Rheems",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17572",
    city: "Ronks",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17573",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17575",
    city: "Silver Spring",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17576",
    city: "Smoketown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17578",
    city: "Stevens",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17579",
    city: "Strasburg",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17580",
    city: "Talmage",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17581",
    city: "Terre Hill",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17582",
    city: "Washington Boro",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17584",
    city: "Willow Street",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17585",
    city: "Witmer",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17601",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17602",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17603",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17604",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17605",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17606",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17607",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17608",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17611",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17622",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17699",
    city: "Lancaster",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17701",
    city: "Williamsport",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17702",
    city: "Williamsport",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17703",
    city: "Williamsport",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17705",
    city: "Williamsport",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17720",
    city: "Antes Fort",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17721",
    city: "Avis",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17723",
    city: "Cammal",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17724",
    city: "Canton",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17726",
    city: "Castanea",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17727",
    city: "Cedar Run",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17728",
    city: "Cogan Station",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17729",
    city: "Cross Fork",
    state: "PA",
    areacode: 717,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17730",
    city: "Dewart",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17731",
    city: "Eagles Mere",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17735",
    city: "Grover",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17737",
    city: "Hughesville",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17739",
    city: "Jersey Mills",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17740",
    city: "Jersey Shore",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17742",
    city: "Lairdsville",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17744",
    city: "Linden",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17745",
    city: "Lock Haven",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17747",
    city: "Loganton",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17748",
    city: "McElhattan",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17749",
    city: "McEwensville",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17750",
    city: "Mackeyville",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17751",
    city: "Mill Hall",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17752",
    city: "Montgomery",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17754",
    city: "Montoursville",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17756",
    city: "Muncy",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17758",
    city: "Muncy Valley",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17760",
    city: "North Bend",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17762",
    city: "Picture Rocks",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17763",
    city: "Ralston",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17764",
    city: "Renovo",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17765",
    city: "Roaring Branch",
    state: "PA",
    areacode: 570,
    county: "Tioga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17768",
    city: "Shunk",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17769",
    city: "Slate Run",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17771",
    city: "Trout Run",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17772",
    city: "Turbotville",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17774",
    city: "Unityville",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17776",
    city: "Waterville",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17777",
    city: "Watsontown",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17778",
    city: "Westport",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17779",
    city: "Woolrich",
    state: "PA",
    areacode: 570,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17801",
    city: "Sunbury",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17810",
    city: "Allenwood",
    state: "PA",
    areacode: 570,
    county: "Lycoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17812",
    city: "Beaver Springs",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17813",
    city: "Beavertown",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17814",
    city: "Benton",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17815",
    city: "Bloomsburg",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17820",
    city: "Catawissa",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17821",
    city: "Danville",
    state: "PA",
    areacode: 570,
    county: "Montour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17822",
    city: "Danville",
    state: "PA",
    areacode: 570,
    county: "Montour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17823",
    city: "Dornsife",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17824",
    city: "Elysburg",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17827",
    city: "Freeburg",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17829",
    city: "Hartleton",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17830",
    city: "Herndon",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17831",
    city: "Hummels Wharf",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17832",
    city: "Marion Heights",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17833",
    city: "Kreamer",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17834",
    city: "Kulpmont",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17835",
    city: "Laurelton",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17836",
    city: "Leck Kill",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17837",
    city: "Lewisburg",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17840",
    city: "Locust Gap",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17841",
    city: "McClure",
    state: "PA",
    areacode: 570,
    county: "Mifflin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17842",
    city: "Middleburg",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17843",
    city: "Beaver Springs",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17844",
    city: "Mifflinburg",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17845",
    city: "Millmont",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17846",
    city: "Millville",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17847",
    city: "Milton",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17850",
    city: "Montandon",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17851",
    city: "Mount Carmel",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17853",
    city: "Mount Pleasant Mills",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17855",
    city: "New Berlin",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17856",
    city: "New Columbia",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17857",
    city: "Northumberland",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17858",
    city: "Numidia",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17859",
    city: "Orangeville",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17860",
    city: "Paxinos",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17861",
    city: "Paxtonville",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17862",
    city: "Penns Creek",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17864",
    city: "Port Trevorton",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17865",
    city: "Potts Grove",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17866",
    city: "Coal Township",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17867",
    city: "Rebuck",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17868",
    city: "Riverside",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17870",
    city: "Selinsgrove",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17872",
    city: "Shamokin",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17876",
    city: "Shamokin Dam",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17877",
    city: "Snydertown",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17878",
    city: "Stillwater",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17880",
    city: "Swengel",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17881",
    city: "Trevorton",
    state: "PA",
    areacode: 570,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17882",
    city: "Troxelville",
    state: "PA",
    areacode: 570,
    county: "Snyder",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17884",
    city: "Washingtonville",
    state: "PA",
    areacode: 570,
    county: "Montour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17885",
    city: "Weikert",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17886",
    city: "West Milton",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17887",
    city: "White Deer",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17888",
    city: "Wilburton",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17889",
    city: "Winfield",
    state: "PA",
    areacode: 570,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17901",
    city: "Pottsville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17920",
    city: "Aristes",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17921",
    city: "Ashland",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17922",
    city: "Auburn",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17923",
    city: "Branchdale",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17925",
    city: "Brockton",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17929",
    city: "Cressona",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17930",
    city: "Cumbola",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17931",
    city: "Frackville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17932",
    city: "Frackville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17933",
    city: "Friedensburg",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17934",
    city: "Gilberton",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17935",
    city: "Girardville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17936",
    city: "Gordon",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17938",
    city: "Hegins",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17941",
    city: "Klingerstown",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17943",
    city: "Lavelle",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17944",
    city: "Llewellyn",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17945",
    city: "Locustdale",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17946",
    city: "Lost Creek",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17948",
    city: "Mahanoy City",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17949",
    city: "Mahanoy Plane",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17951",
    city: "Mar Lin",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17952",
    city: "Mary D",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17953",
    city: "Middleport",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17954",
    city: "Minersville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17957",
    city: "Muir",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17959",
    city: "New Philadelphia",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17960",
    city: "New Ringgold",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17961",
    city: "Orwigsburg",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17963",
    city: "Pine Grove",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17964",
    city: "Pitman",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17965",
    city: "Port Carbon",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17966",
    city: "Ravine",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17967",
    city: "Ringtown",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17968",
    city: "Sacramento",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17970",
    city: "Saint Clair",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17972",
    city: "Schuylkill Haven",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17974",
    city: "Seltzer",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17976",
    city: "Shenandoah",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17978",
    city: "Spring Glen",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17979",
    city: "Summit Station",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17980",
    city: "Tower City",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17981",
    city: "Tremont",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17982",
    city: "Tuscarora",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17983",
    city: "Valley View",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "17985",
    city: "Zion Grove",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18001",
    city: "Lehigh Valley",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18002",
    city: "Lehigh Valley",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18003",
    city: "Lehigh Valley",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18011",
    city: "Alburtis",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18012",
    city: "Aquashicola",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18013",
    city: "Bangor",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18014",
    city: "Bath",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18015",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18016",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18017",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18018",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18020",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18025",
    city: "Bethlehem",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18030",
    city: "Bowmanstown",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18031",
    city: "Breinigsville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18032",
    city: "Catasauqua",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18034",
    city: "Center Valley",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18035",
    city: "Cherryville",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18036",
    city: "Coopersburg",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18037",
    city: "Coplay",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18038",
    city: "Danielsville",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18039",
    city: "Durham",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18040",
    city: "Easton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18041",
    city: "East Greenville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18042",
    city: "Easton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18043",
    city: "Easton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18044",
    city: "Easton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18045",
    city: "Easton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18046",
    city: "East Texas",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18049",
    city: "Emmaus",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18051",
    city: "Fogelsville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18052",
    city: "Whitehall",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18053",
    city: "Germansville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18054",
    city: "Green Lane",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18055",
    city: "Hellertown",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18056",
    city: "Hereford",
    state: "PA",
    areacode: 215,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18058",
    city: "Kunkletown",
    state: "PA",
    areacode: 610,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18059",
    city: "Laurys Station",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18060",
    city: "Limeport",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18062",
    city: "Macungie",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18063",
    city: "Martins Creek",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18064",
    city: "Nazareth",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18065",
    city: "Neffs",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18066",
    city: "New Tripoli",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18067",
    city: "Northampton",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18068",
    city: "Old Zionsville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18069",
    city: "Orefield",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18070",
    city: "Palm",
    state: "PA",
    areacode: 215,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18071",
    city: "Palmerton",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18072",
    city: "Pen Argyl",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18073",
    city: "Pennsburg",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18074",
    city: "Perkiomenville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18076",
    city: "Red Hill",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18077",
    city: "Riegelsville",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18078",
    city: "Schnecksville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18079",
    city: "Slatedale",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18080",
    city: "Slatington",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18081",
    city: "Springtown",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18083",
    city: "Stockertown",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18084",
    city: "Sumneytown",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18085",
    city: "Tatamy",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18086",
    city: "Treichlers",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18087",
    city: "Trexlertown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18088",
    city: "Walnutport",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18091",
    city: "Wind Gap",
    state: "PA",
    areacode: 610,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18092",
    city: "Zionsville",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18098",
    city: "Emmaus",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18099",
    city: "Emmaus",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18101",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18102",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18103",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18104",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18105",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18106",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18109",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18195",
    city: "Allentown",
    state: "PA",
    areacode: 610,
    county: "Lehigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18201",
    city: "Hazleton",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18202",
    city: "Hazleton",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18210",
    city: "Albrightsville",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18211",
    city: "Andreas",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18212",
    city: "Ashfield",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18214",
    city: "Barnesville",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18216",
    city: "Beaver Meadows",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18218",
    city: "Coaldale",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18219",
    city: "Conyngham",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18220",
    city: "Delano",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18221",
    city: "Drifton",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18222",
    city: "Drums",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18223",
    city: "Ebervale",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18224",
    city: "Freeland",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18225",
    city: "Harleigh",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18229",
    city: "Jim Thorpe",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18230",
    city: "Junedale",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18231",
    city: "Kelayres",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18232",
    city: "Lansford",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18234",
    city: "Lattimer Mines",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18235",
    city: "Lehighton",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18237",
    city: "McAdoo",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18239",
    city: "Milnesville",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18240",
    city: "Nesquehoning",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18241",
    city: "Nuremberg",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18242",
    city: "Oneida",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18244",
    city: "Parryville",
    state: "PA",
    areacode: 610,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18245",
    city: "Quakake",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18246",
    city: "Rock Glen",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18247",
    city: "Saint Johns",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18248",
    city: "Sheppton",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18249",
    city: "Sugarloaf",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18250",
    city: "Summit Hill",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18251",
    city: "Sybertsville",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18252",
    city: "Tamaqua",
    state: "PA",
    areacode: 570,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18254",
    city: "Tresckow",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18255",
    city: "Weatherly",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18256",
    city: "Weston",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18301",
    city: "East Stroudsburg",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18302",
    city: "East Stroudsburg",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18320",
    city: "Analomink",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18321",
    city: "Bartonsville",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18322",
    city: "Brodheadsville",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18323",
    city: "Buck Hill Falls",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18324",
    city: "Bushkill",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18325",
    city: "Canadensis",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18326",
    city: "Cresco",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18327",
    city: "Delaware Water Gap",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18328",
    city: "Dingmans Ferry",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18330",
    city: "Effort",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18331",
    city: "Gilbert",
    state: "PA",
    areacode: 610,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18332",
    city: "Henryville",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18333",
    city: "Kresgeville",
    state: "PA",
    areacode: 610,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18334",
    city: "Long Pond",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18335",
    city: "Marshalls Creek",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18336",
    city: "Matamoras",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18337",
    city: "Milford",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18340",
    city: "Millrift",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18341",
    city: "Minisink Hills",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18342",
    city: "Mountainhome",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18343",
    city: "Mount Bethel",
    state: "PA",
    areacode: 570,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18344",
    city: "Mount Pocono",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18346",
    city: "Pocono Summit",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18347",
    city: "Pocono Lake",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18348",
    city: "Pocono Lake Preserve",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18349",
    city: "Pocono Manor",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18350",
    city: "Pocono Pines",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18351",
    city: "Portland",
    state: "PA",
    areacode: 570,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18352",
    city: "Reeders",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18353",
    city: "Saylorsburg",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18354",
    city: "Sciota",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18355",
    city: "Scotrun",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18356",
    city: "Shawnee on Delaware",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18357",
    city: "Skytop",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18360",
    city: "Stroudsburg",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18370",
    city: "Swiftwater",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18371",
    city: "Tamiment",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18372",
    city: "Tannersville",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18403",
    city: "Archbald",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18405",
    city: "Beach Lake",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18407",
    city: "Carbondale",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18410",
    city: "Chinchilla",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18411",
    city: "Clarks Summit",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18413",
    city: "Clifford",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18414",
    city: "Dalton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18415",
    city: "Damascus",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18416",
    city: "Elmhurst",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18417",
    city: "Equinunk",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18419",
    city: "Factoryville",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18420",
    city: "Fleetville",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18421",
    city: "Forest City",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18424",
    city: "Gouldsboro",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18425",
    city: "Greeley",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18426",
    city: "Greentown",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18427",
    city: "Hamlin",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18428",
    city: "Hawley",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18430",
    city: "Herrick Center",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18431",
    city: "Honesdale",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18433",
    city: "Jermyn",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18434",
    city: "Jessup",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18435",
    city: "Lackawaxen",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18436",
    city: "Lake Ariel",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18437",
    city: "Lake Como",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18438",
    city: "Lakeville",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18439",
    city: "Lakewood",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18440",
    city: "La Plume",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18441",
    city: "Lenoxville",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18443",
    city: "Milanville",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18444",
    city: "Moscow",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18445",
    city: "Newfoundland",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18446",
    city: "Nicholson",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18447",
    city: "Olyphant",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18448",
    city: "Olyphant",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18449",
    city: "Orson",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18451",
    city: "Paupack",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18452",
    city: "Peckville",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18453",
    city: "Pleasant Mount",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18454",
    city: "Poyntelle",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18455",
    city: "Preston Park",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18456",
    city: "Prompton",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18457",
    city: "Rowland",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18458",
    city: "Shohola",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18459",
    city: "South Canaan",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18460",
    city: "South Sterling",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18461",
    city: "Starlight",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18462",
    city: "Starrucca",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18463",
    city: "Sterling",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18464",
    city: "Tafton",
    state: "PA",
    areacode: 570,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18465",
    city: "Thompson",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18466",
    city: "Tobyhanna",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18469",
    city: "Tyler Hill",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18470",
    city: "Union Dale",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18471",
    city: "Waverly",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18472",
    city: "Waymart",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18473",
    city: "White Mills",
    state: "PA",
    areacode: 570,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18501",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18502",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18503",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18504",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18505",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18507",
    city: "Moosic",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18508",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18509",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18510",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18512",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18515",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18517",
    city: "Taylor",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18518",
    city: "Old Forge",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18519",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18540",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18577",
    city: "Scranton",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18601",
    city: "Beach Haven",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18602",
    city: "Bear Creek",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18603",
    city: "Berwick",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18610",
    city: "Blakeslee",
    state: "PA",
    areacode: 570,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18611",
    city: "Cambra",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18612",
    city: "Dallas",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18614",
    city: "Dushore",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18615",
    city: "Falls",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18616",
    city: "Forksville",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18617",
    city: "Glen Lyon",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18618",
    city: "Harveys Lake",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18619",
    city: "Hillsgrove",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18621",
    city: "Hunlock Creek",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18622",
    city: "Huntington Mills",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18623",
    city: "Laceyville",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18624",
    city: "Lake Harmony",
    state: "PA",
    areacode: 570,
    county: "Carbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18625",
    city: "Lake Winola",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18626",
    city: "Laporte",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18627",
    city: "Lehman",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18628",
    city: "Lopez",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18629",
    city: "Mehoopany",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18630",
    city: "Meshoppen",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18631",
    city: "Mifflinville",
    state: "PA",
    areacode: 570,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18632",
    city: "Mildred",
    state: "PA",
    areacode: 570,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18634",
    city: "Nanticoke",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18635",
    city: "Nescopeck",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18636",
    city: "Noxen",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18640",
    city: "Pittston",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18641",
    city: "Pittston",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18642",
    city: "Duryea",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18643",
    city: "Pittston",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18644",
    city: "Wyoming",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18651",
    city: "Plymouth",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18653",
    city: "Ransom",
    state: "PA",
    areacode: 570,
    county: "Lackawanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18654",
    city: "Shawanese",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18655",
    city: "Shickshinny",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18656",
    city: "Sweet Valley",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18657",
    city: "Tunkhannock",
    state: "PA",
    areacode: 570,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18660",
    city: "Wapwallopen",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18661",
    city: "White Haven",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18690",
    city: "Dallas",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18701",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18702",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18703",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18704",
    city: "Kingston",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18705",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18706",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18707",
    city: "Mountain Top",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18708",
    city: "Shavertown",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18709",
    city: "Luzerne",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18710",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18711",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18762",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18764",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18765",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18766",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18767",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18769",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18773",
    city: "Wilkes Barre",
    state: "PA",
    areacode: 570,
    county: "Luzerne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18801",
    city: "Montrose",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18810",
    city: "Athens",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18812",
    city: "Brackney",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18813",
    city: "Brooklyn",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18814",
    city: "Burlington",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18815",
    city: "Camptown",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18816",
    city: "Dimock",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18817",
    city: "East Smithfield",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18818",
    city: "Friendsville",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18820",
    city: "Gibson",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18821",
    city: "Great Bend",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18822",
    city: "Hallstead",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18823",
    city: "Harford",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18824",
    city: "Hop Bottom",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18825",
    city: "Jackson",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18826",
    city: "Kingsley",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18827",
    city: "Lanesboro",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18828",
    city: "Lawton",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18829",
    city: "Le Raysville",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18830",
    city: "Little Meadows",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18831",
    city: "Milan",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18832",
    city: "Monroeton",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18833",
    city: "New Albany",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18834",
    city: "New Milford",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18837",
    city: "Rome",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18840",
    city: "Sayre",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18842",
    city: "South Gibson",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18843",
    city: "South Montrose",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18844",
    city: "Springville",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18845",
    city: "Stevensville",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18846",
    city: "Sugar Run",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18847",
    city: "Susquehanna",
    state: "PA",
    areacode: 570,
    county: "Susquehanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18848",
    city: "Towanda",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18850",
    city: "Ulster",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18851",
    city: "Warren Center",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18853",
    city: "Wyalusing",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18854",
    city: "Wysox",
    state: "PA",
    areacode: 570,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18901",
    city: "Doylestown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18902",
    city: "Doylestown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18910",
    city: "Bedminster",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18911",
    city: "Blooming Glen",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18912",
    city: "Buckingham",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18913",
    city: "Carversville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18914",
    city: "Chalfont",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18915",
    city: "Colmar",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18916",
    city: "Danboro",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18917",
    city: "Dublin",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18918",
    city: "Earlington",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18920",
    city: "Erwinna",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18921",
    city: "Ferndale",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18922",
    city: "Forest Grove",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18923",
    city: "Fountainville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18925",
    city: "Furlong",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18927",
    city: "Hilltown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18928",
    city: "Holicong",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18929",
    city: "Jamison",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18930",
    city: "Kintnersville",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18931",
    city: "Lahaska",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18932",
    city: "Line Lexington",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18933",
    city: "Lumberville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18934",
    city: "Mechanicsville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18935",
    city: "Milford Square",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18936",
    city: "Montgomeryville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18938",
    city: "New Hope",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18940",
    city: "Newtown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18942",
    city: "Ottsville",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18943",
    city: "Penns Park",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18944",
    city: "Perkasie",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18946",
    city: "Pineville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18947",
    city: "Pipersville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18949",
    city: "Plumsteadville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18950",
    city: "Point Pleasant",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18951",
    city: "Quakertown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18953",
    city: "Revere",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18954",
    city: "Richboro",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18955",
    city: "Richlandtown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18956",
    city: "Rushland",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18957",
    city: "Salford",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18958",
    city: "Salfordville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18960",
    city: "Sellersville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18962",
    city: "Silverdale",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18963",
    city: "Solebury",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18964",
    city: "Souderton",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18966",
    city: "Southampton",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18968",
    city: "Spinnerstown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18969",
    city: "Telford",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18970",
    city: "Trumbauersville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18971",
    city: "Tylersport",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18972",
    city: "Upper Black Eddy",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18974",
    city: "Warminster",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18976",
    city: "Warrington",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18977",
    city: "Washington Crossing",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18979",
    city: "Woxall",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18980",
    city: "Wycombe",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18981",
    city: "Zionhill",
    state: "PA",
    areacode: 610,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "18991",
    city: "Warminster",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19001",
    city: "Abington",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19002",
    city: "Ambler",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19003",
    city: "Ardmore",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19004",
    city: "Bala Cynwyd",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19006",
    city: "Huntingdon Valley",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19007",
    city: "Bristol",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19008",
    city: "Broomall",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19009",
    city: "Bryn Athyn",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19010",
    city: "Bryn Mawr",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19012",
    city: "Cheltenham",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19013",
    city: "Chester",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19014",
    city: "Aston",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19015",
    city: "Brookhaven",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19016",
    city: "Chester",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19017",
    city: "Chester Heights",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19018",
    city: "Clifton Heights",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19019",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19020",
    city: "Bensalem",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19021",
    city: "Croydon",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19022",
    city: "Crum Lynne",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19023",
    city: "Darby",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19025",
    city: "Dresher",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19026",
    city: "Drexel Hill",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19027",
    city: "Elkins Park",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19028",
    city: "Edgemont",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19029",
    city: "Essington",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19030",
    city: "Fairless Hills",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19031",
    city: "Flourtown",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19032",
    city: "Folcroft",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19033",
    city: "Folsom",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19034",
    city: "Fort Washington",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19035",
    city: "Gladwyne",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19036",
    city: "Glenolden",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19037",
    city: "Glen Riddle Lima",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19038",
    city: "Glenside",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19039",
    city: "Gradyville",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19040",
    city: "Hatboro",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19041",
    city: "Haverford",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19043",
    city: "Holmes",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19044",
    city: "Horsham",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19046",
    city: "Jenkintown",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19047",
    city: "Langhorne",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19048",
    city: "Fort Washington",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19049",
    city: "Fort Washington",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19050",
    city: "Lansdowne",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19052",
    city: "Lenni",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19053",
    city: "Feasterville Trevose",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19054",
    city: "Levittown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19055",
    city: "Levittown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19056",
    city: "Levittown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19057",
    city: "Levittown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19058",
    city: "Levittown",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19060",
    city: "Garnet Valley",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19061",
    city: "Marcus Hook",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19063",
    city: "Media",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19064",
    city: "Springfield",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19065",
    city: "Media",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19066",
    city: "Merion Station",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19067",
    city: "Morrisville",
    state: "PA",
    areacode: 215,
    county: "Bucks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19070",
    city: "Morton",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19072",
    city: "Narberth",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19073",
    city: "Newtown Square",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19074",
    city: "Norwood",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19075",
    city: "Oreland",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19076",
    city: "Prospect Park",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19078",
    city: "Ridley Park",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19079",
    city: "Sharon Hill",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19081",
    city: "Swarthmore",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19082",
    city: "Upper Darby",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19083",
    city: "Havertown",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19085",
    city: "Villanova",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19086",
    city: "Wallingford",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19087",
    city: "Wayne",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19090",
    city: "Willow Grove",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19091",
    city: "Media",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19092",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19093",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19094",
    city: "Woodlyn",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19095",
    city: "Wyncote",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19096",
    city: "Wynnewood",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19098",
    city: "Holmes",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19099",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19101",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19102",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19103",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19104",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19105",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19106",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19107",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19108",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19109",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19110",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19111",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19112",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19113",
    city: "Philadelphia",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19114",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19115",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19116",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19118",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19119",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19120",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19121",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19122",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19123",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19124",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19125",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19126",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19127",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19128",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19129",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19130",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19131",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19132",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19133",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19134",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19135",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19136",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19137",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19138",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19139",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19140",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19141",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19142",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19143",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19144",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19145",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19146",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19147",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19148",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19149",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19150",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19151",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19152",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19153",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19154",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19155",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19160",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19161",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19162",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19170",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19171",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19172",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19173",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19175",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19176",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19177",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19178",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19179",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19181",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19182",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19183",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19184",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19185",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19187",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19188",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19190",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19191",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19192",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19193",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19194",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19195",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19196",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19197",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19244",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19255",
    city: "Philadelphia",
    state: "PA",
    areacode: 215,
    county: "Philadelphia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19301",
    city: "Paoli",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19310",
    city: "Atglen",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19311",
    city: "Avondale",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19312",
    city: "Berwyn",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19316",
    city: "Brandamore",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19317",
    city: "Chadds Ford",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19318",
    city: "Chatham",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19319",
    city: "Cheyney",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19320",
    city: "Coatesville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19330",
    city: "Cochranville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19331",
    city: "Concordville",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19333",
    city: "Devon",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19335",
    city: "Downingtown",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19339",
    city: "Concordville",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19340",
    city: "Concordville",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19341",
    city: "Exton",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19342",
    city: "Glen Mills",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19343",
    city: "Glenmoore",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19344",
    city: "Honey Brook",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19345",
    city: "Immaculata",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19346",
    city: "Kelton",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19347",
    city: "Kemblesville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19348",
    city: "Kennett Square",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19350",
    city: "Landenberg",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19351",
    city: "Lewisville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19352",
    city: "Lincoln University",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19353",
    city: "Lionville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19354",
    city: "Lyndell",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19355",
    city: "Malvern",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19357",
    city: "Mendenhall",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19358",
    city: "Modena",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19360",
    city: "New London",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19362",
    city: "Nottingham",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19363",
    city: "Oxford",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19365",
    city: "Parkesburg",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19366",
    city: "Pocopson",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19367",
    city: "Pomeroy",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19369",
    city: "Sadsburyville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19372",
    city: "Thorndale",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19373",
    city: "Thornton",
    state: "PA",
    areacode: 610,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19374",
    city: "Toughkenamon",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19375",
    city: "Unionville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19376",
    city: "Wagontown",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19380",
    city: "West Chester",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19381",
    city: "West Chester",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19382",
    city: "West Chester",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19383",
    city: "West Chester",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19390",
    city: "West Grove",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19395",
    city: "Westtown",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19397",
    city: "Southeastern",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19398",
    city: "Southeastern",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19399",
    city: "Southeastern",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19401",
    city: "Norristown",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19403",
    city: "Norristown",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19404",
    city: "Norristown",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19405",
    city: "Bridgeport",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19406",
    city: "King of Prussia",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19407",
    city: "Audubon",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19408",
    city: "Eagleville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19409",
    city: "Fairview Village",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19415",
    city: "Eagleville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19421",
    city: "Birchrunville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19422",
    city: "Blue Bell",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19423",
    city: "Cedars",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19424",
    city: "Blue Bell",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19425",
    city: "Chester Springs",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19426",
    city: "Collegeville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19428",
    city: "Conshohocken",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19429",
    city: "Conshohocken",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19430",
    city: "Creamery",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19432",
    city: "Devault",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19435",
    city: "Frederick",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19436",
    city: "Gwynedd",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19437",
    city: "Gwynedd Valley",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19438",
    city: "Harleysville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19440",
    city: "Hatfield",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19441",
    city: "Harleysville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19442",
    city: "Kimberton",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19443",
    city: "Kulpsville",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19444",
    city: "Lafayette Hill",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19446",
    city: "Lansdale",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19450",
    city: "Lederach",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19451",
    city: "Mainland",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19453",
    city: "Mont Clare",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19454",
    city: "North Wales",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19455",
    city: "North Wales",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19456",
    city: "Oaks",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19457",
    city: "Parker Ford",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19460",
    city: "Phoenixville",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19462",
    city: "Plymouth Meeting",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19464",
    city: "Pottstown",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19465",
    city: "Pottstown",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19468",
    city: "Royersford",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19470",
    city: "Saint Peters",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19472",
    city: "Sassamansville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19473",
    city: "Schwenksville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19474",
    city: "Skippack",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19475",
    city: "Spring City",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19477",
    city: "Spring House",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19478",
    city: "Spring Mount",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19480",
    city: "Uwchland",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19481",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19482",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19484",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19486",
    city: "West Point",
    state: "PA",
    areacode: 215,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19490",
    city: "Worcester",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19492",
    city: "Zieglerville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19493",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19494",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19495",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19496",
    city: "Valley Forge",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19501",
    city: "Adamstown",
    state: "PA",
    areacode: 717,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19503",
    city: "Bally",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19504",
    city: "Barto",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19505",
    city: "Bechtelsville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19506",
    city: "Bernville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19507",
    city: "Bethel",
    state: "PA",
    areacode: 717,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19508",
    city: "Birdsboro",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19510",
    city: "Blandon",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19511",
    city: "Bowers",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19512",
    city: "Boyertown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19516",
    city: "Centerport",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19518",
    city: "Douglassville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19519",
    city: "Earlville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19520",
    city: "Elverson",
    state: "PA",
    areacode: 610,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19522",
    city: "Fleetwood",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19523",
    city: "Geigertown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19525",
    city: "Gilbertsville",
    state: "PA",
    areacode: 610,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19526",
    city: "Hamburg",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19529",
    city: "Kempton",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19530",
    city: "Kutztown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19533",
    city: "Leesport",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19534",
    city: "Lenhartsville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19535",
    city: "Limekiln",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19536",
    city: "Lyon Station",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19538",
    city: "Maxatawny",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19539",
    city: "Mertztown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19540",
    city: "Mohnton",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19541",
    city: "Mohrsville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19543",
    city: "Morgantown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19544",
    city: "Mount Aetna",
    state: "PA",
    areacode: 717,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19545",
    city: "New Berlinville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19547",
    city: "Oley",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19548",
    city: "Pine Forge",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19549",
    city: "Port Clinton",
    state: "PA",
    areacode: 610,
    county: "Schuylkill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19550",
    city: "Rehrersburg",
    state: "PA",
    areacode: 717,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19551",
    city: "Robesonia",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19554",
    city: "Shartlesville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19555",
    city: "Shoemakersville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19559",
    city: "Strausstown",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19560",
    city: "Temple",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19562",
    city: "Topton",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19564",
    city: "Virginville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19565",
    city: "Wernersville",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19567",
    city: "Womelsdorf",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19601",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19602",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19603",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19604",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19605",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19606",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19607",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19608",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19609",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19610",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19611",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19612",
    city: "Reading",
    state: "PA",
    areacode: 610,
    county: "Berks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19701",
    city: "Bear",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19702",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19703",
    city: "Claymont",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19706",
    city: "Delaware City",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19707",
    city: "Hockessin",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19708",
    city: "Kirkwood",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19709",
    city: "Middletown",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19710",
    city: "Montchanin",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19711",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19712",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19713",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19714",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19715",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19716",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19717",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19718",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19720",
    city: "New Castle",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19721",
    city: "New Castle",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19725",
    city: "Newark",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19726",
    city: "New Castle",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19730",
    city: "Odessa",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19731",
    city: "Port Penn",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19732",
    city: "Rockland",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19733",
    city: "Saint Georges",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19734",
    city: "Townsend",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19735",
    city: "Winterthur",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19736",
    city: "Yorklyn",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19801",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19802",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19803",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19804",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19805",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19806",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19807",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19808",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19809",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19810",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19850",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19880",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19884",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19885",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19886",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19890",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19891",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19892",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19893",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19894",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19895",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19896",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19897",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19898",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19899",
    city: "Wilmington",
    state: "DE",
    areacode: 302,
    county: "New Castle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19901",
    city: "Dover",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19902",
    city: "Dover AFB",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19903",
    city: "Dover",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19904",
    city: "Dover",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19905",
    city: "Dover",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19906",
    city: "Dover",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19930",
    city: "Bethany Beach",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19931",
    city: "Bethel",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19933",
    city: "Bridgeville",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19934",
    city: "Camden Wyoming",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19936",
    city: "Cheswold",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19938",
    city: "Clayton",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19939",
    city: "Dagsboro",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19940",
    city: "Delmar",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19941",
    city: "Ellendale",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19943",
    city: "Felton",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19944",
    city: "Fenwick Island",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19945",
    city: "Frankford",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19946",
    city: "Frederica",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19947",
    city: "Georgetown",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19950",
    city: "Greenwood",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19951",
    city: "Harbeson",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19952",
    city: "Harrington",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19953",
    city: "Hartly",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19954",
    city: "Houston",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19955",
    city: "Kenton",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19956",
    city: "Laurel",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19958",
    city: "Lewes",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19960",
    city: "Lincoln",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19961",
    city: "Little Creek",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19962",
    city: "Magnolia",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19963",
    city: "Milford",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19964",
    city: "Marydel",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19966",
    city: "Millsboro",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19967",
    city: "Millville",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19968",
    city: "Milton",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19969",
    city: "Nassau",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19970",
    city: "Ocean View",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19971",
    city: "Rehoboth Beach",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19973",
    city: "Seaford",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19975",
    city: "Selbyville",
    state: "DE",
    areacode: 302,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19977",
    city: "Smyrna",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19979",
    city: "Viola",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "19980",
    city: "Woodside",
    state: "DE",
    areacode: 302,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20001",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20002",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20003",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20004",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20005",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20006",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20007",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20008",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20009",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20010",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20011",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20012",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20013",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20015",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20016",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20017",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20018",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20019",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20020",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20022",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20024",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20026",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20027",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20029",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20030",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20032",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20033",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20035",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20036",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20037",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20038",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20039",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20040",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20041",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20042",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20043",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20044",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20045",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20047",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20049",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20050",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20052",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20053",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20055",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20056",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20057",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20058",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20059",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20060",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20061",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20062",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20063",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20064",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20065",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20066",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20067",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20068",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20069",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20070",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20071",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20073",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20074",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20075",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20076",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20077",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20078",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20080",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20081",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20082",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20090",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20091",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20101",
    city: "Dulles",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20102",
    city: "Dulles",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20103",
    city: "Dulles",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20104",
    city: "Dulles",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20105",
    city: "Aldie",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20106",
    city: "Amissville",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20108",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Manassas City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20109",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20110",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Manassas City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20111",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20112",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20113",
    city: "Manassas",
    state: "VA",
    areacode: 703,
    county: "Manassas Park City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20115",
    city: "Marshall",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20116",
    city: "Marshall",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20117",
    city: "Middleburg",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20118",
    city: "Middleburg",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20119",
    city: "Catlett",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20120",
    city: "Centreville",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20121",
    city: "Centreville",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20122",
    city: "Centreville",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20124",
    city: "Clifton",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20128",
    city: "Orlean",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20129",
    city: "Paeonian Springs",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20130",
    city: "Paris",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20131",
    city: "Philomont",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20132",
    city: "Purcellville",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20134",
    city: "Purcellville",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20135",
    city: "Bluemont",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20136",
    city: "Bristow",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20137",
    city: "Broad Run",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20138",
    city: "Calverton",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20139",
    city: "Casanova",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20140",
    city: "Rectortown",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20141",
    city: "Round Hill",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20142",
    city: "Round Hill",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20143",
    city: "Catharpin",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20144",
    city: "Delaplane",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20146",
    city: "Ashburn",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20147",
    city: "Ashburn",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20148",
    city: "Ashburn",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20149",
    city: "Ashburn",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20151",
    city: "Chantilly",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20152",
    city: "Chantilly",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20153",
    city: "Chantilly",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20155",
    city: "Gainesville",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20156",
    city: "Gainesville",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20158",
    city: "Hamilton",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20159",
    city: "Hamilton",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20160",
    city: "Lincoln",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20163",
    city: "Sterling",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20164",
    city: "Sterling",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20165",
    city: "Sterling",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20166",
    city: "Sterling",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20167",
    city: "Sterling",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20168",
    city: "Haymarket",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20169",
    city: "Haymarket",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20170",
    city: "Herndon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20171",
    city: "Herndon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20172",
    city: "Herndon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20175",
    city: "Leesburg",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20176",
    city: "Leesburg",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20177",
    city: "Leesburg",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20178",
    city: "Leesburg",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20180",
    city: "Lovettsville",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20181",
    city: "Nokesville",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20182",
    city: "Nokesville",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20184",
    city: "Upperville",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20185",
    city: "Upperville",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20186",
    city: "Warrenton",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20187",
    city: "Warrenton",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20188",
    city: "Warrenton",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20189",
    city: "Dulles",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20190",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20191",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20192",
    city: "Herndon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20194",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20195",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20196",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20197",
    city: "Waterford",
    state: "VA",
    areacode: 540,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20198",
    city: "The Plains",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20201",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20202",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20203",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20204",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20206",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20207",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20208",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20210",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20211",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20212",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20213",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20214",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20215",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20216",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20217",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20218",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20219",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20220",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20221",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20222",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20223",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20224",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20226",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20227",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20228",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20229",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20230",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20232",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20233",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20235",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20237",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20238",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20239",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20240",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20241",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20242",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20244",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20245",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20250",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20251",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20252",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20254",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20260",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20261",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20262",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20265",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20266",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20268",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20270",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20277",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20289",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20299",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20301",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20303",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20306",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20310",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20314",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20317",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20318",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20319",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20330",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20340",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20350",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20355",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20370",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20372",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20373",
    city: "Naval Anacost Annex",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20374",
    city: "Washington Navy Yard",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20375",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20376",
    city: "Washington Navy Yard",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20380",
    city: "Washington",
    state: "DC",
    areacode: 703,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20388",
    city: "Washington Navy Yard",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20389",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20390",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20391",
    city: "Washington Navy Yard",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20392",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20393",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20394",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20395",
    city: "Washington",
    state: "DC",
    areacode: 301,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20398",
    city: "Washington Navy Yard",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20401",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20402",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20403",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20404",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20405",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20406",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20407",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20408",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20410",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20411",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20412",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20413",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20414",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20415",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20416",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20417",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20418",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20419",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20420",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20421",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20422",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20423",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20424",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20425",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20426",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20427",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20428",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20429",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20431",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20433",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20434",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20435",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20436",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20437",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20439",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20440",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20441",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20442",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20444",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20447",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20451",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20453",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20456",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20460",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20463",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20468",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20469",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20470",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20472",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20500",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20501",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20502",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20503",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20504",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20505",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20506",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20507",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20508",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20509",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20510",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20511",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20515",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20520",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20521",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20522",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20523",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20524",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20525",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20526",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20527",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20528",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20529",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20530",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20531",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20533",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20534",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20535",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20536",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20537",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20538",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20539",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20540",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20541",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20542",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20543",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20544",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20546",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20547",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20548",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20549",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20551",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20552",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20553",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20554",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20555",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20557",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20559",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20560",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20565",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20566",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20570",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20571",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20572",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20573",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20575",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20576",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20577",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20578",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20579",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20580",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20581",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20585",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20586",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20588",
    city: "DHS",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20590",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20591",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20593",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20594",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20597",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20598",
    city: "DHS",
    state: "VA",
    areacode: 703,
    county: "Loudoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20599",
    city: "Washington",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20601",
    city: "Waldorf",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20602",
    city: "Waldorf",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20603",
    city: "Waldorf",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20604",
    city: "Waldorf",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20606",
    city: "Abell",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20607",
    city: "Accokeek",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20608",
    city: "Aquasco",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20609",
    city: "Avenue",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20610",
    city: "Barstow",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20611",
    city: "Bel Alton",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20612",
    city: "Benedict",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20613",
    city: "Brandywine",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20615",
    city: "Broomes Island",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20616",
    city: "Bryans Road",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20617",
    city: "Bryantown",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20618",
    city: "Bushwood",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20619",
    city: "California",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20620",
    city: "Callaway",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20621",
    city: "Chaptico",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20622",
    city: "Charlotte Hall",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20623",
    city: "Cheltenham",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20624",
    city: "Clements",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20625",
    city: "Cobb Island",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20626",
    city: "Coltons Point",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20627",
    city: "Compton",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20628",
    city: "Dameron",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20629",
    city: "Dowell",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20630",
    city: "Drayden",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20632",
    city: "Faulkner",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20634",
    city: "Great Mills",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20635",
    city: "Helen",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20636",
    city: "Hollywood",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20637",
    city: "Hughesville",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20639",
    city: "Huntingtown",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20640",
    city: "Indian Head",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20643",
    city: "Ironsides",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20645",
    city: "Issue",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20646",
    city: "La Plata",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20650",
    city: "Leonardtown",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20653",
    city: "Lexington Park",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20656",
    city: "Loveville",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20657",
    city: "Lusby",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20658",
    city: "Marbury",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20659",
    city: "Mechanicsville",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20660",
    city: "Morganza",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20661",
    city: "Mount Victoria",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20662",
    city: "Nanjemoy",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20664",
    city: "Newburg",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20667",
    city: "Park Hall",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20670",
    city: "Patuxent River",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20674",
    city: "Piney Point",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20675",
    city: "Pomfret",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20676",
    city: "Port Republic",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20677",
    city: "Port Tobacco",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20678",
    city: "Prince Frederick",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20680",
    city: "Ridge",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20682",
    city: "Rock Point",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20684",
    city: "Saint Inigoes",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20685",
    city: "Saint Leonard",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20686",
    city: "Saint Marys City",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20687",
    city: "Scotland",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20688",
    city: "Solomons",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20689",
    city: "Sunderland",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20690",
    city: "Tall Timbers",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20692",
    city: "Valley Lee",
    state: "MD",
    areacode: 301,
    county: "Saint Marys",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20693",
    city: "Welcome",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20695",
    city: "White Plains",
    state: "MD",
    areacode: 301,
    county: "Charles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20697",
    city: "Southern MD Facility",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20701",
    city: "Annapolis Junction",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20703",
    city: "Lanham",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20704",
    city: "Beltsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20705",
    city: "Beltsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20706",
    city: "Lanham",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20707",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20708",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20709",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20710",
    city: "Bladensburg",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20711",
    city: "Lothian",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20712",
    city: "Mount Rainier",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20714",
    city: "North Beach",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20715",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20716",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20717",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20718",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20719",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20720",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20721",
    city: "Bowie",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20722",
    city: "Brentwood",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20723",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20724",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20725",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20726",
    city: "Laurel",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20731",
    city: "Capitol Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20732",
    city: "Chesapeake Beach",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20733",
    city: "Churchton",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20735",
    city: "Clinton",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20736",
    city: "Owings",
    state: "MD",
    areacode: 301,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20737",
    city: "Riverdale",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20738",
    city: "Riverdale",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20740",
    city: "College Park",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20741",
    city: "College Park",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20742",
    city: "College Park",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20743",
    city: "Capitol Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20744",
    city: "Fort Washington",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20745",
    city: "Oxon Hill",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20746",
    city: "Suitland",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20747",
    city: "District Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20748",
    city: "Temple Hills",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20749",
    city: "Fort Washington",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20750",
    city: "Oxon Hill",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20751",
    city: "Deale",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20752",
    city: "Suitland",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20753",
    city: "District Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20754",
    city: "Dunkirk",
    state: "MD",
    areacode: 410,
    county: "Calvert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20755",
    city: "Fort George G Meade",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20757",
    city: "Temple Hills",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20758",
    city: "Friendship",
    state: "MD",
    areacode: 301,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20759",
    city: "Fulton",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20762",
    city: "Andrews AFB",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20763",
    city: "Savage",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20764",
    city: "Shady Side",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20765",
    city: "Galesville",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20768",
    city: "Greenbelt",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20769",
    city: "Glenn Dale",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20770",
    city: "Greenbelt",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20771",
    city: "Greenbelt",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20772",
    city: "Upper Marlboro",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20773",
    city: "Upper Marlboro",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20774",
    city: "Upper Marlboro",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20775",
    city: "Upper Marlboro",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20776",
    city: "Harwood",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20777",
    city: "Highland",
    state: "MD",
    areacode: 301,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20778",
    city: "West River",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20779",
    city: "Tracys Landing",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20781",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20782",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20783",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20784",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20785",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20787",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20788",
    city: "Hyattsville",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20790",
    city: "Capitol Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20791",
    city: "Capitol Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20792",
    city: "Upper Marlboro",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20794",
    city: "Jessup",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20797",
    city: "Southern MD Facility",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20799",
    city: "Capitol Heights",
    state: "MD",
    areacode: 301,
    county: "Prince Georges",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20810",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20811",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20812",
    city: "Glen Echo",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20813",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20814",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20815",
    city: "Chevy Chase",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20816",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20817",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20818",
    city: "Cabin John",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20824",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20825",
    city: "Chevy Chase",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20827",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20830",
    city: "Olney",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20832",
    city: "Olney",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20833",
    city: "Brookeville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20837",
    city: "Poolesville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20838",
    city: "Barnesville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20839",
    city: "Beallsville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20841",
    city: "Boyds",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20842",
    city: "Dickerson",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20847",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20848",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20849",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20850",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20851",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20852",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20853",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20854",
    city: "Potomac",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20855",
    city: "Derwood",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20857",
    city: "Rockville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20859",
    city: "Potomac",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20860",
    city: "Sandy Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20861",
    city: "Ashton",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20862",
    city: "Brinklow",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20866",
    city: "Burtonsville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20868",
    city: "Spencerville",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20871",
    city: "Clarksburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20872",
    city: "Damascus",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20874",
    city: "Germantown",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20875",
    city: "Germantown",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20876",
    city: "Germantown",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20877",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20878",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20879",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20880",
    city: "Washington Grove",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20882",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20883",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20884",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20885",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20886",
    city: "Montgomery Village",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20889",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20891",
    city: "Kensington",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20892",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20894",
    city: "Bethesda",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20895",
    city: "Kensington",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20896",
    city: "Garrett Park",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20897",
    city: "Suburb Maryland Fac",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20898",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20899",
    city: "Gaithersburg",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20901",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20902",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20903",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20904",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20905",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20906",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20907",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20908",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20910",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20911",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20912",
    city: "Takoma Park",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20913",
    city: "Takoma Park",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20914",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20915",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20916",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20918",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20993",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "20997",
    city: "Silver Spring",
    state: "MD",
    areacode: 301,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21001",
    city: "Aberdeen",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21005",
    city: "Aberdeen Proving Ground",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21009",
    city: "Abingdon",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21010",
    city: "Gunpowder",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21012",
    city: "Arnold",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21013",
    city: "Baldwin",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21014",
    city: "Bel Air",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21015",
    city: "Bel Air",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21017",
    city: "Belcamp",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21018",
    city: "Benson",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21020",
    city: "Boring",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21022",
    city: "Brooklandville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21023",
    city: "Butler",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21027",
    city: "Chase",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21028",
    city: "Churchville",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21029",
    city: "Clarksville",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21030",
    city: "Cockeysville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21031",
    city: "Hunt Valley",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21032",
    city: "Crownsville",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21034",
    city: "Darlington",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21035",
    city: "Davidsonville",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21036",
    city: "Dayton",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21037",
    city: "Edgewater",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21040",
    city: "Edgewood",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21041",
    city: "Ellicott City",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21042",
    city: "Ellicott City",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21043",
    city: "Ellicott City",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21044",
    city: "Columbia",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21045",
    city: "Columbia",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21046",
    city: "Columbia",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21047",
    city: "Fallston",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21048",
    city: "Finksburg",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21050",
    city: "Forest Hill",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21051",
    city: "Fork",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21052",
    city: "Fort Howard",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21053",
    city: "Freeland",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21054",
    city: "Gambrills",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21056",
    city: "Gibson Island",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21057",
    city: "Glen Arm",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21060",
    city: "Glen Burnie",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21061",
    city: "Glen Burnie",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21062",
    city: "Glen Burnie",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21065",
    city: "Hunt Valley",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21071",
    city: "Glyndon",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21074",
    city: "Hampstead",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21075",
    city: "Elkridge",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21076",
    city: "Hanover",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21077",
    city: "Harmans",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21078",
    city: "Havre de Grace",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21082",
    city: "Hydes",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21084",
    city: "Jarrettsville",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21085",
    city: "Joppa",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21087",
    city: "Kingsville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21088",
    city: "Lineboro",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21090",
    city: "Linthicum Heights",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21092",
    city: "Long Green",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21093",
    city: "Lutherville Timonium",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21094",
    city: "Lutherville Timonium",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21102",
    city: "Manchester",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21104",
    city: "Marriottsville",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21105",
    city: "Maryland Line",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21106",
    city: "Mayo",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21108",
    city: "Millersville",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21111",
    city: "Monkton",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21113",
    city: "Odenton",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21114",
    city: "Crofton",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21117",
    city: "Owings Mills",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21120",
    city: "Parkton",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21122",
    city: "Pasadena",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21123",
    city: "Pasadena",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21128",
    city: "Perry Hall",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21130",
    city: "Perryman",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21131",
    city: "Phoenix",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21132",
    city: "Pylesville",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21133",
    city: "Randallstown",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21136",
    city: "Reisterstown",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21139",
    city: "Riderwood",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21140",
    city: "Riva",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21144",
    city: "Severn",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21146",
    city: "Severna Park",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21150",
    city: "Simpsonville",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21152",
    city: "Sparks Glencoe",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21153",
    city: "Stevenson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21154",
    city: "Street",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21155",
    city: "Upperco",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21156",
    city: "Upper Falls",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21157",
    city: "Westminster",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21158",
    city: "Westminster",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21160",
    city: "Whiteford",
    state: "MD",
    areacode: 410,
    county: "Harford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21161",
    city: "White Hall",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21162",
    city: "White Marsh",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21163",
    city: "Woodstock",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21201",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21202",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21203",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21204",
    city: "Towson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21205",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21206",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21207",
    city: "Gwynn Oak",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21208",
    city: "Pikesville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21209",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21210",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21211",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21212",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21213",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21214",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21215",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21216",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21217",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21218",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21219",
    city: "Sparrows Point",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21220",
    city: "Middle River",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21221",
    city: "Essex",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21222",
    city: "Dundalk",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21223",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21224",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21225",
    city: "Brooklyn",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21226",
    city: "Curtis Bay",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21227",
    city: "Halethorpe",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21228",
    city: "Catonsville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21229",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21230",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21231",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21233",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21234",
    city: "Parkville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21235",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21236",
    city: "Nottingham",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21237",
    city: "Rosedale",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21239",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21240",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21241",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21244",
    city: "Windsor Mill",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21250",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21251",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21252",
    city: "Towson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21263",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21264",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21270",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21273",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21275",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21278",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21279",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21281",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21282",
    city: "Pikesville",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21284",
    city: "Towson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21285",
    city: "Towson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21286",
    city: "Towson",
    state: "MD",
    areacode: 410,
    county: "Baltimore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21287",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21289",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21290",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21297",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21298",
    city: "Baltimore",
    state: "MD",
    areacode: 410,
    county: "Baltimore City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21401",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21402",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21403",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21404",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21405",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21409",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21411",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21412",
    city: "Annapolis",
    state: "MD",
    areacode: 410,
    county: "Anne Arundel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21501",
    city: "Cumberland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21502",
    city: "Cumberland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21503",
    city: "Cumberland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21504",
    city: "Cumberland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21505",
    city: "Cumberland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21520",
    city: "Accident",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21521",
    city: "Barton",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21522",
    city: "Bittinger",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21523",
    city: "Bloomington",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21524",
    city: "Corriganville",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21528",
    city: "Eckhart Mines",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21529",
    city: "Ellerslie",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21530",
    city: "Flintstone",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21531",
    city: "Friendsville",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21532",
    city: "Frostburg",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21536",
    city: "Grantsville",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21538",
    city: "Kitzmiller",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21539",
    city: "Lonaconing",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21540",
    city: "Luke",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21541",
    city: "McHenry",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21542",
    city: "Midland",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21543",
    city: "Midlothian",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21545",
    city: "Mount Savage",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21550",
    city: "Oakland",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21555",
    city: "Oldtown",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21556",
    city: "Pinto",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21557",
    city: "Rawlings",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21560",
    city: "Spring Gap",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21561",
    city: "Swanton",
    state: "MD",
    areacode: 301,
    county: "Garrett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21562",
    city: "Westernport",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21601",
    city: "Easton",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21607",
    city: "Barclay",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21609",
    city: "Bethlehem",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21610",
    city: "Betterton",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21612",
    city: "Bozman",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21613",
    city: "Cambridge",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21617",
    city: "Centreville",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21619",
    city: "Chester",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21620",
    city: "Chestertown",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21622",
    city: "Church Creek",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21623",
    city: "Church Hill",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21624",
    city: "Claiborne",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21625",
    city: "Cordova",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21626",
    city: "Crapo",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21627",
    city: "Crocheron",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21628",
    city: "Crumpton",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21629",
    city: "Denton",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21631",
    city: "East New Market",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21632",
    city: "Federalsburg",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21634",
    city: "Fishing Creek",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21635",
    city: "Galena",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21636",
    city: "Goldsboro",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21638",
    city: "Grasonville",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21639",
    city: "Greensboro",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21640",
    city: "Henderson",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21641",
    city: "Hillsboro",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21643",
    city: "Hurlock",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21644",
    city: "Ingleside",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21645",
    city: "Kennedyville",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21647",
    city: "McDaniel",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21648",
    city: "Madison",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21649",
    city: "Marydel",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21650",
    city: "Massey",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21651",
    city: "Millington",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21652",
    city: "Neavitt",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21653",
    city: "Newcomb",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21654",
    city: "Oxford",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21655",
    city: "Preston",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21656",
    city: "Price",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21657",
    city: "Queen Anne",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21658",
    city: "Queenstown",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21659",
    city: "Rhodesdale",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21660",
    city: "Ridgely",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21661",
    city: "Rock Hall",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21662",
    city: "Royal Oak",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21663",
    city: "Saint Michaels",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21664",
    city: "Secretary",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21665",
    city: "Sherwood",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21666",
    city: "Stevensville",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21667",
    city: "Still Pond",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21668",
    city: "Sudlersville",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21669",
    city: "Taylors Island",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21670",
    city: "Templeville",
    state: "MD",
    areacode: 410,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21671",
    city: "Tilghman",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21672",
    city: "Toddville",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21673",
    city: "Trappe",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21675",
    city: "Wingate",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21676",
    city: "Wittman",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21677",
    city: "Woolford",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21678",
    city: "Worton",
    state: "MD",
    areacode: 410,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21679",
    city: "Wye Mills",
    state: "MD",
    areacode: 410,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21690",
    city: "Chestertown",
    state: "MD",
    areacode: 410,
    county: "Queen Annes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21701",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21702",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21703",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21704",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21705",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21709",
    city: "Frederick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21710",
    city: "Adamstown",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21711",
    city: "Big Pool",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21713",
    city: "Boonsboro",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21714",
    city: "Braddock Heights",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21715",
    city: "Brownsville",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21716",
    city: "Brunswick",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21717",
    city: "Buckeystown",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21718",
    city: "Burkittsville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21719",
    city: "Cascade",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21720",
    city: "Cavetown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21721",
    city: "Chewsville",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21722",
    city: "Clear Spring",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21723",
    city: "Cooksville",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21727",
    city: "Emmitsburg",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21733",
    city: "Fairplay",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21734",
    city: "Funkstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21737",
    city: "Glenelg",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21738",
    city: "Glenwood",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21740",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21741",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21742",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21746",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21747",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21749",
    city: "Hagerstown",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21750",
    city: "Hancock",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21754",
    city: "Ijamsville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21755",
    city: "Jefferson",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21756",
    city: "Keedysville",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21757",
    city: "Keymar",
    state: "MD",
    areacode: 410,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21758",
    city: "Knoxville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21759",
    city: "Ladiesburg",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21762",
    city: "Libertytown",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21765",
    city: "Lisbon",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21766",
    city: "Little Orleans",
    state: "MD",
    areacode: 301,
    county: "Allegany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21767",
    city: "Maugansville",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21769",
    city: "Middletown",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21770",
    city: "Monrovia",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21771",
    city: "Mount Airy",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21773",
    city: "Myersville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21774",
    city: "New Market",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21775",
    city: "New Midway",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21776",
    city: "New Windsor",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21777",
    city: "Point of Rocks",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21778",
    city: "Rocky Ridge",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21779",
    city: "Rohrersville",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21780",
    city: "Sabillasville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21781",
    city: "Saint James",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21782",
    city: "Sharpsburg",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21783",
    city: "Smithsburg",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21784",
    city: "Sykesville",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21787",
    city: "Taneytown",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21788",
    city: "Thurmont",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21790",
    city: "Tuscarora",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21791",
    city: "Union Bridge",
    state: "MD",
    areacode: 410,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21792",
    city: "Unionville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21793",
    city: "Walkersville",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21794",
    city: "West Friendship",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21795",
    city: "Williamsport",
    state: "MD",
    areacode: 301,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21797",
    city: "Woodbine",
    state: "MD",
    areacode: 410,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21798",
    city: "Woodsboro",
    state: "MD",
    areacode: 301,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21801",
    city: "Salisbury",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21802",
    city: "Salisbury",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21803",
    city: "Salisbury",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21804",
    city: "Salisbury",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21810",
    city: "Allen",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21811",
    city: "Berlin",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21813",
    city: "Bishopville",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21814",
    city: "Bivalve",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21817",
    city: "Crisfield",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21821",
    city: "Deal Island",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21822",
    city: "Eden",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21824",
    city: "Ewell",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21826",
    city: "Fruitland",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21829",
    city: "Girdletree",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21830",
    city: "Hebron",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21835",
    city: "Linkwood",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21836",
    city: "Manokin",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21837",
    city: "Mardela Springs",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21838",
    city: "Marion Station",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21840",
    city: "Nanticoke",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21841",
    city: "Newark",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21842",
    city: "Ocean City",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21843",
    city: "Ocean City",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21849",
    city: "Parsonsburg",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21850",
    city: "Pittsville",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21851",
    city: "Pocomoke City",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21852",
    city: "Powellville",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21853",
    city: "Princess Anne",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21856",
    city: "Quantico",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21857",
    city: "Rehobeth",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21861",
    city: "Sharptown",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21862",
    city: "Showell",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21863",
    city: "Snow Hill",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21864",
    city: "Stockton",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21865",
    city: "Tyaskin",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21866",
    city: "Tylerton",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21867",
    city: "Upper Fairmount",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21869",
    city: "Vienna",
    state: "MD",
    areacode: 410,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21871",
    city: "Westover",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21872",
    city: "Whaleyville",
    state: "MD",
    areacode: 410,
    county: "Worcester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21874",
    city: "Willards",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21875",
    city: "Delmar",
    state: "MD",
    areacode: 410,
    county: "Wicomico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21890",
    city: "Westover",
    state: "MD",
    areacode: 410,
    county: "Somerset",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21901",
    city: "North East",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21902",
    city: "Perry Point",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21903",
    city: "Perryville",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21904",
    city: "Port Deposit",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21911",
    city: "Rising Sun",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21912",
    city: "Warwick",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21913",
    city: "Cecilton",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21914",
    city: "Charlestown",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21915",
    city: "Chesapeake City",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21916",
    city: "Childs",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21917",
    city: "Colora",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21918",
    city: "Conowingo",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21919",
    city: "Earleville",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21920",
    city: "Elk Mills",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21921",
    city: "Elkton",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21922",
    city: "Elkton",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "21930",
    city: "Georgetown",
    state: "MD",
    areacode: 410,
    county: "Cecil",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22003",
    city: "Annandale",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22009",
    city: "Burke",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22015",
    city: "Burke",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22025",
    city: "Dumfries",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22026",
    city: "Dumfries",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22027",
    city: "Dunn Loring",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22030",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22031",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22032",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22033",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22034",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22035",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22036",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22037",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22038",
    city: "Fairfax",
    state: "VA",
    areacode: 703,
    county: "Fairfax City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22039",
    city: "Fairfax Station",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22040",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Falls Church City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22041",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22042",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22043",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22044",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22046",
    city: "Falls Church",
    state: "VA",
    areacode: 703,
    county: "Falls Church City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22060",
    city: "Fort Belvoir",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22066",
    city: "Great Falls",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22067",
    city: "Greenway",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22079",
    city: "Lorton",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22081",
    city: "Merrifield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22082",
    city: "Merrifield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22095",
    city: "Herndon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22096",
    city: "Reston",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22101",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22102",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22103",
    city: "West McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22106",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22107",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22108",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22109",
    city: "McLean",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22116",
    city: "Merrifield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22118",
    city: "Merrifield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22119",
    city: "Merrifield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22121",
    city: "Mount Vernon",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22122",
    city: "Newington",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22124",
    city: "Oakton",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22125",
    city: "Occoquan",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22134",
    city: "Quantico",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22135",
    city: "Quantico",
    state: "VA",
    areacode: 703,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22150",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22151",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22152",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22153",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22156",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22158",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22159",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22160",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22161",
    city: "Springfield",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22172",
    city: "Triangle",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22180",
    city: "Vienna",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22181",
    city: "Vienna",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22182",
    city: "Vienna",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22183",
    city: "Vienna",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22185",
    city: "Vienna",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22191",
    city: "Woodbridge",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22192",
    city: "Woodbridge",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22193",
    city: "Woodbridge",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22194",
    city: "Woodbridge",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22195",
    city: "Woodbridge",
    state: "VA",
    areacode: 703,
    county: "Prince William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22199",
    city: "Lorton",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22201",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22202",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22203",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22204",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22205",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22206",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22207",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22209",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22210",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22211",
    city: "Fort Myer",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22212",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22213",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22214",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22215",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22216",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22217",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22219",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22225",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22226",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22227",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22230",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22240",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22241",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22242",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22243",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22244",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22245",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22246",
    city: "Arlington",
    state: "VA",
    areacode: 703,
    county: "Arlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22301",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22302",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22303",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22304",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22305",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22306",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22307",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22308",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22309",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22310",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22311",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22312",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22313",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22314",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22315",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Fairfax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22320",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22331",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22332",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22333",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22334",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22350",
    city: "Alexandria",
    state: "VA",
    areacode: 703,
    county: "Alexandria City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22401",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Fredericksburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22402",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Fredericksburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22403",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22404",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Fredericksburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22405",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22406",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22407",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22408",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22412",
    city: "Fredericksburg",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22427",
    city: "Bowling Green",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22428",
    city: "Bowling Green",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22430",
    city: "Brooke",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22432",
    city: "Burgess",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22433",
    city: "Burr Hill",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22435",
    city: "Callao",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22436",
    city: "Caret",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22437",
    city: "Center Cross",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22438",
    city: "Champlain",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22442",
    city: "Coles Point",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22443",
    city: "Colonial Beach",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22446",
    city: "Corbin",
    state: "VA",
    areacode: 540,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22448",
    city: "Dahlgren",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22451",
    city: "Dogue",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22454",
    city: "Dunnsville",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22456",
    city: "Edwardsville",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22460",
    city: "Farnham",
    state: "VA",
    areacode: 804,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22463",
    city: "Garrisonville",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22469",
    city: "Hague",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22471",
    city: "Hartwood",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22472",
    city: "Haynesville",
    state: "VA",
    areacode: 804,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22473",
    city: "Heathsville",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22476",
    city: "Hustle",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22480",
    city: "Irvington",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22481",
    city: "Jersey",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22482",
    city: "Kilmarnock",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22485",
    city: "King George",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22488",
    city: "Kinsale",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22501",
    city: "Ladysmith",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22503",
    city: "Lancaster",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22504",
    city: "Laneview",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22507",
    city: "Lively",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22508",
    city: "Locust Grove",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22509",
    city: "Loretto",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22511",
    city: "Lottsburg",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22513",
    city: "Merry Point",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22514",
    city: "Milford",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22517",
    city: "Mollusk",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22520",
    city: "Montross",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22523",
    city: "Morattico",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22524",
    city: "Mount Holly",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22526",
    city: "Ninde",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22528",
    city: "Nuttsville",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22529",
    city: "Oldhams",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22530",
    city: "Ophelia",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22534",
    city: "Partlow",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22535",
    city: "Port Royal",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22538",
    city: "Rappahannock Academy",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22539",
    city: "Reedville",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22542",
    city: "Rhoadesville",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22544",
    city: "Rollins Fork",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22545",
    city: "Ruby",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22546",
    city: "Ruther Glen",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22547",
    city: "Sealston",
    state: "VA",
    areacode: 540,
    county: "King George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22548",
    city: "Sharps",
    state: "VA",
    areacode: 804,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22551",
    city: "Spotsylvania",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22552",
    city: "Sparta",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22553",
    city: "Spotsylvania",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22554",
    city: "Stafford",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22555",
    city: "Stafford",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22556",
    city: "Stafford",
    state: "VA",
    areacode: 540,
    county: "Stafford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22558",
    city: "Stratford",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22560",
    city: "Tappahannock",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22565",
    city: "Thornburg",
    state: "VA",
    areacode: 540,
    county: "Spotsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22567",
    city: "Unionville",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22570",
    city: "Village",
    state: "VA",
    areacode: 804,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22572",
    city: "Warsaw",
    state: "VA",
    areacode: 804,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22576",
    city: "Weems",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22577",
    city: "Sandy Point",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22578",
    city: "White Stone",
    state: "VA",
    areacode: 804,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22579",
    city: "Wicomico Church",
    state: "VA",
    areacode: 804,
    county: "Northumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22580",
    city: "Woodford",
    state: "VA",
    areacode: 804,
    county: "Caroline",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22581",
    city: "Zacata",
    state: "VA",
    areacode: 804,
    county: "Westmoreland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22601",
    city: "Winchester",
    state: "VA",
    areacode: 540,
    county: "Winchester City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22602",
    city: "Winchester",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22603",
    city: "Winchester",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22604",
    city: "Winchester",
    state: "VA",
    areacode: 540,
    county: "Winchester City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22610",
    city: "Bentonville",
    state: "VA",
    areacode: 540,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22611",
    city: "Berryville",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22620",
    city: "Boyce",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22622",
    city: "Brucetown",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22623",
    city: "Chester Gap",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22624",
    city: "Clear Brook",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22625",
    city: "Cross Junction",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22626",
    city: "Fishers Hill",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22627",
    city: "Flint Hill",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22630",
    city: "Front Royal",
    state: "VA",
    areacode: 540,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22637",
    city: "Gore",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22639",
    city: "Hume",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22640",
    city: "Huntly",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22641",
    city: "Strasburg",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22642",
    city: "Linden",
    state: "VA",
    areacode: 540,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22643",
    city: "Markham",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22644",
    city: "Maurertown",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22645",
    city: "Middletown",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22646",
    city: "Millwood",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22649",
    city: "Middletown",
    state: "VA",
    areacode: 540,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22650",
    city: "Rileyville",
    state: "VA",
    areacode: 540,
    county: "Page",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22652",
    city: "Fort Valley",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22654",
    city: "Star Tannery",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22655",
    city: "Stephens City",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22656",
    city: "Stephenson",
    state: "VA",
    areacode: 540,
    county: "Frederick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22657",
    city: "Strasburg",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22660",
    city: "Toms Brook",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22663",
    city: "White Post",
    state: "VA",
    areacode: 540,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22664",
    city: "Woodstock",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22701",
    city: "Culpeper",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22709",
    city: "Aroda",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22711",
    city: "Banco",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22712",
    city: "Bealeton",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22713",
    city: "Boston",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22714",
    city: "Brandy Station",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22715",
    city: "Brightwood",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22716",
    city: "Castleton",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22718",
    city: "Elkwood",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22719",
    city: "Etlan",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22720",
    city: "Goldvein",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22722",
    city: "Haywood",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22723",
    city: "Hood",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22724",
    city: "Jeffersonton",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22725",
    city: "Leon",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22726",
    city: "Lignum",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22727",
    city: "Madison",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22728",
    city: "Midland",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22729",
    city: "Mitchells",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22730",
    city: "Oakpark",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22731",
    city: "Pratts",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22732",
    city: "Radiant",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22733",
    city: "Rapidan",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22734",
    city: "Remington",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22735",
    city: "Reva",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22736",
    city: "Richardsville",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22737",
    city: "Rixeyville",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22738",
    city: "Rochelle",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22739",
    city: "Somerville",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22740",
    city: "Sperryville",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22741",
    city: "Stevensburg",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22742",
    city: "Sumerduck",
    state: "VA",
    areacode: 540,
    county: "Fauquier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22743",
    city: "Syria",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22746",
    city: "Viewtown",
    state: "VA",
    areacode: 540,
    county: "Culpeper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22747",
    city: "Washington",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22748",
    city: "Wolftown",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22749",
    city: "Woodville",
    state: "VA",
    areacode: 540,
    county: "Rappahannock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22801",
    city: "Harrisonburg",
    state: "VA",
    areacode: 540,
    county: "Harrisonburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22802",
    city: "Harrisonburg",
    state: "VA",
    areacode: 540,
    county: "Harrisonburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22803",
    city: "Harrisonburg",
    state: "VA",
    areacode: 540,
    county: "Harrisonburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22807",
    city: "Harrisonburg",
    state: "VA",
    areacode: 540,
    county: "Harrisonburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22810",
    city: "Basye",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22811",
    city: "Bergton",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22812",
    city: "Bridgewater",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22815",
    city: "Broadway",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22820",
    city: "Criders",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22821",
    city: "Dayton",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22824",
    city: "Edinburg",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22827",
    city: "Elkton",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22830",
    city: "Fulks Run",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22831",
    city: "Hinton",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22832",
    city: "Keezletown",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22833",
    city: "Lacey Spring",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22834",
    city: "Linville",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22835",
    city: "Luray",
    state: "VA",
    areacode: 540,
    county: "Page",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22840",
    city: "McGaheysville",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22841",
    city: "Mount Crawford",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22842",
    city: "Mount Jackson",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22843",
    city: "Mount Solon",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22844",
    city: "New Market",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22845",
    city: "Orkney Springs",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22846",
    city: "Penn Laird",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22847",
    city: "Quicksburg",
    state: "VA",
    areacode: 540,
    county: "Shenandoah",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22848",
    city: "Pleasant Valley",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22849",
    city: "Shenandoah",
    state: "VA",
    areacode: 540,
    county: "Page",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22850",
    city: "Singers Glen",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22851",
    city: "Stanley",
    state: "VA",
    areacode: 540,
    county: "Page",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22853",
    city: "Timberville",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22901",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22902",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22903",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22904",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22905",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22906",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22907",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22908",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22909",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22910",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Charlottesville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22911",
    city: "Charlottesville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22920",
    city: "Afton",
    state: "VA",
    areacode: 540,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22922",
    city: "Arrington",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22923",
    city: "Barboursville",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22924",
    city: "Batesville",
    state: "VA",
    areacode: 540,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22931",
    city: "Covesville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22932",
    city: "Crozet",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22935",
    city: "Dyke",
    state: "VA",
    areacode: 434,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22936",
    city: "Earlysville",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22937",
    city: "Esmont",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22938",
    city: "Faber",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22939",
    city: "Fishersville",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22940",
    city: "Free Union",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22942",
    city: "Gordonsville",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22943",
    city: "Greenwood",
    state: "VA",
    areacode: 540,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22945",
    city: "Ivy",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22946",
    city: "Keene",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22947",
    city: "Keswick",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22948",
    city: "Locust Dale",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22949",
    city: "Lovingston",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22952",
    city: "Lyndhurst",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22957",
    city: "Montpelier Station",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22958",
    city: "Nellysford",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22959",
    city: "North Garden",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22960",
    city: "Orange",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22963",
    city: "Palmyra",
    state: "VA",
    areacode: 434,
    county: "Fluvanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22964",
    city: "Piney River",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22965",
    city: "Quinque",
    state: "VA",
    areacode: 434,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22967",
    city: "Roseland",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22968",
    city: "Ruckersville",
    state: "VA",
    areacode: 434,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22969",
    city: "Schuyler",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22971",
    city: "Shipman",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22972",
    city: "Somerset",
    state: "VA",
    areacode: 540,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22973",
    city: "Stanardsville",
    state: "VA",
    areacode: 434,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22974",
    city: "Troy",
    state: "VA",
    areacode: 434,
    county: "Fluvanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22976",
    city: "Tyro",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22980",
    city: "Waynesboro",
    state: "VA",
    areacode: 540,
    county: "Waynesboro City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22987",
    city: "White Hall",
    state: "VA",
    areacode: 434,
    county: "Albemarle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "22989",
    city: "Woodberry Forest",
    state: "VA",
    areacode: 540,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23001",
    city: "Achilles",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23002",
    city: "Amelia Court House",
    state: "VA",
    areacode: 804,
    county: "Amelia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23003",
    city: "Ark",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23004",
    city: "Arvonia",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23005",
    city: "Ashland",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23009",
    city: "Aylett",
    state: "VA",
    areacode: 804,
    county: "King William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23011",
    city: "Barhamsville",
    state: "VA",
    areacode: 804,
    county: "New Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23014",
    city: "Beaumont",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23015",
    city: "Beaverdam",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23018",
    city: "Bena",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23021",
    city: "Bohannon",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23022",
    city: "Bremo Bluff",
    state: "VA",
    areacode: 434,
    county: "Fluvanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23023",
    city: "Bruington",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23024",
    city: "Bumpass",
    state: "VA",
    areacode: 540,
    county: "Louisa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23025",
    city: "Cardinal",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23027",
    city: "Cartersville",
    state: "VA",
    areacode: 804,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23030",
    city: "Charles City",
    state: "VA",
    areacode: 804,
    county: "Charles City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23031",
    city: "Christchurch",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23032",
    city: "Church View",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23035",
    city: "Cobbs Creek",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23038",
    city: "Columbia",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23039",
    city: "Crozier",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23040",
    city: "Cumberland",
    state: "VA",
    areacode: 804,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23043",
    city: "Deltaville",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23045",
    city: "Diggs",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23047",
    city: "Doswell",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23050",
    city: "Dutton",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23055",
    city: "Fork Union",
    state: "VA",
    areacode: 434,
    county: "Fluvanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23056",
    city: "Foster",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23058",
    city: "Glen Allen",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23059",
    city: "Glen Allen",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23060",
    city: "Glen Allen",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23061",
    city: "Gloucester",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23062",
    city: "Gloucester Point",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23063",
    city: "Goochland",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23064",
    city: "Grimstead",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23065",
    city: "Gum Spring",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23066",
    city: "Gwynn",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23067",
    city: "Hadensville",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23068",
    city: "Hallieford",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23069",
    city: "Hanover",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23070",
    city: "Hardyville",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23071",
    city: "Hartfield",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23072",
    city: "Hayes",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23075",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23076",
    city: "Hudgins",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23079",
    city: "Jamaica",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23081",
    city: "Jamestown",
    state: "VA",
    areacode: 757,
    county: "James City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23083",
    city: "Jetersville",
    state: "VA",
    areacode: 804,
    county: "Amelia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23084",
    city: "Kents Store",
    state: "VA",
    areacode: 434,
    county: "Fluvanna",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23085",
    city: "King and Queen Court House",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23086",
    city: "King William",
    state: "VA",
    areacode: 804,
    county: "King William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23089",
    city: "Lanexa",
    state: "VA",
    areacode: 804,
    county: "New Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23090",
    city: "Lightfoot",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23091",
    city: "Little Plymouth",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23092",
    city: "Locust Hill",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23093",
    city: "Louisa",
    state: "VA",
    areacode: 540,
    county: "Louisa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23102",
    city: "Maidens",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23103",
    city: "Manakin Sabot",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23105",
    city: "Mannboro",
    state: "VA",
    areacode: 804,
    county: "Amelia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23106",
    city: "Manquin",
    state: "VA",
    areacode: 804,
    county: "King William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23107",
    city: "Maryus",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23108",
    city: "Mascot",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23109",
    city: "Mathews",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23110",
    city: "Mattaponi",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23111",
    city: "Mechanicsville",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23112",
    city: "Midlothian",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23113",
    city: "Midlothian",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23114",
    city: "Midlothian",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23115",
    city: "Millers Tavern",
    state: "VA",
    areacode: 804,
    county: "Essex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23116",
    city: "Mechanicsville",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23117",
    city: "Mineral",
    state: "VA",
    areacode: 540,
    county: "Louisa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23119",
    city: "Moon",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23120",
    city: "Moseley",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23123",
    city: "New Canton",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23124",
    city: "New Kent",
    state: "VA",
    areacode: 804,
    county: "New Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23125",
    city: "New Point",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23126",
    city: "Newtown",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23127",
    city: "Norge",
    state: "VA",
    areacode: 757,
    county: "James City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23128",
    city: "North",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23129",
    city: "Oilville",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23130",
    city: "Onemo",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23131",
    city: "Ordinary",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23138",
    city: "Port Haywood",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23139",
    city: "Powhatan",
    state: "VA",
    areacode: 804,
    county: "Powhatan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23140",
    city: "Providence Forge",
    state: "VA",
    areacode: 804,
    county: "New Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23141",
    city: "Quinton",
    state: "VA",
    areacode: 804,
    county: "New Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23146",
    city: "Rockville",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23147",
    city: "Ruthville",
    state: "VA",
    areacode: 804,
    county: "Charles City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23148",
    city: "Saint Stephens Church",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23149",
    city: "Saluda",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23150",
    city: "Sandston",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23153",
    city: "Sandy Hook",
    state: "VA",
    areacode: 804,
    county: "Goochland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23154",
    city: "Schley",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23155",
    city: "Severn",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23156",
    city: "Shacklefords",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23160",
    city: "State Farm",
    state: "VA",
    areacode: 804,
    county: "Powhatan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23161",
    city: "Stevensville",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23162",
    city: "Studley",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23163",
    city: "Susan",
    state: "VA",
    areacode: 804,
    county: "Mathews",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23168",
    city: "Toano",
    state: "VA",
    areacode: 757,
    county: "James City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23169",
    city: "Topping",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23170",
    city: "Trevilians",
    state: "VA",
    areacode: 540,
    county: "Louisa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23173",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23175",
    city: "Urbanna",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23176",
    city: "Wake",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23177",
    city: "Walkerton",
    state: "VA",
    areacode: 804,
    county: "King and Queen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23178",
    city: "Ware Neck",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23180",
    city: "Water View",
    state: "VA",
    areacode: 804,
    county: "Middlesex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23181",
    city: "West Point",
    state: "VA",
    areacode: 804,
    county: "King William",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23183",
    city: "White Marsh",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23184",
    city: "Wicomico",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23185",
    city: "Williamsburg",
    state: "VA",
    areacode: 757,
    county: "James City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23186",
    city: "Williamsburg",
    state: "VA",
    areacode: 757,
    county: "Williamsburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23187",
    city: "Williamsburg",
    state: "VA",
    areacode: 757,
    county: "Williamsburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23188",
    city: "Williamsburg",
    state: "VA",
    areacode: 757,
    county: "James City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23190",
    city: "Woods Cross Roads",
    state: "VA",
    areacode: 804,
    county: "Gloucester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23192",
    city: "Montpelier",
    state: "VA",
    areacode: 804,
    county: "Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23218",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23219",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23220",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23221",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23222",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23223",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23224",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23225",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23226",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23227",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23228",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23229",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23230",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23231",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23232",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23233",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23234",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23235",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23236",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23237",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23238",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23241",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23242",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23249",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23250",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23255",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23260",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23261",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23269",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23273",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23274",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23276",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23278",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23279",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23282",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23284",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23285",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23286",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23288",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23289",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23290",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23291",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23292",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23293",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23294",
    city: "Henrico",
    state: "VA",
    areacode: 804,
    county: "Henrico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23295",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23297",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23298",
    city: "Richmond",
    state: "VA",
    areacode: 804,
    county: "Richmond City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23301",
    city: "Accomac",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23302",
    city: "Assawoman",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23303",
    city: "Atlantic",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23304",
    city: "Battery Park",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23306",
    city: "Belle Haven",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23307",
    city: "Birdsnest",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23308",
    city: "Bloxom",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23310",
    city: "Cape Charles",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23313",
    city: "Capeville",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23314",
    city: "Carrollton",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23315",
    city: "Carrsville",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23316",
    city: "Cheriton",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23320",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23321",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23322",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23323",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23324",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23325",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23326",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23327",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23328",
    city: "Chesapeake",
    state: "VA",
    areacode: 757,
    county: "Chesapeake City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23336",
    city: "Chincoteague Island",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23337",
    city: "Wallops Island",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23341",
    city: "Craddockville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23345",
    city: "Davis Wharf",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23347",
    city: "Eastville",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23350",
    city: "Exmore",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23354",
    city: "Franktown",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23356",
    city: "Greenbackville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23357",
    city: "Greenbush",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23358",
    city: "Hacksneck",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23359",
    city: "Hallwood",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23389",
    city: "Harborton",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23395",
    city: "Horntown",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23396",
    city: "Oak Hall",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23397",
    city: "Isle of Wight",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23398",
    city: "Jamesville",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23399",
    city: "Jenkins Bridge",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23401",
    city: "Keller",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23404",
    city: "Locustville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23405",
    city: "Machipongo",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23407",
    city: "Mappsville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23408",
    city: "Marionville",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23409",
    city: "Mears",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23410",
    city: "Melfa",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23412",
    city: "Modest Town",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23413",
    city: "Nassawadox",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23414",
    city: "Nelsonia",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23415",
    city: "New Church",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23416",
    city: "Oak Hall",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23417",
    city: "Onancock",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23418",
    city: "Onley",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23419",
    city: "Oyster",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23420",
    city: "Painter",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23421",
    city: "Parksley",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23422",
    city: "Pungoteague",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23423",
    city: "Quinby",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23424",
    city: "Rescue",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23426",
    city: "Sanford",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23427",
    city: "Saxis",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23429",
    city: "Seaview",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23430",
    city: "Smithfield",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23431",
    city: "Smithfield",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23432",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23433",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23434",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23435",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23436",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23437",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23438",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23439",
    city: "Suffolk",
    state: "VA",
    areacode: 757,
    county: "Suffolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23440",
    city: "Tangier",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23441",
    city: "Tasley",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23442",
    city: "Temperanceville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23443",
    city: "Townsend",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23450",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23451",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23452",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23453",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23454",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23455",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23456",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23457",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23458",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23459",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23460",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23461",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23462",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23463",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23464",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23465",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23466",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23467",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23471",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23479",
    city: "Virginia Beach",
    state: "VA",
    areacode: 757,
    county: "Virginia Beach City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23480",
    city: "Wachapreague",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23482",
    city: "Wardtown",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23483",
    city: "Wattsville",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23486",
    city: "Willis Wharf",
    state: "VA",
    areacode: 757,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23487",
    city: "Windsor",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23488",
    city: "Withams",
    state: "VA",
    areacode: 757,
    county: "Accomack",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23501",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23502",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23503",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23504",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23505",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23506",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23507",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23508",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23509",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23510",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23511",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23513",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23514",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23515",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23517",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23518",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23519",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23523",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23529",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23541",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23551",
    city: "Norfolk",
    state: "VA",
    areacode: 757,
    county: "Norfolk City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23601",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23602",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23603",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23604",
    city: "Fort Eustis",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23605",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23606",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23607",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23608",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23609",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23612",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23628",
    city: "Newport News",
    state: "VA",
    areacode: 757,
    county: "Newport News City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23630",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23651",
    city: "Fort Monroe",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23661",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23662",
    city: "Poquoson",
    state: "VA",
    areacode: 757,
    county: "Poquoson City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23663",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23664",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23665",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23666",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23667",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23668",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23669",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23670",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23681",
    city: "Hampton",
    state: "VA",
    areacode: 757,
    county: "Hampton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23690",
    city: "Yorktown",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23691",
    city: "Yorktown",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23692",
    city: "Yorktown",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23693",
    city: "Yorktown",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23694",
    city: "Lackey",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23696",
    city: "Seaford",
    state: "VA",
    areacode: 757,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23701",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23702",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23703",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23704",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23705",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23707",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23708",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23709",
    city: "Portsmouth",
    state: "VA",
    areacode: 757,
    county: "Portsmouth City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23801",
    city: "Fort Lee",
    state: "VA",
    areacode: 804,
    county: "Prince George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23803",
    city: "Petersburg",
    state: "VA",
    areacode: 804,
    county: "Petersburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23804",
    city: "Petersburg",
    state: "VA",
    areacode: 804,
    county: "Petersburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23805",
    city: "Petersburg",
    state: "VA",
    areacode: 804,
    county: "Petersburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23806",
    city: "Virginia State University",
    state: "VA",
    areacode: 804,
    county: "Petersburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23821",
    city: "Alberta",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23822",
    city: "Ammon",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23824",
    city: "Blackstone",
    state: "VA",
    areacode: 434,
    county: "Nottoway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23827",
    city: "Boykins",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23828",
    city: "Branchville",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23829",
    city: "Capron",
    state: "VA",
    areacode: 434,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23830",
    city: "Carson",
    state: "VA",
    areacode: 804,
    county: "Prince George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23831",
    city: "Chester",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23832",
    city: "Chesterfield",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23833",
    city: "Church Road",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23834",
    city: "Colonial Heights",
    state: "VA",
    areacode: 804,
    county: "Colonial Heights City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23836",
    city: "Chester",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23837",
    city: "Courtland",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23838",
    city: "Chesterfield",
    state: "VA",
    areacode: 804,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23839",
    city: "Dendron",
    state: "VA",
    areacode: 757,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23840",
    city: "DeWitt",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23841",
    city: "Dinwiddie",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23842",
    city: "Disputanta",
    state: "VA",
    areacode: 804,
    county: "Prince George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23843",
    city: "Dolphin",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23844",
    city: "Drewryville",
    state: "VA",
    areacode: 434,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23845",
    city: "Ebony",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23846",
    city: "Elberon",
    state: "VA",
    areacode: 757,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23847",
    city: "Emporia",
    state: "VA",
    areacode: 434,
    county: "Emporia City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23850",
    city: "Ford",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23851",
    city: "Franklin",
    state: "VA",
    areacode: 757,
    county: "Franklin City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23856",
    city: "Freeman",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23857",
    city: "Gasburg",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23860",
    city: "Hopewell",
    state: "VA",
    areacode: 804,
    county: "Hopewell City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23866",
    city: "Ivor",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23867",
    city: "Jarratt",
    state: "VA",
    areacode: 434,
    county: "Greensville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23868",
    city: "Lawrenceville",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23870",
    city: "Jarratt",
    state: "VA",
    areacode: 434,
    county: "Greensville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23872",
    city: "McKenney",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23873",
    city: "Meredithville",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23874",
    city: "Newsoms",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23875",
    city: "Prince George",
    state: "VA",
    areacode: 804,
    county: "Prince George",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23876",
    city: "Rawlings",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23878",
    city: "Sedley",
    state: "VA",
    areacode: 757,
    county: "Southampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23879",
    city: "Skippers",
    state: "VA",
    areacode: 434,
    county: "Greensville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23881",
    city: "Spring Grove",
    state: "VA",
    areacode: 757,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23882",
    city: "Stony Creek",
    state: "VA",
    areacode: 434,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23883",
    city: "Surry",
    state: "VA",
    areacode: 757,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23884",
    city: "Sussex",
    state: "VA",
    areacode: 434,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23885",
    city: "Sutherland",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23887",
    city: "Valentines",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23888",
    city: "Wakefield",
    state: "VA",
    areacode: 757,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23889",
    city: "Warfield",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23890",
    city: "Waverly",
    state: "VA",
    areacode: 804,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23891",
    city: "Waverly",
    state: "VA",
    areacode: 804,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23893",
    city: "White Plains",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23894",
    city: "Wilsons",
    state: "VA",
    areacode: 804,
    county: "Dinwiddie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23897",
    city: "Yale",
    state: "VA",
    areacode: 434,
    county: "Sussex",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23898",
    city: "Zuni",
    state: "VA",
    areacode: 757,
    county: "Isle of Wight",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23899",
    city: "Claremont",
    state: "VA",
    areacode: 757,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23901",
    city: "Farmville",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23909",
    city: "Farmville",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23915",
    city: "Baskerville",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23917",
    city: "Boydton",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23919",
    city: "Bracey",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23920",
    city: "Brodnax",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23921",
    city: "Buckingham",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23922",
    city: "Burkeville",
    state: "VA",
    areacode: 434,
    county: "Nottoway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23923",
    city: "Charlotte Court House",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23924",
    city: "Chase City",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23927",
    city: "Clarksville",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23930",
    city: "Crewe",
    state: "VA",
    areacode: 434,
    county: "Nottoway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23934",
    city: "Cullen",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23936",
    city: "Dillwyn",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23937",
    city: "Drakes Branch",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23938",
    city: "Dundas",
    state: "VA",
    areacode: 434,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23939",
    city: "Evergreen",
    state: "VA",
    areacode: 434,
    county: "Appomattox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23941",
    city: "Fort Mitchell",
    state: "VA",
    areacode: 434,
    county: "Lunenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23942",
    city: "Green Bay",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23943",
    city: "Hampden Sydney",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23944",
    city: "Kenbridge",
    state: "VA",
    areacode: 434,
    county: "Lunenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23947",
    city: "Keysville",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23950",
    city: "La Crosse",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23952",
    city: "Lunenburg",
    state: "VA",
    areacode: 434,
    county: "Lunenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23954",
    city: "Meherrin",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23955",
    city: "Nottoway",
    state: "VA",
    areacode: 434,
    county: "Nottoway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23958",
    city: "Pamplin",
    state: "VA",
    areacode: 434,
    county: "Appomattox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23959",
    city: "Phenix",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23960",
    city: "Prospect",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23962",
    city: "Randolph",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23963",
    city: "Red House",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23964",
    city: "Red Oak",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23966",
    city: "Rice",
    state: "VA",
    areacode: 434,
    county: "Prince Edward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23967",
    city: "Saxe",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23968",
    city: "Skipwith",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23970",
    city: "South Hill",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23974",
    city: "Victoria",
    state: "VA",
    areacode: 434,
    county: "Lunenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "23976",
    city: "Wylliesburg",
    state: "VA",
    areacode: 434,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24001",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24002",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24003",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24004",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24005",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24006",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24007",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24008",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24009",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24010",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24011",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24012",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24013",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24014",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24015",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24016",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24017",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24018",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24019",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24020",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24022",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24023",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24024",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24025",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24026",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24027",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24028",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24029",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24030",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24031",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24032",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24033",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24034",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24035",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24036",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24037",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24038",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24040",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24042",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24043",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Roanoke City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24050",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24053",
    city: "Ararat",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24054",
    city: "Axton",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24055",
    city: "Bassett",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24058",
    city: "Belspring",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24059",
    city: "Bent Mountain",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24060",
    city: "Blacksburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24061",
    city: "Blacksburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24062",
    city: "Blacksburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24063",
    city: "Blacksburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24064",
    city: "Blue Ridge",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24065",
    city: "Boones Mill",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24066",
    city: "Buchanan",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24067",
    city: "Callaway",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24068",
    city: "Christiansburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24069",
    city: "Cascade",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24070",
    city: "Catawba",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24072",
    city: "Check",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24073",
    city: "Christiansburg",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24076",
    city: "Claudville",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24077",
    city: "Cloverdale",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24078",
    city: "Collinsville",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24079",
    city: "Copper Hill",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24082",
    city: "Critz",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24083",
    city: "Daleville",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24084",
    city: "Dublin",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24085",
    city: "Eagle Rock",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24086",
    city: "Eggleston",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24087",
    city: "Elliston",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24088",
    city: "Ferrum",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24089",
    city: "Fieldale",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24090",
    city: "Fincastle",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24091",
    city: "Floyd",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24092",
    city: "Glade Hill",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24093",
    city: "Glen Lyn",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24095",
    city: "Goodview",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24101",
    city: "Hardy",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24102",
    city: "Henry",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24104",
    city: "Huddleston",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24105",
    city: "Indian Valley",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24111",
    city: "McCoy",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24112",
    city: "Martinsville",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24113",
    city: "Martinsville",
    state: "VA",
    areacode: 276,
    county: "Martinsville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24114",
    city: "Martinsville",
    state: "VA",
    areacode: 276,
    county: "Martinsville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24115",
    city: "Martinsville",
    state: "VA",
    areacode: 276,
    county: "Martinsville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24120",
    city: "Meadows of Dan",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24121",
    city: "Moneta",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24122",
    city: "Montvale",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24124",
    city: "Narrows",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24126",
    city: "Newbern",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24127",
    city: "New Castle",
    state: "VA",
    areacode: 540,
    county: "Craig",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24128",
    city: "Newport",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24129",
    city: "New River",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24130",
    city: "Oriskany",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24131",
    city: "Paint Bank",
    state: "VA",
    areacode: 540,
    county: "Craig",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24132",
    city: "Parrott",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24133",
    city: "Patrick Springs",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24134",
    city: "Pearisburg",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24136",
    city: "Pembroke",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24137",
    city: "Penhook",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24138",
    city: "Pilot",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24139",
    city: "Pittsville",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24141",
    city: "Radford",
    state: "VA",
    areacode: 540,
    county: "Radford City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24142",
    city: "Radford",
    state: "VA",
    areacode: 540,
    county: "Radford City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24143",
    city: "Radford",
    state: "VA",
    areacode: 540,
    county: "Radford City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24146",
    city: "Redwood",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24147",
    city: "Rich Creek",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24148",
    city: "Ridgeway",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24149",
    city: "Riner",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24150",
    city: "Ripplemead",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24151",
    city: "Rocky Mount",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24153",
    city: "Salem",
    state: "VA",
    areacode: 540,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24155",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24157",
    city: "Roanoke",
    state: "VA",
    areacode: 540,
    county: "Salem",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24161",
    city: "Sandy Level",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24162",
    city: "Shawsville",
    state: "VA",
    areacode: 540,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24165",
    city: "Spencer",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24167",
    city: "Staffordsville",
    state: "VA",
    areacode: 540,
    county: "Giles",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24168",
    city: "Stanleytown",
    state: "VA",
    areacode: 276,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24171",
    city: "Stuart",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24174",
    city: "Thaxton",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24175",
    city: "Troutville",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24176",
    city: "Union Hall",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24177",
    city: "Vesta",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24178",
    city: "Villamont",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24179",
    city: "Vinton",
    state: "VA",
    areacode: 540,
    county: "Roanoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24184",
    city: "Wirtz",
    state: "VA",
    areacode: 540,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24185",
    city: "Woolwine",
    state: "VA",
    areacode: 276,
    county: "Patrick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24201",
    city: "Bristol",
    state: "VA",
    areacode: 276,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24202",
    city: "Bristol",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24203",
    city: "Bristol",
    state: "VA",
    areacode: 276,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24205",
    city: "Bristol",
    state: "VA",
    areacode: 276,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24209",
    city: "Bristol",
    state: "VA",
    areacode: 276,
    county: "Bristol",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24210",
    city: "Abingdon",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24211",
    city: "Abingdon",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24212",
    city: "Abingdon",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24215",
    city: "Andover",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24216",
    city: "Appalachia",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24217",
    city: "Bee",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24218",
    city: "Ben Hur",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24219",
    city: "Big Stone Gap",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24220",
    city: "Birchleaf",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24221",
    city: "Blackwater",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24224",
    city: "Castlewood",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24225",
    city: "Cleveland",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24226",
    city: "Clinchco",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24228",
    city: "Clintwood",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24230",
    city: "Coeburn",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24236",
    city: "Damascus",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24237",
    city: "Dante",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24239",
    city: "Davenport",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24243",
    city: "Dryden",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24244",
    city: "Duffield",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24245",
    city: "Dungannon",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24246",
    city: "East Stone Gap",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24248",
    city: "Ewing",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24250",
    city: "Fort Blackmore",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24251",
    city: "Gate City",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24256",
    city: "Haysi",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24258",
    city: "Hiltons",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24260",
    city: "Honaker",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24263",
    city: "Jonesville",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24265",
    city: "Keokee",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24266",
    city: "Lebanon",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24269",
    city: "McClure",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24270",
    city: "Mendota",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24271",
    city: "Nickelsville",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24272",
    city: "Nora",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24273",
    city: "Norton",
    state: "VA",
    areacode: 276,
    county: "Norton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24277",
    city: "Pennington Gap",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24279",
    city: "Pound",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24280",
    city: "Rosedale",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24281",
    city: "Rose Hill",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24282",
    city: "Saint Charles",
    state: "VA",
    areacode: 276,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24283",
    city: "Saint Paul",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24290",
    city: "Weber City",
    state: "VA",
    areacode: 276,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24292",
    city: "Whitetop",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24293",
    city: "Wise",
    state: "VA",
    areacode: 276,
    county: "Wise",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24301",
    city: "Pulaski",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24311",
    city: "Atkins",
    state: "VA",
    areacode: 276,
    county: "Smyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24312",
    city: "Austinville",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24313",
    city: "Barren Springs",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24314",
    city: "Bastian",
    state: "VA",
    areacode: 276,
    county: "Bland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24315",
    city: "Bland",
    state: "VA",
    areacode: 276,
    county: "Bland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24316",
    city: "Broadford",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24317",
    city: "Cana",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24318",
    city: "Ceres",
    state: "VA",
    areacode: 276,
    county: "Bland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24319",
    city: "Chilhowie",
    state: "VA",
    areacode: 276,
    county: "Smyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24322",
    city: "Cripple Creek",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24323",
    city: "Crockett",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24324",
    city: "Draper",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24325",
    city: "Dugspur",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24326",
    city: "Elk Creek",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24327",
    city: "Emory",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24328",
    city: "Fancy Gap",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24330",
    city: "Fries",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24333",
    city: "Galax",
    state: "VA",
    areacode: 276,
    county: "Galax City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24340",
    city: "Glade Spring",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24343",
    city: "Hillsville",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24347",
    city: "Hiwassee",
    state: "VA",
    areacode: 540,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24348",
    city: "Independence",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24350",
    city: "Ivanhoe",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24351",
    city: "Lambsburg",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24352",
    city: "Laurel Fork",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24354",
    city: "Marion",
    state: "VA",
    areacode: 276,
    county: "Smyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24360",
    city: "Max Meadows",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24361",
    city: "Meadowview",
    state: "VA",
    areacode: 276,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24363",
    city: "Mouth of Wilson",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24366",
    city: "Rocky Gap",
    state: "VA",
    areacode: 276,
    county: "Bland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24368",
    city: "Rural Retreat",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24370",
    city: "Saltville",
    state: "VA",
    areacode: 276,
    county: "Smyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24374",
    city: "Speedwell",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24375",
    city: "Sugar Grove",
    state: "VA",
    areacode: 276,
    county: "Smyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24377",
    city: "Tannersville",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24378",
    city: "Troutdale",
    state: "VA",
    areacode: 276,
    county: "Grayson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24380",
    city: "Willis",
    state: "VA",
    areacode: 540,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24381",
    city: "Woodlawn",
    state: "VA",
    areacode: 276,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24382",
    city: "Wytheville",
    state: "VA",
    areacode: 276,
    county: "Wythe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24401",
    city: "Staunton",
    state: "VA",
    areacode: 540,
    county: "Staunton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24402",
    city: "Staunton",
    state: "VA",
    areacode: 540,
    county: "Staunton City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24411",
    city: "Augusta Springs",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24412",
    city: "Bacova",
    state: "VA",
    areacode: 540,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24413",
    city: "Blue Grass",
    state: "VA",
    areacode: 540,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24415",
    city: "Brownsburg",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24416",
    city: "Buena Vista",
    state: "VA",
    areacode: 540,
    county: "Buena Vista City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24421",
    city: "Churchville",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24422",
    city: "Clifton Forge",
    state: "VA",
    areacode: 540,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24426",
    city: "Covington",
    state: "VA",
    areacode: 540,
    county: "Covington City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24430",
    city: "Craigsville",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24431",
    city: "Crimora",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24432",
    city: "Deerfield",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24433",
    city: "Doe Hill",
    state: "VA",
    areacode: 540,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24435",
    city: "Fairfield",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24437",
    city: "Fort Defiance",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24438",
    city: "Glen Wilton",
    state: "VA",
    areacode: 540,
    county: "Botetourt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24439",
    city: "Goshen",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24440",
    city: "Greenville",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24441",
    city: "Grottoes",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24442",
    city: "Head Waters",
    state: "VA",
    areacode: 540,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24445",
    city: "Hot Springs",
    state: "VA",
    areacode: 540,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24448",
    city: "Iron Gate",
    state: "VA",
    areacode: 540,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24450",
    city: "Lexington",
    state: "VA",
    areacode: 540,
    county: "Lexington City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24457",
    city: "Low Moor",
    state: "VA",
    areacode: 540,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24458",
    city: "McDowell",
    state: "VA",
    areacode: 540,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24459",
    city: "Middlebrook",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24460",
    city: "Millboro",
    state: "VA",
    areacode: 540,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24463",
    city: "Mint Spring",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24464",
    city: "Montebello",
    state: "VA",
    areacode: 540,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24465",
    city: "Monterey",
    state: "VA",
    areacode: 540,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24467",
    city: "Mount Sidney",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24469",
    city: "New Hope",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24471",
    city: "Port Republic",
    state: "VA",
    areacode: 540,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24472",
    city: "Raphine",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24473",
    city: "Rockbridge Baths",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24474",
    city: "Selma",
    state: "VA",
    areacode: 540,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24476",
    city: "Steeles Tavern",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24477",
    city: "Stuarts Draft",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24479",
    city: "Swoope",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24482",
    city: "Verona",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24483",
    city: "Vesuvius",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24484",
    city: "Warm Springs",
    state: "VA",
    areacode: 540,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24485",
    city: "West Augusta",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24486",
    city: "Weyers Cave",
    state: "VA",
    areacode: 540,
    county: "Augusta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24487",
    city: "Williamsville",
    state: "VA",
    areacode: 540,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24501",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24502",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24503",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24504",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24505",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24506",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24513",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24514",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24515",
    city: "Lynchburg",
    state: "VA",
    areacode: 434,
    county: "Lynchburg City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24517",
    city: "Altavista",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24520",
    city: "Alton",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24521",
    city: "Amherst",
    state: "VA",
    areacode: 434,
    county: "Amherst",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24522",
    city: "Appomattox",
    state: "VA",
    areacode: 434,
    county: "Appomattox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24523",
    city: "Bedford",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24526",
    city: "Big Island",
    state: "VA",
    areacode: 434,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24527",
    city: "Blairs",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24528",
    city: "Brookneal",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24529",
    city: "Buffalo Junction",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24530",
    city: "Callands",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24531",
    city: "Chatham",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24533",
    city: "Clifford",
    state: "VA",
    areacode: 434,
    county: "Amherst",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24534",
    city: "Clover",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24535",
    city: "Cluster Springs",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24536",
    city: "Coleman Falls",
    state: "VA",
    areacode: 434,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24538",
    city: "Concord",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24539",
    city: "Crystal Hill",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24540",
    city: "Danville",
    state: "VA",
    areacode: 434,
    county: "Danville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24541",
    city: "Danville",
    state: "VA",
    areacode: 434,
    county: "Danville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24543",
    city: "Danville",
    state: "VA",
    areacode: 434,
    county: "Danville City",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24549",
    city: "Dry Fork",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24550",
    city: "Evington",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24551",
    city: "Forest",
    state: "VA",
    areacode: 434,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24553",
    city: "Gladstone",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24554",
    city: "Gladys",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24555",
    city: "Glasgow",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24556",
    city: "Goode",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24557",
    city: "Gretna",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24558",
    city: "Halifax",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24562",
    city: "Howardsville",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24563",
    city: "Hurt",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24565",
    city: "Java",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24566",
    city: "Keeling",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24569",
    city: "Long Island",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24570",
    city: "Lowry",
    state: "VA",
    areacode: 540,
    county: "Bedford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24571",
    city: "Lynch Station",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24572",
    city: "Madison Heights",
    state: "VA",
    areacode: 434,
    county: "Amherst",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24574",
    city: "Monroe",
    state: "VA",
    areacode: 434,
    county: "Amherst",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24576",
    city: "Naruna",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24577",
    city: "Nathalie",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24578",
    city: "Natural Bridge",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24579",
    city: "Natural Bridge Station",
    state: "VA",
    areacode: 540,
    county: "Rockbridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24580",
    city: "Nelson",
    state: "VA",
    areacode: 434,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24581",
    city: "Norwood",
    state: "VA",
    areacode: 434,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24586",
    city: "Ringgold",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24588",
    city: "Rustburg",
    state: "VA",
    areacode: 434,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24589",
    city: "Scottsburg",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24590",
    city: "Scottsville",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24592",
    city: "South Boston",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24593",
    city: "Spout Spring",
    state: "VA",
    areacode: 434,
    county: "Appomattox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24594",
    city: "Sutherlin",
    state: "VA",
    areacode: 434,
    county: "Pittsylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24595",
    city: "Sweet Briar",
    state: "VA",
    areacode: 434,
    county: "Amherst",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24597",
    city: "Vernon Hill",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24598",
    city: "Virgilina",
    state: "VA",
    areacode: 434,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24599",
    city: "Wingina",
    state: "VA",
    areacode: 434,
    county: "Buckingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24601",
    city: "Amonate",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24602",
    city: "Bandy",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24603",
    city: "Big Rock",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24604",
    city: "Bishop",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24605",
    city: "Bluefield",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24606",
    city: "Boissevain",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24607",
    city: "Breaks",
    state: "VA",
    areacode: 276,
    county: "Dickenson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24608",
    city: "Burkes Garden",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24609",
    city: "Cedar Bluff",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24612",
    city: "Doran",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24613",
    city: "Falls Mills",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24614",
    city: "Grundy",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24619",
    city: "Horsepen",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24620",
    city: "Hurley",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24622",
    city: "Jewell Ridge",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24624",
    city: "Keen Mountain",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24627",
    city: "Mavisdale",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24628",
    city: "Maxie",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24630",
    city: "North Tazewell",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24631",
    city: "Oakwood",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24634",
    city: "Pilgrims Knob",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24635",
    city: "Pocahontas",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24637",
    city: "Pounding Mill",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24639",
    city: "Raven",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24640",
    city: "Red Ash",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24641",
    city: "Richlands",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24646",
    city: "Rowe",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24647",
    city: "Shortt Gap",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24649",
    city: "Swords Creek",
    state: "VA",
    areacode: 276,
    county: "Russell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24651",
    city: "Tazewell",
    state: "VA",
    areacode: 276,
    county: "Tazewell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24656",
    city: "Vansant",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24657",
    city: "Whitewood",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24658",
    city: "Wolford",
    state: "VA",
    areacode: 276,
    county: "Buchanan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24701",
    city: "Bluefield",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24712",
    city: "Athens",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24714",
    city: "Beeson",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24715",
    city: "Bramwell",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24716",
    city: "Bud",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24719",
    city: "Covel",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24724",
    city: "Freeman",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24726",
    city: "Herndon",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24729",
    city: "Hiawatha",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24731",
    city: "Kegley",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24732",
    city: "Kellysville",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24733",
    city: "Lashmeet",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24736",
    city: "Matoaka",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24737",
    city: "Montcalm",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24738",
    city: "Nemours",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24739",
    city: "Princeton",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24740",
    city: "Princeton",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24747",
    city: "Rock",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24751",
    city: "Wolfe",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24801",
    city: "Welch",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24808",
    city: "Anawalt",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24811",
    city: "Avondale",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24813",
    city: "Bartley",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24815",
    city: "Berwind",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24816",
    city: "Big Sandy",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24817",
    city: "Bradshaw",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24818",
    city: "Brenton",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24822",
    city: "Clear Fork",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24823",
    city: "Coal Mountain",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24826",
    city: "Cucumber",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24827",
    city: "Cyclone",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24828",
    city: "Davy",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24829",
    city: "Eckman",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24830",
    city: "Elbert",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24831",
    city: "Elkhorn",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24834",
    city: "Fanrock",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24836",
    city: "Gary",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24839",
    city: "Hanover",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24843",
    city: "Hensley",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24844",
    city: "Iaeger",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24845",
    city: "Ikes Fork",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24846",
    city: "Isaban",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24847",
    city: "Itmann",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24848",
    city: "Jenkinjones",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24849",
    city: "Jesse",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24850",
    city: "Jolo",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24851",
    city: "Justice",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24853",
    city: "Kimball",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24854",
    city: "Kopperston",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24855",
    city: "Kyle",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24857",
    city: "Lynco",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24859",
    city: "Marianna",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24860",
    city: "Matheny",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24861",
    city: "Maybeury",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24862",
    city: "Mohawk",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24866",
    city: "Newhall",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24867",
    city: "New Richmond",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24868",
    city: "Northfork",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24869",
    city: "North Spring",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24870",
    city: "Oceana",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24871",
    city: "Pageton",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24872",
    city: "Panther",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24873",
    city: "Paynesville",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24874",
    city: "Pineville",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24878",
    city: "Premier",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24879",
    city: "Raysal",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24880",
    city: "Rock View",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24881",
    city: "Roderfield",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24882",
    city: "Simon",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24884",
    city: "Squire",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24887",
    city: "Switchback",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24888",
    city: "Thorpe",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24892",
    city: "War",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24894",
    city: "Warriormine",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24895",
    city: "Wilcoe",
    state: "WV",
    areacode: 304,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24898",
    city: "Wyoming",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24901",
    city: "Lewisburg",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24902",
    city: "Fairlea",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24910",
    city: "Alderson",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24915",
    city: "Arbovale",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24916",
    city: "Asbury",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24918",
    city: "Ballard",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24920",
    city: "Bartow",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24924",
    city: "Buckeye",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24925",
    city: "Caldwell",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24927",
    city: "Cass",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24931",
    city: "Crawley",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24934",
    city: "Dunmore",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24935",
    city: "Forest Hill",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24938",
    city: "Frankford",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24941",
    city: "Gap Mills",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24943",
    city: "Grassy Meadows",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24944",
    city: "Green Bank",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24945",
    city: "Greenville",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24946",
    city: "Hillsboro",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24951",
    city: "Lindside",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24954",
    city: "Marlinton",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24957",
    city: "Maxwelton",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24962",
    city: "Pence Springs",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24963",
    city: "Peterstown",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24966",
    city: "Renick",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24970",
    city: "Ronceverte",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24974",
    city: "Secondcreek",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24976",
    city: "Sinks Grove",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24977",
    city: "Smoot",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24981",
    city: "Talcott",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24983",
    city: "Union",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24984",
    city: "Waiteville",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24985",
    city: "Wayside",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24986",
    city: "White Sulphur Springs",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24991",
    city: "Williamsburg",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "24993",
    city: "Wolfcreek",
    state: "WV",
    areacode: 304,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25002",
    city: "Alloy",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25003",
    city: "Alum Creek",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25005",
    city: "Amma",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25007",
    city: "Arnett",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25008",
    city: "Artie",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25009",
    city: "Ashford",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25011",
    city: "Bancroft",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25015",
    city: "Belle",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25019",
    city: "Bickmore",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25021",
    city: "Bim",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25022",
    city: "Blair",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25024",
    city: "Bloomingrose",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25025",
    city: "Blount",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25026",
    city: "Blue Creek",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25028",
    city: "Bob White",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25030",
    city: "Bomont",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25031",
    city: "Boomer",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25033",
    city: "Buffalo",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25035",
    city: "Cabin Creek",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25036",
    city: "Cannelton",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25039",
    city: "Cedar Grove",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25040",
    city: "Charlton Heights",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25043",
    city: "Clay",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25044",
    city: "Clear Creek",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25045",
    city: "Clendenin",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25047",
    city: "Clothier",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25048",
    city: "Colcord",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25049",
    city: "Comfort",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25051",
    city: "Costa",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25053",
    city: "Danville",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25054",
    city: "Dawes",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25057",
    city: "Deep Water",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25059",
    city: "Dixie",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25060",
    city: "Dorothy",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25061",
    city: "Drybranch",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25062",
    city: "Dry Creek",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25063",
    city: "Duck",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25064",
    city: "Dunbar",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25067",
    city: "East Bank",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25070",
    city: "Eleanor",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25071",
    city: "Elkview",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25075",
    city: "Eskdale",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25076",
    city: "Ethel",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25079",
    city: "Falling Rock",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25081",
    city: "Foster",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25082",
    city: "Fraziers Bottom",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25083",
    city: "Gallagher",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25085",
    city: "Gauley Bridge",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25086",
    city: "Glasgow",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25088",
    city: "Glen",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25090",
    city: "Glen Ferris",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25093",
    city: "Gordon",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25102",
    city: "Handley",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25103",
    city: "Hansford",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25106",
    city: "Henderson",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25107",
    city: "Hernshaw",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25108",
    city: "Hewett",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25109",
    city: "Hometown",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25110",
    city: "Hugheston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25111",
    city: "Indore",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25112",
    city: "Institute",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25113",
    city: "Ivydale",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25114",
    city: "Jeffrey",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25115",
    city: "Kanawha Falls",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25118",
    city: "Kimberly",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25119",
    city: "Kincaid",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25121",
    city: "Lake",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25123",
    city: "Leon",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25124",
    city: "Liberty",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25125",
    city: "Lizemores",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25126",
    city: "London",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25130",
    city: "Madison",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25132",
    city: "Mammoth",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25133",
    city: "Maysel",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25134",
    city: "Miami",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25136",
    city: "Montgomery",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25139",
    city: "Mount Carbon",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25140",
    city: "Naoma",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25141",
    city: "Nebo",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25142",
    city: "Nellis",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25143",
    city: "Nitro",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25148",
    city: "Orgas",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25149",
    city: "Ottawa",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25152",
    city: "Page",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25154",
    city: "Peytona",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25156",
    city: "Pinch",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25159",
    city: "Poca",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25160",
    city: "Pond Gap",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25161",
    city: "Powellton",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25162",
    city: "Pratt",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25164",
    city: "Procious",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25165",
    city: "Racine",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25168",
    city: "Red House",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25169",
    city: "Ridgeview",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25173",
    city: "Robson",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25174",
    city: "Rock Creek",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25177",
    city: "Saint Albans",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25180",
    city: "Saxon",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25181",
    city: "Seth",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25183",
    city: "Sharples",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25185",
    city: "Mount Olive",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25186",
    city: "Smithers",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25187",
    city: "Southside",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25193",
    city: "Sylvester",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25201",
    city: "Tad",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25202",
    city: "Tornado",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25203",
    city: "Turtle Creek",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25204",
    city: "Twilight",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25205",
    city: "Uneeda",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25206",
    city: "Van",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25208",
    city: "Wharton",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25209",
    city: "Whitesville",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25211",
    city: "Widen",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25213",
    city: "Winfield",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25214",
    city: "Winifrede",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25231",
    city: "Advent",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25234",
    city: "Arnoldsburg",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25235",
    city: "Chloe",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25239",
    city: "Cottageville",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25241",
    city: "Evans",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25243",
    city: "Gandeeville",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25244",
    city: "Gay",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25245",
    city: "Given",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25247",
    city: "Hartford",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25248",
    city: "Kenna",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25251",
    city: "Left Hand",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25252",
    city: "Le Roy",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25253",
    city: "Letart",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25259",
    city: "Looneyville",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25260",
    city: "Mason",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25261",
    city: "Millstone",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25262",
    city: "Millwood",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25264",
    city: "Mount Alto",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25265",
    city: "New Haven",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25266",
    city: "Newton",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25267",
    city: "Normantown",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25268",
    city: "Orma",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25270",
    city: "Reedy",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25271",
    city: "Ripley",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25275",
    city: "Sandyville",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25276",
    city: "Spencer",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25285",
    city: "Wallback",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25286",
    city: "Walton",
    state: "WV",
    areacode: 304,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25287",
    city: "West Columbia",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25301",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25302",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25303",
    city: "South Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25304",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25305",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25306",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25309",
    city: "South Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25311",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25312",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25313",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25314",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25315",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25317",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25320",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25321",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25322",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25323",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25324",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25325",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25326",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25327",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25328",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25329",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25330",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25331",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25332",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25333",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25334",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25335",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25336",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25337",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25338",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25339",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25350",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25356",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25357",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25358",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25360",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25361",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25362",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25364",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25365",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25375",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25387",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25389",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25392",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25396",
    city: "Charleston",
    state: "WV",
    areacode: 304,
    county: "Kanawha",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25401",
    city: "Martinsburg",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25402",
    city: "Martinsburg",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25403",
    city: "Martinsburg",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25404",
    city: "Martinsburg",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25405",
    city: "Martinsburg",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25410",
    city: "Bakerton",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25411",
    city: "Berkeley Springs",
    state: "WV",
    areacode: 304,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25413",
    city: "Bunker Hill",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25414",
    city: "Charles Town",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25419",
    city: "Falling Waters",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25420",
    city: "Gerrardstown",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25421",
    city: "Glengary",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25422",
    city: "Great Cacapon",
    state: "WV",
    areacode: 304,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25423",
    city: "Halltown",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25425",
    city: "Harpers Ferry",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25427",
    city: "Hedgesville",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25428",
    city: "Inwood",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25430",
    city: "Kearneysville",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25431",
    city: "Levels",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25432",
    city: "Millville",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25434",
    city: "Paw Paw",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25437",
    city: "Points",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25438",
    city: "Ranson",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25440",
    city: "Ridgeway",
    state: "WV",
    areacode: 304,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25441",
    city: "Rippon",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25442",
    city: "Shenandoah Junction",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25443",
    city: "Shepherdstown",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25444",
    city: "Slanesville",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25446",
    city: "Summit Point",
    state: "WV",
    areacode: 304,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25501",
    city: "Alkol",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25502",
    city: "Apple Grove",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25503",
    city: "Ashton",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25504",
    city: "Barboursville",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25505",
    city: "Big Creek",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25506",
    city: "Branchland",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25507",
    city: "Ceredo",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25508",
    city: "Chapmanville",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25510",
    city: "Culloden",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25511",
    city: "Dunlow",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25512",
    city: "East Lynn",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25514",
    city: "Fort Gay",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25515",
    city: "Gallipolis Ferry",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25517",
    city: "Genoa",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25520",
    city: "Glenwood",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25521",
    city: "Griffithsville",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25523",
    city: "Hamlin",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25524",
    city: "Harts",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25526",
    city: "Hurricane",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25529",
    city: "Julian",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25530",
    city: "Kenova",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25534",
    city: "Kiahsville",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25535",
    city: "Lavalette",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25537",
    city: "Lesage",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25540",
    city: "Midkiff",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25541",
    city: "Milton",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25544",
    city: "Myra",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25545",
    city: "Ona",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25547",
    city: "Pecks Mill",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25550",
    city: "Point Pleasant",
    state: "WV",
    areacode: 304,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25555",
    city: "Prichard",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25557",
    city: "Ranger",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25559",
    city: "Salt Rock",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25560",
    city: "Scott Depot",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25562",
    city: "Shoals",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25564",
    city: "Sod",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25565",
    city: "Spurlockville",
    state: "WV",
    areacode: 304,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25567",
    city: "Sumerco",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25569",
    city: "Teays",
    state: "WV",
    areacode: 304,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25570",
    city: "Wayne",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25571",
    city: "West Hamlin",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25572",
    city: "Woodville",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25573",
    city: "Yawkey",
    state: "WV",
    areacode: 304,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25601",
    city: "Logan",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25606",
    city: "Accoville",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25607",
    city: "Amherstdale",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25608",
    city: "Baisden",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25611",
    city: "Bruno",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25612",
    city: "Chauncey",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25614",
    city: "Cora",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25617",
    city: "Davin",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25621",
    city: "Gilbert",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25624",
    city: "Henlawson",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25625",
    city: "Holden",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25628",
    city: "Kistler",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25630",
    city: "Lorado",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25632",
    city: "Lyburn",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25634",
    city: "Mallory",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25635",
    city: "Man",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25637",
    city: "Mount Gay",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25638",
    city: "Omar",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25639",
    city: "Peach Creek",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25644",
    city: "Sarah Ann",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25646",
    city: "Stollings",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25647",
    city: "Switzer",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25649",
    city: "Verdunville",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25650",
    city: "Verner",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25651",
    city: "Wharncliffe",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25652",
    city: "Whitman",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25653",
    city: "Wilkinson",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25654",
    city: "Yolyn",
    state: "WV",
    areacode: 304,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25661",
    city: "Williamson",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25665",
    city: "Borderland",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25666",
    city: "Breeden",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25667",
    city: "Chattaroy",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25669",
    city: "Crum",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25670",
    city: "Delbarton",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25671",
    city: "Dingess",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25672",
    city: "Edgarton",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25674",
    city: "Kermit",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25676",
    city: "Lenore",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25678",
    city: "Matewan",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25685",
    city: "Naugatuck",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25686",
    city: "Newtown",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25688",
    city: "North Matewan",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25690",
    city: "Ragland",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25691",
    city: "Rawl",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25692",
    city: "Red Jacket",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25696",
    city: "Varney",
    state: "WV",
    areacode: 304,
    county: "Mingo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25699",
    city: "Wilsondale",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25701",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25702",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25703",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25704",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25705",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25706",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25707",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25708",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25709",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25710",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25711",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25712",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25713",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25714",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25715",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25716",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25717",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25718",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25719",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25720",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25721",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25722",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25723",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25724",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25725",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25726",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25727",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25728",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25729",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25755",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25770",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25771",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25772",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25773",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25774",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25775",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25776",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25777",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25778",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25779",
    city: "Huntington",
    state: "WV",
    areacode: 304,
    county: "Cabell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25801",
    city: "Beckley",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25802",
    city: "Beckley",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25810",
    city: "Allen Junction",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25811",
    city: "Amigo",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25812",
    city: "Ansted",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25813",
    city: "Beaver",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25817",
    city: "Bolt",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25818",
    city: "Bradley",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25820",
    city: "Camp Creek",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25823",
    city: "Coal City",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25825",
    city: "Cool Ridge",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25826",
    city: "Corinne",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25827",
    city: "Crab Orchard",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25831",
    city: "Danese",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25832",
    city: "Daniels",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25833",
    city: "Dothan",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25836",
    city: "Eccles",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25837",
    city: "Edmond",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25839",
    city: "Fairdale",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25840",
    city: "Fayetteville",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25841",
    city: "Flat Top",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25843",
    city: "Ghent",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25844",
    city: "Glen Daniel",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25845",
    city: "Glen Fork",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25846",
    city: "Glen Jean",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25848",
    city: "Glen Rogers",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25849",
    city: "Glen White",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25851",
    city: "Harper",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25853",
    city: "Helen",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25854",
    city: "Hico",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25855",
    city: "Hilltop",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25857",
    city: "Josephine",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25860",
    city: "Lanark",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25862",
    city: "Lansing",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25864",
    city: "Layland",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25865",
    city: "Lester",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25866",
    city: "Lochgelly",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25868",
    city: "Lookout",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25870",
    city: "Maben",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25871",
    city: "Mabscott",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25873",
    city: "MacArthur",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25875",
    city: "McGraws",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25876",
    city: "Saulsville",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25878",
    city: "Midway",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25879",
    city: "Minden",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25880",
    city: "Mount Hope",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25882",
    city: "Mullens",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25888",
    city: "Mount Hope",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25901",
    city: "Oak Hill",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25902",
    city: "Odd",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25904",
    city: "Pax",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25906",
    city: "Piney View",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25907",
    city: "Prince",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25908",
    city: "Princewick",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25909",
    city: "Prosperity",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25911",
    city: "Raleigh",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25913",
    city: "Ravencliff",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25915",
    city: "Rhodell",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25916",
    city: "Sabine",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25917",
    city: "Scarbro",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25918",
    city: "Shady Spring",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25919",
    city: "Skelton",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25920",
    city: "Slab Fork",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25921",
    city: "Sophia",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25922",
    city: "Spanishburg",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25927",
    city: "Stanaford",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25928",
    city: "Stephenson",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25932",
    city: "Surveyor",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25936",
    city: "Thurmond",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25938",
    city: "Victor",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25942",
    city: "Winona",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25943",
    city: "Wyco",
    state: "WV",
    areacode: 304,
    county: "Wyoming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25951",
    city: "Hinton",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25958",
    city: "Charmco",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25962",
    city: "Rainelle",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25966",
    city: "Green Sulphur Springs",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25969",
    city: "Jumping Branch",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25971",
    city: "Lerona",
    state: "WV",
    areacode: 304,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25972",
    city: "Leslie",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25976",
    city: "Meadow Bridge",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25977",
    city: "Meadow Creek",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25978",
    city: "Nimitz",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25979",
    city: "Pipestem",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25981",
    city: "Quinwood",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25984",
    city: "Rupert",
    state: "WV",
    areacode: 304,
    county: "Greenbrier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25985",
    city: "Sandstone",
    state: "WV",
    areacode: 304,
    county: "Summers",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25986",
    city: "Spring Dale",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "25989",
    city: "White Oak",
    state: "WV",
    areacode: 304,
    county: "Raleigh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26003",
    city: "Wheeling",
    state: "WV",
    areacode: 304,
    county: "Ohio",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26030",
    city: "Beech Bottom",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26031",
    city: "Benwood",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26032",
    city: "Bethany",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26033",
    city: "Cameron",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26034",
    city: "Chester",
    state: "WV",
    areacode: 304,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26035",
    city: "Colliers",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26036",
    city: "Dallas",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26037",
    city: "Follansbee",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26038",
    city: "Glen Dale",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26039",
    city: "Glen Easton",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26040",
    city: "McMechen",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26041",
    city: "Moundsville",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26047",
    city: "New Cumberland",
    state: "WV",
    areacode: 304,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26050",
    city: "Newell",
    state: "WV",
    areacode: 304,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26055",
    city: "Proctor",
    state: "WV",
    areacode: 304,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26056",
    city: "New Manchester",
    state: "WV",
    areacode: 304,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26058",
    city: "Short Creek",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26059",
    city: "Triadelphia",
    state: "WV",
    areacode: 304,
    county: "Ohio",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26060",
    city: "Valley Grove",
    state: "WV",
    areacode: 304,
    county: "Ohio",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26062",
    city: "Weirton",
    state: "WV",
    areacode: 304,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26070",
    city: "Wellsburg",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26074",
    city: "West Liberty",
    state: "WV",
    areacode: 304,
    county: "Ohio",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26075",
    city: "Windsor Heights",
    state: "WV",
    areacode: 304,
    county: "Brooke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26101",
    city: "Parkersburg",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26102",
    city: "Parkersburg",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26103",
    city: "Parkersburg",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26104",
    city: "Parkersburg",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26105",
    city: "Vienna",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26106",
    city: "Parkersburg",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26120",
    city: "Mineral Wells",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26121",
    city: "Mineral Wells",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26133",
    city: "Belleville",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26134",
    city: "Belmont",
    state: "WV",
    areacode: 304,
    county: "Pleasants",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26136",
    city: "Big Bend",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26137",
    city: "Big Springs",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26138",
    city: "Brohard",
    state: "WV",
    areacode: 304,
    county: "Wirt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26141",
    city: "Creston",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26142",
    city: "Davisville",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26143",
    city: "Elizabeth",
    state: "WV",
    areacode: 304,
    county: "Wirt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26146",
    city: "Friendly",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26147",
    city: "Grantsville",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26148",
    city: "Macfarlan",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26149",
    city: "Middlebourne",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26150",
    city: "Mineral Wells",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26151",
    city: "Mount Zion",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26152",
    city: "Munday",
    state: "WV",
    areacode: 304,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26155",
    city: "New Martinsville",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26159",
    city: "Paden City",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26160",
    city: "Palestine",
    state: "WV",
    areacode: 304,
    county: "Wirt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26161",
    city: "Petroleum",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26162",
    city: "Porters Falls",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26164",
    city: "Ravenswood",
    state: "WV",
    areacode: 304,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26167",
    city: "Reader",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26169",
    city: "Rockport",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26170",
    city: "Saint Marys",
    state: "WV",
    areacode: 304,
    county: "Pleasants",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26175",
    city: "Sistersville",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26178",
    city: "Smithville",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26180",
    city: "Walker",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26181",
    city: "Washington",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26184",
    city: "Waverly",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26187",
    city: "Williamstown",
    state: "WV",
    areacode: 304,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26201",
    city: "Buckhannon",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26202",
    city: "Fenwick",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26203",
    city: "Erbacon",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26205",
    city: "Craigsville",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26206",
    city: "Cowen",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26208",
    city: "Camden on Gauley",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26209",
    city: "Snowshoe",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26210",
    city: "Adrian",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26215",
    city: "Cleveland",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26217",
    city: "Diana",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26218",
    city: "French Creek",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26219",
    city: "Frenchton",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26222",
    city: "Hacker Valley",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26224",
    city: "Helvetia",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26228",
    city: "Kanawha Head",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26229",
    city: "Lorentz",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26230",
    city: "Pickens",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26234",
    city: "Rock Cave",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26236",
    city: "Selbyville",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26237",
    city: "Tallmansville",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26238",
    city: "Volga",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26241",
    city: "Elkins",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26250",
    city: "Belington",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26253",
    city: "Beverly",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26254",
    city: "Bowden",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26257",
    city: "Coalton",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26259",
    city: "Dailey",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26260",
    city: "Davis",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26261",
    city: "Richwood",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26263",
    city: "Dryfork",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26264",
    city: "Durbin",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26266",
    city: "Upperglade",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26267",
    city: "Ellamore",
    state: "WV",
    areacode: 304,
    county: "Upshur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26268",
    city: "Glady",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26269",
    city: "Hambleton",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26270",
    city: "Harman",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26271",
    city: "Hendricks",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26273",
    city: "Huttonsville",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26275",
    city: "Junior",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26276",
    city: "Kerens",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26278",
    city: "Mabie",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26280",
    city: "Mill Creek",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26282",
    city: "Monterville",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26283",
    city: "Montrose",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26285",
    city: "Norton",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26287",
    city: "Parsons",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26288",
    city: "Webster Springs",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26289",
    city: "Red Creek",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26291",
    city: "Slatyfork",
    state: "WV",
    areacode: 304,
    county: "Pocahontas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26292",
    city: "Thomas",
    state: "WV",
    areacode: 304,
    county: "Tucker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26293",
    city: "Valley Bend",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26294",
    city: "Valley Head",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26296",
    city: "Whitmer",
    state: "WV",
    areacode: 304,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26298",
    city: "Bergoo",
    state: "WV",
    areacode: 304,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26301",
    city: "Clarksburg",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26302",
    city: "Clarksburg",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26306",
    city: "Clarksburg",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26320",
    city: "Alma",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26321",
    city: "Alum Bridge",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26323",
    city: "Anmoore",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26325",
    city: "Auburn",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26327",
    city: "Berea",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26330",
    city: "Bridgeport",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26335",
    city: "Burnsville",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26337",
    city: "Cairo",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26338",
    city: "Camden",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26339",
    city: "Center Point",
    state: "WV",
    areacode: 304,
    county: "Doddridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26342",
    city: "Coxs Mills",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26343",
    city: "Crawford",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26346",
    city: "Ellenboro",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26347",
    city: "Flemington",
    state: "WV",
    areacode: 304,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26348",
    city: "Folsom",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26349",
    city: "Galloway",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26351",
    city: "Glenville",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26354",
    city: "Grafton",
    state: "WV",
    areacode: 304,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26361",
    city: "Gypsy",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26362",
    city: "Harrisville",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26366",
    city: "Haywood",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26369",
    city: "Hepzibah",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26372",
    city: "Horner",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26374",
    city: "Independence",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26376",
    city: "Ireland",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26377",
    city: "Jacksonburg",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26378",
    city: "Jane Lew",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26384",
    city: "Linn",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26385",
    city: "Lost Creek",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26386",
    city: "Lumberport",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26404",
    city: "Meadowbrook",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26405",
    city: "Moatsville",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26408",
    city: "Mount Clare",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26410",
    city: "Newburg",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26411",
    city: "New Milton",
    state: "WV",
    areacode: 304,
    county: "Doddridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26412",
    city: "Orlando",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26415",
    city: "Pennsboro",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26416",
    city: "Philippi",
    state: "WV",
    areacode: 304,
    county: "Barbour",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26419",
    city: "Pine Grove",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26421",
    city: "Pullman",
    state: "WV",
    areacode: 304,
    county: "Ritchie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26422",
    city: "Reynoldsville",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26424",
    city: "Rosemont",
    state: "WV",
    areacode: 304,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26425",
    city: "Rowlesburg",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26426",
    city: "Salem",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26430",
    city: "Sand Fork",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26431",
    city: "Shinnston",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26434",
    city: "Shirley",
    state: "WV",
    areacode: 304,
    county: "Tyler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26435",
    city: "Simpson",
    state: "WV",
    areacode: 304,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26436",
    city: "Smithburg",
    state: "WV",
    areacode: 304,
    county: "Doddridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26437",
    city: "Smithfield",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26438",
    city: "Spelter",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26440",
    city: "Thornton",
    state: "WV",
    areacode: 304,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26443",
    city: "Troy",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26444",
    city: "Tunnelton",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26447",
    city: "Walkersville",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26448",
    city: "Wallace",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26451",
    city: "West Milford",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26452",
    city: "Weston",
    state: "WV",
    areacode: 304,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26456",
    city: "West Union",
    state: "WV",
    areacode: 304,
    county: "Doddridge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26463",
    city: "Wyatt",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26501",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26502",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26504",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26505",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26506",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26507",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26508",
    city: "Morgantown",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26519",
    city: "Albright",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26520",
    city: "Arthurdale",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26521",
    city: "Blacksville",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26524",
    city: "Bretz",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26525",
    city: "Bruceton Mills",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26527",
    city: "Cassville",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26531",
    city: "Dellslow",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26534",
    city: "Granville",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26537",
    city: "Kingwood",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26541",
    city: "Maidsville",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26542",
    city: "Masontown",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26543",
    city: "Osage",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26544",
    city: "Pentress",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26546",
    city: "Pursglove",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26547",
    city: "Reedsville",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26554",
    city: "Fairmont",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26555",
    city: "Fairmont",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26559",
    city: "Barrackville",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26560",
    city: "Baxter",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26561",
    city: "Big Run",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26562",
    city: "Burton",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26563",
    city: "Carolina",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26566",
    city: "Colfax",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26568",
    city: "Enterprise",
    state: "WV",
    areacode: 304,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26570",
    city: "Fairview",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26571",
    city: "Farmington",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26572",
    city: "Four States",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26574",
    city: "Grant Town",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26575",
    city: "Hundred",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26576",
    city: "Idamay",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26578",
    city: "Kingmont",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26581",
    city: "Littleton",
    state: "WV",
    areacode: 304,
    county: "Wetzel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26582",
    city: "Mannington",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26585",
    city: "Metz",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26586",
    city: "Montana Mines",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26587",
    city: "Rachel",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26588",
    city: "Rivesville",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26590",
    city: "Wana",
    state: "WV",
    areacode: 304,
    county: "Monongalia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26591",
    city: "Worthington",
    state: "WV",
    areacode: 304,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26601",
    city: "Sutton",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26610",
    city: "Birch River",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26611",
    city: "Cedarville",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26615",
    city: "Copen",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26617",
    city: "Dille",
    state: "WV",
    areacode: 304,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26619",
    city: "Exchange",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26621",
    city: "Flatwoods",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26623",
    city: "Frametown",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26624",
    city: "Gassaway",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26627",
    city: "Heaters",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26629",
    city: "Little Birch",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26631",
    city: "Napier",
    state: "WV",
    areacode: 304,
    county: "Braxton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26636",
    city: "Rosedale",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26638",
    city: "Shock",
    state: "WV",
    areacode: 304,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26651",
    city: "Summersville",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26656",
    city: "Belva",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26660",
    city: "Calvin",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26662",
    city: "Canvas",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26667",
    city: "Drennen",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26671",
    city: "Gilboa",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26675",
    city: "Keslers Cross Lanes",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26676",
    city: "Leivasy",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26678",
    city: "Mount Lookout",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26679",
    city: "Mount Nebo",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26680",
    city: "Nallen",
    state: "WV",
    areacode: 304,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26681",
    city: "Nettie",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26684",
    city: "Pool",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26690",
    city: "Swiss",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26691",
    city: "Tioga",
    state: "WV",
    areacode: 304,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26704",
    city: "Augusta",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26705",
    city: "Aurora",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26707",
    city: "Bayard",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26710",
    city: "Burlington",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26711",
    city: "Capon Bridge",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26714",
    city: "Delray",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26716",
    city: "Eglon",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26717",
    city: "Elk Garden",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26719",
    city: "Fort Ashby",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26720",
    city: "Gormania",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26722",
    city: "Green Spring",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26726",
    city: "Keyser",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26731",
    city: "Lahmansville",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26739",
    city: "Mount Storm",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26743",
    city: "New Creek",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26750",
    city: "Piedmont",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26753",
    city: "Ridgeley",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26755",
    city: "Rio",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26757",
    city: "Romney",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26761",
    city: "Shanks",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26763",
    city: "Springfield",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26764",
    city: "Terra Alta",
    state: "WV",
    areacode: 304,
    county: "Preston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26767",
    city: "Wiley Ford",
    state: "WV",
    areacode: 304,
    county: "Mineral",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26801",
    city: "Baker",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26802",
    city: "Brandywine",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26804",
    city: "Circleville",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26807",
    city: "Franklin",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26808",
    city: "High View",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26810",
    city: "Lost City",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26812",
    city: "Mathias",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26814",
    city: "Riverton",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26815",
    city: "Sugar Grove",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26817",
    city: "Bloomery",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26818",
    city: "Fisher",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26823",
    city: "Capon Springs",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26833",
    city: "Maysville",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26836",
    city: "Moorefield",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26838",
    city: "Milam",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26845",
    city: "Old Fields",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26847",
    city: "Petersburg",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26851",
    city: "Wardensville",
    state: "WV",
    areacode: 304,
    county: "Hardy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26852",
    city: "Purgitsville",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26855",
    city: "Cabins",
    state: "WV",
    areacode: 304,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26865",
    city: "Yellow Spring",
    state: "WV",
    areacode: 304,
    county: "Hampshire",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26866",
    city: "Upper Tract",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26884",
    city: "Seneca Rocks",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "26886",
    city: "Onego",
    state: "WV",
    areacode: 304,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27006",
    city: "Advance",
    state: "NC",
    areacode: 336,
    county: "Davie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27007",
    city: "Ararat",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27009",
    city: "Belews Creek",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27010",
    city: "Bethania",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27011",
    city: "Boonville",
    state: "NC",
    areacode: 336,
    county: "Yadkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27012",
    city: "Clemmons",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27013",
    city: "Cleveland",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27014",
    city: "Cooleemee",
    state: "NC",
    areacode: 336,
    county: "Davie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27016",
    city: "Danbury",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27017",
    city: "Dobson",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27018",
    city: "East Bend",
    state: "NC",
    areacode: 336,
    county: "Yadkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27019",
    city: "Germanton",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27020",
    city: "Hamptonville",
    state: "NC",
    areacode: 336,
    county: "Yadkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27021",
    city: "King",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27022",
    city: "Lawsonville",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27023",
    city: "Lewisville",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27024",
    city: "Lowgap",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27025",
    city: "Madison",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27027",
    city: "Mayodan",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27028",
    city: "Mocksville",
    state: "NC",
    areacode: 336,
    county: "Davie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27030",
    city: "Mount Airy",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27031",
    city: "Mount Airy",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27040",
    city: "Pfafftown",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27041",
    city: "Pilot Mountain",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27042",
    city: "Pine Hall",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27043",
    city: "Pinnacle",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27045",
    city: "Rural Hall",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27046",
    city: "Sandy Ridge",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27047",
    city: "Siloam",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27048",
    city: "Stoneville",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27049",
    city: "Toast",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27050",
    city: "Tobaccoville",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27051",
    city: "Walkertown",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27052",
    city: "Walnut Cove",
    state: "NC",
    areacode: 336,
    county: "Stokes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27053",
    city: "Westfield",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27054",
    city: "Woodleaf",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27055",
    city: "Yadkinville",
    state: "NC",
    areacode: 336,
    county: "Yadkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27094",
    city: "Rural Hall",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27098",
    city: "Rural Hall",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27099",
    city: "Rural Hall",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27101",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27102",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27103",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27104",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27105",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27106",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27107",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27108",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27109",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27110",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27111",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27113",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27114",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27115",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27116",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27117",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27120",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27127",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27130",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27150",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27152",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27155",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27157",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27198",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27199",
    city: "Winston Salem",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27201",
    city: "Alamance",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27202",
    city: "Altamahaw",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27203",
    city: "Asheboro",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27204",
    city: "Asheboro",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27205",
    city: "Asheboro",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27207",
    city: "Bear Creek",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27208",
    city: "Bennett",
    state: "NC",
    areacode: 336,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27209",
    city: "Biscoe",
    state: "NC",
    areacode: 910,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27212",
    city: "Blanch",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27213",
    city: "Bonlee",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27214",
    city: "Browns Summit",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27215",
    city: "Burlington",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27216",
    city: "Burlington",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27217",
    city: "Burlington",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27228",
    city: "Bynum",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27229",
    city: "Candor",
    state: "NC",
    areacode: 910,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27230",
    city: "Cedar Falls",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27231",
    city: "Cedar Grove",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27233",
    city: "Climax",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27235",
    city: "Colfax",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27237",
    city: "Cumnock",
    state: "NC",
    areacode: 336,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27239",
    city: "Denton",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27242",
    city: "Eagle Springs",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27243",
    city: "Efland",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27244",
    city: "Elon",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27247",
    city: "Ether",
    state: "NC",
    areacode: 910,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27248",
    city: "Franklinville",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27249",
    city: "Gibsonville",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27252",
    city: "Goldston",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27253",
    city: "Graham",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27256",
    city: "Gulf",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27258",
    city: "Haw River",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27259",
    city: "Highfalls",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27260",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27261",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27262",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27263",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27264",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27265",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27268",
    city: "High Point",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27278",
    city: "Hillsborough",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27281",
    city: "Jackson Springs",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27282",
    city: "Jamestown",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27283",
    city: "Julian",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27284",
    city: "Kernersville",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27285",
    city: "Kernersville",
    state: "NC",
    areacode: 336,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27288",
    city: "Eden",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27289",
    city: "Eden",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27291",
    city: "Leasburg",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27292",
    city: "Lexington",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27293",
    city: "Lexington",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27294",
    city: "Lexington",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27295",
    city: "Lexington",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27298",
    city: "Liberty",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27299",
    city: "Linwood",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27301",
    city: "McLeansville",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27302",
    city: "Mebane",
    state: "NC",
    areacode: 919,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27305",
    city: "Milton",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27306",
    city: "Mount Gilead",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27310",
    city: "Oak Ridge",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27311",
    city: "Pelham",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27312",
    city: "Pittsboro",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27313",
    city: "Pleasant Garden",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27314",
    city: "Prospect Hill",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27315",
    city: "Providence",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27316",
    city: "Ramseur",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27317",
    city: "Randleman",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27320",
    city: "Reidsville",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27323",
    city: "Reidsville",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27325",
    city: "Robbins",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27326",
    city: "Ruffin",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27330",
    city: "Sanford",
    state: "NC",
    areacode: 919,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27331",
    city: "Sanford",
    state: "NC",
    areacode: 919,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27332",
    city: "Sanford",
    state: "NC",
    areacode: 919,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27340",
    city: "Saxapahaw",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27341",
    city: "Seagrove",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27342",
    city: "Sedalia",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27343",
    city: "Semora",
    state: "NC",
    areacode: 336,
    county: "Person",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27344",
    city: "Siler City",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27349",
    city: "Snow Camp",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27350",
    city: "Sophia",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27351",
    city: "Southmont",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27355",
    city: "Staley",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27356",
    city: "Star",
    state: "NC",
    areacode: 910,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27357",
    city: "Stokesdale",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27358",
    city: "Summerfield",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27359",
    city: "Swepsonville",
    state: "NC",
    areacode: 336,
    county: "Alamance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27360",
    city: "Thomasville",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27361",
    city: "Thomasville",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27370",
    city: "Trinity",
    state: "NC",
    areacode: 336,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27371",
    city: "Troy",
    state: "NC",
    areacode: 910,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27373",
    city: "Wallburg",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27374",
    city: "Welcome",
    state: "NC",
    areacode: 336,
    county: "Davidson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27375",
    city: "Wentworth",
    state: "NC",
    areacode: 336,
    county: "Rockingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27376",
    city: "West End",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27377",
    city: "Whitsett",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27379",
    city: "Yanceyville",
    state: "NC",
    areacode: 336,
    county: "Caswell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27401",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27402",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27403",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27404",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27405",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27406",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27407",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27408",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27409",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27410",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27411",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27412",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27413",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27415",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27416",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27417",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27419",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27420",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27425",
    city: "Greensboro",
    state: "NC",
    areacode: 704,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27427",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27429",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27435",
    city: "Greensboro",
    state: "NC",
    areacode: 704,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27438",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27455",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27495",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27497",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27498",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27499",
    city: "Greensboro",
    state: "NC",
    areacode: 336,
    county: "Guilford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27501",
    city: "Angier",
    state: "NC",
    areacode: 919,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27502",
    city: "Apex",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27503",
    city: "Bahama",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27504",
    city: "Benson",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27505",
    city: "Broadway",
    state: "NC",
    areacode: 919,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27506",
    city: "Buies Creek",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27507",
    city: "Bullock",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27508",
    city: "Bunn",
    state: "NC",
    areacode: 919,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27509",
    city: "Butner",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27510",
    city: "Carrboro",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27511",
    city: "Cary",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27512",
    city: "Cary",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27513",
    city: "Cary",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27514",
    city: "Chapel Hill",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27515",
    city: "Chapel Hill",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27516",
    city: "Chapel Hill",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27517",
    city: "Chapel Hill",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27518",
    city: "Cary",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27519",
    city: "Cary",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27520",
    city: "Clayton",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27521",
    city: "Coats",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27522",
    city: "Creedmoor",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27523",
    city: "Apex",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27524",
    city: "Four Oaks",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27525",
    city: "Franklinton",
    state: "NC",
    areacode: 919,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27526",
    city: "Fuquay Varina",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27527",
    city: "Clayton",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27528",
    city: "Clayton",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27529",
    city: "Garner",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27530",
    city: "Goldsboro",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27531",
    city: "Goldsboro",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27532",
    city: "Goldsboro",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27533",
    city: "Goldsboro",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27534",
    city: "Goldsboro",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27536",
    city: "Henderson",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27537",
    city: "Henderson",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27539",
    city: "Apex",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27540",
    city: "Holly Springs",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27541",
    city: "Hurdle Mills",
    state: "NC",
    areacode: 336,
    county: "Person",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27542",
    city: "Kenly",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27543",
    city: "Kipling",
    state: "NC",
    areacode: 919,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27544",
    city: "Kittrell",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27545",
    city: "Knightdale",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27546",
    city: "Lillington",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27549",
    city: "Louisburg",
    state: "NC",
    areacode: 919,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27551",
    city: "Macon",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27552",
    city: "Mamers",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27553",
    city: "Manson",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27555",
    city: "Micro",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27556",
    city: "Middleburg",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27557",
    city: "Middlesex",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27559",
    city: "Moncure",
    state: "NC",
    areacode: 919,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27560",
    city: "Morrisville",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27562",
    city: "New Hill",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27563",
    city: "Norlina",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27565",
    city: "Oxford",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27568",
    city: "Pine Level",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27569",
    city: "Princeton",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27570",
    city: "Ridgeway",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27571",
    city: "Rolesville",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27572",
    city: "Rougemont",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27573",
    city: "Roxboro",
    state: "NC",
    areacode: 336,
    county: "Person",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27574",
    city: "Roxboro",
    state: "NC",
    areacode: 336,
    county: "Person",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27576",
    city: "Selma",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27577",
    city: "Smithfield",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27581",
    city: "Stem",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27582",
    city: "Stovall",
    state: "NC",
    areacode: 919,
    county: "Granville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27583",
    city: "Timberlake",
    state: "NC",
    areacode: 336,
    county: "Person",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27584",
    city: "Townsville",
    state: "NC",
    areacode: 252,
    county: "Vance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27586",
    city: "Vaughan",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27587",
    city: "Wake Forest",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27588",
    city: "Wake Forest",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27589",
    city: "Warrenton",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27591",
    city: "Wendell",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27592",
    city: "Willow Spring",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27593",
    city: "Wilsons Mills",
    state: "NC",
    areacode: 919,
    county: "Johnston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27594",
    city: "Wise",
    state: "NC",
    areacode: 252,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27596",
    city: "Youngsville",
    state: "NC",
    areacode: 919,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27597",
    city: "Zebulon",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27599",
    city: "Chapel Hill",
    state: "NC",
    areacode: 919,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27601",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27602",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27603",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27604",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27605",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27606",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27607",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27608",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27609",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27610",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27611",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27612",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27613",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27614",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27615",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27616",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27617",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27619",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27620",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27622",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27623",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27624",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27625",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27626",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27627",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27628",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27629",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27634",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27635",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27636",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27640",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27650",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27656",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27658",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27661",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27668",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27675",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27676",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27690",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27695",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27697",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27698",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27699",
    city: "Raleigh",
    state: "NC",
    areacode: 919,
    county: "Wake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27701",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27702",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27703",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27704",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27705",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27706",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27707",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27708",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27709",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27710",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27711",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27712",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27713",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27715",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27717",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27722",
    city: "Durham",
    state: "NC",
    areacode: 919,
    county: "Durham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27801",
    city: "Rocky Mount",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27802",
    city: "Rocky Mount",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27803",
    city: "Rocky Mount",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27804",
    city: "Rocky Mount",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27805",
    city: "Aulander",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27806",
    city: "Aurora",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27807",
    city: "Bailey",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27808",
    city: "Bath",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27809",
    city: "Battleboro",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27810",
    city: "Belhaven",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27811",
    city: "Bellarthur",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27812",
    city: "Bethel",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27813",
    city: "Black Creek",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27814",
    city: "Blounts Creek",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27815",
    city: "Rocky Mount",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27816",
    city: "Castalia",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27817",
    city: "Chocowinity",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27818",
    city: "Como",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27819",
    city: "Conetoe",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27820",
    city: "Conway",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27821",
    city: "Edward",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27822",
    city: "Elm City",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27823",
    city: "Enfield",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27824",
    city: "Engelhard",
    state: "NC",
    areacode: 252,
    county: "Hyde",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27825",
    city: "Everetts",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27826",
    city: "Fairfield",
    state: "NC",
    areacode: 252,
    county: "Hyde",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27827",
    city: "Falkland",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27828",
    city: "Farmville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27829",
    city: "Fountain",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27830",
    city: "Fremont",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27831",
    city: "Garysburg",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27832",
    city: "Gaston",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27833",
    city: "Greenville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27834",
    city: "Greenville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27835",
    city: "Greenville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27836",
    city: "Greenville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27837",
    city: "Grimesland",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27839",
    city: "Halifax",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27840",
    city: "Hamilton",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27841",
    city: "Hassell",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27842",
    city: "Henrico",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27843",
    city: "Hobgood",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27844",
    city: "Hollister",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27845",
    city: "Jackson",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27846",
    city: "Jamesville",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27847",
    city: "Kelford",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27849",
    city: "Lewiston Woodville",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27850",
    city: "Littleton",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27851",
    city: "Lucama",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27852",
    city: "Macclesfield",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27853",
    city: "Margarettsville",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27855",
    city: "Murfreesboro",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27856",
    city: "Nashville",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27857",
    city: "Oak City",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27858",
    city: "Greenville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27860",
    city: "Pantego",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27861",
    city: "Parmele",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27862",
    city: "Pendleton",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27863",
    city: "Pikeville",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27864",
    city: "Pinetops",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27865",
    city: "Pinetown",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27866",
    city: "Pleasant Hill",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27867",
    city: "Potecasi",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27868",
    city: "Red Oak",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27869",
    city: "Rich Square",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27870",
    city: "Roanoke Rapids",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27871",
    city: "Robersonville",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27872",
    city: "Roxobel",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27873",
    city: "Saratoga",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27874",
    city: "Scotland Neck",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27875",
    city: "Scranton",
    state: "NC",
    areacode: 252,
    county: "Hyde",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27876",
    city: "Seaboard",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27877",
    city: "Severn",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27878",
    city: "Sharpsburg",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27879",
    city: "Simpson",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27880",
    city: "Sims",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27881",
    city: "Speed",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27882",
    city: "Spring Hope",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27883",
    city: "Stantonsburg",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27884",
    city: "Stokes",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27885",
    city: "Swanquarter",
    state: "NC",
    areacode: 252,
    county: "Hyde",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27886",
    city: "Tarboro",
    state: "NC",
    areacode: 252,
    county: "Edgecombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27887",
    city: "Tillery",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27888",
    city: "Walstonburg",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27889",
    city: "Washington",
    state: "NC",
    areacode: 252,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27890",
    city: "Weldon",
    state: "NC",
    areacode: 252,
    county: "Halifax",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27891",
    city: "Whitakers",
    state: "NC",
    areacode: 252,
    county: "Nash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27892",
    city: "Williamston",
    state: "NC",
    areacode: 252,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27893",
    city: "Wilson",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27894",
    city: "Wilson",
    state: "NC",
    areacode: 919,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27895",
    city: "Wilson",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27896",
    city: "Wilson",
    state: "NC",
    areacode: 252,
    county: "Wilson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27897",
    city: "Woodland",
    state: "NC",
    areacode: 252,
    county: "Northampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27906",
    city: "Elizabeth City",
    state: "NC",
    areacode: 252,
    county: "Pasquotank",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27907",
    city: "Elizabeth City",
    state: "NC",
    areacode: 252,
    county: "Pasquotank",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27909",
    city: "Elizabeth City",
    state: "NC",
    areacode: 252,
    county: "Pasquotank",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27910",
    city: "Ahoskie",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27915",
    city: "Avon",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27916",
    city: "Aydlett",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27917",
    city: "Barco",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27919",
    city: "Belvidere",
    state: "NC",
    areacode: 252,
    county: "Perquimans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27920",
    city: "Buxton",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27921",
    city: "Camden",
    state: "NC",
    areacode: 252,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27922",
    city: "Cofield",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27923",
    city: "Coinjock",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27924",
    city: "Colerain",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27925",
    city: "Columbia",
    state: "NC",
    areacode: 252,
    county: "Tyrrell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27926",
    city: "Corapeake",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27927",
    city: "Corolla",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27928",
    city: "Creswell",
    state: "NC",
    areacode: 252,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27929",
    city: "Currituck",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27930",
    city: "Hertford",
    state: "NC",
    areacode: 252,
    county: "Perquimans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27932",
    city: "Edenton",
    state: "NC",
    areacode: 252,
    county: "Chowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27935",
    city: "Eure",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27936",
    city: "Frisco",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27937",
    city: "Gates",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27938",
    city: "Gatesville",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27939",
    city: "Grandy",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27941",
    city: "Harbinger",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27942",
    city: "Harrellsville",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27943",
    city: "Hatteras",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27944",
    city: "Hertford",
    state: "NC",
    areacode: 252,
    county: "Perquimans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27946",
    city: "Hobbsville",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27947",
    city: "Jarvisburg",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27948",
    city: "Kill Devil Hills",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27949",
    city: "Kitty Hawk",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27950",
    city: "Knotts Island",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27953",
    city: "Manns Harbor",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27954",
    city: "Manteo",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27956",
    city: "Maple",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27957",
    city: "Merry Hill",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27958",
    city: "Moyock",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27959",
    city: "Nags Head",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27960",
    city: "Ocracoke",
    state: "NC",
    areacode: 252,
    county: "Hyde",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27962",
    city: "Plymouth",
    state: "NC",
    areacode: 252,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27964",
    city: "Point Harbor",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27965",
    city: "Poplar Branch",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27966",
    city: "Powells Point",
    state: "NC",
    areacode: 252,
    county: "Currituck",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27967",
    city: "Powellsville",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27968",
    city: "Rodanthe",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27969",
    city: "Roduco",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27970",
    city: "Roper",
    state: "NC",
    areacode: 252,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27972",
    city: "Salvo",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27973",
    city: "Shawboro",
    state: "NC",
    areacode: 252,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27974",
    city: "Shiloh",
    state: "NC",
    areacode: 252,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27976",
    city: "South Mills",
    state: "NC",
    areacode: 252,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27978",
    city: "Stumpy Point",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27979",
    city: "Sunbury",
    state: "NC",
    areacode: 252,
    county: "Gates",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27980",
    city: "Tyner",
    state: "NC",
    areacode: 252,
    county: "Chowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27981",
    city: "Wanchese",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27982",
    city: "Waves",
    state: "NC",
    areacode: 252,
    county: "Dare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27983",
    city: "Windsor",
    state: "NC",
    areacode: 252,
    county: "Bertie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27985",
    city: "Winfall",
    state: "NC",
    areacode: 252,
    county: "Perquimans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "27986",
    city: "Winton",
    state: "NC",
    areacode: 252,
    county: "Hertford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28001",
    city: "Albemarle",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28002",
    city: "Albemarle",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28006",
    city: "Alexis",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28007",
    city: "Ansonville",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28009",
    city: "Badin",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28010",
    city: "Barium Springs",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28012",
    city: "Belmont",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28016",
    city: "Bessemer City",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28017",
    city: "Boiling Springs",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28018",
    city: "Bostic",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28019",
    city: "Caroleen",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28020",
    city: "Casar",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28021",
    city: "Cherryville",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28023",
    city: "China Grove",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28024",
    city: "Cliffside",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28025",
    city: "Concord",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28026",
    city: "Concord",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28027",
    city: "Concord",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28031",
    city: "Cornelius",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28032",
    city: "Cramerton",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28033",
    city: "Crouse",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28034",
    city: "Dallas",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28035",
    city: "Davidson",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28036",
    city: "Davidson",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28037",
    city: "Denver",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28038",
    city: "Earl",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28039",
    city: "East Spencer",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28040",
    city: "Ellenboro",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28041",
    city: "Faith",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28042",
    city: "Fallston",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28043",
    city: "Forest City",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28052",
    city: "Gastonia",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28053",
    city: "Gastonia",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28054",
    city: "Gastonia",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28055",
    city: "Gastonia",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28056",
    city: "Gastonia",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28070",
    city: "Huntersville",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28071",
    city: "Gold Hill",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28072",
    city: "Granite Quarry",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28073",
    city: "Grover",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28074",
    city: "Harris",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28075",
    city: "Harrisburg",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28076",
    city: "Henrietta",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28077",
    city: "High Shoals",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28078",
    city: "Huntersville",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28079",
    city: "Indian Trail",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28080",
    city: "Iron Station",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28081",
    city: "Kannapolis",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28082",
    city: "Kannapolis",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28083",
    city: "Kannapolis",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28086",
    city: "Kings Mountain",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28088",
    city: "Landis",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28089",
    city: "Lattimore",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28090",
    city: "Lawndale",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28091",
    city: "Lilesville",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28092",
    city: "Lincolnton",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28093",
    city: "Lincolnton",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28097",
    city: "Locust",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28098",
    city: "Lowell",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28101",
    city: "McAdenville",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28102",
    city: "McFarlan",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28103",
    city: "Marshville",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28104",
    city: "Matthews",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28105",
    city: "Matthews",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28106",
    city: "Matthews",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28107",
    city: "Midland",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28108",
    city: "Mineral Springs",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28109",
    city: "Misenheimer",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28110",
    city: "Monroe",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28111",
    city: "Monroe",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28112",
    city: "Monroe",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28114",
    city: "Mooresboro",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28115",
    city: "Mooresville",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28117",
    city: "Mooresville",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28119",
    city: "Morven",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28120",
    city: "Mount Holly",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28123",
    city: "Mount Mourne",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28124",
    city: "Mount Pleasant",
    state: "NC",
    areacode: 704,
    county: "Cabarrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28125",
    city: "Mount Ulla",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28126",
    city: "Newell",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28127",
    city: "New London",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28128",
    city: "Norwood",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28129",
    city: "Oakboro",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28130",
    city: "Paw Creek",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28133",
    city: "Peachland",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28134",
    city: "Pineville",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28135",
    city: "Polkton",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28136",
    city: "Polkville",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28137",
    city: "Richfield",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28138",
    city: "Rockwell",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28139",
    city: "Rutherfordton",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28144",
    city: "Salisbury",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28145",
    city: "Salisbury",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28146",
    city: "Salisbury",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28147",
    city: "Salisbury",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28150",
    city: "Shelby",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28151",
    city: "Shelby",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28152",
    city: "Shelby",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28159",
    city: "Spencer",
    state: "NC",
    areacode: 704,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28160",
    city: "Spindale",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28163",
    city: "Stanfield",
    state: "NC",
    areacode: 704,
    county: "Stanly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28164",
    city: "Stanley",
    state: "NC",
    areacode: 704,
    county: "Gaston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28166",
    city: "Troutman",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28167",
    city: "Union Mills",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28168",
    city: "Vale",
    state: "NC",
    areacode: 704,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28169",
    city: "Waco",
    state: "NC",
    areacode: 704,
    county: "Cleveland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28170",
    city: "Wadesboro",
    state: "NC",
    areacode: 704,
    county: "Anson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28173",
    city: "Waxhaw",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28174",
    city: "Wingate",
    state: "NC",
    areacode: 704,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28201",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28202",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28203",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28204",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28205",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28206",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28207",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28208",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28209",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28210",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28211",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28212",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28213",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28214",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28215",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28216",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28217",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28218",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28219",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28220",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28221",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28222",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28223",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28224",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28226",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28227",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28228",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28229",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28230",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28231",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28232",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28233",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28234",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28235",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28236",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28237",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28241",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28242",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28243",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28244",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28246",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28247",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28253",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28254",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28255",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28256",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28258",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28260",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28262",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28263",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28265",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28266",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28269",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28270",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28271",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28272",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28273",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28274",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28275",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28277",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28278",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28280",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28281",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28282",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28284",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28285",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28287",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28288",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28289",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28290",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28296",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28297",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28299",
    city: "Charlotte",
    state: "NC",
    areacode: 704,
    county: "Mecklenburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28301",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28302",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28303",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28304",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28305",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28306",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28307",
    city: "Fort Bragg",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28308",
    city: "Pope Army Airfield",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28309",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28310",
    city: "Fort Bragg",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28311",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28312",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28314",
    city: "Fayetteville",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28315",
    city: "Aberdeen",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28318",
    city: "Autryville",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28319",
    city: "Barnesville",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28320",
    city: "Bladenboro",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28323",
    city: "Bunnlevel",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28325",
    city: "Calypso",
    state: "NC",
    areacode: 919,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28326",
    city: "Cameron",
    state: "NC",
    areacode: 919,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28327",
    city: "Carthage",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28328",
    city: "Clinton",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28329",
    city: "Clinton",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28330",
    city: "Cordova",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28331",
    city: "Cumberland",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28332",
    city: "Dublin",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28333",
    city: "Dudley",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28334",
    city: "Dunn",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28335",
    city: "Dunn",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28337",
    city: "Elizabethtown",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28338",
    city: "Ellerbe",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28339",
    city: "Erwin",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28340",
    city: "Fairmont",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28341",
    city: "Faison",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28342",
    city: "Falcon",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28343",
    city: "Gibson",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28344",
    city: "Godwin",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28345",
    city: "Hamlet",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28347",
    city: "Hoffman",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28348",
    city: "Hope Mills",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28349",
    city: "Kenansville",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28350",
    city: "Lakeview",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28351",
    city: "Laurel Hill",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28352",
    city: "Laurinburg",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28353",
    city: "Laurinburg",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28355",
    city: "Lemon Springs",
    state: "NC",
    areacode: 919,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28356",
    city: "Linden",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28357",
    city: "Lumber Bridge",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28358",
    city: "Lumberton",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28359",
    city: "Lumberton",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28360",
    city: "Lumberton",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28362",
    city: "Marietta",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28363",
    city: "Marston",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28364",
    city: "Maxton",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28365",
    city: "Mount Olive",
    state: "NC",
    areacode: 919,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28366",
    city: "Newton Grove",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28367",
    city: "Norman",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28368",
    city: "Olivia",
    state: "NC",
    areacode: 919,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28369",
    city: "Orrum",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28370",
    city: "Pinehurst",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28371",
    city: "Parkton",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28372",
    city: "Pembroke",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28373",
    city: "Pinebluff",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28374",
    city: "Pinehurst",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28375",
    city: "Proctorville",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28376",
    city: "Raeford",
    state: "NC",
    areacode: 910,
    county: "Hoke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28377",
    city: "Red Springs",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28378",
    city: "Rex",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28379",
    city: "Rockingham",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28380",
    city: "Rockingham",
    state: "NC",
    areacode: 910,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28382",
    city: "Roseboro",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28383",
    city: "Rowland",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28384",
    city: "Saint Pauls",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28385",
    city: "Salemburg",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28386",
    city: "Shannon",
    state: "NC",
    areacode: 910,
    county: "Robeson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28387",
    city: "Southern Pines",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28388",
    city: "Southern Pines",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28390",
    city: "Spring Lake",
    state: "NC",
    areacode: 910,
    county: "Harnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28391",
    city: "Stedman",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28392",
    city: "Tar Heel",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28393",
    city: "Turkey",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28394",
    city: "Vass",
    state: "NC",
    areacode: 910,
    county: "Moore",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28395",
    city: "Wade",
    state: "NC",
    areacode: 910,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28396",
    city: "Wagram",
    state: "NC",
    areacode: 910,
    county: "Scotland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28398",
    city: "Warsaw",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28399",
    city: "White Oak",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28401",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28402",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28403",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28404",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28405",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28406",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28407",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28408",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28409",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28410",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28411",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28412",
    city: "Wilmington",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28420",
    city: "Ash",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28421",
    city: "Atkinson",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28422",
    city: "Bolivia",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28423",
    city: "Bolton",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28424",
    city: "Brunswick",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28425",
    city: "Burgaw",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28428",
    city: "Carolina Beach",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28429",
    city: "Castle Hayne",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28430",
    city: "Cerro Gordo",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28431",
    city: "Chadbourn",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28432",
    city: "Clarendon",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28433",
    city: "Clarkton",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28434",
    city: "Council",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28435",
    city: "Currie",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28436",
    city: "Delco",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28438",
    city: "Evergreen",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28439",
    city: "Fair Bluff",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28441",
    city: "Garland",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28442",
    city: "Hallsboro",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28443",
    city: "Hampstead",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28444",
    city: "Harrells",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28445",
    city: "Holly Ridge",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28447",
    city: "Ivanhoe",
    state: "NC",
    areacode: 910,
    county: "Sampson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28448",
    city: "Kelly",
    state: "NC",
    areacode: 910,
    county: "Bladen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28449",
    city: "Kure Beach",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28450",
    city: "Lake Waccamaw",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28451",
    city: "Leland",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28452",
    city: "Longwood",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28453",
    city: "Magnolia",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28454",
    city: "Maple Hill",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28455",
    city: "Nakina",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28456",
    city: "Riegelwood",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28457",
    city: "Rocky Point",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28458",
    city: "Rose Hill",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28459",
    city: "Shallotte",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28460",
    city: "Sneads Ferry",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28461",
    city: "Southport",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28462",
    city: "Supply",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28463",
    city: "Tabor City",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28464",
    city: "Teachey",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28465",
    city: "Oak Island",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28466",
    city: "Wallace",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28467",
    city: "Calabash",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28468",
    city: "Sunset Beach",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28469",
    city: "Ocean Isle Beach",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28470",
    city: "Shallotte",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28472",
    city: "Whiteville",
    state: "NC",
    areacode: 910,
    county: "Columbus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28478",
    city: "Willard",
    state: "NC",
    areacode: 910,
    county: "Pender",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28479",
    city: "Winnabow",
    state: "NC",
    areacode: 910,
    county: "Brunswick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28480",
    city: "Wrightsville Beach",
    state: "NC",
    areacode: 910,
    county: "New Hanover",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28501",
    city: "Kinston",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28502",
    city: "Kinston",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28503",
    city: "Kinston",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28504",
    city: "Kinston",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28508",
    city: "Albertson",
    state: "NC",
    areacode: 919,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28509",
    city: "Alliance",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28510",
    city: "Arapahoe",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28511",
    city: "Atlantic",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28512",
    city: "Atlantic Beach",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28513",
    city: "Ayden",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28515",
    city: "Bayboro",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28516",
    city: "Beaufort",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28518",
    city: "Beulaville",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28519",
    city: "Bridgeton",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28520",
    city: "Cedar Island",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28521",
    city: "Chinquapin",
    state: "NC",
    areacode: 910,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28522",
    city: "Comfort",
    state: "NC",
    areacode: 252,
    county: "Jones",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28523",
    city: "Cove City",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28524",
    city: "Davis",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28525",
    city: "Deep Run",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28526",
    city: "Dover",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28527",
    city: "Ernul",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28528",
    city: "Gloucester",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28529",
    city: "Grantsboro",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28530",
    city: "Grifton",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28531",
    city: "Harkers Island",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28532",
    city: "Havelock",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28533",
    city: "Cherry Point",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28537",
    city: "Hobucken",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28538",
    city: "Hookerton",
    state: "NC",
    areacode: 252,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28539",
    city: "Hubert",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28540",
    city: "Jacksonville",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28541",
    city: "Jacksonville",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28542",
    city: "Camp Lejeune",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28543",
    city: "Tarawa Terrace",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28544",
    city: "Midway Park",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28545",
    city: "McCutcheon Field",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28546",
    city: "Jacksonville",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28547",
    city: "Camp Lejeune",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28551",
    city: "La Grange",
    state: "NC",
    areacode: 252,
    county: "Lenoir",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28552",
    city: "Lowland",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28553",
    city: "Marshallberg",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28554",
    city: "Maury",
    state: "NC",
    areacode: 252,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28555",
    city: "Maysville",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28556",
    city: "Merritt",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28557",
    city: "Morehead City",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28560",
    city: "New Bern",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28561",
    city: "New Bern",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28562",
    city: "New Bern",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28563",
    city: "New Bern",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28564",
    city: "New Bern",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28570",
    city: "Newport",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28571",
    city: "Oriental",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28572",
    city: "Pink Hill",
    state: "NC",
    areacode: 252,
    county: "Duplin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28573",
    city: "Pollocksville",
    state: "NC",
    areacode: 252,
    county: "Jones",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28574",
    city: "Richlands",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28575",
    city: "Salter Path",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28577",
    city: "Sealevel",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28578",
    city: "Seven Springs",
    state: "NC",
    areacode: 252,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28579",
    city: "Smyrna",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28580",
    city: "Snow Hill",
    state: "NC",
    areacode: 252,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28581",
    city: "Stacy",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28582",
    city: "Stella",
    state: "NC",
    areacode: 910,
    county: "Onslow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28583",
    city: "Stonewall",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28584",
    city: "Swansboro",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28585",
    city: "Trenton",
    state: "NC",
    areacode: 252,
    county: "Jones",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28586",
    city: "Vanceboro",
    state: "NC",
    areacode: 252,
    county: "Craven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28587",
    city: "Vandemere",
    state: "NC",
    areacode: 252,
    county: "Pamlico",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28589",
    city: "Williston",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28590",
    city: "Winterville",
    state: "NC",
    areacode: 252,
    county: "Pitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28594",
    city: "Emerald Isle",
    state: "NC",
    areacode: 252,
    county: "Carteret",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28601",
    city: "Hickory",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28602",
    city: "Hickory",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28603",
    city: "Hickory",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28604",
    city: "Banner Elk",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28605",
    city: "Blowing Rock",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28606",
    city: "Boomer",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28607",
    city: "Boone",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28608",
    city: "Boone",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28609",
    city: "Catawba",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28610",
    city: "Claremont",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28611",
    city: "Collettsville",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28612",
    city: "Connelly Springs",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28613",
    city: "Conover",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28615",
    city: "Creston",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28616",
    city: "Crossnore",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28617",
    city: "Crumpler",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28618",
    city: "Deep Gap",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28619",
    city: "Drexel",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28621",
    city: "Elkin",
    state: "NC",
    areacode: 336,
    county: "Surry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28622",
    city: "Elk Park",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28623",
    city: "Ennice",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28624",
    city: "Ferguson",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28625",
    city: "Statesville",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28626",
    city: "Fleetwood",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28627",
    city: "Glade Valley",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28628",
    city: "Glen Alpine",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28629",
    city: "Glendale Springs",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28630",
    city: "Granite Falls",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28631",
    city: "Grassy Creek",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28633",
    city: "Lenoir",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28634",
    city: "Harmony",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28635",
    city: "Hays",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28636",
    city: "Hiddenite",
    state: "NC",
    areacode: 828,
    county: "Alexander",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28637",
    city: "Hildebran",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28638",
    city: "Hudson",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28640",
    city: "Jefferson",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28641",
    city: "Jonas Ridge",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28642",
    city: "Jonesville",
    state: "NC",
    areacode: 336,
    county: "Yadkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28643",
    city: "Lansing",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28644",
    city: "Laurel Springs",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28645",
    city: "Lenoir",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28646",
    city: "Linville",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28647",
    city: "Linville Falls",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28649",
    city: "McGrady",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28650",
    city: "Maiden",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28651",
    city: "Millers Creek",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28652",
    city: "Minneapolis",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28653",
    city: "Montezuma",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28654",
    city: "Moravian Falls",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28655",
    city: "Morganton",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28656",
    city: "North Wilkesboro",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28657",
    city: "Newland",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28658",
    city: "Newton",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28659",
    city: "North Wilkesboro",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28660",
    city: "Olin",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28661",
    city: "Patterson",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28662",
    city: "Pineola",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28663",
    city: "Piney Creek",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28664",
    city: "Plumtree",
    state: "NC",
    areacode: 828,
    county: "Avery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28665",
    city: "Purlear",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28666",
    city: "Icard",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28667",
    city: "Rhodhiss",
    state: "NC",
    areacode: 828,
    county: "Caldwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28668",
    city: "Roaring Gap",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28669",
    city: "Roaring River",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28670",
    city: "Ronda",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28671",
    city: "Rutherford College",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28672",
    city: "Scottville",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28673",
    city: "Sherrills Ford",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28675",
    city: "Sparta",
    state: "NC",
    areacode: 336,
    county: "Alleghany",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28676",
    city: "State Road",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28677",
    city: "Statesville",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28678",
    city: "Stony Point",
    state: "NC",
    areacode: 704,
    county: "Alexander",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28679",
    city: "Sugar Grove",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28680",
    city: "Morganton",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28681",
    city: "Taylorsville",
    state: "NC",
    areacode: 828,
    county: "Alexander",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28682",
    city: "Terrell",
    state: "NC",
    areacode: 828,
    county: "Catawba",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28683",
    city: "Thurmond",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28684",
    city: "Todd",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28685",
    city: "Traphill",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28687",
    city: "Statesville",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28688",
    city: "Turnersburg",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28689",
    city: "Union Grove",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28690",
    city: "Valdese",
    state: "NC",
    areacode: 828,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28691",
    city: "Valle Crucis",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28692",
    city: "Vilas",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28693",
    city: "Warrensville",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28694",
    city: "West Jefferson",
    state: "NC",
    areacode: 336,
    county: "Ashe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28697",
    city: "Wilkesboro",
    state: "NC",
    areacode: 336,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28698",
    city: "Zionville",
    state: "NC",
    areacode: 828,
    county: "Watauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28699",
    city: "Scotts",
    state: "NC",
    areacode: 704,
    county: "Iredell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28701",
    city: "Alexander",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28702",
    city: "Almond",
    state: "NC",
    areacode: 828,
    county: "Swain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28704",
    city: "Arden",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28705",
    city: "Bakersville",
    state: "NC",
    areacode: 828,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28707",
    city: "Balsam",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28708",
    city: "Balsam Grove",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28709",
    city: "Barnardsville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28710",
    city: "Bat Cave",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28711",
    city: "Black Mountain",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28712",
    city: "Brevard",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28713",
    city: "Bryson City",
    state: "NC",
    areacode: 828,
    county: "Swain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28714",
    city: "Burnsville",
    state: "NC",
    areacode: 828,
    county: "Yancey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28715",
    city: "Candler",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28716",
    city: "Canton",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28717",
    city: "Cashiers",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28718",
    city: "Cedar Mountain",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28719",
    city: "Cherokee",
    state: "NC",
    areacode: 828,
    county: "Swain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28720",
    city: "Chimney Rock",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28721",
    city: "Clyde",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28722",
    city: "Columbus",
    state: "NC",
    areacode: 828,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28723",
    city: "Cullowhee",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28724",
    city: "Dana",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28725",
    city: "Dillsboro",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28726",
    city: "East Flat Rock",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28727",
    city: "Edneyville",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28728",
    city: "Enka",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28729",
    city: "Etowah",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28730",
    city: "Fairview",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28731",
    city: "Flat Rock",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28732",
    city: "Fletcher",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28733",
    city: "Fontana Dam",
    state: "NC",
    areacode: 828,
    county: "Graham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28734",
    city: "Franklin",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28735",
    city: "Gerton",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28736",
    city: "Glenville",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28737",
    city: "Glenwood",
    state: "NC",
    areacode: 828,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28738",
    city: "Hazelwood",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28739",
    city: "Hendersonville",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28740",
    city: "Green Mountain",
    state: "NC",
    areacode: 828,
    county: "Yancey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28741",
    city: "Highlands",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28742",
    city: "Horse Shoe",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28743",
    city: "Hot Springs",
    state: "NC",
    areacode: 828,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28744",
    city: "Franklin",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28745",
    city: "Lake Junaluska",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28746",
    city: "Lake Lure",
    state: "NC",
    areacode: 828,
    county: "Rutherford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28747",
    city: "Lake Toxaway",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28748",
    city: "Leicester",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28749",
    city: "Little Switzerland",
    state: "NC",
    areacode: 828,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28750",
    city: "Lynn",
    state: "NC",
    areacode: 828,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28751",
    city: "Maggie Valley",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28752",
    city: "Marion",
    state: "NC",
    areacode: 828,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28753",
    city: "Marshall",
    state: "NC",
    areacode: 828,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28754",
    city: "Mars Hill",
    state: "NC",
    areacode: 828,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28755",
    city: "Micaville",
    state: "NC",
    areacode: 828,
    county: "Yancey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28756",
    city: "Mill Spring",
    state: "NC",
    areacode: 828,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28757",
    city: "Montreat",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28758",
    city: "Mountain Home",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28759",
    city: "Mills River",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28760",
    city: "Naples",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28761",
    city: "Nebo",
    state: "NC",
    areacode: 828,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28762",
    city: "Old Fort",
    state: "NC",
    areacode: 828,
    county: "McDowell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28763",
    city: "Otto",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28765",
    city: "Penland",
    state: "NC",
    areacode: 828,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28766",
    city: "Penrose",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28768",
    city: "Pisgah Forest",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28770",
    city: "Ridgecrest",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28771",
    city: "Robbinsville",
    state: "NC",
    areacode: 828,
    county: "Graham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28772",
    city: "Rosman",
    state: "NC",
    areacode: 828,
    county: "Transylvania",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28773",
    city: "Saluda",
    state: "NC",
    areacode: 828,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28774",
    city: "Sapphire",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28775",
    city: "Scaly Mountain",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28776",
    city: "Skyland",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28777",
    city: "Spruce Pine",
    state: "NC",
    areacode: 828,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28778",
    city: "Swannanoa",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28779",
    city: "Sylva",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28781",
    city: "Topton",
    state: "NC",
    areacode: 828,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28782",
    city: "Tryon",
    state: "NC",
    areacode: 828,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28783",
    city: "Tuckasegee",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28784",
    city: "Tuxedo",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28785",
    city: "Waynesville",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28786",
    city: "Waynesville",
    state: "NC",
    areacode: 828,
    county: "Haywood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28787",
    city: "Weaverville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28788",
    city: "Webster",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28789",
    city: "Whittier",
    state: "NC",
    areacode: 828,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28790",
    city: "Zirconia",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28791",
    city: "Hendersonville",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28792",
    city: "Hendersonville",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28793",
    city: "Hendersonville",
    state: "NC",
    areacode: 828,
    county: "Henderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28801",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28802",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28803",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28804",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28805",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28806",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28810",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28813",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28814",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28815",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28816",
    city: "Asheville",
    state: "NC",
    areacode: 828,
    county: "Buncombe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28901",
    city: "Andrews",
    state: "NC",
    areacode: 828,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28902",
    city: "Brasstown",
    state: "NC",
    areacode: 828,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28903",
    city: "Culberson",
    state: "NC",
    areacode: 828,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28904",
    city: "Hayesville",
    state: "NC",
    areacode: 828,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28905",
    city: "Marble",
    state: "NC",
    areacode: 828,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28906",
    city: "Murphy",
    state: "NC",
    areacode: 828,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "28909",
    city: "Warne",
    state: "NC",
    areacode: 828,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29001",
    city: "Alcolu",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29002",
    city: "Ballentine",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29003",
    city: "Bamberg",
    state: "SC",
    areacode: 803,
    county: "Bamberg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29006",
    city: "Batesburg",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29009",
    city: "Bethune",
    state: "SC",
    areacode: 843,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29010",
    city: "Bishopville",
    state: "SC",
    areacode: 803,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29014",
    city: "Blackstock",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29015",
    city: "Blair",
    state: "SC",
    areacode: 803,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29016",
    city: "Blythewood",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29018",
    city: "Bowman",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29020",
    city: "Camden",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29021",
    city: "Camden",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29030",
    city: "Cameron",
    state: "SC",
    areacode: 803,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29031",
    city: "Carlisle",
    state: "SC",
    areacode: 803,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29032",
    city: "Cassatt",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29033",
    city: "Cayce",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29036",
    city: "Chapin",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29037",
    city: "Chappells",
    state: "SC",
    areacode: 864,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29038",
    city: "Cope",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29039",
    city: "Cordova",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29040",
    city: "Dalzell",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29041",
    city: "Davis Station",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29042",
    city: "Denmark",
    state: "SC",
    areacode: 803,
    county: "Bamberg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29044",
    city: "Eastover",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29045",
    city: "Elgin",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29046",
    city: "Elliott",
    state: "SC",
    areacode: 803,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29047",
    city: "Elloree",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29048",
    city: "Eutawville",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29051",
    city: "Gable",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29052",
    city: "Gadsden",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29053",
    city: "Gaston",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29054",
    city: "Gilbert",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29055",
    city: "Great Falls",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29056",
    city: "Greeleyville",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29058",
    city: "Heath Springs",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29059",
    city: "Holly Hill",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29061",
    city: "Hopkins",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29062",
    city: "Horatio",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29063",
    city: "Irmo",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29065",
    city: "Jenkinsville",
    state: "SC",
    areacode: 803,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29067",
    city: "Kershaw",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29069",
    city: "Lamar",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29070",
    city: "Leesville",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29071",
    city: "Lexington",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29072",
    city: "Lexington",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29073",
    city: "Lexington",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29074",
    city: "Liberty Hill",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29075",
    city: "Little Mountain",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29078",
    city: "Lugoff",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29079",
    city: "Lydia",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29080",
    city: "Lynchburg",
    state: "SC",
    areacode: 803,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29081",
    city: "Ehrhardt",
    state: "SC",
    areacode: 803,
    county: "Bamberg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29082",
    city: "Lodge",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29101",
    city: "McBee",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29102",
    city: "Manning",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29104",
    city: "Mayesville",
    state: "SC",
    areacode: 803,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29105",
    city: "Monetta",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29107",
    city: "Neeses",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29108",
    city: "Newberry",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29111",
    city: "New Zion",
    state: "SC",
    areacode: 843,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29112",
    city: "North",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29113",
    city: "Norway",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29114",
    city: "Olanta",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29115",
    city: "Orangeburg",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29116",
    city: "Orangeburg",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29117",
    city: "Orangeburg",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29118",
    city: "Orangeburg",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29122",
    city: "Peak",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29123",
    city: "Pelion",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29125",
    city: "Pinewood",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29126",
    city: "Pomaria",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29127",
    city: "Prosperity",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29128",
    city: "Rembert",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29129",
    city: "Ridge Spring",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29130",
    city: "Ridgeway",
    state: "SC",
    areacode: 803,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29132",
    city: "Rion",
    state: "SC",
    areacode: 803,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29133",
    city: "Rowesville",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29135",
    city: "Saint Matthews",
    state: "SC",
    areacode: 803,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29137",
    city: "Salley",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29138",
    city: "Saluda",
    state: "SC",
    areacode: 864,
    county: "Saluda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29142",
    city: "Santee",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29143",
    city: "Sardinia",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29145",
    city: "Silverstreet",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29146",
    city: "Springfield",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29147",
    city: "State Park",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29148",
    city: "Summerton",
    state: "SC",
    areacode: 803,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29150",
    city: "Sumter",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29151",
    city: "Sumter",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29152",
    city: "Shaw AFB",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29153",
    city: "Sumter",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29154",
    city: "Sumter",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29160",
    city: "Swansea",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29161",
    city: "Timmonsville",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29162",
    city: "Turbeville",
    state: "SC",
    areacode: 843,
    county: "Clarendon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29163",
    city: "Vance",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29164",
    city: "Wagener",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29166",
    city: "Ward",
    state: "SC",
    areacode: 803,
    county: "Saluda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29168",
    city: "Wedgefield",
    state: "SC",
    areacode: 803,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29169",
    city: "West Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29170",
    city: "West Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29171",
    city: "West Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29172",
    city: "West Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29175",
    city: "Westville",
    state: "SC",
    areacode: 803,
    county: "Kershaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29177",
    city: "White Rock",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29178",
    city: "Whitmire",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29180",
    city: "Winnsboro",
    state: "SC",
    areacode: 803,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29201",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29202",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29203",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29204",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29205",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29206",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29207",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29208",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29209",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29210",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29211",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29212",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29214",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29215",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29216",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29217",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29218",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29219",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29220",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29221",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29222",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29223",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29224",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29225",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29226",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29227",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29228",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Lexington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29229",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29230",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29240",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29250",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29260",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29290",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29292",
    city: "Columbia",
    state: "SC",
    areacode: 803,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29301",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29302",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29303",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29304",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29305",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29306",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29307",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29316",
    city: "Boiling Springs",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29319",
    city: "Spartanburg",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29320",
    city: "Arcadia",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29321",
    city: "Buffalo",
    state: "SC",
    areacode: 864,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29322",
    city: "Campobello",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29323",
    city: "Chesnee",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29324",
    city: "Clifton",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29325",
    city: "Clinton",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29329",
    city: "Converse",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29330",
    city: "Cowpens",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29331",
    city: "Cross Anchor",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29332",
    city: "Cross Hill",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29333",
    city: "Drayton",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29334",
    city: "Duncan",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29335",
    city: "Enoree",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29336",
    city: "Fairforest",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29338",
    city: "Fingerville",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29340",
    city: "Gaffney",
    state: "SC",
    areacode: 864,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29341",
    city: "Gaffney",
    state: "SC",
    areacode: 864,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29342",
    city: "Gaffney",
    state: "SC",
    areacode: 864,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29346",
    city: "Glendale",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29348",
    city: "Gramling",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29349",
    city: "Inman",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29351",
    city: "Joanna",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29353",
    city: "Jonesville",
    state: "SC",
    areacode: 864,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29355",
    city: "Kinards",
    state: "SC",
    areacode: 803,
    county: "Newberry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29356",
    city: "Landrum",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29360",
    city: "Laurens",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29364",
    city: "Lockhart",
    state: "SC",
    areacode: 864,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29365",
    city: "Lyman",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29368",
    city: "Mayo",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29369",
    city: "Moore",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29370",
    city: "Mountville",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29372",
    city: "Pacolet",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29373",
    city: "Pacolet Mills",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29374",
    city: "Pauline",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29375",
    city: "Reidville",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29376",
    city: "Roebuck",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29377",
    city: "Startex",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29378",
    city: "Una",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29379",
    city: "Union",
    state: "SC",
    areacode: 864,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29384",
    city: "Waterloo",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29385",
    city: "Wellford",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29386",
    city: "White Stone",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29388",
    city: "Woodruff",
    state: "SC",
    areacode: 864,
    county: "Spartanburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29395",
    city: "Jonesville",
    state: "SC",
    areacode: 864,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29401",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29402",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29403",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29404",
    city: "Charleston AFB",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29405",
    city: "North Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29406",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29407",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29409",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29410",
    city: "Hanahan",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29412",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29413",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29414",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29415",
    city: "North Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29416",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29417",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29418",
    city: "North Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29419",
    city: "North Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29420",
    city: "North Charleston",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29422",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29423",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29424",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29425",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29426",
    city: "Adams Run",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29429",
    city: "Awendaw",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29431",
    city: "Bonneau",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29432",
    city: "Branchville",
    state: "SC",
    areacode: 803,
    county: "Orangeburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29433",
    city: "Canadys",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29434",
    city: "Cordesville",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29435",
    city: "Cottageville",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29436",
    city: "Cross",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29437",
    city: "Dorchester",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29438",
    city: "Edisto Island",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29439",
    city: "Folly Beach",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29440",
    city: "Georgetown",
    state: "SC",
    areacode: 843,
    county: "Georgetown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29442",
    city: "Georgetown",
    state: "SC",
    areacode: 843,
    county: "Georgetown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29445",
    city: "Goose Creek",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29446",
    city: "Green Pond",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29447",
    city: "Grover",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29448",
    city: "Harleyville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29449",
    city: "Hollywood",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29450",
    city: "Huger",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29451",
    city: "Isle of Palms",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29452",
    city: "Jacksonboro",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29453",
    city: "Jamestown",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29455",
    city: "Johns Island",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29456",
    city: "Ladson",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29457",
    city: "Johns Island",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29458",
    city: "McClellanville",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29461",
    city: "Moncks Corner",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29464",
    city: "Mount Pleasant",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29465",
    city: "Mount Pleasant",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29466",
    city: "Mount Pleasant",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29468",
    city: "Pineville",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29469",
    city: "Pinopolis",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29470",
    city: "Ravenel",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29471",
    city: "Reevesville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29472",
    city: "Ridgeville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29474",
    city: "Round O",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29475",
    city: "Ruffin",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29476",
    city: "Russellville",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29477",
    city: "Saint George",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29479",
    city: "Saint Stephen",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29481",
    city: "Smoaks",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29482",
    city: "Sullivans Island",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29483",
    city: "Summerville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29484",
    city: "Summerville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29485",
    city: "Summerville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29486",
    city: "Summerville",
    state: "SC",
    areacode: 843,
    county: "Dorchester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29487",
    city: "Wadmalaw Island",
    state: "SC",
    areacode: 843,
    county: "Charleston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29488",
    city: "Walterboro",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29492",
    city: "Charleston",
    state: "SC",
    areacode: 843,
    county: "Berkeley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29493",
    city: "Williams",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29501",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29502",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29503",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29504",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29505",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29506",
    city: "Florence",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29510",
    city: "Andrews",
    state: "SC",
    areacode: 843,
    county: "Georgetown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29511",
    city: "Aynor",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29512",
    city: "Bennettsville",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29516",
    city: "Blenheim",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29518",
    city: "Cades",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29519",
    city: "Centenary",
    state: "SC",
    areacode: 843,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29520",
    city: "Cheraw",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29525",
    city: "Clio",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29526",
    city: "Conway",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29527",
    city: "Conway",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29528",
    city: "Conway",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29530",
    city: "Coward",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29532",
    city: "Darlington",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29536",
    city: "Dillon",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29540",
    city: "Darlington",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29541",
    city: "Effingham",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29543",
    city: "Fork",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29544",
    city: "Galivants Ferry",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29545",
    city: "Green Sea",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29546",
    city: "Gresham",
    state: "SC",
    areacode: 843,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29547",
    city: "Hamer",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29550",
    city: "Hartsville",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29551",
    city: "Hartsville",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29554",
    city: "Hemingway",
    state: "SC",
    areacode: 843,
    county: "Georgetown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29555",
    city: "Johnsonville",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29556",
    city: "Kingstree",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29560",
    city: "Lake City",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29563",
    city: "Lake View",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29564",
    city: "Lane",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29565",
    city: "Latta",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29566",
    city: "Little River",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29567",
    city: "Little Rock",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29568",
    city: "Longs",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29569",
    city: "Loris",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29570",
    city: "McColl",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29571",
    city: "Marion",
    state: "SC",
    areacode: 843,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29572",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29574",
    city: "Mullins",
    state: "SC",
    areacode: 843,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29575",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29576",
    city: "Murrells Inlet",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29577",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29578",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29579",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29580",
    city: "Nesmith",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29581",
    city: "Nichols",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29582",
    city: "North Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29583",
    city: "Pamplico",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29584",
    city: "Patrick",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29585",
    city: "Pawleys Island",
    state: "SC",
    areacode: 843,
    county: "Georgetown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29587",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29588",
    city: "Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29589",
    city: "Rains",
    state: "SC",
    areacode: 843,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29590",
    city: "Salters",
    state: "SC",
    areacode: 843,
    county: "Williamsburg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29591",
    city: "Scranton",
    state: "SC",
    areacode: 843,
    county: "Florence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29592",
    city: "Sellers",
    state: "SC",
    areacode: 843,
    county: "Dillon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29593",
    city: "Society Hill",
    state: "SC",
    areacode: 843,
    county: "Darlington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29594",
    city: "Tatum",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29596",
    city: "Wallace",
    state: "SC",
    areacode: 843,
    county: "Marlboro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29597",
    city: "North Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29598",
    city: "North Myrtle Beach",
    state: "SC",
    areacode: 843,
    county: "Horry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29601",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29602",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29603",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29604",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29605",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29606",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29607",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29608",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29609",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29610",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29611",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29612",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29613",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29614",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29615",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29616",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29617",
    city: "Greenville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29620",
    city: "Abbeville",
    state: "SC",
    areacode: 864,
    county: "Abbeville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29621",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29622",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29623",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29624",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29625",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29626",
    city: "Anderson",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29627",
    city: "Belton",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29628",
    city: "Calhoun Falls",
    state: "SC",
    areacode: 864,
    county: "Abbeville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29630",
    city: "Central",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29631",
    city: "Clemson",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29632",
    city: "Clemson",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29633",
    city: "Clemson",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29634",
    city: "Clemson",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29635",
    city: "Cleveland",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29636",
    city: "Conestee",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29638",
    city: "Donalds",
    state: "SC",
    areacode: 864,
    county: "Abbeville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29639",
    city: "Due West",
    state: "SC",
    areacode: 864,
    county: "Abbeville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29640",
    city: "Easley",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29641",
    city: "Easley",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29642",
    city: "Easley",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29643",
    city: "Fair Play",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29644",
    city: "Fountain Inn",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29645",
    city: "Gray Court",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29646",
    city: "Greenwood",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29647",
    city: "Greenwood",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29648",
    city: "Greenwood",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29649",
    city: "Greenwood",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29650",
    city: "Greer",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29651",
    city: "Greer",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29652",
    city: "Greer",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29653",
    city: "Hodges",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29654",
    city: "Honea Path",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29655",
    city: "Iva",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29656",
    city: "La France",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29657",
    city: "Liberty",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29658",
    city: "Long Creek",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29659",
    city: "Lowndesville",
    state: "SC",
    areacode: 864,
    county: "Abbeville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29661",
    city: "Marietta",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29662",
    city: "Mauldin",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29664",
    city: "Mountain Rest",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29665",
    city: "Newry",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29666",
    city: "Ninety Six",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29667",
    city: "Norris",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29669",
    city: "Pelzer",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29670",
    city: "Pendleton",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29671",
    city: "Pickens",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29672",
    city: "Seneca",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29673",
    city: "Piedmont",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29675",
    city: "Richland",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29676",
    city: "Salem",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29677",
    city: "Sandy Springs",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29678",
    city: "Seneca",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29679",
    city: "Seneca",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29680",
    city: "Simpsonville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29681",
    city: "Simpsonville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29682",
    city: "Six Mile",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29683",
    city: "Slater",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29684",
    city: "Starr",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29685",
    city: "Sunset",
    state: "SC",
    areacode: 864,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29686",
    city: "Tamassee",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29687",
    city: "Taylors",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29688",
    city: "Tigerville",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29689",
    city: "Townville",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29690",
    city: "Travelers Rest",
    state: "SC",
    areacode: 864,
    county: "Greenville",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29691",
    city: "Walhalla",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29692",
    city: "Ware Shoals",
    state: "SC",
    areacode: 864,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29693",
    city: "Westminster",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29695",
    city: "Hodges",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29696",
    city: "West Union",
    state: "SC",
    areacode: 864,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29697",
    city: "Williamston",
    state: "SC",
    areacode: 864,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29702",
    city: "Blacksburg",
    state: "SC",
    areacode: 864,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29703",
    city: "Bowling Green",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29704",
    city: "Catawba",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29706",
    city: "Chester",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29707",
    city: "Fort Mill",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29708",
    city: "Fort Mill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29709",
    city: "Chesterfield",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29710",
    city: "Clover",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29712",
    city: "Edgemoor",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29714",
    city: "Fort Lawn",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29715",
    city: "Fort Mill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29716",
    city: "Fort Mill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29717",
    city: "Hickory Grove",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29718",
    city: "Jefferson",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29720",
    city: "Lancaster",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29721",
    city: "Lancaster",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29722",
    city: "Lancaster",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29724",
    city: "Lando",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29726",
    city: "McConnells",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29727",
    city: "Mount Croghan",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29728",
    city: "Pageland",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29729",
    city: "Richburg",
    state: "SC",
    areacode: 803,
    county: "Chester",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29730",
    city: "Rock Hill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29731",
    city: "Rock Hill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29732",
    city: "Rock Hill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29733",
    city: "Rock Hill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29734",
    city: "Rock Hill",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29741",
    city: "Ruby",
    state: "SC",
    areacode: 843,
    county: "Chesterfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29742",
    city: "Sharon",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29743",
    city: "Smyrna",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29744",
    city: "Van Wyck",
    state: "SC",
    areacode: 803,
    county: "Lancaster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29745",
    city: "York",
    state: "SC",
    areacode: 803,
    county: "York",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29801",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29802",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29803",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29804",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29805",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29808",
    city: "Aiken",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29809",
    city: "New Ellenton",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29810",
    city: "Allendale",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29812",
    city: "Barnwell",
    state: "SC",
    areacode: 803,
    county: "Barnwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29813",
    city: "Hilda",
    state: "SC",
    areacode: 803,
    county: "Barnwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29816",
    city: "Bath",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29817",
    city: "Blackville",
    state: "SC",
    areacode: 803,
    county: "Barnwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29819",
    city: "Bradley",
    state: "SC",
    areacode: 864,
    county: "Greenwood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29821",
    city: "Clarks Hill",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29822",
    city: "Clearwater",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29824",
    city: "Edgefield",
    state: "SC",
    areacode: 803,
    county: "Edgefield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29826",
    city: "Elko",
    state: "SC",
    areacode: 803,
    county: "Barnwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29827",
    city: "Fairfax",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29828",
    city: "Gloverville",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29829",
    city: "Graniteville",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29831",
    city: "Jackson",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29832",
    city: "Johnston",
    state: "SC",
    areacode: 803,
    county: "Edgefield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29834",
    city: "Langley",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29835",
    city: "McCormick",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29836",
    city: "Martin",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29838",
    city: "Modoc",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29839",
    city: "Montmorenci",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29840",
    city: "Mount Carmel",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29841",
    city: "North Augusta",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29842",
    city: "Beech Island",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29843",
    city: "Olar",
    state: "SC",
    areacode: 803,
    county: "Bamberg",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29844",
    city: "Parksville",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29845",
    city: "Plum Branch",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29846",
    city: "Sycamore",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29847",
    city: "Trenton",
    state: "SC",
    areacode: 803,
    county: "Edgefield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29848",
    city: "Troy",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29849",
    city: "Ulmer",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29850",
    city: "Vaucluse",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29851",
    city: "Warrenville",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29853",
    city: "Williston",
    state: "SC",
    areacode: 803,
    county: "Barnwell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29856",
    city: "Windsor",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29860",
    city: "North Augusta",
    state: "SC",
    areacode: 803,
    county: "Edgefield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29861",
    city: "North Augusta",
    state: "SC",
    areacode: 803,
    county: "Aiken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29899",
    city: "McCormick",
    state: "SC",
    areacode: 864,
    county: "McCormick",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29901",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29902",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29903",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29904",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29905",
    city: "Parris Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29906",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29907",
    city: "Beaufort",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29909",
    city: "Okatie",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29910",
    city: "Bluffton",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29911",
    city: "Brunson",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29912",
    city: "Coosawhatchie",
    state: "SC",
    areacode: 843,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29913",
    city: "Crocketville",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29914",
    city: "Dale",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29915",
    city: "Daufuskie Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29916",
    city: "Early Branch",
    state: "SC",
    areacode: 843,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29918",
    city: "Estill",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29920",
    city: "Saint Helena Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29921",
    city: "Furman",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29922",
    city: "Garnett",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29923",
    city: "Gifford",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29924",
    city: "Hampton",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29925",
    city: "Hilton Head Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29926",
    city: "Hilton Head Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29927",
    city: "Hardeeville",
    state: "SC",
    areacode: 843,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29928",
    city: "Hilton Head Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29929",
    city: "Islandton",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29931",
    city: "Lobeco",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29932",
    city: "Luray",
    state: "SC",
    areacode: 803,
    county: "Allendale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29933",
    city: "Miley",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29934",
    city: "Pineland",
    state: "SC",
    areacode: 843,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29935",
    city: "Port Royal",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29936",
    city: "Ridgeland",
    state: "SC",
    areacode: 843,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29938",
    city: "Hilton Head Island",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29939",
    city: "Scotia",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29940",
    city: "Seabrook",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29941",
    city: "Sheldon",
    state: "SC",
    areacode: 843,
    county: "Beaufort",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29943",
    city: "Tillman",
    state: "SC",
    areacode: 843,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29944",
    city: "Varnville",
    state: "SC",
    areacode: 803,
    county: "Hampton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "29945",
    city: "Yemassee",
    state: "SC",
    areacode: 843,
    county: "Colleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30002",
    city: "Avondale Estates",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30003",
    city: "Norcross",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30004",
    city: "Alpharetta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30005",
    city: "Alpharetta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30006",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30007",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30008",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30009",
    city: "Alpharetta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30010",
    city: "Norcross",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30011",
    city: "Auburn",
    state: "GA",
    areacode: 770,
    county: "Barrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30012",
    city: "Conyers",
    state: "GA",
    areacode: 770,
    county: "Rockdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30013",
    city: "Conyers",
    state: "GA",
    areacode: 770,
    county: "Rockdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30014",
    city: "Covington",
    state: "GA",
    areacode: 770,
    county: "Newton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30015",
    city: "Covington",
    state: "GA",
    areacode: 770,
    county: "Newton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30016",
    city: "Covington",
    state: "GA",
    areacode: 770,
    county: "Newton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30017",
    city: "Grayson",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30018",
    city: "Jersey",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30019",
    city: "Dacula",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30021",
    city: "Clarkston",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30022",
    city: "Alpharetta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30023",
    city: "Alpharetta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30024",
    city: "Suwanee",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30025",
    city: "Social Circle",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30026",
    city: "North Metro",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30028",
    city: "Cumming",
    state: "GA",
    areacode: 770,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30029",
    city: "North Metro",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30030",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30031",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30032",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30033",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30034",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30035",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30036",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30037",
    city: "Decatur",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30038",
    city: "Lithonia",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30039",
    city: "Snellville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30040",
    city: "Cumming",
    state: "GA",
    areacode: 770,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30041",
    city: "Cumming",
    state: "GA",
    areacode: 770,
    county: "Forsyth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30042",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30043",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30044",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30045",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30046",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30047",
    city: "Lilburn",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30048",
    city: "Lilburn",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30049",
    city: "Lawrenceville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30052",
    city: "Loganville",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30054",
    city: "Oxford",
    state: "GA",
    areacode: 770,
    county: "Newton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30055",
    city: "Mansfield",
    state: "GA",
    areacode: 770,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30056",
    city: "Newborn",
    state: "GA",
    areacode: 770,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30058",
    city: "Lithonia",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30060",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30061",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30062",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30063",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30064",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30065",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30066",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30067",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30068",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30069",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30070",
    city: "Porterdale",
    state: "GA",
    areacode: 770,
    county: "Newton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30071",
    city: "Norcross",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30072",
    city: "Pine Lake",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30074",
    city: "Redan",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30075",
    city: "Roswell",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30076",
    city: "Roswell",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30077",
    city: "Roswell",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30078",
    city: "Snellville",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30079",
    city: "Scottdale",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30080",
    city: "Smyrna",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30081",
    city: "Smyrna",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30082",
    city: "Smyrna",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30083",
    city: "Stone Mountain",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30084",
    city: "Tucker",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30085",
    city: "Tucker",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30086",
    city: "Stone Mountain",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30087",
    city: "Stone Mountain",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30088",
    city: "Stone Mountain",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30090",
    city: "Marietta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30091",
    city: "Norcross",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30092",
    city: "Peachtree Corners",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30093",
    city: "Norcross",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30094",
    city: "Conyers",
    state: "GA",
    areacode: 770,
    county: "Rockdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30095",
    city: "Duluth",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30096",
    city: "Duluth",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30097",
    city: "Duluth",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30098",
    city: "Duluth",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30099",
    city: "Duluth",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30101",
    city: "Acworth",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30102",
    city: "Acworth",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30103",
    city: "Adairsville",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30104",
    city: "Aragon",
    state: "GA",
    areacode: 770,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30105",
    city: "Armuchee",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30106",
    city: "Austell",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30107",
    city: "Ball Ground",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30108",
    city: "Bowdon",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30109",
    city: "Bowdon Junction",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30110",
    city: "Bremen",
    state: "GA",
    areacode: 770,
    county: "Haralson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30111",
    city: "Clarkdale",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30112",
    city: "Carrollton",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30113",
    city: "Buchanan",
    state: "GA",
    areacode: 770,
    county: "Haralson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30114",
    city: "Canton",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30115",
    city: "Canton",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30116",
    city: "Carrollton",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30117",
    city: "Carrollton",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30118",
    city: "Carrollton",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30119",
    city: "Carrollton",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30120",
    city: "Cartersville",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30121",
    city: "Cartersville",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30122",
    city: "Lithia Springs",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30123",
    city: "Cassville",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30124",
    city: "Cave Spring",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30125",
    city: "Cedartown",
    state: "GA",
    areacode: 770,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30126",
    city: "Mableton",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30127",
    city: "Powder Springs",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30129",
    city: "Coosa",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30132",
    city: "Dallas",
    state: "GA",
    areacode: 770,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30133",
    city: "Douglasville",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30134",
    city: "Douglasville",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30135",
    city: "Douglasville",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30137",
    city: "Emerson",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30138",
    city: "Esom Hill",
    state: "GA",
    areacode: 770,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30139",
    city: "Fairmount",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30140",
    city: "Felton",
    state: "GA",
    areacode: 770,
    county: "Haralson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30141",
    city: "Hiram",
    state: "GA",
    areacode: 770,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30142",
    city: "Holly Springs",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30143",
    city: "Jasper",
    state: "GA",
    areacode: 706,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30144",
    city: "Kennesaw",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30145",
    city: "Kingston",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30146",
    city: "Lebanon",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30147",
    city: "Lindale",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30148",
    city: "Marble Hill",
    state: "GA",
    areacode: 770,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30149",
    city: "Mount Berry",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30150",
    city: "Mount Zion",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30151",
    city: "Nelson",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30152",
    city: "Kennesaw",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30153",
    city: "Rockmart",
    state: "GA",
    areacode: 770,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30154",
    city: "Douglasville",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30156",
    city: "Kennesaw",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30157",
    city: "Dallas",
    state: "GA",
    areacode: 770,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30160",
    city: "Kennesaw",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30161",
    city: "Rome",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30162",
    city: "Rome",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30164",
    city: "Rome",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30165",
    city: "Rome",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30168",
    city: "Austell",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30169",
    city: "Canton",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30170",
    city: "Roopville",
    state: "GA",
    areacode: 770,
    county: "Heard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30171",
    city: "Rydal",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30172",
    city: "Shannon",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30173",
    city: "Silver Creek",
    state: "GA",
    areacode: 706,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30175",
    city: "Talking Rock",
    state: "GA",
    areacode: 706,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30176",
    city: "Tallapoosa",
    state: "GA",
    areacode: 770,
    county: "Haralson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30177",
    city: "Tate",
    state: "GA",
    areacode: 770,
    county: "Pickens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30178",
    city: "Taylorsville",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30179",
    city: "Temple",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30180",
    city: "Villa Rica",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30182",
    city: "Waco",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30183",
    city: "Waleska",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30184",
    city: "White",
    state: "GA",
    areacode: 770,
    county: "Bartow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30185",
    city: "Whitesburg",
    state: "GA",
    areacode: 770,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30187",
    city: "Winston",
    state: "GA",
    areacode: 770,
    county: "Douglas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30188",
    city: "Woodstock",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30189",
    city: "Woodstock",
    state: "GA",
    areacode: 770,
    county: "Cherokee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30204",
    city: "Barnesville",
    state: "GA",
    areacode: 770,
    county: "Lamar",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30205",
    city: "Brooks",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30206",
    city: "Concord",
    state: "GA",
    areacode: 770,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30212",
    city: "Experiment",
    state: "GA",
    areacode: 770,
    county: "Spalding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30213",
    city: "Fairburn",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30214",
    city: "Fayetteville",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30215",
    city: "Fayetteville",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30216",
    city: "Flovilla",
    state: "GA",
    areacode: 770,
    county: "Butts",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30217",
    city: "Franklin",
    state: "GA",
    areacode: 706,
    county: "Heard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30218",
    city: "Gay",
    state: "GA",
    areacode: 706,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30220",
    city: "Grantville",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30222",
    city: "Greenville",
    state: "GA",
    areacode: 706,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30223",
    city: "Griffin",
    state: "GA",
    areacode: 770,
    county: "Spalding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30224",
    city: "Griffin",
    state: "GA",
    areacode: 770,
    county: "Spalding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30228",
    city: "Hampton",
    state: "GA",
    areacode: 770,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30229",
    city: "Haralson",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30230",
    city: "Hogansville",
    state: "GA",
    areacode: 706,
    county: "Troup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30233",
    city: "Jackson",
    state: "GA",
    areacode: 770,
    county: "Butts",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30234",
    city: "Jenkinsburg",
    state: "GA",
    areacode: 770,
    county: "Butts",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30236",
    city: "Jonesboro",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30237",
    city: "Jonesboro",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30238",
    city: "Jonesboro",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30240",
    city: "Lagrange",
    state: "GA",
    areacode: 706,
    county: "Troup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30241",
    city: "Lagrange",
    state: "GA",
    areacode: 706,
    county: "Troup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30248",
    city: "Locust Grove",
    state: "GA",
    areacode: 770,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30250",
    city: "Lovejoy",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30251",
    city: "Luthersville",
    state: "GA",
    areacode: 770,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30252",
    city: "McDonough",
    state: "GA",
    areacode: 770,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30253",
    city: "McDonough",
    state: "GA",
    areacode: 770,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30256",
    city: "Meansville",
    state: "GA",
    areacode: 770,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30257",
    city: "Milner",
    state: "GA",
    areacode: 770,
    county: "Lamar",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30258",
    city: "Molena",
    state: "GA",
    areacode: 770,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30259",
    city: "Moreland",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30260",
    city: "Morrow",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30261",
    city: "Lagrange",
    state: "GA",
    areacode: 706,
    county: "Troup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30263",
    city: "Newnan",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30264",
    city: "Newnan",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30265",
    city: "Newnan",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30266",
    city: "Orchard Hill",
    state: "GA",
    areacode: 770,
    county: "Spalding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30268",
    city: "Palmetto",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30269",
    city: "Peachtree City",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30270",
    city: "Peachtree City",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30271",
    city: "Newnan",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30272",
    city: "Red Oak",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30273",
    city: "Rex",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30274",
    city: "Riverdale",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30275",
    city: "Sargent",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30276",
    city: "Senoia",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30277",
    city: "Sharpsburg",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30281",
    city: "Stockbridge",
    state: "GA",
    areacode: 770,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30284",
    city: "Sunny Side",
    state: "GA",
    areacode: 770,
    county: "Spalding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30285",
    city: "The Rock",
    state: "GA",
    areacode: 706,
    county: "Upson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30286",
    city: "Thomaston",
    state: "GA",
    areacode: 706,
    county: "Upson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30287",
    city: "Morrow",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30288",
    city: "Conley",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30289",
    city: "Turin",
    state: "GA",
    areacode: 770,
    county: "Coweta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30290",
    city: "Tyrone",
    state: "GA",
    areacode: 770,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30291",
    city: "Union City",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30292",
    city: "Williamson",
    state: "GA",
    areacode: 770,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30293",
    city: "Woodbury",
    state: "GA",
    areacode: 706,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30294",
    city: "Ellenwood",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30295",
    city: "Zebulon",
    state: "GA",
    areacode: 706,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30296",
    city: "Riverdale",
    state: "GA",
    areacode: 770,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30297",
    city: "Forest Park",
    state: "GA",
    areacode: 404,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30298",
    city: "Forest Park",
    state: "GA",
    areacode: 404,
    county: "Clayton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30301",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30302",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30303",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30304",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30305",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30306",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30307",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30308",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30309",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30310",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30311",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30312",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30313",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30314",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30315",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30316",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30317",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30318",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30319",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30320",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30321",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30322",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30324",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30325",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30326",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30327",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30328",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30329",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30331",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30332",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30333",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30334",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30336",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30337",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30338",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30339",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "Cobb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30340",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30341",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30342",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30343",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30344",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30345",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30346",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30348",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30349",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30350",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30353",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30354",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30355",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30356",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30357",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30358",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30359",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30360",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30361",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30362",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30363",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30364",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30366",
    city: "Atlanta",
    state: "GA",
    areacode: 770,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30368",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30369",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30370",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30371",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30374",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30375",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30377",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30378",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30380",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30384",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30385",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30388",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30392",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30394",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30396",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30398",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30401",
    city: "Swainsboro",
    state: "GA",
    areacode: 478,
    county: "Emanuel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30410",
    city: "Ailey",
    state: "GA",
    areacode: 912,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30411",
    city: "Alamo",
    state: "GA",
    areacode: 229,
    county: "Wheeler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30412",
    city: "Alston",
    state: "GA",
    areacode: 912,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30413",
    city: "Bartow",
    state: "GA",
    areacode: 478,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30414",
    city: "Bellville",
    state: "GA",
    areacode: 912,
    county: "Evans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30415",
    city: "Brooklet",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30417",
    city: "Claxton",
    state: "GA",
    areacode: 912,
    county: "Evans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30420",
    city: "Cobbtown",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30421",
    city: "Collins",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30423",
    city: "Daisy",
    state: "GA",
    areacode: 912,
    county: "Evans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30424",
    city: "Dover",
    state: "GA",
    areacode: 912,
    county: "Screven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30425",
    city: "Garfield",
    state: "GA",
    areacode: 478,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30426",
    city: "Girard",
    state: "GA",
    areacode: 478,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30427",
    city: "Glennville",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30428",
    city: "Glenwood",
    state: "GA",
    areacode: 229,
    county: "Wheeler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30429",
    city: "Hagan",
    state: "GA",
    areacode: 912,
    county: "Evans",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30434",
    city: "Louisville",
    state: "GA",
    areacode: 478,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30436",
    city: "Lyons",
    state: "GA",
    areacode: 912,
    county: "Toombs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30438",
    city: "Manassas",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30439",
    city: "Metter",
    state: "GA",
    areacode: 912,
    county: "Candler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30441",
    city: "Midville",
    state: "GA",
    areacode: 478,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30442",
    city: "Millen",
    state: "GA",
    areacode: 478,
    county: "Jenkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30445",
    city: "Mount Vernon",
    state: "GA",
    areacode: 912,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30446",
    city: "Newington",
    state: "GA",
    areacode: 912,
    county: "Screven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30447",
    city: "Norristown",
    state: "GA",
    areacode: 478,
    county: "Emanuel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30448",
    city: "Nunez",
    state: "GA",
    areacode: 478,
    county: "Emanuel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30449",
    city: "Oliver",
    state: "GA",
    areacode: 912,
    county: "Screven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30450",
    city: "Portal",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30451",
    city: "Pulaski",
    state: "GA",
    areacode: 912,
    county: "Candler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30452",
    city: "Register",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30453",
    city: "Reidsville",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30454",
    city: "Rockledge",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30455",
    city: "Rocky Ford",
    state: "GA",
    areacode: 912,
    county: "Screven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30456",
    city: "Sardis",
    state: "GA",
    areacode: 478,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30457",
    city: "Soperton",
    state: "GA",
    areacode: 912,
    county: "Treutlen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30458",
    city: "Statesboro",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30459",
    city: "Statesboro",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30460",
    city: "Statesboro",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30461",
    city: "Statesboro",
    state: "GA",
    areacode: 912,
    county: "Bulloch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30464",
    city: "Stillmore",
    state: "GA",
    areacode: 478,
    county: "Emanuel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30467",
    city: "Sylvania",
    state: "GA",
    areacode: 912,
    county: "Screven",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30470",
    city: "Tarrytown",
    state: "GA",
    areacode: 912,
    county: "Treutlen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30471",
    city: "Twin City",
    state: "GA",
    areacode: 478,
    county: "Emanuel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30473",
    city: "Uvalda",
    state: "GA",
    areacode: 912,
    county: "Toombs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30474",
    city: "Vidalia",
    state: "GA",
    areacode: 912,
    county: "Toombs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30475",
    city: "Vidalia",
    state: "GA",
    areacode: 912,
    county: "Toombs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30477",
    city: "Wadley",
    state: "GA",
    areacode: 478,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30499",
    city: "Reidsville",
    state: "GA",
    areacode: 912,
    county: "Tattnall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30501",
    city: "Gainesville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30502",
    city: "Chestnut Mountain",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30503",
    city: "Gainesville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30504",
    city: "Gainesville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30506",
    city: "Gainesville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30507",
    city: "Gainesville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30510",
    city: "Alto",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30511",
    city: "Baldwin",
    state: "GA",
    areacode: 706,
    county: "Banks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30512",
    city: "Blairsville",
    state: "GA",
    areacode: 706,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30513",
    city: "Blue Ridge",
    state: "GA",
    areacode: 706,
    county: "Fannin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30514",
    city: "Blairsville",
    state: "GA",
    areacode: 706,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30515",
    city: "Buford",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30516",
    city: "Bowersville",
    state: "GA",
    areacode: 706,
    county: "Hart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30517",
    city: "Braselton",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30518",
    city: "Buford",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30519",
    city: "Buford",
    state: "GA",
    areacode: 770,
    county: "Gwinnett",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30520",
    city: "Canon",
    state: "GA",
    areacode: 706,
    county: "Hart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30521",
    city: "Carnesville",
    state: "GA",
    areacode: 706,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30522",
    city: "Cherry Log",
    state: "GA",
    areacode: 706,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30523",
    city: "Clarkesville",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30525",
    city: "Clayton",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30527",
    city: "Clermont",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30528",
    city: "Cleveland",
    state: "GA",
    areacode: 706,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30529",
    city: "Commerce",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30530",
    city: "Commerce",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30531",
    city: "Cornelia",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30533",
    city: "Dahlonega",
    state: "GA",
    areacode: 706,
    county: "Lumpkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30534",
    city: "Dawsonville",
    state: "GA",
    areacode: 706,
    county: "Dawson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30535",
    city: "Demorest",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30536",
    city: "Ellijay",
    state: "GA",
    areacode: 706,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30537",
    city: "Dillard",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30538",
    city: "Eastanollee",
    state: "GA",
    areacode: 706,
    county: "Stephens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30539",
    city: "East Ellijay",
    state: "GA",
    areacode: 706,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30540",
    city: "Ellijay",
    state: "GA",
    areacode: 706,
    county: "Gilmer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30541",
    city: "Epworth",
    state: "GA",
    areacode: 706,
    county: "Fannin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30542",
    city: "Flowery Branch",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30543",
    city: "Gillsville",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30545",
    city: "Helen",
    state: "GA",
    areacode: 706,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30546",
    city: "Hiawassee",
    state: "GA",
    areacode: 706,
    county: "Towns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30547",
    city: "Homer",
    state: "GA",
    areacode: 706,
    county: "Banks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30548",
    city: "Hoschton",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30549",
    city: "Jefferson",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30552",
    city: "Lakemont",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30553",
    city: "Lavonia",
    state: "GA",
    areacode: 706,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30554",
    city: "Lula",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30555",
    city: "McCaysville",
    state: "GA",
    areacode: 706,
    county: "Fannin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30557",
    city: "Martin",
    state: "GA",
    areacode: 706,
    county: "Stephens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30558",
    city: "Maysville",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30559",
    city: "Mineral Bluff",
    state: "GA",
    areacode: 706,
    county: "Fannin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30560",
    city: "Morganton",
    state: "GA",
    areacode: 706,
    county: "Fannin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30562",
    city: "Mountain City",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30563",
    city: "Mount Airy",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30564",
    city: "Murrayville",
    state: "GA",
    areacode: 770,
    county: "Lumpkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30565",
    city: "Nicholson",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30566",
    city: "Oakwood",
    state: "GA",
    areacode: 770,
    county: "Hall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30567",
    city: "Pendergrass",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30568",
    city: "Rabun Gap",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30571",
    city: "Sautee Nacoochee",
    state: "GA",
    areacode: 706,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30572",
    city: "Suches",
    state: "GA",
    areacode: 706,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30573",
    city: "Tallulah Falls",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30575",
    city: "Talmo",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30576",
    city: "Tiger",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30577",
    city: "Toccoa",
    state: "GA",
    areacode: 706,
    county: "Stephens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30580",
    city: "Turnerville",
    state: "GA",
    areacode: 706,
    county: "Habersham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30581",
    city: "Wiley",
    state: "GA",
    areacode: 706,
    county: "Rabun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30582",
    city: "Young Harris",
    state: "GA",
    areacode: 706,
    county: "Towns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30597",
    city: "Dahlonega",
    state: "GA",
    areacode: 706,
    county: "Lumpkin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30598",
    city: "Toccoa Falls",
    state: "GA",
    areacode: 706,
    county: "Stephens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30599",
    city: "Commerce",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30601",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30602",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30603",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30604",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30605",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30606",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30607",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30608",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30609",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30612",
    city: "Athens",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30619",
    city: "Arnoldsville",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30620",
    city: "Bethlehem",
    state: "GA",
    areacode: 770,
    county: "Barrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30621",
    city: "Bishop",
    state: "GA",
    areacode: 706,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30622",
    city: "Bogart",
    state: "GA",
    areacode: 770,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30623",
    city: "Bostwick",
    state: "GA",
    areacode: 706,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30624",
    city: "Bowman",
    state: "GA",
    areacode: 706,
    county: "Elbert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30625",
    city: "Buckhead",
    state: "GA",
    areacode: 706,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30627",
    city: "Carlton",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30628",
    city: "Colbert",
    state: "GA",
    areacode: 706,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30629",
    city: "Comer",
    state: "GA",
    areacode: 706,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30630",
    city: "Crawford",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30631",
    city: "Crawfordville",
    state: "GA",
    areacode: 706,
    county: "Taliaferro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30633",
    city: "Danielsville",
    state: "GA",
    areacode: 706,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30634",
    city: "Dewy Rose",
    state: "GA",
    areacode: 706,
    county: "Elbert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30635",
    city: "Elberton",
    state: "GA",
    areacode: 706,
    county: "Elbert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30638",
    city: "Farmington",
    state: "GA",
    areacode: 706,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30639",
    city: "Franklin Springs",
    state: "GA",
    areacode: 706,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30641",
    city: "Good Hope",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30642",
    city: "Greensboro",
    state: "GA",
    areacode: 706,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30643",
    city: "Hartwell",
    state: "GA",
    areacode: 706,
    county: "Hart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30645",
    city: "High Shoals",
    state: "GA",
    areacode: 706,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30646",
    city: "Hull",
    state: "GA",
    areacode: 706,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30647",
    city: "Ila",
    state: "GA",
    areacode: 706,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30648",
    city: "Lexington",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30650",
    city: "Madison",
    state: "GA",
    areacode: 706,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30655",
    city: "Monroe",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30656",
    city: "Monroe",
    state: "GA",
    areacode: 770,
    county: "Walton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30660",
    city: "Rayle",
    state: "GA",
    areacode: 706,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30662",
    city: "Royston",
    state: "GA",
    areacode: 706,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30663",
    city: "Rutledge",
    state: "GA",
    areacode: 706,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30664",
    city: "Sharon",
    state: "GA",
    areacode: 706,
    county: "Taliaferro",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30665",
    city: "Siloam",
    state: "GA",
    areacode: 706,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30666",
    city: "Statham",
    state: "GA",
    areacode: 770,
    county: "Barrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30667",
    city: "Stephens",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30668",
    city: "Tignall",
    state: "GA",
    areacode: 706,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30669",
    city: "Union Point",
    state: "GA",
    areacode: 706,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30671",
    city: "Maxeys",
    state: "GA",
    areacode: 706,
    county: "Oglethorpe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30673",
    city: "Washington",
    state: "GA",
    areacode: 706,
    county: "Wilkes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30677",
    city: "Watkinsville",
    state: "GA",
    areacode: 706,
    county: "Oconee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30678",
    city: "White Plains",
    state: "GA",
    areacode: 706,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30680",
    city: "Winder",
    state: "GA",
    areacode: 770,
    county: "Barrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30683",
    city: "Winterville",
    state: "GA",
    areacode: 706,
    county: "Clarke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30701",
    city: "Calhoun",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30703",
    city: "Calhoun",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30705",
    city: "Chatsworth",
    state: "GA",
    areacode: 706,
    county: "Murray",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30707",
    city: "Chickamauga",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30708",
    city: "Cisco",
    state: "GA",
    areacode: 706,
    county: "Murray",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30710",
    city: "Cohutta",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30711",
    city: "Crandall",
    state: "GA",
    areacode: 706,
    county: "Murray",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30719",
    city: "Dalton",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30720",
    city: "Dalton",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30721",
    city: "Dalton",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30722",
    city: "Dalton",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30724",
    city: "Eton",
    state: "GA",
    areacode: 706,
    county: "Murray",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30725",
    city: "Flintstone",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30726",
    city: "Graysville",
    state: "GA",
    areacode: 706,
    county: "Catoosa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30728",
    city: "La Fayette",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30730",
    city: "Lyerly",
    state: "GA",
    areacode: 706,
    county: "Chattooga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30731",
    city: "Menlo",
    state: "GA",
    areacode: 706,
    county: "Chattooga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30732",
    city: "Oakman",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30733",
    city: "Plainville",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30734",
    city: "Ranger",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30735",
    city: "Resaca",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30736",
    city: "Ringgold",
    state: "GA",
    areacode: 706,
    county: "Catoosa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30738",
    city: "Rising Fawn",
    state: "GA",
    areacode: 706,
    county: "Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30739",
    city: "Rock Spring",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30740",
    city: "Rocky Face",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30741",
    city: "Rossville",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30742",
    city: "Fort Oglethorpe",
    state: "GA",
    areacode: 706,
    county: "Catoosa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30746",
    city: "Sugar Valley",
    state: "GA",
    areacode: 706,
    county: "Gordon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30747",
    city: "Summerville",
    state: "GA",
    areacode: 706,
    county: "Chattooga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30750",
    city: "Lookout Mountain",
    state: "GA",
    areacode: 706,
    county: "Walker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30751",
    city: "Tennga",
    state: "GA",
    areacode: 706,
    county: "Murray",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30752",
    city: "Trenton",
    state: "GA",
    areacode: 706,
    county: "Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30753",
    city: "Trion",
    state: "GA",
    areacode: 706,
    county: "Chattooga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30755",
    city: "Tunnel Hill",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30756",
    city: "Varnell",
    state: "GA",
    areacode: 706,
    county: "Whitfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30757",
    city: "Wildwood",
    state: "GA",
    areacode: 706,
    county: "Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30802",
    city: "Appling",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30803",
    city: "Avera",
    state: "GA",
    areacode: 706,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30805",
    city: "Blythe",
    state: "GA",
    areacode: 706,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30806",
    city: "Boneville",
    state: "GA",
    areacode: 706,
    county: "McDuffie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30807",
    city: "Camak",
    state: "GA",
    areacode: 706,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30808",
    city: "Dearing",
    state: "GA",
    areacode: 706,
    county: "McDuffie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30809",
    city: "Evans",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30810",
    city: "Gibson",
    state: "GA",
    areacode: 706,
    county: "Glascock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30811",
    city: "Gough",
    state: "GA",
    areacode: 706,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30812",
    city: "Gracewood",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30813",
    city: "Grovetown",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30814",
    city: "Harlem",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30815",
    city: "Hephzibah",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30816",
    city: "Keysville",
    state: "GA",
    areacode: 706,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30817",
    city: "Lincolnton",
    state: "GA",
    areacode: 706,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30818",
    city: "Matthews",
    state: "GA",
    areacode: 706,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30819",
    city: "Mesena",
    state: "GA",
    areacode: 706,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30820",
    city: "Mitchell",
    state: "GA",
    areacode: 706,
    county: "Glascock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30821",
    city: "Norwood",
    state: "GA",
    areacode: 706,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30822",
    city: "Perkins",
    state: "GA",
    areacode: 478,
    county: "Jenkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30823",
    city: "Stapleton",
    state: "GA",
    areacode: 706,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30824",
    city: "Thomson",
    state: "GA",
    areacode: 706,
    county: "McDuffie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30828",
    city: "Warrenton",
    state: "GA",
    areacode: 706,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30830",
    city: "Waynesboro",
    state: "GA",
    areacode: 706,
    county: "Burke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30833",
    city: "Wrens",
    state: "GA",
    areacode: 706,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30901",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30903",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30904",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30905",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30906",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30907",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30909",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30912",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30914",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30916",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30917",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30919",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "30999",
    city: "Augusta",
    state: "GA",
    areacode: 706,
    county: "Richmond",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31001",
    city: "Abbeville",
    state: "GA",
    areacode: 229,
    county: "Wilcox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31002",
    city: "Adrian",
    state: "GA",
    areacode: 478,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31003",
    city: "Allentown",
    state: "GA",
    areacode: 478,
    county: "Wilkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31004",
    city: "Bolingbroke",
    state: "GA",
    areacode: 478,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31005",
    city: "Bonaire",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31006",
    city: "Butler",
    state: "GA",
    areacode: 478,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31007",
    city: "Byromville",
    state: "GA",
    areacode: 478,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31008",
    city: "Byron",
    state: "GA",
    areacode: 478,
    county: "Peach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31009",
    city: "Cadwell",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31010",
    city: "Cordele",
    state: "GA",
    areacode: 229,
    county: "Crisp",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31011",
    city: "Chauncey",
    state: "GA",
    areacode: 478,
    county: "Dodge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31012",
    city: "Chester",
    state: "GA",
    areacode: 478,
    county: "Dodge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31013",
    city: "Clinchfield",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31014",
    city: "Cochran",
    state: "GA",
    areacode: 478,
    county: "Bleckley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31015",
    city: "Cordele",
    state: "GA",
    areacode: 229,
    county: "Crisp",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31016",
    city: "Culloden",
    state: "GA",
    areacode: 478,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31017",
    city: "Danville",
    state: "GA",
    areacode: 478,
    county: "Twiggs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31018",
    city: "Davisboro",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31019",
    city: "Dexter",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31020",
    city: "Dry Branch",
    state: "GA",
    areacode: 478,
    county: "Twiggs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31021",
    city: "Dublin",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31022",
    city: "Dudley",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31023",
    city: "Eastman",
    state: "GA",
    areacode: 478,
    county: "Dodge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31024",
    city: "Eatonton",
    state: "GA",
    areacode: 706,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31025",
    city: "Elko",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31026",
    city: "Eatonton",
    state: "GA",
    areacode: 706,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31027",
    city: "East Dublin",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31028",
    city: "Centerville",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31029",
    city: "Forsyth",
    state: "GA",
    areacode: 478,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31030",
    city: "Fort Valley",
    state: "GA",
    areacode: 478,
    county: "Peach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31031",
    city: "Gordon",
    state: "GA",
    areacode: 478,
    county: "Wilkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31032",
    city: "Gray",
    state: "GA",
    areacode: 478,
    county: "Jones",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31033",
    city: "Haddock",
    state: "GA",
    areacode: 478,
    county: "Jones",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31034",
    city: "Hardwick",
    state: "GA",
    areacode: 478,
    county: "Baldwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31035",
    city: "Harrison",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31036",
    city: "Hawkinsville",
    state: "GA",
    areacode: 478,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31037",
    city: "McRae Helena",
    state: "GA",
    areacode: 229,
    county: "Dodge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31038",
    city: "Hillsboro",
    state: "GA",
    areacode: 706,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31039",
    city: "Howard",
    state: "GA",
    areacode: 478,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31040",
    city: "Dublin",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31041",
    city: "Ideal",
    state: "GA",
    areacode: 478,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31042",
    city: "Irwinton",
    state: "GA",
    areacode: 478,
    county: "Wilkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31044",
    city: "Jeffersonville",
    state: "GA",
    areacode: 478,
    county: "Twiggs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31045",
    city: "Jewell",
    state: "GA",
    areacode: 706,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31046",
    city: "Juliette",
    state: "GA",
    areacode: 478,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31047",
    city: "Kathleen",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31049",
    city: "Kite",
    state: "GA",
    areacode: 478,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31050",
    city: "Knoxville",
    state: "GA",
    areacode: 478,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31051",
    city: "Lilly",
    state: "GA",
    areacode: 478,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31052",
    city: "Lizella",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31054",
    city: "McIntyre",
    state: "GA",
    areacode: 478,
    county: "Wilkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31055",
    city: "McRae Helena",
    state: "GA",
    areacode: 229,
    county: "Telfair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31057",
    city: "Marshallville",
    state: "GA",
    areacode: 478,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31058",
    city: "Mauk",
    state: "GA",
    areacode: 478,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31059",
    city: "Milledgeville",
    state: "GA",
    areacode: 478,
    county: "Baldwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31060",
    city: "Milan",
    state: "GA",
    areacode: 229,
    county: "Telfair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31061",
    city: "Milledgeville",
    state: "GA",
    areacode: 478,
    county: "Baldwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31062",
    city: "Milledgeville",
    state: "GA",
    areacode: 478,
    county: "Baldwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31063",
    city: "Montezuma",
    state: "GA",
    areacode: 478,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31064",
    city: "Monticello",
    state: "GA",
    areacode: 706,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31065",
    city: "Montrose",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31066",
    city: "Musella",
    state: "GA",
    areacode: 478,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31067",
    city: "Oconee",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31068",
    city: "Oglethorpe",
    state: "GA",
    areacode: 478,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31069",
    city: "Perry",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31070",
    city: "Pinehurst",
    state: "GA",
    areacode: 478,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31071",
    city: "Pineview",
    state: "GA",
    areacode: 229,
    county: "Wilcox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31072",
    city: "Pitts",
    state: "GA",
    areacode: 229,
    county: "Wilcox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31075",
    city: "Rentz",
    state: "GA",
    areacode: 478,
    county: "Laurens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31076",
    city: "Reynolds",
    state: "GA",
    areacode: 478,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31077",
    city: "Rhine",
    state: "GA",
    areacode: 229,
    county: "Dodge",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31078",
    city: "Roberta",
    state: "GA",
    areacode: 478,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31079",
    city: "Rochelle",
    state: "GA",
    areacode: 229,
    county: "Wilcox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31081",
    city: "Rupert",
    state: "GA",
    areacode: 478,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31082",
    city: "Sandersville",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31083",
    city: "Scotland",
    state: "GA",
    areacode: 912,
    county: "Telfair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31084",
    city: "Seville",
    state: "GA",
    areacode: 229,
    county: "Wilcox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31085",
    city: "Shady Dale",
    state: "GA",
    areacode: 706,
    county: "Jasper",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31086",
    city: "Smarr",
    state: "GA",
    areacode: 478,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31087",
    city: "Sparta",
    state: "GA",
    areacode: 706,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31088",
    city: "Warner Robins",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31089",
    city: "Tennille",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31090",
    city: "Toomsboro",
    state: "GA",
    areacode: 478,
    county: "Wilkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31091",
    city: "Unadilla",
    state: "GA",
    areacode: 478,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31092",
    city: "Vienna",
    state: "GA",
    areacode: 229,
    county: "Dooly",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31093",
    city: "Warner Robins",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31094",
    city: "Warthen",
    state: "GA",
    areacode: 478,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31095",
    city: "Warner Robins",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31096",
    city: "Wrightsville",
    state: "GA",
    areacode: 478,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31097",
    city: "Yatesville",
    state: "GA",
    areacode: 706,
    county: "Upson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31098",
    city: "Warner Robins",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31099",
    city: "Warner Robins",
    state: "GA",
    areacode: 478,
    county: "Houston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31106",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31107",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31119",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31126",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31131",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31136",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31139",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31141",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31145",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31146",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31150",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31156",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31169",
    city: "Peachtree City",
    state: "GA",
    areacode: 404,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31192",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31193",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31195",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31196",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31201",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31202",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31203",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31204",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31205",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31206",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31207",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31208",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31209",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31210",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31211",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31213",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31216",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31217",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31220",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31221",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31294",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31295",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31296",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31297",
    city: "Macon",
    state: "GA",
    areacode: 478,
    county: "Bibb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31301",
    city: "Allenhurst",
    state: "GA",
    areacode: 912,
    county: "Long",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31302",
    city: "Bloomingdale",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31303",
    city: "Clyo",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31304",
    city: "Crescent",
    state: "GA",
    areacode: 912,
    county: "McIntosh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31305",
    city: "Darien",
    state: "GA",
    areacode: 912,
    county: "McIntosh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31307",
    city: "Eden",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31308",
    city: "Ellabell",
    state: "GA",
    areacode: 912,
    county: "Bryan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31309",
    city: "Fleming",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31310",
    city: "Hinesville",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31312",
    city: "Guyton",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31313",
    city: "Hinesville",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31314",
    city: "Fort Stewart",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31315",
    city: "Fort Stewart",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31316",
    city: "Ludowici",
    state: "GA",
    areacode: 912,
    county: "Long",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31318",
    city: "Meldrim",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31319",
    city: "Meridian",
    state: "GA",
    areacode: 912,
    county: "McIntosh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31320",
    city: "Midway",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31321",
    city: "Pembroke",
    state: "GA",
    areacode: 912,
    county: "Bryan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31322",
    city: "Pooler",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31323",
    city: "Riceboro",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31324",
    city: "Richmond Hill",
    state: "GA",
    areacode: 912,
    county: "Bryan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31326",
    city: "Rincon",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31327",
    city: "Sapelo Island",
    state: "GA",
    areacode: 912,
    county: "McIntosh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31328",
    city: "Tybee Island",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31329",
    city: "Springfield",
    state: "GA",
    areacode: 912,
    county: "Effingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31331",
    city: "Townsend",
    state: "GA",
    areacode: 912,
    county: "McIntosh",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31333",
    city: "Walthourville",
    state: "GA",
    areacode: 912,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31401",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31402",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31403",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31404",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31405",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31406",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31407",
    city: "Port Wentworth",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31408",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31409",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31410",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31411",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31412",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31414",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31415",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31416",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31418",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31419",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31420",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31421",
    city: "Savannah",
    state: "GA",
    areacode: 912,
    county: "Chatham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31501",
    city: "Waycross",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31502",
    city: "Waycross",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31503",
    city: "Waycross",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31510",
    city: "Alma",
    state: "GA",
    areacode: 912,
    county: "Bacon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31512",
    city: "Ambrose",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31513",
    city: "Baxley",
    state: "GA",
    areacode: 912,
    county: "Appling",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31515",
    city: "Baxley",
    state: "GA",
    areacode: 912,
    county: "Appling",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31516",
    city: "Blackshear",
    state: "GA",
    areacode: 912,
    county: "Pierce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31518",
    city: "Bristol",
    state: "GA",
    areacode: 912,
    county: "Appling",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31519",
    city: "Broxton",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31520",
    city: "Brunswick",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31521",
    city: "Brunswick",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31522",
    city: "Saint Simons Island",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31523",
    city: "Brunswick",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31524",
    city: "Brunswick",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31525",
    city: "Brunswick",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31527",
    city: "Jekyll Island",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31532",
    city: "Denton",
    state: "GA",
    areacode: 912,
    county: "Jeff Davis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31533",
    city: "Douglas",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31534",
    city: "Douglas",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31535",
    city: "Douglas",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31537",
    city: "Folkston",
    state: "GA",
    areacode: 912,
    county: "Charlton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31539",
    city: "Hazlehurst",
    state: "GA",
    areacode: 912,
    county: "Jeff Davis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31542",
    city: "Hoboken",
    state: "GA",
    areacode: 912,
    county: "Brantley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31543",
    city: "Hortense",
    state: "GA",
    areacode: 912,
    county: "Brantley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31544",
    city: "Jacksonville",
    state: "GA",
    areacode: 229,
    county: "Telfair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31545",
    city: "Jesup",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31546",
    city: "Jesup",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31547",
    city: "Kings Bay",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31548",
    city: "Kingsland",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31549",
    city: "Lumber City",
    state: "GA",
    areacode: 912,
    county: "Telfair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31550",
    city: "Manor",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31551",
    city: "Mershon",
    state: "GA",
    areacode: 912,
    county: "Pierce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31552",
    city: "Millwood",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31553",
    city: "Nahunta",
    state: "GA",
    areacode: 912,
    county: "Brantley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31554",
    city: "Nicholls",
    state: "GA",
    areacode: 912,
    county: "Coffee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31555",
    city: "Odum",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31556",
    city: "Offerman",
    state: "GA",
    areacode: 912,
    county: "Pierce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31557",
    city: "Patterson",
    state: "GA",
    areacode: 912,
    county: "Pierce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31558",
    city: "Saint Marys",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31560",
    city: "Screven",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31561",
    city: "Sea Island",
    state: "GA",
    areacode: 912,
    county: "Glynn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31562",
    city: "Saint George",
    state: "GA",
    areacode: 912,
    county: "Charlton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31563",
    city: "Surrency",
    state: "GA",
    areacode: 912,
    county: "Appling",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31564",
    city: "Waresboro",
    state: "GA",
    areacode: 912,
    county: "Ware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31565",
    city: "Waverly",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31566",
    city: "Waynesville",
    state: "GA",
    areacode: 912,
    county: "Brantley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31567",
    city: "West Green",
    state: "GA",
    areacode: 912,
    county: "Jeff Davis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31568",
    city: "White Oak",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31569",
    city: "Woodbine",
    state: "GA",
    areacode: 912,
    county: "Camden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31598",
    city: "Jesup",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31599",
    city: "Jesup",
    state: "GA",
    areacode: 912,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31601",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31602",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31603",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31604",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31605",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31606",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31620",
    city: "Adel",
    state: "GA",
    areacode: 229,
    county: "Cook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31622",
    city: "Alapaha",
    state: "GA",
    areacode: 229,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31623",
    city: "Argyle",
    state: "GA",
    areacode: 912,
    county: "Clinch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31624",
    city: "Axson",
    state: "GA",
    areacode: 912,
    county: "Atkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31625",
    city: "Barney",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31626",
    city: "Boston",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31627",
    city: "Cecil",
    state: "GA",
    areacode: 229,
    county: "Cook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31629",
    city: "Dixie",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31630",
    city: "Du Pont",
    state: "GA",
    areacode: 912,
    county: "Clinch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31631",
    city: "Fargo",
    state: "GA",
    areacode: 912,
    county: "Clinch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31632",
    city: "Hahira",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31634",
    city: "Homerville",
    state: "GA",
    areacode: 912,
    county: "Clinch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31635",
    city: "Lakeland",
    state: "GA",
    areacode: 229,
    county: "Lanier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31636",
    city: "Lake Park",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31637",
    city: "Lenox",
    state: "GA",
    areacode: 229,
    county: "Cook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31638",
    city: "Morven",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31639",
    city: "Nashville",
    state: "GA",
    areacode: 229,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31641",
    city: "Naylor",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31642",
    city: "Pearson",
    state: "GA",
    areacode: 912,
    county: "Atkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31643",
    city: "Quitman",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31645",
    city: "Ray City",
    state: "GA",
    areacode: 229,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31647",
    city: "Sparks",
    state: "GA",
    areacode: 229,
    county: "Cook",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31648",
    city: "Statenville",
    state: "GA",
    areacode: 229,
    county: "Echols",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31649",
    city: "Stockton",
    state: "GA",
    areacode: 229,
    county: "Lanier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31650",
    city: "Willacoochee",
    state: "GA",
    areacode: 912,
    county: "Atkinson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31698",
    city: "Valdosta",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31699",
    city: "Moody AFB",
    state: "GA",
    areacode: 229,
    county: "Lowndes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31701",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31702",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31703",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31704",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31705",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31706",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31707",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31708",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31709",
    city: "Americus",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31711",
    city: "Andersonville",
    state: "GA",
    areacode: 229,
    county: "Macon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31712",
    city: "Arabi",
    state: "GA",
    areacode: 229,
    county: "Crisp",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31714",
    city: "Ashburn",
    state: "GA",
    areacode: 229,
    county: "Turner",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31716",
    city: "Baconton",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31719",
    city: "Americus",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31720",
    city: "Barwick",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31721",
    city: "Albany",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31722",
    city: "Berlin",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31727",
    city: "Brookfield",
    state: "GA",
    areacode: 229,
    county: "Tift",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31730",
    city: "Camilla",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31733",
    city: "Chula",
    state: "GA",
    areacode: 229,
    county: "Tift",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31735",
    city: "Cobb",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31738",
    city: "Coolidge",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31739",
    city: "Cotton",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31743",
    city: "De Soto",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31744",
    city: "Doerun",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31747",
    city: "Ellenton",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31749",
    city: "Enigma",
    state: "GA",
    areacode: 229,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31750",
    city: "Fitzgerald",
    state: "GA",
    areacode: 229,
    county: "Ben Hill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31753",
    city: "Funston",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31756",
    city: "Hartsfield",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31757",
    city: "Thomasville",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31758",
    city: "Thomasville",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31760",
    city: "Irwinville",
    state: "GA",
    areacode: 229,
    county: "Irwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31763",
    city: "Leesburg",
    state: "GA",
    areacode: 229,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31764",
    city: "Leslie",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31765",
    city: "Meigs",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31768",
    city: "Moultrie",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31769",
    city: "Mystic",
    state: "GA",
    areacode: 229,
    county: "Irwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31771",
    city: "Norman Park",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31772",
    city: "Oakfield",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31773",
    city: "Ochlocknee",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31774",
    city: "Ocilla",
    state: "GA",
    areacode: 229,
    county: "Irwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31775",
    city: "Omega",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31776",
    city: "Moultrie",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31778",
    city: "Pavo",
    state: "GA",
    areacode: 229,
    county: "Brooks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31779",
    city: "Pelham",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31780",
    city: "Plains",
    state: "GA",
    areacode: 229,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31781",
    city: "Poulan",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31782",
    city: "Putney",
    state: "GA",
    areacode: 229,
    county: "Dougherty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31783",
    city: "Rebecca",
    state: "GA",
    areacode: 229,
    county: "Turner",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31784",
    city: "Sale City",
    state: "GA",
    areacode: 229,
    county: "Mitchell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31787",
    city: "Smithville",
    state: "GA",
    areacode: 229,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31788",
    city: "Moultrie",
    state: "GA",
    areacode: 229,
    county: "Colquitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31789",
    city: "Sumner",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31790",
    city: "Sycamore",
    state: "GA",
    areacode: 229,
    county: "Turner",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31791",
    city: "Sylvester",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31792",
    city: "Thomasville",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31793",
    city: "Tifton",
    state: "GA",
    areacode: 229,
    county: "Tift",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31794",
    city: "Tifton",
    state: "GA",
    areacode: 229,
    county: "Tift",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31795",
    city: "Ty Ty",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31796",
    city: "Warwick",
    state: "GA",
    areacode: 229,
    county: "Worth",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31798",
    city: "Wray",
    state: "GA",
    areacode: 229,
    county: "Irwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31799",
    city: "Thomasville",
    state: "GA",
    areacode: 229,
    county: "Thomas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31801",
    city: "Box Springs",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31803",
    city: "Buena Vista",
    state: "GA",
    areacode: 229,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31804",
    city: "Cataula",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31805",
    city: "Cusseta",
    state: "GA",
    areacode: 706,
    county: "Chattahoochee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31806",
    city: "Ellaville",
    state: "GA",
    areacode: 229,
    county: "Schley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31807",
    city: "Ellerslie",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31808",
    city: "Fortson",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31810",
    city: "Geneva",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31811",
    city: "Hamilton",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31812",
    city: "Junction City",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31814",
    city: "Louvale",
    state: "GA",
    areacode: 229,
    county: "Stewart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31815",
    city: "Lumpkin",
    state: "GA",
    areacode: 229,
    county: "Stewart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31816",
    city: "Manchester",
    state: "GA",
    areacode: 706,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31820",
    city: "Midland",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31821",
    city: "Omaha",
    state: "GA",
    areacode: 229,
    county: "Stewart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31822",
    city: "Pine Mountain",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31823",
    city: "Pine Mountain Valley",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31824",
    city: "Preston",
    state: "GA",
    areacode: 229,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31825",
    city: "Richland",
    state: "GA",
    areacode: 229,
    county: "Stewart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31826",
    city: "Shiloh",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31827",
    city: "Talbotton",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31829",
    city: "Upatoi",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31830",
    city: "Warm Springs",
    state: "GA",
    areacode: 706,
    county: "Meriwether",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31831",
    city: "Waverly Hall",
    state: "GA",
    areacode: 706,
    county: "Harris",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31832",
    city: "Weston",
    state: "GA",
    areacode: 229,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31833",
    city: "West Point",
    state: "GA",
    areacode: 706,
    county: "Troup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31836",
    city: "Woodland",
    state: "GA",
    areacode: 706,
    county: "Talbot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31901",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31902",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31903",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31904",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31905",
    city: "Fort Benning",
    state: "GA",
    areacode: 706,
    county: "Chattahoochee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31906",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31907",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31908",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31909",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31914",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31917",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31993",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31995",
    city: "Fort Benning",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31997",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31998",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "31999",
    city: "Columbus",
    state: "GA",
    areacode: 706,
    county: "Muscogee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32003",
    city: "Fleming Island",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32004",
    city: "Ponte Vedra Beach",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32006",
    city: "Fleming Island",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32007",
    city: "Bostwick",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32008",
    city: "Branford",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32009",
    city: "Bryceville",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32011",
    city: "Callahan",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32013",
    city: "Day",
    state: "FL",
    areacode: 386,
    county: "Lafayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32024",
    city: "Lake City",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32025",
    city: "Lake City",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32030",
    city: "Doctors Inlet",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32033",
    city: "Elkton",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32034",
    city: "Fernandina Beach",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32035",
    city: "Fernandina Beach",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32038",
    city: "Fort White",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32040",
    city: "Glen Saint Mary",
    state: "FL",
    areacode: 904,
    county: "Baker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32041",
    city: "Yulee",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32042",
    city: "Graham",
    state: "FL",
    areacode: 352,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32043",
    city: "Green Cove Springs",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32044",
    city: "Hampton",
    state: "FL",
    areacode: 904,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32046",
    city: "Hilliard",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32050",
    city: "Middleburg",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32052",
    city: "Jasper",
    state: "FL",
    areacode: 386,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32053",
    city: "Jennings",
    state: "FL",
    areacode: 386,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32054",
    city: "Lake Butler",
    state: "FL",
    areacode: 386,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32055",
    city: "Lake City",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32056",
    city: "Lake City",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32058",
    city: "Lawtey",
    state: "FL",
    areacode: 904,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32059",
    city: "Lee",
    state: "FL",
    areacode: 850,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32060",
    city: "Live Oak",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32061",
    city: "Lulu",
    state: "FL",
    areacode: 386,
    county: "Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32062",
    city: "McAlpin",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32063",
    city: "Macclenny",
    state: "FL",
    areacode: 904,
    county: "Baker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32064",
    city: "Live Oak",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32065",
    city: "Orange Park",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32066",
    city: "Mayo",
    state: "FL",
    areacode: 386,
    county: "Lafayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32067",
    city: "Orange Park",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32068",
    city: "Middleburg",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32071",
    city: "O Brien",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32073",
    city: "Orange Park",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32079",
    city: "Penney Farms",
    state: "FL",
    areacode: 904,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32080",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32081",
    city: "Ponte Vedra",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32082",
    city: "Ponte Vedra Beach",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32083",
    city: "Raiford",
    state: "FL",
    areacode: 386,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32084",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32085",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32086",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32087",
    city: "Sanderson",
    state: "FL",
    areacode: 904,
    county: "Baker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32091",
    city: "Starke",
    state: "FL",
    areacode: 904,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32092",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32094",
    city: "Wellborn",
    state: "FL",
    areacode: 386,
    county: "Suwannee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32095",
    city: "Saint Augustine",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32096",
    city: "White Springs",
    state: "FL",
    areacode: 386,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32097",
    city: "Yulee",
    state: "FL",
    areacode: 904,
    county: "Nassau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32099",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32102",
    city: "Astor",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32105",
    city: "Barberville",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32110",
    city: "Bunnell",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32111",
    city: "Candler",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32112",
    city: "Crescent City",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32113",
    city: "Citra",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32114",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32115",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32116",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32117",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32118",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32119",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32120",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32121",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32122",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32123",
    city: "Port Orange",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32124",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32125",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32126",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32127",
    city: "Port Orange",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32128",
    city: "Port Orange",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32129",
    city: "Port Orange",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32130",
    city: "De Leon Springs",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32131",
    city: "East Palatka",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32132",
    city: "Edgewater",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32133",
    city: "Eastlake Weir",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32134",
    city: "Fort McCoy",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32135",
    city: "Palm Coast",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32136",
    city: "Flagler Beach",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32137",
    city: "Palm Coast",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32138",
    city: "Grandin",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32139",
    city: "Georgetown",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32140",
    city: "Florahome",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32141",
    city: "Edgewater",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32142",
    city: "Palm Coast",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32143",
    city: "Palm Coast",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32145",
    city: "Hastings",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32147",
    city: "Hollister",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32148",
    city: "Interlachen",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32149",
    city: "Interlachen",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32157",
    city: "Lake Como",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32158",
    city: "Lady Lake",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32159",
    city: "Lady Lake",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32160",
    city: "Lake Geneva",
    state: "FL",
    areacode: 352,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32162",
    city: "The Villages",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32163",
    city: "The Villages",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32164",
    city: "Palm Coast",
    state: "FL",
    areacode: 386,
    county: "Flagler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32168",
    city: "New Smyrna Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32169",
    city: "New Smyrna Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32170",
    city: "New Smyrna Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32173",
    city: "Ormond Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32174",
    city: "Ormond Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32175",
    city: "Ormond Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32176",
    city: "Ormond Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32177",
    city: "Palatka",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32178",
    city: "Palatka",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32179",
    city: "Ocklawaha",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32180",
    city: "Pierson",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32181",
    city: "Pomona Park",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32182",
    city: "Orange Springs",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32183",
    city: "Ocklawaha",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32185",
    city: "Putnam Hall",
    state: "FL",
    areacode: 352,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32187",
    city: "San Mateo",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32189",
    city: "Satsuma",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32190",
    city: "Seville",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32192",
    city: "Sparr",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32193",
    city: "Welaka",
    state: "FL",
    areacode: 386,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32195",
    city: "Weirsdale",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32198",
    city: "Daytona Beach",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32201",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32202",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32203",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32204",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32205",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32206",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32207",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32208",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32209",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32210",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32211",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32212",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32214",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32216",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32217",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32218",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32219",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32220",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32221",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32222",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32223",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32224",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32225",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32226",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32227",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32228",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32229",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32231",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32232",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32233",
    city: "Atlantic Beach",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32234",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32235",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32236",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32238",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32239",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32240",
    city: "Jacksonville Beach",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32241",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32244",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32245",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32246",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32247",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32250",
    city: "Jacksonville Beach",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32254",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32255",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32256",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32257",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32258",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32259",
    city: "Saint Johns",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32260",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Saint Johns",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32266",
    city: "Neptune Beach",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32277",
    city: "Jacksonville",
    state: "FL",
    areacode: 904,
    county: "Duval",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32301",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32302",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32303",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32304",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32305",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32306",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32307",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32308",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32309",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32310",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32311",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32312",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32313",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32314",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32315",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32316",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32317",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32318",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32320",
    city: "Apalachicola",
    state: "FL",
    areacode: 850,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32321",
    city: "Bristol",
    state: "FL",
    areacode: 850,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32322",
    city: "Carrabelle",
    state: "FL",
    areacode: 850,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32323",
    city: "Lanark Village",
    state: "FL",
    areacode: 850,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32324",
    city: "Chattahoochee",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32326",
    city: "Crawfordville",
    state: "FL",
    areacode: 850,
    county: "Wakulla",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32327",
    city: "Crawfordville",
    state: "FL",
    areacode: 850,
    county: "Wakulla",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32328",
    city: "Eastpoint",
    state: "FL",
    areacode: 850,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32329",
    city: "Apalachicola",
    state: "FL",
    areacode: 850,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32330",
    city: "Greensboro",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32331",
    city: "Greenville",
    state: "FL",
    areacode: 850,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32332",
    city: "Gretna",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32333",
    city: "Havana",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32334",
    city: "Hosford",
    state: "FL",
    areacode: 850,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32335",
    city: "Sumatra",
    state: "FL",
    areacode: 850,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32336",
    city: "Lamont",
    state: "FL",
    areacode: 850,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32337",
    city: "Lloyd",
    state: "FL",
    areacode: 850,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32340",
    city: "Madison",
    state: "FL",
    areacode: 850,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32341",
    city: "Madison",
    state: "FL",
    areacode: 850,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32343",
    city: "Midway",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32344",
    city: "Monticello",
    state: "FL",
    areacode: 850,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32345",
    city: "Monticello",
    state: "FL",
    areacode: 850,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32346",
    city: "Panacea",
    state: "FL",
    areacode: 850,
    county: "Wakulla",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32347",
    city: "Perry",
    state: "FL",
    areacode: 850,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32348",
    city: "Perry",
    state: "FL",
    areacode: 850,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32350",
    city: "Pinetta",
    state: "FL",
    areacode: 850,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32351",
    city: "Quincy",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32352",
    city: "Quincy",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32353",
    city: "Quincy",
    state: "FL",
    areacode: 850,
    county: "Gadsden",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32355",
    city: "Saint Marks",
    state: "FL",
    areacode: 850,
    county: "Wakulla",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32356",
    city: "Salem",
    state: "FL",
    areacode: 850,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32357",
    city: "Shady Grove",
    state: "FL",
    areacode: 850,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32358",
    city: "Sopchoppy",
    state: "FL",
    areacode: 850,
    county: "Wakulla",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32359",
    city: "Steinhatchee",
    state: "FL",
    areacode: 352,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32360",
    city: "Telogia",
    state: "FL",
    areacode: 850,
    county: "Liberty",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32361",
    city: "Wacissa",
    state: "FL",
    areacode: 850,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32362",
    city: "Woodville",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32399",
    city: "Tallahassee",
    state: "FL",
    areacode: 850,
    county: "Leon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32401",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32402",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32403",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32404",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32405",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32406",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32407",
    city: "Panama City Beach",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32408",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32409",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32410",
    city: "Mexico Beach",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32411",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32412",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32413",
    city: "Panama City Beach",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32417",
    city: "Panama City",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32420",
    city: "Alford",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32421",
    city: "Altha",
    state: "FL",
    areacode: 850,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32422",
    city: "Argyle",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32423",
    city: "Bascom",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32424",
    city: "Blountstown",
    state: "FL",
    areacode: 850,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32425",
    city: "Bonifay",
    state: "FL",
    areacode: 850,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32426",
    city: "Campbellton",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32427",
    city: "Caryville",
    state: "FL",
    areacode: 850,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32428",
    city: "Chipley",
    state: "FL",
    areacode: 850,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32430",
    city: "Clarksville",
    state: "FL",
    areacode: 850,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32431",
    city: "Cottondale",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32432",
    city: "Cypress",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32433",
    city: "Defuniak Springs",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32434",
    city: "Mossy Head",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32435",
    city: "Defuniak Springs",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32437",
    city: "Ebro",
    state: "FL",
    areacode: 850,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32438",
    city: "Fountain",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32439",
    city: "Freeport",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32440",
    city: "Graceville",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32442",
    city: "Grand Ridge",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32443",
    city: "Greenwood",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32444",
    city: "Lynn Haven",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32445",
    city: "Malone",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32446",
    city: "Marianna",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32447",
    city: "Marianna",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32448",
    city: "Marianna",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32449",
    city: "Wewahitchka",
    state: "FL",
    areacode: 850,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32452",
    city: "Noma",
    state: "FL",
    areacode: 850,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32455",
    city: "Ponce de Leon",
    state: "FL",
    areacode: 850,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32456",
    city: "Port Saint Joe",
    state: "FL",
    areacode: 850,
    county: "Gulf",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32457",
    city: "Port Saint Joe",
    state: "FL",
    areacode: 850,
    county: "Gulf",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32459",
    city: "Santa Rosa Beach",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32460",
    city: "Sneads",
    state: "FL",
    areacode: 850,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32461",
    city: "Inlet Beach",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32462",
    city: "Vernon",
    state: "FL",
    areacode: 850,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32463",
    city: "Wausau",
    state: "FL",
    areacode: 850,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32464",
    city: "Westville",
    state: "FL",
    areacode: 850,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32465",
    city: "Wewahitchka",
    state: "FL",
    areacode: 850,
    county: "Gulf",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32466",
    city: "Youngstown",
    state: "FL",
    areacode: 850,
    county: "Bay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32501",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32502",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32503",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32504",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32505",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32506",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32507",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32508",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32509",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32511",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32512",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32513",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32514",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32516",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32520",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32521",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32522",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32523",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32524",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32526",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32530",
    city: "Bagdad",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32531",
    city: "Baker",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32533",
    city: "Cantonment",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32534",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32535",
    city: "Century",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32536",
    city: "Crestview",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32537",
    city: "Milligan",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32538",
    city: "Paxton",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32539",
    city: "Crestview",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32540",
    city: "Destin",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32541",
    city: "Destin",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32542",
    city: "Eglin AFB",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32544",
    city: "Hurlburt Field",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32547",
    city: "Fort Walton Beach",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32548",
    city: "Fort Walton Beach",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32549",
    city: "Fort Walton Beach",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32550",
    city: "Miramar Beach",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32559",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32560",
    city: "Gonzalez",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32561",
    city: "Gulf Breeze",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32562",
    city: "Gulf Breeze",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32563",
    city: "Gulf Breeze",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32564",
    city: "Holt",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32565",
    city: "Jay",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32566",
    city: "Navarre",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32567",
    city: "Laurel Hill",
    state: "FL",
    areacode: 850,
    county: "Walton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32568",
    city: "McDavid",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32569",
    city: "Mary Esther",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32570",
    city: "Milton",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32571",
    city: "Milton",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32572",
    city: "Milton",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32577",
    city: "Molino",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32578",
    city: "Niceville",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32579",
    city: "Shalimar",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32580",
    city: "Valparaiso",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32583",
    city: "Milton",
    state: "FL",
    areacode: 850,
    county: "Santa Rosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32588",
    city: "Niceville",
    state: "FL",
    areacode: 850,
    county: "Okaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32591",
    city: "Pensacola",
    state: "FL",
    areacode: 850,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "32601",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32602",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32603",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32604",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32605",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32606",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32607",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32608",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32609",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32610",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32611",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32612",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32614",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32615",
    city: "Alachua",
    state: "FL",
    areacode: 386,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32616",
    city: "Alachua",
    state: "FL",
    areacode: 386,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32617",
    city: "Anthony",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32618",
    city: "Archer",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32619",
    city: "Bell",
    state: "FL",
    areacode: 352,
    county: "Gilchrist",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32621",
    city: "Bronson",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32622",
    city: "Brooker",
    state: "FL",
    areacode: 352,
    county: "Bradford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32625",
    city: "Cedar Key",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32626",
    city: "Chiefland",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32627",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32628",
    city: "Cross City",
    state: "FL",
    areacode: 352,
    county: "Dixie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32631",
    city: "Earleton",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32633",
    city: "Evinston",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32634",
    city: "Fairfield",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32635",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32639",
    city: "Gulf Hammock",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32640",
    city: "Hawthorne",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32641",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32643",
    city: "High Springs",
    state: "FL",
    areacode: 386,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32644",
    city: "Chiefland",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32648",
    city: "Horseshoe Beach",
    state: "FL",
    areacode: 352,
    county: "Dixie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32653",
    city: "Gainesville",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32654",
    city: "Island Grove",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32655",
    city: "High Springs",
    state: "FL",
    areacode: 386,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32656",
    city: "Keystone Heights",
    state: "FL",
    areacode: 352,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32658",
    city: "La Crosse",
    state: "FL",
    areacode: 386,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32662",
    city: "Lochloosa",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32663",
    city: "Lowell",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32664",
    city: "McIntosh",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32666",
    city: "Melrose",
    state: "FL",
    areacode: 352,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32667",
    city: "Micanopy",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32668",
    city: "Morriston",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32669",
    city: "Newberry",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32680",
    city: "Old Town",
    state: "FL",
    areacode: 352,
    county: "Dixie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32681",
    city: "Orange Lake",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32683",
    city: "Otter Creek",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32686",
    city: "Reddick",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32692",
    city: "Suwannee",
    state: "FL",
    areacode: 352,
    county: "Dixie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32693",
    city: "Trenton",
    state: "FL",
    areacode: 352,
    county: "Gilchrist",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32694",
    city: "Waldo",
    state: "FL",
    areacode: 352,
    county: "Alachua",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32696",
    city: "Williston",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32697",
    city: "Worthington Springs",
    state: "FL",
    areacode: 386,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32701",
    city: "Altamonte Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32702",
    city: "Altoona",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32703",
    city: "Apopka",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32704",
    city: "Apopka",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32706",
    city: "Cassadaga",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32707",
    city: "Casselberry",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32708",
    city: "Winter Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32709",
    city: "Christmas",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32710",
    city: "Clarcona",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32712",
    city: "Apopka",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32713",
    city: "Debary",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32714",
    city: "Altamonte Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32715",
    city: "Altamonte Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32716",
    city: "Altamonte Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32718",
    city: "Casselberry",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32719",
    city: "Winter Springs",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32720",
    city: "Deland",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32721",
    city: "Deland",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32722",
    city: "Glenwood",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32723",
    city: "Deland",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32724",
    city: "Deland",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32725",
    city: "Deltona",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32726",
    city: "Eustis",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32727",
    city: "Eustis",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32728",
    city: "Deltona",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32730",
    city: "Casselberry",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32732",
    city: "Geneva",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32733",
    city: "Goldenrod",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32735",
    city: "Grand Island",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32736",
    city: "Eustis",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32738",
    city: "Deltona",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32739",
    city: "Deltona",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32744",
    city: "Lake Helen",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32745",
    city: "Mid Florida",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32746",
    city: "Lake Mary",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32747",
    city: "Lake Monroe",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32750",
    city: "Longwood",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32751",
    city: "Maitland",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32752",
    city: "Longwood",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32753",
    city: "Debary",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32754",
    city: "Mims",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32756",
    city: "Mount Dora",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32757",
    city: "Mount Dora",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32759",
    city: "Oak Hill",
    state: "FL",
    areacode: 386,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32762",
    city: "Oviedo",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32763",
    city: "Orange City",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32764",
    city: "Osteen",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32765",
    city: "Oviedo",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32766",
    city: "Oviedo",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32767",
    city: "Paisley",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32768",
    city: "Plymouth",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32771",
    city: "Sanford",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32772",
    city: "Sanford",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32773",
    city: "Sanford",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32774",
    city: "Orange City",
    state: "FL",
    areacode: 407,
    county: "Volusia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32775",
    city: "Scottsmoor",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32776",
    city: "Sorrento",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32777",
    city: "Tangerine",
    state: "FL",
    areacode: 352,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32778",
    city: "Tavares",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32779",
    city: "Longwood",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32780",
    city: "Titusville",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32781",
    city: "Titusville",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32783",
    city: "Titusville",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32784",
    city: "Umatilla",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32789",
    city: "Winter Park",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32790",
    city: "Winter Park",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32791",
    city: "Longwood",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32792",
    city: "Winter Park",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32793",
    city: "Winter Park",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32794",
    city: "Maitland",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32795",
    city: "Lake Mary",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32796",
    city: "Titusville",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32798",
    city: "Zellwood",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32799",
    city: "Mid Florida",
    state: "FL",
    areacode: 407,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32801",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32802",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32803",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32804",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32805",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32806",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32807",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32808",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32809",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32810",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32811",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32812",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32814",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32815",
    city: "Orlando",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32816",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32817",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32818",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32819",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32820",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32821",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32822",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32824",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32825",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32826",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32827",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32828",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32829",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32830",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32831",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32832",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32833",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32834",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32835",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32836",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32837",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32839",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32853",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32854",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32855",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32856",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32857",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32858",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32859",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32860",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32861",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32862",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32867",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32868",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32869",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32872",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32877",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32878",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32885",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32886",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32887",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32891",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32896",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32897",
    city: "Orlando",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32899",
    city: "Orlando",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32901",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32902",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32903",
    city: "Indialantic",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32904",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32905",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32906",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32907",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32908",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32909",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32910",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32911",
    city: "Palm Bay",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32912",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32919",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32920",
    city: "Cape Canaveral",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32922",
    city: "Cocoa",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32923",
    city: "Cocoa",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32924",
    city: "Cocoa",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32925",
    city: "Patrick AFB",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32926",
    city: "Cocoa",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32927",
    city: "Cocoa",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32931",
    city: "Cocoa Beach",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32932",
    city: "Cocoa Beach",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32934",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32935",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32936",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32937",
    city: "Satellite Beach",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32940",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32941",
    city: "Melbourne",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32948",
    city: "Fellsmere",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32949",
    city: "Grant",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32950",
    city: "Malabar",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32951",
    city: "Melbourne Beach",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32952",
    city: "Merritt Island",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32953",
    city: "Merritt Island",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32954",
    city: "Merritt Island",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32955",
    city: "Rockledge",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32956",
    city: "Rockledge",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32957",
    city: "Roseland",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32958",
    city: "Sebastian",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32959",
    city: "Sharpes",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32960",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32961",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32962",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32963",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32964",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32965",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32966",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32967",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32968",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32969",
    city: "Vero Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32970",
    city: "Wabasso",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32971",
    city: "Winter Beach",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32976",
    city: "Sebastian",
    state: "FL",
    areacode: 321,
    county: "Brevard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "32978",
    city: "Sebastian",
    state: "FL",
    areacode: 772,
    county: "Indian River",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33001",
    city: "Long Key",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33002",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33004",
    city: "Dania",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33008",
    city: "Hallandale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33009",
    city: "Hallandale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33010",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33011",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33012",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33013",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33014",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33015",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33016",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33017",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33018",
    city: "Hialeah",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33019",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33020",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33021",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33022",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33023",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33024",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33025",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33026",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33027",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33028",
    city: "Pembroke Pines",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33029",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33030",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33031",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33032",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33033",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33034",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33035",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33036",
    city: "Islamorada",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33037",
    city: "Key Largo",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33039",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33040",
    city: "Key West",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33041",
    city: "Key West",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33042",
    city: "Summerland Key",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33043",
    city: "Big Pine Key",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33045",
    city: "Key West",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33050",
    city: "Marathon",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33051",
    city: "Key Colony Beach",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33052",
    city: "Marathon Shores",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33054",
    city: "Opa Locka",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33055",
    city: "Opa Locka",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33056",
    city: "Miami Gardens",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33060",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33061",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33062",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33063",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33064",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33065",
    city: "Coral Springs",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33066",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33067",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33068",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33069",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33070",
    city: "Tavernier",
    state: "FL",
    areacode: 305,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33071",
    city: "Coral Springs",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33072",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33073",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33074",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33075",
    city: "Coral Springs",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33076",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33077",
    city: "Pompano Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33081",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33082",
    city: "Pembroke Pines",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33083",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33084",
    city: "Hollywood",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33090",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33092",
    city: "Homestead",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33093",
    city: "Margate",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33097",
    city: "Coconut Creek",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33101",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33102",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33106",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33109",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33111",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33112",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33114",
    city: "Coral Gables",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33116",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33119",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33122",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33124",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33125",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33126",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33127",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33128",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33129",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33130",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33131",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33132",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33133",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33134",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33135",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33136",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33137",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33138",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33139",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33140",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33141",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33142",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33143",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33144",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33145",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33146",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33147",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33149",
    city: "Key Biscayne",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33150",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33151",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33152",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33153",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33154",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33155",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33156",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33157",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33158",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33160",
    city: "North Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33161",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33162",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33163",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33164",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33165",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33166",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33167",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33168",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33169",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33170",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33172",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33173",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33174",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33175",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33176",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33177",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33178",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33179",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33180",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33181",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33182",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33183",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33184",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33185",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33186",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33187",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33188",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33189",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33190",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33191",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33192",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33193",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33194",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33195",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33196",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33197",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33198",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33199",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33206",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33222",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33231",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33233",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33234",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33238",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33239",
    city: "Miami Beach",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33242",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33243",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33245",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33247",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33255",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33256",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33257",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33261",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33265",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33266",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33269",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33280",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33283",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33296",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33299",
    city: "Miami",
    state: "FL",
    areacode: 305,
    county: "Miami-Dade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33301",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33302",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33303",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33304",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33305",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33306",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33307",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33308",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33309",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33310",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33311",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33312",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33313",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33314",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33315",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33316",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33317",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33318",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33319",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33320",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33321",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33322",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33323",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33324",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33325",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33326",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33327",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33328",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33329",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33330",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33331",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33332",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33334",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33335",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33336",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33337",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33338",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33339",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33340",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33345",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33346",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33348",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33349",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33351",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33355",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33359",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33388",
    city: "Plantation",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33394",
    city: "Fort Lauderdale",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33401",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33402",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33403",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33404",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33405",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33406",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33407",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33408",
    city: "North Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33409",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33410",
    city: "Palm Beach Gardens",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33411",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33412",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33413",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33414",
    city: "Wellington",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33415",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33416",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33417",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33418",
    city: "Palm Beach Gardens",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33419",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33420",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33421",
    city: "Royal Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33422",
    city: "West Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33424",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33425",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33426",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33427",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33428",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33429",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33430",
    city: "Belle Glade",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33431",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33432",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33433",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33434",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33435",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33436",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33437",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33438",
    city: "Canal Point",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33440",
    city: "Clewiston",
    state: "FL",
    areacode: 863,
    county: "Hendry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33441",
    city: "Deerfield Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33442",
    city: "Deerfield Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33443",
    city: "Deerfield Beach",
    state: "FL",
    areacode: 954,
    county: "Broward",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33444",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33445",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33446",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33448",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33449",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33454",
    city: "Greenacres",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33455",
    city: "Hobe Sound",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33458",
    city: "Jupiter",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33459",
    city: "Lake Harbor",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33460",
    city: "Lake Worth Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33461",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33462",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33463",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33464",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33465",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33466",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33467",
    city: "Lake Worth",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33468",
    city: "Jupiter",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33469",
    city: "Jupiter",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33470",
    city: "Loxahatchee",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33471",
    city: "Moore Haven",
    state: "FL",
    areacode: 863,
    county: "Glades",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33472",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33473",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33474",
    city: "Boynton Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33475",
    city: "Hobe Sound",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33476",
    city: "Pahokee",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33477",
    city: "Jupiter",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33478",
    city: "Jupiter",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33480",
    city: "Palm Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33481",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33482",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33483",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33484",
    city: "Delray Beach",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33486",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33487",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33488",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33493",
    city: "South Bay",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33496",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33497",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33498",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33499",
    city: "Boca Raton",
    state: "FL",
    areacode: 561,
    county: "Palm Beach",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33503",
    city: "Balm",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33508",
    city: "Brandon",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33509",
    city: "Brandon",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33510",
    city: "Brandon",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33511",
    city: "Brandon",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33513",
    city: "Bushnell",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33514",
    city: "Center Hill",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33521",
    city: "Coleman",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33523",
    city: "Dade City",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33524",
    city: "Crystal Springs",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33525",
    city: "Dade City",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33526",
    city: "Dade City",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33527",
    city: "Dover",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33530",
    city: "Durant",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33534",
    city: "Gibsonton",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33537",
    city: "Lacoochee",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33538",
    city: "Lake Panasoffkee",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33539",
    city: "Zephyrhills",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33540",
    city: "Zephyrhills",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33541",
    city: "Zephyrhills",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33542",
    city: "Zephyrhills",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33543",
    city: "Wesley Chapel",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33544",
    city: "Wesley Chapel",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33545",
    city: "Wesley Chapel",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33547",
    city: "Lithia",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33548",
    city: "Lutz",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33549",
    city: "Lutz",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33550",
    city: "Mango",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33556",
    city: "Odessa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33558",
    city: "Lutz",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33559",
    city: "Lutz",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33563",
    city: "Plant City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33564",
    city: "Plant City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33565",
    city: "Plant City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33566",
    city: "Plant City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33567",
    city: "Plant City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33568",
    city: "Riverview",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33569",
    city: "Riverview",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33570",
    city: "Ruskin",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33571",
    city: "Sun City Center",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33572",
    city: "Apollo Beach",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33573",
    city: "Sun City Center",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33574",
    city: "Saint Leo",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33575",
    city: "Ruskin",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33576",
    city: "San Antonio",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33578",
    city: "Riverview",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33579",
    city: "Riverview",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33583",
    city: "Seffner",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33584",
    city: "Seffner",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33585",
    city: "Sumterville",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33586",
    city: "Sun City",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33587",
    city: "Sydney",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33592",
    city: "Thonotosassa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33593",
    city: "Trilby",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33594",
    city: "Valrico",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33595",
    city: "Valrico",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33596",
    city: "Valrico",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33597",
    city: "Webster",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33598",
    city: "Wimauma",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33601",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33602",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33603",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33604",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33605",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33606",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33607",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33608",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33609",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33610",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33611",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33612",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33613",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33614",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33615",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33616",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33617",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33618",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33619",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33620",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33621",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33622",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33623",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33624",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33625",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33626",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33629",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33630",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33631",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33633",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33634",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33635",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33637",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33646",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33647",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33650",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33655",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33660",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33664",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33672",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33673",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33674",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33675",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33677",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33679",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33680",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33681",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33682",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33684",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33685",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33686",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33687",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33688",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33689",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33694",
    city: "Tampa",
    state: "FL",
    areacode: 813,
    county: "Hillsborough",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33701",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33702",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33703",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33704",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33705",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33706",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33707",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33708",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33709",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33710",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33711",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33712",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33713",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33714",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33715",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33716",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33729",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33730",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33731",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33732",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33733",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33734",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33736",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33738",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33740",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33741",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33742",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33743",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33744",
    city: "Bay Pines",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33747",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33755",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33756",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33757",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33758",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33759",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33760",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33761",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33762",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33763",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33764",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33765",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33766",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33767",
    city: "Clearwater Beach",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33769",
    city: "Clearwater",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33770",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33771",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33772",
    city: "Seminole",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33773",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33774",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33775",
    city: "Seminole",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33776",
    city: "Seminole",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33777",
    city: "Seminole",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33778",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33779",
    city: "Largo",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33780",
    city: "Pinellas Park",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33781",
    city: "Pinellas Park",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33782",
    city: "Pinellas Park",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33784",
    city: "Saint Petersburg",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33785",
    city: "Indian Rocks Beach",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33786",
    city: "Belleair Beach",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33801",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33802",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33803",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33804",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33805",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33806",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33807",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33809",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33810",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33811",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33812",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33813",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33815",
    city: "Lakeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33820",
    city: "Alturas",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33823",
    city: "Auburndale",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33825",
    city: "Avon Park",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33826",
    city: "Avon Park",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33827",
    city: "Babson Park",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33830",
    city: "Bartow",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33831",
    city: "Bartow",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33834",
    city: "Bowling Green",
    state: "FL",
    areacode: 863,
    county: "Hardee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33835",
    city: "Bradley",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33836",
    city: "Davenport",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33837",
    city: "Davenport",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33838",
    city: "Dundee",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33839",
    city: "Eagle Lake",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33840",
    city: "Eaton Park",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33841",
    city: "Fort Meade",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33843",
    city: "Frostproof",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33844",
    city: "Haines City",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33845",
    city: "Haines City",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33846",
    city: "Highland City",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33847",
    city: "Homeland",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33848",
    city: "Intercession City",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33849",
    city: "Kathleen",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33850",
    city: "Lake Alfred",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33851",
    city: "Lake Hamilton",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33852",
    city: "Lake Placid",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33853",
    city: "Lake Wales",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33854",
    city: "Lakeshore",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33855",
    city: "Indian Lake Estates",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33856",
    city: "Nalcrest",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33857",
    city: "Lorida",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33858",
    city: "Loughman",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33859",
    city: "Lake Wales",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33860",
    city: "Mulberry",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33862",
    city: "Lake Placid",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33863",
    city: "Nichols",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33865",
    city: "Ona",
    state: "FL",
    areacode: 863,
    county: "Hardee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33867",
    city: "River Ranch",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33868",
    city: "Polk City",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33870",
    city: "Sebring",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33871",
    city: "Sebring",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33872",
    city: "Sebring",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33873",
    city: "Wauchula",
    state: "FL",
    areacode: 863,
    county: "Hardee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33875",
    city: "Sebring",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33876",
    city: "Sebring",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33877",
    city: "Waverly",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33880",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33881",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33882",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33883",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33884",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33885",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33888",
    city: "Winter Haven",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33890",
    city: "Zolfo Springs",
    state: "FL",
    areacode: 863,
    county: "Hardee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33896",
    city: "Davenport",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33897",
    city: "Davenport",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33898",
    city: "Lake Wales",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33901",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33902",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33903",
    city: "North Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33904",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33905",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33906",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33907",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33908",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33909",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33910",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33911",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33912",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33913",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33914",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33915",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33916",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33917",
    city: "North Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33918",
    city: "North Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33919",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33920",
    city: "Alva",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33921",
    city: "Boca Grande",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33922",
    city: "Bokeelia",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33924",
    city: "Captiva",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33927",
    city: "El Jobean",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33928",
    city: "Estero",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33929",
    city: "Estero",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33930",
    city: "Felda",
    state: "FL",
    areacode: 863,
    county: "Hendry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33931",
    city: "Fort Myers Beach",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33932",
    city: "Fort Myers Beach",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33935",
    city: "Labelle",
    state: "FL",
    areacode: 863,
    county: "Hendry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33936",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33938",
    city: "Murdock",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33944",
    city: "Palmdale",
    state: "FL",
    areacode: 863,
    county: "Glades",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33945",
    city: "Pineland",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33946",
    city: "Placida",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33947",
    city: "Rotonda West",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33948",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33949",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33950",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33951",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33952",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33953",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33954",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33955",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33956",
    city: "Saint James City",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33957",
    city: "Sanibel",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33960",
    city: "Venus",
    state: "FL",
    areacode: 863,
    county: "Highlands",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33965",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33966",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33967",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33970",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33971",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33972",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33973",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33974",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33975",
    city: "Labelle",
    state: "FL",
    areacode: 863,
    county: "Hendry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33976",
    city: "Lehigh Acres",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33980",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33981",
    city: "Port Charlotte",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33982",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33983",
    city: "Punta Gorda",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33990",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33991",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33993",
    city: "Cape Coral",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "33994",
    city: "Fort Myers",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34001",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34002",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34004",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34007",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34008",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34009",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34010",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34011",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34020",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34021",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34022",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34023",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34024",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34025",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34030",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34031",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34032",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34033",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34034",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34035",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34036",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34037",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34038",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34039",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34041",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34042",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34044",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34050",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34051",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34052",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34053",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34054",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34055",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34058",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34060",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34066",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34067",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34068",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34069",
    city: "DPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34078",
    city: "APO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34080",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34081",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34082",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34083",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34084",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34085",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34086",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34087",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34088",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34089",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34090",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34091",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34092",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34093",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34094",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34095",
    city: "FPO",
    state: "AA",
    areacode: 0,
    county: "",
    timezone3: "EST+1",
    timezone: "America/Santo_Domingo",
  },
  {
    zipcode: "34101",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34102",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34103",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34104",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34105",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34106",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34107",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34108",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34109",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34110",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34112",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34113",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34114",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34116",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34117",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34119",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34120",
    city: "Naples",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34133",
    city: "Bonita Springs",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34134",
    city: "Bonita Springs",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34135",
    city: "Bonita Springs",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34136",
    city: "Bonita Springs",
    state: "FL",
    areacode: 239,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34137",
    city: "Copeland",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34138",
    city: "Chokoloskee",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34139",
    city: "Everglades City",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34140",
    city: "Goodland",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34141",
    city: "Ochopee",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34142",
    city: "Immokalee",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34143",
    city: "Immokalee",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34145",
    city: "Marco Island",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34146",
    city: "Marco Island",
    state: "FL",
    areacode: 239,
    county: "Collier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34201",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34202",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34203",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34204",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34205",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34206",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34207",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34208",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34209",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34210",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34211",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34212",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34215",
    city: "Cortez",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34216",
    city: "Anna Maria",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34217",
    city: "Bradenton Beach",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34218",
    city: "Holmes Beach",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34219",
    city: "Parrish",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34220",
    city: "Palmetto",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34221",
    city: "Palmetto",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34222",
    city: "Ellenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34223",
    city: "Englewood",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34224",
    city: "Englewood",
    state: "FL",
    areacode: 941,
    county: "Charlotte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34228",
    city: "Longboat Key",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34229",
    city: "Osprey",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34230",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34231",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34232",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34233",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34234",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34235",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34236",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34237",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34238",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34239",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34240",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34241",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34242",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34243",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34249",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34250",
    city: "Terra Ceia",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34251",
    city: "Myakka City",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34260",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34264",
    city: "Oneco",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34265",
    city: "Arcadia",
    state: "FL",
    areacode: 863,
    county: "DeSoto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34266",
    city: "Arcadia",
    state: "FL",
    areacode: 863,
    county: "DeSoto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34267",
    city: "Fort Ogden",
    state: "FL",
    areacode: 863,
    county: "DeSoto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34268",
    city: "Nocatee",
    state: "FL",
    areacode: 863,
    county: "DeSoto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34269",
    city: "Arcadia",
    state: "FL",
    areacode: 863,
    county: "DeSoto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34270",
    city: "Tallevast",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34272",
    city: "Laurel",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34274",
    city: "Nokomis",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34275",
    city: "Nokomis",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34276",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34277",
    city: "Sarasota",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34280",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34281",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34282",
    city: "Bradenton",
    state: "FL",
    areacode: 941,
    county: "Manatee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34284",
    city: "Venice",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34285",
    city: "Venice",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34286",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34287",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34288",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34289",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34290",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34291",
    city: "North Port",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34292",
    city: "Venice",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34293",
    city: "Venice",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34295",
    city: "Englewood",
    state: "FL",
    areacode: 941,
    county: "Sarasota",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34420",
    city: "Belleview",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34421",
    city: "Belleview",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34423",
    city: "Crystal River",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34428",
    city: "Crystal River",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34429",
    city: "Crystal River",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34430",
    city: "Dunnellon",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34431",
    city: "Dunnellon",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34432",
    city: "Dunnellon",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34433",
    city: "Dunnellon",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34434",
    city: "Dunnellon",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34436",
    city: "Floral City",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34441",
    city: "Hernando",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34442",
    city: "Hernando",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34445",
    city: "Holder",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34446",
    city: "Homosassa",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34447",
    city: "Homosassa Springs",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34448",
    city: "Homosassa",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34449",
    city: "Inglis",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34450",
    city: "Inverness",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34451",
    city: "Inverness",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34452",
    city: "Inverness",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34453",
    city: "Inverness",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34460",
    city: "Lecanto",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34461",
    city: "Lecanto",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34464",
    city: "Beverly Hills",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34465",
    city: "Beverly Hills",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34470",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34471",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34472",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34473",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34474",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34475",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34476",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34477",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34478",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34479",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34480",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34481",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34482",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34483",
    city: "Ocala",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34484",
    city: "Oxford",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34487",
    city: "Homosassa",
    state: "FL",
    areacode: 352,
    county: "Citrus",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34488",
    city: "Silver Springs",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34489",
    city: "Silver Springs",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34491",
    city: "Summerfield",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34492",
    city: "Summerfield",
    state: "FL",
    areacode: 352,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34498",
    city: "Yankeetown",
    state: "FL",
    areacode: 352,
    county: "Levy",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34601",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34602",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34603",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34604",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34605",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34606",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34607",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34608",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34609",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34610",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34611",
    city: "Spring Hill",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34613",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34614",
    city: "Brooksville",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34636",
    city: "Istachatta",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34637",
    city: "Land O' Lakes",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34638",
    city: "Land O' Lakes",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34639",
    city: "Land O' Lakes",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34652",
    city: "New Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34653",
    city: "New Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34654",
    city: "New Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34655",
    city: "New Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34656",
    city: "New Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34660",
    city: "Ozona",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34661",
    city: "Nobleton",
    state: "FL",
    areacode: 352,
    county: "Hernando",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34667",
    city: "Hudson",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34668",
    city: "Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34669",
    city: "Hudson",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34673",
    city: "Port Richey",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34674",
    city: "Hudson",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34677",
    city: "Oldsmar",
    state: "FL",
    areacode: 813,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34679",
    city: "Aripeka",
    state: "FL",
    areacode: 813,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34680",
    city: "Elfers",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34681",
    city: "Crystal Beach",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34682",
    city: "Palm Harbor",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34683",
    city: "Palm Harbor",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34684",
    city: "Palm Harbor",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34685",
    city: "Palm Harbor",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34688",
    city: "Tarpon Springs",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34689",
    city: "Tarpon Springs",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34690",
    city: "Holiday",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34691",
    city: "Holiday",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34692",
    city: "Holiday",
    state: "FL",
    areacode: 727,
    county: "Pasco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34695",
    city: "Safety Harbor",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34697",
    city: "Dunedin",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34698",
    city: "Dunedin",
    state: "FL",
    areacode: 727,
    county: "Pinellas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34705",
    city: "Astatula",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34711",
    city: "Clermont",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34712",
    city: "Clermont",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34713",
    city: "Clermont",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34714",
    city: "Clermont",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34715",
    city: "Clermont",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34729",
    city: "Ferndale",
    state: "FL",
    areacode: 407,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34731",
    city: "Fruitland Park",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34734",
    city: "Gotha",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34736",
    city: "Groveland",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34737",
    city: "Howey in the Hills",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34739",
    city: "Kenansville",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34740",
    city: "Killarney",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34741",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34742",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34743",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34744",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34745",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34746",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34747",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34748",
    city: "Leesburg",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34749",
    city: "Leesburg",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34753",
    city: "Mascotte",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34755",
    city: "Minneola",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34756",
    city: "Montverde",
    state: "FL",
    areacode: 407,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34758",
    city: "Kissimmee",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34759",
    city: "Kissimmee",
    state: "FL",
    areacode: 863,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34760",
    city: "Oakland",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34761",
    city: "Ocoee",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34762",
    city: "Okahumpka",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34769",
    city: "Saint Cloud",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34770",
    city: "Saint Cloud",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34771",
    city: "Saint Cloud",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34772",
    city: "Saint Cloud",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34773",
    city: "Saint Cloud",
    state: "FL",
    areacode: 407,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34777",
    city: "Winter Garden",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34778",
    city: "Winter Garden",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34785",
    city: "Wildwood",
    state: "FL",
    areacode: 352,
    county: "Sumter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34786",
    city: "Windermere",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34787",
    city: "Winter Garden",
    state: "FL",
    areacode: 407,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34788",
    city: "Leesburg",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34789",
    city: "Leesburg",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34797",
    city: "Yalaha",
    state: "FL",
    areacode: 352,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34945",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34946",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34947",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34948",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34949",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34950",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34951",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34952",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34953",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34954",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34956",
    city: "Indiantown",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34957",
    city: "Jensen Beach",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34958",
    city: "Jensen Beach",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34972",
    city: "Okeechobee",
    state: "FL",
    areacode: 863,
    county: "Okeechobee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34973",
    city: "Okeechobee",
    state: "FL",
    areacode: 863,
    county: "Okeechobee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34974",
    city: "Okeechobee",
    state: "FL",
    areacode: 863,
    county: "Okeechobee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34979",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34981",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34982",
    city: "Fort Pierce",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34983",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34984",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34985",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34986",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34987",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34988",
    city: "Port Saint Lucie",
    state: "FL",
    areacode: 772,
    county: "Saint Lucie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34990",
    city: "Palm City",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34991",
    city: "Palm City",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34992",
    city: "Port Salerno",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34994",
    city: "Stuart",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34995",
    city: "Stuart",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34996",
    city: "Stuart",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "34997",
    city: "Stuart",
    state: "FL",
    areacode: 772,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "35004",
    city: "Moody",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35005",
    city: "Adamsville",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35006",
    city: "Adger",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35007",
    city: "Alabaster",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35010",
    city: "Alexander City",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35011",
    city: "Alexander City",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35013",
    city: "Allgood",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35014",
    city: "Alpine",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35015",
    city: "Alton",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35016",
    city: "Arab",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35019",
    city: "Baileyton",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35020",
    city: "Bessemer",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35021",
    city: "Bessemer",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35022",
    city: "Bessemer",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35023",
    city: "Bessemer",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35031",
    city: "Blountsville",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35032",
    city: "Bon Air",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35033",
    city: "Bremen",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35034",
    city: "Brent",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35035",
    city: "Brierfield",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35036",
    city: "Brookside",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35038",
    city: "Burnwell",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35040",
    city: "Calera",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35042",
    city: "Centreville",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35043",
    city: "Chelsea",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35044",
    city: "Childersburg",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35045",
    city: "Clanton",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35046",
    city: "Clanton",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35048",
    city: "Clay",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35049",
    city: "Cleveland",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35051",
    city: "Columbiana",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35052",
    city: "Cook Springs",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35053",
    city: "Crane Hill",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35054",
    city: "Cropwell",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35055",
    city: "Cullman",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35056",
    city: "Cullman",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35057",
    city: "Cullman",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35058",
    city: "Cullman",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35060",
    city: "Docena",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35061",
    city: "Dolomite",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35062",
    city: "Dora",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35063",
    city: "Empire",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35064",
    city: "Fairfield",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35068",
    city: "Fultondale",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35070",
    city: "Garden City",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35071",
    city: "Gardendale",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35072",
    city: "Goodwater",
    state: "AL",
    areacode: 256,
    county: "Coosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35073",
    city: "Graysville",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35074",
    city: "Green Pond",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35077",
    city: "Hanceville",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35078",
    city: "Harpersville",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35079",
    city: "Hayden",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35080",
    city: "Helena",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35082",
    city: "Hollins",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35083",
    city: "Holly Pond",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35085",
    city: "Jemison",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35087",
    city: "Joppa",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35089",
    city: "Kellyton",
    state: "AL",
    areacode: 256,
    county: "Coosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35091",
    city: "Kimberly",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35094",
    city: "Leeds",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35096",
    city: "Lincoln",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35097",
    city: "Locust Fork",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35098",
    city: "Logan",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35111",
    city: "McCalla",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35112",
    city: "Margaret",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35114",
    city: "Alabaster",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35115",
    city: "Montevallo",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35116",
    city: "Morris",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35117",
    city: "Mount Olive",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35118",
    city: "Mulga",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35119",
    city: "New Castle",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35120",
    city: "Odenville",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35121",
    city: "Oneonta",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35123",
    city: "Palmerdale",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35124",
    city: "Pelham",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35125",
    city: "Pell City",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35126",
    city: "Pinson",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35127",
    city: "Pleasant Grove",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35128",
    city: "Pell City",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35130",
    city: "Quinton",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35131",
    city: "Ragland",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35133",
    city: "Remlap",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35135",
    city: "Riverside",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35136",
    city: "Rockford",
    state: "AL",
    areacode: 256,
    county: "Coosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35137",
    city: "Saginaw",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35139",
    city: "Sayre",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35142",
    city: "Shannon",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35143",
    city: "Shelby",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35144",
    city: "Siluria",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35146",
    city: "Springville",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35147",
    city: "Sterrett",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35148",
    city: "Sumiton",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35149",
    city: "Sycamore",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35150",
    city: "Sylacauga",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35151",
    city: "Sylacauga",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35160",
    city: "Talladega",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35161",
    city: "Talladega",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35171",
    city: "Thorsby",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35172",
    city: "Trafford",
    state: "AL",
    areacode: 205,
    county: "Blount",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35173",
    city: "Trussville",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35175",
    city: "Union Grove",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35176",
    city: "Vandiver",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35178",
    city: "Vincent",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35179",
    city: "Vinemont",
    state: "AL",
    areacode: 256,
    county: "Cullman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35180",
    city: "Warrior",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35181",
    city: "Watson",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35182",
    city: "Wattsville",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35183",
    city: "Weogufka",
    state: "AL",
    areacode: 256,
    county: "Coosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35184",
    city: "West Blocton",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35185",
    city: "Westover",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35186",
    city: "Wilsonville",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35187",
    city: "Wilton",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35188",
    city: "Woodstock",
    state: "AL",
    areacode: 205,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35201",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35202",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35203",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35204",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35205",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35206",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35207",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35208",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35209",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35210",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35211",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35212",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35213",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35214",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35215",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35216",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35217",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35218",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35219",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35220",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35221",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35222",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35223",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35224",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35226",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35228",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35229",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35231",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35232",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35233",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35234",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35235",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35236",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35237",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35238",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35242",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35243",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35244",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35246",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35249",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35253",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35254",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35255",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35259",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35260",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35261",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35266",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35270",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35282",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35283",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35285",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35287",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35288",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35290",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35291",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35292",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35293",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35294",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35295",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35296",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35297",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35298",
    city: "Birmingham",
    state: "AL",
    areacode: 205,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35401",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35402",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35403",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35404",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35405",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35406",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35407",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35440",
    city: "Abernant",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35441",
    city: "Akron",
    state: "AL",
    areacode: 205,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35442",
    city: "Aliceville",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35443",
    city: "Boligee",
    state: "AL",
    areacode: 205,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35444",
    city: "Brookwood",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35446",
    city: "Buhl",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35447",
    city: "Carrollton",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35448",
    city: "Clinton",
    state: "AL",
    areacode: 205,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35449",
    city: "Coaling",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35452",
    city: "Coker",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35453",
    city: "Cottondale",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35456",
    city: "Duncanville",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35457",
    city: "Echola",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35458",
    city: "Elrod",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35459",
    city: "Emelle",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35460",
    city: "Epes",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35461",
    city: "Ethelsville",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35462",
    city: "Eutaw",
    state: "AL",
    areacode: 205,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35463",
    city: "Fosters",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35464",
    city: "Gainesville",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35466",
    city: "Gordo",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35469",
    city: "Knoxville",
    state: "AL",
    areacode: 205,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35470",
    city: "Livingston",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35471",
    city: "McShan",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35473",
    city: "Northport",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35474",
    city: "Moundville",
    state: "AL",
    areacode: 205,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35475",
    city: "Northport",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35476",
    city: "Northport",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35477",
    city: "Panola",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35478",
    city: "Peterson",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35480",
    city: "Ralph",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35481",
    city: "Reform",
    state: "AL",
    areacode: 205,
    county: "Pickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35482",
    city: "Samantha",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35486",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35487",
    city: "Tuscaloosa",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35490",
    city: "Vance",
    state: "AL",
    areacode: 205,
    county: "Tuscaloosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35491",
    city: "West Greene",
    state: "AL",
    areacode: 205,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35501",
    city: "Jasper",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35502",
    city: "Jasper",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35503",
    city: "Jasper",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35504",
    city: "Jasper",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35540",
    city: "Addison",
    state: "AL",
    areacode: 256,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35541",
    city: "Arley",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35542",
    city: "Bankston",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35543",
    city: "Bear Creek",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35544",
    city: "Beaverton",
    state: "AL",
    areacode: 205,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35545",
    city: "Belk",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35546",
    city: "Berry",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35548",
    city: "Brilliant",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35549",
    city: "Carbon Hill",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35550",
    city: "Cordova",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35551",
    city: "Delmar",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35552",
    city: "Detroit",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35553",
    city: "Double Springs",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35554",
    city: "Eldridge",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35555",
    city: "Fayette",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35559",
    city: "Glen Allen",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35560",
    city: "Goodsprings",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35563",
    city: "Guin",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35564",
    city: "Hackleburg",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35565",
    city: "Haleyville",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35570",
    city: "Hamilton",
    state: "AL",
    areacode: 205,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35571",
    city: "Hodges",
    state: "AL",
    areacode: 205,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35572",
    city: "Houston",
    state: "AL",
    areacode: 256,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35573",
    city: "Kansas",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35574",
    city: "Kennedy",
    state: "AL",
    areacode: 205,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35575",
    city: "Lynn",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35576",
    city: "Millport",
    state: "AL",
    areacode: 205,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35577",
    city: "Natural Bridge",
    state: "AL",
    areacode: 205,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35578",
    city: "Nauvoo",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35579",
    city: "Oakman",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35580",
    city: "Parrish",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35581",
    city: "Phil Campbell",
    state: "AL",
    areacode: 205,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35582",
    city: "Red Bay",
    state: "AL",
    areacode: 256,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35584",
    city: "Sipsey",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35585",
    city: "Spruce Pine",
    state: "AL",
    areacode: 256,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35586",
    city: "Sulligent",
    state: "AL",
    areacode: 205,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35587",
    city: "Townley",
    state: "AL",
    areacode: 205,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35592",
    city: "Vernon",
    state: "AL",
    areacode: 205,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35593",
    city: "Vina",
    state: "AL",
    areacode: 256,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35594",
    city: "Winfield",
    state: "AL",
    areacode: 205,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35601",
    city: "Decatur",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35602",
    city: "Decatur",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35603",
    city: "Decatur",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35609",
    city: "Decatur",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35610",
    city: "Anderson",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35611",
    city: "Athens",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35612",
    city: "Athens",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35613",
    city: "Athens",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35614",
    city: "Athens",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35615",
    city: "Belle Mina",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35616",
    city: "Cherokee",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35617",
    city: "Cloverdale",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35618",
    city: "Courtland",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35619",
    city: "Danville",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35620",
    city: "Elkmont",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35621",
    city: "Eva",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35622",
    city: "Falkville",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35630",
    city: "Florence",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35631",
    city: "Florence",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35632",
    city: "Florence",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35633",
    city: "Florence",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35634",
    city: "Florence",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35640",
    city: "Hartselle",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35643",
    city: "Hillsboro",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35645",
    city: "Killen",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35646",
    city: "Leighton",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35647",
    city: "Lester",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35648",
    city: "Lexington",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35649",
    city: "Mooresville",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35650",
    city: "Moulton",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35651",
    city: "Mount Hope",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35652",
    city: "Rogersville",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35653",
    city: "Russellville",
    state: "AL",
    areacode: 256,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35654",
    city: "Russellville",
    state: "AL",
    areacode: 256,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35660",
    city: "Sheffield",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35661",
    city: "Muscle Shoals",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35662",
    city: "Muscle Shoals",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35670",
    city: "Somerville",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35671",
    city: "Tanner",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35672",
    city: "Town Creek",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35673",
    city: "Trinity",
    state: "AL",
    areacode: 256,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35674",
    city: "Tuscumbia",
    state: "AL",
    areacode: 256,
    county: "Colbert",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35677",
    city: "Waterloo",
    state: "AL",
    areacode: 256,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35699",
    city: "Decatur",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35739",
    city: "Ardmore",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35740",
    city: "Bridgeport",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35741",
    city: "Brownsboro",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35742",
    city: "Capshaw",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35744",
    city: "Dutton",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35745",
    city: "Estillfork",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35746",
    city: "Fackler",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35747",
    city: "Grant",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35748",
    city: "Gurley",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35749",
    city: "Harvest",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35750",
    city: "Hazel Green",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35751",
    city: "Hollytree",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35752",
    city: "Hollywood",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35754",
    city: "Laceys Spring",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35755",
    city: "Langston",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35756",
    city: "Madison",
    state: "AL",
    areacode: 256,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35757",
    city: "Madison",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35758",
    city: "Madison",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35759",
    city: "Meridianville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35760",
    city: "New Hope",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35761",
    city: "New Market",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35762",
    city: "Normal",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35763",
    city: "Owens Cross Roads",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35764",
    city: "Paint Rock",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35765",
    city: "Pisgah",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35766",
    city: "Princeton",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35767",
    city: "Ryland",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35768",
    city: "Scottsboro",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35769",
    city: "Scottsboro",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35771",
    city: "Section",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35772",
    city: "Stevenson",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35773",
    city: "Toney",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35774",
    city: "Trenton",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35775",
    city: "Valhermoso Springs",
    state: "AL",
    areacode: 256,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35776",
    city: "Woodville",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35801",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35802",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35803",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35804",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35805",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35806",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35807",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35808",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35809",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35810",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35811",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35812",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35813",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35814",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35815",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35816",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35824",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35893",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35894",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35895",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35896",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35897",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35898",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35899",
    city: "Huntsville",
    state: "AL",
    areacode: 256,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35901",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35902",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35903",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35904",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35905",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35906",
    city: "Rainbow City",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35907",
    city: "Gadsden",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35950",
    city: "Albertville",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35951",
    city: "Albertville",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35952",
    city: "Altoona",
    state: "AL",
    areacode: 205,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35953",
    city: "Ashville",
    state: "AL",
    areacode: 205,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35954",
    city: "Attalla",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35956",
    city: "Boaz",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35957",
    city: "Boaz",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35958",
    city: "Bryant",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35959",
    city: "Cedar Bluff",
    state: "AL",
    areacode: 256,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35960",
    city: "Centre",
    state: "AL",
    areacode: 256,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35961",
    city: "Collinsville",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35962",
    city: "Crossville",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35963",
    city: "Dawson",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35964",
    city: "Douglas",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35966",
    city: "Flat Rock",
    state: "AL",
    areacode: 256,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35967",
    city: "Fort Payne",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35968",
    city: "Fort Payne",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35971",
    city: "Fyffe",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35972",
    city: "Gallant",
    state: "AL",
    areacode: 256,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35973",
    city: "Gaylesville",
    state: "AL",
    areacode: 256,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35974",
    city: "Geraldine",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35975",
    city: "Groveoak",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35976",
    city: "Guntersville",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35978",
    city: "Henagar",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35979",
    city: "Higdon",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35980",
    city: "Horton",
    state: "AL",
    areacode: 256,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35981",
    city: "Ider",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35983",
    city: "Leesburg",
    state: "AL",
    areacode: 256,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35984",
    city: "Mentone",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35986",
    city: "Rainsville",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35987",
    city: "Steele",
    state: "AL",
    areacode: 256,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35988",
    city: "Sylvania",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35989",
    city: "Valley Head",
    state: "AL",
    areacode: 256,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "35990",
    city: "Walnut Grove",
    state: "AL",
    areacode: 205,
    county: "Etowah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36003",
    city: "Autaugaville",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36005",
    city: "Banks",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36006",
    city: "Billingsley",
    state: "AL",
    areacode: 205,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36008",
    city: "Booth",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36009",
    city: "Brantley",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36010",
    city: "Brundidge",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36013",
    city: "Cecil",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36015",
    city: "Chapman",
    state: "AL",
    areacode: 334,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36016",
    city: "Clayton",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36017",
    city: "Clio",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36020",
    city: "Coosada",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36022",
    city: "Deatsville",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36023",
    city: "East Tallassee",
    state: "AL",
    areacode: 334,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36024",
    city: "Eclectic",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36025",
    city: "Elmore",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36026",
    city: "Equality",
    state: "AL",
    areacode: 256,
    county: "Coosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36027",
    city: "Eufaula",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36028",
    city: "Dozier",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36029",
    city: "Fitzpatrick",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36030",
    city: "Forest Home",
    state: "AL",
    areacode: 334,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36031",
    city: "Fort Davis",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36032",
    city: "Fort Deposit",
    state: "AL",
    areacode: 334,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36033",
    city: "Georgiana",
    state: "AL",
    areacode: 334,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36034",
    city: "Glenwood",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36035",
    city: "Goshen",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36036",
    city: "Grady",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36037",
    city: "Greenville",
    state: "AL",
    areacode: 334,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36038",
    city: "Gantt",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36039",
    city: "Hardaway",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36040",
    city: "Hayneville",
    state: "AL",
    areacode: 334,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36041",
    city: "Highland Home",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36042",
    city: "Honoraville",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36043",
    city: "Hope Hull",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36045",
    city: "Kent",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36046",
    city: "Lapine",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36047",
    city: "Letohatchee",
    state: "AL",
    areacode: 334,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36048",
    city: "Louisville",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36049",
    city: "Luverne",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36051",
    city: "Marbury",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36052",
    city: "Mathews",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36053",
    city: "Midway",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36054",
    city: "Millbrook",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36057",
    city: "Mount Meigs",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36061",
    city: "Perote",
    state: "AL",
    areacode: 334,
    county: "Bullock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36062",
    city: "Petrey",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36064",
    city: "Pike Road",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36065",
    city: "Pine Level",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36066",
    city: "Prattville",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36067",
    city: "Prattville",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36068",
    city: "Prattville",
    state: "AL",
    areacode: 334,
    county: "Autauga",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36069",
    city: "Ramer",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36071",
    city: "Rutledge",
    state: "AL",
    areacode: 334,
    county: "Crenshaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36072",
    city: "Eufaula",
    state: "AL",
    areacode: 334,
    county: "Barbour",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36075",
    city: "Shorter",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36078",
    city: "Tallassee",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36079",
    city: "Troy",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36080",
    city: "Titus",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36081",
    city: "Troy",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36082",
    city: "Troy",
    state: "AL",
    areacode: 334,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36083",
    city: "Tuskegee",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36087",
    city: "Tuskegee Institute",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36088",
    city: "Tuskegee Institute",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36089",
    city: "Union Springs",
    state: "AL",
    areacode: 334,
    county: "Bullock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36091",
    city: "Verbena",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36092",
    city: "Wetumpka",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36093",
    city: "Wetumpka",
    state: "AL",
    areacode: 334,
    county: "Elmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36101",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36102",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36103",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36104",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36105",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36106",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36107",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36108",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36109",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36110",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36111",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36112",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36113",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36114",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36115",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36116",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36117",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36118",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36119",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36120",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36121",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36123",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36124",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36125",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36130",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36131",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36132",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36135",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36140",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36141",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36142",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36177",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36191",
    city: "Montgomery",
    state: "AL",
    areacode: 334,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36201",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36202",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36203",
    city: "Oxford",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36204",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36205",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36206",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36207",
    city: "Anniston",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36250",
    city: "Alexandria",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36251",
    city: "Ashland",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36253",
    city: "Bynum",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36254",
    city: "Choccolocco",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36255",
    city: "Cragford",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36256",
    city: "Daviston",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36257",
    city: "De Armanville",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36258",
    city: "Delta",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36260",
    city: "Eastaboga",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36261",
    city: "Edwardsville",
    state: "AL",
    areacode: 256,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36262",
    city: "Fruithurst",
    state: "AL",
    areacode: 256,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36263",
    city: "Graham",
    state: "AL",
    areacode: 256,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36264",
    city: "Heflin",
    state: "AL",
    areacode: 256,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36265",
    city: "Jacksonville",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36266",
    city: "Lineville",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36267",
    city: "Millerville",
    state: "AL",
    areacode: 256,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36268",
    city: "Munford",
    state: "AL",
    areacode: 256,
    county: "Talladega",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36269",
    city: "Muscadine",
    state: "AL",
    areacode: 256,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36271",
    city: "Ohatchee",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36272",
    city: "Piedmont",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36273",
    city: "Ranburne",
    state: "AL",
    areacode: 256,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36274",
    city: "Roanoke",
    state: "AL",
    areacode: 334,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36275",
    city: "Spring Garden",
    state: "AL",
    areacode: 256,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36276",
    city: "Wadley",
    state: "AL",
    areacode: 256,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36277",
    city: "Weaver",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36278",
    city: "Wedowee",
    state: "AL",
    areacode: 256,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36279",
    city: "Wellington",
    state: "AL",
    areacode: 256,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36280",
    city: "Woodland",
    state: "AL",
    areacode: 256,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36301",
    city: "Dothan",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36302",
    city: "Dothan",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36303",
    city: "Dothan",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36304",
    city: "Dothan",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36305",
    city: "Dothan",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36310",
    city: "Abbeville",
    state: "AL",
    areacode: 334,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36311",
    city: "Ariton",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36312",
    city: "Ashford",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36313",
    city: "Bellwood",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36314",
    city: "Black",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36316",
    city: "Chancellor",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36317",
    city: "Clopton",
    state: "AL",
    areacode: 334,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36318",
    city: "Coffee Springs",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36319",
    city: "Columbia",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36320",
    city: "Cottonwood",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36321",
    city: "Cowarts",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36322",
    city: "Daleville",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36323",
    city: "Elba",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36330",
    city: "Enterprise",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36331",
    city: "Enterprise",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36340",
    city: "Geneva",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36343",
    city: "Gordon",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36344",
    city: "Hartford",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36345",
    city: "Headland",
    state: "AL",
    areacode: 334,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36346",
    city: "Jack",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36349",
    city: "Malvern",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36350",
    city: "Midland City",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36351",
    city: "New Brockton",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36352",
    city: "Newton",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36353",
    city: "Newville",
    state: "AL",
    areacode: 334,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36360",
    city: "Ozark",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36361",
    city: "Ozark",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36362",
    city: "Fort Rucker",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36370",
    city: "Pansey",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36371",
    city: "Pinckard",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36373",
    city: "Shorterville",
    state: "AL",
    areacode: 334,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36374",
    city: "Skipperville",
    state: "AL",
    areacode: 334,
    county: "Dale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36375",
    city: "Slocomb",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36376",
    city: "Webb",
    state: "AL",
    areacode: 334,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36401",
    city: "Evergreen",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36420",
    city: "Andalusia",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36421",
    city: "Andalusia",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36425",
    city: "Beatrice",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36426",
    city: "Brewton",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36427",
    city: "Brewton",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36429",
    city: "Brooklyn",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36432",
    city: "Castleberry",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36435",
    city: "Coy",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36436",
    city: "Dickinson",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36439",
    city: "Excel",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36441",
    city: "Flomaton",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36442",
    city: "Florala",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36444",
    city: "Franklin",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36445",
    city: "Frisco City",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36446",
    city: "Fulton",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36449",
    city: "Uriah",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36451",
    city: "Grove Hill",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36453",
    city: "Kinston",
    state: "AL",
    areacode: 334,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36454",
    city: "Lenox",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36455",
    city: "Lockhart",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36456",
    city: "McKenzie",
    state: "AL",
    areacode: 334,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36457",
    city: "Megargel",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36458",
    city: "Mexia",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36460",
    city: "Monroeville",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36461",
    city: "Monroeville",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36467",
    city: "Opp",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36470",
    city: "Perdue Hill",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36471",
    city: "Peterman",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36473",
    city: "Range",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36474",
    city: "Red Level",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36475",
    city: "Repton",
    state: "AL",
    areacode: 251,
    county: "Conecuh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36476",
    city: "River Falls",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36477",
    city: "Samson",
    state: "AL",
    areacode: 334,
    county: "Geneva",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36480",
    city: "Uriah",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36481",
    city: "Vredenburgh",
    state: "AL",
    areacode: 251,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36482",
    city: "Whatley",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36483",
    city: "Wing",
    state: "AL",
    areacode: 334,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36502",
    city: "Atmore",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36503",
    city: "Atmore",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36504",
    city: "Atmore",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36505",
    city: "Axis",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36507",
    city: "Bay Minette",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36509",
    city: "Bayou La Batre",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36511",
    city: "Bon Secour",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36512",
    city: "Bucks",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36513",
    city: "Calvert",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36518",
    city: "Chatom",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36521",
    city: "Chunchula",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36522",
    city: "Citronelle",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36523",
    city: "Coden",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36524",
    city: "Coffeeville",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36525",
    city: "Creola",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36526",
    city: "Daphne",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36527",
    city: "Daphne",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36528",
    city: "Dauphin Island",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36529",
    city: "Deer Park",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36530",
    city: "Elberta",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36532",
    city: "Fairhope",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36533",
    city: "Fairhope",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36535",
    city: "Foley",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36536",
    city: "Foley",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36538",
    city: "Frankville",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36539",
    city: "Fruitdale",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36540",
    city: "Gainestown",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36541",
    city: "Grand Bay",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36542",
    city: "Gulf Shores",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36543",
    city: "Huxford",
    state: "AL",
    areacode: 251,
    county: "Escambia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36544",
    city: "Irvington",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36545",
    city: "Jackson",
    state: "AL",
    areacode: 251,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36547",
    city: "Gulf Shores",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36548",
    city: "Leroy",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36549",
    city: "Lillian",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36550",
    city: "Little River",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36551",
    city: "Loxley",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36553",
    city: "McIntosh",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36555",
    city: "Magnolia Springs",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36556",
    city: "Malcolm",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36558",
    city: "Millry",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36559",
    city: "Montrose",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36560",
    city: "Mount Vernon",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36561",
    city: "Orange Beach",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36562",
    city: "Perdido",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36564",
    city: "Point Clear",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36567",
    city: "Robertsdale",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36568",
    city: "Saint Elmo",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36569",
    city: "Saint Stephens",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36571",
    city: "Saraland",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36572",
    city: "Satsuma",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36574",
    city: "Seminole",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36575",
    city: "Semmes",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36576",
    city: "Silverhill",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36577",
    city: "Spanish Fort",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36578",
    city: "Stapleton",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36579",
    city: "Stockton",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36580",
    city: "Summerdale",
    state: "AL",
    areacode: 251,
    county: "Baldwin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36581",
    city: "Sunflower",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36582",
    city: "Theodore",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36583",
    city: "Tibbie",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36584",
    city: "Vinegar Bend",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36585",
    city: "Wagarville",
    state: "AL",
    areacode: 251,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36587",
    city: "Wilmer",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36590",
    city: "Theodore",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36601",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36602",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36603",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36604",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36605",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36606",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36607",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36608",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36609",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36610",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36611",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36612",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36613",
    city: "Eight Mile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36615",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36616",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36617",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36618",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36619",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36628",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36633",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36640",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36641",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36644",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36652",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36660",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36663",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36670",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36671",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36675",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36685",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36688",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36689",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36691",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36693",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36695",
    city: "Mobile",
    state: "AL",
    areacode: 251,
    county: "Mobile",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36701",
    city: "Selma",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36702",
    city: "Selma",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36703",
    city: "Selma",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36720",
    city: "Alberta",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36722",
    city: "Arlington",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36723",
    city: "Boykin",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36726",
    city: "Camden",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36727",
    city: "Campbell",
    state: "AL",
    areacode: 334,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36728",
    city: "Catherine",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36732",
    city: "Demopolis",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36736",
    city: "Dixons Mills",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36738",
    city: "Faunsdale",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36740",
    city: "Forkland",
    state: "AL",
    areacode: 334,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36741",
    city: "Furman",
    state: "AL",
    areacode: 251,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36742",
    city: "Gallion",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36744",
    city: "Greensboro",
    state: "AL",
    areacode: 334,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36745",
    city: "Jefferson",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36748",
    city: "Linden",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36749",
    city: "Jones",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36750",
    city: "Maplesville",
    state: "AL",
    areacode: 205,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36751",
    city: "Lower Peach Tree",
    state: "AL",
    areacode: 334,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36752",
    city: "Lowndesboro",
    state: "AL",
    areacode: 334,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36753",
    city: "McWilliams",
    state: "AL",
    areacode: 251,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36754",
    city: "Magnolia",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36756",
    city: "Marion",
    state: "AL",
    areacode: 334,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36758",
    city: "Plantersville",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36759",
    city: "Marion Junction",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36761",
    city: "Minter",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36763",
    city: "Myrtlewood",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36764",
    city: "Nanafalia",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36765",
    city: "Newbern",
    state: "AL",
    areacode: 334,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36766",
    city: "Oak Hill",
    state: "AL",
    areacode: 251,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36767",
    city: "Orrville",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36768",
    city: "Pine Apple",
    state: "AL",
    areacode: 251,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36769",
    city: "Pine Hill",
    state: "AL",
    areacode: 334,
    county: "Wilcox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36773",
    city: "Safford",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36775",
    city: "Sardis",
    state: "AL",
    areacode: 334,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36776",
    city: "Sawyerville",
    state: "AL",
    areacode: 334,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36782",
    city: "Sweet Water",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36783",
    city: "Thomaston",
    state: "AL",
    areacode: 334,
    county: "Marengo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36784",
    city: "Thomasville",
    state: "AL",
    areacode: 334,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36785",
    city: "Tyler",
    state: "AL",
    areacode: 334,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36786",
    city: "Uniontown",
    state: "AL",
    areacode: 334,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36790",
    city: "Stanton",
    state: "AL",
    areacode: 334,
    county: "Chilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36792",
    city: "Randolph",
    state: "AL",
    areacode: 334,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36793",
    city: "Lawley",
    state: "AL",
    areacode: 334,
    county: "Bibb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36801",
    city: "Opelika",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36802",
    city: "Opelika",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36803",
    city: "Opelika",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36804",
    city: "Opelika",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36830",
    city: "Auburn",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36831",
    city: "Auburn",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36832",
    city: "Auburn",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36849",
    city: "Auburn University",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36850",
    city: "Camp Hill",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36851",
    city: "Cottonton",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36852",
    city: "Cusseta",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36853",
    city: "Dadeville",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36854",
    city: "Valley",
    state: "AL",
    areacode: 334,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36855",
    city: "Five Points",
    state: "AL",
    areacode: 334,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36856",
    city: "Fort Mitchell",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36858",
    city: "Hatchechubbee",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36859",
    city: "Holy Trinity",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36860",
    city: "Hurtsboro",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36861",
    city: "Jacksons Gap",
    state: "AL",
    areacode: 256,
    county: "Tallapoosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36862",
    city: "Lafayette",
    state: "AL",
    areacode: 334,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36863",
    city: "Lanett",
    state: "AL",
    areacode: 334,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36865",
    city: "Loachapoka",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36866",
    city: "Notasulga",
    state: "AL",
    areacode: 334,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36867",
    city: "Phenix City",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36868",
    city: "Phenix City",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36869",
    city: "Phenix City",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36870",
    city: "Phenix City",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36871",
    city: "Pittsview",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36872",
    city: "Valley",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36874",
    city: "Salem",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36875",
    city: "Seale",
    state: "AL",
    areacode: 334,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36877",
    city: "Smiths Station",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36879",
    city: "Waverly",
    state: "AL",
    areacode: 334,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36901",
    city: "Bellamy",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36904",
    city: "Butler",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36907",
    city: "Cuba",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36908",
    city: "Gilbertown",
    state: "AL",
    areacode: 251,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36910",
    city: "Jachin",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36912",
    city: "Lisman",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36913",
    city: "Melvin",
    state: "AL",
    areacode: 251,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36915",
    city: "Needham",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36916",
    city: "Pennington",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36919",
    city: "Silas",
    state: "AL",
    areacode: 251,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36921",
    city: "Toxey",
    state: "AL",
    areacode: 251,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36922",
    city: "Ward",
    state: "AL",
    areacode: 205,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "36925",
    city: "York",
    state: "AL",
    areacode: 205,
    county: "Sumter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37010",
    city: "Adams",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37011",
    city: "Antioch",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37012",
    city: "Alexandria",
    state: "TN",
    areacode: 615,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37013",
    city: "Antioch",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37014",
    city: "Arrington",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37015",
    city: "Ashland City",
    state: "TN",
    areacode: 615,
    county: "Cheatham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37016",
    city: "Auburntown",
    state: "TN",
    areacode: 615,
    county: "Cannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37018",
    city: "Beechgrove",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37019",
    city: "Belfast",
    state: "TN",
    areacode: 931,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37020",
    city: "Bell Buckle",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37022",
    city: "Bethpage",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37023",
    city: "Big Rock",
    state: "TN",
    areacode: 931,
    county: "Stewart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37024",
    city: "Brentwood",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37025",
    city: "Bon Aqua",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37026",
    city: "Bradyville",
    state: "TN",
    areacode: 615,
    county: "Cannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37027",
    city: "Brentwood",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37028",
    city: "Bumpus Mills",
    state: "TN",
    areacode: 931,
    county: "Stewart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37029",
    city: "Burns",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37030",
    city: "Carthage",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37031",
    city: "Castalian Springs",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37032",
    city: "Cedar Hill",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37033",
    city: "Centerville",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37034",
    city: "Chapel Hill",
    state: "TN",
    areacode: 931,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37035",
    city: "Chapmansboro",
    state: "TN",
    areacode: 615,
    county: "Cheatham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37036",
    city: "Charlotte",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37037",
    city: "Christiana",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37040",
    city: "Clarksville",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37041",
    city: "Clarksville",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37042",
    city: "Clarksville",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37043",
    city: "Clarksville",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37044",
    city: "Clarksville",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37046",
    city: "College Grove",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37047",
    city: "Cornersville",
    state: "TN",
    areacode: 931,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37048",
    city: "Cottontown",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37049",
    city: "Cross Plains",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37050",
    city: "Cumberland City",
    state: "TN",
    areacode: 931,
    county: "Stewart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37051",
    city: "Cumberland Furnace",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37052",
    city: "Cunningham",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37055",
    city: "Dickson",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37056",
    city: "Dickson",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37057",
    city: "Dixon Springs",
    state: "TN",
    areacode: 615,
    county: "Trousdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37058",
    city: "Dover",
    state: "TN",
    areacode: 931,
    county: "Stewart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37059",
    city: "Dowelltown",
    state: "TN",
    areacode: 615,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37060",
    city: "Eagleville",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37061",
    city: "Erin",
    state: "TN",
    areacode: 931,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37062",
    city: "Fairview",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37063",
    city: "Fosterville",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37064",
    city: "Franklin",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37065",
    city: "Franklin",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37066",
    city: "Gallatin",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37067",
    city: "Franklin",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37068",
    city: "Franklin",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37069",
    city: "Franklin",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37070",
    city: "Goodlettsville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37071",
    city: "Gladeville",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37072",
    city: "Goodlettsville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37073",
    city: "Greenbrier",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37074",
    city: "Hartsville",
    state: "TN",
    areacode: 615,
    county: "Trousdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37075",
    city: "Hendersonville",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37076",
    city: "Hermitage",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37077",
    city: "Hendersonville",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37078",
    city: "Hurricane Mills",
    state: "TN",
    areacode: 931,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37079",
    city: "Indian Mound",
    state: "TN",
    areacode: 931,
    county: "Stewart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37080",
    city: "Joelton",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37082",
    city: "Kingston Springs",
    state: "TN",
    areacode: 615,
    county: "Cheatham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37083",
    city: "Lafayette",
    state: "TN",
    areacode: 615,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37085",
    city: "Lascassas",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37086",
    city: "La Vergne",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37087",
    city: "Lebanon",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37088",
    city: "Lebanon",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37089",
    city: "La Vergne",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37090",
    city: "Lebanon",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37091",
    city: "Lewisburg",
    state: "TN",
    areacode: 931,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37095",
    city: "Liberty",
    state: "TN",
    areacode: 615,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37096",
    city: "Linden",
    state: "TN",
    areacode: 931,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37097",
    city: "Lobelville",
    state: "TN",
    areacode: 931,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37098",
    city: "Lyles",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37101",
    city: "McEwen",
    state: "TN",
    areacode: 931,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37110",
    city: "McMinnville",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37111",
    city: "McMinnville",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37115",
    city: "Madison",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37116",
    city: "Madison",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37118",
    city: "Milton",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37119",
    city: "Mitchellville",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37121",
    city: "Mount Juliet",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37122",
    city: "Mount Juliet",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37127",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37128",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37129",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37130",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37131",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37132",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37133",
    city: "Murfreesboro",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37134",
    city: "New Johnsonville",
    state: "TN",
    areacode: 931,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37135",
    city: "Nolensville",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37136",
    city: "Norene",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37137",
    city: "Nunnelly",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37138",
    city: "Old Hickory",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37140",
    city: "Only",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37141",
    city: "Orlinda",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37142",
    city: "Palmyra",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37143",
    city: "Pegram",
    state: "TN",
    areacode: 615,
    county: "Cheatham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37144",
    city: "Petersburg",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37145",
    city: "Pleasant Shade",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37146",
    city: "Pleasant View",
    state: "TN",
    areacode: 615,
    county: "Cheatham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37148",
    city: "Portland",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37149",
    city: "Readyville",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37150",
    city: "Red Boiling Springs",
    state: "TN",
    areacode: 615,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37151",
    city: "Riddleton",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37152",
    city: "Ridgetop",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37153",
    city: "Rockvale",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37160",
    city: "Shelbyville",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37161",
    city: "Shelbyville",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37162",
    city: "Shelbyville",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37165",
    city: "Slayden",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37166",
    city: "Smithville",
    state: "TN",
    areacode: 615,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37167",
    city: "Smyrna",
    state: "TN",
    areacode: 615,
    county: "Rutherford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37171",
    city: "Southside",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37172",
    city: "Springfield",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37174",
    city: "Spring Hill",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37175",
    city: "Stewart",
    state: "TN",
    areacode: 931,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37178",
    city: "Tennessee Ridge",
    state: "TN",
    areacode: 931,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37179",
    city: "Thompsons Station",
    state: "TN",
    areacode: 615,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37180",
    city: "Unionville",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37181",
    city: "Vanleer",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37183",
    city: "Wartrace",
    state: "TN",
    areacode: 931,
    county: "Bedford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37184",
    city: "Watertown",
    state: "TN",
    areacode: 615,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37185",
    city: "Waverly",
    state: "TN",
    areacode: 931,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37186",
    city: "Westmoreland",
    state: "TN",
    areacode: 615,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37187",
    city: "White Bluff",
    state: "TN",
    areacode: 615,
    county: "Dickson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37188",
    city: "White House",
    state: "TN",
    areacode: 615,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37189",
    city: "Whites Creek",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37190",
    city: "Woodbury",
    state: "TN",
    areacode: 615,
    county: "Cannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37191",
    city: "Woodlawn",
    state: "TN",
    areacode: 931,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37201",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37202",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37203",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37204",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37205",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37206",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37207",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37208",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37209",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37210",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37211",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37212",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37213",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37214",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37215",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37216",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37217",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37218",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37219",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37220",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37221",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37222",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37224",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37227",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37228",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37229",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37230",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37232",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37234",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37235",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37236",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37238",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37240",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37241",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37242",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37243",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37244",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37246",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37250",
    city: "Nashville",
    state: "TN",
    areacode: 615,
    county: "Davidson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37301",
    city: "Altamont",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37302",
    city: "Apison",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37303",
    city: "Athens",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37304",
    city: "Bakewell",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37305",
    city: "Beersheba Springs",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37306",
    city: "Belvidere",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37307",
    city: "Benton",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37308",
    city: "Birchwood",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37309",
    city: "Calhoun",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37310",
    city: "Charleston",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37311",
    city: "Cleveland",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37312",
    city: "Cleveland",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37313",
    city: "Coalmont",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37314",
    city: "Coker Creek",
    state: "TN",
    areacode: 423,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37315",
    city: "Collegedale",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37316",
    city: "Conasauga",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37317",
    city: "Copperhill",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37318",
    city: "Cowan",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37320",
    city: "Cleveland",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37321",
    city: "Dayton",
    state: "TN",
    areacode: 423,
    county: "Rhea",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37322",
    city: "Decatur",
    state: "TN",
    areacode: 423,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37323",
    city: "Cleveland",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37324",
    city: "Decherd",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37325",
    city: "Delano",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37326",
    city: "Ducktown",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37327",
    city: "Dunlap",
    state: "TN",
    areacode: 423,
    county: "Sequatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37328",
    city: "Elora",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37329",
    city: "Englewood",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37330",
    city: "Estill Springs",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37331",
    city: "Etowah",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37332",
    city: "Evensville",
    state: "TN",
    areacode: 423,
    county: "Rhea",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37333",
    city: "Farner",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37334",
    city: "Fayetteville",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37335",
    city: "Flintville",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37336",
    city: "Georgetown",
    state: "TN",
    areacode: 423,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37337",
    city: "Grandview",
    state: "TN",
    areacode: 423,
    county: "Cumberland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37338",
    city: "Graysville",
    state: "TN",
    areacode: 423,
    county: "Rhea",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37339",
    city: "Gruetli Laager",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37340",
    city: "Guild",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37341",
    city: "Harrison",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37342",
    city: "Hillsboro",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37343",
    city: "Hixson",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37345",
    city: "Huntland",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37347",
    city: "Jasper",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37348",
    city: "Kelso",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37349",
    city: "Manchester",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37350",
    city: "Lookout Mountain",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37351",
    city: "Lupton City",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37352",
    city: "Lynchburg",
    state: "TN",
    areacode: 931,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37353",
    city: "McDonald",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37354",
    city: "Madisonville",
    state: "TN",
    areacode: 423,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37355",
    city: "Manchester",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37356",
    city: "Monteagle",
    state: "TN",
    areacode: 931,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37357",
    city: "Morrison",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37359",
    city: "Mulberry",
    state: "TN",
    areacode: 931,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37360",
    city: "Normandy",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37361",
    city: "Ocoee",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37362",
    city: "Old Fort",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37363",
    city: "Ooltewah",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37364",
    city: "Cleveland",
    state: "TN",
    areacode: 423,
    county: "Bradley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37365",
    city: "Palmer",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37366",
    city: "Pelham",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37367",
    city: "Pikeville",
    state: "TN",
    areacode: 423,
    county: "Bledsoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37369",
    city: "Reliance",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37370",
    city: "Riceville",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37371",
    city: "Athens",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37373",
    city: "Sale Creek",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37374",
    city: "Sequatchie",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37375",
    city: "Sewanee",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37376",
    city: "Sherwood",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37377",
    city: "Signal Mountain",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37378",
    city: "Smartt",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37379",
    city: "Soddy Daisy",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37380",
    city: "South Pittsburg",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37381",
    city: "Spring City",
    state: "TN",
    areacode: 423,
    county: "Rhea",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37382",
    city: "Summitville",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37383",
    city: "Sewanee",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37384",
    city: "Soddy Daisy",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37385",
    city: "Tellico Plains",
    state: "TN",
    areacode: 423,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37387",
    city: "Tracy City",
    state: "TN",
    areacode: 931,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37388",
    city: "Tullahoma",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37389",
    city: "Arnold AFB",
    state: "TN",
    areacode: 931,
    county: "Coffee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37391",
    city: "Turtletown",
    state: "TN",
    areacode: 423,
    county: "Polk",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37394",
    city: "Viola",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37396",
    city: "Whiteside",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37397",
    city: "Whitwell",
    state: "TN",
    areacode: 423,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37398",
    city: "Winchester",
    state: "TN",
    areacode: 931,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37401",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37402",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37403",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37404",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37405",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37406",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37407",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37408",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37409",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37410",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37411",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37412",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37414",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37415",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37416",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37419",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37421",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37422",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37424",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37450",
    city: "Chattanooga",
    state: "TN",
    areacode: 423,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37501",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37544",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37601",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37602",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37604",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37605",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37614",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37615",
    city: "Johnson City",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37616",
    city: "Afton",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37617",
    city: "Blountville",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37618",
    city: "Bluff City",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37620",
    city: "Bristol",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37621",
    city: "Bristol",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37625",
    city: "Bristol",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37640",
    city: "Butler",
    state: "TN",
    areacode: 423,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37641",
    city: "Chuckey",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37642",
    city: "Church Hill",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37643",
    city: "Elizabethton",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37644",
    city: "Elizabethton",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37645",
    city: "Mount Carmel",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37650",
    city: "Erwin",
    state: "TN",
    areacode: 423,
    county: "Unicoi",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37656",
    city: "Fall Branch",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37657",
    city: "Flag Pond",
    state: "TN",
    areacode: 423,
    county: "Unicoi",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37658",
    city: "Hampton",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37659",
    city: "Jonesborough",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37660",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37662",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37663",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37664",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37665",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37669",
    city: "Kingsport",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37680",
    city: "Laurel Bloomery",
    state: "TN",
    areacode: 423,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37681",
    city: "Limestone",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37682",
    city: "Milligan College",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37683",
    city: "Mountain City",
    state: "TN",
    areacode: 423,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37684",
    city: "Mountain Home",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37686",
    city: "Piney Flats",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37687",
    city: "Roan Mountain",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37688",
    city: "Shady Valley",
    state: "TN",
    areacode: 423,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37690",
    city: "Telford",
    state: "TN",
    areacode: 423,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37691",
    city: "Trade",
    state: "TN",
    areacode: 423,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37692",
    city: "Unicoi",
    state: "TN",
    areacode: 423,
    county: "Unicoi",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37694",
    city: "Watauga",
    state: "TN",
    areacode: 423,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37699",
    city: "Piney Flats",
    state: "TN",
    areacode: 423,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37701",
    city: "Alcoa",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37705",
    city: "Andersonville",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37707",
    city: "Arthur",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37708",
    city: "Bean Station",
    state: "TN",
    areacode: 865,
    county: "Grainger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37709",
    city: "Blaine",
    state: "TN",
    areacode: 865,
    county: "Grainger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37710",
    city: "Briceville",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37711",
    city: "Bulls Gap",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37713",
    city: "Bybee",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37714",
    city: "Caryville",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37715",
    city: "Clairfield",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37716",
    city: "Clinton",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37717",
    city: "Clinton",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37719",
    city: "Coalfield",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37721",
    city: "Corryton",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37722",
    city: "Cosby",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37723",
    city: "Crab Orchard",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "37724",
    city: "Cumberland Gap",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37725",
    city: "Dandridge",
    state: "TN",
    areacode: 865,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37726",
    city: "Deer Lodge",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37727",
    city: "Del Rio",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37729",
    city: "Duff",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37730",
    city: "Eagan",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37731",
    city: "Eidson",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37732",
    city: "Elgin",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37733",
    city: "Rugby",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37737",
    city: "Friendsville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37738",
    city: "Gatlinburg",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37742",
    city: "Greenback",
    state: "TN",
    areacode: 865,
    county: "Loudon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37743",
    city: "Greeneville",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37744",
    city: "Greeneville",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37745",
    city: "Greeneville",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37748",
    city: "Harriman",
    state: "TN",
    areacode: 865,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37752",
    city: "Harrogate",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37753",
    city: "Hartford",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37754",
    city: "Heiskell",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37755",
    city: "Helenwood",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37756",
    city: "Huntsville",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37757",
    city: "Jacksboro",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37760",
    city: "Jefferson City",
    state: "TN",
    areacode: 865,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37762",
    city: "Jellico",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37763",
    city: "Kingston",
    state: "TN",
    areacode: 865,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37764",
    city: "Kodak",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37765",
    city: "Kyles Ford",
    state: "TN",
    areacode: 423,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37766",
    city: "La Follette",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37769",
    city: "Rocky Top",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37770",
    city: "Lancing",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37771",
    city: "Lenoir City",
    state: "TN",
    areacode: 865,
    county: "Loudon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37772",
    city: "Lenoir City",
    state: "TN",
    areacode: 865,
    county: "Loudon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37773",
    city: "Lone Mountain",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37774",
    city: "Loudon",
    state: "TN",
    areacode: 865,
    county: "Loudon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37777",
    city: "Louisville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37778",
    city: "Lowland",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37779",
    city: "Luttrell",
    state: "TN",
    areacode: 865,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37801",
    city: "Maryville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37802",
    city: "Maryville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37803",
    city: "Maryville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37804",
    city: "Maryville",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37806",
    city: "Mascot",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37807",
    city: "Maynardville",
    state: "TN",
    areacode: 865,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37809",
    city: "Midway",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37810",
    city: "Mohawk",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37811",
    city: "Mooresburg",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37813",
    city: "Morristown",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37814",
    city: "Morristown",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37815",
    city: "Morristown",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37816",
    city: "Morristown",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37818",
    city: "Mosheim",
    state: "TN",
    areacode: 423,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37819",
    city: "Newcomb",
    state: "TN",
    areacode: 423,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37820",
    city: "New Market",
    state: "TN",
    areacode: 865,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37821",
    city: "Newport",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37822",
    city: "Newport",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37824",
    city: "New Tazewell",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37825",
    city: "New Tazewell",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37826",
    city: "Niota",
    state: "TN",
    areacode: 423,
    county: "McMinn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37828",
    city: "Norris",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37829",
    city: "Oakdale",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37830",
    city: "Oak Ridge",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37831",
    city: "Oak Ridge",
    state: "TN",
    areacode: 865,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37840",
    city: "Oliver Springs",
    state: "TN",
    areacode: 423,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37841",
    city: "Oneida",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37843",
    city: "Parrottsville",
    state: "TN",
    areacode: 423,
    county: "Cocke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37845",
    city: "Petros",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37846",
    city: "Philadelphia",
    state: "TN",
    areacode: 865,
    county: "Loudon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37847",
    city: "Pioneer",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37848",
    city: "Powder Springs",
    state: "TN",
    areacode: 865,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37849",
    city: "Powell",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37851",
    city: "Pruden",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37852",
    city: "Robbins",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37853",
    city: "Rockford",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37854",
    city: "Rockwood",
    state: "TN",
    areacode: 865,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37857",
    city: "Rogersville",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37860",
    city: "Russellville",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37861",
    city: "Rutledge",
    state: "TN",
    areacode: 865,
    county: "Grainger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37862",
    city: "Sevierville",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37863",
    city: "Pigeon Forge",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37864",
    city: "Sevierville",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37865",
    city: "Seymour",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37866",
    city: "Sharps Chapel",
    state: "TN",
    areacode: 865,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37867",
    city: "Shawanee",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37868",
    city: "Pigeon Forge",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37869",
    city: "Sneedville",
    state: "TN",
    areacode: 423,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37870",
    city: "Speedwell",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37871",
    city: "Strawberry Plains",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37872",
    city: "Sunbright",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37873",
    city: "Surgoinsville",
    state: "TN",
    areacode: 423,
    county: "Hawkins",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37874",
    city: "Sweetwater",
    state: "TN",
    areacode: 423,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37876",
    city: "Sevierville",
    state: "TN",
    areacode: 865,
    county: "Sevier",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37877",
    city: "Talbott",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37878",
    city: "Tallassee",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37879",
    city: "Tazewell",
    state: "TN",
    areacode: 423,
    county: "Claiborne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37880",
    city: "Ten Mile",
    state: "TN",
    areacode: 423,
    county: "Roane",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37881",
    city: "Thorn Hill",
    state: "TN",
    areacode: 865,
    county: "Grainger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37882",
    city: "Townsend",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37885",
    city: "Vonore",
    state: "TN",
    areacode: 423,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37886",
    city: "Walland",
    state: "TN",
    areacode: 865,
    county: "Blount",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37887",
    city: "Wartburg",
    state: "TN",
    areacode: 423,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37888",
    city: "Washburn",
    state: "TN",
    areacode: 865,
    county: "Grainger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37890",
    city: "White Pine",
    state: "TN",
    areacode: 865,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37891",
    city: "Whitesburg",
    state: "TN",
    areacode: 423,
    county: "Hamblen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37892",
    city: "Winfield",
    state: "TN",
    areacode: 423,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37901",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37902",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37909",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37912",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37914",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37915",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37916",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37917",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37918",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37919",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37920",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37921",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37922",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37923",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37924",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37927",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37928",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37929",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37930",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37931",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37932",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37933",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37934",
    city: "Farragut",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37938",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37939",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37940",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37950",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37995",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37996",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37997",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "37998",
    city: "Knoxville",
    state: "TN",
    areacode: 865,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "38001",
    city: "Alamo",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38002",
    city: "Arlington",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38004",
    city: "Atoka",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38006",
    city: "Bells",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38007",
    city: "Bogota",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38008",
    city: "Bolivar",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38010",
    city: "Braden",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38011",
    city: "Brighton",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38012",
    city: "Brownsville",
    state: "TN",
    areacode: 731,
    county: "Haywood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38014",
    city: "Brunswick",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38015",
    city: "Burlison",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38016",
    city: "Cordova",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38017",
    city: "Collierville",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38018",
    city: "Cordova",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38019",
    city: "Covington",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38021",
    city: "Crockett Mills",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38023",
    city: "Drummonds",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38024",
    city: "Dyersburg",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38025",
    city: "Dyersburg",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38027",
    city: "Collierville",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38028",
    city: "Eads",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38029",
    city: "Ellendale",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38030",
    city: "Finley",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38034",
    city: "Friendship",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38036",
    city: "Gallaway",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38037",
    city: "Gates",
    state: "TN",
    areacode: 731,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38039",
    city: "Grand Junction",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38040",
    city: "Halls",
    state: "TN",
    areacode: 731,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38041",
    city: "Henning",
    state: "TN",
    areacode: 731,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38042",
    city: "Hickory Valley",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38044",
    city: "Hornsby",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38045",
    city: "Laconia",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38046",
    city: "La Grange",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38047",
    city: "Lenox",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38048",
    city: "Macon",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38049",
    city: "Mason",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38050",
    city: "Maury City",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38052",
    city: "Middleton",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38053",
    city: "Millington",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38054",
    city: "Millington",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38055",
    city: "Millington",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38057",
    city: "Moscow",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38058",
    city: "Munford",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38059",
    city: "Newbern",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38060",
    city: "Oakland",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38061",
    city: "Pocahontas",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38063",
    city: "Ripley",
    state: "TN",
    areacode: 731,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38066",
    city: "Rossville",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38067",
    city: "Saulsbury",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38068",
    city: "Somerville",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38069",
    city: "Stanton",
    state: "TN",
    areacode: 731,
    county: "Haywood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38070",
    city: "Tigrett",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38071",
    city: "Tipton",
    state: "TN",
    areacode: 901,
    county: "Tipton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38075",
    city: "Whiteville",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38076",
    city: "Williston",
    state: "TN",
    areacode: 901,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38077",
    city: "Wynnburg",
    state: "TN",
    areacode: 731,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38079",
    city: "Tiptonville",
    state: "TN",
    areacode: 731,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38080",
    city: "Ridgely",
    state: "TN",
    areacode: 731,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38083",
    city: "Millington",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38088",
    city: "Cordova",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38101",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38103",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38104",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38105",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38106",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38107",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38108",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38109",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38111",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38112",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38113",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38114",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38115",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38116",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38117",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38118",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38119",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38120",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38122",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38124",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38125",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38126",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38127",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38128",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38130",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38131",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38132",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38133",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38134",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38135",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38136",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38137",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38138",
    city: "Germantown",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38139",
    city: "Germantown",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38141",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38145",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38148",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38150",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38151",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38152",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38157",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38159",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38161",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38163",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38166",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38167",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38168",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38173",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38174",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38175",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38177",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38181",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38182",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38183",
    city: "Germantown",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38184",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38186",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38187",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38188",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38190",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38193",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38194",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38197",
    city: "Memphis",
    state: "TN",
    areacode: 901,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38201",
    city: "McKenzie",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38220",
    city: "Atwood",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38221",
    city: "Big Sandy",
    state: "TN",
    areacode: 731,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38222",
    city: "Buchanan",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38223",
    city: "Como",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38224",
    city: "Cottage Grove",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38225",
    city: "Dresden",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38226",
    city: "Dukedom",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38229",
    city: "Gleason",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38230",
    city: "Greenfield",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38231",
    city: "Henry",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38232",
    city: "Hornbeak",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38233",
    city: "Kenton",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38235",
    city: "McLemoresville",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38236",
    city: "Mansfield",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38237",
    city: "Martin",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38238",
    city: "Martin",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38240",
    city: "Obion",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38241",
    city: "Palmersville",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38242",
    city: "Paris",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38251",
    city: "Puryear",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38253",
    city: "Rives",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38254",
    city: "Samburg",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38255",
    city: "Sharon",
    state: "TN",
    areacode: 731,
    county: "Weakley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38256",
    city: "Springville",
    state: "TN",
    areacode: 731,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38257",
    city: "South Fulton",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38258",
    city: "Trezevant",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38259",
    city: "Trimble",
    state: "TN",
    areacode: 731,
    county: "Dyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38260",
    city: "Troy",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38261",
    city: "Union City",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38271",
    city: "Woodland Mills",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38281",
    city: "Union City",
    state: "TN",
    areacode: 731,
    county: "Obion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38301",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38302",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38303",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38305",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38308",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38310",
    city: "Adamsville",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38311",
    city: "Bath Springs",
    state: "TN",
    areacode: 731,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38313",
    city: "Beech Bluff",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38314",
    city: "Jackson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38315",
    city: "Bethel Springs",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38316",
    city: "Bradford",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38317",
    city: "Bruceton",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38318",
    city: "Buena Vista",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38320",
    city: "Camden",
    state: "TN",
    areacode: 731,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38321",
    city: "Cedar Grove",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38324",
    city: "Clarksburg",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38326",
    city: "Counce",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38327",
    city: "Crump",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38328",
    city: "Darden",
    state: "TN",
    areacode: 731,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38329",
    city: "Decaturville",
    state: "TN",
    areacode: 731,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38330",
    city: "Dyer",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38331",
    city: "Eaton",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38332",
    city: "Enville",
    state: "TN",
    areacode: 731,
    county: "Chester",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38333",
    city: "Eva",
    state: "TN",
    areacode: 731,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38334",
    city: "Finger",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38336",
    city: "Fruitvale",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38337",
    city: "Gadsden",
    state: "TN",
    areacode: 731,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38338",
    city: "Gibson",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38339",
    city: "Guys",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38340",
    city: "Henderson",
    state: "TN",
    areacode: 731,
    county: "Chester",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38341",
    city: "Holladay",
    state: "TN",
    areacode: 731,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38342",
    city: "Hollow Rock",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38343",
    city: "Humboldt",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38344",
    city: "Huntingdon",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38345",
    city: "Huron",
    state: "TN",
    areacode: 731,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38346",
    city: "Idlewild",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38347",
    city: "Jacks Creek",
    state: "TN",
    areacode: 731,
    county: "Chester",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38348",
    city: "Lavinia",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38351",
    city: "Lexington",
    state: "TN",
    areacode: 731,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38352",
    city: "Luray",
    state: "TN",
    areacode: 731,
    county: "Chester",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38355",
    city: "Medina",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38356",
    city: "Medon",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38357",
    city: "Michie",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38358",
    city: "Milan",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38359",
    city: "Milledgeville",
    state: "TN",
    areacode: 731,
    county: "Chester",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38361",
    city: "Morris Chapel",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38362",
    city: "Oakfield",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38363",
    city: "Parsons",
    state: "TN",
    areacode: 731,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38365",
    city: "Pickwick Dam",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38366",
    city: "Pinson",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38367",
    city: "Ramer",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38368",
    city: "Reagan",
    state: "TN",
    areacode: 731,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38369",
    city: "Rutherford",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38370",
    city: "Saltillo",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38371",
    city: "Sardis",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38372",
    city: "Savannah",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38374",
    city: "Scotts Hill",
    state: "TN",
    areacode: 731,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38375",
    city: "Selmer",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38376",
    city: "Shiloh",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38377",
    city: "Silerton",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38378",
    city: "Spring Creek",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38379",
    city: "Stantonville",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38380",
    city: "Sugar Tree",
    state: "TN",
    areacode: 731,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38381",
    city: "Toone",
    state: "TN",
    areacode: 731,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38382",
    city: "Trenton",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38387",
    city: "Westport",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38388",
    city: "Wildersville",
    state: "TN",
    areacode: 731,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38389",
    city: "Yorkville",
    state: "TN",
    areacode: 731,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38390",
    city: "Yuma",
    state: "TN",
    areacode: 731,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38391",
    city: "Denmark",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38392",
    city: "Mercer",
    state: "TN",
    areacode: 731,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38393",
    city: "Chewalla",
    state: "TN",
    areacode: 731,
    county: "McNairy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38401",
    city: "Columbia",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38402",
    city: "Columbia",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38425",
    city: "Clifton",
    state: "TN",
    areacode: 931,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38449",
    city: "Ardmore",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38450",
    city: "Collinwood",
    state: "TN",
    areacode: 931,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38451",
    city: "Culleoka",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38452",
    city: "Cypress Inn",
    state: "TN",
    areacode: 931,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38453",
    city: "Dellrose",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38454",
    city: "Duck River",
    state: "TN",
    areacode: 931,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38455",
    city: "Elkton",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38456",
    city: "Ethridge",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38457",
    city: "Five Points",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38459",
    city: "Frankewing",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38460",
    city: "Goodspring",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38461",
    city: "Hampshire",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38462",
    city: "Hohenwald",
    state: "TN",
    areacode: 931,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38463",
    city: "Iron City",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38464",
    city: "Lawrenceburg",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38468",
    city: "Leoma",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38469",
    city: "Loretto",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38471",
    city: "Lutts",
    state: "TN",
    areacode: 931,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38472",
    city: "Lynnville",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38473",
    city: "Minor Hill",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38474",
    city: "Mount Pleasant",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38475",
    city: "Olivehill",
    state: "TN",
    areacode: 731,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38476",
    city: "Primm Springs",
    state: "TN",
    areacode: 931,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38477",
    city: "Prospect",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38478",
    city: "Pulaski",
    state: "TN",
    areacode: 931,
    county: "Giles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38481",
    city: "Saint Joseph",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38482",
    city: "Santa Fe",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38483",
    city: "Summertown",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38485",
    city: "Waynesboro",
    state: "TN",
    areacode: 931,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38486",
    city: "Westpoint",
    state: "TN",
    areacode: 931,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38487",
    city: "Williamsport",
    state: "TN",
    areacode: 931,
    county: "Maury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38488",
    city: "Taft",
    state: "TN",
    areacode: 931,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38501",
    city: "Cookeville",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38502",
    city: "Cookeville",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38503",
    city: "Cookeville",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38504",
    city: "Allardt",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38505",
    city: "Cookeville",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38506",
    city: "Cookeville",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38541",
    city: "Allons",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38542",
    city: "Allred",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38543",
    city: "Alpine",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38544",
    city: "Baxter",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38545",
    city: "Bloomington Springs",
    state: "TN",
    areacode: 931,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38547",
    city: "Brush Creek",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38548",
    city: "Buffalo Valley",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38549",
    city: "Byrdstown",
    state: "TN",
    areacode: 931,
    county: "Pickett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38550",
    city: "Campaign",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38551",
    city: "Celina",
    state: "TN",
    areacode: 931,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38552",
    city: "Chestnut Mound",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38553",
    city: "Clarkrange",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38554",
    city: "Crawford",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38555",
    city: "Crossville",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38556",
    city: "Jamestown",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38557",
    city: "Crossville",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38558",
    city: "Crossville",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38559",
    city: "Doyle",
    state: "TN",
    areacode: 931,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38560",
    city: "Elmwood",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38562",
    city: "Gainesboro",
    state: "TN",
    areacode: 931,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38563",
    city: "Gordonsville",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38564",
    city: "Granville",
    state: "TN",
    areacode: 931,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38565",
    city: "Grimsley",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38567",
    city: "Hickman",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38568",
    city: "Hilham",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38569",
    city: "Lancaster",
    state: "TN",
    areacode: 615,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38570",
    city: "Livingston",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38571",
    city: "Crossville",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38572",
    city: "Crossville",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38573",
    city: "Monroe",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38574",
    city: "Monterey",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38575",
    city: "Moss",
    state: "TN",
    areacode: 931,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38577",
    city: "Pall Mall",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38578",
    city: "Pleasant Hill",
    state: "TN",
    areacode: 931,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38579",
    city: "Quebeck",
    state: "TN",
    areacode: 931,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38580",
    city: "Rickman",
    state: "TN",
    areacode: 931,
    county: "Overton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38581",
    city: "Rock Island",
    state: "TN",
    areacode: 931,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38582",
    city: "Silver Point",
    state: "TN",
    areacode: 931,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38583",
    city: "Sparta",
    state: "TN",
    areacode: 931,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38585",
    city: "Spencer",
    state: "TN",
    areacode: 931,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38587",
    city: "Walling",
    state: "TN",
    areacode: 931,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38588",
    city: "Whitleyville",
    state: "TN",
    areacode: 931,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38589",
    city: "Wilder",
    state: "TN",
    areacode: 931,
    county: "Fentress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38601",
    city: "Abbeville",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38602",
    city: "Arkabutla",
    state: "MS",
    areacode: 662,
    county: "Tate",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38603",
    city: "Ashland",
    state: "MS",
    areacode: 662,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38606",
    city: "Batesville",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38609",
    city: "Marks",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38610",
    city: "Blue Mountain",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38611",
    city: "Byhalia",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38614",
    city: "Clarksdale",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38617",
    city: "Coahoma",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38618",
    city: "Coldwater",
    state: "MS",
    areacode: 662,
    county: "Tate",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38619",
    city: "Como",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38620",
    city: "Courtland",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38621",
    city: "Crenshaw",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38622",
    city: "Crowder",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38623",
    city: "Darling",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38625",
    city: "Dumas",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38626",
    city: "Dundee",
    state: "MS",
    areacode: 662,
    county: "Tunica",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38627",
    city: "Etta",
    state: "MS",
    areacode: 662,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38628",
    city: "Sledge",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38629",
    city: "Falkner",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38630",
    city: "Farrell",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38631",
    city: "Friars Point",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38632",
    city: "Hernando",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38633",
    city: "Hickory Flat",
    state: "MS",
    areacode: 662,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38634",
    city: "Holly Springs",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38635",
    city: "Holly Springs",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38637",
    city: "Horn Lake",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38638",
    city: "Independence",
    state: "MS",
    areacode: 662,
    county: "Tate",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38639",
    city: "Jonestown",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38641",
    city: "Lake Cormorant",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38642",
    city: "Lamar",
    state: "MS",
    areacode: 662,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38643",
    city: "Lambert",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38644",
    city: "Lula",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38645",
    city: "Lyon",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38646",
    city: "Marks",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38647",
    city: "Michigan City",
    state: "MS",
    areacode: 662,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38649",
    city: "Mount Pleasant",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38650",
    city: "Myrtle",
    state: "MS",
    areacode: 662,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38651",
    city: "Nesbit",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38652",
    city: "New Albany",
    state: "MS",
    areacode: 662,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38654",
    city: "Olive Branch",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38655",
    city: "Oxford",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38658",
    city: "Pope",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38659",
    city: "Potts Camp",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38661",
    city: "Red Banks",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38663",
    city: "Ripley",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38664",
    city: "Robinsonville",
    state: "MS",
    areacode: 662,
    county: "Tunica",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38665",
    city: "Sarah",
    state: "MS",
    areacode: 662,
    county: "Tate",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38666",
    city: "Sardis",
    state: "MS",
    areacode: 662,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38668",
    city: "Senatobia",
    state: "MS",
    areacode: 662,
    county: "Tate",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38669",
    city: "Sherard",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38670",
    city: "Sledge",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38671",
    city: "Southaven",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38672",
    city: "Southaven",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38673",
    city: "Taylor",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38674",
    city: "Tiplersville",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38675",
    city: "Memphis Stc",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38676",
    city: "Tunica",
    state: "MS",
    areacode: 662,
    county: "Tunica",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38677",
    city: "University",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38679",
    city: "Victoria",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38680",
    city: "Walls",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38683",
    city: "Walnut",
    state: "MS",
    areacode: 662,
    county: "Tippah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38685",
    city: "Waterford",
    state: "MS",
    areacode: 662,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38686",
    city: "Walls",
    state: "MS",
    areacode: 662,
    county: "DeSoto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38701",
    city: "Greenville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38702",
    city: "Greenville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38703",
    city: "Greenville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38704",
    city: "Greenville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38720",
    city: "Alligator",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38721",
    city: "Anguilla",
    state: "MS",
    areacode: 662,
    county: "Sharkey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38722",
    city: "Arcola",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38723",
    city: "Avon",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38725",
    city: "Benoit",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38726",
    city: "Beulah",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38730",
    city: "Boyle",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38731",
    city: "Chatham",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38732",
    city: "Cleveland",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38733",
    city: "Cleveland",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38736",
    city: "Doddsville",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38737",
    city: "Drew",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38738",
    city: "Parchman",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38739",
    city: "Dublin",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38740",
    city: "Duncan",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38744",
    city: "Glen Allan",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38745",
    city: "Grace",
    state: "MS",
    areacode: 662,
    county: "Issaquena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38746",
    city: "Gunnison",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38748",
    city: "Hollandale",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38749",
    city: "Indianola",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38751",
    city: "Indianola",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38753",
    city: "Inverness",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38754",
    city: "Isola",
    state: "MS",
    areacode: 662,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38756",
    city: "Leland",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38759",
    city: "Merigold",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38760",
    city: "Metcalfe",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38761",
    city: "Moorhead",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38762",
    city: "Mound Bayou",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38764",
    city: "Pace",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38765",
    city: "Panther Burn",
    state: "MS",
    areacode: 662,
    county: "Sharkey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38767",
    city: "Rena Lara",
    state: "MS",
    areacode: 662,
    county: "Coahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38768",
    city: "Rome",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38769",
    city: "Rosedale",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38771",
    city: "Ruleville",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38772",
    city: "Scott",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38773",
    city: "Shaw",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38774",
    city: "Shelby",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38776",
    city: "Stoneville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38778",
    city: "Sunflower",
    state: "MS",
    areacode: 662,
    county: "Sunflower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38780",
    city: "Wayside",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38781",
    city: "Winstonville",
    state: "MS",
    areacode: 662,
    county: "Bolivar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38782",
    city: "Winterville",
    state: "MS",
    areacode: 662,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38801",
    city: "Tupelo",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38802",
    city: "Tupelo",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38803",
    city: "Tupelo",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38804",
    city: "Tupelo",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38820",
    city: "Algoma",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38821",
    city: "Amory",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38824",
    city: "Baldwyn",
    state: "MS",
    areacode: 662,
    county: "Prentiss",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38825",
    city: "Becker",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38826",
    city: "Belden",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38827",
    city: "Belmont",
    state: "MS",
    areacode: 662,
    county: "Tishomingo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38828",
    city: "Blue Springs",
    state: "MS",
    areacode: 662,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38829",
    city: "Booneville",
    state: "MS",
    areacode: 662,
    county: "Prentiss",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38833",
    city: "Burnsville",
    state: "MS",
    areacode: 662,
    county: "Tishomingo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38834",
    city: "Corinth",
    state: "MS",
    areacode: 662,
    county: "Alcorn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38835",
    city: "Corinth",
    state: "MS",
    areacode: 662,
    county: "Alcorn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38838",
    city: "Dennis",
    state: "MS",
    areacode: 662,
    county: "Tishomingo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38839",
    city: "Derma",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38841",
    city: "Ecru",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38843",
    city: "Fulton",
    state: "MS",
    areacode: 662,
    county: "Itawamba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38844",
    city: "Gattman",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38846",
    city: "Glen",
    state: "MS",
    areacode: 662,
    county: "Alcorn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38847",
    city: "Golden",
    state: "MS",
    areacode: 662,
    county: "Itawamba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38848",
    city: "Greenwood Springs",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38849",
    city: "Guntown",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38850",
    city: "Houlka",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38851",
    city: "Houston",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38852",
    city: "Iuka",
    state: "MS",
    areacode: 662,
    county: "Tishomingo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38855",
    city: "Mantachie",
    state: "MS",
    areacode: 662,
    county: "Itawamba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38856",
    city: "Marietta",
    state: "MS",
    areacode: 662,
    county: "Itawamba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38857",
    city: "Mooreville",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38858",
    city: "Nettleton",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38859",
    city: "New Site",
    state: "MS",
    areacode: 662,
    county: "Prentiss",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38860",
    city: "Okolona",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38862",
    city: "Plantersville",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38863",
    city: "Pontotoc",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38864",
    city: "Randolph",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38865",
    city: "Rienzi",
    state: "MS",
    areacode: 662,
    county: "Alcorn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38866",
    city: "Saltillo",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38868",
    city: "Shannon",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38869",
    city: "Sherman",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38870",
    city: "Smithville",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38871",
    city: "Thaxton",
    state: "MS",
    areacode: 662,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38873",
    city: "Tishomingo",
    state: "MS",
    areacode: 662,
    county: "Tishomingo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38874",
    city: "Toccopola",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38875",
    city: "Trebloc",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38876",
    city: "Tremont",
    state: "MS",
    areacode: 662,
    county: "Itawamba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38877",
    city: "Van Vleet",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38878",
    city: "Vardaman",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38879",
    city: "Verona",
    state: "MS",
    areacode: 662,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38880",
    city: "Wheeler",
    state: "MS",
    areacode: 662,
    county: "Prentiss",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38901",
    city: "Grenada",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38902",
    city: "Grenada",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38913",
    city: "Banner",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38914",
    city: "Big Creek",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38915",
    city: "Bruce",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38916",
    city: "Calhoun City",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38917",
    city: "Carrollton",
    state: "MS",
    areacode: 662,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38920",
    city: "Cascilla",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38921",
    city: "Charleston",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38922",
    city: "Coffeeville",
    state: "MS",
    areacode: 662,
    county: "Yalobusha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38923",
    city: "Coila",
    state: "MS",
    areacode: 662,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38924",
    city: "Cruger",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38925",
    city: "Duck Hill",
    state: "MS",
    areacode: 662,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38926",
    city: "Elliott",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38927",
    city: "Enid",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38928",
    city: "Glendora",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38929",
    city: "Gore Springs",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38930",
    city: "Greenwood",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38935",
    city: "Greenwood",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38940",
    city: "Holcomb",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38941",
    city: "Itta Bena",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38943",
    city: "McCarley",
    state: "MS",
    areacode: 662,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38944",
    city: "Minter City",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38945",
    city: "Greenwood",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38946",
    city: "Morgan City",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38947",
    city: "North Carrollton",
    state: "MS",
    areacode: 662,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38948",
    city: "Oakland",
    state: "MS",
    areacode: 662,
    county: "Yalobusha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38949",
    city: "Paris",
    state: "MS",
    areacode: 662,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38950",
    city: "Philipp",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38951",
    city: "Pittsboro",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38952",
    city: "Schlater",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38953",
    city: "Scobey",
    state: "MS",
    areacode: 662,
    county: "Yalobusha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38954",
    city: "Sidon",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38955",
    city: "Slate Spring",
    state: "MS",
    areacode: 662,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38957",
    city: "Sumner",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38958",
    city: "Swan Lake",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38959",
    city: "Swiftown",
    state: "MS",
    areacode: 662,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38960",
    city: "Tie Plant",
    state: "MS",
    areacode: 662,
    county: "Grenada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38961",
    city: "Tillatoba",
    state: "MS",
    areacode: 662,
    county: "Yalobusha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38962",
    city: "Tippo",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38963",
    city: "Tutwiler",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38964",
    city: "Vance",
    state: "MS",
    areacode: 662,
    county: "Quitman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38965",
    city: "Water Valley",
    state: "MS",
    areacode: 662,
    county: "Yalobusha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38966",
    city: "Webb",
    state: "MS",
    areacode: 662,
    county: "Tallahatchie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "38967",
    city: "Winona",
    state: "MS",
    areacode: 662,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39038",
    city: "Belzoni",
    state: "MS",
    areacode: 662,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39039",
    city: "Benton",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39040",
    city: "Bentonia",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39041",
    city: "Bolton",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39042",
    city: "Brandon",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39043",
    city: "Brandon",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39044",
    city: "Braxton",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39045",
    city: "Camden",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39046",
    city: "Canton",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39047",
    city: "Brandon",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39051",
    city: "Carthage",
    state: "MS",
    areacode: 601,
    county: "Leake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39054",
    city: "Cary",
    state: "MS",
    areacode: 662,
    county: "Sharkey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39056",
    city: "Clinton",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39057",
    city: "Conehatta",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39058",
    city: "Clinton",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39059",
    city: "Crystal Springs",
    state: "MS",
    areacode: 601,
    county: "Copiah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39060",
    city: "Clinton",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39061",
    city: "Delta City",
    state: "MS",
    areacode: 662,
    county: "Sharkey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39062",
    city: "D Lo",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39063",
    city: "Durant",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39066",
    city: "Edwards",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39067",
    city: "Ethel",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39069",
    city: "Fayette",
    state: "MS",
    areacode: 601,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39071",
    city: "Flora",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39073",
    city: "Florence",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39074",
    city: "Forest",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39077",
    city: "Gallman",
    state: "MS",
    areacode: 601,
    county: "Copiah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39078",
    city: "Georgetown",
    state: "MS",
    areacode: 601,
    county: "Copiah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39079",
    city: "Goodman",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39080",
    city: "Harperville",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39081",
    city: "Fayette",
    state: "MS",
    areacode: 601,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39082",
    city: "Harrisville",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39083",
    city: "Hazlehurst",
    state: "MS",
    areacode: 601,
    county: "Copiah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39086",
    city: "Hermanville",
    state: "MS",
    areacode: 601,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39087",
    city: "Hillsboro",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39088",
    city: "Holly Bluff",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39090",
    city: "Kosciusko",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39092",
    city: "Lake",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39094",
    city: "Lena",
    state: "MS",
    areacode: 601,
    county: "Leake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39095",
    city: "Lexington",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39096",
    city: "Lorman",
    state: "MS",
    areacode: 601,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39097",
    city: "Louise",
    state: "MS",
    areacode: 662,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39098",
    city: "Ludlow",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39107",
    city: "McAdams",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39108",
    city: "McCool",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39109",
    city: "Madden",
    state: "MS",
    areacode: 601,
    county: "Leake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39110",
    city: "Madison",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39111",
    city: "Magee",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39113",
    city: "Mayersville",
    state: "MS",
    areacode: 662,
    county: "Issaquena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39114",
    city: "Mendenhall",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39115",
    city: "Midnight",
    state: "MS",
    areacode: 662,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39116",
    city: "Mize",
    state: "MS",
    areacode: 601,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39117",
    city: "Morton",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39119",
    city: "Mount Olive",
    state: "MS",
    areacode: 601,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39120",
    city: "Natchez",
    state: "MS",
    areacode: 601,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39121",
    city: "Natchez",
    state: "MS",
    areacode: 601,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39122",
    city: "Natchez",
    state: "MS",
    areacode: 601,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39130",
    city: "Madison",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39140",
    city: "Newhebron",
    state: "MS",
    areacode: 601,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39144",
    city: "Pattison",
    state: "MS",
    areacode: 601,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39145",
    city: "Pelahatchie",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39146",
    city: "Pickens",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39148",
    city: "Piney Woods",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39149",
    city: "Pinola",
    state: "MS",
    areacode: 601,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39150",
    city: "Port Gibson",
    state: "MS",
    areacode: 601,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39151",
    city: "Puckett",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39152",
    city: "Pulaski",
    state: "MS",
    areacode: 601,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39153",
    city: "Raleigh",
    state: "MS",
    areacode: 601,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39154",
    city: "Raymond",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39156",
    city: "Redwood",
    state: "MS",
    areacode: 601,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39157",
    city: "Ridgeland",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39158",
    city: "Ridgeland",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39159",
    city: "Rolling Fork",
    state: "MS",
    areacode: 662,
    county: "Sharkey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39160",
    city: "Sallis",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39161",
    city: "Sandhill",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39162",
    city: "Satartia",
    state: "MS",
    areacode: 662,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39163",
    city: "Sharon",
    state: "MS",
    areacode: 601,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39165",
    city: "Sibley",
    state: "MS",
    areacode: 601,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39166",
    city: "Silver City",
    state: "MS",
    areacode: 662,
    county: "Humphreys",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39167",
    city: "Star",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39168",
    city: "Taylorsville",
    state: "MS",
    areacode: 601,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39169",
    city: "Tchula",
    state: "MS",
    areacode: 662,
    county: "Holmes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39170",
    city: "Terry",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39171",
    city: "Thomastown",
    state: "MS",
    areacode: 662,
    county: "Leake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39173",
    city: "Tinsley",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39174",
    city: "Tougaloo",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39175",
    city: "Utica",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39176",
    city: "Vaiden",
    state: "MS",
    areacode: 662,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39177",
    city: "Valley Park",
    state: "MS",
    areacode: 601,
    county: "Issaquena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39179",
    city: "Vaughan",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39180",
    city: "Vicksburg",
    state: "MS",
    areacode: 601,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39181",
    city: "Vicksburg",
    state: "MS",
    areacode: 601,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39182",
    city: "Vicksburg",
    state: "MS",
    areacode: 601,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39183",
    city: "Vicksburg",
    state: "MS",
    areacode: 601,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39189",
    city: "Walnut Grove",
    state: "MS",
    areacode: 601,
    county: "Leake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39190",
    city: "Washington",
    state: "MS",
    areacode: 601,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39191",
    city: "Wesson",
    state: "MS",
    areacode: 601,
    county: "Copiah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39192",
    city: "West",
    state: "MS",
    areacode: 662,
    county: "Attala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39193",
    city: "Whitfield",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39194",
    city: "Yazoo City",
    state: "MS",
    areacode: 662,
    county: "Yazoo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39201",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39202",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39203",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39204",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39205",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39206",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39207",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39208",
    city: "Pearl",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39209",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39210",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39211",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39212",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39213",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39215",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39216",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39217",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39218",
    city: "Richland",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39225",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39232",
    city: "Flowood",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39236",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39250",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39269",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39271",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39272",
    city: "Byram",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39282",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39283",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39284",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39286",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39288",
    city: "Pearl",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39289",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39296",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Hinds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39298",
    city: "Jackson",
    state: "MS",
    areacode: 601,
    county: "Rankin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39301",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39302",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39303",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39304",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39305",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39307",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39309",
    city: "Meridian",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39320",
    city: "Bailey",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39322",
    city: "Buckatunna",
    state: "MS",
    areacode: 601,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39323",
    city: "Chunky",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39324",
    city: "Clara",
    state: "MS",
    areacode: 601,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39325",
    city: "Collinsville",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39326",
    city: "Daleville",
    state: "MS",
    areacode: 601,
    county: "Kemper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39327",
    city: "Decatur",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39328",
    city: "DeKalb",
    state: "MS",
    areacode: 601,
    county: "Kemper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39330",
    city: "Enterprise",
    state: "MS",
    areacode: 601,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39332",
    city: "Hickory",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39335",
    city: "Lauderdale",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39336",
    city: "Lawrence",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39337",
    city: "Little Rock",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39338",
    city: "Louin",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39339",
    city: "Louisville",
    state: "MS",
    areacode: 662,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39341",
    city: "Macon",
    state: "MS",
    areacode: 662,
    county: "Noxubee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39342",
    city: "Marion",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39345",
    city: "Newton",
    state: "MS",
    areacode: 601,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39346",
    city: "Noxapater",
    state: "MS",
    areacode: 662,
    county: "Winston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39347",
    city: "Pachuta",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39348",
    city: "Paulding",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39350",
    city: "Philadelphia",
    state: "MS",
    areacode: 601,
    county: "Neshoba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39352",
    city: "Porterville",
    state: "MS",
    areacode: 662,
    county: "Kemper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39354",
    city: "Preston",
    state: "MS",
    areacode: 601,
    county: "Kemper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39355",
    city: "Quitman",
    state: "MS",
    areacode: 601,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39356",
    city: "Rose Hill",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39358",
    city: "Scooba",
    state: "MS",
    areacode: 662,
    county: "Kemper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39359",
    city: "Sebastopol",
    state: "MS",
    areacode: 601,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39360",
    city: "Shubuta",
    state: "MS",
    areacode: 601,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39361",
    city: "Shuqualak",
    state: "MS",
    areacode: 662,
    county: "Noxubee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39362",
    city: "State Line",
    state: "MS",
    areacode: 601,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39363",
    city: "Stonewall",
    state: "MS",
    areacode: 601,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39364",
    city: "Toomsuba",
    state: "MS",
    areacode: 601,
    county: "Lauderdale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39365",
    city: "Union",
    state: "MS",
    areacode: 601,
    county: "Neshoba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39366",
    city: "Vossburg",
    state: "MS",
    areacode: 601,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39367",
    city: "Waynesboro",
    state: "MS",
    areacode: 662,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39401",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39402",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39403",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39404",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39406",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39407",
    city: "Hattiesburg",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39421",
    city: "Bassfield",
    state: "MS",
    areacode: 601,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39422",
    city: "Bay Springs",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39423",
    city: "Beaumont",
    state: "MS",
    areacode: 601,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39425",
    city: "Brooklyn",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39426",
    city: "Carriere",
    state: "MS",
    areacode: 601,
    county: "Pearl River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39427",
    city: "Carson",
    state: "MS",
    areacode: 601,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39428",
    city: "Collins",
    state: "MS",
    areacode: 601,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39429",
    city: "Columbia",
    state: "MS",
    areacode: 601,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39436",
    city: "Eastabuchie",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39437",
    city: "Ellisville",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39439",
    city: "Heidelberg",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39440",
    city: "Laurel",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39441",
    city: "Laurel",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39442",
    city: "Laurel",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39443",
    city: "Laurel",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39451",
    city: "Leakesville",
    state: "MS",
    areacode: 601,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39452",
    city: "Lucedale",
    state: "MS",
    areacode: 601,
    county: "George",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39455",
    city: "Lumberton",
    state: "MS",
    areacode: 601,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39456",
    city: "McLain",
    state: "MS",
    areacode: 601,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39457",
    city: "McNeill",
    state: "MS",
    areacode: 601,
    county: "Pearl River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39459",
    city: "Moselle",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39460",
    city: "Moss",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39461",
    city: "Neely",
    state: "MS",
    areacode: 601,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39462",
    city: "New Augusta",
    state: "MS",
    areacode: 601,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39463",
    city: "Nicholson",
    state: "MS",
    areacode: 601,
    county: "Pearl River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39464",
    city: "Ovett",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39465",
    city: "Petal",
    state: "MS",
    areacode: 601,
    county: "Forrest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39466",
    city: "Picayune",
    state: "MS",
    areacode: 601,
    county: "Pearl River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39470",
    city: "Poplarville",
    state: "MS",
    areacode: 601,
    county: "Pearl River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39474",
    city: "Prentiss",
    state: "MS",
    areacode: 601,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39475",
    city: "Purvis",
    state: "MS",
    areacode: 601,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39476",
    city: "Richton",
    state: "MS",
    areacode: 601,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39477",
    city: "Sandersville",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39478",
    city: "Sandy Hook",
    state: "MS",
    areacode: 601,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39479",
    city: "Seminary",
    state: "MS",
    areacode: 601,
    county: "Covington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39480",
    city: "Soso",
    state: "MS",
    areacode: 601,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39481",
    city: "Stringer",
    state: "MS",
    areacode: 601,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39482",
    city: "Sumrall",
    state: "MS",
    areacode: 601,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39483",
    city: "Foxworth",
    state: "MS",
    areacode: 601,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39501",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39502",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39503",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39505",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39506",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39507",
    city: "Gulfport",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39520",
    city: "Bay Saint Louis",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39521",
    city: "Bay Saint Louis",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39522",
    city: "Stennis Space Center",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39525",
    city: "Diamondhead",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39529",
    city: "Stennis Space Center",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39530",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39531",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39532",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39533",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39534",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39535",
    city: "Biloxi",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39540",
    city: "Diberville",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39552",
    city: "Escatawpa",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39553",
    city: "Gautier",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39555",
    city: "Hurley",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39556",
    city: "Kiln",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39558",
    city: "Lakeshore",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39560",
    city: "Long Beach",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39561",
    city: "McHenry",
    state: "MS",
    areacode: 601,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39562",
    city: "Moss Point",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39563",
    city: "Moss Point",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39564",
    city: "Ocean Springs",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39565",
    city: "Vancleave",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39566",
    city: "Ocean Springs",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39567",
    city: "Pascagoula",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39568",
    city: "Pascagoula",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39569",
    city: "Pascagoula",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39571",
    city: "Pass Christian",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39572",
    city: "Pearlington",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39573",
    city: "Perkinston",
    state: "MS",
    areacode: 601,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39574",
    city: "Saucier",
    state: "MS",
    areacode: 228,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39576",
    city: "Waveland",
    state: "MS",
    areacode: 228,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39577",
    city: "Wiggins",
    state: "MS",
    areacode: 601,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39581",
    city: "Pascagoula",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39595",
    city: "Pascagoula",
    state: "MS",
    areacode: 228,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39601",
    city: "Brookhaven",
    state: "MS",
    areacode: 601,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39602",
    city: "Brookhaven",
    state: "MS",
    areacode: 601,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39603",
    city: "Brookhaven",
    state: "MS",
    areacode: 601,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39629",
    city: "Bogue Chitto",
    state: "MS",
    areacode: 601,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39630",
    city: "Bude",
    state: "MS",
    areacode: 601,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39631",
    city: "Centreville",
    state: "MS",
    areacode: 601,
    county: "Wilkinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39632",
    city: "Chatawa",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39633",
    city: "Crosby",
    state: "MS",
    areacode: 601,
    county: "Wilkinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39635",
    city: "Fernwood",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39638",
    city: "Gloster",
    state: "MS",
    areacode: 601,
    county: "Amite",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39641",
    city: "Jayess",
    state: "MS",
    areacode: 601,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39643",
    city: "Kokomo",
    state: "MS",
    areacode: 601,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39645",
    city: "Liberty",
    state: "MS",
    areacode: 601,
    county: "Amite",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39647",
    city: "McCall Creek",
    state: "MS",
    areacode: 601,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39648",
    city: "McComb",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39649",
    city: "McComb",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39652",
    city: "Magnolia",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39653",
    city: "Meadville",
    state: "MS",
    areacode: 601,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39654",
    city: "Monticello",
    state: "MS",
    areacode: 601,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39656",
    city: "Oak Vale",
    state: "MS",
    areacode: 601,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39657",
    city: "Osyka",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39661",
    city: "Roxie",
    state: "MS",
    areacode: 601,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39662",
    city: "Ruth",
    state: "MS",
    areacode: 601,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39663",
    city: "Silver Creek",
    state: "MS",
    areacode: 601,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39664",
    city: "Smithdale",
    state: "MS",
    areacode: 601,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39665",
    city: "Sontag",
    state: "MS",
    areacode: 601,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39666",
    city: "Summit",
    state: "MS",
    areacode: 601,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39667",
    city: "Tylertown",
    state: "MS",
    areacode: 601,
    county: "Walthall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39668",
    city: "Union Church",
    state: "MS",
    areacode: 601,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39669",
    city: "Woodville",
    state: "MS",
    areacode: 601,
    county: "Wilkinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39701",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39702",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39703",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39704",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39705",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39710",
    city: "Columbus",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39730",
    city: "Aberdeen",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39735",
    city: "Ackerman",
    state: "MS",
    areacode: 662,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39736",
    city: "Artesia",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39737",
    city: "Bellefontaine",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39739",
    city: "Brooksville",
    state: "MS",
    areacode: 662,
    county: "Noxubee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39740",
    city: "Caledonia",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39741",
    city: "Cedarbluff",
    state: "MS",
    areacode: 662,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39743",
    city: "Crawford",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39744",
    city: "Eupora",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39745",
    city: "French Camp",
    state: "MS",
    areacode: 662,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39746",
    city: "Hamilton",
    state: "MS",
    areacode: 662,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39747",
    city: "Kilmichael",
    state: "MS",
    areacode: 662,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39750",
    city: "Maben",
    state: "MS",
    areacode: 662,
    county: "Oktibbeha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39751",
    city: "Mantee",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39752",
    city: "Mathiston",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39753",
    city: "Mayhew",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39754",
    city: "Montpelier",
    state: "MS",
    areacode: 662,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39755",
    city: "Pheba",
    state: "MS",
    areacode: 662,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39756",
    city: "Prairie",
    state: "MS",
    areacode: 662,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39759",
    city: "Starkville",
    state: "MS",
    areacode: 662,
    county: "Oktibbeha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39760",
    city: "Starkville",
    state: "MS",
    areacode: 662,
    county: "Oktibbeha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39762",
    city: "Mississippi State",
    state: "MS",
    areacode: 662,
    county: "Oktibbeha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39766",
    city: "Steens",
    state: "MS",
    areacode: 662,
    county: "Lowndes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39767",
    city: "Stewart",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39769",
    city: "Sturgis",
    state: "MS",
    areacode: 662,
    county: "Oktibbeha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39771",
    city: "Walthall",
    state: "MS",
    areacode: 662,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39772",
    city: "Weir",
    state: "MS",
    areacode: 662,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39773",
    city: "West Point",
    state: "MS",
    areacode: 662,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39776",
    city: "Woodland",
    state: "MS",
    areacode: 662,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "39813",
    city: "Arlington",
    state: "GA",
    areacode: 229,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39815",
    city: "Attapulgus",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39817",
    city: "Bainbridge",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39818",
    city: "Bainbridge",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39819",
    city: "Bainbridge",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39823",
    city: "Blakely",
    state: "GA",
    areacode: 229,
    county: "Early",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39824",
    city: "Bluffton",
    state: "GA",
    areacode: 229,
    county: "Early",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39825",
    city: "Brinson",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39826",
    city: "Bronwood",
    state: "GA",
    areacode: 229,
    county: "Terrell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39827",
    city: "Cairo",
    state: "GA",
    areacode: 229,
    county: "Grady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39828",
    city: "Cairo",
    state: "GA",
    areacode: 229,
    county: "Grady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39829",
    city: "Calvary",
    state: "GA",
    areacode: 229,
    county: "Grady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39832",
    city: "Cedar Springs",
    state: "GA",
    areacode: 229,
    county: "Early",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39834",
    city: "Climax",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39836",
    city: "Coleman",
    state: "GA",
    areacode: 229,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39837",
    city: "Colquitt",
    state: "GA",
    areacode: 229,
    county: "Miller",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39840",
    city: "Cuthbert",
    state: "GA",
    areacode: 229,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39841",
    city: "Damascus",
    state: "GA",
    areacode: 229,
    county: "Early",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39842",
    city: "Dawson",
    state: "GA",
    areacode: 229,
    county: "Terrell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39845",
    city: "Donalsonville",
    state: "GA",
    areacode: 229,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39846",
    city: "Edison",
    state: "GA",
    areacode: 229,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39851",
    city: "Fort Gaines",
    state: "GA",
    areacode: 229,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39852",
    city: "Fowlstown",
    state: "GA",
    areacode: 229,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39854",
    city: "Georgetown",
    state: "GA",
    areacode: 229,
    county: "Quitman",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39859",
    city: "Iron City",
    state: "GA",
    areacode: 229,
    county: "Seminole",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39861",
    city: "Jakin",
    state: "GA",
    areacode: 229,
    county: "Early",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39862",
    city: "Leary",
    state: "GA",
    areacode: 229,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39866",
    city: "Morgan",
    state: "GA",
    areacode: 229,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39867",
    city: "Morris",
    state: "GA",
    areacode: 229,
    county: "Quitman",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39870",
    city: "Newton",
    state: "GA",
    areacode: 912,
    county: "Baker",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39877",
    city: "Parrott",
    state: "GA",
    areacode: 229,
    county: "Webster",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39885",
    city: "Sasser",
    state: "GA",
    areacode: 229,
    county: "Terrell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39886",
    city: "Shellman",
    state: "GA",
    areacode: 229,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39897",
    city: "Whigham",
    state: "GA",
    areacode: 229,
    county: "Grady",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "39901",
    city: "Atlanta",
    state: "GA",
    areacode: 404,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40003",
    city: "Bagdad",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40004",
    city: "Bardstown",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40006",
    city: "Bedford",
    state: "KY",
    areacode: 502,
    county: "Trimble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40007",
    city: "Bethlehem",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40008",
    city: "Bloomfield",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40009",
    city: "Bradfordsville",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40010",
    city: "Buckner",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40011",
    city: "Campbellsburg",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40012",
    city: "Chaplin",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40013",
    city: "Coxs Creek",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40014",
    city: "Crestwood",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40018",
    city: "Eastwood",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40019",
    city: "Eminence",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40020",
    city: "Fairfield",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40022",
    city: "Finchville",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40023",
    city: "Fisherville",
    state: "KY",
    areacode: 502,
    county: "Spencer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40025",
    city: "Glenview",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40026",
    city: "Goshen",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40027",
    city: "Harrods Creek",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40031",
    city: "La Grange",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40032",
    city: "La Grange",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40033",
    city: "Lebanon",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40036",
    city: "Lockport",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40037",
    city: "Loretto",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40040",
    city: "Mackville",
    state: "KY",
    areacode: 859,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40041",
    city: "Masonic Home",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40045",
    city: "Milton",
    state: "KY",
    areacode: 502,
    county: "Trimble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40046",
    city: "Mount Eden",
    state: "KY",
    areacode: 502,
    county: "Spencer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40047",
    city: "Mount Washington",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40048",
    city: "Nazareth",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40049",
    city: "Nerinx",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40050",
    city: "New Castle",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40051",
    city: "New Haven",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40052",
    city: "New Hope",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40055",
    city: "Pendleton",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40056",
    city: "Pewee Valley",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40057",
    city: "Pleasureville",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40058",
    city: "Port Royal",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40059",
    city: "Prospect",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40060",
    city: "Raywick",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40061",
    city: "Saint Catharine",
    state: "KY",
    areacode: 859,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40062",
    city: "Saint Francis",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40063",
    city: "Saint Mary",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40065",
    city: "Shelbyville",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40066",
    city: "Shelbyville",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40067",
    city: "Simpsonville",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40068",
    city: "Smithfield",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40069",
    city: "Springfield",
    state: "KY",
    areacode: 859,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40070",
    city: "Sulphur",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40071",
    city: "Taylorsville",
    state: "KY",
    areacode: 502,
    county: "Spencer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40075",
    city: "Turners Station",
    state: "KY",
    areacode: 502,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40076",
    city: "Waddy",
    state: "KY",
    areacode: 502,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40077",
    city: "Westport",
    state: "KY",
    areacode: 502,
    county: "Oldham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40078",
    city: "Willisburg",
    state: "KY",
    areacode: 859,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40104",
    city: "Battletown",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40107",
    city: "Boston",
    state: "KY",
    areacode: 502,
    county: "Nelson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40108",
    city: "Brandenburg",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40109",
    city: "Brooks",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40110",
    city: "Clermont",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40111",
    city: "Cloverport",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40115",
    city: "Custer",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40117",
    city: "Ekron",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40118",
    city: "Fairdale",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40119",
    city: "Falls of Rough",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40121",
    city: "Fort Knox",
    state: "KY",
    areacode: 502,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40122",
    city: "Fort Knox",
    state: "KY",
    areacode: 502,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40129",
    city: "Hillview",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40140",
    city: "Garfield",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40142",
    city: "Guston",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40143",
    city: "Hardinsburg",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40144",
    city: "Harned",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40145",
    city: "Hudson",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40146",
    city: "Irvington",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40150",
    city: "Lebanon Junction",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40152",
    city: "McDaniels",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40153",
    city: "McQuady",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40155",
    city: "Muldraugh",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40157",
    city: "Payneville",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40159",
    city: "Radcliff",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40160",
    city: "Radcliff",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40161",
    city: "Rhodelia",
    state: "KY",
    areacode: 270,
    county: "Meade",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40162",
    city: "Rineyville",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40165",
    city: "Shepherdsville",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40166",
    city: "Shepherdsville",
    state: "KY",
    areacode: 502,
    county: "Bullitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40170",
    city: "Stephensport",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40171",
    city: "Union Star",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40175",
    city: "Vine Grove",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40176",
    city: "Webster",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40177",
    city: "West Point",
    state: "KY",
    areacode: 502,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40178",
    city: "Westview",
    state: "KY",
    areacode: 270,
    county: "Breckinridge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "40201",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40202",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40203",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40204",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40205",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40206",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40207",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40208",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40209",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40210",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40211",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40212",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40213",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40214",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40215",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40216",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40217",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40218",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40219",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40220",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40221",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40222",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40223",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40224",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40225",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40228",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40229",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40231",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40232",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40233",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40241",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40242",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40243",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40245",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40250",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40251",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40252",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40253",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40255",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40256",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40257",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40258",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40259",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40261",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40266",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40268",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40269",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40270",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40272",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40280",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40281",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40282",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40283",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40285",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40287",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40289",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40290",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40291",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40292",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40293",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40294",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40295",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40296",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40297",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40298",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40299",
    city: "Louisville",
    state: "KY",
    areacode: 502,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40310",
    city: "Burgin",
    state: "KY",
    areacode: 859,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40311",
    city: "Carlisle",
    state: "KY",
    areacode: 859,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40312",
    city: "Clay City",
    state: "KY",
    areacode: 606,
    county: "Powell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40313",
    city: "Clearfield",
    state: "KY",
    areacode: 606,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40316",
    city: "Denniston",
    state: "KY",
    areacode: 606,
    county: "Menifee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40317",
    city: "Elliottville",
    state: "KY",
    areacode: 606,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40319",
    city: "Farmers",
    state: "KY",
    areacode: 606,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40322",
    city: "Frenchburg",
    state: "KY",
    areacode: 606,
    county: "Menifee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40324",
    city: "Georgetown",
    state: "KY",
    areacode: 502,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40328",
    city: "Gravel Switch",
    state: "KY",
    areacode: 270,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40330",
    city: "Harrodsburg",
    state: "KY",
    areacode: 859,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40334",
    city: "Hope",
    state: "KY",
    areacode: 859,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40336",
    city: "Irvine",
    state: "KY",
    areacode: 606,
    county: "Estill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40337",
    city: "Jeffersonville",
    state: "KY",
    areacode: 859,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40339",
    city: "Keene",
    state: "KY",
    areacode: 859,
    county: "Jessamine",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40340",
    city: "Nicholasville",
    state: "KY",
    areacode: 859,
    county: "Jessamine",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40342",
    city: "Lawrenceburg",
    state: "KY",
    areacode: 502,
    county: "Anderson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40346",
    city: "Means",
    state: "KY",
    areacode: 606,
    county: "Menifee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40347",
    city: "Midway",
    state: "KY",
    areacode: 859,
    county: "Woodford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40348",
    city: "Millersburg",
    state: "KY",
    areacode: 859,
    county: "Bourbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40350",
    city: "Moorefield",
    state: "KY",
    areacode: 859,
    county: "Nicholas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40351",
    city: "Morehead",
    state: "KY",
    areacode: 606,
    county: "Rowan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40353",
    city: "Mount Sterling",
    state: "KY",
    areacode: 859,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40355",
    city: "New Liberty",
    state: "KY",
    areacode: 502,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40356",
    city: "Nicholasville",
    state: "KY",
    areacode: 859,
    county: "Jessamine",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40357",
    city: "North Middletown",
    state: "KY",
    areacode: 859,
    county: "Bourbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40358",
    city: "Olympia",
    state: "KY",
    areacode: 606,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40359",
    city: "Owenton",
    state: "KY",
    areacode: 502,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40360",
    city: "Owingsville",
    state: "KY",
    areacode: 606,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40361",
    city: "Paris",
    state: "KY",
    areacode: 859,
    county: "Bourbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40362",
    city: "Paris",
    state: "KY",
    areacode: 859,
    county: "Bourbon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40363",
    city: "Perry Park",
    state: "KY",
    areacode: 502,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40370",
    city: "Sadieville",
    state: "KY",
    areacode: 502,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40371",
    city: "Salt Lick",
    state: "KY",
    areacode: 606,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40372",
    city: "Salvisa",
    state: "KY",
    areacode: 859,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40374",
    city: "Sharpsburg",
    state: "KY",
    areacode: 606,
    county: "Bath",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40376",
    city: "Slade",
    state: "KY",
    areacode: 606,
    county: "Powell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40379",
    city: "Stamping Ground",
    state: "KY",
    areacode: 502,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40380",
    city: "Stanton",
    state: "KY",
    areacode: 606,
    county: "Powell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40383",
    city: "Versailles",
    state: "KY",
    areacode: 859,
    county: "Woodford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40384",
    city: "Versailles",
    state: "KY",
    areacode: 859,
    county: "Woodford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40385",
    city: "Waco",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40387",
    city: "Wellington",
    state: "KY",
    areacode: 606,
    county: "Menifee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40390",
    city: "Wilmore",
    state: "KY",
    areacode: 859,
    county: "Jessamine",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40391",
    city: "Winchester",
    state: "KY",
    areacode: 859,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40392",
    city: "Winchester",
    state: "KY",
    areacode: 859,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40402",
    city: "Annville",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40403",
    city: "Berea",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40404",
    city: "Berea",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40405",
    city: "Bighill",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40409",
    city: "Brodhead",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40410",
    city: "Bryantsville",
    state: "KY",
    areacode: 859,
    county: "Garrard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40419",
    city: "Crab Orchard",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40422",
    city: "Danville",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40423",
    city: "Danville",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40434",
    city: "Gray Hawk",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40437",
    city: "Hustonville",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40440",
    city: "Junction City",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40442",
    city: "Kings Mountain",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40444",
    city: "Lancaster",
    state: "KY",
    areacode: 859,
    county: "Garrard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40445",
    city: "Livingston",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40447",
    city: "McKee",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40448",
    city: "McKinney",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40452",
    city: "Mitchellsburg",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40456",
    city: "Mount Vernon",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40460",
    city: "Orlando",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40461",
    city: "Paint Lick",
    state: "KY",
    areacode: 859,
    county: "Garrard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40464",
    city: "Parksville",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40468",
    city: "Perryville",
    state: "KY",
    areacode: 859,
    county: "Boyle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40472",
    city: "Ravenna",
    state: "KY",
    areacode: 606,
    county: "Estill",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40473",
    city: "Renfro Valley",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40475",
    city: "Richmond",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40476",
    city: "Richmond",
    state: "KY",
    areacode: 859,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40481",
    city: "Sandgap",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40484",
    city: "Stanford",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40486",
    city: "Tyner",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40488",
    city: "Waneta",
    state: "KY",
    areacode: 606,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40489",
    city: "Waynesburg",
    state: "KY",
    areacode: 606,
    county: "Lincoln",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40492",
    city: "Wildie",
    state: "KY",
    areacode: 606,
    county: "Rockcastle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40502",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40503",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40504",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40505",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40506",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40507",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40508",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40509",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40510",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40511",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40512",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40513",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40514",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40515",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40516",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40517",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40522",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40523",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40524",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40526",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40533",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40536",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40544",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40546",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40550",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40555",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40574",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40575",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40576",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40577",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40578",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40579",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40580",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40581",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40582",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40583",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40588",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40591",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40598",
    city: "Lexington",
    state: "KY",
    areacode: 859,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40601",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40602",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40603",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40604",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40618",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40619",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40620",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40621",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40622",
    city: "Frankfort",
    state: "KY",
    areacode: 502,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40701",
    city: "Corbin",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40702",
    city: "Corbin",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40724",
    city: "Bush",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40729",
    city: "East Bernstadt",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40730",
    city: "Emlyn",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40734",
    city: "Gray",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40737",
    city: "Keavy",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40740",
    city: "Lily",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40741",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40742",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40743",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40744",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40745",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40750",
    city: "London",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40755",
    city: "Pittsburg",
    state: "KY",
    areacode: 606,
    county: "Laurel",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40759",
    city: "Rockholds",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40763",
    city: "Siler",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40769",
    city: "Williamsburg",
    state: "KY",
    areacode: 606,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40771",
    city: "Woodbine",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40801",
    city: "Ages Brookside",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40803",
    city: "Asher",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40806",
    city: "Baxter",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40807",
    city: "Benham",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40808",
    city: "Big Laurel",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40810",
    city: "Bledsoe",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40813",
    city: "Calvin",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40815",
    city: "Cawood",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40816",
    city: "Chappell",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40818",
    city: "Coalgood",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40819",
    city: "Coldiron",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40820",
    city: "Cranks",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40823",
    city: "Cumberland",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40824",
    city: "Dayhoit",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40826",
    city: "Eolia",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40827",
    city: "Essie",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40828",
    city: "Evarts",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40829",
    city: "Grays Knob",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40830",
    city: "Gulston",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40831",
    city: "Harlan",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40840",
    city: "Helton",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40843",
    city: "Holmes Mill",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40844",
    city: "Hoskinston",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40845",
    city: "Hulen",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40847",
    city: "Kenvir",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40849",
    city: "Lejunior",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40854",
    city: "Loyall",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40855",
    city: "Lynch",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40856",
    city: "Miracle",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40858",
    city: "Mozelle",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40862",
    city: "Partridge",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40863",
    city: "Pathfork",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40865",
    city: "Putney",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40868",
    city: "Stinnett",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40870",
    city: "Totz",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40873",
    city: "Wallins Creek",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40874",
    city: "Warbranch",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40902",
    city: "Arjay",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40903",
    city: "Artemus",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40906",
    city: "Barbourville",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40913",
    city: "Beverly",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40914",
    city: "Big Creek",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40915",
    city: "Bimble",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40921",
    city: "Bryants Store",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40923",
    city: "Cannon",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40927",
    city: "Closplint",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40930",
    city: "Dewitt",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40932",
    city: "Fall Rock",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40935",
    city: "Flat Lick",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40939",
    city: "Fourmile",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40940",
    city: "Frakes",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40941",
    city: "Garrard",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40943",
    city: "Girdler",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40944",
    city: "Goose Rock",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40946",
    city: "Green Road",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40949",
    city: "Heidrick",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40951",
    city: "Hima",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40953",
    city: "Hinkle",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40955",
    city: "Ingram",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40958",
    city: "Kettle Island",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40962",
    city: "Manchester",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40964",
    city: "Mary Alice",
    state: "KY",
    areacode: 606,
    county: "Harlan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40965",
    city: "Middlesboro",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40972",
    city: "Oneida",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40977",
    city: "Pineville",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40979",
    city: "Roark",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40981",
    city: "Saul",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40982",
    city: "Scalf",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40983",
    city: "Sextons Creek",
    state: "KY",
    areacode: 606,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40988",
    city: "Stoney Fork",
    state: "KY",
    areacode: 606,
    county: "Bell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40995",
    city: "Trosper",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "40997",
    city: "Walker",
    state: "KY",
    areacode: 606,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41001",
    city: "Alexandria",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41002",
    city: "Augusta",
    state: "KY",
    areacode: 606,
    county: "Bracken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41003",
    city: "Berry",
    state: "KY",
    areacode: 859,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41004",
    city: "Brooksville",
    state: "KY",
    areacode: 606,
    county: "Bracken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41005",
    city: "Burlington",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41006",
    city: "Butler",
    state: "KY",
    areacode: 859,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41007",
    city: "California",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41008",
    city: "Carrollton",
    state: "KY",
    areacode: 502,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41010",
    city: "Corinth",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41011",
    city: "Covington",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41012",
    city: "Covington",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41014",
    city: "Covington",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41015",
    city: "Latonia",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41016",
    city: "Covington",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41017",
    city: "Fort Mitchell",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41018",
    city: "Erlanger",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41019",
    city: "Covington",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41021",
    city: "Hebron",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41022",
    city: "Florence",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41025",
    city: "Erlanger",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41030",
    city: "Crittenden",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41031",
    city: "Cynthiana",
    state: "KY",
    areacode: 859,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41033",
    city: "De Mossville",
    state: "KY",
    areacode: 859,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41034",
    city: "Dover",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41035",
    city: "Dry Ridge",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41037",
    city: "Elizaville",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41039",
    city: "Ewing",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41040",
    city: "Falmouth",
    state: "KY",
    areacode: 859,
    county: "Pendleton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41041",
    city: "Flemingsburg",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41042",
    city: "Florence",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41043",
    city: "Foster",
    state: "KY",
    areacode: 606,
    county: "Bracken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41044",
    city: "Germantown",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41045",
    city: "Ghent",
    state: "KY",
    areacode: 502,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41046",
    city: "Glencoe",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41048",
    city: "Hebron",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41049",
    city: "Hillsboro",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41051",
    city: "Independence",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41052",
    city: "Jonesville",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41053",
    city: "Kenton",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41054",
    city: "Mason",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41055",
    city: "Mayslick",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41056",
    city: "Maysville",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41059",
    city: "Melbourne",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41061",
    city: "Milford",
    state: "KY",
    areacode: 606,
    county: "Bracken",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41062",
    city: "Minerva",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41063",
    city: "Morning View",
    state: "KY",
    areacode: 859,
    county: "Kenton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41064",
    city: "Mount Olivet",
    state: "KY",
    areacode: 606,
    county: "Robertson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41071",
    city: "Newport",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41072",
    city: "Newport",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41073",
    city: "Bellevue",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41074",
    city: "Dayton",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41075",
    city: "Fort Thomas",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41076",
    city: "Newport",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41080",
    city: "Petersburg",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41081",
    city: "Plummers Landing",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41083",
    city: "Sanders",
    state: "KY",
    areacode: 502,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41085",
    city: "Silver Grove",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41086",
    city: "Sparta",
    state: "KY",
    areacode: 859,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41091",
    city: "Union",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41092",
    city: "Verona",
    state: "KY",
    areacode: 859,
    county: "Gallatin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41093",
    city: "Wallingford",
    state: "KY",
    areacode: 606,
    county: "Fleming",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41094",
    city: "Walton",
    state: "KY",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41095",
    city: "Warsaw",
    state: "KY",
    areacode: 859,
    county: "Gallatin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41096",
    city: "Washington",
    state: "KY",
    areacode: 606,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41097",
    city: "Williamstown",
    state: "KY",
    areacode: 859,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41098",
    city: "Worthville",
    state: "KY",
    areacode: 502,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41099",
    city: "Newport",
    state: "KY",
    areacode: 859,
    county: "Campbell",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41101",
    city: "Ashland",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41102",
    city: "Ashland",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41105",
    city: "Ashland",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41114",
    city: "Ashland",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41121",
    city: "Argillite",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41124",
    city: "Blaine",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41128",
    city: "Carter",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41129",
    city: "Catlettsburg",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41132",
    city: "Denton",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41135",
    city: "Emerson",
    state: "KY",
    areacode: 606,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41139",
    city: "Flatwoods",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41141",
    city: "Garrison",
    state: "KY",
    areacode: 606,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41142",
    city: "Grahn",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41143",
    city: "Grayson",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41144",
    city: "Greenup",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41146",
    city: "Hitchins",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41149",
    city: "Isonville",
    state: "KY",
    areacode: 606,
    county: "Elliott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41159",
    city: "Martha",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41160",
    city: "Mazie",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41164",
    city: "Olive Hill",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41166",
    city: "Quincy",
    state: "KY",
    areacode: 606,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41168",
    city: "Rush",
    state: "KY",
    areacode: 606,
    county: "Boyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41169",
    city: "Russell",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41171",
    city: "Sandy Hook",
    state: "KY",
    areacode: 606,
    county: "Elliott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41173",
    city: "Soldier",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41174",
    city: "South Portsmouth",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41175",
    city: "South Shore",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41179",
    city: "Vanceburg",
    state: "KY",
    areacode: 606,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41180",
    city: "Webbville",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41181",
    city: "Willard",
    state: "KY",
    areacode: 606,
    county: "Carter",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41183",
    city: "Worthington",
    state: "KY",
    areacode: 606,
    county: "Greenup",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41189",
    city: "Tollesboro",
    state: "KY",
    areacode: 606,
    county: "Lewis",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41201",
    city: "Adams",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41203",
    city: "Beauty",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41204",
    city: "Boons Camp",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41214",
    city: "Debord",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41216",
    city: "East Point",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41219",
    city: "Flatgap",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41222",
    city: "Hagerhill",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41224",
    city: "Inez",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41226",
    city: "Keaton",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41230",
    city: "Louisa",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41231",
    city: "Lovely",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41232",
    city: "Lowmansville",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41234",
    city: "Meally",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41238",
    city: "Oil Springs",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41240",
    city: "Paintsville",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41250",
    city: "Pilgrim",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41254",
    city: "River",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41255",
    city: "Sitka",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41256",
    city: "Staffordsville",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41257",
    city: "Stambaugh",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41260",
    city: "Thelma",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41262",
    city: "Tomahawk",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41263",
    city: "Tutor Key",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41264",
    city: "Ulysses",
    state: "KY",
    areacode: 606,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41265",
    city: "Van Lear",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41267",
    city: "Warfield",
    state: "KY",
    areacode: 606,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41268",
    city: "West Van Lear",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41271",
    city: "Williamsport",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41274",
    city: "Wittensville",
    state: "KY",
    areacode: 606,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41301",
    city: "Campton",
    state: "KY",
    areacode: 606,
    county: "Wolfe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41310",
    city: "Bays",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41311",
    city: "Beattyville",
    state: "KY",
    areacode: 606,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41314",
    city: "Booneville",
    state: "KY",
    areacode: 606,
    county: "Owsley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41317",
    city: "Clayhole",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41332",
    city: "Hazel Green",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41339",
    city: "Jackson",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41347",
    city: "Lone",
    state: "KY",
    areacode: 606,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41348",
    city: "Lost Creek",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41351",
    city: "Mistletoe",
    state: "KY",
    areacode: 606,
    county: "Owsley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41352",
    city: "Mize",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41360",
    city: "Pine Ridge",
    state: "KY",
    areacode: 606,
    county: "Wolfe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41364",
    city: "Ricetown",
    state: "KY",
    areacode: 606,
    county: "Owsley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41365",
    city: "Rogers",
    state: "KY",
    areacode: 606,
    county: "Wolfe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41366",
    city: "Rousseau",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41367",
    city: "Rowdy",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41368",
    city: "Saint Helens",
    state: "KY",
    areacode: 606,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41385",
    city: "Vancleve",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41386",
    city: "Vincent",
    state: "KY",
    areacode: 606,
    county: "Owsley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41390",
    city: "Whick",
    state: "KY",
    areacode: 606,
    county: "Breathitt",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41397",
    city: "Zoe",
    state: "KY",
    areacode: 606,
    county: "Lee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41408",
    city: "Cannel City",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41413",
    city: "Crockett",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41421",
    city: "Elkfork",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41425",
    city: "Ezel",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41426",
    city: "Falcon",
    state: "KY",
    areacode: 606,
    county: "Magoffin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41451",
    city: "Malone",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41464",
    city: "Royalton",
    state: "KY",
    areacode: 606,
    county: "Magoffin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41465",
    city: "Salyersville",
    state: "KY",
    areacode: 606,
    county: "Magoffin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41472",
    city: "West Liberty",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41477",
    city: "Wrigley",
    state: "KY",
    areacode: 606,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41501",
    city: "Pikeville",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41502",
    city: "Pikeville",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41503",
    city: "South Williamson",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41512",
    city: "Ashcamp",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41513",
    city: "Belcher",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41514",
    city: "Belfry",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41517",
    city: "Burdine",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41519",
    city: "Canada",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41520",
    city: "Dorton",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41522",
    city: "Elkhorn City",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41524",
    city: "Fedscreek",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41526",
    city: "Fords Branch",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41527",
    city: "Forest Hills",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41528",
    city: "Freeburn",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41531",
    city: "Hardy",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41534",
    city: "Hellier",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41535",
    city: "Huddy",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41537",
    city: "Jenkins",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41538",
    city: "Jonancy",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41539",
    city: "Kimper",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41540",
    city: "Lick Creek",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41542",
    city: "Lookout",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41543",
    city: "McAndrews",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41544",
    city: "McCarr",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41547",
    city: "Majestic",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41548",
    city: "Mouthcard",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41549",
    city: "Myra",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41553",
    city: "Phelps",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41554",
    city: "Phyllis",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41555",
    city: "Pinsonfork",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41557",
    city: "Raccoon",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41558",
    city: "Ransom",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41559",
    city: "Regina",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41560",
    city: "Robinson Creek",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41561",
    city: "Rockhouse",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41562",
    city: "Shelbiana",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41563",
    city: "Shelby Gap",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41564",
    city: "Sidney",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41566",
    city: "Steele",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41567",
    city: "Stone",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41568",
    city: "Stopover",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41571",
    city: "Varney",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41572",
    city: "Virgie",
    state: "KY",
    areacode: 606,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41601",
    city: "Allen",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41602",
    city: "Auxier",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41603",
    city: "Banner",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41604",
    city: "Beaver",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41605",
    city: "Betsy Layne",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41606",
    city: "Bevinsville",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41607",
    city: "Blue River",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41612",
    city: "Bypro",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41615",
    city: "Dana",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41616",
    city: "David",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41619",
    city: "Drift",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41621",
    city: "Dwale",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41622",
    city: "Eastern",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41630",
    city: "Garrett",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41631",
    city: "Grethel",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41632",
    city: "Gunlock",
    state: "KY",
    areacode: 606,
    county: "Magoffin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41635",
    city: "Harold",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41636",
    city: "Hi Hat",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41640",
    city: "Hueysville",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41642",
    city: "Ivel",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41643",
    city: "Lackey",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41645",
    city: "Langley",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41647",
    city: "McDowell",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41649",
    city: "Martin",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41650",
    city: "Melvin",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41651",
    city: "Minnie",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41653",
    city: "Prestonsburg",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41655",
    city: "Printer",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41659",
    city: "Stanville",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41660",
    city: "Teaberry",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41663",
    city: "Tram",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41666",
    city: "Wayland",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41667",
    city: "Weeksbury",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41669",
    city: "Wheelwright",
    state: "KY",
    areacode: 606,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41701",
    city: "Hazard",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41702",
    city: "Hazard",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41712",
    city: "Ary",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41713",
    city: "Avawam",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41714",
    city: "Bear Branch",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41719",
    city: "Bonnyman",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41721",
    city: "Buckhorn",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41722",
    city: "Bulan",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41723",
    city: "Busy",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41725",
    city: "Carrie",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41727",
    city: "Chavies",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41729",
    city: "Combs",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41731",
    city: "Cornettsville",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41735",
    city: "Delphia",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41736",
    city: "Dice",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41739",
    city: "Dwarf",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41740",
    city: "Emmalena",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41743",
    city: "Fisty",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41745",
    city: "Gays Creek",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41746",
    city: "Happy",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41749",
    city: "Hyden",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41751",
    city: "Jeff",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41754",
    city: "Krypton",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41759",
    city: "Sassafras",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41760",
    city: "Scuddy",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41762",
    city: "Sizerock",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41763",
    city: "Slemp",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41764",
    city: "Smilax",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41766",
    city: "Thousandsticks",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41772",
    city: "Vest",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41773",
    city: "Vicco",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41774",
    city: "Viper",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41775",
    city: "Wendover",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41776",
    city: "Wooton",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41777",
    city: "Yeaddiss",
    state: "KY",
    areacode: 606,
    county: "Leslie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41778",
    city: "Yerkes",
    state: "KY",
    areacode: 606,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41804",
    city: "Blackey",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41810",
    city: "Cromona",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41812",
    city: "Deane",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41815",
    city: "Ermine",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41817",
    city: "Garner",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41819",
    city: "Gordon",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41821",
    city: "Hallie",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41822",
    city: "Hindman",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41824",
    city: "Isom",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41825",
    city: "Jackhorn",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41826",
    city: "Jeremiah",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41828",
    city: "Kite",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41831",
    city: "Leburn",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41832",
    city: "Letcher",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41833",
    city: "Linefork",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41834",
    city: "Littcarr",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41835",
    city: "McRoberts",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41836",
    city: "Mallie",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41837",
    city: "Mayking",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41838",
    city: "Millstone",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41839",
    city: "Mousie",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41840",
    city: "Neon",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41843",
    city: "Pine Top",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41844",
    city: "Pippa Passes",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41845",
    city: "Premium",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41847",
    city: "Redfox",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41848",
    city: "Roxana",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41849",
    city: "Seco",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41855",
    city: "Thornton",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41858",
    city: "Whitesburg",
    state: "KY",
    areacode: 606,
    county: "Letcher",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41859",
    city: "Dema",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41861",
    city: "Raven",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "41862",
    city: "Topmost",
    state: "KY",
    areacode: 606,
    county: "Knott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42001",
    city: "Paducah",
    state: "KY",
    areacode: 270,
    county: "McCracken",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42002",
    city: "Paducah",
    state: "KY",
    areacode: 270,
    county: "McCracken",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42003",
    city: "Paducah",
    state: "KY",
    areacode: 270,
    county: "McCracken",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42020",
    city: "Almo",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42021",
    city: "Arlington",
    state: "KY",
    areacode: 270,
    county: "Carlisle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42022",
    city: "Bandana",
    state: "KY",
    areacode: 270,
    county: "Ballard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42023",
    city: "Bardwell",
    state: "KY",
    areacode: 270,
    county: "Carlisle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42024",
    city: "Barlow",
    state: "KY",
    areacode: 270,
    county: "Ballard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42025",
    city: "Benton",
    state: "KY",
    areacode: 270,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42027",
    city: "Boaz",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42028",
    city: "Burna",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42029",
    city: "Calvert City",
    state: "KY",
    areacode: 270,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42031",
    city: "Clinton",
    state: "KY",
    areacode: 270,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42032",
    city: "Columbus",
    state: "KY",
    areacode: 270,
    county: "Hickman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42033",
    city: "Crayne",
    state: "KY",
    areacode: 270,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42035",
    city: "Cunningham",
    state: "KY",
    areacode: 270,
    county: "Carlisle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42036",
    city: "Dexter",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42037",
    city: "Dycusburg",
    state: "KY",
    areacode: 270,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42038",
    city: "Eddyville",
    state: "KY",
    areacode: 270,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42039",
    city: "Fancy Farm",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42040",
    city: "Farmington",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42041",
    city: "Fulton",
    state: "KY",
    areacode: 270,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42044",
    city: "Gilbertsville",
    state: "KY",
    areacode: 270,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42045",
    city: "Grand Rivers",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42047",
    city: "Hampton",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42048",
    city: "Hardin",
    state: "KY",
    areacode: 270,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42049",
    city: "Hazel",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42050",
    city: "Hickman",
    state: "KY",
    areacode: 270,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42051",
    city: "Hickory",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42053",
    city: "Kevil",
    state: "KY",
    areacode: 270,
    county: "McCracken",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42054",
    city: "Kirksey",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42055",
    city: "Kuttawa",
    state: "KY",
    areacode: 270,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42056",
    city: "La Center",
    state: "KY",
    areacode: 270,
    county: "Ballard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42058",
    city: "Ledbetter",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42060",
    city: "Lovelaceville",
    state: "KY",
    areacode: 270,
    county: "Ballard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42061",
    city: "Lowes",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42063",
    city: "Lynnville",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42064",
    city: "Marion",
    state: "KY",
    areacode: 270,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42066",
    city: "Mayfield",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42069",
    city: "Melber",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42070",
    city: "Milburn",
    state: "KY",
    areacode: 270,
    county: "Carlisle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42071",
    city: "Murray",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42076",
    city: "New Concord",
    state: "KY",
    areacode: 270,
    county: "Calloway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42078",
    city: "Salem",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42079",
    city: "Sedalia",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42081",
    city: "Smithland",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42082",
    city: "Symsonia",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42083",
    city: "Tiline",
    state: "KY",
    areacode: 270,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42085",
    city: "Water Valley",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42086",
    city: "West Paducah",
    state: "KY",
    areacode: 270,
    county: "McCracken",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42087",
    city: "Wickliffe",
    state: "KY",
    areacode: 270,
    county: "Ballard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42088",
    city: "Wingo",
    state: "KY",
    areacode: 270,
    county: "Graves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42101",
    city: "Bowling Green",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42102",
    city: "Bowling Green",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42103",
    city: "Bowling Green",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42104",
    city: "Bowling Green",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42120",
    city: "Adolphus",
    state: "KY",
    areacode: 270,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42122",
    city: "Alvaton",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42123",
    city: "Austin",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42124",
    city: "Beaumont",
    state: "KY",
    areacode: 270,
    county: "Metcalfe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42127",
    city: "Cave City",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42128",
    city: "Bowling Green",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42129",
    city: "Edmonton",
    state: "KY",
    areacode: 270,
    county: "Metcalfe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42130",
    city: "Eighty Eight",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42131",
    city: "Etoile",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42133",
    city: "Fountain Run",
    state: "KY",
    areacode: 270,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42134",
    city: "Franklin",
    state: "KY",
    areacode: 270,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42135",
    city: "Franklin",
    state: "KY",
    areacode: 270,
    county: "Simpson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42140",
    city: "Gamaliel",
    state: "KY",
    areacode: 270,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42141",
    city: "Glasgow",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42142",
    city: "Glasgow",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42151",
    city: "Hestand",
    state: "KY",
    areacode: 270,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42152",
    city: "Hiseville",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42153",
    city: "Holland",
    state: "KY",
    areacode: 270,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42154",
    city: "Knob Lick",
    state: "KY",
    areacode: 270,
    county: "Metcalfe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42156",
    city: "Lucas",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42157",
    city: "Mount Hermon",
    state: "KY",
    areacode: 270,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42159",
    city: "Oakland",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42160",
    city: "Park City",
    state: "KY",
    areacode: 270,
    county: "Barren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42163",
    city: "Rocky Hill",
    state: "KY",
    areacode: 270,
    county: "Edmonson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42164",
    city: "Scottsville",
    state: "KY",
    areacode: 270,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42166",
    city: "Summer Shade",
    state: "KY",
    areacode: 270,
    county: "Metcalfe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42167",
    city: "Tompkinsville",
    state: "KY",
    areacode: 270,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42170",
    city: "Woodburn",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42171",
    city: "Smiths Grove",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42201",
    city: "Aberdeen",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42202",
    city: "Adairville",
    state: "KY",
    areacode: 270,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42204",
    city: "Allensville",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42206",
    city: "Auburn",
    state: "KY",
    areacode: 270,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42207",
    city: "Bee Spring",
    state: "KY",
    areacode: 270,
    county: "Edmonson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42210",
    city: "Brownsville",
    state: "KY",
    areacode: 270,
    county: "Edmonson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42211",
    city: "Cadiz",
    state: "KY",
    areacode: 270,
    county: "Trigg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42214",
    city: "Center",
    state: "KY",
    areacode: 270,
    county: "Metcalfe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42215",
    city: "Cerulean",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42216",
    city: "Clifty",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42217",
    city: "Crofton",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42219",
    city: "Dunbar",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42220",
    city: "Elkton",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42221",
    city: "Fairview",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42223",
    city: "Fort Campbell",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42232",
    city: "Gracey",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42234",
    city: "Guthrie",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42236",
    city: "Herndon",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42240",
    city: "Hopkinsville",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42241",
    city: "Hopkinsville",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42252",
    city: "Jetson",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42254",
    city: "La Fayette",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42256",
    city: "Lewisburg",
    state: "KY",
    areacode: 270,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42259",
    city: "Mammoth Cave",
    state: "KY",
    areacode: 270,
    county: "Edmonson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42261",
    city: "Morgantown",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42262",
    city: "Oak Grove",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42265",
    city: "Olmstead",
    state: "KY",
    areacode: 270,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42266",
    city: "Pembroke",
    state: "KY",
    areacode: 270,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42273",
    city: "Rochester",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42274",
    city: "Rockfield",
    state: "KY",
    areacode: 270,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42275",
    city: "Roundhill",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42276",
    city: "Russellville",
    state: "KY",
    areacode: 270,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42280",
    city: "Sharon Grove",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42285",
    city: "Sweeden",
    state: "KY",
    areacode: 270,
    county: "Edmonson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42286",
    city: "Trenton",
    state: "KY",
    areacode: 270,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42288",
    city: "Woodbury",
    state: "KY",
    areacode: 270,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42301",
    city: "Owensboro",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42302",
    city: "Owensboro",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42303",
    city: "Owensboro",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42304",
    city: "Owensboro",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42320",
    city: "Beaver Dam",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42321",
    city: "Beech Creek",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42322",
    city: "Beech Grove",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42323",
    city: "Beechmont",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42324",
    city: "Belton",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42325",
    city: "Bremen",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42326",
    city: "Browder",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42327",
    city: "Calhoun",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42328",
    city: "Centertown",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42330",
    city: "Central City",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42332",
    city: "Cleaton",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42333",
    city: "Cromwell",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42334",
    city: "Curdsville",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42337",
    city: "Drakesboro",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42338",
    city: "Dundee",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42339",
    city: "Dunmor",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42343",
    city: "Fordsville",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42344",
    city: "Graham",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42345",
    city: "Greenville",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42347",
    city: "Hartford",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42348",
    city: "Hawesville",
    state: "KY",
    areacode: 270,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42349",
    city: "Horse Branch",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42350",
    city: "Island",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42351",
    city: "Lewisport",
    state: "KY",
    areacode: 270,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42352",
    city: "Livermore",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42354",
    city: "McHenry",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42355",
    city: "Maceo",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42356",
    city: "Maple Mount",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42361",
    city: "Olaton",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42364",
    city: "Hawesville",
    state: "KY",
    areacode: 270,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42366",
    city: "Philpot",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42367",
    city: "Powderly",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42368",
    city: "Reynolds Station",
    state: "KY",
    areacode: 270,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42369",
    city: "Rockport",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42370",
    city: "Rosine",
    state: "KY",
    areacode: 270,
    county: "Ohio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42371",
    city: "Rumsey",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42372",
    city: "Sacramento",
    state: "KY",
    areacode: 270,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42374",
    city: "South Carrollton",
    state: "KY",
    areacode: 270,
    county: "Muhlenberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42376",
    city: "Utica",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42377",
    city: "West Louisville",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42378",
    city: "Whitesville",
    state: "KY",
    areacode: 270,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42402",
    city: "Baskett",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42404",
    city: "Clay",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42406",
    city: "Corydon",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42408",
    city: "Dawson Springs",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42409",
    city: "Dixon",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42410",
    city: "Earlington",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42411",
    city: "Fredonia",
    state: "KY",
    areacode: 270,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42413",
    city: "Hanson",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42419",
    city: "Henderson",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42420",
    city: "Henderson",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42431",
    city: "Madisonville",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42436",
    city: "Manitou",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42437",
    city: "Morganfield",
    state: "KY",
    areacode: 270,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42440",
    city: "Mortons Gap",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42441",
    city: "Nebo",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42442",
    city: "Nortonville",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42444",
    city: "Poole",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42445",
    city: "Princeton",
    state: "KY",
    areacode: 270,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42450",
    city: "Providence",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42451",
    city: "Reed",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42452",
    city: "Robards",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42453",
    city: "Saint Charles",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42455",
    city: "Sebree",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42456",
    city: "Slaughters",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42457",
    city: "Smith Mills",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42458",
    city: "Spottsville",
    state: "KY",
    areacode: 270,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42459",
    city: "Sturgis",
    state: "KY",
    areacode: 270,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42460",
    city: "Sullivan",
    state: "KY",
    areacode: 270,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42461",
    city: "Uniontown",
    state: "KY",
    areacode: 270,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42462",
    city: "Waverly",
    state: "KY",
    areacode: 270,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42463",
    city: "Wheatcroft",
    state: "KY",
    areacode: 270,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42464",
    city: "White Plains",
    state: "KY",
    areacode: 270,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42501",
    city: "Somerset",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42502",
    city: "Somerset",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42503",
    city: "Somerset",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42516",
    city: "Bethelridge",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42518",
    city: "Bronston",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42519",
    city: "Burnside",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42528",
    city: "Dunnville",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42533",
    city: "Ferguson",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42539",
    city: "Liberty",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42541",
    city: "Middleburg",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42544",
    city: "Nancy",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42553",
    city: "Science Hill",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42558",
    city: "Tateville",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42564",
    city: "West Somerset",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42565",
    city: "Windsor",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42566",
    city: "Yosemite",
    state: "KY",
    areacode: 606,
    county: "Casey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42567",
    city: "Eubank",
    state: "KY",
    areacode: 606,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42602",
    city: "Albany",
    state: "KY",
    areacode: 606,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42603",
    city: "Alpha",
    state: "KY",
    areacode: 606,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42629",
    city: "Jamestown",
    state: "KY",
    areacode: 270,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42631",
    city: "Marshes Siding",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42633",
    city: "Monticello",
    state: "KY",
    areacode: 606,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42634",
    city: "Parkers Lake",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42635",
    city: "Pine Knot",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42638",
    city: "Revelo",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42642",
    city: "Russell Springs",
    state: "KY",
    areacode: 270,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42647",
    city: "Stearns",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42649",
    city: "Strunk",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42653",
    city: "Whitley City",
    state: "KY",
    areacode: 606,
    county: "McCreary",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42701",
    city: "Elizabethtown",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42702",
    city: "Elizabethtown",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42712",
    city: "Big Clifty",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42713",
    city: "Bonnieville",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42715",
    city: "Breeding",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42716",
    city: "Buffalo",
    state: "KY",
    areacode: 270,
    county: "Larue",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42717",
    city: "Burkesville",
    state: "KY",
    areacode: 270,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42718",
    city: "Campbellsville",
    state: "KY",
    areacode: 270,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42719",
    city: "Campbellsville",
    state: "KY",
    areacode: 270,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42720",
    city: "Cane Valley",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42721",
    city: "Caneyville",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42722",
    city: "Canmer",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42724",
    city: "Cecilia",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42726",
    city: "Clarkson",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42728",
    city: "Columbia",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42729",
    city: "Cub Run",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42732",
    city: "Eastview",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42733",
    city: "Elk Horn",
    state: "KY",
    areacode: 270,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42740",
    city: "Glendale",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42741",
    city: "Glens Fork",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42742",
    city: "Gradyville",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42743",
    city: "Greensburg",
    state: "KY",
    areacode: 270,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42746",
    city: "Hardyville",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42748",
    city: "Hodgenville",
    state: "KY",
    areacode: 270,
    county: "Larue",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42749",
    city: "Horse Cave",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42753",
    city: "Knifley",
    state: "KY",
    areacode: 270,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42754",
    city: "Leitchfield",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42755",
    city: "Leitchfield",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42757",
    city: "Magnolia",
    state: "KY",
    areacode: 270,
    county: "Larue",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42758",
    city: "Mannsville",
    state: "KY",
    areacode: 270,
    county: "Taylor",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42759",
    city: "Marrowbone",
    state: "KY",
    areacode: 270,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42762",
    city: "Millwood",
    state: "KY",
    areacode: 270,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42764",
    city: "Mount Sherman",
    state: "KY",
    areacode: 270,
    county: "Green",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42765",
    city: "Munfordville",
    state: "KY",
    areacode: 270,
    county: "Hart",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42776",
    city: "Sonora",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42782",
    city: "Summersville",
    state: "KY",
    areacode: 270,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "42784",
    city: "Upton",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "42788",
    city: "White Mills",
    state: "KY",
    areacode: 270,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43001",
    city: "Alexandria",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43002",
    city: "Amlin",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43003",
    city: "Ashley",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43004",
    city: "Blacklick",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43005",
    city: "Bladensburg",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43006",
    city: "Brinkhaven",
    state: "OH",
    areacode: 740,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43007",
    city: "Broadway",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43008",
    city: "Buckeye Lake",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43009",
    city: "Cable",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43010",
    city: "Catawba",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43011",
    city: "Centerburg",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43013",
    city: "Croton",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43014",
    city: "Danville",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43015",
    city: "Delaware",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43016",
    city: "Dublin",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43017",
    city: "Dublin",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43018",
    city: "Etna",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43019",
    city: "Fredericktown",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43021",
    city: "Galena",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43022",
    city: "Gambier",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43023",
    city: "Granville",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43025",
    city: "Hebron",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43026",
    city: "Hilliard",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43027",
    city: "Homer",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43028",
    city: "Howard",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43029",
    city: "Irwin",
    state: "OH",
    areacode: 937,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43030",
    city: "Jacksontown",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43031",
    city: "Johnstown",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43032",
    city: "Kilbourne",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43033",
    city: "Kirkersville",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43035",
    city: "Lewis Center",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43036",
    city: "Magnetic Springs",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43037",
    city: "Martinsburg",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43040",
    city: "Marysville",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43041",
    city: "Marysville",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43044",
    city: "Mechanicsburg",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43045",
    city: "Milford Center",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43046",
    city: "Millersport",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43047",
    city: "Mingo",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43048",
    city: "Mount Liberty",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43050",
    city: "Mount Vernon",
    state: "OH",
    areacode: 740,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43054",
    city: "New Albany",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43055",
    city: "Newark",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43056",
    city: "Heath",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43058",
    city: "Newark",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43060",
    city: "North Lewisburg",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43061",
    city: "Ostrander",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43062",
    city: "Pataskala",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43064",
    city: "Plain City",
    state: "OH",
    areacode: 614,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43065",
    city: "Powell",
    state: "OH",
    areacode: 614,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43066",
    city: "Radnor",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43067",
    city: "Raymond",
    state: "OH",
    areacode: 937,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43068",
    city: "Reynoldsburg",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43069",
    city: "Reynoldsburg",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43070",
    city: "Rosewood",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43071",
    city: "Saint Louisville",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43072",
    city: "Saint Paris",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43073",
    city: "Summit Station",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43074",
    city: "Sunbury",
    state: "OH",
    areacode: 740,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43076",
    city: "Thornville",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43077",
    city: "Unionville Center",
    state: "OH",
    areacode: 740,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43078",
    city: "Urbana",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43080",
    city: "Utica",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43081",
    city: "Westerville",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43082",
    city: "Westerville",
    state: "OH",
    areacode: 614,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43083",
    city: "Westville",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43084",
    city: "Woodstock",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43085",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43086",
    city: "Westerville",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43093",
    city: "Newark",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43101",
    city: "Adelphi",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43102",
    city: "Amanda",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43103",
    city: "Ashville",
    state: "OH",
    areacode: 740,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43105",
    city: "Baltimore",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43106",
    city: "Bloomingburg",
    state: "OH",
    areacode: 740,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43107",
    city: "Bremen",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43109",
    city: "Brice",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43110",
    city: "Canal Winchester",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43111",
    city: "Carbon Hill",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43112",
    city: "Carroll",
    state: "OH",
    areacode: 614,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43113",
    city: "Circleville",
    state: "OH",
    areacode: 740,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43115",
    city: "Clarksburg",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43116",
    city: "Commercial Point",
    state: "OH",
    areacode: 614,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43117",
    city: "Derby",
    state: "OH",
    areacode: 614,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43119",
    city: "Galloway",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43123",
    city: "Grove City",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43125",
    city: "Groveport",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43126",
    city: "Harrisburg",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43127",
    city: "Haydenville",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43128",
    city: "Jeffersonville",
    state: "OH",
    areacode: 740,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43130",
    city: "Lancaster",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43135",
    city: "Laurelville",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43136",
    city: "Lithopolis",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43137",
    city: "Lockbourne",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43138",
    city: "Logan",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43140",
    city: "London",
    state: "OH",
    areacode: 740,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43142",
    city: "Milledgeville",
    state: "OH",
    areacode: 740,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43143",
    city: "Mount Sterling",
    state: "OH",
    areacode: 740,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43144",
    city: "Murray City",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43145",
    city: "New Holland",
    state: "OH",
    areacode: 740,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43146",
    city: "Orient",
    state: "OH",
    areacode: 614,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43147",
    city: "Pickerington",
    state: "OH",
    areacode: 614,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43148",
    city: "Pleasantville",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43149",
    city: "Rockbridge",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43150",
    city: "Rushville",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43151",
    city: "Sedalia",
    state: "OH",
    areacode: 740,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43152",
    city: "South Bloomingville",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43153",
    city: "South Solon",
    state: "OH",
    areacode: 937,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43154",
    city: "Stoutsville",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43155",
    city: "Sugar Grove",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43156",
    city: "Tarlton",
    state: "OH",
    areacode: 740,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43157",
    city: "Thurston",
    state: "OH",
    areacode: 740,
    county: "Fairfield",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43158",
    city: "Union Furnace",
    state: "OH",
    areacode: 740,
    county: "Hocking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43160",
    city: "Washington Court House",
    state: "OH",
    areacode: 740,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43162",
    city: "West Jefferson",
    state: "OH",
    areacode: 740,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43164",
    city: "Williamsport",
    state: "OH",
    areacode: 740,
    county: "Pickaway",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43194",
    city: "Lockbourne",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43195",
    city: "Groveport",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43199",
    city: "Groveport",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43201",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43202",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43203",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43204",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43205",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43206",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43207",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43209",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43210",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43211",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43212",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43213",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43214",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43215",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43216",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43217",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43218",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43219",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43220",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43221",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43222",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43223",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43224",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43226",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43227",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43228",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43229",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43230",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43231",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43232",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43234",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43235",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43236",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43240",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43251",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43260",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43266",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43268",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43270",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43271",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43272",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43279",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43287",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43291",
    city: "Columbus",
    state: "OH",
    areacode: 614,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43301",
    city: "Marion",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43302",
    city: "Marion",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43310",
    city: "Belle Center",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43311",
    city: "Bellefontaine",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43314",
    city: "Caledonia",
    state: "OH",
    areacode: 419,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43315",
    city: "Cardington",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43316",
    city: "Carey",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43317",
    city: "Chesterville",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43318",
    city: "De Graff",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43319",
    city: "East Liberty",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43320",
    city: "Edison",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43321",
    city: "Fulton",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43322",
    city: "Green Camp",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43323",
    city: "Harpster",
    state: "OH",
    areacode: 740,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43324",
    city: "Huntsville",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43325",
    city: "Iberia",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43326",
    city: "Kenton",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43330",
    city: "Kirby",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43331",
    city: "Lakeview",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43332",
    city: "La Rue",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43333",
    city: "Lewistown",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43334",
    city: "Marengo",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43335",
    city: "Martel",
    state: "OH",
    areacode: 419,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43336",
    city: "Middleburg",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43337",
    city: "Morral",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43338",
    city: "Mount Gilead",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43340",
    city: "Mount Victory",
    state: "OH",
    areacode: 937,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43341",
    city: "New Bloomington",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43342",
    city: "Prospect",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43343",
    city: "Quincy",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43344",
    city: "Richwood",
    state: "OH",
    areacode: 740,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43345",
    city: "Ridgeway",
    state: "OH",
    areacode: 937,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43346",
    city: "Roundhead",
    state: "OH",
    areacode: 937,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43347",
    city: "Rushsylvania",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43348",
    city: "Russells Point",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43349",
    city: "Shauck",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43350",
    city: "Sparta",
    state: "OH",
    areacode: 419,
    county: "Morrow",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43351",
    city: "Upper Sandusky",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43356",
    city: "Waldo",
    state: "OH",
    areacode: 740,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43357",
    city: "West Liberty",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43358",
    city: "West Mansfield",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43359",
    city: "Wharton",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43360",
    city: "Zanesfield",
    state: "OH",
    areacode: 937,
    county: "Logan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43402",
    city: "Bowling Green",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43403",
    city: "Bowling Green",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43406",
    city: "Bradner",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43407",
    city: "Burgoon",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43408",
    city: "Clay Center",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43410",
    city: "Clyde",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43412",
    city: "Curtice",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43413",
    city: "Cygnet",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43414",
    city: "Dunbridge",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43416",
    city: "Elmore",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43420",
    city: "Fremont",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43430",
    city: "Genoa",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43431",
    city: "Gibsonburg",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43432",
    city: "Graytown",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43433",
    city: "Gypsum",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43434",
    city: "Harbor View",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43435",
    city: "Helena",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43436",
    city: "Isle Saint George",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43437",
    city: "Jerry City",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43438",
    city: "Kelleys Island",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43439",
    city: "Lacarne",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43440",
    city: "Lakeside Marblehead",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43441",
    city: "Lemoyne",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43442",
    city: "Lindsey",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43443",
    city: "Luckey",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43445",
    city: "Martin",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43446",
    city: "Middle Bass",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43447",
    city: "Millbury",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43449",
    city: "Oak Harbor",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43450",
    city: "Pemberville",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43451",
    city: "Portage",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43452",
    city: "Port Clinton",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43456",
    city: "Put in Bay",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43457",
    city: "Risingsun",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43458",
    city: "Rocky Ridge",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43460",
    city: "Rossford",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43462",
    city: "Rudolph",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43463",
    city: "Stony Ridge",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43464",
    city: "Vickery",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43465",
    city: "Walbridge",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43466",
    city: "Wayne",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43467",
    city: "West Millgrove",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43468",
    city: "Williston",
    state: "OH",
    areacode: 419,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43469",
    city: "Woodville",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43501",
    city: "Alvordton",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43502",
    city: "Archbold",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43504",
    city: "Berkey",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43505",
    city: "Blakeslee",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43506",
    city: "Bryan",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43510",
    city: "Colton",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43511",
    city: "Custar",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43512",
    city: "Defiance",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43515",
    city: "Delta",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43516",
    city: "Deshler",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43517",
    city: "Edgerton",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43518",
    city: "Edon",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43519",
    city: "Evansport",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43520",
    city: "Farmer",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43521",
    city: "Fayette",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43522",
    city: "Grand Rapids",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43523",
    city: "Grelton",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43524",
    city: "Hamler",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43525",
    city: "Haskins",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43526",
    city: "Hicksville",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43527",
    city: "Holgate",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43528",
    city: "Holland",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43529",
    city: "Hoytville",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43530",
    city: "Jewell",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43531",
    city: "Kunkle",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43532",
    city: "Liberty Center",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43533",
    city: "Lyons",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43534",
    city: "McClure",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43535",
    city: "Malinta",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43536",
    city: "Mark Center",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43537",
    city: "Maumee",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43540",
    city: "Metamora",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43541",
    city: "Milton Center",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43542",
    city: "Monclova",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43543",
    city: "Montpelier",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43545",
    city: "Napoleon",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43547",
    city: "Neapolis",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43548",
    city: "New Bavaria",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43549",
    city: "Ney",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43550",
    city: "Okolona",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43551",
    city: "Perrysburg",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43552",
    city: "Perrysburg",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43553",
    city: "Pettisville",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43554",
    city: "Pioneer",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43555",
    city: "Ridgeville Corners",
    state: "OH",
    areacode: 419,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43556",
    city: "Sherwood",
    state: "OH",
    areacode: 419,
    county: "Defiance",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43557",
    city: "Stryker",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43558",
    city: "Swanton",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43560",
    city: "Sylvania",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43565",
    city: "Tontogany",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43566",
    city: "Waterville",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43567",
    city: "Wauseon",
    state: "OH",
    areacode: 419,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43569",
    city: "Weston",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43570",
    city: "West Unity",
    state: "OH",
    areacode: 419,
    county: "Williams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43571",
    city: "Whitehouse",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43601",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43603",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43604",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43605",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43606",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43607",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43608",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43609",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43610",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43611",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43612",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43613",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43614",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43615",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43616",
    city: "Oregon",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43617",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43619",
    city: "Northwood",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43620",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43623",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43635",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43652",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43654",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43656",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43657",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43659",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43660",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43661",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43666",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43667",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43681",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43682",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43697",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43699",
    city: "Toledo",
    state: "OH",
    areacode: 419,
    county: "Lucas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43701",
    city: "Zanesville",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43702",
    city: "Zanesville",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43711",
    city: "Ava",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43713",
    city: "Barnesville",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43716",
    city: "Beallsville",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43717",
    city: "Belle Valley",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43718",
    city: "Belmont",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43719",
    city: "Bethesda",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43720",
    city: "Blue Rock",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43721",
    city: "Brownsville",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43722",
    city: "Buffalo",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43723",
    city: "Byesville",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43724",
    city: "Caldwell",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43725",
    city: "Cambridge",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43727",
    city: "Chandlersville",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43728",
    city: "Chesterhill",
    state: "OH",
    areacode: 740,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43730",
    city: "Corning",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43731",
    city: "Crooksville",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43732",
    city: "Cumberland",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43733",
    city: "Derwent",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43734",
    city: "Duncan Falls",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43735",
    city: "East Fultonham",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43736",
    city: "Fairview",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43738",
    city: "Fultonham",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43739",
    city: "Glenford",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43740",
    city: "Gratiot",
    state: "OH",
    areacode: 740,
    county: "Licking",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43746",
    city: "Hopewell",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43747",
    city: "Jerusalem",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43748",
    city: "Junction City",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43749",
    city: "Kimbolton",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43750",
    city: "Kipling",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43752",
    city: "Laings",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43754",
    city: "Lewisville",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43755",
    city: "Lore City",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43756",
    city: "McConnelsville",
    state: "OH",
    areacode: 740,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43757",
    city: "Malaga",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43758",
    city: "Malta",
    state: "OH",
    areacode: 740,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43759",
    city: "Morristown",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43760",
    city: "Mount Perry",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43761",
    city: "Moxahala",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43762",
    city: "New Concord",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43764",
    city: "New Lexington",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43766",
    city: "New Straitsville",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43767",
    city: "Norwich",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43768",
    city: "Old Washington",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43771",
    city: "Philo",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43772",
    city: "Pleasant City",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43773",
    city: "Quaker City",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43777",
    city: "Roseville",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43778",
    city: "Salesville",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43779",
    city: "Sarahsville",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43780",
    city: "Senecaville",
    state: "OH",
    areacode: 740,
    county: "Guernsey",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43782",
    city: "Shawnee",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43783",
    city: "Somerset",
    state: "OH",
    areacode: 740,
    county: "Perry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43786",
    city: "Stafford",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43787",
    city: "Stockport",
    state: "OH",
    areacode: 740,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43788",
    city: "Summerfield",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43791",
    city: "White Cottage",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43793",
    city: "Woodsfield",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43802",
    city: "Adamsville",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43803",
    city: "Bakersville",
    state: "OH",
    areacode: 330,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43804",
    city: "Baltic",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43805",
    city: "Blissfield",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43811",
    city: "Conesville",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43812",
    city: "Coshocton",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43821",
    city: "Dresden",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43822",
    city: "Frazeysburg",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43824",
    city: "Fresno",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43828",
    city: "Keene",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43830",
    city: "Nashport",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43832",
    city: "Newcomerstown",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43836",
    city: "Plainfield",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43837",
    city: "Port Washington",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43840",
    city: "Stone Creek",
    state: "OH",
    areacode: 330,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43842",
    city: "Trinway",
    state: "OH",
    areacode: 740,
    county: "Muskingum",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43843",
    city: "Walhonding",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43844",
    city: "Warsaw",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43845",
    city: "West Lafayette",
    state: "OH",
    areacode: 740,
    county: "Coshocton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43901",
    city: "Adena",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43902",
    city: "Alledonia",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43903",
    city: "Amsterdam",
    state: "OH",
    areacode: 740,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43905",
    city: "Barton",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43906",
    city: "Bellaire",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43907",
    city: "Cadiz",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43908",
    city: "Bergholz",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43909",
    city: "Blaine",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43910",
    city: "Bloomingdale",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43912",
    city: "Bridgeport",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43913",
    city: "Brilliant",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43914",
    city: "Cameron",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43915",
    city: "Clarington",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43916",
    city: "Colerain",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43917",
    city: "Dillonvale",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43920",
    city: "East Liverpool",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43925",
    city: "East Springfield",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43926",
    city: "Empire",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43927",
    city: "Fairpoint",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43928",
    city: "Glencoe",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43930",
    city: "Hammondsville",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43931",
    city: "Hannibal",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43932",
    city: "Irondale",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43933",
    city: "Jacobsburg",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43934",
    city: "Lansing",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43935",
    city: "Martins Ferry",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43937",
    city: "Maynard",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43938",
    city: "Mingo Junction",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43939",
    city: "Mount Pleasant",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43940",
    city: "Neffs",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43941",
    city: "Piney Fork",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43942",
    city: "Powhatan Point",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43943",
    city: "Rayland",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43944",
    city: "Richmond",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43945",
    city: "Salineville",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43946",
    city: "Sardis",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43947",
    city: "Shadyside",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43948",
    city: "Smithfield",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43950",
    city: "Saint Clairsville",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43951",
    city: "Lafferty",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43952",
    city: "Steubenville",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43953",
    city: "Steubenville",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43961",
    city: "Stratton",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43962",
    city: "Summitville",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43963",
    city: "Tiltonsville",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43964",
    city: "Toronto",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43967",
    city: "Warnock",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43968",
    city: "Wellsville",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43970",
    city: "Wolf Run",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43971",
    city: "Yorkville",
    state: "OH",
    areacode: 740,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43972",
    city: "Bannock",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43973",
    city: "Freeport",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43974",
    city: "Harrisville",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43976",
    city: "Hopedale",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43977",
    city: "Flushing",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43981",
    city: "New Athens",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43983",
    city: "Piedmont",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43984",
    city: "New Rumley",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43985",
    city: "Holloway",
    state: "OH",
    areacode: 740,
    county: "Belmont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43986",
    city: "Jewett",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "43988",
    city: "Scio",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44001",
    city: "Amherst",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44003",
    city: "Andover",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44004",
    city: "Ashtabula",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44005",
    city: "Ashtabula",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44010",
    city: "Austinburg",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44011",
    city: "Avon",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44012",
    city: "Avon Lake",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44017",
    city: "Berea",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44021",
    city: "Burton",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44022",
    city: "Chagrin Falls",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44023",
    city: "Chagrin Falls",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44024",
    city: "Chardon",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44026",
    city: "Chesterland",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44028",
    city: "Columbia Station",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44030",
    city: "Conneaut",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44032",
    city: "Dorset",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44033",
    city: "East Claridon",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44035",
    city: "Elyria",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44036",
    city: "Elyria",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44039",
    city: "North Ridgeville",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44040",
    city: "Gates Mills",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44041",
    city: "Geneva",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44044",
    city: "Grafton",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44045",
    city: "Grand River",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44046",
    city: "Huntsburg",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44047",
    city: "Jefferson",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44048",
    city: "Kingsville",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44049",
    city: "Kipton",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44050",
    city: "Lagrange",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44052",
    city: "Lorain",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44053",
    city: "Lorain",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44054",
    city: "Sheffield Lake",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44055",
    city: "Lorain",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44056",
    city: "Macedonia",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44057",
    city: "Madison",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44060",
    city: "Mentor",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44061",
    city: "Mentor",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44062",
    city: "Middlefield",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44064",
    city: "Montville",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44065",
    city: "Newbury",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44067",
    city: "Northfield",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44068",
    city: "North Kingsville",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44070",
    city: "North Olmsted",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44072",
    city: "Novelty",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44073",
    city: "Novelty",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44074",
    city: "Oberlin",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44076",
    city: "Orwell",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44077",
    city: "Painesville",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44080",
    city: "Parkman",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44081",
    city: "Perry",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44082",
    city: "Pierpont",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44084",
    city: "Rock Creek",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44085",
    city: "Rome",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44086",
    city: "Thompson",
    state: "OH",
    areacode: 440,
    county: "Geauga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44087",
    city: "Twinsburg",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44088",
    city: "Unionville",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44089",
    city: "Vermilion",
    state: "OH",
    areacode: 440,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44090",
    city: "Wellington",
    state: "OH",
    areacode: 440,
    county: "Lorain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44092",
    city: "Wickliffe",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44093",
    city: "Williamsfield",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44094",
    city: "Willoughby",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44095",
    city: "Eastlake",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44096",
    city: "Willoughby",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44097",
    city: "Eastlake",
    state: "OH",
    areacode: 440,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44099",
    city: "Windsor",
    state: "OH",
    areacode: 440,
    county: "Ashtabula",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44101",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44102",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44103",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44104",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44105",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44106",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44107",
    city: "Lakewood",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44108",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44109",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44110",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44111",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44112",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44113",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44114",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44115",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44116",
    city: "Rocky River",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44117",
    city: "Euclid",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44118",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44119",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44120",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44121",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44122",
    city: "Beachwood",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44123",
    city: "Euclid",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44124",
    city: "Cleveland",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44125",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44126",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44127",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44128",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44129",
    city: "Cleveland",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44130",
    city: "Cleveland",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44131",
    city: "Independence",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44132",
    city: "Euclid",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44133",
    city: "North Royalton",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44134",
    city: "Cleveland",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44135",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44136",
    city: "Strongsville",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44137",
    city: "Maple Heights",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44138",
    city: "Olmsted Falls",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44139",
    city: "Solon",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44140",
    city: "Bay Village",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44141",
    city: "Brecksville",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44142",
    city: "Brook Park",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44143",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44144",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44145",
    city: "Westlake",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44146",
    city: "Bedford",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44147",
    city: "Broadview Heights",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44149",
    city: "Strongsville",
    state: "OH",
    areacode: 440,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44181",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44188",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44190",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44191",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44192",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44193",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44194",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44195",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44197",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44198",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44199",
    city: "Cleveland",
    state: "OH",
    areacode: 216,
    county: "Cuyahoga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44201",
    city: "Atwater",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44202",
    city: "Aurora",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44203",
    city: "Barberton",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44210",
    city: "Bath",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44211",
    city: "Brady Lake",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44212",
    city: "Brunswick",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44214",
    city: "Burbank",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44215",
    city: "Chippewa Lake",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44216",
    city: "Clinton",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44217",
    city: "Creston",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44221",
    city: "Cuyahoga Falls",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44222",
    city: "Cuyahoga Falls",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44223",
    city: "Cuyahoga Falls",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44224",
    city: "Stow",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44230",
    city: "Doylestown",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44231",
    city: "Garrettsville",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44232",
    city: "Green",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44233",
    city: "Hinckley",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44234",
    city: "Hiram",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44235",
    city: "Homerville",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44236",
    city: "Hudson",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44237",
    city: "Hudson",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44240",
    city: "Kent",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44241",
    city: "Streetsboro",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44242",
    city: "Kent",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44243",
    city: "Kent",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44250",
    city: "Lakemore",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44251",
    city: "Westfield Center",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44253",
    city: "Litchfield",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44254",
    city: "Lodi",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44255",
    city: "Mantua",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44256",
    city: "Medina",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44258",
    city: "Medina",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44260",
    city: "Mogadore",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44262",
    city: "Munroe Falls",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44264",
    city: "Peninsula",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44265",
    city: "Randolph",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44266",
    city: "Ravenna",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44270",
    city: "Rittman",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44272",
    city: "Rootstown",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44273",
    city: "Seville",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44274",
    city: "Sharon Center",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44275",
    city: "Spencer",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44276",
    city: "Sterling",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44278",
    city: "Tallmadge",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44280",
    city: "Valley City",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44281",
    city: "Wadsworth",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44282",
    city: "Wadsworth",
    state: "OH",
    areacode: 330,
    county: "Medina",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44285",
    city: "Wayland",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44286",
    city: "Richfield",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44287",
    city: "West Salem",
    state: "OH",
    areacode: 419,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44288",
    city: "Windham",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44301",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44302",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44303",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44304",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44305",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44306",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44307",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44308",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44309",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44310",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44311",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44312",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44313",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44314",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44315",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44316",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44317",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44319",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44320",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44321",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44325",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44326",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44328",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44333",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44334",
    city: "Fairlawn",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44372",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44396",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44398",
    city: "Akron",
    state: "OH",
    areacode: 330,
    county: "Summit",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44401",
    city: "Berlin Center",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44402",
    city: "Bristolville",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44403",
    city: "Brookfield",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44404",
    city: "Burghill",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44405",
    city: "Campbell",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44406",
    city: "Canfield",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44408",
    city: "Columbiana",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44410",
    city: "Cortland",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44411",
    city: "Deerfield",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44412",
    city: "Diamond",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44413",
    city: "East Palestine",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44415",
    city: "Elkton",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44416",
    city: "Ellsworth",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44417",
    city: "Farmdale",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44418",
    city: "Fowler",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44420",
    city: "Girard",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44422",
    city: "Greenford",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44423",
    city: "Hanoverton",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44424",
    city: "Hartford",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44425",
    city: "Hubbard",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44427",
    city: "Kensington",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44428",
    city: "Kinsman",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44429",
    city: "Lake Milton",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44430",
    city: "Leavittsburg",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44431",
    city: "Leetonia",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44432",
    city: "Lisbon",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44436",
    city: "Lowellville",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44437",
    city: "McDonald",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44438",
    city: "Masury",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44439",
    city: "Mesopotamia",
    state: "OH",
    areacode: 440,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44440",
    city: "Mineral Ridge",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44441",
    city: "Negley",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44442",
    city: "New Middletown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44443",
    city: "New Springfield",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44444",
    city: "Newton Falls",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44445",
    city: "New Waterford",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44446",
    city: "Niles",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44449",
    city: "North Benton",
    state: "OH",
    areacode: 330,
    county: "Portage",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44450",
    city: "North Bloomfield",
    state: "OH",
    areacode: 440,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44451",
    city: "North Jackson",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44452",
    city: "North Lima",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44453",
    city: "Orangeville",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44454",
    city: "Petersburg",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44455",
    city: "Rogers",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44460",
    city: "Salem",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44470",
    city: "Southington",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44471",
    city: "Struthers",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44473",
    city: "Vienna",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44481",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44482",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44483",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44484",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44485",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44486",
    city: "Warren",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44490",
    city: "Washingtonville",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44491",
    city: "West Farmington",
    state: "OH",
    areacode: 330,
    county: "Trumbull",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44492",
    city: "West Point",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44493",
    city: "Winona",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44501",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44502",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44503",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44504",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44505",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44506",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44507",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44509",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44510",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44511",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44512",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44513",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44514",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44515",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44555",
    city: "Youngstown",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44601",
    city: "Alliance",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44606",
    city: "Apple Creek",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44607",
    city: "Augusta",
    state: "OH",
    areacode: 330,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44608",
    city: "Beach City",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44609",
    city: "Beloit",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44610",
    city: "Berlin",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44611",
    city: "Big Prairie",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44612",
    city: "Bolivar",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44613",
    city: "Brewster",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44614",
    city: "Canal Fulton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44615",
    city: "Carrollton",
    state: "OH",
    areacode: 330,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44617",
    city: "Charm",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44618",
    city: "Dalton",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44619",
    city: "Damascus",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44620",
    city: "Dellroy",
    state: "OH",
    areacode: 330,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44621",
    city: "Dennison",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44622",
    city: "Dover",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44624",
    city: "Dundee",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44625",
    city: "East Rochester",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44626",
    city: "East Sparta",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44627",
    city: "Fredericksburg",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44628",
    city: "Glenmont",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44629",
    city: "Gnadenhutten",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44630",
    city: "Greentown",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44632",
    city: "Hartville",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44633",
    city: "Holmesville",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44634",
    city: "Homeworth",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44636",
    city: "Kidron",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44637",
    city: "Killbuck",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44638",
    city: "Lakeville",
    state: "OH",
    areacode: 419,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44639",
    city: "Leesville",
    state: "OH",
    areacode: 740,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44640",
    city: "Limaville",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44641",
    city: "Louisville",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44643",
    city: "Magnolia",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44644",
    city: "Malvern",
    state: "OH",
    areacode: 330,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44645",
    city: "Marshallville",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44646",
    city: "Massillon",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44647",
    city: "Massillon",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44648",
    city: "Massillon",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44650",
    city: "Maximo",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44651",
    city: "Mechanicstown",
    state: "OH",
    areacode: 330,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44652",
    city: "Middlebranch",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44653",
    city: "Midvale",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44654",
    city: "Millersburg",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44656",
    city: "Mineral City",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44657",
    city: "Minerva",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44659",
    city: "Mount Eaton",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44660",
    city: "Mount Hope",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44661",
    city: "Nashville",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44662",
    city: "Navarre",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44663",
    city: "New Philadelphia",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44665",
    city: "North Georgetown",
    state: "OH",
    areacode: 330,
    county: "Columbiana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44666",
    city: "North Lawrence",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44667",
    city: "Orrville",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44669",
    city: "Paris",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44670",
    city: "Robertsville",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44671",
    city: "Sandyville",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44672",
    city: "Sebring",
    state: "OH",
    areacode: 330,
    county: "Mahoning",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44675",
    city: "Sherrodsville",
    state: "OH",
    areacode: 740,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44676",
    city: "Shreve",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44677",
    city: "Smithville",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44678",
    city: "Somerdale",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44679",
    city: "Stillwater",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44680",
    city: "Strasburg",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44681",
    city: "Sugarcreek",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44682",
    city: "Tuscarawas",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44683",
    city: "Uhrichsville",
    state: "OH",
    areacode: 740,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44685",
    city: "Uniontown",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44687",
    city: "Walnut Creek",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44688",
    city: "Waynesburg",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44689",
    city: "Wilmot",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44690",
    city: "Winesburg",
    state: "OH",
    areacode: 330,
    county: "Holmes",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44691",
    city: "Wooster",
    state: "OH",
    areacode: 330,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44693",
    city: "Deersville",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44695",
    city: "Bowerston",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44697",
    city: "Zoar",
    state: "OH",
    areacode: 330,
    county: "Tuscarawas",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44699",
    city: "Tippecanoe",
    state: "OH",
    areacode: 740,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44701",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44702",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44703",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44704",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44705",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44706",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44707",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44708",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44709",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44710",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44711",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44714",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44718",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44720",
    city: "North Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44721",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44730",
    city: "East Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44735",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44750",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44767",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44799",
    city: "Canton",
    state: "OH",
    areacode: 330,
    county: "Stark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44802",
    city: "Alvada",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44804",
    city: "Arcadia",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44805",
    city: "Ashland",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44807",
    city: "Attica",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44809",
    city: "Bascom",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44811",
    city: "Bellevue",
    state: "OH",
    areacode: 419,
    county: "Sandusky",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44813",
    city: "Bellville",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44814",
    city: "Berlin Heights",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44815",
    city: "Bettsville",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44816",
    city: "Birmingham",
    state: "OH",
    areacode: 440,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44817",
    city: "Bloomdale",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44818",
    city: "Bloomville",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44820",
    city: "Bucyrus",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44822",
    city: "Butler",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44824",
    city: "Castalia",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44825",
    city: "Chatfield",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44826",
    city: "Collins",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44827",
    city: "Crestline",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44828",
    city: "Flat Rock",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44830",
    city: "Fostoria",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44833",
    city: "Galion",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44836",
    city: "Green Springs",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44837",
    city: "Greenwich",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44838",
    city: "Hayesville",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44839",
    city: "Huron",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44840",
    city: "Jeromesville",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44841",
    city: "Kansas",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44842",
    city: "Loudonville",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44843",
    city: "Lucas",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44844",
    city: "McCutchenville",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44845",
    city: "Melmore",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44846",
    city: "Milan",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44847",
    city: "Monroeville",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44848",
    city: "Nankin",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44849",
    city: "Nevada",
    state: "OH",
    areacode: 740,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44850",
    city: "New Haven",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44851",
    city: "New London",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44853",
    city: "New Riegel",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44854",
    city: "New Washington",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44855",
    city: "North Fairfield",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44856",
    city: "North Robinson",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44857",
    city: "Norwalk",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44859",
    city: "Nova",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44860",
    city: "Oceola",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44861",
    city: "Old Fort",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44862",
    city: "Ontario",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44864",
    city: "Perrysville",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44865",
    city: "Plymouth",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44866",
    city: "Polk",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44867",
    city: "Republic",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44870",
    city: "Sandusky",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44871",
    city: "Sandusky",
    state: "OH",
    areacode: 419,
    county: "Erie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44874",
    city: "Savannah",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44875",
    city: "Shelby",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44878",
    city: "Shiloh",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44880",
    city: "Sullivan",
    state: "OH",
    areacode: 419,
    county: "Ashland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44881",
    city: "Sulphur Springs",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44882",
    city: "Sycamore",
    state: "OH",
    areacode: 419,
    county: "Wyandot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44883",
    city: "Tiffin",
    state: "OH",
    areacode: 419,
    county: "Seneca",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44887",
    city: "Tiro",
    state: "OH",
    areacode: 419,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44888",
    city: "Willard",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44889",
    city: "Wakeman",
    state: "OH",
    areacode: 440,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44890",
    city: "Willard",
    state: "OH",
    areacode: 419,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44901",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44902",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44903",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44904",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44905",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44906",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "44907",
    city: "Mansfield",
    state: "OH",
    areacode: 419,
    county: "Richland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45001",
    city: "Addyston",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45002",
    city: "Cleves",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45003",
    city: "College Corner",
    state: "OH",
    areacode: 513,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45004",
    city: "Collinsville",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45005",
    city: "Franklin",
    state: "OH",
    areacode: 937,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45011",
    city: "Hamilton",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45012",
    city: "Hamilton",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45013",
    city: "Hamilton",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45014",
    city: "Fairfield",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45015",
    city: "Hamilton",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45018",
    city: "Fairfield",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45030",
    city: "Harrison",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45032",
    city: "Harveysburg",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45033",
    city: "Hooven",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45034",
    city: "Kings Mills",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45036",
    city: "Lebanon",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45039",
    city: "Maineville",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45040",
    city: "Mason",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45041",
    city: "Miamitown",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45042",
    city: "Middletown",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45044",
    city: "Middletown",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45050",
    city: "Monroe",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45051",
    city: "Mount Saint Joseph",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45052",
    city: "North Bend",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45053",
    city: "Okeana",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45054",
    city: "Oregonia",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45055",
    city: "Overpeck",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45056",
    city: "Oxford",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45061",
    city: "Ross",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45062",
    city: "Seven Mile",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45063",
    city: "Shandon",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45064",
    city: "Somerville",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45065",
    city: "South Lebanon",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45066",
    city: "Springboro",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45067",
    city: "Trenton",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45068",
    city: "Waynesville",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45069",
    city: "West Chester",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45070",
    city: "West Elkton",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45071",
    city: "West Chester",
    state: "OH",
    areacode: 513,
    county: "Butler",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45101",
    city: "Aberdeen",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45102",
    city: "Amelia",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45103",
    city: "Batavia",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45105",
    city: "Bentonville",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45106",
    city: "Bethel",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45107",
    city: "Blanchester",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45111",
    city: "Camp Dennison",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45112",
    city: "Chilo",
    state: "OH",
    areacode: 937,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45113",
    city: "Clarksville",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45114",
    city: "Cuba",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45115",
    city: "Decatur",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45118",
    city: "Fayetteville",
    state: "OH",
    areacode: 513,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45119",
    city: "Feesburg",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45120",
    city: "Felicity",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45121",
    city: "Georgetown",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45122",
    city: "Goshen",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45123",
    city: "Greenfield",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45130",
    city: "Hamersville",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45131",
    city: "Higginsport",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45132",
    city: "Highland",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45133",
    city: "Hillsboro",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45135",
    city: "Leesburg",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45140",
    city: "Loveland",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45142",
    city: "Lynchburg",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45144",
    city: "Manchester",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45146",
    city: "Martinsville",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45147",
    city: "Miamiville",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45148",
    city: "Midland",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45150",
    city: "Milford",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45152",
    city: "Morrow",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45153",
    city: "Moscow",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45154",
    city: "Mount Orab",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45155",
    city: "Mowrystown",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45156",
    city: "Neville",
    state: "OH",
    areacode: 937,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45157",
    city: "New Richmond",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45158",
    city: "Newtonsville",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45159",
    city: "New Vienna",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45160",
    city: "Owensville",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45162",
    city: "Pleasant Plain",
    state: "OH",
    areacode: 513,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45164",
    city: "Port William",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45166",
    city: "Reesville",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45167",
    city: "Ripley",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45168",
    city: "Russellville",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45169",
    city: "Sabina",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45171",
    city: "Sardinia",
    state: "OH",
    areacode: 937,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45172",
    city: "Sinking Spring",
    state: "OH",
    areacode: 937,
    county: "Highland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45174",
    city: "Terrace Park",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45176",
    city: "Williamsburg",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45177",
    city: "Wilmington",
    state: "OH",
    areacode: 937,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45201",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45202",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45203",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45204",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45205",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45206",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45207",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45208",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45209",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45211",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45212",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45213",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45214",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45215",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45216",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45217",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45218",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45219",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45220",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45221",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45222",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45223",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45224",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45225",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45226",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45227",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45229",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45230",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45231",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45232",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45233",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45234",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45235",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45236",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45237",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45238",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45239",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45240",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45241",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45242",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45243",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45244",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45245",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Clermont",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45246",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45247",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45248",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45249",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45250",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45251",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45252",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45253",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45254",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45255",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45258",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45262",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45263",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45264",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45267",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45268",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45269",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45270",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45271",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45273",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45274",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45275",
    city: "Cincinnati",
    state: "OH",
    areacode: 859,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45277",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45280",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45296",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45298",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45299",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45301",
    city: "Alpha",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45302",
    city: "Anna",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45303",
    city: "Ansonia",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45304",
    city: "Arcanum",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45305",
    city: "Bellbrook",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45306",
    city: "Botkins",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45307",
    city: "Bowersville",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45308",
    city: "Bradford",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45309",
    city: "Brookville",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45310",
    city: "Burkettsville",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45311",
    city: "Camden",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45312",
    city: "Casstown",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45314",
    city: "Cedarville",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45315",
    city: "Clayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45316",
    city: "Clifton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45317",
    city: "Conover",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45318",
    city: "Covington",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45319",
    city: "Donnelsville",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45320",
    city: "Eaton",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45321",
    city: "Eldorado",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45322",
    city: "Englewood",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45323",
    city: "Enon",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45324",
    city: "Fairborn",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45325",
    city: "Farmersville",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45326",
    city: "Fletcher",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45327",
    city: "Germantown",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45328",
    city: "Gettysburg",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45330",
    city: "Gratis",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45331",
    city: "Greenville",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45332",
    city: "Hollansburg",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45333",
    city: "Houston",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45334",
    city: "Jackson Center",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45335",
    city: "Jamestown",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45336",
    city: "Kettlersville",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45337",
    city: "Laura",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45338",
    city: "Lewisburg",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45339",
    city: "Ludlow Falls",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45340",
    city: "Maplewood",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45341",
    city: "Medway",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45342",
    city: "Miamisburg",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45343",
    city: "Miamisburg",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45344",
    city: "New Carlisle",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45345",
    city: "New Lebanon",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45346",
    city: "New Madison",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45347",
    city: "New Paris",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45348",
    city: "New Weston",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45349",
    city: "North Hampton",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45350",
    city: "North Star",
    state: "OH",
    areacode: 419,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45351",
    city: "Osgood",
    state: "OH",
    areacode: 419,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45352",
    city: "Palestine",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45353",
    city: "Pemberton",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45354",
    city: "Phillipsburg",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45356",
    city: "Piqua",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45358",
    city: "Pitsburg",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45359",
    city: "Pleasant Hill",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45360",
    city: "Port Jefferson",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45361",
    city: "Potsdam",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45362",
    city: "Rossburg",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45363",
    city: "Russia",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45365",
    city: "Sidney",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45367",
    city: "Sidney",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45368",
    city: "South Charleston",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45369",
    city: "South Vienna",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45370",
    city: "Spring Valley",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45371",
    city: "Tipp City",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45372",
    city: "Tremont City",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45373",
    city: "Troy",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45374",
    city: "Troy",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45377",
    city: "Vandalia",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45378",
    city: "Verona",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45380",
    city: "Versailles",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45381",
    city: "West Alexandria",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45382",
    city: "West Manchester",
    state: "OH",
    areacode: 937,
    county: "Preble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45383",
    city: "West Milton",
    state: "OH",
    areacode: 937,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45384",
    city: "Wilberforce",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45385",
    city: "Xenia",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45387",
    city: "Yellow Springs",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45388",
    city: "Yorkshire",
    state: "OH",
    areacode: 419,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45389",
    city: "Christiansburg",
    state: "OH",
    areacode: 937,
    county: "Champaign",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45390",
    city: "Union City",
    state: "OH",
    areacode: 937,
    county: "Darke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45401",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45402",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45403",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45404",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45405",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45406",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45409",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45410",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45412",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45413",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45414",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45415",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45416",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45417",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45419",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45420",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45422",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45423",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45424",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45426",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45428",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45429",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45430",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45431",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45432",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45433",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45434",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45435",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45437",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45439",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45440",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45441",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45448",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45449",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45458",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45459",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45469",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45470",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45475",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45479",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45481",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45482",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45490",
    city: "Dayton",
    state: "OH",
    areacode: 937,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45501",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45502",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45503",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45504",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45505",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45506",
    city: "Springfield",
    state: "OH",
    areacode: 937,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45601",
    city: "Chillicothe",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45612",
    city: "Bainbridge",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45613",
    city: "Beaver",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45614",
    city: "Bidwell",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45616",
    city: "Blue Creek",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45617",
    city: "Bourneville",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45618",
    city: "Cherry Fork",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45619",
    city: "Chesapeake",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45620",
    city: "Cheshire",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45621",
    city: "Coalton",
    state: "OH",
    areacode: 740,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45622",
    city: "Creola",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45623",
    city: "Crown City",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45624",
    city: "Cynthiana",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45628",
    city: "Frankfort",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45629",
    city: "Franklin Furnace",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45630",
    city: "Friendship",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45631",
    city: "Gallipolis",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45633",
    city: "Hallsville",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45634",
    city: "Hamden",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45636",
    city: "Haverhill",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45638",
    city: "Ironton",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45640",
    city: "Jackson",
    state: "OH",
    areacode: 740,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45642",
    city: "Jasper",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45643",
    city: "Kerr",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45644",
    city: "Kingston",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45645",
    city: "Kitts Hill",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45646",
    city: "Latham",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45647",
    city: "Londonderry",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45648",
    city: "Lucasville",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45650",
    city: "Lynx",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45651",
    city: "McArthur",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45652",
    city: "McDermott",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45653",
    city: "Minford",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45654",
    city: "New Plymouth",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45656",
    city: "Oak Hill",
    state: "OH",
    areacode: 740,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45657",
    city: "Otway",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45658",
    city: "Patriot",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45659",
    city: "Pedro",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45660",
    city: "Peebles",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45661",
    city: "Piketon",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45662",
    city: "Portsmouth",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45663",
    city: "West Portsmouth",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45669",
    city: "Proctorville",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45671",
    city: "Rarden",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45672",
    city: "Ray",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45673",
    city: "Richmond Dale",
    state: "OH",
    areacode: 740,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45674",
    city: "Rio Grande",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45675",
    city: "Rock Camp",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45677",
    city: "Scioto Furnace",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45678",
    city: "Scottown",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45679",
    city: "Seaman",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45680",
    city: "South Point",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45681",
    city: "South Salem",
    state: "OH",
    areacode: 937,
    county: "Ross",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45682",
    city: "South Webster",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45683",
    city: "Stockdale",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45684",
    city: "Stout",
    state: "OH",
    areacode: 937,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45685",
    city: "Thurman",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45686",
    city: "Vinton",
    state: "OH",
    areacode: 740,
    county: "Gallia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45687",
    city: "Wakefield",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45688",
    city: "Waterloo",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45690",
    city: "Waverly",
    state: "OH",
    areacode: 740,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45692",
    city: "Wellston",
    state: "OH",
    areacode: 740,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45693",
    city: "West Union",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45694",
    city: "Wheelersburg",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45695",
    city: "Wilkesville",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45696",
    city: "Willow Wood",
    state: "OH",
    areacode: 740,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45697",
    city: "Winchester",
    state: "OH",
    areacode: 937,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45698",
    city: "Zaleski",
    state: "OH",
    areacode: 740,
    county: "Vinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45699",
    city: "Lucasville",
    state: "OH",
    areacode: 740,
    county: "Scioto",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45701",
    city: "Athens",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45710",
    city: "Albany",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45711",
    city: "Amesville",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45712",
    city: "Barlow",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45713",
    city: "Bartlett",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45714",
    city: "Belpre",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45715",
    city: "Beverly",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45716",
    city: "Buchtel",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45717",
    city: "Carbondale",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45719",
    city: "Chauncey",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45720",
    city: "Chester",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45721",
    city: "Coal Run",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45723",
    city: "Coolville",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45724",
    city: "Cutler",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45727",
    city: "Dexter City",
    state: "OH",
    areacode: 740,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45729",
    city: "Fleming",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45732",
    city: "Glouster",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45734",
    city: "Graysville",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45735",
    city: "Guysville",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45739",
    city: "Hockingport",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45740",
    city: "Jacksonville",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45741",
    city: "Langsville",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45742",
    city: "Little Hocking",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45743",
    city: "Long Bottom",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45744",
    city: "Lowell",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45745",
    city: "Lower Salem",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45746",
    city: "Macksburg",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45750",
    city: "Marietta",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45760",
    city: "Middleport",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45761",
    city: "Millfield",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45764",
    city: "Nelsonville",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45766",
    city: "New Marshfield",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45767",
    city: "New Matamoras",
    state: "OH",
    areacode: 740,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45768",
    city: "Newport",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45769",
    city: "Pomeroy",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45770",
    city: "Portland",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45771",
    city: "Racine",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45772",
    city: "Reedsville",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45773",
    city: "Reno",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45775",
    city: "Rutland",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45776",
    city: "Shade",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45777",
    city: "Sharpsburg",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45778",
    city: "Stewart",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45779",
    city: "Syracuse",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45780",
    city: "The Plains",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45782",
    city: "Trimble",
    state: "OH",
    areacode: 740,
    county: "Athens",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45783",
    city: "Tuppers Plains",
    state: "OH",
    areacode: 740,
    county: "Meigs",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45784",
    city: "Vincent",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45786",
    city: "Waterford",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45787",
    city: "Watertown",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45788",
    city: "Whipple",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45789",
    city: "Wingett Run",
    state: "OH",
    areacode: 740,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45801",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45802",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45804",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45805",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45806",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45807",
    city: "Lima",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45808",
    city: "Beaverdam",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45809",
    city: "Gomer",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45810",
    city: "Ada",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45812",
    city: "Alger",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45813",
    city: "Antwerp",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45814",
    city: "Arlington",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45815",
    city: "Belmore",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45816",
    city: "Benton Ridge",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45817",
    city: "Bluffton",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45819",
    city: "Buckland",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45820",
    city: "Cairo",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45821",
    city: "Cecil",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45822",
    city: "Celina",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45826",
    city: "Chickasaw",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45827",
    city: "Cloverdale",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45828",
    city: "Coldwater",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45830",
    city: "Columbus Grove",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45831",
    city: "Continental",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45832",
    city: "Convoy",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45833",
    city: "Delphos",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45835",
    city: "Dola",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45836",
    city: "Dunkirk",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45837",
    city: "Dupont",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45838",
    city: "Elgin",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45839",
    city: "Findlay",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45840",
    city: "Findlay",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45841",
    city: "Jenera",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45843",
    city: "Forest",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45844",
    city: "Fort Jennings",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45845",
    city: "Fort Loramie",
    state: "OH",
    areacode: 937,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45846",
    city: "Fort Recovery",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45848",
    city: "Glandorf",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45849",
    city: "Grover Hill",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45850",
    city: "Harrod",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45851",
    city: "Haviland",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45853",
    city: "Kalida",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45854",
    city: "Lafayette",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45855",
    city: "Latty",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45856",
    city: "Leipsic",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45858",
    city: "McComb",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45859",
    city: "McGuffey",
    state: "OH",
    areacode: 419,
    county: "Hardin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45860",
    city: "Maria Stein",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45861",
    city: "Melrose",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45862",
    city: "Mendon",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45863",
    city: "Middle Point",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45864",
    city: "Miller City",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45865",
    city: "Minster",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45866",
    city: "Montezuma",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45867",
    city: "Mount Blanchard",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45868",
    city: "Mount Cory",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45869",
    city: "New Bremen",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45870",
    city: "New Hampshire",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45871",
    city: "New Knoxville",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45872",
    city: "North Baltimore",
    state: "OH",
    areacode: 419,
    county: "Wood",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45873",
    city: "Oakwood",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45874",
    city: "Ohio City",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45875",
    city: "Ottawa",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45876",
    city: "Ottoville",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45877",
    city: "Pandora",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45879",
    city: "Paulding",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45880",
    city: "Payne",
    state: "OH",
    areacode: 419,
    county: "Paulding",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45881",
    city: "Rawson",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45882",
    city: "Rockford",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45883",
    city: "Saint Henry",
    state: "OH",
    areacode: 419,
    county: "Mercer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45884",
    city: "Saint Johns",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45885",
    city: "Saint Marys",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45886",
    city: "Scott",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45887",
    city: "Spencerville",
    state: "OH",
    areacode: 419,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45888",
    city: "Uniopolis",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45889",
    city: "Van Buren",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45890",
    city: "Vanlue",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45891",
    city: "Van Wert",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45893",
    city: "Vaughnsville",
    state: "OH",
    areacode: 419,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45894",
    city: "Venedocia",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45895",
    city: "Wapakoneta",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45896",
    city: "Waynesfield",
    state: "OH",
    areacode: 419,
    county: "Auglaize",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45897",
    city: "Williamstown",
    state: "OH",
    areacode: 419,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45898",
    city: "Willshire",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45899",
    city: "Wren",
    state: "OH",
    areacode: 419,
    county: "Van Wert",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "45999",
    city: "Cincinnati",
    state: "OH",
    areacode: 513,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46001",
    city: "Alexandria",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46011",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46012",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46013",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46014",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46015",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46016",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46017",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46018",
    city: "Anderson",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46030",
    city: "Arcadia",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46031",
    city: "Atlanta",
    state: "IN",
    areacode: 765,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46032",
    city: "Carmel",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46033",
    city: "Carmel",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46034",
    city: "Cicero",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46035",
    city: "Colfax",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46036",
    city: "Elwood",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46037",
    city: "Fishers",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46038",
    city: "Fishers",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46039",
    city: "Forest",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46040",
    city: "Fortville",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46041",
    city: "Frankfort",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46044",
    city: "Frankton",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46045",
    city: "Goldsmith",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46047",
    city: "Hobbs",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46048",
    city: "Ingalls",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46049",
    city: "Kempton",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46050",
    city: "Kirklin",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46051",
    city: "Lapel",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46052",
    city: "Lebanon",
    state: "IN",
    areacode: 765,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46055",
    city: "McCordsville",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46056",
    city: "Markleville",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46057",
    city: "Michigantown",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46058",
    city: "Mulberry",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46060",
    city: "Noblesville",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46061",
    city: "Noblesville",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46062",
    city: "Noblesville",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46063",
    city: "Orestes",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46064",
    city: "Pendleton",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46065",
    city: "Rossville",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46067",
    city: "Sedalia",
    state: "IN",
    areacode: 765,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46068",
    city: "Sharpsville",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46069",
    city: "Sheridan",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46070",
    city: "Summitville",
    state: "IN",
    areacode: 765,
    county: "Madison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46071",
    city: "Thorntown",
    state: "IN",
    areacode: 765,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46072",
    city: "Tipton",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46074",
    city: "Westfield",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46075",
    city: "Whitestown",
    state: "IN",
    areacode: 317,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46076",
    city: "Windfall",
    state: "IN",
    areacode: 765,
    county: "Tipton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46077",
    city: "Zionsville",
    state: "IN",
    areacode: 317,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46082",
    city: "Carmel",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46085",
    city: "Fishers",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46102",
    city: "Advance",
    state: "IN",
    areacode: 765,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46103",
    city: "Amo",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46104",
    city: "Arlington",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46105",
    city: "Bainbridge",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46106",
    city: "Bargersville",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46107",
    city: "Beech Grove",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46110",
    city: "Boggstown",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46111",
    city: "Brooklyn",
    state: "IN",
    areacode: 317,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46112",
    city: "Brownsburg",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46113",
    city: "Camby",
    state: "IN",
    areacode: 317,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46115",
    city: "Carthage",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46117",
    city: "Charlottesville",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46118",
    city: "Clayton",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46120",
    city: "Cloverdale",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46121",
    city: "Coatesville",
    state: "IN",
    areacode: 765,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46122",
    city: "Danville",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46123",
    city: "Avon",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46124",
    city: "Edinburgh",
    state: "IN",
    areacode: 812,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46125",
    city: "Eminence",
    state: "IN",
    areacode: 765,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46126",
    city: "Fairland",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46127",
    city: "Falmouth",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46128",
    city: "Fillmore",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46129",
    city: "Finly",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46130",
    city: "Fountaintown",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46131",
    city: "Franklin",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46133",
    city: "Glenwood",
    state: "IN",
    areacode: 765,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46135",
    city: "Greencastle",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46140",
    city: "Greenfield",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46142",
    city: "Greenwood",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46143",
    city: "Greenwood",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46144",
    city: "Gwynneville",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46146",
    city: "Homer",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46147",
    city: "Jamestown",
    state: "IN",
    areacode: 765,
    county: "Boone",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46148",
    city: "Knightstown",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46149",
    city: "Lizton",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46150",
    city: "Manilla",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46151",
    city: "Martinsville",
    state: "IN",
    areacode: 765,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46154",
    city: "Maxwell",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46155",
    city: "Mays",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46156",
    city: "Milroy",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46157",
    city: "Monrovia",
    state: "IN",
    areacode: 317,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46158",
    city: "Mooresville",
    state: "IN",
    areacode: 317,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46160",
    city: "Morgantown",
    state: "IN",
    areacode: 812,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46161",
    city: "Morristown",
    state: "IN",
    areacode: 765,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46162",
    city: "Needham",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46163",
    city: "New Palestine",
    state: "IN",
    areacode: 317,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46164",
    city: "Nineveh",
    state: "IN",
    areacode: 317,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46165",
    city: "North Salem",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46166",
    city: "Paragon",
    state: "IN",
    areacode: 765,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46167",
    city: "Pittsboro",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46168",
    city: "Plainfield",
    state: "IN",
    areacode: 317,
    county: "Hendricks",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46170",
    city: "Putnamville",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46171",
    city: "Reelsville",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46172",
    city: "Roachdale",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46173",
    city: "Rushville",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46175",
    city: "Russellville",
    state: "IN",
    areacode: 765,
    county: "Putnam",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46176",
    city: "Shelbyville",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46180",
    city: "Stilesville",
    state: "IN",
    areacode: 317,
    county: "Morgan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46181",
    city: "Trafalgar",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46182",
    city: "Waldron",
    state: "IN",
    areacode: 317,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46183",
    city: "West Newton",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46184",
    city: "Whiteland",
    state: "IN",
    areacode: 317,
    county: "Johnson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46186",
    city: "Wilkinson",
    state: "IN",
    areacode: 765,
    county: "Hancock",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46201",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46202",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46203",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46204",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46205",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46206",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46207",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46208",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46209",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46213",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46214",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46216",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46217",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46218",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46219",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46220",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46221",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46222",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46224",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46225",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46226",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46227",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46228",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46229",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46230",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46231",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46234",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46235",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46236",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46237",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46239",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46240",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46241",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46242",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46244",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46247",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46249",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46250",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46251",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46253",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46254",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46255",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46256",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46259",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46260",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46262",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46268",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46277",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46278",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46280",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46282",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46283",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46285",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46288",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46290",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Hamilton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46298",
    city: "Indianapolis",
    state: "IN",
    areacode: 317,
    county: "Marion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46301",
    city: "Beverly Shores",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46302",
    city: "Boone Grove",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46303",
    city: "Cedar Lake",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46304",
    city: "Chesterton",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46307",
    city: "Crown Point",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46308",
    city: "Crown Point",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46310",
    city: "Demotte",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46311",
    city: "Dyer",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46312",
    city: "East Chicago",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46319",
    city: "Griffith",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46320",
    city: "Hammond",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46321",
    city: "Munster",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46322",
    city: "Highland",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46323",
    city: "Hammond",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46324",
    city: "Hammond",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46325",
    city: "Hammond",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46327",
    city: "Hammond",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46340",
    city: "Hanna",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46341",
    city: "Hebron",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46342",
    city: "Hobart",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46345",
    city: "Kingsbury",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46346",
    city: "Kingsford Heights",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46347",
    city: "Kouts",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46348",
    city: "La Crosse",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46349",
    city: "Lake Village",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46350",
    city: "La Porte",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46352",
    city: "La Porte",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46355",
    city: "Leroy",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46356",
    city: "Lowell",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46360",
    city: "Michigan City",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46361",
    city: "Michigan City",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46365",
    city: "Mill Creek",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46366",
    city: "North Judson",
    state: "IN",
    areacode: 574,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46368",
    city: "Portage",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46371",
    city: "Rolling Prairie",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46372",
    city: "Roselawn",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46373",
    city: "Saint John",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46374",
    city: "San Pierre",
    state: "IN",
    areacode: 219,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46375",
    city: "Schererville",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46376",
    city: "Schneider",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46377",
    city: "Shelby",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46379",
    city: "Sumava Resorts",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46380",
    city: "Tefft",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46381",
    city: "Thayer",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46382",
    city: "Union Mills",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46383",
    city: "Valparaiso",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46384",
    city: "Valparaiso",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46385",
    city: "Valparaiso",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46390",
    city: "Wanatah",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46391",
    city: "Westville",
    state: "IN",
    areacode: 219,
    county: "LaPorte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46392",
    city: "Wheatfield",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46393",
    city: "Wheeler",
    state: "IN",
    areacode: 219,
    county: "Porter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46394",
    city: "Whiting",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46401",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46402",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46403",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46404",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46405",
    city: "Lake Station",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46406",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46407",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46408",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46409",
    city: "Gary",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46410",
    city: "Merrillville",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46411",
    city: "Merrillville",
    state: "IN",
    areacode: 219,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46501",
    city: "Argos",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46502",
    city: "Atwood",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46504",
    city: "Bourbon",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46506",
    city: "Bremen",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46507",
    city: "Bristol",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46508",
    city: "Burket",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46510",
    city: "Claypool",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46511",
    city: "Culver",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46513",
    city: "Donaldson",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46514",
    city: "Elkhart",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46515",
    city: "Elkhart",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46516",
    city: "Elkhart",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46517",
    city: "Elkhart",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46524",
    city: "Etna Green",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46526",
    city: "Goshen",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46527",
    city: "Goshen",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46528",
    city: "Goshen",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46530",
    city: "Granger",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46531",
    city: "Grovertown",
    state: "IN",
    areacode: 574,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46532",
    city: "Hamlet",
    state: "IN",
    areacode: 574,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46534",
    city: "Knox",
    state: "IN",
    areacode: 574,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46536",
    city: "Lakeville",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46537",
    city: "Lapaz",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46538",
    city: "Leesburg",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46539",
    city: "Mentone",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46540",
    city: "Middlebury",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46542",
    city: "Milford",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46543",
    city: "Millersburg",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46544",
    city: "Mishawaka",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46545",
    city: "Mishawaka",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46546",
    city: "Mishawaka",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46550",
    city: "Nappanee",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46552",
    city: "New Carlisle",
    state: "IN",
    areacode: 574,
    county: "LaPorte",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46553",
    city: "New Paris",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46554",
    city: "North Liberty",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46555",
    city: "North Webster",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46556",
    city: "Notre Dame",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46561",
    city: "Osceola",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46562",
    city: "Pierceton",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46563",
    city: "Plymouth",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46565",
    city: "Shipshewana",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46567",
    city: "Syracuse",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46570",
    city: "Tippecanoe",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46571",
    city: "Topeka",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46572",
    city: "Tyner",
    state: "IN",
    areacode: 574,
    county: "Marshall",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46573",
    city: "Wakarusa",
    state: "IN",
    areacode: 574,
    county: "Elkhart",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46574",
    city: "Walkerton",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46580",
    city: "Warsaw",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46581",
    city: "Warsaw",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46582",
    city: "Warsaw",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46590",
    city: "Winona Lake",
    state: "IN",
    areacode: 574,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46595",
    city: "Wyatt",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46601",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46613",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46614",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46615",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46616",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46617",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46619",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46624",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46626",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46628",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46634",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46635",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46637",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46660",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46680",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46699",
    city: "South Bend",
    state: "IN",
    areacode: 574,
    county: "St Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46701",
    city: "Albion",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46702",
    city: "Andrews",
    state: "IN",
    areacode: 260,
    county: "Huntington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46703",
    city: "Angola",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46704",
    city: "Arcola",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46705",
    city: "Ashley",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46706",
    city: "Auburn",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46710",
    city: "Avilla",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46711",
    city: "Berne",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46713",
    city: "Bippus",
    state: "IN",
    areacode: 260,
    county: "Huntington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46714",
    city: "Bluffton",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46721",
    city: "Butler",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46723",
    city: "Churubusco",
    state: "IN",
    areacode: 260,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46725",
    city: "Columbia City",
    state: "IN",
    areacode: 260,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46730",
    city: "Corunna",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46731",
    city: "Craigville",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46732",
    city: "Cromwell",
    state: "IN",
    areacode: 574,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46733",
    city: "Decatur",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46737",
    city: "Fremont",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46738",
    city: "Garrett",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46740",
    city: "Geneva",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46741",
    city: "Grabill",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46742",
    city: "Hamilton",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46743",
    city: "Harlan",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46745",
    city: "Hoagland",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46746",
    city: "Howe",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46747",
    city: "Hudson",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46748",
    city: "Huntertown",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46750",
    city: "Huntington",
    state: "IN",
    areacode: 260,
    county: "Huntington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46755",
    city: "Kendallville",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46759",
    city: "Keystone",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46760",
    city: "Kimmell",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46761",
    city: "LaGrange",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46763",
    city: "Laotto",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46764",
    city: "Larwill",
    state: "IN",
    areacode: 260,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46765",
    city: "Leo",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46766",
    city: "Liberty Center",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46767",
    city: "Ligonier",
    state: "IN",
    areacode: 574,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46769",
    city: "Linn Grove",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46770",
    city: "Markle",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46771",
    city: "Mongo",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46772",
    city: "Monroe",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46773",
    city: "Monroeville",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46774",
    city: "New Haven",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46776",
    city: "Orland",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46777",
    city: "Ossian",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46778",
    city: "Petroleum",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46779",
    city: "Pleasant Lake",
    state: "IN",
    areacode: 260,
    county: "Steuben",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46780",
    city: "Pleasant Mills",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46781",
    city: "Poneto",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46782",
    city: "Preble",
    state: "IN",
    areacode: 260,
    county: "Adams",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46783",
    city: "Roanoke",
    state: "IN",
    areacode: 260,
    county: "Huntington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46784",
    city: "Rome City",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46785",
    city: "Saint Joe",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46786",
    city: "South Milford",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46787",
    city: "South Whitley",
    state: "IN",
    areacode: 260,
    county: "Whitley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46788",
    city: "Spencerville",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46789",
    city: "Stroh",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46791",
    city: "Uniondale",
    state: "IN",
    areacode: 260,
    county: "Wells",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46792",
    city: "Warren",
    state: "IN",
    areacode: 260,
    county: "Huntington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46793",
    city: "Waterloo",
    state: "IN",
    areacode: 260,
    county: "DeKalb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46794",
    city: "Wawaka",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46795",
    city: "Wolcottville",
    state: "IN",
    areacode: 260,
    county: "LaGrange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46796",
    city: "Wolflake",
    state: "IN",
    areacode: 260,
    county: "Noble",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46797",
    city: "Woodburn",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46798",
    city: "Yoder",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46799",
    city: "Zanesville",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46801",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46802",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46803",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46804",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46805",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46806",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46807",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46808",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46809",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46814",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46815",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46816",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46818",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46819",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46825",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46835",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46845",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46850",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46851",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46852",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46853",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46854",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46855",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46856",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46857",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46858",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46859",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46860",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46861",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46862",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46863",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46864",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46865",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46866",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46867",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46868",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46869",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46885",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46895",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46896",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46897",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46898",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46899",
    city: "Fort Wayne",
    state: "IN",
    areacode: 260,
    county: "Allen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46901",
    city: "Kokomo",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46902",
    city: "Kokomo",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46903",
    city: "Kokomo",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46904",
    city: "Kokomo",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46910",
    city: "Akron",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46911",
    city: "Amboy",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46912",
    city: "Athens",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46913",
    city: "Bringhurst",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46914",
    city: "Bunker Hill",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46915",
    city: "Burlington",
    state: "IN",
    areacode: 765,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46916",
    city: "Burrows",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46917",
    city: "Camden",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46919",
    city: "Converse",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46920",
    city: "Cutler",
    state: "IN",
    areacode: 765,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46921",
    city: "Deedsville",
    state: "IN",
    areacode: 574,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46922",
    city: "Delong",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46923",
    city: "Delphi",
    state: "IN",
    areacode: 317,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46926",
    city: "Denver",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46928",
    city: "Fairmount",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46929",
    city: "Flora",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46930",
    city: "Fowlerton",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46931",
    city: "Fulton",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46932",
    city: "Galveston",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46933",
    city: "Gas City",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46935",
    city: "Grass Creek",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46936",
    city: "Greentown",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46937",
    city: "Hemlock",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46938",
    city: "Jonesboro",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46939",
    city: "Kewanna",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46940",
    city: "La Fontaine",
    state: "IN",
    areacode: 765,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46941",
    city: "Lagro",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46942",
    city: "Lake Cicott",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46943",
    city: "Laketon",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46945",
    city: "Leiters Ford",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46946",
    city: "Liberty Mills",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46947",
    city: "Logansport",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46950",
    city: "Lucerne",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46951",
    city: "Macy",
    state: "IN",
    areacode: 574,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46952",
    city: "Marion",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46953",
    city: "Marion",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46957",
    city: "Matthews",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46958",
    city: "Mexico",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46959",
    city: "Miami",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46960",
    city: "Monterey",
    state: "IN",
    areacode: 574,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46961",
    city: "New Waverly",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46962",
    city: "North Manchester",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46965",
    city: "Oakford",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46967",
    city: "Onward",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46968",
    city: "Ora",
    state: "IN",
    areacode: 574,
    county: "Starke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "46970",
    city: "Peru",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46971",
    city: "Grissom ARB",
    state: "IN",
    areacode: 765,
    county: "Miami",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46974",
    city: "Roann",
    state: "IN",
    areacode: 765,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46975",
    city: "Rochester",
    state: "IN",
    areacode: 574,
    county: "Fulton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46977",
    city: "Rockfield",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46978",
    city: "Royal Center",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46979",
    city: "Russiaville",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46980",
    city: "Servia",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46982",
    city: "Silver Lake",
    state: "IN",
    areacode: 260,
    county: "Kosciusko",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46984",
    city: "Somerset",
    state: "IN",
    areacode: 765,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46985",
    city: "Star City",
    state: "IN",
    areacode: 574,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46986",
    city: "Swayzee",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46987",
    city: "Sweetser",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46988",
    city: "Twelve Mile",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46989",
    city: "Upland",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46990",
    city: "Urbana",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46991",
    city: "Van Buren",
    state: "IN",
    areacode: 765,
    county: "Grant",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46992",
    city: "Wabash",
    state: "IN",
    areacode: 260,
    county: "Wabash",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46994",
    city: "Walton",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46995",
    city: "West Middleton",
    state: "IN",
    areacode: 765,
    county: "Howard",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46996",
    city: "Winamac",
    state: "IN",
    areacode: 574,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "46998",
    city: "Young America",
    state: "IN",
    areacode: 574,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47001",
    city: "Aurora",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47003",
    city: "West College Corner",
    state: "IN",
    areacode: 317,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47006",
    city: "Batesville",
    state: "IN",
    areacode: 812,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47010",
    city: "Bath",
    state: "IN",
    areacode: 765,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47011",
    city: "Bennington",
    state: "IN",
    areacode: 812,
    county: "Switzerland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47012",
    city: "Brookville",
    state: "IN",
    areacode: 765,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47016",
    city: "Cedar Grove",
    state: "IN",
    areacode: 765,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47017",
    city: "Cross Plains",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47018",
    city: "Dillsboro",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47019",
    city: "East Enterprise",
    state: "IN",
    areacode: 812,
    county: "Switzerland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47020",
    city: "Florence",
    state: "IN",
    areacode: 812,
    county: "Switzerland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47021",
    city: "Friendship",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47022",
    city: "Guilford",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47023",
    city: "Holton",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47024",
    city: "Laurel",
    state: "IN",
    areacode: 765,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47025",
    city: "Lawrenceburg",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47030",
    city: "Metamora",
    state: "IN",
    areacode: 765,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47031",
    city: "Milan",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47032",
    city: "Moores Hill",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47033",
    city: "Morris",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47034",
    city: "Napoleon",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47035",
    city: "New Trenton",
    state: "IN",
    areacode: 812,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47036",
    city: "Oldenburg",
    state: "IN",
    areacode: 812,
    county: "Franklin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47037",
    city: "Osgood",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47038",
    city: "Patriot",
    state: "IN",
    areacode: 812,
    county: "Switzerland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47039",
    city: "Pierceville",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47040",
    city: "Rising Sun",
    state: "IN",
    areacode: 812,
    county: "Ohio",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47041",
    city: "Sunman",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47042",
    city: "Versailles",
    state: "IN",
    areacode: 812,
    county: "Ripley",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47043",
    city: "Vevay",
    state: "IN",
    areacode: 812,
    county: "Switzerland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47060",
    city: "West Harrison",
    state: "IN",
    areacode: 812,
    county: "Dearborn",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47102",
    city: "Austin",
    state: "IN",
    areacode: 812,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47104",
    city: "Bethlehem",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47106",
    city: "Borden",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47107",
    city: "Bradford",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47108",
    city: "Campbellsburg",
    state: "IN",
    areacode: 812,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47110",
    city: "Central",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47111",
    city: "Charlestown",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47112",
    city: "Corydon",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47114",
    city: "Crandall",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47115",
    city: "Depauw",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47116",
    city: "Eckerty",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47117",
    city: "Elizabeth",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47118",
    city: "English",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47119",
    city: "Floyds Knobs",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47120",
    city: "Fredericksburg",
    state: "IN",
    areacode: 812,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47122",
    city: "Georgetown",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47123",
    city: "Grantsburg",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47124",
    city: "Greenville",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47125",
    city: "Hardinsburg",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47126",
    city: "Henryville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47129",
    city: "Clarksville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47130",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47131",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47132",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47133",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47134",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47135",
    city: "Laconia",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47136",
    city: "Lanesville",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47137",
    city: "Leavenworth",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47138",
    city: "Lexington",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47140",
    city: "Marengo",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47141",
    city: "Marysville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47142",
    city: "Mauckport",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47143",
    city: "Memphis",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47144",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47145",
    city: "Milltown",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47146",
    city: "Mount Saint Francis",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47147",
    city: "Nabb",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47150",
    city: "New Albany",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47151",
    city: "New Albany",
    state: "IN",
    areacode: 812,
    county: "Floyd",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47160",
    city: "New Middletown",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47161",
    city: "New Salisbury",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47162",
    city: "New Washington",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47163",
    city: "Otisco",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47164",
    city: "Palmyra",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47165",
    city: "Pekin",
    state: "IN",
    areacode: 812,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47166",
    city: "Ramsey",
    state: "IN",
    areacode: 812,
    county: "Harrison",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47167",
    city: "Salem",
    state: "IN",
    areacode: 812,
    county: "Washington",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47170",
    city: "Scottsburg",
    state: "IN",
    areacode: 812,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47172",
    city: "Sellersburg",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47174",
    city: "Sulphur",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47175",
    city: "Taswell",
    state: "IN",
    areacode: 812,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47177",
    city: "Underwood",
    state: "IN",
    areacode: 812,
    county: "Scott",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47190",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47199",
    city: "Jeffersonville",
    state: "IN",
    areacode: 812,
    county: "Clark",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47201",
    city: "Columbus",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47202",
    city: "Columbus",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47203",
    city: "Columbus",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47220",
    city: "Brownstown",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47223",
    city: "Butlerville",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47224",
    city: "Canaan",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47225",
    city: "Clarksburg",
    state: "IN",
    areacode: 812,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47226",
    city: "Clifford",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47227",
    city: "Commiskey",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47228",
    city: "Cortland",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47229",
    city: "Crothersville",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47230",
    city: "Deputy",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47231",
    city: "Dupont",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47232",
    city: "Elizabethtown",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47234",
    city: "Flat Rock",
    state: "IN",
    areacode: 812,
    county: "Shelby",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47235",
    city: "Freetown",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47236",
    city: "Grammer",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47240",
    city: "Greensburg",
    state: "IN",
    areacode: 812,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47243",
    city: "Hanover",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47244",
    city: "Hartsville",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47245",
    city: "Hayden",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47246",
    city: "Hope",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47247",
    city: "Jonesville",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47249",
    city: "Kurtz",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47250",
    city: "Madison",
    state: "IN",
    areacode: 812,
    county: "Jefferson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47260",
    city: "Medora",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47263",
    city: "New Point",
    state: "IN",
    areacode: 812,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47264",
    city: "Norman",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47265",
    city: "North Vernon",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47270",
    city: "Paris Crossing",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47272",
    city: "Saint Paul",
    state: "IN",
    areacode: 317,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47273",
    city: "Scipio",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47274",
    city: "Seymour",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47280",
    city: "Taylorsville",
    state: "IN",
    areacode: 812,
    county: "Bartholomew",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47281",
    city: "Vallonia",
    state: "IN",
    areacode: 812,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47282",
    city: "Vernon",
    state: "IN",
    areacode: 812,
    county: "Jennings",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47283",
    city: "Westport",
    state: "IN",
    areacode: 812,
    county: "Decatur",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47302",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47303",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47304",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47305",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47306",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47307",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47308",
    city: "Muncie",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47320",
    city: "Albany",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47322",
    city: "Bentonville",
    state: "IN",
    areacode: 765,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47324",
    city: "Boston",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47325",
    city: "Brownsville",
    state: "IN",
    areacode: 765,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47326",
    city: "Bryant",
    state: "IN",
    areacode: 260,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47327",
    city: "Cambridge City",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47330",
    city: "Centerville",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47331",
    city: "Connersville",
    state: "IN",
    areacode: 765,
    county: "Fayette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47334",
    city: "Daleville",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47335",
    city: "Dublin",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47336",
    city: "Dunkirk",
    state: "IN",
    areacode: 765,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47337",
    city: "Dunreith",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47338",
    city: "Eaton",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47339",
    city: "Economy",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47340",
    city: "Farmland",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47341",
    city: "Fountain City",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47342",
    city: "Gaston",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47344",
    city: "Greensboro",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47345",
    city: "Greens Fork",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47346",
    city: "Hagerstown",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47348",
    city: "Hartford City",
    state: "IN",
    areacode: 765,
    county: "Blackford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47351",
    city: "Kennard",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47352",
    city: "Lewisville",
    state: "IN",
    areacode: 765,
    county: "Rush",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47353",
    city: "Liberty",
    state: "IN",
    areacode: 765,
    county: "Union",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47354",
    city: "Losantville",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47355",
    city: "Lynn",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47356",
    city: "Middletown",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47357",
    city: "Milton",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47358",
    city: "Modoc",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47359",
    city: "Montpelier",
    state: "IN",
    areacode: 765,
    county: "Blackford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47360",
    city: "Mooreland",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47361",
    city: "Mount Summit",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47362",
    city: "New Castle",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47366",
    city: "New Lisbon",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47367",
    city: "Oakville",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47368",
    city: "Parker City",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47369",
    city: "Pennville",
    state: "IN",
    areacode: 260,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47370",
    city: "Pershing",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47371",
    city: "Portland",
    state: "IN",
    areacode: 260,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47373",
    city: "Redkey",
    state: "IN",
    areacode: 765,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47374",
    city: "Richmond",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47375",
    city: "Richmond",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47380",
    city: "Ridgeville",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47381",
    city: "Salamonia",
    state: "IN",
    areacode: 260,
    county: "Jay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47382",
    city: "Saratoga",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47383",
    city: "Selma",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47384",
    city: "Shirley",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47385",
    city: "Spiceland",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47386",
    city: "Springport",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47387",
    city: "Straughn",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47388",
    city: "Sulphur Springs",
    state: "IN",
    areacode: 765,
    county: "Henry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47390",
    city: "Union City",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47392",
    city: "Webster",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47393",
    city: "Williamsburg",
    state: "IN",
    areacode: 765,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47394",
    city: "Winchester",
    state: "IN",
    areacode: 765,
    county: "Randolph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47396",
    city: "Yorktown",
    state: "IN",
    areacode: 765,
    county: "Delaware",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47401",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47402",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47403",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47404",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47405",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47406",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47407",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47408",
    city: "Bloomington",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47420",
    city: "Avoca",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47421",
    city: "Bedford",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47424",
    city: "Bloomfield",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47426",
    city: "Clear Creek",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47427",
    city: "Coal City",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47429",
    city: "Ellettsville",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47431",
    city: "Freedom",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47432",
    city: "French Lick",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47433",
    city: "Gosport",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47434",
    city: "Harrodsburg",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47435",
    city: "Helmsburg",
    state: "IN",
    areacode: 812,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47436",
    city: "Heltonville",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47437",
    city: "Huron",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47438",
    city: "Jasonville",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47441",
    city: "Linton",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47443",
    city: "Lyons",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47445",
    city: "Midland",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47446",
    city: "Mitchell",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47448",
    city: "Nashville",
    state: "IN",
    areacode: 812,
    county: "Brown",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47449",
    city: "Newberry",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47451",
    city: "Oolitic",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47452",
    city: "Orleans",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47453",
    city: "Owensburg",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47454",
    city: "Paoli",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47455",
    city: "Patricksburg",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47456",
    city: "Quincy",
    state: "IN",
    areacode: 317,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47457",
    city: "Scotland",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47458",
    city: "Smithville",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47459",
    city: "Solsberry",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47460",
    city: "Spencer",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47462",
    city: "Springville",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47463",
    city: "Stanford",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47464",
    city: "Stinesville",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47465",
    city: "Switz City",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47467",
    city: "Tunnelton",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47468",
    city: "Unionville",
    state: "IN",
    areacode: 812,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47469",
    city: "West Baden Springs",
    state: "IN",
    areacode: 812,
    county: "Orange",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47470",
    city: "Williams",
    state: "IN",
    areacode: 812,
    county: "Lawrence",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47471",
    city: "Worthington",
    state: "IN",
    areacode: 812,
    county: "Greene",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47501",
    city: "Washington",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47512",
    city: "Bicknell",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47513",
    city: "Birdseye",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47514",
    city: "Branchville",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47515",
    city: "Bristow",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47516",
    city: "Bruceville",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47519",
    city: "Cannelburg",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47520",
    city: "Cannelton",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47521",
    city: "Celestine",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47522",
    city: "Crane",
    state: "IN",
    areacode: 812,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47523",
    city: "Dale",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47524",
    city: "Decker",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47525",
    city: "Derby",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47527",
    city: "Dubois",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47528",
    city: "Edwardsport",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47529",
    city: "Elnora",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47531",
    city: "Evanston",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47532",
    city: "Ferdinand",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47535",
    city: "Freelandville",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47536",
    city: "Fulda",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47537",
    city: "Gentryville",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47541",
    city: "Holland",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47542",
    city: "Huntingburg",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47545",
    city: "Ireland",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47546",
    city: "Jasper",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47547",
    city: "Jasper",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47549",
    city: "Jasper",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47550",
    city: "Lamar",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47551",
    city: "Leopold",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47552",
    city: "Lincoln City",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47553",
    city: "Loogootee",
    state: "IN",
    areacode: 812,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47556",
    city: "Mariah Hill",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47557",
    city: "Monroe City",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47558",
    city: "Montgomery",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47561",
    city: "Oaktown",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47562",
    city: "Odon",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47564",
    city: "Otwell",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47567",
    city: "Petersburg",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47568",
    city: "Plainville",
    state: "IN",
    areacode: 812,
    county: "Daviess",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47573",
    city: "Ragsdale",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47574",
    city: "Rome",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47575",
    city: "Saint Anthony",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47576",
    city: "Saint Croix",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47577",
    city: "Saint Meinrad",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47578",
    city: "Sandborn",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47579",
    city: "Santa Claus",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47580",
    city: "Schnellville",
    state: "IN",
    areacode: 812,
    county: "Dubois",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47581",
    city: "Shoals",
    state: "IN",
    areacode: 812,
    county: "Martin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47584",
    city: "Spurgeon",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47585",
    city: "Stendal",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47586",
    city: "Tell City",
    state: "IN",
    areacode: 812,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47588",
    city: "Troy",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47590",
    city: "Velpen",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47591",
    city: "Vincennes",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47596",
    city: "Westphalia",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47597",
    city: "Wheatland",
    state: "IN",
    areacode: 812,
    county: "Knox",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47598",
    city: "Winslow",
    state: "IN",
    areacode: 812,
    county: "Pike",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47601",
    city: "Boonville",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47610",
    city: "Chandler",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47611",
    city: "Chrisney",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47612",
    city: "Cynthiana",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47613",
    city: "Elberfeld",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47615",
    city: "Grandview",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47616",
    city: "Griffin",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47617",
    city: "Hatfield",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47618",
    city: "Inglefield",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47619",
    city: "Lynnville",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47620",
    city: "Mount Vernon",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47629",
    city: "Newburgh",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47630",
    city: "Newburgh",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47631",
    city: "New Harmony",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47633",
    city: "Poseyville",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47634",
    city: "Richland",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47635",
    city: "Rockport",
    state: "IN",
    areacode: 812,
    county: "Spencer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47637",
    city: "Tennyson",
    state: "IN",
    areacode: 812,
    county: "Warrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47638",
    city: "Wadesville",
    state: "IN",
    areacode: 812,
    county: "Posey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47639",
    city: "Haubstadt",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47640",
    city: "Hazleton",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47647",
    city: "Buckskin",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47648",
    city: "Fort Branch",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47649",
    city: "Francisco",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47654",
    city: "Mackey",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47660",
    city: "Oakland City",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47665",
    city: "Owensville",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47666",
    city: "Patoka",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47670",
    city: "Princeton",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47683",
    city: "Somerville",
    state: "IN",
    areacode: 812,
    county: "Gibson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47701",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47702",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47703",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47704",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47705",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47706",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47708",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47710",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47711",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47712",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47713",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47714",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47715",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47716",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47719",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47720",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47721",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47722",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47724",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47725",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47728",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47730",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47731",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47732",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47733",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47734",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47735",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47736",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47737",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47740",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47747",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47750",
    city: "Evansville",
    state: "IN",
    areacode: 812,
    county: "Vanderburgh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47801",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47802",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47803",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47804",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47805",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47807",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47808",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47809",
    city: "Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47831",
    city: "Blanford",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47832",
    city: "Bloomingdale",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47833",
    city: "Bowling Green",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47834",
    city: "Brazil",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47836",
    city: "Bridgeton",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47837",
    city: "Carbon",
    state: "IN",
    areacode: 812,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47838",
    city: "Carlisle",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47840",
    city: "Centerpoint",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47841",
    city: "Clay City",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47842",
    city: "Clinton",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47845",
    city: "Coalmont",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47846",
    city: "Cory",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47847",
    city: "Dana",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47848",
    city: "Dugger",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47849",
    city: "Fairbanks",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47850",
    city: "Farmersburg",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47851",
    city: "Fontanet",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47852",
    city: "Graysville",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47853",
    city: "Harmony",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47854",
    city: "Hillsdale",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47855",
    city: "Hymera",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47857",
    city: "Knightsville",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47858",
    city: "Lewis",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47859",
    city: "Marshall",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47860",
    city: "Mecca",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47861",
    city: "Merom",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47862",
    city: "Montezuma",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47863",
    city: "New Goshen",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47865",
    city: "Paxton",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47866",
    city: "Pimento",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47868",
    city: "Poland",
    state: "IN",
    areacode: 812,
    county: "Owen",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47869",
    city: "Prairie Creek",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47870",
    city: "Prairieton",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47871",
    city: "Riley",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47872",
    city: "Rockville",
    state: "IN",
    areacode: 765,
    county: "Parke",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47874",
    city: "Rosedale",
    state: "IN",
    areacode: 765,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47875",
    city: "Saint Bernice",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47876",
    city: "Saint Mary of the Woods",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47878",
    city: "Seelyville",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47879",
    city: "Shelburn",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47880",
    city: "Shepardsville",
    state: "IN",
    areacode: 317,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47881",
    city: "Staunton",
    state: "IN",
    areacode: 812,
    county: "Clay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47882",
    city: "Sullivan",
    state: "IN",
    areacode: 812,
    county: "Sullivan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47884",
    city: "Universal",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47885",
    city: "West Terre Haute",
    state: "IN",
    areacode: 812,
    county: "Vigo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47901",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47902",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47903",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47904",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47905",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47906",
    city: "West Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47907",
    city: "West Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47909",
    city: "Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47916",
    city: "Alamo",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47917",
    city: "Ambia",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47918",
    city: "Attica",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47920",
    city: "Battle Ground",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47921",
    city: "Boswell",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47922",
    city: "Brook",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47923",
    city: "Brookston",
    state: "IN",
    areacode: 765,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47924",
    city: "Buck Creek",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47925",
    city: "Buffalo",
    state: "IN",
    areacode: 574,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47926",
    city: "Burnettsville",
    state: "IN",
    areacode: 574,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47928",
    city: "Cayuga",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47929",
    city: "Chalmers",
    state: "IN",
    areacode: 219,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47930",
    city: "Clarks Hill",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47932",
    city: "Covington",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47933",
    city: "Crawfordsville",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47940",
    city: "Darlington",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47941",
    city: "Dayton",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47942",
    city: "Earl Park",
    state: "IN",
    areacode: 219,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47943",
    city: "Fair Oaks",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47944",
    city: "Fowler",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47946",
    city: "Francesville",
    state: "IN",
    areacode: 219,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47948",
    city: "Goodland",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47949",
    city: "Hillsboro",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47950",
    city: "Idaville",
    state: "IN",
    areacode: 574,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47951",
    city: "Kentland",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47952",
    city: "Kingman",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47954",
    city: "Ladoga",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47955",
    city: "Linden",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47957",
    city: "Medaryville",
    state: "IN",
    areacode: 219,
    county: "Pulaski",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47958",
    city: "Mellott",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47959",
    city: "Monon",
    state: "IN",
    areacode: 219,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47960",
    city: "Monticello",
    state: "IN",
    areacode: 574,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47962",
    city: "Montmorenci",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47963",
    city: "Morocco",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47964",
    city: "Mount Ayr",
    state: "IN",
    areacode: 219,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47965",
    city: "New Market",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47966",
    city: "Newport",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47967",
    city: "New Richmond",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47968",
    city: "New Ross",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47969",
    city: "Newtown",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47970",
    city: "Otterbein",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47971",
    city: "Oxford",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47974",
    city: "Perrysville",
    state: "IN",
    areacode: 765,
    county: "Vermillion",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47975",
    city: "Pine Village",
    state: "IN",
    areacode: 765,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47977",
    city: "Remington",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47978",
    city: "Rensselaer",
    state: "IN",
    areacode: 219,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "47980",
    city: "Reynolds",
    state: "IN",
    areacode: 219,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47981",
    city: "Romney",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47982",
    city: "State Line",
    state: "IN",
    areacode: 765,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47983",
    city: "Stockwell",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47986",
    city: "Templeton",
    state: "IN",
    areacode: 765,
    county: "Benton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47987",
    city: "Veedersburg",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47988",
    city: "Wallace",
    state: "IN",
    areacode: 765,
    county: "Fountain",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47989",
    city: "Waveland",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47990",
    city: "Waynetown",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47991",
    city: "West Lebanon",
    state: "IN",
    areacode: 765,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47992",
    city: "Westpoint",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47993",
    city: "Williamsport",
    state: "IN",
    areacode: 765,
    county: "Warren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47994",
    city: "Wingate",
    state: "IN",
    areacode: 765,
    county: "Montgomery",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47995",
    city: "Wolcott",
    state: "IN",
    areacode: 219,
    county: "White",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47996",
    city: "West Lafayette",
    state: "IN",
    areacode: 765,
    county: "Tippecanoe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "47997",
    city: "Yeoman",
    state: "IN",
    areacode: 574,
    county: "Carroll",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48001",
    city: "Algonac",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48002",
    city: "Allenton",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48003",
    city: "Almont",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48004",
    city: "Anchorville",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48005",
    city: "Armada",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48006",
    city: "Avoca",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48007",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48009",
    city: "Birmingham",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48012",
    city: "Birmingham",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48014",
    city: "Capac",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48015",
    city: "Center Line",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48017",
    city: "Clawson",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48021",
    city: "Eastpointe",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48022",
    city: "Emmett",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48023",
    city: "Fair Haven",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48025",
    city: "Franklin",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48026",
    city: "Fraser",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48027",
    city: "Goodells",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48028",
    city: "Harsens Island",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48030",
    city: "Hazel Park",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48032",
    city: "Jeddo",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48033",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48034",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48035",
    city: "Clinton Township",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48036",
    city: "Clinton Township",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48037",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48038",
    city: "Clinton Township",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48039",
    city: "Marine City",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48040",
    city: "Marysville",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48041",
    city: "Memphis",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48042",
    city: "Macomb",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48043",
    city: "Mount Clemens",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48044",
    city: "Macomb",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48045",
    city: "Harrison Township",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48046",
    city: "Mount Clemens",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48047",
    city: "New Baltimore",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48048",
    city: "New Haven",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48049",
    city: "North Street",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48050",
    city: "New Haven",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48051",
    city: "New Baltimore",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48054",
    city: "East China",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48059",
    city: "Fort Gratiot",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48060",
    city: "Port Huron",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48061",
    city: "Port Huron",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48062",
    city: "Richmond",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48063",
    city: "Columbus",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48064",
    city: "Casco",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48065",
    city: "Romeo",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48066",
    city: "Roseville",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48067",
    city: "Royal Oak",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48068",
    city: "Royal Oak",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48069",
    city: "Pleasant Ridge",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48070",
    city: "Huntington Woods",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48071",
    city: "Madison Heights",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48072",
    city: "Berkley",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48073",
    city: "Royal Oak",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48074",
    city: "Smiths Creek",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48075",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48076",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48079",
    city: "Saint Clair",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48080",
    city: "Saint Clair Shores",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48081",
    city: "Saint Clair Shores",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48082",
    city: "Saint Clair Shores",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48083",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48084",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48085",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48086",
    city: "Southfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48088",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48089",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48090",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48091",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48092",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48093",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48094",
    city: "Washington",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48095",
    city: "Washington",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48096",
    city: "Ray",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48097",
    city: "Yale",
    state: "MI",
    areacode: 810,
    county: "Saint Clair",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48098",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48099",
    city: "Troy",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48101",
    city: "Allen Park",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48103",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48104",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48105",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48106",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48107",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48108",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48109",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48110",
    city: "Azalia",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48111",
    city: "Belleville",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48112",
    city: "Belleville",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48113",
    city: "Ann Arbor",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48114",
    city: "Brighton",
    state: "MI",
    areacode: 810,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48115",
    city: "Bridgewater",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48116",
    city: "Brighton",
    state: "MI",
    areacode: 810,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48117",
    city: "Carleton",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48118",
    city: "Chelsea",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48120",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48121",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48122",
    city: "Melvindale",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48123",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48124",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48125",
    city: "Dearborn Heights",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48126",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48127",
    city: "Dearborn Heights",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48128",
    city: "Dearborn",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48130",
    city: "Dexter",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48131",
    city: "Dundee",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48133",
    city: "Erie",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48134",
    city: "Flat Rock",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48135",
    city: "Garden City",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48136",
    city: "Garden City",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48137",
    city: "Gregory",
    state: "MI",
    areacode: 734,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48138",
    city: "Grosse Ile",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48139",
    city: "Hamburg",
    state: "MI",
    areacode: 810,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48140",
    city: "Ida",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48141",
    city: "Inkster",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48143",
    city: "Lakeland",
    state: "MI",
    areacode: 810,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48144",
    city: "Lambertville",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48145",
    city: "La Salle",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48146",
    city: "Lincoln Park",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48150",
    city: "Livonia",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48151",
    city: "Livonia",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48152",
    city: "Livonia",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48153",
    city: "Livonia",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48154",
    city: "Livonia",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48157",
    city: "Luna Pier",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48158",
    city: "Manchester",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48159",
    city: "Maybee",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48160",
    city: "Milan",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48161",
    city: "Monroe",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48162",
    city: "Monroe",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48164",
    city: "New Boston",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48165",
    city: "New Hudson",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48166",
    city: "Newport",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48167",
    city: "Northville",
    state: "MI",
    areacode: 248,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48168",
    city: "Northville",
    state: "MI",
    areacode: 248,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48169",
    city: "Pinckney",
    state: "MI",
    areacode: 734,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48170",
    city: "Plymouth",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48173",
    city: "Rockwood",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48174",
    city: "Romulus",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48175",
    city: "Salem",
    state: "MI",
    areacode: 810,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48176",
    city: "Saline",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48177",
    city: "Samaria",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48178",
    city: "South Lyon",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48179",
    city: "South Rockwood",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48180",
    city: "Taylor",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48182",
    city: "Temperance",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48183",
    city: "Trenton",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48184",
    city: "Wayne",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48185",
    city: "Westland",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48186",
    city: "Westland",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48187",
    city: "Canton",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48188",
    city: "Canton",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48189",
    city: "Whitmore Lake",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48190",
    city: "Whittaker",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48191",
    city: "Willis",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48192",
    city: "Wyandotte",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48193",
    city: "Riverview",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48195",
    city: "Southgate",
    state: "MI",
    areacode: 734,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48197",
    city: "Ypsilanti",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48198",
    city: "Ypsilanti",
    state: "MI",
    areacode: 734,
    county: "Washtenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48201",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48202",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48203",
    city: "Highland Park",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48204",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48205",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48206",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48207",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48208",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48209",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48210",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48211",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48212",
    city: "Hamtramck",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48213",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48214",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48215",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48216",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48217",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48218",
    city: "River Rouge",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48219",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48220",
    city: "Ferndale",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48221",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48222",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48223",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48224",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48225",
    city: "Harper Woods",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48226",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48227",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48228",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48229",
    city: "Ecorse",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48230",
    city: "Grosse Pointe",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48231",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48232",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48233",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48234",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48235",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48236",
    city: "Grosse Pointe",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48237",
    city: "Oak Park",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48238",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48239",
    city: "Redford",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48240",
    city: "Redford",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48242",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48243",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48244",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48255",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48260",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48264",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48265",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48266",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48267",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48268",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48269",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48272",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48275",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48277",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48278",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48279",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48288",
    city: "Detroit",
    state: "MI",
    areacode: 313,
    county: "Wayne",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48301",
    city: "Bloomfield Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48302",
    city: "Bloomfield Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48303",
    city: "Bloomfield Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48304",
    city: "Bloomfield Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48306",
    city: "Rochester",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48307",
    city: "Rochester",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48308",
    city: "Rochester",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48309",
    city: "Rochester",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48310",
    city: "Sterling Heights",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48311",
    city: "Sterling Heights",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48312",
    city: "Sterling Heights",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48313",
    city: "Sterling Heights",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48314",
    city: "Sterling Heights",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48315",
    city: "Utica",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48316",
    city: "Utica",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48317",
    city: "Utica",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48318",
    city: "Utica",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48320",
    city: "Keego Harbor",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48321",
    city: "Auburn Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48322",
    city: "West Bloomfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48323",
    city: "West Bloomfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48324",
    city: "West Bloomfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48325",
    city: "West Bloomfield",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48326",
    city: "Auburn Hills",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48327",
    city: "Waterford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48328",
    city: "Waterford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48329",
    city: "Waterford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48330",
    city: "Drayton Plains",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48331",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48332",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48333",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48334",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48335",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48336",
    city: "Farmington",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48340",
    city: "Pontiac",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48341",
    city: "Pontiac",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48342",
    city: "Pontiac",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48343",
    city: "Pontiac",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48346",
    city: "Clarkston",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48347",
    city: "Clarkston",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48348",
    city: "Clarkston",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48350",
    city: "Davisburg",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48353",
    city: "Hartland",
    state: "MI",
    areacode: 810,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48356",
    city: "Highland",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48357",
    city: "Highland",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48359",
    city: "Lake Orion",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48360",
    city: "Lake Orion",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48361",
    city: "Lake Orion",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48362",
    city: "Lake Orion",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48363",
    city: "Oakland",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48366",
    city: "Lakeville",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48367",
    city: "Leonard",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48370",
    city: "Oxford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48371",
    city: "Oxford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48374",
    city: "Novi",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48375",
    city: "Novi",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48376",
    city: "Novi",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48377",
    city: "Novi",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48380",
    city: "Milford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48381",
    city: "Milford",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48382",
    city: "Commerce Township",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48383",
    city: "White Lake",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48386",
    city: "White Lake",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48387",
    city: "Union Lake",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48390",
    city: "Walled Lake",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48393",
    city: "Wixom",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48397",
    city: "Warren",
    state: "MI",
    areacode: 586,
    county: "Macomb",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48401",
    city: "Applegate",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48410",
    city: "Argyle",
    state: "MI",
    areacode: 989,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48411",
    city: "Atlas",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48412",
    city: "Attica",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48413",
    city: "Bad Axe",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48414",
    city: "Bancroft",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48415",
    city: "Birch Run",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48416",
    city: "Brown City",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48417",
    city: "Burt",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48418",
    city: "Byron",
    state: "MI",
    areacode: 810,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48419",
    city: "Carsonville",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48420",
    city: "Clio",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48421",
    city: "Columbiaville",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48422",
    city: "Croswell",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48423",
    city: "Davison",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48426",
    city: "Decker",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48427",
    city: "Deckerville",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48428",
    city: "Dryden",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48429",
    city: "Durand",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48430",
    city: "Fenton",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48432",
    city: "Filion",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48433",
    city: "Flushing",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48434",
    city: "Forestville",
    state: "MI",
    areacode: 989,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48435",
    city: "Fostoria",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48436",
    city: "Gaines",
    state: "MI",
    areacode: 989,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48437",
    city: "Genesee",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48438",
    city: "Goodrich",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48439",
    city: "Grand Blanc",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48440",
    city: "Hadley",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48441",
    city: "Harbor Beach",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48442",
    city: "Holly",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48444",
    city: "Imlay City",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48445",
    city: "Kinde",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48446",
    city: "Lapeer",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48449",
    city: "Lennon",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48450",
    city: "Lexington",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48451",
    city: "Linden",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48453",
    city: "Marlette",
    state: "MI",
    areacode: 989,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48454",
    city: "Melvin",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48455",
    city: "Metamora",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48456",
    city: "Minden City",
    state: "MI",
    areacode: 989,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48457",
    city: "Montrose",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48458",
    city: "Mount Morris",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48460",
    city: "New Lothrop",
    state: "MI",
    areacode: 810,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48461",
    city: "North Branch",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48462",
    city: "Ortonville",
    state: "MI",
    areacode: 248,
    county: "Oakland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48463",
    city: "Otisville",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48464",
    city: "Otter Lake",
    state: "MI",
    areacode: 810,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48465",
    city: "Palms",
    state: "MI",
    areacode: 989,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48466",
    city: "Peck",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48467",
    city: "Port Austin",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48468",
    city: "Port Hope",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48469",
    city: "Port Sanilac",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48470",
    city: "Ruth",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48471",
    city: "Sandusky",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48472",
    city: "Snover",
    state: "MI",
    areacode: 810,
    county: "Sanilac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48473",
    city: "Swartz Creek",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48475",
    city: "Ubly",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48476",
    city: "Vernon",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48480",
    city: "Grand Blanc",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48501",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48502",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48503",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48504",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48505",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48506",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48507",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48509",
    city: "Burton",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48519",
    city: "Burton",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48529",
    city: "Burton",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48531",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48532",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48550",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48551",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48552",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48553",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48554",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48555",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48556",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48557",
    city: "Flint",
    state: "MI",
    areacode: 810,
    county: "Genesee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48601",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48602",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48603",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48604",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48605",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48606",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48607",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48608",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48609",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48610",
    city: "Alger",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48611",
    city: "Auburn",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48612",
    city: "Beaverton",
    state: "MI",
    areacode: 989,
    county: "Gladwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48613",
    city: "Bentley",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48614",
    city: "Brant",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48615",
    city: "Breckenridge",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48616",
    city: "Chesaning",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48617",
    city: "Clare",
    state: "MI",
    areacode: 989,
    county: "Clare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48618",
    city: "Coleman",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48619",
    city: "Comins",
    state: "MI",
    areacode: 989,
    county: "Oscoda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48620",
    city: "Edenville",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48621",
    city: "Fairview",
    state: "MI",
    areacode: 989,
    county: "Oscoda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48622",
    city: "Farwell",
    state: "MI",
    areacode: 989,
    county: "Clare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48623",
    city: "Freeland",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48624",
    city: "Gladwin",
    state: "MI",
    areacode: 989,
    county: "Gladwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48625",
    city: "Harrison",
    state: "MI",
    areacode: 989,
    county: "Clare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48626",
    city: "Hemlock",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48627",
    city: "Higgins Lake",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48628",
    city: "Hope",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48629",
    city: "Houghton Lake",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48630",
    city: "Houghton Lake Heights",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48631",
    city: "Kawkawlin",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48632",
    city: "Lake",
    state: "MI",
    areacode: 989,
    county: "Clare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48633",
    city: "Lake George",
    state: "MI",
    areacode: 989,
    county: "Clare",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48634",
    city: "Linwood",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48635",
    city: "Lupton",
    state: "MI",
    areacode: 989,
    county: "Ogemaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48636",
    city: "Luzerne",
    state: "MI",
    areacode: 989,
    county: "Oscoda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48637",
    city: "Merrill",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48638",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48640",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48641",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48642",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48647",
    city: "Mio",
    state: "MI",
    areacode: 989,
    county: "Oscoda",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48649",
    city: "Oakley",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48650",
    city: "Pinconning",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48651",
    city: "Prudenville",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48652",
    city: "Rhodes",
    state: "MI",
    areacode: 989,
    county: "Gladwin",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48653",
    city: "Roscommon",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48654",
    city: "Rose City",
    state: "MI",
    areacode: 989,
    county: "Ogemaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48655",
    city: "Saint Charles",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48656",
    city: "Saint Helen",
    state: "MI",
    areacode: 989,
    county: "Roscommon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48657",
    city: "Sanford",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48658",
    city: "Standish",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48659",
    city: "Sterling",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48661",
    city: "West Branch",
    state: "MI",
    areacode: 989,
    county: "Ogemaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48662",
    city: "Wheeler",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48663",
    city: "Saginaw",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48667",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48670",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48674",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48686",
    city: "Midland",
    state: "MI",
    areacode: 989,
    county: "Midland",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48701",
    city: "Akron",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48703",
    city: "Au Gres",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48705",
    city: "Barton City",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48706",
    city: "Bay City",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48707",
    city: "Bay City",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48708",
    city: "Bay City",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48710",
    city: "University Center",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48720",
    city: "Bay Port",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48721",
    city: "Black River",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48722",
    city: "Bridgeport",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48723",
    city: "Caro",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48724",
    city: "Carrollton",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48725",
    city: "Caseville",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48726",
    city: "Cass City",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48727",
    city: "Clifford",
    state: "MI",
    areacode: 989,
    county: "Lapeer",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48728",
    city: "Curran",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48729",
    city: "Deford",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48730",
    city: "East Tawas",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48731",
    city: "Elkton",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48732",
    city: "Essexville",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48733",
    city: "Fairgrove",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48734",
    city: "Frankenmuth",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48735",
    city: "Gagetown",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48737",
    city: "Glennie",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48738",
    city: "Greenbush",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48739",
    city: "Hale",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48740",
    city: "Harrisville",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48741",
    city: "Kingston",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48742",
    city: "Lincoln",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48743",
    city: "Long Lake",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48744",
    city: "Mayville",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48745",
    city: "Mikado",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48746",
    city: "Millington",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48747",
    city: "Munger",
    state: "MI",
    areacode: 989,
    county: "Bay",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48748",
    city: "National City",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48749",
    city: "Omer",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48750",
    city: "Oscoda",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48754",
    city: "Owendale",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48755",
    city: "Pigeon",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48756",
    city: "Prescott",
    state: "MI",
    areacode: 989,
    county: "Ogemaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48757",
    city: "Reese",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48758",
    city: "Richville",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48759",
    city: "Sebewaing",
    state: "MI",
    areacode: 989,
    county: "Huron",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48760",
    city: "Silverwood",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48761",
    city: "South Branch",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48762",
    city: "Spruce",
    state: "MI",
    areacode: 989,
    county: "Alcona",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48763",
    city: "Tawas City",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48764",
    city: "Tawas City",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48765",
    city: "Turner",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48766",
    city: "Twining",
    state: "MI",
    areacode: 989,
    county: "Arenac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48767",
    city: "Unionville",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48768",
    city: "Vassar",
    state: "MI",
    areacode: 989,
    county: "Tuscola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48770",
    city: "Whittemore",
    state: "MI",
    areacode: 989,
    county: "Iosco",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48787",
    city: "Frankenmuth",
    state: "MI",
    areacode: 989,
    county: "Saginaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48801",
    city: "Alma",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48804",
    city: "Mount Pleasant",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48805",
    city: "Okemos",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48806",
    city: "Ashley",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48807",
    city: "Bannister",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48808",
    city: "Bath",
    state: "MI",
    areacode: 517,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48809",
    city: "Belding",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48811",
    city: "Carson City",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48812",
    city: "Cedar Lake",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48813",
    city: "Charlotte",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48815",
    city: "Clarksville",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48816",
    city: "Cohoctah",
    state: "MI",
    areacode: 517,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48817",
    city: "Corunna",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48818",
    city: "Crystal",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48819",
    city: "Dansville",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48820",
    city: "DeWitt",
    state: "MI",
    areacode: 517,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48821",
    city: "Dimondale",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48822",
    city: "Eagle",
    state: "MI",
    areacode: 517,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48823",
    city: "East Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48824",
    city: "East Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48825",
    city: "East Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48826",
    city: "East Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48827",
    city: "Eaton Rapids",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48829",
    city: "Edmore",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48830",
    city: "Elm Hall",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48831",
    city: "Elsie",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48832",
    city: "Elwell",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48833",
    city: "Eureka",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48834",
    city: "Fenwick",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48835",
    city: "Fowler",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48836",
    city: "Fowlerville",
    state: "MI",
    areacode: 517,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48837",
    city: "Grand Ledge",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48838",
    city: "Greenville",
    state: "MI",
    areacode: 231,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48840",
    city: "Haslett",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48841",
    city: "Henderson",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48842",
    city: "Holt",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48843",
    city: "Howell",
    state: "MI",
    areacode: 517,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48844",
    city: "Howell",
    state: "MI",
    areacode: 517,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48845",
    city: "Hubbardston",
    state: "MI",
    areacode: 989,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48846",
    city: "Ionia",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48847",
    city: "Ithaca",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48848",
    city: "Laingsburg",
    state: "MI",
    areacode: 517,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48849",
    city: "Lake Odessa",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48850",
    city: "Lakeview",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48851",
    city: "Lyons",
    state: "MI",
    areacode: 989,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48852",
    city: "McBride",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48853",
    city: "Maple Rapids",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48854",
    city: "Mason",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48855",
    city: "Howell",
    state: "MI",
    areacode: 517,
    county: "Livingston",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48856",
    city: "Middleton",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48857",
    city: "Morrice",
    state: "MI",
    areacode: 517,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48858",
    city: "Mount Pleasant",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48859",
    city: "Mount Pleasant",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48860",
    city: "Muir",
    state: "MI",
    areacode: 989,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48861",
    city: "Mulliken",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48862",
    city: "North Star",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48864",
    city: "Okemos",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48865",
    city: "Orleans",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48866",
    city: "Ovid",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48867",
    city: "Owosso",
    state: "MI",
    areacode: 989,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48870",
    city: "Palo",
    state: "MI",
    areacode: 989,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48871",
    city: "Perrinton",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48872",
    city: "Perry",
    state: "MI",
    areacode: 517,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48873",
    city: "Pewamo",
    state: "MI",
    areacode: 989,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48874",
    city: "Pompeii",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48875",
    city: "Portland",
    state: "MI",
    areacode: 517,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48876",
    city: "Potterville",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48877",
    city: "Riverdale",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48878",
    city: "Rosebush",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48879",
    city: "Saint Johns",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48880",
    city: "Saint Louis",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48881",
    city: "Saranac",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48882",
    city: "Shaftsburg",
    state: "MI",
    areacode: 517,
    county: "Shiawassee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48883",
    city: "Shepherd",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48884",
    city: "Sheridan",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48885",
    city: "Sidney",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48886",
    city: "Six Lakes",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48887",
    city: "Smyrna",
    state: "MI",
    areacode: 616,
    county: "Ionia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48888",
    city: "Stanton",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48889",
    city: "Sumner",
    state: "MI",
    areacode: 989,
    county: "Gratiot",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48890",
    city: "Sunfield",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48891",
    city: "Vestaburg",
    state: "MI",
    areacode: 989,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48892",
    city: "Webberville",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48893",
    city: "Weidman",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48894",
    city: "Westphalia",
    state: "MI",
    areacode: 989,
    county: "Clinton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48895",
    city: "Williamston",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48896",
    city: "Winn",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48897",
    city: "Woodland",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48901",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48906",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48908",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48909",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48910",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48911",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48912",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48913",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48915",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48916",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48917",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48918",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48919",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48922",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48924",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48929",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48930",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48933",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48937",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48951",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48956",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "48980",
    city: "Lansing",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49001",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49002",
    city: "Portage",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49003",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49004",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49005",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49006",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49007",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49008",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49009",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49010",
    city: "Allegan",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49011",
    city: "Athens",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49012",
    city: "Augusta",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49013",
    city: "Bangor",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49014",
    city: "Battle Creek",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49015",
    city: "Battle Creek",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49016",
    city: "Battle Creek",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49017",
    city: "Battle Creek",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49018",
    city: "Battle Creek",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49019",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49020",
    city: "Bedford",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49021",
    city: "Bellevue",
    state: "MI",
    areacode: 269,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49022",
    city: "Benton Harbor",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49023",
    city: "Benton Harbor",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49024",
    city: "Portage",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49026",
    city: "Bloomingdale",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49027",
    city: "Breedsville",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49028",
    city: "Bronson",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49029",
    city: "Burlington",
    state: "MI",
    areacode: 517,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49030",
    city: "Burr Oak",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49031",
    city: "Cassopolis",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49032",
    city: "Centreville",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49033",
    city: "Ceresco",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49034",
    city: "Climax",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49035",
    city: "Cloverdale",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49036",
    city: "Coldwater",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49037",
    city: "Battle Creek",
    state: "MI",
    areacode: 517,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49038",
    city: "Coloma",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49039",
    city: "Hagar Shores",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49040",
    city: "Colon",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49041",
    city: "Comstock",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49042",
    city: "Constantine",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49043",
    city: "Covert",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49045",
    city: "Decatur",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49046",
    city: "Delton",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49047",
    city: "Dowagiac",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49048",
    city: "Kalamazoo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49050",
    city: "Dowling",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49051",
    city: "East Leroy",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49052",
    city: "Fulton",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49053",
    city: "Galesburg",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49055",
    city: "Gobles",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49056",
    city: "Grand Junction",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49057",
    city: "Hartford",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49058",
    city: "Hastings",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49060",
    city: "Hickory Corners",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49061",
    city: "Jones",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49062",
    city: "Kendall",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49063",
    city: "Lacota",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49064",
    city: "Lawrence",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49065",
    city: "Lawton",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49066",
    city: "Leonidas",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49067",
    city: "Marcellus",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49068",
    city: "Marshall",
    state: "MI",
    areacode: 269,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49070",
    city: "Martin",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49071",
    city: "Mattawan",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49072",
    city: "Mendon",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49073",
    city: "Nashville",
    state: "MI",
    areacode: 517,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49074",
    city: "Nazareth",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49075",
    city: "Nottawa",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49076",
    city: "Olivet",
    state: "MI",
    areacode: 269,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49077",
    city: "Oshtemo",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49078",
    city: "Otsego",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49079",
    city: "Paw Paw",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49080",
    city: "Plainwell",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49081",
    city: "Portage",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49082",
    city: "Quincy",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49083",
    city: "Richland",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49084",
    city: "Riverside",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49085",
    city: "Saint Joseph",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49087",
    city: "Schoolcraft",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49088",
    city: "Scotts",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49089",
    city: "Sherwood",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49090",
    city: "South Haven",
    state: "MI",
    areacode: 269,
    county: "Van Buren",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49091",
    city: "Sturgis",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49092",
    city: "Tekonsha",
    state: "MI",
    areacode: 517,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49093",
    city: "Three Rivers",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49094",
    city: "Union City",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49095",
    city: "Vandalia",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49096",
    city: "Vermontville",
    state: "MI",
    areacode: 517,
    county: "Eaton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49097",
    city: "Vicksburg",
    state: "MI",
    areacode: 269,
    county: "Kalamazoo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49098",
    city: "Watervliet",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49099",
    city: "White Pigeon",
    state: "MI",
    areacode: 269,
    county: "Saint Joseph",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49101",
    city: "Baroda",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49102",
    city: "Berrien Center",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49103",
    city: "Berrien Springs",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49104",
    city: "Berrien Springs",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49106",
    city: "Bridgman",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49107",
    city: "Buchanan",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49111",
    city: "Eau Claire",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49112",
    city: "Edwardsburg",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49113",
    city: "Galien",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49115",
    city: "Harbert",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49116",
    city: "Lakeside",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49117",
    city: "New Buffalo",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49119",
    city: "New Troy",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49120",
    city: "Niles",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49125",
    city: "Sawyer",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49126",
    city: "Sodus",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49127",
    city: "Stevensville",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49128",
    city: "Three Oaks",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49129",
    city: "Union Pier",
    state: "MI",
    areacode: 269,
    county: "Berrien",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49130",
    city: "Union",
    state: "MI",
    areacode: 269,
    county: "Cass",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49201",
    city: "Jackson",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49202",
    city: "Jackson",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49203",
    city: "Jackson",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49204",
    city: "Jackson",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49220",
    city: "Addison",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49221",
    city: "Adrian",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49224",
    city: "Albion",
    state: "MI",
    areacode: 517,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49227",
    city: "Allen",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49228",
    city: "Blissfield",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49229",
    city: "Britton",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49230",
    city: "Brooklyn",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49232",
    city: "Camden",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49233",
    city: "Cement City",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49234",
    city: "Clarklake",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49235",
    city: "Clayton",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49236",
    city: "Clinton",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49237",
    city: "Concord",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49238",
    city: "Deerfield",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49239",
    city: "Frontier",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49240",
    city: "Grass Lake",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49241",
    city: "Hanover",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49242",
    city: "Hillsdale",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49245",
    city: "Homer",
    state: "MI",
    areacode: 517,
    county: "Calhoun",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49246",
    city: "Horton",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49247",
    city: "Hudson",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49248",
    city: "Jasper",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49249",
    city: "Jerome",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49250",
    city: "Jonesville",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49251",
    city: "Leslie",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49252",
    city: "Litchfield",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49253",
    city: "Manitou Beach",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49254",
    city: "Michigan Center",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49255",
    city: "Montgomery",
    state: "MI",
    areacode: 517,
    county: "Branch",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49256",
    city: "Morenci",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49257",
    city: "Moscow",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49258",
    city: "Mosherville",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49259",
    city: "Munith",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49261",
    city: "Napoleon",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49262",
    city: "North Adams",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49263",
    city: "Norvell",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49264",
    city: "Onondaga",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49265",
    city: "Onsted",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49266",
    city: "Osseo",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49267",
    city: "Ottawa Lake",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49268",
    city: "Palmyra",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49269",
    city: "Parma",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49270",
    city: "Petersburg",
    state: "MI",
    areacode: 734,
    county: "Monroe",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49271",
    city: "Pittsford",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49272",
    city: "Pleasant Lake",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49274",
    city: "Reading",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49276",
    city: "Riga",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49277",
    city: "Rives Junction",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49279",
    city: "Sand Creek",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49281",
    city: "Somerset",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49282",
    city: "Somerset Center",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49283",
    city: "Spring Arbor",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49284",
    city: "Springport",
    state: "MI",
    areacode: 517,
    county: "Jackson",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49285",
    city: "Stockbridge",
    state: "MI",
    areacode: 517,
    county: "Ingham",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49286",
    city: "Tecumseh",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49287",
    city: "Tipton",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49288",
    city: "Waldron",
    state: "MI",
    areacode: 517,
    county: "Hillsdale",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49289",
    city: "Weston",
    state: "MI",
    areacode: 517,
    county: "Lenawee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49301",
    city: "Ada",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49302",
    city: "Alto",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49303",
    city: "Bailey",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49304",
    city: "Baldwin",
    state: "MI",
    areacode: 231,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49305",
    city: "Barryton",
    state: "MI",
    areacode: 989,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49306",
    city: "Belmont",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49307",
    city: "Big Rapids",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49309",
    city: "Bitely",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49310",
    city: "Blanchard",
    state: "MI",
    areacode: 989,
    county: "Isabella",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49311",
    city: "Bradley",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49312",
    city: "Brohman",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49314",
    city: "Burnips",
    state: "MI",
    areacode: 616,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49315",
    city: "Byron Center",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49316",
    city: "Caledonia",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49317",
    city: "Cannonsburg",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49318",
    city: "Casnovia",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49319",
    city: "Cedar Springs",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49320",
    city: "Chippewa Lake",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49321",
    city: "Comstock Park",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49322",
    city: "Coral",
    state: "MI",
    areacode: 231,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49323",
    city: "Dorr",
    state: "MI",
    areacode: 616,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49325",
    city: "Freeport",
    state: "MI",
    areacode: 616,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49326",
    city: "Gowen",
    state: "MI",
    areacode: 231,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49327",
    city: "Grant",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49328",
    city: "Hopkins",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49329",
    city: "Howard City",
    state: "MI",
    areacode: 231,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49330",
    city: "Kent City",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49331",
    city: "Lowell",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49332",
    city: "Mecosta",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49333",
    city: "Middleville",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49335",
    city: "Moline",
    state: "MI",
    areacode: 616,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49336",
    city: "Morley",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49337",
    city: "Newaygo",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49338",
    city: "Paris",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49339",
    city: "Pierson",
    state: "MI",
    areacode: 231,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49340",
    city: "Remus",
    state: "MI",
    areacode: 989,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49341",
    city: "Rockford",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49342",
    city: "Rodney",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49343",
    city: "Sand Lake",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49344",
    city: "Shelbyville",
    state: "MI",
    areacode: 269,
    county: "Barry",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49345",
    city: "Sparta",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49346",
    city: "Stanwood",
    state: "MI",
    areacode: 231,
    county: "Mecosta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49347",
    city: "Trufant",
    state: "MI",
    areacode: 231,
    county: "Montcalm",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49348",
    city: "Wayland",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49349",
    city: "White Cloud",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49351",
    city: "Rockford",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49355",
    city: "Ada",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49356",
    city: "Ada",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49357",
    city: "Ada",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49401",
    city: "Allendale",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49402",
    city: "Branch",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49403",
    city: "Conklin",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49404",
    city: "Coopersville",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49405",
    city: "Custer",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49406",
    city: "Douglas",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49408",
    city: "Fennville",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49409",
    city: "Ferrysburg",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49410",
    city: "Fountain",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49411",
    city: "Free Soil",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49412",
    city: "Fremont",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49413",
    city: "Fremont",
    state: "MI",
    areacode: 231,
    county: "Newaygo",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49415",
    city: "Fruitport",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49416",
    city: "Glenn",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49417",
    city: "Grand Haven",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49418",
    city: "Grandville",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49419",
    city: "Hamilton",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49420",
    city: "Hart",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49421",
    city: "Hesperia",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49422",
    city: "Holland",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49423",
    city: "Holland",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49424",
    city: "Holland",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49425",
    city: "Holton",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49426",
    city: "Hudsonville",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49427",
    city: "Jamestown",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49428",
    city: "Jenison",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49429",
    city: "Jenison",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49430",
    city: "Lamont",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49431",
    city: "Ludington",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49434",
    city: "Macatawa",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49435",
    city: "Marne",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49436",
    city: "Mears",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49437",
    city: "Montague",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49440",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49441",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49442",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49443",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49444",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49445",
    city: "Muskegon",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49446",
    city: "New Era",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49448",
    city: "Nunica",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49449",
    city: "Pentwater",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49450",
    city: "Pullman",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49451",
    city: "Ravenna",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49452",
    city: "Rothbury",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49453",
    city: "Saugatuck",
    state: "MI",
    areacode: 269,
    county: "Allegan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49454",
    city: "Scottville",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49455",
    city: "Shelby",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49456",
    city: "Spring Lake",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49457",
    city: "Twin Lake",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49458",
    city: "Walhalla",
    state: "MI",
    areacode: 231,
    county: "Mason",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49459",
    city: "Walkerville",
    state: "MI",
    areacode: 231,
    county: "Oceana",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49460",
    city: "West Olive",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49461",
    city: "Whitehall",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49463",
    city: "Sylvan Beach",
    state: "MI",
    areacode: 231,
    county: "Muskegon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49464",
    city: "Zeeland",
    state: "MI",
    areacode: 616,
    county: "Ottawa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49468",
    city: "Grandville",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49501",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49502",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49503",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49504",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49505",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49506",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49507",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49508",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49509",
    city: "Wyoming",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49510",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49512",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49514",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49515",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49516",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49518",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49519",
    city: "Wyoming",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49523",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49525",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49528",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49530",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49534",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49544",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49546",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49548",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49555",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49560",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49588",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49599",
    city: "Grand Rapids",
    state: "MI",
    areacode: 616,
    county: "Kent",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49601",
    city: "Cadillac",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49610",
    city: "Acme",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49611",
    city: "Alba",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49612",
    city: "Alden",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49613",
    city: "Arcadia",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49614",
    city: "Bear Lake",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49615",
    city: "Bellaire",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49616",
    city: "Benzonia",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49617",
    city: "Beulah",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49618",
    city: "Boon",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49619",
    city: "Brethren",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49620",
    city: "Buckley",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49621",
    city: "Cedar",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49622",
    city: "Central Lake",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49623",
    city: "Chase",
    state: "MI",
    areacode: 231,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49625",
    city: "Copemish",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49626",
    city: "Eastlake",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49627",
    city: "Eastport",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49628",
    city: "Elberta",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49629",
    city: "Elk Rapids",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49630",
    city: "Empire",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49631",
    city: "Evart",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49632",
    city: "Falmouth",
    state: "MI",
    areacode: 231,
    county: "Missaukee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49633",
    city: "Fife Lake",
    state: "MI",
    areacode: 231,
    county: "Kalkaska",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49634",
    city: "Filer City",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49635",
    city: "Frankfort",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49636",
    city: "Glen Arbor",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49637",
    city: "Grawn",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49638",
    city: "Harrietta",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49639",
    city: "Hersey",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49640",
    city: "Honor",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49642",
    city: "Idlewild",
    state: "MI",
    areacode: 231,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49643",
    city: "Interlochen",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49644",
    city: "Irons",
    state: "MI",
    areacode: 231,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49645",
    city: "Kaleva",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49646",
    city: "Kalkaska",
    state: "MI",
    areacode: 231,
    county: "Kalkaska",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49648",
    city: "Kewadin",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49649",
    city: "Kingsley",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49650",
    city: "Lake Ann",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49651",
    city: "Lake City",
    state: "MI",
    areacode: 231,
    county: "Missaukee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49653",
    city: "Lake Leelanau",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49654",
    city: "Leland",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49655",
    city: "Leroy",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49656",
    city: "Luther",
    state: "MI",
    areacode: 231,
    county: "Lake",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49657",
    city: "McBain",
    state: "MI",
    areacode: 231,
    county: "Missaukee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49659",
    city: "Mancelona",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49660",
    city: "Manistee",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49663",
    city: "Manton",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49664",
    city: "Maple City",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49665",
    city: "Marion",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49666",
    city: "Mayfield",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49667",
    city: "Merritt",
    state: "MI",
    areacode: 231,
    county: "Missaukee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49668",
    city: "Mesick",
    state: "MI",
    areacode: 231,
    county: "Wexford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49670",
    city: "Northport",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49673",
    city: "Old Mission",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49674",
    city: "Omena",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49675",
    city: "Onekama",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49676",
    city: "Rapid City",
    state: "MI",
    areacode: 231,
    county: "Kalkaska",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49677",
    city: "Reed City",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49679",
    city: "Sears",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49680",
    city: "South Boardman",
    state: "MI",
    areacode: 231,
    county: "Kalkaska",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49682",
    city: "Suttons Bay",
    state: "MI",
    areacode: 231,
    county: "Leelanau",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49683",
    city: "Thompsonville",
    state: "MI",
    areacode: 231,
    county: "Benzie",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49684",
    city: "Traverse City",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49685",
    city: "Traverse City",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49686",
    city: "Traverse City",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49688",
    city: "Tustin",
    state: "MI",
    areacode: 231,
    county: "Osceola",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49689",
    city: "Wellston",
    state: "MI",
    areacode: 231,
    county: "Manistee",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49690",
    city: "Williamsburg",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49696",
    city: "Traverse City",
    state: "MI",
    areacode: 231,
    county: "Grand Traverse",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49701",
    city: "Mackinaw City",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49705",
    city: "Afton",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49706",
    city: "Alanson",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49707",
    city: "Alpena",
    state: "MI",
    areacode: 989,
    county: "Alpena",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49709",
    city: "Atlanta",
    state: "MI",
    areacode: 989,
    county: "Montmorency",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49710",
    city: "Barbeau",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49711",
    city: "Bay Shore",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49712",
    city: "Boyne City",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49713",
    city: "Boyne Falls",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49715",
    city: "Brimley",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49716",
    city: "Brutus",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49717",
    city: "Burt Lake",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49718",
    city: "Carp Lake",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49719",
    city: "Cedarville",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49720",
    city: "Charlevoix",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49721",
    city: "Cheboygan",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49722",
    city: "Conway",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49723",
    city: "Cross Village",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49724",
    city: "Dafter",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49725",
    city: "De Tour Village",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49726",
    city: "Drummond Island",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49727",
    city: "East Jordan",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49728",
    city: "Eckerman",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49729",
    city: "Ellsworth",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49730",
    city: "Elmira",
    state: "MI",
    areacode: 231,
    county: "Antrim",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49733",
    city: "Frederic",
    state: "MI",
    areacode: 989,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49734",
    city: "Gaylord",
    state: "MI",
    areacode: 989,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49735",
    city: "Gaylord",
    state: "MI",
    areacode: 989,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49736",
    city: "Goetzville",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49737",
    city: "Good Hart",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49738",
    city: "Grayling",
    state: "MI",
    areacode: 989,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49739",
    city: "Grayling",
    state: "MI",
    areacode: 989,
    county: "Crawford",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49740",
    city: "Harbor Springs",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49743",
    city: "Hawks",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49744",
    city: "Herron",
    state: "MI",
    areacode: 989,
    county: "Alpena",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49745",
    city: "Hessel",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49746",
    city: "Hillman",
    state: "MI",
    areacode: 989,
    county: "Montmorency",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49747",
    city: "Hubbard Lake",
    state: "MI",
    areacode: 989,
    county: "Alpena",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49748",
    city: "Hulbert",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49749",
    city: "Indian River",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49751",
    city: "Johannesburg",
    state: "MI",
    areacode: 989,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49752",
    city: "Kinross",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49753",
    city: "Lachine",
    state: "MI",
    areacode: 989,
    county: "Alpena",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49755",
    city: "Levering",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49756",
    city: "Lewiston",
    state: "MI",
    areacode: 989,
    county: "Montmorency",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49757",
    city: "Mackinac Island",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49759",
    city: "Millersburg",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49760",
    city: "Moran",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49761",
    city: "Mullett Lake",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49762",
    city: "Naubinway",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49764",
    city: "Oden",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49765",
    city: "Onaway",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49766",
    city: "Ossineke",
    state: "MI",
    areacode: 989,
    county: "Alpena",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49768",
    city: "Paradise",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49769",
    city: "Pellston",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49770",
    city: "Petoskey",
    state: "MI",
    areacode: 231,
    county: "Emmet",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49774",
    city: "Pickford",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49775",
    city: "Pointe Aux Pins",
    state: "MI",
    areacode: 231,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49776",
    city: "Posen",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49777",
    city: "Presque Isle",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49779",
    city: "Rogers City",
    state: "MI",
    areacode: 989,
    county: "Presque Isle",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49780",
    city: "Rudyard",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49781",
    city: "Saint Ignace",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49782",
    city: "Beaver Island",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49783",
    city: "Sault Sainte Marie",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49784",
    city: "Kincheloe",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49785",
    city: "Kincheloe",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49786",
    city: "Kincheloe",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49788",
    city: "Kincheloe",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49791",
    city: "Topinabee",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49792",
    city: "Tower",
    state: "MI",
    areacode: 989,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49793",
    city: "Trout Lake",
    state: "MI",
    areacode: 906,
    county: "Chippewa",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49795",
    city: "Vanderbilt",
    state: "MI",
    areacode: 989,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49796",
    city: "Walloon Lake",
    state: "MI",
    areacode: 231,
    county: "Charlevoix",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49797",
    city: "Waters",
    state: "MI",
    areacode: 989,
    county: "Otsego",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49799",
    city: "Wolverine",
    state: "MI",
    areacode: 231,
    county: "Cheboygan",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49801",
    city: "Iron Mountain",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49802",
    city: "Kingsford",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49805",
    city: "Allouez",
    state: "MI",
    areacode: 906,
    county: "Keweenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49806",
    city: "Au Train",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49807",
    city: "Bark River",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49808",
    city: "Big Bay",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49812",
    city: "Carney",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49814",
    city: "Champion",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49815",
    city: "Channing",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49816",
    city: "Chatham",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49817",
    city: "Cooks",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49818",
    city: "Cornell",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49819",
    city: "Arnold",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49820",
    city: "Curtis",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49821",
    city: "Daggett",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49822",
    city: "Deerton",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49825",
    city: "Eben Junction",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49826",
    city: "Rumely",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49827",
    city: "Engadine",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49829",
    city: "Escanaba",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49831",
    city: "Felch",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49833",
    city: "Little Lake",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49834",
    city: "Foster City",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49835",
    city: "Garden",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49836",
    city: "Germfask",
    state: "MI",
    areacode: 906,
    county: "Schoolcraft",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49837",
    city: "Gladstone",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49838",
    city: "Gould City",
    state: "MI",
    areacode: 906,
    county: "Mackinac",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49839",
    city: "Grand Marais",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49840",
    city: "Gulliver",
    state: "MI",
    areacode: 906,
    county: "Schoolcraft",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49841",
    city: "Gwinn",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49845",
    city: "Harris",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49847",
    city: "Hermansville",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49848",
    city: "Ingalls",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49849",
    city: "Ishpeming",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49852",
    city: "Loretto",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49853",
    city: "McMillan",
    state: "MI",
    areacode: 906,
    county: "Luce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49854",
    city: "Manistique",
    state: "MI",
    areacode: 906,
    county: "Schoolcraft",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49855",
    city: "Marquette",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49858",
    city: "Menominee",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49861",
    city: "Michigamme",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49862",
    city: "Munising",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49863",
    city: "Nadeau",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49864",
    city: "Nahma",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49865",
    city: "National Mine",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49866",
    city: "Negaunee",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49868",
    city: "Newberry",
    state: "MI",
    areacode: 906,
    county: "Luce",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49870",
    city: "Norway",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49871",
    city: "Palmer",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49872",
    city: "Perkins",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49873",
    city: "Perronville",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49874",
    city: "Powers",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49876",
    city: "Quinnesec",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49877",
    city: "Ralph",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49878",
    city: "Rapid River",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49879",
    city: "Republic",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49880",
    city: "Rock",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49881",
    city: "Sagola",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49883",
    city: "Seney",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49884",
    city: "Shingleton",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49885",
    city: "Skandia",
    state: "MI",
    areacode: 906,
    county: "Marquette",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49886",
    city: "Spalding",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49887",
    city: "Stephenson",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49891",
    city: "Trenary",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49892",
    city: "Vulcan",
    state: "MI",
    areacode: 906,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49893",
    city: "Wallace",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49894",
    city: "Wells",
    state: "MI",
    areacode: 906,
    county: "Delta",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49895",
    city: "Wetmore",
    state: "MI",
    areacode: 906,
    county: "Alger",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49896",
    city: "Wilson",
    state: "MI",
    areacode: 906,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49901",
    city: "Ahmeek",
    state: "MI",
    areacode: 906,
    county: "Keweenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49902",
    city: "Alpha",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49903",
    city: "Amasa",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49905",
    city: "Atlantic Mine",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49908",
    city: "Baraga",
    state: "MI",
    areacode: 906,
    county: "Baraga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49910",
    city: "Bergland",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49911",
    city: "Bessemer",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49912",
    city: "Bruce Crossing",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49913",
    city: "Calumet",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49915",
    city: "Caspian",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49916",
    city: "Chassell",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49917",
    city: "Copper City",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49918",
    city: "Copper Harbor",
    state: "MI",
    areacode: 906,
    county: "Keweenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49919",
    city: "Covington",
    state: "MI",
    areacode: 906,
    county: "Baraga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49920",
    city: "Crystal Falls",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49921",
    city: "Dodgeville",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49922",
    city: "Dollar Bay",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49925",
    city: "Ewen",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49927",
    city: "Gaastra",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49929",
    city: "Greenland",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49930",
    city: "Hancock",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49931",
    city: "Houghton",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49934",
    city: "Hubbell",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49935",
    city: "Iron River",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49938",
    city: "Ironwood",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49942",
    city: "Kearsarge",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49945",
    city: "Lake Linden",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49946",
    city: "Lanse",
    state: "MI",
    areacode: 906,
    county: "Baraga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49947",
    city: "Marenisco",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49948",
    city: "Mass City",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49950",
    city: "Mohawk",
    state: "MI",
    areacode: 906,
    county: "Keweenaw",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49952",
    city: "Nisula",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49953",
    city: "Ontonagon",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49955",
    city: "Painesdale",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49958",
    city: "Pelkie",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49959",
    city: "Ramsay",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49960",
    city: "Rockland",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49961",
    city: "Sidnaw",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49962",
    city: "Skanee",
    state: "MI",
    areacode: 906,
    county: "Baraga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49963",
    city: "South Range",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49964",
    city: "Stambaugh",
    state: "MI",
    areacode: 906,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49965",
    city: "Toivola",
    state: "MI",
    areacode: 906,
    county: "Houghton",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49967",
    city: "Trout Creek",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49968",
    city: "Wakefield",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49969",
    city: "Watersmeet",
    state: "MI",
    areacode: 906,
    county: "Gogebic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "49970",
    city: "Watton",
    state: "MI",
    areacode: 906,
    county: "Baraga",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "49971",
    city: "White Pine",
    state: "MI",
    areacode: 906,
    county: "Ontonagon",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "50001",
    city: "Ackworth",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50002",
    city: "Adair",
    state: "IA",
    areacode: 641,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50003",
    city: "Adel",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50005",
    city: "Albion",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50006",
    city: "Alden",
    state: "IA",
    areacode: 515,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50007",
    city: "Alleman",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50008",
    city: "Allerton",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50009",
    city: "Altoona",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50010",
    city: "Ames",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50011",
    city: "Ames",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50012",
    city: "Ames",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50013",
    city: "Ames",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50014",
    city: "Ames",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50020",
    city: "Anita",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50021",
    city: "Ankeny",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50022",
    city: "Atlantic",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50023",
    city: "Ankeny",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50025",
    city: "Audubon",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50026",
    city: "Bagley",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50027",
    city: "Barnes City",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50028",
    city: "Baxter",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50029",
    city: "Bayard",
    state: "IA",
    areacode: 712,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50031",
    city: "Beaver",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50032",
    city: "Berwick",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50033",
    city: "Bevington",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50034",
    city: "Blairsburg",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50035",
    city: "Bondurant",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50036",
    city: "Boone",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50037",
    city: "Boone",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50038",
    city: "Booneville",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50039",
    city: "Bouton",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50040",
    city: "Boxholm",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50041",
    city: "Bradford",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50042",
    city: "Brayton",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50043",
    city: "Buckeye",
    state: "IA",
    areacode: 515,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50044",
    city: "Bussey",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50046",
    city: "Cambridge",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50047",
    city: "Carlisle",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50048",
    city: "Casey",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50049",
    city: "Chariton",
    state: "IA",
    areacode: 641,
    county: "Lucas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50050",
    city: "Churdan",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50051",
    city: "Clemons",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50052",
    city: "Clio",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50054",
    city: "Colfax",
    state: "IA",
    areacode: 515,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50055",
    city: "Collins",
    state: "IA",
    areacode: 641,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50056",
    city: "Colo",
    state: "IA",
    areacode: 641,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50057",
    city: "Columbia",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50058",
    city: "Coon Rapids",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50059",
    city: "Cooper",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50060",
    city: "Corydon",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50061",
    city: "Cumming",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50062",
    city: "Melcher Dallas",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50063",
    city: "Dallas Center",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50064",
    city: "Dana",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50065",
    city: "Davis City",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50066",
    city: "Dawson",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50067",
    city: "Decatur",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50068",
    city: "Derby",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50069",
    city: "De Soto",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50070",
    city: "Dexter",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50071",
    city: "Dows",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50072",
    city: "Earlham",
    state: "IA",
    areacode: 515,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50073",
    city: "Elkhart",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50074",
    city: "Ellston",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50075",
    city: "Ellsworth",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50076",
    city: "Exira",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50078",
    city: "Ferguson",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50099",
    city: "Boone",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50101",
    city: "Galt",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50102",
    city: "Garden City",
    state: "IA",
    areacode: 515,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50103",
    city: "Garden Grove",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50104",
    city: "Gibson",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50105",
    city: "Gilbert",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50106",
    city: "Gilman",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50107",
    city: "Grand Junction",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50108",
    city: "Grand River",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50109",
    city: "Granger",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50110",
    city: "Gray",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50111",
    city: "Grimes",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50112",
    city: "Grinnell",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50115",
    city: "Guthrie Center",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50116",
    city: "Hamilton",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50117",
    city: "Hamlin",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50118",
    city: "Hartford",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50119",
    city: "Harvey",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50120",
    city: "Haverhill",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50122",
    city: "Hubbard",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50123",
    city: "Humeston",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50124",
    city: "Huxley",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50125",
    city: "Indianola",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50126",
    city: "Iowa Falls",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50127",
    city: "Ira",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50128",
    city: "Jamaica",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50129",
    city: "Jefferson",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50130",
    city: "Jewell",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50131",
    city: "Johnston",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50132",
    city: "Kamrar",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50133",
    city: "Kellerton",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50134",
    city: "Kelley",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50135",
    city: "Kellogg",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50136",
    city: "Keswick",
    state: "IA",
    areacode: 319,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50137",
    city: "Killduff",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50138",
    city: "Knoxville",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50139",
    city: "Lacona",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50140",
    city: "Lamoni",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50141",
    city: "Laurel",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50142",
    city: "Le Grand",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50143",
    city: "Leighton",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50144",
    city: "Leon",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50145",
    city: "Liberty Center",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50146",
    city: "Linden",
    state: "IA",
    areacode: 641,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50147",
    city: "Lineville",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50148",
    city: "Liscomb",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50149",
    city: "Lorimor",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50150",
    city: "Lovilia",
    state: "IA",
    areacode: 641,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50151",
    city: "Lucas",
    state: "IA",
    areacode: 641,
    county: "Lucas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50152",
    city: "Luther",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50153",
    city: "Lynnville",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50154",
    city: "McCallsburg",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50155",
    city: "Macksburg",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50156",
    city: "Madrid",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50157",
    city: "Malcom",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50158",
    city: "Marshalltown",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50160",
    city: "Martensdale",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50161",
    city: "Maxwell",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50162",
    city: "Melbourne",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50163",
    city: "Melcher Dallas",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50164",
    city: "Menlo",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50165",
    city: "Millerton",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50166",
    city: "Milo",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50167",
    city: "Minburn",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50168",
    city: "Mingo",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50169",
    city: "Mitchellville",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50170",
    city: "Monroe",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50171",
    city: "Montezuma",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50173",
    city: "Montour",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50174",
    city: "Murray",
    state: "IA",
    areacode: 641,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50201",
    city: "Nevada",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50206",
    city: "New Providence",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50207",
    city: "New Sharon",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50208",
    city: "Newton",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50210",
    city: "New Virginia",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50211",
    city: "Norwalk",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50212",
    city: "Ogden",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50213",
    city: "Osceola",
    state: "IA",
    areacode: 641,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50214",
    city: "Otley",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50216",
    city: "Panora",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50217",
    city: "Paton",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50218",
    city: "Patterson",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50219",
    city: "Pella",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50220",
    city: "Perry",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50222",
    city: "Peru",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50223",
    city: "Pilot Mound",
    state: "IA",
    areacode: 515,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50225",
    city: "Pleasantville",
    state: "IA",
    areacode: 515,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50226",
    city: "Polk City",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50227",
    city: "Popejoy",
    state: "IA",
    areacode: 515,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50228",
    city: "Prairie City",
    state: "IA",
    areacode: 515,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50229",
    city: "Prole",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50230",
    city: "Radcliffe",
    state: "IA",
    areacode: 515,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50231",
    city: "Randall",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50232",
    city: "Reasnor",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50233",
    city: "Redfield",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50234",
    city: "Rhodes",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50235",
    city: "Rippey",
    state: "IA",
    areacode: 515,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50236",
    city: "Roland",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50237",
    city: "Runnells",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50238",
    city: "Russell",
    state: "IA",
    areacode: 641,
    county: "Lucas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50239",
    city: "Saint Anthony",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50240",
    city: "Saint Charles",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50241",
    city: "Saint Marys",
    state: "IA",
    areacode: 641,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50242",
    city: "Searsboro",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50243",
    city: "Sheldahl",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50244",
    city: "Slater",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50246",
    city: "Stanhope",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50247",
    city: "State Center",
    state: "IA",
    areacode: 641,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50248",
    city: "Story City",
    state: "IA",
    areacode: 515,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50249",
    city: "Stratford",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50250",
    city: "Stuart",
    state: "IA",
    areacode: 515,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50251",
    city: "Sully",
    state: "IA",
    areacode: 641,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50252",
    city: "Swan",
    state: "IA",
    areacode: 515,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50254",
    city: "Thayer",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50255",
    city: "Thornburg",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50256",
    city: "Tracy",
    state: "IA",
    areacode: 641,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50257",
    city: "Truro",
    state: "IA",
    areacode: 641,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50258",
    city: "Union",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50259",
    city: "Gifford",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50261",
    city: "Van Meter",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50262",
    city: "Van Wert",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50263",
    city: "Waukee",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50264",
    city: "Weldon",
    state: "IA",
    areacode: 641,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50265",
    city: "West Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50266",
    city: "West Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50268",
    city: "What Cheer",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50269",
    city: "Whitten",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50271",
    city: "Williams",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50272",
    city: "Williamson",
    state: "IA",
    areacode: 641,
    county: "Lucas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50273",
    city: "Winterset",
    state: "IA",
    areacode: 515,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50274",
    city: "Wiota",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50275",
    city: "Woodburn",
    state: "IA",
    areacode: 641,
    county: "Clarke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50276",
    city: "Woodward",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50277",
    city: "Yale",
    state: "IA",
    areacode: 641,
    county: "Guthrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50278",
    city: "Zearing",
    state: "IA",
    areacode: 641,
    county: "Story",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50301",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50302",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50303",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50304",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50305",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50306",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50307",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50308",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50309",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50310",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50311",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50312",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50313",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50314",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50315",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50316",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50317",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50318",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50319",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50320",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50321",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50322",
    city: "Urbandale",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50323",
    city: "Urbandale",
    state: "IA",
    areacode: 515,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50324",
    city: "Windsor Heights",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50325",
    city: "Clive",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50327",
    city: "Pleasant Hill",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50328",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50329",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50330",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50331",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50332",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50333",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50334",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50335",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50336",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50339",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50340",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50359",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50360",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50361",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50362",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50363",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50364",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50367",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50368",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50369",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50380",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50381",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50391",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50392",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50393",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50394",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50395",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50396",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50398",
    city: "Urbandale",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50401",
    city: "Mason City",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50402",
    city: "Mason City",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50420",
    city: "Alexander",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50421",
    city: "Belmond",
    state: "IA",
    areacode: 641,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50423",
    city: "Britt",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50424",
    city: "Buffalo Center",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50426",
    city: "Carpenter",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50427",
    city: "Chapin",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50428",
    city: "Clear Lake",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50430",
    city: "Corwith",
    state: "IA",
    areacode: 515,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50431",
    city: "Coulter",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50432",
    city: "Crystal Lake",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50433",
    city: "Dougherty",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50434",
    city: "Fertile",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50435",
    city: "Floyd",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50436",
    city: "Forest City",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50438",
    city: "Garner",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50439",
    city: "Goodell",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50440",
    city: "Grafton",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50441",
    city: "Hampton",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50444",
    city: "Hanlontown",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50446",
    city: "Joice",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50447",
    city: "Kanawha",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50448",
    city: "Kensett",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50449",
    city: "Klemme",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50450",
    city: "Lake Mills",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50451",
    city: "Lakota",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50452",
    city: "Latimer",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50453",
    city: "Leland",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50454",
    city: "Little Cedar",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50455",
    city: "McIntire",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50456",
    city: "Manly",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50457",
    city: "Meservey",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50458",
    city: "Nora Springs",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50459",
    city: "Northwood",
    state: "IA",
    areacode: 641,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50460",
    city: "Orchard",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50461",
    city: "Osage",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50464",
    city: "Plymouth",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50465",
    city: "Rake",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50466",
    city: "Riceville",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50467",
    city: "Rock Falls",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50468",
    city: "Rockford",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50469",
    city: "Rockwell",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50470",
    city: "Rowan",
    state: "IA",
    areacode: 641,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50471",
    city: "Rudd",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50472",
    city: "Saint Ansgar",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50473",
    city: "Scarville",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50475",
    city: "Sheffield",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50476",
    city: "Stacyville",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50477",
    city: "Swaledale",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50478",
    city: "Thompson",
    state: "IA",
    areacode: 641,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50479",
    city: "Thornton",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50480",
    city: "Titonka",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50481",
    city: "Toeterville",
    state: "IA",
    areacode: 641,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50482",
    city: "Ventura",
    state: "IA",
    areacode: 641,
    county: "Cerro Gordo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50483",
    city: "Wesley",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50484",
    city: "Woden",
    state: "IA",
    areacode: 641,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50501",
    city: "Fort Dodge",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50510",
    city: "Albert City",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50511",
    city: "Algona",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50514",
    city: "Armstrong",
    state: "IA",
    areacode: 712,
    county: "Emmet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50515",
    city: "Ayrshire",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50516",
    city: "Badger",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50517",
    city: "Bancroft",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50518",
    city: "Barnum",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50519",
    city: "Bode",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50520",
    city: "Bradgate",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50521",
    city: "Burnside",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50522",
    city: "Burt",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50523",
    city: "Callender",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50524",
    city: "Clare",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50525",
    city: "Clarion",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50526",
    city: "Clarion",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50527",
    city: "Curlew",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50528",
    city: "Cylinder",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50529",
    city: "Dakota City",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50530",
    city: "Dayton",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50531",
    city: "Dolliver",
    state: "IA",
    areacode: 712,
    county: "Emmet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50532",
    city: "Duncombe",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50533",
    city: "Eagle Grove",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50535",
    city: "Early",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50536",
    city: "Emmetsburg",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50538",
    city: "Farnhamville",
    state: "IA",
    areacode: 515,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50539",
    city: "Fenton",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50540",
    city: "Fonda",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50541",
    city: "Gilmore City",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50542",
    city: "Goldfield",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50543",
    city: "Gowrie",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50544",
    city: "Harcourt",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50545",
    city: "Hardy",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50546",
    city: "Havelock",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50548",
    city: "Humboldt",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50551",
    city: "Jolley",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50552",
    city: "Knierim",
    state: "IA",
    areacode: 515,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50554",
    city: "Laurens",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50556",
    city: "Ledyard",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50557",
    city: "Lehigh",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50558",
    city: "Livermore",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50559",
    city: "Lone Rock",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50560",
    city: "Lu Verne",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50561",
    city: "Lytton",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50562",
    city: "Mallard",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50563",
    city: "Manson",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50565",
    city: "Marathon",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50566",
    city: "Moorland",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50567",
    city: "Nemaha",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50568",
    city: "Newell",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50569",
    city: "Otho",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50570",
    city: "Ottosen",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50571",
    city: "Palmer",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50573",
    city: "Plover",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50574",
    city: "Pocahontas",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50575",
    city: "Pomeroy",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50576",
    city: "Rembrandt",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50577",
    city: "Renwick",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50578",
    city: "Ringsted",
    state: "IA",
    areacode: 712,
    county: "Emmet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50579",
    city: "Rockwell City",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50581",
    city: "Rolfe",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50582",
    city: "Rutland",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50583",
    city: "Sac City",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50585",
    city: "Sioux Rapids",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50586",
    city: "Somers",
    state: "IA",
    areacode: 515,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50588",
    city: "Storm Lake",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50590",
    city: "Swea City",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50591",
    city: "Thor",
    state: "IA",
    areacode: 515,
    county: "Humboldt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50592",
    city: "Truesdale",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50593",
    city: "Varina",
    state: "IA",
    areacode: 712,
    county: "Pocahontas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50594",
    city: "Vincent",
    state: "IA",
    areacode: 515,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50595",
    city: "Webster City",
    state: "IA",
    areacode: 515,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50597",
    city: "West Bend",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50598",
    city: "Whittemore",
    state: "IA",
    areacode: 515,
    county: "Kossuth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50599",
    city: "Woolstock",
    state: "IA",
    areacode: 515,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50601",
    city: "Ackley",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50602",
    city: "Allison",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50603",
    city: "Alta Vista",
    state: "IA",
    areacode: 641,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50604",
    city: "Aplington",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50605",
    city: "Aredale",
    state: "IA",
    areacode: 641,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50606",
    city: "Arlington",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50607",
    city: "Aurora",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50608",
    city: "Austinville",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50609",
    city: "Beaman",
    state: "IA",
    areacode: 641,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50611",
    city: "Bristow",
    state: "IA",
    areacode: 641,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50612",
    city: "Buckingham",
    state: "IA",
    areacode: 319,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50613",
    city: "Cedar Falls",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50614",
    city: "Cedar Falls",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50616",
    city: "Charles City",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50619",
    city: "Clarksville",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50620",
    city: "Colwell",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50621",
    city: "Conrad",
    state: "IA",
    areacode: 641,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50622",
    city: "Denver",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50623",
    city: "Dewar",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50624",
    city: "Dike",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50625",
    city: "Dumont",
    state: "IA",
    areacode: 641,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50626",
    city: "Dunkerton",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50627",
    city: "Eldora",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50628",
    city: "Elma",
    state: "IA",
    areacode: 641,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50629",
    city: "Fairbank",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50630",
    city: "Fredericksburg",
    state: "IA",
    areacode: 563,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50631",
    city: "Frederika",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50632",
    city: "Garwin",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50633",
    city: "Geneva",
    state: "IA",
    areacode: 641,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50634",
    city: "Gilbertville",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50635",
    city: "Gladbrook",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50636",
    city: "Greene",
    state: "IA",
    areacode: 641,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50638",
    city: "Grundy Center",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50641",
    city: "Hazleton",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50642",
    city: "Holland",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50643",
    city: "Hudson",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50644",
    city: "Independence",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50645",
    city: "Ionia",
    state: "IA",
    areacode: 641,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50647",
    city: "Janesville",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50648",
    city: "Jesup",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50649",
    city: "Kesley",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50650",
    city: "Lamont",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50651",
    city: "La Porte City",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50652",
    city: "Lincoln",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50653",
    city: "Marble Rock",
    state: "IA",
    areacode: 641,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50654",
    city: "Masonville",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50655",
    city: "Maynard",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50657",
    city: "Morrison",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50658",
    city: "Nashua",
    state: "IA",
    areacode: 641,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50659",
    city: "New Hampton",
    state: "IA",
    areacode: 641,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50660",
    city: "New Hartford",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50661",
    city: "North Washington",
    state: "IA",
    areacode: 641,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50662",
    city: "Oelwein",
    state: "IA",
    areacode: 319,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50664",
    city: "Oran",
    state: "IA",
    areacode: 319,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50665",
    city: "Parkersburg",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50666",
    city: "Plainfield",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50667",
    city: "Raymond",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50668",
    city: "Readlyn",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50669",
    city: "Reinbeck",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50670",
    city: "Shell Rock",
    state: "IA",
    areacode: 319,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50671",
    city: "Stanley",
    state: "IA",
    areacode: 319,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50672",
    city: "Steamboat Rock",
    state: "IA",
    areacode: 641,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50673",
    city: "Stout",
    state: "IA",
    areacode: 319,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50674",
    city: "Sumner",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50675",
    city: "Traer",
    state: "IA",
    areacode: 319,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50676",
    city: "Tripoli",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50677",
    city: "Waverly",
    state: "IA",
    areacode: 319,
    county: "Bremer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50680",
    city: "Wellsburg",
    state: "IA",
    areacode: 641,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50681",
    city: "Westgate",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50682",
    city: "Winthrop",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50701",
    city: "Waterloo",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50702",
    city: "Waterloo",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50703",
    city: "Waterloo",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50704",
    city: "Waterloo",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50707",
    city: "Evansdale",
    state: "IA",
    areacode: 319,
    county: "Black Hawk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50801",
    city: "Creston",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50830",
    city: "Afton",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50831",
    city: "Arispe",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50833",
    city: "Bedford",
    state: "IA",
    areacode: 712,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50835",
    city: "Benton",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50836",
    city: "Blockton",
    state: "IA",
    areacode: 641,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50837",
    city: "Bridgewater",
    state: "IA",
    areacode: 641,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50839",
    city: "Carbon",
    state: "IA",
    areacode: 641,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50840",
    city: "Clearfield",
    state: "IA",
    areacode: 641,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50841",
    city: "Corning",
    state: "IA",
    areacode: 641,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50842",
    city: "Cromwell",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50843",
    city: "Cumberland",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50845",
    city: "Diagonal",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50846",
    city: "Fontanelle",
    state: "IA",
    areacode: 641,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50847",
    city: "Grant",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50848",
    city: "Gravity",
    state: "IA",
    areacode: 712,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50849",
    city: "Greenfield",
    state: "IA",
    areacode: 641,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50851",
    city: "Lenox",
    state: "IA",
    areacode: 641,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50853",
    city: "Massena",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50854",
    city: "Mount Ayr",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50857",
    city: "Nodaway",
    state: "IA",
    areacode: 712,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50858",
    city: "Orient",
    state: "IA",
    areacode: 641,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50859",
    city: "Prescott",
    state: "IA",
    areacode: 641,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50860",
    city: "Redding",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50861",
    city: "Shannon City",
    state: "IA",
    areacode: 641,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50862",
    city: "Sharpsburg",
    state: "IA",
    areacode: 641,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50863",
    city: "Tingley",
    state: "IA",
    areacode: 641,
    county: "Ringgold",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50864",
    city: "Villisca",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50936",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50940",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50947",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50950",
    city: "Boone",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50980",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50981",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50982",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "50983",
    city: "Des Moines",
    state: "IA",
    areacode: 515,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51001",
    city: "Akron",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51002",
    city: "Alta",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51003",
    city: "Alton",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51004",
    city: "Anthon",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51005",
    city: "Aurelia",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51006",
    city: "Battle Creek",
    state: "IA",
    areacode: 712,
    county: "Ida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51007",
    city: "Bronson",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51008",
    city: "Brunsville",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51009",
    city: "Calumet",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51010",
    city: "Castana",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51011",
    city: "Chatsworth",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51012",
    city: "Cherokee",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51014",
    city: "Cleghorn",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51015",
    city: "Climbing Hill",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51016",
    city: "Correctionville",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51018",
    city: "Cushing",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51019",
    city: "Danbury",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51020",
    city: "Galva",
    state: "IA",
    areacode: 712,
    county: "Ida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51022",
    city: "Granville",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51023",
    city: "Hawarden",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51024",
    city: "Hinton",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51025",
    city: "Holstein",
    state: "IA",
    areacode: 712,
    county: "Ida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51026",
    city: "Hornick",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51027",
    city: "Ireton",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51028",
    city: "Kingsley",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51029",
    city: "Larrabee",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51030",
    city: "Lawton",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51031",
    city: "Le Mars",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51033",
    city: "Linn Grove",
    state: "IA",
    areacode: 712,
    county: "Buena Vista",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51034",
    city: "Mapleton",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51035",
    city: "Marcus",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51036",
    city: "Maurice",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51037",
    city: "Meriden",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51038",
    city: "Merrill",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51039",
    city: "Moville",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51040",
    city: "Onawa",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51041",
    city: "Orange City",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51044",
    city: "Oto",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51045",
    city: "Oyens",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51046",
    city: "Paullina",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51047",
    city: "Peterson",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51048",
    city: "Pierson",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51049",
    city: "Quimby",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51050",
    city: "Remsen",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51051",
    city: "Rodney",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51052",
    city: "Salix",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51053",
    city: "Schaller",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51054",
    city: "Sergeant Bluff",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51055",
    city: "Sloan",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51056",
    city: "Smithland",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51058",
    city: "Sutherland",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51060",
    city: "Ute",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51061",
    city: "Washta",
    state: "IA",
    areacode: 712,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51062",
    city: "Westfield",
    state: "IA",
    areacode: 712,
    county: "Plymouth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51063",
    city: "Whiting",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51101",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51102",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51103",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51104",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51105",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51106",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51108",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51109",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51111",
    city: "Sioux City",
    state: "IA",
    areacode: 712,
    county: "Woodbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51201",
    city: "Sheldon",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51230",
    city: "Alvord",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51231",
    city: "Archer",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51232",
    city: "Ashton",
    state: "IA",
    areacode: 712,
    county: "Osceola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51234",
    city: "Boyden",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51235",
    city: "Doon",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51237",
    city: "George",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51238",
    city: "Hospers",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51239",
    city: "Hull",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51240",
    city: "Inwood",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51241",
    city: "Larchwood",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51242",
    city: "Lester",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51243",
    city: "Little Rock",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51244",
    city: "Matlock",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51245",
    city: "Primghar",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51246",
    city: "Rock Rapids",
    state: "IA",
    areacode: 712,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51247",
    city: "Rock Valley",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51248",
    city: "Sanborn",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51249",
    city: "Sibley",
    state: "IA",
    areacode: 712,
    county: "Osceola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51250",
    city: "Sioux Center",
    state: "IA",
    areacode: 712,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51301",
    city: "Spencer",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51331",
    city: "Arnolds Park",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51333",
    city: "Dickens",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51334",
    city: "Estherville",
    state: "IA",
    areacode: 712,
    county: "Emmet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51338",
    city: "Everly",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51340",
    city: "Fostoria",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51341",
    city: "Gillett Grove",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51342",
    city: "Graettinger",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51343",
    city: "Greenville",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51345",
    city: "Harris",
    state: "IA",
    areacode: 712,
    county: "Osceola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51346",
    city: "Hartley",
    state: "IA",
    areacode: 712,
    county: "Obrien",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51347",
    city: "Lake Park",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51350",
    city: "Melvin",
    state: "IA",
    areacode: 712,
    county: "Osceola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51351",
    city: "Milford",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51354",
    city: "Ocheyedan",
    state: "IA",
    areacode: 712,
    county: "Osceola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51355",
    city: "Okoboji",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51357",
    city: "Royal",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51358",
    city: "Ruthven",
    state: "IA",
    areacode: 712,
    county: "Palo Alto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51360",
    city: "Spirit Lake",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51363",
    city: "Superior",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51364",
    city: "Terril",
    state: "IA",
    areacode: 712,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51365",
    city: "Wallingford",
    state: "IA",
    areacode: 712,
    county: "Emmet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51366",
    city: "Webb",
    state: "IA",
    areacode: 712,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51401",
    city: "Carroll",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51430",
    city: "Arcadia",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51431",
    city: "Arthur",
    state: "IA",
    areacode: 712,
    county: "Ida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51432",
    city: "Aspinwall",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51433",
    city: "Auburn",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51436",
    city: "Breda",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51439",
    city: "Charter Oak",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51440",
    city: "Dedham",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51441",
    city: "Deloit",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51442",
    city: "Denison",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51443",
    city: "Glidden",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51444",
    city: "Halbur",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51445",
    city: "Ida Grove",
    state: "IA",
    areacode: 712,
    county: "Ida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51446",
    city: "Irwin",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51447",
    city: "Kirkman",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51448",
    city: "Kiron",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51449",
    city: "Lake City",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51450",
    city: "Lake View",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51451",
    city: "Lanesboro",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51452",
    city: "Lidderdale",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51453",
    city: "Lohrville",
    state: "IA",
    areacode: 712,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51454",
    city: "Manilla",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51455",
    city: "Manning",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51458",
    city: "Odebolt",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51459",
    city: "Ralston",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51460",
    city: "Ricketts",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51461",
    city: "Schleswig",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51462",
    city: "Scranton",
    state: "IA",
    areacode: 712,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51463",
    city: "Templeton",
    state: "IA",
    areacode: 712,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51465",
    city: "Vail",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51466",
    city: "Wall Lake",
    state: "IA",
    areacode: 712,
    county: "Sac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51467",
    city: "Westside",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51501",
    city: "Council Bluffs",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51502",
    city: "Council Bluffs",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51503",
    city: "Council Bluffs",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51510",
    city: "Carter Lake",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51520",
    city: "Arion",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51521",
    city: "Avoca",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51523",
    city: "Blencoe",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51525",
    city: "Carson",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51526",
    city: "Crescent",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51527",
    city: "Defiance",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51528",
    city: "Dow City",
    state: "IA",
    areacode: 712,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51529",
    city: "Dunlap",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51530",
    city: "Earling",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51531",
    city: "Elk Horn",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51532",
    city: "Elliott",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51533",
    city: "Emerson",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51534",
    city: "Glenwood",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51535",
    city: "Griswold",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51536",
    city: "Hancock",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51537",
    city: "Harlan",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51540",
    city: "Hastings",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51541",
    city: "Henderson",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51542",
    city: "Honey Creek",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51543",
    city: "Kimballton",
    state: "IA",
    areacode: 712,
    county: "Audubon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51544",
    city: "Lewis",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51545",
    city: "Little Sioux",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51546",
    city: "Logan",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51548",
    city: "McClelland",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51549",
    city: "Macedonia",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51550",
    city: "Magnolia",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51551",
    city: "Malvern",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51552",
    city: "Marne",
    state: "IA",
    areacode: 712,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51553",
    city: "Minden",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51554",
    city: "Mineola",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51555",
    city: "Missouri Valley",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51556",
    city: "Modale",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51557",
    city: "Mondamin",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51558",
    city: "Moorhead",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51559",
    city: "Neola",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51560",
    city: "Oakland",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51561",
    city: "Pacific Junction",
    state: "IA",
    areacode: 712,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51562",
    city: "Panama",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51563",
    city: "Persia",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51564",
    city: "Pisgah",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51565",
    city: "Portsmouth",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51566",
    city: "Red Oak",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51570",
    city: "Shelby",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51571",
    city: "Silver City",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51572",
    city: "Soldier",
    state: "IA",
    areacode: 712,
    county: "Monona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51573",
    city: "Stanton",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51575",
    city: "Treynor",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51576",
    city: "Underwood",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51577",
    city: "Walnut",
    state: "IA",
    areacode: 712,
    county: "Pottawattamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51578",
    city: "Westphalia",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51579",
    city: "Woodbine",
    state: "IA",
    areacode: 712,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51591",
    city: "Red Oak",
    state: "IA",
    areacode: 712,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51593",
    city: "Harlan",
    state: "IA",
    areacode: 712,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51601",
    city: "Shenandoah",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51603",
    city: "Shenandoah",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51630",
    city: "Blanchard",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51631",
    city: "Braddyville",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51632",
    city: "Clarinda",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51636",
    city: "Coin",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51637",
    city: "College Springs",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51638",
    city: "Essex",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51639",
    city: "Farragut",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51640",
    city: "Hamburg",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51645",
    city: "Imogene",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51646",
    city: "New Market",
    state: "IA",
    areacode: 712,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51647",
    city: "Northboro",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51648",
    city: "Percival",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51649",
    city: "Randolph",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51650",
    city: "Riverton",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51651",
    city: "Shambaugh",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51652",
    city: "Sidney",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51653",
    city: "Tabor",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51654",
    city: "Thurman",
    state: "IA",
    areacode: 712,
    county: "Fremont",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "51656",
    city: "Yorktown",
    state: "IA",
    areacode: 712,
    county: "Page",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52001",
    city: "Dubuque",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52002",
    city: "Dubuque",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52003",
    city: "Dubuque",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52004",
    city: "Dubuque",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52030",
    city: "Andrew",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52031",
    city: "Bellevue",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52032",
    city: "Bernard",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52033",
    city: "Cascade",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52035",
    city: "Colesburg",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52036",
    city: "Delaware",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52037",
    city: "Delmar",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52038",
    city: "Dundee",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52039",
    city: "Durango",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52040",
    city: "Dyersville",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52041",
    city: "Earlville",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52042",
    city: "Edgewood",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52043",
    city: "Elkader",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52044",
    city: "Elkport",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52045",
    city: "Epworth",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52046",
    city: "Farley",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52047",
    city: "Farmersburg",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52048",
    city: "Garber",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52049",
    city: "Garnavillo",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52050",
    city: "Greeley",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52052",
    city: "Guttenberg",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52053",
    city: "Holy Cross",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52054",
    city: "La Motte",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52056",
    city: "Luxemburg",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52057",
    city: "Manchester",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52060",
    city: "Maquoketa",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52064",
    city: "Miles",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52065",
    city: "New Vienna",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52066",
    city: "North Buena Vista",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52068",
    city: "Peosta",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52069",
    city: "Preston",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52070",
    city: "Sabula",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52071",
    city: "Saint Donatus",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52072",
    city: "Saint Olaf",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52073",
    city: "Sherrill",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52074",
    city: "Spragueville",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52075",
    city: "Springbrook",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52076",
    city: "Strawberry Point",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52077",
    city: "Volga",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52078",
    city: "Worthington",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52079",
    city: "Zwingle",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52099",
    city: "Dubuque",
    state: "IA",
    areacode: 563,
    county: "Dubuque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52101",
    city: "Decorah",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52132",
    city: "Calmar",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52133",
    city: "Castalia",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52134",
    city: "Chester",
    state: "IA",
    areacode: 563,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52135",
    city: "Clermont",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52136",
    city: "Cresco",
    state: "IA",
    areacode: 563,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52140",
    city: "Dorchester",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52141",
    city: "Elgin",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52142",
    city: "Fayette",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52144",
    city: "Fort Atkinson",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52146",
    city: "Harpers Ferry",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52147",
    city: "Hawkeye",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52149",
    city: "Highlandville",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52151",
    city: "Lansing",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52154",
    city: "Lawler",
    state: "IA",
    areacode: 563,
    county: "Chickasaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52155",
    city: "Lime Springs",
    state: "IA",
    areacode: 563,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52156",
    city: "Luana",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52157",
    city: "McGregor",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52158",
    city: "Marquette",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52159",
    city: "Monona",
    state: "IA",
    areacode: 563,
    county: "Clayton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52160",
    city: "New Albin",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52161",
    city: "Ossian",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52162",
    city: "Postville",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52163",
    city: "Protivin",
    state: "IA",
    areacode: 563,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52164",
    city: "Randalia",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52165",
    city: "Ridgeway",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52166",
    city: "Saint Lucas",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52168",
    city: "Spillville",
    state: "IA",
    areacode: 563,
    county: "Winneshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52169",
    city: "Wadena",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52170",
    city: "Waterville",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52171",
    city: "Waucoma",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52172",
    city: "Waukon",
    state: "IA",
    areacode: 563,
    county: "Allamakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52175",
    city: "West Union",
    state: "IA",
    areacode: 563,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52201",
    city: "Ainsworth",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52202",
    city: "Alburnett",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52203",
    city: "Amana",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52204",
    city: "Amana",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52205",
    city: "Anamosa",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52206",
    city: "Atkins",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52207",
    city: "Baldwin",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52208",
    city: "Belle Plaine",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52209",
    city: "Blairstown",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52210",
    city: "Brandon",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52211",
    city: "Brooklyn",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52212",
    city: "Center Junction",
    state: "IA",
    areacode: 563,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52213",
    city: "Center Point",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52214",
    city: "Central City",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52215",
    city: "Chelsea",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52216",
    city: "Clarence",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52217",
    city: "Clutier",
    state: "IA",
    areacode: 319,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52218",
    city: "Coggon",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52219",
    city: "Prairieburg",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52220",
    city: "Conroy",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52221",
    city: "Guernsey",
    state: "IA",
    areacode: 319,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52222",
    city: "Deep River",
    state: "IA",
    areacode: 641,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52223",
    city: "Delhi",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52224",
    city: "Dysart",
    state: "IA",
    areacode: 319,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52225",
    city: "Elberon",
    state: "IA",
    areacode: 319,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52227",
    city: "Ely",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52228",
    city: "Fairfax",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52229",
    city: "Garrison",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52231",
    city: "Harper",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52232",
    city: "Hartwick",
    state: "IA",
    areacode: 319,
    county: "Poweshiek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52233",
    city: "Hiawatha",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52235",
    city: "Hills",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52236",
    city: "Homestead",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52237",
    city: "Hopkinton",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52240",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52241",
    city: "Coralville",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52242",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52243",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52244",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52245",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52246",
    city: "Iowa City",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52247",
    city: "Kalona",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52248",
    city: "Keota",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52249",
    city: "Keystone",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52251",
    city: "Ladora",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52252",
    city: "Langworthy",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52253",
    city: "Lisbon",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52254",
    city: "Lost Nation",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52255",
    city: "Lowden",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52257",
    city: "Luzerne",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52301",
    city: "Marengo",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52302",
    city: "Marion",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52305",
    city: "Martelle",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52306",
    city: "Mechanicsville",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52307",
    city: "Middle Amana",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52308",
    city: "Millersburg",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52309",
    city: "Monmouth",
    state: "IA",
    areacode: 563,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52310",
    city: "Monticello",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52312",
    city: "Morley",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52313",
    city: "Mount Auburn",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52314",
    city: "Mount Vernon",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52315",
    city: "Newhall",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52316",
    city: "North English",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52317",
    city: "North Liberty",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52318",
    city: "Norway",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52320",
    city: "Olin",
    state: "IA",
    areacode: 319,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52321",
    city: "Onslow",
    state: "IA",
    areacode: 563,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52322",
    city: "Oxford",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52323",
    city: "Oxford Junction",
    state: "IA",
    areacode: 563,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52324",
    city: "Palo",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52325",
    city: "Parnell",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52326",
    city: "Quasqueton",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52327",
    city: "Riverside",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52328",
    city: "Robins",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52329",
    city: "Rowley",
    state: "IA",
    areacode: 319,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52330",
    city: "Ryan",
    state: "IA",
    areacode: 563,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52332",
    city: "Shellsburg",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52333",
    city: "Solon",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52334",
    city: "South Amana",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52335",
    city: "South English",
    state: "IA",
    areacode: 319,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52336",
    city: "Springville",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52337",
    city: "Stanwood",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52338",
    city: "Swisher",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52339",
    city: "Tama",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52340",
    city: "Tiffin",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52341",
    city: "Toddville",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52342",
    city: "Toledo",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52344",
    city: "Troy Mills",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52345",
    city: "Urbana",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52346",
    city: "Van Horne",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52347",
    city: "Victor",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52348",
    city: "Vining",
    state: "IA",
    areacode: 641,
    county: "Tama",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52349",
    city: "Vinton",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52351",
    city: "Walford",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52352",
    city: "Walker",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52353",
    city: "Washington",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52354",
    city: "Watkins",
    state: "IA",
    areacode: 319,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52355",
    city: "Webster",
    state: "IA",
    areacode: 319,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52356",
    city: "Wellman",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52358",
    city: "West Branch",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52359",
    city: "West Chester",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52361",
    city: "Williamsburg",
    state: "IA",
    areacode: 319,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52362",
    city: "Wyoming",
    state: "IA",
    areacode: 563,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52401",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52402",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52403",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52404",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52405",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52406",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52407",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52408",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52409",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52410",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52411",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52497",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52498",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52499",
    city: "Cedar Rapids",
    state: "IA",
    areacode: 319,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52501",
    city: "Ottumwa",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52530",
    city: "Agency",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52531",
    city: "Albia",
    state: "IA",
    areacode: 641,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52533",
    city: "Batavia",
    state: "IA",
    areacode: 641,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52534",
    city: "Beacon",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52535",
    city: "Birmingham",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52536",
    city: "Blakesburg",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52537",
    city: "Bloomfield",
    state: "IA",
    areacode: 641,
    county: "Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52540",
    city: "Brighton",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52542",
    city: "Cantril",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52543",
    city: "Cedar",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52544",
    city: "Centerville",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52548",
    city: "Chillicothe",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52549",
    city: "Cincinnati",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52550",
    city: "Delta",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52551",
    city: "Douds",
    state: "IA",
    areacode: 641,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52552",
    city: "Drakesville",
    state: "IA",
    areacode: 641,
    county: "Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52553",
    city: "Eddyville",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52554",
    city: "Eldon",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52555",
    city: "Exline",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52556",
    city: "Fairfield",
    state: "IA",
    areacode: 641,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52557",
    city: "Fairfield",
    state: "IA",
    areacode: 641,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52560",
    city: "Floris",
    state: "IA",
    areacode: 641,
    county: "Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52561",
    city: "Fremont",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52562",
    city: "Hayesville",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52563",
    city: "Hedrick",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52565",
    city: "Keosauqua",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52566",
    city: "Kirkville",
    state: "IA",
    areacode: 641,
    county: "Wapello",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52567",
    city: "Libertyville",
    state: "IA",
    areacode: 641,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52568",
    city: "Martinsburg",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52569",
    city: "Melrose",
    state: "IA",
    areacode: 641,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52570",
    city: "Milton",
    state: "IA",
    areacode: 641,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52571",
    city: "Moravia",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52572",
    city: "Moulton",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52573",
    city: "Mount Sterling",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52574",
    city: "Mystic",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52576",
    city: "Ollie",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52577",
    city: "Oskaloosa",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52580",
    city: "Packwood",
    state: "IA",
    areacode: 319,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52581",
    city: "Plano",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52583",
    city: "Promise City",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52584",
    city: "Pulaski",
    state: "IA",
    areacode: 641,
    county: "Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52585",
    city: "Richland",
    state: "IA",
    areacode: 319,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52586",
    city: "Rose Hill",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52588",
    city: "Selma",
    state: "IA",
    areacode: 641,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52590",
    city: "Seymour",
    state: "IA",
    areacode: 641,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52591",
    city: "Sigourney",
    state: "IA",
    areacode: 641,
    county: "Keokuk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52593",
    city: "Udell",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52594",
    city: "Unionville",
    state: "IA",
    areacode: 641,
    county: "Appanoose",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52595",
    city: "University Park",
    state: "IA",
    areacode: 641,
    county: "Mahaska",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52601",
    city: "Burlington",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52619",
    city: "Argyle",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52620",
    city: "Bonaparte",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52621",
    city: "Crawfordsville",
    state: "IA",
    areacode: 319,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52623",
    city: "Danville",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52624",
    city: "Denmark",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52625",
    city: "Donnellson",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52626",
    city: "Farmington",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52627",
    city: "Fort Madison",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52630",
    city: "Hillsboro",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52631",
    city: "Houghton",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52632",
    city: "Keokuk",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52635",
    city: "Lockridge",
    state: "IA",
    areacode: 319,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52637",
    city: "Mediapolis",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52638",
    city: "Middletown",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52639",
    city: "Montrose",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52640",
    city: "Morning Sun",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52641",
    city: "Mount Pleasant",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52642",
    city: "Rome",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52644",
    city: "Mount Union",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52645",
    city: "New London",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52646",
    city: "Oakville",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52647",
    city: "Olds",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52648",
    city: "Pilot Grove",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52649",
    city: "Salem",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52650",
    city: "Sperry",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52651",
    city: "Stockport",
    state: "IA",
    areacode: 319,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52652",
    city: "Swedesburg",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52653",
    city: "Wapello",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52654",
    city: "Wayland",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52655",
    city: "West Burlington",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52656",
    city: "West Point",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52657",
    city: "Saint Paul",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52658",
    city: "Wever",
    state: "IA",
    areacode: 319,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52659",
    city: "Winfield",
    state: "IA",
    areacode: 319,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52660",
    city: "Yarmouth",
    state: "IA",
    areacode: 319,
    county: "Des Moines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52701",
    city: "Andover",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52720",
    city: "Atalissa",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52721",
    city: "Bennett",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52722",
    city: "Bettendorf",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52726",
    city: "Blue Grass",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52727",
    city: "Bryant",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52728",
    city: "Buffalo",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52729",
    city: "Calamus",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52730",
    city: "Camanche",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52731",
    city: "Charlotte",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52732",
    city: "Clinton",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52733",
    city: "Clinton",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52734",
    city: "Clinton",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52736",
    city: "Clinton",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52737",
    city: "Columbus City",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52738",
    city: "Columbus Junction",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52739",
    city: "Conesville",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52742",
    city: "DeWitt",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52745",
    city: "Dixon",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52746",
    city: "Donahue",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52747",
    city: "Durant",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52748",
    city: "Eldridge",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52749",
    city: "Fruitland",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52750",
    city: "Goose Lake",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52751",
    city: "Grand Mound",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52752",
    city: "Grandview",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52753",
    city: "Le Claire",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52754",
    city: "Letts",
    state: "IA",
    areacode: 319,
    county: "Louisa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52755",
    city: "Lone Tree",
    state: "IA",
    areacode: 319,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52756",
    city: "Long Grove",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52757",
    city: "Low Moor",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52758",
    city: "McCausland",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52759",
    city: "Montpelier",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52760",
    city: "Moscow",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52761",
    city: "Muscatine",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52765",
    city: "New Liberty",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52766",
    city: "Nichols",
    state: "IA",
    areacode: 319,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52767",
    city: "Pleasant Valley",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52768",
    city: "Princeton",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52769",
    city: "Stockton",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52771",
    city: "Teeds Grove",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52772",
    city: "Tipton",
    state: "IA",
    areacode: 563,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52773",
    city: "Walcott",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52774",
    city: "Welton",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52776",
    city: "West Liberty",
    state: "IA",
    areacode: 319,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52777",
    city: "Wheatland",
    state: "IA",
    areacode: 563,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52778",
    city: "Wilton",
    state: "IA",
    areacode: 563,
    county: "Muscatine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52801",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52802",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52803",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52804",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52805",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52806",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52807",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52808",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "52809",
    city: "Davenport",
    state: "IA",
    areacode: 563,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53001",
    city: "Adell",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53002",
    city: "Allenton",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53003",
    city: "Ashippun",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53004",
    city: "Belgium",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53005",
    city: "Brookfield",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53006",
    city: "Brownsville",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53007",
    city: "Butler",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53008",
    city: "Brookfield",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53010",
    city: "Campbellsport",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53011",
    city: "Cascade",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53012",
    city: "Cedarburg",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53013",
    city: "Cedar Grove",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53014",
    city: "Chilton",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53015",
    city: "Cleveland",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53016",
    city: "Clyman",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53017",
    city: "Colgate",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53018",
    city: "Delafield",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53019",
    city: "Eden",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53020",
    city: "Elkhart Lake",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53021",
    city: "Fredonia",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53022",
    city: "Germantown",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53023",
    city: "Glenbeulah",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53024",
    city: "Grafton",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53026",
    city: "Greenbush",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53027",
    city: "Hartford",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53029",
    city: "Hartland",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53031",
    city: "Hingham",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53032",
    city: "Horicon",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53033",
    city: "Hubertus",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53034",
    city: "Hustisford",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53035",
    city: "Iron Ridge",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53036",
    city: "Ixonia",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53037",
    city: "Jackson",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53038",
    city: "Johnson Creek",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53039",
    city: "Juneau",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53040",
    city: "Kewaskum",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53042",
    city: "Kiel",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53044",
    city: "Kohler",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53045",
    city: "Brookfield",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53046",
    city: "Lannon",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53047",
    city: "Lebanon",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53048",
    city: "Lomira",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53049",
    city: "Malone",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53050",
    city: "Mayville",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53051",
    city: "Menomonee Falls",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53052",
    city: "Menomonee Falls",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53056",
    city: "Merton",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53057",
    city: "Mount Calvary",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53058",
    city: "Nashotah",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53059",
    city: "Neosho",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53060",
    city: "Newburg",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53061",
    city: "New Holstein",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53062",
    city: "New Holstein",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53063",
    city: "Newton",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53064",
    city: "North Lake",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53065",
    city: "Oakfield",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53066",
    city: "Oconomowoc",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53069",
    city: "Okauchee",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53070",
    city: "Oostburg",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53072",
    city: "Pewaukee",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53073",
    city: "Plymouth",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53074",
    city: "Port Washington",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53075",
    city: "Random Lake",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53076",
    city: "Richfield",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53078",
    city: "Rubicon",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53079",
    city: "Saint Cloud",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53080",
    city: "Saukville",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53081",
    city: "Sheboygan",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53082",
    city: "Sheboygan",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53083",
    city: "Sheboygan",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53085",
    city: "Sheboygan Falls",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53086",
    city: "Slinger",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53088",
    city: "Stockbridge",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53089",
    city: "Sussex",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53090",
    city: "West Bend",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53091",
    city: "Theresa",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53092",
    city: "Mequon",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53093",
    city: "Waldo",
    state: "WI",
    areacode: 920,
    county: "Sheboygan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53094",
    city: "Watertown",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53095",
    city: "West Bend",
    state: "WI",
    areacode: 262,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53097",
    city: "Mequon",
    state: "WI",
    areacode: 262,
    county: "Ozaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53098",
    city: "Watertown",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53099",
    city: "Woodland",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53101",
    city: "Bassett",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53102",
    city: "Benet Lake",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53103",
    city: "Big Bend",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53104",
    city: "Bristol",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53105",
    city: "Burlington",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53108",
    city: "Caledonia",
    state: "WI",
    areacode: 414,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53109",
    city: "Camp Lake",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53110",
    city: "Cudahy",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53114",
    city: "Darien",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53115",
    city: "Delavan",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53118",
    city: "Dousman",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53119",
    city: "Eagle",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53120",
    city: "East Troy",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53121",
    city: "Elkhorn",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53122",
    city: "Elm Grove",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53125",
    city: "Fontana",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53126",
    city: "Franksville",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53127",
    city: "Genesee Depot",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53128",
    city: "Genoa City",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53129",
    city: "Greendale",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53130",
    city: "Hales Corners",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53132",
    city: "Franklin",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53137",
    city: "Helenville",
    state: "WI",
    areacode: 414,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53138",
    city: "Honey Creek",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53139",
    city: "Kansasville",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53140",
    city: "Kenosha",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53141",
    city: "Kenosha",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53142",
    city: "Kenosha",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53143",
    city: "Kenosha",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53144",
    city: "Kenosha",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53146",
    city: "New Berlin",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53147",
    city: "Lake Geneva",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53148",
    city: "Lyons",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53149",
    city: "Mukwonago",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53150",
    city: "Muskego",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53151",
    city: "New Berlin",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53152",
    city: "New Munster",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53153",
    city: "North Prairie",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53154",
    city: "Oak Creek",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53156",
    city: "Palmyra",
    state: "WI",
    areacode: 414,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53157",
    city: "Pell Lake",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53158",
    city: "Pleasant Prairie",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53159",
    city: "Powers Lake",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53167",
    city: "Rochester",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53168",
    city: "Salem",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53170",
    city: "Silver Lake",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53171",
    city: "Somers",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53172",
    city: "South Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53176",
    city: "Springfield",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53177",
    city: "Sturtevant",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53178",
    city: "Sullivan",
    state: "WI",
    areacode: 262,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53179",
    city: "Trevor",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53181",
    city: "Twin Lakes",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53182",
    city: "Union Grove",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53183",
    city: "Wales",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53184",
    city: "Walworth",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53185",
    city: "Waterford",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53186",
    city: "Waukesha",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53187",
    city: "Waukesha",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53188",
    city: "Waukesha",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53189",
    city: "Waukesha",
    state: "WI",
    areacode: 262,
    county: "Waukesha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53190",
    city: "Whitewater",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53191",
    city: "Williams Bay",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53192",
    city: "Wilmot",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53194",
    city: "Woodworth",
    state: "WI",
    areacode: 262,
    county: "Kenosha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53195",
    city: "Zenda",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53201",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53202",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53203",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53204",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53205",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53206",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53207",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53208",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53209",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53210",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53211",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53212",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53213",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53214",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53215",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53216",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53217",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53218",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53219",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53220",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53221",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53222",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53223",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53224",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53225",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53226",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53227",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53228",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53233",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53234",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53235",
    city: "Saint Francis",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53237",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53259",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53263",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53274",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53278",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53288",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53290",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53293",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53295",
    city: "Milwaukee",
    state: "WI",
    areacode: 414,
    county: "Milwaukee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53401",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53402",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53403",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53404",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53405",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53406",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53407",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53408",
    city: "Racine",
    state: "WI",
    areacode: 262,
    county: "Racine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53501",
    city: "Afton",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53502",
    city: "Albany",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53503",
    city: "Arena",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53504",
    city: "Argyle",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53505",
    city: "Avalon",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53506",
    city: "Avoca",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53507",
    city: "Barneveld",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53508",
    city: "Belleville",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53510",
    city: "Belmont",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53511",
    city: "Beloit",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53512",
    city: "Beloit",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53515",
    city: "Black Earth",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53516",
    city: "Blanchardville",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53517",
    city: "Blue Mounds",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53518",
    city: "Blue River",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53520",
    city: "Brodhead",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53521",
    city: "Brooklyn",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53522",
    city: "Browntown",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53523",
    city: "Cambridge",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53525",
    city: "Clinton",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53526",
    city: "Cobb",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53527",
    city: "Cottage Grove",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53528",
    city: "Cross Plains",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53529",
    city: "Dane",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53530",
    city: "Darlington",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53531",
    city: "Deerfield",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53532",
    city: "DeForest",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53533",
    city: "Dodgeville",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53534",
    city: "Edgerton",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53535",
    city: "Edmund",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53536",
    city: "Evansville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53537",
    city: "Footville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53538",
    city: "Fort Atkinson",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53540",
    city: "Gotham",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53541",
    city: "Gratiot",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53542",
    city: "Hanover",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53543",
    city: "Highland",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53544",
    city: "Hollandale",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53545",
    city: "Janesville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53546",
    city: "Janesville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53547",
    city: "Janesville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53548",
    city: "Janesville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53549",
    city: "Jefferson",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53550",
    city: "Juda",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53551",
    city: "Lake Mills",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53553",
    city: "Linden",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53554",
    city: "Livingston",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53555",
    city: "Lodi",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53556",
    city: "Lone Rock",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53557",
    city: "Lowell",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53558",
    city: "McFarland",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53559",
    city: "Marshall",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53560",
    city: "Mazomanie",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53561",
    city: "Merrimac",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53562",
    city: "Middleton",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53563",
    city: "Milton",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53565",
    city: "Mineral Point",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53566",
    city: "Monroe",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53569",
    city: "Montfort",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53570",
    city: "Monticello",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53571",
    city: "Morrisonville",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53572",
    city: "Mount Horeb",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53573",
    city: "Muscoda",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53574",
    city: "New Glarus",
    state: "WI",
    areacode: 608,
    county: "Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53575",
    city: "Oregon",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53576",
    city: "Orfordville",
    state: "WI",
    areacode: 608,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53577",
    city: "Plain",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53578",
    city: "Prairie du Sac",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53579",
    city: "Reeseville",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53580",
    city: "Rewey",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53581",
    city: "Richland Center",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53582",
    city: "Ridgeway",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53583",
    city: "Sauk City",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53584",
    city: "Sextonville",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53585",
    city: "Sharon",
    state: "WI",
    areacode: 262,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53586",
    city: "Shullsburg",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53587",
    city: "South Wayne",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53588",
    city: "Spring Green",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53589",
    city: "Stoughton",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53590",
    city: "Sun Prairie",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53593",
    city: "Verona",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53594",
    city: "Waterloo",
    state: "WI",
    areacode: 920,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53595",
    city: "Dodgeville",
    state: "WI",
    areacode: 608,
    county: "Iowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53596",
    city: "Sun Prairie",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53597",
    city: "Waunakee",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53598",
    city: "Windsor",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53599",
    city: "Woodford",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53701",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53702",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53703",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53704",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53705",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53706",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53707",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53708",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53711",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53713",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53714",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53715",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53716",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53717",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53718",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53719",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53725",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53726",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53744",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53774",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53777",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53782",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53783",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53784",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53785",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53786",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53788",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53790",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53791",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53792",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53793",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53794",
    city: "Madison",
    state: "WI",
    areacode: 608,
    county: "Dane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53801",
    city: "Bagley",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53802",
    city: "Beetown",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53803",
    city: "Benton",
    state: "WI",
    areacode: 608,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53804",
    city: "Bloomington",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53805",
    city: "Boscobel",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53806",
    city: "Cassville",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53807",
    city: "Cuba City",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53808",
    city: "Dickeyville",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53809",
    city: "Fennimore",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53810",
    city: "Glen Haven",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53811",
    city: "Hazel Green",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53812",
    city: "Kieler",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53813",
    city: "Lancaster",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53816",
    city: "Mount Hope",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53817",
    city: "Patch Grove",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53818",
    city: "Platteville",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53820",
    city: "Potosi",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53821",
    city: "Prairie du Chien",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53824",
    city: "Sinsinawa",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53825",
    city: "Stitzer",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53826",
    city: "Wauzeka",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53827",
    city: "Woodman",
    state: "WI",
    areacode: 608,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53901",
    city: "Portage",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53910",
    city: "Adams",
    state: "WI",
    areacode: 608,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53911",
    city: "Arlington",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53913",
    city: "Baraboo",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53916",
    city: "Beaver Dam",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53919",
    city: "Brandon",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53920",
    city: "Briggsville",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53922",
    city: "Burnett",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53923",
    city: "Cambria",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53924",
    city: "Cazenovia",
    state: "WI",
    areacode: 608,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53925",
    city: "Columbus",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53926",
    city: "Dalton",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53928",
    city: "Doylestown",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53929",
    city: "Elroy",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53930",
    city: "Endeavor",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53931",
    city: "Fairwater",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53932",
    city: "Fall River",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53933",
    city: "Fox Lake",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53934",
    city: "Friendship",
    state: "WI",
    areacode: 608,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53935",
    city: "Friesland",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53936",
    city: "Grand Marsh",
    state: "WI",
    areacode: 608,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53937",
    city: "Hillpoint",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53939",
    city: "Kingston",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53940",
    city: "Lake Delton",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53941",
    city: "La Valle",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53942",
    city: "Lime Ridge",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53943",
    city: "Loganville",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53944",
    city: "Lyndon Station",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53946",
    city: "Markesan",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53947",
    city: "Marquette",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53948",
    city: "Mauston",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53949",
    city: "Montello",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53950",
    city: "New Lisbon",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53951",
    city: "North Freedom",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53952",
    city: "Oxford",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53953",
    city: "Packwaukee",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53954",
    city: "Pardeeville",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53955",
    city: "Poynette",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53956",
    city: "Randolph",
    state: "WI",
    areacode: 920,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53957",
    city: "Randolph",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53958",
    city: "Reedsburg",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53959",
    city: "Reedsburg",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53960",
    city: "Rio",
    state: "WI",
    areacode: 920,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53961",
    city: "Rock Springs",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53962",
    city: "Union Center",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53963",
    city: "Waupun",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53964",
    city: "Westfield",
    state: "WI",
    areacode: 608,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53965",
    city: "Wisconsin Dells",
    state: "WI",
    areacode: 608,
    county: "Sauk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53968",
    city: "Wonewoc",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "53969",
    city: "Wyocena",
    state: "WI",
    areacode: 608,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54001",
    city: "Amery",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54002",
    city: "Baldwin",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54003",
    city: "Beldenville",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54004",
    city: "Clayton",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54005",
    city: "Clear Lake",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54006",
    city: "Cushing",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54007",
    city: "Deer Park",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54009",
    city: "Dresser",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54011",
    city: "Ellsworth",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54013",
    city: "Glenwood City",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54014",
    city: "Hager City",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54015",
    city: "Hammond",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54016",
    city: "Hudson",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54017",
    city: "New Richmond",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54020",
    city: "Osceola",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54021",
    city: "Prescott",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54022",
    city: "River Falls",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54023",
    city: "Roberts",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54024",
    city: "Saint Croix Falls",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54025",
    city: "Somerset",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54026",
    city: "Star Prairie",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54027",
    city: "Wilson",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54028",
    city: "Woodville",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54082",
    city: "Houlton",
    state: "WI",
    areacode: 715,
    county: "Saint Croix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54101",
    city: "Abrams",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54102",
    city: "Amberg",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54103",
    city: "Armstrong Creek",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54104",
    city: "Athelstane",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54106",
    city: "Black Creek",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54107",
    city: "Bonduel",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54110",
    city: "Brillion",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54111",
    city: "Cecil",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54112",
    city: "Coleman",
    state: "WI",
    areacode: 920,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54113",
    city: "Combined Locks",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54114",
    city: "Crivitz",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54115",
    city: "De Pere",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54119",
    city: "Dunbar",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54120",
    city: "Fence",
    state: "WI",
    areacode: 715,
    county: "Florence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54121",
    city: "Florence",
    state: "WI",
    areacode: 715,
    county: "Florence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54123",
    city: "Forest Junction",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54124",
    city: "Gillett",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54125",
    city: "Goodman",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54126",
    city: "Greenleaf",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54127",
    city: "Green Valley",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54128",
    city: "Gresham",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54129",
    city: "Hilbert",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54130",
    city: "Kaukauna",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54131",
    city: "Freedom",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54135",
    city: "Keshena",
    state: "WI",
    areacode: 715,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54136",
    city: "Kimberly",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54137",
    city: "Krakow",
    state: "WI",
    areacode: 920,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54138",
    city: "Lakewood",
    state: "WI",
    areacode: 715,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54139",
    city: "Lena",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54140",
    city: "Little Chute",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54141",
    city: "Little Suamico",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54143",
    city: "Marinette",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54149",
    city: "Mountain",
    state: "WI",
    areacode: 715,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54150",
    city: "Neopit",
    state: "WI",
    areacode: 715,
    county: "Menominee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54151",
    city: "Niagara",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54152",
    city: "Nichols",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54153",
    city: "Oconto",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54154",
    city: "Oconto Falls",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54155",
    city: "Oneida",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54156",
    city: "Pembine",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54157",
    city: "Peshtigo",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54159",
    city: "Porterfield",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54160",
    city: "Potter",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54161",
    city: "Pound",
    state: "WI",
    areacode: 920,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54162",
    city: "Pulaski",
    state: "WI",
    areacode: 920,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54165",
    city: "Seymour",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54166",
    city: "Shawano",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54169",
    city: "Sherwood",
    state: "WI",
    areacode: 920,
    county: "Calumet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54170",
    city: "Shiocton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54171",
    city: "Sobieski",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54173",
    city: "Suamico",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54174",
    city: "Suring",
    state: "WI",
    areacode: 920,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54175",
    city: "Townsend",
    state: "WI",
    areacode: 715,
    county: "Oconto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54177",
    city: "Wausaukee",
    state: "WI",
    areacode: 715,
    county: "Marinette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54180",
    city: "Wrightstown",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54201",
    city: "Algoma",
    state: "WI",
    areacode: 920,
    county: "Kewaunee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54202",
    city: "Baileys Harbor",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54204",
    city: "Brussels",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54205",
    city: "Casco",
    state: "WI",
    areacode: 920,
    county: "Kewaunee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54207",
    city: "Collins",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54208",
    city: "Denmark",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54209",
    city: "Egg Harbor",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54210",
    city: "Ellison Bay",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54211",
    city: "Ephraim",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54212",
    city: "Fish Creek",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54213",
    city: "Forestville",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54214",
    city: "Francis Creek",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54215",
    city: "Kellnersville",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54216",
    city: "Kewaunee",
    state: "WI",
    areacode: 920,
    county: "Kewaunee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54217",
    city: "Luxemburg",
    state: "WI",
    areacode: 920,
    county: "Kewaunee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54220",
    city: "Manitowoc",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54221",
    city: "Manitowoc",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54226",
    city: "Maplewood",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54227",
    city: "Maribel",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54228",
    city: "Mishicot",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54229",
    city: "New Franken",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54230",
    city: "Reedsville",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54232",
    city: "Saint Nazianz",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54234",
    city: "Sister Bay",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54235",
    city: "Sturgeon Bay",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54240",
    city: "Tisch Mills",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54241",
    city: "Two Rivers",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54245",
    city: "Valders",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54246",
    city: "Washington Island",
    state: "WI",
    areacode: 920,
    county: "Door",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54247",
    city: "Whitelaw",
    state: "WI",
    areacode: 920,
    county: "Manitowoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54301",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54302",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54303",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54304",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54305",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54306",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54307",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54308",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54311",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54313",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54324",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54344",
    city: "Green Bay",
    state: "WI",
    areacode: 920,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54401",
    city: "Wausau",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54402",
    city: "Wausau",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54403",
    city: "Wausau",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54404",
    city: "Marshfield",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54405",
    city: "Abbotsford",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54406",
    city: "Amherst",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54407",
    city: "Amherst Junction",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54408",
    city: "Aniwa",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54409",
    city: "Antigo",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54410",
    city: "Arpin",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54411",
    city: "Athens",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54412",
    city: "Auburndale",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54413",
    city: "Babcock",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54414",
    city: "Birnamwood",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54415",
    city: "Blenker",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54416",
    city: "Bowler",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54417",
    city: "Brokaw",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54418",
    city: "Bryant",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54420",
    city: "Chili",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54421",
    city: "Colby",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54422",
    city: "Curtiss",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54423",
    city: "Custer",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54424",
    city: "Deerbrook",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54425",
    city: "Dorchester",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54426",
    city: "Edgar",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54427",
    city: "Eland",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54428",
    city: "Elcho",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54429",
    city: "Elderon",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54430",
    city: "Elton",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54433",
    city: "Gilman",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54435",
    city: "Gleason",
    state: "WI",
    areacode: 715,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54436",
    city: "Granton",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54437",
    city: "Greenwood",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54440",
    city: "Hatley",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54441",
    city: "Hewitt",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54442",
    city: "Irma",
    state: "WI",
    areacode: 715,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54443",
    city: "Junction City",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54446",
    city: "Loyal",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54447",
    city: "Lublin",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54448",
    city: "Marathon",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54449",
    city: "Marshfield",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54450",
    city: "Mattoon",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54451",
    city: "Medford",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54452",
    city: "Merrill",
    state: "WI",
    areacode: 715,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54454",
    city: "Milladore",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54455",
    city: "Mosinee",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54456",
    city: "Neillsville",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54457",
    city: "Nekoosa",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54458",
    city: "Nelsonville",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54459",
    city: "Ogema",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54460",
    city: "Owen",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54462",
    city: "Pearson",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54463",
    city: "Pelican Lake",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54464",
    city: "Phlox",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54465",
    city: "Pickerel",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54466",
    city: "Pittsville",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54467",
    city: "Plover",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54469",
    city: "Port Edwards",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54470",
    city: "Rib Lake",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54471",
    city: "Ringle",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54473",
    city: "Rosholt",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54474",
    city: "Rothschild",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54475",
    city: "Rudolph",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54476",
    city: "Schofield",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54479",
    city: "Spencer",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54480",
    city: "Stetsonville",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54481",
    city: "Stevens Point",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54482",
    city: "Stevens Point",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54484",
    city: "Stratford",
    state: "WI",
    areacode: 715,
    county: "Marathon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54485",
    city: "Summit Lake",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54486",
    city: "Tigerton",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54487",
    city: "Tomahawk",
    state: "WI",
    areacode: 715,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54488",
    city: "Unity",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54489",
    city: "Vesper",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54490",
    city: "Westboro",
    state: "WI",
    areacode: 715,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54491",
    city: "White Lake",
    state: "WI",
    areacode: 715,
    county: "Langlade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54492",
    city: "Stevens Point",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54493",
    city: "Willard",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54494",
    city: "Wisconsin Rapids",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54495",
    city: "Wisconsin Rapids",
    state: "WI",
    areacode: 715,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54498",
    city: "Withee",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54499",
    city: "Wittenberg",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54501",
    city: "Rhinelander",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54511",
    city: "Argonne",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54512",
    city: "Boulder Junction",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54513",
    city: "Brantwood",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54514",
    city: "Butternut",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54515",
    city: "Catawba",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54517",
    city: "Clam Lake",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54519",
    city: "Conover",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54520",
    city: "Crandon",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54521",
    city: "Eagle River",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54524",
    city: "Fifield",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54525",
    city: "Gile",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54526",
    city: "Glen Flora",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54527",
    city: "Glidden",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54529",
    city: "Harshaw",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54530",
    city: "Hawkins",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54531",
    city: "Hazelhurst",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54532",
    city: "Heafford Junction",
    state: "WI",
    areacode: 715,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54534",
    city: "Hurley",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54536",
    city: "Iron Belt",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54537",
    city: "Kennan",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54538",
    city: "Lac du Flambeau",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54539",
    city: "Lake Tomahawk",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54540",
    city: "Land O' Lakes",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54541",
    city: "Laona",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54542",
    city: "Long Lake",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54543",
    city: "McNaughton",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54545",
    city: "Manitowish Waters",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54546",
    city: "Mellen",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54547",
    city: "Mercer",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54548",
    city: "Minocqua",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54550",
    city: "Montreal",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54552",
    city: "Park Falls",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54554",
    city: "Phelps",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54555",
    city: "Phillips",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54556",
    city: "Prentice",
    state: "WI",
    areacode: 715,
    county: "Price",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54557",
    city: "Presque Isle",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54558",
    city: "Saint Germain",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54559",
    city: "Saxon",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54560",
    city: "Sayner",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54561",
    city: "Star Lake",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54562",
    city: "Three Lakes",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54563",
    city: "Tony",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54564",
    city: "Tripoli",
    state: "WI",
    areacode: 715,
    county: "Oneida",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54565",
    city: "Upson",
    state: "WI",
    areacode: 715,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54566",
    city: "Wabeno",
    state: "WI",
    areacode: 715,
    county: "Forest",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54568",
    city: "Woodruff",
    state: "WI",
    areacode: 715,
    county: "Vilas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54601",
    city: "La Crosse",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54602",
    city: "La Crosse",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54603",
    city: "La Crosse",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54610",
    city: "Alma",
    state: "WI",
    areacode: 608,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54611",
    city: "Alma Center",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54612",
    city: "Arcadia",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54613",
    city: "Arkdale",
    state: "WI",
    areacode: 608,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54614",
    city: "Bangor",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54615",
    city: "Black River Falls",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54616",
    city: "Blair",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54618",
    city: "Camp Douglas",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54619",
    city: "Cashton",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54620",
    city: "Cataract",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54621",
    city: "Chaseburg",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54622",
    city: "Cochrane",
    state: "WI",
    areacode: 608,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54623",
    city: "Coon Valley",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54624",
    city: "De Soto",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54625",
    city: "Dodge",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54626",
    city: "Eastman",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54627",
    city: "Ettrick",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54628",
    city: "Ferryville",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54629",
    city: "Fountain City",
    state: "WI",
    areacode: 608,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54630",
    city: "Galesville",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54631",
    city: "Gays Mills",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54632",
    city: "Genoa",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54634",
    city: "Hillsboro",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54635",
    city: "Hixton",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54636",
    city: "Holmen",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54637",
    city: "Hustler",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54638",
    city: "Kendall",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54639",
    city: "La Farge",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54641",
    city: "Mather",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54642",
    city: "Melrose",
    state: "WI",
    areacode: 608,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54643",
    city: "Millston",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54644",
    city: "Mindoro",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54645",
    city: "Mount Sterling",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54646",
    city: "Necedah",
    state: "WI",
    areacode: 608,
    county: "Juneau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54648",
    city: "Norwalk",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54649",
    city: "Oakdale",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54650",
    city: "Onalaska",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54651",
    city: "Ontario",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54652",
    city: "Readstown",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54653",
    city: "Rockland",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54654",
    city: "Seneca",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54655",
    city: "Soldiers Grove",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54656",
    city: "Sparta",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54657",
    city: "Steuben",
    state: "WI",
    areacode: 608,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54658",
    city: "Stoddard",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54659",
    city: "Taylor",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54660",
    city: "Tomah",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54661",
    city: "Trempealeau",
    state: "WI",
    areacode: 608,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54662",
    city: "Tunnel City",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54664",
    city: "Viola",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54665",
    city: "Viroqua",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54666",
    city: "Warrens",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54667",
    city: "Westby",
    state: "WI",
    areacode: 608,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54669",
    city: "West Salem",
    state: "WI",
    areacode: 608,
    county: "La Crosse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54670",
    city: "Wilton",
    state: "WI",
    areacode: 608,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54701",
    city: "Eau Claire",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54702",
    city: "Eau Claire",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54703",
    city: "Eau Claire",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54720",
    city: "Altoona",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54721",
    city: "Arkansaw",
    state: "WI",
    areacode: 715,
    county: "Pepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54722",
    city: "Augusta",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54723",
    city: "Bay City",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54724",
    city: "Bloomer",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54725",
    city: "Boyceville",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54726",
    city: "Boyd",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54727",
    city: "Cadott",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54728",
    city: "Chetek",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54729",
    city: "Chippewa Falls",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54730",
    city: "Colfax",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54731",
    city: "Conrath",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54732",
    city: "Cornell",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54733",
    city: "Dallas",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54734",
    city: "Downing",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54735",
    city: "Downsville",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54736",
    city: "Durand",
    state: "WI",
    areacode: 715,
    county: "Pepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54737",
    city: "Eau Galle",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54738",
    city: "Eleva",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54739",
    city: "Elk Mound",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54740",
    city: "Elmwood",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54741",
    city: "Fairchild",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54742",
    city: "Fall Creek",
    state: "WI",
    areacode: 715,
    county: "Eau Claire",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54743",
    city: "Gilmanton",
    state: "WI",
    areacode: 715,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54745",
    city: "Holcombe",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54746",
    city: "Humbird",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54747",
    city: "Independence",
    state: "WI",
    areacode: 715,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54748",
    city: "Jim Falls",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54749",
    city: "Knapp",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54750",
    city: "Maiden Rock",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54751",
    city: "Menomonie",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54754",
    city: "Merrillan",
    state: "WI",
    areacode: 715,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54755",
    city: "Mondovi",
    state: "WI",
    areacode: 715,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54756",
    city: "Nelson",
    state: "WI",
    areacode: 715,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54757",
    city: "New Auburn",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54758",
    city: "Osseo",
    state: "WI",
    areacode: 715,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54759",
    city: "Pepin",
    state: "WI",
    areacode: 715,
    county: "Pepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54760",
    city: "Pigeon Falls",
    state: "WI",
    areacode: 715,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54761",
    city: "Plum City",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54762",
    city: "Prairie Farm",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54763",
    city: "Ridgeland",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54764",
    city: "Rock Falls",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54765",
    city: "Sand Creek",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54766",
    city: "Sheldon",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54767",
    city: "Spring Valley",
    state: "WI",
    areacode: 715,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54768",
    city: "Stanley",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54769",
    city: "Stockholm",
    state: "WI",
    areacode: 715,
    county: "Pepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54770",
    city: "Strum",
    state: "WI",
    areacode: 715,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54771",
    city: "Thorp",
    state: "WI",
    areacode: 715,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54772",
    city: "Wheeler",
    state: "WI",
    areacode: 715,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54773",
    city: "Whitehall",
    state: "WI",
    areacode: 715,
    county: "Trempealeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54774",
    city: "Chippewa Falls",
    state: "WI",
    areacode: 715,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54801",
    city: "Spooner",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54805",
    city: "Almena",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54806",
    city: "Ashland",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54810",
    city: "Balsam Lake",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54812",
    city: "Barron",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54813",
    city: "Barronett",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54814",
    city: "Bayfield",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54816",
    city: "Benoit",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54817",
    city: "Birchwood",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54819",
    city: "Bruce",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54820",
    city: "Brule",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54821",
    city: "Cable",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54822",
    city: "Cameron",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54824",
    city: "Centuria",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54826",
    city: "Comstock",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54827",
    city: "Cornucopia",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54828",
    city: "Couderay",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54829",
    city: "Cumberland",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54830",
    city: "Danbury",
    state: "WI",
    areacode: 715,
    county: "Burnett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54832",
    city: "Drummond",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54835",
    city: "Exeland",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54836",
    city: "Foxboro",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54837",
    city: "Frederic",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54838",
    city: "Gordon",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54839",
    city: "Grand View",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54840",
    city: "Grantsburg",
    state: "WI",
    areacode: 715,
    county: "Burnett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54841",
    city: "Haugen",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54842",
    city: "Hawthorne",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54843",
    city: "Hayward",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54844",
    city: "Herbster",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54845",
    city: "Hertel",
    state: "WI",
    areacode: 715,
    county: "Burnett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54846",
    city: "High Bridge",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54847",
    city: "Iron River",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54848",
    city: "Ladysmith",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54849",
    city: "Lake Nebagamon",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54850",
    city: "La Pointe",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54853",
    city: "Luck",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54854",
    city: "Maple",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54855",
    city: "Marengo",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54856",
    city: "Mason",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54857",
    city: "Mikana",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54858",
    city: "Milltown",
    state: "WI",
    areacode: 715,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54859",
    city: "Minong",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54861",
    city: "Odanah",
    state: "WI",
    areacode: 715,
    county: "Ashland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54862",
    city: "Ojibwa",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54864",
    city: "Poplar",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54865",
    city: "Port Wing",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54867",
    city: "Radisson",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54868",
    city: "Rice Lake",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54870",
    city: "Sarona",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54871",
    city: "Shell Lake",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54872",
    city: "Siren",
    state: "WI",
    areacode: 715,
    county: "Burnett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54873",
    city: "Solon Springs",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54874",
    city: "South Range",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54875",
    city: "Springbrook",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54876",
    city: "Stone Lake",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54880",
    city: "Superior",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54888",
    city: "Trego",
    state: "WI",
    areacode: 715,
    county: "Washburn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54889",
    city: "Turtle Lake",
    state: "WI",
    areacode: 715,
    county: "Barron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54890",
    city: "Wascott",
    state: "WI",
    areacode: 715,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54891",
    city: "Washburn",
    state: "WI",
    areacode: 715,
    county: "Bayfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54893",
    city: "Webster",
    state: "WI",
    areacode: 715,
    county: "Burnett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54895",
    city: "Weyerhaeuser",
    state: "WI",
    areacode: 715,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54896",
    city: "Winter",
    state: "WI",
    areacode: 715,
    county: "Sawyer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54901",
    city: "Oshkosh",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54902",
    city: "Oshkosh",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54903",
    city: "Oshkosh",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54904",
    city: "Oshkosh",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54906",
    city: "Oshkosh",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54909",
    city: "Almond",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54911",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54912",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54913",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54914",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54915",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54919",
    city: "Appleton",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54921",
    city: "Bancroft",
    state: "WI",
    areacode: 715,
    county: "Portage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54922",
    city: "Bear Creek",
    state: "WI",
    areacode: 715,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54923",
    city: "Berlin",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54926",
    city: "Big Falls",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54927",
    city: "Butte des Morts",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54928",
    city: "Caroline",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54929",
    city: "Clintonville",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54930",
    city: "Coloma",
    state: "WI",
    areacode: 715,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54931",
    city: "Dale",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54932",
    city: "Eldorado",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54933",
    city: "Embarrass",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54935",
    city: "Fond du Lac",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54936",
    city: "Fond du Lac",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54937",
    city: "Fond du Lac",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54940",
    city: "Fremont",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54941",
    city: "Green Lake",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54942",
    city: "Greenville",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54943",
    city: "Hancock",
    state: "WI",
    areacode: 715,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54944",
    city: "Hortonville",
    state: "WI",
    areacode: 920,
    county: "Outagamie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54945",
    city: "Iola",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54946",
    city: "King",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54947",
    city: "Larsen",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54948",
    city: "Leopolis",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54949",
    city: "Manawa",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54950",
    city: "Marion",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54952",
    city: "Menasha",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54956",
    city: "Neenah",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54957",
    city: "Neenah",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54960",
    city: "Neshkoro",
    state: "WI",
    areacode: 920,
    county: "Marquette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54961",
    city: "New London",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54962",
    city: "Ogdensburg",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54963",
    city: "Omro",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54964",
    city: "Pickett",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54965",
    city: "Pine River",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54966",
    city: "Plainfield",
    state: "WI",
    areacode: 715,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54967",
    city: "Poy Sippi",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54968",
    city: "Princeton",
    state: "WI",
    areacode: 920,
    county: "Green Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54969",
    city: "Readfield",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54970",
    city: "Redgranite",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54971",
    city: "Ripon",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54974",
    city: "Rosendale",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54976",
    city: "Saxeville",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54977",
    city: "Scandinavia",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54978",
    city: "Tilleda",
    state: "WI",
    areacode: 715,
    county: "Shawano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54979",
    city: "Van Dyne",
    state: "WI",
    areacode: 920,
    county: "Fond du Lac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54980",
    city: "Waukau",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54981",
    city: "Waupaca",
    state: "WI",
    areacode: 715,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54982",
    city: "Wautoma",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54983",
    city: "Weyauwega",
    state: "WI",
    areacode: 920,
    county: "Waupaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54984",
    city: "Wild Rose",
    state: "WI",
    areacode: 920,
    county: "Waushara",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54985",
    city: "Winnebago",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "54986",
    city: "Winneconne",
    state: "WI",
    areacode: 920,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55001",
    city: "Afton",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55003",
    city: "Bayport",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55005",
    city: "Bethel",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55006",
    city: "Braham",
    state: "MN",
    areacode: 320,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55007",
    city: "Brook Park",
    state: "MN",
    areacode: 320,
    county: "Kanabec",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55008",
    city: "Cambridge",
    state: "MN",
    areacode: 763,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55009",
    city: "Cannon Falls",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55010",
    city: "Castle Rock",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55011",
    city: "Cedar",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55012",
    city: "Center City",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55013",
    city: "Chisago City",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55014",
    city: "Circle Pines",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55016",
    city: "Cottage Grove",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55017",
    city: "Dalbo",
    state: "MN",
    areacode: 763,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55018",
    city: "Dennison",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55019",
    city: "Dundas",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55020",
    city: "Elko New Market",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55021",
    city: "Faribault",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55024",
    city: "Farmington",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55025",
    city: "Forest Lake",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55026",
    city: "Frontenac",
    state: "MN",
    areacode: 651,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55027",
    city: "Goodhue",
    state: "MN",
    areacode: 651,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55029",
    city: "Grandy",
    state: "MN",
    areacode: 763,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55030",
    city: "Grasston",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55031",
    city: "Hampton",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55032",
    city: "Harris",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55033",
    city: "Hastings",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55036",
    city: "Henriette",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55037",
    city: "Hinckley",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55038",
    city: "Hugo",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55040",
    city: "Isanti",
    state: "MN",
    areacode: 763,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55041",
    city: "Lake City",
    state: "MN",
    areacode: 651,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55042",
    city: "Lake Elmo",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55043",
    city: "Lakeland",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55044",
    city: "Lakeville",
    state: "MN",
    areacode: 952,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55045",
    city: "Lindstrom",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55046",
    city: "Lonsdale",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55047",
    city: "Marine on Saint Croix",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55049",
    city: "Medford",
    state: "MN",
    areacode: 507,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55051",
    city: "Mora",
    state: "MN",
    areacode: 320,
    county: "Kanabec",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55052",
    city: "Morristown",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55053",
    city: "Nerstrand",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55054",
    city: "Elko New Market",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55055",
    city: "Newport",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55056",
    city: "North Branch",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55057",
    city: "Northfield",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55060",
    city: "Owatonna",
    state: "MN",
    areacode: 507,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55063",
    city: "Pine City",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55065",
    city: "Randolph",
    state: "MN",
    areacode: 507,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55066",
    city: "Red Wing",
    state: "MN",
    areacode: 651,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55067",
    city: "Rock Creek",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55068",
    city: "Rosemount",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55069",
    city: "Rush City",
    state: "MN",
    areacode: 320,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55070",
    city: "Saint Francis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55071",
    city: "Saint Paul Park",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55072",
    city: "Sandstone",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55073",
    city: "Scandia",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55074",
    city: "Shafer",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55075",
    city: "South Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55076",
    city: "Inver Grove Heights",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55077",
    city: "Inver Grove Heights",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55078",
    city: "Stacy",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55079",
    city: "Stacy",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55080",
    city: "Stanchfield",
    state: "MN",
    areacode: 320,
    county: "Isanti",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55082",
    city: "Stillwater",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55083",
    city: "Stillwater",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55084",
    city: "Taylors Falls",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55085",
    city: "Vermillion",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55087",
    city: "Warsaw",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55088",
    city: "Webster",
    state: "MN",
    areacode: 952,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55089",
    city: "Welch",
    state: "MN",
    areacode: 651,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55090",
    city: "Willernie",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55092",
    city: "Wyoming",
    state: "MN",
    areacode: 651,
    county: "Chisago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55101",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55102",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55103",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55104",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55105",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55106",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55107",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55108",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55109",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55110",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55111",
    city: "Saint Paul",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55112",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55113",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55114",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55115",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55116",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55117",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55118",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55119",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55120",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55121",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55122",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55123",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55124",
    city: "Saint Paul",
    state: "MN",
    areacode: 952,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55125",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55126",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55127",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55128",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55129",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55130",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55131",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55133",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55144",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55145",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55146",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55150",
    city: "Mendota",
    state: "MN",
    areacode: 651,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55155",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55164",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55170",
    city: "Saint Paul",
    state: "MN",
    areacode: 651,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55301",
    city: "Albertville",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55302",
    city: "Annandale",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55303",
    city: "Anoka",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55304",
    city: "Andover",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55305",
    city: "Hopkins",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55306",
    city: "Burnsville",
    state: "MN",
    areacode: 952,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55307",
    city: "Arlington",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55308",
    city: "Becker",
    state: "MN",
    areacode: 763,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55309",
    city: "Big Lake",
    state: "MN",
    areacode: 763,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55310",
    city: "Bird Island",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55311",
    city: "Maple Grove",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55312",
    city: "Brownton",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55313",
    city: "Buffalo",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55314",
    city: "Buffalo Lake",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55315",
    city: "Carver",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55316",
    city: "Champlin",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55317",
    city: "Chanhassen",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55318",
    city: "Chaska",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55319",
    city: "Clear Lake",
    state: "MN",
    areacode: 320,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55320",
    city: "Clearwater",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55321",
    city: "Cokato",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55322",
    city: "Cologne",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55323",
    city: "Crystal Bay",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55324",
    city: "Darwin",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55325",
    city: "Dassel",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55327",
    city: "Dayton",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55328",
    city: "Delano",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55329",
    city: "Eden Valley",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55330",
    city: "Elk River",
    state: "MN",
    areacode: 763,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55331",
    city: "Excelsior",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55332",
    city: "Fairfax",
    state: "MN",
    areacode: 507,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55333",
    city: "Franklin",
    state: "MN",
    areacode: 507,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55334",
    city: "Gaylord",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55335",
    city: "Gibbon",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55336",
    city: "Glencoe",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55337",
    city: "Burnsville",
    state: "MN",
    areacode: 952,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55338",
    city: "Green Isle",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55339",
    city: "Hamburg",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55340",
    city: "Hamel",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55341",
    city: "Hanover",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55342",
    city: "Hector",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55343",
    city: "Hopkins",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55344",
    city: "Eden Prairie",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55345",
    city: "Minnetonka",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55346",
    city: "Eden Prairie",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55347",
    city: "Eden Prairie",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55349",
    city: "Howard Lake",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55350",
    city: "Hutchinson",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55352",
    city: "Jordan",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55353",
    city: "Kimball",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55354",
    city: "Lester Prairie",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55355",
    city: "Litchfield",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55356",
    city: "Long Lake",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55357",
    city: "Loretto",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55358",
    city: "Maple Lake",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55359",
    city: "Maple Plain",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55360",
    city: "Mayer",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55361",
    city: "Minnetonka Beach",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55362",
    city: "Monticello",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55363",
    city: "Montrose",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55364",
    city: "Mound",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55366",
    city: "New Auburn",
    state: "MN",
    areacode: 320,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55367",
    city: "New Germany",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55368",
    city: "Norwood Young America",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55369",
    city: "Osseo",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55370",
    city: "Plato",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55371",
    city: "Princeton",
    state: "MN",
    areacode: 763,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55372",
    city: "Prior Lake",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55373",
    city: "Rockford",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55374",
    city: "Rogers",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55375",
    city: "Saint Bonifacius",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55376",
    city: "Saint Michael",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55377",
    city: "Santiago",
    state: "MN",
    areacode: 763,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55378",
    city: "Savage",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55379",
    city: "Shakopee",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55381",
    city: "Silver Lake",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55382",
    city: "South Haven",
    state: "MN",
    areacode: 320,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55384",
    city: "Spring Park",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55385",
    city: "Stewart",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55386",
    city: "Victoria",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55387",
    city: "Waconia",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55388",
    city: "Watertown",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55389",
    city: "Watkins",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55390",
    city: "Waverly",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55391",
    city: "Wayzata",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55395",
    city: "Winsted",
    state: "MN",
    areacode: 320,
    county: "McLeod",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55396",
    city: "Winthrop",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55397",
    city: "Young America",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55398",
    city: "Zimmerman",
    state: "MN",
    areacode: 763,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55401",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55402",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55403",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55404",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55405",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55406",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55407",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55408",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55409",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55410",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55411",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55412",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55413",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55414",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55415",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55416",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55417",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55418",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55419",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55420",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55421",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55422",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55423",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55424",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55425",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55426",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55427",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55428",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55429",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55430",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55431",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55432",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55433",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55434",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55435",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55436",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55437",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55438",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55439",
    city: "Minneapolis",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55440",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55441",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55442",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55443",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55444",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55445",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55446",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55447",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55448",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55449",
    city: "Minneapolis",
    state: "MN",
    areacode: 763,
    county: "Anoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55450",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55454",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55455",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55458",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55459",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55467",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55470",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55472",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55474",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55478",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55479",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55480",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55483",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55484",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55485",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55486",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55487",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55488",
    city: "Minneapolis",
    state: "MN",
    areacode: 612,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55554",
    city: "Norwood",
    state: "MN",
    areacode: 952,
    county: "Carver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55569",
    city: "Osseo",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55572",
    city: "Rockford",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55573",
    city: "Young America",
    state: "MN",
    areacode: 952,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55575",
    city: "Howard Lake",
    state: "MN",
    areacode: 320,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55577",
    city: "Rockford",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55592",
    city: "Maple Plain",
    state: "MN",
    areacode: 763,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55593",
    city: "Maple Plain",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55599",
    city: "Loretto",
    state: "MN",
    areacode: 763,
    county: "Hennepin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55601",
    city: "Beaver Bay",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55602",
    city: "Brimson",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55603",
    city: "Finland",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55604",
    city: "Grand Marais",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55605",
    city: "Grand Portage",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55606",
    city: "Hovland",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55607",
    city: "Isabella",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55609",
    city: "Knife River",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55612",
    city: "Lutsen",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55613",
    city: "Schroeder",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55614",
    city: "Silver Bay",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55615",
    city: "Tofte",
    state: "MN",
    areacode: 218,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55616",
    city: "Two Harbors",
    state: "MN",
    areacode: 218,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55702",
    city: "Alborn",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55703",
    city: "Angora",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55704",
    city: "Askov",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55705",
    city: "Aurora",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55706",
    city: "Babbitt",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55707",
    city: "Barnum",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55708",
    city: "Biwabik",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55709",
    city: "Bovey",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55710",
    city: "Britt",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55711",
    city: "Brookston",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55712",
    city: "Bruno",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55713",
    city: "Buhl",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55716",
    city: "Calumet",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55717",
    city: "Canyon",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55718",
    city: "Carlton",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55719",
    city: "Chisholm",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55720",
    city: "Cloquet",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55721",
    city: "Cohasset",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55722",
    city: "Coleraine",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55723",
    city: "Cook",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55724",
    city: "Cotton",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55725",
    city: "Crane Lake",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55726",
    city: "Cromwell",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55730",
    city: "Grand Rapids",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55731",
    city: "Ely",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55732",
    city: "Embarrass",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55733",
    city: "Esko",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55734",
    city: "Eveleth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55735",
    city: "Finlayson",
    state: "MN",
    areacode: 320,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55736",
    city: "Floodwood",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55738",
    city: "Forbes",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55741",
    city: "Gilbert",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55742",
    city: "Goodland",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55744",
    city: "Grand Rapids",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55745",
    city: "Grand Rapids",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55746",
    city: "Hibbing",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55748",
    city: "Hill City",
    state: "MN",
    areacode: 218,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55749",
    city: "Holyoke",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55750",
    city: "Hoyt Lakes",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55751",
    city: "Iron",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55752",
    city: "Jacobson",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55753",
    city: "Keewatin",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55756",
    city: "Kerrick",
    state: "MN",
    areacode: 218,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55757",
    city: "Kettle River",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55758",
    city: "Kinney",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55760",
    city: "McGregor",
    state: "MN",
    areacode: 218,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55763",
    city: "Makinen",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55764",
    city: "Marble",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55765",
    city: "Meadowlands",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55766",
    city: "Melrude",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55767",
    city: "Moose Lake",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55768",
    city: "Mountain Iron",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55769",
    city: "Nashwauk",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55771",
    city: "Orr",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55772",
    city: "Nett Lake",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55775",
    city: "Pengilly",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55777",
    city: "Virginia",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55779",
    city: "Saginaw",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55780",
    city: "Sawyer",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55781",
    city: "Side Lake",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55782",
    city: "Soudan",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55783",
    city: "Sturgeon Lake",
    state: "MN",
    areacode: 218,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55784",
    city: "Swan River",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55785",
    city: "Swatara",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55786",
    city: "Taconite",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55787",
    city: "Tamarack",
    state: "MN",
    areacode: 218,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55790",
    city: "Tower",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55791",
    city: "Twig",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55792",
    city: "Virginia",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55793",
    city: "Warba",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55795",
    city: "Willow River",
    state: "MN",
    areacode: 218,
    county: "Pine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55796",
    city: "Winton",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55797",
    city: "Wrenshall",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55798",
    city: "Wright",
    state: "MN",
    areacode: 218,
    county: "Carlton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55801",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55802",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55803",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55804",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55805",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55806",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55807",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55808",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55810",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55811",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55812",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55814",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55815",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55816",
    city: "Duluth",
    state: "MN",
    areacode: 218,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55901",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55902",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55903",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55904",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55905",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55906",
    city: "Rochester",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55909",
    city: "Adams",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55910",
    city: "Altura",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55912",
    city: "Austin",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55917",
    city: "Blooming Prairie",
    state: "MN",
    areacode: 507,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55918",
    city: "Brownsdale",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55919",
    city: "Brownsville",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55920",
    city: "Byron",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55921",
    city: "Caledonia",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55922",
    city: "Canton",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55923",
    city: "Chatfield",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55924",
    city: "Claremont",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55925",
    city: "Dakota",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55926",
    city: "Dexter",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55927",
    city: "Dodge Center",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55929",
    city: "Dover",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55931",
    city: "Eitzen",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55932",
    city: "Elgin",
    state: "MN",
    areacode: 507,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55933",
    city: "Elkton",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55934",
    city: "Eyota",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55935",
    city: "Fountain",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55936",
    city: "Grand Meadow",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55939",
    city: "Harmony",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55940",
    city: "Hayfield",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55941",
    city: "Hokah",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55942",
    city: "Homer",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55943",
    city: "Houston",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55944",
    city: "Kasson",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55945",
    city: "Kellogg",
    state: "MN",
    areacode: 507,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55946",
    city: "Kenyon",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55947",
    city: "La Crescent",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55949",
    city: "Lanesboro",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55950",
    city: "Lansing",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55951",
    city: "Le Roy",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55952",
    city: "Lewiston",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55953",
    city: "Lyle",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55954",
    city: "Mabel",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55955",
    city: "Mantorville",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55956",
    city: "Mazeppa",
    state: "MN",
    areacode: 507,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55957",
    city: "Millville",
    state: "MN",
    areacode: 507,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55959",
    city: "Minnesota City",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55960",
    city: "Oronoco",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55961",
    city: "Ostrander",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55962",
    city: "Peterson",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55963",
    city: "Pine Island",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55964",
    city: "Plainview",
    state: "MN",
    areacode: 507,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55965",
    city: "Preston",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55967",
    city: "Racine",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55968",
    city: "Reads Landing",
    state: "MN",
    areacode: 651,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55969",
    city: "Rollingstone",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55970",
    city: "Rose Creek",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55971",
    city: "Rushford",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55972",
    city: "Saint Charles",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55973",
    city: "Sargeant",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55974",
    city: "Spring Grove",
    state: "MN",
    areacode: 507,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55975",
    city: "Spring Valley",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55976",
    city: "Stewartville",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55977",
    city: "Taopi",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55979",
    city: "Utica",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55981",
    city: "Wabasha",
    state: "MN",
    areacode: 651,
    county: "Wabasha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55982",
    city: "Waltham",
    state: "MN",
    areacode: 507,
    county: "Mower",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55983",
    city: "Wanamingo",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55985",
    city: "West Concord",
    state: "MN",
    areacode: 507,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55987",
    city: "Winona",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55988",
    city: "Stockton",
    state: "MN",
    areacode: 507,
    county: "Winona",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55990",
    city: "Wykoff",
    state: "MN",
    areacode: 507,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55991",
    city: "Zumbro Falls",
    state: "MN",
    areacode: 507,
    county: "Olmsted",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "55992",
    city: "Zumbrota",
    state: "MN",
    areacode: 507,
    county: "Goodhue",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56001",
    city: "Mankato",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56002",
    city: "Mankato",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56003",
    city: "Mankato",
    state: "MN",
    areacode: 507,
    county: "Nicollet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56007",
    city: "Albert Lea",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56009",
    city: "Alden",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56010",
    city: "Amboy",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56011",
    city: "Belle Plaine",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56013",
    city: "Blue Earth",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56014",
    city: "Bricelyn",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56016",
    city: "Clarks Grove",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56017",
    city: "Cleveland",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56019",
    city: "Comfrey",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56020",
    city: "Conger",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56021",
    city: "Courtland",
    state: "MN",
    areacode: 507,
    county: "Nicollet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56022",
    city: "Darfur",
    state: "MN",
    areacode: 507,
    county: "Watonwan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56023",
    city: "Delavan",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56024",
    city: "Eagle Lake",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56025",
    city: "Easton",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56026",
    city: "Ellendale",
    state: "MN",
    areacode: 507,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56027",
    city: "Elmore",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56028",
    city: "Elysian",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56029",
    city: "Emmons",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56030",
    city: "Essig",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56031",
    city: "Fairmont",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56032",
    city: "Freeborn",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56033",
    city: "Frost",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56034",
    city: "Garden City",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56035",
    city: "Geneva",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56036",
    city: "Glenville",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56037",
    city: "Good Thunder",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56039",
    city: "Granada",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56041",
    city: "Hanska",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56042",
    city: "Hartland",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56043",
    city: "Hayward",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56044",
    city: "Henderson",
    state: "MN",
    areacode: 507,
    county: "Sibley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56045",
    city: "Hollandale",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56046",
    city: "Hope",
    state: "MN",
    areacode: 507,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56047",
    city: "Huntley",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56048",
    city: "Janesville",
    state: "MN",
    areacode: 507,
    county: "Waseca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56050",
    city: "Kasota",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56051",
    city: "Kiester",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56052",
    city: "Kilkenny",
    state: "MN",
    areacode: 507,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56054",
    city: "Lafayette",
    state: "MN",
    areacode: 507,
    county: "Nicollet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56055",
    city: "Lake Crystal",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56056",
    city: "La Salle",
    state: "MN",
    areacode: 507,
    county: "Watonwan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56057",
    city: "Le Center",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56058",
    city: "Le Sueur",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56060",
    city: "Lewisville",
    state: "MN",
    areacode: 507,
    county: "Watonwan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56062",
    city: "Madelia",
    state: "MN",
    areacode: 507,
    county: "Watonwan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56063",
    city: "Madison Lake",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56065",
    city: "Mapleton",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56068",
    city: "Minnesota Lake",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56069",
    city: "Montgomery",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56071",
    city: "New Prague",
    state: "MN",
    areacode: 952,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56072",
    city: "New Richland",
    state: "MN",
    areacode: 507,
    county: "Waseca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56073",
    city: "New Ulm",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56074",
    city: "Nicollet",
    state: "MN",
    areacode: 507,
    county: "Nicollet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56075",
    city: "Northrop",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56078",
    city: "Pemberton",
    state: "MN",
    areacode: 507,
    county: "Waseca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56080",
    city: "Saint Clair",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56081",
    city: "Saint James",
    state: "MN",
    areacode: 507,
    county: "Watonwan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56082",
    city: "Saint Peter",
    state: "MN",
    areacode: 507,
    county: "Nicollet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56083",
    city: "Sanborn",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56084",
    city: "Searles",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56085",
    city: "Sleepy Eye",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56087",
    city: "Springfield",
    state: "MN",
    areacode: 507,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56088",
    city: "Truman",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56089",
    city: "Twin Lakes",
    state: "MN",
    areacode: 507,
    county: "Freeborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56090",
    city: "Vernon Center",
    state: "MN",
    areacode: 507,
    county: "Blue Earth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56091",
    city: "Waldorf",
    state: "MN",
    areacode: 507,
    county: "Waseca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56093",
    city: "Waseca",
    state: "MN",
    areacode: 507,
    county: "Waseca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56096",
    city: "Waterville",
    state: "MN",
    areacode: 507,
    county: "Le Sueur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56097",
    city: "Wells",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56098",
    city: "Winnebago",
    state: "MN",
    areacode: 507,
    county: "Faribault",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56101",
    city: "Windom",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56110",
    city: "Adrian",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56111",
    city: "Alpha",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56113",
    city: "Arco",
    state: "MN",
    areacode: 507,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56114",
    city: "Avoca",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56115",
    city: "Balaton",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56116",
    city: "Beaver Creek",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56117",
    city: "Bigelow",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56118",
    city: "Bingham Lake",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56119",
    city: "Brewster",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56120",
    city: "Butterfield",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56121",
    city: "Ceylon",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56122",
    city: "Chandler",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56123",
    city: "Currie",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56125",
    city: "Dovray",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56127",
    city: "Dunnell",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56128",
    city: "Edgerton",
    state: "MN",
    areacode: 507,
    county: "Pipestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56129",
    city: "Ellsworth",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56131",
    city: "Fulda",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56132",
    city: "Garvin",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56134",
    city: "Hardwick",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56136",
    city: "Hendricks",
    state: "MN",
    areacode: 507,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56137",
    city: "Heron Lake",
    state: "MN",
    areacode: 507,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56138",
    city: "Hills",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56139",
    city: "Holland",
    state: "MN",
    areacode: 507,
    county: "Pipestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56140",
    city: "Ihlen",
    state: "MN",
    areacode: 507,
    county: "Pipestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56141",
    city: "Iona",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56142",
    city: "Ivanhoe",
    state: "MN",
    areacode: 507,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56143",
    city: "Jackson",
    state: "MN",
    areacode: 507,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56144",
    city: "Jasper",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56145",
    city: "Jeffers",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56146",
    city: "Kanaranzi",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56147",
    city: "Kenneth",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56149",
    city: "Lake Benton",
    state: "MN",
    areacode: 507,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56150",
    city: "Lakefield",
    state: "MN",
    areacode: 507,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56151",
    city: "Lake Wilson",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56152",
    city: "Lamberton",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56153",
    city: "Leota",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56155",
    city: "Lismore",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56156",
    city: "Luverne",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56157",
    city: "Lynd",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56158",
    city: "Magnolia",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56159",
    city: "Mountain Lake",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56160",
    city: "Odin",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56161",
    city: "Okabena",
    state: "MN",
    areacode: 507,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56162",
    city: "Ormsby",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56164",
    city: "Pipestone",
    state: "MN",
    areacode: 507,
    county: "Pipestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56165",
    city: "Reading",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56166",
    city: "Revere",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56167",
    city: "Round Lake",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56168",
    city: "Rushmore",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56169",
    city: "Russell",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56170",
    city: "Ruthton",
    state: "MN",
    areacode: 507,
    county: "Pipestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56171",
    city: "Sherburn",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56172",
    city: "Slayton",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56173",
    city: "Steen",
    state: "MN",
    areacode: 507,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56174",
    city: "Storden",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56175",
    city: "Tracy",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56176",
    city: "Trimont",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56178",
    city: "Tyler",
    state: "MN",
    areacode: 507,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56180",
    city: "Walnut Grove",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56181",
    city: "Welcome",
    state: "MN",
    areacode: 507,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56183",
    city: "Westbrook",
    state: "MN",
    areacode: 507,
    county: "Cottonwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56185",
    city: "Wilmont",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56186",
    city: "Woodstock",
    state: "MN",
    areacode: 507,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56187",
    city: "Worthington",
    state: "MN",
    areacode: 507,
    county: "Nobles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56201",
    city: "Willmar",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56207",
    city: "Alberta",
    state: "MN",
    areacode: 320,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56208",
    city: "Appleton",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56209",
    city: "Atwater",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56210",
    city: "Barry",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56211",
    city: "Beardsley",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56212",
    city: "Bellingham",
    state: "MN",
    areacode: 320,
    county: "Lac Qui Parle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56214",
    city: "Belview",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56215",
    city: "Benson",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56216",
    city: "Blomkest",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56218",
    city: "Boyd",
    state: "MN",
    areacode: 320,
    county: "Lac Qui Parle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56219",
    city: "Browns Valley",
    state: "MN",
    areacode: 320,
    county: "Traverse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56220",
    city: "Canby",
    state: "MN",
    areacode: 507,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56221",
    city: "Chokio",
    state: "MN",
    areacode: 320,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56222",
    city: "Clara City",
    state: "MN",
    areacode: 320,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56223",
    city: "Clarkfield",
    state: "MN",
    areacode: 320,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56224",
    city: "Clements",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56225",
    city: "Clinton",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56226",
    city: "Clontarf",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56227",
    city: "Correll",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56228",
    city: "Cosmos",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56229",
    city: "Cottonwood",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56230",
    city: "Danube",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56231",
    city: "Danvers",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56232",
    city: "Dawson",
    state: "MN",
    areacode: 320,
    county: "Lac Qui Parle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56235",
    city: "Donnelly",
    state: "MN",
    areacode: 320,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56236",
    city: "Dumont",
    state: "MN",
    areacode: 320,
    county: "Traverse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56237",
    city: "Echo",
    state: "MN",
    areacode: 507,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56239",
    city: "Ghent",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56240",
    city: "Graceville",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56241",
    city: "Granite Falls",
    state: "MN",
    areacode: 320,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56243",
    city: "Grove City",
    state: "MN",
    areacode: 320,
    county: "Meeker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56244",
    city: "Hancock",
    state: "MN",
    areacode: 320,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56245",
    city: "Hanley Falls",
    state: "MN",
    areacode: 507,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56248",
    city: "Herman",
    state: "MN",
    areacode: 320,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56249",
    city: "Holloway",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56251",
    city: "Kandiyohi",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56252",
    city: "Kerkhoven",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56253",
    city: "Lake Lillian",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56255",
    city: "Lucan",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56256",
    city: "Madison",
    state: "MN",
    areacode: 320,
    county: "Lac Qui Parle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56257",
    city: "Marietta",
    state: "MN",
    areacode: 320,
    county: "Lac Qui Parle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56258",
    city: "Marshall",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56260",
    city: "Maynard",
    state: "MN",
    areacode: 320,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56262",
    city: "Milan",
    state: "MN",
    areacode: 320,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56263",
    city: "Milroy",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56264",
    city: "Minneota",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56265",
    city: "Montevideo",
    state: "MN",
    areacode: 320,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56266",
    city: "Morgan",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56267",
    city: "Morris",
    state: "MN",
    areacode: 320,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56270",
    city: "Morton",
    state: "MN",
    areacode: 507,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56271",
    city: "Murdock",
    state: "MN",
    areacode: 320,
    county: "Swift",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56273",
    city: "New London",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56274",
    city: "Norcross",
    state: "MN",
    areacode: 320,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56276",
    city: "Odessa",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56277",
    city: "Olivia",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56278",
    city: "Ortonville",
    state: "MN",
    areacode: 320,
    county: "Big Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56279",
    city: "Pennock",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56280",
    city: "Porter",
    state: "MN",
    areacode: 507,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56281",
    city: "Prinsburg",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56282",
    city: "Raymond",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56283",
    city: "Redwood Falls",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56284",
    city: "Renville",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56285",
    city: "Sacred Heart",
    state: "MN",
    areacode: 320,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56287",
    city: "Seaforth",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56288",
    city: "Spicer",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56289",
    city: "Sunburg",
    state: "MN",
    areacode: 320,
    county: "Kandiyohi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56291",
    city: "Taunton",
    state: "MN",
    areacode: 507,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56292",
    city: "Vesta",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56293",
    city: "Wabasso",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56294",
    city: "Wanda",
    state: "MN",
    areacode: 507,
    county: "Redwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56295",
    city: "Watson",
    state: "MN",
    areacode: 320,
    county: "Chippewa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56296",
    city: "Wheaton",
    state: "MN",
    areacode: 320,
    county: "Traverse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56297",
    city: "Wood Lake",
    state: "MN",
    areacode: 507,
    county: "Yellow Medicine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56301",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56302",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56303",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56304",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Sherburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56307",
    city: "Albany",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56308",
    city: "Alexandria",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56309",
    city: "Ashby",
    state: "MN",
    areacode: 218,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56310",
    city: "Avon",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56311",
    city: "Barrett",
    state: "MN",
    areacode: 320,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56312",
    city: "Belgrade",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56313",
    city: "Bock",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56314",
    city: "Bowlus",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56315",
    city: "Brandon",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56316",
    city: "Brooten",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56317",
    city: "Buckman",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56318",
    city: "Burtrum",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56319",
    city: "Carlos",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56320",
    city: "Cold Spring",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56321",
    city: "Collegeville",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56323",
    city: "Cyrus",
    state: "MN",
    areacode: 320,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56324",
    city: "Dalton",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56325",
    city: "Elrosa",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56326",
    city: "Evansville",
    state: "MN",
    areacode: 218,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56327",
    city: "Farwell",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56328",
    city: "Flensburg",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56329",
    city: "Foley",
    state: "MN",
    areacode: 320,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56330",
    city: "Foreston",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56331",
    city: "Freeport",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56332",
    city: "Garfield",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56333",
    city: "Gilman",
    state: "MN",
    areacode: 320,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56334",
    city: "Glenwood",
    state: "MN",
    areacode: 320,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56335",
    city: "Greenwald",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56336",
    city: "Grey Eagle",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56338",
    city: "Hillman",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56339",
    city: "Hoffman",
    state: "MN",
    areacode: 320,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56340",
    city: "Holdingford",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56341",
    city: "Holmes City",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56342",
    city: "Isle",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56343",
    city: "Kensington",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56344",
    city: "Lastrup",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56345",
    city: "Little Falls",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56347",
    city: "Long Prairie",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56349",
    city: "Lowry",
    state: "MN",
    areacode: 320,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56350",
    city: "McGrath",
    state: "MN",
    areacode: 320,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56352",
    city: "Melrose",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56353",
    city: "Milaca",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56354",
    city: "Miltona",
    state: "MN",
    areacode: 218,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56355",
    city: "Nelson",
    state: "MN",
    areacode: 320,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56356",
    city: "New Munich",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56357",
    city: "Oak Park",
    state: "MN",
    areacode: 320,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56358",
    city: "Ogilvie",
    state: "MN",
    areacode: 320,
    county: "Kanabec",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56359",
    city: "Onamia",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56360",
    city: "Osakis",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56361",
    city: "Parkers Prairie",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56362",
    city: "Paynesville",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56363",
    city: "Pease",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56364",
    city: "Pierz",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56367",
    city: "Rice",
    state: "MN",
    areacode: 320,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56368",
    city: "Richmond",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56369",
    city: "Rockville",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56371",
    city: "Roscoe",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56372",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56373",
    city: "Royalton",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56374",
    city: "Saint Joseph",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56375",
    city: "Saint Stephen",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56376",
    city: "Saint Martin",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56377",
    city: "Sartell",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56378",
    city: "Sauk Centre",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56379",
    city: "Sauk Rapids",
    state: "MN",
    areacode: 320,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56381",
    city: "Starbuck",
    state: "MN",
    areacode: 320,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56382",
    city: "Swanville",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56384",
    city: "Upsala",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56385",
    city: "Villard",
    state: "MN",
    areacode: 320,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56386",
    city: "Wahkon",
    state: "MN",
    areacode: 320,
    county: "Mille Lacs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56387",
    city: "Waite Park",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56388",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56389",
    city: "West Union",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56393",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56395",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56396",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56397",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56398",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56399",
    city: "Saint Cloud",
    state: "MN",
    areacode: 320,
    county: "Stearns",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56401",
    city: "Brainerd",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56425",
    city: "Baxter",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56431",
    city: "Aitkin",
    state: "MN",
    areacode: 218,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56433",
    city: "Akeley",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56434",
    city: "Aldrich",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56435",
    city: "Backus",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56436",
    city: "Benedict",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56437",
    city: "Bertha",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56438",
    city: "Browerville",
    state: "MN",
    areacode: 320,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56440",
    city: "Clarissa",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56441",
    city: "Crosby",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56442",
    city: "Crosslake",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56443",
    city: "Cushing",
    state: "MN",
    areacode: 218,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56444",
    city: "Deerwood",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56446",
    city: "Eagle Bend",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56447",
    city: "Emily",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56448",
    city: "Fifty Lakes",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56449",
    city: "Fort Ripley",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56450",
    city: "Garrison",
    state: "MN",
    areacode: 320,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56452",
    city: "Hackensack",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56453",
    city: "Hewitt",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56455",
    city: "Ironton",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56456",
    city: "Jenkins",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56458",
    city: "Lake George",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56459",
    city: "Lake Hubert",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56461",
    city: "Laporte",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56464",
    city: "Menahga",
    state: "MN",
    areacode: 218,
    county: "Wadena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56465",
    city: "Merrifield",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56466",
    city: "Motley",
    state: "MN",
    areacode: 218,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56467",
    city: "Nevis",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56468",
    city: "Nisswa",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56469",
    city: "Palisade",
    state: "MN",
    areacode: 218,
    county: "Aitkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56470",
    city: "Park Rapids",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56472",
    city: "Pequot Lakes",
    state: "MN",
    areacode: 218,
    county: "Crow Wing",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56473",
    city: "Pillager",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56474",
    city: "Pine River",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56475",
    city: "Randall",
    state: "MN",
    areacode: 320,
    county: "Morrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56477",
    city: "Sebeka",
    state: "MN",
    areacode: 218,
    county: "Wadena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56479",
    city: "Staples",
    state: "MN",
    areacode: 218,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56481",
    city: "Verndale",
    state: "MN",
    areacode: 218,
    county: "Wadena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56482",
    city: "Wadena",
    state: "MN",
    areacode: 218,
    county: "Wadena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56484",
    city: "Walker",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56501",
    city: "Detroit Lakes",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56502",
    city: "Detroit Lakes",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56510",
    city: "Ada",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56511",
    city: "Audubon",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56514",
    city: "Barnesville",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56515",
    city: "Battle Lake",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56516",
    city: "Bejou",
    state: "MN",
    areacode: 218,
    county: "Mahnomen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56517",
    city: "Beltrami",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56518",
    city: "Bluffton",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56519",
    city: "Borup",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56520",
    city: "Breckenridge",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56521",
    city: "Callaway",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56522",
    city: "Campbell",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56523",
    city: "Climax",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56524",
    city: "Clitherall",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56525",
    city: "Comstock",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56527",
    city: "Deer Creek",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56528",
    city: "Dent",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56529",
    city: "Dilworth",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56531",
    city: "Elbow Lake",
    state: "MN",
    areacode: 218,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56533",
    city: "Elizabeth",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56534",
    city: "Erhard",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56535",
    city: "Erskine",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56536",
    city: "Felton",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56537",
    city: "Fergus Falls",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56538",
    city: "Fergus Falls",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56540",
    city: "Fertile",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56541",
    city: "Flom",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56542",
    city: "Fosston",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56543",
    city: "Foxhome",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56544",
    city: "Frazee",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56545",
    city: "Gary",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56546",
    city: "Georgetown",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56547",
    city: "Glyndon",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56548",
    city: "Halstad",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56549",
    city: "Hawley",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56550",
    city: "Hendrum",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56551",
    city: "Henning",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56552",
    city: "Hitterdal",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56553",
    city: "Kent",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56554",
    city: "Lake Park",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56556",
    city: "McIntosh",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56557",
    city: "Mahnomen",
    state: "MN",
    areacode: 218,
    county: "Mahnomen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56560",
    city: "Moorhead",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56561",
    city: "Moorhead",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56562",
    city: "Moorhead",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56563",
    city: "Moorhead",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56565",
    city: "Nashua",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56566",
    city: "Naytahwaush",
    state: "MN",
    areacode: 218,
    county: "Mahnomen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56567",
    city: "New York Mills",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56568",
    city: "Nielsville",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56569",
    city: "Ogema",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56570",
    city: "Osage",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56571",
    city: "Ottertail",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56572",
    city: "Pelican Rapids",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56573",
    city: "Perham",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56574",
    city: "Perley",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56575",
    city: "Ponsford",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56576",
    city: "Richville",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56577",
    city: "Richwood",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56578",
    city: "Rochert",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56579",
    city: "Rothsay",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56580",
    city: "Sabin",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56581",
    city: "Shelly",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56583",
    city: "Tintah",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56584",
    city: "Twin Valley",
    state: "MN",
    areacode: 218,
    county: "Norman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56585",
    city: "Ulen",
    state: "MN",
    areacode: 218,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56586",
    city: "Underwood",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56587",
    city: "Vergas",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56588",
    city: "Vining",
    state: "MN",
    areacode: 218,
    county: "Otter Tail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56589",
    city: "Waubun",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56590",
    city: "Wendell",
    state: "MN",
    areacode: 218,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56591",
    city: "White Earth",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56592",
    city: "Winger",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56593",
    city: "Wolf Lake",
    state: "MN",
    areacode: 218,
    county: "Becker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56594",
    city: "Wolverton",
    state: "MN",
    areacode: 218,
    county: "Wilkin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56601",
    city: "Bemidji",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56619",
    city: "Bemidji",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56621",
    city: "Bagley",
    state: "MN",
    areacode: 218,
    county: "Clearwater",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56623",
    city: "Baudette",
    state: "MN",
    areacode: 218,
    county: "Lake of the Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56626",
    city: "Bena",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56627",
    city: "Big Falls",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56628",
    city: "Bigfork",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56629",
    city: "Birchdale",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56630",
    city: "Blackduck",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56631",
    city: "Bowstring",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56633",
    city: "Cass Lake",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56634",
    city: "Clearbrook",
    state: "MN",
    areacode: 218,
    county: "Clearwater",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56636",
    city: "Deer River",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56637",
    city: "Talmoon",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56639",
    city: "Effie",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56641",
    city: "Federal Dam",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56644",
    city: "Gonvick",
    state: "MN",
    areacode: 218,
    county: "Clearwater",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56646",
    city: "Gully",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56647",
    city: "Hines",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56649",
    city: "International Falls",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56650",
    city: "Kelliher",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56651",
    city: "Lengby",
    state: "MN",
    areacode: 218,
    county: "Mahnomen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56652",
    city: "Leonard",
    state: "MN",
    areacode: 218,
    county: "Clearwater",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56653",
    city: "Littlefork",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56654",
    city: "Loman",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56655",
    city: "Longville",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56657",
    city: "Marcell",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56658",
    city: "Margie",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56659",
    city: "Max",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56660",
    city: "Mizpah",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56661",
    city: "Northome",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56662",
    city: "Outing",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56663",
    city: "Pennington",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56666",
    city: "Ponemah",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56667",
    city: "Puposky",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56668",
    city: "Ranier",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56669",
    city: "Kabetogama",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56670",
    city: "Redby",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56671",
    city: "Redlake",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56672",
    city: "Remer",
    state: "MN",
    areacode: 218,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56673",
    city: "Roosevelt",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56676",
    city: "Shevlin",
    state: "MN",
    areacode: 218,
    county: "Clearwater",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56678",
    city: "Solway",
    state: "MN",
    areacode: 218,
    county: "Hubbard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56679",
    city: "South International Falls",
    state: "MN",
    areacode: 218,
    county: "Koochiching",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56680",
    city: "Spring Lake",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56681",
    city: "Squaw Lake",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56683",
    city: "Tenstrike",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56684",
    city: "Trail",
    state: "MN",
    areacode: 218,
    county: "Pennington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56685",
    city: "Waskish",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56686",
    city: "Williams",
    state: "MN",
    areacode: 218,
    county: "Lake of the Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56687",
    city: "Wilton",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56688",
    city: "Wirt",
    state: "MN",
    areacode: 218,
    county: "Itasca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56701",
    city: "Thief River Falls",
    state: "MN",
    areacode: 218,
    county: "Pennington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56710",
    city: "Alvarado",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56711",
    city: "Angle Inlet",
    state: "MN",
    areacode: 218,
    county: "Lake of the Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56713",
    city: "Argyle",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56714",
    city: "Badger",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56715",
    city: "Brooks",
    state: "MN",
    areacode: 218,
    county: "Red Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56716",
    city: "Crookston",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56720",
    city: "Donaldson",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56721",
    city: "East Grand Forks",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56722",
    city: "Euclid",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56723",
    city: "Fisher",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56724",
    city: "Gatzke",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56725",
    city: "Goodridge",
    state: "MN",
    areacode: 218,
    county: "Pennington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56726",
    city: "Greenbush",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56727",
    city: "Grygla",
    state: "MN",
    areacode: 218,
    county: "Beltrami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56728",
    city: "Hallock",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56729",
    city: "Halma",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56731",
    city: "Humboldt",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56732",
    city: "Karlstad",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56733",
    city: "Kennedy",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56734",
    city: "Lake Bronson",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56735",
    city: "Lancaster",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56736",
    city: "Mentor",
    state: "MN",
    areacode: 218,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56737",
    city: "Middle River",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56738",
    city: "Newfolden",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56740",
    city: "Noyes",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56741",
    city: "Oak Island",
    state: "MN",
    areacode: 218,
    county: "Lake of the Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56742",
    city: "Oklee",
    state: "MN",
    areacode: 218,
    county: "Red Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56744",
    city: "Oslo",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56748",
    city: "Plummer",
    state: "MN",
    areacode: 218,
    county: "Red Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56750",
    city: "Red Lake Falls",
    state: "MN",
    areacode: 218,
    county: "Red Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56751",
    city: "Roseau",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56754",
    city: "Saint Hilaire",
    state: "MN",
    areacode: 218,
    county: "Pennington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56755",
    city: "Saint Vincent",
    state: "MN",
    areacode: 218,
    county: "Kittson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56756",
    city: "Salol",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56757",
    city: "Stephen",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56758",
    city: "Strandquist",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56759",
    city: "Strathcona",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56760",
    city: "Viking",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56761",
    city: "Wannaska",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56762",
    city: "Warren",
    state: "MN",
    areacode: 218,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56763",
    city: "Warroad",
    state: "MN",
    areacode: 218,
    county: "Roseau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "56901",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56902",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56904",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56908",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56915",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56920",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56933",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56935",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56944",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56945",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56950",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56964",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56965",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56966",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56967",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56968",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56969",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56970",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56971",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56972",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56973",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56980",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56981",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56982",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56983",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56984",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56985",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56998",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "56999",
    city: "Parcel Return Service",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "57001",
    city: "Alcester",
    state: "SD",
    areacode: 605,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57002",
    city: "Aurora",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57003",
    city: "Baltic",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57004",
    city: "Beresford",
    state: "SD",
    areacode: 605,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57005",
    city: "Brandon",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57006",
    city: "Brookings",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57007",
    city: "Brookings",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57010",
    city: "Burbank",
    state: "SD",
    areacode: 605,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57012",
    city: "Canistota",
    state: "SD",
    areacode: 605,
    county: "McCook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57013",
    city: "Canton",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57014",
    city: "Centerville",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57015",
    city: "Chancellor",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57016",
    city: "Chester",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57017",
    city: "Colman",
    state: "SD",
    areacode: 605,
    county: "Moody",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57018",
    city: "Colton",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57020",
    city: "Crooks",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57021",
    city: "Davis",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57022",
    city: "Dell Rapids",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57024",
    city: "Egan",
    state: "SD",
    areacode: 605,
    county: "Moody",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57025",
    city: "Elk Point",
    state: "SD",
    areacode: 605,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57026",
    city: "Elkton",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57027",
    city: "Fairview",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57028",
    city: "Flandreau",
    state: "SD",
    areacode: 605,
    county: "Moody",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57029",
    city: "Freeman",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57030",
    city: "Garretson",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57031",
    city: "Gayville",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57032",
    city: "Harrisburg",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57033",
    city: "Hartford",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57034",
    city: "Hudson",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57035",
    city: "Humboldt",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57036",
    city: "Hurley",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57037",
    city: "Irene",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57038",
    city: "Jefferson",
    state: "SD",
    areacode: 605,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57039",
    city: "Lennox",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57040",
    city: "Lesterville",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57041",
    city: "Lyons",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57042",
    city: "Madison",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57043",
    city: "Marion",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57045",
    city: "Menno",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57046",
    city: "Mission Hill",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57047",
    city: "Monroe",
    state: "SD",
    areacode: 605,
    county: "McCook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57048",
    city: "Montrose",
    state: "SD",
    areacode: 605,
    county: "McCook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57049",
    city: "North Sioux City",
    state: "SD",
    areacode: 605,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57050",
    city: "Nunda",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57051",
    city: "Oldham",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57052",
    city: "Olivet",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57053",
    city: "Parker",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57054",
    city: "Ramona",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57055",
    city: "Renner",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57057",
    city: "Rutland",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57058",
    city: "Salem",
    state: "SD",
    areacode: 605,
    county: "McCook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57059",
    city: "Scotland",
    state: "SD",
    areacode: 605,
    county: "Bon Homme",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57061",
    city: "Sinai",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57062",
    city: "Springfield",
    state: "SD",
    areacode: 605,
    county: "Bon Homme",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57063",
    city: "Tabor",
    state: "SD",
    areacode: 605,
    county: "Bon Homme",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57064",
    city: "Tea",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57065",
    city: "Trent",
    state: "SD",
    areacode: 605,
    county: "Moody",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57066",
    city: "Tyndall",
    state: "SD",
    areacode: 605,
    county: "Bon Homme",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57067",
    city: "Utica",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57068",
    city: "Valley Springs",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57069",
    city: "Vermillion",
    state: "SD",
    areacode: 605,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57070",
    city: "Viborg",
    state: "SD",
    areacode: 605,
    county: "Turner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57071",
    city: "Volga",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57072",
    city: "Volin",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57073",
    city: "Wakonda",
    state: "SD",
    areacode: 605,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57075",
    city: "Wentworth",
    state: "SD",
    areacode: 605,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57076",
    city: "Winfred",
    state: "SD",
    areacode: 605,
    county: "Miner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57077",
    city: "Worthing",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57078",
    city: "Yankton",
    state: "SD",
    areacode: 605,
    county: "Yankton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57101",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57103",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57104",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57105",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57106",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57107",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57108",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57109",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57110",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57117",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57118",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57186",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57193",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57197",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57198",
    city: "Sioux Falls",
    state: "SD",
    areacode: 605,
    county: "Minnehaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57201",
    city: "Watertown",
    state: "SD",
    areacode: 605,
    county: "Codington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57212",
    city: "Arlington",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57213",
    city: "Astoria",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57214",
    city: "Badger",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57216",
    city: "Big Stone City",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57217",
    city: "Bradley",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57218",
    city: "Brandt",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57219",
    city: "Bristol",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57220",
    city: "Bruce",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57221",
    city: "Bryant",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57223",
    city: "Castlewood",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57224",
    city: "Claire City",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57225",
    city: "Clark",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57226",
    city: "Clear Lake",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57227",
    city: "Corona",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57231",
    city: "De Smet",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57232",
    city: "Eden",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57233",
    city: "Erwin",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57234",
    city: "Estelline",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57235",
    city: "Florence",
    state: "SD",
    areacode: 605,
    county: "Codington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57236",
    city: "Garden City",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57237",
    city: "Gary",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57238",
    city: "Goodwin",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57239",
    city: "Grenville",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57241",
    city: "Hayti",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57242",
    city: "Hazel",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57243",
    city: "Henry",
    state: "SD",
    areacode: 605,
    county: "Codington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57245",
    city: "Kranzburg",
    state: "SD",
    areacode: 605,
    county: "Codington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57246",
    city: "Labolt",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57247",
    city: "Lake City",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57248",
    city: "Lake Norden",
    state: "SD",
    areacode: 605,
    county: "Hamlin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57249",
    city: "Lake Preston",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57251",
    city: "Marvin",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57252",
    city: "Milbank",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57255",
    city: "New Effington",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57256",
    city: "Ortley",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57257",
    city: "Peever",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57258",
    city: "Raymond",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57259",
    city: "Revillo",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57260",
    city: "Rosholt",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57261",
    city: "Roslyn",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57262",
    city: "Sisseton",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57263",
    city: "South Shore",
    state: "SD",
    areacode: 605,
    county: "Codington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57264",
    city: "Stockholm",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57265",
    city: "Strandburg",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57266",
    city: "Summit",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57268",
    city: "Toronto",
    state: "SD",
    areacode: 605,
    county: "Deuel",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57269",
    city: "Twin Brooks",
    state: "SD",
    areacode: 605,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57270",
    city: "Veblen",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57271",
    city: "Vienna",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57272",
    city: "Wallace",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57273",
    city: "Waubay",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57274",
    city: "Webster",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57276",
    city: "White",
    state: "SD",
    areacode: 605,
    county: "Brookings",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57278",
    city: "Willow Lake",
    state: "SD",
    areacode: 605,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57279",
    city: "Wilmot",
    state: "SD",
    areacode: 605,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57301",
    city: "Mitchell",
    state: "SD",
    areacode: 605,
    county: "Davison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57311",
    city: "Alexandria",
    state: "SD",
    areacode: 605,
    county: "Hanson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57312",
    city: "Alpena",
    state: "SD",
    areacode: 605,
    county: "Jerauld",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57313",
    city: "Armour",
    state: "SD",
    areacode: 605,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57314",
    city: "Artesian",
    state: "SD",
    areacode: 605,
    county: "Sanborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57315",
    city: "Avon",
    state: "SD",
    areacode: 605,
    county: "Bon Homme",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57317",
    city: "Bonesteel",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57319",
    city: "Bridgewater",
    state: "SD",
    areacode: 605,
    county: "McCook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57321",
    city: "Canova",
    state: "SD",
    areacode: 605,
    county: "Miner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57322",
    city: "Carpenter",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57323",
    city: "Carthage",
    state: "SD",
    areacode: 605,
    county: "Miner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57324",
    city: "Cavour",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57325",
    city: "Chamberlain",
    state: "SD",
    areacode: 605,
    county: "Brule",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57326",
    city: "Chamberlain",
    state: "SD",
    areacode: 605,
    county: "Brule",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57328",
    city: "Corsica",
    state: "SD",
    areacode: 605,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57329",
    city: "Dante",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57330",
    city: "Delmont",
    state: "SD",
    areacode: 605,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57331",
    city: "Dimock",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57332",
    city: "Emery",
    state: "SD",
    areacode: 605,
    county: "Hanson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57334",
    city: "Ethan",
    state: "SD",
    areacode: 605,
    county: "Davison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57335",
    city: "Fairfax",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57337",
    city: "Fedora",
    state: "SD",
    areacode: 605,
    county: "Miner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57339",
    city: "Fort Thompson",
    state: "SD",
    areacode: 605,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57340",
    city: "Fulton",
    state: "SD",
    areacode: 605,
    county: "Hanson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57341",
    city: "Gann Valley",
    state: "SD",
    areacode: 605,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57342",
    city: "Geddes",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57344",
    city: "Harrison",
    state: "SD",
    areacode: 605,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57345",
    city: "Highmore",
    state: "SD",
    areacode: 605,
    county: "Hyde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57346",
    city: "Stephan",
    state: "SD",
    areacode: 605,
    county: "Hyde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57348",
    city: "Hitchcock",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57349",
    city: "Howard",
    state: "SD",
    areacode: 605,
    county: "Miner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57350",
    city: "Huron",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57353",
    city: "Iroquois",
    state: "SD",
    areacode: 605,
    county: "Kingsbury",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57354",
    city: "Kaylor",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57355",
    city: "Kimball",
    state: "SD",
    areacode: 605,
    county: "Jerauld",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57356",
    city: "Lake Andes",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57358",
    city: "Lane",
    state: "SD",
    areacode: 605,
    county: "Jerauld",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57359",
    city: "Letcher",
    state: "SD",
    areacode: 605,
    county: "Sanborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57361",
    city: "Marty",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57362",
    city: "Miller",
    state: "SD",
    areacode: 605,
    county: "Hand",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57363",
    city: "Mount Vernon",
    state: "SD",
    areacode: 605,
    county: "Davison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57364",
    city: "New Holland",
    state: "SD",
    areacode: 605,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57365",
    city: "Oacoma",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57366",
    city: "Parkston",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57367",
    city: "Pickstown",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57368",
    city: "Plankinton",
    state: "SD",
    areacode: 605,
    county: "Aurora",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57369",
    city: "Platte",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57370",
    city: "Pukwana",
    state: "SD",
    areacode: 605,
    county: "Brule",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57371",
    city: "Ree Heights",
    state: "SD",
    areacode: 605,
    county: "Hand",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57373",
    city: "Saint Lawrence",
    state: "SD",
    areacode: 605,
    county: "Hand",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57374",
    city: "Spencer",
    state: "SD",
    areacode: 605,
    county: "Hanson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57375",
    city: "Stickney",
    state: "SD",
    areacode: 605,
    county: "Aurora",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57376",
    city: "Tripp",
    state: "SD",
    areacode: 605,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57379",
    city: "Virgil",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57380",
    city: "Wagner",
    state: "SD",
    areacode: 605,
    county: "Charles Mix",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57381",
    city: "Wessington",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57382",
    city: "Wessington Springs",
    state: "SD",
    areacode: 605,
    county: "Jerauld",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57383",
    city: "White Lake",
    state: "SD",
    areacode: 605,
    county: "Aurora",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57384",
    city: "Wolsey",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57385",
    city: "Woonsocket",
    state: "SD",
    areacode: 605,
    county: "Sanborn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57386",
    city: "Yale",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57399",
    city: "Huron",
    state: "SD",
    areacode: 605,
    county: "Beadle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57401",
    city: "Aberdeen",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57402",
    city: "Aberdeen",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57420",
    city: "Akaska",
    state: "SD",
    areacode: 605,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57421",
    city: "Amherst",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57422",
    city: "Andover",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57424",
    city: "Ashton",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57426",
    city: "Barnard",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57427",
    city: "Bath",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57428",
    city: "Bowdle",
    state: "SD",
    areacode: 605,
    county: "Edmunds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57429",
    city: "Brentford",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57430",
    city: "Britton",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57432",
    city: "Claremont",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57433",
    city: "Columbia",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57434",
    city: "Conde",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57435",
    city: "Cresbard",
    state: "SD",
    areacode: 605,
    county: "Faulk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57436",
    city: "Doland",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57437",
    city: "Eureka",
    state: "SD",
    areacode: 605,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57438",
    city: "Faulkton",
    state: "SD",
    areacode: 605,
    county: "Faulk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57439",
    city: "Ferney",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57440",
    city: "Frankfort",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57441",
    city: "Frederick",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57442",
    city: "Gettysburg",
    state: "SD",
    areacode: 605,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57445",
    city: "Groton",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57446",
    city: "Hecla",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57448",
    city: "Hosmer",
    state: "SD",
    areacode: 605,
    county: "Edmunds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57449",
    city: "Houghton",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57450",
    city: "Hoven",
    state: "SD",
    areacode: 605,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57451",
    city: "Ipswich",
    state: "SD",
    areacode: 605,
    county: "Edmunds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57452",
    city: "Java",
    state: "SD",
    areacode: 605,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57454",
    city: "Langford",
    state: "SD",
    areacode: 605,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57455",
    city: "Lebanon",
    state: "SD",
    areacode: 605,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57456",
    city: "Leola",
    state: "SD",
    areacode: 605,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57457",
    city: "Long Lake",
    state: "SD",
    areacode: 605,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57460",
    city: "Mansfield",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57461",
    city: "Mellette",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57465",
    city: "Northville",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57466",
    city: "Onaka",
    state: "SD",
    areacode: 605,
    county: "Faulk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57467",
    city: "Orient",
    state: "SD",
    areacode: 605,
    county: "Hand",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57468",
    city: "Pierpont",
    state: "SD",
    areacode: 605,
    county: "Day",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57469",
    city: "Redfield",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57470",
    city: "Rockham",
    state: "SD",
    areacode: 605,
    county: "Faulk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57471",
    city: "Roscoe",
    state: "SD",
    areacode: 605,
    county: "Edmunds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57472",
    city: "Selby",
    state: "SD",
    areacode: 605,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57473",
    city: "Seneca",
    state: "SD",
    areacode: 605,
    county: "Faulk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57474",
    city: "Stratford",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57475",
    city: "Tolstoy",
    state: "SD",
    areacode: 605,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57476",
    city: "Tulare",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57477",
    city: "Turton",
    state: "SD",
    areacode: 605,
    county: "Spink",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57479",
    city: "Warner",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57481",
    city: "Westport",
    state: "SD",
    areacode: 605,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57501",
    city: "Pierre",
    state: "SD",
    areacode: 605,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57520",
    city: "Agar",
    state: "SD",
    areacode: 605,
    county: "Sully",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57521",
    city: "Belvidere",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57522",
    city: "Blunt",
    state: "SD",
    areacode: 605,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57523",
    city: "Burke",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57528",
    city: "Colome",
    state: "SD",
    areacode: 605,
    county: "Tripp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57529",
    city: "Dallas",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57531",
    city: "Draper",
    state: "SD",
    areacode: 605,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57532",
    city: "Fort Pierre",
    state: "SD",
    areacode: 605,
    county: "Stanley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57533",
    city: "Gregory",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57534",
    city: "Hamill",
    state: "SD",
    areacode: 605,
    county: "Tripp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57536",
    city: "Harrold",
    state: "SD",
    areacode: 605,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57537",
    city: "Hayes",
    state: "SD",
    areacode: 605,
    county: "Stanley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57538",
    city: "Herrick",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57540",
    city: "Holabird",
    state: "SD",
    areacode: 605,
    county: "Hyde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57541",
    city: "Ideal",
    state: "SD",
    areacode: 605,
    county: "Tripp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57543",
    city: "Kadoka",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57544",
    city: "Kennebec",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57547",
    city: "Long Valley",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57548",
    city: "Lower Brule",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57551",
    city: "Martin",
    state: "SD",
    areacode: 605,
    county: "Bennett",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57552",
    city: "Midland",
    state: "SD",
    areacode: 605,
    county: "Haakon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57553",
    city: "Milesville",
    state: "SD",
    areacode: 605,
    county: "Haakon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57555",
    city: "Mission",
    state: "SD",
    areacode: 605,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57559",
    city: "Murdo",
    state: "SD",
    areacode: 605,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57560",
    city: "Norris",
    state: "SD",
    areacode: 605,
    county: "Mellette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57562",
    city: "Okaton",
    state: "SD",
    areacode: 605,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57563",
    city: "Okreek",
    state: "SD",
    areacode: 605,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57564",
    city: "Onida",
    state: "SD",
    areacode: 605,
    county: "Sully",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57566",
    city: "Parmelee",
    state: "SD",
    areacode: 605,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57567",
    city: "Philip",
    state: "SD",
    areacode: 605,
    county: "Haakon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57568",
    city: "Presho",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57569",
    city: "Reliance",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57570",
    city: "Rosebud",
    state: "SD",
    areacode: 605,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57571",
    city: "Saint Charles",
    state: "SD",
    areacode: 605,
    county: "Gregory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57572",
    city: "Saint Francis",
    state: "SD",
    areacode: 605,
    county: "Todd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57574",
    city: "Tuthill",
    state: "SD",
    areacode: 605,
    county: "Bennett",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57576",
    city: "Vivian",
    state: "SD",
    areacode: 605,
    county: "Lyman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57577",
    city: "Wanblee",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57579",
    city: "White River",
    state: "SD",
    areacode: 605,
    county: "Mellette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57580",
    city: "Winner",
    state: "SD",
    areacode: 605,
    county: "Tripp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57584",
    city: "Witten",
    state: "SD",
    areacode: 605,
    county: "Tripp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57585",
    city: "Wood",
    state: "SD",
    areacode: 605,
    county: "Mellette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57601",
    city: "Mobridge",
    state: "SD",
    areacode: 605,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57620",
    city: "Bison",
    state: "SD",
    areacode: 605,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57621",
    city: "Bullhead",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57622",
    city: "Cherry Creek",
    state: "SD",
    areacode: 605,
    county: "Ziebach",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57623",
    city: "Dupree",
    state: "SD",
    areacode: 605,
    county: "Ziebach",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57625",
    city: "Eagle Butte",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57626",
    city: "Faith",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57630",
    city: "Glencross",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57631",
    city: "Glenham",
    state: "SD",
    areacode: 605,
    county: "Walworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57632",
    city: "Herreid",
    state: "SD",
    areacode: 605,
    county: "Campbell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57633",
    city: "Isabel",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57634",
    city: "Keldron",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57636",
    city: "Lantry",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57638",
    city: "Lemmon",
    state: "SD",
    areacode: 605,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57639",
    city: "Little Eagle",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57640",
    city: "Lodgepole",
    state: "SD",
    areacode: 605,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57641",
    city: "McIntosh",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57642",
    city: "McLaughlin",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57644",
    city: "Meadow",
    state: "SD",
    areacode: 605,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57645",
    city: "Morristown",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57646",
    city: "Mound City",
    state: "SD",
    areacode: 605,
    county: "Campbell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57648",
    city: "Pollock",
    state: "SD",
    areacode: 605,
    county: "Campbell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "57649",
    city: "Prairie City",
    state: "SD",
    areacode: 605,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57650",
    city: "Ralph",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57651",
    city: "Reva",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57652",
    city: "Ridgeview",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57656",
    city: "Timber Lake",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57657",
    city: "Trail City",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57658",
    city: "Wakpala",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57659",
    city: "Walker",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57660",
    city: "Watauga",
    state: "SD",
    areacode: 605,
    county: "Corson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57661",
    city: "Whitehorse",
    state: "SD",
    areacode: 605,
    county: "Dewey",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57701",
    city: "Rapid City",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57702",
    city: "Rapid City",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57703",
    city: "Rapid City",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57706",
    city: "Ellsworth AFB",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57709",
    city: "Rapid City",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57714",
    city: "Allen",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57716",
    city: "Batesland",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57717",
    city: "Belle Fourche",
    state: "SD",
    areacode: 605,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57718",
    city: "Black Hawk",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57719",
    city: "Box Elder",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57720",
    city: "Buffalo",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57722",
    city: "Buffalo Gap",
    state: "SD",
    areacode: 605,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57724",
    city: "Camp Crook",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57725",
    city: "Caputa",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57730",
    city: "Custer",
    state: "SD",
    areacode: 605,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57732",
    city: "Deadwood",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57735",
    city: "Edgemont",
    state: "SD",
    areacode: 605,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57737",
    city: "Enning",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57738",
    city: "Fairburn",
    state: "SD",
    areacode: 605,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57741",
    city: "Fort Meade",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57744",
    city: "Hermosa",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57745",
    city: "Hill City",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57747",
    city: "Hot Springs",
    state: "SD",
    areacode: 605,
    county: "Fall River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57748",
    city: "Howes",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57750",
    city: "Interior",
    state: "SD",
    areacode: 605,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57751",
    city: "Keystone",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57752",
    city: "Kyle",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57754",
    city: "Lead",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57755",
    city: "Ludlow",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57756",
    city: "Manderson",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57758",
    city: "Mud Butte",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57759",
    city: "Nemo",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57760",
    city: "Newell",
    state: "SD",
    areacode: 605,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57761",
    city: "New Underwood",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57762",
    city: "Nisland",
    state: "SD",
    areacode: 605,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57763",
    city: "Oelrichs",
    state: "SD",
    areacode: 605,
    county: "Fall River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57764",
    city: "Oglala",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57766",
    city: "Oral",
    state: "SD",
    areacode: 605,
    county: "Fall River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57767",
    city: "Owanka",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57769",
    city: "Piedmont",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57770",
    city: "Pine Ridge",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57772",
    city: "Porcupine",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57773",
    city: "Pringle",
    state: "SD",
    areacode: 605,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57775",
    city: "Quinn",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57776",
    city: "Redig",
    state: "SD",
    areacode: 605,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57779",
    city: "Saint Onge",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57780",
    city: "Scenic",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57782",
    city: "Smithwick",
    state: "SD",
    areacode: 605,
    county: "Fall River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57783",
    city: "Spearfish",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57785",
    city: "Sturgis",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57787",
    city: "Union Center",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57788",
    city: "Vale",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57790",
    city: "Wall",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57791",
    city: "Wasta",
    state: "SD",
    areacode: 605,
    county: "Pennington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57792",
    city: "White Owl",
    state: "SD",
    areacode: 605,
    county: "Meade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57793",
    city: "Whitewood",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57794",
    city: "Wounded Knee",
    state: "SD",
    areacode: 605,
    county: "Oglala Lakota",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "57799",
    city: "Spearfish",
    state: "SD",
    areacode: 605,
    county: "Lawrence",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58001",
    city: "Abercrombie",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58002",
    city: "Absaraka",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58004",
    city: "Amenia",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58005",
    city: "Argusville",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58006",
    city: "Arthur",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58007",
    city: "Ayr",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58008",
    city: "Barney",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58009",
    city: "Blanchard",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58011",
    city: "Buffalo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58012",
    city: "Casselton",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58013",
    city: "Cayuga",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58015",
    city: "Christine",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58016",
    city: "Clifford",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58017",
    city: "Cogswell",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58018",
    city: "Colfax",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58021",
    city: "Davenport",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58027",
    city: "Enderlin",
    state: "ND",
    areacode: 701,
    county: "Ransom",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58029",
    city: "Erie",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58030",
    city: "Fairmount",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58031",
    city: "Fingal",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58032",
    city: "Forman",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58033",
    city: "Fort Ransom",
    state: "ND",
    areacode: 701,
    county: "Ransom",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58035",
    city: "Galesburg",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58036",
    city: "Gardner",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58038",
    city: "Grandin",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58040",
    city: "Gwinner",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58041",
    city: "Hankinson",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58042",
    city: "Harwood",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58043",
    city: "Havana",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58045",
    city: "Hillsboro",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58046",
    city: "Hope",
    state: "ND",
    areacode: 701,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58047",
    city: "Horace",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58048",
    city: "Hunter",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58049",
    city: "Kathryn",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58051",
    city: "Kindred",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58052",
    city: "Leonard",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58053",
    city: "Lidgerwood",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58054",
    city: "Lisbon",
    state: "ND",
    areacode: 701,
    county: "Ransom",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58056",
    city: "Luverne",
    state: "ND",
    areacode: 701,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58057",
    city: "McLeod",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58058",
    city: "Mantador",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58059",
    city: "Mapleton",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58060",
    city: "Milnor",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58061",
    city: "Mooreton",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58062",
    city: "Nome",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58063",
    city: "Oriska",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58064",
    city: "Page",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58065",
    city: "Pillsbury",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58067",
    city: "Rutland",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58068",
    city: "Sheldon",
    state: "ND",
    areacode: 701,
    county: "Ransom",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58069",
    city: "Stirum",
    state: "ND",
    areacode: 701,
    county: "Sargent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58071",
    city: "Tower City",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58072",
    city: "Valley City",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58074",
    city: "Wahpeton",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58075",
    city: "Wahpeton",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58076",
    city: "Wahpeton",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58077",
    city: "Walcott",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58078",
    city: "West Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58079",
    city: "Wheatland",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58081",
    city: "Wyndmere",
    state: "ND",
    areacode: 701,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58102",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58103",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58104",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58105",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58106",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58107",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58108",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58109",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58121",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58122",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58124",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58125",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58126",
    city: "Fargo",
    state: "ND",
    areacode: 701,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58201",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58202",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58203",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58204",
    city: "Grand Forks AFB",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58205",
    city: "Grand Forks AFB",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58206",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58207",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58208",
    city: "Grand Forks",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58210",
    city: "Adams",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58212",
    city: "Aneta",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58214",
    city: "Arvilla",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58216",
    city: "Bathgate",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58218",
    city: "Buxton",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58219",
    city: "Caledonia",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58220",
    city: "Cavalier",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58222",
    city: "Crystal",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58223",
    city: "Cummings",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58224",
    city: "Dahlen",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58225",
    city: "Drayton",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58227",
    city: "Edinburg",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58228",
    city: "Emerado",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58229",
    city: "Fairdale",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58230",
    city: "Finley",
    state: "ND",
    areacode: 701,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58231",
    city: "Fordville",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58233",
    city: "Forest River",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58235",
    city: "Gilby",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58236",
    city: "Glasston",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58237",
    city: "Grafton",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58238",
    city: "Hamilton",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58239",
    city: "Hannah",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58240",
    city: "Hatton",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58241",
    city: "Hensel",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58243",
    city: "Hoople",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58244",
    city: "Inkster",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58249",
    city: "Langdon",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58250",
    city: "Lankin",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58251",
    city: "Larimore",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58254",
    city: "McVille",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58255",
    city: "Maida",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58256",
    city: "Manvel",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58257",
    city: "Mayville",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58258",
    city: "Mekinock",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58259",
    city: "Michigan",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58260",
    city: "Milton",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58261",
    city: "Minto",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58262",
    city: "Mountain",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58265",
    city: "Neche",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58266",
    city: "Niagara",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58267",
    city: "Northwood",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58269",
    city: "Osnabrock",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58270",
    city: "Park River",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58271",
    city: "Pembina",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58272",
    city: "Petersburg",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58273",
    city: "Pisek",
    state: "ND",
    areacode: 701,
    county: "Walsh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58274",
    city: "Portland",
    state: "ND",
    areacode: 701,
    county: "Traill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58275",
    city: "Reynolds",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58276",
    city: "Saint Thomas",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58277",
    city: "Sharon",
    state: "ND",
    areacode: 701,
    county: "Steele",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58278",
    city: "Thompson",
    state: "ND",
    areacode: 701,
    county: "Grand Forks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58281",
    city: "Wales",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58282",
    city: "Walhalla",
    state: "ND",
    areacode: 701,
    county: "Pembina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58301",
    city: "Devils Lake",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58310",
    city: "Agate",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58311",
    city: "Alsen",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58313",
    city: "Balta",
    state: "ND",
    areacode: 701,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58316",
    city: "Belcourt",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58317",
    city: "Bisbee",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58318",
    city: "Bottineau",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58321",
    city: "Brocket",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58323",
    city: "Calvin",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58324",
    city: "Cando",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58325",
    city: "Churchs Ferry",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58327",
    city: "Crary",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58329",
    city: "Dunseith",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58330",
    city: "Edmore",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58331",
    city: "Egeland",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58332",
    city: "Esmond",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58335",
    city: "Fort Totten",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58338",
    city: "Hampden",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58339",
    city: "Hansboro",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58341",
    city: "Harvey",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58343",
    city: "Knox",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58344",
    city: "Lakota",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58345",
    city: "Lawton",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58346",
    city: "Leeds",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58348",
    city: "Maddock",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58351",
    city: "Minnewaukan",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58352",
    city: "Munich",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58353",
    city: "Mylo",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58355",
    city: "Nekoma",
    state: "ND",
    areacode: 701,
    county: "Cavalier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58356",
    city: "New Rockford",
    state: "ND",
    areacode: 701,
    county: "Eddy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58357",
    city: "Oberon",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58361",
    city: "Pekin",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58362",
    city: "Penn",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58363",
    city: "Perth",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58365",
    city: "Rocklake",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58366",
    city: "Rolette",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58367",
    city: "Rolla",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58368",
    city: "Rugby",
    state: "ND",
    areacode: 701,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58369",
    city: "Saint John",
    state: "ND",
    areacode: 701,
    county: "Rolette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58370",
    city: "Saint Michael",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58372",
    city: "Sarles",
    state: "ND",
    areacode: 701,
    county: "Towner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58374",
    city: "Sheyenne",
    state: "ND",
    areacode: 701,
    county: "Eddy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58377",
    city: "Starkweather",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58379",
    city: "Tokio",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58380",
    city: "Tolna",
    state: "ND",
    areacode: 701,
    county: "Nelson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58381",
    city: "Warwick",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58382",
    city: "Webster",
    state: "ND",
    areacode: 701,
    county: "Ramsey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58384",
    city: "Willow City",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58385",
    city: "Wolford",
    state: "ND",
    areacode: 701,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58386",
    city: "York",
    state: "ND",
    areacode: 701,
    county: "Benson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58401",
    city: "Jamestown",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58402",
    city: "Jamestown",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58405",
    city: "Jamestown",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58413",
    city: "Ashley",
    state: "ND",
    areacode: 701,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58415",
    city: "Berlin",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58416",
    city: "Binford",
    state: "ND",
    areacode: 701,
    county: "Griggs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58418",
    city: "Bowdon",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58420",
    city: "Buchanan",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58421",
    city: "Carrington",
    state: "ND",
    areacode: 701,
    county: "Foster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58422",
    city: "Cathay",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58423",
    city: "Chaseley",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58424",
    city: "Cleveland",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58425",
    city: "Cooperstown",
    state: "ND",
    areacode: 701,
    county: "Griggs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58426",
    city: "Courtenay",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58428",
    city: "Dawson",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58429",
    city: "Dazey",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58430",
    city: "Denhoff",
    state: "ND",
    areacode: 701,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58431",
    city: "Dickey",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58433",
    city: "Edgeley",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58436",
    city: "Ellendale",
    state: "ND",
    areacode: 701,
    county: "Dickey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58438",
    city: "Fessenden",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58439",
    city: "Forbes",
    state: "ND",
    areacode: 701,
    county: "Dickey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58440",
    city: "Fredonia",
    state: "ND",
    areacode: 701,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58441",
    city: "Fullerton",
    state: "ND",
    areacode: 701,
    county: "Dickey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58442",
    city: "Gackle",
    state: "ND",
    areacode: 701,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58443",
    city: "Glenfield",
    state: "ND",
    areacode: 701,
    county: "Foster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58444",
    city: "Goodrich",
    state: "ND",
    areacode: 701,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58445",
    city: "Grace City",
    state: "ND",
    areacode: 701,
    county: "Foster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58448",
    city: "Hannaford",
    state: "ND",
    areacode: 701,
    county: "Griggs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58451",
    city: "Hurdsfield",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58452",
    city: "Jessie",
    state: "ND",
    areacode: 701,
    county: "Griggs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58454",
    city: "Jud",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58455",
    city: "Kensal",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58456",
    city: "Kulm",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58458",
    city: "Lamoure",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58460",
    city: "Lehr",
    state: "ND",
    areacode: 701,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58461",
    city: "Litchville",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58463",
    city: "McClusky",
    state: "ND",
    areacode: 701,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58464",
    city: "McHenry",
    state: "ND",
    areacode: 701,
    county: "Foster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58466",
    city: "Marion",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58467",
    city: "Medina",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58472",
    city: "Montpelier",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58474",
    city: "Oakes",
    state: "ND",
    areacode: 701,
    county: "Dickey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58475",
    city: "Pettibone",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58476",
    city: "Pingree",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58477",
    city: "Regan",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58478",
    city: "Robinson",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58479",
    city: "Rogers",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58480",
    city: "Sanborn",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58481",
    city: "Spiritwood",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58482",
    city: "Steele",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58483",
    city: "Streeter",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58484",
    city: "Sutton",
    state: "ND",
    areacode: 701,
    county: "Griggs",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58486",
    city: "Sykeston",
    state: "ND",
    areacode: 701,
    county: "Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58487",
    city: "Tappen",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58488",
    city: "Tuttle",
    state: "ND",
    areacode: 701,
    county: "Kidder",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58490",
    city: "Verona",
    state: "ND",
    areacode: 701,
    county: "Lamoure",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58492",
    city: "Wimbledon",
    state: "ND",
    areacode: 701,
    county: "Barnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58494",
    city: "Wing",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58495",
    city: "Wishek",
    state: "ND",
    areacode: 701,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58496",
    city: "Woodworth",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58497",
    city: "Ypsilanti",
    state: "ND",
    areacode: 701,
    county: "Stutsman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58501",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58502",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58503",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58504",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58505",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58506",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58507",
    city: "Bismarck",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58520",
    city: "Almont",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58521",
    city: "Baldwin",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58523",
    city: "Beulah",
    state: "ND",
    areacode: 701,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58524",
    city: "Braddock",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58528",
    city: "Cannon Ball",
    state: "ND",
    areacode: 701,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58529",
    city: "Carson",
    state: "ND",
    areacode: 701,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58530",
    city: "Center",
    state: "ND",
    areacode: 701,
    county: "Oliver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58531",
    city: "Coleharbor",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58532",
    city: "Driscoll",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58533",
    city: "Elgin",
    state: "ND",
    areacode: 701,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58535",
    city: "Flasher",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58538",
    city: "Fort Yates",
    state: "ND",
    areacode: 701,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58540",
    city: "Garrison",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58541",
    city: "Golden Valley",
    state: "ND",
    areacode: 701,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58542",
    city: "Hague",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58544",
    city: "Hazelton",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58545",
    city: "Hazen",
    state: "ND",
    areacode: 701,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58549",
    city: "Kintyre",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58552",
    city: "Linton",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58554",
    city: "Mandan",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58558",
    city: "Menoken",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58559",
    city: "Mercer",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58560",
    city: "Moffit",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58561",
    city: "Napoleon",
    state: "ND",
    areacode: 701,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58562",
    city: "New Leipzig",
    state: "ND",
    areacode: 701,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58563",
    city: "New Salem",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58564",
    city: "Raleigh",
    state: "ND",
    areacode: 701,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58565",
    city: "Riverdale",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58566",
    city: "Saint Anthony",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58568",
    city: "Selfridge",
    state: "ND",
    areacode: 701,
    county: "Sioux",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58569",
    city: "Shields",
    state: "ND",
    areacode: 701,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58570",
    city: "Solen",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58571",
    city: "Stanton",
    state: "ND",
    areacode: 701,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58572",
    city: "Sterling",
    state: "ND",
    areacode: 701,
    county: "Burleigh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58573",
    city: "Strasburg",
    state: "ND",
    areacode: 701,
    county: "Emmons",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58575",
    city: "Turtle Lake",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58576",
    city: "Underwood",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58577",
    city: "Washburn",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58579",
    city: "Wilton",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58580",
    city: "Zap",
    state: "ND",
    areacode: 701,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58581",
    city: "Zeeland",
    state: "ND",
    areacode: 701,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58601",
    city: "Dickinson",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58602",
    city: "Dickinson",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58620",
    city: "Amidon",
    state: "ND",
    areacode: 701,
    county: "Slope",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58621",
    city: "Beach",
    state: "ND",
    areacode: 701,
    county: "Golden Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58622",
    city: "Belfield",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58623",
    city: "Bowman",
    state: "ND",
    areacode: 701,
    county: "Bowman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58625",
    city: "Dodge",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58626",
    city: "Dunn Center",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58627",
    city: "Fairfield",
    state: "ND",
    areacode: 701,
    county: "Billings",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58630",
    city: "Gladstone",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58631",
    city: "Glen Ullin",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58632",
    city: "Golva",
    state: "ND",
    areacode: 701,
    county: "Golden Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58634",
    city: "Grassy Butte",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58636",
    city: "Halliday",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58638",
    city: "Hebron",
    state: "ND",
    areacode: 701,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58639",
    city: "Hettinger",
    state: "ND",
    areacode: 701,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58640",
    city: "Killdeer",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58641",
    city: "Lefor",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58642",
    city: "Manning",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58643",
    city: "Marmarth",
    state: "ND",
    areacode: 701,
    county: "Slope",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58644",
    city: "Marshall",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58645",
    city: "Medora",
    state: "ND",
    areacode: 701,
    county: "Billings",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58646",
    city: "Mott",
    state: "ND",
    areacode: 701,
    county: "Hettinger",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58647",
    city: "New England",
    state: "ND",
    areacode: 701,
    county: "Hettinger",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58649",
    city: "Reeder",
    state: "ND",
    areacode: 701,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58650",
    city: "Regent",
    state: "ND",
    areacode: 701,
    county: "Hettinger",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58651",
    city: "Rhame",
    state: "ND",
    areacode: 701,
    county: "Bowman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58652",
    city: "Richardton",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58653",
    city: "Scranton",
    state: "ND",
    areacode: 701,
    county: "Bowman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58654",
    city: "Sentinel Butte",
    state: "ND",
    areacode: 701,
    county: "Golden Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58655",
    city: "South Heart",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58656",
    city: "Taylor",
    state: "ND",
    areacode: 701,
    county: "Stark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "58701",
    city: "Minot",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58702",
    city: "Minot",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58703",
    city: "Minot",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58704",
    city: "Minot AFB",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58705",
    city: "Minot AFB",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58707",
    city: "Minot",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58710",
    city: "Anamoose",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58711",
    city: "Antler",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58712",
    city: "Balfour",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58713",
    city: "Bantry",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58716",
    city: "Benedict",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58718",
    city: "Berthold",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58721",
    city: "Bowbells",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58722",
    city: "Burlington",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58723",
    city: "Butte",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58725",
    city: "Carpio",
    state: "ND",
    areacode: 701,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58727",
    city: "Columbus",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58730",
    city: "Crosby",
    state: "ND",
    areacode: 701,
    county: "Divide",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58731",
    city: "Deering",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58733",
    city: "Des Lacs",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58734",
    city: "Donnybrook",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58735",
    city: "Douglas",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58736",
    city: "Drake",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58737",
    city: "Flaxton",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58740",
    city: "Glenburn",
    state: "ND",
    areacode: 701,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58741",
    city: "Granville",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58744",
    city: "Karlsruhe",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58746",
    city: "Kenmare",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58748",
    city: "Kramer",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58750",
    city: "Lansford",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58752",
    city: "Lignite",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58755",
    city: "McGregor",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58756",
    city: "Makoti",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58757",
    city: "Mandaree",
    state: "ND",
    areacode: 701,
    county: "Dunn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58758",
    city: "Martin",
    state: "ND",
    areacode: 701,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58759",
    city: "Max",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58760",
    city: "Maxbass",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58761",
    city: "Mohall",
    state: "ND",
    areacode: 701,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58762",
    city: "Newburg",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58763",
    city: "New Town",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58765",
    city: "Noonan",
    state: "ND",
    areacode: 701,
    county: "Divide",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58768",
    city: "Norwich",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58769",
    city: "Palermo",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58770",
    city: "Parshall",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58771",
    city: "Plaza",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58772",
    city: "Portal",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58773",
    city: "Powers Lake",
    state: "ND",
    areacode: 701,
    county: "Burke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58775",
    city: "Roseglen",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58776",
    city: "Ross",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58778",
    city: "Ruso",
    state: "ND",
    areacode: 701,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58779",
    city: "Ryder",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58781",
    city: "Sawyer",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58782",
    city: "Sherwood",
    state: "ND",
    areacode: 701,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58783",
    city: "Souris",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58784",
    city: "Stanley",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58785",
    city: "Surrey",
    state: "ND",
    areacode: 701,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58787",
    city: "Tolley",
    state: "ND",
    areacode: 701,
    county: "Renville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58788",
    city: "Towner",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58789",
    city: "Upham",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58790",
    city: "Velva",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58792",
    city: "Voltaire",
    state: "ND",
    areacode: 701,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58793",
    city: "Westhope",
    state: "ND",
    areacode: 701,
    county: "Bottineau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58794",
    city: "White Earth",
    state: "ND",
    areacode: 701,
    county: "Mountrail",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58795",
    city: "Wildrose",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58801",
    city: "Williston",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58802",
    city: "Williston",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58803",
    city: "Williston",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58830",
    city: "Alamo",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58831",
    city: "Alexander",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58833",
    city: "Ambrose",
    state: "ND",
    areacode: 701,
    county: "Divide",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58835",
    city: "Arnegard",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58838",
    city: "Cartwright",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58843",
    city: "Epping",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58844",
    city: "Fortuna",
    state: "ND",
    areacode: 701,
    county: "Divide",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58845",
    city: "Grenora",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58847",
    city: "Keene",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58849",
    city: "Ray",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58852",
    city: "Tioga",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58853",
    city: "Trenton",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58854",
    city: "Watford City",
    state: "ND",
    areacode: 701,
    county: "McKenzie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "58856",
    city: "Zahl",
    state: "ND",
    areacode: 701,
    county: "Williams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "59001",
    city: "Absarokee",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59002",
    city: "Acton",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59003",
    city: "Ashland",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59004",
    city: "Ashland",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59006",
    city: "Ballantine",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59007",
    city: "Bearcreek",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59008",
    city: "Belfry",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59010",
    city: "Bighorn",
    state: "MT",
    areacode: 406,
    county: "Treasure",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59011",
    city: "Big Timber",
    state: "MT",
    areacode: 406,
    county: "Sweet Grass",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59012",
    city: "Birney",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59013",
    city: "Boyd",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59014",
    city: "Bridger",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59015",
    city: "Broadview",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59016",
    city: "Busby",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59018",
    city: "Clyde Park",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59019",
    city: "Columbus",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59020",
    city: "Cooke City",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59022",
    city: "Crow Agency",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59024",
    city: "Custer",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59025",
    city: "Decker",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59026",
    city: "Edgar",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59027",
    city: "Emigrant",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59028",
    city: "Fishtail",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59029",
    city: "Fromberg",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59030",
    city: "Gardiner",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59031",
    city: "Garryowen",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59032",
    city: "Grass Range",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59033",
    city: "Greycliff",
    state: "MT",
    areacode: 406,
    county: "Sweet Grass",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59034",
    city: "Hardin",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59035",
    city: "Fort Smith",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59036",
    city: "Harlowton",
    state: "MT",
    areacode: 406,
    county: "Wheatland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59037",
    city: "Huntley",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59038",
    city: "Hysham",
    state: "MT",
    areacode: 406,
    county: "Treasure",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59039",
    city: "Ingomar",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59041",
    city: "Joliet",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59043",
    city: "Lame Deer",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59044",
    city: "Laurel",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59046",
    city: "Lavina",
    state: "MT",
    areacode: 406,
    county: "Golden Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59047",
    city: "Livingston",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59050",
    city: "Lodge Grass",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59052",
    city: "McLeod",
    state: "MT",
    areacode: 406,
    county: "Sweet Grass",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59053",
    city: "Martinsdale",
    state: "MT",
    areacode: 406,
    county: "Meagher",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59054",
    city: "Melstone",
    state: "MT",
    areacode: 406,
    county: "Musselshell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59055",
    city: "Melville",
    state: "MT",
    areacode: 406,
    county: "Sweet Grass",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59057",
    city: "Molt",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59058",
    city: "Mosby",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59059",
    city: "Musselshell",
    state: "MT",
    areacode: 406,
    county: "Musselshell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59061",
    city: "Nye",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59062",
    city: "Otter",
    state: "MT",
    areacode: 406,
    county: "Powder River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59063",
    city: "Park City",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59064",
    city: "Pompeys Pillar",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59065",
    city: "Pray",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59066",
    city: "Pryor",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59067",
    city: "Rapelje",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59068",
    city: "Red Lodge",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59069",
    city: "Reed Point",
    state: "MT",
    areacode: 406,
    county: "Stillwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59070",
    city: "Roberts",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59071",
    city: "Roscoe",
    state: "MT",
    areacode: 406,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59072",
    city: "Roundup",
    state: "MT",
    areacode: 406,
    county: "Musselshell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59073",
    city: "Roundup",
    state: "MT",
    areacode: 406,
    county: "Musselshell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59074",
    city: "Ryegate",
    state: "MT",
    areacode: 406,
    county: "Golden Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59075",
    city: "Saint Xavier",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59076",
    city: "Sanders",
    state: "MT",
    areacode: 406,
    county: "Treasure",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59077",
    city: "Sand Springs",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59078",
    city: "Shawmut",
    state: "MT",
    areacode: 406,
    county: "Wheatland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59079",
    city: "Shepherd",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59081",
    city: "Silver Gate",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59082",
    city: "Springdale",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59083",
    city: "Sumatra",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59084",
    city: "Teigen",
    state: "MT",
    areacode: 406,
    county: "Petroleum",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59085",
    city: "Two Dot",
    state: "MT",
    areacode: 406,
    county: "Wheatland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59086",
    city: "Wilsall",
    state: "MT",
    areacode: 406,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59087",
    city: "Winnett",
    state: "MT",
    areacode: 406,
    county: "Petroleum",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59088",
    city: "Worden",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59089",
    city: "Wyola",
    state: "MT",
    areacode: 406,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59101",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59102",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59103",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59104",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59105",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59106",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59107",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59108",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59111",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59112",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59114",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59115",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59116",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59117",
    city: "Billings",
    state: "MT",
    areacode: 406,
    county: "Yellowstone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59201",
    city: "Wolf Point",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59211",
    city: "Antelope",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59212",
    city: "Bainville",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59213",
    city: "Brockton",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59214",
    city: "Brockway",
    state: "MT",
    areacode: 406,
    county: "McCone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59215",
    city: "Circle",
    state: "MT",
    areacode: 406,
    county: "McCone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59217",
    city: "Crane",
    state: "MT",
    areacode: 406,
    county: "Richland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59218",
    city: "Culbertson",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59219",
    city: "Dagmar",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59221",
    city: "Fairview",
    state: "MT",
    areacode: 406,
    county: "Richland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59222",
    city: "Flaxville",
    state: "MT",
    areacode: 406,
    county: "Daniels",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59223",
    city: "Fort Peck",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59225",
    city: "Frazer",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59226",
    city: "Froid",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59230",
    city: "Glasgow",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59231",
    city: "Saint Marie",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59240",
    city: "Glentana",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59241",
    city: "Hinsdale",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59242",
    city: "Homestead",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59243",
    city: "Lambert",
    state: "MT",
    areacode: 406,
    county: "Richland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59244",
    city: "Larslan",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59247",
    city: "Medicine Lake",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59248",
    city: "Nashua",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59250",
    city: "Opheim",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59252",
    city: "Outlook",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59253",
    city: "Peerless",
    state: "MT",
    areacode: 406,
    county: "Daniels",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59254",
    city: "Plentywood",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59255",
    city: "Poplar",
    state: "MT",
    areacode: 406,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59256",
    city: "Raymond",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59257",
    city: "Redstone",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59258",
    city: "Reserve",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59259",
    city: "Richey",
    state: "MT",
    areacode: 406,
    county: "Dawson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59260",
    city: "Richland",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59261",
    city: "Saco",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59262",
    city: "Savage",
    state: "MT",
    areacode: 406,
    county: "Richland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59263",
    city: "Scobey",
    state: "MT",
    areacode: 406,
    county: "Daniels",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59270",
    city: "Sidney",
    state: "MT",
    areacode: 406,
    county: "Richland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59273",
    city: "Vandalia",
    state: "MT",
    areacode: 406,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59274",
    city: "Vida",
    state: "MT",
    areacode: 406,
    county: "McCone",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59275",
    city: "Westby",
    state: "MT",
    areacode: 406,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59276",
    city: "Whitetail",
    state: "MT",
    areacode: 406,
    county: "Daniels",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59301",
    city: "Miles City",
    state: "MT",
    areacode: 406,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59311",
    city: "Alzada",
    state: "MT",
    areacode: 406,
    county: "Carter",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59312",
    city: "Angela",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59313",
    city: "Baker",
    state: "MT",
    areacode: 406,
    county: "Fallon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59314",
    city: "Biddle",
    state: "MT",
    areacode: 406,
    county: "Powder River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59315",
    city: "Bloomfield",
    state: "MT",
    areacode: 406,
    county: "Dawson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59316",
    city: "Boyes",
    state: "MT",
    areacode: 406,
    county: "Carter",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59317",
    city: "Broadus",
    state: "MT",
    areacode: 406,
    county: "Powder River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59318",
    city: "Brusett",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59319",
    city: "Capitol",
    state: "MT",
    areacode: 406,
    county: "Carter",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59322",
    city: "Cohagen",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59323",
    city: "Colstrip",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59324",
    city: "Ekalaka",
    state: "MT",
    areacode: 406,
    county: "Carter",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59326",
    city: "Fallon",
    state: "MT",
    areacode: 406,
    county: "Prairie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59327",
    city: "Forsyth",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59330",
    city: "Glendive",
    state: "MT",
    areacode: 406,
    county: "Dawson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59332",
    city: "Hammond",
    state: "MT",
    areacode: 406,
    county: "Carter",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59333",
    city: "Hathaway",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59336",
    city: "Ismay",
    state: "MT",
    areacode: 406,
    county: "Fallon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59337",
    city: "Jordan",
    state: "MT",
    areacode: 406,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59338",
    city: "Kinsey",
    state: "MT",
    areacode: 406,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59339",
    city: "Lindsay",
    state: "MT",
    areacode: 406,
    county: "Dawson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59341",
    city: "Mildred",
    state: "MT",
    areacode: 406,
    county: "Prairie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59343",
    city: "Olive",
    state: "MT",
    areacode: 406,
    county: "Powder River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59344",
    city: "Plevna",
    state: "MT",
    areacode: 406,
    county: "Fallon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59345",
    city: "Powderville",
    state: "MT",
    areacode: 406,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59347",
    city: "Rosebud",
    state: "MT",
    areacode: 406,
    county: "Rosebud",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59349",
    city: "Terry",
    state: "MT",
    areacode: 406,
    county: "Prairie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59351",
    city: "Volborg",
    state: "MT",
    areacode: 406,
    county: "Powder River",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59353",
    city: "Wibaux",
    state: "MT",
    areacode: 406,
    county: "Wibaux",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59354",
    city: "Willard",
    state: "MT",
    areacode: 406,
    county: "Fallon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59401",
    city: "Great Falls",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59402",
    city: "Malmstrom AFB",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59403",
    city: "Great Falls",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59404",
    city: "Great Falls",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59405",
    city: "Great Falls",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59406",
    city: "Great Falls",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59410",
    city: "Augusta",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59411",
    city: "Babb",
    state: "MT",
    areacode: 406,
    county: "Glacier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59412",
    city: "Belt",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59414",
    city: "Black Eagle",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59416",
    city: "Brady",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59417",
    city: "Browning",
    state: "MT",
    areacode: 406,
    county: "Glacier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59418",
    city: "Buffalo",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59419",
    city: "Bynum",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59420",
    city: "Carter",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59421",
    city: "Cascade",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59422",
    city: "Choteau",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59424",
    city: "Coffee Creek",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59425",
    city: "Conrad",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59427",
    city: "Cut Bank",
    state: "MT",
    areacode: 406,
    county: "Glacier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59430",
    city: "Denton",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59432",
    city: "Dupuyer",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59433",
    city: "Dutton",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59434",
    city: "East Glacier Park",
    state: "MT",
    areacode: 406,
    county: "Glacier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59435",
    city: "Ethridge",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59436",
    city: "Fairfield",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59440",
    city: "Floweree",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59441",
    city: "Forestgrove",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59442",
    city: "Fort Benton",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59443",
    city: "Fort Shaw",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59444",
    city: "Galata",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59446",
    city: "Geraldine",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59447",
    city: "Geyser",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59448",
    city: "Heart Butte",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59450",
    city: "Highwood",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59451",
    city: "Hilger",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59452",
    city: "Hobson",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59453",
    city: "Judith Gap",
    state: "MT",
    areacode: 406,
    county: "Wheatland",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59454",
    city: "Kevin",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59456",
    city: "Ledger",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59457",
    city: "Lewistown",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59460",
    city: "Loma",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59461",
    city: "Lothair",
    state: "MT",
    areacode: 406,
    county: "Liberty",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59462",
    city: "Moccasin",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59463",
    city: "Monarch",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59464",
    city: "Moore",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59465",
    city: "Neihart",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59466",
    city: "Oilmont",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59467",
    city: "Pendroy",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59468",
    city: "Power",
    state: "MT",
    areacode: 406,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59469",
    city: "Raynesford",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59471",
    city: "Roy",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59472",
    city: "Sand Coulee",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59474",
    city: "Shelby",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59477",
    city: "Simms",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59479",
    city: "Stanford",
    state: "MT",
    areacode: 406,
    county: "Judith Basin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59480",
    city: "Stockett",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59482",
    city: "Sunburst",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59483",
    city: "Sun River",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59484",
    city: "Sweet Grass",
    state: "MT",
    areacode: 406,
    county: "Toole",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59485",
    city: "Ulm",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59486",
    city: "Valier",
    state: "MT",
    areacode: 406,
    county: "Pondera",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59487",
    city: "Vaughn",
    state: "MT",
    areacode: 406,
    county: "Cascade",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59489",
    city: "Winifred",
    state: "MT",
    areacode: 406,
    county: "Fergus",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59501",
    city: "Havre",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59520",
    city: "Big Sandy",
    state: "MT",
    areacode: 406,
    county: "Chouteau",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59521",
    city: "Box Elder",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59522",
    city: "Chester",
    state: "MT",
    areacode: 406,
    county: "Liberty",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59523",
    city: "Chinook",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59524",
    city: "Dodson",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59525",
    city: "Gildford",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59526",
    city: "Harlem",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59527",
    city: "Hays",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59528",
    city: "Hingham",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59529",
    city: "Hogeland",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59530",
    city: "Inverness",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59531",
    city: "Joplin",
    state: "MT",
    areacode: 406,
    county: "Liberty",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59532",
    city: "Kremlin",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59535",
    city: "Lloyd",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59537",
    city: "Loring",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59538",
    city: "Malta",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59540",
    city: "Rudyard",
    state: "MT",
    areacode: 406,
    county: "Hill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59542",
    city: "Turner",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59544",
    city: "Whitewater",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59545",
    city: "Whitlash",
    state: "MT",
    areacode: 406,
    county: "Liberty",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59546",
    city: "Zortman",
    state: "MT",
    areacode: 406,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59547",
    city: "Zurich",
    state: "MT",
    areacode: 406,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59601",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59602",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59604",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59620",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59623",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59624",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59625",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59626",
    city: "Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59631",
    city: "Basin",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59632",
    city: "Boulder",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59633",
    city: "Canyon Creek",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59634",
    city: "Clancy",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59635",
    city: "East Helena",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59636",
    city: "Fort Harrison",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59638",
    city: "Jefferson City",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59639",
    city: "Lincoln",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59640",
    city: "Marysville",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59641",
    city: "Radersburg",
    state: "MT",
    areacode: 406,
    county: "Broadwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59642",
    city: "Ringling",
    state: "MT",
    areacode: 406,
    county: "Meagher",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59643",
    city: "Toston",
    state: "MT",
    areacode: 406,
    county: "Broadwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59644",
    city: "Townsend",
    state: "MT",
    areacode: 406,
    county: "Broadwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59645",
    city: "White Sulphur Springs",
    state: "MT",
    areacode: 406,
    county: "Meagher",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59647",
    city: "Winston",
    state: "MT",
    areacode: 406,
    county: "Broadwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59648",
    city: "Wolf Creek",
    state: "MT",
    areacode: 406,
    county: "Lewis and Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59701",
    city: "Butte",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59702",
    city: "Butte",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59703",
    city: "Butte",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59707",
    city: "Butte",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59710",
    city: "Alder",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59711",
    city: "Anaconda",
    state: "MT",
    areacode: 406,
    county: "Deer Lodge",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59713",
    city: "Avon",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59714",
    city: "Belgrade",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59715",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59716",
    city: "Big Sky",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59717",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59718",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59719",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59720",
    city: "Cameron",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59721",
    city: "Cardwell",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59722",
    city: "Deer Lodge",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59724",
    city: "Dell",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59725",
    city: "Dillon",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59727",
    city: "Divide",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59728",
    city: "Elliston",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59729",
    city: "Ennis",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59730",
    city: "Gallatin Gateway",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59731",
    city: "Garrison",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59732",
    city: "Glen",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59733",
    city: "Gold Creek",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59735",
    city: "Harrison",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59736",
    city: "Jackson",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59739",
    city: "Lima",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59740",
    city: "McAllister",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59741",
    city: "Manhattan",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59743",
    city: "Melrose",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59745",
    city: "Norris",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59746",
    city: "Polaris",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59747",
    city: "Pony",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59748",
    city: "Ramsay",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59749",
    city: "Sheridan",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59750",
    city: "Butte",
    state: "MT",
    areacode: 406,
    county: "Silver Bow",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59751",
    city: "Silver Star",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59752",
    city: "Three Forks",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59754",
    city: "Twin Bridges",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59755",
    city: "Virginia City",
    state: "MT",
    areacode: 406,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59756",
    city: "Warm Springs",
    state: "MT",
    areacode: 406,
    county: "Deer Lodge",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59758",
    city: "West Yellowstone",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59759",
    city: "Whitehall",
    state: "MT",
    areacode: 406,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59760",
    city: "Willow Creek",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59761",
    city: "Wisdom",
    state: "MT",
    areacode: 406,
    county: "Beaverhead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59762",
    city: "Wise River",
    state: "MT",
    areacode: 406,
    county: "Deer Lodge",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59771",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59772",
    city: "Bozeman",
    state: "MT",
    areacode: 406,
    county: "Gallatin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59801",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59802",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59803",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59804",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59806",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59807",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59808",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59812",
    city: "Missoula",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59820",
    city: "Alberton",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59821",
    city: "Arlee",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59823",
    city: "Bonner",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59824",
    city: "Charlo",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59825",
    city: "Clinton",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59826",
    city: "Condon",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59827",
    city: "Conner",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59828",
    city: "Corvallis",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59829",
    city: "Darby",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59830",
    city: "De Borgia",
    state: "MT",
    areacode: 406,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59831",
    city: "Dixon",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59832",
    city: "Drummond",
    state: "MT",
    areacode: 406,
    county: "Granite",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59833",
    city: "Florence",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59834",
    city: "Frenchtown",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59835",
    city: "Grantsdale",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59837",
    city: "Hall",
    state: "MT",
    areacode: 406,
    county: "Granite",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59840",
    city: "Hamilton",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59841",
    city: "Pinesdale",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59842",
    city: "Haugan",
    state: "MT",
    areacode: 406,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59843",
    city: "Helmville",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59844",
    city: "Heron",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59845",
    city: "Hot Springs",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59846",
    city: "Huson",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59847",
    city: "Lolo",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59848",
    city: "Lonepine",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59851",
    city: "Milltown",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59853",
    city: "Noxon",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59854",
    city: "Ovando",
    state: "MT",
    areacode: 406,
    county: "Powell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59855",
    city: "Pablo",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59856",
    city: "Paradise",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59858",
    city: "Philipsburg",
    state: "MT",
    areacode: 406,
    county: "Granite",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59859",
    city: "Plains",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59860",
    city: "Polson",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59863",
    city: "Ravalli",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59864",
    city: "Ronan",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59865",
    city: "Saint Ignatius",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59866",
    city: "Saint Regis",
    state: "MT",
    areacode: 406,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59867",
    city: "Saltese",
    state: "MT",
    areacode: 406,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59868",
    city: "Seeley Lake",
    state: "MT",
    areacode: 406,
    county: "Missoula",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59870",
    city: "Stevensville",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59871",
    city: "Sula",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59872",
    city: "Superior",
    state: "MT",
    areacode: 406,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59873",
    city: "Thompson Falls",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59874",
    city: "Trout Creek",
    state: "MT",
    areacode: 406,
    county: "Sanders",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59875",
    city: "Victor",
    state: "MT",
    areacode: 406,
    county: "Ravalli",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59901",
    city: "Kalispell",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59903",
    city: "Kalispell",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59904",
    city: "Kalispell",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59910",
    city: "Big Arm",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59911",
    city: "Bigfork",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59912",
    city: "Columbia Falls",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59913",
    city: "Coram",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59914",
    city: "Dayton",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59915",
    city: "Elmo",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59916",
    city: "Essex",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59917",
    city: "Eureka",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59918",
    city: "Fortine",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59919",
    city: "Hungry Horse",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59920",
    city: "Kila",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59921",
    city: "Lake McDonald",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59922",
    city: "Lakeside",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59923",
    city: "Libby",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59925",
    city: "Marion",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59926",
    city: "Martin City",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59927",
    city: "Olney",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59928",
    city: "Polebridge",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59929",
    city: "Proctor",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59930",
    city: "Rexford",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59931",
    city: "Rollins",
    state: "MT",
    areacode: 406,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59932",
    city: "Somers",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59933",
    city: "Stryker",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59934",
    city: "Trego",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59935",
    city: "Troy",
    state: "MT",
    areacode: 406,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59936",
    city: "West Glacier",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "59937",
    city: "Whitefish",
    state: "MT",
    areacode: 406,
    county: "Flathead",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "60002",
    city: "Antioch",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60004",
    city: "Arlington Heights",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60005",
    city: "Arlington Heights",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60006",
    city: "Arlington Heights",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60007",
    city: "Elk Grove Village",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60008",
    city: "Rolling Meadows",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60009",
    city: "Elk Grove Village",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60010",
    city: "Barrington",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60011",
    city: "Barrington",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60012",
    city: "Crystal Lake",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60013",
    city: "Cary",
    state: "IL",
    areacode: 847,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60014",
    city: "Crystal Lake",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60015",
    city: "Deerfield",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60016",
    city: "Des Plaines",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60017",
    city: "Des Plaines",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60018",
    city: "Des Plaines",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60019",
    city: "Des Plaines",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60020",
    city: "Fox Lake",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60021",
    city: "Fox River Grove",
    state: "IL",
    areacode: 847,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60022",
    city: "Glencoe",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60025",
    city: "Glenview",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60026",
    city: "Glenview",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60029",
    city: "Golf",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60030",
    city: "Grayslake",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60031",
    city: "Gurnee",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60033",
    city: "Harvard",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60034",
    city: "Hebron",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60035",
    city: "Highland Park",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60037",
    city: "Fort Sheridan",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60038",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60039",
    city: "Crystal Lake",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60040",
    city: "Highwood",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60041",
    city: "Ingleside",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60042",
    city: "Island Lake",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60043",
    city: "Kenilworth",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60044",
    city: "Lake Bluff",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60045",
    city: "Lake Forest",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60046",
    city: "Lake Villa",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60047",
    city: "Lake Zurich",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60048",
    city: "Libertyville",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60050",
    city: "McHenry",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60051",
    city: "McHenry",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60053",
    city: "Morton Grove",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60055",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60056",
    city: "Mount Prospect",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60060",
    city: "Mundelein",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60061",
    city: "Vernon Hills",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60062",
    city: "Northbrook",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60064",
    city: "North Chicago",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60065",
    city: "Northbrook",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60067",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60068",
    city: "Park Ridge",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60069",
    city: "Lincolnshire",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60070",
    city: "Prospect Heights",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60071",
    city: "Richmond",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60072",
    city: "Ringwood",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60073",
    city: "Round Lake",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60074",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60075",
    city: "Russell",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60076",
    city: "Skokie",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60077",
    city: "Skokie",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60078",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60079",
    city: "Waukegan",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60081",
    city: "Spring Grove",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60082",
    city: "Techny",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60083",
    city: "Wadsworth",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60084",
    city: "Wauconda",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60085",
    city: "Waukegan",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60086",
    city: "North Chicago",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60087",
    city: "Waukegan",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60088",
    city: "Great Lakes",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60089",
    city: "Buffalo Grove",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60090",
    city: "Wheeling",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60091",
    city: "Wilmette",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60093",
    city: "Winnetka",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60094",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60095",
    city: "Palatine",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60096",
    city: "Winthrop Harbor",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60097",
    city: "Wonder Lake",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60098",
    city: "Woodstock",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60099",
    city: "Zion",
    state: "IL",
    areacode: 847,
    county: "Lake",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60101",
    city: "Addison",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60102",
    city: "Algonquin",
    state: "IL",
    areacode: 847,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60103",
    city: "Bartlett",
    state: "IL",
    areacode: 630,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60104",
    city: "Bellwood",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60105",
    city: "Bensenville",
    state: "IL",
    areacode: 708,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60106",
    city: "Bensenville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60107",
    city: "Streamwood",
    state: "IL",
    areacode: 630,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60108",
    city: "Bloomingdale",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60109",
    city: "Burlington",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60110",
    city: "Carpentersville",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60111",
    city: "Clare",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60112",
    city: "Cortland",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60113",
    city: "Creston",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60115",
    city: "DeKalb",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60116",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60117",
    city: "Bloomingdale",
    state: "IL",
    areacode: 708,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60118",
    city: "Dundee",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60119",
    city: "Elburn",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60120",
    city: "Elgin",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60121",
    city: "Elgin",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60122",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60123",
    city: "Elgin",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60124",
    city: "Elgin",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60126",
    city: "Elmhurst",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60128",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60129",
    city: "Esmond",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60130",
    city: "Forest Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60131",
    city: "Franklin Park",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60132",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60133",
    city: "Hanover Park",
    state: "IL",
    areacode: 630,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60134",
    city: "Geneva",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60135",
    city: "Genoa",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60136",
    city: "Gilberts",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60137",
    city: "Glen Ellyn",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60138",
    city: "Glen Ellyn",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60139",
    city: "Glendale Heights",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60140",
    city: "Hampshire",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60141",
    city: "Hines",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60142",
    city: "Huntley",
    state: "IL",
    areacode: 847,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60143",
    city: "Itasca",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60144",
    city: "Kaneville",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60145",
    city: "Kingston",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60146",
    city: "Kirkland",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60147",
    city: "Lafox",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60148",
    city: "Lombard",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60150",
    city: "Malta",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60151",
    city: "Maple Park",
    state: "IL",
    areacode: 815,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60152",
    city: "Marengo",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60153",
    city: "Maywood",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60154",
    city: "Westchester",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60155",
    city: "Broadview",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60156",
    city: "Lake in the Hills",
    state: "IL",
    areacode: 847,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60157",
    city: "Medinah",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60159",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60160",
    city: "Melrose Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60161",
    city: "Melrose Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60162",
    city: "Hillside",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60163",
    city: "Berkeley",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60164",
    city: "Melrose Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60165",
    city: "Stone Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60168",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60169",
    city: "Hoffman Estates",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60171",
    city: "River Grove",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60172",
    city: "Roselle",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60173",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60174",
    city: "Saint Charles",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60175",
    city: "Saint Charles",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60176",
    city: "Schiller Park",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60177",
    city: "South Elgin",
    state: "IL",
    areacode: 847,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60178",
    city: "Sycamore",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60179",
    city: "Hoffman Estates",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60180",
    city: "Union",
    state: "IL",
    areacode: 815,
    county: "McHenry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60181",
    city: "Villa Park",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60183",
    city: "Wasco",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60184",
    city: "Wayne",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60185",
    city: "West Chicago",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60186",
    city: "West Chicago",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60187",
    city: "Wheaton",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60188",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60189",
    city: "Wheaton",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60190",
    city: "Winfield",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60191",
    city: "Wood Dale",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60192",
    city: "Hoffman Estates",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60193",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60194",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60195",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60196",
    city: "Schaumburg",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60197",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60199",
    city: "Carol Stream",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60201",
    city: "Evanston",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60202",
    city: "Evanston",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60203",
    city: "Evanston",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60204",
    city: "Evanston",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60208",
    city: "Evanston",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60301",
    city: "Oak Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60302",
    city: "Oak Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60303",
    city: "Oak Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60304",
    city: "Oak Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60305",
    city: "River Forest",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60399",
    city: "Wood Dale",
    state: "IL",
    areacode: 708,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60401",
    city: "Beecher",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60402",
    city: "Berwyn",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60403",
    city: "Crest Hill",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60404",
    city: "Shorewood",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60406",
    city: "Blue Island",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60407",
    city: "Braceville",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60408",
    city: "Braidwood",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60409",
    city: "Calumet City",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60410",
    city: "Channahon",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60411",
    city: "Chicago Heights",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60412",
    city: "Chicago Heights",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60415",
    city: "Chicago Ridge",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60416",
    city: "Coal City",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60417",
    city: "Crete",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60418",
    city: "Crestwood",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60419",
    city: "Dolton",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60420",
    city: "Dwight",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60421",
    city: "Elwood",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60422",
    city: "Flossmoor",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60423",
    city: "Frankfort",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60424",
    city: "Gardner",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60425",
    city: "Glenwood",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60426",
    city: "Harvey",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60428",
    city: "Markham",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60429",
    city: "Hazel Crest",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60430",
    city: "Homewood",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60431",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60432",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60433",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60434",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60435",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60436",
    city: "Joliet",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60437",
    city: "Kinsman",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60438",
    city: "Lansing",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60439",
    city: "Lemont",
    state: "IL",
    areacode: 630,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60440",
    city: "Bolingbrook",
    state: "IL",
    areacode: 630,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60441",
    city: "Lockport",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60442",
    city: "Manhattan",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60443",
    city: "Matteson",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60444",
    city: "Mazon",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60445",
    city: "Midlothian",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60446",
    city: "Romeoville",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60447",
    city: "Minooka",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60448",
    city: "Mokena",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60449",
    city: "Monee",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60450",
    city: "Morris",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60451",
    city: "New Lenox",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60452",
    city: "Oak Forest",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60453",
    city: "Oak Lawn",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60454",
    city: "Oak Lawn",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60455",
    city: "Bridgeview",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60456",
    city: "Hometown",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60457",
    city: "Hickory Hills",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60458",
    city: "Justice",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60459",
    city: "Burbank",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60460",
    city: "Odell",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60461",
    city: "Olympia Fields",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60462",
    city: "Orland Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60463",
    city: "Palos Heights",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60464",
    city: "Palos Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60465",
    city: "Palos Hills",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60466",
    city: "Park Forest",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60467",
    city: "Orland Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60468",
    city: "Peotone",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60469",
    city: "Posen",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60470",
    city: "Ransom",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60471",
    city: "Richton Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60472",
    city: "Robbins",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60473",
    city: "South Holland",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60474",
    city: "South Wilmington",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60475",
    city: "Steger",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60476",
    city: "Thornton",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60477",
    city: "Tinley Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60478",
    city: "Country Club Hills",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60479",
    city: "Verona",
    state: "IL",
    areacode: 815,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60480",
    city: "Willow Springs",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60481",
    city: "Wilmington",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60482",
    city: "Worth",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60484",
    city: "University Park",
    state: "IL",
    areacode: 708,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60487",
    city: "Tinley Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60490",
    city: "Bolingbrook",
    state: "IL",
    areacode: 630,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60491",
    city: "Homer Glen",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60499",
    city: "Bedford Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60501",
    city: "Summit Argo",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60502",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60503",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60504",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60505",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60506",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60507",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60510",
    city: "Batavia",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60511",
    city: "Big Rock",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60512",
    city: "Bristol",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60513",
    city: "Brookfield",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60514",
    city: "Clarendon Hills",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60515",
    city: "Downers Grove",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60516",
    city: "Downers Grove",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60517",
    city: "Woodridge",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60518",
    city: "Earlville",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60519",
    city: "Eola",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60520",
    city: "Hinckley",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60521",
    city: "Hinsdale",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60522",
    city: "Hinsdale",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60523",
    city: "Oak Brook",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60525",
    city: "La Grange",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60526",
    city: "La Grange Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60527",
    city: "Willowbrook",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60530",
    city: "Lee",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60531",
    city: "Leland",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60532",
    city: "Lisle",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60534",
    city: "Lyons",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60536",
    city: "Millbrook",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60537",
    city: "Millington",
    state: "IL",
    areacode: 815,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60538",
    city: "Montgomery",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60539",
    city: "Mooseheart",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60540",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60541",
    city: "Newark",
    state: "IL",
    areacode: 815,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60542",
    city: "North Aurora",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60543",
    city: "Oswego",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60544",
    city: "Plainfield",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60545",
    city: "Plano",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60546",
    city: "Riverside",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60548",
    city: "Sandwich",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60549",
    city: "Serena",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60550",
    city: "Shabbona",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60551",
    city: "Sheridan",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60552",
    city: "Somonauk",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60553",
    city: "Steward",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60554",
    city: "Sugar Grove",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60555",
    city: "Warrenville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60556",
    city: "Waterman",
    state: "IL",
    areacode: 815,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60557",
    city: "Wedron",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60558",
    city: "Western Springs",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60559",
    city: "Westmont",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60560",
    city: "Yorkville",
    state: "IL",
    areacode: 630,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60561",
    city: "Darien",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60563",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60564",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60565",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60566",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60567",
    city: "Naperville",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60568",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "Kane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60569",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60572",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60585",
    city: "Plainfield",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60586",
    city: "Plainfield",
    state: "IL",
    areacode: 815,
    county: "Will",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60598",
    city: "Aurora",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60599",
    city: "Fox Valley",
    state: "IL",
    areacode: 630,
    county: "DuPage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60601",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60602",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60603",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60604",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60605",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60606",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60607",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60608",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60609",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60610",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60611",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60612",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60613",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60614",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60615",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60616",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60617",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60618",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60619",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60620",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60621",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60622",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60623",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60624",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60625",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60626",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60628",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60629",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60630",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60631",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60632",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60633",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60634",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60636",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60637",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60638",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60639",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60640",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60641",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60642",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60643",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60644",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60645",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60646",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60647",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60649",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60651",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60652",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60653",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60654",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60655",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60656",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60657",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60659",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60660",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60661",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60664",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60666",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60668",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60669",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60670",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60673",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60674",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60675",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60677",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60678",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60680",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60681",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60682",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60684",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60685",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60686",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60687",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60688",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60689",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60690",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60691",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60693",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60694",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60695",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60696",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60697",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60699",
    city: "Chicago",
    state: "IL",
    areacode: 312,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60701",
    city: "Chicago",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60706",
    city: "Harwood Heights",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60707",
    city: "Elmwood Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60712",
    city: "Lincolnwood",
    state: "IL",
    areacode: 773,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60714",
    city: "Niles",
    state: "IL",
    areacode: 847,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60803",
    city: "Alsip",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60804",
    city: "Cicero",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60805",
    city: "Evergreen Park",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60827",
    city: "Riverdale",
    state: "IL",
    areacode: 708,
    county: "Cook",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60901",
    city: "Kankakee",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60910",
    city: "Aroma Park",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60911",
    city: "Ashkum",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60912",
    city: "Beaverville",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60913",
    city: "Bonfield",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60914",
    city: "Bourbonnais",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60915",
    city: "Bradley",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60917",
    city: "Buckingham",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60918",
    city: "Buckley",
    state: "IL",
    areacode: 217,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60919",
    city: "Cabery",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60920",
    city: "Campus",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60921",
    city: "Chatsworth",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60922",
    city: "Chebanse",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60924",
    city: "Cissna Park",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60926",
    city: "Claytonville",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60927",
    city: "Clifton",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60928",
    city: "Crescent City",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60929",
    city: "Cullom",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60930",
    city: "Danforth",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60931",
    city: "Donovan",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60932",
    city: "East Lynn",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60933",
    city: "Elliott",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60934",
    city: "Emington",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60935",
    city: "Essex",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60936",
    city: "Gibson City",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60938",
    city: "Gilman",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60939",
    city: "Goodwine",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60940",
    city: "Grant Park",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60941",
    city: "Herscher",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60942",
    city: "Hoopeston",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60944",
    city: "Hopkins Park",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60945",
    city: "Iroquois",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60946",
    city: "Kempton",
    state: "IL",
    areacode: 815,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60948",
    city: "Loda",
    state: "IL",
    areacode: 217,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60949",
    city: "Ludlow",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60950",
    city: "Manteno",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60951",
    city: "Martinton",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60952",
    city: "Melvin",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60953",
    city: "Milford",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60954",
    city: "Momence",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60955",
    city: "Onarga",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60956",
    city: "Papineau",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60957",
    city: "Paxton",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60958",
    city: "Pembroke Township",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60959",
    city: "Piper City",
    state: "IL",
    areacode: 815,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60960",
    city: "Rankin",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60961",
    city: "Reddick",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60962",
    city: "Roberts",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60963",
    city: "Rossville",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60964",
    city: "Saint Anne",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60966",
    city: "Sheldon",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60967",
    city: "Stockland",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60968",
    city: "Thawville",
    state: "IL",
    areacode: 217,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60969",
    city: "Union Hill",
    state: "IL",
    areacode: 815,
    county: "Kankakee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60970",
    city: "Watseka",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60973",
    city: "Wellington",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "60974",
    city: "Woodland",
    state: "IL",
    areacode: 815,
    county: "Iroquois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61001",
    city: "Apple River",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61006",
    city: "Ashton",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61007",
    city: "Baileyville",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61008",
    city: "Belvidere",
    state: "IL",
    areacode: 815,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61010",
    city: "Byron",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61011",
    city: "Caledonia",
    state: "IL",
    areacode: 815,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61012",
    city: "Capron",
    state: "IL",
    areacode: 815,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61013",
    city: "Cedarville",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61014",
    city: "Chadwick",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61015",
    city: "Chana",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61016",
    city: "Cherry Valley",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61018",
    city: "Dakota",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61019",
    city: "Davis",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61020",
    city: "Davis Junction",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61021",
    city: "Dixon",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61024",
    city: "Durand",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61025",
    city: "East Dubuque",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61027",
    city: "Eleroy",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61028",
    city: "Elizabeth",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61030",
    city: "Forreston",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61031",
    city: "Franklin Grove",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61032",
    city: "Freeport",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61036",
    city: "Galena",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61037",
    city: "Galt",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61038",
    city: "Garden Prairie",
    state: "IL",
    areacode: 815,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61039",
    city: "German Valley",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61041",
    city: "Hanover",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61042",
    city: "Harmon",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61043",
    city: "Holcomb",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61044",
    city: "Kent",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61046",
    city: "Lanark",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61047",
    city: "Leaf River",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61048",
    city: "Lena",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61049",
    city: "Lindenwood",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61050",
    city: "McConnell",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61051",
    city: "Milledgeville",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61052",
    city: "Monroe Center",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61053",
    city: "Mount Carroll",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61054",
    city: "Mount Morris",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61057",
    city: "Nachusa",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61059",
    city: "Nora",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61060",
    city: "Orangeville",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61061",
    city: "Oregon",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61062",
    city: "Pearl City",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61063",
    city: "Pecatonica",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61064",
    city: "Polo",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61065",
    city: "Poplar Grove",
    state: "IL",
    areacode: 815,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61067",
    city: "Ridott",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61068",
    city: "Rochelle",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61070",
    city: "Rock City",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61071",
    city: "Rock Falls",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61072",
    city: "Rockton",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61073",
    city: "Roscoe",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61074",
    city: "Savanna",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61075",
    city: "Scales Mound",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61077",
    city: "Seward",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61078",
    city: "Shannon",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61079",
    city: "Shirland",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61080",
    city: "South Beloit",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61081",
    city: "Sterling",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61084",
    city: "Stillman Valley",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61085",
    city: "Stockton",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61087",
    city: "Warren",
    state: "IL",
    areacode: 815,
    county: "Jo Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61088",
    city: "Winnebago",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61089",
    city: "Winslow",
    state: "IL",
    areacode: 815,
    county: "Stephenson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61091",
    city: "Woosung",
    state: "IL",
    areacode: 815,
    county: "Ogle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61101",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61102",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61103",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61104",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61105",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61106",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61107",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61108",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61109",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61110",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61111",
    city: "Loves Park",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61112",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61114",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61115",
    city: "Machesney Park",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61125",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61126",
    city: "Rockford",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61130",
    city: "Loves Park",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61131",
    city: "Loves Park",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61132",
    city: "Loves Park",
    state: "IL",
    areacode: 815,
    county: "Winnebago",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61201",
    city: "Rock Island",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61204",
    city: "Rock Island",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61230",
    city: "Albany",
    state: "IL",
    areacode: 309,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61231",
    city: "Aledo",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61232",
    city: "Andalusia",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61233",
    city: "Andover",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61234",
    city: "Annawan",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61235",
    city: "Atkinson",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61236",
    city: "Barstow",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61237",
    city: "Buffalo Prairie",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61238",
    city: "Cambridge",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61239",
    city: "Carbon Cliff",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61240",
    city: "Coal Valley",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61241",
    city: "Colona",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61242",
    city: "Cordova",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61243",
    city: "Deer Grove",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61244",
    city: "East Moline",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61250",
    city: "Erie",
    state: "IL",
    areacode: 309,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61251",
    city: "Fenton",
    state: "IL",
    areacode: 309,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61252",
    city: "Fulton",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61254",
    city: "Geneseo",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61256",
    city: "Hampton",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61257",
    city: "Hillsdale",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61258",
    city: "Hooppole",
    state: "IL",
    areacode: 815,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61259",
    city: "Illinois City",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61260",
    city: "Joy",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61261",
    city: "Lyndon",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61262",
    city: "Lynn Center",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61263",
    city: "Matherville",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61264",
    city: "Milan",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61265",
    city: "Moline",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61266",
    city: "Moline",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61270",
    city: "Morrison",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61272",
    city: "New Boston",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61273",
    city: "Orion",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61274",
    city: "Osco",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61275",
    city: "Port Byron",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61276",
    city: "Preemption",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61277",
    city: "Prophetstown",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61278",
    city: "Rapids City",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61279",
    city: "Reynolds",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61281",
    city: "Sherrard",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61282",
    city: "Silvis",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61283",
    city: "Tampico",
    state: "IL",
    areacode: 815,
    county: "Whiteside",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61284",
    city: "Taylor Ridge",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61285",
    city: "Thomson",
    state: "IL",
    areacode: 815,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61299",
    city: "Rock Island",
    state: "IL",
    areacode: 309,
    county: "Rock Island",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61301",
    city: "La Salle",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61310",
    city: "Amboy",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61311",
    city: "Ancona",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61312",
    city: "Arlington",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61313",
    city: "Blackstone",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61314",
    city: "Buda",
    state: "IL",
    areacode: 309,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61315",
    city: "Bureau",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61316",
    city: "Cedar Point",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61317",
    city: "Cherry",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61318",
    city: "Compton",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61319",
    city: "Cornell",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61320",
    city: "Dalzell",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61321",
    city: "Dana",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61322",
    city: "Depue",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61323",
    city: "Dover",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61324",
    city: "Eldena",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61325",
    city: "Grand Ridge",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61326",
    city: "Granville",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61327",
    city: "Hennepin",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61328",
    city: "Kasbeer",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61329",
    city: "Ladd",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61330",
    city: "La Moille",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61331",
    city: "Lee Center",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61332",
    city: "Leonore",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61333",
    city: "Long Point",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61334",
    city: "Lostant",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61335",
    city: "McNabb",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61336",
    city: "Magnolia",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61337",
    city: "Malden",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61338",
    city: "Manlius",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61340",
    city: "Mark",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61341",
    city: "Marseilles",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61342",
    city: "Mendota",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61344",
    city: "Mineral",
    state: "IL",
    areacode: 309,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61345",
    city: "Neponset",
    state: "IL",
    areacode: 309,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61346",
    city: "New Bedford",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61348",
    city: "Oglesby",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61349",
    city: "Ohio",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61350",
    city: "Ottawa",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61353",
    city: "Paw Paw",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61354",
    city: "Peru",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61356",
    city: "Princeton",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61358",
    city: "Rutland",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61359",
    city: "Seatonville",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61360",
    city: "Seneca",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61361",
    city: "Sheffield",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61362",
    city: "Spring Valley",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61363",
    city: "Standard",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61364",
    city: "Streator",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61367",
    city: "Sublette",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61368",
    city: "Tiskilwa",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61369",
    city: "Toluca",
    state: "IL",
    areacode: 815,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61370",
    city: "Tonica",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61371",
    city: "Triumph",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61372",
    city: "Troy Grove",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61373",
    city: "Utica",
    state: "IL",
    areacode: 815,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61374",
    city: "Van Orin",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61375",
    city: "Varna",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61376",
    city: "Walnut",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61377",
    city: "Wenona",
    state: "IL",
    areacode: 815,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61378",
    city: "West Brooklyn",
    state: "IL",
    areacode: 815,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61379",
    city: "Wyanet",
    state: "IL",
    areacode: 815,
    county: "Bureau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61401",
    city: "Galesburg",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61402",
    city: "Galesburg",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61410",
    city: "Abingdon",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61411",
    city: "Adair",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61412",
    city: "Alexis",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61413",
    city: "Alpha",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61414",
    city: "Altona",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61415",
    city: "Avon",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61416",
    city: "Bardolph",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61417",
    city: "Berwick",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61418",
    city: "Biggsville",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61419",
    city: "Bishop Hill",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61420",
    city: "Blandinsville",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61421",
    city: "Bradford",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61422",
    city: "Bushnell",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61423",
    city: "Cameron",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61424",
    city: "Camp Grove",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61425",
    city: "Carman",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61426",
    city: "Castleton",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61427",
    city: "Cuba",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61428",
    city: "Dahinda",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61430",
    city: "East Galesburg",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61431",
    city: "Ellisville",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61432",
    city: "Fairview",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61433",
    city: "Fiatt",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61434",
    city: "Galva",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61435",
    city: "Gerlaw",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61436",
    city: "Gilson",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61437",
    city: "Gladstone",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61438",
    city: "Good Hope",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61439",
    city: "Henderson",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61440",
    city: "Industry",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61441",
    city: "Ipava",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61442",
    city: "Keithsburg",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61443",
    city: "Kewanee",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61447",
    city: "Kirkwood",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61448",
    city: "Knoxville",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61449",
    city: "La Fayette",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61450",
    city: "La Harpe",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61451",
    city: "Laura",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61452",
    city: "Littleton",
    state: "IL",
    areacode: 309,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61453",
    city: "Little York",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61454",
    city: "Lomax",
    state: "IL",
    areacode: 217,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61455",
    city: "Macomb",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61458",
    city: "Maquon",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61459",
    city: "Marietta",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61460",
    city: "Media",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61462",
    city: "Monmouth",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61465",
    city: "New Windsor",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61466",
    city: "North Henderson",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61467",
    city: "Oneida",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61468",
    city: "Ophiem",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61469",
    city: "Oquawka",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61470",
    city: "Prairie City",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61471",
    city: "Raritan",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61472",
    city: "Rio",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61473",
    city: "Roseville",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61474",
    city: "Saint Augustine",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61475",
    city: "Sciota",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61476",
    city: "Seaton",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61477",
    city: "Smithfield",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61478",
    city: "Smithshire",
    state: "IL",
    areacode: 309,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61479",
    city: "Speer",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61480",
    city: "Stronghurst",
    state: "IL",
    areacode: 309,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61482",
    city: "Table Grove",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61483",
    city: "Toulon",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61484",
    city: "Vermont",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61485",
    city: "Victoria",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61486",
    city: "Viola",
    state: "IL",
    areacode: 309,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61488",
    city: "Wataga",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61489",
    city: "Williamsfield",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61490",
    city: "Woodhull",
    state: "IL",
    areacode: 309,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61491",
    city: "Wyoming",
    state: "IL",
    areacode: 309,
    county: "Stark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61501",
    city: "Astoria",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61516",
    city: "Benson",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61517",
    city: "Brimfield",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61519",
    city: "Bryant",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61520",
    city: "Canton",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61523",
    city: "Chillicothe",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61524",
    city: "Dunfermline",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61525",
    city: "Dunlap",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61526",
    city: "Edelstein",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61528",
    city: "Edwards",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61529",
    city: "Elmwood",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61530",
    city: "Eureka",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61531",
    city: "Farmington",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61532",
    city: "Forest City",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61533",
    city: "Glasford",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61534",
    city: "Green Valley",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61535",
    city: "Groveland",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61536",
    city: "Hanna City",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61537",
    city: "Henry",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61539",
    city: "Kingston Mines",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61540",
    city: "Lacon",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61541",
    city: "La Rose",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61542",
    city: "Lewistown",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61543",
    city: "Liverpool",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61544",
    city: "London Mills",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61545",
    city: "Lowpoint",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61546",
    city: "Manito",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61547",
    city: "Mapleton",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61548",
    city: "Metamora",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61550",
    city: "Morton",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61552",
    city: "Mossville",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61553",
    city: "Norris",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61554",
    city: "Pekin",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61555",
    city: "Pekin",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61558",
    city: "Pekin",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61559",
    city: "Princeville",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61560",
    city: "Putnam",
    state: "IL",
    areacode: 815,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61561",
    city: "Roanoke",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61562",
    city: "Rome",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61563",
    city: "Saint David",
    state: "IL",
    areacode: 309,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61564",
    city: "South Pekin",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61565",
    city: "Sparland",
    state: "IL",
    areacode: 309,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61567",
    city: "Topeka",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61568",
    city: "Tremont",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61569",
    city: "Trivoli",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61570",
    city: "Washburn",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61571",
    city: "Washington",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61572",
    city: "Yates City",
    state: "IL",
    areacode: 309,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61601",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61602",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61603",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61604",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61605",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61606",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61607",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61610",
    city: "Creve Coeur",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61611",
    city: "East Peoria",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61612",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61613",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61614",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61615",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61616",
    city: "Peoria Heights",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61625",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61629",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61630",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61633",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61634",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61635",
    city: "East Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61636",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61637",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61638",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61639",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61641",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61643",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61650",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61651",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61652",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61653",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61654",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61655",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61656",
    city: "Peoria",
    state: "IL",
    areacode: 309,
    county: "Peoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61701",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61702",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61704",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61705",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61709",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61710",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61720",
    city: "Anchor",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61721",
    city: "Armington",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61722",
    city: "Arrowsmith",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61723",
    city: "Atlanta",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61724",
    city: "Bellflower",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61725",
    city: "Carlock",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61726",
    city: "Chenoa",
    state: "IL",
    areacode: 815,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61727",
    city: "Clinton",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61728",
    city: "Colfax",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61729",
    city: "Congerville",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61730",
    city: "Cooksville",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61731",
    city: "Cropsey",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61732",
    city: "Danvers",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61733",
    city: "Deer Creek",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61734",
    city: "Delavan",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61735",
    city: "DeWitt",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61736",
    city: "Downs",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61737",
    city: "Ellsworth",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61738",
    city: "El Paso",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61739",
    city: "Fairbury",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61740",
    city: "Flanagan",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61741",
    city: "Forrest",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61742",
    city: "Goodfield",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61743",
    city: "Graymont",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61744",
    city: "Gridley",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61745",
    city: "Heyworth",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61747",
    city: "Hopedale",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61748",
    city: "Hudson",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61749",
    city: "Kenney",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61750",
    city: "Lane",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61751",
    city: "Lawndale",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61752",
    city: "Le Roy",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61753",
    city: "Lexington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61754",
    city: "McLean",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61755",
    city: "Mackinaw",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61756",
    city: "Maroa",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61758",
    city: "Merna",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61759",
    city: "Minier",
    state: "IL",
    areacode: 309,
    county: "Tazewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61760",
    city: "Minonk",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61761",
    city: "Normal",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61764",
    city: "Pontiac",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61769",
    city: "Saunemin",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61770",
    city: "Saybrook",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61771",
    city: "Secor",
    state: "IL",
    areacode: 309,
    county: "Woodford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61772",
    city: "Shirley",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61773",
    city: "Sibley",
    state: "IL",
    areacode: 217,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61774",
    city: "Stanford",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61775",
    city: "Strawn",
    state: "IL",
    areacode: 815,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61776",
    city: "Towanda",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61777",
    city: "Wapella",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61778",
    city: "Waynesville",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61790",
    city: "Normal",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61791",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61799",
    city: "Bloomington",
    state: "IL",
    areacode: 309,
    county: "McLean",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61801",
    city: "Urbana",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61802",
    city: "Urbana",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61803",
    city: "Urbana",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61810",
    city: "Allerton",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61811",
    city: "Alvin",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61812",
    city: "Armstrong",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61813",
    city: "Bement",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61814",
    city: "Bismarck",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61815",
    city: "Bondville",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61816",
    city: "Broadlands",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61817",
    city: "Catlin",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61818",
    city: "Cerro Gordo",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61820",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61821",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61822",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61824",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61825",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61826",
    city: "Champaign",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61830",
    city: "Cisco",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61831",
    city: "Collison",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61832",
    city: "Danville",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61833",
    city: "Tilton",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61834",
    city: "Danville",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61839",
    city: "De Land",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61840",
    city: "Dewey",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61841",
    city: "Fairmount",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61842",
    city: "Farmer City",
    state: "IL",
    areacode: 309,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61843",
    city: "Fisher",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61844",
    city: "Fithian",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61845",
    city: "Foosland",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61846",
    city: "Georgetown",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61847",
    city: "Gifford",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61848",
    city: "Henning",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61849",
    city: "Homer",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61850",
    city: "Indianola",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61851",
    city: "Ivesdale",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61852",
    city: "Longview",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61853",
    city: "Mahomet",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61854",
    city: "Mansfield",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61855",
    city: "Milmine",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61856",
    city: "Monticello",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61857",
    city: "Muncie",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61858",
    city: "Oakwood",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61859",
    city: "Ogden",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61862",
    city: "Penfield",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61863",
    city: "Pesotum",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61864",
    city: "Philo",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61865",
    city: "Potomac",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61866",
    city: "Rantoul",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61870",
    city: "Ridge Farm",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61871",
    city: "Royal",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61872",
    city: "Sadorus",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61873",
    city: "Saint Joseph",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61874",
    city: "Savoy",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61875",
    city: "Seymour",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61876",
    city: "Sidell",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61877",
    city: "Sidney",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61878",
    city: "Thomasboro",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61880",
    city: "Tolono",
    state: "IL",
    areacode: 217,
    county: "Champaign",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61882",
    city: "Weldon",
    state: "IL",
    areacode: 217,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61883",
    city: "Westville",
    state: "IL",
    areacode: 217,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61884",
    city: "White Heath",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61910",
    city: "Arcola",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61911",
    city: "Arthur",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61912",
    city: "Ashmore",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61913",
    city: "Atwood",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61914",
    city: "Bethany",
    state: "IL",
    areacode: 217,
    county: "Moultrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61917",
    city: "Brocton",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61919",
    city: "Camargo",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61920",
    city: "Charleston",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61924",
    city: "Chrisman",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61925",
    city: "Dalton City",
    state: "IL",
    areacode: 217,
    county: "Moultrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61928",
    city: "Gays",
    state: "IL",
    areacode: 217,
    county: "Moultrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61929",
    city: "Hammond",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61930",
    city: "Hindsboro",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61931",
    city: "Humboldt",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61932",
    city: "Hume",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61933",
    city: "Kansas",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61936",
    city: "La Place",
    state: "IL",
    areacode: 217,
    county: "Piatt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61937",
    city: "Lovington",
    state: "IL",
    areacode: 217,
    county: "Moultrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61938",
    city: "Mattoon",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61940",
    city: "Metcalf",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61941",
    city: "Murdock",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61942",
    city: "Newman",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61943",
    city: "Oakland",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61944",
    city: "Paris",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61949",
    city: "Redmon",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61951",
    city: "Sullivan",
    state: "IL",
    areacode: 217,
    county: "Moultrie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61953",
    city: "Tuscola",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61955",
    city: "Vermilion",
    state: "IL",
    areacode: 217,
    county: "Edgar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61956",
    city: "Villa Grove",
    state: "IL",
    areacode: 217,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "61957",
    city: "Windsor",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62001",
    city: "Alhambra",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62002",
    city: "Alton",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62006",
    city: "Batchtown",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62009",
    city: "Benld",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62010",
    city: "Bethalto",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62011",
    city: "Bingham",
    state: "IL",
    areacode: 217,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62012",
    city: "Brighton",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62013",
    city: "Brussels",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62014",
    city: "Bunker Hill",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62015",
    city: "Butler",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62016",
    city: "Carrollton",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62017",
    city: "Coffeen",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62018",
    city: "Cottage Hills",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62019",
    city: "Donnellson",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62021",
    city: "Dorsey",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62022",
    city: "Dow",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62023",
    city: "Eagarville",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62024",
    city: "East Alton",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62025",
    city: "Edwardsville",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62026",
    city: "Edwardsville",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62027",
    city: "Eldred",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62028",
    city: "Elsah",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62030",
    city: "Fidelity",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62031",
    city: "Fieldon",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62032",
    city: "Fillmore",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62033",
    city: "Gillespie",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62034",
    city: "Glen Carbon",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62035",
    city: "Godfrey",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62036",
    city: "Golden Eagle",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62037",
    city: "Grafton",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62040",
    city: "Granite City",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62044",
    city: "Greenfield",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62045",
    city: "Hamburg",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62046",
    city: "Hamel",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62047",
    city: "Hardin",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62048",
    city: "Hartford",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62049",
    city: "Hillsboro",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62050",
    city: "Hillview",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62051",
    city: "Irving",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62052",
    city: "Jerseyville",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62053",
    city: "Kampsville",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62054",
    city: "Kane",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62056",
    city: "Litchfield",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62058",
    city: "Livingston",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62059",
    city: "Lovejoy",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62060",
    city: "Madison",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62061",
    city: "Marine",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62062",
    city: "Maryville",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62063",
    city: "Medora",
    state: "IL",
    areacode: 618,
    county: "Jersey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62065",
    city: "Michael",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62067",
    city: "Moro",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62069",
    city: "Mount Olive",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62070",
    city: "Mozier",
    state: "IL",
    areacode: 618,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62071",
    city: "National Stock Yards",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62074",
    city: "New Douglas",
    state: "IL",
    areacode: 217,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62075",
    city: "Nokomis",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62076",
    city: "Ohlman",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62077",
    city: "Panama",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62078",
    city: "Patterson",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62079",
    city: "Piasa",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62080",
    city: "Ramsey",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62081",
    city: "Rockbridge",
    state: "IL",
    areacode: 618,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62082",
    city: "Roodhouse",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62083",
    city: "Rosamond",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62084",
    city: "Roxana",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62085",
    city: "Sawyerville",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62086",
    city: "Sorento",
    state: "IL",
    areacode: 217,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62087",
    city: "South Roxana",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62088",
    city: "Staunton",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62089",
    city: "Taylor Springs",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62090",
    city: "Venice",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62091",
    city: "Walshville",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62092",
    city: "White Hall",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62093",
    city: "Wilsonville",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62094",
    city: "Witt",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62095",
    city: "Wood River",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62097",
    city: "Worden",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62098",
    city: "Wrights",
    state: "IL",
    areacode: 217,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62201",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62202",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62203",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62204",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62205",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62206",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62207",
    city: "East Saint Louis",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62208",
    city: "Fairview Heights",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62214",
    city: "Addieville",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62215",
    city: "Albers",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62216",
    city: "Aviston",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62217",
    city: "Baldwin",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62218",
    city: "Bartelso",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62219",
    city: "Beckemeyer",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62220",
    city: "Belleville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62221",
    city: "Belleville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62222",
    city: "Belleville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62223",
    city: "Belleville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62225",
    city: "Scott AFB",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62226",
    city: "Belleville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62230",
    city: "Breese",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62231",
    city: "Carlyle",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62232",
    city: "Caseyville",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62233",
    city: "Chester",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62234",
    city: "Collinsville",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62236",
    city: "Columbia",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62237",
    city: "Coulterville",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62238",
    city: "Cutler",
    state: "IL",
    areacode: 618,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62239",
    city: "Dupo",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62240",
    city: "East Carondelet",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62241",
    city: "Ellis Grove",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62242",
    city: "Evansville",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62243",
    city: "Freeburg",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62244",
    city: "Fults",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62245",
    city: "Germantown",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62246",
    city: "Greenville",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62248",
    city: "Hecker",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62249",
    city: "Highland",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62250",
    city: "Hoffman",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62252",
    city: "Huey",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62253",
    city: "Keyesport",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62254",
    city: "Lebanon",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62255",
    city: "Lenzburg",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62256",
    city: "Maeystown",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62257",
    city: "Marissa",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62258",
    city: "Mascoutah",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62259",
    city: "Menard",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62260",
    city: "Millstadt",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62261",
    city: "Modoc",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62262",
    city: "Mulberry Grove",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62263",
    city: "Nashville",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62264",
    city: "New Athens",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62265",
    city: "New Baden",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62266",
    city: "New Memphis",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62268",
    city: "Oakdale",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62269",
    city: "O Fallon",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62271",
    city: "Okawville",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62272",
    city: "Percy",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62273",
    city: "Pierron",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62274",
    city: "Pinckneyville",
    state: "IL",
    areacode: 618,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62275",
    city: "Pocahontas",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62277",
    city: "Prairie du Rocher",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62278",
    city: "Red Bud",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62279",
    city: "Renault",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62280",
    city: "Rockwood",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62281",
    city: "Saint Jacob",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62282",
    city: "Saint Libory",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62284",
    city: "Smithboro",
    state: "IL",
    areacode: 618,
    county: "Bond",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62285",
    city: "Smithton",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62286",
    city: "Sparta",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62288",
    city: "Steeleville",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62289",
    city: "Summerfield",
    state: "IL",
    areacode: 618,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62292",
    city: "Tilden",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62293",
    city: "Trenton",
    state: "IL",
    areacode: 618,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62294",
    city: "Troy",
    state: "IL",
    areacode: 618,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62295",
    city: "Valmeyer",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62297",
    city: "Walsh",
    state: "IL",
    areacode: 618,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62298",
    city: "Waterloo",
    state: "IL",
    areacode: 618,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62301",
    city: "Quincy",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62305",
    city: "Quincy",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62306",
    city: "Quincy",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62311",
    city: "Augusta",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62312",
    city: "Barry",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62313",
    city: "Basco",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62314",
    city: "Baylis",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62316",
    city: "Bowen",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62319",
    city: "Camden",
    state: "IL",
    areacode: 217,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62320",
    city: "Camp Point",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62321",
    city: "Carthage",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62323",
    city: "Chambersburg",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62324",
    city: "Clayton",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62325",
    city: "Coatsburg",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62326",
    city: "Colchester",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62329",
    city: "Colusa",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62330",
    city: "Dallas City",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62334",
    city: "Elvaston",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62336",
    city: "Ferris",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62338",
    city: "Fowler",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62339",
    city: "Golden",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62340",
    city: "Griggsville",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62341",
    city: "Hamilton",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62343",
    city: "Hull",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62344",
    city: "Huntsville",
    state: "IL",
    areacode: 217,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62345",
    city: "Kinderhook",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62346",
    city: "La Prairie",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62347",
    city: "Liberty",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62348",
    city: "Lima",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62349",
    city: "Loraine",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62351",
    city: "Mendon",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62352",
    city: "Milton",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62353",
    city: "Mount Sterling",
    state: "IL",
    areacode: 217,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62354",
    city: "Nauvoo",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62355",
    city: "Nebo",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62356",
    city: "New Canton",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62357",
    city: "New Salem",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62358",
    city: "Niota",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62359",
    city: "Paloma",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62360",
    city: "Payson",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62361",
    city: "Pearl",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62362",
    city: "Perry",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62363",
    city: "Pittsfield",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62365",
    city: "Plainville",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62366",
    city: "Pleasant Hill",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62367",
    city: "Plymouth",
    state: "IL",
    areacode: 309,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62370",
    city: "Rockport",
    state: "IL",
    areacode: 217,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62373",
    city: "Sutter",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62374",
    city: "Tennessee",
    state: "IL",
    areacode: 309,
    county: "McDonough",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62375",
    city: "Timewell",
    state: "IL",
    areacode: 217,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62376",
    city: "Ursa",
    state: "IL",
    areacode: 217,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62378",
    city: "Versailles",
    state: "IL",
    areacode: 217,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62379",
    city: "Warsaw",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62380",
    city: "West Point",
    state: "IL",
    areacode: 217,
    county: "Hancock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62401",
    city: "Effingham",
    state: "IL",
    areacode: 217,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62410",
    city: "Allendale",
    state: "IL",
    areacode: 618,
    county: "Wabash",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62411",
    city: "Altamont",
    state: "IL",
    areacode: 618,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62413",
    city: "Annapolis",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62414",
    city: "Beecher City",
    state: "IL",
    areacode: 618,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62417",
    city: "Bridgeport",
    state: "IL",
    areacode: 618,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62418",
    city: "Brownstown",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62419",
    city: "Calhoun",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62420",
    city: "Casey",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62421",
    city: "Claremont",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62422",
    city: "Cowden",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62423",
    city: "Dennison",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62424",
    city: "Dieterich",
    state: "IL",
    areacode: 217,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62425",
    city: "Dundas",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62426",
    city: "Edgewood",
    state: "IL",
    areacode: 618,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62427",
    city: "Flat Rock",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62428",
    city: "Greenup",
    state: "IL",
    areacode: 217,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62431",
    city: "Herrick",
    state: "IL",
    areacode: 618,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62432",
    city: "Hidalgo",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62433",
    city: "Hutsonville",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62434",
    city: "Ingraham",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62436",
    city: "Jewett",
    state: "IL",
    areacode: 217,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62438",
    city: "Lakewood",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62439",
    city: "Lawrenceville",
    state: "IL",
    areacode: 618,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62440",
    city: "Lerna",
    state: "IL",
    areacode: 217,
    county: "Coles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62441",
    city: "Marshall",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62442",
    city: "Martinsville",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62443",
    city: "Mason",
    state: "IL",
    areacode: 618,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62444",
    city: "Mode",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62445",
    city: "Montrose",
    state: "IL",
    areacode: 217,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62446",
    city: "Mount Erie",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62447",
    city: "Neoga",
    state: "IL",
    areacode: 217,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62448",
    city: "Newton",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62449",
    city: "Oblong",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62450",
    city: "Olney",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62451",
    city: "Palestine",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62452",
    city: "Parkersburg",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62454",
    city: "Robinson",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62458",
    city: "Saint Elmo",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62459",
    city: "Sainte Marie",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62460",
    city: "Saint Francisville",
    state: "IL",
    areacode: 618,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62461",
    city: "Shumway",
    state: "IL",
    areacode: 217,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62462",
    city: "Sigel",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62463",
    city: "Stewardson",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62464",
    city: "Stoy",
    state: "IL",
    areacode: 618,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62465",
    city: "Strasburg",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62466",
    city: "Sumner",
    state: "IL",
    areacode: 618,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62467",
    city: "Teutopolis",
    state: "IL",
    areacode: 217,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62468",
    city: "Toledo",
    state: "IL",
    areacode: 217,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62469",
    city: "Trilla",
    state: "IL",
    areacode: 217,
    county: "Cumberland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62471",
    city: "Vandalia",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62473",
    city: "Watson",
    state: "IL",
    areacode: 217,
    county: "Effingham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62474",
    city: "Westfield",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62475",
    city: "West Liberty",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62476",
    city: "West Salem",
    state: "IL",
    areacode: 618,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62477",
    city: "West Union",
    state: "IL",
    areacode: 217,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62478",
    city: "West York",
    state: "IL",
    areacode: 618,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62479",
    city: "Wheeler",
    state: "IL",
    areacode: 217,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62480",
    city: "Willow Hill",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62481",
    city: "Yale",
    state: "IL",
    areacode: 618,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62501",
    city: "Argenta",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62510",
    city: "Assumption",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62512",
    city: "Beason",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62513",
    city: "Blue Mound",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62514",
    city: "Boody",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62515",
    city: "Buffalo",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62517",
    city: "Bulpitt",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62518",
    city: "Chestnut",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62519",
    city: "Cornland",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62520",
    city: "Dawson",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62521",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62522",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62523",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62524",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62525",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62526",
    city: "Decatur",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62530",
    city: "Divernon",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62531",
    city: "Edinburg",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62532",
    city: "Elwin",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62533",
    city: "Farmersville",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62534",
    city: "Findlay",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62535",
    city: "Forsyth",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62536",
    city: "Glenarm",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62537",
    city: "Harristown",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62538",
    city: "Harvel",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62539",
    city: "Illiopolis",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62540",
    city: "Kincaid",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62541",
    city: "Lake Fork",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62543",
    city: "Latham",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62544",
    city: "Macon",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62545",
    city: "Mechanicsburg",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62546",
    city: "Morrisonville",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62547",
    city: "Mount Auburn",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62548",
    city: "Mount Pulaski",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62549",
    city: "Mount Zion",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62550",
    city: "Moweaqua",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62551",
    city: "Niantic",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62553",
    city: "Oconee",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62554",
    city: "Oreana",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62555",
    city: "Owaneco",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62556",
    city: "Palmer",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62557",
    city: "Pana",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62558",
    city: "Pawnee",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62560",
    city: "Raymond",
    state: "IL",
    areacode: 217,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62561",
    city: "Riverton",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62563",
    city: "Rochester",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62565",
    city: "Shelbyville",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62567",
    city: "Stonington",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62568",
    city: "Taylorville",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62570",
    city: "Tovey",
    state: "IL",
    areacode: 217,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62571",
    city: "Tower Hill",
    state: "IL",
    areacode: 217,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62572",
    city: "Waggoner",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62573",
    city: "Warrensburg",
    state: "IL",
    areacode: 217,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62601",
    city: "Alexander",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62610",
    city: "Alsey",
    state: "IL",
    areacode: 217,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62611",
    city: "Arenzville",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62612",
    city: "Ashland",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62613",
    city: "Athens",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62615",
    city: "Auburn",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62617",
    city: "Bath",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62618",
    city: "Beardstown",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62621",
    city: "Bluffs",
    state: "IL",
    areacode: 217,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62622",
    city: "Bluff Springs",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62624",
    city: "Browning",
    state: "IL",
    areacode: 217,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62625",
    city: "Cantrall",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62626",
    city: "Carlinville",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62627",
    city: "Chandlerville",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62628",
    city: "Chapin",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62629",
    city: "Chatham",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62630",
    city: "Chesterfield",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62631",
    city: "Concord",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62633",
    city: "Easton",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62634",
    city: "Elkhart",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62635",
    city: "Emden",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62638",
    city: "Franklin",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62639",
    city: "Frederick",
    state: "IL",
    areacode: 217,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62640",
    city: "Girard",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62642",
    city: "Greenview",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62643",
    city: "Hartsburg",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62644",
    city: "Havana",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62649",
    city: "Hettick",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62650",
    city: "Jacksonville",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62651",
    city: "Jacksonville",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62655",
    city: "Kilbourne",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62656",
    city: "Lincoln",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62659",
    city: "Lincolns New Salem",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62660",
    city: "Literberry",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62661",
    city: "Loami",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62662",
    city: "Lowder",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62663",
    city: "Manchester",
    state: "IL",
    areacode: 217,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62664",
    city: "Mason City",
    state: "IL",
    areacode: 217,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62665",
    city: "Meredosia",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62666",
    city: "Middletown",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62667",
    city: "Modesto",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62668",
    city: "Murrayville",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62670",
    city: "New Berlin",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62671",
    city: "New Holland",
    state: "IL",
    areacode: 217,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62672",
    city: "Nilwood",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62673",
    city: "Oakford",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62674",
    city: "Palmyra",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62675",
    city: "Petersburg",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62677",
    city: "Pleasant Plains",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62681",
    city: "Rushville",
    state: "IL",
    areacode: 217,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62682",
    city: "San Jose",
    state: "IL",
    areacode: 309,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62683",
    city: "Scottville",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62684",
    city: "Sherman",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62685",
    city: "Shipman",
    state: "IL",
    areacode: 618,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62688",
    city: "Tallula",
    state: "IL",
    areacode: 217,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62689",
    city: "Thayer",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62690",
    city: "Virden",
    state: "IL",
    areacode: 217,
    county: "Macoupin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62691",
    city: "Virginia",
    state: "IL",
    areacode: 217,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62692",
    city: "Waverly",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62693",
    city: "Williamsville",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62694",
    city: "Winchester",
    state: "IL",
    areacode: 217,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62695",
    city: "Woodson",
    state: "IL",
    areacode: 217,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62701",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62702",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62703",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62704",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62705",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62706",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62707",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62708",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62711",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62712",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62715",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62716",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62719",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62722",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62723",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62726",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62736",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62739",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62756",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62757",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62761",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62762",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62763",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62764",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62765",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62766",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62767",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62769",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62776",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62777",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62781",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62786",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62791",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62794",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62796",
    city: "Springfield",
    state: "IL",
    areacode: 217,
    county: "Sangamon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62801",
    city: "Centralia",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62803",
    city: "Hoyleton",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62806",
    city: "Albion",
    state: "IL",
    areacode: 618,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62807",
    city: "Alma",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62808",
    city: "Ashley",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62809",
    city: "Barnhill",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62810",
    city: "Belle Rive",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62811",
    city: "Bellmont",
    state: "IL",
    areacode: 618,
    county: "Wabash",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62812",
    city: "Benton",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62814",
    city: "Bluford",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62815",
    city: "Bone Gap",
    state: "IL",
    areacode: 618,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62816",
    city: "Bonnie",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62817",
    city: "Broughton",
    state: "IL",
    areacode: 618,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62818",
    city: "Browns",
    state: "IL",
    areacode: 618,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62819",
    city: "Buckner",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62820",
    city: "Burnt Prairie",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62821",
    city: "Carmi",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62822",
    city: "Christopher",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62823",
    city: "Cisne",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62824",
    city: "Clay City",
    state: "IL",
    areacode: 618,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62825",
    city: "Coello",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62827",
    city: "Crossville",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62828",
    city: "Dahlgren",
    state: "IL",
    areacode: 618,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62829",
    city: "Dale",
    state: "IL",
    areacode: 618,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62830",
    city: "Dix",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62831",
    city: "Du Bois",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62832",
    city: "Du Quoin",
    state: "IL",
    areacode: 618,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62833",
    city: "Ellery",
    state: "IL",
    areacode: 618,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62834",
    city: "Emma",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62835",
    city: "Enfield",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62836",
    city: "Ewing",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62837",
    city: "Fairfield",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62838",
    city: "Farina",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62839",
    city: "Flora",
    state: "IL",
    areacode: 618,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62840",
    city: "Frankfort Heights",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62841",
    city: "Freeman Spur",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62842",
    city: "Geff",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62843",
    city: "Golden Gate",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62844",
    city: "Grayville",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62846",
    city: "Ina",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62848",
    city: "Irvington",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62849",
    city: "Iuka",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62850",
    city: "Johnsonville",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62851",
    city: "Keenes",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62852",
    city: "Keensburg",
    state: "IL",
    areacode: 618,
    county: "Wabash",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62853",
    city: "Kell",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62854",
    city: "Kinmundy",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62856",
    city: "Logan",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62858",
    city: "Louisville",
    state: "IL",
    areacode: 618,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62859",
    city: "McLeansboro",
    state: "IL",
    areacode: 618,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62860",
    city: "Macedonia",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62861",
    city: "Maunie",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62862",
    city: "Mill Shoals",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62863",
    city: "Mount Carmel",
    state: "IL",
    areacode: 618,
    county: "Wabash",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62864",
    city: "Mount Vernon",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62865",
    city: "Mulkeytown",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62866",
    city: "Nason",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62867",
    city: "New Haven",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62868",
    city: "Noble",
    state: "IL",
    areacode: 618,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62869",
    city: "Norris City",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62870",
    city: "Odin",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62871",
    city: "Omaha",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62872",
    city: "Opdyke",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62874",
    city: "Orient",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62875",
    city: "Patoka",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62876",
    city: "Radom",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62877",
    city: "Richview",
    state: "IL",
    areacode: 618,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62878",
    city: "Rinard",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62879",
    city: "Sailor Springs",
    state: "IL",
    areacode: 618,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62880",
    city: "Saint Peter",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62881",
    city: "Salem",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62882",
    city: "Sandoval",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62883",
    city: "Scheller",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62884",
    city: "Sesser",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62885",
    city: "Shobonier",
    state: "IL",
    areacode: 618,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62886",
    city: "Sims",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62887",
    city: "Springerton",
    state: "IL",
    areacode: 618,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62888",
    city: "Tamaroa",
    state: "IL",
    areacode: 618,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62889",
    city: "Texico",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62890",
    city: "Thompsonville",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62891",
    city: "Valier",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62892",
    city: "Vernon",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62893",
    city: "Walnut Hill",
    state: "IL",
    areacode: 618,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62894",
    city: "Waltonville",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62895",
    city: "Wayne City",
    state: "IL",
    areacode: 618,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62896",
    city: "West Frankfort",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62897",
    city: "Whittington",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62898",
    city: "Woodlawn",
    state: "IL",
    areacode: 618,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62899",
    city: "Xenia",
    state: "IL",
    areacode: 618,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62901",
    city: "Carbondale",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62902",
    city: "Carbondale",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62903",
    city: "Carbondale",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62905",
    city: "Alto Pass",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62906",
    city: "Anna",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62907",
    city: "Ava",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62908",
    city: "Belknap",
    state: "IL",
    areacode: 618,
    county: "Massac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62909",
    city: "Boles",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62910",
    city: "Brookport",
    state: "IL",
    areacode: 618,
    county: "Massac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62912",
    city: "Buncombe",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62914",
    city: "Cairo",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62915",
    city: "Cambria",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62916",
    city: "Campbell Hill",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62917",
    city: "Carrier Mills",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62918",
    city: "Carterville",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62919",
    city: "Cave in Rock",
    state: "IL",
    areacode: 618,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62920",
    city: "Cobden",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62921",
    city: "Colp",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62922",
    city: "Creal Springs",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62923",
    city: "Cypress",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62924",
    city: "De Soto",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62926",
    city: "Dongola",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62927",
    city: "Dowell",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62928",
    city: "Eddyville",
    state: "IL",
    areacode: 618,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62930",
    city: "Eldorado",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62931",
    city: "Elizabethtown",
    state: "IL",
    areacode: 618,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62932",
    city: "Elkville",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62933",
    city: "Energy",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62934",
    city: "Equality",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62935",
    city: "Galatia",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62938",
    city: "Golconda",
    state: "IL",
    areacode: 618,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62939",
    city: "Goreville",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62940",
    city: "Gorham",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62941",
    city: "Grand Chain",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62942",
    city: "Grand Tower",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62943",
    city: "Grantsburg",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62946",
    city: "Harrisburg",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62947",
    city: "Herod",
    state: "IL",
    areacode: 618,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62948",
    city: "Herrin",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62949",
    city: "Hurst",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62950",
    city: "Jacob",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62951",
    city: "Johnston City",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62952",
    city: "Jonesboro",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62953",
    city: "Joppa",
    state: "IL",
    areacode: 618,
    county: "Massac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62954",
    city: "Junction",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62956",
    city: "Karnak",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62957",
    city: "McClure",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62958",
    city: "Makanda",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62959",
    city: "Marion",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62960",
    city: "Metropolis",
    state: "IL",
    areacode: 618,
    county: "Massac",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62961",
    city: "Millcreek",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62962",
    city: "Miller City",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62963",
    city: "Mound City",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62964",
    city: "Mounds",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62965",
    city: "Muddy",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62966",
    city: "Murphysboro",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62967",
    city: "New Burnside",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62969",
    city: "Olive Branch",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62970",
    city: "Olmsted",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62972",
    city: "Ozark",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62973",
    city: "Perks",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62974",
    city: "Pittsburg",
    state: "IL",
    areacode: 618,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62975",
    city: "Pomona",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62976",
    city: "Pulaski",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62977",
    city: "Raleigh",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62979",
    city: "Ridgway",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62982",
    city: "Rosiclare",
    state: "IL",
    areacode: 618,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62983",
    city: "Royalton",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62984",
    city: "Shawneetown",
    state: "IL",
    areacode: 618,
    county: "Gallatin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62985",
    city: "Simpson",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62987",
    city: "Stonefort",
    state: "IL",
    areacode: 618,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62988",
    city: "Tamms",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62990",
    city: "Thebes",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62992",
    city: "Ullin",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62993",
    city: "Unity",
    state: "IL",
    areacode: 618,
    county: "Alexander",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62994",
    city: "Vergennes",
    state: "IL",
    areacode: 618,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62995",
    city: "Vienna",
    state: "IL",
    areacode: 618,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62996",
    city: "Villa Ridge",
    state: "IL",
    areacode: 618,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62997",
    city: "Willisville",
    state: "IL",
    areacode: 618,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62998",
    city: "Wolf Lake",
    state: "IL",
    areacode: 618,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "62999",
    city: "Zeigler",
    state: "IL",
    areacode: 618,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63005",
    city: "Chesterfield",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63006",
    city: "Chesterfield",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63010",
    city: "Arnold",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63011",
    city: "Ballwin",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63012",
    city: "Barnhart",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63013",
    city: "Beaufort",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63014",
    city: "Berger",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63015",
    city: "Catawissa",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63016",
    city: "Cedar Hill",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63017",
    city: "Chesterfield",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63019",
    city: "Crystal City",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63020",
    city: "De Soto",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63021",
    city: "Ballwin",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63022",
    city: "Ballwin",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63023",
    city: "Dittmer",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63024",
    city: "Ballwin",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63025",
    city: "Eureka",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63026",
    city: "Fenton",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63028",
    city: "Festus",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63030",
    city: "Fletcher",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63031",
    city: "Florissant",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63032",
    city: "Florissant",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63033",
    city: "Florissant",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63034",
    city: "Florissant",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63036",
    city: "French Village",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63037",
    city: "Gerald",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63038",
    city: "Wildwood",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63039",
    city: "Gray Summit",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63040",
    city: "Wildwood",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63041",
    city: "Grubville",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63042",
    city: "Hazelwood",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63043",
    city: "Maryland Heights",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63044",
    city: "Bridgeton",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63045",
    city: "Earth City",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63047",
    city: "Hematite",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63048",
    city: "Herculaneum",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63049",
    city: "High Ridge",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63050",
    city: "Hillsboro",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63051",
    city: "House Springs",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63052",
    city: "Imperial",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63053",
    city: "Kimmswick",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63055",
    city: "Labadie",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63056",
    city: "Leslie",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63057",
    city: "Liguori",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63060",
    city: "Lonedell",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63061",
    city: "Luebbering",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63065",
    city: "Mapaville",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63066",
    city: "Morse Mill",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63068",
    city: "New Haven",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63069",
    city: "Pacific",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63070",
    city: "Pevely",
    state: "MO",
    areacode: 636,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63071",
    city: "Richwoods",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63072",
    city: "Robertsville",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63073",
    city: "Saint Albans",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63074",
    city: "Saint Ann",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63077",
    city: "Saint Clair",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63079",
    city: "Stanton",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63080",
    city: "Sullivan",
    state: "MO",
    areacode: 573,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63084",
    city: "Union",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63087",
    city: "Valles Mines",
    state: "MO",
    areacode: 636,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63088",
    city: "Valley Park",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63089",
    city: "Villa Ridge",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63090",
    city: "Washington",
    state: "MO",
    areacode: 636,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63091",
    city: "Rosebud",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63099",
    city: "Fenton",
    state: "MO",
    areacode: 636,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63101",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63102",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63103",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63104",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63105",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63106",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63107",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63108",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63109",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63110",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63111",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63112",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63113",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63114",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63115",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63116",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63117",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63118",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63119",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63120",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63121",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63122",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63123",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63124",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63125",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63126",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63127",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63128",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63129",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63130",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63131",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63132",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63133",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63134",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63135",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63136",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63137",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63138",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63139",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63140",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63141",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63143",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63144",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63145",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63146",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63147",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63150",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63151",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63155",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63156",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63157",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63158",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63160",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63163",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63164",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63166",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63167",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63169",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63171",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63177",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63178",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63179",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63180",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63188",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63195",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63197",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63199",
    city: "Saint Louis",
    state: "MO",
    areacode: 314,
    county: "Saint Louis City",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63301",
    city: "Saint Charles",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63302",
    city: "Saint Charles",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63303",
    city: "Saint Charles",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63304",
    city: "Saint Charles",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63330",
    city: "Annada",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63332",
    city: "Augusta",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63333",
    city: "Bellflower",
    state: "MO",
    areacode: 573,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63334",
    city: "Bowling Green",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63336",
    city: "Clarksville",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63338",
    city: "Cottleville",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63339",
    city: "Curryville",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63341",
    city: "Defiance",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63342",
    city: "Dutzow",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63343",
    city: "Elsberry",
    state: "MO",
    areacode: 573,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63344",
    city: "Eolia",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63345",
    city: "Farber",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63346",
    city: "Flinthill",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63347",
    city: "Foley",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63348",
    city: "Foristell",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63349",
    city: "Hawk Point",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63350",
    city: "High Hill",
    state: "MO",
    areacode: 636,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63351",
    city: "Jonesburg",
    state: "MO",
    areacode: 636,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63352",
    city: "Laddonia",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63353",
    city: "Louisiana",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63357",
    city: "Marthasville",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63359",
    city: "Middletown",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63361",
    city: "Montgomery City",
    state: "MO",
    areacode: 573,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63362",
    city: "Moscow Mills",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63363",
    city: "New Florence",
    state: "MO",
    areacode: 573,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63365",
    city: "New Melle",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63366",
    city: "O Fallon",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63367",
    city: "Lake Saint Louis",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63368",
    city: "O Fallon",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63369",
    city: "Old Monroe",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63370",
    city: "Olney",
    state: "MO",
    areacode: 573,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63373",
    city: "Portage des Sioux",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63376",
    city: "Saint Peters",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63377",
    city: "Silex",
    state: "MO",
    areacode: 573,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63378",
    city: "Treloar",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63379",
    city: "Troy",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63380",
    city: "Truesdale",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63381",
    city: "Truxton",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63382",
    city: "Vandalia",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63383",
    city: "Warrenton",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63384",
    city: "Wellsville",
    state: "MO",
    areacode: 573,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63385",
    city: "Wentzville",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63386",
    city: "West Alton",
    state: "MO",
    areacode: 636,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63387",
    city: "Whiteside",
    state: "MO",
    areacode: 573,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63388",
    city: "Williamsburg",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63389",
    city: "Winfield",
    state: "MO",
    areacode: 636,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63390",
    city: "Wright City",
    state: "MO",
    areacode: 636,
    county: "Warren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63401",
    city: "Hannibal",
    state: "MO",
    areacode: 573,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63430",
    city: "Alexandria",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63431",
    city: "Anabel",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63432",
    city: "Arbela",
    state: "MO",
    areacode: 660,
    county: "Scotland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63433",
    city: "Ashburn",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63434",
    city: "Bethel",
    state: "MO",
    areacode: 660,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63435",
    city: "Canton",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63436",
    city: "Center",
    state: "MO",
    areacode: 573,
    county: "Ralls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63437",
    city: "Clarence",
    state: "MO",
    areacode: 660,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63438",
    city: "Durham",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63439",
    city: "Emden",
    state: "MO",
    areacode: 573,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63440",
    city: "Ewing",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63441",
    city: "Frankford",
    state: "MO",
    areacode: 573,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63442",
    city: "Granger",
    state: "MO",
    areacode: 660,
    county: "Scotland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63443",
    city: "Hunnewell",
    state: "MO",
    areacode: 573,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63445",
    city: "Kahoka",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63446",
    city: "Knox City",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63447",
    city: "La Belle",
    state: "MO",
    areacode: 660,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63448",
    city: "La Grange",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63450",
    city: "Lentner",
    state: "MO",
    areacode: 573,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63451",
    city: "Leonard",
    state: "MO",
    areacode: 660,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63452",
    city: "Lewistown",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63453",
    city: "Luray",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63454",
    city: "Maywood",
    state: "MO",
    areacode: 573,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63456",
    city: "Monroe City",
    state: "MO",
    areacode: 573,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63457",
    city: "Monticello",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63458",
    city: "Newark",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63459",
    city: "New London",
    state: "MO",
    areacode: 573,
    county: "Ralls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63460",
    city: "Novelty",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63461",
    city: "Palmyra",
    state: "MO",
    areacode: 573,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63462",
    city: "Perry",
    state: "MO",
    areacode: 573,
    county: "Ralls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63463",
    city: "Philadelphia",
    state: "MO",
    areacode: 573,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63465",
    city: "Revere",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63466",
    city: "Saint Patrick",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63467",
    city: "Saverton",
    state: "MO",
    areacode: 573,
    county: "Ralls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63468",
    city: "Shelbina",
    state: "MO",
    areacode: 573,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63469",
    city: "Shelbyville",
    state: "MO",
    areacode: 573,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63471",
    city: "Taylor",
    state: "MO",
    areacode: 573,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63472",
    city: "Wayland",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63473",
    city: "Williamstown",
    state: "MO",
    areacode: 573,
    county: "Lewis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63474",
    city: "Wyaconda",
    state: "MO",
    areacode: 660,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63501",
    city: "Kirksville",
    state: "MO",
    areacode: 660,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63530",
    city: "Atlanta",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63531",
    city: "Baring",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63532",
    city: "Bevier",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63533",
    city: "Brashear",
    state: "MO",
    areacode: 660,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63534",
    city: "Callao",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63535",
    city: "Coatsville",
    state: "MO",
    areacode: 660,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63536",
    city: "Downing",
    state: "MO",
    areacode: 660,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63537",
    city: "Edina",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63538",
    city: "Elmer",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63539",
    city: "Ethel",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63540",
    city: "Gibbs",
    state: "MO",
    areacode: 660,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63541",
    city: "Glenwood",
    state: "MO",
    areacode: 660,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63543",
    city: "Gorin",
    state: "MO",
    areacode: 660,
    county: "Scotland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63544",
    city: "Green Castle",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63545",
    city: "Green City",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63546",
    city: "Greentop",
    state: "MO",
    areacode: 660,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63547",
    city: "Hurdland",
    state: "MO",
    areacode: 660,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63548",
    city: "Lancaster",
    state: "MO",
    areacode: 660,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63549",
    city: "La Plata",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63551",
    city: "Livonia",
    state: "MO",
    areacode: 660,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63552",
    city: "Macon",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63555",
    city: "Memphis",
    state: "MO",
    areacode: 660,
    county: "Scotland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63556",
    city: "Milan",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63557",
    city: "New Boston",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63558",
    city: "New Cambria",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63559",
    city: "Novinger",
    state: "MO",
    areacode: 660,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63560",
    city: "Pollock",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63561",
    city: "Queen City",
    state: "MO",
    areacode: 660,
    county: "Schuyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63563",
    city: "Rutledge",
    state: "MO",
    areacode: 660,
    county: "Scotland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63565",
    city: "Unionville",
    state: "MO",
    areacode: 660,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63566",
    city: "Winigan",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63567",
    city: "Worthington",
    state: "MO",
    areacode: 660,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63601",
    city: "Park Hills",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63620",
    city: "Annapolis",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63621",
    city: "Arcadia",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63622",
    city: "Belgrade",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63623",
    city: "Belleview",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63624",
    city: "Bismarck",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63625",
    city: "Black",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63626",
    city: "Blackwell",
    state: "MO",
    areacode: 636,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63627",
    city: "Bloomsdale",
    state: "MO",
    areacode: 573,
    county: "Sainte Genevieve",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63628",
    city: "Bonne Terre",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63629",
    city: "Bunker",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63630",
    city: "Cadet",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63631",
    city: "Caledonia",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63632",
    city: "Cascade",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63633",
    city: "Centerville",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63636",
    city: "Des Arc",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63637",
    city: "Doe Run",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63638",
    city: "Ellington",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63640",
    city: "Farmington",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63645",
    city: "Fredericktown",
    state: "MO",
    areacode: 573,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63648",
    city: "Irondale",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63650",
    city: "Ironton",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63651",
    city: "Knob Lick",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63653",
    city: "Leadwood",
    state: "MO",
    areacode: 573,
    county: "Saint Francois",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63654",
    city: "Lesterville",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63655",
    city: "Marquand",
    state: "MO",
    areacode: 573,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63656",
    city: "Middle Brook",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63660",
    city: "Mineral Point",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63662",
    city: "Patton",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63663",
    city: "Pilot Knob",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63664",
    city: "Potosi",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63665",
    city: "Redford",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63666",
    city: "Reynolds",
    state: "MO",
    areacode: 573,
    county: "Reynolds",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63670",
    city: "Sainte Genevieve",
    state: "MO",
    areacode: 573,
    county: "Sainte Genevieve",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63673",
    city: "Saint Mary",
    state: "MO",
    areacode: 573,
    county: "Sainte Genevieve",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63674",
    city: "Tiff",
    state: "MO",
    areacode: 573,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63675",
    city: "Vulcan",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63701",
    city: "Cape Girardeau",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63702",
    city: "Cape Girardeau",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63703",
    city: "Cape Girardeau",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63730",
    city: "Advance",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63732",
    city: "Altenburg",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63735",
    city: "Bell City",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63736",
    city: "Benton",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63737",
    city: "Brazeau",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63738",
    city: "Brownwood",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63739",
    city: "Burfordville",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63740",
    city: "Chaffee",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63742",
    city: "Commerce",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63743",
    city: "Daisy",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63744",
    city: "Delta",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63745",
    city: "Dutchtown",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63746",
    city: "Farrar",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63747",
    city: "Friedheim",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63748",
    city: "Frohna",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63750",
    city: "Gipsy",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63751",
    city: "Glenallen",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63752",
    city: "Gordonville",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63755",
    city: "Jackson",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63758",
    city: "Kelso",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63760",
    city: "Leopold",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63763",
    city: "McGee",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63764",
    city: "Marble Hill",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63766",
    city: "Millersville",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63767",
    city: "Morley",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63769",
    city: "Oak Ridge",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63770",
    city: "Old Appleton",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63771",
    city: "Oran",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63774",
    city: "Perkins",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63775",
    city: "Perryville",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63776",
    city: "McBride",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63779",
    city: "Pocahontas",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63780",
    city: "Scott City",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63781",
    city: "Sedgewickville",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63782",
    city: "Sturdivant",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63783",
    city: "Uniontown",
    state: "MO",
    areacode: 573,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63784",
    city: "Vanduser",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63785",
    city: "Whitewater",
    state: "MO",
    areacode: 573,
    county: "Cape Girardeau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63787",
    city: "Zalma",
    state: "MO",
    areacode: 573,
    county: "Bollinger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63801",
    city: "Sikeston",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63820",
    city: "Anniston",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63821",
    city: "Arbyrd",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63822",
    city: "Bernie",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63823",
    city: "Bertrand",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63824",
    city: "Blodgett",
    state: "MO",
    areacode: 573,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63825",
    city: "Bloomfield",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63826",
    city: "Braggadocio",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63827",
    city: "Bragg City",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63828",
    city: "Canalou",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63829",
    city: "Cardwell",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63830",
    city: "Caruthersville",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63833",
    city: "Catron",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63834",
    city: "Charleston",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63837",
    city: "Clarkton",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63839",
    city: "Cooter",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63840",
    city: "Deering",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63841",
    city: "Dexter",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63845",
    city: "East Prairie",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63846",
    city: "Essex",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63847",
    city: "Gibson",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63848",
    city: "Gideon",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63849",
    city: "Gobler",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63850",
    city: "Grayridge",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63851",
    city: "Hayti",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63852",
    city: "Holcomb",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63853",
    city: "Holland",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63855",
    city: "Hornersville",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63857",
    city: "Kennett",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63860",
    city: "Kewanee",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63862",
    city: "Lilbourn",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63863",
    city: "Malden",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63866",
    city: "Marston",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63867",
    city: "Matthews",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63868",
    city: "Morehouse",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63869",
    city: "New Madrid",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63870",
    city: "Parma",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63873",
    city: "Portageville",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63874",
    city: "Risco",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63875",
    city: "Rives",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63876",
    city: "Senath",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63877",
    city: "Steele",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63878",
    city: "Tallapoosa",
    state: "MO",
    areacode: 573,
    county: "New Madrid",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63879",
    city: "Wardell",
    state: "MO",
    areacode: 573,
    county: "Pemiscot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63880",
    city: "Whiteoak",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63881",
    city: "Wolf Island",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63882",
    city: "Wyatt",
    state: "MO",
    areacode: 573,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63901",
    city: "Poplar Bluff",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63902",
    city: "Poplar Bluff",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63931",
    city: "Briar",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63932",
    city: "Broseley",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63933",
    city: "Campbell",
    state: "MO",
    areacode: 573,
    county: "Dunklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63934",
    city: "Clubb",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63935",
    city: "Doniphan",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63936",
    city: "Dudley",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63937",
    city: "Ellsinore",
    state: "MO",
    areacode: 573,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63939",
    city: "Fairdealing",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63940",
    city: "Fisk",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63941",
    city: "Fremont",
    state: "MO",
    areacode: 573,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63942",
    city: "Gatewood",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63943",
    city: "Grandin",
    state: "MO",
    areacode: 573,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63944",
    city: "Greenville",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63945",
    city: "Harviell",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63950",
    city: "Lodi",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63951",
    city: "Lowndes",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63952",
    city: "Mill Spring",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63953",
    city: "Naylor",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63954",
    city: "Neelyville",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63955",
    city: "Oxly",
    state: "MO",
    areacode: 573,
    county: "Ripley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63956",
    city: "Patterson",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63957",
    city: "Piedmont",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63960",
    city: "Puxico",
    state: "MO",
    areacode: 573,
    county: "Stoddard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63961",
    city: "Qulin",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63962",
    city: "Rombauer",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63964",
    city: "Silva",
    state: "MO",
    areacode: 573,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63965",
    city: "Van Buren",
    state: "MO",
    areacode: 573,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63966",
    city: "Wappapello",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "63967",
    city: "Williamsville",
    state: "MO",
    areacode: 573,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64001",
    city: "Alma",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64002",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64011",
    city: "Bates City",
    state: "MO",
    areacode: 816,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64012",
    city: "Belton",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64013",
    city: "Blue Springs",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64014",
    city: "Blue Springs",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64015",
    city: "Blue Springs",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64016",
    city: "Buckner",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64017",
    city: "Camden",
    state: "MO",
    areacode: 816,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64018",
    city: "Camden Point",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64019",
    city: "Centerview",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64020",
    city: "Concordia",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64021",
    city: "Corder",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64022",
    city: "Dover",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64024",
    city: "Excelsior Springs",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64028",
    city: "Farley",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64029",
    city: "Grain Valley",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64030",
    city: "Grandview",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64034",
    city: "Greenwood",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64035",
    city: "Hardin",
    state: "MO",
    areacode: 660,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64036",
    city: "Henrietta",
    state: "MO",
    areacode: 816,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64037",
    city: "Higginsville",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64040",
    city: "Holden",
    state: "MO",
    areacode: 816,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64048",
    city: "Holt",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64050",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64051",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64052",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64053",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64054",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64055",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64056",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64057",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64058",
    city: "Independence",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64060",
    city: "Kearney",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64061",
    city: "Kingsville",
    state: "MO",
    areacode: 816,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64062",
    city: "Lawson",
    state: "MO",
    areacode: 816,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64063",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64064",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64065",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64066",
    city: "Levasy",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64067",
    city: "Lexington",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64068",
    city: "Liberty",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64069",
    city: "Liberty",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64070",
    city: "Lone Jack",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64071",
    city: "Mayview",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64072",
    city: "Missouri City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64073",
    city: "Mosby",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64074",
    city: "Napoleon",
    state: "MO",
    areacode: 816,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64075",
    city: "Oak Grove",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64076",
    city: "Odessa",
    state: "MO",
    areacode: 816,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64077",
    city: "Orrick",
    state: "MO",
    areacode: 816,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64078",
    city: "Peculiar",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64079",
    city: "Platte City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64080",
    city: "Pleasant Hill",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64081",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64082",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64083",
    city: "Raymore",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64084",
    city: "Rayville",
    state: "MO",
    areacode: 660,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64085",
    city: "Richmond",
    state: "MO",
    areacode: 816,
    county: "Ray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64086",
    city: "Lees Summit",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64088",
    city: "Sibley",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64089",
    city: "Smithville",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64090",
    city: "Strasburg",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64092",
    city: "Waldron",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64093",
    city: "Warrensburg",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64096",
    city: "Waverly",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64097",
    city: "Wellington",
    state: "MO",
    areacode: 816,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64098",
    city: "Weston",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64101",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64102",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64105",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64106",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64108",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64109",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64110",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64111",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64112",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64113",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64114",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64116",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64117",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64118",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64119",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64120",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64121",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64123",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64124",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64125",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64126",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64127",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64128",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64129",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64130",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64131",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64132",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64133",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64134",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64136",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64137",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64138",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64139",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64141",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64144",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64145",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64146",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64147",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64148",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64149",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64150",
    city: "Riverside",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64151",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64152",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64153",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64154",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64155",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64156",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64157",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64158",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64161",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64162",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64163",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64164",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64165",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64166",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64167",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64168",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64170",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64171",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64179",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64180",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64184",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64187",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64188",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64190",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64191",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64195",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64196",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64197",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64198",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64199",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64401",
    city: "Agency",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64402",
    city: "Albany",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64420",
    city: "Allendale",
    state: "MO",
    areacode: 660,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64421",
    city: "Amazonia",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64422",
    city: "Amity",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64423",
    city: "Barnard",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64424",
    city: "Bethany",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64426",
    city: "Blythedale",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64427",
    city: "Bolckow",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64428",
    city: "Burlington Junction",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64429",
    city: "Cameron",
    state: "MO",
    areacode: 816,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64430",
    city: "Clarksdale",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64431",
    city: "Clearmont",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64432",
    city: "Clyde",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64433",
    city: "Conception",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64434",
    city: "Conception Junction",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64436",
    city: "Cosby",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64437",
    city: "Craig",
    state: "MO",
    areacode: 660,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64438",
    city: "Darlington",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64439",
    city: "Dearborn",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64440",
    city: "DeKalb",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64441",
    city: "Denver",
    state: "MO",
    areacode: 660,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64442",
    city: "Eagleville",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64443",
    city: "Easton",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64444",
    city: "Edgerton",
    state: "MO",
    areacode: 816,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64445",
    city: "Elmo",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64446",
    city: "Fairfax",
    state: "MO",
    areacode: 660,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64448",
    city: "Faucett",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64449",
    city: "Fillmore",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64451",
    city: "Forest City",
    state: "MO",
    areacode: 660,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64453",
    city: "Gentry",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64454",
    city: "Gower",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64455",
    city: "Graham",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64456",
    city: "Grant City",
    state: "MO",
    areacode: 660,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64457",
    city: "Guilford",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64458",
    city: "Hatfield",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64459",
    city: "Helena",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64461",
    city: "Hopkins",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64463",
    city: "King City",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64465",
    city: "Lathrop",
    state: "MO",
    areacode: 816,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64466",
    city: "Maitland",
    state: "MO",
    areacode: 660,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64467",
    city: "Martinsville",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64468",
    city: "Maryville",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64469",
    city: "Maysville",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64470",
    city: "Mound City",
    state: "MO",
    areacode: 660,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64471",
    city: "New Hampton",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64473",
    city: "Oregon",
    state: "MO",
    areacode: 660,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64474",
    city: "Osborn",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64475",
    city: "Parnell",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64476",
    city: "Pickering",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64477",
    city: "Plattsburg",
    state: "MO",
    areacode: 816,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64479",
    city: "Ravenwood",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64480",
    city: "Rea",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64481",
    city: "Ridgeway",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64482",
    city: "Rock Port",
    state: "MO",
    areacode: 660,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64483",
    city: "Rosendale",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64484",
    city: "Rushville",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64485",
    city: "Savannah",
    state: "MO",
    areacode: 816,
    county: "Andrew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64486",
    city: "Sheridan",
    state: "MO",
    areacode: 660,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64487",
    city: "Skidmore",
    state: "MO",
    areacode: 660,
    county: "Nodaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64489",
    city: "Stanberry",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64490",
    city: "Stewartsville",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64491",
    city: "Tarkio",
    state: "MO",
    areacode: 660,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64492",
    city: "Trimble",
    state: "MO",
    areacode: 816,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64493",
    city: "Turney",
    state: "MO",
    areacode: 816,
    county: "Clinton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64494",
    city: "Union Star",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64496",
    city: "Watson",
    state: "MO",
    areacode: 660,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64497",
    city: "Weatherby",
    state: "MO",
    areacode: 816,
    county: "DeKalb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64498",
    city: "Westboro",
    state: "MO",
    areacode: 660,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64499",
    city: "Worth",
    state: "MO",
    areacode: 660,
    county: "Worth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64501",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64502",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64503",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64504",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64505",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64506",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64507",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64508",
    city: "Saint Joseph",
    state: "MO",
    areacode: 816,
    county: "Buchanan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64601",
    city: "Chillicothe",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64620",
    city: "Altamont",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64622",
    city: "Bogard",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64623",
    city: "Bosworth",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64624",
    city: "Braymer",
    state: "MO",
    areacode: 816,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64625",
    city: "Breckenridge",
    state: "MO",
    areacode: 660,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64628",
    city: "Brookfield",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64630",
    city: "Browning",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64631",
    city: "Bucklin",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64632",
    city: "Cainsville",
    state: "MO",
    areacode: 660,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64633",
    city: "Carrollton",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64635",
    city: "Chula",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64636",
    city: "Coffey",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64637",
    city: "Cowgill",
    state: "MO",
    areacode: 660,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64638",
    city: "Dawn",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64639",
    city: "De Witt",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64640",
    city: "Gallatin",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64641",
    city: "Galt",
    state: "MO",
    areacode: 660,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64642",
    city: "Gilman City",
    state: "MO",
    areacode: 660,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64643",
    city: "Hale",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64644",
    city: "Hamilton",
    state: "MO",
    areacode: 816,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64645",
    city: "Harris",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64646",
    city: "Humphreys",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64647",
    city: "Jameson",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64648",
    city: "Jamesport",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64649",
    city: "Kidder",
    state: "MO",
    areacode: 816,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64650",
    city: "Kingston",
    state: "MO",
    areacode: 816,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64651",
    city: "Laclede",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64652",
    city: "Laredo",
    state: "MO",
    areacode: 660,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64653",
    city: "Linneus",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64654",
    city: "Lock Springs",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64655",
    city: "Lucerne",
    state: "MO",
    areacode: 660,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64656",
    city: "Ludlow",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64657",
    city: "McFall",
    state: "MO",
    areacode: 660,
    county: "Gentry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64658",
    city: "Marceline",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64659",
    city: "Meadville",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64660",
    city: "Mendon",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64661",
    city: "Mercer",
    state: "MO",
    areacode: 660,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64664",
    city: "Mooresville",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64667",
    city: "Newtown",
    state: "MO",
    areacode: 660,
    county: "Sullivan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64668",
    city: "Norborne",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64670",
    city: "Pattonsburg",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64671",
    city: "Polo",
    state: "MO",
    areacode: 660,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64672",
    city: "Powersville",
    state: "MO",
    areacode: 660,
    county: "Putnam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64673",
    city: "Princeton",
    state: "MO",
    areacode: 660,
    county: "Mercer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64674",
    city: "Purdin",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64676",
    city: "Rothville",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64679",
    city: "Spickard",
    state: "MO",
    areacode: 660,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64680",
    city: "Stet",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64681",
    city: "Sumner",
    state: "MO",
    areacode: 660,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64682",
    city: "Tina",
    state: "MO",
    areacode: 660,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64683",
    city: "Trenton",
    state: "MO",
    areacode: 660,
    county: "Grundy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64686",
    city: "Utica",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64688",
    city: "Wheeling",
    state: "MO",
    areacode: 660,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64689",
    city: "Winston",
    state: "MO",
    areacode: 660,
    county: "Daviess",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64701",
    city: "Harrisonville",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64720",
    city: "Adrian",
    state: "MO",
    areacode: 816,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64722",
    city: "Amoret",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64723",
    city: "Amsterdam",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64724",
    city: "Appleton City",
    state: "MO",
    areacode: 660,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64725",
    city: "Archie",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64726",
    city: "Blairstown",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64728",
    city: "Bronaugh",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64730",
    city: "Butler",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64733",
    city: "Chilhowee",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64734",
    city: "Cleveland",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64735",
    city: "Clinton",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64738",
    city: "Collins",
    state: "MO",
    areacode: 417,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64739",
    city: "Creighton",
    state: "MO",
    areacode: 816,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64740",
    city: "Deepwater",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64741",
    city: "Deerfield",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64742",
    city: "Drexel",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64743",
    city: "East Lynne",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64744",
    city: "El Dorado Springs",
    state: "MO",
    areacode: 417,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64745",
    city: "Foster",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64746",
    city: "Freeman",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64747",
    city: "Garden City",
    state: "MO",
    areacode: 816,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64748",
    city: "Golden City",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64750",
    city: "Harwood",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64752",
    city: "Hume",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64755",
    city: "Jasper",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64756",
    city: "Jerico Springs",
    state: "MO",
    areacode: 417,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64759",
    city: "Lamar",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64761",
    city: "Leeton",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64762",
    city: "Liberal",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64763",
    city: "Lowry City",
    state: "MO",
    areacode: 417,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64765",
    city: "Metz",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64766",
    city: "Milford",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64767",
    city: "Milo",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64769",
    city: "Mindenmines",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64770",
    city: "Montrose",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64771",
    city: "Moundville",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64772",
    city: "Nevada",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64776",
    city: "Osceola",
    state: "MO",
    areacode: 417,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64778",
    city: "Richards",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64779",
    city: "Rich Hill",
    state: "MO",
    areacode: 417,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64780",
    city: "Rockville",
    state: "MO",
    areacode: 660,
    county: "Bates",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64781",
    city: "Roscoe",
    state: "MO",
    areacode: 417,
    county: "Saint Clair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64783",
    city: "Schell City",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64784",
    city: "Sheldon",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64788",
    city: "Urich",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64790",
    city: "Walker",
    state: "MO",
    areacode: 417,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64801",
    city: "Joplin",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64802",
    city: "Joplin",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64803",
    city: "Joplin",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64804",
    city: "Joplin",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64830",
    city: "Alba",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64831",
    city: "Anderson",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64832",
    city: "Asbury",
    state: "MO",
    areacode: 417,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64833",
    city: "Avilla",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64834",
    city: "Carl Junction",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64835",
    city: "Carterville",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64836",
    city: "Carthage",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64840",
    city: "Diamond",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64841",
    city: "Duenweg",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64842",
    city: "Fairview",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64843",
    city: "Goodman",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64844",
    city: "Granby",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64847",
    city: "Lanagan",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64848",
    city: "La Russell",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64849",
    city: "Neck City",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64850",
    city: "Neosho",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64853",
    city: "Newtonia",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64854",
    city: "Noel",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64855",
    city: "Oronogo",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64856",
    city: "Pineville",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64857",
    city: "Purcell",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64858",
    city: "Racine",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64859",
    city: "Reeds",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64861",
    city: "Rocky Comfort",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64862",
    city: "Sarcoxie",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64863",
    city: "South West City",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64864",
    city: "Saginaw",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64865",
    city: "Seneca",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64866",
    city: "Stark City",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64867",
    city: "Stella",
    state: "MO",
    areacode: 417,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64868",
    city: "Tiff City",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64870",
    city: "Webb City",
    state: "MO",
    areacode: 417,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64873",
    city: "Wentworth",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64874",
    city: "Wheaton",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "64999",
    city: "Kansas City",
    state: "MO",
    areacode: 816,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65001",
    city: "Argyle",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65010",
    city: "Ashland",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65011",
    city: "Barnett",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65013",
    city: "Belle",
    state: "MO",
    areacode: 573,
    county: "Maries",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65014",
    city: "Bland",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65016",
    city: "Bonnots Mill",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65017",
    city: "Brumley",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65018",
    city: "California",
    state: "MO",
    areacode: 573,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65020",
    city: "Camdenton",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65023",
    city: "Centertown",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65024",
    city: "Chamois",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65025",
    city: "Clarksburg",
    state: "MO",
    areacode: 573,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65026",
    city: "Eldon",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65032",
    city: "Eugene",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65034",
    city: "Fortuna",
    state: "MO",
    areacode: 660,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65035",
    city: "Freeburg",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65036",
    city: "Gasconade",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65037",
    city: "Gravois Mills",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65038",
    city: "Laurie",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65039",
    city: "Hartsburg",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65040",
    city: "Henley",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65041",
    city: "Hermann",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65042",
    city: "High Point",
    state: "MO",
    areacode: 660,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65043",
    city: "Holts Summit",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65046",
    city: "Jamestown",
    state: "MO",
    areacode: 660,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65047",
    city: "Kaiser",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65048",
    city: "Koeltztown",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65049",
    city: "Lake Ozark",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65050",
    city: "Latham",
    state: "MO",
    areacode: 660,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65051",
    city: "Linn",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65052",
    city: "Linn Creek",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65053",
    city: "Lohman",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65054",
    city: "Loose Creek",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65055",
    city: "McGirk",
    state: "MO",
    areacode: 573,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65058",
    city: "Meta",
    state: "MO",
    areacode: 573,
    county: "Maries",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65059",
    city: "Mokane",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65061",
    city: "Morrison",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65062",
    city: "Mount Sterling",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65063",
    city: "New Bloomfield",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65064",
    city: "Olean",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65065",
    city: "Osage Beach",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65066",
    city: "Owensville",
    state: "MO",
    areacode: 573,
    county: "Gasconade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65067",
    city: "Portland",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65068",
    city: "Prairie Home",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65069",
    city: "Rhineland",
    state: "MO",
    areacode: 573,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65072",
    city: "Rocky Mount",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65074",
    city: "Russellville",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65075",
    city: "Saint Elizabeth",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65076",
    city: "Saint Thomas",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65077",
    city: "Steedman",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65078",
    city: "Stover",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65079",
    city: "Sunrise Beach",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65080",
    city: "Tebbetts",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65081",
    city: "Tipton",
    state: "MO",
    areacode: 660,
    county: "Moniteau",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65082",
    city: "Tuscumbia",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65083",
    city: "Ulman",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65084",
    city: "Versailles",
    state: "MO",
    areacode: 573,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65085",
    city: "Westphalia",
    state: "MO",
    areacode: 573,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65101",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65102",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65103",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65104",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65105",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65106",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65107",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65108",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65109",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65110",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65111",
    city: "Jefferson City",
    state: "MO",
    areacode: 573,
    county: "Cole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65201",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65202",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65203",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65205",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65211",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65212",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65215",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65216",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65217",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65218",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65230",
    city: "Armstrong",
    state: "MO",
    areacode: 660,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65231",
    city: "Auxvasse",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65232",
    city: "Benton City",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65233",
    city: "Boonville",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65236",
    city: "Brunswick",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65237",
    city: "Bunceton",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65239",
    city: "Cairo",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65240",
    city: "Centralia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65243",
    city: "Clark",
    state: "MO",
    areacode: 573,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65244",
    city: "Clifton Hill",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65246",
    city: "Dalton",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65247",
    city: "Excello",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65248",
    city: "Fayette",
    state: "MO",
    areacode: 660,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65250",
    city: "Franklin",
    state: "MO",
    areacode: 660,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65251",
    city: "Fulton",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65254",
    city: "Glasgow",
    state: "MO",
    areacode: 660,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65255",
    city: "Hallsville",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65256",
    city: "Harrisburg",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65257",
    city: "Higbee",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65258",
    city: "Holliday",
    state: "MO",
    areacode: 660,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65259",
    city: "Huntsville",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65260",
    city: "Jacksonville",
    state: "MO",
    areacode: 660,
    county: "Macon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65261",
    city: "Keytesville",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65262",
    city: "Kingdom City",
    state: "MO",
    areacode: 573,
    county: "Callaway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65263",
    city: "Madison",
    state: "MO",
    areacode: 660,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65264",
    city: "Martinsburg",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65265",
    city: "Mexico",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65270",
    city: "Moberly",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65274",
    city: "New Franklin",
    state: "MO",
    areacode: 660,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65275",
    city: "Paris",
    state: "MO",
    areacode: 660,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65276",
    city: "Pilot Grove",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65278",
    city: "Renick",
    state: "MO",
    areacode: 660,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65279",
    city: "Rocheport",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65280",
    city: "Rush Hill",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65281",
    city: "Salisbury",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65282",
    city: "Santa Fe",
    state: "MO",
    areacode: 573,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65283",
    city: "Stoutsville",
    state: "MO",
    areacode: 573,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65284",
    city: "Sturgeon",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65285",
    city: "Thompson",
    state: "MO",
    areacode: 573,
    county: "Audrain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65286",
    city: "Triplett",
    state: "MO",
    areacode: 660,
    county: "Chariton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65287",
    city: "Wooldridge",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65299",
    city: "Columbia",
    state: "MO",
    areacode: 573,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65301",
    city: "Sedalia",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65302",
    city: "Sedalia",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65305",
    city: "Whiteman AFB",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65320",
    city: "Arrow Rock",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65321",
    city: "Blackburn",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65322",
    city: "Blackwater",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65323",
    city: "Calhoun",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65324",
    city: "Climax Springs",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65325",
    city: "Cole Camp",
    state: "MO",
    areacode: 660,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65326",
    city: "Edwards",
    state: "MO",
    areacode: 660,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65327",
    city: "Emma",
    state: "MO",
    areacode: 660,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65329",
    city: "Florence",
    state: "MO",
    areacode: 660,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65330",
    city: "Gilliam",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65332",
    city: "Green Ridge",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65333",
    city: "Houstonia",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65334",
    city: "Hughesville",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65335",
    city: "Ionia",
    state: "MO",
    areacode: 660,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65336",
    city: "Knob Noster",
    state: "MO",
    areacode: 660,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65337",
    city: "La Monte",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65338",
    city: "Lincoln",
    state: "MO",
    areacode: 660,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65339",
    city: "Malta Bend",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65340",
    city: "Marshall",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65344",
    city: "Miami",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65345",
    city: "Mora",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65347",
    city: "Nelson",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65348",
    city: "Otterville",
    state: "MO",
    areacode: 660,
    county: "Cooper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65349",
    city: "Slater",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65350",
    city: "Smithton",
    state: "MO",
    areacode: 660,
    county: "Pettis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65351",
    city: "Sweet Springs",
    state: "MO",
    areacode: 660,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65354",
    city: "Syracuse",
    state: "MO",
    areacode: 660,
    county: "Morgan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65355",
    city: "Warsaw",
    state: "MO",
    areacode: 660,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65360",
    city: "Windsor",
    state: "MO",
    areacode: 660,
    county: "Henry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65401",
    city: "Rolla",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65402",
    city: "Rolla",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65409",
    city: "Rolla",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65436",
    city: "Beulah",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65438",
    city: "Birch Tree",
    state: "MO",
    areacode: 573,
    county: "Shannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65439",
    city: "Bixby",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65440",
    city: "Boss",
    state: "MO",
    areacode: 573,
    county: "Dent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65441",
    city: "Bourbon",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65443",
    city: "Brinktown",
    state: "MO",
    areacode: 573,
    county: "Maries",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65444",
    city: "Bucyrus",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65446",
    city: "Cherryville",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65449",
    city: "Cook Station",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65452",
    city: "Crocker",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65453",
    city: "Cuba",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65456",
    city: "Davisville",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65457",
    city: "Devils Elbow",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65459",
    city: "Dixon",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65461",
    city: "Duke",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65462",
    city: "Edgar Springs",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65463",
    city: "Eldridge",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65464",
    city: "Elk Creek",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65466",
    city: "Eminence",
    state: "MO",
    areacode: 573,
    county: "Shannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65468",
    city: "Eunice",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65470",
    city: "Falcon",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65473",
    city: "Fort Leonard Wood",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65479",
    city: "Hartshorn",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65483",
    city: "Houston",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65484",
    city: "Huggins",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65486",
    city: "Iberia",
    state: "MO",
    areacode: 573,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65501",
    city: "Jadwin",
    state: "MO",
    areacode: 573,
    county: "Dent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65529",
    city: "Jerome",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65532",
    city: "Lake Spring",
    state: "MO",
    areacode: 573,
    county: "Dent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65534",
    city: "Laquey",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65535",
    city: "Leasburg",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65536",
    city: "Lebanon",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65541",
    city: "Lenox",
    state: "MO",
    areacode: 573,
    county: "Dent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65542",
    city: "Licking",
    state: "MO",
    areacode: 573,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65543",
    city: "Lynchburg",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65546",
    city: "Montier",
    state: "MO",
    areacode: 573,
    county: "Shannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65548",
    city: "Mountain View",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65550",
    city: "Newburg",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65552",
    city: "Plato",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65555",
    city: "Raymondville",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65556",
    city: "Richland",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65557",
    city: "Roby",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65559",
    city: "Saint James",
    state: "MO",
    areacode: 573,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65560",
    city: "Salem",
    state: "MO",
    areacode: 573,
    county: "Dent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65564",
    city: "Solo",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65565",
    city: "Steelville",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65566",
    city: "Viburnum",
    state: "MO",
    areacode: 573,
    county: "Iron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65567",
    city: "Stoutland",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65570",
    city: "Success",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65571",
    city: "Summersville",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65580",
    city: "Vichy",
    state: "MO",
    areacode: 573,
    county: "Maries",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65582",
    city: "Vienna",
    state: "MO",
    areacode: 573,
    county: "Maries",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65583",
    city: "Waynesville",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65584",
    city: "Saint Robert",
    state: "MO",
    areacode: 573,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65586",
    city: "Wesco",
    state: "MO",
    areacode: 573,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65588",
    city: "Winona",
    state: "MO",
    areacode: 573,
    county: "Shannon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65589",
    city: "Yukon",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65590",
    city: "Long Lane",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65591",
    city: "Montreal",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65601",
    city: "Aldrich",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65603",
    city: "Arcola",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65604",
    city: "Ash Grove",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65605",
    city: "Aurora",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65606",
    city: "Alton",
    state: "MO",
    areacode: 417,
    county: "Oregon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65607",
    city: "Caplinger Mills",
    state: "MO",
    areacode: 417,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65608",
    city: "Ava",
    state: "MO",
    areacode: 417,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65609",
    city: "Bakersfield",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65610",
    city: "Billings",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65611",
    city: "Blue Eye",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65612",
    city: "Bois D Arc",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65613",
    city: "Bolivar",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65614",
    city: "Bradleyville",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65615",
    city: "Branson",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65616",
    city: "Branson",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65617",
    city: "Brighton",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65618",
    city: "Brixey",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65619",
    city: "Brookline",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65620",
    city: "Bruner",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65622",
    city: "Buffalo",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65623",
    city: "Butterfield",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65624",
    city: "Cape Fair",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65625",
    city: "Cassville",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65626",
    city: "Caulfield",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65627",
    city: "Cedarcreek",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65629",
    city: "Chadwick",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65630",
    city: "Chestnutridge",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65631",
    city: "Clever",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65632",
    city: "Conway",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65633",
    city: "Crane",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65634",
    city: "Cross Timbers",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65635",
    city: "Dadeville",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65636",
    city: "Diggins",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65637",
    city: "Dora",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65638",
    city: "Drury",
    state: "MO",
    areacode: 417,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65640",
    city: "Dunnegan",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65641",
    city: "Eagle Rock",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65644",
    city: "Elkland",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65645",
    city: "Morrisville",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65646",
    city: "Everton",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65647",
    city: "Exeter",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65648",
    city: "Fair Grove",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65649",
    city: "Fair Play",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65650",
    city: "Flemington",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65652",
    city: "Fordland",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65653",
    city: "Forsyth",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65654",
    city: "Freistatt",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65655",
    city: "Gainesville",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65656",
    city: "Galena",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65657",
    city: "Garrison",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65658",
    city: "Golden",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65660",
    city: "Graff",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65661",
    city: "Greenfield",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65662",
    city: "Grovespring",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65663",
    city: "Half Way",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65664",
    city: "Halltown",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65666",
    city: "Hardenville",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65667",
    city: "Hartville",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65668",
    city: "Hermitage",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65669",
    city: "Highlandville",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65672",
    city: "Hollister",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65673",
    city: "Hollister",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65674",
    city: "Humansville",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65675",
    city: "Hurley",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65676",
    city: "Isabella",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65679",
    city: "Kirbyville",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65680",
    city: "Kissee Mills",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65681",
    city: "Lampe",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65682",
    city: "Lockwood",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65685",
    city: "Louisburg",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65686",
    city: "Kimberling City",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65688",
    city: "Brandsville",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65689",
    city: "Cabool",
    state: "MO",
    areacode: 417,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65690",
    city: "Couch",
    state: "MO",
    areacode: 417,
    county: "Oregon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65692",
    city: "Koshkonong",
    state: "MO",
    areacode: 417,
    county: "Oregon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65702",
    city: "Macomb",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65704",
    city: "Mansfield",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65705",
    city: "Marionville",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65706",
    city: "Marshfield",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65707",
    city: "Miller",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65708",
    city: "Monett",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65710",
    city: "Morrisville",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65711",
    city: "Mountain Grove",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65712",
    city: "Mount Vernon",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65713",
    city: "Niangua",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65714",
    city: "Nixa",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65715",
    city: "Noble",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65717",
    city: "Norwood",
    state: "MO",
    areacode: 417,
    county: "Wright",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65720",
    city: "Oldfield",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65721",
    city: "Ozark",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65722",
    city: "Phillipsburg",
    state: "MO",
    areacode: 417,
    county: "Laclede",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65723",
    city: "Pierce City",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65724",
    city: "Pittsburg",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65725",
    city: "Pleasant Hope",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65726",
    city: "Point Lookout",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65727",
    city: "Polk",
    state: "MO",
    areacode: 417,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65728",
    city: "Ponce de Leon",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65729",
    city: "Pontiac",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65730",
    city: "Powell",
    state: "MO",
    areacode: 417,
    county: "McDonald",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65731",
    city: "Powersite",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65732",
    city: "Preston",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65733",
    city: "Protem",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65734",
    city: "Purdy",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65735",
    city: "Quincy",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65737",
    city: "Reeds Spring",
    state: "MO",
    areacode: 417,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65738",
    city: "Republic",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65739",
    city: "Ridgedale",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65740",
    city: "Rockaway Beach",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65741",
    city: "Rockbridge",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65742",
    city: "Rogersville",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65744",
    city: "Rueter",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65745",
    city: "Seligman",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65746",
    city: "Seymour",
    state: "MO",
    areacode: 417,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65747",
    city: "Shell Knob",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65752",
    city: "South Greenfield",
    state: "MO",
    areacode: 417,
    county: "Dade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65753",
    city: "Sparta",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65754",
    city: "Spokane",
    state: "MO",
    areacode: 417,
    county: "Christian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65755",
    city: "Squires",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65756",
    city: "Stotts City",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65757",
    city: "Strafford",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65759",
    city: "Taneyville",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65760",
    city: "Tecumseh",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65761",
    city: "Theodosia",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65762",
    city: "Thornfield",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65764",
    city: "Tunas",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65765",
    city: "Turners",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65766",
    city: "Udall",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65767",
    city: "Urbana",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65768",
    city: "Vanzant",
    state: "MO",
    areacode: 417,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65769",
    city: "Verona",
    state: "MO",
    areacode: 417,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65770",
    city: "Walnut Grove",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65771",
    city: "Walnut Shade",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65772",
    city: "Washburn",
    state: "MO",
    areacode: 417,
    county: "Barry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65773",
    city: "Wasola",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65774",
    city: "Weaubleau",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65775",
    city: "West Plains",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65777",
    city: "Moody",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65778",
    city: "Myrtle",
    state: "MO",
    areacode: 417,
    county: "Oregon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65779",
    city: "Wheatland",
    state: "MO",
    areacode: 417,
    county: "Hickory",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65781",
    city: "Willard",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65783",
    city: "Windyville",
    state: "MO",
    areacode: 417,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65784",
    city: "Zanoni",
    state: "MO",
    areacode: 417,
    county: "Ozark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65785",
    city: "Stockton",
    state: "MO",
    areacode: 417,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65786",
    city: "Macks Creek",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65787",
    city: "Roach",
    state: "MO",
    areacode: 573,
    county: "Camden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65788",
    city: "Peace Valley",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65789",
    city: "Pomona",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65790",
    city: "Pottersville",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65791",
    city: "Thayer",
    state: "MO",
    areacode: 417,
    county: "Oregon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65793",
    city: "Willow Springs",
    state: "MO",
    areacode: 417,
    county: "Howell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65801",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65802",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65803",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65804",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65805",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65806",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65807",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65808",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65809",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65810",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65814",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65817",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65890",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65897",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65898",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "65899",
    city: "Springfield",
    state: "MO",
    areacode: 417,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66002",
    city: "Atchison",
    state: "KS",
    areacode: 913,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66006",
    city: "Baldwin City",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66007",
    city: "Basehor",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66008",
    city: "Bendena",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66010",
    city: "Blue Mound",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66012",
    city: "Bonner Springs",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66013",
    city: "Bucyrus",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66014",
    city: "Centerville",
    state: "KS",
    areacode: 913,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66015",
    city: "Colony",
    state: "KS",
    areacode: 620,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66016",
    city: "Cummings",
    state: "KS",
    areacode: 913,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66017",
    city: "Denton",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66018",
    city: "De Soto",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66020",
    city: "Easton",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66021",
    city: "Edgerton",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66023",
    city: "Effingham",
    state: "KS",
    areacode: 913,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66024",
    city: "Elwood",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66025",
    city: "Eudora",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66026",
    city: "Fontana",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66027",
    city: "Fort Leavenworth",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66030",
    city: "Gardner",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66031",
    city: "New Century",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66032",
    city: "Garnett",
    state: "KS",
    areacode: 785,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66033",
    city: "Greeley",
    state: "KS",
    areacode: 785,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66035",
    city: "Highland",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66036",
    city: "Hillsdale",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66039",
    city: "Kincaid",
    state: "KS",
    areacode: 620,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66040",
    city: "La Cygne",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66041",
    city: "Lancaster",
    state: "KS",
    areacode: 913,
    county: "Atchison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66042",
    city: "Lane",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66043",
    city: "Lansing",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66044",
    city: "Lawrence",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66045",
    city: "Lawrence",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66046",
    city: "Lawrence",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66047",
    city: "Lawrence",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66048",
    city: "Leavenworth",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66049",
    city: "Lawrence",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66050",
    city: "Lecompton",
    state: "KS",
    areacode: 785,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66051",
    city: "Olathe",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66052",
    city: "Linwood",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66053",
    city: "Louisburg",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66054",
    city: "McLouth",
    state: "KS",
    areacode: 913,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66056",
    city: "Mound City",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66058",
    city: "Muscotah",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66060",
    city: "Nortonville",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66061",
    city: "Olathe",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66062",
    city: "Olathe",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66063",
    city: "Olathe",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66064",
    city: "Osawatomie",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66066",
    city: "Oskaloosa",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66067",
    city: "Ottawa",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66070",
    city: "Ozawkie",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66071",
    city: "Paola",
    state: "KS",
    areacode: 913,
    county: "Miami",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66072",
    city: "Parker",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66073",
    city: "Perry",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66075",
    city: "Pleasanton",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66076",
    city: "Pomona",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66078",
    city: "Princeton",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66079",
    city: "Rantoul",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66080",
    city: "Richmond",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66083",
    city: "Spring Hill",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66085",
    city: "Stilwell",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66086",
    city: "Tonganoxie",
    state: "KS",
    areacode: 913,
    county: "Leavenworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66087",
    city: "Troy",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66088",
    city: "Valley Falls",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66090",
    city: "Wathena",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66091",
    city: "Welda",
    state: "KS",
    areacode: 785,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66092",
    city: "Wellsville",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66093",
    city: "Westphalia",
    state: "KS",
    areacode: 785,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66094",
    city: "White Cloud",
    state: "KS",
    areacode: 785,
    county: "Doniphan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66095",
    city: "Williamsburg",
    state: "KS",
    areacode: 785,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66097",
    city: "Winchester",
    state: "KS",
    areacode: 913,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66101",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66102",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66103",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66104",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66105",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66106",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66109",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66110",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66111",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66112",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66113",
    city: "Edwardsville",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66115",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66117",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66118",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66119",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66160",
    city: "Kansas City",
    state: "KS",
    areacode: 913,
    county: "Wyandotte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66201",
    city: "Mission",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66202",
    city: "Mission",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66203",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66204",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66205",
    city: "Mission",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66206",
    city: "Leawood",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66207",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66208",
    city: "Prairie Village",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66209",
    city: "Leawood",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66210",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66211",
    city: "Leawood",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66212",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66213",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66214",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66215",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66216",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66217",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66218",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66219",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66220",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66221",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66222",
    city: "Mission",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66223",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66224",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66225",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66226",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66227",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66250",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66251",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66276",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66282",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66283",
    city: "Overland Park",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66285",
    city: "Lenexa",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66286",
    city: "Shawnee",
    state: "KS",
    areacode: 913,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66401",
    city: "Alma",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66402",
    city: "Auburn",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66403",
    city: "Axtell",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66404",
    city: "Baileyville",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66406",
    city: "Beattie",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66407",
    city: "Belvue",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66408",
    city: "Bern",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66409",
    city: "Berryton",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66411",
    city: "Blue Rapids",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66412",
    city: "Bremen",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66413",
    city: "Burlingame",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66414",
    city: "Carbondale",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66415",
    city: "Centralia",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66416",
    city: "Circleville",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66417",
    city: "Corning",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66418",
    city: "Delia",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66419",
    city: "Denison",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66420",
    city: "Dover",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66422",
    city: "Emmett",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66423",
    city: "Eskridge",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66424",
    city: "Everest",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66425",
    city: "Fairview",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66426",
    city: "Fostoria",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66427",
    city: "Frankfort",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66428",
    city: "Goff",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66429",
    city: "Grantville",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66431",
    city: "Harveyville",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66432",
    city: "Havensville",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66434",
    city: "Hiawatha",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66436",
    city: "Holton",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66438",
    city: "Home",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66439",
    city: "Horton",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66440",
    city: "Hoyt",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66441",
    city: "Junction City",
    state: "KS",
    areacode: 785,
    county: "Geary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66442",
    city: "Fort Riley",
    state: "KS",
    areacode: 785,
    county: "Geary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66449",
    city: "Leonardville",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66451",
    city: "Lyndon",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66501",
    city: "McFarland",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66502",
    city: "Manhattan",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66503",
    city: "Manhattan",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66505",
    city: "Manhattan",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66506",
    city: "Manhattan",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66507",
    city: "Maple Hill",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66508",
    city: "Marysville",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66509",
    city: "Mayetta",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66510",
    city: "Melvern",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66512",
    city: "Meriden",
    state: "KS",
    areacode: 785,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66514",
    city: "Milford",
    state: "KS",
    areacode: 785,
    county: "Geary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66515",
    city: "Morrill",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66516",
    city: "Netawaka",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66517",
    city: "Ogden",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66518",
    city: "Oketo",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66520",
    city: "Olsburg",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66521",
    city: "Onaga",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66522",
    city: "Oneida",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66523",
    city: "Osage City",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66524",
    city: "Overbrook",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66526",
    city: "Paxico",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66527",
    city: "Powhattan",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66528",
    city: "Quenemo",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66531",
    city: "Riley",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66532",
    city: "Robinson",
    state: "KS",
    areacode: 785,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66533",
    city: "Rossville",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66534",
    city: "Sabetha",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66535",
    city: "Saint George",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66536",
    city: "Saint Marys",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66537",
    city: "Scranton",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66538",
    city: "Seneca",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66539",
    city: "Silver Lake",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66540",
    city: "Soldier",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66541",
    city: "Summerfield",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66542",
    city: "Tecumseh",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66543",
    city: "Vassar",
    state: "KS",
    areacode: 785,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66544",
    city: "Vermillion",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66546",
    city: "Wakarusa",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66547",
    city: "Wamego",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66548",
    city: "Waterville",
    state: "KS",
    areacode: 785,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66549",
    city: "Westmoreland",
    state: "KS",
    areacode: 785,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66550",
    city: "Wetmore",
    state: "KS",
    areacode: 785,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66552",
    city: "Whiting",
    state: "KS",
    areacode: 785,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66554",
    city: "Randolph",
    state: "KS",
    areacode: 785,
    county: "Riley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66601",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66603",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66604",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66605",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66606",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66607",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66608",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66609",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66610",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66611",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66612",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66614",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66615",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66616",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66617",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66618",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66619",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66620",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66621",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66622",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66624",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66625",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66626",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66629",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66630",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66636",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66647",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66667",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66675",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66683",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66699",
    city: "Topeka",
    state: "KS",
    areacode: 785,
    county: "Shawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66701",
    city: "Fort Scott",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66710",
    city: "Altoona",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66711",
    city: "Arcadia",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66712",
    city: "Arma",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66713",
    city: "Baxter Springs",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66714",
    city: "Benedict",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66716",
    city: "Bronson",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66717",
    city: "Buffalo",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66720",
    city: "Chanute",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66724",
    city: "Cherokee",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66725",
    city: "Columbus",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66728",
    city: "Crestline",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66732",
    city: "Elsmore",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66733",
    city: "Erie",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66734",
    city: "Farlington",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66735",
    city: "Franklin",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66736",
    city: "Fredonia",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66738",
    city: "Fulton",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66739",
    city: "Galena",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66740",
    city: "Galesburg",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66741",
    city: "Garland",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66742",
    city: "Gas",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66743",
    city: "Girard",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66746",
    city: "Hepler",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66748",
    city: "Humboldt",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66749",
    city: "Iola",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66751",
    city: "La Harpe",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66753",
    city: "McCune",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66754",
    city: "Mapleton",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66755",
    city: "Moran",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66756",
    city: "Mulberry",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66757",
    city: "Neodesha",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66758",
    city: "Neosho Falls",
    state: "KS",
    areacode: 620,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66760",
    city: "Opolis",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66761",
    city: "Piqua",
    state: "KS",
    areacode: 620,
    county: "Woodson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66762",
    city: "Pittsburg",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66763",
    city: "Frontenac",
    state: "KS",
    areacode: 620,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66767",
    city: "Prescott",
    state: "KS",
    areacode: 913,
    county: "Linn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66769",
    city: "Redfield",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66770",
    city: "Riverton",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66771",
    city: "Saint Paul",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66772",
    city: "Savonburg",
    state: "KS",
    areacode: 620,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66773",
    city: "Scammon",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66775",
    city: "Stark",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66776",
    city: "Thayer",
    state: "KS",
    areacode: 620,
    county: "Neosho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66777",
    city: "Toronto",
    state: "KS",
    areacode: 620,
    county: "Woodson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66778",
    city: "Treece",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66779",
    city: "Uniontown",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66780",
    city: "Walnut",
    state: "KS",
    areacode: 620,
    county: "Bourbon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66781",
    city: "Weir",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66782",
    city: "West Mineral",
    state: "KS",
    areacode: 620,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66783",
    city: "Yates Center",
    state: "KS",
    areacode: 620,
    county: "Woodson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66801",
    city: "Emporia",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66830",
    city: "Admire",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66833",
    city: "Allen",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66834",
    city: "Alta Vista",
    state: "KS",
    areacode: 785,
    county: "Wabaunsee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66835",
    city: "Americus",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66838",
    city: "Burdick",
    state: "KS",
    areacode: 785,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66839",
    city: "Burlington",
    state: "KS",
    areacode: 620,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66840",
    city: "Burns",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66842",
    city: "Cassoday",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66843",
    city: "Cedar Point",
    state: "KS",
    areacode: 620,
    county: "Chase",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66845",
    city: "Cottonwood Falls",
    state: "KS",
    areacode: 620,
    county: "Chase",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66846",
    city: "Council Grove",
    state: "KS",
    areacode: 620,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66849",
    city: "Dwight",
    state: "KS",
    areacode: 785,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66850",
    city: "Elmdale",
    state: "KS",
    areacode: 620,
    county: "Chase",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66851",
    city: "Florence",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66852",
    city: "Gridley",
    state: "KS",
    areacode: 620,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66853",
    city: "Hamilton",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66854",
    city: "Hartford",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66855",
    city: "Lamont",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66856",
    city: "Lebo",
    state: "KS",
    areacode: 620,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66857",
    city: "Le Roy",
    state: "KS",
    areacode: 620,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66858",
    city: "Lincolnville",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66859",
    city: "Lost Springs",
    state: "KS",
    areacode: 785,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66860",
    city: "Madison",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66861",
    city: "Marion",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66862",
    city: "Matfield Green",
    state: "KS",
    areacode: 620,
    county: "Chase",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66863",
    city: "Neal",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66864",
    city: "Neosho Rapids",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66865",
    city: "Olpe",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66866",
    city: "Peabody",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66868",
    city: "Reading",
    state: "KS",
    areacode: 620,
    county: "Lyon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66869",
    city: "Strong City",
    state: "KS",
    areacode: 620,
    county: "Chase",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66870",
    city: "Virgil",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66871",
    city: "Waverly",
    state: "KS",
    areacode: 785,
    county: "Coffey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66872",
    city: "White City",
    state: "KS",
    areacode: 785,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66873",
    city: "Wilsey",
    state: "KS",
    areacode: 785,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66901",
    city: "Concordia",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66930",
    city: "Agenda",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66932",
    city: "Athol",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66933",
    city: "Barnes",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66935",
    city: "Belleville",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66936",
    city: "Burr Oak",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66937",
    city: "Clifton",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66938",
    city: "Clyde",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66939",
    city: "Courtland",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66940",
    city: "Cuba",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66941",
    city: "Esbon",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66942",
    city: "Formoso",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66943",
    city: "Greenleaf",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66944",
    city: "Haddam",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66945",
    city: "Hanover",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66946",
    city: "Hollenberg",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66948",
    city: "Jamestown",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66949",
    city: "Jewell",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66951",
    city: "Kensington",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66952",
    city: "Lebanon",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66953",
    city: "Linn",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66955",
    city: "Mahaska",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66956",
    city: "Mankato",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66958",
    city: "Morrowville",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66959",
    city: "Munden",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66960",
    city: "Narka",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66961",
    city: "Norway",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66962",
    city: "Palmer",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66963",
    city: "Randall",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66964",
    city: "Republic",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66966",
    city: "Scandia",
    state: "KS",
    areacode: 785,
    county: "Republic",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66967",
    city: "Smith Center",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66968",
    city: "Washington",
    state: "KS",
    areacode: 785,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "66970",
    city: "Webber",
    state: "KS",
    areacode: 785,
    county: "Jewell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67001",
    city: "Andale",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67002",
    city: "Andover",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67003",
    city: "Anthony",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67004",
    city: "Argonia",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67005",
    city: "Arkansas City",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67008",
    city: "Atlanta",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67009",
    city: "Attica",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67010",
    city: "Augusta",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67012",
    city: "Beaumont",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67013",
    city: "Belle Plaine",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67016",
    city: "Bentley",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67017",
    city: "Benton",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67018",
    city: "Bluff City",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67019",
    city: "Burden",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67020",
    city: "Burrton",
    state: "KS",
    areacode: 620,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67021",
    city: "Byers",
    state: "KS",
    areacode: 620,
    county: "Pratt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67022",
    city: "Caldwell",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67023",
    city: "Cambridge",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67024",
    city: "Cedar Vale",
    state: "KS",
    areacode: 620,
    county: "Chautauqua",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67025",
    city: "Cheney",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67026",
    city: "Clearwater",
    state: "KS",
    areacode: 620,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67028",
    city: "Coats",
    state: "KS",
    areacode: 620,
    county: "Pratt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67029",
    city: "Coldwater",
    state: "KS",
    areacode: 620,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67030",
    city: "Colwich",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67031",
    city: "Conway Springs",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67035",
    city: "Cunningham",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67036",
    city: "Danville",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67037",
    city: "Derby",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67038",
    city: "Dexter",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67039",
    city: "Douglass",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67041",
    city: "Elbing",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67042",
    city: "El Dorado",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67045",
    city: "Eureka",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67047",
    city: "Fall River",
    state: "KS",
    areacode: 620,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67049",
    city: "Freeport",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67050",
    city: "Garden Plain",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67051",
    city: "Geuda Springs",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67052",
    city: "Goddard",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67053",
    city: "Goessel",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67054",
    city: "Greensburg",
    state: "KS",
    areacode: 620,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67055",
    city: "Greenwich",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67056",
    city: "Halstead",
    state: "KS",
    areacode: 316,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67057",
    city: "Hardtner",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67058",
    city: "Harper",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67059",
    city: "Haviland",
    state: "KS",
    areacode: 620,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67060",
    city: "Haysville",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67061",
    city: "Hazelton",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67062",
    city: "Hesston",
    state: "KS",
    areacode: 620,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67063",
    city: "Hillsboro",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67065",
    city: "Isabel",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67066",
    city: "Iuka",
    state: "KS",
    areacode: 620,
    county: "Pratt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67067",
    city: "Kechi",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67068",
    city: "Kingman",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67070",
    city: "Kiowa",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67071",
    city: "Lake City",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67072",
    city: "Latham",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67073",
    city: "Lehigh",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67074",
    city: "Leon",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67101",
    city: "Maize",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67102",
    city: "Maple City",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67103",
    city: "Mayfield",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67104",
    city: "Medicine Lodge",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67105",
    city: "Milan",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67106",
    city: "Milton",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67107",
    city: "Moundridge",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67108",
    city: "Mount Hope",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67109",
    city: "Mullinville",
    state: "KS",
    areacode: 620,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67110",
    city: "Mulvane",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67111",
    city: "Murdock",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67112",
    city: "Nashville",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67114",
    city: "Newton",
    state: "KS",
    areacode: 316,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67117",
    city: "North Newton",
    state: "KS",
    areacode: 316,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67118",
    city: "Norwich",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67119",
    city: "Oxford",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67120",
    city: "Peck",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67122",
    city: "Piedmont",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67123",
    city: "Potwin",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67124",
    city: "Pratt",
    state: "KS",
    areacode: 620,
    county: "Pratt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67127",
    city: "Protection",
    state: "KS",
    areacode: 620,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67131",
    city: "Rock",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67132",
    city: "Rosalia",
    state: "KS",
    areacode: 620,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67133",
    city: "Rose Hill",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67134",
    city: "Sawyer",
    state: "KS",
    areacode: 620,
    county: "Pratt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67135",
    city: "Sedgwick",
    state: "KS",
    areacode: 316,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67137",
    city: "Severy",
    state: "KS",
    areacode: 620,
    county: "Greenwood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67138",
    city: "Sharon",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67140",
    city: "South Haven",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67142",
    city: "Spivey",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67143",
    city: "Sun City",
    state: "KS",
    areacode: 620,
    county: "Barber",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67144",
    city: "Towanda",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67146",
    city: "Udall",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67147",
    city: "Valley Center",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67149",
    city: "Viola",
    state: "KS",
    areacode: 620,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67150",
    city: "Waldron",
    state: "KS",
    areacode: 620,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67151",
    city: "Walton",
    state: "KS",
    areacode: 620,
    county: "Harvey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67152",
    city: "Wellington",
    state: "KS",
    areacode: 620,
    county: "Sumner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67154",
    city: "Whitewater",
    state: "KS",
    areacode: 316,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67155",
    city: "Wilmore",
    state: "KS",
    areacode: 620,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67156",
    city: "Winfield",
    state: "KS",
    areacode: 620,
    county: "Cowley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67159",
    city: "Zenda",
    state: "KS",
    areacode: 620,
    county: "Kingman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67201",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67202",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67203",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67204",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67205",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67206",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67207",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67208",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67209",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67210",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67211",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67212",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67213",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67214",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67215",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67216",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67217",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67218",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67219",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67220",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67221",
    city: "McConnell AFB",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67223",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67226",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67227",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67228",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67230",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67232",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67235",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67260",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67275",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67276",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67277",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67278",
    city: "Wichita",
    state: "KS",
    areacode: 316,
    county: "Sedgwick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67301",
    city: "Independence",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67330",
    city: "Altamont",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67332",
    city: "Bartlett",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67333",
    city: "Caney",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67334",
    city: "Chautauqua",
    state: "KS",
    areacode: 620,
    county: "Chautauqua",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67335",
    city: "Cherryvale",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67336",
    city: "Chetopa",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67337",
    city: "Coffeyville",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67340",
    city: "Dearing",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67341",
    city: "Dennis",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67342",
    city: "Edna",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67344",
    city: "Elk City",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67345",
    city: "Elk Falls",
    state: "KS",
    areacode: 620,
    county: "Elk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67346",
    city: "Grenola",
    state: "KS",
    areacode: 620,
    county: "Elk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67347",
    city: "Havana",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67349",
    city: "Howard",
    state: "KS",
    areacode: 620,
    county: "Elk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67351",
    city: "Liberty",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67352",
    city: "Longton",
    state: "KS",
    areacode: 620,
    county: "Elk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67353",
    city: "Moline",
    state: "KS",
    areacode: 620,
    county: "Elk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67354",
    city: "Mound Valley",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67355",
    city: "Niotaze",
    state: "KS",
    areacode: 620,
    county: "Chautauqua",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67356",
    city: "Oswego",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67357",
    city: "Parsons",
    state: "KS",
    areacode: 620,
    county: "Labette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67360",
    city: "Peru",
    state: "KS",
    areacode: 620,
    county: "Chautauqua",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67361",
    city: "Sedan",
    state: "KS",
    areacode: 620,
    county: "Chautauqua",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67363",
    city: "Sycamore",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67364",
    city: "Tyro",
    state: "KS",
    areacode: 620,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67401",
    city: "Salina",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67402",
    city: "Salina",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67410",
    city: "Abilene",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67416",
    city: "Assaria",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67417",
    city: "Aurora",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67418",
    city: "Barnard",
    state: "KS",
    areacode: 785,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67420",
    city: "Beloit",
    state: "KS",
    areacode: 785,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67422",
    city: "Bennington",
    state: "KS",
    areacode: 785,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67423",
    city: "Beverly",
    state: "KS",
    areacode: 785,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67425",
    city: "Brookville",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67427",
    city: "Bushton",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67428",
    city: "Canton",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67430",
    city: "Cawker City",
    state: "KS",
    areacode: 785,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67431",
    city: "Chapman",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67432",
    city: "Clay Center",
    state: "KS",
    areacode: 785,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67436",
    city: "Delphos",
    state: "KS",
    areacode: 785,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67437",
    city: "Downs",
    state: "KS",
    areacode: 785,
    county: "Osborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67438",
    city: "Durham",
    state: "KS",
    areacode: 620,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67439",
    city: "Ellsworth",
    state: "KS",
    areacode: 785,
    county: "Ellsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67441",
    city: "Enterprise",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67442",
    city: "Falun",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67443",
    city: "Galva",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67444",
    city: "Geneseo",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67445",
    city: "Glasco",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67446",
    city: "Glen Elder",
    state: "KS",
    areacode: 785,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67447",
    city: "Green",
    state: "KS",
    areacode: 785,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67448",
    city: "Gypsum",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67449",
    city: "Herington",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67450",
    city: "Holyrood",
    state: "KS",
    areacode: 785,
    county: "Ellsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67451",
    city: "Hope",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67452",
    city: "Hunter",
    state: "KS",
    areacode: 785,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67454",
    city: "Kanopolis",
    state: "KS",
    areacode: 785,
    county: "Ellsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67455",
    city: "Lincoln",
    state: "KS",
    areacode: 785,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67456",
    city: "Lindsborg",
    state: "KS",
    areacode: 785,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67457",
    city: "Little River",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67458",
    city: "Longford",
    state: "KS",
    areacode: 785,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67459",
    city: "Lorraine",
    state: "KS",
    areacode: 785,
    county: "Ellsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67460",
    city: "McPherson",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67464",
    city: "Marquette",
    state: "KS",
    areacode: 785,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67466",
    city: "Miltonvale",
    state: "KS",
    areacode: 785,
    county: "Cloud",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67467",
    city: "Minneapolis",
    state: "KS",
    areacode: 785,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67468",
    city: "Morganville",
    state: "KS",
    areacode: 785,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67470",
    city: "New Cambria",
    state: "KS",
    areacode: 785,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67473",
    city: "Osborne",
    state: "KS",
    areacode: 785,
    county: "Osborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67474",
    city: "Portis",
    state: "KS",
    areacode: 785,
    county: "Osborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67475",
    city: "Ramona",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67476",
    city: "Roxbury",
    state: "KS",
    areacode: 785,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67478",
    city: "Simpson",
    state: "KS",
    areacode: 785,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67480",
    city: "Solomon",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67481",
    city: "Sylvan Grove",
    state: "KS",
    areacode: 785,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67482",
    city: "Talmage",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67483",
    city: "Tampa",
    state: "KS",
    areacode: 785,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67484",
    city: "Tescott",
    state: "KS",
    areacode: 785,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67485",
    city: "Tipton",
    state: "KS",
    areacode: 785,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67487",
    city: "Wakefield",
    state: "KS",
    areacode: 785,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67490",
    city: "Wilson",
    state: "KS",
    areacode: 785,
    county: "Ellsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67491",
    city: "Windom",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67492",
    city: "Woodbine",
    state: "KS",
    areacode: 785,
    county: "Dickinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67501",
    city: "Hutchinson",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67502",
    city: "Hutchinson",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67504",
    city: "Hutchinson",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67505",
    city: "South Hutchinson",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67510",
    city: "Abbyville",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67511",
    city: "Albert",
    state: "KS",
    areacode: 620,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67512",
    city: "Alden",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67513",
    city: "Alexander",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67514",
    city: "Arlington",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67515",
    city: "Arnold",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67516",
    city: "Bazine",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67518",
    city: "Beeler",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67519",
    city: "Belpre",
    state: "KS",
    areacode: 620,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67520",
    city: "Bison",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67521",
    city: "Brownell",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67522",
    city: "Buhler",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67523",
    city: "Burdett",
    state: "KS",
    areacode: 620,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67524",
    city: "Chase",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67525",
    city: "Claflin",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67526",
    city: "Ellinwood",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67529",
    city: "Garfield",
    state: "KS",
    areacode: 620,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67530",
    city: "Great Bend",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67543",
    city: "Haven",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67544",
    city: "Hoisington",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67545",
    city: "Hudson",
    state: "KS",
    areacode: 620,
    county: "Stafford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67546",
    city: "Inman",
    state: "KS",
    areacode: 620,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67547",
    city: "Kinsley",
    state: "KS",
    areacode: 620,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67548",
    city: "La Crosse",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67550",
    city: "Larned",
    state: "KS",
    areacode: 620,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67552",
    city: "Lewis",
    state: "KS",
    areacode: 620,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67553",
    city: "Liebenthal",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67554",
    city: "Lyons",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67556",
    city: "McCracken",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67557",
    city: "Macksville",
    state: "KS",
    areacode: 620,
    county: "Stafford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67559",
    city: "Nekoma",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67560",
    city: "Ness City",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67561",
    city: "Nickerson",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67563",
    city: "Offerle",
    state: "KS",
    areacode: 620,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67564",
    city: "Olmitz",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67565",
    city: "Otis",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67566",
    city: "Partridge",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67567",
    city: "Pawnee Rock",
    state: "KS",
    areacode: 620,
    county: "Barton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67568",
    city: "Plevna",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67570",
    city: "Pretty Prairie",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67572",
    city: "Ransom",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67573",
    city: "Raymond",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67574",
    city: "Rozel",
    state: "KS",
    areacode: 620,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67575",
    city: "Rush Center",
    state: "KS",
    areacode: 785,
    county: "Rush",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67576",
    city: "Saint John",
    state: "KS",
    areacode: 620,
    county: "Stafford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67578",
    city: "Stafford",
    state: "KS",
    areacode: 620,
    county: "Stafford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67579",
    city: "Sterling",
    state: "KS",
    areacode: 620,
    county: "Rice",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67581",
    city: "Sylvia",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67583",
    city: "Turon",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67584",
    city: "Utica",
    state: "KS",
    areacode: 785,
    county: "Ness",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67585",
    city: "Yoder",
    state: "KS",
    areacode: 620,
    county: "Reno",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67601",
    city: "Hays",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67621",
    city: "Agra",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67622",
    city: "Almena",
    state: "KS",
    areacode: 785,
    county: "Norton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67623",
    city: "Alton",
    state: "KS",
    areacode: 785,
    county: "Osborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67625",
    city: "Bogue",
    state: "KS",
    areacode: 785,
    county: "Graham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67626",
    city: "Bunker Hill",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67627",
    city: "Catharine",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67628",
    city: "Cedar",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67629",
    city: "Clayton",
    state: "KS",
    areacode: 785,
    county: "Norton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67631",
    city: "Collyer",
    state: "KS",
    areacode: 785,
    county: "Trego",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67632",
    city: "Damar",
    state: "KS",
    areacode: 785,
    county: "Rooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67634",
    city: "Dorrance",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67635",
    city: "Dresden",
    state: "KS",
    areacode: 785,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67637",
    city: "Ellis",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67638",
    city: "Gaylord",
    state: "KS",
    areacode: 785,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67639",
    city: "Glade",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67640",
    city: "Gorham",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67642",
    city: "Hill City",
    state: "KS",
    areacode: 785,
    county: "Graham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67643",
    city: "Jennings",
    state: "KS",
    areacode: 785,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67644",
    city: "Kirwin",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67645",
    city: "Lenora",
    state: "KS",
    areacode: 785,
    county: "Norton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67646",
    city: "Logan",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67647",
    city: "Long Island",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67648",
    city: "Lucas",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67649",
    city: "Luray",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67650",
    city: "Morland",
    state: "KS",
    areacode: 785,
    county: "Graham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67651",
    city: "Natoma",
    state: "KS",
    areacode: 785,
    county: "Osborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67653",
    city: "Norcatur",
    state: "KS",
    areacode: 785,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67654",
    city: "Norton",
    state: "KS",
    areacode: 785,
    county: "Norton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67656",
    city: "Ogallah",
    state: "KS",
    areacode: 785,
    county: "Trego",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67657",
    city: "Palco",
    state: "KS",
    areacode: 785,
    county: "Rooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67658",
    city: "Paradise",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67659",
    city: "Penokee",
    state: "KS",
    areacode: 785,
    county: "Graham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67660",
    city: "Pfeifer",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67661",
    city: "Phillipsburg",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67663",
    city: "Plainville",
    state: "KS",
    areacode: 785,
    county: "Rooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67664",
    city: "Prairie View",
    state: "KS",
    areacode: 785,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67665",
    city: "Russell",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67667",
    city: "Schoenchen",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67669",
    city: "Stockton",
    state: "KS",
    areacode: 785,
    county: "Rooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67671",
    city: "Victoria",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67672",
    city: "WaKeeney",
    state: "KS",
    areacode: 785,
    county: "Trego",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67673",
    city: "Waldo",
    state: "KS",
    areacode: 785,
    county: "Russell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67674",
    city: "Walker",
    state: "KS",
    areacode: 785,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67675",
    city: "Woodston",
    state: "KS",
    areacode: 785,
    county: "Rooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67701",
    city: "Colby",
    state: "KS",
    areacode: 785,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67730",
    city: "Atwood",
    state: "KS",
    areacode: 785,
    county: "Rawlins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67731",
    city: "Bird City",
    state: "KS",
    areacode: 785,
    county: "Cheyenne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67732",
    city: "Brewster",
    state: "KS",
    areacode: 785,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67733",
    city: "Edson",
    state: "KS",
    areacode: 785,
    county: "Sherman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67734",
    city: "Gem",
    state: "KS",
    areacode: 785,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67735",
    city: "Goodland",
    state: "KS",
    areacode: 785,
    county: "Sherman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67736",
    city: "Gove",
    state: "KS",
    areacode: 785,
    county: "Gove",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67737",
    city: "Grainfield",
    state: "KS",
    areacode: 785,
    county: "Gove",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67738",
    city: "Grinnell",
    state: "KS",
    areacode: 785,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67739",
    city: "Herndon",
    state: "KS",
    areacode: 785,
    county: "Rawlins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67740",
    city: "Hoxie",
    state: "KS",
    areacode: 785,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67741",
    city: "Kanorado",
    state: "KS",
    areacode: 785,
    county: "Sherman",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67743",
    city: "Levant",
    state: "KS",
    areacode: 785,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67744",
    city: "Ludell",
    state: "KS",
    areacode: 785,
    county: "Rawlins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67745",
    city: "McDonald",
    state: "KS",
    areacode: 785,
    county: "Rawlins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67747",
    city: "Monument",
    state: "KS",
    areacode: 785,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67748",
    city: "Oakley",
    state: "KS",
    areacode: 785,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67749",
    city: "Oberlin",
    state: "KS",
    areacode: 785,
    county: "Decatur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67751",
    city: "Park",
    state: "KS",
    areacode: 785,
    county: "Gove",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67752",
    city: "Quinter",
    state: "KS",
    areacode: 785,
    county: "Gove",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67753",
    city: "Rexford",
    state: "KS",
    areacode: 785,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67756",
    city: "Saint Francis",
    state: "KS",
    areacode: 785,
    county: "Cheyenne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67757",
    city: "Selden",
    state: "KS",
    areacode: 785,
    county: "Sheridan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67758",
    city: "Sharon Springs",
    state: "KS",
    areacode: 785,
    county: "Wallace",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67761",
    city: "Wallace",
    state: "KS",
    areacode: 785,
    county: "Wallace",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67762",
    city: "Weskan",
    state: "KS",
    areacode: 785,
    county: "Wallace",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67764",
    city: "Winona",
    state: "KS",
    areacode: 785,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67801",
    city: "Dodge City",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67831",
    city: "Ashland",
    state: "KS",
    areacode: 620,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67834",
    city: "Bucklin",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67835",
    city: "Cimarron",
    state: "KS",
    areacode: 620,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67836",
    city: "Coolidge",
    state: "KS",
    areacode: 620,
    county: "Hamilton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67837",
    city: "Copeland",
    state: "KS",
    areacode: 620,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67838",
    city: "Deerfield",
    state: "KS",
    areacode: 620,
    county: "Kearny",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67839",
    city: "Dighton",
    state: "KS",
    areacode: 620,
    county: "Lane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67840",
    city: "Englewood",
    state: "KS",
    areacode: 620,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67841",
    city: "Ensign",
    state: "KS",
    areacode: 620,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67842",
    city: "Ford",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67843",
    city: "Fort Dodge",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67844",
    city: "Fowler",
    state: "KS",
    areacode: 620,
    county: "Meade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67846",
    city: "Garden City",
    state: "KS",
    areacode: 620,
    county: "Finney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67849",
    city: "Hanston",
    state: "KS",
    areacode: 620,
    county: "Hodgeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67850",
    city: "Healy",
    state: "KS",
    areacode: 620,
    county: "Lane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67851",
    city: "Holcomb",
    state: "KS",
    areacode: 620,
    county: "Finney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67853",
    city: "Ingalls",
    state: "KS",
    areacode: 620,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67854",
    city: "Jetmore",
    state: "KS",
    areacode: 620,
    county: "Hodgeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67855",
    city: "Johnson",
    state: "KS",
    areacode: 620,
    county: "Stanton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67857",
    city: "Kendall",
    state: "KS",
    areacode: 620,
    county: "Hamilton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67859",
    city: "Kismet",
    state: "KS",
    areacode: 620,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67860",
    city: "Lakin",
    state: "KS",
    areacode: 620,
    county: "Kearny",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67861",
    city: "Leoti",
    state: "KS",
    areacode: 620,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67862",
    city: "Manter",
    state: "KS",
    areacode: 620,
    county: "Stanton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67863",
    city: "Marienthal",
    state: "KS",
    areacode: 620,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67864",
    city: "Meade",
    state: "KS",
    areacode: 620,
    county: "Meade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67865",
    city: "Minneola",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67867",
    city: "Montezuma",
    state: "KS",
    areacode: 620,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67868",
    city: "Pierceville",
    state: "KS",
    areacode: 620,
    county: "Finney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67869",
    city: "Plains",
    state: "KS",
    areacode: 620,
    county: "Meade",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67870",
    city: "Satanta",
    state: "KS",
    areacode: 620,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67871",
    city: "Scott City",
    state: "KS",
    areacode: 620,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67876",
    city: "Spearville",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67877",
    city: "Sublette",
    state: "KS",
    areacode: 620,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67878",
    city: "Syracuse",
    state: "KS",
    areacode: 620,
    county: "Hamilton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67879",
    city: "Tribune",
    state: "KS",
    areacode: 620,
    county: "Greeley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "67880",
    city: "Ulysses",
    state: "KS",
    areacode: 620,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67882",
    city: "Wright",
    state: "KS",
    areacode: 620,
    county: "Ford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67901",
    city: "Liberal",
    state: "KS",
    areacode: 620,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67905",
    city: "Liberal",
    state: "KS",
    areacode: 620,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67950",
    city: "Elkhart",
    state: "KS",
    areacode: 620,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67951",
    city: "Hugoton",
    state: "KS",
    areacode: 620,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67952",
    city: "Moscow",
    state: "KS",
    areacode: 620,
    county: "Stevens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67953",
    city: "Richfield",
    state: "KS",
    areacode: 620,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "67954",
    city: "Rolla",
    state: "KS",
    areacode: 620,
    county: "Morton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68001",
    city: "Abie",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68002",
    city: "Arlington",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68003",
    city: "Ashland",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68004",
    city: "Bancroft",
    state: "NE",
    areacode: 402,
    county: "Cuming",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68005",
    city: "Bellevue",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68007",
    city: "Bennington",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68008",
    city: "Blair",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68010",
    city: "Boys Town",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68014",
    city: "Bruno",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68015",
    city: "Cedar Bluffs",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68016",
    city: "Cedar Creek",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68017",
    city: "Ceresco",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68018",
    city: "Colon",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68019",
    city: "Craig",
    state: "NE",
    areacode: 402,
    county: "Burt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68020",
    city: "Decatur",
    state: "NE",
    areacode: 402,
    county: "Burt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68022",
    city: "Elkhorn",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68023",
    city: "Fort Calhoun",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68025",
    city: "Fremont",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68026",
    city: "Fremont",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68028",
    city: "Gretna",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68029",
    city: "Herman",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68030",
    city: "Homer",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68031",
    city: "Hooper",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68033",
    city: "Ithaca",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68034",
    city: "Kennard",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68036",
    city: "Linwood",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68037",
    city: "Louisville",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68038",
    city: "Lyons",
    state: "NE",
    areacode: 402,
    county: "Burt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68039",
    city: "Macy",
    state: "NE",
    areacode: 402,
    county: "Thurston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68040",
    city: "Malmo",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68041",
    city: "Mead",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68042",
    city: "Memphis",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68044",
    city: "Nickerson",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68045",
    city: "Oakland",
    state: "NE",
    areacode: 402,
    county: "Burt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68046",
    city: "Papillion",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68047",
    city: "Pender",
    state: "NE",
    areacode: 402,
    county: "Thurston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68048",
    city: "Plattsmouth",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68050",
    city: "Prague",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68055",
    city: "Rosalie",
    state: "NE",
    areacode: 402,
    county: "Cuming",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68056",
    city: "Saint Columbans",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68057",
    city: "Scribner",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68058",
    city: "South Bend",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68059",
    city: "Springfield",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68061",
    city: "Tekamah",
    state: "NE",
    areacode: 402,
    county: "Burt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68062",
    city: "Thurston",
    state: "NE",
    areacode: 402,
    county: "Thurston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68063",
    city: "Uehling",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68064",
    city: "Valley",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68065",
    city: "Valparaiso",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68066",
    city: "Wahoo",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68067",
    city: "Walthill",
    state: "NE",
    areacode: 402,
    county: "Thurston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68068",
    city: "Washington",
    state: "NE",
    areacode: 402,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68069",
    city: "Waterloo",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68070",
    city: "Weston",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68071",
    city: "Winnebago",
    state: "NE",
    areacode: 402,
    county: "Thurston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68072",
    city: "Winslow",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68073",
    city: "Yutan",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68101",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68102",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68103",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68104",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68105",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68106",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68107",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68108",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68109",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68110",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68111",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68112",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68113",
    city: "Offutt AFB",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68114",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68116",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68117",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68118",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68119",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68122",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68123",
    city: "Bellevue",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68124",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68127",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68128",
    city: "La Vista",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68130",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68131",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68132",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68133",
    city: "Papillion",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68134",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68135",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68136",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68137",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68138",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68139",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68142",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68144",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68145",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68147",
    city: "Bellevue",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68152",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68154",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68157",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Sarpy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68164",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68172",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68175",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68176",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68178",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68179",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68180",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68182",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68183",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68197",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68198",
    city: "Omaha",
    state: "NE",
    areacode: 402,
    county: "Douglas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68301",
    city: "Adams",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68303",
    city: "Alexandria",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68304",
    city: "Alvo",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68305",
    city: "Auburn",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68307",
    city: "Avoca",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68309",
    city: "Barneston",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68310",
    city: "Beatrice",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68313",
    city: "Beaver Crossing",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68314",
    city: "Bee",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68315",
    city: "Belvidere",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68316",
    city: "Benedict",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68317",
    city: "Bennet",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68318",
    city: "Blue Springs",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68319",
    city: "Bradshaw",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68320",
    city: "Brock",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68321",
    city: "Brownville",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68322",
    city: "Bruning",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68323",
    city: "Burchard",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68324",
    city: "Burr",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68325",
    city: "Byron",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68326",
    city: "Carleton",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68327",
    city: "Chester",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68328",
    city: "Clatonia",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68329",
    city: "Cook",
    state: "NE",
    areacode: 402,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68330",
    city: "Cordova",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68331",
    city: "Cortland",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68332",
    city: "Crab Orchard",
    state: "NE",
    areacode: 402,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68333",
    city: "Crete",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68335",
    city: "Davenport",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68336",
    city: "Davey",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68337",
    city: "Dawson",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68338",
    city: "Daykin",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68339",
    city: "Denton",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68340",
    city: "Deshler",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68341",
    city: "DeWitt",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68342",
    city: "Diller",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68343",
    city: "Dorchester",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68344",
    city: "Douglas",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68345",
    city: "Du Bois",
    state: "NE",
    areacode: 402,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68346",
    city: "Dunbar",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68347",
    city: "Eagle",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68348",
    city: "Elk Creek",
    state: "NE",
    areacode: 402,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68349",
    city: "Elmwood",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68350",
    city: "Endicott",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68351",
    city: "Exeter",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68352",
    city: "Fairbury",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68354",
    city: "Fairmont",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68355",
    city: "Falls City",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68357",
    city: "Filley",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68358",
    city: "Firth",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68359",
    city: "Friend",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68360",
    city: "Garland",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68361",
    city: "Geneva",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68362",
    city: "Gilead",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68364",
    city: "Goehner",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68365",
    city: "Grafton",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68366",
    city: "Greenwood",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68367",
    city: "Gresham",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68368",
    city: "Hallam",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68370",
    city: "Hebron",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68371",
    city: "Henderson",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68372",
    city: "Hickman",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68375",
    city: "Hubbell",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68376",
    city: "Humboldt",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68377",
    city: "Jansen",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68378",
    city: "Johnson",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68379",
    city: "Julian",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68380",
    city: "Lewiston",
    state: "NE",
    areacode: 402,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68381",
    city: "Liberty",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68382",
    city: "Lorton",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68401",
    city: "McCool Junction",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68402",
    city: "Malcolm",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68403",
    city: "Manley",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68404",
    city: "Martell",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68405",
    city: "Milford",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68406",
    city: "Milligan",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68407",
    city: "Murdock",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68409",
    city: "Murray",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68410",
    city: "Nebraska City",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68413",
    city: "Nehawka",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68414",
    city: "Nemaha",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68415",
    city: "Odell",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68416",
    city: "Ohiowa",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68417",
    city: "Otoe",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68418",
    city: "Palmyra",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68419",
    city: "Panama",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68420",
    city: "Pawnee City",
    state: "NE",
    areacode: 402,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68421",
    city: "Peru",
    state: "NE",
    areacode: 402,
    county: "Nemaha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68422",
    city: "Pickrell",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68423",
    city: "Pleasant Dale",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68424",
    city: "Plymouth",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68428",
    city: "Raymond",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68429",
    city: "Reynolds",
    state: "NE",
    areacode: 402,
    county: "Thayer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68430",
    city: "Roca",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68431",
    city: "Rulo",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68433",
    city: "Salem",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68434",
    city: "Seward",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68436",
    city: "Shickley",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68437",
    city: "Shubert",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68438",
    city: "Sprague",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68439",
    city: "Staplehurst",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68440",
    city: "Steele City",
    state: "NE",
    areacode: 402,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68441",
    city: "Steinauer",
    state: "NE",
    areacode: 402,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68442",
    city: "Stella",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68443",
    city: "Sterling",
    state: "NE",
    areacode: 402,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68444",
    city: "Strang",
    state: "NE",
    areacode: 402,
    county: "Fillmore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68445",
    city: "Swanton",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68446",
    city: "Syracuse",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68447",
    city: "Table Rock",
    state: "NE",
    areacode: 402,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68448",
    city: "Talmage",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68450",
    city: "Tecumseh",
    state: "NE",
    areacode: 402,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68452",
    city: "Ong",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68453",
    city: "Tobias",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68454",
    city: "Unadilla",
    state: "NE",
    areacode: 402,
    county: "Otoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68455",
    city: "Union",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68456",
    city: "Utica",
    state: "NE",
    areacode: 402,
    county: "Seward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68457",
    city: "Verdon",
    state: "NE",
    areacode: 402,
    county: "Richardson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68458",
    city: "Virginia",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68460",
    city: "Waco",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68461",
    city: "Walton",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68462",
    city: "Waverly",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68463",
    city: "Weeping Water",
    state: "NE",
    areacode: 402,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68464",
    city: "Western",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68465",
    city: "Wilber",
    state: "NE",
    areacode: 402,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68466",
    city: "Wymore",
    state: "NE",
    areacode: 402,
    county: "Gage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68467",
    city: "York",
    state: "NE",
    areacode: 402,
    county: "York",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68501",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68502",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68503",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68504",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68505",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68506",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68507",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68508",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68509",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68510",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68512",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68514",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68516",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68517",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68520",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68521",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68522",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68523",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68524",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68526",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68527",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68528",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68529",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68531",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68532",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68542",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68544",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68583",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68588",
    city: "Lincoln",
    state: "NE",
    areacode: 402,
    county: "Lancaster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68601",
    city: "Columbus",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68602",
    city: "Columbus",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68620",
    city: "Albion",
    state: "NE",
    areacode: 402,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68621",
    city: "Ames",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68622",
    city: "Bartlett",
    state: "NE",
    areacode: 308,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68623",
    city: "Belgrade",
    state: "NE",
    areacode: 308,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68624",
    city: "Bellwood",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68626",
    city: "Brainard",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68627",
    city: "Cedar Rapids",
    state: "NE",
    areacode: 308,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68628",
    city: "Clarks",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68629",
    city: "Clarkson",
    state: "NE",
    areacode: 402,
    county: "Colfax",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68631",
    city: "Creston",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68632",
    city: "David City",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68633",
    city: "Dodge",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68634",
    city: "Duncan",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68635",
    city: "Dwight",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68636",
    city: "Elgin",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68637",
    city: "Ericson",
    state: "NE",
    areacode: 308,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68638",
    city: "Fullerton",
    state: "NE",
    areacode: 308,
    county: "Nance",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68640",
    city: "Genoa",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68641",
    city: "Howells",
    state: "NE",
    areacode: 402,
    county: "Colfax",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68642",
    city: "Humphrey",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68643",
    city: "Leigh",
    state: "NE",
    areacode: 402,
    county: "Colfax",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68644",
    city: "Lindsay",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68647",
    city: "Monroe",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68648",
    city: "Morse Bluff",
    state: "NE",
    areacode: 402,
    county: "Saunders",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68649",
    city: "North Bend",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68651",
    city: "Osceola",
    state: "NE",
    areacode: 402,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68652",
    city: "Petersburg",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68653",
    city: "Platte Center",
    state: "NE",
    areacode: 402,
    county: "Platte",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68654",
    city: "Polk",
    state: "NE",
    areacode: 402,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68655",
    city: "Primrose",
    state: "NE",
    areacode: 308,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68658",
    city: "Rising City",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68659",
    city: "Rogers",
    state: "NE",
    areacode: 402,
    county: "Colfax",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68660",
    city: "Saint Edward",
    state: "NE",
    areacode: 402,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68661",
    city: "Schuyler",
    state: "NE",
    areacode: 402,
    county: "Colfax",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68662",
    city: "Shelby",
    state: "NE",
    areacode: 402,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68663",
    city: "Silver Creek",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68664",
    city: "Snyder",
    state: "NE",
    areacode: 402,
    county: "Dodge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68665",
    city: "Spalding",
    state: "NE",
    areacode: 308,
    county: "Greeley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68666",
    city: "Stromsburg",
    state: "NE",
    areacode: 402,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68667",
    city: "Surprise",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68669",
    city: "Ulysses",
    state: "NE",
    areacode: 402,
    county: "Butler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68701",
    city: "Norfolk",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68702",
    city: "Norfolk",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68710",
    city: "Allen",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68711",
    city: "Amelia",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68713",
    city: "Atkinson",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68714",
    city: "Bassett",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68715",
    city: "Battle Creek",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68716",
    city: "Beemer",
    state: "NE",
    areacode: 402,
    county: "Cuming",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68717",
    city: "Belden",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68718",
    city: "Bloomfield",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68719",
    city: "Bristow",
    state: "NE",
    areacode: 402,
    county: "Boyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68720",
    city: "Brunswick",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68722",
    city: "Butte",
    state: "NE",
    areacode: 402,
    county: "Boyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68723",
    city: "Carroll",
    state: "NE",
    areacode: 402,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68724",
    city: "Center",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68725",
    city: "Chambers",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68726",
    city: "Clearwater",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68727",
    city: "Coleridge",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68728",
    city: "Concord",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68729",
    city: "Creighton",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68730",
    city: "Crofton",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68731",
    city: "Dakota City",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68732",
    city: "Dixon",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68733",
    city: "Emerson",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68734",
    city: "Emmet",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68735",
    city: "Ewing",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68736",
    city: "Fordyce",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68738",
    city: "Hadar",
    state: "NE",
    areacode: 402,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68739",
    city: "Hartington",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68740",
    city: "Hoskins",
    state: "NE",
    areacode: 402,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68741",
    city: "Hubbard",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68742",
    city: "Inman",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68743",
    city: "Jackson",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68745",
    city: "Laurel",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68746",
    city: "Lynch",
    state: "NE",
    areacode: 402,
    county: "Boyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68747",
    city: "McLean",
    state: "NE",
    areacode: 402,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68748",
    city: "Madison",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68749",
    city: "Magnet",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68751",
    city: "Maskell",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68752",
    city: "Meadow Grove",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68753",
    city: "Mills",
    state: "NE",
    areacode: 402,
    county: "Keya Paha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68755",
    city: "Naper",
    state: "NE",
    areacode: 402,
    county: "Boyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68756",
    city: "Neligh",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68757",
    city: "Newcastle",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68758",
    city: "Newman Grove",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68759",
    city: "Newport",
    state: "NE",
    areacode: 402,
    county: "Keya Paha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68760",
    city: "Niobrara",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68761",
    city: "Oakdale",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68763",
    city: "O'Neill",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68764",
    city: "Orchard",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68765",
    city: "Osmond",
    state: "NE",
    areacode: 402,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68766",
    city: "Page",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68767",
    city: "Pierce",
    state: "NE",
    areacode: 402,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68768",
    city: "Pilger",
    state: "NE",
    areacode: 402,
    county: "Stanton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68769",
    city: "Plainview",
    state: "NE",
    areacode: 402,
    county: "Pierce",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68770",
    city: "Ponca",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68771",
    city: "Randolph",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68773",
    city: "Royal",
    state: "NE",
    areacode: 402,
    county: "Antelope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68774",
    city: "Saint Helena",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68776",
    city: "South Sioux City",
    state: "NE",
    areacode: 402,
    county: "Dakota",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68777",
    city: "Spencer",
    state: "NE",
    areacode: 402,
    county: "Boyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68778",
    city: "Springview",
    state: "NE",
    areacode: 402,
    county: "Keya Paha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68779",
    city: "Stanton",
    state: "NE",
    areacode: 402,
    county: "Stanton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68780",
    city: "Stuart",
    state: "NE",
    areacode: 402,
    county: "Holt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68781",
    city: "Tilden",
    state: "NE",
    areacode: 402,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68783",
    city: "Verdigre",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68784",
    city: "Wakefield",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68785",
    city: "Waterbury",
    state: "NE",
    areacode: 402,
    county: "Dixon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68786",
    city: "Wausa",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68787",
    city: "Wayne",
    state: "NE",
    areacode: 402,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68788",
    city: "West Point",
    state: "NE",
    areacode: 402,
    county: "Cuming",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68789",
    city: "Winnetoon",
    state: "NE",
    areacode: 402,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68790",
    city: "Winside",
    state: "NE",
    areacode: 402,
    county: "Wayne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68791",
    city: "Wisner",
    state: "NE",
    areacode: 402,
    county: "Cuming",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68792",
    city: "Wynot",
    state: "NE",
    areacode: 402,
    county: "Cedar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68801",
    city: "Grand Island",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68802",
    city: "Grand Island",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68803",
    city: "Grand Island",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68810",
    city: "Alda",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68812",
    city: "Amherst",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68813",
    city: "Anselmo",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68814",
    city: "Ansley",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68815",
    city: "Arcadia",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68816",
    city: "Archer",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68817",
    city: "Ashton",
    state: "NE",
    areacode: 308,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68818",
    city: "Aurora",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68820",
    city: "Boelus",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68821",
    city: "Brewster",
    state: "NE",
    areacode: 308,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68822",
    city: "Broken Bow",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68823",
    city: "Burwell",
    state: "NE",
    areacode: 308,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68824",
    city: "Cairo",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68825",
    city: "Callaway",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68826",
    city: "Central City",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68827",
    city: "Chapman",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68828",
    city: "Comstock",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68831",
    city: "Dannebrog",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68832",
    city: "Doniphan",
    state: "NE",
    areacode: 402,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68833",
    city: "Dunning",
    state: "NE",
    areacode: 308,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68834",
    city: "Eddyville",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68835",
    city: "Elba",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68836",
    city: "Elm Creek",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68837",
    city: "Elyria",
    state: "NE",
    areacode: 308,
    county: "Valley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68838",
    city: "Farwell",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68840",
    city: "Gibbon",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68841",
    city: "Giltner",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68842",
    city: "Greeley",
    state: "NE",
    areacode: 308,
    county: "Greeley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68843",
    city: "Hampton",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68844",
    city: "Hazard",
    state: "NE",
    areacode: 308,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68845",
    city: "Kearney",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68846",
    city: "Hordville",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68847",
    city: "Kearney",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68848",
    city: "Kearney",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68849",
    city: "Kearney",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68850",
    city: "Lexington",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68852",
    city: "Litchfield",
    state: "NE",
    areacode: 308,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68853",
    city: "Loup City",
    state: "NE",
    areacode: 308,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68854",
    city: "Marquette",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68855",
    city: "Mason City",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68856",
    city: "Merna",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68858",
    city: "Miller",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68859",
    city: "North Loup",
    state: "NE",
    areacode: 308,
    county: "Valley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68860",
    city: "Oconto",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68861",
    city: "Odessa",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68862",
    city: "Ord",
    state: "NE",
    areacode: 308,
    county: "Valley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68863",
    city: "Overton",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68864",
    city: "Palmer",
    state: "NE",
    areacode: 308,
    county: "Merrick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68865",
    city: "Phillips",
    state: "NE",
    areacode: 402,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68866",
    city: "Pleasanton",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68869",
    city: "Ravenna",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68870",
    city: "Riverdale",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68871",
    city: "Rockville",
    state: "NE",
    areacode: 308,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68872",
    city: "Saint Libory",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68873",
    city: "Saint Paul",
    state: "NE",
    areacode: 308,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68874",
    city: "Sargent",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68875",
    city: "Scotia",
    state: "NE",
    areacode: 308,
    county: "Greeley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68876",
    city: "Shelton",
    state: "NE",
    areacode: 308,
    county: "Buffalo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68878",
    city: "Sumner",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68879",
    city: "Taylor",
    state: "NE",
    areacode: 308,
    county: "Loup",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68881",
    city: "Westerville",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68882",
    city: "Wolbach",
    state: "NE",
    areacode: 308,
    county: "Greeley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68883",
    city: "Wood River",
    state: "NE",
    areacode: 308,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68901",
    city: "Hastings",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68902",
    city: "Hastings",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68920",
    city: "Alma",
    state: "NE",
    areacode: 308,
    county: "Harlan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68922",
    city: "Arapahoe",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68923",
    city: "Atlanta",
    state: "NE",
    areacode: 308,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68924",
    city: "Axtell",
    state: "NE",
    areacode: 308,
    county: "Kearney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68925",
    city: "Ayr",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68926",
    city: "Beaver City",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68927",
    city: "Bertrand",
    state: "NE",
    areacode: 308,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68928",
    city: "Bladen",
    state: "NE",
    areacode: 402,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68929",
    city: "Bloomington",
    state: "NE",
    areacode: 308,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68930",
    city: "Blue Hill",
    state: "NE",
    areacode: 402,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68932",
    city: "Campbell",
    state: "NE",
    areacode: 402,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68933",
    city: "Clay Center",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68934",
    city: "Deweese",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68935",
    city: "Edgar",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68936",
    city: "Edison",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68937",
    city: "Elwood",
    state: "NE",
    areacode: 308,
    county: "Gosper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68938",
    city: "Fairfield",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68939",
    city: "Franklin",
    state: "NE",
    areacode: 308,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68940",
    city: "Funk",
    state: "NE",
    areacode: 308,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68941",
    city: "Glenvil",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68942",
    city: "Guide Rock",
    state: "NE",
    areacode: 402,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68943",
    city: "Hardy",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68944",
    city: "Harvard",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68945",
    city: "Heartwell",
    state: "NE",
    areacode: 308,
    county: "Kearney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68946",
    city: "Hendley",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68947",
    city: "Hildreth",
    state: "NE",
    areacode: 308,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68948",
    city: "Holbrook",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68949",
    city: "Holdrege",
    state: "NE",
    areacode: 308,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68950",
    city: "Holstein",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68952",
    city: "Inavale",
    state: "NE",
    areacode: 402,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68954",
    city: "Inland",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68955",
    city: "Juniata",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68956",
    city: "Kenesaw",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68957",
    city: "Lawrence",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68958",
    city: "Loomis",
    state: "NE",
    areacode: 308,
    county: "Phelps",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68959",
    city: "Minden",
    state: "NE",
    areacode: 308,
    county: "Kearney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68960",
    city: "Naponee",
    state: "NE",
    areacode: 308,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68961",
    city: "Nelson",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68964",
    city: "Oak",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68966",
    city: "Orleans",
    state: "NE",
    areacode: 308,
    county: "Harlan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68967",
    city: "Oxford",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68969",
    city: "Ragan",
    state: "NE",
    areacode: 308,
    county: "Harlan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68970",
    city: "Red Cloud",
    state: "NE",
    areacode: 402,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68971",
    city: "Republican City",
    state: "NE",
    areacode: 308,
    county: "Harlan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68972",
    city: "Riverton",
    state: "NE",
    areacode: 402,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68973",
    city: "Roseland",
    state: "NE",
    areacode: 402,
    county: "Adams",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68974",
    city: "Ruskin",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68975",
    city: "Saronville",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68976",
    city: "Smithfield",
    state: "NE",
    areacode: 308,
    county: "Gosper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68977",
    city: "Stamford",
    state: "NE",
    areacode: 308,
    county: "Harlan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68978",
    city: "Superior",
    state: "NE",
    areacode: 402,
    county: "Nuckolls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68979",
    city: "Sutton",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68980",
    city: "Trumbull",
    state: "NE",
    areacode: 402,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68981",
    city: "Upland",
    state: "NE",
    areacode: 402,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "68982",
    city: "Wilcox",
    state: "NE",
    areacode: 308,
    county: "Kearney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69001",
    city: "McCook",
    state: "NE",
    areacode: 308,
    county: "Red Willow",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69020",
    city: "Bartley",
    state: "NE",
    areacode: 308,
    county: "Red Willow",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69021",
    city: "Benkelman",
    state: "NE",
    areacode: 308,
    county: "Dundy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69022",
    city: "Cambridge",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69023",
    city: "Champion",
    state: "NE",
    areacode: 308,
    county: "Chase",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69024",
    city: "Culbertson",
    state: "NE",
    areacode: 308,
    county: "Hitchcock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69025",
    city: "Curtis",
    state: "NE",
    areacode: 308,
    county: "Frontier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69026",
    city: "Danbury",
    state: "NE",
    areacode: 308,
    county: "Red Willow",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69027",
    city: "Enders",
    state: "NE",
    areacode: 308,
    county: "Chase",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69028",
    city: "Eustis",
    state: "NE",
    areacode: 308,
    county: "Frontier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69029",
    city: "Farnam",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69030",
    city: "Haigler",
    state: "NE",
    areacode: 308,
    county: "Dundy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69032",
    city: "Hayes Center",
    state: "NE",
    areacode: 308,
    county: "Hayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69033",
    city: "Imperial",
    state: "NE",
    areacode: 308,
    county: "Chase",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69034",
    city: "Indianola",
    state: "NE",
    areacode: 308,
    county: "Red Willow",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69036",
    city: "Lebanon",
    state: "NE",
    areacode: 308,
    county: "Red Willow",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69037",
    city: "Max",
    state: "NE",
    areacode: 308,
    county: "Dundy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69038",
    city: "Maywood",
    state: "NE",
    areacode: 308,
    county: "Frontier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69039",
    city: "Moorefield",
    state: "NE",
    areacode: 308,
    county: "Frontier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69040",
    city: "Palisade",
    state: "NE",
    areacode: 308,
    county: "Hayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69041",
    city: "Parks",
    state: "NE",
    areacode: 308,
    county: "Dundy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69042",
    city: "Stockville",
    state: "NE",
    areacode: 308,
    county: "Frontier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69043",
    city: "Stratton",
    state: "NE",
    areacode: 308,
    county: "Hitchcock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69044",
    city: "Trenton",
    state: "NE",
    areacode: 308,
    county: "Hitchcock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69045",
    city: "Wauneta",
    state: "NE",
    areacode: 308,
    county: "Chase",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69046",
    city: "Wilsonville",
    state: "NE",
    areacode: 308,
    county: "Furnas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69101",
    city: "North Platte",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69103",
    city: "North Platte",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69120",
    city: "Arnold",
    state: "NE",
    areacode: 308,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69121",
    city: "Arthur",
    state: "NE",
    areacode: 308,
    county: "Arthur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69122",
    city: "Big Springs",
    state: "NE",
    areacode: 308,
    county: "Deuel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69123",
    city: "Brady",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69125",
    city: "Broadwater",
    state: "NE",
    areacode: 308,
    county: "Morrill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69127",
    city: "Brule",
    state: "NE",
    areacode: 308,
    county: "Keith",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69128",
    city: "Bushnell",
    state: "NE",
    areacode: 308,
    county: "Kimball",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69129",
    city: "Chappell",
    state: "NE",
    areacode: 308,
    county: "Deuel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69130",
    city: "Cozad",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69131",
    city: "Dalton",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69132",
    city: "Dickens",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69133",
    city: "Dix",
    state: "NE",
    areacode: 308,
    county: "Kimball",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69134",
    city: "Elsie",
    state: "NE",
    areacode: 308,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69135",
    city: "Elsmere",
    state: "NE",
    areacode: 308,
    county: "Cherry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69138",
    city: "Gothenburg",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69140",
    city: "Grant",
    state: "NE",
    areacode: 308,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69141",
    city: "Gurley",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69142",
    city: "Halsey",
    state: "NE",
    areacode: 308,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69143",
    city: "Hershey",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69144",
    city: "Keystone",
    state: "NE",
    areacode: 308,
    county: "Keith",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69145",
    city: "Kimball",
    state: "NE",
    areacode: 308,
    county: "Kimball",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69146",
    city: "Lemoyne",
    state: "NE",
    areacode: 308,
    county: "Keith",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69147",
    city: "Lewellen",
    state: "NE",
    areacode: 308,
    county: "Garden",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69148",
    city: "Lisco",
    state: "NE",
    areacode: 308,
    county: "Garden",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69149",
    city: "Lodgepole",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69150",
    city: "Madrid",
    state: "NE",
    areacode: 308,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69151",
    city: "Maxwell",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69152",
    city: "Mullen",
    state: "NE",
    areacode: 308,
    county: "Hooker",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69153",
    city: "Ogallala",
    state: "NE",
    areacode: 308,
    county: "Keith",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69154",
    city: "Oshkosh",
    state: "NE",
    areacode: 308,
    county: "Garden",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69155",
    city: "Paxton",
    state: "NE",
    areacode: 308,
    county: "Keith",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69156",
    city: "Potter",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69157",
    city: "Purdum",
    state: "NE",
    areacode: 308,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69160",
    city: "Sidney",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69161",
    city: "Seneca",
    state: "NE",
    areacode: 308,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69162",
    city: "Sidney",
    state: "NE",
    areacode: 308,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69163",
    city: "Stapleton",
    state: "NE",
    areacode: 308,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69165",
    city: "Sutherland",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69166",
    city: "Thedford",
    state: "NE",
    areacode: 308,
    county: "Thomas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69167",
    city: "Tryon",
    state: "NE",
    areacode: 308,
    county: "McPherson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69168",
    city: "Venango",
    state: "NE",
    areacode: 308,
    county: "Perkins",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69169",
    city: "Wallace",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69170",
    city: "Wellfleet",
    state: "NE",
    areacode: 308,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69171",
    city: "Willow Island",
    state: "NE",
    areacode: 308,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69201",
    city: "Valentine",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69210",
    city: "Ainsworth",
    state: "NE",
    areacode: 402,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69211",
    city: "Cody",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69212",
    city: "Crookston",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69214",
    city: "Johnstown",
    state: "NE",
    areacode: 402,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69216",
    city: "Kilgore",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69217",
    city: "Long Pine",
    state: "NE",
    areacode: 402,
    county: "Rock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69218",
    city: "Merriman",
    state: "NE",
    areacode: 308,
    county: "Cherry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69219",
    city: "Nenzel",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69220",
    city: "Sparks",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69221",
    city: "Wood Lake",
    state: "NE",
    areacode: 402,
    county: "Cherry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "69301",
    city: "Alliance",
    state: "NE",
    areacode: 308,
    county: "Box Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69331",
    city: "Angora",
    state: "NE",
    areacode: 308,
    county: "Morrill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69333",
    city: "Ashby",
    state: "NE",
    areacode: 308,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69334",
    city: "Bayard",
    state: "NE",
    areacode: 308,
    county: "Morrill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69335",
    city: "Bingham",
    state: "NE",
    areacode: 308,
    county: "Garden",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69336",
    city: "Bridgeport",
    state: "NE",
    areacode: 308,
    county: "Morrill",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69337",
    city: "Chadron",
    state: "NE",
    areacode: 308,
    county: "Dawes",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69339",
    city: "Crawford",
    state: "NE",
    areacode: 308,
    county: "Dawes",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69340",
    city: "Ellsworth",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69341",
    city: "Gering",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69343",
    city: "Gordon",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69345",
    city: "Harrisburg",
    state: "NE",
    areacode: 308,
    county: "Banner",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69346",
    city: "Harrison",
    state: "NE",
    areacode: 308,
    county: "Sioux",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69347",
    city: "Hay Springs",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69348",
    city: "Hemingford",
    state: "NE",
    areacode: 308,
    county: "Box Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69350",
    city: "Hyannis",
    state: "NE",
    areacode: 308,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69351",
    city: "Lakeside",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69352",
    city: "Lyman",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69353",
    city: "McGrew",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69354",
    city: "Marsland",
    state: "NE",
    areacode: 308,
    county: "Dawes",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69355",
    city: "Melbeta",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69356",
    city: "Minatare",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69357",
    city: "Mitchell",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69358",
    city: "Morrill",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69360",
    city: "Rushville",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69361",
    city: "Scottsbluff",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69363",
    city: "Scottsbluff",
    state: "NE",
    areacode: 308,
    county: "Scotts Bluff",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69365",
    city: "Whiteclay",
    state: "NE",
    areacode: 308,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69366",
    city: "Whitman",
    state: "NE",
    areacode: 308,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "69367",
    city: "Whitney",
    state: "NE",
    areacode: 308,
    county: "Dawes",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "70001",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70002",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70003",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70004",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70005",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70006",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70009",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70010",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70011",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70030",
    city: "Des Allemands",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70031",
    city: "Ama",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70032",
    city: "Arabi",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70033",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70036",
    city: "Barataria",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70037",
    city: "Belle Chasse",
    state: "LA",
    areacode: 985,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70038",
    city: "Boothville",
    state: "LA",
    areacode: 985,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70039",
    city: "Boutte",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70040",
    city: "Braithwaite",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70041",
    city: "Buras",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70043",
    city: "Chalmette",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70044",
    city: "Chalmette",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70047",
    city: "Destrehan",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70049",
    city: "Edgard",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70050",
    city: "Empire",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70051",
    city: "Garyville",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70052",
    city: "Gramercy",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70053",
    city: "Gretna",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70054",
    city: "Gretna",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70055",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70056",
    city: "Gretna",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70057",
    city: "Hahnville",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70058",
    city: "Harvey",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70059",
    city: "Harvey",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70060",
    city: "Metairie",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70062",
    city: "Kenner",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70063",
    city: "Kenner",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70064",
    city: "Kenner",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70065",
    city: "Kenner",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70067",
    city: "Lafitte",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70068",
    city: "Laplace",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70069",
    city: "Laplace",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70070",
    city: "Luling",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70071",
    city: "Lutcher",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70072",
    city: "Marrero",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70073",
    city: "Marrero",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70075",
    city: "Meraux",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70076",
    city: "Mount Airy",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70078",
    city: "New Sarpy",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70079",
    city: "Norco",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70080",
    city: "Paradis",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70081",
    city: "Pilottown",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70082",
    city: "Pointe A la Hache",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70083",
    city: "Port Sulphur",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70084",
    city: "Reserve",
    state: "LA",
    areacode: 985,
    county: "St John the Baptist",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70085",
    city: "Saint Bernard",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70086",
    city: "Saint James",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70087",
    city: "Saint Rose",
    state: "LA",
    areacode: 985,
    county: "Saint Charles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70090",
    city: "Vacherie",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70091",
    city: "Venice",
    state: "LA",
    areacode: 504,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70092",
    city: "Violet",
    state: "LA",
    areacode: 504,
    county: "Saint Bernard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70093",
    city: "Belle Chasse",
    state: "LA",
    areacode: 985,
    county: "Plaquemines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70094",
    city: "Westwego",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70096",
    city: "Westwego",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70097",
    city: "Kenner",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70112",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70113",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70114",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70115",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70116",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70117",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70118",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70119",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70121",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70122",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70123",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70124",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70125",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70126",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70127",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70128",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70129",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70130",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70131",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70139",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70141",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70142",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70143",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70145",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70146",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70148",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70150",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70151",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70152",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70153",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70154",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70156",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70157",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70158",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70159",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70160",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70161",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70162",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70163",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70164",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70165",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70166",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70167",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70170",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70172",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70174",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70175",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70176",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70177",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70178",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70179",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70181",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70182",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70183",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70184",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70185",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70186",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70187",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70189",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70190",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70195",
    city: "New Orleans",
    state: "LA",
    areacode: 504,
    county: "Orleans",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70301",
    city: "Thibodaux",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70302",
    city: "Thibodaux",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70310",
    city: "Thibodaux",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70339",
    city: "Pierre Part",
    state: "LA",
    areacode: 985,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70340",
    city: "Amelia",
    state: "LA",
    areacode: 985,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70341",
    city: "Belle Rose",
    state: "LA",
    areacode: 225,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70342",
    city: "Berwick",
    state: "LA",
    areacode: 985,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70343",
    city: "Bourg",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70344",
    city: "Chauvin",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70345",
    city: "Cut Off",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70346",
    city: "Donaldsonville",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70352",
    city: "Donner",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70353",
    city: "Dulac",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70354",
    city: "Galliano",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70355",
    city: "Gheens",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70356",
    city: "Gibson",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70357",
    city: "Golden Meadow",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70358",
    city: "Grand Isle",
    state: "LA",
    areacode: 504,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70359",
    city: "Gray",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70360",
    city: "Houma",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70361",
    city: "Houma",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70363",
    city: "Houma",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70364",
    city: "Houma",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70371",
    city: "Kraemer",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70372",
    city: "Labadieville",
    state: "LA",
    areacode: 985,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70373",
    city: "Larose",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70374",
    city: "Lockport",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70375",
    city: "Mathews",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70377",
    city: "Montegut",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70380",
    city: "Morgan City",
    state: "LA",
    areacode: 985,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70381",
    city: "Morgan City",
    state: "LA",
    areacode: 985,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70390",
    city: "Napoleonville",
    state: "LA",
    areacode: 985,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70391",
    city: "Paincourtville",
    state: "LA",
    areacode: 985,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70392",
    city: "Patterson",
    state: "LA",
    areacode: 985,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70393",
    city: "Plattenville",
    state: "LA",
    areacode: 985,
    county: "Assumption",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70394",
    city: "Raceland",
    state: "LA",
    areacode: 985,
    county: "Lafourche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70395",
    city: "Schriever",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70397",
    city: "Theriot",
    state: "LA",
    areacode: 985,
    county: "Terrebonne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70401",
    city: "Hammond",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70402",
    city: "Hammond",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70403",
    city: "Hammond",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70404",
    city: "Hammond",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70420",
    city: "Abita Springs",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70421",
    city: "Akers",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70422",
    city: "Amite",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70426",
    city: "Angie",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70427",
    city: "Bogalusa",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70429",
    city: "Bogalusa",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70431",
    city: "Bush",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70433",
    city: "Covington",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70434",
    city: "Covington",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70435",
    city: "Covington",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70436",
    city: "Fluker",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70437",
    city: "Folsom",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70438",
    city: "Franklinton",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70441",
    city: "Greensburg",
    state: "LA",
    areacode: 225,
    county: "Saint Helena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70442",
    city: "Husser",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70443",
    city: "Independence",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70444",
    city: "Kentwood",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70445",
    city: "Lacombe",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70446",
    city: "Loranger",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70447",
    city: "Madisonville",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70448",
    city: "Mandeville",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70449",
    city: "Maurepas",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70450",
    city: "Mount Hermon",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70451",
    city: "Natalbany",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70452",
    city: "Pearl River",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70453",
    city: "Pine Grove",
    state: "LA",
    areacode: 225,
    county: "Saint Helena",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70454",
    city: "Ponchatoula",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70455",
    city: "Robert",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70456",
    city: "Roseland",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70457",
    city: "Saint Benedict",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70458",
    city: "Slidell",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70459",
    city: "Slidell",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70460",
    city: "Slidell",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70461",
    city: "Slidell",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70462",
    city: "Springfield",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70463",
    city: "Sun",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70464",
    city: "Talisheek",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70465",
    city: "Tangipahoa",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70466",
    city: "Tickfaw",
    state: "LA",
    areacode: 985,
    county: "Tangipahoa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70467",
    city: "Varnado",
    state: "LA",
    areacode: 985,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70469",
    city: "Slidell",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70470",
    city: "Mandeville",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70471",
    city: "Mandeville",
    state: "LA",
    areacode: 985,
    county: "Saint Tammany",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70501",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70502",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70503",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70504",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70505",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70506",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70507",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70508",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70509",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70510",
    city: "Abbeville",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70511",
    city: "Abbeville",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70512",
    city: "Arnaudville",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70513",
    city: "Avery Island",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70514",
    city: "Baldwin",
    state: "LA",
    areacode: 337,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70515",
    city: "Basile",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70516",
    city: "Branch",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70517",
    city: "Breaux Bridge",
    state: "LA",
    areacode: 337,
    county: "Saint Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70518",
    city: "Broussard",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70519",
    city: "Cade",
    state: "LA",
    areacode: 337,
    county: "Saint Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70520",
    city: "Carencro",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70521",
    city: "Cecilia",
    state: "LA",
    areacode: 337,
    county: "Saint Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70522",
    city: "Centerville",
    state: "LA",
    areacode: 337,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70523",
    city: "Charenton",
    state: "LA",
    areacode: 337,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70524",
    city: "Chataignier",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70525",
    city: "Church Point",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70526",
    city: "Crowley",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70527",
    city: "Crowley",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70528",
    city: "Delcambre",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70529",
    city: "Duson",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70531",
    city: "Egan",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70532",
    city: "Elton",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70533",
    city: "Erath",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70534",
    city: "Estherwood",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70535",
    city: "Eunice",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70537",
    city: "Evangeline",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70538",
    city: "Franklin",
    state: "LA",
    areacode: 337,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70540",
    city: "Garden City",
    state: "LA",
    areacode: 337,
    county: "Saint Mary",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70541",
    city: "Grand Coteau",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70542",
    city: "Gueydan",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70543",
    city: "Iota",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70544",
    city: "Jeanerette",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70546",
    city: "Jennings",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70548",
    city: "Kaplan",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70549",
    city: "Lake Arthur",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70550",
    city: "Lawtell",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70551",
    city: "Leonville",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70552",
    city: "Loreauville",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70554",
    city: "Mamou",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70555",
    city: "Maurice",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70556",
    city: "Mermentau",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70558",
    city: "Milton",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70559",
    city: "Morse",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70560",
    city: "New Iberia",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70562",
    city: "New Iberia",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70563",
    city: "New Iberia",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70569",
    city: "Lydia",
    state: "LA",
    areacode: 337,
    county: "Iberia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70570",
    city: "Opelousas",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70571",
    city: "Opelousas",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70575",
    city: "Perry",
    state: "LA",
    areacode: 337,
    county: "Vermilion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70576",
    city: "Pine Prairie",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70577",
    city: "Port Barre",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70578",
    city: "Rayne",
    state: "LA",
    areacode: 337,
    county: "Acadia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70580",
    city: "Reddell",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70581",
    city: "Roanoke",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70582",
    city: "Saint Martinville",
    state: "LA",
    areacode: 337,
    county: "Saint Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70583",
    city: "Scott",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70584",
    city: "Sunset",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70585",
    city: "Turkey Creek",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70586",
    city: "Ville Platte",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70589",
    city: "Washington",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70591",
    city: "Welsh",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70592",
    city: "Youngsville",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70593",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70596",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70598",
    city: "Lafayette",
    state: "LA",
    areacode: 337,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70601",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70602",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70605",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70606",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70607",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70609",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70611",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70612",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70615",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70616",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70629",
    city: "Lake Charles",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70630",
    city: "Bell City",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70631",
    city: "Cameron",
    state: "LA",
    areacode: 337,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70632",
    city: "Creole",
    state: "LA",
    areacode: 337,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70633",
    city: "Dequincy",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70634",
    city: "Deridder",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70637",
    city: "Dry Creek",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70638",
    city: "Elizabeth",
    state: "LA",
    areacode: 318,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70639",
    city: "Evans",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70640",
    city: "Fenton",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70643",
    city: "Grand Chenier",
    state: "LA",
    areacode: 337,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70644",
    city: "Grant",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70645",
    city: "Hackberry",
    state: "LA",
    areacode: 337,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70646",
    city: "Hayes",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70647",
    city: "Iowa",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70648",
    city: "Kinder",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70650",
    city: "Lacassine",
    state: "LA",
    areacode: 337,
    county: "Jefferson Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70651",
    city: "Leblanc",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70652",
    city: "Longville",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70653",
    city: "Merryville",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70654",
    city: "Mittie",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70655",
    city: "Oberlin",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70656",
    city: "Pitkin",
    state: "LA",
    areacode: 318,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70657",
    city: "Ragley",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70658",
    city: "Reeves",
    state: "LA",
    areacode: 337,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70659",
    city: "Rosepine",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70660",
    city: "Singer",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70661",
    city: "Starks",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70662",
    city: "Sugartown",
    state: "LA",
    areacode: 337,
    county: "Beauregard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70663",
    city: "Sulphur",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70664",
    city: "Sulphur",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70665",
    city: "Sulphur",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70668",
    city: "Vinton",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70669",
    city: "Westlake",
    state: "LA",
    areacode: 337,
    county: "Calcasieu",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70704",
    city: "Baker",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70706",
    city: "Denham Springs",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70707",
    city: "Gonzales",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70710",
    city: "Addis",
    state: "LA",
    areacode: 225,
    county: "West Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70711",
    city: "Albany",
    state: "LA",
    areacode: 985,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70712",
    city: "Angola",
    state: "LA",
    areacode: 225,
    county: "West Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70714",
    city: "Baker",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70715",
    city: "Batchelor",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70718",
    city: "Brittany",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70719",
    city: "Brusly",
    state: "LA",
    areacode: 225,
    county: "West Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70721",
    city: "Carville",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70722",
    city: "Clinton",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70723",
    city: "Convent",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70725",
    city: "Darrow",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70726",
    city: "Denham Springs",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70727",
    city: "Denham Springs",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70728",
    city: "Duplessis",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70729",
    city: "Erwinville",
    state: "LA",
    areacode: 225,
    county: "West Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70730",
    city: "Ethel",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70732",
    city: "Fordoche",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70733",
    city: "French Settlement",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70734",
    city: "Geismar",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70736",
    city: "Glynn",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70737",
    city: "Gonzales",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70738",
    city: "Burnside",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70739",
    city: "Greenwell Springs",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70740",
    city: "Grosse Tete",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70743",
    city: "Hester",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70744",
    city: "Holden",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70747",
    city: "Innis",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70748",
    city: "Jackson",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70749",
    city: "Jarreau",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70750",
    city: "Krotz Springs",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70752",
    city: "Lakeland",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70753",
    city: "Lettsworth",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70754",
    city: "Livingston",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70755",
    city: "Livonia",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70756",
    city: "Lottie",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70757",
    city: "Maringouin",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70759",
    city: "Morganza",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70760",
    city: "New Roads",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70761",
    city: "Norwood",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70762",
    city: "Oscar",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70763",
    city: "Paulina",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70764",
    city: "Plaquemine",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70765",
    city: "Plaquemine",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70767",
    city: "Port Allen",
    state: "LA",
    areacode: 225,
    county: "West Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70769",
    city: "Prairieville",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70770",
    city: "Pride",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70772",
    city: "Rosedale",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70773",
    city: "Rougon",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70774",
    city: "Saint Amant",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70775",
    city: "Saint Francisville",
    state: "LA",
    areacode: 225,
    county: "West Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70776",
    city: "Saint Gabriel",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70777",
    city: "Slaughter",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70778",
    city: "Sorrento",
    state: "LA",
    areacode: 225,
    county: "Ascension",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70780",
    city: "Sunshine",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70782",
    city: "Tunica",
    state: "LA",
    areacode: 225,
    county: "West Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70783",
    city: "Ventress",
    state: "LA",
    areacode: 225,
    county: "Pointe Coupee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70784",
    city: "Wakefield",
    state: "LA",
    areacode: 225,
    county: "West Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70785",
    city: "Walker",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70786",
    city: "Watson",
    state: "LA",
    areacode: 225,
    county: "Livingston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70787",
    city: "Weyanoke",
    state: "LA",
    areacode: 225,
    county: "West Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70788",
    city: "White Castle",
    state: "LA",
    areacode: 225,
    county: "Iberville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70789",
    city: "Wilson",
    state: "LA",
    areacode: 225,
    county: "East Feliciana",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70791",
    city: "Zachary",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70792",
    city: "Uncle Sam",
    state: "LA",
    areacode: 225,
    county: "Saint James",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70801",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70802",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70803",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70804",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70805",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70806",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70807",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70808",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70809",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70810",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70811",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70812",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70813",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70814",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70815",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70816",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70817",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70818",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70819",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70820",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70821",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70822",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70823",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70825",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70826",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70827",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70831",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70833",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70835",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70836",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70837",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70873",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70874",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70879",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70884",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70891",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70892",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70893",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70894",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70895",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70896",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "70898",
    city: "Baton Rouge",
    state: "LA",
    areacode: 225,
    county: "East Baton Rouge",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71001",
    city: "Arcadia",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71002",
    city: "Ashland",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71003",
    city: "Athens",
    state: "LA",
    areacode: 318,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71004",
    city: "Belcher",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71006",
    city: "Benton",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71007",
    city: "Bethany",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71008",
    city: "Bienville",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71009",
    city: "Blanchard",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71016",
    city: "Castor",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71018",
    city: "Cotton Valley",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71019",
    city: "Coushatta",
    state: "LA",
    areacode: 318,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71021",
    city: "Cullen",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71023",
    city: "Doyline",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71024",
    city: "Dubberly",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71027",
    city: "Frierson",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71028",
    city: "Gibsland",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71029",
    city: "Gilliam",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71030",
    city: "Gloster",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71031",
    city: "Goldonna",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71032",
    city: "Grand Cane",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71033",
    city: "Greenwood",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71034",
    city: "Hall Summit",
    state: "LA",
    areacode: 318,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71037",
    city: "Haughton",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71038",
    city: "Haynesville",
    state: "LA",
    areacode: 318,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71039",
    city: "Heflin",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71040",
    city: "Homer",
    state: "LA",
    areacode: 318,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71043",
    city: "Hosston",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71044",
    city: "Ida",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71045",
    city: "Jamestown",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71046",
    city: "Keatchie",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71047",
    city: "Keithville",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71048",
    city: "Lisbon",
    state: "LA",
    areacode: 318,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71049",
    city: "Logansport",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71050",
    city: "Longstreet",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71051",
    city: "Elm Grove",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71052",
    city: "Mansfield",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71055",
    city: "Minden",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71058",
    city: "Minden",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71060",
    city: "Mooringsport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71061",
    city: "Oil City",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71063",
    city: "Pelican",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71064",
    city: "Plain Dealing",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71065",
    city: "Pleasant Hill",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71066",
    city: "Powhatan",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71067",
    city: "Princeton",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71068",
    city: "Ringgold",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71069",
    city: "Rodessa",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71070",
    city: "Saline",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71071",
    city: "Sarepta",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71072",
    city: "Shongaloo",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71073",
    city: "Sibley",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71075",
    city: "Springhill",
    state: "LA",
    areacode: 318,
    county: "Webster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71078",
    city: "Stonewall",
    state: "LA",
    areacode: 318,
    county: "De Soto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71079",
    city: "Summerfield",
    state: "LA",
    areacode: 318,
    county: "Claiborne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71080",
    city: "Taylor",
    state: "LA",
    areacode: 318,
    county: "Bienville",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71082",
    city: "Vivian",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71101",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71102",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71103",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71104",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71105",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71106",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71107",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71108",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71109",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71110",
    city: "Barksdale AFB",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71111",
    city: "Bossier City",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71112",
    city: "Bossier City",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71113",
    city: "Bossier City",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71115",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71118",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71119",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71120",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71129",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71130",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71133",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71134",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71135",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71136",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71137",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71138",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71148",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71149",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71150",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71151",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71152",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71153",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71154",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71156",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71161",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71162",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71163",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71164",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71165",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71166",
    city: "Shreveport",
    state: "LA",
    areacode: 318,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71171",
    city: "Bossier City",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71172",
    city: "Bossier City",
    state: "LA",
    areacode: 318,
    county: "Bossier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71201",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71202",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71203",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71207",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71209",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71210",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71211",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71212",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71213",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71217",
    city: "Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71218",
    city: "Archibald",
    state: "LA",
    areacode: 318,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71219",
    city: "Baskin",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71220",
    city: "Bastrop",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71221",
    city: "Bastrop",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71222",
    city: "Bernice",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71223",
    city: "Bonita",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71225",
    city: "Calhoun",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71226",
    city: "Chatham",
    state: "LA",
    areacode: 318,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71227",
    city: "Choudrant",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71229",
    city: "Collinston",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71230",
    city: "Crowville",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71232",
    city: "Delhi",
    state: "LA",
    areacode: 318,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71233",
    city: "Delta",
    state: "LA",
    areacode: 318,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71234",
    city: "Downsville",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71235",
    city: "Dubach",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71237",
    city: "Epps",
    state: "LA",
    areacode: 318,
    county: "West Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71238",
    city: "Eros",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71240",
    city: "Fairbanks",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71241",
    city: "Farmerville",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71242",
    city: "Forest",
    state: "LA",
    areacode: 318,
    county: "West Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71243",
    city: "Fort Necessity",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71245",
    city: "Grambling",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71247",
    city: "Hodge",
    state: "LA",
    areacode: 318,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71249",
    city: "Jigger",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71250",
    city: "Jones",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71251",
    city: "Jonesboro",
    state: "LA",
    areacode: 318,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71253",
    city: "Kilbourne",
    state: "LA",
    areacode: 318,
    county: "West Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71254",
    city: "Lake Providence",
    state: "LA",
    areacode: 318,
    county: "East Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71256",
    city: "Lillie",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71259",
    city: "Mangham",
    state: "LA",
    areacode: 318,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71260",
    city: "Marion",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71261",
    city: "Mer Rouge",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71263",
    city: "Oak Grove",
    state: "LA",
    areacode: 318,
    county: "West Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71264",
    city: "Oak Ridge",
    state: "LA",
    areacode: 318,
    county: "Morehouse",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71266",
    city: "Pioneer",
    state: "LA",
    areacode: 318,
    county: "West Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71268",
    city: "Quitman",
    state: "LA",
    areacode: 318,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71269",
    city: "Rayville",
    state: "LA",
    areacode: 318,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71270",
    city: "Ruston",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71272",
    city: "Ruston",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71273",
    city: "Ruston",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71275",
    city: "Simsboro",
    state: "LA",
    areacode: 318,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71276",
    city: "Sondheimer",
    state: "LA",
    areacode: 318,
    county: "East Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71277",
    city: "Spearsville",
    state: "LA",
    areacode: 318,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71279",
    city: "Start",
    state: "LA",
    areacode: 318,
    county: "Richland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71280",
    city: "Sterlington",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71281",
    city: "Swartz",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71282",
    city: "Tallulah",
    state: "LA",
    areacode: 318,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71284",
    city: "Tallulah",
    state: "LA",
    areacode: 318,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71286",
    city: "Transylvania",
    state: "LA",
    areacode: 318,
    county: "East Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71291",
    city: "West Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71292",
    city: "West Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71294",
    city: "West Monroe",
    state: "LA",
    areacode: 318,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71295",
    city: "Winnsboro",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71301",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71302",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71303",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71306",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71307",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71309",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71315",
    city: "Alexandria",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71316",
    city: "Acme",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71320",
    city: "Bordelonville",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71322",
    city: "Bunkie",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71323",
    city: "Center Point",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71324",
    city: "Chase",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71325",
    city: "Cheneyville",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71326",
    city: "Clayton",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71327",
    city: "Cottonport",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71328",
    city: "Deville",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71329",
    city: "Dupont",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71330",
    city: "Echo",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71331",
    city: "Effie",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71333",
    city: "Evergreen",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71334",
    city: "Ferriday",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71336",
    city: "Gilbert",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71339",
    city: "Hamburg",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71340",
    city: "Harrisonburg",
    state: "LA",
    areacode: 318,
    county: "Catahoula",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71341",
    city: "Hessmer",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71342",
    city: "Jena",
    state: "LA",
    areacode: 318,
    county: "LaSalle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71343",
    city: "Jonesville",
    state: "LA",
    areacode: 318,
    county: "Catahoula",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71345",
    city: "Lebeau",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71346",
    city: "Lecompte",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71348",
    city: "Libuse",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71350",
    city: "Mansura",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71351",
    city: "Marksville",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71353",
    city: "Melville",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71354",
    city: "Monterey",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71355",
    city: "Moreauville",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71356",
    city: "Morrow",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71357",
    city: "Newellton",
    state: "LA",
    areacode: 318,
    county: "Tensas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71358",
    city: "Palmetto",
    state: "LA",
    areacode: 337,
    county: "Saint Landry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71359",
    city: "Pineville",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71360",
    city: "Pineville",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71361",
    city: "Pineville",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71362",
    city: "Plaucheville",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71363",
    city: "Rhinehart",
    state: "LA",
    areacode: 318,
    county: "Catahoula",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71365",
    city: "Ruby",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71366",
    city: "Saint Joseph",
    state: "LA",
    areacode: 318,
    county: "Tensas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71367",
    city: "Saint Landry",
    state: "LA",
    areacode: 337,
    county: "Evangeline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71368",
    city: "Sicily Island",
    state: "LA",
    areacode: 318,
    county: "Catahoula",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71369",
    city: "Simmesport",
    state: "LA",
    areacode: 318,
    county: "Avoyelles",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71371",
    city: "Trout",
    state: "LA",
    areacode: 318,
    county: "LaSalle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71373",
    city: "Vidalia",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71375",
    city: "Waterproof",
    state: "LA",
    areacode: 318,
    county: "Tensas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71377",
    city: "Wildsville",
    state: "LA",
    areacode: 318,
    county: "Concordia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71378",
    city: "Wisner",
    state: "LA",
    areacode: 318,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71401",
    city: "Aimwell",
    state: "LA",
    areacode: 318,
    county: "Catahoula",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71403",
    city: "Anacoco",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71404",
    city: "Atlanta",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71405",
    city: "Ball",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71406",
    city: "Belmont",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71407",
    city: "Bentley",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71409",
    city: "Boyce",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71410",
    city: "Calvin",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71411",
    city: "Campti",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71414",
    city: "Clarence",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71415",
    city: "Clarks",
    state: "LA",
    areacode: 318,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71416",
    city: "Cloutierville",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71417",
    city: "Colfax",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71418",
    city: "Columbia",
    state: "LA",
    areacode: 318,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71419",
    city: "Converse",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71422",
    city: "Dodson",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71423",
    city: "Dry Prong",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71424",
    city: "Elmer",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71425",
    city: "Enterprise",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71426",
    city: "Fisher",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71427",
    city: "Flatwoods",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71428",
    city: "Flora",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71429",
    city: "Florien",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71430",
    city: "Forest Hill",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71431",
    city: "Gardner",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71432",
    city: "Georgetown",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71433",
    city: "Glenmora",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71434",
    city: "Gorum",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71435",
    city: "Grayson",
    state: "LA",
    areacode: 318,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71438",
    city: "Hineston",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71439",
    city: "Hornbeck",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71440",
    city: "Joyce",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71441",
    city: "Kelly",
    state: "LA",
    areacode: 318,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71443",
    city: "Kurthwood",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71446",
    city: "Leesville",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71447",
    city: "Lena",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71448",
    city: "Longleaf",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71449",
    city: "Many",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71450",
    city: "Marthaville",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71452",
    city: "Melrose",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71454",
    city: "Montgomery",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71455",
    city: "Mora",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71456",
    city: "Natchez",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71457",
    city: "Natchitoches",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71458",
    city: "Natchitoches",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71459",
    city: "Fort Polk",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71460",
    city: "Negreet",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71461",
    city: "New Llano",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71462",
    city: "Noble",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71463",
    city: "Oakdale",
    state: "LA",
    areacode: 318,
    county: "Allen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71465",
    city: "Olla",
    state: "LA",
    areacode: 318,
    county: "LaSalle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71466",
    city: "Otis",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71467",
    city: "Pollock",
    state: "LA",
    areacode: 318,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71468",
    city: "Provencal",
    state: "LA",
    areacode: 337,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71469",
    city: "Robeline",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71471",
    city: "Saint Maurice",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71472",
    city: "Sieper",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71473",
    city: "Sikes",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71474",
    city: "Simpson",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71475",
    city: "Slagle",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71477",
    city: "Tioga",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71479",
    city: "Tullos",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71480",
    city: "Urania",
    state: "LA",
    areacode: 318,
    county: "LaSalle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71483",
    city: "Winnfield",
    state: "LA",
    areacode: 318,
    county: "Winn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71485",
    city: "Woodworth",
    state: "LA",
    areacode: 318,
    county: "Rapides",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71486",
    city: "Zwolle",
    state: "LA",
    areacode: 318,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71496",
    city: "Leesville",
    state: "LA",
    areacode: 337,
    county: "Vernon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71497",
    city: "Natchitoches",
    state: "LA",
    areacode: 318,
    county: "Natchitoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71601",
    city: "Pine Bluff",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71602",
    city: "White Hall",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71603",
    city: "Pine Bluff",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71611",
    city: "Pine Bluff",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71612",
    city: "White Hall",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71613",
    city: "Pine Bluff",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71630",
    city: "Arkansas City",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71631",
    city: "Banks",
    state: "AR",
    areacode: 870,
    county: "Bradley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71635",
    city: "Crossett",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71638",
    city: "Dermott",
    state: "AR",
    areacode: 870,
    county: "Chicot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71639",
    city: "Dumas",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71640",
    city: "Eudora",
    state: "AR",
    areacode: 870,
    county: "Chicot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71642",
    city: "Fountain Hill",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71643",
    city: "Gould",
    state: "AR",
    areacode: 870,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71644",
    city: "Grady",
    state: "AR",
    areacode: 870,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71646",
    city: "Hamburg",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71647",
    city: "Hermitage",
    state: "AR",
    areacode: 870,
    county: "Bradley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71651",
    city: "Jersey",
    state: "AR",
    areacode: 870,
    county: "Bradley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71652",
    city: "Kingsland",
    state: "AR",
    areacode: 870,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71653",
    city: "Lake Village",
    state: "AR",
    areacode: 870,
    county: "Chicot",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71654",
    city: "McGehee",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71655",
    city: "Monticello",
    state: "AR",
    areacode: 870,
    county: "Drew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71656",
    city: "Monticello",
    state: "AR",
    areacode: 870,
    county: "Drew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71657",
    city: "Monticello",
    state: "AR",
    areacode: 870,
    county: "Drew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71658",
    city: "Montrose",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71659",
    city: "Moscow",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71660",
    city: "New Edinburg",
    state: "AR",
    areacode: 870,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71661",
    city: "Parkdale",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71662",
    city: "Pickens",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71663",
    city: "Portland",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71665",
    city: "Rison",
    state: "AR",
    areacode: 870,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71666",
    city: "Rohwer",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71667",
    city: "Star City",
    state: "AR",
    areacode: 870,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71670",
    city: "Tillar",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71671",
    city: "Warren",
    state: "AR",
    areacode: 870,
    county: "Bradley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71674",
    city: "Watson",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71675",
    city: "Wilmar",
    state: "AR",
    areacode: 870,
    county: "Drew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71676",
    city: "Wilmot",
    state: "AR",
    areacode: 870,
    county: "Ashley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71677",
    city: "Winchester",
    state: "AR",
    areacode: 870,
    county: "Drew",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71678",
    city: "Yorktown",
    state: "AR",
    areacode: 870,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71701",
    city: "Camden",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71711",
    city: "Camden",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71720",
    city: "Bearden",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71721",
    city: "Beirne",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71722",
    city: "Bluff City",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71724",
    city: "Calion",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71725",
    city: "Carthage",
    state: "AR",
    areacode: 870,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71726",
    city: "Chidester",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71728",
    city: "Curtis",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71730",
    city: "El Dorado",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71731",
    city: "El Dorado",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71740",
    city: "Emerson",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71742",
    city: "Fordyce",
    state: "AR",
    areacode: 870,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71743",
    city: "Gurdon",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71744",
    city: "Hampton",
    state: "AR",
    areacode: 870,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71745",
    city: "Harrell",
    state: "AR",
    areacode: 870,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71747",
    city: "Huttig",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71748",
    city: "Ivan",
    state: "AR",
    areacode: 870,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71749",
    city: "Junction City",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71750",
    city: "Lawson",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71751",
    city: "Louann",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71752",
    city: "McNeil",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71753",
    city: "Magnolia",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71754",
    city: "Magnolia",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71758",
    city: "Mount Holly",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71759",
    city: "Norphlet",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71762",
    city: "Smackover",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71763",
    city: "Sparkman",
    state: "AR",
    areacode: 870,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71764",
    city: "Stephens",
    state: "AR",
    areacode: 870,
    county: "Ouachita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71765",
    city: "Strong",
    state: "AR",
    areacode: 870,
    county: "Union",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71766",
    city: "Thornton",
    state: "AR",
    areacode: 870,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71770",
    city: "Waldo",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71772",
    city: "Whelen Springs",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71801",
    city: "Hope",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71802",
    city: "Hope",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71820",
    city: "Alleene",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71822",
    city: "Ashdown",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71823",
    city: "Ben Lomond",
    state: "AR",
    areacode: 870,
    county: "Sevier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71825",
    city: "Blevins",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71826",
    city: "Bradley",
    state: "AR",
    areacode: 870,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71827",
    city: "Buckner",
    state: "AR",
    areacode: 870,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71828",
    city: "Cale",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71831",
    city: "Columbus",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71832",
    city: "De Queen",
    state: "AR",
    areacode: 870,
    county: "Sevier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71833",
    city: "Dierks",
    state: "AR",
    areacode: 870,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71834",
    city: "Doddridge",
    state: "AR",
    areacode: 870,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71835",
    city: "Emmet",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71836",
    city: "Foreman",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71837",
    city: "Fouke",
    state: "AR",
    areacode: 870,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71838",
    city: "Fulton",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71839",
    city: "Garland City",
    state: "AR",
    areacode: 870,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71840",
    city: "Genoa",
    state: "AR",
    areacode: 870,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71841",
    city: "Gillham",
    state: "AR",
    areacode: 870,
    county: "Sevier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71842",
    city: "Horatio",
    state: "AR",
    areacode: 870,
    county: "Sevier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71845",
    city: "Lewisville",
    state: "AR",
    areacode: 870,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71846",
    city: "Lockesburg",
    state: "AR",
    areacode: 870,
    county: "Sevier",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71847",
    city: "McCaskill",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71851",
    city: "Mineral Springs",
    state: "AR",
    areacode: 870,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71852",
    city: "Nashville",
    state: "AR",
    areacode: 870,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71853",
    city: "Ogden",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71854",
    city: "Texarkana",
    state: "AR",
    areacode: 870,
    county: "Miller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71855",
    city: "Ozan",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71857",
    city: "Prescott",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71858",
    city: "Rosston",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71859",
    city: "Saratoga",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71860",
    city: "Stamps",
    state: "AR",
    areacode: 870,
    county: "Lafayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71861",
    city: "Taylor",
    state: "AR",
    areacode: 870,
    county: "Columbia",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71862",
    city: "Washington",
    state: "AR",
    areacode: 870,
    county: "Hempstead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71864",
    city: "Willisville",
    state: "AR",
    areacode: 870,
    county: "Nevada",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71865",
    city: "Wilton",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71866",
    city: "Winthrop",
    state: "AR",
    areacode: 870,
    county: "Little River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71901",
    city: "Hot Springs National Park",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71902",
    city: "Hot Springs National Park",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71903",
    city: "Hot Springs National Park",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71909",
    city: "Hot Springs Village",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71910",
    city: "Hot Springs Village",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71913",
    city: "Hot Springs National Park",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71914",
    city: "Hot Springs National Park",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71920",
    city: "Alpine",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71921",
    city: "Amity",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71922",
    city: "Antoine",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71923",
    city: "Arkadelphia",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71929",
    city: "Bismarck",
    state: "AR",
    areacode: 501,
    county: "Hot Spring",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71932",
    city: "Board Camp",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71933",
    city: "Bonnerdale",
    state: "AR",
    areacode: 501,
    county: "Hot Spring",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71935",
    city: "Caddo Gap",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71937",
    city: "Cove",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71940",
    city: "Delight",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71941",
    city: "Donaldson",
    state: "AR",
    areacode: 501,
    county: "Hot Spring",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71942",
    city: "Friendship",
    state: "AR",
    areacode: 501,
    county: "Hot Spring",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71943",
    city: "Glenwood",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71944",
    city: "Grannis",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71945",
    city: "Hatfield",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71949",
    city: "Jessieville",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71950",
    city: "Kirby",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71952",
    city: "Langley",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71953",
    city: "Mena",
    state: "AR",
    areacode: 479,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71956",
    city: "Mountain Pine",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71957",
    city: "Mount Ida",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71958",
    city: "Murfreesboro",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71959",
    city: "Newhope",
    state: "AR",
    areacode: 870,
    county: "Pike",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71960",
    city: "Norman",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71961",
    city: "Oden",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71962",
    city: "Okolona",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71964",
    city: "Pearcy",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71965",
    city: "Pencil Bluff",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71966",
    city: "Oden",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71968",
    city: "Royal",
    state: "AR",
    areacode: 501,
    county: "Garland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71969",
    city: "Sims",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71970",
    city: "Story",
    state: "AR",
    areacode: 870,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71971",
    city: "Umpire",
    state: "AR",
    areacode: 870,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71972",
    city: "Vandervoort",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71973",
    city: "Wickes",
    state: "AR",
    areacode: 870,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71998",
    city: "Arkadelphia",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "71999",
    city: "Arkadelphia",
    state: "AR",
    areacode: 870,
    county: "Clark",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72001",
    city: "Adona",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72002",
    city: "Alexander",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72003",
    city: "Almyra",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72004",
    city: "Altheimer",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72005",
    city: "Amagon",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72006",
    city: "Augusta",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72007",
    city: "Austin",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72010",
    city: "Bald Knob",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72011",
    city: "Bauxite",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72012",
    city: "Beebe",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72013",
    city: "Bee Branch",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72014",
    city: "Beedeville",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72015",
    city: "Benton",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72016",
    city: "Bigelow",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72017",
    city: "Biscoe",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72018",
    city: "Benton",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72019",
    city: "Benton",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72020",
    city: "Bradford",
    state: "AR",
    areacode: 501,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72021",
    city: "Brinkley",
    state: "AR",
    areacode: 870,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72022",
    city: "Bryant",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72023",
    city: "Cabot",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72024",
    city: "Carlisle",
    state: "AR",
    areacode: 870,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72025",
    city: "Casa",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72026",
    city: "Casscoe",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72027",
    city: "Center Ridge",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72028",
    city: "Choctaw",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72029",
    city: "Clarendon",
    state: "AR",
    areacode: 870,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72030",
    city: "Cleveland",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72031",
    city: "Clinton",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72032",
    city: "Conway",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72033",
    city: "Conway",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72034",
    city: "Conway",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72035",
    city: "Conway",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72036",
    city: "Cotton Plant",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72037",
    city: "Coy",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72038",
    city: "Crocketts Bluff",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72039",
    city: "Damascus",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72040",
    city: "Des Arc",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72041",
    city: "De Valls Bluff",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72042",
    city: "DeWitt",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72043",
    city: "Diaz",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72044",
    city: "Edgemont",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72045",
    city: "El Paso",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72046",
    city: "England",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72047",
    city: "Enola",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72048",
    city: "Ethel",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72051",
    city: "Fox",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72052",
    city: "Garner",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72053",
    city: "College Station",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72055",
    city: "Gillett",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72057",
    city: "Grapevine",
    state: "AR",
    areacode: 870,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72058",
    city: "Greenbrier",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72059",
    city: "Gregory",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72060",
    city: "Griffithville",
    state: "AR",
    areacode: 501,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72061",
    city: "Guy",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72063",
    city: "Hattieville",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72064",
    city: "Hazen",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72065",
    city: "Hensley",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72066",
    city: "Hickory Plains",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72067",
    city: "Higden",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72068",
    city: "Higginson",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72069",
    city: "Holly Grove",
    state: "AR",
    areacode: 870,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72070",
    city: "Houston",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72072",
    city: "Humnoke",
    state: "AR",
    areacode: 870,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72073",
    city: "Humphrey",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72074",
    city: "Hunter",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72075",
    city: "Jacksonport",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72076",
    city: "Jacksonville",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72078",
    city: "Jacksonville",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72079",
    city: "Jefferson",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72080",
    city: "Jerusalem",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72081",
    city: "Judsonia",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72082",
    city: "Kensett",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72083",
    city: "Keo",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72084",
    city: "Leola",
    state: "AR",
    areacode: 870,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72085",
    city: "Letona",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72086",
    city: "Lonoke",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72087",
    city: "Lonsdale",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72088",
    city: "Fairfield Bay",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72089",
    city: "Bryant",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72099",
    city: "Little Rock AFB",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72101",
    city: "McCrory",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72102",
    city: "McRae",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72103",
    city: "Mabelvale",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72104",
    city: "Malvern",
    state: "AR",
    areacode: 501,
    county: "Hot Spring",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72106",
    city: "Mayflower",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72107",
    city: "Menifee",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72108",
    city: "Monroe",
    state: "AR",
    areacode: 870,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72110",
    city: "Morrilton",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72111",
    city: "Mount Vernon",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72112",
    city: "Newport",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72113",
    city: "Maumelle",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72114",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72115",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72116",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72117",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72118",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72119",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72120",
    city: "Sherwood",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72121",
    city: "Pangburn",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72122",
    city: "Paron",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72123",
    city: "Patterson",
    state: "AR",
    areacode: 870,
    county: "Woodruff",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72124",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72125",
    city: "Perry",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72126",
    city: "Perryville",
    state: "AR",
    areacode: 501,
    county: "Perry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72127",
    city: "Plumerville",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72128",
    city: "Poyen",
    state: "AR",
    areacode: 870,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72129",
    city: "Prattsville",
    state: "AR",
    areacode: 870,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72130",
    city: "Prim",
    state: "AR",
    areacode: 870,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72131",
    city: "Quitman",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72132",
    city: "Redfield",
    state: "AR",
    areacode: 501,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72133",
    city: "Reydell",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72134",
    city: "Roe",
    state: "AR",
    areacode: 870,
    county: "Monroe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72135",
    city: "Roland",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72136",
    city: "Romance",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72137",
    city: "Rose Bud",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72139",
    city: "Russell",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72140",
    city: "Saint Charles",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72141",
    city: "Scotland",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72142",
    city: "Scott",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72143",
    city: "Searcy",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72145",
    city: "Searcy",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72149",
    city: "Searcy",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72150",
    city: "Sheridan",
    state: "AR",
    areacode: 870,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72152",
    city: "Sherrill",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72153",
    city: "Shirley",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72156",
    city: "Solgohachia",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72157",
    city: "Springfield",
    state: "AR",
    areacode: 501,
    county: "Conway",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72158",
    city: "Benton",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72160",
    city: "Stuttgart",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72164",
    city: "Sweet Home",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72165",
    city: "Thida",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72166",
    city: "Tichnor",
    state: "AR",
    areacode: 870,
    county: "Arkansas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72167",
    city: "Traskwood",
    state: "AR",
    areacode: 501,
    county: "Saline",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72168",
    city: "Tucker",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72169",
    city: "Tupelo",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72170",
    city: "Ulm",
    state: "AR",
    areacode: 870,
    county: "Prairie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72173",
    city: "Vilonia",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72175",
    city: "Wabbaseka",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72176",
    city: "Ward",
    state: "AR",
    areacode: 501,
    county: "Lonoke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72178",
    city: "West Point",
    state: "AR",
    areacode: 501,
    county: "White",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72179",
    city: "Wilburn",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72180",
    city: "Woodson",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72181",
    city: "Wooster",
    state: "AR",
    areacode: 501,
    county: "Faulkner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72182",
    city: "Wright",
    state: "AR",
    areacode: 870,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72183",
    city: "Wrightsville",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72190",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72199",
    city: "North Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72201",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72202",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72203",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72204",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72205",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72206",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72207",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72209",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72210",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72211",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72212",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72214",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72215",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72216",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72217",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72219",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72221",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72222",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72223",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72225",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72227",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72231",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72255",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72260",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72295",
    city: "Little Rock",
    state: "AR",
    areacode: 501,
    county: "Pulaski",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72301",
    city: "West Memphis",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72303",
    city: "West Memphis",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72310",
    city: "Armorel",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72311",
    city: "Aubrey",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72312",
    city: "Barton",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72313",
    city: "Bassett",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72315",
    city: "Blytheville",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72316",
    city: "Blytheville",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72319",
    city: "Gosnell",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72320",
    city: "Brickeys",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72321",
    city: "Burdette",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72322",
    city: "Caldwell",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72324",
    city: "Cherry Valley",
    state: "AR",
    areacode: 870,
    county: "Cross",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72325",
    city: "Clarkedale",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72326",
    city: "Colt",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72327",
    city: "Crawfordsville",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72328",
    city: "Crumrod",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72329",
    city: "Driver",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72330",
    city: "Dyess",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72331",
    city: "Earle",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72332",
    city: "Edmondson",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72333",
    city: "Elaine",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72335",
    city: "Forrest City",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72336",
    city: "Forrest City",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72338",
    city: "Frenchmans Bayou",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72339",
    city: "Gilmore",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72340",
    city: "Goodwin",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72341",
    city: "Haynes",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72342",
    city: "Helena",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72346",
    city: "Heth",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72347",
    city: "Hickory Ridge",
    state: "AR",
    areacode: 870,
    county: "Cross",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72348",
    city: "Hughes",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72350",
    city: "Joiner",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72351",
    city: "Keiser",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72352",
    city: "La Grange",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72353",
    city: "Lambrook",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72354",
    city: "Lepanto",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72355",
    city: "Lexa",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72358",
    city: "Luxora",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72359",
    city: "Madison",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72360",
    city: "Marianna",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72364",
    city: "Marion",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72365",
    city: "Marked Tree",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72366",
    city: "Marvell",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72367",
    city: "Mellwood",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72368",
    city: "Moro",
    state: "AR",
    areacode: 870,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72369",
    city: "Oneida",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72370",
    city: "Osceola",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72372",
    city: "Palestine",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72373",
    city: "Parkin",
    state: "AR",
    areacode: 870,
    county: "Cross",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72374",
    city: "Poplar Grove",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72376",
    city: "Proctor",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72377",
    city: "Rivervale",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72379",
    city: "Snow Lake",
    state: "AR",
    areacode: 870,
    county: "Desha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72383",
    city: "Turner",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72384",
    city: "Turrell",
    state: "AR",
    areacode: 870,
    county: "Crittenden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72386",
    city: "Tyronza",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72387",
    city: "Vanndale",
    state: "AR",
    areacode: 870,
    county: "Cross",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72389",
    city: "Wabash",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72390",
    city: "West Helena",
    state: "AR",
    areacode: 870,
    county: "Phillips",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72391",
    city: "West Ridge",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72392",
    city: "Wheatley",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72394",
    city: "Widener",
    state: "AR",
    areacode: 870,
    county: "Saint Francis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72395",
    city: "Wilson",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72396",
    city: "Wynne",
    state: "AR",
    areacode: 870,
    county: "Cross",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72401",
    city: "Jonesboro",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72402",
    city: "Jonesboro",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72403",
    city: "Jonesboro",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72404",
    city: "Jonesboro",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72405",
    city: "Jonesboro",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72410",
    city: "Alicia",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72411",
    city: "Bay",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72412",
    city: "Beech Grove",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72413",
    city: "Biggers",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72414",
    city: "Black Oak",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72415",
    city: "Black Rock",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72416",
    city: "Bono",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72417",
    city: "Brookland",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72419",
    city: "Caraway",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72421",
    city: "Cash",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72422",
    city: "Corning",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72424",
    city: "Datto",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72425",
    city: "Delaplaine",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72426",
    city: "Dell",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72427",
    city: "Egypt",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72428",
    city: "Etowah",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72429",
    city: "Fisher",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72430",
    city: "Greenway",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72431",
    city: "Grubbs",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72432",
    city: "Harrisburg",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72433",
    city: "Hoxie",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72434",
    city: "Imboden",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72435",
    city: "Knobel",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72436",
    city: "Lafe",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72437",
    city: "Lake City",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72438",
    city: "Leachville",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72440",
    city: "Lynn",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72441",
    city: "McDougal",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72442",
    city: "Manila",
    state: "AR",
    areacode: 870,
    county: "Mississippi",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72443",
    city: "Marmaduke",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72444",
    city: "Maynard",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72445",
    city: "Minturn",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72447",
    city: "Monette",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72449",
    city: "O Kean",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72450",
    city: "Paragould",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72451",
    city: "Paragould",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72453",
    city: "Peach Orchard",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72454",
    city: "Piggott",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72455",
    city: "Pocahontas",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72456",
    city: "Pollard",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72457",
    city: "Portia",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72458",
    city: "Powhatan",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72459",
    city: "Ravenden",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72460",
    city: "Ravenden Springs",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72461",
    city: "Rector",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72462",
    city: "Reyno",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72464",
    city: "Saint Francis",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72465",
    city: "Sedgwick",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72466",
    city: "Smithville",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72467",
    city: "State University",
    state: "AR",
    areacode: 870,
    county: "Craighead",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72469",
    city: "Strawberry",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72470",
    city: "Success",
    state: "AR",
    areacode: 870,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72471",
    city: "Swifton",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72472",
    city: "Trumann",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72473",
    city: "Tuckerman",
    state: "AR",
    areacode: 870,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72474",
    city: "Walcott",
    state: "AR",
    areacode: 870,
    county: "Greene",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72475",
    city: "Waldenburg",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72476",
    city: "Walnut Ridge",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72478",
    city: "Warm Springs",
    state: "AR",
    areacode: 870,
    county: "Randolph",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72479",
    city: "Weiner",
    state: "AR",
    areacode: 870,
    county: "Poinsett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72482",
    city: "Williford",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72501",
    city: "Batesville",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72503",
    city: "Batesville",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72512",
    city: "Horseshoe Bend",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72513",
    city: "Ash Flat",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72515",
    city: "Bexar",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72517",
    city: "Brockwell",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72519",
    city: "Calico Rock",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72520",
    city: "Camp",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72521",
    city: "Cave City",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72522",
    city: "Charlotte",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72523",
    city: "Concord",
    state: "AR",
    areacode: 870,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72524",
    city: "Cord",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72525",
    city: "Cherokee Village",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72526",
    city: "Cushman",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72527",
    city: "Desha",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72528",
    city: "Dolph",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72529",
    city: "Cherokee Village",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72530",
    city: "Drasco",
    state: "AR",
    areacode: 870,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72531",
    city: "Elizabeth",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72532",
    city: "Evening Shade",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72533",
    city: "Fifty Six",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72534",
    city: "Floral",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72536",
    city: "Franklin",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72537",
    city: "Gamaliel",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72538",
    city: "Gepp",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72539",
    city: "Glencoe",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72540",
    city: "Guion",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72542",
    city: "Hardy",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72543",
    city: "Heber Springs",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72544",
    city: "Henderson",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72545",
    city: "Heber Springs",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72546",
    city: "Ida",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72550",
    city: "Locust Grove",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72553",
    city: "Magness",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72554",
    city: "Mammoth Spring",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72555",
    city: "Marcella",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72556",
    city: "Melbourne",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72560",
    city: "Mountain View",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72561",
    city: "Mount Pleasant",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72562",
    city: "Newark",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72564",
    city: "Oil Trough",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72565",
    city: "Oxford",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72566",
    city: "Pineville",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72567",
    city: "Pleasant Grove",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72568",
    city: "Pleasant Plains",
    state: "AR",
    areacode: 501,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72569",
    city: "Poughkeepsie",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72571",
    city: "Rosie",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72572",
    city: "Saffell",
    state: "AR",
    areacode: 870,
    county: "Lawrence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72573",
    city: "Sage",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72575",
    city: "Salado",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72576",
    city: "Salem",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72577",
    city: "Sidney",
    state: "AR",
    areacode: 870,
    county: "Sharp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72578",
    city: "Sturkie",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72579",
    city: "Sulphur Rock",
    state: "AR",
    areacode: 870,
    county: "Independence",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72581",
    city: "Tumbling Shoals",
    state: "AR",
    areacode: 501,
    county: "Cleburne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72583",
    city: "Viola",
    state: "AR",
    areacode: 870,
    county: "Fulton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72584",
    city: "Violet Hill",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72585",
    city: "Wideman",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72587",
    city: "Wiseman",
    state: "AR",
    areacode: 870,
    county: "Izard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72601",
    city: "Harrison",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72602",
    city: "Harrison",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72611",
    city: "Alpena",
    state: "AR",
    areacode: 870,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72613",
    city: "Beaver",
    state: "AR",
    areacode: 479,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72615",
    city: "Bergman",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72616",
    city: "Berryville",
    state: "AR",
    areacode: 870,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72617",
    city: "Big Flat",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72619",
    city: "Bull Shoals",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72623",
    city: "Clarkridge",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72624",
    city: "Compton",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72626",
    city: "Cotter",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72628",
    city: "Deer",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72629",
    city: "Dennard",
    state: "AR",
    areacode: 501,
    county: "Van Buren",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72630",
    city: "Diamond City",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72631",
    city: "Eureka Springs",
    state: "AR",
    areacode: 479,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72632",
    city: "Eureka Springs",
    state: "AR",
    areacode: 479,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72633",
    city: "Everton",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72634",
    city: "Flippin",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72635",
    city: "Gassville",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72636",
    city: "Gilbert",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72638",
    city: "Green Forest",
    state: "AR",
    areacode: 870,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72639",
    city: "Harriet",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72640",
    city: "Hasty",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72641",
    city: "Jasper",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72642",
    city: "Lakeview",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72643",
    city: "Lead Hill",
    state: "MO",
    areacode: 417,
    county: "Taney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72644",
    city: "Lead Hill",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72645",
    city: "Leslie",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72648",
    city: "Marble Falls",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72650",
    city: "Marshall",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72651",
    city: "Midway",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72653",
    city: "Mountain Home",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72654",
    city: "Mountain Home",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72655",
    city: "Mount Judea",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72657",
    city: "Timbo",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72658",
    city: "Norfork",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72659",
    city: "Norfork",
    state: "AR",
    areacode: 870,
    county: "Baxter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72660",
    city: "Oak Grove",
    state: "AR",
    areacode: 870,
    county: "Carroll",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72661",
    city: "Oakland",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72662",
    city: "Omaha",
    state: "AR",
    areacode: 870,
    county: "Boone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72663",
    city: "Onia",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72666",
    city: "Parthenon",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72668",
    city: "Peel",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72669",
    city: "Pindall",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72670",
    city: "Ponca",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72672",
    city: "Pyatt",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72675",
    city: "Saint Joe",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72677",
    city: "Summit",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72679",
    city: "Tilly",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72680",
    city: "Timbo",
    state: "AR",
    areacode: 870,
    county: "Stone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72682",
    city: "Valley Springs",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72683",
    city: "Vendor",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72685",
    city: "Western Grove",
    state: "AR",
    areacode: 870,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72686",
    city: "Witts Springs",
    state: "AR",
    areacode: 870,
    county: "Searcy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72687",
    city: "Yellville",
    state: "AR",
    areacode: 870,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72701",
    city: "Fayetteville",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72702",
    city: "Fayetteville",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72703",
    city: "Fayetteville",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72704",
    city: "Fayetteville",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72711",
    city: "Avoca",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72712",
    city: "Bentonville",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72713",
    city: "Bentonville",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72714",
    city: "Bella Vista",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72715",
    city: "Bella Vista",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72716",
    city: "Bentonville",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72717",
    city: "Canehill",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72718",
    city: "Cave Springs",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72719",
    city: "Centerton",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72721",
    city: "Combs",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72722",
    city: "Decatur",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72727",
    city: "Elkins",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72728",
    city: "Elm Springs",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72729",
    city: "Evansville",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72730",
    city: "Farmington",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72732",
    city: "Garfield",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72733",
    city: "Gateway",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72734",
    city: "Gentry",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72735",
    city: "Goshen",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72736",
    city: "Gravette",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72737",
    city: "Greenland",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72738",
    city: "Hindsville",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72739",
    city: "Hiwasse",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72740",
    city: "Huntsville",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72741",
    city: "Johnson",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72742",
    city: "Kingston",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72744",
    city: "Lincoln",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72745",
    city: "Lowell",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72747",
    city: "Maysville",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72749",
    city: "Morrow",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72751",
    city: "Pea Ridge",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72752",
    city: "Pettigrew",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72753",
    city: "Prairie Grove",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72756",
    city: "Rogers",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72757",
    city: "Rogers",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72758",
    city: "Rogers",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72760",
    city: "Saint Paul",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72761",
    city: "Siloam Springs",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72762",
    city: "Springdale",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72764",
    city: "Springdale",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72765",
    city: "Springdale",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72766",
    city: "Springdale",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72768",
    city: "Sulphur Springs",
    state: "AR",
    areacode: 479,
    county: "Benton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72769",
    city: "Summers",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72770",
    city: "Tontitown",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72773",
    city: "Wesley",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72774",
    city: "West Fork",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72776",
    city: "Witter",
    state: "AR",
    areacode: 479,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72801",
    city: "Russellville",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72802",
    city: "Russellville",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72811",
    city: "Russellville",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72812",
    city: "Russellville",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72820",
    city: "Alix",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72821",
    city: "Altus",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72823",
    city: "Atkins",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72824",
    city: "Belleville",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72826",
    city: "Blue Mountain",
    state: "AR",
    areacode: 501,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72827",
    city: "Bluffton",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72828",
    city: "Briggsville",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72829",
    city: "Centerville",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72830",
    city: "Clarksville",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72832",
    city: "Coal Hill",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72833",
    city: "Danville",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72834",
    city: "Dardanelle",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72835",
    city: "Delaware",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72837",
    city: "Dover",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72838",
    city: "Gravelly",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72839",
    city: "Hagarville",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72840",
    city: "Hartman",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72841",
    city: "Harvey",
    state: "AR",
    areacode: 479,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72842",
    city: "Havana",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72843",
    city: "Hector",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72845",
    city: "Knoxville",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72846",
    city: "Lamar",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72847",
    city: "London",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72851",
    city: "New Blaine",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72852",
    city: "Oark",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72853",
    city: "Ola",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72854",
    city: "Ozone",
    state: "AR",
    areacode: 479,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72855",
    city: "Paris",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72856",
    city: "Pelsor",
    state: "AR",
    areacode: 870,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72857",
    city: "Plainview",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72858",
    city: "Pottsville",
    state: "AR",
    areacode: 479,
    county: "Pope",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72860",
    city: "Rover",
    state: "AR",
    areacode: 479,
    county: "Yell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72863",
    city: "Scranton",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72865",
    city: "Subiaco",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72901",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72902",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72903",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72904",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72905",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72906",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72908",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72913",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72914",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72916",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72917",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72918",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72919",
    city: "Fort Smith",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72921",
    city: "Alma",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72923",
    city: "Barling",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72926",
    city: "Boles",
    state: "AR",
    areacode: 479,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72927",
    city: "Booneville",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72928",
    city: "Branch",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72930",
    city: "Cecil",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72932",
    city: "Cedarville",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72933",
    city: "Charleston",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72934",
    city: "Chester",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72935",
    city: "Dyer",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72936",
    city: "Greenwood",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72937",
    city: "Hackett",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72938",
    city: "Hartford",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72940",
    city: "Huntington",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72941",
    city: "Lavaca",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72943",
    city: "Magazine",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72944",
    city: "Mansfield",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72945",
    city: "Midland",
    state: "AR",
    areacode: 479,
    county: "Sebastian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72946",
    city: "Mountainburg",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72947",
    city: "Mulberry",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72948",
    city: "Natural Dam",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72949",
    city: "Ozark",
    state: "AR",
    areacode: 479,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72950",
    city: "Parks",
    state: "AR",
    areacode: 479,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72951",
    city: "Ratcliff",
    state: "AR",
    areacode: 479,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72952",
    city: "Rudy",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72955",
    city: "Uniontown",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72956",
    city: "Van Buren",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72957",
    city: "Van Buren",
    state: "AR",
    areacode: 479,
    county: "Crawford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72958",
    city: "Waldron",
    state: "AR",
    areacode: 479,
    county: "Scott",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "72959",
    city: "Winslow",
    state: "AR",
    areacode: 479,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73001",
    city: "Albert",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73002",
    city: "Alex",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73003",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73004",
    city: "Amber",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73005",
    city: "Anadarko",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73006",
    city: "Apache",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73007",
    city: "Arcadia",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73008",
    city: "Bethany",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73009",
    city: "Binger",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73010",
    city: "Blanchard",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73011",
    city: "Bradley",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73012",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73013",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73014",
    city: "Calumet",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73015",
    city: "Carnegie",
    state: "OK",
    areacode: 580,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73016",
    city: "Cashion",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73017",
    city: "Cement",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73018",
    city: "Chickasha",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73019",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73020",
    city: "Choctaw",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73021",
    city: "Colony",
    state: "OK",
    areacode: 405,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73022",
    city: "Concho",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73023",
    city: "Chickasha",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73024",
    city: "Corn",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73025",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73026",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73027",
    city: "Coyle",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73028",
    city: "Crescent",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73029",
    city: "Cyril",
    state: "OK",
    areacode: 580,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73030",
    city: "Davis",
    state: "OK",
    areacode: 580,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73031",
    city: "Dibble",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73032",
    city: "Dougherty",
    state: "OK",
    areacode: 580,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73033",
    city: "Eakly",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73034",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73036",
    city: "El Reno",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73038",
    city: "Fort Cobb",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73039",
    city: "Davis",
    state: "OK",
    areacode: 580,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73040",
    city: "Geary",
    state: "OK",
    areacode: 405,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73041",
    city: "Gotebo",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73042",
    city: "Gracemont",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73043",
    city: "Greenfield",
    state: "OK",
    areacode: 405,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73044",
    city: "Guthrie",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73045",
    city: "Harrah",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73047",
    city: "Hinton",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73048",
    city: "Hydro",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73049",
    city: "Jones",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73050",
    city: "Langston",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73051",
    city: "Lexington",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73052",
    city: "Lindsay",
    state: "OK",
    areacode: 405,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73053",
    city: "Lookeba",
    state: "OK",
    areacode: 405,
    county: "Caddo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73054",
    city: "Luther",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73055",
    city: "Marlow",
    state: "OK",
    areacode: 405,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73056",
    city: "Marshall",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73057",
    city: "Maysville",
    state: "OK",
    areacode: 405,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73058",
    city: "Meridian",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73059",
    city: "Minco",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73061",
    city: "Morrison",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73062",
    city: "Mountain View",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73063",
    city: "Mulhall",
    state: "OK",
    areacode: 405,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73064",
    city: "Mustang",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73065",
    city: "Newcastle",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73066",
    city: "Nicoma Park",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73067",
    city: "Ninnekah",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73068",
    city: "Noble",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73069",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73070",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73071",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73072",
    city: "Norman",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73073",
    city: "Orlando",
    state: "OK",
    areacode: 580,
    county: "Logan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73074",
    city: "Paoli",
    state: "OK",
    areacode: 405,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73075",
    city: "Pauls Valley",
    state: "OK",
    areacode: 405,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73077",
    city: "Perry",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73078",
    city: "Piedmont",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73079",
    city: "Pocasset",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73080",
    city: "Purcell",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73082",
    city: "Rush Springs",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73083",
    city: "Edmond",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73084",
    city: "Spencer",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73085",
    city: "Yukon",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73086",
    city: "Sulphur",
    state: "OK",
    areacode: 580,
    county: "Murray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73089",
    city: "Tuttle",
    state: "OK",
    areacode: 580,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73090",
    city: "Union City",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73092",
    city: "Verden",
    state: "OK",
    areacode: 405,
    county: "Grady",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73093",
    city: "Washington",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73095",
    city: "Wayne",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73096",
    city: "Weatherford",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73097",
    city: "Wheatland",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73098",
    city: "Wynnewood",
    state: "OK",
    areacode: 405,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73099",
    city: "Yukon",
    state: "OK",
    areacode: 405,
    county: "Canadian",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73101",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73102",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73103",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73104",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73105",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73106",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73107",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73108",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73109",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73110",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73111",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73112",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73113",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73114",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73115",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73116",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73117",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73118",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73119",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73120",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73121",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73122",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73123",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73124",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73125",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73126",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73127",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73128",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73129",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73130",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73131",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73132",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73134",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73135",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73136",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73137",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73139",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73140",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73141",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73142",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73143",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73144",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73145",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73146",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73147",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73148",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73149",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73150",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73151",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73152",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73153",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73154",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73155",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73156",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73157",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73159",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73160",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73162",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73163",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73164",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73165",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73167",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73169",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73170",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73172",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73173",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73178",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73179",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73184",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73189",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73190",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73194",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73195",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73196",
    city: "Oklahoma City",
    state: "OK",
    areacode: 405,
    county: "Oklahoma",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73301",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73344",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73401",
    city: "Ardmore",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73402",
    city: "Ardmore",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73403",
    city: "Ardmore",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73425",
    city: "Countyline",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73430",
    city: "Burneyville",
    state: "OK",
    areacode: 580,
    county: "Love",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73432",
    city: "Coleman",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73433",
    city: "Elmore City",
    state: "OK",
    areacode: 580,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73434",
    city: "Foster",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73435",
    city: "Fox",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73436",
    city: "Gene Autry",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73437",
    city: "Graham",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73438",
    city: "Healdton",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73439",
    city: "Kingston",
    state: "OK",
    areacode: 580,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73440",
    city: "Lebanon",
    state: "OK",
    areacode: 580,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73441",
    city: "Leon",
    state: "OK",
    areacode: 580,
    county: "Love",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73442",
    city: "Loco",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73443",
    city: "Lone Grove",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73444",
    city: "Hennepin",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73446",
    city: "Madill",
    state: "OK",
    areacode: 580,
    county: "Marshall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73447",
    city: "Mannsville",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73448",
    city: "Marietta",
    state: "OK",
    areacode: 580,
    county: "Love",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73449",
    city: "Mead",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73450",
    city: "Milburn",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73453",
    city: "Overbrook",
    state: "OK",
    areacode: 580,
    county: "Love",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73455",
    city: "Ravia",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73456",
    city: "Ringling",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73458",
    city: "Springer",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73459",
    city: "Thackerville",
    state: "OK",
    areacode: 580,
    county: "Love",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73460",
    city: "Tishomingo",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73461",
    city: "Wapanucka",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73463",
    city: "Wilson",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73481",
    city: "Ratliff City",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73487",
    city: "Tatums",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73488",
    city: "Tussy",
    state: "OK",
    areacode: 580,
    county: "Carter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73491",
    city: "Velma",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73501",
    city: "Lawton",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73502",
    city: "Lawton",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73503",
    city: "Fort Sill",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73505",
    city: "Lawton",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73506",
    city: "Lawton",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73507",
    city: "Lawton",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73520",
    city: "Addington",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73521",
    city: "Altus",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73522",
    city: "Altus",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73523",
    city: "Altus AFB",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73526",
    city: "Blair",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73527",
    city: "Cache",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73528",
    city: "Chattanooga",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73529",
    city: "Comanche",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73530",
    city: "Davidson",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73531",
    city: "Devol",
    state: "OK",
    areacode: 580,
    county: "Cotton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73532",
    city: "Duke",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73533",
    city: "Duncan",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73534",
    city: "Duncan",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73536",
    city: "Duncan",
    state: "OK",
    areacode: 580,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73537",
    city: "Eldorado",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73538",
    city: "Elgin",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73539",
    city: "Elmer",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73540",
    city: "Faxon",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73541",
    city: "Fletcher",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73542",
    city: "Frederick",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73543",
    city: "Geronimo",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73544",
    city: "Gould",
    state: "OK",
    areacode: 580,
    county: "Harmon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73546",
    city: "Grandfield",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73547",
    city: "Granite",
    state: "OK",
    areacode: 580,
    county: "Greer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73548",
    city: "Hastings",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73549",
    city: "Headrick",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73550",
    city: "Hollis",
    state: "OK",
    areacode: 580,
    county: "Harmon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73551",
    city: "Hollister",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73552",
    city: "Indiahoma",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73553",
    city: "Loveland",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73554",
    city: "Mangum",
    state: "OK",
    areacode: 580,
    county: "Greer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73555",
    city: "Manitou",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73556",
    city: "Martha",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73557",
    city: "Medicine Park",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73558",
    city: "Meers",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73559",
    city: "Mountain Park",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73560",
    city: "Olustee",
    state: "OK",
    areacode: 580,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73561",
    city: "Oscar",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73562",
    city: "Randlett",
    state: "OK",
    areacode: 580,
    county: "Cotton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73564",
    city: "Roosevelt",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73565",
    city: "Ryan",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73566",
    city: "Snyder",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73567",
    city: "Sterling",
    state: "OK",
    areacode: 580,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73568",
    city: "Temple",
    state: "OK",
    areacode: 580,
    county: "Cotton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73569",
    city: "Terral",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73570",
    city: "Tipton",
    state: "OK",
    areacode: 580,
    county: "Tillman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73571",
    city: "Vinson",
    state: "OK",
    areacode: 580,
    county: "Harmon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73572",
    city: "Walters",
    state: "OK",
    areacode: 580,
    county: "Cotton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73573",
    city: "Waurika",
    state: "OK",
    areacode: 580,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73601",
    city: "Clinton",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73620",
    city: "Arapaho",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73622",
    city: "Bessie",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73624",
    city: "Burns Flat",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73625",
    city: "Butler",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73626",
    city: "Canute",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73627",
    city: "Carter",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73628",
    city: "Cheyenne",
    state: "OK",
    areacode: 580,
    county: "Roger Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73632",
    city: "Cordell",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73638",
    city: "Crawford",
    state: "OK",
    areacode: 580,
    county: "Roger Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73639",
    city: "Custer City",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73641",
    city: "Dill City",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73642",
    city: "Durham",
    state: "OK",
    areacode: 580,
    county: "Roger Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73644",
    city: "Elk City",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73645",
    city: "Erick",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73646",
    city: "Fay",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73647",
    city: "Foss",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73648",
    city: "Elk City",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73650",
    city: "Hammon",
    state: "OK",
    areacode: 580,
    county: "Roger Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73651",
    city: "Hobart",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73654",
    city: "Leedey",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73655",
    city: "Lone Wolf",
    state: "OK",
    areacode: 580,
    county: "Kiowa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73658",
    city: "Oakwood",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73659",
    city: "Putnam",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73660",
    city: "Reydon",
    state: "OK",
    areacode: 580,
    county: "Roger Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73661",
    city: "Rocky",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73662",
    city: "Sayre",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73663",
    city: "Seiling",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73664",
    city: "Sentinel",
    state: "OK",
    areacode: 580,
    county: "Washita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73666",
    city: "Sweetwater",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73667",
    city: "Taloga",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73668",
    city: "Texola",
    state: "OK",
    areacode: 580,
    county: "Beckham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73669",
    city: "Thomas",
    state: "OK",
    areacode: 580,
    county: "Custer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73673",
    city: "Willow",
    state: "OK",
    areacode: 580,
    county: "Greer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73701",
    city: "Enid",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73702",
    city: "Enid",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73703",
    city: "Enid",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73705",
    city: "Enid",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73706",
    city: "Enid",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73716",
    city: "Aline",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73717",
    city: "Alva",
    state: "OK",
    areacode: 580,
    county: "Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73718",
    city: "Ames",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73719",
    city: "Amorita",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73720",
    city: "Bison",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73722",
    city: "Burlington",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73724",
    city: "Canton",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73726",
    city: "Carmen",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73727",
    city: "Carrier",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73728",
    city: "Cherokee",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73729",
    city: "Cleo Springs",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73730",
    city: "Covington",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73731",
    city: "Dacoma",
    state: "OK",
    areacode: 580,
    county: "Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73733",
    city: "Douglas",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73734",
    city: "Dover",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73735",
    city: "Drummond",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73736",
    city: "Fairmont",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73737",
    city: "Fairview",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73738",
    city: "Garber",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73739",
    city: "Goltry",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73741",
    city: "Helena",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73742",
    city: "Hennessey",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73743",
    city: "Hillsdale",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73744",
    city: "Hitchcock",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73746",
    city: "Hopeton",
    state: "OK",
    areacode: 580,
    county: "Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73747",
    city: "Isabella",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73749",
    city: "Jet",
    state: "OK",
    areacode: 580,
    county: "Alfalfa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73750",
    city: "Kingfisher",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73753",
    city: "Kremlin",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73754",
    city: "Lahoma",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73755",
    city: "Longdale",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73756",
    city: "Loyal",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73757",
    city: "Lucien",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73758",
    city: "Manchester",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73759",
    city: "Medford",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73760",
    city: "Meno",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73761",
    city: "Nash",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73762",
    city: "Okarche",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73763",
    city: "Okeene",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73764",
    city: "Omega",
    state: "OK",
    areacode: 405,
    county: "Kingfisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73766",
    city: "Pond Creek",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73768",
    city: "Ringwood",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73770",
    city: "Southard",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73771",
    city: "Wakita",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73772",
    city: "Watonga",
    state: "OK",
    areacode: 580,
    county: "Blaine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73773",
    city: "Waukomis",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73801",
    city: "Woodward",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73802",
    city: "Woodward",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73832",
    city: "Arnett",
    state: "OK",
    areacode: 580,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73834",
    city: "Buffalo",
    state: "OK",
    areacode: 580,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73835",
    city: "Camargo",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73838",
    city: "Chester",
    state: "OK",
    areacode: 580,
    county: "Major",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73840",
    city: "Fargo",
    state: "OK",
    areacode: 580,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73841",
    city: "Fort Supply",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73842",
    city: "Freedom",
    state: "OK",
    areacode: 580,
    county: "Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73843",
    city: "Gage",
    state: "OK",
    areacode: 580,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73844",
    city: "Gate",
    state: "OK",
    areacode: 580,
    county: "Beaver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73848",
    city: "Laverne",
    state: "OK",
    areacode: 580,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73851",
    city: "May",
    state: "OK",
    areacode: 580,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73852",
    city: "Mooreland",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73853",
    city: "Mutual",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73855",
    city: "Rosston",
    state: "OK",
    areacode: 580,
    county: "Harper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73857",
    city: "Sharon",
    state: "OK",
    areacode: 580,
    county: "Woodward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73858",
    city: "Shattuck",
    state: "OK",
    areacode: 580,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73859",
    city: "Vici",
    state: "OK",
    areacode: 580,
    county: "Dewey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73860",
    city: "Waynoka",
    state: "OK",
    areacode: 580,
    county: "Woods",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73901",
    city: "Adams",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73931",
    city: "Balko",
    state: "OK",
    areacode: 580,
    county: "Beaver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73932",
    city: "Beaver",
    state: "OK",
    areacode: 580,
    county: "Beaver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73933",
    city: "Boise City",
    state: "OK",
    areacode: 580,
    county: "Cimarron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73937",
    city: "Felt",
    state: "OK",
    areacode: 580,
    county: "Cimarron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73938",
    city: "Forgan",
    state: "OK",
    areacode: 580,
    county: "Beaver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73939",
    city: "Goodwell",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73942",
    city: "Guymon",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73944",
    city: "Hardesty",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73945",
    city: "Hooker",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73946",
    city: "Kenton",
    state: "OK",
    areacode: 580,
    county: "Cimarron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73947",
    city: "Keyes",
    state: "OK",
    areacode: 580,
    county: "Cimarron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73949",
    city: "Texhoma",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73950",
    city: "Turpin",
    state: "OK",
    areacode: 580,
    county: "Beaver",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73951",
    city: "Tyrone",
    state: "OK",
    areacode: 580,
    county: "Texas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "73960",
    city: "Texhoma",
    state: "TX",
    areacode: 806,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74001",
    city: "Avant",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74002",
    city: "Barnsdall",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74003",
    city: "Bartlesville",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74004",
    city: "Bartlesville",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74005",
    city: "Bartlesville",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74006",
    city: "Bartlesville",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74008",
    city: "Bixby",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74010",
    city: "Bristow",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74011",
    city: "Broken Arrow",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74012",
    city: "Broken Arrow",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74013",
    city: "Broken Arrow",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74014",
    city: "Broken Arrow",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74015",
    city: "Catoosa",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74016",
    city: "Chelsea",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74017",
    city: "Claremore",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74018",
    city: "Claremore",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74019",
    city: "Claremore",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74020",
    city: "Cleveland",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74021",
    city: "Collinsville",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74022",
    city: "Copan",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74023",
    city: "Cushing",
    state: "OK",
    areacode: 918,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74026",
    city: "Davenport",
    state: "OK",
    areacode: 918,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74027",
    city: "Delaware",
    state: "OK",
    areacode: 918,
    county: "Nowata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74028",
    city: "Depew",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74029",
    city: "Dewey",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74030",
    city: "Drumright",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74031",
    city: "Foyil",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74032",
    city: "Glencoe",
    state: "OK",
    areacode: 580,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74033",
    city: "Glenpool",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74034",
    city: "Hallett",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74035",
    city: "Hominy",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74036",
    city: "Inola",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74037",
    city: "Jenks",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74038",
    city: "Jennings",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74039",
    city: "Kellyville",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74041",
    city: "Kiefer",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74042",
    city: "Lenapah",
    state: "OK",
    areacode: 918,
    county: "Nowata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74043",
    city: "Leonard",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74044",
    city: "Mannford",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74045",
    city: "Maramec",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74046",
    city: "Milfay",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74047",
    city: "Mounds",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74048",
    city: "Nowata",
    state: "OK",
    areacode: 918,
    county: "Nowata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74050",
    city: "Oakhurst",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74051",
    city: "Ochelata",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74052",
    city: "Oilton",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74053",
    city: "Oologah",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74054",
    city: "Osage",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74055",
    city: "Owasso",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74056",
    city: "Pawhuska",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74058",
    city: "Pawnee",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74059",
    city: "Perkins",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74060",
    city: "Prue",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74061",
    city: "Ramona",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74062",
    city: "Ripley",
    state: "OK",
    areacode: 918,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74063",
    city: "Sand Springs",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74066",
    city: "Sapulpa",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74067",
    city: "Sapulpa",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74068",
    city: "Shamrock",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74070",
    city: "Skiatook",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74071",
    city: "Slick",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74072",
    city: "South Coffeyville",
    state: "OK",
    areacode: 918,
    county: "Nowata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74073",
    city: "Sperry",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74074",
    city: "Stillwater",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74075",
    city: "Stillwater",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74076",
    city: "Stillwater",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74077",
    city: "Stillwater",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74078",
    city: "Stillwater",
    state: "OK",
    areacode: 405,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74079",
    city: "Stroud",
    state: "OK",
    areacode: 918,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74080",
    city: "Talala",
    state: "OK",
    areacode: 918,
    county: "Rogers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74081",
    city: "Terlton",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74082",
    city: "Vera",
    state: "OK",
    areacode: 918,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74083",
    city: "Wann",
    state: "OK",
    areacode: 918,
    county: "Nowata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74084",
    city: "Wynona",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74085",
    city: "Yale",
    state: "OK",
    areacode: 918,
    county: "Payne",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74101",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74102",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74103",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74104",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74105",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74106",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74107",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74108",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74110",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74112",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74114",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74115",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74116",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74117",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74119",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74120",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74121",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74126",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74127",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74128",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74129",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74130",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74131",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Creek",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74132",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74133",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74134",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74135",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74136",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74137",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74141",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74145",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74146",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74147",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74148",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74149",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74150",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74152",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74153",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74155",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74156",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74157",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74158",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74159",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74169",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74170",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74171",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74172",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74182",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74186",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74187",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74192",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74193",
    city: "Tulsa",
    state: "OK",
    areacode: 918,
    county: "Tulsa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74301",
    city: "Vinita",
    state: "OK",
    areacode: 918,
    county: "Craig",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74330",
    city: "Adair",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74331",
    city: "Afton",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74332",
    city: "Big Cabin",
    state: "OK",
    areacode: 918,
    county: "Craig",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74333",
    city: "Bluejacket",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74335",
    city: "Cardin",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74337",
    city: "Chouteau",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74338",
    city: "Colcord",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74339",
    city: "Commerce",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74340",
    city: "Disney",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74342",
    city: "Eucha",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74343",
    city: "Fairland",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74344",
    city: "Grove",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74345",
    city: "Grove",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74346",
    city: "Jay",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74347",
    city: "Kansas",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74349",
    city: "Ketchum",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74350",
    city: "Langley",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74352",
    city: "Locust Grove",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74354",
    city: "Miami",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74355",
    city: "Miami",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74358",
    city: "North Miami",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74359",
    city: "Oaks",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74360",
    city: "Picher",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74361",
    city: "Pryor",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74362",
    city: "Pryor",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74363",
    city: "Quapaw",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74364",
    city: "Rose",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74365",
    city: "Salina",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74366",
    city: "Spavinaw",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74367",
    city: "Strang",
    state: "OK",
    areacode: 918,
    county: "Mayes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74368",
    city: "Twin Oaks",
    state: "OK",
    areacode: 918,
    county: "Delaware",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74369",
    city: "Welch",
    state: "OK",
    areacode: 918,
    county: "Craig",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74370",
    city: "Wyandotte",
    state: "OK",
    areacode: 918,
    county: "Ottawa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74401",
    city: "Muskogee",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74402",
    city: "Muskogee",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74403",
    city: "Muskogee",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74421",
    city: "Beggs",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74422",
    city: "Boynton",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74423",
    city: "Braggs",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74425",
    city: "Canadian",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74426",
    city: "Checotah",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74427",
    city: "Cookson",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74428",
    city: "Council Hill",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74429",
    city: "Coweta",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74430",
    city: "Crowder",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74431",
    city: "Dewar",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74432",
    city: "Eufaula",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74434",
    city: "Fort Gibson",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74435",
    city: "Gore",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74436",
    city: "Haskell",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74437",
    city: "Henryetta",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74438",
    city: "Hitchita",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74439",
    city: "Braggs",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74440",
    city: "Hoyt",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74441",
    city: "Hulbert",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74442",
    city: "Indianola",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74444",
    city: "Moodys",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74445",
    city: "Morris",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74446",
    city: "Okay",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74447",
    city: "Okmulgee",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74450",
    city: "Oktaha",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74451",
    city: "Park Hill",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74452",
    city: "Peggs",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74454",
    city: "Porter",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74455",
    city: "Porum",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74456",
    city: "Preston",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74457",
    city: "Proctor",
    state: "OK",
    areacode: 918,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74458",
    city: "Redbird",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74459",
    city: "Rentiesville",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74460",
    city: "Schulter",
    state: "OK",
    areacode: 918,
    county: "Okmulgee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74461",
    city: "Stidham",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74462",
    city: "Stigler",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74463",
    city: "Taft",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74464",
    city: "Tahlequah",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74465",
    city: "Tahlequah",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74467",
    city: "Wagoner",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74468",
    city: "Wainwright",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74469",
    city: "Warner",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74470",
    city: "Webbers Falls",
    state: "OK",
    areacode: 918,
    county: "Muskogee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74471",
    city: "Welling",
    state: "OK",
    areacode: 918,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74472",
    city: "Whitefield",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74477",
    city: "Wagoner",
    state: "OK",
    areacode: 918,
    county: "Wagoner",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74501",
    city: "McAlester",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74502",
    city: "McAlester",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74521",
    city: "Albion",
    state: "OK",
    areacode: 918,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74522",
    city: "Alderson",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74523",
    city: "Antlers",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74525",
    city: "Atoka",
    state: "OK",
    areacode: 580,
    county: "Atoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74528",
    city: "Blanco",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74529",
    city: "Blocker",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74530",
    city: "Bromide",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74531",
    city: "Calvin",
    state: "OK",
    areacode: 405,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74533",
    city: "Caney",
    state: "OK",
    areacode: 580,
    county: "Atoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74534",
    city: "Centrahoma",
    state: "OK",
    areacode: 580,
    county: "Coal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74535",
    city: "Clarita",
    state: "OK",
    areacode: 580,
    county: "Coal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74536",
    city: "Clayton",
    state: "OK",
    areacode: 918,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74538",
    city: "Coalgate",
    state: "OK",
    areacode: 580,
    county: "Coal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74540",
    city: "Daisy",
    state: "OK",
    areacode: 580,
    county: "Atoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74543",
    city: "Finley",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74545",
    city: "Gowen",
    state: "OK",
    areacode: 918,
    county: "Latimer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74546",
    city: "Haileyville",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74547",
    city: "Hartshorne",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74549",
    city: "Honobia",
    state: "OK",
    areacode: 580,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74552",
    city: "Kinta",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74553",
    city: "Kiowa",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74554",
    city: "Krebs",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74555",
    city: "Lane",
    state: "OK",
    areacode: 580,
    county: "Atoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74556",
    city: "Lehigh",
    state: "OK",
    areacode: 580,
    county: "Coal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74557",
    city: "Moyers",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74558",
    city: "Nashoba",
    state: "OK",
    areacode: 918,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74559",
    city: "Panola",
    state: "OK",
    areacode: 918,
    county: "Latimer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74560",
    city: "Pittsburg",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74561",
    city: "Quinton",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74562",
    city: "Rattan",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74563",
    city: "Red Oak",
    state: "OK",
    areacode: 918,
    county: "Latimer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74565",
    city: "Savanna",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74567",
    city: "Snow",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74569",
    city: "Stringtown",
    state: "OK",
    areacode: 580,
    county: "Atoka",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74570",
    city: "Stuart",
    state: "OK",
    areacode: 918,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74571",
    city: "Talihina",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74572",
    city: "Tupelo",
    state: "OK",
    areacode: 580,
    county: "Coal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74574",
    city: "Tuskahoma",
    state: "OK",
    areacode: 918,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74576",
    city: "Wardville",
    state: "OK",
    areacode: 918,
    county: "Pittsburg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74577",
    city: "Whitesboro",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74578",
    city: "Wilburton",
    state: "OK",
    areacode: 918,
    county: "Latimer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74601",
    city: "Ponca City",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74602",
    city: "Ponca City",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74604",
    city: "Ponca City",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74630",
    city: "Billings",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74631",
    city: "Blackwell",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74632",
    city: "Braman",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74633",
    city: "Burbank",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74636",
    city: "Deer Creek",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74637",
    city: "Fairfax",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74640",
    city: "Hunter",
    state: "OK",
    areacode: 580,
    county: "Garfield",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74641",
    city: "Kaw City",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74643",
    city: "Lamont",
    state: "OK",
    areacode: 580,
    county: "Grant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74644",
    city: "Marland",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74646",
    city: "Nardin",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74647",
    city: "Newkirk",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74650",
    city: "Ralston",
    state: "OK",
    areacode: 918,
    county: "Pawnee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74651",
    city: "Red Rock",
    state: "OK",
    areacode: 580,
    county: "Noble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74652",
    city: "Shidler",
    state: "OK",
    areacode: 918,
    county: "Osage",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74653",
    city: "Tonkawa",
    state: "OK",
    areacode: 580,
    county: "Kay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74701",
    city: "Durant",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74702",
    city: "Durant",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74720",
    city: "Achille",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74721",
    city: "Albany",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74722",
    city: "Battiest",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74723",
    city: "Bennington",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74724",
    city: "Bethel",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74726",
    city: "Bokchito",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74727",
    city: "Boswell",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74728",
    city: "Broken Bow",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74729",
    city: "Caddo",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74730",
    city: "Calera",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74731",
    city: "Cartwright",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74733",
    city: "Colbert",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74734",
    city: "Eagletown",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74735",
    city: "Fort Towson",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74736",
    city: "Garvin",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74737",
    city: "Golden",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74738",
    city: "Grant",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74740",
    city: "Haworth",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74741",
    city: "Hendrix",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74743",
    city: "Hugo",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74745",
    city: "Idabel",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74747",
    city: "Kemp",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74748",
    city: "Kenefic",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74750",
    city: "Millerton",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74752",
    city: "Pickens",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74753",
    city: "Platter",
    state: "OK",
    areacode: 580,
    county: "Bryan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74754",
    city: "Ringold",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74755",
    city: "Rufe",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74756",
    city: "Sawyer",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74759",
    city: "Soper",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74760",
    city: "Spencerville",
    state: "OK",
    areacode: 580,
    county: "Pushmataha",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74761",
    city: "Swink",
    state: "OK",
    areacode: 580,
    county: "Choctaw",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74764",
    city: "Valliant",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74766",
    city: "Wright City",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74801",
    city: "Shawnee",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74802",
    city: "Shawnee",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74804",
    city: "Shawnee",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74818",
    city: "Seminole",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74820",
    city: "Ada",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74821",
    city: "Ada",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74824",
    city: "Agra",
    state: "OK",
    areacode: 918,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74825",
    city: "Allen",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74826",
    city: "Asher",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74827",
    city: "Atwood",
    state: "OK",
    areacode: 405,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74829",
    city: "Boley",
    state: "OK",
    areacode: 918,
    county: "Okfuskee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74830",
    city: "Bowlegs",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74831",
    city: "Byars",
    state: "OK",
    areacode: 405,
    county: "McClain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74832",
    city: "Carney",
    state: "OK",
    areacode: 405,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74833",
    city: "Castle",
    state: "OK",
    areacode: 918,
    county: "Okfuskee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74834",
    city: "Chandler",
    state: "OK",
    areacode: 405,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74836",
    city: "Connerville",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74837",
    city: "Cromwell",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74839",
    city: "Dustin",
    state: "OK",
    areacode: 918,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74840",
    city: "Earlsboro",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74842",
    city: "Fittstown",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74843",
    city: "Fitzhugh",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74844",
    city: "Francis",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74845",
    city: "Hanna",
    state: "OK",
    areacode: 918,
    county: "McIntosh",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74848",
    city: "Holdenville",
    state: "OK",
    areacode: 405,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74849",
    city: "Konawa",
    state: "OK",
    areacode: 580,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74850",
    city: "Lamar",
    state: "OK",
    areacode: 405,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74851",
    city: "McLoud",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74852",
    city: "Macomb",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74854",
    city: "Maud",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74855",
    city: "Meeker",
    state: "OK",
    areacode: 405,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74856",
    city: "Mill Creek",
    state: "OK",
    areacode: 580,
    county: "Johnston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74857",
    city: "Newalla",
    state: "OK",
    areacode: 405,
    county: "Cleveland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74859",
    city: "Okemah",
    state: "OK",
    areacode: 918,
    county: "Okfuskee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74860",
    city: "Paden",
    state: "OK",
    areacode: 405,
    county: "Okfuskee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74864",
    city: "Prague",
    state: "OK",
    areacode: 405,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74865",
    city: "Roff",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74866",
    city: "Saint Louis",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74867",
    city: "Sasakwa",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74868",
    city: "Seminole",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74869",
    city: "Sparks",
    state: "OK",
    areacode: 918,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74871",
    city: "Stonewall",
    state: "OK",
    areacode: 580,
    county: "Pontotoc",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74872",
    city: "Stratford",
    state: "OK",
    areacode: 580,
    county: "Garvin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74873",
    city: "Tecumseh",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74875",
    city: "Tryon",
    state: "OK",
    areacode: 918,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74878",
    city: "Wanette",
    state: "OK",
    areacode: 405,
    county: "Pottawatomie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74880",
    city: "Weleetka",
    state: "OK",
    areacode: 405,
    county: "Okfuskee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74881",
    city: "Wellston",
    state: "OK",
    areacode: 405,
    county: "Lincoln",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74883",
    city: "Wetumka",
    state: "OK",
    areacode: 405,
    county: "Hughes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74884",
    city: "Wewoka",
    state: "OK",
    areacode: 405,
    county: "Seminole",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74901",
    city: "Arkoma",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74902",
    city: "Pocola",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74930",
    city: "Bokoshe",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74931",
    city: "Bunch",
    state: "OK",
    areacode: 918,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74932",
    city: "Cameron",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74935",
    city: "Fanshawe",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74936",
    city: "Gans",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74937",
    city: "Heavener",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74939",
    city: "Hodgen",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74940",
    city: "Howe",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74941",
    city: "Keota",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74942",
    city: "Leflore",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74943",
    city: "Lequire",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74944",
    city: "McCurtain",
    state: "OK",
    areacode: 918,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74945",
    city: "Marble City",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74946",
    city: "Moffett",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74947",
    city: "Monroe",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74948",
    city: "Muldrow",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74949",
    city: "Muse",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74951",
    city: "Panama",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74953",
    city: "Poteau",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74954",
    city: "Roland",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74955",
    city: "Sallisaw",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74956",
    city: "Shady Point",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74957",
    city: "Smithville",
    state: "OK",
    areacode: 580,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74959",
    city: "Spiro",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74960",
    city: "Stilwell",
    state: "OK",
    areacode: 918,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74962",
    city: "Vian",
    state: "OK",
    areacode: 918,
    county: "Sequoyah",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74963",
    city: "Watson",
    state: "OK",
    areacode: 580,
    county: "McCurtain",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74964",
    city: "Watts",
    state: "OK",
    areacode: 918,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74965",
    city: "Westville",
    state: "OK",
    areacode: 918,
    county: "Adair",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "74966",
    city: "Wister",
    state: "OK",
    areacode: 918,
    county: "Leflore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75001",
    city: "Addison",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75002",
    city: "Allen",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75006",
    city: "Carrollton",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75007",
    city: "Carrollton",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75009",
    city: "Celina",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75010",
    city: "Carrollton",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75011",
    city: "Carrollton",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75013",
    city: "Allen",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75014",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75015",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75016",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75017",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75019",
    city: "Coppell",
    state: "TX",
    areacode: 940,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75020",
    city: "Denison",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75021",
    city: "Denison",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75022",
    city: "Flower Mound",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75023",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75024",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75025",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75026",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75027",
    city: "Flower Mound",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75028",
    city: "Flower Mound",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75029",
    city: "Lewisville",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75030",
    city: "Rowlett",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75032",
    city: "Rockwall",
    state: "TX",
    areacode: 972,
    county: "Rockwall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75033",
    city: "Frisco",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75034",
    city: "Frisco",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75035",
    city: "Frisco",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75036",
    city: "Frisco",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75038",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75039",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75040",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75041",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75042",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75043",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75044",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75045",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75046",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75047",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75048",
    city: "Sachse",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75049",
    city: "Garland",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75050",
    city: "Grand Prairie",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75051",
    city: "Grand Prairie",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75052",
    city: "Grand Prairie",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75053",
    city: "Grand Prairie",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75054",
    city: "Grand Prairie",
    state: "TX",
    areacode: 972,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75056",
    city: "The Colony",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75057",
    city: "Lewisville",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75058",
    city: "Gunter",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75059",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75060",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75061",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75062",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75063",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75064",
    city: "Irving",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75065",
    city: "Lake Dallas",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75067",
    city: "Lewisville",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75068",
    city: "Little Elm",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75069",
    city: "McKinney",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75070",
    city: "McKinney",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75071",
    city: "McKinney",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75072",
    city: "McKinney",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75074",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75075",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75076",
    city: "Pottsboro",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75077",
    city: "Lewisville",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75078",
    city: "Prosper",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75080",
    city: "Richardson",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75081",
    city: "Richardson",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75082",
    city: "Richardson",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75083",
    city: "Richardson",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75085",
    city: "Richardson",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75086",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75087",
    city: "Rockwall",
    state: "TX",
    areacode: 972,
    county: "Rockwall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75088",
    city: "Rowlett",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75089",
    city: "Rowlett",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75090",
    city: "Sherman",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75091",
    city: "Sherman",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75092",
    city: "Sherman",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75093",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75094",
    city: "Plano",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75097",
    city: "Weston",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75098",
    city: "Wylie",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75099",
    city: "Coppell",
    state: "TX",
    areacode: 940,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75101",
    city: "Bardwell",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75102",
    city: "Barry",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75103",
    city: "Canton",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75104",
    city: "Cedar Hill",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75105",
    city: "Chatfield",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75106",
    city: "Cedar Hill",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75109",
    city: "Corsicana",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75110",
    city: "Corsicana",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75114",
    city: "Crandall",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75115",
    city: "Desoto",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75116",
    city: "Duncanville",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75117",
    city: "Edgewood",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75118",
    city: "Elmo",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75119",
    city: "Ennis",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75120",
    city: "Ennis",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75121",
    city: "Copeville",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75123",
    city: "Desoto",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75124",
    city: "Eustace",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75125",
    city: "Ferris",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75126",
    city: "Forney",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75127",
    city: "Fruitvale",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75132",
    city: "Fate",
    state: "TX",
    areacode: 972,
    county: "Rockwall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75134",
    city: "Lancaster",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75135",
    city: "Caddo Mills",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75137",
    city: "Duncanville",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75138",
    city: "Duncanville",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75140",
    city: "Grand Saline",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75141",
    city: "Hutchins",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75142",
    city: "Kaufman",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75143",
    city: "Kemp",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75144",
    city: "Kerens",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75146",
    city: "Lancaster",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75147",
    city: "Mabank",
    state: "TX",
    areacode: 903,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75148",
    city: "Malakoff",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75149",
    city: "Mesquite",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75150",
    city: "Mesquite",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75151",
    city: "Corsicana",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75152",
    city: "Palmer",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75153",
    city: "Powell",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75154",
    city: "Red Oak",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75155",
    city: "Rice",
    state: "TX",
    areacode: 972,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75156",
    city: "Mabank",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75157",
    city: "Rosser",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75158",
    city: "Scurry",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75159",
    city: "Seagoville",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75160",
    city: "Terrell",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75161",
    city: "Terrell",
    state: "TX",
    areacode: 972,
    county: "Kaufman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75163",
    city: "Trinidad",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75164",
    city: "Josephine",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75165",
    city: "Waxahachie",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75166",
    city: "Lavon",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75167",
    city: "Waxahachie",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75168",
    city: "Waxahachie",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75169",
    city: "Wills Point",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75172",
    city: "Wilmer",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75173",
    city: "Nevada",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75180",
    city: "Balch Springs",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75181",
    city: "Mesquite",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75182",
    city: "Sunnyvale",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75185",
    city: "Mesquite",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75187",
    city: "Mesquite",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75189",
    city: "Royse City",
    state: "TX",
    areacode: 972,
    county: "Rockwall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75201",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75202",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75203",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75204",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75205",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75206",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75207",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75208",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75209",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75210",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75211",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75212",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75214",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75215",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75216",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75217",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75218",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75219",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75220",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75221",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75222",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75223",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75224",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75225",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75226",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75227",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75228",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75229",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75230",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75231",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75232",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75233",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75234",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75235",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75236",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75237",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75238",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75240",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75241",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75242",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75243",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75244",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75246",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75247",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75248",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75249",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75250",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75251",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75252",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75253",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75254",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75260",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75261",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75262",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75263",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75264",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75265",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75266",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75267",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75270",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75275",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75277",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75283",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75284",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75285",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75287",
    city: "Dallas",
    state: "TX",
    areacode: 972,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75301",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75303",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75312",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75313",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75315",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75320",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75326",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75336",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75339",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75342",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75354",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75355",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75356",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75357",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75358",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75359",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75360",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75367",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75368",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75370",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75371",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75372",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75373",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75374",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75376",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75378",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75379",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75380",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75381",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75382",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75389",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75390",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75391",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75392",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75393",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75394",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75395",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75397",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75398",
    city: "Dallas",
    state: "TX",
    areacode: 214,
    county: "Dallas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75401",
    city: "Greenville",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75402",
    city: "Greenville",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75403",
    city: "Greenville",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75404",
    city: "Greenville",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75407",
    city: "Princeton",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75409",
    city: "Anna",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75410",
    city: "Alba",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75411",
    city: "Arthur City",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75412",
    city: "Bagwell",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75413",
    city: "Bailey",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75414",
    city: "Bells",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75415",
    city: "Ben Franklin",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75416",
    city: "Blossom",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75417",
    city: "Bogata",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75418",
    city: "Bonham",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75420",
    city: "Brashear",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75421",
    city: "Brookston",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75422",
    city: "Campbell",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75423",
    city: "Celeste",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75424",
    city: "Blue Ridge",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75425",
    city: "Chicota",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75426",
    city: "Clarksville",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75428",
    city: "Commerce",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75429",
    city: "Commerce",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75431",
    city: "Como",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75432",
    city: "Cooper",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75433",
    city: "Cumby",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75434",
    city: "Cunningham",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75435",
    city: "Deport",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75436",
    city: "Detroit",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75437",
    city: "Dike",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75438",
    city: "Dodd City",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75439",
    city: "Ector",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75440",
    city: "Emory",
    state: "TX",
    areacode: 903,
    county: "Rains",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75441",
    city: "Enloe",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75442",
    city: "Farmersville",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75443",
    city: "Gober",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75444",
    city: "Golden",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75446",
    city: "Honey Grove",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75447",
    city: "Ivanhoe",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75448",
    city: "Klondike",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75449",
    city: "Ladonia",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75450",
    city: "Lake Creek",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75451",
    city: "Leesburg",
    state: "TX",
    areacode: 903,
    county: "Camp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75452",
    city: "Leonard",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75453",
    city: "Lone Oak",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75454",
    city: "Melissa",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75455",
    city: "Mount Pleasant",
    state: "TX",
    areacode: 903,
    county: "Titus",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75456",
    city: "Mount Pleasant",
    state: "TX",
    areacode: 903,
    county: "Titus",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75457",
    city: "Mount Vernon",
    state: "TX",
    areacode: 903,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75458",
    city: "Merit",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75459",
    city: "Howe",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75460",
    city: "Paris",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75461",
    city: "Paris",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75462",
    city: "Paris",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75468",
    city: "Pattonville",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75469",
    city: "Pecan Gap",
    state: "TX",
    areacode: 903,
    county: "Delta",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75470",
    city: "Petty",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75471",
    city: "Pickton",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75472",
    city: "Point",
    state: "TX",
    areacode: 903,
    county: "Rains",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75473",
    city: "Powderly",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75474",
    city: "Quinlan",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75475",
    city: "Randolph",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75476",
    city: "Ravenna",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75477",
    city: "Roxton",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75478",
    city: "Saltillo",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75479",
    city: "Savoy",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75480",
    city: "Scroggins",
    state: "TX",
    areacode: 903,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75481",
    city: "Sulphur Bluff",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75482",
    city: "Sulphur Springs",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75483",
    city: "Sulphur Springs",
    state: "TX",
    areacode: 903,
    county: "Hopkins",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75485",
    city: "Westminster",
    state: "TX",
    areacode: 972,
    county: "Collin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75486",
    city: "Sumner",
    state: "TX",
    areacode: 903,
    county: "Lamar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75487",
    city: "Talco",
    state: "TX",
    areacode: 903,
    county: "Franklin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75488",
    city: "Telephone",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75489",
    city: "Tom Bean",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75490",
    city: "Trenton",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75491",
    city: "Whitewright",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75492",
    city: "Windom",
    state: "TX",
    areacode: 903,
    county: "Fannin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75493",
    city: "Winfield",
    state: "TX",
    areacode: 903,
    county: "Titus",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75494",
    city: "Winnsboro",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75495",
    city: "Van Alstyne",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75496",
    city: "Wolfe City",
    state: "TX",
    areacode: 903,
    county: "Hunt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75497",
    city: "Yantis",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75501",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75503",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75504",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75505",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75507",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75550",
    city: "Annona",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75551",
    city: "Atlanta",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75554",
    city: "Avery",
    state: "TX",
    areacode: 903,
    county: "Red River",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75555",
    city: "Bivins",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75556",
    city: "Bloomburg",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75558",
    city: "Cookville",
    state: "TX",
    areacode: 903,
    county: "Titus",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75559",
    city: "DeKalb",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75560",
    city: "Douglassville",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75561",
    city: "Hooks",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75562",
    city: "Kildare",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75563",
    city: "Linden",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75564",
    city: "Lodi",
    state: "TX",
    areacode: 903,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75565",
    city: "McLeod",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75566",
    city: "Marietta",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75567",
    city: "Maud",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75568",
    city: "Naples",
    state: "TX",
    areacode: 903,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75569",
    city: "Nash",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75570",
    city: "New Boston",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75571",
    city: "Omaha",
    state: "TX",
    areacode: 903,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75572",
    city: "Queen City",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75573",
    city: "Redwater",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75574",
    city: "Simms",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75599",
    city: "Texarkana",
    state: "TX",
    areacode: 903,
    county: "Bowie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75601",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75602",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75603",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75604",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75605",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75606",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75607",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75608",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75615",
    city: "Longview",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75630",
    city: "Avinger",
    state: "TX",
    areacode: 903,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75631",
    city: "Beckville",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75633",
    city: "Carthage",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75636",
    city: "Cason",
    state: "TX",
    areacode: 903,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75637",
    city: "Clayton",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75638",
    city: "Daingerfield",
    state: "TX",
    areacode: 903,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75639",
    city: "De Berry",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75640",
    city: "Diana",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75641",
    city: "Easton",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75642",
    city: "Elysian Fields",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75643",
    city: "Gary",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75644",
    city: "Gilmer",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75645",
    city: "Gilmer",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75647",
    city: "Gladewater",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75650",
    city: "Hallsville",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75651",
    city: "Harleton",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75652",
    city: "Henderson",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75653",
    city: "Henderson",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75654",
    city: "Henderson",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75656",
    city: "Hughes Springs",
    state: "TX",
    areacode: 903,
    county: "Cass",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75657",
    city: "Jefferson",
    state: "TX",
    areacode: 903,
    county: "Marion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75658",
    city: "Joinerville",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75659",
    city: "Jonesville",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75660",
    city: "Judson",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75661",
    city: "Karnack",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75662",
    city: "Kilgore",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75663",
    city: "Kilgore",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75666",
    city: "Laird Hill",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75667",
    city: "Laneville",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75668",
    city: "Lone Star",
    state: "TX",
    areacode: 903,
    county: "Morris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75669",
    city: "Long Branch",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75670",
    city: "Marshall",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75671",
    city: "Marshall",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75672",
    city: "Marshall",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75680",
    city: "Minden",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75681",
    city: "Mount Enterprise",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75682",
    city: "New London",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75683",
    city: "Ore City",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75684",
    city: "Overton",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75685",
    city: "Panola",
    state: "TX",
    areacode: 903,
    county: "Panola",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75686",
    city: "Pittsburg",
    state: "TX",
    areacode: 903,
    county: "Camp",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75687",
    city: "Price",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75688",
    city: "Scottsville",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75689",
    city: "Selman City",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75691",
    city: "Tatum",
    state: "TX",
    areacode: 903,
    county: "Rusk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75692",
    city: "Waskom",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75693",
    city: "White Oak",
    state: "TX",
    areacode: 903,
    county: "Gregg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75694",
    city: "Woodlawn",
    state: "TX",
    areacode: 903,
    county: "Harrison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75701",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75702",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75703",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75704",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75705",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75706",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75707",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75708",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75709",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75710",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75711",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75712",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75713",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75750",
    city: "Arp",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75751",
    city: "Athens",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75752",
    city: "Athens",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75754",
    city: "Ben Wheeler",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75755",
    city: "Big Sandy",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75756",
    city: "Brownsboro",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75757",
    city: "Bullard",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75758",
    city: "Chandler",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75759",
    city: "Cuney",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75760",
    city: "Cushing",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75762",
    city: "Flint",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75763",
    city: "Frankston",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75764",
    city: "Gallatin",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75765",
    city: "Hawkins",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75766",
    city: "Jacksonville",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75770",
    city: "Larue",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75771",
    city: "Lindale",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75772",
    city: "Maydelle",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75773",
    city: "Mineola",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75778",
    city: "Murchison",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75779",
    city: "Neches",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75780",
    city: "New Summerfield",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75782",
    city: "Poynor",
    state: "TX",
    areacode: 903,
    county: "Henderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75783",
    city: "Quitman",
    state: "TX",
    areacode: 903,
    county: "Wood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75784",
    city: "Reklaw",
    state: "TX",
    areacode: 936,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75785",
    city: "Rusk",
    state: "TX",
    areacode: 903,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75788",
    city: "Sacul",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75789",
    city: "Troup",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75790",
    city: "Van",
    state: "TX",
    areacode: 903,
    county: "Van Zandt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75791",
    city: "Whitehouse",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75792",
    city: "Winona",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75797",
    city: "Big Sandy",
    state: "TX",
    areacode: 903,
    county: "Upshur",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75798",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75799",
    city: "Tyler",
    state: "TX",
    areacode: 903,
    county: "Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75801",
    city: "Palestine",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75802",
    city: "Palestine",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75803",
    city: "Palestine",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75831",
    city: "Buffalo",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75832",
    city: "Cayuga",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75833",
    city: "Centerville",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75834",
    city: "Centralia",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75835",
    city: "Crockett",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75838",
    city: "Donie",
    state: "TX",
    areacode: 254,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75839",
    city: "Elkhart",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75840",
    city: "Fairfield",
    state: "TX",
    areacode: 903,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75844",
    city: "Grapeland",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75845",
    city: "Groveton",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75846",
    city: "Jewett",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75847",
    city: "Kennard",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75848",
    city: "Kirvin",
    state: "TX",
    areacode: 903,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75849",
    city: "Latexo",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75850",
    city: "Leona",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75851",
    city: "Lovelady",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75852",
    city: "Midway",
    state: "TX",
    areacode: 903,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75853",
    city: "Montalba",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75855",
    city: "Oakwood",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75856",
    city: "Pennington",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75858",
    city: "Ratcliff",
    state: "TX",
    areacode: 936,
    county: "Houston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75859",
    city: "Streetman",
    state: "TX",
    areacode: 903,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75860",
    city: "Teague",
    state: "TX",
    areacode: 254,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75861",
    city: "Tennessee Colony",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75862",
    city: "Trinity",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75865",
    city: "Woodlake",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75880",
    city: "Tennessee Colony",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75882",
    city: "Palestine",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75884",
    city: "Tennessee Colony",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75886",
    city: "Tennessee Colony",
    state: "TX",
    areacode: 903,
    county: "Anderson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75901",
    city: "Lufkin",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75902",
    city: "Lufkin",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75903",
    city: "Lufkin",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75904",
    city: "Lufkin",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75915",
    city: "Lufkin",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75925",
    city: "Alto",
    state: "TX",
    areacode: 936,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75926",
    city: "Apple Springs",
    state: "TX",
    areacode: 936,
    county: "Trinity",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75928",
    city: "Bon Wier",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75929",
    city: "Broaddus",
    state: "TX",
    areacode: 936,
    county: "San Augustine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75930",
    city: "Bronson",
    state: "TX",
    areacode: 409,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75931",
    city: "Brookeland",
    state: "TX",
    areacode: 409,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75932",
    city: "Burkeville",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75933",
    city: "Call",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75934",
    city: "Camden",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75935",
    city: "Center",
    state: "TX",
    areacode: 936,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75936",
    city: "Chester",
    state: "TX",
    areacode: 936,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75937",
    city: "Chireno",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75938",
    city: "Colmesneil",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75939",
    city: "Corrigan",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75941",
    city: "Diboll",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75942",
    city: "Doucette",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75943",
    city: "Douglass",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75944",
    city: "Etoile",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75946",
    city: "Garrison",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75948",
    city: "Hemphill",
    state: "TX",
    areacode: 409,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75949",
    city: "Huntington",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75951",
    city: "Jasper",
    state: "TX",
    areacode: 409,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75954",
    city: "Joaquin",
    state: "TX",
    areacode: 936,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75956",
    city: "Kirbyville",
    state: "TX",
    areacode: 409,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75958",
    city: "Martinsville",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75959",
    city: "Milam",
    state: "TX",
    areacode: 409,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75960",
    city: "Moscow",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75961",
    city: "Nacogdoches",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75962",
    city: "Nacogdoches",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75963",
    city: "Nacogdoches",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75964",
    city: "Nacogdoches",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75965",
    city: "Nacogdoches",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75966",
    city: "Newton",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75968",
    city: "Pineland",
    state: "TX",
    areacode: 409,
    county: "Sabine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75969",
    city: "Pollok",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75972",
    city: "San Augustine",
    state: "TX",
    areacode: 936,
    county: "San Augustine",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75973",
    city: "Shelbyville",
    state: "TX",
    areacode: 936,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75974",
    city: "Tenaha",
    state: "TX",
    areacode: 936,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75975",
    city: "Timpson",
    state: "TX",
    areacode: 936,
    county: "Shelby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75976",
    city: "Wells",
    state: "TX",
    areacode: 936,
    county: "Cherokee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75977",
    city: "Wiergate",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75978",
    city: "Woden",
    state: "TX",
    areacode: 936,
    county: "Nacogdoches",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75979",
    city: "Woodville",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75980",
    city: "Zavalla",
    state: "TX",
    areacode: 936,
    county: "Angelina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "75990",
    city: "Woodville",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76001",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76002",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76003",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76004",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76005",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76006",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76007",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76008",
    city: "Aledo",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76009",
    city: "Alvarado",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76010",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76011",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76012",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76013",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76014",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76015",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76016",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76017",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76018",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76019",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76020",
    city: "Azle",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76021",
    city: "Bedford",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76022",
    city: "Bedford",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76023",
    city: "Boyd",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76028",
    city: "Burleson",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76031",
    city: "Cleburne",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76033",
    city: "Cleburne",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76034",
    city: "Colleyville",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76035",
    city: "Cresson",
    state: "TX",
    areacode: 817,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76036",
    city: "Crowley",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76039",
    city: "Euless",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76040",
    city: "Euless",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76041",
    city: "Forreston",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76043",
    city: "Glen Rose",
    state: "TX",
    areacode: 254,
    county: "Somervell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76044",
    city: "Godley",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76048",
    city: "Granbury",
    state: "TX",
    areacode: 817,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76049",
    city: "Granbury",
    state: "TX",
    areacode: 817,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76050",
    city: "Grandview",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76051",
    city: "Grapevine",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76052",
    city: "Haslet",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76053",
    city: "Hurst",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76054",
    city: "Hurst",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76055",
    city: "Itasca",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76058",
    city: "Joshua",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76059",
    city: "Keene",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76060",
    city: "Kennedale",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76061",
    city: "Lillian",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76063",
    city: "Mansfield",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76064",
    city: "Maypearl",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76065",
    city: "Midlothian",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76066",
    city: "Millsap",
    state: "TX",
    areacode: 940,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76067",
    city: "Mineral Wells",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76068",
    city: "Mineral Wells",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76070",
    city: "Nemo",
    state: "TX",
    areacode: 254,
    county: "Somervell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76071",
    city: "Newark",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76073",
    city: "Paradise",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76077",
    city: "Rainbow",
    state: "TX",
    areacode: 254,
    county: "Somervell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76078",
    city: "Rhome",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76082",
    city: "Springtown",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76084",
    city: "Venus",
    state: "TX",
    areacode: 972,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76085",
    city: "Weatherford",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76086",
    city: "Weatherford",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76087",
    city: "Weatherford",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76088",
    city: "Weatherford",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76092",
    city: "Southlake",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76093",
    city: "Rio Vista",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76094",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76095",
    city: "Bedford",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76096",
    city: "Arlington",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76097",
    city: "Burleson",
    state: "TX",
    areacode: 817,
    county: "Johnson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76098",
    city: "Azle",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76099",
    city: "Grapevine",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76101",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76102",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76103",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76104",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76105",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76106",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76107",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76108",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76109",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76110",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76111",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76112",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76113",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76114",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76115",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76116",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76117",
    city: "Haltom City",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76118",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76119",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76120",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76121",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76122",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76123",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76124",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76126",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76127",
    city: "Naval Air Station JRB",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76129",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76130",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76131",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76132",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76133",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76134",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76135",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76136",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76137",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76140",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76147",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76148",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76150",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76155",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76161",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76162",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76163",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76164",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76166",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76177",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76179",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76180",
    city: "North Richland Hills",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76181",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76182",
    city: "North Richland Hills",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76185",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76190",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76191",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76192",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76193",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76195",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76196",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76197",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76198",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76199",
    city: "Fort Worth",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76201",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76202",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76203",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76204",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76205",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76206",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76207",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76208",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76209",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76210",
    city: "Denton",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76225",
    city: "Alvord",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76226",
    city: "Argyle",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76227",
    city: "Aubrey",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76228",
    city: "Bellevue",
    state: "TX",
    areacode: 940,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76230",
    city: "Bowie",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76233",
    city: "Collinsville",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76234",
    city: "Decatur",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76238",
    city: "Era",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76239",
    city: "Forestburg",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76240",
    city: "Gainesville",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76241",
    city: "Gainesville",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76244",
    city: "Keller",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76245",
    city: "Gordonville",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76246",
    city: "Greenwood",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76247",
    city: "Justin",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76248",
    city: "Keller",
    state: "TX",
    areacode: 817,
    county: "Tarrant",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76249",
    city: "Krum",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76250",
    city: "Lindsay",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76251",
    city: "Montague",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76252",
    city: "Muenster",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76253",
    city: "Myra",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76255",
    city: "Nocona",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76258",
    city: "Pilot Point",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76259",
    city: "Ponder",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76261",
    city: "Ringgold",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76262",
    city: "Roanoke",
    state: "TX",
    areacode: 817,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76263",
    city: "Rosston",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76264",
    city: "Sadler",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76265",
    city: "Saint Jo",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76266",
    city: "Sanger",
    state: "TX",
    areacode: 940,
    county: "Denton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76267",
    city: "Slidell",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76268",
    city: "Southmayd",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76270",
    city: "Sunset",
    state: "TX",
    areacode: 940,
    county: "Montague",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76271",
    city: "Tioga",
    state: "TX",
    areacode: 254,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76272",
    city: "Valley View",
    state: "TX",
    areacode: 940,
    county: "Cooke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76273",
    city: "Whitesboro",
    state: "TX",
    areacode: 903,
    county: "Grayson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76301",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76302",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76305",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76306",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76307",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76308",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76309",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76310",
    city: "Wichita Falls",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76311",
    city: "Sheppard AFB",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76351",
    city: "Archer City",
    state: "TX",
    areacode: 940,
    county: "Archer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76352",
    city: "Bluegrove",
    state: "TX",
    areacode: 940,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76354",
    city: "Burkburnett",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76357",
    city: "Byers",
    state: "TX",
    areacode: 940,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76360",
    city: "Electra",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76363",
    city: "Goree",
    state: "TX",
    areacode: 940,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76364",
    city: "Harrold",
    state: "TX",
    areacode: 940,
    county: "Wilbarger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76365",
    city: "Henrietta",
    state: "TX",
    areacode: 940,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76366",
    city: "Holliday",
    state: "TX",
    areacode: 940,
    county: "Archer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76367",
    city: "Iowa Park",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76369",
    city: "Kamay",
    state: "TX",
    areacode: 940,
    county: "Wichita",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76370",
    city: "Megargel",
    state: "TX",
    areacode: 940,
    county: "Archer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76371",
    city: "Munday",
    state: "TX",
    areacode: 940,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76372",
    city: "Newcastle",
    state: "TX",
    areacode: 940,
    county: "Young",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76373",
    city: "Oklaunion",
    state: "TX",
    areacode: 940,
    county: "Wilbarger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76374",
    city: "Olney",
    state: "TX",
    areacode: 940,
    county: "Young",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76377",
    city: "Petrolia",
    state: "TX",
    areacode: 940,
    county: "Clay",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76379",
    city: "Scotland",
    state: "TX",
    areacode: 940,
    county: "Archer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76380",
    city: "Seymour",
    state: "TX",
    areacode: 940,
    county: "Baylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76384",
    city: "Vernon",
    state: "TX",
    areacode: 940,
    county: "Wilbarger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76385",
    city: "Vernon",
    state: "TX",
    areacode: 940,
    county: "Wilbarger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76388",
    city: "Weinert",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76389",
    city: "Windthorst",
    state: "TX",
    areacode: 940,
    county: "Archer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76401",
    city: "Stephenville",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76402",
    city: "Stephenville",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76424",
    city: "Breckenridge",
    state: "TX",
    areacode: 254,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76426",
    city: "Bridgeport",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76427",
    city: "Bryson",
    state: "TX",
    areacode: 940,
    county: "Jack",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76429",
    city: "Caddo",
    state: "TX",
    areacode: 254,
    county: "Stephens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76430",
    city: "Albany",
    state: "TX",
    areacode: 325,
    county: "Shackelford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76431",
    city: "Chico",
    state: "TX",
    areacode: 940,
    county: "Wise",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76432",
    city: "Blanket",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76433",
    city: "Bluff Dale",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76435",
    city: "Carbon",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76436",
    city: "Carlton",
    state: "TX",
    areacode: 254,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76437",
    city: "Cisco",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76439",
    city: "Dennis",
    state: "TX",
    areacode: 940,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76442",
    city: "Comanche",
    state: "TX",
    areacode: 325,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76443",
    city: "Cross Plains",
    state: "TX",
    areacode: 254,
    county: "Callahan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76444",
    city: "De Leon",
    state: "TX",
    areacode: 254,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76445",
    city: "Desdemona",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76446",
    city: "Dublin",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76448",
    city: "Eastland",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76449",
    city: "Graford",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76450",
    city: "Graham",
    state: "TX",
    areacode: 940,
    county: "Young",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76452",
    city: "Energy",
    state: "TX",
    areacode: 254,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76453",
    city: "Gordon",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76454",
    city: "Gorman",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76455",
    city: "Gustine",
    state: "TX",
    areacode: 325,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76457",
    city: "Hico",
    state: "TX",
    areacode: 254,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76458",
    city: "Jacksboro",
    state: "TX",
    areacode: 940,
    county: "Jack",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76459",
    city: "Jermyn",
    state: "TX",
    areacode: 940,
    county: "Jack",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76460",
    city: "Loving",
    state: "TX",
    areacode: 940,
    county: "Young",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76461",
    city: "Lingleville",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76462",
    city: "Lipan",
    state: "TX",
    areacode: 254,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76463",
    city: "Mingus",
    state: "TX",
    areacode: 940,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76464",
    city: "Moran",
    state: "TX",
    areacode: 325,
    county: "Shackelford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76465",
    city: "Morgan Mill",
    state: "TX",
    areacode: 254,
    county: "Erath",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76466",
    city: "Olden",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76467",
    city: "Paluxy",
    state: "TX",
    areacode: 254,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76468",
    city: "Proctor",
    state: "TX",
    areacode: 254,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76469",
    city: "Putnam",
    state: "TX",
    areacode: 325,
    county: "Callahan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76470",
    city: "Ranger",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76471",
    city: "Rising Star",
    state: "TX",
    areacode: 254,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76472",
    city: "Santo",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76474",
    city: "Sidney",
    state: "TX",
    areacode: 254,
    county: "Comanche",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76475",
    city: "Strawn",
    state: "TX",
    areacode: 940,
    county: "Eastland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76476",
    city: "Tolar",
    state: "TX",
    areacode: 254,
    county: "Hood",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76481",
    city: "South Bend",
    state: "TX",
    areacode: 940,
    county: "Young",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76483",
    city: "Throckmorton",
    state: "TX",
    areacode: 940,
    county: "Throckmorton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76484",
    city: "Palo Pinto",
    state: "TX",
    areacode: 940,
    county: "Palo Pinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76485",
    city: "Peaster",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76486",
    city: "Perrin",
    state: "TX",
    areacode: 940,
    county: "Jack",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76487",
    city: "Poolville",
    state: "TX",
    areacode: 817,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76490",
    city: "Whitt",
    state: "TX",
    areacode: 940,
    county: "Parker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76491",
    city: "Woodson",
    state: "TX",
    areacode: 940,
    county: "Throckmorton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76501",
    city: "Temple",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76502",
    city: "Temple",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76503",
    city: "Temple",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76504",
    city: "Temple",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76508",
    city: "Temple",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76511",
    city: "Bartlett",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76513",
    city: "Belton",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76518",
    city: "Buckholts",
    state: "TX",
    areacode: 254,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76519",
    city: "Burlington",
    state: "TX",
    areacode: 254,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76520",
    city: "Cameron",
    state: "TX",
    areacode: 254,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76522",
    city: "Copperas Cove",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76523",
    city: "Davilla",
    state: "TX",
    areacode: 254,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76524",
    city: "Eddy",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76525",
    city: "Evant",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76526",
    city: "Flat",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76527",
    city: "Florence",
    state: "TX",
    areacode: 254,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76528",
    city: "Gatesville",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76530",
    city: "Granger",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76531",
    city: "Hamilton",
    state: "TX",
    areacode: 254,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76533",
    city: "Heidenheimer",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76534",
    city: "Holland",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76537",
    city: "Jarrell",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76538",
    city: "Jonesboro",
    state: "TX",
    areacode: 254,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76539",
    city: "Kempner",
    state: "TX",
    areacode: 512,
    county: "Lampasas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76540",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76541",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76542",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76543",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76544",
    city: "Fort Hood",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76547",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76548",
    city: "Harker Heights",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76549",
    city: "Killeen",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76550",
    city: "Lampasas",
    state: "TX",
    areacode: 512,
    county: "Lampasas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76554",
    city: "Little River Academy",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76556",
    city: "Milano",
    state: "TX",
    areacode: 512,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76557",
    city: "Moody",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76558",
    city: "Mound",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76559",
    city: "Nolanville",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76561",
    city: "Oglesby",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76564",
    city: "Pendleton",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76565",
    city: "Pottsville",
    state: "TX",
    areacode: 254,
    county: "Hamilton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76566",
    city: "Purmela",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76567",
    city: "Rockdale",
    state: "TX",
    areacode: 512,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76569",
    city: "Rogers",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76570",
    city: "Rosebud",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76571",
    city: "Salado",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76573",
    city: "Schwertner",
    state: "TX",
    areacode: 254,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76574",
    city: "Taylor",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76577",
    city: "Thorndale",
    state: "TX",
    areacode: 512,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76578",
    city: "Thrall",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76579",
    city: "Troy",
    state: "TX",
    areacode: 254,
    county: "Bell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76596",
    city: "Gatesville",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76597",
    city: "Gatesville",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76598",
    city: "Gatesville",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76599",
    city: "Gatesville",
    state: "TX",
    areacode: 254,
    county: "Coryell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76621",
    city: "Abbott",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76622",
    city: "Aquilla",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76623",
    city: "Avalon",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76624",
    city: "Axtell",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76626",
    city: "Blooming Grove",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76627",
    city: "Blum",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76628",
    city: "Brandon",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76629",
    city: "Bremond",
    state: "TX",
    areacode: 254,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76630",
    city: "Bruceville",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76631",
    city: "Bynum",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76632",
    city: "Chilton",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76633",
    city: "China Spring",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76634",
    city: "Clifton",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76635",
    city: "Coolidge",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76636",
    city: "Covington",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76637",
    city: "Cranfills Gap",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76638",
    city: "Crawford",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76639",
    city: "Dawson",
    state: "TX",
    areacode: 254,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76640",
    city: "Elm Mott",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76641",
    city: "Frost",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76642",
    city: "Groesbeck",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76643",
    city: "Hewitt",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76644",
    city: "Laguna Park",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76645",
    city: "Hillsboro",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76648",
    city: "Hubbard",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76649",
    city: "Iredell",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76650",
    city: "Irene",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76651",
    city: "Italy",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76652",
    city: "Kopperl",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76653",
    city: "Kosse",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76654",
    city: "Leroy",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76655",
    city: "Lorena",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76656",
    city: "Lott",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76657",
    city: "McGregor",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76660",
    city: "Malone",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76661",
    city: "Marlin",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76664",
    city: "Mart",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76665",
    city: "Meridian",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76666",
    city: "Mertens",
    state: "TX",
    areacode: 903,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76667",
    city: "Mexia",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76670",
    city: "Milford",
    state: "TX",
    areacode: 972,
    county: "Ellis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76671",
    city: "Morgan",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76673",
    city: "Mount Calm",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76676",
    city: "Penelope",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76678",
    city: "Prairie Hill",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76679",
    city: "Purdon",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76680",
    city: "Reagan",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76681",
    city: "Richland",
    state: "TX",
    areacode: 903,
    county: "Navarro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76682",
    city: "Riesel",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76684",
    city: "Ross",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76685",
    city: "Satin",
    state: "TX",
    areacode: 254,
    county: "Falls",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76686",
    city: "Tehuacana",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76687",
    city: "Thornton",
    state: "TX",
    areacode: 254,
    county: "Limestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76689",
    city: "Valley Mills",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76690",
    city: "Walnut Springs",
    state: "TX",
    areacode: 254,
    county: "Bosque",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76691",
    city: "West",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76692",
    city: "Whitney",
    state: "TX",
    areacode: 254,
    county: "Hill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76693",
    city: "Wortham",
    state: "TX",
    areacode: 254,
    county: "Freestone",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76701",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76702",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76703",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76704",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76705",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76706",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76707",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76708",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76710",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76711",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76712",
    city: "Woodway",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76714",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76715",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76716",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76797",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76798",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76799",
    city: "Waco",
    state: "TX",
    areacode: 254,
    county: "McLennan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76801",
    city: "Brownwood",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76802",
    city: "Early",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76803",
    city: "Early",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76804",
    city: "Brownwood",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76820",
    city: "Art",
    state: "TX",
    areacode: 325,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76821",
    city: "Ballinger",
    state: "TX",
    areacode: 325,
    county: "Runnels",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76823",
    city: "Bangs",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76824",
    city: "Bend",
    state: "TX",
    areacode: 325,
    county: "San Saba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76825",
    city: "Brady",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76827",
    city: "Brookesmith",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76828",
    city: "Burkett",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76831",
    city: "Castell",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76832",
    city: "Cherokee",
    state: "TX",
    areacode: 325,
    county: "San Saba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76834",
    city: "Coleman",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76836",
    city: "Doole",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76837",
    city: "Eden",
    state: "TX",
    areacode: 325,
    county: "Concho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76841",
    city: "Fort McKavett",
    state: "TX",
    areacode: 325,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76842",
    city: "Fredonia",
    state: "TX",
    areacode: 325,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76844",
    city: "Goldthwaite",
    state: "TX",
    areacode: 325,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76845",
    city: "Gouldbusk",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76848",
    city: "Hext",
    state: "TX",
    areacode: 325,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76849",
    city: "Junction",
    state: "TX",
    areacode: 325,
    county: "Kimble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76852",
    city: "Lohn",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76853",
    city: "Lometa",
    state: "TX",
    areacode: 512,
    county: "Lampasas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76854",
    city: "London",
    state: "TX",
    areacode: 325,
    county: "Kimble",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76855",
    city: "Lowake",
    state: "TX",
    areacode: 325,
    county: "Concho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76856",
    city: "Mason",
    state: "TX",
    areacode: 325,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76857",
    city: "May",
    state: "TX",
    areacode: 254,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76858",
    city: "Melvin",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76859",
    city: "Menard",
    state: "TX",
    areacode: 325,
    county: "Menard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76861",
    city: "Miles",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76862",
    city: "Millersview",
    state: "TX",
    areacode: 325,
    county: "Concho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76864",
    city: "Mullin",
    state: "TX",
    areacode: 325,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76865",
    city: "Norton",
    state: "TX",
    areacode: 325,
    county: "Runnels",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76866",
    city: "Paint Rock",
    state: "TX",
    areacode: 325,
    county: "Concho",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76869",
    city: "Pontotoc",
    state: "TX",
    areacode: 325,
    county: "Mason",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76870",
    city: "Priddy",
    state: "TX",
    areacode: 325,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76871",
    city: "Richland Springs",
    state: "TX",
    areacode: 325,
    county: "San Saba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76872",
    city: "Rochelle",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76873",
    city: "Rockwood",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76874",
    city: "Roosevelt",
    state: "TX",
    areacode: 325,
    county: "Sutton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76875",
    city: "Rowena",
    state: "TX",
    areacode: 325,
    county: "Runnels",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76877",
    city: "San Saba",
    state: "TX",
    areacode: 325,
    county: "San Saba",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76878",
    city: "Santa Anna",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76880",
    city: "Star",
    state: "TX",
    areacode: 325,
    county: "Mills",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76882",
    city: "Talpa",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76883",
    city: "Telegraph",
    state: "TX",
    areacode: 325,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76884",
    city: "Valera",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76885",
    city: "Valley Spring",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76886",
    city: "Veribest",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76887",
    city: "Voca",
    state: "TX",
    areacode: 325,
    county: "McCulloch",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76888",
    city: "Voss",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76890",
    city: "Zephyr",
    state: "TX",
    areacode: 325,
    county: "Brown",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76901",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76902",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76903",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76904",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76905",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76906",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76908",
    city: "Goodfellow AFB",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76909",
    city: "San Angelo",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76930",
    city: "Barnhart",
    state: "TX",
    areacode: 325,
    county: "Irion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76932",
    city: "Big Lake",
    state: "TX",
    areacode: 325,
    county: "Reagan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76933",
    city: "Bronte",
    state: "TX",
    areacode: 325,
    county: "Coke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76934",
    city: "Carlsbad",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76935",
    city: "Christoval",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76936",
    city: "Eldorado",
    state: "TX",
    areacode: 325,
    county: "Schleicher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76937",
    city: "Eola",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76939",
    city: "Knickerbocker",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76940",
    city: "Mereta",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76941",
    city: "Mertzon",
    state: "TX",
    areacode: 325,
    county: "Irion",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76943",
    city: "Ozona",
    state: "TX",
    areacode: 325,
    county: "Crockett",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76945",
    city: "Robert Lee",
    state: "TX",
    areacode: 325,
    county: "Coke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76949",
    city: "Silver",
    state: "TX",
    areacode: 325,
    county: "Coke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76950",
    city: "Sonora",
    state: "TX",
    areacode: 325,
    county: "Sutton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76951",
    city: "Sterling City",
    state: "TX",
    areacode: 325,
    county: "Sterling",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76953",
    city: "Tennyson",
    state: "TX",
    areacode: 325,
    county: "Coke",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76955",
    city: "Vancourt",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76957",
    city: "Wall",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "76958",
    city: "Water Valley",
    state: "TX",
    areacode: 325,
    county: "Tom Green",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77001",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77002",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77003",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77004",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77005",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77006",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77007",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77008",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77009",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77010",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77011",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77012",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77013",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77014",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77015",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77016",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77017",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77018",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77019",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77020",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77021",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77022",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77023",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77024",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77025",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77026",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77027",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77028",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77029",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77030",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77031",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77032",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77033",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77034",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77035",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77036",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77037",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77038",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77039",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77040",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77041",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77042",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77043",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77044",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77045",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77046",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77047",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77048",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77049",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77050",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77051",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77052",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77053",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77054",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77055",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77056",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77057",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77058",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77059",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77060",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77061",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77062",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77063",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77064",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77065",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77066",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77067",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77068",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77069",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77070",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77071",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77072",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77073",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77074",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77075",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77076",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77077",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77078",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77079",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77080",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77081",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77082",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77083",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77084",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77085",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77086",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77087",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77088",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77089",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77090",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77091",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77092",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77093",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77094",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77095",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77096",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77098",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77099",
    city: "Houston",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77201",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77202",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77203",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77204",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77205",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77206",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77207",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77208",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77209",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77210",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77212",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77213",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77215",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77216",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77217",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77218",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77219",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77220",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77221",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77222",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77223",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77224",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77225",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77226",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77227",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77228",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77229",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77230",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77231",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77233",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77234",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77235",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77236",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77237",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77238",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77240",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77241",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77242",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77243",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77244",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77245",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77248",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77249",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77251",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77252",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77253",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77254",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77255",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77256",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77257",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77258",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77259",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77261",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77262",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77263",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77265",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77266",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77267",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77268",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77269",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77270",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77271",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77272",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77273",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77274",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77275",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77277",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77279",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77280",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77282",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77284",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77287",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77288",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77289",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77290",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77291",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77292",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77293",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77297",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77299",
    city: "Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77301",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77302",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77303",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77304",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77305",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77306",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77315",
    city: "North Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77316",
    city: "Montgomery",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77318",
    city: "Willis",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77320",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77325",
    city: "Kingwood",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77326",
    city: "Ace",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77327",
    city: "Cleveland",
    state: "TX",
    areacode: 281,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77328",
    city: "Cleveland",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77331",
    city: "Coldspring",
    state: "TX",
    areacode: 936,
    county: "San Jacinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77332",
    city: "Dallardsville",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77333",
    city: "Dobbin",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77334",
    city: "Dodge",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77335",
    city: "Goodrich",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77336",
    city: "Huffman",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77337",
    city: "Hufsmith",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77338",
    city: "Humble",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77339",
    city: "Kingwood",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77340",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77341",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77342",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77343",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77344",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77345",
    city: "Kingwood",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77346",
    city: "Humble",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77347",
    city: "Humble",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77348",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77349",
    city: "Huntsville",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77350",
    city: "Leggett",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77351",
    city: "Livingston",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77353",
    city: "Magnolia",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77354",
    city: "Magnolia",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77355",
    city: "Magnolia",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77356",
    city: "Montgomery",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77357",
    city: "New Caney",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77358",
    city: "New Waverly",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77359",
    city: "Oakhurst",
    state: "TX",
    areacode: 936,
    county: "San Jacinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77360",
    city: "Onalaska",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77362",
    city: "Pinehurst",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77363",
    city: "Plantersville",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77364",
    city: "Pointblank",
    state: "TX",
    areacode: 936,
    county: "San Jacinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77365",
    city: "Porter",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77367",
    city: "Riverside",
    state: "TX",
    areacode: 936,
    county: "Walker",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77368",
    city: "Romayor",
    state: "TX",
    areacode: 281,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77369",
    city: "Rye",
    state: "TX",
    areacode: 281,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77371",
    city: "Shepherd",
    state: "TX",
    areacode: 936,
    county: "San Jacinto",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77372",
    city: "Splendora",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77373",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77374",
    city: "Thicket",
    state: "TX",
    areacode: 936,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77375",
    city: "Tomball",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77376",
    city: "Votaw",
    state: "TX",
    areacode: 936,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77377",
    city: "Tomball",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77378",
    city: "Willis",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77379",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77380",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77381",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77382",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77383",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77384",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77385",
    city: "Conroe",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77386",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77387",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77388",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77389",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77391",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77393",
    city: "Spring",
    state: "TX",
    areacode: 281,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77396",
    city: "Humble",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77399",
    city: "Livingston",
    state: "TX",
    areacode: 936,
    county: "Polk",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77401",
    city: "Bellaire",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77402",
    city: "Bellaire",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77404",
    city: "Bay City",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77406",
    city: "Richmond",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77407",
    city: "Richmond",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77410",
    city: "Cypress",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77411",
    city: "Alief",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77412",
    city: "Altair",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77413",
    city: "Barker",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77414",
    city: "Bay City",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77415",
    city: "Cedar Lane",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77417",
    city: "Beasley",
    state: "TX",
    areacode: 979,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77418",
    city: "Bellville",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77419",
    city: "Blessing",
    state: "TX",
    areacode: 361,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77420",
    city: "Boling",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77422",
    city: "Brazoria",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77423",
    city: "Brookshire",
    state: "TX",
    areacode: 281,
    county: "Waller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77426",
    city: "Chappell Hill",
    state: "TX",
    areacode: 979,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77428",
    city: "Collegeport",
    state: "TX",
    areacode: 361,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77429",
    city: "Cypress",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77430",
    city: "Damon",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77431",
    city: "Danciger",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77432",
    city: "Danevang",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77433",
    city: "Cypress",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77434",
    city: "Eagle Lake",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77435",
    city: "East Bernard",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77436",
    city: "Egypt",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77437",
    city: "El Campo",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77440",
    city: "Elmaton",
    state: "TX",
    areacode: 361,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77441",
    city: "Fulshear",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77442",
    city: "Garwood",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77443",
    city: "Glen Flora",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77444",
    city: "Guy",
    state: "TX",
    areacode: 979,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77445",
    city: "Hempstead",
    state: "TX",
    areacode: 979,
    county: "Waller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77446",
    city: "Prairie View",
    state: "TX",
    areacode: 979,
    county: "Waller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77447",
    city: "Hockley",
    state: "TX",
    areacode: 936,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77448",
    city: "Hungerford",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77449",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77450",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77451",
    city: "Kendleton",
    state: "TX",
    areacode: 979,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77452",
    city: "Kenney",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77453",
    city: "Lane City",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77454",
    city: "Lissie",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77455",
    city: "Louise",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77456",
    city: "Markham",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77457",
    city: "Matagorda",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77458",
    city: "Midfield",
    state: "TX",
    areacode: 361,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77459",
    city: "Missouri City",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77460",
    city: "Nada",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77461",
    city: "Needville",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77463",
    city: "Old Ocean",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77464",
    city: "Orchard",
    state: "TX",
    areacode: 979,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77465",
    city: "Palacios",
    state: "TX",
    areacode: 361,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77466",
    city: "Pattison",
    state: "TX",
    areacode: 281,
    county: "Waller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77467",
    city: "Pierce",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77468",
    city: "Pledger",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77469",
    city: "Richmond",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77470",
    city: "Rock Island",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77471",
    city: "Rosenberg",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77473",
    city: "San Felipe",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77474",
    city: "Sealy",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77475",
    city: "Sheridan",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77476",
    city: "Simonton",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77477",
    city: "Stafford",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77478",
    city: "Sugar Land",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77479",
    city: "Sugar Land",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77480",
    city: "Sweeny",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77481",
    city: "Thompsons",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77482",
    city: "Van Vleck",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77483",
    city: "Wadsworth",
    state: "TX",
    areacode: 979,
    county: "Matagorda",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77484",
    city: "Waller",
    state: "TX",
    areacode: 936,
    county: "Waller",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77485",
    city: "Wallis",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77486",
    city: "West Columbia",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77487",
    city: "Sugar Land",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77488",
    city: "Wharton",
    state: "TX",
    areacode: 979,
    county: "Wharton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77489",
    city: "Missouri City",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77491",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77492",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77493",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77494",
    city: "Katy",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77496",
    city: "Sugar Land",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77497",
    city: "Stafford",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77498",
    city: "Sugar Land",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77501",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77502",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77503",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77504",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77505",
    city: "Pasadena",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77506",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77507",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77508",
    city: "Pasadena",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77510",
    city: "Santa Fe",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77511",
    city: "Alvin",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77512",
    city: "Alvin",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77514",
    city: "Anahuac",
    state: "TX",
    areacode: 409,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77515",
    city: "Angleton",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77516",
    city: "Angleton",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77517",
    city: "Santa Fe",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77518",
    city: "Bacliff",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77519",
    city: "Batson",
    state: "TX",
    areacode: 936,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77520",
    city: "Baytown",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77521",
    city: "Baytown",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77522",
    city: "Baytown",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77523",
    city: "Baytown",
    state: "TX",
    areacode: 281,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77530",
    city: "Channelview",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77531",
    city: "Clute",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77532",
    city: "Crosby",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77533",
    city: "Daisetta",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77534",
    city: "Danbury",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77535",
    city: "Dayton",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77536",
    city: "Deer Park",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77538",
    city: "Devers",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77539",
    city: "Dickinson",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77541",
    city: "Freeport",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77542",
    city: "Freeport",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77545",
    city: "Fresno",
    state: "TX",
    areacode: 281,
    county: "Fort Bend",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77546",
    city: "Friendswood",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77547",
    city: "Galena Park",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77549",
    city: "Friendswood",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77550",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77551",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77552",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77553",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77554",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77555",
    city: "Galveston",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77560",
    city: "Hankamer",
    state: "TX",
    areacode: 409,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77561",
    city: "Hardin",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77562",
    city: "Highlands",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77563",
    city: "Hitchcock",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77564",
    city: "Hull",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77565",
    city: "Kemah",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77566",
    city: "Lake Jackson",
    state: "TX",
    areacode: 979,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77568",
    city: "La Marque",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77571",
    city: "La Porte",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77572",
    city: "La Porte",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77573",
    city: "League City",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77574",
    city: "League City",
    state: "TX",
    areacode: 281,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77575",
    city: "Liberty",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77577",
    city: "Liverpool",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77578",
    city: "Manvel",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77580",
    city: "Mont Belvieu",
    state: "TX",
    areacode: 281,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77581",
    city: "Pearland",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77582",
    city: "Raywood",
    state: "TX",
    areacode: 936,
    county: "Liberty",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77583",
    city: "Rosharon",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77584",
    city: "Pearland",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77585",
    city: "Saratoga",
    state: "TX",
    areacode: 936,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77586",
    city: "Seabrook",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77587",
    city: "South Houston",
    state: "TX",
    areacode: 713,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77588",
    city: "Pearland",
    state: "TX",
    areacode: 281,
    county: "Brazoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77590",
    city: "Texas City",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77591",
    city: "Texas City",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77592",
    city: "Texas City",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77597",
    city: "Wallisville",
    state: "TX",
    areacode: 409,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77598",
    city: "Webster",
    state: "TX",
    areacode: 281,
    county: "Harris",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77611",
    city: "Bridge City",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77612",
    city: "Buna",
    state: "TX",
    areacode: 409,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77613",
    city: "China",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77614",
    city: "Deweyville",
    state: "TX",
    areacode: 409,
    county: "Newton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77615",
    city: "Evadale",
    state: "TX",
    areacode: 409,
    county: "Jasper",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77616",
    city: "Fred",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77617",
    city: "Gilchrist",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77619",
    city: "Groves",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77622",
    city: "Hamshire",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77623",
    city: "High Island",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77624",
    city: "Hillister",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77625",
    city: "Kountze",
    state: "TX",
    areacode: 409,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77626",
    city: "Mauriceville",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77627",
    city: "Nederland",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77629",
    city: "Nome",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77630",
    city: "Orange",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77631",
    city: "Orange",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77632",
    city: "Orange",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77639",
    city: "Orangefield",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77640",
    city: "Port Arthur",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77641",
    city: "Port Arthur",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77642",
    city: "Port Arthur",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77643",
    city: "Port Arthur",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77650",
    city: "Port Bolivar",
    state: "TX",
    areacode: 409,
    county: "Galveston",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77651",
    city: "Port Neches",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77655",
    city: "Sabine Pass",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77656",
    city: "Silsbee",
    state: "TX",
    areacode: 409,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77657",
    city: "Lumberton",
    state: "TX",
    areacode: 409,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77659",
    city: "Sour Lake",
    state: "TX",
    areacode: 409,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77660",
    city: "Spurger",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77661",
    city: "Stowell",
    state: "TX",
    areacode: 409,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77662",
    city: "Vidor",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77663",
    city: "Village Mills",
    state: "TX",
    areacode: 409,
    county: "Hardin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77664",
    city: "Warren",
    state: "TX",
    areacode: 409,
    county: "Tyler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77665",
    city: "Winnie",
    state: "TX",
    areacode: 409,
    county: "Chambers",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77670",
    city: "Vidor",
    state: "TX",
    areacode: 409,
    county: "Orange",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77701",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77702",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77703",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77704",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77705",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77706",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77707",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77708",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77710",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77713",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77720",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77725",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77726",
    city: "Beaumont",
    state: "TX",
    areacode: 409,
    county: "Jefferson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77801",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77802",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77803",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77805",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77806",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77807",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77808",
    city: "Bryan",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77830",
    city: "Anderson",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77831",
    city: "Bedias",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77833",
    city: "Brenham",
    state: "TX",
    areacode: 979,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77834",
    city: "Brenham",
    state: "TX",
    areacode: 979,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77835",
    city: "Burton",
    state: "TX",
    areacode: 979,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77836",
    city: "Caldwell",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77837",
    city: "Calvert",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77838",
    city: "Chriesman",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77840",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77841",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77842",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77843",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77844",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77845",
    city: "College Station",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77850",
    city: "Concord",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77852",
    city: "Deanville",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77853",
    city: "Dime Box",
    state: "TX",
    areacode: 979,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77855",
    city: "Flynn",
    state: "TX",
    areacode: 936,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77856",
    city: "Franklin",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77857",
    city: "Gause",
    state: "TX",
    areacode: 979,
    county: "Milam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77859",
    city: "Hearne",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77861",
    city: "Iola",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77862",
    city: "Kurten",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77863",
    city: "Lyons",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77864",
    city: "Madisonville",
    state: "TX",
    areacode: 936,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77865",
    city: "Marquez",
    state: "TX",
    areacode: 903,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77866",
    city: "Millican",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77867",
    city: "Mumford",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77868",
    city: "Navasota",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77870",
    city: "New Baden",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77871",
    city: "Normangee",
    state: "TX",
    areacode: 936,
    county: "Leon",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77872",
    city: "North Zulch",
    state: "TX",
    areacode: 936,
    county: "Madison",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77873",
    city: "Richards",
    state: "TX",
    areacode: 936,
    county: "Montgomery",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77875",
    city: "Roans Prairie",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77876",
    city: "Shiro",
    state: "TX",
    areacode: 936,
    county: "Grimes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77878",
    city: "Snook",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77879",
    city: "Somerville",
    state: "TX",
    areacode: 979,
    county: "Burleson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77880",
    city: "Washington",
    state: "TX",
    areacode: 936,
    county: "Washington",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77881",
    city: "Wellborn",
    state: "TX",
    areacode: 979,
    county: "Brazos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77882",
    city: "Wheelock",
    state: "TX",
    areacode: 979,
    county: "Robertson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77901",
    city: "Victoria",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77902",
    city: "Victoria",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77903",
    city: "Victoria",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77904",
    city: "Victoria",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77905",
    city: "Victoria",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77950",
    city: "Austwell",
    state: "TX",
    areacode: 361,
    county: "Refugio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77951",
    city: "Bloomington",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77954",
    city: "Cuero",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77957",
    city: "Edna",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77960",
    city: "Fannin",
    state: "TX",
    areacode: 361,
    county: "Goliad",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77961",
    city: "Francitas",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77962",
    city: "Ganado",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77963",
    city: "Goliad",
    state: "TX",
    areacode: 361,
    county: "Goliad",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77964",
    city: "Hallettsville",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77967",
    city: "Hochheim",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77968",
    city: "Inez",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77969",
    city: "La Salle",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77970",
    city: "La Ward",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77971",
    city: "Lolita",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77973",
    city: "McFaddin",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77974",
    city: "Meyersville",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77975",
    city: "Moulton",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77976",
    city: "Nursery",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77977",
    city: "Placedo",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77978",
    city: "Point Comfort",
    state: "TX",
    areacode: 361,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77979",
    city: "Port Lavaca",
    state: "TX",
    areacode: 361,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77982",
    city: "Port O Connor",
    state: "TX",
    areacode: 361,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77983",
    city: "Seadrift",
    state: "TX",
    areacode: 361,
    county: "Calhoun",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77984",
    city: "Shiner",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77986",
    city: "Sublime",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77987",
    city: "Sweet Home",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77988",
    city: "Telferner",
    state: "TX",
    areacode: 361,
    county: "Victoria",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77989",
    city: "Thomaston",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77990",
    city: "Tivoli",
    state: "TX",
    areacode: 361,
    county: "Refugio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77991",
    city: "Vanderbilt",
    state: "TX",
    areacode: 361,
    county: "Jackson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77993",
    city: "Weesatche",
    state: "TX",
    areacode: 361,
    county: "Goliad",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77994",
    city: "Westhoff",
    state: "TX",
    areacode: 830,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "77995",
    city: "Yoakum",
    state: "TX",
    areacode: 361,
    county: "Lavaca",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78001",
    city: "Artesia Wells",
    state: "TX",
    areacode: 830,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78002",
    city: "Atascosa",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78003",
    city: "Bandera",
    state: "TX",
    areacode: 830,
    county: "Bandera",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78004",
    city: "Bergheim",
    state: "TX",
    areacode: 830,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78005",
    city: "Bigfoot",
    state: "TX",
    areacode: 830,
    county: "Frio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78006",
    city: "Boerne",
    state: "TX",
    areacode: 830,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78007",
    city: "Calliham",
    state: "TX",
    areacode: 361,
    county: "McMullen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78008",
    city: "Campbellton",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78009",
    city: "Castroville",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78010",
    city: "Center Point",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78011",
    city: "Charlotte",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78012",
    city: "Christine",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78013",
    city: "Comfort",
    state: "TX",
    areacode: 830,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78014",
    city: "Cotulla",
    state: "TX",
    areacode: 830,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78015",
    city: "Boerne",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78016",
    city: "Devine",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78017",
    city: "Dilley",
    state: "TX",
    areacode: 830,
    county: "Frio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78019",
    city: "Encinal",
    state: "TX",
    areacode: 956,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78021",
    city: "Fowlerton",
    state: "TX",
    areacode: 830,
    county: "La Salle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78022",
    city: "George West",
    state: "TX",
    areacode: 361,
    county: "Live Oak",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78023",
    city: "Helotes",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78024",
    city: "Hunt",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78025",
    city: "Ingram",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78026",
    city: "Jourdanton",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78027",
    city: "Kendalia",
    state: "TX",
    areacode: 830,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78028",
    city: "Kerrville",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78029",
    city: "Kerrville",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78039",
    city: "La Coste",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78040",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78041",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78042",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78043",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78044",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78045",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78046",
    city: "Laredo",
    state: "TX",
    areacode: 956,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78050",
    city: "Leming",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78052",
    city: "Lytle",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78054",
    city: "Macdona",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78055",
    city: "Medina",
    state: "TX",
    areacode: 830,
    county: "Bandera",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78056",
    city: "Mico",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78057",
    city: "Moore",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78058",
    city: "Mountain Home",
    state: "TX",
    areacode: 830,
    county: "Kerr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78059",
    city: "Natalia",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78060",
    city: "Oakville",
    state: "TX",
    areacode: 361,
    county: "Live Oak",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78061",
    city: "Pearsall",
    state: "TX",
    areacode: 830,
    county: "Frio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78062",
    city: "Peggy",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78063",
    city: "Pipe Creek",
    state: "TX",
    areacode: 830,
    county: "Bandera",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78064",
    city: "Pleasanton",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78065",
    city: "Poteet",
    state: "TX",
    areacode: 830,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78066",
    city: "Rio Medina",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78067",
    city: "San Ygnacio",
    state: "TX",
    areacode: 956,
    county: "Zapata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78069",
    city: "Somerset",
    state: "TX",
    areacode: 210,
    county: "Atascosa",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78070",
    city: "Spring Branch",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78071",
    city: "Three Rivers",
    state: "TX",
    areacode: 361,
    county: "Live Oak",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78072",
    city: "Tilden",
    state: "TX",
    areacode: 361,
    county: "McMullen",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78073",
    city: "Von Ormy",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78074",
    city: "Waring",
    state: "TX",
    areacode: 830,
    county: "Kendall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78075",
    city: "Whitsett",
    state: "TX",
    areacode: 830,
    county: "Live Oak",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78076",
    city: "Zapata",
    state: "TX",
    areacode: 956,
    county: "Zapata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78101",
    city: "Adkins",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78102",
    city: "Beeville",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78104",
    city: "Beeville",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78107",
    city: "Berclair",
    state: "TX",
    areacode: 361,
    county: "Goliad",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78108",
    city: "Cibolo",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78109",
    city: "Converse",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78111",
    city: "Ecleto",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78112",
    city: "Elmendorf",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78113",
    city: "Falls City",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78114",
    city: "Floresville",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78115",
    city: "Geronimo",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78116",
    city: "Gillett",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78117",
    city: "Hobson",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78118",
    city: "Karnes City",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78119",
    city: "Kenedy",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78121",
    city: "La Vernia",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78122",
    city: "Leesville",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78123",
    city: "McQueeney",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78124",
    city: "Marion",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78125",
    city: "Mineral",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78130",
    city: "New Braunfels",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78131",
    city: "New Braunfels",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78132",
    city: "New Braunfels",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78133",
    city: "Canyon Lake",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78135",
    city: "New Braunfels",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78140",
    city: "Nixon",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78141",
    city: "Nordheim",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78142",
    city: "Normanna",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78143",
    city: "Pandora",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78144",
    city: "Panna Maria",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78145",
    city: "Pawnee",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78146",
    city: "Pettus",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78147",
    city: "Poth",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78148",
    city: "Universal City",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78150",
    city: "JBSA Randolph",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78151",
    city: "Runge",
    state: "TX",
    areacode: 830,
    county: "Karnes",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78152",
    city: "Saint Hedwig",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78154",
    city: "Schertz",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78155",
    city: "Seguin",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78156",
    city: "Seguin",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78159",
    city: "Smiley",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78160",
    city: "Stockdale",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78161",
    city: "Sutherland Springs",
    state: "TX",
    areacode: 830,
    county: "Wilson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78162",
    city: "Tuleta",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78163",
    city: "Bulverde",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78164",
    city: "Yorktown",
    state: "TX",
    areacode: 361,
    county: "DeWitt",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78201",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78202",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78203",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78204",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78205",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78206",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78207",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78208",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78209",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78210",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78211",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78212",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78213",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78214",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78215",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78216",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78217",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78218",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78219",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78220",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78221",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78222",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78223",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78224",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78225",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78226",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78227",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78228",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78229",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78230",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78231",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78232",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78233",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78234",
    city: "JBSA Ft Sam Houston",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78235",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78236",
    city: "JBSA Lackland",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78237",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78238",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78239",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78240",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78241",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78242",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78243",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78244",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78245",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78246",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78247",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78248",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78249",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78250",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78251",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78252",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78253",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78254",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78255",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78256",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78257",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78258",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78259",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78260",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78261",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78263",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78264",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78265",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78266",
    city: "San Antonio",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78268",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78269",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78270",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78278",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78279",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78280",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78283",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78284",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78285",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78288",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78289",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78291",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78292",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78293",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78294",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78295",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78296",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78297",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78298",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78299",
    city: "San Antonio",
    state: "TX",
    areacode: 210,
    county: "Bexar",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78330",
    city: "Agua Dulce",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78332",
    city: "Alice",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78333",
    city: "Alice",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78335",
    city: "Aransas Pass",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78336",
    city: "Aransas Pass",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78338",
    city: "Armstrong",
    state: "TX",
    areacode: 361,
    county: "Kenedy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78339",
    city: "Banquete",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78340",
    city: "Bayside",
    state: "TX",
    areacode: 361,
    county: "Refugio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78341",
    city: "Benavides",
    state: "TX",
    areacode: 361,
    county: "Duval",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78342",
    city: "Ben Bolt",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78343",
    city: "Bishop",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78344",
    city: "Bruni",
    state: "TX",
    areacode: 361,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78347",
    city: "Chapman Ranch",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78349",
    city: "Concepcion",
    state: "TX",
    areacode: 361,
    county: "Duval",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78350",
    city: "Dinero",
    state: "TX",
    areacode: 361,
    county: "Live Oak",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78351",
    city: "Driscoll",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78352",
    city: "Edroy",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78353",
    city: "Encino",
    state: "TX",
    areacode: 361,
    county: "Brooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78355",
    city: "Falfurrias",
    state: "TX",
    areacode: 361,
    county: "Brooks",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78357",
    city: "Freer",
    state: "TX",
    areacode: 361,
    county: "Duval",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78358",
    city: "Fulton",
    state: "TX",
    areacode: 361,
    county: "Aransas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78359",
    city: "Gregory",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78360",
    city: "Guerra",
    state: "TX",
    areacode: 361,
    county: "Jim Hogg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78361",
    city: "Hebbronville",
    state: "TX",
    areacode: 361,
    county: "Jim Hogg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78362",
    city: "Ingleside",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78363",
    city: "Kingsville",
    state: "TX",
    areacode: 361,
    county: "Kleberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78364",
    city: "Kingsville",
    state: "TX",
    areacode: 361,
    county: "Kleberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78368",
    city: "Mathis",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78369",
    city: "Mirando City",
    state: "TX",
    areacode: 361,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78370",
    city: "Odem",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78371",
    city: "Oilton",
    state: "TX",
    areacode: 361,
    county: "Webb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78372",
    city: "Orange Grove",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78373",
    city: "Port Aransas",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78374",
    city: "Portland",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78375",
    city: "Premont",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78376",
    city: "Realitos",
    state: "TX",
    areacode: 361,
    county: "Duval",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78377",
    city: "Refugio",
    state: "TX",
    areacode: 361,
    county: "Refugio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78379",
    city: "Riviera",
    state: "TX",
    areacode: 361,
    county: "Kleberg",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78380",
    city: "Robstown",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78381",
    city: "Rockport",
    state: "TX",
    areacode: 361,
    county: "Aransas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78382",
    city: "Rockport",
    state: "TX",
    areacode: 361,
    county: "Aransas",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78383",
    city: "Sandia",
    state: "TX",
    areacode: 361,
    county: "Jim Wells",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78384",
    city: "San Diego",
    state: "TX",
    areacode: 361,
    county: "Duval",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78385",
    city: "Sarita",
    state: "TX",
    areacode: 361,
    county: "Kenedy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78387",
    city: "Sinton",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78389",
    city: "Skidmore",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78390",
    city: "Taft",
    state: "TX",
    areacode: 361,
    county: "San Patricio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78391",
    city: "Tynan",
    state: "TX",
    areacode: 361,
    county: "Bee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78393",
    city: "Woodsboro",
    state: "TX",
    areacode: 361,
    county: "Refugio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78401",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78402",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78403",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78404",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78405",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78406",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78407",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78408",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78409",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78410",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78411",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78412",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78413",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78414",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78415",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78416",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78417",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78418",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78419",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78426",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78427",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78460",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78463",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78465",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78466",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78467",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78468",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78469",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78472",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78480",
    city: "Corpus Christi",
    state: "TX",
    areacode: 361,
    county: "Nueces",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78501",
    city: "McAllen",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78502",
    city: "McAllen",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78503",
    city: "McAllen",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78504",
    city: "McAllen",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78505",
    city: "McAllen",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78516",
    city: "Alamo",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78520",
    city: "Brownsville",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78521",
    city: "Brownsville",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78522",
    city: "Brownsville",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78523",
    city: "Brownsville",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78526",
    city: "Brownsville",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78535",
    city: "Combes",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78536",
    city: "Delmita",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78537",
    city: "Donna",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78538",
    city: "Edcouch",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78539",
    city: "Edinburg",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78540",
    city: "Edinburg",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78541",
    city: "Edinburg",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78542",
    city: "Edinburg",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78543",
    city: "Elsa",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78545",
    city: "Falcon Heights",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78547",
    city: "Garciasville",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78548",
    city: "Grulla",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78549",
    city: "Hargill",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78550",
    city: "Harlingen",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78551",
    city: "Harlingen",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78552",
    city: "Harlingen",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78553",
    city: "Harlingen",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78557",
    city: "Hidalgo",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78558",
    city: "La Blanca",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78559",
    city: "La Feria",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78560",
    city: "La Joya",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78561",
    city: "Lasara",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78562",
    city: "La Villa",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78563",
    city: "Linn",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78564",
    city: "Lopeno",
    state: "TX",
    areacode: 956,
    county: "Zapata",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78565",
    city: "Los Ebanos",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78566",
    city: "Los Fresnos",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78567",
    city: "Los Indios",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78568",
    city: "Lozano",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78569",
    city: "Lyford",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78570",
    city: "Mercedes",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78572",
    city: "Mission",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78573",
    city: "Mission",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78574",
    city: "Mission",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78575",
    city: "Olmito",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78576",
    city: "Penitas",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78577",
    city: "Pharr",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78578",
    city: "Port Isabel",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78579",
    city: "Progreso",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78580",
    city: "Raymondville",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78582",
    city: "Rio Grande City",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78583",
    city: "Rio Hondo",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78584",
    city: "Roma",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78585",
    city: "Salineno",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78586",
    city: "San Benito",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78588",
    city: "San Isidro",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78589",
    city: "San Juan",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78590",
    city: "San Perlita",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78591",
    city: "Santa Elena",
    state: "TX",
    areacode: 956,
    county: "Starr",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78592",
    city: "Santa Maria",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78593",
    city: "Santa Rosa",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78594",
    city: "Sebastian",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78595",
    city: "Sullivan City",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78596",
    city: "Weslaco",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78597",
    city: "South Padre Island",
    state: "TX",
    areacode: 956,
    county: "Cameron",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78598",
    city: "Port Mansfield",
    state: "TX",
    areacode: 956,
    county: "Willacy",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78599",
    city: "Weslaco",
    state: "TX",
    areacode: 956,
    county: "Hidalgo",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78602",
    city: "Bastrop",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78604",
    city: "Belmont",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78605",
    city: "Bertram",
    state: "TX",
    areacode: 512,
    county: "Burnet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78606",
    city: "Blanco",
    state: "TX",
    areacode: 830,
    county: "Blanco",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78607",
    city: "Bluffton",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78608",
    city: "Briggs",
    state: "TX",
    areacode: 512,
    county: "Burnet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78609",
    city: "Buchanan Dam",
    state: "TX",
    areacode: 512,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78610",
    city: "Buda",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78611",
    city: "Burnet",
    state: "TX",
    areacode: 512,
    county: "Burnet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78612",
    city: "Cedar Creek",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78613",
    city: "Cedar Park",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78614",
    city: "Cost",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78615",
    city: "Coupland",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78616",
    city: "Dale",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78617",
    city: "Del Valle",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78618",
    city: "Doss",
    state: "TX",
    areacode: 830,
    county: "Gillespie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78619",
    city: "Driftwood",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78620",
    city: "Dripping Springs",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78621",
    city: "Elgin",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78622",
    city: "Fentress",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78623",
    city: "Fischer",
    state: "TX",
    areacode: 830,
    county: "Comal",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78624",
    city: "Fredericksburg",
    state: "TX",
    areacode: 830,
    county: "Gillespie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78626",
    city: "Georgetown",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78627",
    city: "Georgetown",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78628",
    city: "Georgetown",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78629",
    city: "Gonzales",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78630",
    city: "Cedar Park",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78631",
    city: "Harper",
    state: "TX",
    areacode: 830,
    county: "Gillespie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78632",
    city: "Harwood",
    state: "TX",
    areacode: 830,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78633",
    city: "Georgetown",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78634",
    city: "Hutto",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78635",
    city: "Hye",
    state: "TX",
    areacode: 830,
    county: "Blanco",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78636",
    city: "Johnson City",
    state: "TX",
    areacode: 830,
    county: "Blanco",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78638",
    city: "Kingsbury",
    state: "TX",
    areacode: 830,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78639",
    city: "Kingsland",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78640",
    city: "Kyle",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78641",
    city: "Leander",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78642",
    city: "Liberty Hill",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78643",
    city: "Llano",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78644",
    city: "Lockhart",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78645",
    city: "Leander",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78646",
    city: "Leander",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78648",
    city: "Luling",
    state: "TX",
    areacode: 830,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78650",
    city: "McDade",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78651",
    city: "McNeil",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78652",
    city: "Manchaca",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78653",
    city: "Manor",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78654",
    city: "Marble Falls",
    state: "TX",
    areacode: 830,
    county: "Burnet",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78655",
    city: "Martindale",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78656",
    city: "Maxwell",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78657",
    city: "Horseshoe Bay",
    state: "TX",
    areacode: 830,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78658",
    city: "Ottine",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78659",
    city: "Paige",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78660",
    city: "Pflugerville",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78661",
    city: "Prairie Lea",
    state: "TX",
    areacode: 512,
    county: "Caldwell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78662",
    city: "Red Rock",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78663",
    city: "Round Mountain",
    state: "TX",
    areacode: 830,
    county: "Blanco",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78664",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78665",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78666",
    city: "San Marcos",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78667",
    city: "San Marcos",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78669",
    city: "Spicewood",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78670",
    city: "Staples",
    state: "TX",
    areacode: 512,
    county: "Guadalupe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78671",
    city: "Stonewall",
    state: "TX",
    areacode: 830,
    county: "Gillespie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78672",
    city: "Tow",
    state: "TX",
    areacode: 325,
    county: "Llano",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78673",
    city: "Walburg",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78674",
    city: "Weir",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78675",
    city: "Willow City",
    state: "TX",
    areacode: 830,
    county: "Gillespie",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78676",
    city: "Wimberley",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78677",
    city: "Wrightsboro",
    state: "TX",
    areacode: 830,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78680",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78681",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78682",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78683",
    city: "Round Rock",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78691",
    city: "Pflugerville",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78701",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78702",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78703",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78704",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78705",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78708",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78709",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78710",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78711",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78712",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78713",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78714",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78715",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78716",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78717",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78718",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78719",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78720",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78721",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78722",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78723",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78724",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78725",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78726",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78727",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78728",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78729",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Williamson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78730",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78731",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78732",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78733",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78734",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78735",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78736",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78737",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Hays",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78738",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78739",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78741",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78742",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78744",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78745",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78746",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78747",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78748",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78749",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78750",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78751",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78752",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78753",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78754",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78755",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78756",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78757",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78758",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78759",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78760",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78761",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78762",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78763",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78764",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78765",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78766",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78767",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78768",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78772",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78773",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78774",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78778",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78779",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78783",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78799",
    city: "Austin",
    state: "TX",
    areacode: 512,
    county: "Travis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78801",
    city: "Uvalde",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78802",
    city: "Uvalde",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78827",
    city: "Asherton",
    state: "TX",
    areacode: 830,
    county: "Dimmit",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78828",
    city: "Barksdale",
    state: "TX",
    areacode: 830,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78829",
    city: "Batesville",
    state: "TX",
    areacode: 830,
    county: "Zavala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78830",
    city: "Big Wells",
    state: "TX",
    areacode: 830,
    county: "Dimmit",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78832",
    city: "Brackettville",
    state: "TX",
    areacode: 830,
    county: "Kinney",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78833",
    city: "Camp Wood",
    state: "TX",
    areacode: 830,
    county: "Real",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78834",
    city: "Carrizo Springs",
    state: "TX",
    areacode: 830,
    county: "Dimmit",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78836",
    city: "Catarina",
    state: "TX",
    areacode: 830,
    county: "Dimmit",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78837",
    city: "Comstock",
    state: "TX",
    areacode: 432,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78838",
    city: "Concan",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78839",
    city: "Crystal City",
    state: "TX",
    areacode: 830,
    county: "Zavala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78840",
    city: "Del Rio",
    state: "TX",
    areacode: 830,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78841",
    city: "Del Rio",
    state: "TX",
    areacode: 830,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78842",
    city: "Del Rio",
    state: "TX",
    areacode: 830,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78843",
    city: "Laughlin AFB",
    state: "TX",
    areacode: 830,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78847",
    city: "Del Rio",
    state: "TX",
    areacode: 830,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78850",
    city: "D Hanis",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78851",
    city: "Dryden",
    state: "TX",
    areacode: 432,
    county: "Terrell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78852",
    city: "Eagle Pass",
    state: "TX",
    areacode: 830,
    county: "Maverick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78853",
    city: "Eagle Pass",
    state: "TX",
    areacode: 830,
    county: "Maverick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78860",
    city: "El Indio",
    state: "TX",
    areacode: 830,
    county: "Maverick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78861",
    city: "Hondo",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78870",
    city: "Knippa",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78871",
    city: "Langtry",
    state: "TX",
    areacode: 432,
    county: "Val Verde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78872",
    city: "La Pryor",
    state: "TX",
    areacode: 830,
    county: "Zavala",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78873",
    city: "Leakey",
    state: "TX",
    areacode: 830,
    county: "Real",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78877",
    city: "Quemado",
    state: "TX",
    areacode: 830,
    county: "Maverick",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78879",
    city: "Rio Frio",
    state: "TX",
    areacode: 830,
    county: "Real",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78880",
    city: "Rocksprings",
    state: "TX",
    areacode: 830,
    county: "Edwards",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78881",
    city: "Sabinal",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78883",
    city: "Tarpley",
    state: "TX",
    areacode: 830,
    county: "Bandera",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78884",
    city: "Utopia",
    state: "TX",
    areacode: 830,
    county: "Uvalde",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78885",
    city: "Vanderpool",
    state: "TX",
    areacode: 830,
    county: "Bandera",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78886",
    city: "Yancey",
    state: "TX",
    areacode: 830,
    county: "Medina",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78931",
    city: "Bleiblerville",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78932",
    city: "Carmine",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78933",
    city: "Cat Spring",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78934",
    city: "Columbus",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78935",
    city: "Alleyton",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78938",
    city: "Ellinger",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78940",
    city: "Fayetteville",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78941",
    city: "Flatonia",
    state: "TX",
    areacode: 361,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78942",
    city: "Giddings",
    state: "TX",
    areacode: 979,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78943",
    city: "Glidden",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78944",
    city: "Industry",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78945",
    city: "La Grange",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78946",
    city: "Ledbetter",
    state: "TX",
    areacode: 979,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78947",
    city: "Lexington",
    state: "TX",
    areacode: 979,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78948",
    city: "Lincoln",
    state: "TX",
    areacode: 979,
    county: "Lee",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78949",
    city: "Muldoon",
    state: "TX",
    areacode: 512,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78950",
    city: "New Ulm",
    state: "TX",
    areacode: 979,
    county: "Austin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78951",
    city: "Oakland",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78952",
    city: "Plum",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78953",
    city: "Rosanky",
    state: "TX",
    areacode: 830,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78954",
    city: "Round Top",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78956",
    city: "Schulenburg",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78957",
    city: "Smithville",
    state: "TX",
    areacode: 512,
    county: "Bastrop",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78959",
    city: "Waelder",
    state: "TX",
    areacode: 512,
    county: "Gonzales",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78960",
    city: "Warda",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78961",
    city: "Warrenton",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78962",
    city: "Weimar",
    state: "TX",
    areacode: 979,
    county: "Colorado",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "78963",
    city: "West Point",
    state: "TX",
    areacode: 979,
    county: "Fayette",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79001",
    city: "Adrian",
    state: "TX",
    areacode: 806,
    county: "Oldham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79002",
    city: "Alanreed",
    state: "TX",
    areacode: 806,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79003",
    city: "Allison",
    state: "TX",
    areacode: 806,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79005",
    city: "Booker",
    state: "TX",
    areacode: 806,
    county: "Lipscomb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79007",
    city: "Borger",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79008",
    city: "Borger",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79009",
    city: "Bovina",
    state: "TX",
    areacode: 806,
    county: "Parmer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79010",
    city: "Boys Ranch",
    state: "TX",
    areacode: 806,
    county: "Oldham",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79011",
    city: "Briscoe",
    state: "TX",
    areacode: 806,
    county: "Hemphill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79012",
    city: "Bushland",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79013",
    city: "Cactus",
    state: "TX",
    areacode: 806,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79014",
    city: "Canadian",
    state: "TX",
    areacode: 806,
    county: "Hemphill",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79015",
    city: "Canyon",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79016",
    city: "Canyon",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79018",
    city: "Channing",
    state: "TX",
    areacode: 806,
    county: "Hartley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79019",
    city: "Claude",
    state: "TX",
    areacode: 806,
    county: "Armstrong",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79021",
    city: "Cotton Center",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79022",
    city: "Dalhart",
    state: "TX",
    areacode: 806,
    county: "Dallam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79024",
    city: "Darrouzett",
    state: "TX",
    areacode: 806,
    county: "Lipscomb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79025",
    city: "Dawn",
    state: "TX",
    areacode: 806,
    county: "Deaf Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79027",
    city: "Dimmitt",
    state: "TX",
    areacode: 806,
    county: "Castro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79029",
    city: "Dumas",
    state: "TX",
    areacode: 806,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79031",
    city: "Earth",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79032",
    city: "Edmonson",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79033",
    city: "Farnsworth",
    state: "TX",
    areacode: 806,
    county: "Ochiltree",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79034",
    city: "Follett",
    state: "TX",
    areacode: 806,
    county: "Lipscomb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79035",
    city: "Friona",
    state: "TX",
    areacode: 806,
    county: "Parmer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79036",
    city: "Fritch",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79039",
    city: "Groom",
    state: "TX",
    areacode: 806,
    county: "Carson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79040",
    city: "Gruver",
    state: "TX",
    areacode: 806,
    county: "Hansford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79041",
    city: "Hale Center",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79042",
    city: "Happy",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79043",
    city: "Hart",
    state: "TX",
    areacode: 806,
    county: "Castro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79044",
    city: "Hartley",
    state: "TX",
    areacode: 806,
    county: "Hartley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79045",
    city: "Hereford",
    state: "TX",
    areacode: 806,
    county: "Deaf Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79046",
    city: "Higgins",
    state: "TX",
    areacode: 806,
    county: "Lipscomb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79051",
    city: "Kerrick",
    state: "TX",
    areacode: 806,
    county: "Dallam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79052",
    city: "Kress",
    state: "TX",
    areacode: 806,
    county: "Swisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79053",
    city: "Lazbuddie",
    state: "TX",
    areacode: 806,
    county: "Parmer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79054",
    city: "Lefors",
    state: "TX",
    areacode: 806,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79056",
    city: "Lipscomb",
    state: "TX",
    areacode: 806,
    county: "Lipscomb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79057",
    city: "McLean",
    state: "TX",
    areacode: 806,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79058",
    city: "Masterson",
    state: "TX",
    areacode: 806,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79059",
    city: "Miami",
    state: "TX",
    areacode: 806,
    county: "Roberts",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79061",
    city: "Mobeetie",
    state: "TX",
    areacode: 806,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79062",
    city: "Morse",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79063",
    city: "Nazareth",
    state: "TX",
    areacode: 806,
    county: "Castro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79064",
    city: "Olton",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79065",
    city: "Pampa",
    state: "TX",
    areacode: 806,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79066",
    city: "Pampa",
    state: "TX",
    areacode: 806,
    county: "Gray",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79068",
    city: "Panhandle",
    state: "TX",
    areacode: 806,
    county: "Carson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79070",
    city: "Perryton",
    state: "TX",
    areacode: 806,
    county: "Ochiltree",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79072",
    city: "Plainview",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79073",
    city: "Plainview",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79077",
    city: "Samnorwood",
    state: "TX",
    areacode: 806,
    county: "Collingsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79078",
    city: "Sanford",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79079",
    city: "Shamrock",
    state: "TX",
    areacode: 806,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79080",
    city: "Skellytown",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79081",
    city: "Spearman",
    state: "TX",
    areacode: 806,
    county: "Hansford",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79082",
    city: "Springlake",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79083",
    city: "Stinnett",
    state: "TX",
    areacode: 806,
    county: "Hutchinson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79084",
    city: "Stratford",
    state: "TX",
    areacode: 806,
    county: "Sherman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79085",
    city: "Summerfield",
    state: "TX",
    areacode: 806,
    county: "Castro",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79086",
    city: "Sunray",
    state: "TX",
    areacode: 806,
    county: "Moore",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79087",
    city: "Texline",
    state: "TX",
    areacode: 806,
    county: "Dallam",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79088",
    city: "Tulia",
    state: "TX",
    areacode: 806,
    county: "Swisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79091",
    city: "Umbarger",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79092",
    city: "Vega",
    state: "TX",
    areacode: 806,
    county: "Deaf Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79093",
    city: "Waka",
    state: "TX",
    areacode: 806,
    county: "Ochiltree",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79094",
    city: "Wayside",
    state: "TX",
    areacode: 806,
    county: "Armstrong",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79095",
    city: "Wellington",
    state: "TX",
    areacode: 806,
    county: "Collingsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79096",
    city: "Wheeler",
    state: "TX",
    areacode: 806,
    county: "Wheeler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79097",
    city: "White Deer",
    state: "TX",
    areacode: 806,
    county: "Carson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79098",
    city: "Wildorado",
    state: "TX",
    areacode: 806,
    county: "Deaf Smith",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79101",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79102",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79103",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79104",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79105",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79106",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79107",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79108",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79109",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79110",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79111",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79114",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79116",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79117",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79118",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79119",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79120",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79121",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Randall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79124",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79159",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79166",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79168",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79172",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79174",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79178",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79185",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79189",
    city: "Amarillo",
    state: "TX",
    areacode: 806,
    county: "Potter",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79201",
    city: "Childress",
    state: "TX",
    areacode: 940,
    county: "Childress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79220",
    city: "Afton",
    state: "TX",
    areacode: 806,
    county: "Dickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79221",
    city: "Aiken",
    state: "TX",
    areacode: 806,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79223",
    city: "Cee Vee",
    state: "TX",
    areacode: 940,
    county: "Cottle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79225",
    city: "Chillicothe",
    state: "TX",
    areacode: 254,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79226",
    city: "Clarendon",
    state: "TX",
    areacode: 806,
    county: "Donley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79227",
    city: "Crowell",
    state: "TX",
    areacode: 254,
    county: "Foard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79229",
    city: "Dickens",
    state: "TX",
    areacode: 806,
    county: "Dickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79230",
    city: "Dodson",
    state: "TX",
    areacode: 806,
    county: "Collingsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79231",
    city: "Dougherty",
    state: "TX",
    areacode: 806,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79233",
    city: "Estelline",
    state: "TX",
    areacode: 806,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79234",
    city: "Flomot",
    state: "TX",
    areacode: 806,
    county: "Motley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79235",
    city: "Floydada",
    state: "TX",
    areacode: 806,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79236",
    city: "Guthrie",
    state: "TX",
    areacode: 806,
    county: "King",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79237",
    city: "Hedley",
    state: "TX",
    areacode: 806,
    county: "Donley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79239",
    city: "Lakeview",
    state: "TX",
    areacode: 806,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79240",
    city: "Lelia Lake",
    state: "TX",
    areacode: 806,
    county: "Donley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79241",
    city: "Lockney",
    state: "TX",
    areacode: 806,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79243",
    city: "McAdoo",
    state: "TX",
    areacode: 806,
    county: "Crosby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79244",
    city: "Matador",
    state: "TX",
    areacode: 806,
    county: "Motley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79245",
    city: "Memphis",
    state: "TX",
    areacode: 806,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79247",
    city: "Odell",
    state: "TX",
    areacode: 940,
    county: "Wilbarger",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79248",
    city: "Paducah",
    state: "TX",
    areacode: 806,
    county: "Cottle",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79250",
    city: "Petersburg",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79251",
    city: "Quail",
    state: "TX",
    areacode: 806,
    county: "Collingsworth",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79252",
    city: "Quanah",
    state: "TX",
    areacode: 254,
    county: "Hardeman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79255",
    city: "Quitaque",
    state: "TX",
    areacode: 806,
    county: "Briscoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79256",
    city: "Roaring Springs",
    state: "TX",
    areacode: 806,
    county: "Motley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79257",
    city: "Silverton",
    state: "TX",
    areacode: 806,
    county: "Briscoe",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79258",
    city: "South Plains",
    state: "TX",
    areacode: 806,
    county: "Floyd",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79259",
    city: "Tell",
    state: "TX",
    areacode: 940,
    county: "Childress",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79261",
    city: "Turkey",
    state: "TX",
    areacode: 806,
    county: "Hall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79311",
    city: "Abernathy",
    state: "TX",
    areacode: 806,
    county: "Hale",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79312",
    city: "Amherst",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79313",
    city: "Anton",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79314",
    city: "Bledsoe",
    state: "TX",
    areacode: 806,
    county: "Cochran",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79316",
    city: "Brownfield",
    state: "TX",
    areacode: 806,
    county: "Terry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79322",
    city: "Crosbyton",
    state: "TX",
    areacode: 806,
    county: "Crosby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79323",
    city: "Denver City",
    state: "TX",
    areacode: 806,
    county: "Yoakum",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79324",
    city: "Enochs",
    state: "TX",
    areacode: 806,
    county: "Bailey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79325",
    city: "Farwell",
    state: "TX",
    areacode: 806,
    county: "Parmer",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79326",
    city: "Fieldton",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79329",
    city: "Idalou",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79330",
    city: "Justiceburg",
    state: "TX",
    areacode: 806,
    county: "Garza",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79331",
    city: "Lamesa",
    state: "TX",
    areacode: 806,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79336",
    city: "Levelland",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79338",
    city: "Levelland",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79339",
    city: "Littlefield",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79342",
    city: "Loop",
    state: "TX",
    areacode: 806,
    county: "Gaines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79343",
    city: "Lorenzo",
    state: "TX",
    areacode: 806,
    county: "Crosby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79344",
    city: "Maple",
    state: "TX",
    areacode: 806,
    county: "Bailey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79345",
    city: "Meadow",
    state: "TX",
    areacode: 806,
    county: "Terry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79346",
    city: "Morton",
    state: "TX",
    areacode: 806,
    county: "Cochran",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79347",
    city: "Muleshoe",
    state: "TX",
    areacode: 806,
    county: "Bailey",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79350",
    city: "New Deal",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79351",
    city: "Odonnell",
    state: "TX",
    areacode: 806,
    county: "Lynn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79353",
    city: "Pep",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79355",
    city: "Plains",
    state: "TX",
    areacode: 806,
    county: "Yoakum",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79356",
    city: "Post",
    state: "TX",
    areacode: 806,
    county: "Garza",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79357",
    city: "Ralls",
    state: "TX",
    areacode: 806,
    county: "Crosby",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79358",
    city: "Ropesville",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79359",
    city: "Seagraves",
    state: "TX",
    areacode: 806,
    county: "Gaines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79360",
    city: "Seminole",
    state: "TX",
    areacode: 432,
    county: "Gaines",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79363",
    city: "Shallowater",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79364",
    city: "Slaton",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79366",
    city: "Ransom Canyon",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79367",
    city: "Smyer",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79369",
    city: "Spade",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79370",
    city: "Spur",
    state: "TX",
    areacode: 806,
    county: "Dickens",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79371",
    city: "Sudan",
    state: "TX",
    areacode: 806,
    county: "Lamb",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79372",
    city: "Sundown",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79373",
    city: "Tahoka",
    state: "TX",
    areacode: 806,
    county: "Lynn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79376",
    city: "Tokio",
    state: "TX",
    areacode: 806,
    county: "Yoakum",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79377",
    city: "Welch",
    state: "TX",
    areacode: 806,
    county: "Dawson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79378",
    city: "Wellman",
    state: "TX",
    areacode: 806,
    county: "Terry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79379",
    city: "Whiteface",
    state: "TX",
    areacode: 806,
    county: "Cochran",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79380",
    city: "Whitharral",
    state: "TX",
    areacode: 806,
    county: "Hockley",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79381",
    city: "Wilson",
    state: "TX",
    areacode: 806,
    county: "Lynn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79382",
    city: "Wolfforth",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79383",
    city: "New Home",
    state: "TX",
    areacode: 806,
    county: "Lynn",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79401",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79402",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79403",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79404",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79406",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79407",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79408",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79409",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79410",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79411",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79412",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79413",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79414",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79415",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79416",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79423",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79424",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79430",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79452",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79453",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79457",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79464",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79490",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79491",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79493",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79499",
    city: "Lubbock",
    state: "TX",
    areacode: 806,
    county: "Lubbock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79501",
    city: "Anson",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79502",
    city: "Aspermont",
    state: "TX",
    areacode: 940,
    county: "Stonewall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79503",
    city: "Avoca",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79504",
    city: "Baird",
    state: "TX",
    areacode: 325,
    county: "Callahan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79505",
    city: "Benjamin",
    state: "TX",
    areacode: 940,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79506",
    city: "Blackwell",
    state: "TX",
    areacode: 325,
    county: "Nolan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79508",
    city: "Buffalo Gap",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79510",
    city: "Clyde",
    state: "TX",
    areacode: 325,
    county: "Callahan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79511",
    city: "Coahoma",
    state: "TX",
    areacode: 432,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79512",
    city: "Colorado City",
    state: "TX",
    areacode: 325,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79516",
    city: "Dunn",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79517",
    city: "Fluvanna",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79518",
    city: "Girard",
    state: "TX",
    areacode: 806,
    county: "Kent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79519",
    city: "Goldsboro",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79520",
    city: "Hamlin",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79521",
    city: "Haskell",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79525",
    city: "Hawley",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79526",
    city: "Hermleigh",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79527",
    city: "Ira",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79528",
    city: "Jayton",
    state: "TX",
    areacode: 806,
    county: "Kent",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79529",
    city: "Knox City",
    state: "TX",
    areacode: 940,
    county: "Knox",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79530",
    city: "Lawn",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79532",
    city: "Loraine",
    state: "TX",
    areacode: 325,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79533",
    city: "Lueders",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79534",
    city: "McCaulley",
    state: "TX",
    areacode: 325,
    county: "Fisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79535",
    city: "Maryneal",
    state: "TX",
    areacode: 325,
    county: "Nolan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79536",
    city: "Merkel",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79537",
    city: "Nolan",
    state: "TX",
    areacode: 325,
    county: "Nolan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79538",
    city: "Novice",
    state: "TX",
    areacode: 325,
    county: "Coleman",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79539",
    city: "O Brien",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79540",
    city: "Old Glory",
    state: "TX",
    areacode: 940,
    county: "Stonewall",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79541",
    city: "Ovalo",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79543",
    city: "Roby",
    state: "TX",
    areacode: 325,
    county: "Fisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79544",
    city: "Rochester",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79545",
    city: "Roscoe",
    state: "TX",
    areacode: 325,
    county: "Nolan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79546",
    city: "Rotan",
    state: "TX",
    areacode: 325,
    county: "Fisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79547",
    city: "Rule",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79548",
    city: "Rule",
    state: "TX",
    areacode: 940,
    county: "Haskell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79549",
    city: "Snyder",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79550",
    city: "Snyder",
    state: "TX",
    areacode: 325,
    county: "Scurry",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79553",
    city: "Stamford",
    state: "TX",
    areacode: 325,
    county: "Jones",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79556",
    city: "Sweetwater",
    state: "TX",
    areacode: 325,
    county: "Nolan",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79560",
    city: "Sylvester",
    state: "TX",
    areacode: 325,
    county: "Fisher",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79561",
    city: "Trent",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79562",
    city: "Tuscola",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79563",
    city: "Tye",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79565",
    city: "Westbrook",
    state: "TX",
    areacode: 325,
    county: "Mitchell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79566",
    city: "Wingate",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79567",
    city: "Winters",
    state: "TX",
    areacode: 325,
    county: "Runnels",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79601",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79602",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79603",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79604",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79605",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79606",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79607",
    city: "Dyess AFB",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79608",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79697",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79698",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79699",
    city: "Abilene",
    state: "TX",
    areacode: 325,
    county: "Taylor",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79701",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79702",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79703",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79704",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79705",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79706",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79707",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79708",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79710",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79711",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79712",
    city: "Midland",
    state: "TX",
    areacode: 432,
    county: "Midland",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79713",
    city: "Ackerly",
    state: "TX",
    areacode: 432,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79714",
    city: "Andrews",
    state: "TX",
    areacode: 432,
    county: "Andrews",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79718",
    city: "Balmorhea",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79719",
    city: "Barstow",
    state: "TX",
    areacode: 432,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79720",
    city: "Big Spring",
    state: "TX",
    areacode: 432,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79721",
    city: "Big Spring",
    state: "TX",
    areacode: 432,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79730",
    city: "Coyanosa",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79731",
    city: "Crane",
    state: "TX",
    areacode: 432,
    county: "Crane",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79733",
    city: "Forsan",
    state: "TX",
    areacode: 432,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79734",
    city: "Fort Davis",
    state: "TX",
    areacode: 432,
    county: "Jeff Davis",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79735",
    city: "Fort Stockton",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79738",
    city: "Gail",
    state: "TX",
    areacode: 432,
    county: "Borden",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79739",
    city: "Garden City",
    state: "TX",
    areacode: 432,
    county: "Glasscock",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79740",
    city: "Girvin",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79741",
    city: "Goldsmith",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79742",
    city: "Grandfalls",
    state: "TX",
    areacode: 432,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79743",
    city: "Imperial",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79744",
    city: "Iraan",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79745",
    city: "Kermit",
    state: "TX",
    areacode: 432,
    county: "Winkler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79748",
    city: "Knott",
    state: "TX",
    areacode: 432,
    county: "Howard",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79749",
    city: "Lenorah",
    state: "TX",
    areacode: 432,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79752",
    city: "McCamey",
    state: "TX",
    areacode: 432,
    county: "Upton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79754",
    city: "Mentone",
    state: "TX",
    areacode: 432,
    county: "Loving",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79755",
    city: "Midkiff",
    state: "TX",
    areacode: 432,
    county: "Upton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79756",
    city: "Monahans",
    state: "TX",
    areacode: 432,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79758",
    city: "Gardendale",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79759",
    city: "Notrees",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79760",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79761",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79762",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79763",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79764",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79765",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79766",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79768",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79769",
    city: "Odessa",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79770",
    city: "Orla",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79772",
    city: "Pecos",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79776",
    city: "Penwell",
    state: "TX",
    areacode: 432,
    county: "Ector",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79777",
    city: "Pyote",
    state: "TX",
    areacode: 432,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79778",
    city: "Rankin",
    state: "TX",
    areacode: 432,
    county: "Upton",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79780",
    city: "Saragosa",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79781",
    city: "Sheffield",
    state: "TX",
    areacode: 432,
    county: "Pecos",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79782",
    city: "Stanton",
    state: "TX",
    areacode: 432,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79783",
    city: "Tarzan",
    state: "TX",
    areacode: 432,
    county: "Martin",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79785",
    city: "Toyah",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79786",
    city: "Toyahvale",
    state: "TX",
    areacode: 432,
    county: "Reeves",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79788",
    city: "Wickett",
    state: "TX",
    areacode: 432,
    county: "Ward",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79789",
    city: "Wink",
    state: "TX",
    areacode: 432,
    county: "Winkler",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79821",
    city: "Anthony",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79830",
    city: "Alpine",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79831",
    city: "Alpine",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79832",
    city: "Alpine",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79834",
    city: "Big Bend National Park",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79835",
    city: "Canutillo",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79836",
    city: "Clint",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79837",
    city: "Dell City",
    state: "TX",
    areacode: 915,
    county: "Hudspeth",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79838",
    city: "Fabens",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79839",
    city: "Fort Hancock",
    state: "TX",
    areacode: 915,
    county: "Hudspeth",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79842",
    city: "Marathon",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79843",
    city: "Marfa",
    state: "TX",
    areacode: 432,
    county: "Presidio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79845",
    city: "Presidio",
    state: "TX",
    areacode: 432,
    county: "Presidio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79846",
    city: "Redford",
    state: "TX",
    areacode: 432,
    county: "Presidio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79847",
    city: "Salt Flat",
    state: "TX",
    areacode: 915,
    county: "Hudspeth",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79848",
    city: "Sanderson",
    state: "TX",
    areacode: 432,
    county: "Terrell",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79849",
    city: "San Elizario",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79851",
    city: "Sierra Blanca",
    state: "TX",
    areacode: 915,
    county: "Hudspeth",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79852",
    city: "Terlingua",
    state: "TX",
    areacode: 432,
    county: "Brewster",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79853",
    city: "Tornillo",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79854",
    city: "Valentine",
    state: "TX",
    areacode: 432,
    county: "Presidio",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79855",
    city: "Van Horn",
    state: "TX",
    areacode: 915,
    county: "Culberson",
    timezone3: "CST",
    timezone: "US/Central",
  },
  {
    zipcode: "79901",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79902",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79903",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79904",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79905",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79906",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79907",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79908",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79910",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79911",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79912",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79913",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79914",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79915",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79916",
    city: "Fort Bliss",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79917",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79918",
    city: "Fort Bliss",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79920",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79922",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79923",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79924",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79925",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79926",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79927",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79928",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79929",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79930",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79931",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79932",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79934",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79935",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79936",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79937",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79938",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79940",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79941",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79942",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79943",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79944",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79945",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79946",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79947",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79948",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79949",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79950",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79951",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79952",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79953",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79954",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79955",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79958",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79960",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79961",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79968",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79976",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79978",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79980",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79995",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79996",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79997",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79998",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "79999",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80001",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80002",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80003",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80004",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80005",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80006",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80007",
    city: "Arvada",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80010",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80011",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80012",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80013",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80014",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80015",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80016",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80017",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80018",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80019",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80020",
    city: "Broomfield",
    state: "CO",
    areacode: 303,
    county: "Broomfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80021",
    city: "Broomfield",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80022",
    city: "Commerce City",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80023",
    city: "Broomfield",
    state: "CO",
    areacode: 303,
    county: "Broomfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80024",
    city: "Dupont",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80025",
    city: "Eldorado Springs",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80026",
    city: "Lafayette",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80027",
    city: "Louisville",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80030",
    city: "Westminster",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80031",
    city: "Westminster",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80033",
    city: "Wheat Ridge",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80034",
    city: "Wheat Ridge",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80035",
    city: "Westminster",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80036",
    city: "Westminster",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80037",
    city: "Commerce City",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80038",
    city: "Broomfield",
    state: "CO",
    areacode: 303,
    county: "Broomfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80040",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80041",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80042",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80044",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80045",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80046",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80047",
    city: "Aurora",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80101",
    city: "Agate",
    state: "CO",
    areacode: 719,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80102",
    city: "Bennett",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80103",
    city: "Byers",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80104",
    city: "Castle Rock",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80105",
    city: "Deer Trail",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80106",
    city: "Elbert",
    state: "CO",
    areacode: 303,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80107",
    city: "Elizabeth",
    state: "CO",
    areacode: 303,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80108",
    city: "Castle Rock",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80109",
    city: "Castle Rock",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80110",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80111",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80112",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80113",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80116",
    city: "Franktown",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80117",
    city: "Kiowa",
    state: "CO",
    areacode: 303,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80118",
    city: "Larkspur",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80120",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80121",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80122",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80123",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80124",
    city: "Lone Tree",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80125",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80126",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80127",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80128",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80129",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80130",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80131",
    city: "Louviers",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80132",
    city: "Monument",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80133",
    city: "Palmer Lake",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80134",
    city: "Parker",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80135",
    city: "Sedalia",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80136",
    city: "Strasburg",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80137",
    city: "Watkins",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80138",
    city: "Parker",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80150",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80151",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80155",
    city: "Englewood",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80160",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80161",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80162",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80163",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Douglas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80165",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80166",
    city: "Littleton",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80201",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80202",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80203",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80204",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80205",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80206",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80207",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80208",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80209",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80210",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80211",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80212",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80214",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80215",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80216",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80217",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80218",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80219",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80220",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80221",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80222",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80223",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80224",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80225",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80226",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80227",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80228",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80229",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80230",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80231",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80232",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80233",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80234",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80235",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80236",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80237",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80238",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80239",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80241",
    city: "Thornton",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80243",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80244",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80246",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Arapahoe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80247",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80248",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80249",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80250",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80256",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80257",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80259",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80260",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80261",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80263",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80264",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80265",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80266",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80271",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80273",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80274",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80281",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80290",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80291",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80293",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80294",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80299",
    city: "Denver",
    state: "CO",
    areacode: 303,
    county: "Denver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80301",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80302",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80303",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80304",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80305",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80306",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80307",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80308",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80309",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80310",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80314",
    city: "Boulder",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80401",
    city: "Golden",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80402",
    city: "Golden",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80403",
    city: "Golden",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80419",
    city: "Golden",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80420",
    city: "Alma",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80421",
    city: "Bailey",
    state: "CO",
    areacode: 303,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80422",
    city: "Black Hawk",
    state: "CO",
    areacode: 303,
    county: "Gilpin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80423",
    city: "Bond",
    state: "CO",
    areacode: 303,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80424",
    city: "Breckenridge",
    state: "CO",
    areacode: 970,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80425",
    city: "Buffalo Creek",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80426",
    city: "Burns",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80427",
    city: "Central City",
    state: "CO",
    areacode: 303,
    county: "Gilpin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80428",
    city: "Clark",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80429",
    city: "Climax",
    state: "CO",
    areacode: 719,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80430",
    city: "Coalmont",
    state: "CO",
    areacode: 970,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80432",
    city: "Como",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80433",
    city: "Conifer",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80434",
    city: "Cowdrey",
    state: "CO",
    areacode: 970,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80435",
    city: "Dillon",
    state: "CO",
    areacode: 970,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80436",
    city: "Dumont",
    state: "CO",
    areacode: 303,
    county: "Clear Creek",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80437",
    city: "Evergreen",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80438",
    city: "Empire",
    state: "CO",
    areacode: 303,
    county: "Clear Creek",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80439",
    city: "Evergreen",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80440",
    city: "Fairplay",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80442",
    city: "Fraser",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80443",
    city: "Frisco",
    state: "CO",
    areacode: 970,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80444",
    city: "Georgetown",
    state: "CO",
    areacode: 303,
    county: "Clear Creek",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80446",
    city: "Granby",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80447",
    city: "Grand Lake",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80448",
    city: "Grant",
    state: "CO",
    areacode: 303,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80449",
    city: "Hartsel",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80451",
    city: "Hot Sulphur Springs",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80452",
    city: "Idaho Springs",
    state: "CO",
    areacode: 303,
    county: "Clear Creek",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80453",
    city: "Idledale",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80454",
    city: "Indian Hills",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80455",
    city: "Jamestown",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80456",
    city: "Jefferson",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80457",
    city: "Kittredge",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80459",
    city: "Kremmling",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80461",
    city: "Leadville",
    state: "CO",
    areacode: 719,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80463",
    city: "McCoy",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80465",
    city: "Morrison",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80466",
    city: "Nederland",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80467",
    city: "Oak Creek",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80468",
    city: "Parshall",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80469",
    city: "Phippsburg",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80470",
    city: "Pine",
    state: "CO",
    areacode: 303,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80471",
    city: "Pinecliffe",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80473",
    city: "Rand",
    state: "CO",
    areacode: 970,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80474",
    city: "Rollinsville",
    state: "CO",
    areacode: 303,
    county: "Gilpin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80475",
    city: "Shawnee",
    state: "CO",
    areacode: 303,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80476",
    city: "Silver Plume",
    state: "CO",
    areacode: 303,
    county: "Clear Creek",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80477",
    city: "Steamboat Springs",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80478",
    city: "Tabernash",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80479",
    city: "Toponas",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80480",
    city: "Walden",
    state: "CO",
    areacode: 970,
    county: "Jackson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80481",
    city: "Ward",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80482",
    city: "Winter Park",
    state: "CO",
    areacode: 970,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80483",
    city: "Yampa",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80487",
    city: "Steamboat Springs",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80488",
    city: "Steamboat Springs",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80497",
    city: "Silverthorne",
    state: "CO",
    areacode: 970,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80498",
    city: "Silverthorne",
    state: "CO",
    areacode: 970,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80501",
    city: "Longmont",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80502",
    city: "Longmont",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80503",
    city: "Longmont",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80504",
    city: "Longmont",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80510",
    city: "Allenspark",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80511",
    city: "Estes Park",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80512",
    city: "Bellvue",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80513",
    city: "Berthoud",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80514",
    city: "Dacono",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80515",
    city: "Drake",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80516",
    city: "Erie",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80517",
    city: "Estes Park",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80520",
    city: "Firestone",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80521",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80522",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80523",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80524",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80525",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80526",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80527",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80528",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80530",
    city: "Frederick",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80532",
    city: "Glen Haven",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80533",
    city: "Hygiene",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80534",
    city: "Johnstown",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80535",
    city: "Laporte",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80536",
    city: "Livermore",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80537",
    city: "Loveland",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80538",
    city: "Loveland",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80539",
    city: "Loveland",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80540",
    city: "Lyons",
    state: "CO",
    areacode: 303,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80541",
    city: "Masonville",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80542",
    city: "Mead",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80543",
    city: "Milliken",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80544",
    city: "Niwot",
    state: "CO",
    areacode: 303,
    county: "Boulder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80545",
    city: "Red Feather Lakes",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80546",
    city: "Severance",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80547",
    city: "Timnath",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80549",
    city: "Wellington",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80550",
    city: "Windsor",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80551",
    city: "Windsor",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80553",
    city: "Fort Collins",
    state: "CO",
    areacode: 970,
    county: "Larimer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80601",
    city: "Brighton",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80602",
    city: "Brighton",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80603",
    city: "Brighton",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80610",
    city: "Ault",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80611",
    city: "Briggsdale",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80612",
    city: "Carr",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80614",
    city: "Eastlake",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80615",
    city: "Eaton",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80620",
    city: "Evans",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80621",
    city: "Fort Lupton",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80622",
    city: "Galeton",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80623",
    city: "Gilcrest",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80624",
    city: "Gill",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80631",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80632",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80633",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80634",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80638",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80639",
    city: "Greeley",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80640",
    city: "Henderson",
    state: "CO",
    areacode: 303,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80642",
    city: "Hudson",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80643",
    city: "Keenesburg",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80644",
    city: "Kersey",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80645",
    city: "La Salle",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80646",
    city: "Lucerne",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80648",
    city: "Nunn",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80649",
    city: "Orchard",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80650",
    city: "Pierce",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80651",
    city: "Platteville",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80652",
    city: "Roggen",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80653",
    city: "Weldona",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80654",
    city: "Wiggins",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80701",
    city: "Fort Morgan",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80705",
    city: "Log Lane Village",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80720",
    city: "Akron",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80721",
    city: "Amherst",
    state: "CO",
    areacode: 970,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80722",
    city: "Atwood",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80723",
    city: "Brush",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80726",
    city: "Crook",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80727",
    city: "Eckley",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80728",
    city: "Fleming",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80729",
    city: "Grover",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80731",
    city: "Haxtun",
    state: "CO",
    areacode: 970,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80732",
    city: "Hereford",
    state: "CO",
    areacode: 303,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80733",
    city: "Hillrose",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80734",
    city: "Holyoke",
    state: "CO",
    areacode: 970,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80735",
    city: "Idalia",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80736",
    city: "Iliff",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80737",
    city: "Julesburg",
    state: "CO",
    areacode: 970,
    county: "Sedgwick",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80740",
    city: "Lindon",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80741",
    city: "Merino",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80742",
    city: "New Raymer",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80743",
    city: "Otis",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80744",
    city: "Ovid",
    state: "CO",
    areacode: 970,
    county: "Sedgwick",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80745",
    city: "Padroni",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80746",
    city: "Paoli",
    state: "CO",
    areacode: 970,
    county: "Phillips",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80747",
    city: "Peetz",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80749",
    city: "Sedgwick",
    state: "CO",
    areacode: 303,
    county: "Sedgwick",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80750",
    city: "Snyder",
    state: "CO",
    areacode: 970,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80751",
    city: "Sterling",
    state: "CO",
    areacode: 970,
    county: "Logan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80754",
    city: "Stoneham",
    state: "CO",
    areacode: 970,
    county: "Weld",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80755",
    city: "Vernon",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80757",
    city: "Woodrow",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80758",
    city: "Wray",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80759",
    city: "Yuma",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80801",
    city: "Anton",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80802",
    city: "Arapahoe",
    state: "CO",
    areacode: 719,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80804",
    city: "Arriba",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80805",
    city: "Bethune",
    state: "CO",
    areacode: 719,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80807",
    city: "Burlington",
    state: "CO",
    areacode: 719,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80808",
    city: "Calhan",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80809",
    city: "Cascade",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80810",
    city: "Cheyenne Wells",
    state: "CO",
    areacode: 719,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80812",
    city: "Cope",
    state: "CO",
    areacode: 970,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80813",
    city: "Cripple Creek",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80814",
    city: "Divide",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80815",
    city: "Flagler",
    state: "CO",
    areacode: 719,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80816",
    city: "Florissant",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80817",
    city: "Fountain",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80818",
    city: "Genoa",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80819",
    city: "Green Mountain Falls",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80820",
    city: "Guffey",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80821",
    city: "Hugo",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80822",
    city: "Joes",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80823",
    city: "Karval",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80824",
    city: "Kirk",
    state: "CO",
    areacode: 970,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80825",
    city: "Kit Carson",
    state: "CO",
    areacode: 719,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80826",
    city: "Limon",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80827",
    city: "Lake George",
    state: "CO",
    areacode: 719,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80828",
    city: "Limon",
    state: "CO",
    areacode: 719,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80829",
    city: "Manitou Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80830",
    city: "Matheson",
    state: "CO",
    areacode: 719,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80831",
    city: "Peyton",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80832",
    city: "Ramah",
    state: "CO",
    areacode: 719,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80833",
    city: "Rush",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80834",
    city: "Seibert",
    state: "CO",
    areacode: 719,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80835",
    city: "Simla",
    state: "CO",
    areacode: 719,
    county: "Elbert",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80836",
    city: "Stratton",
    state: "CO",
    areacode: 970,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80840",
    city: "USAF Academy",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80841",
    city: "USAF Academy",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80860",
    city: "Victor",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80861",
    city: "Vona",
    state: "CO",
    areacode: 719,
    county: "Kit Carson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80862",
    city: "Wild Horse",
    state: "CO",
    areacode: 719,
    county: "Cheyenne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80863",
    city: "Woodland Park",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80864",
    city: "Yoder",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80866",
    city: "Woodland Park",
    state: "CO",
    areacode: 719,
    county: "Teller",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80901",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80902",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80903",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80904",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80905",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80906",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80907",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80908",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80909",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80910",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80911",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80912",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80913",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80914",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80915",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80916",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80917",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80918",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80919",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80920",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80921",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80922",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80923",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80924",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80925",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80926",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80927",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80928",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80929",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80930",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80931",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80932",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80933",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80934",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80935",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80936",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80937",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80938",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80939",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80941",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80942",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80946",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80947",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80949",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80950",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80951",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80960",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80962",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80970",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80977",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80995",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "80997",
    city: "Colorado Springs",
    state: "CO",
    areacode: 719,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81001",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81002",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81003",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81004",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81005",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81006",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81007",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81008",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81009",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81010",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81011",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81012",
    city: "Pueblo",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81019",
    city: "Colorado City",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81020",
    city: "Aguilar",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81021",
    city: "Arlington",
    state: "CO",
    areacode: 719,
    county: "Kiowa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81022",
    city: "Avondale",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81023",
    city: "Beulah",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81024",
    city: "Boncarbo",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81025",
    city: "Boone",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81027",
    city: "Branson",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81029",
    city: "Campo",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81030",
    city: "Cheraw",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81033",
    city: "Crowley",
    state: "CO",
    areacode: 719,
    county: "Crowley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81034",
    city: "Ordway",
    state: "CO",
    areacode: 719,
    county: "Crowley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81036",
    city: "Eads",
    state: "CO",
    areacode: 719,
    county: "Kiowa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81038",
    city: "Fort Lyon",
    state: "CO",
    areacode: 719,
    county: "Bent",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81039",
    city: "Fowler",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81040",
    city: "Gardner",
    state: "CO",
    areacode: 719,
    county: "Huerfano",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81041",
    city: "Granada",
    state: "CO",
    areacode: 719,
    county: "Prowers",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81043",
    city: "Hartman",
    state: "CO",
    areacode: 719,
    county: "Prowers",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81044",
    city: "Hasty",
    state: "CO",
    areacode: 719,
    county: "Bent",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81045",
    city: "Haswell",
    state: "CO",
    areacode: 719,
    county: "Kiowa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81046",
    city: "Hoehne",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81047",
    city: "Holly",
    state: "CO",
    areacode: 719,
    county: "Prowers",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81049",
    city: "Kim",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81050",
    city: "La Junta",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81052",
    city: "Lamar",
    state: "CO",
    areacode: 719,
    county: "Prowers",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81054",
    city: "Las Animas",
    state: "CO",
    areacode: 719,
    county: "Bent",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81055",
    city: "La Veta",
    state: "CO",
    areacode: 719,
    county: "Huerfano",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81057",
    city: "McClave",
    state: "CO",
    areacode: 719,
    county: "Bent",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81058",
    city: "Manzanola",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81059",
    city: "Model",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81062",
    city: "Olney Springs",
    state: "CO",
    areacode: 719,
    county: "Crowley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81063",
    city: "Ordway",
    state: "CO",
    areacode: 719,
    county: "Crowley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81064",
    city: "Pritchett",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81067",
    city: "Rocky Ford",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81069",
    city: "Rye",
    state: "CO",
    areacode: 719,
    county: "Pueblo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81071",
    city: "Sheridan Lake",
    state: "CO",
    areacode: 719,
    county: "Kiowa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81073",
    city: "Springfield",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81076",
    city: "Sugar City",
    state: "CO",
    areacode: 719,
    county: "Crowley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81077",
    city: "Swink",
    state: "CO",
    areacode: 719,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81081",
    city: "Trinchera",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81082",
    city: "Trinidad",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81084",
    city: "Two Buttes",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81087",
    city: "Vilas",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81089",
    city: "Walsenburg",
    state: "CO",
    areacode: 719,
    county: "Huerfano",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81090",
    city: "Walsh",
    state: "CO",
    areacode: 719,
    county: "Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81091",
    city: "Weston",
    state: "CO",
    areacode: 719,
    county: "Las Animas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81092",
    city: "Wiley",
    state: "CO",
    areacode: 719,
    county: "Prowers",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81101",
    city: "Alamosa",
    state: "CO",
    areacode: 719,
    county: "Alamosa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81102",
    city: "Alamosa",
    state: "CO",
    areacode: 719,
    county: "Alamosa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81120",
    city: "Antonito",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81121",
    city: "Arboles",
    state: "CO",
    areacode: 970,
    county: "Archuleta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81122",
    city: "Bayfield",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81123",
    city: "Blanca",
    state: "CO",
    areacode: 719,
    county: "Costilla",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81124",
    city: "Capulin",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81125",
    city: "Center",
    state: "CO",
    areacode: 719,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81126",
    city: "Chama",
    state: "CO",
    areacode: 719,
    county: "Costilla",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81128",
    city: "Chromo",
    state: "CO",
    areacode: 970,
    county: "Archuleta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81129",
    city: "Conejos",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81130",
    city: "Creede",
    state: "CO",
    areacode: 719,
    county: "Mineral",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81131",
    city: "Crestone",
    state: "CO",
    areacode: 719,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81132",
    city: "Del Norte",
    state: "CO",
    areacode: 719,
    county: "Rio Grande",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81133",
    city: "Fort Garland",
    state: "CO",
    areacode: 719,
    county: "Costilla",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81135",
    city: "Homelake",
    state: "CO",
    areacode: 719,
    county: "Rio Grande",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81136",
    city: "Hooper",
    state: "CO",
    areacode: 719,
    county: "Alamosa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81137",
    city: "Ignacio",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81138",
    city: "Jaroso",
    state: "CO",
    areacode: 719,
    county: "Costilla",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81140",
    city: "La Jara",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81141",
    city: "Manassa",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81143",
    city: "Moffat",
    state: "CO",
    areacode: 719,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81144",
    city: "Monte Vista",
    state: "CO",
    areacode: 719,
    county: "Rio Grande",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81146",
    city: "Mosca",
    state: "CO",
    areacode: 719,
    county: "Alamosa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81147",
    city: "Pagosa Springs",
    state: "CO",
    areacode: 970,
    county: "Archuleta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81148",
    city: "Romeo",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81149",
    city: "Saguache",
    state: "CO",
    areacode: 719,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81151",
    city: "Sanford",
    state: "CO",
    areacode: 719,
    county: "Conejos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81152",
    city: "San Luis",
    state: "CO",
    areacode: 719,
    county: "Costilla",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81154",
    city: "South Fork",
    state: "CO",
    areacode: 719,
    county: "Rio Grande",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81155",
    city: "Villa Grove",
    state: "CO",
    areacode: 719,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81157",
    city: "Pagosa Springs",
    state: "CO",
    areacode: 970,
    county: "Archuleta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81201",
    city: "Salida",
    state: "CO",
    areacode: 719,
    county: "Chaffee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81210",
    city: "Almont",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81211",
    city: "Buena Vista",
    state: "CO",
    areacode: 719,
    county: "Chaffee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81212",
    city: "Canon City",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81215",
    city: "Canon City",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81220",
    city: "Cimarron",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81221",
    city: "Coal Creek",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81222",
    city: "Coaldale",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81223",
    city: "Cotopaxi",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81224",
    city: "Crested Butte",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81225",
    city: "Crested Butte",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81226",
    city: "Florence",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81227",
    city: "Monarch",
    state: "CO",
    areacode: 719,
    county: "Chaffee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81228",
    city: "Granite",
    state: "CO",
    areacode: 719,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81230",
    city: "Gunnison",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81231",
    city: "Gunnison",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81232",
    city: "Hillside",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81233",
    city: "Howard",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81235",
    city: "Lake City",
    state: "CO",
    areacode: 970,
    county: "Hinsdale",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81236",
    city: "Nathrop",
    state: "CO",
    areacode: 719,
    county: "Chaffee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81237",
    city: "Ohio City",
    state: "CO",
    areacode: 719,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81239",
    city: "Parlin",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81240",
    city: "Penrose",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81241",
    city: "Pitkin",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81242",
    city: "Poncha Springs",
    state: "CO",
    areacode: 719,
    county: "Chaffee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81243",
    city: "Powderhorn",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81244",
    city: "Rockvale",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81248",
    city: "Sargents",
    state: "CO",
    areacode: 970,
    county: "Saguache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81251",
    city: "Twin Lakes",
    state: "CO",
    areacode: 719,
    county: "Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81252",
    city: "Westcliffe",
    state: "CO",
    areacode: 719,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81253",
    city: "Wetmore",
    state: "CO",
    areacode: 719,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81290",
    city: "Florence",
    state: "CO",
    areacode: 719,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81301",
    city: "Durango",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81302",
    city: "Durango",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81303",
    city: "Durango",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81320",
    city: "Cahone",
    state: "CO",
    areacode: 970,
    county: "Dolores",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81321",
    city: "Cortez",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81323",
    city: "Dolores",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81324",
    city: "Dove Creek",
    state: "CO",
    areacode: 970,
    county: "Dolores",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81325",
    city: "Egnar",
    state: "CO",
    areacode: 970,
    county: "Dolores",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81326",
    city: "Hesperus",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81327",
    city: "Lewis",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81328",
    city: "Mancos",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81329",
    city: "Marvel",
    state: "CO",
    areacode: 970,
    county: "La Plata",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81330",
    city: "Mesa Verde National Park",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81331",
    city: "Pleasant View",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81332",
    city: "Rico",
    state: "CO",
    areacode: 970,
    county: "Dolores",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81334",
    city: "Towaoc",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81335",
    city: "Yellow Jacket",
    state: "CO",
    areacode: 970,
    county: "Montezuma",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81401",
    city: "Montrose",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81402",
    city: "Montrose",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81403",
    city: "Montrose",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81410",
    city: "Austin",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81411",
    city: "Bedrock",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81413",
    city: "Cedaredge",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81414",
    city: "Cory",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81415",
    city: "Crawford",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81416",
    city: "Delta",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81418",
    city: "Eckert",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81419",
    city: "Hotchkiss",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81420",
    city: "Lazear",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81422",
    city: "Naturita",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81423",
    city: "Norwood",
    state: "CO",
    areacode: 970,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81424",
    city: "Nucla",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81425",
    city: "Olathe",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81426",
    city: "Ophir",
    state: "CO",
    areacode: 970,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81427",
    city: "Ouray",
    state: "CO",
    areacode: 970,
    county: "Ouray",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81428",
    city: "Paonia",
    state: "CO",
    areacode: 970,
    county: "Delta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81429",
    city: "Paradox",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81430",
    city: "Placerville",
    state: "CO",
    areacode: 970,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81431",
    city: "Redvale",
    state: "CO",
    areacode: 970,
    county: "Montrose",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81432",
    city: "Ridgway",
    state: "CO",
    areacode: 970,
    county: "Ouray",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81433",
    city: "Silverton",
    state: "CO",
    areacode: 970,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81434",
    city: "Somerset",
    state: "CO",
    areacode: 970,
    county: "Gunnison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81435",
    city: "Telluride",
    state: "CO",
    areacode: 970,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81501",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81502",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81503",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81504",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81505",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81506",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81507",
    city: "Grand Junction",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81520",
    city: "Clifton",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81521",
    city: "Fruita",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81522",
    city: "Gateway",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81523",
    city: "Glade Park",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81524",
    city: "Loma",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81525",
    city: "Mack",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81526",
    city: "Palisade",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81527",
    city: "Whitewater",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81601",
    city: "Glenwood Springs",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81602",
    city: "Glenwood Springs",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81610",
    city: "Dinosaur",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81611",
    city: "Aspen",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81612",
    city: "Aspen",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81615",
    city: "Snowmass Village",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81620",
    city: "Avon",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81621",
    city: "Basalt",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81623",
    city: "Carbondale",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81624",
    city: "Collbran",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81625",
    city: "Craig",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81626",
    city: "Craig",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81630",
    city: "De Beque",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81631",
    city: "Eagle",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81632",
    city: "Edwards",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81633",
    city: "Dinosaur",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81635",
    city: "Parachute",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81636",
    city: "Battlement Mesa",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81637",
    city: "Gypsum",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81638",
    city: "Hamilton",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81639",
    city: "Hayden",
    state: "CO",
    areacode: 970,
    county: "Routt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81640",
    city: "Maybell",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81641",
    city: "Meeker",
    state: "CO",
    areacode: 970,
    county: "Rio Blanco",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81642",
    city: "Meredith",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81643",
    city: "Mesa",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81645",
    city: "Minturn",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81646",
    city: "Molina",
    state: "CO",
    areacode: 970,
    county: "Mesa",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81647",
    city: "New Castle",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81648",
    city: "Rangely",
    state: "CO",
    areacode: 970,
    county: "Rio Blanco",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81649",
    city: "Red Cliff",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81650",
    city: "Rifle",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81652",
    city: "Silt",
    state: "CO",
    areacode: 970,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81653",
    city: "Slater",
    state: "CO",
    areacode: 970,
    county: "Moffat",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81654",
    city: "Snowmass",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81655",
    city: "Wolcott",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81656",
    city: "Woody Creek",
    state: "CO",
    areacode: 970,
    county: "Pitkin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81657",
    city: "Vail",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "81658",
    city: "Vail",
    state: "CO",
    areacode: 970,
    county: "Eagle",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82001",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82002",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82003",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82005",
    city: "FE Warren AFB",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82006",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82007",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82009",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82010",
    city: "Cheyenne",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82050",
    city: "Albin",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82051",
    city: "Bosler",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82052",
    city: "Buford",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82053",
    city: "Burns",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82054",
    city: "Carpenter",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82055",
    city: "Centennial",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82058",
    city: "Garrett",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82059",
    city: "Granite Canon",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82060",
    city: "Hillsdale",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82061",
    city: "Horse Creek",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82063",
    city: "Jelm",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82070",
    city: "Laramie",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82071",
    city: "Laramie",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82072",
    city: "Laramie",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82073",
    city: "Laramie",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82081",
    city: "Meriden",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82082",
    city: "Pine Bluffs",
    state: "WY",
    areacode: 307,
    county: "Laramie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82083",
    city: "Rock River",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82084",
    city: "Tie Siding",
    state: "WY",
    areacode: 307,
    county: "Albany",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82190",
    city: "Yellowstone National Park",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82201",
    city: "Wheatland",
    state: "WY",
    areacode: 307,
    county: "Platte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82210",
    city: "Chugwater",
    state: "WY",
    areacode: 307,
    county: "Platte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82212",
    city: "Fort Laramie",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82213",
    city: "Glendo",
    state: "WY",
    areacode: 307,
    county: "Platte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82214",
    city: "Guernsey",
    state: "WY",
    areacode: 307,
    county: "Platte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82215",
    city: "Hartville",
    state: "WY",
    areacode: 307,
    county: "Platte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82217",
    city: "Hawk Springs",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82218",
    city: "Huntley",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82219",
    city: "Jay Em",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82221",
    city: "Lagrange",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82222",
    city: "Lance Creek",
    state: "WY",
    areacode: 307,
    county: "Niobrara",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82223",
    city: "Lingle",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82224",
    city: "Lost Springs",
    state: "WY",
    areacode: 307,
    county: "Converse",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82225",
    city: "Lusk",
    state: "WY",
    areacode: 307,
    county: "Niobrara",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82227",
    city: "Manville",
    state: "WY",
    areacode: 307,
    county: "Niobrara",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82229",
    city: "Shawnee",
    state: "WY",
    areacode: 307,
    county: "Converse",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82240",
    city: "Torrington",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82242",
    city: "Van Tassell",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82243",
    city: "Veteran",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82244",
    city: "Yoder",
    state: "WY",
    areacode: 307,
    county: "Goshen",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82301",
    city: "Rawlins",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82310",
    city: "Jeffrey City",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82321",
    city: "Baggs",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82322",
    city: "Bairoil",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82323",
    city: "Dixon",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82324",
    city: "Elk Mountain",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82325",
    city: "Encampment",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82327",
    city: "Hanna",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82329",
    city: "Medicine Bow",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82331",
    city: "Saratoga",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82332",
    city: "Savery",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82334",
    city: "Sinclair",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82335",
    city: "Walcott",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82336",
    city: "Wamsutter",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82401",
    city: "Worland",
    state: "WY",
    areacode: 307,
    county: "Washakie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82410",
    city: "Basin",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82411",
    city: "Burlington",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82412",
    city: "Byron",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82414",
    city: "Cody",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82420",
    city: "Cowley",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82421",
    city: "Deaver",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82422",
    city: "Emblem",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82423",
    city: "Frannie",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82426",
    city: "Greybull",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82428",
    city: "Hyattville",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82430",
    city: "Kirby",
    state: "WY",
    areacode: 307,
    county: "Hot Springs",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82431",
    city: "Lovell",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82432",
    city: "Manderson",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82433",
    city: "Meeteetse",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82434",
    city: "Otto",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82435",
    city: "Powell",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82440",
    city: "Ralston",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82441",
    city: "Shell",
    state: "WY",
    areacode: 307,
    county: "Big Horn",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82442",
    city: "Ten Sleep",
    state: "WY",
    areacode: 307,
    county: "Washakie",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82443",
    city: "Thermopolis",
    state: "WY",
    areacode: 307,
    county: "Hot Springs",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82450",
    city: "Wapiti",
    state: "WY",
    areacode: 307,
    county: "Park",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82501",
    city: "Riverton",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82510",
    city: "Arapahoe",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82512",
    city: "Crowheart",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82513",
    city: "Dubois",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82514",
    city: "Fort Washakie",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82515",
    city: "Hudson",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82516",
    city: "Kinnear",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82520",
    city: "Lander",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82523",
    city: "Pavillion",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82524",
    city: "Saint Stephens",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82601",
    city: "Casper",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82602",
    city: "Casper",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82604",
    city: "Casper",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82605",
    city: "Casper",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82609",
    city: "Casper",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82615",
    city: "Shirley Basin",
    state: "WY",
    areacode: 307,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82620",
    city: "Alcova",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82630",
    city: "Arminto",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82633",
    city: "Douglas",
    state: "WY",
    areacode: 307,
    county: "Converse",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82635",
    city: "Edgerton",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82636",
    city: "Evansville",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82637",
    city: "Glenrock",
    state: "WY",
    areacode: 307,
    county: "Converse",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82638",
    city: "Hiland",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82639",
    city: "Kaycee",
    state: "WY",
    areacode: 307,
    county: "Johnson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82640",
    city: "Linch",
    state: "WY",
    areacode: 307,
    county: "Johnson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82642",
    city: "Lysite",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82643",
    city: "Midwest",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82644",
    city: "Mills",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82646",
    city: "Natrona",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82648",
    city: "Powder River",
    state: "WY",
    areacode: 307,
    county: "Natrona",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82649",
    city: "Shoshoni",
    state: "WY",
    areacode: 307,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82701",
    city: "Newcastle",
    state: "WY",
    areacode: 307,
    county: "Weston",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82710",
    city: "Aladdin",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82711",
    city: "Alva",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82712",
    city: "Beulah",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82714",
    city: "Devils Tower",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82715",
    city: "Four Corners",
    state: "WY",
    areacode: 307,
    county: "Weston",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82716",
    city: "Gillette",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82717",
    city: "Gillette",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82718",
    city: "Gillette",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82720",
    city: "Hulett",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82721",
    city: "Moorcroft",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82723",
    city: "Osage",
    state: "WY",
    areacode: 307,
    county: "Weston",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82725",
    city: "Recluse",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82727",
    city: "Rozet",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82729",
    city: "Sundance",
    state: "WY",
    areacode: 307,
    county: "Crook",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82730",
    city: "Upton",
    state: "WY",
    areacode: 307,
    county: "Weston",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82731",
    city: "Weston",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82732",
    city: "Wright",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82801",
    city: "Sheridan",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82831",
    city: "Arvada",
    state: "WY",
    areacode: 307,
    county: "Campbell",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82832",
    city: "Banner",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82833",
    city: "Big Horn",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82834",
    city: "Buffalo",
    state: "WY",
    areacode: 307,
    county: "Johnson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82835",
    city: "Clearmont",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82836",
    city: "Dayton",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82837",
    city: "Leiter",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82838",
    city: "Parkman",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82839",
    city: "Ranchester",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82840",
    city: "Saddlestring",
    state: "WY",
    areacode: 307,
    county: "Johnson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82842",
    city: "Story",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82844",
    city: "Wolf",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82845",
    city: "Wyarno",
    state: "WY",
    areacode: 307,
    county: "Sheridan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82901",
    city: "Rock Springs",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82902",
    city: "Rock Springs",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82922",
    city: "Bondurant",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82923",
    city: "Boulder",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82925",
    city: "Cora",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82929",
    city: "Little America",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82930",
    city: "Evanston",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82931",
    city: "Evanston",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82932",
    city: "Farson",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82933",
    city: "Fort Bridger",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82934",
    city: "Granger",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82935",
    city: "Green River",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82936",
    city: "Lonetree",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82937",
    city: "Lyman",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82938",
    city: "McKinnon",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82939",
    city: "Mountain View",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82941",
    city: "Pinedale",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82942",
    city: "Point of Rocks",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82943",
    city: "Reliance",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82944",
    city: "Robertson",
    state: "WY",
    areacode: 307,
    county: "Uinta",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "82945",
    city: "Superior",
    state: "WY",
    areacode: 307,
    county: "Sweetwater",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83001",
    city: "Jackson",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83002",
    city: "Jackson",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83011",
    city: "Kelly",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83012",
    city: "Moose",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83013",
    city: "Moran",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83014",
    city: "Wilson",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83025",
    city: "Teton Village",
    state: "WY",
    areacode: 307,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83101",
    city: "Kemmerer",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83110",
    city: "Afton",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83111",
    city: "Auburn",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83112",
    city: "Bedford",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83113",
    city: "Big Piney",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83114",
    city: "Cokeville",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83115",
    city: "Daniel",
    state: "WY",
    areacode: 307,
    county: "Sublette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83116",
    city: "Diamondville",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83118",
    city: "Etna",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83119",
    city: "Fairview",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83120",
    city: "Freedom",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83121",
    city: "Frontier",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83122",
    city: "Grover",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83123",
    city: "La Barge",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83124",
    city: "Opal",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83126",
    city: "Smoot",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83127",
    city: "Thayne",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83128",
    city: "Alpine",
    state: "WY",
    areacode: 307,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83201",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83202",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83203",
    city: "Fort Hall",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83204",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83205",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83206",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83209",
    city: "Pocatello",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83210",
    city: "Aberdeen",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83211",
    city: "American Falls",
    state: "ID",
    areacode: 208,
    county: "Power",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83212",
    city: "Arbon",
    state: "ID",
    areacode: 208,
    county: "Power",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83213",
    city: "Arco",
    state: "ID",
    areacode: 208,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83214",
    city: "Arimo",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83215",
    city: "Atomic City",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83217",
    city: "Bancroft",
    state: "ID",
    areacode: 208,
    county: "Caribou",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83218",
    city: "Basalt",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83220",
    city: "Bern",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83221",
    city: "Blackfoot",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83223",
    city: "Bloomington",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83226",
    city: "Challis",
    state: "ID",
    areacode: 208,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83227",
    city: "Clayton",
    state: "ID",
    areacode: 208,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83228",
    city: "Clifton",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83229",
    city: "Cobalt",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83232",
    city: "Dayton",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83233",
    city: "Dingle",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83234",
    city: "Downey",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83235",
    city: "Ellis",
    state: "ID",
    areacode: 208,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83236",
    city: "Firth",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83237",
    city: "Franklin",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83238",
    city: "Geneva",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83239",
    city: "Georgetown",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83241",
    city: "Grace",
    state: "ID",
    areacode: 208,
    county: "Caribou",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83243",
    city: "Holbrook",
    state: "ID",
    areacode: 208,
    county: "Oneida",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83244",
    city: "Howe",
    state: "ID",
    areacode: 208,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83245",
    city: "Inkom",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83246",
    city: "Lava Hot Springs",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83250",
    city: "McCammon",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83251",
    city: "Mackay",
    state: "ID",
    areacode: 208,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83252",
    city: "Malad City",
    state: "ID",
    areacode: 208,
    county: "Oneida",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83253",
    city: "May",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83254",
    city: "Montpelier",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83255",
    city: "Moore",
    state: "ID",
    areacode: 208,
    county: "Butte",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83256",
    city: "Moreland",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83261",
    city: "Paris",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83262",
    city: "Pingree",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83263",
    city: "Preston",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83271",
    city: "Rockland",
    state: "ID",
    areacode: 208,
    county: "Power",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83272",
    city: "Saint Charles",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83274",
    city: "Shelley",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83276",
    city: "Soda Springs",
    state: "ID",
    areacode: 208,
    county: "Caribou",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83277",
    city: "Springfield",
    state: "ID",
    areacode: 208,
    county: "Bingham",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83278",
    city: "Stanley",
    state: "ID",
    areacode: 208,
    county: "Custer",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83281",
    city: "Swanlake",
    state: "ID",
    areacode: 208,
    county: "Bannock",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83283",
    city: "Thatcher",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83285",
    city: "Wayan",
    state: "ID",
    areacode: 208,
    county: "Caribou",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83286",
    city: "Weston",
    state: "ID",
    areacode: 208,
    county: "Franklin",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83287",
    city: "Fish Haven",
    state: "ID",
    areacode: 208,
    county: "Bear Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83301",
    city: "Twin Falls",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83302",
    city: "Rogerson",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83303",
    city: "Twin Falls",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83311",
    city: "Albion",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83312",
    city: "Almo",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83313",
    city: "Bellevue",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83314",
    city: "Bliss",
    state: "ID",
    areacode: 208,
    county: "Gooding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83316",
    city: "Buhl",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83318",
    city: "Burley",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83320",
    city: "Carey",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83321",
    city: "Castleford",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83322",
    city: "Corral",
    state: "ID",
    areacode: 208,
    county: "Camas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83323",
    city: "Declo",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83324",
    city: "Dietrich",
    state: "ID",
    areacode: 208,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83325",
    city: "Eden",
    state: "ID",
    areacode: 208,
    county: "Jerome",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83327",
    city: "Fairfield",
    state: "ID",
    areacode: 208,
    county: "Camas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83328",
    city: "Filer",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83330",
    city: "Gooding",
    state: "ID",
    areacode: 208,
    county: "Gooding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83332",
    city: "Hagerman",
    state: "ID",
    areacode: 208,
    county: "Gooding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83333",
    city: "Hailey",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83334",
    city: "Hansen",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83335",
    city: "Hazelton",
    state: "ID",
    areacode: 208,
    county: "Jerome",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83336",
    city: "Heyburn",
    state: "ID",
    areacode: 208,
    county: "Minidoka",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83337",
    city: "Hill City",
    state: "ID",
    areacode: 208,
    county: "Camas",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83338",
    city: "Jerome",
    state: "ID",
    areacode: 208,
    county: "Jerome",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83340",
    city: "Ketchum",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83341",
    city: "Kimberly",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83342",
    city: "Malta",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83343",
    city: "Minidoka",
    state: "ID",
    areacode: 208,
    county: "Minidoka",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83344",
    city: "Murtaugh",
    state: "ID",
    areacode: 208,
    county: "Twin Falls",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83346",
    city: "Oakley",
    state: "ID",
    areacode: 208,
    county: "Cassia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83347",
    city: "Paul",
    state: "ID",
    areacode: 208,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83348",
    city: "Picabo",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83349",
    city: "Richfield",
    state: "ID",
    areacode: 208,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83350",
    city: "Rupert",
    state: "ID",
    areacode: 208,
    county: "Minidoka",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83352",
    city: "Shoshone",
    state: "ID",
    areacode: 208,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83353",
    city: "Sun Valley",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83354",
    city: "Sun Valley",
    state: "ID",
    areacode: 208,
    county: "Blaine",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83355",
    city: "Wendell",
    state: "ID",
    areacode: 208,
    county: "Gooding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83401",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83402",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83403",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83404",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83405",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83406",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83414",
    city: "Alta",
    state: "WY",
    areacode: 208,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83415",
    city: "Idaho Falls",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83420",
    city: "Ashton",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83421",
    city: "Chester",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83422",
    city: "Driggs",
    state: "ID",
    areacode: 208,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83423",
    city: "Dubois",
    state: "ID",
    areacode: 208,
    county: "Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83424",
    city: "Felt",
    state: "ID",
    areacode: 208,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83425",
    city: "Hamer",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83427",
    city: "Iona",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83428",
    city: "Irwin",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83429",
    city: "Island Park",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83431",
    city: "Lewisville",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83433",
    city: "Macks Inn",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83434",
    city: "Menan",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83435",
    city: "Monteview",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83436",
    city: "Newdale",
    state: "ID",
    areacode: 208,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83438",
    city: "Parker",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83440",
    city: "Rexburg",
    state: "ID",
    areacode: 208,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83441",
    city: "Rexburg",
    state: "ID",
    areacode: 208,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83442",
    city: "Rigby",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83443",
    city: "Ririe",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83444",
    city: "Roberts",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83445",
    city: "Saint Anthony",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83446",
    city: "Spencer",
    state: "ID",
    areacode: 208,
    county: "Clark",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83448",
    city: "Sugar City",
    state: "ID",
    areacode: 208,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83449",
    city: "Swan Valley",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83450",
    city: "Terreton",
    state: "ID",
    areacode: 208,
    county: "Jefferson",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83451",
    city: "Teton",
    state: "ID",
    areacode: 208,
    county: "Fremont",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83452",
    city: "Tetonia",
    state: "ID",
    areacode: 208,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83454",
    city: "Ucon",
    state: "ID",
    areacode: 208,
    county: "Bonneville",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83455",
    city: "Victor",
    state: "ID",
    areacode: 208,
    county: "Teton",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83460",
    city: "Rexburg",
    state: "ID",
    areacode: 208,
    county: "Madison",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83462",
    city: "Carmen",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83463",
    city: "Gibbonsville",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83464",
    city: "Leadore",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83465",
    city: "Lemhi",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83466",
    city: "North Fork",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83467",
    city: "Salmon",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83468",
    city: "Tendoy",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83469",
    city: "Shoup",
    state: "ID",
    areacode: 208,
    county: "Lemhi",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83501",
    city: "Lewiston",
    state: "ID",
    areacode: 208,
    county: "Nez Perce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83520",
    city: "Ahsahka",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83522",
    city: "Cottonwood",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83523",
    city: "Craigmont",
    state: "ID",
    areacode: 208,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83524",
    city: "Culdesac",
    state: "ID",
    areacode: 208,
    county: "Nez Perce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83525",
    city: "Elk City",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83526",
    city: "Ferdinand",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83530",
    city: "Grangeville",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83531",
    city: "Fenn",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83533",
    city: "Greencreek",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83535",
    city: "Juliaetta",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83536",
    city: "Kamiah",
    state: "ID",
    areacode: 208,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83537",
    city: "Kendrick",
    state: "ID",
    areacode: 208,
    county: "Nez Perce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83539",
    city: "Kooskia",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83540",
    city: "Lapwai",
    state: "ID",
    areacode: 208,
    county: "Nez Perce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83541",
    city: "Lenore",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83542",
    city: "Lucile",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83543",
    city: "Nezperce",
    state: "ID",
    areacode: 208,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83544",
    city: "Orofino",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83545",
    city: "Peck",
    state: "ID",
    areacode: 208,
    county: "Nez Perce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83546",
    city: "Pierce",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83547",
    city: "Pollock",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83548",
    city: "Reubens",
    state: "ID",
    areacode: 208,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83549",
    city: "Riggins",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83552",
    city: "Stites",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83553",
    city: "Weippe",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83554",
    city: "White Bird",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83555",
    city: "Winchester",
    state: "ID",
    areacode: 208,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83602",
    city: "Banks",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83604",
    city: "Bruneau",
    state: "ID",
    areacode: 208,
    county: "Owyhee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83605",
    city: "Caldwell",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83606",
    city: "Caldwell",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83607",
    city: "Caldwell",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83610",
    city: "Cambridge",
    state: "ID",
    areacode: 208,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83611",
    city: "Cascade",
    state: "ID",
    areacode: 208,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83612",
    city: "Council",
    state: "ID",
    areacode: 208,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83615",
    city: "Donnelly",
    state: "ID",
    areacode: 208,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83616",
    city: "Eagle",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83617",
    city: "Emmett",
    state: "ID",
    areacode: 208,
    county: "Gem",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83619",
    city: "Fruitland",
    state: "ID",
    areacode: 208,
    county: "Payette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83622",
    city: "Garden Valley",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83623",
    city: "Glenns Ferry",
    state: "ID",
    areacode: 208,
    county: "Elmore",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83624",
    city: "Grand View",
    state: "ID",
    areacode: 208,
    county: "Owyhee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83626",
    city: "Greenleaf",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83627",
    city: "Hammett",
    state: "ID",
    areacode: 208,
    county: "Elmore",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83628",
    city: "Homedale",
    state: "ID",
    areacode: 208,
    county: "Owyhee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83629",
    city: "Horseshoe Bend",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83630",
    city: "Huston",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83631",
    city: "Idaho City",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83632",
    city: "Indian Valley",
    state: "ID",
    areacode: 208,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83633",
    city: "King Hill",
    state: "ID",
    areacode: 208,
    county: "Elmore",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83634",
    city: "Kuna",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83635",
    city: "Lake Fork",
    state: "ID",
    areacode: 208,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83636",
    city: "Letha",
    state: "ID",
    areacode: 208,
    county: "Gem",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83637",
    city: "Lowman",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83638",
    city: "McCall",
    state: "ID",
    areacode: 208,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83639",
    city: "Marsing",
    state: "ID",
    areacode: 208,
    county: "Owyhee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83641",
    city: "Melba",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83642",
    city: "Meridian",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83643",
    city: "Mesa",
    state: "ID",
    areacode: 208,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83644",
    city: "Middleton",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83645",
    city: "Midvale",
    state: "ID",
    areacode: 208,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83646",
    city: "Meridian",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83647",
    city: "Mountain Home",
    state: "ID",
    areacode: 208,
    county: "Elmore",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83648",
    city: "Mountain Home AFB",
    state: "ID",
    areacode: 208,
    county: "Elmore",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83650",
    city: "Murphy",
    state: "ID",
    areacode: 208,
    county: "Owyhee",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83651",
    city: "Nampa",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83652",
    city: "Nampa",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83653",
    city: "Nampa",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83654",
    city: "New Meadows",
    state: "ID",
    areacode: 208,
    county: "Adams",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83655",
    city: "New Plymouth",
    state: "ID",
    areacode: 208,
    county: "Payette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83656",
    city: "Notus",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83657",
    city: "Ola",
    state: "ID",
    areacode: 208,
    county: "Gem",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83660",
    city: "Parma",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83661",
    city: "Payette",
    state: "ID",
    areacode: 208,
    county: "Payette",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83666",
    city: "Placerville",
    state: "ID",
    areacode: 208,
    county: "Boise",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83669",
    city: "Star",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83670",
    city: "Sweet",
    state: "ID",
    areacode: 208,
    county: "Gem",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83671",
    city: "Warren",
    state: "ID",
    areacode: 208,
    county: "Idaho",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83672",
    city: "Weiser",
    state: "ID",
    areacode: 208,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83676",
    city: "Wilder",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83677",
    city: "Yellow Pine",
    state: "ID",
    areacode: 208,
    county: "Valley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83680",
    city: "Meridian",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83686",
    city: "Nampa",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83687",
    city: "Nampa",
    state: "ID",
    areacode: 208,
    county: "Canyon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83701",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83702",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83703",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83704",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83705",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83706",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83707",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83708",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83709",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83711",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83712",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83713",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83714",
    city: "Garden City",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83715",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83716",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83717",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83719",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83720",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83722",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83724",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83725",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83726",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83728",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83729",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83732",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83735",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83756",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83799",
    city: "Boise",
    state: "ID",
    areacode: 208,
    county: "Ada",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "83801",
    city: "Athol",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83802",
    city: "Avery",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83803",
    city: "Bayview",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83804",
    city: "Blanchard",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83805",
    city: "Bonners Ferry",
    state: "ID",
    areacode: 208,
    county: "Boundary",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83806",
    city: "Bovill",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83808",
    city: "Calder",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83809",
    city: "Careywood",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83810",
    city: "Cataldo",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83811",
    city: "Clark Fork",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83812",
    city: "Clarkia",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83813",
    city: "Cocolalla",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83814",
    city: "Coeur d Alene",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83815",
    city: "Coeur d Alene",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83816",
    city: "Coeur d Alene",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83821",
    city: "Coolin",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83822",
    city: "Oldtown",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83823",
    city: "Deary",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83824",
    city: "Desmet",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83825",
    city: "Dover",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83826",
    city: "Eastport",
    state: "ID",
    areacode: 208,
    county: "Boundary",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83827",
    city: "Elk River",
    state: "ID",
    areacode: 208,
    county: "Clearwater",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83830",
    city: "Fernwood",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83832",
    city: "Genesee",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83833",
    city: "Harrison",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83834",
    city: "Harvard",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83835",
    city: "Hayden",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83836",
    city: "Hope",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83837",
    city: "Kellogg",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83839",
    city: "Kingston",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83840",
    city: "Kootenai",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83841",
    city: "Laclede",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83842",
    city: "Medimont",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83843",
    city: "Moscow",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83844",
    city: "Moscow",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83845",
    city: "Moyie Springs",
    state: "ID",
    areacode: 208,
    county: "Boundary",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83846",
    city: "Mullan",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83847",
    city: "Naples",
    state: "ID",
    areacode: 208,
    county: "Boundary",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83848",
    city: "Nordman",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83849",
    city: "Osburn",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83850",
    city: "Pinehurst",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83851",
    city: "Plummer",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83852",
    city: "Ponderay",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83853",
    city: "Porthill",
    state: "ID",
    areacode: 208,
    county: "Boundary",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83854",
    city: "Post Falls",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83855",
    city: "Potlatch",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83856",
    city: "Priest River",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83857",
    city: "Princeton",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83858",
    city: "Rathdrum",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83860",
    city: "Sagle",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83861",
    city: "Saint Maries",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83864",
    city: "Sandpoint",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83865",
    city: "Colburn",
    state: "ID",
    areacode: 208,
    county: "Bonner",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83866",
    city: "Santa",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83867",
    city: "Silverton",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83868",
    city: "Smelterville",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83869",
    city: "Spirit Lake",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83870",
    city: "Tensed",
    state: "ID",
    areacode: 208,
    county: "Benewah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83871",
    city: "Troy",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83872",
    city: "Viola",
    state: "ID",
    areacode: 208,
    county: "Latah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83873",
    city: "Wallace",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83874",
    city: "Murray",
    state: "ID",
    areacode: 208,
    county: "Shoshone",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83876",
    city: "Worley",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "83877",
    city: "Post Falls",
    state: "ID",
    areacode: 208,
    county: "Kootenai",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "84001",
    city: "Altamont",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84002",
    city: "Altonah",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84003",
    city: "American Fork",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84004",
    city: "Alpine",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84005",
    city: "Eagle Mountain",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84006",
    city: "Bingham Canyon",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84007",
    city: "Bluebell",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84008",
    city: "Bonanza",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84009",
    city: "South Jordan",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84010",
    city: "Bountiful",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84011",
    city: "Bountiful",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84013",
    city: "Cedar Valley",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84014",
    city: "Centerville",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84015",
    city: "Clearfield",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84016",
    city: "Clearfield",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84017",
    city: "Coalville",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84018",
    city: "Croydon",
    state: "UT",
    areacode: 801,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84020",
    city: "Draper",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84021",
    city: "Duchesne",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84022",
    city: "Dugway",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84023",
    city: "Dutch John",
    state: "UT",
    areacode: 435,
    county: "Daggett",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84024",
    city: "Echo",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84025",
    city: "Farmington",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84026",
    city: "Fort Duchesne",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84027",
    city: "Fruitland",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84028",
    city: "Garden City",
    state: "UT",
    areacode: 435,
    county: "Rich",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84029",
    city: "Grantsville",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84031",
    city: "Hanna",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84032",
    city: "Heber City",
    state: "UT",
    areacode: 435,
    county: "Wasatch",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84033",
    city: "Henefer",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84034",
    city: "Ibapah",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84035",
    city: "Jensen",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84036",
    city: "Kamas",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84037",
    city: "Kaysville",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84038",
    city: "Laketown",
    state: "UT",
    areacode: 435,
    county: "Rich",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84039",
    city: "Lapoint",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84040",
    city: "Layton",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84041",
    city: "Layton",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84042",
    city: "Lindon",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84043",
    city: "Lehi",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84044",
    city: "Magna",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84045",
    city: "Saratoga Springs",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84046",
    city: "Manila",
    state: "UT",
    areacode: 435,
    county: "Daggett",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84047",
    city: "Midvale",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84049",
    city: "Midway",
    state: "UT",
    areacode: 435,
    county: "Wasatch",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84050",
    city: "Morgan",
    state: "UT",
    areacode: 801,
    county: "Morgan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84051",
    city: "Mountain Home",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84052",
    city: "Myton",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84053",
    city: "Neola",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84054",
    city: "North Salt Lake",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84055",
    city: "Oakley",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84056",
    city: "Hill AFB",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84057",
    city: "Orem",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84058",
    city: "Orem",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84059",
    city: "Vineyard",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84060",
    city: "Park City",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84061",
    city: "Peoa",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84062",
    city: "Pleasant Grove",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84063",
    city: "Randlett",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84064",
    city: "Randolph",
    state: "UT",
    areacode: 435,
    county: "Rich",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84065",
    city: "Riverton",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84066",
    city: "Roosevelt",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84067",
    city: "Roy",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84068",
    city: "Park City",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84069",
    city: "Rush Valley",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84070",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84071",
    city: "Stockton",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84072",
    city: "Tabiona",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84073",
    city: "Talmage",
    state: "UT",
    areacode: 435,
    county: "Duchesne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84074",
    city: "Tooele",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84075",
    city: "Syracuse",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84076",
    city: "Tridell",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84078",
    city: "Vernal",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84079",
    city: "Vernal",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84080",
    city: "Vernon",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84081",
    city: "West Jordan",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84082",
    city: "Wallsburg",
    state: "UT",
    areacode: 435,
    county: "Wasatch",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84083",
    city: "Wendover",
    state: "UT",
    areacode: 435,
    county: "Tooele",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84084",
    city: "West Jordan",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84085",
    city: "Whiterocks",
    state: "UT",
    areacode: 435,
    county: "Uintah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84086",
    city: "Woodruff",
    state: "UT",
    areacode: 435,
    county: "Rich",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84087",
    city: "Woods Cross",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84088",
    city: "West Jordan",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84089",
    city: "Clearfield",
    state: "UT",
    areacode: 801,
    county: "Davis",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84090",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84091",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84092",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84093",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84094",
    city: "Sandy",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84095",
    city: "South Jordan",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84096",
    city: "Herriman",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84097",
    city: "Orem",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84098",
    city: "Park City",
    state: "UT",
    areacode: 435,
    county: "Summit",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84101",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84102",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84103",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84104",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84105",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84106",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84107",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84108",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84109",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84110",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84111",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84112",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84113",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84114",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84115",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84116",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84117",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84118",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84119",
    city: "West Valley City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84120",
    city: "West Valley City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84121",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84122",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84123",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84124",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84125",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84126",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84127",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84128",
    city: "West Valley City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84129",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84130",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84131",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84132",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84133",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84134",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84138",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84139",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84141",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84143",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84145",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84147",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84148",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84150",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84151",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84152",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84157",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84158",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84165",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84170",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84171",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84180",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84184",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84190",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84199",
    city: "Salt Lake City",
    state: "UT",
    areacode: 801,
    county: "Salt Lake",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84201",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84244",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84301",
    city: "Bear River City",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84302",
    city: "Brigham City",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84304",
    city: "Cache Junction",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84305",
    city: "Clarkston",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84306",
    city: "Collinston",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84307",
    city: "Corinne",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84308",
    city: "Cornish",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84309",
    city: "Deweyville",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84310",
    city: "Eden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84311",
    city: "Fielding",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84312",
    city: "Garland",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84313",
    city: "Grouse Creek",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84314",
    city: "Honeyville",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84315",
    city: "Hooper",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84316",
    city: "Howell",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84317",
    city: "Huntsville",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84318",
    city: "Hyde Park",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84319",
    city: "Hyrum",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84320",
    city: "Lewiston",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84321",
    city: "Logan",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84322",
    city: "Logan",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84323",
    city: "Logan",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84324",
    city: "Mantua",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84325",
    city: "Mendon",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84326",
    city: "Millville",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84327",
    city: "Newton",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84328",
    city: "Paradise",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84329",
    city: "Park Valley",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84330",
    city: "Plymouth",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84331",
    city: "Portage",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84332",
    city: "Providence",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84333",
    city: "Richmond",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84334",
    city: "Riverside",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84335",
    city: "Smithfield",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84336",
    city: "Snowville",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84337",
    city: "Tremonton",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84338",
    city: "Trenton",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84339",
    city: "Wellsville",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84340",
    city: "Willard",
    state: "UT",
    areacode: 435,
    county: "Box Elder",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84341",
    city: "Logan",
    state: "UT",
    areacode: 435,
    county: "Cache",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84401",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84402",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84403",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84404",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84405",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84407",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84408",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84409",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84412",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84414",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84415",
    city: "Ogden",
    state: "UT",
    areacode: 801,
    county: "Weber",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84501",
    city: "Price",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84510",
    city: "Aneth",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84511",
    city: "Blanding",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84512",
    city: "Bluff",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84513",
    city: "Castle Dale",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84515",
    city: "Cisco",
    state: "UT",
    areacode: 435,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84516",
    city: "Clawson",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84518",
    city: "Cleveland",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84520",
    city: "East Carbon",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84521",
    city: "Elmo",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84522",
    city: "Emery",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84523",
    city: "Ferron",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84525",
    city: "Green River",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84526",
    city: "Helper",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84528",
    city: "Huntington",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84529",
    city: "Kenilworth",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84530",
    city: "La Sal",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84531",
    city: "Mexican Hat",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84532",
    city: "Moab",
    state: "UT",
    areacode: 435,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84533",
    city: "Lake Powell",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84534",
    city: "Montezuma Creek",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84535",
    city: "Monticello",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84536",
    city: "Monument Valley",
    state: "UT",
    areacode: 435,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84537",
    city: "Orangeville",
    state: "UT",
    areacode: 435,
    county: "Emery",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84539",
    city: "Sunnyside",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84540",
    city: "Thompson",
    state: "UT",
    areacode: 435,
    county: "Grand",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84542",
    city: "Wellington",
    state: "UT",
    areacode: 435,
    county: "Carbon",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84601",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84602",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84603",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84604",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84605",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84606",
    city: "Provo",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84620",
    city: "Aurora",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84621",
    city: "Axtell",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84622",
    city: "Centerfield",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84623",
    city: "Chester",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84624",
    city: "Delta",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84626",
    city: "Elberta",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84627",
    city: "Ephraim",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84628",
    city: "Eureka",
    state: "UT",
    areacode: 435,
    county: "Juab",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84629",
    city: "Fairview",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84630",
    city: "Fayette",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84631",
    city: "Fillmore",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84632",
    city: "Fountain Green",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84633",
    city: "Goshen",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84634",
    city: "Gunnison",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84635",
    city: "Hinckley",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84636",
    city: "Holden",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84637",
    city: "Kanosh",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84638",
    city: "Leamington",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84639",
    city: "Levan",
    state: "UT",
    areacode: 435,
    county: "Juab",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84640",
    city: "Lynndyl",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84642",
    city: "Manti",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84643",
    city: "Mayfield",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84644",
    city: "Meadow",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84645",
    city: "Mona",
    state: "UT",
    areacode: 435,
    county: "Juab",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84646",
    city: "Moroni",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84647",
    city: "Mount Pleasant",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84648",
    city: "Nephi",
    state: "UT",
    areacode: 435,
    county: "Juab",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84649",
    city: "Oak City",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84651",
    city: "Payson",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84652",
    city: "Redmond",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84653",
    city: "Salem",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84654",
    city: "Salina",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84655",
    city: "Santaquin",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84656",
    city: "Scipio",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84657",
    city: "Sigurd",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84660",
    city: "Spanish Fork",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84662",
    city: "Spring City",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84663",
    city: "Springville",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84664",
    city: "Mapleton",
    state: "UT",
    areacode: 801,
    county: "Utah",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84665",
    city: "Sterling",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84667",
    city: "Wales",
    state: "UT",
    areacode: 435,
    county: "Sanpete",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84701",
    city: "Richfield",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84710",
    city: "Alton",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84711",
    city: "Annabella",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84712",
    city: "Antimony",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84713",
    city: "Beaver",
    state: "UT",
    areacode: 435,
    county: "Beaver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84714",
    city: "Beryl",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84715",
    city: "Bicknell",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84716",
    city: "Boulder",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84718",
    city: "Cannonville",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84719",
    city: "Brian Head",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84720",
    city: "Cedar City",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84721",
    city: "Cedar City",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84722",
    city: "Central",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84723",
    city: "Circleville",
    state: "UT",
    areacode: 435,
    county: "Piute",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84724",
    city: "Elsinore",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84725",
    city: "Enterprise",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84726",
    city: "Escalante",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84728",
    city: "Garrison",
    state: "UT",
    areacode: 435,
    county: "Millard",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84729",
    city: "Glendale",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84730",
    city: "Glenwood",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84731",
    city: "Greenville",
    state: "UT",
    areacode: 435,
    county: "Beaver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84732",
    city: "Greenwich",
    state: "UT",
    areacode: 435,
    county: "Piute",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84733",
    city: "Gunlock",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84734",
    city: "Hanksville",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84735",
    city: "Hatch",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84736",
    city: "Henrieville",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84737",
    city: "Hurricane",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84738",
    city: "Ivins",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84739",
    city: "Joseph",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84740",
    city: "Junction",
    state: "UT",
    areacode: 435,
    county: "Piute",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84741",
    city: "Kanab",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84742",
    city: "Kanarraville",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84743",
    city: "Kingston",
    state: "UT",
    areacode: 435,
    county: "Piute",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84744",
    city: "Koosharem",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84745",
    city: "La Verkin",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84746",
    city: "Leeds",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84747",
    city: "Loa",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84749",
    city: "Lyman",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84750",
    city: "Marysvale",
    state: "UT",
    areacode: 435,
    county: "Piute",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84751",
    city: "Milford",
    state: "UT",
    areacode: 435,
    county: "Beaver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84752",
    city: "Minersville",
    state: "UT",
    areacode: 435,
    county: "Beaver",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84753",
    city: "Modena",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84754",
    city: "Monroe",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84755",
    city: "Mount Carmel",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84756",
    city: "Newcastle",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84757",
    city: "New Harmony",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84758",
    city: "Orderville",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84759",
    city: "Panguitch",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84760",
    city: "Paragonah",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84761",
    city: "Parowan",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84762",
    city: "Duck Creek Village",
    state: "UT",
    areacode: 435,
    county: "Kane",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84763",
    city: "Rockville",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84764",
    city: "Bryce",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84765",
    city: "Santa Clara",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84766",
    city: "Sevier",
    state: "UT",
    areacode: 435,
    county: "Sevier",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84767",
    city: "Springdale",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84770",
    city: "Saint George",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84771",
    city: "Saint George",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84772",
    city: "Summit",
    state: "UT",
    areacode: 435,
    county: "Iron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84773",
    city: "Teasdale",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84774",
    city: "Toquerville",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84775",
    city: "Torrey",
    state: "UT",
    areacode: 435,
    county: "Wayne",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84776",
    city: "Tropic",
    state: "UT",
    areacode: 435,
    county: "Garfield",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84779",
    city: "Virgin",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84780",
    city: "Washington",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84781",
    city: "Pine Valley",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84782",
    city: "Veyo",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84783",
    city: "Dammeron Valley",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84784",
    city: "Hildale",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84790",
    city: "Saint George",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "84791",
    city: "Saint George",
    state: "UT",
    areacode: 435,
    county: "Washington",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85001",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85002",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85003",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85004",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85005",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85006",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85007",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85008",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85009",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85010",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85011",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85012",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85013",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85014",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85015",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85016",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85017",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85018",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85019",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85020",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85021",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85022",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85023",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85024",
    city: "Phoenix",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85026",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85027",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85028",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85029",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85030",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85031",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85032",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85033",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85034",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85035",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85036",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85037",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85038",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85039",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85040",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85041",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85042",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85043",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85044",
    city: "Phoenix",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85045",
    city: "Phoenix",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85046",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85048",
    city: "Phoenix",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85050",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85051",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85053",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85054",
    city: "Phoenix",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85060",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85061",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85062",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85063",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85064",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85065",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85066",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85067",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85068",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85069",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85070",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85071",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85072",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85073",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85074",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85075",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85076",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85078",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85079",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85080",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85082",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85083",
    city: "Phoenix",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85085",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85086",
    city: "Phoenix",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85087",
    city: "New River",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85117",
    city: "Apache Junction",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85118",
    city: "Gold Canyon",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85119",
    city: "Apache Junction",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85120",
    city: "Apache Junction",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85121",
    city: "Bapchule",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85122",
    city: "Casa Grande",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85123",
    city: "Arizona City",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85127",
    city: "Chandler Heights",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85128",
    city: "Coolidge",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85130",
    city: "Casa Grande",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85131",
    city: "Eloy",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85132",
    city: "Florence",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85135",
    city: "Hayden",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85137",
    city: "Kearny",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85138",
    city: "Maricopa",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85139",
    city: "Maricopa",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85140",
    city: "San Tan Valley",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85141",
    city: "Picacho",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85142",
    city: "Queen Creek",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85143",
    city: "San Tan Valley",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85145",
    city: "Red Rock",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85147",
    city: "Sacaton",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85172",
    city: "Stanfield",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85173",
    city: "Superior",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85178",
    city: "Apache Junction",
    state: "AZ",
    areacode: 480,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85190",
    city: "Tortilla Flat",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85191",
    city: "Valley Farms",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85192",
    city: "Winkelman",
    state: "AZ",
    areacode: 928,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85193",
    city: "Casa Grande",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85194",
    city: "Casa Grande",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85201",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85202",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85203",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85204",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85205",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85206",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85207",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85208",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85209",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85210",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85211",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85212",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85213",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85214",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85215",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85216",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85224",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85225",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85226",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85233",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85234",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85236",
    city: "Higley",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85244",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85246",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85248",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85249",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85250",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85251",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85252",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85253",
    city: "Paradise Valley",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85254",
    city: "Scottsdale",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85255",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85256",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85257",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85258",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85259",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85260",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85261",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85262",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85263",
    city: "Rio Verde",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85264",
    city: "Fort McDowell",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85266",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85267",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85268",
    city: "Fountain Hills",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85269",
    city: "Fountain Hills",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85271",
    city: "Scottsdale",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85274",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85275",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85277",
    city: "Mesa",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85280",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85281",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85282",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85283",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85284",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85285",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85286",
    city: "Chandler",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85287",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85288",
    city: "Tempe",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85295",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85296",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85297",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85298",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85299",
    city: "Gilbert",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85301",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85302",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85303",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85304",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85305",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85306",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85307",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85308",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85309",
    city: "Luke AFB",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85310",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85311",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85312",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85318",
    city: "Glendale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85320",
    city: "Aguila",
    state: "AZ",
    areacode: 520,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85321",
    city: "Ajo",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85322",
    city: "Arlington",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85323",
    city: "Avondale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85324",
    city: "Black Canyon City",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85325",
    city: "Bouse",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85326",
    city: "Buckeye",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85327",
    city: "Cave Creek",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85328",
    city: "Cibola",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85329",
    city: "Cashion",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85331",
    city: "Cave Creek",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85332",
    city: "Congress",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85333",
    city: "Dateland",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85334",
    city: "Ehrenberg",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85335",
    city: "El Mirage",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85336",
    city: "San Luis",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85337",
    city: "Gila Bend",
    state: "AZ",
    areacode: 928,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85338",
    city: "Goodyear",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85339",
    city: "Laveen",
    state: "AZ",
    areacode: 602,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85340",
    city: "Litchfield Park",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85341",
    city: "Lukeville",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85342",
    city: "Morristown",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85343",
    city: "Palo Verde",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85344",
    city: "Parker",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85345",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85346",
    city: "Quartzsite",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85347",
    city: "Roll",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85348",
    city: "Salome",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85349",
    city: "San Luis",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85350",
    city: "Somerton",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85351",
    city: "Sun City",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85352",
    city: "Tacna",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85353",
    city: "Tolleson",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85354",
    city: "Tonopah",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85355",
    city: "Waddell",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85356",
    city: "Wellton",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85357",
    city: "Wenden",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85358",
    city: "Wickenburg",
    state: "AZ",
    areacode: 928,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85359",
    city: "Quartzsite",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85360",
    city: "Wikieup",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85361",
    city: "Wittmann",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85362",
    city: "Yarnell",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85363",
    city: "Youngtown",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85364",
    city: "Yuma",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85365",
    city: "Yuma",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85366",
    city: "Yuma",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85367",
    city: "Yuma",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85369",
    city: "Yuma",
    state: "AZ",
    areacode: 928,
    county: "Yuma",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85371",
    city: "Poston",
    state: "AZ",
    areacode: 928,
    county: "La Paz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85372",
    city: "Sun City",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85373",
    city: "Sun City",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85374",
    city: "Surprise",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85375",
    city: "Sun City West",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85376",
    city: "Sun City West",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85377",
    city: "Carefree",
    state: "AZ",
    areacode: 480,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85378",
    city: "Surprise",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85379",
    city: "Surprise",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85380",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85381",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85382",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85383",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85385",
    city: "Peoria",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85387",
    city: "Surprise",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85388",
    city: "Surprise",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85390",
    city: "Wickenburg",
    state: "AZ",
    areacode: 928,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85392",
    city: "Avondale",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85395",
    city: "Goodyear",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85396",
    city: "Buckeye",
    state: "AZ",
    areacode: 623,
    county: "Maricopa",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85501",
    city: "Globe",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85502",
    city: "Globe",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85530",
    city: "Bylas",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85531",
    city: "Central",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85532",
    city: "Claypool",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85533",
    city: "Clifton",
    state: "AZ",
    areacode: 928,
    county: "Greenlee",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85534",
    city: "Duncan",
    state: "AZ",
    areacode: 928,
    county: "Greenlee",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85535",
    city: "Eden",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85536",
    city: "Fort Thomas",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85539",
    city: "Miami",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85540",
    city: "Morenci",
    state: "AZ",
    areacode: 928,
    county: "Greenlee",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85541",
    city: "Payson",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85542",
    city: "Peridot",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85543",
    city: "Pima",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85544",
    city: "Pine",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85545",
    city: "Roosevelt",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85546",
    city: "Safford",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85547",
    city: "Payson",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85548",
    city: "Safford",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85550",
    city: "San Carlos",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85551",
    city: "Solomon",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85552",
    city: "Thatcher",
    state: "AZ",
    areacode: 928,
    county: "Graham",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85553",
    city: "Tonto Basin",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85554",
    city: "Young",
    state: "AZ",
    areacode: 928,
    county: "Gila",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85601",
    city: "Arivaca",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85602",
    city: "Benson",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85603",
    city: "Bisbee",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85605",
    city: "Bowie",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85606",
    city: "Cochise",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85607",
    city: "Douglas",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85608",
    city: "Douglas",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85609",
    city: "Dragoon",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85610",
    city: "Elfrida",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85611",
    city: "Elgin",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85613",
    city: "Fort Huachuca",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85614",
    city: "Green Valley",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85615",
    city: "Hereford",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85616",
    city: "Huachuca City",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85617",
    city: "McNeal",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85618",
    city: "Mammoth",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85619",
    city: "Mount Lemmon",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85620",
    city: "Naco",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85621",
    city: "Nogales",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85622",
    city: "Green Valley",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85623",
    city: "Oracle",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85624",
    city: "Patagonia",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85625",
    city: "Pearce",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85626",
    city: "Pirtleville",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85627",
    city: "Pomerene",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85628",
    city: "Nogales",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85629",
    city: "Sahuarita",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85630",
    city: "Saint David",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85631",
    city: "San Manuel",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85632",
    city: "San Simon",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85633",
    city: "Sasabe",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85634",
    city: "Sells",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85635",
    city: "Sierra Vista",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85636",
    city: "Sierra Vista",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85637",
    city: "Sonoita",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85638",
    city: "Tombstone",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85639",
    city: "Topawa",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85640",
    city: "Tumacacori",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85641",
    city: "Vail",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85643",
    city: "Willcox",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85644",
    city: "Willcox",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85645",
    city: "Amado",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85646",
    city: "Tubac",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85648",
    city: "Rio Rico",
    state: "AZ",
    areacode: 520,
    county: "Santa Cruz",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85650",
    city: "Sierra Vista",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85652",
    city: "Cortaro",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85653",
    city: "Marana",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85654",
    city: "Rillito",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85655",
    city: "Douglas",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85658",
    city: "Marana",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85670",
    city: "Fort Huachuca",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85671",
    city: "Sierra Vista",
    state: "AZ",
    areacode: 520,
    county: "Cochise",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85701",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85702",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85703",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85704",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85705",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85706",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85707",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85708",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85709",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85710",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85711",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85712",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85713",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85714",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85715",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85716",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85717",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85718",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85719",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85720",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85721",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85722",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85723",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85724",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85725",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85726",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85728",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85730",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85731",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85732",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85733",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85734",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85735",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85736",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85737",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85738",
    city: "Catalina",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85739",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pinal",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85740",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85741",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85742",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85743",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85744",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85745",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85746",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85747",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85748",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85749",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85750",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85751",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85752",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85754",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85755",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85756",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85757",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85775",
    city: "Tucson",
    state: "AZ",
    areacode: 520,
    county: "Pima",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85901",
    city: "Show Low",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85902",
    city: "Show Low",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85911",
    city: "Cibecue",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85912",
    city: "White Mountain Lake",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85920",
    city: "Alpine",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85922",
    city: "Blue",
    state: "AZ",
    areacode: 928,
    county: "Greenlee",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85923",
    city: "Clay Springs",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85924",
    city: "Concho",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85925",
    city: "Eagar",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85926",
    city: "Fort Apache",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85927",
    city: "Greer",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85928",
    city: "Heber",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85929",
    city: "Lakeside",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85930",
    city: "McNary",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85931",
    city: "Forest Lakes",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85932",
    city: "Nutrioso",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85933",
    city: "Overgaard",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85934",
    city: "Pinedale",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85935",
    city: "Pinetop",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85936",
    city: "Saint Johns",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85937",
    city: "Snowflake",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85938",
    city: "Springerville",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85939",
    city: "Taylor",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85940",
    city: "Vernon",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "85941",
    city: "Whiteriver",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "85942",
    city: "Woodruff",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86001",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86002",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86003",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86004",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86005",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86011",
    city: "Flagstaff",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86015",
    city: "Bellemont",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86016",
    city: "Gray Mountain",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86017",
    city: "Munds Park",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86018",
    city: "Parks",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86020",
    city: "Cameron",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86021",
    city: "Colorado City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86022",
    city: "Fredonia",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86023",
    city: "Grand Canyon",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86024",
    city: "Happy Jack",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86025",
    city: "Holbrook",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86028",
    city: "Petrified Forest Natl Pk",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86029",
    city: "Sun Valley",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86030",
    city: "Hotevilla",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86031",
    city: "Indian Wells",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86032",
    city: "Joseph City",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86033",
    city: "Kayenta",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86034",
    city: "Keams Canyon",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86035",
    city: "Leupp",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86036",
    city: "Marble Canyon",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86038",
    city: "Mormon Lake",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86039",
    city: "Kykotsmovi Village",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86040",
    city: "Page",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86042",
    city: "Polacca",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86043",
    city: "Second Mesa",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86044",
    city: "Tonalea",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86045",
    city: "Tuba City",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86046",
    city: "Williams",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86047",
    city: "Winslow",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86052",
    city: "North Rim",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86053",
    city: "Kaibeto",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86054",
    city: "Shonto",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86301",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86302",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86303",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86304",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86305",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86312",
    city: "Prescott Valley",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86313",
    city: "Prescott",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86314",
    city: "Prescott Valley",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86315",
    city: "Prescott Valley",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86320",
    city: "Ash Fork",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86321",
    city: "Bagdad",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86322",
    city: "Camp Verde",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86323",
    city: "Chino Valley",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86324",
    city: "Clarkdale",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86325",
    city: "Cornville",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86326",
    city: "Cottonwood",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86327",
    city: "Dewey",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86329",
    city: "Humboldt",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86331",
    city: "Jerome",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86332",
    city: "Kirkland",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86333",
    city: "Mayer",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86334",
    city: "Paulden",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86335",
    city: "Rimrock",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86336",
    city: "Sedona",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86337",
    city: "Seligman",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86338",
    city: "Skull Valley",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86339",
    city: "Sedona",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86340",
    city: "Sedona",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86341",
    city: "Sedona",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86342",
    city: "Lake Montezuma",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86343",
    city: "Crown King",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86351",
    city: "Sedona",
    state: "AZ",
    areacode: 928,
    county: "Yavapai",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86401",
    city: "Kingman",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86402",
    city: "Kingman",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86403",
    city: "Lake Havasu City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86404",
    city: "Lake Havasu City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86405",
    city: "Lake Havasu City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86406",
    city: "Lake Havasu City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86408",
    city: "Lake Havasu City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86409",
    city: "Kingman",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86411",
    city: "Hackberry",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86412",
    city: "Hualapai",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86413",
    city: "Golden Valley",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86426",
    city: "Fort Mohave",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86427",
    city: "Fort Mohave",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86429",
    city: "Bullhead City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86430",
    city: "Bullhead City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86431",
    city: "Chloride",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86432",
    city: "Littlefield",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86433",
    city: "Oatman",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86434",
    city: "Peach Springs",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86435",
    city: "Supai",
    state: "AZ",
    areacode: 928,
    county: "Coconino",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86436",
    city: "Topock",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86437",
    city: "Valentine",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86438",
    city: "Yucca",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86439",
    city: "Bullhead City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86440",
    city: "Mohave Valley",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86441",
    city: "Dolan Springs",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86442",
    city: "Bullhead City",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86443",
    city: "Temple Bar Marina",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86444",
    city: "Meadview",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86445",
    city: "Willow Beach",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86446",
    city: "Mohave Valley",
    state: "AZ",
    areacode: 928,
    county: "Mohave",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86502",
    city: "Chambers",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86503",
    city: "Chinle",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86504",
    city: "Fort Defiance",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86505",
    city: "Ganado",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86506",
    city: "Houck",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86507",
    city: "Lukachukai",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86508",
    city: "Lupton",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86510",
    city: "Pinon",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86511",
    city: "Saint Michaels",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86512",
    city: "Sanders",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86514",
    city: "Teec Nos Pos",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86515",
    city: "Window Rock",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86520",
    city: "Blue Gap",
    state: "AZ",
    areacode: 928,
    county: "Navajo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "86535",
    city: "Dennehotso",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86538",
    city: "Many Farms",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86540",
    city: "Nazlini",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86544",
    city: "Red Valley",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86545",
    city: "Rock Point",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86547",
    city: "Round Rock",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "86556",
    city: "Tsaile",
    state: "AZ",
    areacode: 928,
    county: "Apache",
    timezone3: "MST",
    timezone: "US/Arizona",
  },
  {
    zipcode: "87001",
    city: "Algodones",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87002",
    city: "Belen",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87004",
    city: "Bernalillo",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87005",
    city: "Bluewater",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87006",
    city: "Bosque",
    state: "NM",
    areacode: 505,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87007",
    city: "Casa Blanca",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87008",
    city: "Cedar Crest",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87009",
    city: "Cedarvale",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87010",
    city: "Cerrillos",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87011",
    city: "Claunch",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87012",
    city: "Coyote",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87013",
    city: "Cuba",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87014",
    city: "Cubero",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87015",
    city: "Edgewood",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87016",
    city: "Estancia",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87017",
    city: "Gallina",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87018",
    city: "Counselor",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87020",
    city: "Grants",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87021",
    city: "Milan",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87022",
    city: "Isleta",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87023",
    city: "Jarales",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87024",
    city: "Jemez Pueblo",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87025",
    city: "Jemez Springs",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87026",
    city: "Laguna",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87027",
    city: "La Jara",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87028",
    city: "La Joya",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87029",
    city: "Lindrith",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87031",
    city: "Los Lunas",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87032",
    city: "McIntosh",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87034",
    city: "Pueblo of Acoma",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87035",
    city: "Moriarty",
    state: "NM",
    areacode: 505,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87036",
    city: "Mountainair",
    state: "NM",
    areacode: 505,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87037",
    city: "Nageezi",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87038",
    city: "New Laguna",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87040",
    city: "Paguate",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87041",
    city: "Pena Blanca",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87042",
    city: "Peralta",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87043",
    city: "Placitas",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87044",
    city: "Ponderosa",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87045",
    city: "Prewitt",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87046",
    city: "Regina",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87047",
    city: "Sandia Park",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87048",
    city: "Corrales",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87049",
    city: "San Fidel",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87051",
    city: "San Rafael",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87052",
    city: "Santo Domingo Pueblo",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87053",
    city: "San Ysidro",
    state: "NM",
    areacode: 575,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87056",
    city: "Stanley",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87059",
    city: "Tijeras",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87060",
    city: "Tome",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87061",
    city: "Torreon",
    state: "NM",
    areacode: 505,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87062",
    city: "Veguita",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87063",
    city: "Willard",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87064",
    city: "Youngsville",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87068",
    city: "Bosque Farms",
    state: "NM",
    areacode: 505,
    county: "Valencia",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87070",
    city: "Clines Corners",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87072",
    city: "Cochiti Pueblo",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87083",
    city: "Cochiti Lake",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87101",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87102",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87103",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87104",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87105",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87106",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87107",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87108",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87109",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87110",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87111",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87112",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87113",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87114",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87115",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87116",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87117",
    city: "Kirtland AFB",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87119",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87120",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87121",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87122",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87123",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87124",
    city: "Rio Rancho",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87125",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87131",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87144",
    city: "Rio Rancho",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87151",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87153",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87154",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87158",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87174",
    city: "Rio Rancho",
    state: "NM",
    areacode: 505,
    county: "Sandoval",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87176",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87181",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87184",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87185",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87187",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87190",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87191",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87192",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87193",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87194",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87195",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87196",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87197",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87198",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87199",
    city: "Albuquerque",
    state: "NM",
    areacode: 505,
    county: "Bernalillo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87301",
    city: "Gallup",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87302",
    city: "Gallup",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87305",
    city: "Gallup",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87310",
    city: "Brimhall",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87311",
    city: "Church Rock",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87312",
    city: "Continental Divide",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87313",
    city: "Crownpoint",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87315",
    city: "Fence Lake",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87316",
    city: "Fort Wingate",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87317",
    city: "Gamerco",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87319",
    city: "Mentmore",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87320",
    city: "Mexican Springs",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87321",
    city: "Ramah",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87322",
    city: "Rehoboth",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87323",
    city: "Thoreau",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87325",
    city: "Tohatchi",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87326",
    city: "Vanderwagen",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87327",
    city: "Zuni",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87328",
    city: "Navajo",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87347",
    city: "Jamestown",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87357",
    city: "Pinehill",
    state: "NM",
    areacode: 505,
    county: "Cibola",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87364",
    city: "Sheep Springs",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87365",
    city: "Smith Lake",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87375",
    city: "Yatahey",
    state: "NM",
    areacode: 505,
    county: "McKinley",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87401",
    city: "Farmington",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87402",
    city: "Farmington",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87410",
    city: "Aztec",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87412",
    city: "Blanco",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87413",
    city: "Bloomfield",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87415",
    city: "Flora Vista",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87416",
    city: "Fruitland",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87417",
    city: "Kirtland",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87418",
    city: "La Plata",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87419",
    city: "Navajo Dam",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87420",
    city: "Shiprock",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87421",
    city: "Waterflow",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87455",
    city: "Newcomb",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87461",
    city: "Sanostee",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87499",
    city: "Farmington",
    state: "NM",
    areacode: 505,
    county: "San Juan",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87501",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87502",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87503",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87504",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87505",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87506",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87507",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87508",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87509",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87510",
    city: "Abiquiu",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87511",
    city: "Alcalde",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87512",
    city: "Amalia",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87513",
    city: "Arroyo Hondo",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87514",
    city: "Arroyo Seco",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87515",
    city: "Canjilon",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87516",
    city: "Canones",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87517",
    city: "Carson",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87518",
    city: "Cebolla",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87519",
    city: "Cerro",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87520",
    city: "Chama",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87521",
    city: "Chamisal",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87522",
    city: "Chimayo",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87523",
    city: "Cordova",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87524",
    city: "Costilla",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87525",
    city: "Taos Ski Valley",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87527",
    city: "Dixon",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87528",
    city: "Dulce",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87529",
    city: "El Prado",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87530",
    city: "El Rito",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87531",
    city: "Embudo",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87532",
    city: "Espanola",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87533",
    city: "Espanola",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87535",
    city: "Glorieta",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87537",
    city: "Hernandez",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87538",
    city: "Ilfeld",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87539",
    city: "La Madera",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87540",
    city: "Lamy",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87543",
    city: "Llano",
    state: "NM",
    areacode: 505,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87544",
    city: "Los Alamos",
    state: "NM",
    areacode: 505,
    county: "Los Alamos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87545",
    city: "Los Alamos",
    state: "NM",
    areacode: 505,
    county: "Los Alamos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87547",
    city: "White Rock",
    state: "NM",
    areacode: 505,
    county: "Los Alamos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87548",
    city: "Medanales",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87549",
    city: "Ojo Caliente",
    state: "NM",
    areacode: 505,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87551",
    city: "Los Ojos",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87552",
    city: "Pecos",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87553",
    city: "Penasco",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87554",
    city: "Petaca",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87556",
    city: "Questa",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87557",
    city: "Ranchos de Taos",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87558",
    city: "Red River",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87560",
    city: "Ribera",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87562",
    city: "Rowe",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87564",
    city: "San Cristobal",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87565",
    city: "San Jose",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87566",
    city: "Ohkay Owingeh",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87567",
    city: "Santa Cruz",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87569",
    city: "Serafina",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87571",
    city: "Taos",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87573",
    city: "Tererro",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87574",
    city: "Tesuque",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87575",
    city: "Tierra Amarilla",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87576",
    city: "Trampas",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87577",
    city: "Tres Piedras",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87578",
    city: "Truchas",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87579",
    city: "Vadito",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87580",
    city: "Valdez",
    state: "NM",
    areacode: 575,
    county: "Taos",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87581",
    city: "Vallecitos",
    state: "NM",
    areacode: 575,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87582",
    city: "Velarde",
    state: "NM",
    areacode: 505,
    county: "Rio Arriba",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87583",
    city: "Villanueva",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87592",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87594",
    city: "Santa Fe",
    state: "NM",
    areacode: 505,
    county: "Santa Fe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87654",
    city: "Spaceport City",
    state: "NM",
    areacode: 505,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87701",
    city: "Las Vegas",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87710",
    city: "Angel Fire",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87711",
    city: "Anton Chico",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87712",
    city: "Buena Vista",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87713",
    city: "Chacon",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87714",
    city: "Cimarron",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87715",
    city: "Cleveland",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87718",
    city: "Eagle Nest",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87722",
    city: "Guadalupita",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87723",
    city: "Holman",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87724",
    city: "La Loma",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87728",
    city: "Maxwell",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87729",
    city: "Miami",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87730",
    city: "Mills",
    state: "NM",
    areacode: 575,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87731",
    city: "Montezuma",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87732",
    city: "Mora",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87733",
    city: "Mosquero",
    state: "NM",
    areacode: 575,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87734",
    city: "Ocate",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87735",
    city: "Ojo Feliz",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87736",
    city: "Rainsville",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87740",
    city: "Raton",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87742",
    city: "Rociada",
    state: "NM",
    areacode: 505,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87743",
    city: "Roy",
    state: "NM",
    areacode: 575,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87745",
    city: "Sapello",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87746",
    city: "Solano",
    state: "NM",
    areacode: 575,
    county: "Harding",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87747",
    city: "Springer",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87749",
    city: "Ute Park",
    state: "NM",
    areacode: 575,
    county: "Colfax",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87750",
    city: "Valmora",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87752",
    city: "Wagon Mound",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87753",
    city: "Watrous",
    state: "NM",
    areacode: 575,
    county: "Mora",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87801",
    city: "Socorro",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87820",
    city: "Aragon",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87821",
    city: "Datil",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87823",
    city: "Lemitar",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87824",
    city: "Luna",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87825",
    city: "Magdalena",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87827",
    city: "Pie Town",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87828",
    city: "Polvadera",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87829",
    city: "Quemado",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87830",
    city: "Reserve",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87831",
    city: "San Acacia",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87832",
    city: "San Antonio",
    state: "NM",
    areacode: 575,
    county: "Socorro",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87901",
    city: "Truth or Consequences",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87930",
    city: "Arrey",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87931",
    city: "Caballo",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87933",
    city: "Derry",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87935",
    city: "Elephant Butte",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87936",
    city: "Garfield",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87937",
    city: "Hatch",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87939",
    city: "Monticello",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87940",
    city: "Rincon",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87941",
    city: "Salem",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87942",
    city: "Williamsburg",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "87943",
    city: "Winston",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88001",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88002",
    city: "White Sands Missile Range",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88003",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88004",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88005",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88006",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88007",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88008",
    city: "Santa Teresa",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88009",
    city: "Playas",
    state: "NM",
    areacode: 575,
    county: "Hidalgo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88011",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88012",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88013",
    city: "Las Cruces",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88020",
    city: "Animas",
    state: "NM",
    areacode: 575,
    county: "Hidalgo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88021",
    city: "Anthony",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88022",
    city: "Arenas Valley",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88023",
    city: "Bayard",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88024",
    city: "Berino",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88025",
    city: "Buckhorn",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88026",
    city: "Santa Clara",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88027",
    city: "Chamberino",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88028",
    city: "Cliff",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88029",
    city: "Columbus",
    state: "NM",
    areacode: 575,
    county: "Luna",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88030",
    city: "Deming",
    state: "NM",
    areacode: 575,
    county: "Luna",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88031",
    city: "Deming",
    state: "NM",
    areacode: 575,
    county: "Luna",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88032",
    city: "Dona Ana",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88033",
    city: "Fairacres",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88034",
    city: "Faywood",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88036",
    city: "Fort Bayard",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88038",
    city: "Gila",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88039",
    city: "Glenwood",
    state: "NM",
    areacode: 575,
    county: "Catron",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88040",
    city: "Hachita",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88041",
    city: "Hanover",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88042",
    city: "Hillsboro",
    state: "NM",
    areacode: 575,
    county: "Sierra",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88043",
    city: "Hurley",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88044",
    city: "La Mesa",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88045",
    city: "Lordsburg",
    state: "NM",
    areacode: 575,
    county: "Hidalgo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88046",
    city: "Mesilla",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88047",
    city: "Mesilla Park",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88048",
    city: "Mesquite",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88049",
    city: "Mimbres",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88051",
    city: "Mule Creek",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88052",
    city: "Organ",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88053",
    city: "Pinos Altos",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88054",
    city: "Radium Springs",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88055",
    city: "Redrock",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88056",
    city: "Rodeo",
    state: "NM",
    areacode: 575,
    county: "Hidalgo",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88058",
    city: "San Miguel",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88061",
    city: "Silver City",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88062",
    city: "Silver City",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88063",
    city: "Sunland Park",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88065",
    city: "Tyrone",
    state: "NM",
    areacode: 575,
    county: "Grant",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88072",
    city: "Vado",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88081",
    city: "Chaparral",
    state: "NM",
    areacode: 575,
    county: "Dona Ana",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88101",
    city: "Clovis",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88102",
    city: "Clovis",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88103",
    city: "Cannon AFB",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88112",
    city: "Broadview",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88113",
    city: "Causey",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88114",
    city: "Crossroads",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88115",
    city: "Dora",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88116",
    city: "Elida",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88118",
    city: "Floyd",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88119",
    city: "Fort Sumner",
    state: "NM",
    areacode: 575,
    county: "De Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88120",
    city: "Grady",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88121",
    city: "House",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88122",
    city: "Kenna",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88123",
    city: "Lingo",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88124",
    city: "Melrose",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88125",
    city: "Milnesand",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88126",
    city: "Pep",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88130",
    city: "Portales",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88132",
    city: "Rogers",
    state: "NM",
    areacode: 575,
    county: "Roosevelt",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88133",
    city: "Saint Vrain",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88134",
    city: "Taiban",
    state: "NM",
    areacode: 575,
    county: "De Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88135",
    city: "Texico",
    state: "NM",
    areacode: 575,
    county: "Curry",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88136",
    city: "Yeso",
    state: "NM",
    areacode: 575,
    county: "De Baca",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88201",
    city: "Roswell",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88202",
    city: "Roswell",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88203",
    city: "Roswell",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88210",
    city: "Artesia",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88211",
    city: "Artesia",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88213",
    city: "Caprock",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88220",
    city: "Carlsbad",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88221",
    city: "Carlsbad",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88230",
    city: "Dexter",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88231",
    city: "Eunice",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88232",
    city: "Hagerman",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88240",
    city: "Hobbs",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88241",
    city: "Hobbs",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88242",
    city: "Hobbs",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88244",
    city: "Hobbs",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88250",
    city: "Hope",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88252",
    city: "Jal",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88253",
    city: "Lake Arthur",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88254",
    city: "Lakewood",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88255",
    city: "Loco Hills",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88256",
    city: "Loving",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88260",
    city: "Lovington",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88262",
    city: "McDonald",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88263",
    city: "Malaga",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88264",
    city: "Maljamar",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88265",
    city: "Monument",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88267",
    city: "Tatum",
    state: "NM",
    areacode: 575,
    county: "Lea",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88268",
    city: "Whites City",
    state: "NM",
    areacode: 575,
    county: "Eddy",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88301",
    city: "Carrizozo",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88310",
    city: "Alamogordo",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88311",
    city: "Alamogordo",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88312",
    city: "Alto",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88314",
    city: "Bent",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88316",
    city: "Capitan",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88317",
    city: "Cloudcroft",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88318",
    city: "Corona",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88321",
    city: "Encino",
    state: "NM",
    areacode: 575,
    county: "Torrance",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88323",
    city: "Fort Stanton",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88324",
    city: "Glencoe",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88325",
    city: "High Rolls Mountain Park",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88330",
    city: "Holloman AFB",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88336",
    city: "Hondo",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88337",
    city: "La Luz",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88338",
    city: "Lincoln",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88339",
    city: "Mayhill",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88340",
    city: "Mescalero",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88341",
    city: "Nogal",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88342",
    city: "Orogrande",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88343",
    city: "Picacho",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88344",
    city: "Pinon",
    state: "NM",
    areacode: 575,
    county: "Chaves",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88345",
    city: "Ruidoso",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88346",
    city: "Ruidoso Downs",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88347",
    city: "Sacramento",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88348",
    city: "San Patricio",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88349",
    city: "Sunspot",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88350",
    city: "Timberon",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88351",
    city: "Tinnie",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88352",
    city: "Tularosa",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88353",
    city: "Vaughn",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88354",
    city: "Weed",
    state: "NM",
    areacode: 575,
    county: "Otero",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88355",
    city: "Ruidoso",
    state: "NM",
    areacode: 575,
    county: "Lincoln",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88401",
    city: "Tucumcari",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88410",
    city: "Amistad",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88411",
    city: "Bard",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88414",
    city: "Capulin",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88415",
    city: "Clayton",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88416",
    city: "Conchas Dam",
    state: "NM",
    areacode: 575,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88417",
    city: "Cuervo",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88418",
    city: "Des Moines",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88419",
    city: "Folsom",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88421",
    city: "Garita",
    state: "NM",
    areacode: 505,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88422",
    city: "Gladstone",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88424",
    city: "Grenville",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88426",
    city: "Logan",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88427",
    city: "McAlister",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88430",
    city: "Nara Visa",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88431",
    city: "Newkirk",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88433",
    city: "Quay",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88434",
    city: "San Jon",
    state: "NM",
    areacode: 575,
    county: "Quay",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88435",
    city: "Santa Rosa",
    state: "NM",
    areacode: 575,
    county: "Guadalupe",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88436",
    city: "Sedan",
    state: "NM",
    areacode: 575,
    county: "Union",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88439",
    city: "Trementina",
    state: "NM",
    areacode: 575,
    county: "San Miguel",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88510",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88511",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88512",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88513",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88514",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88515",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88517",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88518",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88519",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88520",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88521",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88523",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88524",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88525",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88526",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88527",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88528",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88529",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88530",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88531",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88532",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88533",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88534",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88535",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88536",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88538",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88539",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88540",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88541",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88542",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88543",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88544",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88545",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88546",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88547",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88548",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88549",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88550",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88553",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88554",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88555",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88556",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88557",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88558",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88559",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88560",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88561",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88562",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88563",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88565",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88566",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88567",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88568",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88569",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88570",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88571",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88572",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88573",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88574",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88575",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88576",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88577",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88578",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88579",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88580",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88581",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88582",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88583",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88584",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88585",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88586",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88587",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88588",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88589",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88590",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88595",
    city: "El Paso",
    state: "TX",
    areacode: 915,
    county: "El Paso",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "88888",
    city: "North Pole",
    state: "DC",
    areacode: 202,
    county: "District of Columbia",
    timezone3: "EST",
    timezone: "US/Eastern",
  },
  {
    zipcode: "88901",
    city: "The Lakes",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "88905",
    city: "The Lakes",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89001",
    city: "Alamo",
    state: "NV",
    areacode: 775,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89002",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89003",
    city: "Beatty",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89004",
    city: "Blue Diamond",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89005",
    city: "Boulder City",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89006",
    city: "Boulder City",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89007",
    city: "Bunkerville",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89008",
    city: "Caliente",
    state: "NV",
    areacode: 775,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89009",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89010",
    city: "Dyer",
    state: "NV",
    areacode: 775,
    county: "Esmeralda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89011",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89012",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89013",
    city: "Goldfield",
    state: "NV",
    areacode: 775,
    county: "Esmeralda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89014",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89015",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89016",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89017",
    city: "Hiko",
    state: "NV",
    areacode: 775,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89018",
    city: "Indian Springs",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89019",
    city: "Jean",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89020",
    city: "Amargosa Valley",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89021",
    city: "Logandale",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89022",
    city: "Manhattan",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89023",
    city: "Mercury",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89024",
    city: "Mesquite",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89025",
    city: "Moapa",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89026",
    city: "Jean",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89027",
    city: "Mesquite",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89028",
    city: "Laughlin",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89029",
    city: "Laughlin",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89030",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89031",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89032",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89033",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89034",
    city: "Mesquite",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89036",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89037",
    city: "Coyote Springs",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89039",
    city: "Cal Nev Ari",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89040",
    city: "Overton",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89041",
    city: "Pahrump",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89042",
    city: "Panaca",
    state: "NV",
    areacode: 775,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89043",
    city: "Pioche",
    state: "NV",
    areacode: 775,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89044",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89045",
    city: "Round Mountain",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89046",
    city: "Searchlight",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89047",
    city: "Silverpeak",
    state: "NV",
    areacode: 775,
    county: "Esmeralda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89048",
    city: "Pahrump",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89049",
    city: "Tonopah",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89052",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89053",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89054",
    city: "Sloan",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89060",
    city: "Pahrump",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89061",
    city: "Pahrump",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89067",
    city: "Coyote Springs",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89070",
    city: "Indian Springs",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89074",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89077",
    city: "Henderson",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89081",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89084",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89085",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89086",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89087",
    city: "North Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89101",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89102",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89103",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89104",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89105",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89106",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89107",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89108",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89109",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89110",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89111",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89112",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89113",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89114",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89115",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89116",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89117",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89118",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89119",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89120",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89121",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89122",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89123",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89124",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89125",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89126",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89127",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89128",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89129",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89130",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89131",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89132",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89133",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89134",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89135",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89136",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89137",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89138",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89139",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89140",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89141",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89142",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89143",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89144",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89145",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89146",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89147",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89148",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89149",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89150",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89151",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89152",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89153",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89154",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89155",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89156",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89157",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89158",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89159",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89160",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89161",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89162",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89163",
    city: "The Lakes",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89164",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89165",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89166",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89169",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89170",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89173",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89177",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89178",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89179",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89180",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89183",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89185",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89191",
    city: "Nellis AFB",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89193",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89195",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89199",
    city: "Las Vegas",
    state: "NV",
    areacode: 702,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89301",
    city: "Ely",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89310",
    city: "Austin",
    state: "NV",
    areacode: 775,
    county: "Lander",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89311",
    city: "Baker",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89314",
    city: "Duckwater",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89315",
    city: "Ely",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89316",
    city: "Eureka",
    state: "NV",
    areacode: 775,
    county: "Eureka",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89317",
    city: "Lund",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89318",
    city: "McGill",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89319",
    city: "Ruth",
    state: "NV",
    areacode: 775,
    county: "White Pine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89402",
    city: "Crystal Bay",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89403",
    city: "Dayton",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89404",
    city: "Denio",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89405",
    city: "Empire",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89406",
    city: "Fallon",
    state: "NV",
    areacode: 775,
    county: "Churchill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89407",
    city: "Fallon",
    state: "NV",
    areacode: 775,
    county: "Churchill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89408",
    city: "Fernley",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89409",
    city: "Gabbs",
    state: "NV",
    areacode: 775,
    county: "Nye",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89410",
    city: "Gardnerville",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89411",
    city: "Genoa",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89412",
    city: "Gerlach",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89413",
    city: "Glenbrook",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89414",
    city: "Golconda",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89415",
    city: "Hawthorne",
    state: "NV",
    areacode: 775,
    county: "Mineral",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89418",
    city: "Imlay",
    state: "NV",
    areacode: 775,
    county: "Pershing",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89419",
    city: "Lovelock",
    state: "NV",
    areacode: 775,
    county: "Pershing",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89420",
    city: "Luning",
    state: "NV",
    areacode: 775,
    county: "Mineral",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89421",
    city: "McDermitt",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89422",
    city: "Mina",
    state: "NV",
    areacode: 775,
    county: "Mineral",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89423",
    city: "Minden",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89424",
    city: "Nixon",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89425",
    city: "Orovada",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89426",
    city: "Paradise Valley",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89427",
    city: "Schurz",
    state: "NV",
    areacode: 775,
    county: "Mineral",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89428",
    city: "Silver City",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89429",
    city: "Silver Springs",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89430",
    city: "Smith",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89431",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89432",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89433",
    city: "Sun Valley",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89434",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89435",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89436",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89437",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Storey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89438",
    city: "Valmy",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89439",
    city: "Verdi",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89440",
    city: "Virginia City",
    state: "NV",
    areacode: 775,
    county: "Storey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89441",
    city: "Sparks",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89442",
    city: "Wadsworth",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89444",
    city: "Wellington",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89445",
    city: "Winnemucca",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89446",
    city: "Winnemucca",
    state: "NV",
    areacode: 775,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89447",
    city: "Yerington",
    state: "NV",
    areacode: 775,
    county: "Lyon",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89448",
    city: "Zephyr Cove",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89449",
    city: "Stateline",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89450",
    city: "Incline Village",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89451",
    city: "Incline Village",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89452",
    city: "Incline Village",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89460",
    city: "Gardnerville",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89496",
    city: "Fallon",
    state: "NV",
    areacode: 775,
    county: "Churchill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89501",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89502",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89503",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89504",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89505",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89506",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89507",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89508",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89509",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89510",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89511",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89512",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89513",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89515",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89519",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89520",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89521",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89523",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89533",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89555",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89557",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89570",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89595",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89599",
    city: "Reno",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89701",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89702",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89703",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89704",
    city: "Washoe Valley",
    state: "NV",
    areacode: 775,
    county: "Washoe",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89705",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89706",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89711",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89712",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89713",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89714",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89721",
    city: "Carson City",
    state: "NV",
    areacode: 775,
    county: "Carson City",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89801",
    city: "Elko",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89802",
    city: "Elko",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89803",
    city: "Elko",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89815",
    city: "Spring Creek",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89820",
    city: "Battle Mountain",
    state: "NV",
    areacode: 775,
    county: "Lander",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89821",
    city: "Crescent Valley",
    state: "NV",
    areacode: 775,
    county: "Eureka",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89822",
    city: "Carlin",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89823",
    city: "Deeth",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89825",
    city: "Jackpot",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89826",
    city: "Jarbidge",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89828",
    city: "Lamoille",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89830",
    city: "Montello",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89831",
    city: "Mountain City",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89832",
    city: "Owyhee",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89833",
    city: "Ruby Valley",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89834",
    city: "Tuscarora",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89835",
    city: "Wells",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "89883",
    city: "West Wendover",
    state: "NV",
    areacode: 775,
    county: "Elko",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "90001",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90002",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90003",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90004",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90005",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90006",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90007",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90008",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90009",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90010",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90011",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90012",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90013",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90014",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90015",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90016",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90017",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90018",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90019",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90020",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90021",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90022",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90023",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90024",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90025",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90026",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90027",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90028",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90029",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90030",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90031",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90032",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90033",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90034",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90035",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90036",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90037",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90038",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90039",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90040",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90041",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90042",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90043",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90044",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90045",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90046",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90047",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90048",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90049",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90050",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90051",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90052",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90053",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90054",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90055",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90056",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90057",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90058",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90059",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90060",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90061",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90062",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90063",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90064",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90065",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90066",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90067",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90068",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90069",
    city: "West Hollywood",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90070",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90071",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90072",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90073",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90074",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90075",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90076",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90077",
    city: "Los Angeles",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90078",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90079",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90080",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90081",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90082",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90083",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90084",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90086",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90087",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90088",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90089",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90091",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90093",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90094",
    city: "Playa Vista",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90095",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90096",
    city: "Los Angeles",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90099",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90134",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90189",
    city: "Los Angeles",
    state: "CA",
    areacode: 213,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90201",
    city: "Bell Gardens",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90202",
    city: "Bell",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90209",
    city: "Beverly Hills",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90210",
    city: "Beverly Hills",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90211",
    city: "Beverly Hills",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90212",
    city: "Beverly Hills",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90213",
    city: "Beverly Hills",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90220",
    city: "Compton",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90221",
    city: "Compton",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90222",
    city: "Compton",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90223",
    city: "Compton",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90224",
    city: "Compton",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90230",
    city: "Culver City",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90231",
    city: "Culver City",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90232",
    city: "Culver City",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90239",
    city: "Downey",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90240",
    city: "Downey",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90241",
    city: "Downey",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90242",
    city: "Downey",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90245",
    city: "El Segundo",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90247",
    city: "Gardena",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90248",
    city: "Gardena",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90249",
    city: "Gardena",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90250",
    city: "Hawthorne",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90251",
    city: "Hawthorne",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90254",
    city: "Hermosa Beach",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90255",
    city: "Huntington Park",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90260",
    city: "Lawndale",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90261",
    city: "Lawndale",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90262",
    city: "Lynwood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90263",
    city: "Malibu",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90264",
    city: "Malibu",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90265",
    city: "Malibu",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90266",
    city: "Manhattan Beach",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90267",
    city: "Manhattan Beach",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90270",
    city: "Maywood",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90272",
    city: "Pacific Palisades",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90274",
    city: "Palos Verdes Peninsula",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90275",
    city: "Rancho Palos Verdes",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90277",
    city: "Redondo Beach",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90278",
    city: "Redondo Beach",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90280",
    city: "South Gate",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90290",
    city: "Topanga",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90291",
    city: "Venice",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90292",
    city: "Marina del Rey",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90293",
    city: "Playa del Rey",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90294",
    city: "Venice",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90295",
    city: "Marina del Rey",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90296",
    city: "Playa del Rey",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90301",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90302",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90303",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90304",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90305",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90306",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90307",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90308",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90309",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90310",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90311",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90312",
    city: "Inglewood",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90401",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90402",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90403",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90404",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90405",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90406",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90407",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90408",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90409",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90410",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90411",
    city: "Santa Monica",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90501",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90502",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90503",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90504",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90505",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90506",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90507",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90508",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90509",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90510",
    city: "Torrance",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90601",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90602",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90603",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90604",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90605",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90606",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90607",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90608",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90609",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90610",
    city: "Whittier",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90620",
    city: "Buena Park",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90621",
    city: "Buena Park",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90622",
    city: "Buena Park",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90623",
    city: "La Palma",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90624",
    city: "Buena Park",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90630",
    city: "Cypress",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90631",
    city: "La Habra",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90632",
    city: "La Habra",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90633",
    city: "La Habra",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90637",
    city: "La Mirada",
    state: "CA",
    areacode: 714,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90638",
    city: "La Mirada",
    state: "CA",
    areacode: 714,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90639",
    city: "La Mirada",
    state: "CA",
    areacode: 714,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90640",
    city: "Montebello",
    state: "CA",
    areacode: 323,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90650",
    city: "Norwalk",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90651",
    city: "Norwalk",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90652",
    city: "Norwalk",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90660",
    city: "Pico Rivera",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90661",
    city: "Pico Rivera",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90662",
    city: "Pico Rivera",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90670",
    city: "Santa Fe Springs",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90671",
    city: "Santa Fe Springs",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90680",
    city: "Stanton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90701",
    city: "Artesia",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90702",
    city: "Artesia",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90703",
    city: "Cerritos",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90704",
    city: "Avalon",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90706",
    city: "Bellflower",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90707",
    city: "Bellflower",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90710",
    city: "Harbor City",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90711",
    city: "Lakewood",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90712",
    city: "Lakewood",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90713",
    city: "Lakewood",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90714",
    city: "Lakewood",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90715",
    city: "Lakewood",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90716",
    city: "Hawaiian Gardens",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90717",
    city: "Lomita",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90720",
    city: "Los Alamitos",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90721",
    city: "Los Alamitos",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90723",
    city: "Paramount",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90731",
    city: "San Pedro",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90732",
    city: "San Pedro",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90733",
    city: "San Pedro",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90734",
    city: "San Pedro",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90740",
    city: "Seal Beach",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90742",
    city: "Sunset Beach",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90743",
    city: "Surfside",
    state: "CA",
    areacode: 562,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90744",
    city: "Wilmington",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90745",
    city: "Carson",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90746",
    city: "Carson",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90747",
    city: "Carson",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90748",
    city: "Wilmington",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90749",
    city: "Carson",
    state: "CA",
    areacode: 310,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90755",
    city: "Signal Hill",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90801",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90802",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90803",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90804",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90805",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90806",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90807",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90808",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90809",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90810",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90813",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90814",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90815",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90822",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90831",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90832",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90833",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90840",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90842",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90844",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90846",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90847",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90848",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90853",
    city: "Long Beach",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "90895",
    city: "Carson",
    state: "CA",
    areacode: 562,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91001",
    city: "Altadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91003",
    city: "Altadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91006",
    city: "Arcadia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91007",
    city: "Arcadia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91008",
    city: "Duarte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91009",
    city: "Duarte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91010",
    city: "Duarte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91011",
    city: "La Canada Flintridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91012",
    city: "La Canada Flintridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91016",
    city: "Monrovia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91017",
    city: "Monrovia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91020",
    city: "Montrose",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91021",
    city: "Montrose",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91023",
    city: "Mount Wilson",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91024",
    city: "Sierra Madre",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91025",
    city: "Sierra Madre",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91030",
    city: "South Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91031",
    city: "South Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91040",
    city: "Sunland",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91041",
    city: "Sunland",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91042",
    city: "Tujunga",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91043",
    city: "Tujunga",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91046",
    city: "Verdugo City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91066",
    city: "Arcadia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91077",
    city: "Arcadia",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91101",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91102",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91103",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91104",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91105",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91106",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91107",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91108",
    city: "San Marino",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91109",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91110",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91114",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91115",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91116",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91117",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91118",
    city: "San Marino",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91121",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91123",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91124",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91125",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91126",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91129",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91182",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91184",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91185",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91188",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91189",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91199",
    city: "Pasadena",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91201",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91202",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91203",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91204",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91205",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91206",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91207",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91208",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91209",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91210",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91214",
    city: "La Crescenta",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91221",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91222",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91224",
    city: "La Crescenta",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91225",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91226",
    city: "Glendale",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91301",
    city: "Agoura Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91302",
    city: "Calabasas",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91303",
    city: "Canoga Park",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91304",
    city: "Canoga Park",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91305",
    city: "Canoga Park",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91306",
    city: "Winnetka",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91307",
    city: "West Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91308",
    city: "West Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91309",
    city: "Canoga Park",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91310",
    city: "Castaic",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91311",
    city: "Chatsworth",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91313",
    city: "Chatsworth",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91316",
    city: "Encino",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91319",
    city: "Newbury Park",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91320",
    city: "Newbury Park",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91321",
    city: "Newhall",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91322",
    city: "Newhall",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91324",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91325",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91326",
    city: "Porter Ranch",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91327",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91328",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91329",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91330",
    city: "Northridge",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91331",
    city: "Pacoima",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91333",
    city: "Pacoima",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91334",
    city: "Pacoima",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91335",
    city: "Reseda",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91337",
    city: "Reseda",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91340",
    city: "San Fernando",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91341",
    city: "San Fernando",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91342",
    city: "Sylmar",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91343",
    city: "North Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91344",
    city: "Granada Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91345",
    city: "Mission Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91346",
    city: "Mission Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91350",
    city: "Santa Clarita",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91351",
    city: "Canyon Country",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91352",
    city: "Sun Valley",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91353",
    city: "Sun Valley",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91354",
    city: "Valencia",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91355",
    city: "Valencia",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91356",
    city: "Tarzana",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91357",
    city: "Tarzana",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91358",
    city: "Thousand Oaks",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91359",
    city: "Westlake Village",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91360",
    city: "Thousand Oaks",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91361",
    city: "Westlake Village",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91362",
    city: "Thousand Oaks",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91364",
    city: "Woodland Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91365",
    city: "Woodland Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91367",
    city: "Woodland Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91371",
    city: "Woodland Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91372",
    city: "Calabasas",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91376",
    city: "Agoura Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91377",
    city: "Oak Park",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91380",
    city: "Santa Clarita",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91381",
    city: "Stevenson Ranch",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91382",
    city: "Santa Clarita",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91383",
    city: "Santa Clarita",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91384",
    city: "Castaic",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91385",
    city: "Valencia",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91386",
    city: "Canyon Country",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91387",
    city: "Canyon Country",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91390",
    city: "Santa Clarita",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91392",
    city: "Sylmar",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91393",
    city: "North Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91394",
    city: "Granada Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91395",
    city: "Mission Hills",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91396",
    city: "Winnetka",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91401",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91402",
    city: "Panorama City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91403",
    city: "Sherman Oaks",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91404",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91405",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91406",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91407",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91408",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91409",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91410",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91411",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91412",
    city: "Panorama City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91413",
    city: "Sherman Oaks",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91416",
    city: "Encino",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91423",
    city: "Sherman Oaks",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91426",
    city: "Encino",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91436",
    city: "Encino",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91470",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91482",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91495",
    city: "Sherman Oaks",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91496",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91499",
    city: "Van Nuys",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91501",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91502",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91503",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91504",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91505",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91506",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91507",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91508",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91510",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91521",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91522",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91523",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91526",
    city: "Burbank",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91601",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91602",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91603",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91604",
    city: "Studio City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91605",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91606",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91607",
    city: "Valley Village",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91608",
    city: "Universal City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91609",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91610",
    city: "Toluca Lake",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91611",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91612",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91614",
    city: "Studio City",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91615",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91616",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91617",
    city: "Valley Village",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91618",
    city: "North Hollywood",
    state: "CA",
    areacode: 818,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91701",
    city: "Rancho Cucamonga",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91702",
    city: "Azusa",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91706",
    city: "Baldwin Park",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91708",
    city: "Chino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91709",
    city: "Chino Hills",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91710",
    city: "Chino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91711",
    city: "Claremont",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91714",
    city: "City of Industry",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91715",
    city: "City of Industry",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91716",
    city: "City of Industry",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91722",
    city: "Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91723",
    city: "Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91724",
    city: "Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91729",
    city: "Rancho Cucamonga",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91730",
    city: "Rancho Cucamonga",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91731",
    city: "El Monte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91732",
    city: "El Monte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91733",
    city: "South El Monte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91734",
    city: "El Monte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91735",
    city: "El Monte",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91737",
    city: "Rancho Cucamonga",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91739",
    city: "Rancho Cucamonga",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91740",
    city: "Glendora",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91741",
    city: "Glendora",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91743",
    city: "Guasti",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91744",
    city: "La Puente",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91745",
    city: "Hacienda Heights",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91746",
    city: "La Puente",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91747",
    city: "La Puente",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91748",
    city: "Rowland Heights",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91749",
    city: "La Puente",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91750",
    city: "La Verne",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91752",
    city: "Mira Loma",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91754",
    city: "Monterey Park",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91755",
    city: "Monterey Park",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91756",
    city: "Monterey Park",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91758",
    city: "Ontario",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91759",
    city: "Mount Baldy",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91761",
    city: "Ontario",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91762",
    city: "Ontario",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91763",
    city: "Montclair",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91764",
    city: "Ontario",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91765",
    city: "Diamond Bar",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91766",
    city: "Pomona",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91767",
    city: "Pomona",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91768",
    city: "Pomona",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91769",
    city: "Pomona",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91770",
    city: "Rosemead",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91771",
    city: "Rosemead",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91772",
    city: "Rosemead",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91773",
    city: "San Dimas",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91775",
    city: "San Gabriel",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91776",
    city: "San Gabriel",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91778",
    city: "San Gabriel",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91780",
    city: "Temple City",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91784",
    city: "Upland",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91785",
    city: "Upland",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91786",
    city: "Upland",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91788",
    city: "Walnut",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91789",
    city: "Walnut",
    state: "CA",
    areacode: 909,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91790",
    city: "West Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91791",
    city: "West Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91792",
    city: "West Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91793",
    city: "West Covina",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91801",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91802",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91803",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91804",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91896",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91899",
    city: "Alhambra",
    state: "CA",
    areacode: 626,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91901",
    city: "Alpine",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91902",
    city: "Bonita",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91903",
    city: "Alpine",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91905",
    city: "Boulevard",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91906",
    city: "Campo",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91908",
    city: "Bonita",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91909",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91910",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91911",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91912",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91913",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91914",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91915",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91916",
    city: "Descanso",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91917",
    city: "Dulzura",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91921",
    city: "Chula Vista",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91931",
    city: "Guatay",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91932",
    city: "Imperial Beach",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91933",
    city: "Imperial Beach",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91934",
    city: "Jacumba",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91935",
    city: "Jamul",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91941",
    city: "La Mesa",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91942",
    city: "La Mesa",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91943",
    city: "La Mesa",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91944",
    city: "La Mesa",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91945",
    city: "Lemon Grove",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91946",
    city: "Lemon Grove",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91948",
    city: "Mount Laguna",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91950",
    city: "National City",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91951",
    city: "National City",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91962",
    city: "Pine Valley",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91963",
    city: "Potrero",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91976",
    city: "Spring Valley",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91977",
    city: "Spring Valley",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91978",
    city: "Spring Valley",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91979",
    city: "Spring Valley",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91980",
    city: "Tecate",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "91987",
    city: "Tecate",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92003",
    city: "Bonsall",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92004",
    city: "Borrego Springs",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92007",
    city: "Cardiff by the Sea",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92008",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92009",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92010",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92011",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92013",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92014",
    city: "Del Mar",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92018",
    city: "Carlsbad",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92019",
    city: "El Cajon",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92020",
    city: "El Cajon",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92021",
    city: "El Cajon",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92022",
    city: "El Cajon",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92023",
    city: "Encinitas",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92024",
    city: "Encinitas",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92025",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92026",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92027",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92028",
    city: "Fallbrook",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92029",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92030",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92033",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92036",
    city: "Julian",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92037",
    city: "La Jolla",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92038",
    city: "La Jolla",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92039",
    city: "La Jolla",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92040",
    city: "Lakeside",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92046",
    city: "Escondido",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92049",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92051",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92052",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92054",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92055",
    city: "Camp Pendleton",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92056",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92057",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92058",
    city: "Oceanside",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92059",
    city: "Pala",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92060",
    city: "Palomar Mountain",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92061",
    city: "Pauma Valley",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92064",
    city: "Poway",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92065",
    city: "Ramona",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92066",
    city: "Ranchita",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92067",
    city: "Rancho Santa Fe",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92068",
    city: "San Luis Rey",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92069",
    city: "San Marcos",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92070",
    city: "Santa Ysabel",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92071",
    city: "Santee",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92072",
    city: "Santee",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92074",
    city: "Poway",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92075",
    city: "Solana Beach",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92078",
    city: "San Marcos",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92079",
    city: "San Marcos",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92081",
    city: "Vista",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92082",
    city: "Valley Center",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92083",
    city: "Vista",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92084",
    city: "Vista",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92085",
    city: "Vista",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92086",
    city: "Warner Springs",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92088",
    city: "Fallbrook",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92091",
    city: "Rancho Santa Fe",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92092",
    city: "La Jolla",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92093",
    city: "La Jolla",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92096",
    city: "San Marcos",
    state: "CA",
    areacode: 760,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92101",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92102",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92103",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92104",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92105",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92106",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92107",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92108",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92109",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92110",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92111",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92112",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92113",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92114",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92115",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92116",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92117",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92118",
    city: "Coronado",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92119",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92120",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92121",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92122",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92123",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92124",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92126",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92127",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92128",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92129",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92130",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92131",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92132",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92134",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92135",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92136",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92137",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92138",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92139",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92140",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92142",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92143",
    city: "San Ysidro",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92145",
    city: "San Diego",
    state: "CA",
    areacode: 858,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92147",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92149",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92150",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92152",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92153",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92154",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92155",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92158",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92159",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92160",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92161",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92163",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92165",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92166",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92167",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92168",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92169",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92170",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92171",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92172",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92173",
    city: "San Ysidro",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92174",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92175",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92176",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92177",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92178",
    city: "Coronado",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92179",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92182",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92186",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92187",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92191",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92192",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92193",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92195",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92196",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92197",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92198",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92199",
    city: "San Diego",
    state: "CA",
    areacode: 619,
    county: "San Diego",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92201",
    city: "Indio",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92202",
    city: "Indio",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92203",
    city: "Indio",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92210",
    city: "Indian Wells",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92211",
    city: "Palm Desert",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92220",
    city: "Banning",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92222",
    city: "Bard",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92223",
    city: "Beaumont",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92225",
    city: "Blythe",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92226",
    city: "Blythe",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92227",
    city: "Brawley",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92230",
    city: "Cabazon",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92231",
    city: "Calexico",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92232",
    city: "Calexico",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92233",
    city: "Calipatria",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92234",
    city: "Cathedral City",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92235",
    city: "Cathedral City",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92236",
    city: "Coachella",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92239",
    city: "Desert Center",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92240",
    city: "Desert Hot Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92241",
    city: "Desert Hot Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92242",
    city: "Earp",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92243",
    city: "El Centro",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92244",
    city: "El Centro",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92247",
    city: "La Quinta",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92248",
    city: "La Quinta",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92249",
    city: "Heber",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92250",
    city: "Holtville",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92251",
    city: "Imperial",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92252",
    city: "Joshua Tree",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92253",
    city: "La Quinta",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92254",
    city: "Mecca",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92255",
    city: "Palm Desert",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92256",
    city: "Morongo Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92257",
    city: "Niland",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92258",
    city: "North Palm Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92259",
    city: "Ocotillo",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92260",
    city: "Palm Desert",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92261",
    city: "Palm Desert",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92262",
    city: "Palm Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92263",
    city: "Palm Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92264",
    city: "Palm Springs",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92266",
    city: "Palo Verde",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92267",
    city: "Parker Dam",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92268",
    city: "Pioneertown",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92270",
    city: "Rancho Mirage",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92273",
    city: "Seeley",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92274",
    city: "Thermal",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92275",
    city: "Salton City",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92276",
    city: "Thousand Palms",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92277",
    city: "Twentynine Palms",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92278",
    city: "Twentynine Palms",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92280",
    city: "Vidal",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92281",
    city: "Westmorland",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92282",
    city: "Whitewater",
    state: "CA",
    areacode: 760,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92283",
    city: "Winterhaven",
    state: "CA",
    areacode: 760,
    county: "Imperial",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92284",
    city: "Yucca Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92285",
    city: "Landers",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92286",
    city: "Yucca Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92301",
    city: "Adelanto",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92304",
    city: "Amboy",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92305",
    city: "Angelus Oaks",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92307",
    city: "Apple Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92308",
    city: "Apple Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92309",
    city: "Baker",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92310",
    city: "Fort Irwin",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92311",
    city: "Barstow",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92312",
    city: "Barstow",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92313",
    city: "Grand Terrace",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92314",
    city: "Big Bear City",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92315",
    city: "Big Bear Lake",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92316",
    city: "Bloomington",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92317",
    city: "Blue Jay",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92318",
    city: "Bryn Mawr",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92320",
    city: "Calimesa",
    state: "CA",
    areacode: 909,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92321",
    city: "Cedar Glen",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92322",
    city: "Cedarpines Park",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92323",
    city: "Cima",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92324",
    city: "Colton",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92325",
    city: "Crestline",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92327",
    city: "Daggett",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92328",
    city: "Death Valley",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92329",
    city: "Phelan",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92331",
    city: "Fontana",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92332",
    city: "Essex",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92333",
    city: "Fawnskin",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92334",
    city: "Fontana",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92335",
    city: "Fontana",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92336",
    city: "Fontana",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92337",
    city: "Fontana",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92338",
    city: "Ludlow",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92339",
    city: "Forest Falls",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92340",
    city: "Hesperia",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92341",
    city: "Green Valley Lake",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92342",
    city: "Helendale",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92344",
    city: "Hesperia",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92345",
    city: "Hesperia",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92346",
    city: "Highland",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92347",
    city: "Hinkley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92350",
    city: "Loma Linda",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92352",
    city: "Lake Arrowhead",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92354",
    city: "Loma Linda",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92356",
    city: "Lucerne Valley",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92357",
    city: "Loma Linda",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92358",
    city: "Lytle Creek",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92359",
    city: "Mentone",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92363",
    city: "Needles",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92364",
    city: "Nipton",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92365",
    city: "Newberry Springs",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92366",
    city: "Mountain Pass",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92368",
    city: "Oro Grande",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92369",
    city: "Patton",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92371",
    city: "Phelan",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92372",
    city: "Pinon Hills",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92373",
    city: "Redlands",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92374",
    city: "Redlands",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92375",
    city: "Redlands",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92376",
    city: "Rialto",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92377",
    city: "Rialto",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92378",
    city: "Rimforest",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92382",
    city: "Running Springs",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92384",
    city: "Shoshone",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92385",
    city: "Skyforest",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92386",
    city: "Sugarloaf",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92389",
    city: "Tecopa",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92391",
    city: "Twin Peaks",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92392",
    city: "Victorville",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92393",
    city: "Victorville",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92394",
    city: "Victorville",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92395",
    city: "Victorville",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92397",
    city: "Wrightwood",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92398",
    city: "Yermo",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92399",
    city: "Yucaipa",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92401",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92402",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92403",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92404",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92405",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92406",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92407",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92408",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92410",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92411",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92413",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92415",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92418",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92423",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92427",
    city: "San Bernardino",
    state: "CA",
    areacode: 909,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92501",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92502",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92503",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92504",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92505",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92506",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92507",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92508",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92509",
    city: "Jurupa Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92513",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92514",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92516",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92517",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92518",
    city: "March ARB",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92519",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92521",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92522",
    city: "Riverside",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92530",
    city: "Lake Elsinore",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92531",
    city: "Lake Elsinore",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92532",
    city: "Lake Elsinore",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92536",
    city: "Aguanga",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92539",
    city: "Anza",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92543",
    city: "Hemet",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92544",
    city: "Hemet",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92545",
    city: "Hemet",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92546",
    city: "Hemet",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92548",
    city: "Homeland",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92549",
    city: "Idyllwild",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92551",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92552",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92553",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92554",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92555",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92556",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92557",
    city: "Moreno Valley",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92561",
    city: "Mountain Center",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92562",
    city: "Murrieta",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92563",
    city: "Murrieta",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92564",
    city: "Murrieta",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92567",
    city: "Nuevo",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92570",
    city: "Perris",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92571",
    city: "Perris",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92572",
    city: "Perris",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92581",
    city: "San Jacinto",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92582",
    city: "San Jacinto",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92583",
    city: "San Jacinto",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92584",
    city: "Menifee",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92585",
    city: "Menifee",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92586",
    city: "Menifee",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92587",
    city: "Menifee",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92589",
    city: "Temecula",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92590",
    city: "Temecula",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92591",
    city: "Temecula",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92592",
    city: "Temecula",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92593",
    city: "Temecula",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92595",
    city: "Wildomar",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92596",
    city: "Winchester",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92599",
    city: "Perris",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92602",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92603",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92604",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92605",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92606",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92607",
    city: "Laguna Niguel",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92609",
    city: "El Toro",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92610",
    city: "Foothill Ranch",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92612",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92614",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92615",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92616",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92617",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92618",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92619",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92620",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92623",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92624",
    city: "Capistrano Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92625",
    city: "Corona del Mar",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92626",
    city: "Costa Mesa",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92627",
    city: "Costa Mesa",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92628",
    city: "Costa Mesa",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92629",
    city: "Dana Point",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92630",
    city: "Lake Forest",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92637",
    city: "Laguna Woods",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92646",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92647",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92648",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92649",
    city: "Huntington Beach",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92650",
    city: "East Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92651",
    city: "Laguna Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92652",
    city: "Laguna Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92653",
    city: "Laguna Hills",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92654",
    city: "Laguna Hills",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92655",
    city: "Midway City",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92656",
    city: "Aliso Viejo",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92657",
    city: "Newport Coast",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92658",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92659",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92660",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92661",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92662",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92663",
    city: "Newport Beach",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92672",
    city: "San Clemente",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92673",
    city: "San Clemente",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92674",
    city: "San Clemente",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92675",
    city: "San Juan Capistrano",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92676",
    city: "Silverado",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92677",
    city: "Laguna Niguel",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92678",
    city: "Trabuco Canyon",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92679",
    city: "Trabuco Canyon",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92683",
    city: "Westminster",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92684",
    city: "Westminster",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92685",
    city: "Westminster",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92688",
    city: "Rancho Santa Margarita",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92690",
    city: "Mission Viejo",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92691",
    city: "Mission Viejo",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92692",
    city: "Mission Viejo",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92693",
    city: "San Juan Capistrano",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92694",
    city: "Ladera Ranch",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92697",
    city: "Irvine",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92698",
    city: "Aliso Viejo",
    state: "CA",
    areacode: 949,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92701",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92702",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92703",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92704",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92705",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92706",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92707",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92708",
    city: "Fountain Valley",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92711",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92712",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92728",
    city: "Fountain Valley",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92735",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92780",
    city: "Tustin",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92781",
    city: "Tustin",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92782",
    city: "Tustin",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92799",
    city: "Santa Ana",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92801",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92802",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92803",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92804",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92805",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92806",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92807",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92808",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92809",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92811",
    city: "Atwood",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92812",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92814",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92815",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92816",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92817",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92821",
    city: "Brea",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92822",
    city: "Brea",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92823",
    city: "Brea",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92825",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92831",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92832",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92833",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92834",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92835",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92836",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92837",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92838",
    city: "Fullerton",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92840",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92841",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92842",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92843",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92844",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92845",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92846",
    city: "Garden Grove",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92850",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92856",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92857",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92859",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92860",
    city: "Norco",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92861",
    city: "Villa Park",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92862",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92863",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92864",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92865",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92866",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92867",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92868",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92869",
    city: "Orange",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92870",
    city: "Placentia",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92871",
    city: "Placentia",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92877",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92878",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92879",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92880",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92881",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92882",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92883",
    city: "Corona",
    state: "CA",
    areacode: 951,
    county: "Riverside",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92885",
    city: "Yorba Linda",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92886",
    city: "Yorba Linda",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92887",
    city: "Yorba Linda",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "92899",
    city: "Anaheim",
    state: "CA",
    areacode: 714,
    county: "Orange",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93001",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93002",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93003",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93004",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93005",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93006",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93007",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93009",
    city: "Ventura",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93010",
    city: "Camarillo",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93011",
    city: "Camarillo",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93012",
    city: "Camarillo",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93013",
    city: "Carpinteria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93014",
    city: "Carpinteria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93015",
    city: "Fillmore",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93016",
    city: "Fillmore",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93020",
    city: "Moorpark",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93021",
    city: "Moorpark",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93022",
    city: "Oak View",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93023",
    city: "Ojai",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93024",
    city: "Ojai",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93030",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93031",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93032",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93033",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93034",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93035",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93036",
    city: "Oxnard",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93040",
    city: "Piru",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93041",
    city: "Port Hueneme",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93042",
    city: "Point Mugu NAWC",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93043",
    city: "Port Hueneme CBC Base",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93044",
    city: "Port Hueneme",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93060",
    city: "Santa Paula",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93061",
    city: "Santa Paula",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93062",
    city: "Simi Valley",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93063",
    city: "Simi Valley",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93064",
    city: "Brandeis",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93065",
    city: "Simi Valley",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93066",
    city: "Somis",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93067",
    city: "Summerland",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93094",
    city: "Simi Valley",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93099",
    city: "Simi Valley",
    state: "CA",
    areacode: 805,
    county: "Ventura",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93101",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93102",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93103",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93105",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93106",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93107",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93108",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93109",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93110",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93111",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93116",
    city: "Goleta",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93117",
    city: "Goleta",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93118",
    city: "Goleta",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93120",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93121",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93130",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93140",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93150",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93160",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93190",
    city: "Santa Barbara",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93199",
    city: "Goleta",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93201",
    city: "Alpaugh",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93202",
    city: "Armona",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93203",
    city: "Arvin",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93204",
    city: "Avenal",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93205",
    city: "Bodfish",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93206",
    city: "Buttonwillow",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93207",
    city: "California Hot Springs",
    state: "CA",
    areacode: 661,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93208",
    city: "Camp Nelson",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93210",
    city: "Coalinga",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93212",
    city: "Corcoran",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93215",
    city: "Delano",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93216",
    city: "Delano",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93218",
    city: "Ducor",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93219",
    city: "Earlimart",
    state: "CA",
    areacode: 661,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93220",
    city: "Edison",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93221",
    city: "Exeter",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93222",
    city: "Pine Mountain Club",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93223",
    city: "Farmersville",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93224",
    city: "Fellows",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93225",
    city: "Frazier Park",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93226",
    city: "Glennville",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93227",
    city: "Goshen",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93230",
    city: "Hanford",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93232",
    city: "Hanford",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93234",
    city: "Huron",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93235",
    city: "Ivanhoe",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93237",
    city: "Kaweah",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93238",
    city: "Kernville",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93239",
    city: "Kettleman City",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93240",
    city: "Lake Isabella",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93241",
    city: "Lamont",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93242",
    city: "Laton",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93243",
    city: "Lebec",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93244",
    city: "Lemon Cove",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93245",
    city: "Lemoore",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93246",
    city: "Lemoore",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93247",
    city: "Lindsay",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93249",
    city: "Lost Hills",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93250",
    city: "McFarland",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93251",
    city: "McKittrick",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93252",
    city: "Maricopa",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93254",
    city: "New Cuyama",
    state: "CA",
    areacode: 661,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93255",
    city: "Onyx",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93256",
    city: "Pixley",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93257",
    city: "Porterville",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93258",
    city: "Porterville",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93260",
    city: "Posey",
    state: "CA",
    areacode: 661,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93261",
    city: "Richgrove",
    state: "CA",
    areacode: 661,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93262",
    city: "Sequoia National Park",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93263",
    city: "Shafter",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93265",
    city: "Springville",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93266",
    city: "Stratford",
    state: "CA",
    areacode: 559,
    county: "Kings",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93267",
    city: "Strathmore",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93268",
    city: "Taft",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93270",
    city: "Terra Bella",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93271",
    city: "Three Rivers",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93272",
    city: "Tipton",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93274",
    city: "Tulare",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93275",
    city: "Tulare",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93276",
    city: "Tupman",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93277",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93278",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93279",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93280",
    city: "Wasco",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93282",
    city: "Waukena",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93283",
    city: "Weldon",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93285",
    city: "Wofford Heights",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93286",
    city: "Woodlake",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93287",
    city: "Woody",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93290",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93291",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93292",
    city: "Visalia",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93301",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93302",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93303",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93304",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93305",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93306",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93307",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93308",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93309",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93311",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93312",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93313",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93314",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93380",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93383",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93384",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93385",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93386",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93387",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93388",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93389",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93390",
    city: "Bakersfield",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93401",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93402",
    city: "Los Osos",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93403",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93405",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93406",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93407",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93408",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93409",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93410",
    city: "San Luis Obispo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93412",
    city: "Los Osos",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93420",
    city: "Arroyo Grande",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93421",
    city: "Arroyo Grande",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93422",
    city: "Atascadero",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93423",
    city: "Atascadero",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93424",
    city: "Avila Beach",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93426",
    city: "Bradley",
    state: "CA",
    areacode: 805,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93427",
    city: "Buellton",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93428",
    city: "Cambria",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93429",
    city: "Casmalia",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93430",
    city: "Cayucos",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93432",
    city: "Creston",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93433",
    city: "Grover Beach",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93434",
    city: "Guadalupe",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93435",
    city: "Harmony",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93436",
    city: "Lompoc",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93437",
    city: "Lompoc",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93438",
    city: "Lompoc",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93440",
    city: "Los Alamos",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93441",
    city: "Los Olivos",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93442",
    city: "Morro Bay",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93443",
    city: "Morro Bay",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93444",
    city: "Nipomo",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93445",
    city: "Oceano",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93446",
    city: "Paso Robles",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93447",
    city: "Paso Robles",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93448",
    city: "Pismo Beach",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93449",
    city: "Pismo Beach",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93450",
    city: "San Ardo",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93451",
    city: "San Miguel",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93452",
    city: "San Simeon",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93453",
    city: "Santa Margarita",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93454",
    city: "Santa Maria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93455",
    city: "Santa Maria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93456",
    city: "Santa Maria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93457",
    city: "Santa Maria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93458",
    city: "Santa Maria",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93460",
    city: "Santa Ynez",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93461",
    city: "Shandon",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93463",
    city: "Solvang",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93464",
    city: "Solvang",
    state: "CA",
    areacode: 805,
    county: "Santa Barbara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93465",
    city: "Templeton",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93475",
    city: "Oceano",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93483",
    city: "Grover Beach",
    state: "CA",
    areacode: 805,
    county: "San Luis Obispo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93501",
    city: "Mojave",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93502",
    city: "Mojave",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93504",
    city: "California City",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93505",
    city: "California City",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93510",
    city: "Acton",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93512",
    city: "Benton",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93513",
    city: "Big Pine",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93514",
    city: "Bishop",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93515",
    city: "Bishop",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93516",
    city: "Boron",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93517",
    city: "Bridgeport",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93518",
    city: "Caliente",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93519",
    city: "Cantil",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93522",
    city: "Darwin",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93523",
    city: "Edwards",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93524",
    city: "Edwards",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93526",
    city: "Independence",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93527",
    city: "Inyokern",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93528",
    city: "Johannesburg",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93529",
    city: "June Lake",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93530",
    city: "Keeler",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93531",
    city: "Keene",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93532",
    city: "Lake Hughes",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93534",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93535",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93536",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93539",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93541",
    city: "Lee Vining",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93542",
    city: "Little Lake",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93543",
    city: "Littlerock",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93544",
    city: "Llano",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93545",
    city: "Lone Pine",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93546",
    city: "Mammoth Lakes",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93549",
    city: "Olancha",
    state: "CA",
    areacode: 760,
    county: "Inyo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93550",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93551",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93552",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93553",
    city: "Pearblossom",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93554",
    city: "Randsburg",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93555",
    city: "Ridgecrest",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93556",
    city: "Ridgecrest",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93558",
    city: "Red Mountain",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93560",
    city: "Rosamond",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93561",
    city: "Tehachapi",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93562",
    city: "Trona",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93563",
    city: "Valyermo",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93581",
    city: "Tehachapi",
    state: "CA",
    areacode: 661,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93584",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93586",
    city: "Lancaster",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93590",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93591",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93592",
    city: "Trona",
    state: "CA",
    areacode: 760,
    county: "San Bernardino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93596",
    city: "Boron",
    state: "CA",
    areacode: 760,
    county: "Kern",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93599",
    city: "Palmdale",
    state: "CA",
    areacode: 661,
    county: "Los Angeles",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93601",
    city: "Ahwahnee",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93602",
    city: "Auberry",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93603",
    city: "Badger",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93604",
    city: "Bass Lake",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93605",
    city: "Big Creek",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93606",
    city: "Biola",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93607",
    city: "Burrel",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93608",
    city: "Cantua Creek",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93609",
    city: "Caruthers",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93610",
    city: "Chowchilla",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93611",
    city: "Clovis",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93612",
    city: "Clovis",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93613",
    city: "Clovis",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93614",
    city: "Coarsegold",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93615",
    city: "Cutler",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93616",
    city: "Del Rey",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93618",
    city: "Dinuba",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93619",
    city: "Clovis",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93620",
    city: "Dos Palos",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93621",
    city: "Dunlap",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93622",
    city: "Firebaugh",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93623",
    city: "Fish Camp",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93624",
    city: "Five Points",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93625",
    city: "Fowler",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93626",
    city: "Friant",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93627",
    city: "Helm",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93628",
    city: "Hume",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93630",
    city: "Kerman",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93631",
    city: "Kingsburg",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93633",
    city: "Kings Canyon National Pk",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93634",
    city: "Lakeshore",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93635",
    city: "Los Banos",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93636",
    city: "Madera",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93637",
    city: "Madera",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93638",
    city: "Madera",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93639",
    city: "Madera",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93640",
    city: "Mendota",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93641",
    city: "Miramonte",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93642",
    city: "Mono Hot Springs",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93643",
    city: "North Fork",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93644",
    city: "Oakhurst",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93645",
    city: "O Neals",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93646",
    city: "Orange Cove",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93647",
    city: "Orosi",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93648",
    city: "Parlier",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93649",
    city: "Piedra",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93650",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93651",
    city: "Prather",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93652",
    city: "Raisin City",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93653",
    city: "Raymond",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93654",
    city: "Reedley",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93656",
    city: "Riverdale",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93657",
    city: "Sanger",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93660",
    city: "San Joaquin",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93661",
    city: "Santa Rita Park",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93662",
    city: "Selma",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93664",
    city: "Shaver Lake",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93665",
    city: "South Dos Palos",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93666",
    city: "Sultana",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93667",
    city: "Tollhouse",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93668",
    city: "Tranquillity",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93669",
    city: "Wishon",
    state: "CA",
    areacode: 559,
    county: "Madera",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93670",
    city: "Yettem",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93673",
    city: "Traver",
    state: "CA",
    areacode: 559,
    county: "Tulare",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93675",
    city: "Squaw Valley",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93701",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93702",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93703",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93704",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93705",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93706",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93707",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93708",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93709",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93710",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93711",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93712",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93714",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93715",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93716",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93717",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93718",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93720",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93721",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93722",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93723",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93724",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93725",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93726",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93727",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93728",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93729",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93730",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93737",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93740",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93741",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93744",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93745",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93747",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93750",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93755",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93760",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93761",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93764",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93765",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93771",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93772",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93773",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93774",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93775",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93776",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93777",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93778",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93779",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93786",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93790",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93791",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93792",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93793",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93794",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93844",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93888",
    city: "Fresno",
    state: "CA",
    areacode: 559,
    county: "Fresno",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93901",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93902",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93905",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93906",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93907",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93908",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93912",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93915",
    city: "Salinas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93920",
    city: "Big Sur",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93921",
    city: "Carmel by the Sea",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93922",
    city: "Carmel",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93923",
    city: "Carmel",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93924",
    city: "Carmel Valley",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93925",
    city: "Chualar",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93926",
    city: "Gonzales",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93927",
    city: "Greenfield",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93928",
    city: "Jolon",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93930",
    city: "King City",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93932",
    city: "Lockwood",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93933",
    city: "Marina",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93940",
    city: "Monterey",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93942",
    city: "Monterey",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93943",
    city: "Monterey",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93944",
    city: "Monterey",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93950",
    city: "Pacific Grove",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93953",
    city: "Pebble Beach",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93954",
    city: "San Lucas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93955",
    city: "Seaside",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93960",
    city: "Soledad",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "93962",
    city: "Spreckels",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94002",
    city: "Belmont",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94005",
    city: "Brisbane",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94010",
    city: "Burlingame",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94011",
    city: "Burlingame",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94014",
    city: "Daly City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94015",
    city: "Daly City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94016",
    city: "Daly City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94017",
    city: "Daly City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94018",
    city: "El Granada",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94019",
    city: "Half Moon Bay",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94020",
    city: "La Honda",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94021",
    city: "Loma Mar",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94022",
    city: "Los Altos",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94023",
    city: "Los Altos",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94024",
    city: "Los Altos",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94025",
    city: "Menlo Park",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94026",
    city: "Menlo Park",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94027",
    city: "Atherton",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94028",
    city: "Portola Valley",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94030",
    city: "Millbrae",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94035",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94037",
    city: "Montara",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94038",
    city: "Moss Beach",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94039",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94040",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94041",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94042",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94043",
    city: "Mountain View",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94044",
    city: "Pacifica",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94060",
    city: "Pescadero",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94061",
    city: "Redwood City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94062",
    city: "Redwood City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94063",
    city: "Redwood City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94064",
    city: "Redwood City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94065",
    city: "Redwood City",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94066",
    city: "San Bruno",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94070",
    city: "San Carlos",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94074",
    city: "San Gregorio",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94080",
    city: "South San Francisco",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94083",
    city: "South San Francisco",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94085",
    city: "Sunnyvale",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94086",
    city: "Sunnyvale",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94087",
    city: "Sunnyvale",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94088",
    city: "Sunnyvale",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94089",
    city: "Sunnyvale",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94102",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94103",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94104",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94105",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94107",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94108",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94109",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94110",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94111",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94112",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94114",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94115",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94116",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94117",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94118",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94119",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94120",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94121",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94122",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94123",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94124",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94125",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94126",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94127",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94128",
    city: "San Francisco",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94129",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94130",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94131",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94132",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94133",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94134",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94137",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94139",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94140",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94141",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94142",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94143",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94144",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94145",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94146",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94147",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94151",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94158",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94159",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94160",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94161",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94163",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94164",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94172",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94177",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94188",
    city: "San Francisco",
    state: "CA",
    areacode: 415,
    county: "San Francisco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94203",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94204",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94205",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94206",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94207",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94208",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94209",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94211",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94229",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94230",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94232",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94234",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94235",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94236",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94237",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94239",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94240",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94244",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94245",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94247",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94248",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94249",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94250",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94252",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94254",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94256",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94257",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94258",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94259",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94261",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94262",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94263",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94267",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94268",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94269",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94271",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94273",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94274",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94277",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94278",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94279",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94280",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94282",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94283",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94284",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94285",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94287",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94288",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94289",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94290",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94291",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94293",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94294",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94295",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94296",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94297",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94298",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94299",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94301",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94302",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94303",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94304",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94305",
    city: "Stanford",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94306",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94309",
    city: "Palo Alto",
    state: "CA",
    areacode: 650,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94401",
    city: "San Mateo",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94402",
    city: "San Mateo",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94403",
    city: "San Mateo",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94404",
    city: "San Mateo",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94497",
    city: "San Mateo",
    state: "CA",
    areacode: 650,
    county: "San Mateo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94501",
    city: "Alameda",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94502",
    city: "Alameda",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94503",
    city: "American Canyon",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94505",
    city: "Discovery Bay",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94506",
    city: "Danville",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94507",
    city: "Alamo",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94508",
    city: "Angwin",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94509",
    city: "Antioch",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94510",
    city: "Benicia",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94511",
    city: "Bethel Island",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94512",
    city: "Birds Landing",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94513",
    city: "Brentwood",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94514",
    city: "Byron",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94515",
    city: "Calistoga",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94516",
    city: "Canyon",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94517",
    city: "Clayton",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94518",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94519",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94520",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94521",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94522",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94523",
    city: "Pleasant Hill",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94524",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94525",
    city: "Crockett",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94526",
    city: "Danville",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94527",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94528",
    city: "Diablo",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94529",
    city: "Concord",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94530",
    city: "El Cerrito",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94531",
    city: "Antioch",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94533",
    city: "Fairfield",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94534",
    city: "Fairfield",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94535",
    city: "Travis AFB",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94536",
    city: "Fremont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94537",
    city: "Fremont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94538",
    city: "Fremont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94539",
    city: "Fremont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94540",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94541",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94542",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94543",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94544",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94545",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94546",
    city: "Castro Valley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94547",
    city: "Hercules",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94548",
    city: "Knightsen",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94549",
    city: "Lafayette",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94550",
    city: "Livermore",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94551",
    city: "Livermore",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94552",
    city: "Castro Valley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94553",
    city: "Martinez",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94555",
    city: "Fremont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94556",
    city: "Moraga",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94557",
    city: "Hayward",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94558",
    city: "Napa",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94559",
    city: "Napa",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94560",
    city: "Newark",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94561",
    city: "Oakley",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94562",
    city: "Oakville",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94563",
    city: "Orinda",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94564",
    city: "Pinole",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94565",
    city: "Pittsburg",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94566",
    city: "Pleasanton",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94567",
    city: "Pope Valley",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94568",
    city: "Dublin",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94569",
    city: "Port Costa",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94570",
    city: "Moraga",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94571",
    city: "Rio Vista",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94572",
    city: "Rodeo",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94573",
    city: "Rutherford",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94574",
    city: "Saint Helena",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94575",
    city: "Moraga",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94576",
    city: "Deer Park",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94577",
    city: "San Leandro",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94578",
    city: "San Leandro",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94579",
    city: "San Leandro",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94580",
    city: "San Lorenzo",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94581",
    city: "Napa",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94582",
    city: "San Ramon",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94583",
    city: "San Ramon",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94585",
    city: "Suisun City",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94586",
    city: "Sunol",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94587",
    city: "Union City",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94588",
    city: "Pleasanton",
    state: "CA",
    areacode: 925,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94589",
    city: "Vallejo",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94590",
    city: "Vallejo",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94591",
    city: "Vallejo",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94592",
    city: "Vallejo",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94595",
    city: "Walnut Creek",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94596",
    city: "Walnut Creek",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94597",
    city: "Walnut Creek",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94598",
    city: "Walnut Creek",
    state: "CA",
    areacode: 925,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94599",
    city: "Yountville",
    state: "CA",
    areacode: 707,
    county: "Napa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94601",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94602",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94603",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94604",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94605",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94606",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94607",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94608",
    city: "Emeryville",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94609",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94610",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94611",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94612",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94613",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94614",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94615",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94617",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94618",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94619",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94620",
    city: "Piedmont",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94621",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94622",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94623",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94624",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94649",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94659",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94660",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94661",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94662",
    city: "Emeryville",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94666",
    city: "Oakland",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94701",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94702",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94703",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94704",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94705",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94706",
    city: "Albany",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94707",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94708",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94709",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94710",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94712",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94720",
    city: "Berkeley",
    state: "CA",
    areacode: 510,
    county: "Alameda",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94801",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94802",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94803",
    city: "El Sobrante",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94804",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94805",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94806",
    city: "San Pablo",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94807",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94808",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94820",
    city: "El Sobrante",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94850",
    city: "Richmond",
    state: "CA",
    areacode: 510,
    county: "Contra Costa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94901",
    city: "San Rafael",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94903",
    city: "San Rafael",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94904",
    city: "Greenbrae",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94912",
    city: "San Rafael",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94913",
    city: "San Rafael",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94914",
    city: "Kentfield",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94915",
    city: "San Rafael",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94920",
    city: "Belvedere Tiburon",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94922",
    city: "Bodega",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94923",
    city: "Bodega Bay",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94924",
    city: "Bolinas",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94925",
    city: "Corte Madera",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94926",
    city: "Rohnert Park",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94927",
    city: "Rohnert Park",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94928",
    city: "Rohnert Park",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94929",
    city: "Dillon Beach",
    state: "CA",
    areacode: 707,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94930",
    city: "Fairfax",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94931",
    city: "Cotati",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94933",
    city: "Forest Knolls",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94937",
    city: "Inverness",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94938",
    city: "Lagunitas",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94939",
    city: "Larkspur",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94940",
    city: "Marshall",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94941",
    city: "Mill Valley",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94942",
    city: "Mill Valley",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94945",
    city: "Novato",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94946",
    city: "Nicasio",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94947",
    city: "Novato",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94948",
    city: "Novato",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94949",
    city: "Novato",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94950",
    city: "Olema",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94951",
    city: "Penngrove",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94952",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94953",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94954",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94955",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94956",
    city: "Point Reyes Station",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94957",
    city: "Ross",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94960",
    city: "San Anselmo",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94963",
    city: "San Geronimo",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94964",
    city: "San Quentin",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94965",
    city: "Sausalito",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94966",
    city: "Sausalito",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94970",
    city: "Stinson Beach",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94971",
    city: "Tomales",
    state: "CA",
    areacode: 707,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94972",
    city: "Valley Ford",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94973",
    city: "Woodacre",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94974",
    city: "San Quentin",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94975",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94976",
    city: "Corte Madera",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94977",
    city: "Larkspur",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94978",
    city: "Fairfax",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94979",
    city: "San Anselmo",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94998",
    city: "Novato",
    state: "CA",
    areacode: 415,
    county: "Marin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "94999",
    city: "Petaluma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95001",
    city: "Aptos",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95002",
    city: "Alviso",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95003",
    city: "Aptos",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95004",
    city: "Aromas",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95005",
    city: "Ben Lomond",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95006",
    city: "Boulder Creek",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95007",
    city: "Brookdale",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95008",
    city: "Campbell",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95009",
    city: "Campbell",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95010",
    city: "Capitola",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95011",
    city: "Campbell",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95012",
    city: "Castroville",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95013",
    city: "Coyote",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95014",
    city: "Cupertino",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95015",
    city: "Cupertino",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95017",
    city: "Davenport",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95018",
    city: "Felton",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95019",
    city: "Freedom",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95020",
    city: "Gilroy",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95021",
    city: "Gilroy",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95023",
    city: "Hollister",
    state: "CA",
    areacode: 831,
    county: "San Benito",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95024",
    city: "Hollister",
    state: "CA",
    areacode: 831,
    county: "San Benito",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95026",
    city: "Holy City",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95030",
    city: "Los Gatos",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95031",
    city: "Los Gatos",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95032",
    city: "Los Gatos",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95033",
    city: "Los Gatos",
    state: "CA",
    areacode: 408,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95035",
    city: "Milpitas",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95036",
    city: "Milpitas",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95037",
    city: "Morgan Hill",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95038",
    city: "Morgan Hill",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95039",
    city: "Moss Landing",
    state: "CA",
    areacode: 831,
    county: "Monterey",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95041",
    city: "Mount Hermon",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95042",
    city: "New Almaden",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95043",
    city: "Paicines",
    state: "CA",
    areacode: 831,
    county: "San Benito",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95044",
    city: "Redwood Estates",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95045",
    city: "San Juan Bautista",
    state: "CA",
    areacode: 831,
    county: "San Benito",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95046",
    city: "San Martin",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95050",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95051",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95052",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95053",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95054",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95055",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95056",
    city: "Santa Clara",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95060",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95061",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95062",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95063",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95064",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95065",
    city: "Santa Cruz",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95066",
    city: "Scotts Valley",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95067",
    city: "Scotts Valley",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95070",
    city: "Saratoga",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95071",
    city: "Saratoga",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95073",
    city: "Soquel",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95075",
    city: "Tres Pinos",
    state: "CA",
    areacode: 831,
    county: "San Benito",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95076",
    city: "Watsonville",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95077",
    city: "Watsonville",
    state: "CA",
    areacode: 831,
    county: "Santa Cruz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95101",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95103",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95106",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95108",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95109",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95110",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95111",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95112",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95113",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95115",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95116",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95117",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95118",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95119",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95120",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95121",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95122",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95123",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95124",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95125",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95126",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95127",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95128",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95129",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95130",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95131",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95132",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95133",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95134",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95135",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95136",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95138",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95139",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95140",
    city: "Mount Hamilton",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95141",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95148",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95150",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95151",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95152",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95153",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95154",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95155",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95156",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95157",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95158",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95159",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95160",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95161",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95164",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95170",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95172",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95173",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95190",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95191",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95192",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95193",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95194",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95196",
    city: "San Jose",
    state: "CA",
    areacode: 408,
    county: "Santa Clara",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95201",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95202",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95203",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95204",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95205",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95206",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95207",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95208",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95209",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95210",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95211",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95212",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95213",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95214",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95215",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95219",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95220",
    city: "Acampo",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95221",
    city: "Altaville",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95222",
    city: "Angels Camp",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95223",
    city: "Arnold",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95224",
    city: "Avery",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95225",
    city: "Burson",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95226",
    city: "Campo Seco",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95227",
    city: "Clements",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95228",
    city: "Copperopolis",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95229",
    city: "Douglas Flat",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95230",
    city: "Farmington",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95231",
    city: "French Camp",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95232",
    city: "Glencoe",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95233",
    city: "Hathaway Pines",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95234",
    city: "Holt",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95236",
    city: "Linden",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95237",
    city: "Lockeford",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95240",
    city: "Lodi",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95241",
    city: "Lodi",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95242",
    city: "Lodi",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95245",
    city: "Mokelumne Hill",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95246",
    city: "Mountain Ranch",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95247",
    city: "Murphys",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95248",
    city: "Rail Road Flat",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95249",
    city: "San Andreas",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95251",
    city: "Vallecito",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95252",
    city: "Valley Springs",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95253",
    city: "Victor",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95254",
    city: "Wallace",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95255",
    city: "West Point",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95257",
    city: "Wilseyville",
    state: "CA",
    areacode: 209,
    county: "Calaveras",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95258",
    city: "Woodbridge",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95267",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95269",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95296",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95297",
    city: "Stockton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95301",
    city: "Atwater",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95303",
    city: "Ballico",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95304",
    city: "Tracy",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95305",
    city: "Big Oak Flat",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95306",
    city: "Catheys Valley",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95307",
    city: "Ceres",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95309",
    city: "Chinese Camp",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95310",
    city: "Columbia",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95311",
    city: "Coulterville",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95312",
    city: "Cressey",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95313",
    city: "Crows Landing",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95315",
    city: "Delhi",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95316",
    city: "Denair",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95317",
    city: "El Nido",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95318",
    city: "El Portal",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95319",
    city: "Empire",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95320",
    city: "Escalon",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95321",
    city: "Groveland",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95322",
    city: "Gustine",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95323",
    city: "Hickman",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95324",
    city: "Hilmar",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95325",
    city: "Hornitos",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95326",
    city: "Hughson",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95327",
    city: "Jamestown",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95328",
    city: "Keyes",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95329",
    city: "La Grange",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95330",
    city: "Lathrop",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95333",
    city: "Le Grand",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95334",
    city: "Livingston",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95335",
    city: "Long Barn",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95336",
    city: "Manteca",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95337",
    city: "Manteca",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95338",
    city: "Mariposa",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95340",
    city: "Merced",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95341",
    city: "Merced",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95343",
    city: "Merced",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95344",
    city: "Merced",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95345",
    city: "Midpines",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95346",
    city: "Mi Wuk Village",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95347",
    city: "Moccasin",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95348",
    city: "Merced",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95350",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95351",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95352",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95353",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95354",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95355",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95356",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95357",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95358",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95360",
    city: "Newman",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95361",
    city: "Oakdale",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95363",
    city: "Patterson",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95364",
    city: "Pinecrest",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95365",
    city: "Planada",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95366",
    city: "Ripon",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95367",
    city: "Riverbank",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95368",
    city: "Salida",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95369",
    city: "Snelling",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95370",
    city: "Sonora",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95372",
    city: "Soulsbyville",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95373",
    city: "Standard",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95374",
    city: "Stevinson",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95375",
    city: "Strawberry",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95376",
    city: "Tracy",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95377",
    city: "Tracy",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95378",
    city: "Tracy",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95379",
    city: "Tuolumne",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95380",
    city: "Turlock",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95381",
    city: "Turlock",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95382",
    city: "Turlock",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95383",
    city: "Twain Harte",
    state: "CA",
    areacode: 209,
    county: "Tuolumne",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95385",
    city: "Vernalis",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95386",
    city: "Waterford",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95387",
    city: "Westley",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95388",
    city: "Winton",
    state: "CA",
    areacode: 209,
    county: "Merced",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95389",
    city: "Yosemite National Park",
    state: "CA",
    areacode: 209,
    county: "Mariposa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95391",
    city: "Tracy",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95397",
    city: "Modesto",
    state: "CA",
    areacode: 209,
    county: "Stanislaus",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95401",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95402",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95403",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95404",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95405",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95406",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95407",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95409",
    city: "Santa Rosa",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95410",
    city: "Albion",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95412",
    city: "Annapolis",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95415",
    city: "Boonville",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95416",
    city: "Boyes Hot Springs",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95417",
    city: "Branscomb",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95418",
    city: "Calpella",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95419",
    city: "Camp Meeker",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95420",
    city: "Caspar",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95421",
    city: "Cazadero",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95422",
    city: "Clearlake",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95423",
    city: "Clearlake Oaks",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95424",
    city: "Clearlake Park",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95425",
    city: "Cloverdale",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95426",
    city: "Cobb",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95427",
    city: "Comptche",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95428",
    city: "Covelo",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95429",
    city: "Dos Rios",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95430",
    city: "Duncans Mills",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95431",
    city: "Eldridge",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95432",
    city: "Elk",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95433",
    city: "El Verano",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95435",
    city: "Finley",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95436",
    city: "Forestville",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95437",
    city: "Fort Bragg",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95439",
    city: "Fulton",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95441",
    city: "Geyserville",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95442",
    city: "Glen Ellen",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95443",
    city: "Glenhaven",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95444",
    city: "Graton",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95445",
    city: "Gualala",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95446",
    city: "Guerneville",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95448",
    city: "Healdsburg",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95449",
    city: "Hopland",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95450",
    city: "Jenner",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95451",
    city: "Kelseyville",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95452",
    city: "Kenwood",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95453",
    city: "Lakeport",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95454",
    city: "Laytonville",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95456",
    city: "Little River",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95457",
    city: "Lower Lake",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95458",
    city: "Lucerne",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95459",
    city: "Manchester",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95460",
    city: "Mendocino",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95461",
    city: "Middletown",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95462",
    city: "Monte Rio",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95463",
    city: "Navarro",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95464",
    city: "Nice",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95465",
    city: "Occidental",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95466",
    city: "Philo",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95467",
    city: "Hidden Valley Lake",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95468",
    city: "Point Arena",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95469",
    city: "Potter Valley",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95470",
    city: "Redwood Valley",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95471",
    city: "Rio Nido",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95472",
    city: "Sebastopol",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95473",
    city: "Sebastopol",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95476",
    city: "Sonoma",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95480",
    city: "Stewarts Point",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95481",
    city: "Talmage",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95482",
    city: "Ukiah",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95485",
    city: "Upper Lake",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95486",
    city: "Villa Grande",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95487",
    city: "Vineburg",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95488",
    city: "Westport",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95490",
    city: "Willits",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95492",
    city: "Windsor",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95493",
    city: "Witter Springs",
    state: "CA",
    areacode: 707,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95494",
    city: "Yorkville",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95497",
    city: "The Sea Ranch",
    state: "CA",
    areacode: 707,
    county: "Sonoma",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95501",
    city: "Eureka",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95502",
    city: "Eureka",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95503",
    city: "Eureka",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95511",
    city: "Alderpoint",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95514",
    city: "Blocksburg",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95518",
    city: "Arcata",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95519",
    city: "McKinleyville",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95521",
    city: "Arcata",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95524",
    city: "Bayside",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95525",
    city: "Blue Lake",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95526",
    city: "Bridgeville",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95527",
    city: "Burnt Ranch",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95528",
    city: "Carlotta",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95531",
    city: "Crescent City",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95532",
    city: "Crescent City",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95534",
    city: "Cutten",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95536",
    city: "Ferndale",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95537",
    city: "Fields Landing",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95538",
    city: "Fort Dick",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95540",
    city: "Fortuna",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95542",
    city: "Garberville",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95543",
    city: "Gasquet",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95545",
    city: "Honeydew",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95546",
    city: "Hoopa",
    state: "CA",
    areacode: 530,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95547",
    city: "Hydesville",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95548",
    city: "Klamath",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95549",
    city: "Kneeland",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95550",
    city: "Korbel",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95551",
    city: "Loleta",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95552",
    city: "Mad River",
    state: "CA",
    areacode: 707,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95553",
    city: "Miranda",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95554",
    city: "Myers Flat",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95555",
    city: "Orick",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95556",
    city: "Orleans",
    state: "CA",
    areacode: 530,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95558",
    city: "Petrolia",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95559",
    city: "Phillipsville",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95560",
    city: "Redway",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95562",
    city: "Rio Dell",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95563",
    city: "Salyer",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95564",
    city: "Samoa",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95565",
    city: "Scotia",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95567",
    city: "Smith River",
    state: "CA",
    areacode: 707,
    county: "Del Norte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95568",
    city: "Somes Bar",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95569",
    city: "Redcrest",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95570",
    city: "Trinidad",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95571",
    city: "Weott",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95573",
    city: "Willow Creek",
    state: "CA",
    areacode: 530,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95585",
    city: "Leggett",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95587",
    city: "Piercy",
    state: "CA",
    areacode: 707,
    county: "Mendocino",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95589",
    city: "Whitethorn",
    state: "CA",
    areacode: 707,
    county: "Humboldt",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95595",
    city: "Zenia",
    state: "CA",
    areacode: 707,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95601",
    city: "Amador City",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95602",
    city: "Auburn",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95603",
    city: "Auburn",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95604",
    city: "Auburn",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95605",
    city: "West Sacramento",
    state: "CA",
    areacode: 916,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95606",
    city: "Brooks",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95607",
    city: "Capay",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95608",
    city: "Carmichael",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95609",
    city: "Carmichael",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95610",
    city: "Citrus Heights",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95611",
    city: "Citrus Heights",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95612",
    city: "Clarksburg",
    state: "CA",
    areacode: 916,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95613",
    city: "Coloma",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95614",
    city: "Cool",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95615",
    city: "Courtland",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95616",
    city: "Davis",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95617",
    city: "Davis",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95618",
    city: "Davis",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95619",
    city: "Diamond Springs",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95620",
    city: "Dixon",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95621",
    city: "Citrus Heights",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95623",
    city: "El Dorado",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95624",
    city: "Elk Grove",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95625",
    city: "Elmira",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95626",
    city: "Elverta",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95627",
    city: "Esparto",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95628",
    city: "Fair Oaks",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95629",
    city: "Fiddletown",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95630",
    city: "Folsom",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95631",
    city: "Foresthill",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95632",
    city: "Galt",
    state: "CA",
    areacode: 209,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95633",
    city: "Garden Valley",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95634",
    city: "Georgetown",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95635",
    city: "Greenwood",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95636",
    city: "Grizzly Flats",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95637",
    city: "Guinda",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95638",
    city: "Herald",
    state: "CA",
    areacode: 209,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95639",
    city: "Hood",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95640",
    city: "Ione",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95641",
    city: "Isleton",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95642",
    city: "Jackson",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95644",
    city: "Kit Carson",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95645",
    city: "Knights Landing",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95646",
    city: "Kirkwood",
    state: "CA",
    areacode: 209,
    county: "Alpine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95648",
    city: "Lincoln",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95650",
    city: "Loomis",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95651",
    city: "Lotus",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95652",
    city: "McClellan",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95653",
    city: "Madison",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95654",
    city: "Martell",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95655",
    city: "Mather",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95656",
    city: "Mount Aukum",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95658",
    city: "Newcastle",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95659",
    city: "Nicolaus",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95660",
    city: "North Highlands",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95661",
    city: "Roseville",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95662",
    city: "Orangevale",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95663",
    city: "Penryn",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95664",
    city: "Pilot Hill",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95665",
    city: "Pine Grove",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95666",
    city: "Pioneer",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95667",
    city: "Placerville",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95668",
    city: "Pleasant Grove",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95669",
    city: "Plymouth",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95670",
    city: "Rancho Cordova",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95671",
    city: "Represa",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95672",
    city: "Rescue",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95673",
    city: "Rio Linda",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95674",
    city: "Rio Oso",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95675",
    city: "River Pines",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95676",
    city: "Robbins",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95677",
    city: "Rocklin",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95678",
    city: "Roseville",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95679",
    city: "Rumsey",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95680",
    city: "Ryde",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95681",
    city: "Sheridan",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95682",
    city: "Shingle Springs",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95683",
    city: "Sloughhouse",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95684",
    city: "Somerset",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95685",
    city: "Sutter Creek",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95686",
    city: "Thornton",
    state: "CA",
    areacode: 209,
    county: "San Joaquin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95687",
    city: "Vacaville",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95688",
    city: "Vacaville",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95689",
    city: "Volcano",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95690",
    city: "Walnut Grove",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95691",
    city: "West Sacramento",
    state: "CA",
    areacode: 916,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95692",
    city: "Wheatland",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95693",
    city: "Wilton",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95694",
    city: "Winters",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95695",
    city: "Woodland",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95696",
    city: "Vacaville",
    state: "CA",
    areacode: 707,
    county: "Solano",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95697",
    city: "Yolo",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95698",
    city: "Zamora",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95699",
    city: "Drytown",
    state: "CA",
    areacode: 209,
    county: "Amador",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95701",
    city: "Alta",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95703",
    city: "Applegate",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95709",
    city: "Camino",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95712",
    city: "Chicago Park",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95713",
    city: "Colfax",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95714",
    city: "Dutch Flat",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95715",
    city: "Emigrant Gap",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95717",
    city: "Gold Run",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95720",
    city: "Kyburz",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95721",
    city: "Echo Lake",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95722",
    city: "Meadow Vista",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95724",
    city: "Norden",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95726",
    city: "Pollock Pines",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95728",
    city: "Soda Springs",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95735",
    city: "Twin Bridges",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95736",
    city: "Weimar",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95741",
    city: "Rancho Cordova",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95742",
    city: "Rancho Cordova",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95746",
    city: "Granite Bay",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95747",
    city: "Roseville",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95757",
    city: "Elk Grove",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95758",
    city: "Elk Grove",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95759",
    city: "Elk Grove",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95762",
    city: "El Dorado Hills",
    state: "CA",
    areacode: 916,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95763",
    city: "Folsom",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95765",
    city: "Rocklin",
    state: "CA",
    areacode: 916,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95776",
    city: "Woodland",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95798",
    city: "West Sacramento",
    state: "CA",
    areacode: 916,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95799",
    city: "West Sacramento",
    state: "CA",
    areacode: 916,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95811",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95812",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95813",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95814",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95815",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95816",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95817",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95818",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95819",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95820",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95821",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95822",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95823",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95824",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95825",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95826",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95827",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95828",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95829",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95830",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95831",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95832",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95833",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95834",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95835",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95836",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95837",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95838",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95840",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95841",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95842",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95843",
    city: "Antelope",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95851",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95852",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95853",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95860",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95864",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95865",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95866",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95867",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95894",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95899",
    city: "Sacramento",
    state: "CA",
    areacode: 916,
    county: "Sacramento",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95901",
    city: "Marysville",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95903",
    city: "Beale AFB",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95910",
    city: "Alleghany",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95912",
    city: "Arbuckle",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95913",
    city: "Artois",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95914",
    city: "Bangor",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95915",
    city: "Belden",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95916",
    city: "Berry Creek",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95917",
    city: "Biggs",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95918",
    city: "Browns Valley",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95919",
    city: "Brownsville",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95920",
    city: "Butte City",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95922",
    city: "Camptonville",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95923",
    city: "Canyon Dam",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95924",
    city: "Cedar Ridge",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95925",
    city: "Challenge",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95926",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95927",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95928",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95929",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95930",
    city: "Clipper Mills",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95932",
    city: "Colusa",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95934",
    city: "Crescent Mills",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95935",
    city: "Dobbins",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95936",
    city: "Downieville",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95937",
    city: "Dunnigan",
    state: "CA",
    areacode: 530,
    county: "Yolo",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95938",
    city: "Durham",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95939",
    city: "Elk Creek",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95940",
    city: "Feather Falls",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95941",
    city: "Forbestown",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95942",
    city: "Forest Ranch",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95943",
    city: "Glenn",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95944",
    city: "Goodyears Bar",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95945",
    city: "Grass Valley",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95946",
    city: "Penn Valley",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95947",
    city: "Greenville",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95948",
    city: "Gridley",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95949",
    city: "Grass Valley",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95950",
    city: "Grimes",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95951",
    city: "Hamilton City",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95953",
    city: "Live Oak",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95954",
    city: "Magalia",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95955",
    city: "Maxwell",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95956",
    city: "Meadow Valley",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95957",
    city: "Meridian",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95958",
    city: "Nelson",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95959",
    city: "Nevada City",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95960",
    city: "North San Juan",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95961",
    city: "Olivehurst",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95962",
    city: "Oregon House",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95963",
    city: "Orland",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95965",
    city: "Oroville",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95966",
    city: "Oroville",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95967",
    city: "Paradise",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95968",
    city: "Palermo",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95969",
    city: "Paradise",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95970",
    city: "Princeton",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95971",
    city: "Quincy",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95972",
    city: "Rackerby",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95973",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95974",
    city: "Richvale",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95975",
    city: "Rough and Ready",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95976",
    city: "Chico",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95977",
    city: "Smartsville",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95978",
    city: "Stirling City",
    state: "CA",
    areacode: 530,
    county: "Butte",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95979",
    city: "Stonyford",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95980",
    city: "Storrie",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95981",
    city: "Strawberry Valley",
    state: "CA",
    areacode: 530,
    county: "Yuba",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95982",
    city: "Sutter",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95983",
    city: "Taylorsville",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95984",
    city: "Twain",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95986",
    city: "Washington",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95987",
    city: "Williams",
    state: "CA",
    areacode: 530,
    county: "Colusa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95988",
    city: "Willows",
    state: "CA",
    areacode: 530,
    county: "Glenn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95991",
    city: "Yuba City",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95992",
    city: "Yuba City",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "95993",
    city: "Yuba City",
    state: "CA",
    areacode: 530,
    county: "Sutter",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96001",
    city: "Redding",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96002",
    city: "Redding",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96003",
    city: "Redding",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96006",
    city: "Adin",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96007",
    city: "Anderson",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96008",
    city: "Bella Vista",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96009",
    city: "Bieber",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96010",
    city: "Big Bar",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96011",
    city: "Big Bend",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96013",
    city: "Burney",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96014",
    city: "Callahan",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96015",
    city: "Canby",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96016",
    city: "Cassel",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96017",
    city: "Castella",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96019",
    city: "Shasta Lake",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96020",
    city: "Chester",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96021",
    city: "Corning",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96022",
    city: "Cottonwood",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96023",
    city: "Dorris",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96024",
    city: "Douglas City",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96025",
    city: "Dunsmuir",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96027",
    city: "Etna",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96028",
    city: "Fall River Mills",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96029",
    city: "Flournoy",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96031",
    city: "Forks of Salmon",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96032",
    city: "Fort Jones",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96033",
    city: "French Gulch",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96034",
    city: "Gazelle",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96035",
    city: "Gerber",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96037",
    city: "Greenview",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96038",
    city: "Grenada",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96039",
    city: "Happy Camp",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96040",
    city: "Hat Creek",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96041",
    city: "Hayfork",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96044",
    city: "Hornbrook",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96046",
    city: "Hyampom",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96047",
    city: "Igo",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96048",
    city: "Junction City",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96049",
    city: "Redding",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96050",
    city: "Klamath River",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96051",
    city: "Lakehead",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96052",
    city: "Lewiston",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96054",
    city: "Lookout",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96055",
    city: "Los Molinos",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96056",
    city: "McArthur",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96057",
    city: "McCloud",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96058",
    city: "Macdoel",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96059",
    city: "Manton",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96061",
    city: "Mill Creek",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96062",
    city: "Millville",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96063",
    city: "Mineral",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96064",
    city: "Montague",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96065",
    city: "Montgomery Creek",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96067",
    city: "Mount Shasta",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96068",
    city: "Nubieber",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96069",
    city: "Oak Run",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96070",
    city: "Obrien",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96071",
    city: "Old Station",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96073",
    city: "Palo Cedro",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96074",
    city: "Paskenta",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96075",
    city: "Paynes Creek",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96076",
    city: "Platina",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96078",
    city: "Proberta",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96079",
    city: "Shasta Lake",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96080",
    city: "Red Bluff",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96084",
    city: "Round Mountain",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96085",
    city: "Scott Bar",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96086",
    city: "Seiad Valley",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96087",
    city: "Shasta",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96088",
    city: "Shingletown",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96089",
    city: "Shasta Lake",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96090",
    city: "Tehama",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96091",
    city: "Trinity Center",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96092",
    city: "Vina",
    state: "CA",
    areacode: 530,
    county: "Tehama",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96093",
    city: "Weaverville",
    state: "CA",
    areacode: 530,
    county: "Trinity",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96094",
    city: "Weed",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96095",
    city: "Whiskeytown",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96096",
    city: "Whitmore",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96097",
    city: "Yreka",
    state: "CA",
    areacode: 530,
    county: "Siskiyou",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96099",
    city: "Redding",
    state: "CA",
    areacode: 530,
    county: "Shasta",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96101",
    city: "Alturas",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96103",
    city: "Blairsden Graeagle",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96104",
    city: "Cedarville",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96105",
    city: "Chilcoot",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96106",
    city: "Clio",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96107",
    city: "Coleville",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96108",
    city: "Davis Creek",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96109",
    city: "Doyle",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96110",
    city: "Eagleville",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96111",
    city: "Floriston",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96112",
    city: "Fort Bidwell",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96113",
    city: "Herlong",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96114",
    city: "Janesville",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96115",
    city: "Lake City",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96116",
    city: "Likely",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96117",
    city: "Litchfield",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96118",
    city: "Loyalton",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96119",
    city: "Madeline",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96120",
    city: "Markleeville",
    state: "CA",
    areacode: 530,
    county: "Alpine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96121",
    city: "Milford",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96122",
    city: "Portola",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96123",
    city: "Ravendale",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96124",
    city: "Calpine",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96125",
    city: "Sierra City",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96126",
    city: "Sierraville",
    state: "CA",
    areacode: 530,
    county: "Sierra",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96127",
    city: "Susanville",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96128",
    city: "Standish",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96129",
    city: "Beckwourth",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96130",
    city: "Susanville",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96132",
    city: "Termo",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96133",
    city: "Topaz",
    state: "CA",
    areacode: 760,
    county: "Mono",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96134",
    city: "Tulelake",
    state: "CA",
    areacode: 530,
    county: "Modoc",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96135",
    city: "Vinton",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96136",
    city: "Wendel",
    state: "CA",
    areacode: 530,
    county: "Lassen",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96137",
    city: "Westwood",
    state: "CA",
    areacode: 530,
    county: "Plumas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96140",
    city: "Carnelian Bay",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96141",
    city: "Homewood",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96142",
    city: "Tahoma",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96143",
    city: "Kings Beach",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96145",
    city: "Tahoe City",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96146",
    city: "Olympic Valley",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96148",
    city: "Tahoe Vista",
    state: "CA",
    areacode: 530,
    county: "Placer",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96150",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96151",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96152",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96154",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96155",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96156",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96157",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96158",
    city: "South Lake Tahoe",
    state: "CA",
    areacode: 530,
    county: "El Dorado",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96160",
    city: "Truckee",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96161",
    city: "Truckee",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96162",
    city: "Truckee",
    state: "CA",
    areacode: 530,
    county: "Nevada",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "96701",
    city: "Aiea",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96703",
    city: "Anahola",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96704",
    city: "Captain Cook",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96705",
    city: "Eleele",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96706",
    city: "Ewa Beach",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96707",
    city: "Kapolei",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96708",
    city: "Haiku",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96709",
    city: "Kapolei",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96710",
    city: "Hakalau",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96712",
    city: "Haleiwa",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96713",
    city: "Hana",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96714",
    city: "Hanalei",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96715",
    city: "Hanamaulu",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96716",
    city: "Hanapepe",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96717",
    city: "Hauula",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96718",
    city: "Hawaii National Park",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96719",
    city: "Hawi",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96720",
    city: "Hilo",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96721",
    city: "Hilo",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96722",
    city: "Princeville",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96725",
    city: "Holualoa",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96726",
    city: "Honaunau",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96727",
    city: "Honokaa",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96728",
    city: "Honomu",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96729",
    city: "Hoolehua",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96730",
    city: "Kaaawa",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96731",
    city: "Kahuku",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96732",
    city: "Kahului",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96733",
    city: "Kahului",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96734",
    city: "Kailua",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96737",
    city: "Ocean View",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96738",
    city: "Waikoloa",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96739",
    city: "Keauhou",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96740",
    city: "Kailua Kona",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96741",
    city: "Kalaheo",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96742",
    city: "Kalaupapa",
    state: "HI",
    areacode: 808,
    county: "Kalawao",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96743",
    city: "Kamuela",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96744",
    city: "Kaneohe",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96745",
    city: "Kailua Kona",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96746",
    city: "Kapaa",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96747",
    city: "Kaumakani",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96748",
    city: "Kaunakakai",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96749",
    city: "Keaau",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96750",
    city: "Kealakekua",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96751",
    city: "Kealia",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96752",
    city: "Kekaha",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96753",
    city: "Kihei",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96754",
    city: "Kilauea",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96755",
    city: "Kapaau",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96756",
    city: "Koloa",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96757",
    city: "Kualapuu",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96759",
    city: "Kunia",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96760",
    city: "Kurtistown",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96761",
    city: "Lahaina",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96762",
    city: "Laie",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96763",
    city: "Lanai City",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96764",
    city: "Laupahoehoe",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96765",
    city: "Lawai",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96766",
    city: "Lihue",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96767",
    city: "Lahaina",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96768",
    city: "Makawao",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96769",
    city: "Makaweli",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96770",
    city: "Maunaloa",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96771",
    city: "Mountain View",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96772",
    city: "Naalehu",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96773",
    city: "Ninole",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96774",
    city: "Ookala",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96776",
    city: "Paauilo",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96777",
    city: "Pahala",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96778",
    city: "Pahoa",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96779",
    city: "Paia",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96780",
    city: "Papaaloa",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96781",
    city: "Papaikou",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96782",
    city: "Pearl City",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96783",
    city: "Pepeekeo",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96784",
    city: "Puunene",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96785",
    city: "Volcano",
    state: "HI",
    areacode: 808,
    county: "Hawaii",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96786",
    city: "Wahiawa",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96788",
    city: "Pukalani",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96789",
    city: "Mililani",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96790",
    city: "Kula",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96791",
    city: "Waialua",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96792",
    city: "Waianae",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96793",
    city: "Wailuku",
    state: "HI",
    areacode: 808,
    county: "Maui",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96795",
    city: "Waimanalo",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96796",
    city: "Waimea",
    state: "HI",
    areacode: 808,
    county: "Kauai",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96797",
    city: "Waipahu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96801",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96802",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96803",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96804",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96805",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96806",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96807",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96808",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96809",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96810",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96811",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96812",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96813",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96814",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96815",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96816",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96817",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96818",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96819",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96820",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96821",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96822",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96823",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96824",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96825",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96826",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96828",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96830",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96836",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96837",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96838",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96839",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96840",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96841",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96843",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96844",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96846",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96847",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96848",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96849",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96850",
    city: "Honolulu",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96853",
    city: "JBPHH",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96854",
    city: "Wheeler Army Airfield",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96857",
    city: "Schofield Barracks",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96858",
    city: "Fort Shafter",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96859",
    city: "Tripler Army Medical Center",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96860",
    city: "JBPHH",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96861",
    city: "Camp H M Smith",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "96863",
    city: "MCBH Kaneohe Bay",
    state: "HI",
    areacode: 808,
    county: "Honolulu",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "97001",
    city: "Antelope",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97002",
    city: "Aurora",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97003",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97004",
    city: "Beavercreek",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97005",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97006",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97007",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97008",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97009",
    city: "Boring",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97010",
    city: "Bridal Veil",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97011",
    city: "Brightwood",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97013",
    city: "Canby",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97014",
    city: "Cascade Locks",
    state: "OR",
    areacode: 541,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97015",
    city: "Clackamas",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97016",
    city: "Clatskanie",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97017",
    city: "Colton",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97018",
    city: "Columbia City",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97019",
    city: "Corbett",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97020",
    city: "Donald",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97021",
    city: "Dufur",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97022",
    city: "Eagle Creek",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97023",
    city: "Estacada",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97024",
    city: "Fairview",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97026",
    city: "Gervais",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97027",
    city: "Gladstone",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97028",
    city: "Government Camp",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97029",
    city: "Grass Valley",
    state: "OR",
    areacode: 541,
    county: "Sherman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97030",
    city: "Gresham",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97031",
    city: "Hood River",
    state: "OR",
    areacode: 541,
    county: "Hood River",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97032",
    city: "Hubbard",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97033",
    city: "Kent",
    state: "OR",
    areacode: 541,
    county: "Sherman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97034",
    city: "Lake Oswego",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97035",
    city: "Lake Oswego",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97036",
    city: "Marylhurst",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97037",
    city: "Maupin",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97038",
    city: "Molalla",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97039",
    city: "Moro",
    state: "OR",
    areacode: 541,
    county: "Sherman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97040",
    city: "Mosier",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97041",
    city: "Mount Hood Parkdale",
    state: "OR",
    areacode: 541,
    county: "Hood River",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97042",
    city: "Mulino",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97044",
    city: "Odell",
    state: "OR",
    areacode: 541,
    county: "Hood River",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97045",
    city: "Oregon City",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97048",
    city: "Rainier",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97049",
    city: "Rhododendron",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97050",
    city: "Rufus",
    state: "OR",
    areacode: 541,
    county: "Sherman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97051",
    city: "Saint Helens",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97053",
    city: "Warren",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97054",
    city: "Deer Island",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97055",
    city: "Sandy",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97056",
    city: "Scappoose",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97057",
    city: "Shaniko",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97058",
    city: "The Dalles",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97060",
    city: "Troutdale",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97062",
    city: "Tualatin",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97063",
    city: "Tygh Valley",
    state: "OR",
    areacode: 541,
    county: "Wasco",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97064",
    city: "Vernonia",
    state: "OR",
    areacode: 503,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97065",
    city: "Wasco",
    state: "OR",
    areacode: 541,
    county: "Sherman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97067",
    city: "Welches",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97068",
    city: "West Linn",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97070",
    city: "Wilsonville",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97071",
    city: "Woodburn",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97075",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97076",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97077",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97078",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97079",
    city: "Beaverton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97080",
    city: "Gresham",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97086",
    city: "Happy Valley",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97089",
    city: "Damascus",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97101",
    city: "Amity",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97102",
    city: "Arch Cape",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97103",
    city: "Astoria",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97106",
    city: "Banks",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97107",
    city: "Bay City",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97108",
    city: "Beaver",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97109",
    city: "Buxton",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97110",
    city: "Cannon Beach",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97111",
    city: "Carlton",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97112",
    city: "Cloverdale",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97113",
    city: "Cornelius",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97114",
    city: "Dayton",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97115",
    city: "Dundee",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97116",
    city: "Forest Grove",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97117",
    city: "Gales Creek",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97118",
    city: "Garibaldi",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97119",
    city: "Gaston",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97121",
    city: "Hammond",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97122",
    city: "Hebo",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97123",
    city: "Hillsboro",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97124",
    city: "Hillsboro",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97125",
    city: "Manning",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97127",
    city: "Lafayette",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97128",
    city: "McMinnville",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97129",
    city: "Hillsboro",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97130",
    city: "Manzanita",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97131",
    city: "Nehalem",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97132",
    city: "Newberg",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97133",
    city: "North Plains",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97134",
    city: "Oceanside",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97135",
    city: "Pacific City",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97136",
    city: "Rockaway Beach",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97137",
    city: "Saint Paul",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97138",
    city: "Seaside",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97140",
    city: "Sherwood",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97141",
    city: "Tillamook",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97143",
    city: "Netarts",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97144",
    city: "Timber",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97145",
    city: "Tolovana Park",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97146",
    city: "Warrenton",
    state: "OR",
    areacode: 503,
    county: "Clatsop",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97147",
    city: "Wheeler",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97148",
    city: "Yamhill",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97149",
    city: "Neskowin",
    state: "OR",
    areacode: 503,
    county: "Tillamook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97201",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97202",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97203",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97204",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97205",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97206",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97207",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97208",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97209",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97210",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97211",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97212",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97213",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97214",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97215",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97216",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97217",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97218",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97219",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97220",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97221",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97222",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97223",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97224",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97225",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97227",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97228",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97229",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97230",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97231",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97232",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97233",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97236",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97238",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97239",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97240",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97242",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97250",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97251",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97252",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97253",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97254",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97256",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97266",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97267",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97268",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97269",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Clackamas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97280",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97281",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97282",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97283",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97286",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97290",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97291",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97292",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97293",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97294",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97296",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Multnomah",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97298",
    city: "Portland",
    state: "OR",
    areacode: 503,
    county: "Washington",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97301",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97302",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97303",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97304",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97305",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97306",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97307",
    city: "Keizer",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97308",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97309",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97310",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97311",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97312",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97314",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97317",
    city: "Salem",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97321",
    city: "Albany",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97322",
    city: "Albany",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97324",
    city: "Alsea",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97325",
    city: "Aumsville",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97326",
    city: "Blodgett",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97327",
    city: "Brownsville",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97329",
    city: "Cascadia",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97330",
    city: "Corvallis",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97331",
    city: "Corvallis",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97333",
    city: "Corvallis",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97335",
    city: "Crabtree",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97336",
    city: "Crawfordsville",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97338",
    city: "Dallas",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97339",
    city: "Corvallis",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97341",
    city: "Depoe Bay",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97342",
    city: "Detroit",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97343",
    city: "Eddyville",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97344",
    city: "Falls City",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97345",
    city: "Foster",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97346",
    city: "Gates",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97347",
    city: "Grand Ronde",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97348",
    city: "Halsey",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97350",
    city: "Idanha",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97351",
    city: "Independence",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97352",
    city: "Jefferson",
    state: "OR",
    areacode: 541,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97355",
    city: "Lebanon",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97357",
    city: "Logsden",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97358",
    city: "Lyons",
    state: "OR",
    areacode: 503,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97360",
    city: "Mill City",
    state: "OR",
    areacode: 503,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97361",
    city: "Monmouth",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97362",
    city: "Mount Angel",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97364",
    city: "Neotsu",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97365",
    city: "Newport",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97366",
    city: "South Beach",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97367",
    city: "Lincoln City",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97368",
    city: "Otis",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97369",
    city: "Otter Rock",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97370",
    city: "Philomath",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97371",
    city: "Rickreall",
    state: "OR",
    areacode: 503,
    county: "Polk",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97373",
    city: "Saint Benedict",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97374",
    city: "Scio",
    state: "OR",
    areacode: 503,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97375",
    city: "Scotts Mills",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97376",
    city: "Seal Rock",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97377",
    city: "Shedd",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97378",
    city: "Sheridan",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97380",
    city: "Siletz",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97381",
    city: "Silverton",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97383",
    city: "Stayton",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97384",
    city: "Mehama",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97385",
    city: "Sublimity",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97386",
    city: "Sweet Home",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97388",
    city: "Gleneden Beach",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97389",
    city: "Tangent",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97390",
    city: "Tidewater",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97391",
    city: "Toledo",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97392",
    city: "Turner",
    state: "OR",
    areacode: 503,
    county: "Marion",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97394",
    city: "Waldport",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97396",
    city: "Willamina",
    state: "OR",
    areacode: 503,
    county: "Yamhill",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97401",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97402",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97403",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97404",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97405",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97406",
    city: "Agness",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97407",
    city: "Allegany",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97408",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97409",
    city: "Alvadore",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97410",
    city: "Azalea",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97411",
    city: "Bandon",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97412",
    city: "Blachly",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97413",
    city: "Blue River",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97414",
    city: "Broadbent",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97415",
    city: "Brookings",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97416",
    city: "Camas Valley",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97417",
    city: "Canyonville",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97419",
    city: "Cheshire",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97420",
    city: "Coos Bay",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97423",
    city: "Coquille",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97424",
    city: "Cottage Grove",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97426",
    city: "Creswell",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97429",
    city: "Days Creek",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97430",
    city: "Deadwood",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97431",
    city: "Dexter",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97432",
    city: "Dillard",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97434",
    city: "Dorena",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97435",
    city: "Drain",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97436",
    city: "Elkton",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97437",
    city: "Elmira",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97438",
    city: "Fall Creek",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97439",
    city: "Florence",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97440",
    city: "Eugene",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97441",
    city: "Gardiner",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97442",
    city: "Glendale",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97443",
    city: "Glide",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97444",
    city: "Gold Beach",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97446",
    city: "Harrisburg",
    state: "OR",
    areacode: 541,
    county: "Linn",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97447",
    city: "Idleyld Park",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97448",
    city: "Junction City",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97449",
    city: "Lakeside",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97450",
    city: "Langlois",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97451",
    city: "Lorane",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97452",
    city: "Lowell",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97453",
    city: "Mapleton",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97454",
    city: "Marcola",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97455",
    city: "Pleasant Hill",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97456",
    city: "Monroe",
    state: "OR",
    areacode: 541,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97457",
    city: "Myrtle Creek",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97458",
    city: "Myrtle Point",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97459",
    city: "North Bend",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97461",
    city: "Noti",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97462",
    city: "Oakland",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97463",
    city: "Oakridge",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97464",
    city: "Ophir",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97465",
    city: "Port Orford",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97466",
    city: "Powers",
    state: "OR",
    areacode: 541,
    county: "Coos",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97467",
    city: "Reedsport",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97469",
    city: "Riddle",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97470",
    city: "Roseburg",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97471",
    city: "Roseburg",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97473",
    city: "Scottsburg",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97475",
    city: "Springfield",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97476",
    city: "Sixes",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97477",
    city: "Springfield",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97478",
    city: "Springfield",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97479",
    city: "Sutherlin",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97480",
    city: "Swisshome",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97481",
    city: "Tenmile",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97484",
    city: "Tiller",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97486",
    city: "Umpqua",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97487",
    city: "Veneta",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97488",
    city: "Vida",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97489",
    city: "Walterville",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97490",
    city: "Walton",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97491",
    city: "Wedderburn",
    state: "OR",
    areacode: 541,
    county: "Curry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97492",
    city: "Westfir",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97493",
    city: "Westlake",
    state: "OR",
    areacode: 541,
    county: "Lane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97494",
    city: "Wilbur",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97495",
    city: "Winchester",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97496",
    city: "Winston",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97497",
    city: "Wolf Creek",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97498",
    city: "Yachats",
    state: "OR",
    areacode: 541,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97499",
    city: "Yoncalla",
    state: "OR",
    areacode: 541,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97501",
    city: "Medford",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97502",
    city: "Central Point",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97503",
    city: "White City",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97504",
    city: "Medford",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97520",
    city: "Ashland",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97522",
    city: "Butte Falls",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97523",
    city: "Cave Junction",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97524",
    city: "Eagle Point",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97525",
    city: "Gold Hill",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97526",
    city: "Grants Pass",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97527",
    city: "Grants Pass",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97528",
    city: "Grants Pass",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97530",
    city: "Jacksonville",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97531",
    city: "Kerby",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97532",
    city: "Merlin",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97533",
    city: "Murphy",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97534",
    city: "O Brien",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97535",
    city: "Phoenix",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97536",
    city: "Prospect",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97537",
    city: "Rogue River",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97538",
    city: "Selma",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97539",
    city: "Shady Cove",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97540",
    city: "Talent",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97541",
    city: "Trail",
    state: "OR",
    areacode: 541,
    county: "Jackson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97543",
    city: "Wilderville",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97544",
    city: "Williams",
    state: "OR",
    areacode: 541,
    county: "Josephine",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97601",
    city: "Klamath Falls",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97602",
    city: "Klamath Falls",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97603",
    city: "Klamath Falls",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97604",
    city: "Crater Lake",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97620",
    city: "Adel",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97621",
    city: "Beatty",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97622",
    city: "Bly",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97623",
    city: "Bonanza",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97624",
    city: "Chiloquin",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97625",
    city: "Dairy",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97626",
    city: "Fort Klamath",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97627",
    city: "Keno",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97630",
    city: "Lakeview",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97632",
    city: "Malin",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97633",
    city: "Merrill",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97634",
    city: "Midland",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97635",
    city: "New Pine Creek",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97636",
    city: "Paisley",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97637",
    city: "Plush",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97638",
    city: "Silver Lake",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97639",
    city: "Sprague River",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97640",
    city: "Summer Lake",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97641",
    city: "Christmas Valley",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97701",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97702",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97703",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97707",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97708",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97709",
    city: "Bend",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97710",
    city: "Fields",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97711",
    city: "Ashwood",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97712",
    city: "Brothers",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97720",
    city: "Burns",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97721",
    city: "Princeton",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97722",
    city: "Diamond",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97730",
    city: "Camp Sherman",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97731",
    city: "Chemult",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97732",
    city: "Crane",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97733",
    city: "Crescent",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97734",
    city: "Culver",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97735",
    city: "Fort Rock",
    state: "OR",
    areacode: 541,
    county: "Lake",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97736",
    city: "Frenchglen",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97737",
    city: "Gilchrist",
    state: "OR",
    areacode: 541,
    county: "Klamath",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97738",
    city: "Hines",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97739",
    city: "La Pine",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97741",
    city: "Madras",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97750",
    city: "Mitchell",
    state: "OR",
    areacode: 541,
    county: "Wheeler",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97751",
    city: "Paulina",
    state: "OR",
    areacode: 541,
    county: "Crook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97752",
    city: "Post",
    state: "OR",
    areacode: 541,
    county: "Crook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97753",
    city: "Powell Butte",
    state: "OR",
    areacode: 541,
    county: "Crook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97754",
    city: "Prineville",
    state: "OR",
    areacode: 541,
    county: "Crook",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97756",
    city: "Redmond",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97758",
    city: "Riley",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97759",
    city: "Sisters",
    state: "OR",
    areacode: 541,
    county: "Deschutes",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97760",
    city: "Terrebonne",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97761",
    city: "Warm Springs",
    state: "OR",
    areacode: 541,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97801",
    city: "Pendleton",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97810",
    city: "Adams",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97812",
    city: "Arlington",
    state: "OR",
    areacode: 541,
    county: "Gilliam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97813",
    city: "Athena",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97814",
    city: "Baker City",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97817",
    city: "Bates",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97818",
    city: "Boardman",
    state: "OR",
    areacode: 541,
    county: "Morrow",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97819",
    city: "Bridgeport",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97820",
    city: "Canyon City",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97823",
    city: "Condon",
    state: "OR",
    areacode: 541,
    county: "Gilliam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97824",
    city: "Cove",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97825",
    city: "Dayville",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97826",
    city: "Echo",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97827",
    city: "Elgin",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97828",
    city: "Enterprise",
    state: "OR",
    areacode: 541,
    county: "Wallowa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97830",
    city: "Fossil",
    state: "OR",
    areacode: 541,
    county: "Wheeler",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97833",
    city: "Haines",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97834",
    city: "Halfway",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97835",
    city: "Helix",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97836",
    city: "Heppner",
    state: "OR",
    areacode: 541,
    county: "Morrow",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97837",
    city: "Hereford",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97838",
    city: "Hermiston",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97839",
    city: "Lexington",
    state: "OR",
    areacode: 541,
    county: "Morrow",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97840",
    city: "Oxbow",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97841",
    city: "Imbler",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97842",
    city: "Imnaha",
    state: "OR",
    areacode: 541,
    county: "Wallowa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97843",
    city: "Ione",
    state: "OR",
    areacode: 541,
    county: "Morrow",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97844",
    city: "Irrigon",
    state: "OR",
    areacode: 541,
    county: "Morrow",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97845",
    city: "John Day",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97846",
    city: "Joseph",
    state: "OR",
    areacode: 541,
    county: "Wallowa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97848",
    city: "Kimberly",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97850",
    city: "La Grande",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97856",
    city: "Long Creek",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97857",
    city: "Lostine",
    state: "OR",
    areacode: 541,
    county: "Wallowa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97859",
    city: "Meacham",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97861",
    city: "Mikkalo",
    state: "OR",
    areacode: 541,
    county: "Gilliam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97862",
    city: "Milton Freewater",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97864",
    city: "Monument",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97865",
    city: "Mount Vernon",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97867",
    city: "North Powder",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97868",
    city: "Pilot Rock",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97869",
    city: "Prairie City",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97870",
    city: "Richland",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97873",
    city: "Seneca",
    state: "OR",
    areacode: 541,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97874",
    city: "Spray",
    state: "OR",
    areacode: 541,
    county: "Wheeler",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97875",
    city: "Stanfield",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97876",
    city: "Summerville",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97877",
    city: "Sumpter",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97880",
    city: "Ukiah",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97882",
    city: "Umatilla",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97883",
    city: "Union",
    state: "OR",
    areacode: 541,
    county: "Union",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97884",
    city: "Unity",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97885",
    city: "Wallowa",
    state: "OR",
    areacode: 541,
    county: "Wallowa",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97886",
    city: "Weston",
    state: "OR",
    areacode: 541,
    county: "Umatilla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97901",
    city: "Adrian",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97902",
    city: "Arock",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97903",
    city: "Brogan",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97904",
    city: "Drewsey",
    state: "OR",
    areacode: 541,
    county: "Harney",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97905",
    city: "Durkee",
    state: "OR",
    areacode: 541,
    county: "Baker",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97906",
    city: "Harper",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97907",
    city: "Huntington",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "97908",
    city: "Ironside",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97909",
    city: "Jamieson",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97910",
    city: "Jordan Valley",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97911",
    city: "Juntura",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97913",
    city: "Nyssa",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97914",
    city: "Ontario",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97917",
    city: "Riverside",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97918",
    city: "Vale",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "97920",
    city: "Westfall",
    state: "OR",
    areacode: 541,
    county: "Malheur",
    timezone3: "MST",
    timezone: "US/Mountain",
  },
  {
    zipcode: "98001",
    city: "Auburn",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98002",
    city: "Auburn",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98003",
    city: "Federal Way",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98004",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98005",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98006",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98007",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98008",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98009",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98010",
    city: "Black Diamond",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98011",
    city: "Bothell",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98012",
    city: "Bothell",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98013",
    city: "Burton",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98014",
    city: "Carnation",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98015",
    city: "Bellevue",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98019",
    city: "Duvall",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98020",
    city: "Edmonds",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98021",
    city: "Bothell",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98022",
    city: "Enumclaw",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98023",
    city: "Federal Way",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98024",
    city: "Fall City",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98025",
    city: "Hobart",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98026",
    city: "Edmonds",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98027",
    city: "Issaquah",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98028",
    city: "Kenmore",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98029",
    city: "Issaquah",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98030",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98031",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98032",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98033",
    city: "Kirkland",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98034",
    city: "Kirkland",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98035",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98036",
    city: "Lynnwood",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98037",
    city: "Lynnwood",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98038",
    city: "Maple Valley",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98039",
    city: "Medina",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98040",
    city: "Mercer Island",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98041",
    city: "Bothell",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98042",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98043",
    city: "Mountlake Terrace",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98045",
    city: "North Bend",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98046",
    city: "Lynnwood",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98047",
    city: "Pacific",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98050",
    city: "Preston",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98051",
    city: "Ravensdale",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98052",
    city: "Redmond",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98053",
    city: "Redmond",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98055",
    city: "Renton",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98056",
    city: "Renton",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98057",
    city: "Renton",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98058",
    city: "Renton",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98059",
    city: "Renton",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98061",
    city: "Rollingbay",
    state: "WA",
    areacode: 206,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98062",
    city: "Seahurst",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98063",
    city: "Federal Way",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98064",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98065",
    city: "Snoqualmie",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98068",
    city: "Snoqualmie Pass",
    state: "WA",
    areacode: 425,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98070",
    city: "Vashon",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98071",
    city: "Auburn",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98072",
    city: "Woodinville",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98073",
    city: "Redmond",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98074",
    city: "Sammamish",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98075",
    city: "Sammamish",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98077",
    city: "Woodinville",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98082",
    city: "Mill Creek",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98083",
    city: "Kirkland",
    state: "WA",
    areacode: 425,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98087",
    city: "Lynnwood",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98089",
    city: "Kent",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98092",
    city: "Auburn",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98093",
    city: "Federal Way",
    state: "WA",
    areacode: 253,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98101",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98102",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98103",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98104",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98105",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98106",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98107",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98108",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98109",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98110",
    city: "Bainbridge Island",
    state: "WA",
    areacode: 206,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98111",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98112",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98113",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98114",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98115",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98116",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98117",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98118",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98119",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98121",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98122",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98124",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98125",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98126",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98127",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98129",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98131",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98133",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98134",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98136",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98138",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98139",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98141",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98144",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98145",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98146",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98148",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98154",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98155",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98158",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98160",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98161",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98164",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98165",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98166",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98168",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98170",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98174",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98175",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98177",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98178",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98181",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98185",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98188",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98190",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98191",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98194",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98195",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98198",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98199",
    city: "Seattle",
    state: "WA",
    areacode: 206,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98201",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98203",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98204",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98206",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98207",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98208",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98213",
    city: "Everett",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98220",
    city: "Acme",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98221",
    city: "Anacortes",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98222",
    city: "Blakely Island",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98223",
    city: "Arlington",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98224",
    city: "Baring",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98225",
    city: "Bellingham",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98226",
    city: "Bellingham",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98227",
    city: "Bellingham",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98228",
    city: "Bellingham",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98229",
    city: "Bellingham",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98230",
    city: "Blaine",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98231",
    city: "Blaine",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98232",
    city: "Bow",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98233",
    city: "Burlington",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98235",
    city: "Clearlake",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98236",
    city: "Clinton",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98237",
    city: "Concrete",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98238",
    city: "Conway",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98239",
    city: "Coupeville",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98240",
    city: "Custer",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98241",
    city: "Darrington",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98243",
    city: "Deer Harbor",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98244",
    city: "Deming",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98245",
    city: "Eastsound",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98247",
    city: "Everson",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98248",
    city: "Ferndale",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98249",
    city: "Freeland",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98250",
    city: "Friday Harbor",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98251",
    city: "Gold Bar",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98252",
    city: "Granite Falls",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98253",
    city: "Greenbank",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98255",
    city: "Hamilton",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98256",
    city: "Index",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98257",
    city: "La Conner",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98258",
    city: "Lake Stevens",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98259",
    city: "North Lakewood",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98260",
    city: "Langley",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98261",
    city: "Lopez Island",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98262",
    city: "Lummi Island",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98263",
    city: "Lyman",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98264",
    city: "Lynden",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98266",
    city: "Maple Falls",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98267",
    city: "Marblemount",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98270",
    city: "Marysville",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98271",
    city: "Marysville",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98272",
    city: "Monroe",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98273",
    city: "Mount Vernon",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98274",
    city: "Mount Vernon",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98275",
    city: "Mukilteo",
    state: "WA",
    areacode: 425,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98276",
    city: "Nooksack",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98277",
    city: "Oak Harbor",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98278",
    city: "Oak Harbor",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98279",
    city: "Olga",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98280",
    city: "Orcas",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98281",
    city: "Point Roberts",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98282",
    city: "Camano Island",
    state: "WA",
    areacode: 360,
    county: "Island",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98283",
    city: "Rockport",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98284",
    city: "Sedro Woolley",
    state: "WA",
    areacode: 360,
    county: "Skagit",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98286",
    city: "Shaw Island",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98287",
    city: "Silvana",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98288",
    city: "Skykomish",
    state: "WA",
    areacode: 360,
    county: "King",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98290",
    city: "Snohomish",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98291",
    city: "Snohomish",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98292",
    city: "Stanwood",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98293",
    city: "Startup",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98294",
    city: "Sultan",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98295",
    city: "Sumas",
    state: "WA",
    areacode: 360,
    county: "Whatcom",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98296",
    city: "Snohomish",
    state: "WA",
    areacode: 360,
    county: "Snohomish",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98297",
    city: "Waldron",
    state: "WA",
    areacode: 360,
    county: "San Juan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98303",
    city: "Anderson Island",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98304",
    city: "Ashford",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98305",
    city: "Beaver",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98310",
    city: "Bremerton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98311",
    city: "Bremerton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98312",
    city: "Bremerton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98314",
    city: "Bremerton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98315",
    city: "Silverdale",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98320",
    city: "Brinnon",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98321",
    city: "Buckley",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98322",
    city: "Burley",
    state: "WA",
    areacode: 253,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98323",
    city: "Carbonado",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98324",
    city: "Carlsborg",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98325",
    city: "Chimacum",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98326",
    city: "Clallam Bay",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98327",
    city: "Dupont",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98328",
    city: "Eatonville",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98329",
    city: "Gig Harbor",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98330",
    city: "Elbe",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98331",
    city: "Forks",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98332",
    city: "Gig Harbor",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98333",
    city: "Fox Island",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98335",
    city: "Gig Harbor",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98336",
    city: "Glenoma",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98337",
    city: "Bremerton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98338",
    city: "Graham",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98339",
    city: "Port Hadlock",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98340",
    city: "Hansville",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98342",
    city: "Indianola",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98343",
    city: "Joyce",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98344",
    city: "Kapowsin",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98345",
    city: "Keyport",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98346",
    city: "Kingston",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98348",
    city: "La Grande",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98349",
    city: "Lakebay",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98350",
    city: "La Push",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98351",
    city: "Longbranch",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98352",
    city: "Sumner",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98353",
    city: "Manchester",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98354",
    city: "Milton",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98355",
    city: "Mineral",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98356",
    city: "Morton",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98357",
    city: "Neah Bay",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98358",
    city: "Nordland",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98359",
    city: "Olalla",
    state: "WA",
    areacode: 253,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98360",
    city: "Orting",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98361",
    city: "Packwood",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98362",
    city: "Port Angeles",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98363",
    city: "Port Angeles",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98364",
    city: "Port Gamble",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98365",
    city: "Port Ludlow",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98366",
    city: "Port Orchard",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98367",
    city: "Port Orchard",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98368",
    city: "Port Townsend",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98370",
    city: "Poulsbo",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98371",
    city: "Puyallup",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98372",
    city: "Puyallup",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98373",
    city: "Puyallup",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98374",
    city: "Puyallup",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98375",
    city: "Puyallup",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98376",
    city: "Quilcene",
    state: "WA",
    areacode: 360,
    county: "Jefferson",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98377",
    city: "Randle",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98378",
    city: "Retsil",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98380",
    city: "Seabeck",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98381",
    city: "Sekiu",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98382",
    city: "Sequim",
    state: "WA",
    areacode: 360,
    county: "Clallam",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98383",
    city: "Silverdale",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98384",
    city: "South Colby",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98385",
    city: "South Prairie",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98386",
    city: "Southworth",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98387",
    city: "Spanaway",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98388",
    city: "Steilacoom",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98390",
    city: "Sumner",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98391",
    city: "Bonney Lake",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98392",
    city: "Suquamish",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98393",
    city: "Tracyton",
    state: "WA",
    areacode: 360,
    county: "Kitsap",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98394",
    city: "Vaughn",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98395",
    city: "Wauna",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98396",
    city: "Wilkeson",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98397",
    city: "Longmire",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98398",
    city: "Paradise Inn",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98401",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98402",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98403",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98404",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98405",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98406",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98407",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98408",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98409",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98411",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98412",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98413",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98415",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98416",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98417",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98418",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98419",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98421",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98422",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98424",
    city: "Fife",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98430",
    city: "Camp Murray",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98431",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98433",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98438",
    city: "McChord AFB",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98439",
    city: "Lakewood",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98443",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98444",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98445",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98446",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98447",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98448",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98464",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98465",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98466",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98467",
    city: "University Place",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98471",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98481",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98490",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98493",
    city: "Tacoma",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98496",
    city: "Lakewood",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98497",
    city: "Lakewood",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98498",
    city: "Lakewood",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98499",
    city: "Lakewood",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98501",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98502",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98503",
    city: "Lacey",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98504",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98505",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98506",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98507",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98508",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98509",
    city: "Lacey",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98511",
    city: "Tumwater",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98512",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98513",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98516",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98520",
    city: "Aberdeen",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98522",
    city: "Adna",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98524",
    city: "Allyn",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98526",
    city: "Amanda Park",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98527",
    city: "Bay Center",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98528",
    city: "Belfair",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98530",
    city: "Bucoda",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98531",
    city: "Centralia",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98532",
    city: "Chehalis",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98533",
    city: "Cinebar",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98535",
    city: "Copalis Beach",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98536",
    city: "Copalis Crossing",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98537",
    city: "Cosmopolis",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98538",
    city: "Curtis",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98539",
    city: "Doty",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98540",
    city: "East Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98541",
    city: "Elma",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98542",
    city: "Ethel",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98544",
    city: "Galvin",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98546",
    city: "Grapeview",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98547",
    city: "Grayland",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98548",
    city: "Hoodsport",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98550",
    city: "Hoquiam",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98552",
    city: "Humptulips",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98554",
    city: "Lebam",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98555",
    city: "Lilliwaup",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98556",
    city: "Littlerock",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98557",
    city: "McCleary",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98558",
    city: "McKenna",
    state: "WA",
    areacode: 360,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98559",
    city: "Malone",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98560",
    city: "Matlock",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98561",
    city: "Menlo",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98562",
    city: "Moclips",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98563",
    city: "Montesano",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98564",
    city: "Mossyrock",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98565",
    city: "Napavine",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98566",
    city: "Neilton",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98568",
    city: "Oakville",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98569",
    city: "Ocean Shores",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98570",
    city: "Onalaska",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98571",
    city: "Pacific Beach",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98572",
    city: "Pe Ell",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98575",
    city: "Quinault",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98576",
    city: "Rainier",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98577",
    city: "Raymond",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98579",
    city: "Rochester",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98580",
    city: "Roy",
    state: "WA",
    areacode: 253,
    county: "Pierce",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98581",
    city: "Ryderwood",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98582",
    city: "Salkum",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98583",
    city: "Satsop",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98584",
    city: "Shelton",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98585",
    city: "Silver Creek",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98586",
    city: "South Bend",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98587",
    city: "Taholah",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98588",
    city: "Tahuya",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98589",
    city: "Tenino",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98590",
    city: "Tokeland",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98591",
    city: "Toledo",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98592",
    city: "Union",
    state: "WA",
    areacode: 360,
    county: "Mason",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98593",
    city: "Vader",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98595",
    city: "Westport",
    state: "WA",
    areacode: 360,
    county: "Grays Harbor",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98596",
    city: "Winlock",
    state: "WA",
    areacode: 360,
    county: "Lewis",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98597",
    city: "Yelm",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98599",
    city: "Olympia",
    state: "WA",
    areacode: 360,
    county: "Thurston",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98601",
    city: "Amboy",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98602",
    city: "Appleton",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98603",
    city: "Ariel",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98604",
    city: "Battle Ground",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98605",
    city: "Bingen",
    state: "WA",
    areacode: 509,
    county: "Skamania",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98606",
    city: "Brush Prairie",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98607",
    city: "Camas",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98609",
    city: "Carrolls",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98610",
    city: "Carson",
    state: "WA",
    areacode: 509,
    county: "Skamania",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98611",
    city: "Castle Rock",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98612",
    city: "Cathlamet",
    state: "WA",
    areacode: 360,
    county: "Wahkiakum",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98613",
    city: "Centerville",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98614",
    city: "Chinook",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98616",
    city: "Cougar",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98617",
    city: "Dallesport",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98619",
    city: "Glenwood",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98620",
    city: "Goldendale",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98621",
    city: "Grays River",
    state: "WA",
    areacode: 360,
    county: "Wahkiakum",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98622",
    city: "Heisson",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98623",
    city: "Husum",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98624",
    city: "Ilwaco",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98625",
    city: "Kalama",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98626",
    city: "Kelso",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98628",
    city: "Klickitat",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98629",
    city: "La Center",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98631",
    city: "Long Beach",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98632",
    city: "Longview",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98635",
    city: "Lyle",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98637",
    city: "Nahcotta",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98638",
    city: "Naselle",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98639",
    city: "North Bonneville",
    state: "WA",
    areacode: 509,
    county: "Skamania",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98640",
    city: "Ocean Park",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98641",
    city: "Oysterville",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98642",
    city: "Ridgefield",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98643",
    city: "Rosburg",
    state: "WA",
    areacode: 360,
    county: "Wahkiakum",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98644",
    city: "Seaview",
    state: "WA",
    areacode: 360,
    county: "Pacific",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98645",
    city: "Silverlake",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98647",
    city: "Skamokawa",
    state: "WA",
    areacode: 360,
    county: "Wahkiakum",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98648",
    city: "Stevenson",
    state: "WA",
    areacode: 509,
    county: "Skamania",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98649",
    city: "Toutle",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98650",
    city: "Trout Lake",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98651",
    city: "Underwood",
    state: "WA",
    areacode: 509,
    county: "Skamania",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98660",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98661",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98662",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98663",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98664",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98665",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98666",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98668",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98670",
    city: "Wahkiacus",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98671",
    city: "Washougal",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98672",
    city: "White Salmon",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98673",
    city: "Wishram",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98674",
    city: "Woodland",
    state: "WA",
    areacode: 360,
    county: "Cowlitz",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98675",
    city: "Yacolt",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98682",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98683",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98684",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98685",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98686",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98687",
    city: "Vancouver",
    state: "WA",
    areacode: 360,
    county: "Clark",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98801",
    city: "Wenatchee",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98802",
    city: "East Wenatchee",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98807",
    city: "Wenatchee",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98811",
    city: "Ardenvoir",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98812",
    city: "Brewster",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98813",
    city: "Bridgeport",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98814",
    city: "Carlton",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98815",
    city: "Cashmere",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98816",
    city: "Chelan",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98817",
    city: "Chelan Falls",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98819",
    city: "Conconully",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98821",
    city: "Dryden",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98822",
    city: "Entiat",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98823",
    city: "Ephrata",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98824",
    city: "George",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98826",
    city: "Leavenworth",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98827",
    city: "Loomis",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98828",
    city: "Malaga",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98829",
    city: "Malott",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98830",
    city: "Mansfield",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98831",
    city: "Manson",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98832",
    city: "Marlin",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98833",
    city: "Mazama",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98834",
    city: "Methow",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98836",
    city: "Monitor",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98837",
    city: "Moses Lake",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98840",
    city: "Okanogan",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98841",
    city: "Omak",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98843",
    city: "Orondo",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98844",
    city: "Oroville",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98845",
    city: "Palisades",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98846",
    city: "Pateros",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98847",
    city: "Peshastin",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98848",
    city: "Quincy",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98849",
    city: "Riverside",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98850",
    city: "Rock Island",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98851",
    city: "Soap Lake",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98852",
    city: "Stehekin",
    state: "WA",
    areacode: 509,
    county: "Chelan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98853",
    city: "Stratford",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98855",
    city: "Tonasket",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98856",
    city: "Twisp",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98857",
    city: "Warden",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98858",
    city: "Waterville",
    state: "WA",
    areacode: 509,
    county: "Douglas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98859",
    city: "Wauconda",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98860",
    city: "Wilson Creek",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98862",
    city: "Winthrop",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98901",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98902",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98903",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98904",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98907",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98908",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98909",
    city: "Yakima",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98920",
    city: "Brownstown",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98921",
    city: "Buena",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98922",
    city: "Cle Elum",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98923",
    city: "Cowiche",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98925",
    city: "Easton",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98926",
    city: "Ellensburg",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98930",
    city: "Grandview",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98932",
    city: "Granger",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98933",
    city: "Harrah",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98934",
    city: "Kittitas",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98935",
    city: "Mabton",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98936",
    city: "Moxee",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98937",
    city: "Naches",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98938",
    city: "Outlook",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98939",
    city: "Parker",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98940",
    city: "Ronald",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98941",
    city: "Roslyn",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98942",
    city: "Selah",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98943",
    city: "South Cle Elum",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98944",
    city: "Sunnyside",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98946",
    city: "Thorp",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98947",
    city: "Tieton",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98948",
    city: "Toppenish",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98950",
    city: "Vantage",
    state: "WA",
    areacode: 509,
    county: "Kittitas",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98951",
    city: "Wapato",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98952",
    city: "White Swan",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "98953",
    city: "Zillah",
    state: "WA",
    areacode: 509,
    county: "Yakima",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99001",
    city: "Airway Heights",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99003",
    city: "Chattaroy",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99004",
    city: "Cheney",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99005",
    city: "Colbert",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99006",
    city: "Deer Park",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99008",
    city: "Edwall",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99009",
    city: "Elk",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99011",
    city: "Fairchild AFB",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99012",
    city: "Fairfield",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99013",
    city: "Ford",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99014",
    city: "Four Lakes",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99016",
    city: "Greenacres",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99017",
    city: "Lamont",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99018",
    city: "Latah",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99019",
    city: "Liberty Lake",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99020",
    city: "Marshall",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99021",
    city: "Mead",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99022",
    city: "Medical Lake",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99023",
    city: "Mica",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99025",
    city: "Newman Lake",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99026",
    city: "Nine Mile Falls",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99027",
    city: "Otis Orchards",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99029",
    city: "Reardan",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99030",
    city: "Rockford",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99031",
    city: "Spangle",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99032",
    city: "Sprague",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99033",
    city: "Tekoa",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99034",
    city: "Tumtum",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99036",
    city: "Valleyford",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99037",
    city: "Veradale",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99039",
    city: "Waverly",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99040",
    city: "Wellpinit",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99101",
    city: "Addy",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99102",
    city: "Albion",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99103",
    city: "Almira",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99104",
    city: "Belmont",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99105",
    city: "Benge",
    state: "WA",
    areacode: 509,
    county: "Adams",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99109",
    city: "Chewelah",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99110",
    city: "Clayton",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99111",
    city: "Colfax",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99113",
    city: "Colton",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99114",
    city: "Colville",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99115",
    city: "Coulee City",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99116",
    city: "Coulee Dam",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99117",
    city: "Creston",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99118",
    city: "Curlew",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99119",
    city: "Cusick",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99121",
    city: "Danville",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99122",
    city: "Davenport",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99123",
    city: "Electric City",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99124",
    city: "Elmer City",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99125",
    city: "Endicott",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99126",
    city: "Evans",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99128",
    city: "Farmington",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99129",
    city: "Fruitland",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99130",
    city: "Garfield",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99131",
    city: "Gifford",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99133",
    city: "Grand Coulee",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99134",
    city: "Harrington",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99135",
    city: "Hartline",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99136",
    city: "Hay",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99137",
    city: "Hunters",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99138",
    city: "Inchelium",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99139",
    city: "Ione",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99140",
    city: "Keller",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99141",
    city: "Kettle Falls",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99143",
    city: "Lacrosse",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99144",
    city: "Lamona",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99146",
    city: "Laurier",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99147",
    city: "Lincoln",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99148",
    city: "Loon Lake",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99149",
    city: "Malden",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99150",
    city: "Malo",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99151",
    city: "Marcus",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99152",
    city: "Metaline",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99153",
    city: "Metaline Falls",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99154",
    city: "Mohler",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99155",
    city: "Nespelem",
    state: "WA",
    areacode: 509,
    county: "Okanogan",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99156",
    city: "Newport",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99157",
    city: "Northport",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99158",
    city: "Oakesdale",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99159",
    city: "Odessa",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99160",
    city: "Orient",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99161",
    city: "Palouse",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99163",
    city: "Pullman",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99164",
    city: "Pullman",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99166",
    city: "Republic",
    state: "WA",
    areacode: 509,
    county: "Ferry",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99167",
    city: "Rice",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99169",
    city: "Ritzville",
    state: "WA",
    areacode: 509,
    county: "Adams",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99170",
    city: "Rosalia",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99171",
    city: "Saint John",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99173",
    city: "Springdale",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99174",
    city: "Steptoe",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99176",
    city: "Thornton",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99179",
    city: "Uniontown",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99180",
    city: "Usk",
    state: "WA",
    areacode: 509,
    county: "Pend Oreille",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99181",
    city: "Valley",
    state: "WA",
    areacode: 509,
    county: "Stevens",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99185",
    city: "Wilbur",
    state: "WA",
    areacode: 509,
    county: "Lincoln",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99201",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99202",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99203",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99204",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99205",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99206",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99207",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99208",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99209",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99210",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99211",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99212",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99213",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99214",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99215",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99216",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99217",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99218",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99219",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99220",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99223",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99224",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99228",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99251",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99252",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99256",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99258",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99260",
    city: "Spokane",
    state: "WA",
    areacode: 509,
    county: "Spokane",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99301",
    city: "Pasco",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99302",
    city: "Pasco",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99320",
    city: "Benton City",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99321",
    city: "Beverly",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99322",
    city: "Bickleton",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99323",
    city: "Burbank",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99324",
    city: "College Place",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99326",
    city: "Connell",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99328",
    city: "Dayton",
    state: "WA",
    areacode: 509,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99329",
    city: "Dixie",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99330",
    city: "Eltopia",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99333",
    city: "Hooper",
    state: "WA",
    areacode: 509,
    county: "Whitman",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99335",
    city: "Kahlotus",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99336",
    city: "Kennewick",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99337",
    city: "Kennewick",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99338",
    city: "Kennewick",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99341",
    city: "Lind",
    state: "WA",
    areacode: 509,
    county: "Adams",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99343",
    city: "Mesa",
    state: "WA",
    areacode: 509,
    county: "Franklin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99344",
    city: "Othello",
    state: "WA",
    areacode: 509,
    county: "Adams",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99345",
    city: "Paterson",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99346",
    city: "Plymouth",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99347",
    city: "Pomeroy",
    state: "WA",
    areacode: 509,
    county: "Garfield",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99348",
    city: "Prescott",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99349",
    city: "Mattawa",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99350",
    city: "Prosser",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99352",
    city: "Richland",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99353",
    city: "West Richland",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99354",
    city: "Richland",
    state: "WA",
    areacode: 509,
    county: "Benton",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99356",
    city: "Roosevelt",
    state: "WA",
    areacode: 509,
    county: "Klickitat",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99357",
    city: "Royal City",
    state: "WA",
    areacode: 509,
    county: "Grant",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99359",
    city: "Starbuck",
    state: "WA",
    areacode: 509,
    county: "Columbia",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99360",
    city: "Touchet",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99361",
    city: "Waitsburg",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99362",
    city: "Walla Walla",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99363",
    city: "Wallula",
    state: "WA",
    areacode: 509,
    county: "Walla Walla",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99371",
    city: "Washtucna",
    state: "WA",
    areacode: 509,
    county: "Adams",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99401",
    city: "Anatone",
    state: "WA",
    areacode: 509,
    county: "Asotin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99402",
    city: "Asotin",
    state: "WA",
    areacode: 509,
    county: "Asotin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99403",
    city: "Clarkston",
    state: "WA",
    areacode: 509,
    county: "Asotin",
    timezone3: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    zipcode: "99501",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99502",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99503",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99504",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99505",
    city: "JBER",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99506",
    city: "JBER",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99507",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99508",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99509",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99510",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99511",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99513",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99514",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99515",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99516",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99517",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99518",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99519",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99520",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99521",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99522",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99523",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99524",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99529",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99530",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99540",
    city: "Indian",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99545",
    city: "Kongiganak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99546",
    city: "Adak",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "99547",
    city: "Atka",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "99548",
    city: "Chignik Lake",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99549",
    city: "Port Heiden",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99550",
    city: "Port Lions",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99551",
    city: "Akiachak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99552",
    city: "Akiak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99553",
    city: "Akutan",
    state: "AK",
    areacode: 907,
    county: "Aleutians East",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99554",
    city: "Alakanuk",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99555",
    city: "Aleknagik",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99556",
    city: "Anchor Point",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99557",
    city: "Aniak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99558",
    city: "Anvik",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99559",
    city: "Bethel",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99561",
    city: "Chefornak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99563",
    city: "Chevak",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99564",
    city: "Chignik",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99565",
    city: "Chignik Lagoon",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99566",
    city: "Chitina",
    state: "AK",
    areacode: 907,
    county: "Copper River",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99567",
    city: "Chugiak",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99568",
    city: "Clam Gulch",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99569",
    city: "Clarks Point",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99571",
    city: "Cold Bay",
    state: "AK",
    areacode: 907,
    county: "Aleutians East",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99572",
    city: "Cooper Landing",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99573",
    city: "Copper Center",
    state: "AK",
    areacode: 907,
    county: "Copper River",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99574",
    city: "Cordova",
    state: "AK",
    areacode: 907,
    county: "Chugach",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99575",
    city: "Crooked Creek",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99576",
    city: "Dillingham",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99577",
    city: "Eagle River",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99578",
    city: "Eek",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99579",
    city: "Egegik",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99580",
    city: "Ekwok",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99581",
    city: "Emmonak",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99583",
    city: "False Pass",
    state: "AK",
    areacode: 907,
    county: "Aleutians East",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99585",
    city: "Marshall",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99586",
    city: "Gakona",
    state: "AK",
    areacode: 907,
    county: "Copper River",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99587",
    city: "Girdwood",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99588",
    city: "Glennallen",
    state: "AK",
    areacode: 907,
    county: "Copper River",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99589",
    city: "Goodnews Bay",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99590",
    city: "Grayling",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99591",
    city: "Saint George Island",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "99599",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99602",
    city: "Holy Cross",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99603",
    city: "Homer",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99604",
    city: "Hooper Bay",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99605",
    city: "Hope",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99606",
    city: "Iliamna",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99607",
    city: "Kalskag",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99608",
    city: "Karluk",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99609",
    city: "Kasigluk",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99610",
    city: "Kasilof",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99611",
    city: "Kenai",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99612",
    city: "King Cove",
    state: "AK",
    areacode: 907,
    county: "Aleutians East",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99613",
    city: "King Salmon",
    state: "AK",
    areacode: 907,
    county: "Bristol Bay",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99614",
    city: "Kipnuk",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99615",
    city: "Kodiak",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99619",
    city: "Kodiak",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99620",
    city: "Kotlik",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99621",
    city: "Kwethluk",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99622",
    city: "Kwigillingok",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99623",
    city: "Wasilla",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99624",
    city: "Larsen Bay",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99625",
    city: "Levelock",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99626",
    city: "Lower Kalskag",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99627",
    city: "McGrath",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99628",
    city: "Manokotak",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99629",
    city: "Wasilla",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99630",
    city: "Mekoryuk",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99631",
    city: "Moose Pass",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99632",
    city: "Mountain Village",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99633",
    city: "Naknek",
    state: "AK",
    areacode: 907,
    county: "Bristol Bay",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99634",
    city: "Napakiak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99635",
    city: "Nikiski",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99636",
    city: "New Stuyahok",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99637",
    city: "Toksook Bay",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99638",
    city: "Nikolski",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99639",
    city: "Ninilchik",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99640",
    city: "Nondalton",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99641",
    city: "Nunapitchuk",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99643",
    city: "Old Harbor",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99644",
    city: "Ouzinkie",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99645",
    city: "Palmer",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99647",
    city: "Pedro Bay",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99648",
    city: "Perryville",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99649",
    city: "Pilot Point",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99650",
    city: "Pilot Station",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99651",
    city: "Platinum",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99652",
    city: "Big Lake",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99653",
    city: "Port Alsworth",
    state: "AK",
    areacode: 907,
    county: "Lake and Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99654",
    city: "Wasilla",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99655",
    city: "Quinhagak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99656",
    city: "Red Devil",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99657",
    city: "Russian Mission",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99658",
    city: "Saint Marys",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99659",
    city: "Saint Michael",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99660",
    city: "Saint Paul Island",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-2",
    timezone: "Pacific/Honolulu",
  },
  {
    zipcode: "99661",
    city: "Sand Point",
    state: "AK",
    areacode: 907,
    county: "Aleutians East",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99662",
    city: "Scammon Bay",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99663",
    city: "Seldovia",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99664",
    city: "Seward",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99665",
    city: "Shageluk",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99666",
    city: "Nunam Iqua",
    state: "AK",
    areacode: 907,
    county: "Kusilvak",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99667",
    city: "Skwentna",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99668",
    city: "Sleetmute",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99669",
    city: "Soldotna",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99670",
    city: "South Naknek",
    state: "AK",
    areacode: 907,
    county: "Bristol Bay",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99671",
    city: "Stebbins",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99672",
    city: "Sterling",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99674",
    city: "Sutton",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99675",
    city: "Takotna",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99676",
    city: "Talkeetna",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99677",
    city: "Tatitlek",
    state: "AK",
    areacode: 907,
    county: "Chugach",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99678",
    city: "Togiak",
    state: "AK",
    areacode: 907,
    county: "Dillingham",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99679",
    city: "Tuluksak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99680",
    city: "Tuntutuliak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99681",
    city: "Tununak",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99682",
    city: "Tyonek",
    state: "AK",
    areacode: 907,
    county: "Kenai Peninsula",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99683",
    city: "Trapper Creek",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99684",
    city: "Unalakleet",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99685",
    city: "Unalaska",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99686",
    city: "Valdez",
    state: "AK",
    areacode: 907,
    county: "Chugach",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99687",
    city: "Wasilla",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99688",
    city: "Willow",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99689",
    city: "Yakutat",
    state: "AK",
    areacode: 907,
    county: "Yakutat",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99690",
    city: "Nightmute",
    state: "AK",
    areacode: 907,
    county: "Bethel",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99691",
    city: "Nikolai",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99692",
    city: "Dutch Harbor",
    state: "AK",
    areacode: 907,
    county: "Aleutians West",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99693",
    city: "Whittier",
    state: "AK",
    areacode: 907,
    county: "Chugach",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99694",
    city: "Houston",
    state: "AK",
    areacode: 907,
    county: "Matanuska-Susitna",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99695",
    city: "Anchorage",
    state: "AK",
    areacode: 907,
    county: "Anchorage",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99697",
    city: "Kodiak",
    state: "AK",
    areacode: 907,
    county: "Kodiak Island",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99701",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99702",
    city: "Eielson AFB",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99703",
    city: "Fort Wainwright",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99704",
    city: "Clear",
    state: "AK",
    areacode: 907,
    county: "Denali",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99705",
    city: "North Pole",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99706",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99707",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99708",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99709",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99710",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99711",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99712",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99714",
    city: "Salcha",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99716",
    city: "Two Rivers",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99720",
    city: "Allakaket",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99721",
    city: "Anaktuvuk Pass",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99722",
    city: "Arctic Village",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99723",
    city: "Barrow",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99724",
    city: "Beaver",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99725",
    city: "Ester",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99726",
    city: "Bettles Field",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99727",
    city: "Buckland",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99729",
    city: "Cantwell",
    state: "AK",
    areacode: 907,
    county: "Denali",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99730",
    city: "Central",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99731",
    city: "Fort Greely",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99732",
    city: "Chicken",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99733",
    city: "Circle",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99734",
    city: "Prudhoe Bay",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99736",
    city: "Deering",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99737",
    city: "Delta Junction",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99738",
    city: "Eagle",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99739",
    city: "Elim",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99740",
    city: "Fort Yukon",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99741",
    city: "Galena",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99742",
    city: "Gambell",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99743",
    city: "Healy",
    state: "AK",
    areacode: 907,
    county: "Denali",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99744",
    city: "Anderson",
    state: "AK",
    areacode: 907,
    county: "Denali",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99745",
    city: "Hughes",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99746",
    city: "Huslia",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99747",
    city: "Kaktovik",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99748",
    city: "Kaltag",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99749",
    city: "Kiana",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99750",
    city: "Kivalina",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99751",
    city: "Kobuk",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99752",
    city: "Kotzebue",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99753",
    city: "Koyuk",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99754",
    city: "Koyukuk",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99755",
    city: "Denali National Park",
    state: "AK",
    areacode: 907,
    county: "Denali",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99756",
    city: "Manley Hot Springs",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99757",
    city: "Lake Minchumina",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99758",
    city: "Minto",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99759",
    city: "Point Lay",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99760",
    city: "Nenana",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99761",
    city: "Noatak",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99762",
    city: "Nome",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99763",
    city: "Noorvik",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99764",
    city: "Northway",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99765",
    city: "Nulato",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99766",
    city: "Point Hope",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99767",
    city: "Rampart",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99768",
    city: "Ruby",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99769",
    city: "Savoonga",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99770",
    city: "Selawik",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99771",
    city: "Shaktoolik",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99772",
    city: "Shishmaref",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99773",
    city: "Shungnak",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99774",
    city: "Stevens Village",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99775",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99776",
    city: "Tanacross",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99777",
    city: "Tanana",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99778",
    city: "Teller",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99780",
    city: "Tok",
    state: "AK",
    areacode: 907,
    county: "Southeast Fairbanks",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99781",
    city: "Venetie",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99782",
    city: "Wainwright",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99783",
    city: "Wales",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99784",
    city: "White Mountain",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99785",
    city: "Brevig Mission",
    state: "AK",
    areacode: 907,
    county: "Nome",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99786",
    city: "Ambler",
    state: "AK",
    areacode: 907,
    county: "Northwest Arctic",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99788",
    city: "Chalkyitsik",
    state: "AK",
    areacode: 907,
    county: "Yukon Koyukuk",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99789",
    city: "Nuiqsut",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99790",
    city: "Fairbanks",
    state: "AK",
    areacode: 907,
    county: "Fairbanks North Star",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99791",
    city: "Atqasuk",
    state: "AK",
    areacode: 907,
    county: "North Slope",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99801",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99802",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99803",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99811",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99812",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99820",
    city: "Angoon",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99821",
    city: "Auke Bay",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99824",
    city: "Douglas",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99825",
    city: "Elfin Cove",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99826",
    city: "Gustavus",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99827",
    city: "Haines",
    state: "AK",
    areacode: 907,
    county: "Haines",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99829",
    city: "Hoonah",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99830",
    city: "Kake",
    state: "AK",
    areacode: 907,
    county: "Petersburg",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99832",
    city: "Pelican",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99833",
    city: "Petersburg",
    state: "AK",
    areacode: 907,
    county: "Petersburg",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99835",
    city: "Sitka",
    state: "AK",
    areacode: 907,
    county: "Sitka",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99836",
    city: "Port Alexander",
    state: "AK",
    areacode: 907,
    county: "Petersburg",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99840",
    city: "Skagway",
    state: "AK",
    areacode: 907,
    county: "Skagway",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99841",
    city: "Tenakee Springs",
    state: "AK",
    areacode: 907,
    county: "Hoonah Angoon",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99850",
    city: "Juneau",
    state: "AK",
    areacode: 907,
    county: "Juneau",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99901",
    city: "Ketchikan",
    state: "AK",
    areacode: 907,
    county: "Ketchikan Gateway",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99903",
    city: "Meyers Chuck",
    state: "AK",
    areacode: 907,
    county: "Wrangell",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99918",
    city: "Coffman Cove",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99919",
    city: "Thorne Bay",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99921",
    city: "Craig",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99922",
    city: "Hydaburg",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99923",
    city: "Hyder",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99925",
    city: "Klawock",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99926",
    city: "Metlakatla",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99927",
    city: "Point Baker",
    state: "AK",
    areacode: 907,
    county: "Prince of Wales Hyder",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99928",
    city: "Ward Cove",
    state: "AK",
    areacode: 907,
    county: "Ketchikan Gateway",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99929",
    city: "Wrangell",
    state: "AK",
    areacode: 907,
    county: "Wrangell",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
  {
    zipcode: "99950",
    city: "Ketchikan",
    state: "AK",
    areacode: 907,
    county: "Ketchikan Gateway",
    timezone3: "PST-1",
    timezone: "US/Alaska",
  },
];
