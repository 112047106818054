import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";

export default function EditUser({ open, onClose, user, refreshUsers }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRoles = async () => {
    try {
      const response = await customFetch(`/v1/partners/${user.partner_id}/roles`);
      const data = await response.json();
      setRoles(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch roles:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchRoles();
    }
  }, [open]);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setRoleId(user.role_id || "");
    }
  }, [user]);

  const handleSubmit = async () => {
    try {
      await customFetch(`/v1/partners/${user.partner_id}/users/${user.id}`, {
        method: "PUT",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          role_id: roleId,
        }),
        headers: { "Content-Type": "application/json" },
      });
      refreshUsers();
      handleClose();
    } catch (error) {
      console.error("Failed to update user:", error.message);
    }
  };

  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setRoleId("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Partner User</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          value={user?.email || ""}
          disabled
          margin="normal"
        />
        <TextField
          select
          fullWidth
          label="Role"
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
          margin="normal"
          disabled={loading || roles.length === 0}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
