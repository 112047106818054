import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import customFetch from '../../../../general/auth/customFetch';
import moment from 'moment-timezone';
import decode from '../../../../general/util/jwtDecode';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const usTimezones = moment.tz
  .names()
  .filter((tz) => tz.startsWith("US/"))
  .map((tz) => {
    const offset = moment.tz(tz).format("Z");
    return {
      label: `${tz.replace("US/", "")} (UTC${offset})`,
      value: tz,
    };
  });

const ScheduleDialog = ({ open, onClose, campaignId, schedule, onSave, onDelete }) => {
  const currentUser = decode();
  const [startTime, setStartTime] = useState(schedule ? schedule.start_time : '');
  const [endTime, setEndTime] = useState(schedule ? schedule.end_time : '');
  const [activeDays, setActiveDays] = useState(schedule ? schedule.active_days : []);
  const [timeZone, setTimeZone] = useState(schedule ? schedule.time_zone : '');


  const handleSave = () => {
    const newSchedule = { start_time: startTime, end_time: endTime, active_days: activeDays, time_zone: timeZone };
    
    if (schedule) {
      // Update existing schedule
      customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${campaignId}/schedules/${schedule.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSchedule),
      })
        .then((response) => response.json())
        .then((data) => {
          onSave(data);
        })
        .catch((error) => console.error('Error updating schedule:', error));
    } else {
      // Create new schedule
      customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${campaignId}/schedules`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSchedule),
      })
        .then((response) => response.json())
        .then((data) => {
          onSave(data);
        })
        .catch((error) => console.error('Error creating schedule:', error));
    }
  };

  const handleDelete = () => {
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${campaignId}/schedules/${schedule.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        onDelete();
      })
      .catch((error) => console.error('Error deleting schedule:', error));
  };

  const handleDayChange = (day) => {
    setActiveDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{schedule ? 'Edit Schedule' : 'Create Schedule'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Start Time"
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Time"
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <FormGroup>
          {daysOfWeek.map((day) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={activeDays.includes(day)}
                  onChange={() => handleDayChange(day)}
                />
              }
              label={day}
              key={day}
            />
          ))}
        </FormGroup>
        <FormControl fullWidth margin="normal">
          <InputLabel>Time Zone</InputLabel>
          <Select
            value={timeZone}
            label="Time Zone"
            onChange={(e) => setTimeZone(e.target.value)}
          >
            {usTimezones.map((tz) => (
              <MenuItem key={tz.value} value={tz.value}>
                {tz.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {schedule && (
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;
