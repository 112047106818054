import React from "react";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { UpdateBranding } from "../../../../general/components/branding/UpdateBranding";

const UpdateTenantBranding = ({ open, onClose, tenant, fetchTenants }) => {
  const currentUser = decode();

  const handleSubmit = async (formData) => {
    const response = await customFetch(
      `/v1/partners/${currentUser.partner_id}/tenants/${tenant.id}/branding`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    await fetchTenants();
  };

  return (
    <UpdateBranding
      open={open}
      onClose={onClose}
      title="Update Tenant Branding"
      initialData={tenant}
      onSubmit={handleSubmit}
      submitButtonText="Update Branding"
    />
  );
};

export default UpdateTenantBranding;