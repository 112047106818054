import jwtDecode from "jwt-decode";
const secret = process.env.REACT_APP_ACCESS_TOKEN_SECRET;

export default function decode() {
  const jwtCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("accessToken="));

  const refreshCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("refreshToken="));

  if (!jwtCookie) {
    window.location = `/login`;
  }

  const jwtToken = jwtCookie.split("=")[1];
  let refreshToken = jwtToken;
  if (refreshCookie) {
    refreshToken = refreshCookie.split("=")[1];
  }

  try {
    const decoded = jwtDecode(jwtToken, secret);
    decoded.accessToken = jwtToken;
    decoded.refreshToken = refreshToken;
    return decoded;
  } catch (error) {
    return error;
  }
}
