import React, { useState, useEffect } from "react";
import { Typography, Button, Tooltip, Box } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditOrder from "./components/EditOrder";
import DeleteOrder from "./components/DeleteOrder";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CreateProductOrder from "./components/CreateProductOrder";
import { PARTNER_ADMIN_ROLE_NAME } from "../../../general/constants/constants";


export default function PartnerAdminOrders() {
  const currentUser = decode();
  const [orders, setOrders] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    lead_count: false,
    formattedStartDate: false,

  });

  const applyRunningFilter = () => {
    setFilterModel({
      items: [
        {
          field: "order_status",
          operator: "equals",
          value: "running",
        },
      ],
    });
  };

  function CustomToolbar({ applyRunningFilter }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Button
          onClick={applyRunningFilter}
          startIcon={<PlayCircleOutlineIcon />}
          color="primary"
        >
          Running Only
        </Button>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    if (currentUser && currentUser.partner_role !== PARTNER_ADMIN_ROLE_NAME) {
      window.location = "/login";
      return;
    }
    await Promise.all([fetchTenantsAsync()]);
    await fetchProductCatalogAndOrders();
  };

  const fetchTenantsAsync = async () => {
    const response = await customFetch(
      `/v1/partners/${currentUser.partner_id}/tenants`
    );
    const list = await response.json();
    setTenantsList(list);
  };

  const fetchProductCatalogAndOrders = async () => {
    const productCatalogResponse = await customFetch(
      `/v1/partners/${currentUser.partner_id}/product-catalog`,
      { method: "GET" }
    );
    const productCatalogData = await productCatalogResponse.json();
    setProductCatalog(productCatalogData);

    const ordersResponse = await customFetch(
      `/v1/partners/${currentUser.partner_id}/orders`,
      { method: "GET" }
    );
    const ordersData = await ordersResponse.json();
    setOrders(
      ordersData.map((order) => {
        const product = productCatalogData.find(
          (p) => p.id === order.product_id
        );
        return {
          ...order,
          id: order.id,
          userName: `${order.first_name} ${order.last_name}`,
          formattedOrderDate: order.order_date
            ? new Date(order.order_date)
            : null,
          formattedStartDate: order.start_date
            ? new Date(order.start_date)
            : null,
          leadCount: `${order.lead_count - order.replacement_count} / ${
            order.num_leads
          } (after ${order.replacement_count} replaced)`,
          dailyCount: `${order.daily_count} / ${order.daily_cap}`,
          productName: product ? product.name : null,
          states: order.states || [],
        };
      })
    );
  };

  const updateOrderInState = (updatedOrder) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === updatedOrder.id
          ? {
              ...order,
              ...updatedOrder,
              states: updatedOrder.states,
              leadCount:
                `${
                  updatedOrder.lead_count - updatedOrder.replacement_count
                } / ${updatedOrder.num_leads} (after ${
                  updatedOrder.replacement_count
                } replaced)` || [],
            }
          : order
      )
    );
  };

  const addOrderToState = (newOrder) => {
    const product = productCatalog.find((p) => p.id === newOrder.product_id);
    const transformedOrder = {
      ...newOrder,
      userName: `${newOrder.first_name} ${newOrder.last_name}`,
      formattedOrderDate: newOrder.order_date
        ? new Date(newOrder.order_date)
        : null,
      formattedStartDate: newOrder.start_date
        ? new Date(newOrder.start_date)
        : null,
      leadCount: `${newOrder.lead_count - newOrder.replacement_count} / ${
        newOrder.num_leads
      } (after ${newOrder.replacement_count} replaced)`,
      dailyCount: `${newOrder.daily_count} / ${newOrder.daily_cap}`,
      productName: product ? product.name : null,
      states: newOrder.states || [],
    };
    setOrders((prevOrders) => [transformedOrder, ...prevOrders]);
  };

  const removeOrderFromState = (orderId) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.id !== orderId)
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Batch ID",
      align: "left",
      headerAlign: "left",
      type: "number",
      flex: 0.6,
      renderCell: (params) => <div>{params.value}</div>,
    },
    { field: "tenant_name", headerName: "Tenant", flex: .8 },
    { field: "userName", headerName: "User Name", flex: 1.2 },
    {
      field: "formattedOrderDate",
      headerName: "Order Date",
      flex: 0.8,
      type: "date",
    },
    {
      field: "refTypeOrProductName",
      headerName: "Product",
      flex: 1,
      valueGetter: (params, row) => row.productName,
    },
    {
      field: "formattedStartDate",
      headerName: "Start Date",
      flex: 0.8,
      type: "date",
    },
    { field: "order_status", headerName: "Status", flex: 0.8 },
    {
      field: "lead_count",
      headerName: "Lead Count",
      align: "left",
      headerAlign: "left",
      type: "number",
      flex: 0.6,
    },
    {
      field: "leadCount",
      headerName: "Number of Leads",
      flex: 1.4,
      sortable: false,
      filterable: false,
    },
    {
      field: "dailyCount",
      headerName: "Daily Count",
      flex: 0.7,
      sortable: false,
      filterable: false,
    },
    {
      field: "states",
      headerName: "States",
      width: 115,
      renderCell: (params) => {
        const row = params.row;
        const states = row.states || [];
        const orderStates = states.join(", ");
        const stateLabel = states.length === 1 ? "state" : "states";
        return (
          <Tooltip title={orderStates} placement="left">
            <Button
              size="small"
              variant="outlined"
              disabled={states.length === 0}
            >
              {states.length > 0
                ? `${states.length} ${stateLabel}`
                : "No states"}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <EditOrder
              order={params.row}
              tenantsList={tenantsList}
              updateOrderInState={updateOrderInState}
            />
            <span> </span>
            <DeleteOrder
              order={params.row}
              removeOrderFromState={removeOrderFromState}
            />
          </>
        );
      },
    },
  ];

  const gridStateKey = `partner-admin-orders-grid-state-${currentUser.partner_id}`;

  const saveGridState = (state) => {
    localStorage.setItem(gridStateKey, JSON.stringify(state));
  };

  const loadGridState = () => {
    const savedState = localStorage.getItem(gridStateKey);
    return savedState ? JSON.parse(savedState) : null;
  };

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        Partner Admin Orders
      </Typography>
      <Box display="flex" justifyContent="space-between" margin={2}>
        <Box marginLeft={2}>
          <CreateProductOrder
            tenantsList={tenantsList}
            updateOrders={addOrderToState}
          />
        </Box>
      </Box>
      <br></br>
      <br></br>
      <div style={{ height: "700px", width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DataGridPro
            rows={orders}
            columns={columns}
            initialState={
              loadGridState() || {
                columns: {
                  columnVisibilityModel: columnVisibilityModel,
                },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }
            }
            onStateChange={(newState) => saveGridState(newState)}
            pageSizeOptions={[10, 25, 50]}
            pagination
            autoHeight
            disableSelectionOnClick
            headerFilters
            slots={{
              toolbar: () => (
                <CustomToolbar applyRunningFilter={applyRunningFilter} />
              ),
              headerFilterMenu: null,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
