import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { GROUP_ADMIN_ROLE_NAME } from "../../../../general/constants/constants";

export default function DeleteOrder({ order, removeOrderFromState }) {
  const [show, setShow] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const currentUser = decode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  const deleteOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/orders/${order.id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        removeOrderFromState(order.id);
        setSnackbar({
          open: true,
          message: "Order deleted successfully",
          severity: "success",
        });
      } else if (response.status >= 400 && response.status < 500) {
        setSnackbar({
          open: true,
          message: "Failed to delete order - client error",
          severity: "error",
        });
      } else if (response.status >= 500) {
        setSnackbar({
          open: true,
          message: "Failed to delete order - server error",
          severity: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "Failed to delete order - network error",
        severity: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <IconButton disabled={currentUser.role === GROUP_ADMIN_ROLE_NAME} onClick={handleShow}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>
          {`Are you sure you want to delete ${order.first_name}'s order for ${order.num_leads} leads?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={deleteOrder} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
