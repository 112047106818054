import React, { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdminTenantSchedules = () => {
  const [schedules, setSchedules] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    loadSchedules();
  }, []);

  const loadSchedules = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/schedules`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch schedules");
      }
      const data = await response.json();
      setSchedules(data);
    } catch (error) {
      console.error("Failed to load schedules:", error);
    }
  };

  const columns = [
    { field: "user_name", headerName: "User", flex: 1 },
    { field: "name", headerName: "Schedule Name", flex: 1 },
    { field: "start_time", headerName: "Start Time", flex: 0.5 },
    { field: "end_time", headerName: "End Time", flex: 0.5 },
    { field: "timezone", headerName: "Timezone", flex: 0.7 },
    { field: "active_days", headerName: "Active Days", flex: 2 },
    { field: "states", headerName: "States", flex: 1.2 },
    {
      field: "is_active",
      headerName: "Active",
      type: "boolean",
      align: "left",
      headerAlign: "left",
      flex: 0.5,
    },
  ];

  return (
    <div className="content">
      <Container
        maxWidth={false}
        disableGutters
        sx={{ width: "100vp", padding: 0, margin: 0 }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // left align text
          }}
        >
          Tenant User Schedules
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // Center align text
          }}
        >
          This table displays the schedules for all users under the tenant.
        </Typography>
        <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
          <DataGridPro
            rows={schedules}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            headerFilters
            pageSizeOptions={[10, 25, 50]}
            pagination
            autoHeight
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminTenantSchedules;
