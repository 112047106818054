import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CircularProgress, Alert, Box } from "@mui/material";
import customFetch from "./general/auth/customFetch";

const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const response = await customFetch("/v1/orders/payment-intent-key");

        if (!response.ok) {
          throw new Error("Failed to load Stripe configuration");
        }

        const data = await response.json();
        if (!data.success || !data.key) {
          throw new Error("Invalid Stripe configuration received");
        }

        const stripe = await loadStripe(data.key);
        if (!stripe) {
          throw new Error("Failed to initialize Stripe");
        }

        setStripePromise(stripe);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
        setError(error.message || "Failed to initialize payment system");
      } finally {
        setIsLoading(false);
      }
    };

    initializeStripe();
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  if (!stripePromise) {
    return (
      <Box p={2}>
        <Alert severity="error" sx={{ mb: 2 }}>
          Unable to initialize payment system. Please try again later.
        </Alert>
      </Box>
    );
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
