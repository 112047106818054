import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Box,
  Button,
  Chip,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import SlackIcon from "@mui/icons-material/Telegram";
import SvgIcon from "@mui/material/SvgIcon";
// Custom Stripe icon using SVG path
const StripeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M13.479 9.883c-1.626-.604-2.512-1.067-2.512-1.803 0-.622.511-1.024 1.402-1.024 1.604 0 3.237.767 4.378 1.164l.655-4.034c-.897-.384-2.74-1.024-5.224-1.024-1.803 0-3.307.473-4.403 1.338-1.13.897-1.724 2.175-1.724 3.746 0 2.827 1.724 4.034 4.544 5.058 1.803.622 2.416 1.067 2.416 1.761 0 .664-.589 1.109-1.637 1.109-1.313 0-3.485-.675-4.925-1.569l-.655 4.127c1.234.737 3.526 1.493 5.903 1.493 1.93 0 3.541-.462 4.67-1.338 1.192-.918 1.82-2.282 1.82-3.961-.011-2.891-1.766-4.137-4.708-5.224z" />
  </SvgIcon>
);

const getIntegrationIcon = (integrationType) => {
  switch (integrationType.toLowerCase()) {
    case "facebook":
      return <FacebookIcon fontSize="large" />;
    case "slack":
      return <SlackIcon fontSize="large" />;
    case "stripe":
      return <StripeIcon fontSize="large" />;
    default:
      return null;
  }
};

const IntegrationCard = ({ integration, onEdit, onDelete, onConnect }) => {
  const isConnected = integration.connected;

  const renderFacebookData = () => {
    if (integration.integration_type !== "Facebook" || !integration.user_data) {
      return null;
    }

    return (
      <>
        <Typography variant="h6" component="div">
          Connection Details:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Oauth User: {integration.user_data.name}
        </Typography>
        {integration.user_data.email && (
          <Typography variant="body2" color="text.secondary">
            Oauth Email: {integration.user_data.email}
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary">
          Facebook ID: {integration.user_data.facebook_id}
        </Typography>
        <Divider sx={{ my: 1 }} />
      </>
    );
  };

  return (
    <Card sx={{ width: 350, mt: 2, mx: "auto" }}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box display="flex" alignItems="center">
            {getIntegrationIcon(integration.integration_type)}
            <Typography variant="h6" component="div" ml={1}>
              {integration.integration_type}
            </Typography>
          </Box>
          <Chip
            label={isConnected ? "Connected" : "Disconnected"}
            color={isConnected ? "success" : "default"}
            size="small"
          />
        </Box>
        <Typography variant="h5" component="div" mb={2}>
          {integration.name}
        </Typography>
        {renderFacebookData()}
        <Typography variant="h6" component="div" mb={1}>
          Created by:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {integration.owner_name}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        {!isConnected && (
          <Button
            startIcon={<LinkIcon />}
            onClick={() => onConnect(integration)}
            variant="contained"
            color="primary"
            sx={{ flexGrow: 1, mr: 1 }}
          >
            Connect
          </Button>
        )}
        <Box>
          {isConnected && (
            <>
              <Tooltip title="Reconnect">
                <IconButton
                  aria-label="reconnect"
                  onClick={() => onConnect(integration)}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => onEdit(integration)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={() => onDelete(integration)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
};

export default IntegrationCard;
