import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
  Alert,
  Tooltip,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/FileCopy";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const SERVER_BASE_URL = process.env.SERVER_BASE_URL || "http://localhost:3000";

const GhlMarketplaceApps = () => {
  const [apps, setApps] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openAppDialog, setOpenAppDialog] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    client_id: "",
    client_secret: "",
    is_active: true,
  });
  const [companyFormValues, setCompanyFormValues] = useState({
    tenant_id: "",
  });
  const currentUser = decode();

  useEffect(() => {
    loadApps();
  }, []);

  useEffect(() => {
    if (apps.length > 0) {
      loadCompanies(apps[selectedTab].id);
    }
  }, [apps, selectedTab]);

  const loadApps = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghlapp`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch apps");
      }
      const data = await response.json();
      setApps(data);
    } catch (error) {
      console.error("Failed to load apps:", error);
    }
  };

  const loadCompanies = async (appId) => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghlapp/${appId}/companies`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch companies");
      }
      const data = await response.json();
      setCompanies(data);
    } catch (error) {
      console.error("Failed to load companies:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOpenAppDialog = (app = null) => {
    setEditMode(!!app);
    setSelectedApp(app);
    setFormValues(
      app
        ? {
            name: app.name,
            client_id: app.client_id,
            client_secret: app.client_secret,
            is_active: app.is_active,
          }
        : { name: "", client_id: "", client_secret: "", is_active: true }
    );
    setOpenAppDialog(true);
  };

  const handleOpenCompanyDialog = (company = null) => {
    setEditMode(!!company);
    setSelectedCompany(company);
    setCompanyFormValues(
      company ? { tenant_id: company.tenant_id } : { tenant_id: "" }
    );
    setOpenCompanyDialog(true);
  };

  const handleCloseAppDialog = () => {
    setOpenAppDialog(false);
    setSelectedApp(null);
    setFormValues({
      name: "",
      client_id: "",
      client_secret: "",
      is_active: true,
    });
  };

  const handleCloseCompanyDialog = () => {
    setOpenCompanyDialog(false);
    setSelectedCompany(null);
    setCompanyFormValues({
      tenant_id: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setCompanyFormValues({ ...companyFormValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormValues({ ...formValues, is_active: e.target.checked });
  };

  const handleSubmitApp = async () => {
    try {
      if (editMode && selectedApp) {
        const payload = {
          name: formValues.name,
          is_active: formValues.is_active,
        };
        if (formValues.client_id !== selectedApp.client_id) {
          payload.client_id = formValues.client_id;
        }
        if (formValues.client_secret !== selectedApp.client_secret) {
          payload.client_secret = formValues.client_secret;
        }
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ghlapp/${selectedApp.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update app");
        }
      } else {
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ghlapp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to create app");
        }
      }
      loadApps();
      handleCloseAppDialog();
    } catch (error) {
      console.error("Failed to save app:", error);
    }
  };

  const handleSubmitCompany = async () => {
    try {
      if (editMode && selectedCompany) {
        const payload = {
          tenant_id: companyFormValues.tenant_id,
        };
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ghlapp/${apps[selectedTab].id}/companies/${selectedCompany.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to update company");
        }
        loadCompanies(apps[selectedTab].id);
      }
      handleCloseCompanyDialog();
    } catch (error) {
      console.error("Failed to save company:", error);
    }
  };

  const handleDeleteApp = async (id) => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/ghlapp/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete app");
      }
      loadApps();
    } catch (error) {
      console.error("Failed to delete app:", error);
    }
  };

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Callback URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const appColumns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "app_id", headerName: "App ID", flex: 1.2 },
    { field: "client_id", headerName: "Client ID", flex: 0.5 },
    { field: "client_secret", headerName: "Client Secret", flex: 0.5 },
    {
      field: "callback_url",
      headerName: "Callback URL",
      flex: 1.5,
      renderCell: (params) => {
        const url = `${SERVER_BASE_URL}/auth/oauth/callback/${params.row.app_id}`;
        return (
          <Box display="flex" alignItems="center">
            <Tooltip title="Copy Callback URL">
              <IconButton onClick={() => handleCopy(url)}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {url}
          </Box>
        );
      },
    },
    { field: "is_active", headerName: "Is Active", flex: 0.5, type: "boolean" },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenAppDialog(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteApp(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const companyColumns = [
    { field: "id", headerName: "Company ID", flex: 0.7 },
    { field: "tenant_id", headerName: "Tenant", flex: 0.6 },
    { field: "name", headerName: "Name", flex: 1.2 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "city", headerName: "City", flex: 0.5 },
    { field: "state", headerName: "State", flex: 0.5 },
    { field: "country", headerName: "Country", flex: 0.5 },
    { field: "timezone", headerName: "Timezone", flex: 0.5 },
    { field: "status", headerName: "Status", flex: 0.5 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleOpenCompanyDialog(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const activeAppsCount = apps.filter((app) => app.is_active).length;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        GHL Marketplace Apps
      </Typography>
      <Typography variant="body1" gutterBottom>
        A developer will need to create an app{" "}
        <a href="https://marketplace.gohighlevel.com/apps">here</a> and then
        save the client and secret to this page. After which, the callback URL
        will be provided to the user and can be used in GHL "Redirect URLs"
        settings section to complete the app.
      </Typography>
      {activeAppsCount > 1 && (
        <Alert severity="warning">
          There is more than one active marketplace app!
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenAppDialog()}
      >
        Add New App
      </Button>
      <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
        <DataGridPro rows={apps} columns={appColumns} pageSize={5} />
      </div>

      <Dialog open={openAppDialog} onClose={handleCloseAppDialog}>
        <DialogTitle>{editMode ? "Edit App" : "Add New App"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Client ID"
            name="client_id"
            value={formValues.client_id}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Client Secret"
            name="client_secret"
            value={formValues.client_secret}
            onChange={handleChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.is_active}
                onChange={handleCheckboxChange}
                name="is_active"
              />
            }
            label="Is Active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAppDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitApp} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      {apps.length > 0 && (
        <Box style={{ marginTop: "20px" }}>
          <Typography variant="h5" gutterBottom>
            GHL Agencies
          </Typography>
          <Typography variant="body1" gutterBottom>
            This sections shows which GHL agencies have been imported from the
            GHL API and their current tenant association. The tenant association
            is used when showing avialable locations and users under the tenant
            admin views.
          </Typography>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {apps.map((app, index) => (
              <Tab key={index} label={app.name} />
            ))}
          </Tabs>
          <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
            <DataGridPro
              rows={companies}
              columns={companyColumns}
              pageSize={5}
            />
          </div>
        </Box>
      )}

      <Dialog open={openCompanyDialog} onClose={handleCloseCompanyDialog}>
        <DialogTitle>
          {editMode ? "Edit Company" : "Add New Company"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tenant ID"
            name="tenant_id"
            value={companyFormValues.tenant_id}
            onChange={handleCompanyChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCompanyDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmitCompany} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GhlMarketplaceApps;
