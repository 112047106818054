import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  TextField,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function AssignAdminDialog({
  open,
  onClose,
  onAssignAdmin,
  groupId,
  currentAdmins,
}) {
  const [availableAdmins, setAvailableAdmins] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentUser = decode();

  useEffect(() => {
    if (open) {
      fetchAvailableAdmins();
    }
  }, [open]);

  useEffect(() => {
    // Set initially selected admins based on currentAdmins
    setSelectedAdmins(currentAdmins.map((admin) => admin.tenant_user_id));
  }, [currentAdmins]);

  const fetchAvailableAdmins = async () => {
    try {
      // Assuming role IDs for group admin and tenant admin are 2 and 1 respectively
      const roleIds = "2,3"; // Adjust these IDs based on your actual role IDs
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users?role_ids=${roleIds}`,
        { method: "GET" }
      );
      const data = await response.json();
      setAvailableAdmins(data);
    } catch (error) {
      console.error("Error fetching available admins:", error);
    }
  };

  const handleToggle = (adminId) => {
    const currentIndex = selectedAdmins.indexOf(adminId);
    const newSelectedAdmins = [...selectedAdmins];

    if (currentIndex === -1) {
      newSelectedAdmins.push(adminId);
    } else {
      newSelectedAdmins.splice(currentIndex, 1);
    }

    setSelectedAdmins(newSelectedAdmins);
  };

  const handleAssign = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/admins`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ tenant_user_ids: selectedAdmins }),
        }
      );
      if (response.ok) {
        onAssignAdmin(selectedAdmins);
        onClose();
      } else {
        const errorData = await response.json();
        console.error("Error assigning admins:", errorData.error);
        // Handle error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error assigning admins:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const filteredAdmins = availableAdmins.filter(
    (admin) =>
      (admin.first_name + " " + admin.last_name)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      admin.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Admins to Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search Admins"
          type="text"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
          {filteredAdmins.map((admin) => {
            const labelId = `checkbox-list-label-${admin.id}`;
            return (
              <ListItem
                key={admin.id}
                dense
                button
                onClick={() => handleToggle(admin.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedAdmins.includes(admin.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${admin.first_name} ${admin.last_name}`}
                  secondary={admin.email}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAssign} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
