import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function CreateReplacement({ statusArr }) {
  const [leadId, setLeadId] = useState("");
  const [status, setStatus] = useState("approved");
  const [show, setShow] = useState(false);
  const currentUser = decode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleStatusChange = (event, newValue) => {
    setStatus(newValue.value);
  };

  const createReplacement = async (e) => {
    e.preventDefault();
    try {
      let body = {
        replacement_status: status,
      };
      await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/replacements`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      window.location = "/admin/replacements";
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleShow}>
        Create New Replacement
      </Button>

      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Create New Replacement</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            id="create-replacement"
            noValidate
            autoComplete="off"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <TextField
              margin="dense"
              id="leadId"
              label="Lead ID"
              type="number"
              fullWidth
              value={leadId}
              onChange={(e) => setLeadId(e.target.value)}
              required
              placeholder="Lead ID..."
            />
            <Autocomplete
              id="status"
              options={statusArr}
              getOptionLabel={(option) => option.label}
              value={statusArr.find((option) => option.value === status) || null}
              onChange={handleStatusChange}
              renderInput={(params) => <TextField {...params} label="Status" required />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={createReplacement} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
