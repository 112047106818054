import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import CreateSchedule from "./components/CreateSchedule";
import EditSchedule from "./components/EditSchedule";
import DeleteSchedule from "./components/DeleteSchedule";
import moment from "moment-timezone";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const ScheduleList = () => {
  const currentUser = decode();
  const [schedules, setSchedules] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [allSchedulesDisabled, setAllSchedulesDisabled] = useState(false);
  const [usedStatesByDay, setUsedStatesByDay] = useState({});

  useEffect(() => {
    fetchProducts();
    fetchOrders();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchSchedules(products[selectedProduct].id);
    }
  }, [products, selectedProduct]);

  const fetchProducts = async () => {
    try {
      const response = await customFetch(`/v1/products/product-catalog`);
      const data = await response.json();
      const relevantProducts = data.filter(
        (product) =>
          product.product_key === "agent-lead" ||
          product.product_key === "agent-adset" ||
          product.product_key === "tenant-campaign-state" ||
          product.product_key === "tenant-ad-set"
      );
      setProducts(relevantProducts);
      if (relevantProducts.length > 0) {
        setSelectedProduct(0);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const fetchSchedules = async (productId) => {
    try {
      const response = await customFetch(
        `/v1/users/${currentUser.tenant_user_id}/products/${productId}/schedules`
      );
      const data = await response.json();
      setSchedules(data);

      const selectedProductKey = products.find(
        (product) => product.id === productId
      )?.product_key;

      // Track states by day instead of just states
      const statesByDay = {};
      let allDisabled = true;

      data.forEach((schedule) => {
        if (
          selectedProductKey === "tenant-campaign-state" ||
          selectedProductKey === "agent-lead"
        ) {
          schedule.active_days.forEach((day) => {
            if (!statesByDay[day]) {
              statesByDay[day] = new Set();
            }
            schedule.states?.forEach((state) => {
              statesByDay[day].add(state);
            });
          });
        }
        if (schedule.is_active) {
          allDisabled = false;
        }
      });

      // Convert Sets to Arrays
      const formattedStatesByDay = {};
      Object.keys(statesByDay).forEach((day) => {
        formattedStatesByDay[day] = Array.from(statesByDay[day]);
      });

      setUsedStatesByDay(formattedStatesByDay);
      setAllSchedulesDisabled(allDisabled);
    } catch (error) {
      console.error("Error fetching schedules", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await customFetch(`/v1/orders/`);
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (params) => {
    setSelectedSchedule(params.row);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedSchedule(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedDeleteId(id);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setSelectedDeleteId(null);
  };

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  const selectedProductKey = products[selectedProduct]?.product_key;
  const showStates =
    selectedProductKey === "tenant-campaign-state" ||
    selectedProductKey === "agent-lead";

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "start_time",
      headerName: "Start Time",
      flex: 0.7,
      renderCell: (params) =>
        moment(params.value, "HH:mm:ssZ").format("hh:mm A"),
    },
    {
      field: "end_time",
      headerName: "End Time",
      flex: 0.7,
      renderCell: (params) =>
        moment(params.value, "HH:mm:ssZ").format("hh:mm A"),
    },
    { field: "timezone", headerName: "Timezone", flex: 1 },
    showStates && { field: "states", headerName: "States", flex: 1 },
    { field: "active_days", headerName: "Active Days", flex: 1 },
    { field: "is_active", headerName: "Active", type: "boolean", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditClick(params)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ].filter(Boolean);

  const getSchedulingIssues = () => {
    if (
      selectedProductKey !== "tenant-campaign-state" &&
      selectedProductKey !== "agent-lead"
    ) {
      return null;
    }

    // Get states from orders
    const orderStates = orders
      .filter((order) => order.product_id === products[selectedProduct].id)
      .map((order) => order.states)
      .flat();

    // Create a map of states and their schedule coverage
    const stateCoverage = {};
    orderStates.forEach((state) => {
      stateCoverage[state] = {
        state: state,
        hasSchedule: false,
        coveredDays: new Set(),
        missingDays: new Set([
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]),
      };
    });

    // Update coverage based on existing schedules
    schedules.forEach((schedule) => {
      if (schedule.is_active) {
        schedule.states?.forEach((state) => {
          if (stateCoverage[state]) {
            stateCoverage[state].hasSchedule = true;
            schedule.active_days.forEach((day) => {
              stateCoverage[state].coveredDays.add(day);
              stateCoverage[state].missingDays.delete(day);
            });
          }
        });
      }
    });

    // Analyze the coverage
    const issues = {
      statesWithNoSchedule: [],
      statesWithPartialCoverage: [],
    };

    Object.values(stateCoverage).forEach((coverage) => {
      if (!coverage.hasSchedule) {
        issues.statesWithNoSchedule.push(coverage.state);
      } else if (coverage.missingDays.size > 0) {
        issues.statesWithPartialCoverage.push({
          state: coverage.state,
          missingDays: Array.from(coverage.missingDays),
        });
      }
    });

    return issues;
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  const showStateAlerts =
    selectedProductKey === "tenant-campaign-state" ||
    selectedProductKey === "agent-lead";

  return (
    <div className="content">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ color: "primary.main", marginBottom: 2, textAlign: "center" }}
        >
          Schedule List
        </Typography>
        <IconButton
          onClick={handleHelpDialogOpen}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            width: 64,
            height: 64,
          }}
        >
          <HelpOutlineIcon sx={{ width: "100%", height: "100%" }} />
        </IconButton>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedProduct} onChange={handleTabChange}>
          {products.map((product) => (
            <Tab key={product.id} label={product.name} />
          ))}
        </Tabs>
      </Box>
      {schedules.length > 0 && allSchedulesDisabled && (
        <Alert severity="error" sx={{ margin: "20px 0px" }}>
          No Leads are delivered when all schedules are disabled.
        </Alert>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "20px 0px" }}
      >
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create Schedule
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "20px 0px" }}
      >
        <Card sx={{ minWidth: 275, marginBottom: 0 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {products[selectedProduct]?.name} Campaign Schedule
            </Typography>
            {products[selectedProduct]?.start_time &&
            products[selectedProduct]?.end_time ? (
              <>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Start Time:{" "}
                  {convertTo12HourFormat(products[selectedProduct].start_time)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  End Time:{" "}
                  {convertTo12HourFormat(products[selectedProduct].end_time)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Active Days:{" "}
                  {products[selectedProduct].active_days
                    ? products[selectedProduct].active_days.join(", ")
                    : "N/A"}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Time Zone: {products[selectedProduct].time_zone}
                </Typography>
              </>
            ) : (
              <Typography sx={{ mb: 1.5 }} color="textSecondary">
                This product runs 24 hours.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      {schedules.length === 0 ? (
        <Alert severity="info">
          No schedule is created for this product. All orders will follow the
          campaign schedule.
        </Alert>
      ) : (
        <>
          {showStateAlerts && (
            <Box sx={{ my: 3 }}>
              {(() => {
                const issues = getSchedulingIssues();
                if (!issues) return null;

                return (
                  <>
                    {issues.statesWithNoSchedule.length > 0 && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        <AlertTitle>
                          Urgent: States Missing Schedules
                        </AlertTitle>
                        <Typography component="div">
                          The following states have active orders but no
                          schedule at all:
                          <Box
                            component="span"
                            sx={{ fontWeight: "bold", display: "block", mt: 1 }}
                          >
                            {issues.statesWithNoSchedule.join(", ")}
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            ⚠️ These states will not receive any leads until a
                            schedule is created.
                          </Box>
                        </Typography>
                      </Alert>
                    )}

                    {issues.statesWithPartialCoverage.length > 0 && (
                      <Box
                        sx={{
                          mb: 2,
                          "& .MuiPaper-root": {
                            // Target the Accordion's paper
                            "&:before": {
                              // Remove the default expansion panel line
                              display: "none",
                            },
                          },
                        }}
                      >
                        <Accordion
                          sx={{
                            backgroundColor: "rgb(255, 244, 229)", // Warning alert background color
                            color: "rgb(102, 60, 0)", // Warning alert text color
                            "& .MuiAccordionSummary-root": {
                              borderRadius: "4px",
                              "&.Mui-expanded": {
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                              },
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{ color: "rgb(102, 60, 0)" }}
                              />
                            }
                            sx={{
                              "& .MuiAccordionSummary-content": {
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                width: "100%",
                              }}
                            >
                              <WarningIcon sx={{ color: "rgb(102, 60, 0)" }} />
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ fontWeight: "medium" }}
                              >
                                Warning:{" "}
                                {issues.statesWithPartialCoverage.length} States
                                Have Incomplete Weekly Coverage
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              borderTop: "1px solid rgba(102, 60, 0, 0.12)",
                              backgroundColor: "rgba(255, 244, 229, 0.8)",
                            }}
                          >
                            <Typography component="div">
                              <Box component="div" sx={{ mb: 2 }}>
                                The following states are missing coverage on
                                certain days:
                              </Box>
                              <Box
                                component="div"
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns:
                                    "repeat(auto-fill, minmax(250px, 1fr))",
                                  gap: 2,
                                }}
                              >
                                {issues.statesWithPartialCoverage.map(
                                  (item) => (
                                    <Box
                                      key={item.state}
                                      sx={{
                                        p: 1.5,
                                        border:
                                          "1px solid rgba(102, 60, 0, 0.2)",
                                        borderRadius: 1,
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.5)",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontWeight: "bold", mb: 0.5 }}
                                      >
                                        {item.state}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontStyle: "italic" }}
                                      >
                                        Missing: {item.missingDays.join(", ")}
                                      </Typography>
                                    </Box>
                                  )
                                )}
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <WarningIcon fontSize="small" />
                                <Typography variant="body2">
                                  Leads will only be delivered on days that have
                                  active schedules.
                                </Typography>
                              </Box>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                    {schedules.length > 0 && allSchedulesDisabled && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        <AlertTitle>
                          Important: All Schedules Disabled
                        </AlertTitle>
                        <Typography>
                          All schedules are currently disabled. No leads will be
                          delivered until at least one schedule is enabled.
                        </Typography>
                      </Alert>
                    )}
                    <Box
                      sx={{
                        mt: 2,
                        p: 2,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="subtitle2" color="text.secondary">
                        💡 Quick Help:
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        • Each state needs a schedule for every day you want to
                        receive leads
                        <br />
                        • You can create multiple schedules to cover different
                        states and days
                        <br />• Make sure to enable schedules after creating
                        them
                      </Typography>
                    </Box>
                  </>
                );
              })()}
            </Box>
          )}
          <Box sx={{ width: "100%", margin: "20px 0px" }}>
            <DataGridPro
              rows={schedules}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </Box>
        </>
      )}
      <CreateSchedule
        open={open}
        handleClose={handleClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        usedStatesByDay={usedStatesByDay}
      />

      <EditSchedule
        openEdit={openEdit}
        handleEditClose={handleEditClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
        usedStatesByDay={usedStatesByDay}
      />

      <DeleteSchedule
        openDelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        selectedDeleteId={selectedDeleteId}
        setSelectedDeleteId={setSelectedDeleteId}
      />

      <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
        <DialogTitle>Schedule Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can manage the schedules for each product. Use the tabs to
            switch between products and view their respective schedules. You can
            create, edit, and delete schedules using the buttons and icons
            provided.
            <ul>
              <li>
                <strong>Create Schedule:</strong> Click the "Create Schedule"
                button to add a new schedule for the selected product.
              </li>
              <li>
                <strong>Edit Schedule:</strong> Use the edit icon next to each
                schedule to change its details.
              </li>
              <li>
                <strong>Delete Schedule:</strong> Use the delete icon next to
                each schedule to remove it.
              </li>
            </ul>
            <Alert severity="warning" sx={{ margin: "20px 0px" }}>
              If a product does not have a schedule, all orders will follow the
              default campaign schedule. Make sure to create schedules for any
              specific times and states where you want to receive those leads.
            </Alert>
            <Alert severity="error" sx={{ margin: "20px 0px" }}>
              Leads are delivered only to active schedules and active states.
              You are responsible for ensuring that schedules cover all the
              states you want to advertise in. If an order includes states that
              do not have schedules, those states may not receive any leads.
            </Alert>
            <Alert severity="info" sx={{ margin: "20px 0px" }}>
              You can choose to disable or delete all schedules. If you do this,
              ads will follow the main campaign schedule instead.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScheduleList;
