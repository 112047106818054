import React, { useState } from "react";
import { IconButton, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import DeleteIcon from '@mui/icons-material/Delete';
import decode from "../../../../general/util/jwtDecode";

export default function DeleteLead({ lead }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentUser = decode();

  const deleteLead = async () => {
    try {
      await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/leads/${lead.id}`, {
        method: "DELETE",
        headers: {},
        credentials: "include",
        withCredentials: true,
      });
      window.location = "/admin/leads";
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton onClick={handleShow}>
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete the lead ${lead.name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteLead} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
