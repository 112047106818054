import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Chip,
  Stack,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

function OrderSummary({ orderDetails }) {
  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Order Summary
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {orderDetails.productName}
            </Typography>
            <Typography mb={1} variant="body2" color="text.secondary">
              Price: <Chip key={orderDetails.price.toFixed(2)} label={orderDetails.price.toFixed(2)} size="small" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Quantity:  <Chip key={orderDetails.quantity} label={orderDetails.quantity} size="small" />
            </Typography>
          </Box>
        </Box>

        {orderDetails.states.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
              Selected States:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {orderDetails.states.map((state) => (
                <Chip key={state} label={state} size="small" />
              ))}
            </Stack>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography variant="subtitle1">
            ${(orderDetails.quantity * orderDetails.price).toFixed(2)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            ${(orderDetails.quantity * orderDetails.price).toFixed(2)}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
}

export default OrderSummary;
