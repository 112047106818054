// BrandingContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { staticBranding } from "./general/constants/staticBranding";

const BrandingContext = createContext();

const DEFAULT_BRANDING = {
  light_logo_icon_url:
    "https://storage.googleapis.com/partner_branding_dev/L2C/BIAB/light_logo_icon.png",
  light_logo_url:
    "https://storage.googleapis.com/partner_branding_dev/L2C/BIAB/light_logo.png",
  dark_logo_icon_url:
    "https://storage.googleapis.com/partner_branding_dev/L2C/BIAB/light_logo_icon.png",
  dark_logo_url:
    "https://storage.googleapis.com/partner_branding_dev/L2C/BIAB/light_logo.png",
};

export const useBranding = () => {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error("useBranding must be used within a BrandingProvider");
  }
  return context;
};

export const BrandingProvider = ({ children, themeMode = "light" }) => {
  const [branding, setBranding] = useState(() => {
    const currentDomain = window.location.hostname;
    return staticBranding[currentDomain] || DEFAULT_BRANDING;
  });

  // Check if dark theme is available
  const hasDarkTheme = Boolean(
    branding.dark_logo_url && branding.dark_logo_icon_url
  );

  const currentBranding = {
    logoIcon:
      themeMode === "light"
        ? encodeURI(branding.light_logo_icon_url)
        : encodeURI(branding.dark_logo_icon_url),
    logoFull:
      themeMode === "light"
        ? encodeURI(branding.light_logo_url)
        : encodeURI(branding.dark_logo_url),
    allLogos: {
      light_logo_icon_url: encodeURI(branding.light_logo_icon_url),
      light_logo_url: encodeURI(branding.light_logo_url),
      dark_logo_icon_url: encodeURI(branding.dark_logo_icon_url),
      dark_logo_url: encodeURI(branding.dark_logo_url),
    },
    hasDarkTheme,
    getLogosForMode: (mode) => ({
      logoIcon:
        mode === "light"
          ? encodeURI(branding.light_logo_icon_url)
          : encodeURI(branding.dark_logo_icon_url),
      logoFull:
        mode === "light"
          ? encodeURI(branding.light_logo_url)
          : encodeURI(branding.dark_logo_url),
    }),
  };

  useEffect(() => {
    const updateBrandingFromAPI = async () => {
      const currentDomain = window.location.hostname;
      try {
        const response = await fetch(`/v1/branding?domain=${currentDomain}`);
        if (!response.ok) throw new Error("API response was not ok");

        const brandingData = await response.json();
        if (brandingData?.light_logo_url) {
          setBranding({
            light_logo_icon_url: brandingData.light_logo_icon_url,
            light_logo_url: brandingData.light_logo_url,
            dark_logo_icon_url: brandingData.dark_logo_icon_url,
            dark_logo_url: brandingData.dark_logo_url,
          });
        }
      } catch (error) {
        console.error("Error fetching updated branding from API:", error);
      }
    };

    updateBrandingFromAPI();
  }, []);

  return (
    <BrandingContext.Provider value={currentBranding}>
      {children}
    </BrandingContext.Provider>
  );
};
