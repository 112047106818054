import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import AddMappingDialog from "./components/AddMappingDialog";
import EditMappingDialog from "./components/EditMappingDialog";
import AddMappingGroupDialog from "./components/AddMappingGroupDialog";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function FieldMappingManagementPage() {
  const [mappingGroups, setMappingGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [openAddMappingDialog, setOpenAddMappingDialog] = useState(false);
  const [openEditMappingDialog, setOpenEditMappingDialog] = useState(false);
  const [openAddMappingGroupDialog, setOpenAddMappingGroupDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteMappingDialog, setOpenDeleteMappingDialog] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [mappingToDelete, setMappingToDelete] = useState(null);
  const currentUser = decode();

  const fetchMappingGroups = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/mappingGroups`,
        { method: "GET" }
      );
      const data = await response.json();
      setMappingGroups(data);
      if (data.length > 0) {
        setSelectedGroup(data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFieldMappings = async (groupId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/fieldMappings?group_id=${groupId}`,
        { method: "GET" }
      );
      const data = await response.json();
      setFieldMappings(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMappingGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      fetchFieldMappings(selectedGroup.id);
    }
  }, [selectedGroup]);

  const handleTabChange = (event, newValue) => {
    setSelectedGroup(mappingGroups[newValue]);
  };

  const handleAddMapping = async (mapping) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/fieldMappings`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(mapping),
        }
      );
      fetchFieldMappings(selectedGroup.id);
      setOpenAddMappingDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditMapping = async (mapping) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/fieldMappings/${mapping.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(mapping),
        }
      );
      fetchFieldMappings(selectedGroup.id);
      setOpenEditMappingDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddMappingGroup = async (name, description) => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/mappingGroups`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, description }),
        }
      );
      fetchMappingGroups();
      setOpenAddMappingGroupDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/mappingGroups/${groupToDelete}`,
        {
          method: "DELETE",
        }
      );
      fetchMappingGroups();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMappingDeleteConfirmation = async () => {
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/fieldMappings/${mappingToDelete}`,
        {
          method: "DELETE",
        }
      );
      fetchFieldMappings(selectedGroup.id);
      setOpenDeleteMappingDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: "tenant_custom_field_name",
      headerName: "Tenant Custom Field",
      flex: 1,
    },
    { field: "ghl_custom_field_name", headerName: "GHL Custom Field", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setMappingToDelete(params.row.id);
              setOpenDeleteMappingDialog(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box p={2}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // left align text
          }}
        >
          Field Mapping Management
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2, // Theme spacing unit
            textAlign: "left", // Center align text
          }}
        >
          Field mappings allow tenant admins to link tenant-specific custom
          fields with GoHighLevel (GHL) custom fields. Mapping groups allow for
          easier organization and management of these mappings.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddMappingGroupDialog(true)}
        >
          Add New Mapping Group
        </Button>
      </Box>
      <Box display="flex" flexGrow={1}>
        <Box flexBasis="20%" display="flex" flexDirection="column">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={
              selectedGroup
                ? mappingGroups.findIndex(
                    (group) => group.id === selectedGroup.id
                  )
                : 0
            }
            onChange={handleTabChange}
          >
            {mappingGroups.map((group, index) => (
              <Tab
                key={group.id}
                label={
                  <Card
                    variant="outlined"
                    sx={{ width: "100%", textAlign: "left", boxShadow: 2 }}
                  >
                    <CardContent>
                      <Typography variant="h6">{group.name}</Typography>
                      <Typography variant="body2">
                        Mappings: {group.mappings_count || 0}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                sx={{ minWidth: "100%", textAlign: "left" }}
              />
            ))}
          </Tabs>
        </Box>
        <Box flexGrow={1} p={1.5}>
          {selectedGroup && (
            <>
              <Card sx={{ boxShadow: 2 }} variant="outlined">
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Typography variant="h5">{selectedGroup.name}</Typography>
                      <Typography variant="body1">
                        {selectedGroup.description}
                      </Typography>
                      <Box mt={2}>
                        <Typography variant="subtitle1">
                          Assigned Products:
                        </Typography>
                        <Box display="flex" flexWrap="wrap" mt={1}>
                          {selectedGroup.associated_products.map(
                            (product, index) => (
                              <Chip
                                key={index}
                                label={product.name}
                                sx={{ margin: 0.5 }}
                              />
                            )
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-end" mt="auto">
                      <Tooltip title="Delete Group">
                        <IconButton
                          onClick={() => {
                            setGroupToDelete(selectedGroup.id);
                            setOpenDeleteDialog(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddMappingDialog(true)}
                >
                  Add Field Mapping
                </Button>
              </Box>
              <Box mt={2}>
                <DataGridPro
                  rows={fieldMappings}
                  columns={columns}
                  pageSize={15}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 15 },
                    },
                  }}
                  pageSizeOptions={[15, 50, 100]}
                  pagination
                  autoHeight
                />
              </Box>
            </>
          )}
        </Box>
        <AddMappingDialog
          open={openAddMappingDialog}
          onClose={() => setOpenAddMappingDialog(false)}
          onAddMapping={handleAddMapping}
          mappingGroupId={selectedGroup?.id}
        />
        <EditMappingDialog
          open={openEditMappingDialog}
          onClose={() => setOpenEditMappingDialog(false)}
          onEditMapping={handleEditMapping}
          mappingGroupId={selectedGroup?.id}
        />
        <AddMappingGroupDialog
          open={openAddMappingGroupDialog}
          onClose={() => setOpenAddMappingGroupDialog(false)}
          onAddGroup={handleAddMappingGroup}
        />
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this mapping group?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmation} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteMappingDialog}
          onClose={() => setOpenDeleteMappingDialog(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this field mapping?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDeleteMappingDialog(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleMappingDeleteConfirmation} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
