import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  TextField,
  Alert,
  MenuItem,
  Tooltip,
  FormControl,
  Snackbar,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { statesList } from "../../../../general/util/statesList";
import { CAP_LANGUAGE } from "../../../../general/constants/constants";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditOrder({ order }) {
  const [status, setStatus] = useState(order.order_status);
  const [dailyCap, setDailyCap] = useState(order.daily_cap);
  const [startDate, setStartDate] = useState(
    (order.start_date && new Date(order.start_date)) || null
  );
  const [states, setStates] = useState(order.states || []);
  const [show, setShow] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [productKey, setProductKey] = useState(""); // New state for product key
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [productDetails, setProductDetails] = useState(null);

  const currentUser = decode();

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    setShow(true);

    try {
      // Fetch product catalog item
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/product-catalog/${order.product_id}`
      );
      if (response.ok) {
        const productData = await response.json();
        setProductDetails(productData);
        setProductKey(productData.product_key);
      } else {
        console.error("Failed to fetch product catalog item");
        setSnackbarMessage("Failed to load product details");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching product catalog item:", error);
      setSnackbarMessage("Failed to load product details");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    // Fetch locations if necessary
    try {
      const response = await customFetch(
        `/v1/users/${currentUser.tenant_user_id}/profile`
      );
      const data = await response.json();
      if (data.locations && data.locations.length > 0) {
        setLocations(data.locations);
        setSelectedLocation(data.primary_location_id);
      }
    } catch (error) {
      console.error("Failed to fetch locations:", error);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);
  const handleStatusChange = (event) => setStatus(event.target.value);
  const handleStatesChange = (event) => {
    setStates(event.target.value);
  };
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    const form = document.getElementById("edit-order");

    // Add validation against product details
    if (productDetails && dailyCap < productDetails.daily_cap) {
      setSnackbarMessage(
        `Daily cap cannot be less than ${productDetails.daily_cap}`
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (form.checkValidity() === false) {
      return;
    }

    try {
      const body = {
        order_status: status,
        daily_cap: dailyCap,
        start_date: startDate,
        states: productKey === "agent-adset" ? [] : states,
        location_id: selectedLocation !== "" ? selectedLocation : null,
      };

      const response = await customFetch(`/v1/orders/${order.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setSnackbarMessage("Order updated successfully!");
        setSnackbarSeverity("success");
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Failed to update order.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      handleClose();
    }
  };

  const isRecentlyUpdated =
    order.last_updated &&
    Date.now() - new Date(order.last_updated).getTime() < 2 * 60 * 60 * 1000;

  return (
    <>
      <Tooltip
        title={
          order.order_status === "free"
            ? "Order is free and cannot be edited."
            : order.order_status === "fulfilled"
            ? "Order is either fulfilled or on hold and cannot be edited."
            : order.order_status === "capped" || order.order_status === "maxed"
            ? "Order has reached its daily limit and cannot be edited"
            : ""
        }
        arrow
      >
        <span>
          <Button
            size="small"
            variant={order.order_status === "free" ? "contained" : "contained"}
            color={order.order_status === "free" ? "primary" : "warning"}
            onClick={handleShow}
            disabled={
              order.order_status === "free" ||
              order.order_status === "fulfilled" ||
              order.order_status === "capped" ||
              order.order_status === "maxed" ||
              (order.order_status === "fulfilled" &&
                order.lead_count - order.replacement_count >= order.num_leads)
            }
          >
            {order.order_status === "free" ? "Free Order" : "Edit"}
          </Button>
        </span>
      </Tooltip>

      <Modal open={show} onClose={handleClose}>
        <div
          style={{
            padding: "20px",
            background: "white",
            margin: "50px auto",
            maxWidth: "500px",
          }}
        >
          <h2>Edit Lead Order</h2>
          {isRecentlyUpdated && (
            <Alert severity="warning" style={{ marginBottom: "20px" }}>
              This order was updated less than 2 hours ago.
            </Alert>
          )}
          <form id="edit-order">
            <TextField
              select
              label="Order Status"
              value={status}
              onChange={handleStatusChange}
              fullWidth
              margin="normal"
              style={{ marginBottom: "20px" }}
              disabled={
                !(
                  order.order_status === "running" ||
                  order.order_status === "paused" ||
                  order.order_status === "hold"
                )
              }
            >
              <MenuItem value="running">Running</MenuItem>
              <MenuItem value="paused">Paused</MenuItem>
              <MenuItem value="pending" disabled>
                Pending
              </MenuItem>
              <MenuItem value="capped" disabled>
                Capped
              </MenuItem>
              <MenuItem value="maxed" disabled>
                Daily Max
              </MenuItem>
              <MenuItem value="hold" disabled>
                Hold
              </MenuItem>
              <MenuItem value="fulfilled" disabled>
                Fulfilled
              </MenuItem>
            </TextField>
            {locations.length > 0 && (
              <FormControl fullWidth>
                <TextField
                  select
                  label="GHL Delivery Account"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  required
                  SelectProps={{
                    native: false,
                  }}
                  disabled={
                    !(
                      order.order_status === "running" ||
                      order.order_status === "paused" ||
                      order.order_status === "hold"
                    )
                  }
                  error={!selectedLocation}
                  helperText={
                    !selectedLocation ? "GHL Delivery Account is required" : ""
                  }
                >
                  {locations.map((location) => (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}{" "}
                      {location.id === selectedLocation ? "(Primary)" : ""}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
            <TextField
              required
              onChange={(e) => setDailyCap(e.target.value)}
              type="number"
              min={productDetails?.daily_cap}
              value={dailyCap}
              label="Daily Lead Cap"
              fullWidth
              margin="normal"
              error={productDetails && dailyCap < productDetails.daily_cap}
              helperText={
                productDetails && dailyCap < productDetails.daily_cap
                  ? `Minimum daily cap is ${productDetails.daily_cap}`
                  : ""
              }
            />

            {dailyCap < (productDetails?.daily_cap || 0) && (
              <Alert severity="error" style={{ marginTop: "10px" }}>
                Minimum Cap of {productDetails?.daily_cap} Leads per Day
              </Alert>
            )}
            <div>{CAP_LANGUAGE}</div>
            {order.order_status === "pending" && (
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <Tooltip
                  title={
                    isRecentlyUpdated
                      ? "Order was updated less than 2 hours ago."
                      : "Start date can only be edited when the status is pending."
                  }
                  arrow
                >
                  <span>
                    <DatePicker
                      className="form-control"
                      selected={startDate ? startDate : ""}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Start Date..."
                      disabled={
                        order.order_status !== "pending" && isRecentlyUpdated
                      }
                    />
                  </span>
                </Tooltip>

                <Alert severity="info" style={{ marginTop: "10px" }}>
                  Start date can only be edited when the status is pending.
                </Alert>
              </div>
            )}
            {order.order_status === "pending" && (
              <Tooltip
                title={
                  isRecentlyUpdated
                    ? "Order was updated less than 2 hours ago."
                    : "States can only be edited when the status is pending."
                }
                arrow
              >
                <span>
                  <TextField
                    select
                    label="States"
                    value={states}
                    onChange={handleStatesChange}
                    fullWidth
                    margin="normal"
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                    }}
                    disabled={
                      order.order_status !== "pending" ||
                      isRecentlyUpdated ||
                      productKey === "agent-adset" // Disable states if product is agent-adset
                    }
                  >
                    {statesList.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
              </Tooltip>
            )}
          </form>
          {order.daily_cap !== dailyCap && status === order.order_status && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Are you sure? <br />
              You will not be able to update this order again for 4 hours.
            </Alert>
          )}
          {order.order_status !== status && status !== "paused" && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Are you sure? <br />
              You will not be able to update this order again for 4 hours.
            </Alert>
          )}
          {order.order_status !== status && status === "paused" && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Are you sure? <br />
              You will not be able to update this order again for 4 hours.
              <br />
              Some leads may still be delivered while paused.
            </Alert>
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={updateOrder}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
