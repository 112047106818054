import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import AgentSidebar from "../../../general/layout/AgentSidebar.js";
import decode from "../../../general/util/jwtDecode.js";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import customFetch from "../../../general/auth/customFetch.js";

export default function Feedback() {
  const currentUser = decode();
  const [type, setType] = useState("");
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handleClear = () => {
    setType("");
    setData("");
    setError(false);
    setSuccess(false);
  };

  const feedbackTypes = [
    { value: "feature", label: "Feature Request" },
    { value: "feedback", label: "Provide Feedback" },
    { value: "bug", label: "Report a Bug" },
  ];

  const createFeedback = async (e) => {
    e.preventDefault();
    try {
      let body = {
        type: type,
        data: data,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
      };
      const response = await customFetch(`/v1/feedback`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      console.log(response.status);
      response.status === 200 ? setSuccess(true) : setError(true);
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  return (
    <Box sx={{ textAlign: "center", p: 2 }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Feedback
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%", maxWidth: 600 }}>
          <Card>
            <CardContent>
              {error ? (
                <Alert severity="error" onClick={handleClear}>
                  There was an issue submitting your request. Please reach out
                  to support by email at support@lead2clientcrm.com and
                  reference error {new Date() / 1}
                </Alert>
              ) : success ? (
                <Alert severity="success" onClick={handleClear}>
                  Request submitted successfully
                </Alert>
              ) : (
                <Box component="form" id="create-feedback">
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="type-label">Submission Type</InputLabel>
                    <Select
                      labelId="type-label"
                      value={type}
                      onChange={handleTypeChange}
                      label="Submission Type"
                    >
                      {feedbackTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Please Provide Your Feedback"
                    type="text"
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                    multiline
                    required
                    margin="normal"
                  />
                </Box>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <Button onClick={handleClear} color="secondary">
                Clear
              </Button>
              <Button
                onClick={createFeedback}
                color="primary"
                disabled={error || success}
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
