import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Tooltip,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import decode from "../../../../general/util/jwtDecode";

export default function EditGroupDialog({ open, onClose, group, onEditGroup }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [alwaysHandleDuplicates, setAlwaysHandleDuplicates] = useState(false);

  const currentUser = decode();

  useEffect(() => {
    if (group) {
      setName(group.name);
      setDescription(group.description);
      setAlwaysHandleDuplicates(group.always_handle_duplicates || false);
    }
  }, [group]);

  const handleEdit = () => {
    onEditGroup(name, description, alwaysHandleDuplicates);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Group</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Group Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Switch
                checked={alwaysHandleDuplicates}
                onChange={(e) => setAlwaysHandleDuplicates(e.target.checked)}
              />
            }
            label="Always Handle Duplicates"
          />
          <Tooltip title="When enabled, all duplicate contacts will be marked with a duplicate tag. By default, contacts are only marked as duplicates if they are more than 30 days old in your CRM.">
            <InfoIcon color="action" sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
