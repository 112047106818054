// theme.js
import { createTheme } from "@mui/material/styles";

export const getTheme = (mode, branding = {}) => {
  return createTheme({
    typography: {
      fontFamily: "Montserrat, Arial, sans-serif",
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // Light mode colors
            primary: {
              main: branding.primaryColor || "#11053C",
            },
            secondary: {
              main: branding.secondaryColor || "#29DDFC",
            },
          }
        : {
            // Dark mode colors
            primary: {
              main: branding.primaryColor || "#A530FA",
            },
            secondary: {
              main: branding.secondaryColor || "#29DDFC",
            },
          }),
    },
  });
};

export default getTheme;
