import React, { useState, useEffect } from "react";
import { Box, Button, Card, Typography, Avatar, Grid } from "@mui/material";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";
import BannerLogo from "../../../general/layout/BannerLogo";
import LeadsBarChart from "../../../general/charts/LeadsBarChart";
import TopStatesDeliveredChart from "../../../general/charts/TopStatesDeliveredChart";
import DoublePieChart from "../../../general/charts/DoublePieChart";
import { LoomEmbed } from "../../../general/constants/LoomEmbeds";
import { useBranding } from "../../../BrandingContext";

export default function AgentDashboard() {
  const currentUser = decode();
  const [hasOrdered, setHasOrdered] = useState(false);
  const { logoFull } = useBranding();

  const getAgentOrders = async () => {
    try {
      const response = await customFetch(`/v1/orders`, {
        method: "GET",
      });
      const data = await response.json();
      data.length > 0 && setHasOrdered(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (currentUser && !currentUser.id) {
      window.location = "/login";
    }
    getAgentOrders();
  }, []);

  return (
    <div className="content">
      <Box
        sx={{
          minHeight: "500px",
          padding: 2,
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          {/* Left Section: Tenant Info and User Avatar */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <Avatar sx={{ width: 56, height: 56 }}>
                {currentUser.firstName?.charAt(0) || "A"}
              </Avatar>
              <Typography variant="h6">
                Welcome, {currentUser.firstName || "Agent"}.
              </Typography>
            </Box>
            <Typography variant="h4" gutterBottom>
              {currentUser.tenant_description || "Business in a Box"}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {currentUser.tenant_description} provides FCC 1-to-1,
              TCPA-compliant exclusive leads, delivered instantly upon
              submission. Empowering agents to immediately connect with
              prospects, enhancing responsiveness and boosting closing rates.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Key Features:</strong>
            </Typography>
            <ul>
              <li>Custom Delivery Schedules</li>
              <li>TCPA Compliant Leads</li>
              <li>Seamless CRM Integration</li>
              <li>Exclusive Aged Leads</li>
            </ul>
            {/* <Typography variant="subtitle1" gutterBottom>
              <strong>Why {currentUser.tenant_name} Stands Out:</strong>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {currentUser.tenant_name} stands apart by providing real-time lead
              delivery, customizable lead schedules, and a lead guarantee. We
              focus on user-friendly design and automation, optimizing every
              aspect of lead acquisition. Our platform is actively evolving,
              ensuring that you benefit from the latest features to stay ahead
              of the competition.
            </Typography> */}
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button variant="contained" color="primary" href="/orders">
                Create Orders
              </Button>
              <Button variant="outlined" color="primary" href="/schedules">
                Create Schedules
              </Button>
            </Box>
          </Grid>

          {/* Right Section: Logo */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={logoFull}
                alt="Company Logo"
                style={{
                  maxHeight: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {currentUser.onboarded ? (
        <Box>
          <Typography variant="h4" ml={1} mt={1} gutterBottom>
            Agent Dashboard
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent="center"
            sx={{
              "& > .dashboard-item": {
                flex: "1 1 calc(50% - 16px)",
                minWidth: "500px",
                maxWidth: "calc(50% - 16px)",
                "@media (max-width: 1023px)": {
                  flex: "1 1 100%",
                  maxWidth: "100%",
                },
              },
            }}
          >
            {currentUser.tenant_id === 1 && (
              <Box className="dashboard-item">
                <LoomEmbed />
              </Box>
            )}
            <Box className="dashboard-item">
              <TopStatesDeliveredChart />
            </Box>
            <Box className="dashboard-item">
              <LeadsBarChart />
            </Box>
            <Box className="dashboard-item">
              <DoublePieChart />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(50vh - 64px)", // Adjust this value based on the height of your nav and app bars
            textAlign: "center",
          }}
        >
          <Typography variant="h6" textAlign="center" mb={2}>
            It looks like you haven't made an order yet, head over to the orders
            page to Get More Leads!
          </Typography>
          <Button variant="contained" href="/orders">
            Take me there
          </Button>
        </Box>
      )}
    </div>
  );
}
