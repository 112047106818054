import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";

export default function DeleteSuperUser({ open, onClose, user, refreshUsers }) {
  const handleDelete = async () => {
    try {
      await customFetch(`/v1/superadmin/users/${user.id}`, {
        method: "DELETE",
      });
      refreshUsers();
      onClose();
    } catch (error) {
      console.error("Failed to delete super user:", error.message);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete Super User</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {user.first_name} {user.last_name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
