import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Divider, Button, Chip } from '@mui/material';
import EditCampaignDialog from './EditCampaignDialog';
import ScheduleDialog from './ScheduleDialog'; // Import the ScheduleDialog component
import ConfirmDialog from './ConfirmDialog'; // Import the ConfirmDialog component
import customFetch from '../../../../general/auth/customFetch';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';
import decode from '../../../../general/util/jwtDecode';


const CampaignCard = ({ campaign, bgColor, onEdit }) => {
  const currentUser = decode();
  const theme = useTheme();
  const [openEdit, setOpenEdit] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [schedule, setSchedule] = useState(campaign.schedule || null);

  const statusColors = {
    ACTIVE: theme.palette.success.main,
    PAUSED: theme.palette.warning.main,
    DELETED: theme.palette.error.main,
    PENDING: theme.palette.info.main,
    IN_REVIEW: theme.palette.warning.light,
    // Add more status types and colors as needed
  };

  const handleEditClick = () => {
    setOpenEdit(true);
  };

  const handleScheduleClick = () => {
    setOpenSchedule(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleSaveSchedule = (newSchedule) => {
    setSchedule(newSchedule);
    setOpenSchedule(false);
  };

  const handleDeleteSchedule = () => {
    setOpenConfirm(true);
  };

  const confirmDeleteSchedule = () => {
    customFetch(`/v1/partners/${currentUser.partner_id}/campaigns/${campaign.id}/schedules/${schedule.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setSchedule(null);
        setOpenConfirm(false);
        setOpenSchedule(false);
      })
      .catch((error) => console.error('Error deleting schedule:', error));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  // Format the time as "HH:MM AM/PM"
  const formatTime = (time) => moment(time, 'HH:mm:ss').format('hh:mm A');

  return (
    <>
      <Card sx={{ maxWidth: 600, width: "100%", mb: 2, backgroundColor: bgColor || 'background.paper', boxShadow: 6, padding: 2, position: 'relative' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {campaign.campaign_name || 'No Name'}
            {campaign.custom_name && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>Custom Name:</strong> {campaign.custom_name}</Typography>
              </Grid>
            )}
          </Typography>
          {campaign.status && (
            <Chip
              label={campaign.status}
              sx={{
                position: 'absolute',
                top: 32,
                right: 32,
                backgroundColor: statusColors[campaign.status] || 'grey',
                color: 'white',
              }}
            />
          )}
          <Divider sx={{ mb: 2, borderBottomWidth: 2, bgcolor: 'grey.500' }} />

          <Typography variant="h6" gutterBottom>
            General Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Campaign ID:</strong> {campaign.campaign_id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Account ID:</strong> {campaign.account_id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Objective:</strong> {campaign.objective}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Campaign Created:</strong> {new Date(campaign.start_time).toLocaleString()}</Typography>
            </Grid>
            {campaign.end_time && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>Campaign End Time:</strong> {new Date(campaign.end_time).toLocaleString()}</Typography>
              </Grid>
            )}
            {campaign.description && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>Description:</strong> {campaign.description}</Typography>
              </Grid>
            )}
            {campaign.industry && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>Industry:</strong> {campaign.industry}</Typography>
              </Grid>
            )}
            {campaign.niche && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2"><strong>Niche:</strong> {campaign.niche}</Typography>
              </Grid>
            )}
          </Grid>

          <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'grey.500' }} />

          <Typography variant="h6" gutterBottom>
            Metrics (Last 24 hours)
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Results:</strong> {campaign.results}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Reach:</strong> {campaign.reach}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Impressions:</strong> {campaign.impressions}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Cost Per Result:</strong> {formatCurrency(campaign.cost_per_result)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Amount Spent:</strong> {formatCurrency(campaign.amount_spent)}</Typography>
            </Grid>
          </Grid>

          {schedule && (
            <>
              <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'grey.500' }} />
              <Typography variant="h6" gutterBottom>
                Active Schedule
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Start Time:</strong> {formatTime(schedule.start_time)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>End Time:</strong> {formatTime(schedule.end_time)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Active Days:</strong> {schedule.active_days.join(', ')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Time Zone:</strong> {schedule.time_zone}</Typography>
                </Grid>
              </Grid>
            </>
          )}

          <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: 'grey.500' }} />

          <Typography variant="h6" gutterBottom>
            Admin Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>BIAB Active:</strong> {campaign.isactive ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Deleted from Facebook:</strong> {campaign.is_deleted ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Created Time:</strong> {new Date(campaign.created_time).toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2"><strong>Updated Time:</strong> {new Date(campaign.updated_time).toLocaleString()}</Typography>
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleEditClick}>
            Edit Campaign
          </Button>

          {schedule ? (
            <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={handleScheduleClick}>
              Edit Schedule
            </Button>
          ) : (
            <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={handleScheduleClick}>
              Create Schedule
            </Button>
          )}
        </CardContent>
      </Card>

      <EditCampaignDialog open={openEdit} onClose={handleCloseEdit} campaign={campaign} onEdit={onEdit} />
      <ScheduleDialog
        open={openSchedule}
        onClose={handleCloseSchedule}
        campaignId={campaign.id}
        schedule={schedule}
        onSave={handleSaveSchedule}
        onDelete={handleDeleteSchedule}
      />
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        onConfirm={confirmDeleteSchedule}
        title="Confirm Delete"
        content="Are you sure you want to delete this schedule?"
      />
    </>
  );
};

export default CampaignCard;
