import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

function FAQ() {
  const theme = useTheme();
  const faqs = [
    {
      question: "How many states should I add and what does it matter?",
      answer: `
        This is dependent on how quickly you would like leads. For a general guide:
          - Add 1-3 states for 10-15 leads a day
          - Add 3-5 states for 15-20 leads a day
          - Add 5-10 states for more than 20 leads a day
      `,
    },
    {
      question: "Do they turn on automatically daily?",
      answer:
        "The leads delivery will turn on daily at 7 AM EST if you have leads to be delivered.",
    },
    {
      question: "What time do my leads start every morning?",
      answer:
        "We start campaigns at 7 AM every morning. You will begin getting leads shortly after.",
    },
    {
      question: "Why did I get more than my cap?",
      answer: `
        We turn off campaigns when you have hit your cap, however, leads will often times continue to flow. 
        We can’t guarantee the cap, but the best way to slow your delivery is to use fewer states.
      `,
    },
    {
      question: "What leads do you replace?",
      answer: `
        We replace leads that meet one of the following criteria:
        - Leads are over 85 (should be confirmed on phone call)
        - Leads with a disconnected number
        - Leads that are outside of the states you requested
      `,
    },
    {
      question: "What leads are not replaced?",
      answer: `
        - Leads that never answered
        - Leads that say wrong number
      `,
    },
    {
      question: "What information is gathered in the leads?",
      answer:
        "Name, Email, City, State, Zip Code, Phone Number, Favorite Hobby, Beneficiary, Date of birth",
    },
    {
      question: "Types of leads available?",
      answer:
        "Currently only Final Expense, however, we are actively developing and testing other lead types.  Stay tuned!!!",
    },
  ];

  return (
    <div className="content">
      <Box sx={{ maxWidth: "800px", mx: "auto" }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        F.A.Q.
      </Typography>

        {faqs.map((faq, index) => (
          <Accordion
            key={index}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon  />
              }
            >
              <Typography sx={{ textAlign: "left" }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {faq.answer.split("\n").map((line, idx) => (
                <Typography key={idx} sx={{ textAlign: "left" }}>
                  {line}
                  <br />
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
}

export default FAQ;
