import jwtDecode from "jwt-decode";
import decode from "../util/jwtDecode";

// Define the handleLogout function
const handleLogout = async () => {
  const currentUser = decode();
  try {
    const body = {
      refresh: document.cookie
        .split(";")
        .find((cookie) => cookie.trim().startsWith("refreshToken="))
        .split("=")[1],
    };
    const logout = await fetch(`/v1/auth/logout`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.accessToken}`,
        Cookie: `accessToken=${currentUser.accessToken}; refreshToken=${currentUser.refreshToken};`,
      },
      body: JSON.stringify(body),
    });

    document.cookie = `accessToken=; path=/; secure; SameSite=Lax; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    document.cookie = `refreshToken=; path=/; secure; SameSite=Lax; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    window.location = "/login";
  } catch (error) {
    console.error("Logout failed: ", error);
  }
};

const customFetch = async (url, options = {}) => {
  const refreshAuthToken = async () => {
    const refreshToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("refreshToken="))
      ?.split("=")[1];

    if (!refreshToken) {
      handleLogout();
      throw new Error("No refresh token available");
    }

    const currentUser = decode();

    const response = await fetch(`/v1/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: refreshToken,
        tenantId: currentUser.tenant_id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      document.cookie = `accessToken=${data.token}; path=/; secure; SameSite=Lax; maxAge=3600;`;
      document.cookie = `refreshToken=${refreshToken}; path=/; secure; SameSite=Lax;`;
      return data.token;
    } else {
      handleLogout();
      throw new Error("Failed to refresh token");
    }
  };

  const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Date.now() / 1000;
  };

  const accessToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("accessToken="))
    ?.split("=")[1];

  if (!options.headers) {
    options.headers = {};
  }

  if (accessToken && !isTokenExpired(accessToken)) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  let response = await fetch(url, options);

  if (response.status === 403 || response.status === 401) {
    try {
      const newAccessToken = await refreshAuthToken();
      options.headers["Authorization"] = `Bearer ${newAccessToken}`;
      response = await fetch(url, options);
    } catch (error) {
      console.error("Failed to refresh token:", error);
      handleLogout();
      throw error;
    }
  }

  return response;
};

export default customFetch;
