import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { GROUP_ADMIN_ROLE_NAME } from "../../../../general/constants/constants"; // Adjust path if needed

const EditUser = ({
  open,
  onClose,
  user,
  refreshUsers,
  ghlUsers,
  unassignedGhlUsers,
}) => {
  const currentUser = decode();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_id: "",
    ghl_user_id: "",
    onboarded: false,
    crm_coupon: false,
    is_active: true,
  });
  const [roles, setRoles] = useState([]);
  const [currentGhlUser, setCurrentGhlUser] = useState(null);

  // Check if the current user is a group admin
  const isGroupAdmin = currentUser.role === GROUP_ADMIN_ROLE_NAME;

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role_id: user.role_id,
        ghl_user_id: user.ghl_user_id,
        onboarded: user.onboarded,
        crm_coupon: user.crm_coupon,
        is_active: user.is_active,
      });

      const assignedGhlUser = ghlUsers.find(
        (ghlUser) => ghlUser.id === user.ghl_user_id
      );
      setCurrentGhlUser(assignedGhlUser);
    }
  }, [user, ghlUsers]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/tenant-roles`
        );
        const jsonData = await response.json();
        setRoles(jsonData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRoles();
  }, [currentUser.tenant_id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleGhlUserChange = (event, value) => {
    setFormData({
      ...formData,
      ghl_user_id: value ? value.id : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users/${user.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      refreshUsers();
      onClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  const availableGhlUsers = currentGhlUser
    ? [...unassignedGhlUsers, currentGhlUser]
    : unassignedGhlUsers;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Role"
          name="role_id"
          select
          value={formData.role_id}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={isGroupAdmin} // Disable role selection if the user is a group admin
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          options={availableGhlUsers}
          getOptionLabel={(option) =>
            `${option.id} - ${option.first_name} ${option.last_name} - (${option.email})`
          }
          value={
            availableGhlUsers.find(
              (ghlUser) => ghlUser.id === formData.ghl_user_id
            ) || null
          }
          onChange={handleGhlUserChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="GHL User ID"
              name="ghl_user_id"
              fullWidth
              margin="normal"
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.onboarded}
              onChange={handleChange}
              name="onboarded"
            />
          }
          label="Onboarded"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.crm_coupon}
              onChange={handleChange}
              name="crm_coupon"
            />
          }
          label="CRM Coupon"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.is_active}
              onChange={handleChange}
              name="is_active"
            />
          }
          label="User Login Access"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
