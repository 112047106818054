import React, { useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useBranding } from "../../BrandingContext";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { logoFull } = useBranding();

  // Get the current domain without port number
  const domain = window.location.hostname;

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    validatePhoneNumber(e.target.value);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(number)) {
      setPhoneError("Invalid phone number. Must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneError) {
      setError("Please fix the errors before submitting.");
      return;
    }
    try {
      setLoading(true);
      setError("");
      await signup();
      setShowAlert(true);
    } catch (error) {
      console.error(error.message);
      setError("Failed to create account. Please try again.");
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const signup = async () => {
    const body = {
      firstName,
      lastName,
      email,
      phoneNumber,
      domain
    };

    try {
      const response = await fetch('/v1/auth/signup', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const result = await response.json();
        if (response.status === 400) {
          setError(result.error || "Invalid input. Please check your information.");
        } else if (response.status === 403) {
          setError("Not authorized for this domain.");
        } else {
          throw new Error(result.error || "Failed to create account. Please try again.");
        }
        return;
      }

      const result = await response.json();
      if (result.message === "User is already a member of this tenant.") {
        navigate("/login");
      }
      
    } catch (error) {
      console.error("Signup error:", error);
      throw new Error("Failed to connect to the server. Please try again later.");
    }
  };

  const isFormValid = () => {
    return (
      firstName.trim() &&
      lastName.trim() &&
      email.trim() &&
      phoneNumber.trim() &&
      !phoneError
    );
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 3,
              }}
            >
              <img
                src={logoFull}
                alt="Company Logo"
                style={{
                  maxWidth: "200px",
                  height: "auto",
                  marginBottom: "16px",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                  mb: 1,
                }}
              >
                Create your account
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                }}
              >
                Get started with lead management
              </Typography>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {showAlert ? (
              <>
                <Alert severity="success">
                  Your email ({email}) has been registered. You may now login.
                </Alert>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={handleClose}
                  fullWidth
                >
                  Continue to Login
                </Button>
              </>
            ) : (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label="First Name"
                  type="text"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="given-name"
                  inputProps={{
                    'aria-label': 'First name',
                  }}
                />
                <TextField
                  label="Last Name"
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="family-name"
                  inputProps={{
                    'aria-label': 'Last name',
                  }}
                />
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="email"
                  inputProps={{
                    'aria-label': 'Email address',
                  }}
                />
                <TextField
                  label="Phone Number"
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                  fullWidth
                  margin="normal"
                  error={!!phoneError}
                  helperText={phoneError}
                  autoComplete="tel"
                  inputProps={{
                    'aria-label': 'Phone number',
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading || !isFormValid()}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : "Create Account"}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Already have an account?{" "}
          <a
            href="/login"
            style={{
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
          >
            Sign in here
          </a>
        </Typography>
      </Box>
    </Container>
  );
}