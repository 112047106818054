import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Snackbar,
  Chip,
  IconButton,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import EditIcon from "@mui/icons-material/Edit";
import PartnerEditIntegration from "./components/PartnerEditIntegration";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const PartnerIntegrations = () => {
  const [integrations, setIntegrations] = useState([]);
  const [integrationTypes, setIntegrationTypes] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const currentUser = decode();

  useEffect(() => {
    loadIntegrations();
    loadIntegrationTypes();
  }, []);

  const loadIntegrations = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/integrations`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch integrations");
      }
      const data = await response.json();

      const processedIntegrations = data.integrations.map((integration) => ({
        ...integration,
        created_time: new Date(integration.created_time),
      }));

      setIntegrations(processedIntegrations);
    } catch (error) {
      console.error("Failed to load integrations:", error);
      setSnackbarMessage("Failed to load integrations");
    } finally {
      setLoading(false);
    }
  };

  const loadIntegrationTypes = async () => {
    try {
      const response = await customFetch("/v1/partners/integration-types");
      if (!response.ok) {
        throw new Error("Failed to fetch integration types");
      }
      const data = await response.json();
      setIntegrationTypes(data.integrationTypes);
    } catch (error) {
      console.error("Failed to load integration types:", error);
      setSnackbarMessage("Failed to load integration types");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  const handleEditClick = (integration) => {
    setSelectedIntegration(integration);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setSelectedIntegration(null);
  };

  const handleUpdateSuccess = () => {
    loadIntegrations();
    setSnackbarMessage("Integration updated successfully");
  };

  const columns = [
    { field: "name", headerName: "Integration Name", flex: 1 },
    { field: "tenant_name", headerName: "Tenant Name", flex: 1 },
    {
      field: "integration_type",
      headerName: "Integration Type",
      flex: 1,
      valueGetter: (params, row) => {
        const integrationType = integrationTypes.find(
          (type) => type.id === row.integration_type_id
        );
        return integrationType ? integrationType.name : "Unknown";
      },
    },
    { field: "owner_name", headerName: "Owner", flex: 1 },
    { field: "owner_email", headerName: "Owner Email", flex: 1.2 },
    {
      field: "connected",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Connected" : "Disconnected"}
          color={params.value ? "success" : "error"}
          variant="outlined"
        />
      ),
    },
    {
      field: "created_time",
      headerName: "Created",
      type: "date",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditClick(params.row)} color="primary">
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Partner Integrations
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        This page displays all integrations across all tenants associated with
        your partner account. You can view and manage these integrations to
        facilitate data synchronization and product catalog creation.
      </Typography>
      <div style={{ height: 600, width: "100%", marginTop: "20px" }}>
        <DataGridPro
          rows={integrations}
          columns={columns}
          pageSize={10}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: CustomToolbar,
            headerFilterMenu: null,
          }}
        />
      </div>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
      <PartnerEditIntegration
        open={editDialogOpen}
        onClose={handleEditClose}
        integration={selectedIntegration}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Container>
  );
};

export default PartnerIntegrations;
