import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

const EditAdSetDialog = ({ open, onClose, adSet, onEdit }) => {
  const [formState, setFormState] = useState({
    custom_name: adSet.custom_name || "",
    cost_per_result_threshold:
      adSet.cost_per_result_threshold?.toString() || "",
    amount_spent_threshold: adSet.amount_spent_threshold?.toString() || "",
    force_enable: adSet.force_enable || false,
    force_disable: adSet.force_disable || false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const updatedAdSet = {
      custom_name: formState.custom_name.trim() || null,
      cost_per_result_threshold:
        formState.cost_per_result_threshold.trim() !== ""
          ? parseFloat(formState.cost_per_result_threshold)
          : null,
      amount_spent_threshold:
        formState.amount_spent_threshold.trim() !== ""
          ? parseFloat(formState.amount_spent_threshold)
          : null,
      force_enable: formState.force_enable,
      force_disable: formState.force_disable,
    };

    onEdit(adSet.id, updatedAdSet);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Ad Set</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Custom Name"
          name="custom_name"
          value={formState.custom_name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Cost Per Result Threshold"
          name="cost_per_result_threshold"
          type="number"
          value={formState.cost_per_result_threshold}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Amount Spent Threshold"
          name="amount_spent_threshold"
          type="number"
          value={formState.amount_spent_threshold}
          onChange={handleChange}
          fullWidth
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Force Enable</InputLabel>
          <Select
            name="force_enable"
            label="Force Enable"
            value={formState.force_enable}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel>Force Disable</InputLabel>
          <Select
            name="force_disable"
            label="Force Disable"
            value={formState.force_disable}
            onChange={handleChange}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdSetDialog;
