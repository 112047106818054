import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Box,
} from "@mui/material";
import { statesList } from "../../../../general/util/statesList";
import moment from "moment-timezone";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const initialScheduleState = {
  name: "",
  description: "",
  start_time: "08:00",
  end_time: "17:00",
  states: [],
  timezone: "",
  is_active: true,
  active_days: [],
};

const CreateSchedule = ({
  open,
  handleClose,
  fetchSchedules,
  products,
  selectedProduct,
  usedStatesByDay,
}) => {
  const currentUser = decode();

  const [newSchedule, setNewSchedule] = useState(initialScheduleState);

  const usTimezones = moment.tz
    .names()
    .filter((tz) => tz.startsWith("US/"))
    .map((tz) => {
      const offset = moment.tz(tz).format("Z");
      return { label: `${tz.replace("US/", "")} (UTC${offset})`, value: tz };
    });

  // Determine if the selected product is an agent-adset type
  const isAgentAdset = products[selectedProduct]?.product_key === "agent-adset";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSchedule((prevSchedule) => ({ ...prevSchedule, [name]: value }));
  };

  const handleCreateSchedule = async () => {
    if (
      !newSchedule.name ||
      !newSchedule.description ||
      !newSchedule.start_time ||
      !newSchedule.end_time ||
      (!isAgentAdset && !newSchedule.states.length) || // Validate states only if not agent-adset
      !newSchedule.timezone ||
      !newSchedule.active_days.length
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      const response = await customFetch(
        `/v1/users/${currentUser.tenant_user_id}/products/${products[selectedProduct].id}/schedules`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newSchedule),
        }
      );
      if (response.status === 201) {
        fetchSchedules(products[selectedProduct].id);
        setNewSchedule(initialScheduleState); // Reset the form
        handleClose();
      } else {
        const errorData = await response.json();
        console.error("Error creating schedule", errorData);
      }
    } catch (error) {
      console.error("Error creating schedule", error);
    }
  };

  const isStateAvailable = (state) => {
    return !newSchedule.active_days.some((day) =>
      usedStatesByDay[day]?.includes(state)
    );
  };

  const isDayAvailable = (day) => {
    return !newSchedule.states.some((state) =>
      usedStatesByDay[day]?.includes(state)
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create a New Schedule</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={newSchedule.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          value={newSchedule.description}
          onChange={handleInputChange}
        />
        <Box display="flex" justifyContent="space-between">
          <TextField
            margin="dense"
            name="start_time"
            label="Start Time"
            type="time"
            value={newSchedule.start_time}
            onChange={handleInputChange}
            fullWidth
            sx={{ flex: 1, marginRight: 1 }}
          />
          <TextField
            margin="dense"
            name="end_time"
            label="End Time"
            type="time"
            value={newSchedule.end_time}
            onChange={handleInputChange}
            fullWidth
            sx={{ flex: 1, marginRight: 1 }}
          />
          <Autocomplete
            options={usTimezones}
            getOptionLabel={(option) => option.label}
            value={usTimezones.find((tz) => tz.value === newSchedule.timezone)}
            onChange={(event, newValue) =>
              setNewSchedule((prevSchedule) => ({
                ...prevSchedule,
                timezone: newValue?.value || "",
              }))
            }
            renderInput={(params) => (
              <TextField {...params} label="Timezone" margin="dense" />
            )}
            fullWidth
            sx={{ flex: 1 }}
          />
        </Box>
        {!isAgentAdset && ( // Conditionally render states field
          <Autocomplete
            multiple
            options={statesList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            value={statesList.filter((state) =>
              newSchedule.states.includes(state.value)
            )}
            onChange={(event, newValue) =>
              setNewSchedule((prevSchedule) => ({
                ...prevSchedule,
                states: newValue.map((state) => state.value),
              }))
            }
            renderOption={(props, option, { selected }) => {
              const isDisabled = !isStateAvailable(option.value);
              return (
                <Tooltip
                  title={
                    isDisabled
                      ? "State is already scheduled for one or more selected days"
                      : ""
                  }
                  arrow
                >
                  <span>
                    <li
                      {...props}
                      style={{
                        backgroundColor: isDisabled
                          ? "rgba(0, 0, 0, 0.08)"
                          : "inherit",
                        color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "inherit",
                        pointerEvents: isDisabled ? "none" : "auto",
                      }}
                    >
                      <Checkbox
                        checked={selected}
                        style={{ marginRight: 8 }}
                        disabled={isDisabled}
                      />
                      {option.label}
                    </li>
                  </span>
                </Tooltip>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="States" margin="dense" fullWidth />
            )}
          />
        )}
        <Autocomplete
          multiple
          options={[
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ]}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={newSchedule.active_days}
          onChange={(event, newValue) =>
            setNewSchedule((prevSchedule) => ({
              ...prevSchedule,
              active_days: newValue,
            }))
          }
          renderOption={(props, option, { selected }) => {
            const isDisabled = !isDayAvailable(option);
            return (
              <Tooltip
                title={
                  isDisabled
                    ? "One or more selected states already have a schedule for this day"
                    : ""
                }
                arrow
              >
                <span>
                  <li
                    {...props}
                    style={{
                      backgroundColor: isDisabled
                        ? "rgba(0, 0, 0, 0.08)"
                        : "inherit",
                      color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "inherit",
                      pointerEvents: isDisabled ? "none" : "auto",
                    }}
                  >
                    <Checkbox
                      checked={selected}
                      style={{ marginRight: 8 }}
                      disabled={isDisabled}
                    />
                    {option}
                  </li>
                </span>
              </Tooltip>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Active Days"
              margin="dense"
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={newSchedule.is_active}
              onChange={(e) =>
                setNewSchedule((prevSchedule) => ({
                  ...prevSchedule,
                  is_active: e.target.checked,
                }))
              }
              color="primary"
            />
          }
          label="Active"
        />
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleCreateSchedule}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSchedule;
