import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AdminButtons from "./components/AdminButtons.js";
import decode from "../../../general/util/jwtDecode.js";
import { LicenseInfo } from "@mui/x-license";
import StatesBarChart from "../../../general/charts/StatesBarChart.js";
import TopAgentsChart from "../../../general/charts/TopAgentsChart.js";
import TopAgentsNeededLeadsChart from "../../../general/charts/TopAgentsNeededLeadsChart.js";
import TopStatesDeliveredChart from "../../../general/charts/TopStatesDeliveredChart.js";
import LeadsBarChart from "../../../general/charts/LeadsBarChart.js";
import DoublePieChart from "../../../general/charts/DoublePieChart.js";
import LeadsByStateAndAgeChart from "../../../general/charts/LeadsByStateAndAgeChart.js";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AdminDashboard() {
  const currentUser = decode();

  useEffect(() => {
    if (currentUser && !currentUser.id) {
      window.location = "/login";
    } else if (currentUser.role !== "Tenant Admin") {
      window.location = "/";
    }
  }, []);

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Dashboard
      </Typography>
      <AdminButtons />

      <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <TopStatesDeliveredChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <StatesBarChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <TopAgentsChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <TopAgentsNeededLeadsChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <LeadsBarChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <DoublePieChart />
        </Box>
        <Box flexBasis="calc(50% - 32px)" minWidth={500} mb={0}>
          <LeadsByStateAndAgeChart />
        </Box>
      </Box>
    </div>
  );
}
