import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import decode from "../../../../general/util/jwtDecode";

export default function AddMappingGroupDialog({ open, onClose, onAddGroup }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const currentUser = decode();

  const handleSubmit = () => {
    if (name.trim()) {
      onAddGroup(name.trim(), description.trim(), currentUser.tenant_id);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Mapping Group</DialogTitle>
      <DialogContent>
        <TextField
          label="Group Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="dense"
        />
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
