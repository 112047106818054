import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Chip,
  FormHelperText,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { GROUP_ADMIN_ROLE_NAME } from "../../../../general/constants/constants";

const CreateUser = ({ open, onClose, refreshUsers, ghlUsers }) => {
  const currentUser = decode();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role_id: "",
    ghl_user_id: "",
    onboarded: false,
    crm_coupon: false,
    groups: [], // New state for selected groups
  });
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]); // State to hold the list of groups
  const [groupError, setGroupError] = useState(false); // State for group selection error

  const isGroupAdmin = currentUser.role === GROUP_ADMIN_ROLE_NAME;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/tenant-roles`
        );
        const jsonData = await response.json();
        setRoles(jsonData);
      } catch (error) {
        console.error(error.message);
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/groups`
        );
        const jsonData = await response.json();
        setGroups(jsonData);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRoles();
    fetchGroups();
  }, [currentUser.tenant_id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "role_id" && value !== formData.role_id) {
      // Reset groups and group error if the role changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        groups: [],
      }));
      setGroupError(false);
    }
  };

  const handleGhlUserChange = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ghl_user_id: value ? value.id : "",
    }));
  };

  const handleGroupsChange = (event, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      groups: value,
    }));
    if (isGroupAdmin && value.length === 0) {
      setGroupError(true);
    } else {
      setGroupError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isGroupAdmin && formData.groups.length === 0) {
      setGroupError(true);
      return;
    }

    try {
      const payload = {
        ...formData,
        groups: formData.groups.map((group) => group.id), // Include selected group IDs in the payload
      };

      await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      refreshUsers();
      onClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Role"
          name="role_id"
          select
          value={formData.role_id}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          options={ghlUsers}
          getOptionLabel={(option) =>
            `${option.first_name} ${option.last_name} - (${option.email})`
          }
          value={
            ghlUsers.find((user) => user.id === formData.ghl_user_id) || null
          }
          onChange={handleGhlUserChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="GHL User ID"
              name="ghl_user_id"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Autocomplete
          multiple
          options={groups}
          getOptionLabel={(option) => option.name}
          value={formData.groups}
          onChange={handleGroupsChange}
          renderTags={(selectedGroups, getTagProps) =>
            selectedGroups.map((group, index) => (
              <Chip
                key={group.id}
                label={group.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Groups"
              name="groups"
              placeholder="Select groups"
              fullWidth
              margin="normal"
              error={groupError}
              helperText={
                groupError
                  ? "At least one group must be selected for Group Admins"
                  : ""
              }
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.onboarded}
              onChange={handleChange}
              name="onboarded"
            />
          }
          label="Onboarded"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.crm_coupon}
              onChange={handleChange}
              name="crm_coupon"
            />
          }
          label="CRM Coupon"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUser;
