import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import { statesList } from "../../../../general/util/statesList";
import { timezonesList } from "../../../../general/util/timezonesList";
import { timezoneData } from "../../../../general/util/timezoneData";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { nextProductOrderUp } from "../../../../general/util/nextUp";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EditLead({ lead, productsList, updateRow }) {
  const [name, setName] = useState(lead.name);
  const [email, setEmail] = useState(lead.email);
  const [phone, setPhone] = useState(lead.phone);
  const [dob, setDob] = useState(new Date(lead.dob));
  const [city, setCity] = useState(lead.city);
  const [state, setState] = useState(lead.state);
  const [timezone, setTimezone] = useState(lead.timezone);
  const [zip, setZip] = useState(lead.zip);
  const [product, setProduct] = useState(lead.product_id || "");
  const [productKey, setProductKey] = useState(lead.product_key || "");
  const [order, setOrder] = useState(lead.ref_order || "");
  const [ordersList, setOrdersList] = useState([]);
  const [location, setLocation] = useState({
    city: lead.city,
    state: lead.state,
    timezone: lead.timezone,
  });
  const [show, setShow] = useState(false);
  const [customFields, setCustomFields] = useState(
    Object.entries(lead.custom_fields || {}).map(([key, value]) => ({
      key,
      value,
    }))
  );
  const currentUser = decode();

  const handleClose = () => setShow(false);

  const handleShow = async () => {
    setShow(true);
    if (zip) {
      setLocationAsync();
    }
    if (lead.product_id && lead.state) {
      await loadInitialOrdersList();
    }
    autoFillLocation();
  };

  const loadInitialOrdersList = async () => {
    try {
      const nextUpOrders = await nextProductOrderUp(
        productKey === "agent-adset" ? null : lead.state,
        lead.product_id
      );
      const currentOrderOption = {
        value: lead.ref_order,
        label: `Current Order: ${lead.ref_order}`,
      };
      const updatedOrdersList = [
        currentOrderOption,
        ...nextUpOrders.filter((o) => o.value !== lead.ref_order),
      ];
      setOrdersList(updatedOrdersList);
    } catch (error) {
      console.error("Failed to load initial orders list:", error);
    }
  };

  const handleProductChange = async (event, newValue) => {
    const newProductId = newValue ? newValue.id : "";
    const newProductKey = newValue ? newValue.product_key : "";
    setProduct(newProductId);
    setProductKey(newProductKey);

    if (newProductId) {
      await updateOrdersList(state, newProductId, newProductKey);
    } else {
      setOrdersList([]);
    }
  };

  const handleStateChange = async (event) => {
    const newState = event.target.value;
    setState(newState);
    if (product && newState) {
      await updateOrdersList(newState, product, productKey);
    }
  };

  const handleTimezoneChange = (event) => setTimezone(event.target.value);

  const getProductName = (productId) => {
    const product = productsList.find((p) => p.id === productId);
    return product ? product.name : "Unknown";
  };

  function parseISODateString(isoString) {
    const date = new Date(isoString);
    return isNaN(date.getTime()) ? null : date; // Check if date is invalid
  }

  const updateLead = async () => {
    try {
      const custom_fields = customFields.reduce((acc, field) => {
        if (field.key && field.value) {
          acc[field.key] = field.value;
        }
        return acc;
      }, {});

      let body = {
        name,
        email,
        phone,
        dob: dob.toISOString(),
        city,
        state,
        zip,
        timezone,
        custom_fields,
        product_id: parseInt(product),
        ref_order: parseInt(order),
        product_key: productKey, // Include the updated product_key
      };
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/leads/${lead.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        const updatedLead = await response.json();
        updateRow({
          ...updatedLead,
          created: updatedLead.created
            ? parseISODateString(updatedLead.created)
            : null,
          reference_type: getProductName(updatedLead.product_id),
          first_name: updatedLead.first_name,
          last_name: updatedLead.last_name,
        });
        handleClose();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setLocationAsync = () => {
    try {
      const locationObj = timezoneData.find((tz) => tz.zipcode === zip);
      locationObj && setLocation(locationObj);
    } catch (error) {
      console.error(error);
    }
  };

  const autoFillLocation = () => {
    try {
      setCity(location.city);
      setState(location.state);
      setTimezone(location.timezone);
    } catch (error) {
      console.error(error);
    }
  };

  const updateOrdersList = async (currentState, currentProduct, currentProductKey) => {
    try {
      const nextUpOrders = await nextProductOrderUp(
        currentProductKey === "agent-adset" ? null : currentState,
        currentProduct
      );

      // Check if the current order is still valid for the new product/state combination
      const currentOrderStillValid = nextUpOrders.some(
        (o) => o.value === order
      );

      let updatedOrdersList;
      if (currentOrderStillValid) {
        updatedOrdersList = [
          { value: order, label: `Current Order: ${order}` },
          ...nextUpOrders.filter((o) => o.value !== order),
        ];
      } else {
        updatedOrdersList = nextUpOrders;
        // Reset the order if it's no longer valid
        setOrder("");
      }

      setOrdersList(updatedOrdersList);
    } catch (error) {
      console.error("Failed to update orders list:", error);
    }
  };

  const handleCustomFieldChange = (index, field, value) => {
    const updatedFields = [...customFields];
    updatedFields[index][field] = value;
    setCustomFields(updatedFields);
  };

  const addCustomField = () => {
    setCustomFields([...customFields, { key: "", value: "" }]);
  };

  const removeCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  return (
    <>
      <IconButton onClick={handleShow}>
        <EditIcon />
      </IconButton>

      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Edit Lead</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="dense"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={dob}
              onChange={(newDate) => setDob(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="dense" />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Zip Code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            margin="dense"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              value={state}
              label="State"
              onChange={handleStateChange}
            >
              {statesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="timezone-label">Timezone</InputLabel>
            <Select
              labelId="timezone-label"
              value={timezone}
              label="Timezone"
              onChange={handleTimezoneChange}
            >
              {timezonesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {customFields.map((field, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Key"
                  value={field.key}
                  onChange={(e) =>
                    handleCustomFieldChange(index, "key", e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Value"
                  value={field.value}
                  onChange={(e) =>
                    handleCustomFieldChange(index, "value", e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => removeCustomField(index)}
                  aria-label="delete"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addCustomField}
            sx={{ marginTop: 2 }}
          >
            Add Custom Field
          </Button>

          <Autocomplete
            fullWidth
            options={productsList}
            getOptionLabel={(option) => option.name}
            value={productsList.find((option) => option.id === product) || null}
            onChange={handleProductChange}
            renderInput={(params) => (
              <TextField {...params} label="Product" margin="dense" />
            )}
          />
          <Autocomplete
            fullWidth
            options={ordersList}
            getOptionLabel={(option) => option.label}
            value={ordersList.find((option) => option.value === order) || null}
            onChange={(event, newValue) => {
              setOrder(newValue ? newValue.value : "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Assigned Order" margin="dense" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={updateLead} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
