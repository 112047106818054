import React, { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const RepushLead = ({ lead, updateRow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasCrmId, setHasCrmId] = useState(lead.crm_id !== null); // Initial state based on lead.crm_id
  const currentUser = decode();

  useEffect(() => {
    setHasCrmId(lead.crm_id !== null); // Update state if lead.crm_id changes
  }, [lead.crm_id]);

  const handleRepush = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/leads/${lead.id}/repush`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to repush lead");
      }

      const updatedLead = await response.json();
      updateRow(updatedLead); // Pass the entire updatedLead object

      if (updatedLead.crm_id) {
        setHasCrmId(true); // Hide the icon if crm_id is successfully returned
      }
    } catch (error) {
      console.error("Error repushing lead:", error);
      // You might want to add some error handling here, like showing a snackbar
    } finally {
      setIsLoading(false);
    }
  };

  return (
    !hasCrmId && (
      <Tooltip title="Repush to CRM">
        <IconButton onClick={handleRepush} disabled={isLoading}>
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    )
  );
};

export default RepushLead;
