import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  Chip,
} from "@mui/material";

function OverrunOrderSummary({ orderDetails }) {
  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%" }}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Order Summary
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {orderDetails.productName}
            </Typography>
            <Typography mb={1} variant="body2" color="text.secondary">
              Price per token: <Chip label={`$${orderDetails.price.toFixed(2)}`} size="small" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Quantity: <Chip label={orderDetails.quantity} size="small" />
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="subtitle1">Subtotal</Typography>
          <Typography variant="subtitle1">
            ${(orderDetails.quantity * orderDetails.price).toFixed(2)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            ${(orderDetails.quantity * orderDetails.price).toFixed(2)}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
}

export default OverrunOrderSummary;