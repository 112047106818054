import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function AddGroupMemberDialog({
  open,
  onClose,
  onAddMember,
  groupMembers,
}) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const currentUser = decode();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`,
          { method: "GET" }
        );
        const data = await response.json();
        const filteredUsers = data.filter(
          (user) => !groupMembers.some((member) => member.id === user.id)
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (open) {
      fetchUsers();
    }
  }, [groupMembers, currentUser.tenant_id, open]);

  const handleAdd = () => {
    onAddMember(selectedUsers.map((user) => user.id));
    setSelectedUsers([]);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Group Member</DialogTitle>
      <DialogContent>
        {users.length === 0 ? (
          <Typography>
            No users available to add. All users are already members of this
            group.
          </Typography>
        ) : (
          <Box mt={2}>
            <Autocomplete
              multiple
              id="user-select"
              options={users}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name} (${option.email})`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Search and select users"
                  placeholder="Start typing to search..."
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={`${option.first_name} ${option.last_name}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
              value={selectedUsers}
              onChange={(event, newValue) => {
                setSelectedUsers(newValue);
              }}
              filterSelectedOptions
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={selectedUsers.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
