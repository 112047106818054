import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import customFetch from "../auth/customFetch";

const TopAgentsNeededLeadsChart = () => {
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customFetch(
          "/v1/dashboards/top-agents-needed-leads"
        );
        const data = await response.json();

        const agentNames = data.map((item) => item.agent_name);
        const neededLeads = data.map((item) => item.needed_leads);

        setChartData([{ data: neededLeads }]);
        setXAxisData(agentNames);
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
      <Card sx={{ maxWidth: 900, width: "100%" }}>
        <CardHeader
          title="Top 10 Agents by Needed Leads (Last 90 Days)"
          titleTypographyProps={{ align: 'left', noWrap: true, style: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
        />
        <CardContent>
          <BarChart
            series={chartData}
            height={400}
            xAxis={[{ data: xAxisData, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default TopAgentsNeededLeadsChart;
