// Path: src/components/shared/UpdateBranding.jsx
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  Box,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";

export const UpdateBranding = ({
  open,
  onClose,
  title,
  initialData,
  onSubmit,
  submitButtonText = "Update Branding",
}) => {
  const [lightPrimaryColor, setLightPrimaryColor] = useState("#ffffff");
  const [lightSecondaryColor, setLightSecondaryColor] = useState("#ffffff");
  const [darkPrimaryColor, setDarkPrimaryColor] = useState("#ffffff");
  const [darkSecondaryColor, setDarkSecondaryColor] = useState("#ffffff");
  const [lightLogoIconName, setLightLogoIconName] = useState("No file chosen");
  const [lightLogoName, setLightLogoName] = useState("No file chosen");
  const [darkLogoIconName, setDarkLogoIconName] = useState("No file chosen");
  const [darkLogoName, setDarkLogoName] = useState("No file chosen");
  const [existingLightLogoIcon, setExistingLightLogoIcon] = useState(false);
  const [existingLightLogo, setExistingLightLogo] = useState(false);
  const [existingDarkLogoIcon, setExistingDarkLogoIcon] = useState(false);
  const [existingDarkLogo, setExistingDarkLogo] = useState(false);

  const [lightLogoIcon, setLightLogoIcon] = useState(null);
  const [lightLogo, setLightLogo] = useState(null);
  const [darkLogoIcon, setDarkLogoIcon] = useState(null);
  const [darkLogo, setDarkLogo] = useState(null);
  const [customDomain, setCustomDomain] = useState("");
  const [colorPickerOpen, setColorPickerOpen] = useState(null);
  const [enableDarkMode, setEnableDarkMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (initialData) {
      setLightPrimaryColor(initialData.light_primary_color || "#ffffff");
      setLightSecondaryColor(initialData.light_secondary_color || "#ffffff");
      setDarkPrimaryColor(initialData.dark_primary_color || "#ffffff");
      setDarkSecondaryColor(initialData.dark_secondary_color || "#ffffff");

      setExistingLightLogoIcon(!!initialData.light_logo_icon_url);
      setExistingLightLogo(!!initialData.light_logo_url);
      setExistingDarkLogoIcon(!!initialData.dark_logo_icon_url);
      setExistingDarkLogo(!!initialData.dark_logo_url);

      setLightLogoIconName(
        initialData.light_logo_icon_url
          ? "Current logo icon will be used"
          : "No file chosen"
      );
      setLightLogoName(
        initialData.light_logo_url
          ? "Current logo will be used"
          : "No file chosen"
      );
      setDarkLogoIconName(
        initialData.dark_logo_icon_url
          ? "Current dark logo icon will be used"
          : "No file chosen"
      );
      setDarkLogoName(
        initialData.dark_logo_url
          ? "Current dark logo will be used"
          : "No file chosen"
      );

      setCustomDomain(initialData.custom_domain || "");
      setEnableDarkMode(
        !!initialData.dark_primary_color || !!initialData.dark_secondary_color
      );
    }
  }, [initialData]);

  const validateCustomDomain = (domain) => {
    const fqdnPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (domain.includes("http://") || domain.includes("https://")) {
      return false;
    }
    return fqdnPattern.test(domain);
  };

  const handleSubmit = async () => {
    if (customDomain && !validateCustomDomain(customDomain)) {
      setErrorMessage("Please enter a valid custom domain");
      return;
    }

    const formData = new FormData();

    if (lightLogoIcon instanceof File) {
      formData.append("light_logo_icon", lightLogoIcon);
    }
    if (lightLogo instanceof File) {
      formData.append("light_logo", lightLogo);
    }
    if (enableDarkMode) {
      if (darkLogoIcon instanceof File) {
        formData.append("dark_logo_icon", darkLogoIcon);
      }
      if (darkLogo instanceof File) {
        formData.append("dark_logo", darkLogo);
      }
      formData.append("dark_primary_color", darkPrimaryColor);
      formData.append("dark_secondary_color", darkSecondaryColor);
    }
    formData.append("light_primary_color", lightPrimaryColor);
    formData.append("light_secondary_color", lightSecondaryColor);
    formData.append("custom_domain", customDomain);

    try {
      await onSubmit(formData);
      onClose();
    } catch (error) {
      setErrorMessage(
        error.message || "An error occurred while updating branding"
      );
    }
  };

  const handleFileSelect = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    const fileHandlers = {
      lightLogoIcon: {
        setName: setLightLogoIconName,
        setFile: setLightLogoIcon,
        existing: existingLightLogoIcon,
      },
      lightLogo: {
        setName: setLightLogoName,
        setFile: setLightLogo,
        existing: existingLightLogo,
      },
      darkLogoIcon: {
        setName: setDarkLogoIconName,
        setFile: setDarkLogoIcon,
        existing: existingDarkLogoIcon,
      },
      darkLogo: {
        setName: setDarkLogoName,
        setFile: setDarkLogo,
        existing: existingDarkLogo,
      },
    };

    const handler = fileHandlers[name];
    if (handler) {
      if (file) {
        handler.setName(file.name);
        handler.setFile(file);
      } else {
        handler.setName(
          handler.existing ? "Current file will be used" : "No file chosen"
        );
        handler.setFile(null);
      }
    }
  };

  const validateHex = (value) => /^#([0-9A-F]{3}){1,2}$/i.test(value);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        {errorMessage && (
          <Typography color="error" variant="body2" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}
        {/* Custom Domain */}
        <Box sx={{ my: 2 }}>
          <InputLabel>Custom Domain</InputLabel>
          <TextField
            margin="dense"
            name="customDomain"
            label="Custom Domain"
            type="text"
            fullWidth
            value={customDomain}
            error={Boolean(customDomain && !validateCustomDomain(customDomain))}
            helperText={
              customDomain && !validateCustomDomain(customDomain)
                ? "Enter a valid custom domain (e.g., subdomain.example.com)"
                : ""
            }
            onChange={(e) => setCustomDomain(e.target.value)}
          />
        </Box>

        {/* Light Mode Section */}
        <Box sx={{ my: 4 }}>
          <InputLabel sx={{ fontWeight: "bold" }}>Light Mode</InputLabel>

          <Box>
            {/* Light Mode Logo Icon */}
            <InputLabel>Light Mode Logo Icon</InputLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                component="label"
                sx={{ marginRight: 2 }}
              >
                {existingLightLogoIcon ? "Change File" : "Choose File"}
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/svg+xml"
                  name="lightLogoIcon"
                  onChange={handleFileSelect}
                  hidden
                />
              </Button>
              <Typography variant="body2" color="textSecondary">
                {existingLightLogoIcon && !lightLogoIcon
                  ? "Current logo icon will be used"
                  : lightLogoIconName}
              </Typography>
            </Box>

            {/* Light Mode Logo */}
            <InputLabel>Light Mode Logo</InputLabel>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                component="label"
                sx={{ marginRight: 2 }}
              >
                {existingLightLogo ? "Change File" : "Choose File"}
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/svg+xml"
                  name="lightLogo"
                  onChange={handleFileSelect}
                  hidden
                />
              </Button>
              <Typography variant="body2" color="textSecondary">
                {existingLightLogo && !lightLogo
                  ? "Current logo will be used"
                  : lightLogoName}
              </Typography>
            </Box>
          </Box>

          {/* Light Primary Color */}
          <Box sx={{ my: 2 }}>
            {/* <InputLabel>Light Mode Primary Color</InputLabel> */}
            <Box display="flex" alignItems="center">
              <TextField
                margin="dense"
                name="lightPrimaryColor"
                label="Light Primary Color"
                type="text"
                fullWidth
                value={lightPrimaryColor}
                error={!validateHex(lightPrimaryColor)}
                helperText={
                  !validateHex(lightPrimaryColor)
                    ? "Enter a valid HEX color code (e.g., #FFFFFF)"
                    : ""
                }
                onChange={(e) => setLightPrimaryColor(e.target.value)}
              />
              <Tooltip title="Pick Color">
                <IconButton
                  onClick={() =>
                    setColorPickerOpen((prev) =>
                      prev === "lightPrimary" ? null : "lightPrimary"
                    )
                  }
                >
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: lightPrimaryColor,
                      border: "1px solid #000",
                      marginLeft: 2,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {colorPickerOpen === "lightPrimary" && (
              <Box sx={{ position: "relative", zIndex: 1, marginTop: 2 }}>
                <HexColorPicker
                  color={lightPrimaryColor}
                  onChange={setLightPrimaryColor}
                />
              </Box>
            )}
          </Box>

          {/* Light Secondary Color */}
          <Box sx={{ my: 2 }}>
            {/* <InputLabel>Light Mode Secondary Color</InputLabel> */}
            <Box display="flex" alignItems="center">
              <TextField
                margin="dense"
                name="lightSecondaryColor"
                label="Light Secondary Color"
                type="text"
                fullWidth
                value={lightSecondaryColor}
                error={!validateHex(lightSecondaryColor)}
                helperText={
                  !validateHex(lightSecondaryColor)
                    ? "Enter a valid HEX color code (e.g., #FFFFFF)"
                    : ""
                }
                onChange={(e) => setLightSecondaryColor(e.target.value)}
              />
              <Tooltip title="Pick Color">
                <IconButton
                  onClick={() =>
                    setColorPickerOpen((prev) =>
                      prev === "lightSecondary" ? null : "lightSecondary"
                    )
                  }
                >
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: lightSecondaryColor,
                      border: "1px solid #000",
                      marginLeft: 2,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {colorPickerOpen === "lightSecondary" && (
              <Box sx={{ position: "relative", zIndex: 1, marginTop: 2 }}>
                <HexColorPicker
                  color={lightSecondaryColor}
                  onChange={setLightSecondaryColor}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Enable Dark Mode Section */}
        <Box sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enableDarkMode}
                onChange={(e) => setEnableDarkMode(e.target.checked)}
              />
            }
            label="Enable Dark Mode Branding"
          />
        </Box>

        {enableDarkMode && (
          <>
            {/* Dark Mode Section */}
            <Box sx={{ my: 4 }}>
              <InputLabel sx={{ fontWeight: "bold" }}>Dark Mode</InputLabel>
              <Box>
                {/* Dark Mode Logo Icon */}
                <InputLabel>Dark Mode Logo Icon (optional)</InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    component="label"
                    sx={{ marginRight: 2 }}
                  >
                    {existingDarkLogoIcon ? "Change File" : "Choose File"}
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/svg+xml"
                      name="darkLogoIcon"
                      onChange={handleFileSelect}
                      hidden
                    />
                  </Button>
                  <Typography variant="body2" color="textSecondary">
                    {existingDarkLogoIcon && !darkLogoIcon
                      ? "Current dark logo icon will be used"
                      : darkLogoIconName}
                  </Typography>
                </Box>

                {/* Dark Mode Logo */}
                <InputLabel>Dark Mode Logo (optional)</InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    component="label"
                    sx={{ marginRight: 2 }}
                  >
                    {existingDarkLogo ? "Change File" : "Choose File"}
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/svg+xml"
                      name="darkLogo"
                      onChange={handleFileSelect}
                      hidden
                    />
                  </Button>
                  <Typography variant="body2" color="textSecondary">
                    {existingDarkLogo && !darkLogo
                      ? "Current dark logo will be used"
                      : darkLogoName}
                  </Typography>
                </Box>
              </Box>
              {/* Dark Primary Color */}
              <Box sx={{ my: 2 }}>
                {/* <InputLabel>Dark Mode Primary Color</InputLabel> */}
                <Box display="flex" alignItems="center">
                  <TextField
                    margin="dense"
                    name="darkPrimaryColor"
                    label="Dark Primary Color"
                    type="text"
                    fullWidth
                    value={darkPrimaryColor}
                    error={!validateHex(darkPrimaryColor)}
                    helperText={
                      !validateHex(darkPrimaryColor)
                        ? "Enter a valid HEX color code (e.g., #FFFFFF)"
                        : ""
                    }
                    onChange={(e) => setDarkPrimaryColor(e.target.value)}
                  />
                  <Tooltip title="Pick Color">
                    <IconButton
                      onClick={() =>
                        setColorPickerOpen((prev) =>
                          prev === "darkPrimary" ? null : "darkPrimary"
                        )
                      }
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          backgroundColor: darkPrimaryColor,
                          border: "1px solid #000",
                          marginLeft: 2,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                {colorPickerOpen === "darkPrimary" && (
                  <Box sx={{ position: "relative", zIndex: 1, marginTop: 2 }}>
                    <HexColorPicker
                      color={darkPrimaryColor}
                      onChange={setDarkPrimaryColor}
                    />
                  </Box>
                )}
              </Box>

              {/* Dark Secondary Color */}
              <Box sx={{ my: 2 }}>
                {/* <InputLabel>Dark Mode Secondary Color</InputLabel> */}
                <Box display="flex" alignItems="center">
                  <TextField
                    margin="dense"
                    name="darkSecondaryColor"
                    label="Dark Secondary Color"
                    type="text"
                    fullWidth
                    value={darkSecondaryColor}
                    error={!validateHex(darkSecondaryColor)}
                    helperText={
                      !validateHex(darkSecondaryColor)
                        ? "Enter a valid HEX color code (e.g., #FFFFFF)"
                        : ""
                    }
                    onChange={(e) => setDarkSecondaryColor(e.target.value)}
                  />
                  <Tooltip title="Pick Color">
                    <IconButton
                      onClick={() =>
                        setColorPickerOpen((prev) =>
                          prev === "darkSecondary" ? null : "darkSecondary"
                        )
                      }
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          backgroundColor: darkSecondaryColor,
                          border: "1px solid #000",
                          marginLeft: 2,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                {colorPickerOpen === "darkSecondary" && (
                  <Box sx={{ position: "relative", zIndex: 1, marginTop: 2 }}>
                    <HexColorPicker
                      color={darkSecondaryColor}
                      onChange={setDarkSecondaryColor}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update Branding
        </Button>
      </DialogActions>
    </Dialog>
  );
};
