import React, { useState, useEffect, useCallback } from "react";
import decode from "../../../general/util/jwtDecode";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import customFetch from "../../../general/auth/customFetch";
import JSONPretty from "react-json-pretty";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const actionColors = {
  POST: "primary",
  PUT: "info",
  DELETE: "error",
  PATCH: "warning",
};

const statusColors = {
  200: "success",
  201: "success",
  204: "success",
  400: "error",
  401: "error",
  403: "error",
  404: "error",
  500: "error",
};

export default function PartnerAudit() {
  const currentUser = decode();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [open, setOpen] = useState(false);

  const dateOperators = getGridDateOperators().filter((operator) =>
    ["is", "after", "onOrAfter", "before", "onOrBefore"].includes(
      operator.value
    )
  );

  const customNumberOperators = getGridNumericOperators().filter(
    (operator) =>
      operator.value !== "isEmpty" && operator.value !== "isNotEmpty"
  );

  const onFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const onSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const fetchAudits = useCallback(async () => {
    setLoading(true);

    if (paginationModel.pageSize === 0) {
      paginationModel.pageSize = 10; // Reset pageSize to default if it is 0
    }

    if (paginationModel.pageSize === -1) {
      paginationModel.pageSize = 10; // Reset pageSize to default if it is 0
    }

    const params = new URLSearchParams({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });

    if (sortModel[0]?.field && sortModel[0]?.sort) {
      params.append("sortField", sortModel[0].field);
      params.append("sortOrder", sortModel[0].sort);
    }

    if (filterModel.items) {
      filterModel.items.forEach((filter) => {
        if (filter.operator === "isNull" || filter.operator === "isOne") {
          params.append(`filter_${filter.field}_value`, true);
          params.append(`filter_${filter.field}_operator`, filter.operator);
        } else if (filter.value !== undefined) {
          if (filter.field === "created_time" && filter.value instanceof Date) {
            filter.value = filter.value.toISOString().split("T")[0];
          }

          params.append(`filter_${filter.field}_value`, filter.value);
          params.append(
            `filter_${filter.field}_operator`,
            filter.operator || "equals"
          );
        }
      });
    }

    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/audits?${params}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const processedData = data.audits.map((item) => ({
        ...item,
        created_time: item.created_time ? new Date(item.created_time) : null,
        updated_time: item.updated_time ? new Date(item.updated_time) : null,
      }));
      setRows(processedData);
      setTotalCount(data.total);
    } catch (error) {
      console.error("Failed to fetch audits:", error);
    } finally {
      setLoading(false);
    }
  }, [sortModel, filterModel, paginationModel]);

  useEffect(() => {
    fetchAudits();
  }, [fetchAudits]);

  const handleDialogOpen = (details, dataType) => {
    setSelectedDetails(details);
    setSelectedDataType(dataType);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedDetails(null);
    setSelectedDataType(null);
  };

  const formatToLocalTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const columns = [
    {
      field: "created_time",
      headerName: "Time",
      type: "dateTime",
      flex: 1.2,
      filterOperators: dateOperators,
      renderCell: (params) => {
        const localTime = formatToLocalTime(params.value);
        return localTime;
      },
    },
    { field: "user_email", headerName: "User Email", flex: 1 },
    { field: "tenant_name", headerName: "Tenant Name", flex: 1 },
    { field: "route", headerName: "Route", flex: 2 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={actionColors[params.value] || "default"}
        />
      ),
    },
    {
      field: "status_code",
      headerName: "Status Code",
      type: "number",
      width: 90,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={statusColors[params.value] || "default"}
        />
      ),
    },
    {
      field: "details",
      headerName: "Request",
      width: 90,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => handleDialogOpen(params.row, "details")}
        >
          View
        </Button>
      ),
    },
    {
      field: "response_body",
      headerName: "Response",
      width: 90,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => handleDialogOpen(params.row, "response_body")}
        >
          View
        </Button>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        Partner Audit
      </Typography>
      <div style={{ width: "100%" }}>
        <DataGridPro
          rows={rows}
          loading={loading}
          columns={columns}
          onPaginationModelChange={setPaginationModel}
          rowCount={totalCount}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerFilters
          pageSizeOptions={[10, 25, 50]}
          autoPageSize
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            headerFilterMenu: null,
          }}
          pagination
        />
      </div>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Details: {selectedDataType === "details" ? "Request" : "Response"}
        </DialogTitle>
        <DialogContent>
          {selectedDetails && (
            <Box mb={2}>
              <Typography variant="subtitle1">
                Time: {formatToLocalTime(selectedDetails.created_time)}
              </Typography>
              <Typography variant="subtitle1">
                Email: {selectedDetails.user_email}
              </Typography>
              <Typography variant="subtitle1">
                Tenant User ID: {selectedDetails.tenant_user_id}
              </Typography>
              <Typography variant="subtitle1">
                Route: {selectedDetails.route}
              </Typography>
              <Typography variant="subtitle1">
                Action:{" "}
                <Chip
                  label={selectedDetails.action}
                  color={actionColors[selectedDetails.action] || "default"}
                />
              </Typography>
              <Typography variant="subtitle1">
                Status Code:{" "}
                <Chip
                  label={selectedDetails.status_code}
                  color={statusColors[selectedDetails.status_code] || "default"}
                />
              </Typography>
            </Box>
          )}
          <JSONPretty
            data={selectedDetails ? selectedDetails[selectedDataType] : {}}
          ></JSONPretty>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
