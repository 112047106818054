import React, { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, Card, CardContent, CardHeader } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import customFetch from "../auth/customFetch";

const fetchDoublePieChartData = async () => {
  const response = await customFetch("/v1/dashboards/leads-by-age-range-and-state");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const processPieChartData = (data) => {
  const { innerChart, outerChart } = data;

  const colorMap = {
    '<50': '#00b3b0',
    '50-59': '#2f96ff',
    '60-69': '#b801d7',
    '70-79': '#60009b',
    '80+': '#2831c9',
  };

  const innerData = innerChart.map(item => ({
    label: item.ageRange,
    value: item.count,
    color: colorMap[item.ageRange]
  }));

  const outerData = outerChart.flatMap(item => 
    item.states.map(stateData => ({
      label: `${stateData.state}`,
      value: stateData.count,
      color: shadeColor(colorMap[item.ageRange], stateData.state.length * 10) // Simple shade calculation based on state length
    }))
  );

  return {
    innerData,
    outerData
  };
};

// Function to shade a color by a certain percentage
const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  const RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  const GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  const BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return "#" + RR + GG + BB;
};

const DoublePieChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchDoublePieChartData();
        const processedData = processPieChartData(result);
        setData(processedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  const series = [
    {
      innerRadius: 0,
      outerRadius: 120,
      arcLabel: (item) => `${item.label}`,
      arcLabelMinAngle: 30,
      data: data.innerData,
    },
    {
      innerRadius: 140,
      outerRadius: 200,
      arcLabel: (item) => `${item.label}`,
      arcLabelMinAngle: 10,
      data: data.outerData,
    },
  ];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
      <Card sx={{ maxWidth: 900, width: "100%" }}>
        <CardHeader
          titleTypographyProps={{
            align: "left",
            noWrap: true,
            style: { textOverflow: "ellipsis", overflow: "hidden" },
          }}
          title="Top 5 States - Age Distribution (Last 90 days)"
        />
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <PieChart
              series={series}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: 'white',
                  fontWeight: 'bold',
                },
              }}
              height={400}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DoublePieChart;
