// faviconUtils.js
const FAVICON_SIZES = [16, 32, 48, 120, 152, 180];

// Helper to determine image type
const getImageType = (url) => {
  const extension = url.split(".").pop().toLowerCase();
  switch (extension) {
    case "svg":
      return "svg";
    case "jpg":
    case "jpeg":
      return "jpeg";
    case "png":
      return "png";
    default:
      return "png"; // default to png
  }
};

// Special handler for SVG since it's vector-based
const handleSvgFavicon = async (svgUrl) => {
  try {
    const response = await fetch(svgUrl);
    const svgText = await response.text();

    // Create a temporary container
    const container = document.createElement("div");
    container.innerHTML = svgText;
    const svgElement = container.querySelector("svg");

    if (!svgElement) {
      throw new Error("Invalid SVG content");
    }

    // Ensure viewBox is set
    if (!svgElement.getAttribute("viewBox")) {
      const width = svgElement.getAttribute("width") || "32";
      const height = svgElement.getAttribute("height") || "32";
      svgElement.setAttribute("viewBox", `0 0 ${width} ${height}`);
    }

    // Convert SVG to data URL
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const encodedData = window.btoa(svgData);
    return `data:image/svg+xml;base64,${encodedData}`;
  } catch (error) {
    console.error("Error processing SVG:", error);
    throw error;
  }
};

export const resizeImage = async (imageUrl, size) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = size;
      canvas.height = size;

      const ctx = canvas.getContext("2d");
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      // Calculate scaling to maintain aspect ratio
      const scale = Math.min(size / img.width, size / img.height);
      const centerShift_x = (size - img.width * scale) / 2;
      const centerShift_y = (size - img.height * scale) / 2;

      // For non-SVG, we might want to add a white background
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, size, size);

      ctx.drawImage(
        img,
        centerShift_x,
        centerShift_y,
        img.width * scale,
        img.height * scale
      );

      const imageType = getImageType(imageUrl);
      resolve(
        canvas.toDataURL(`image/${imageType === "svg" ? "png" : imageType}`)
      );
    };

    img.onerror = () => reject(new Error(`Failed to load image: ${imageUrl}`));
    img.src = imageUrl;
  });
};

// Cache mechanism for favicons
const faviconCache = new Map();

const generateFavicon = async (logoUrl, size) => {
  const cacheKey = `${logoUrl}_${size}`;

  if (faviconCache.has(cacheKey)) {
    return faviconCache.get(cacheKey);
  }

  const imageType = getImageType(logoUrl);
  let faviconUrl;

  if (imageType === "svg") {
    // For SVG, we don't need to resize, just convert to data URL
    faviconUrl = await handleSvgFavicon(logoUrl);
  } else {
    faviconUrl = await resizeImage(logoUrl, size);
  }

  faviconCache.set(cacheKey, faviconUrl);
  return faviconUrl;
};

export const updateFavicon = async (logoUrl) => {
  try {
    if (!logoUrl) {
      console.warn("No logo URL provided for favicon");
      return;
    }

    // Use proxy endpoint
    const proxiedUrl = `/v1/proxy/image?url=${encodeURIComponent(logoUrl)}`;
    const imageType = getImageType(logoUrl);

    console.log(`Processing favicon of type: ${imageType}`);

    // Remove existing favicons
    const existingFavicons = document.querySelectorAll(
      'link[rel*="icon"], link[rel="apple-touch-icon"]'
    );
    existingFavicons.forEach((favicon) => favicon.remove());

    // Define icon configurations
    const iconConfigs = [
      { size: 16, rel: "icon", sizes: "16x16" },
      { size: 32, rel: "icon", sizes: "32x32" },
      { size: 48, rel: "icon", sizes: "48x48" },
      { size: 180, rel: "apple-touch-icon", sizes: "180x180" },
      { size: 152, rel: "apple-touch-icon", sizes: "152x152" },
      { size: 120, rel: "apple-touch-icon", sizes: "120x120" },
    ];

    // For SVG, we don't need multiple sizes
    const configsToUse =
      imageType === "svg" ? [{ size: 32, rel: "icon" }] : iconConfigs;

    // Generate all favicon sizes
    const faviconPromises = configsToUse.map(async (config) => {
      const faviconUrl = await generateFavicon(proxiedUrl, config.size);
      return { ...config, url: faviconUrl };
    });

    // Wait for all favicons to be generated
    const favicons = await Promise.all(faviconPromises);

    // Create and append favicon links
    favicons.forEach((favicon) => {
      const link = document.createElement("link");
      link.rel = favicon.rel;
      if (favicon.sizes) {
        link.sizes = favicon.sizes;
      }
      link.type = imageType === "svg" ? "image/svg+xml" : "image/png";
      link.href = favicon.url;
      document.head.appendChild(link);
    });

    console.log("Favicons updated successfully");
  } catch (error) {
    console.error("Error updating favicons:", error);
    throw error;
  }
};
