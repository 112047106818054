import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Button,
  Switch,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Badge,
  useMediaQuery,
  Box,
} from "@mui/material";
import CreateOrder from "./components/CreateOrder";
import { orderStatusList } from "../../../general/util/orderStatusList";
import EditOrder from "./components/EditOrder";
import decode from "../../../general/util/jwtDecode";
import { LicenseInfo } from "@mui/x-license";
import customFetch from "../../../general/auth/customFetch";
import ReplayIcon from "@mui/icons-material/Replay";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const statusDescriptions = {
  running: "Currently receiving leads",
  paused: "User has paused the order; leads not being delivered",
  capped: "Reached daily cap; may still receive extra leads",
  maxed: "Will not receive leads until the next day",
  hold: "Admin status for pausing an order",
  fulfilled: "Order has been completed",
  pending: "Order has been initiated",
  checkout: "Order was submitted and pending checkout",
};

const OrderCard = ({ order, handleStartOrder, handleStatusChange }) => {
  const [typesList, setTypesList] = useState([]);
  const renderStartDate = () => {
    const status = order.order_status;
    const startDate = order.start_date;
    const orderDate = order.order_date;

    if (status === "pending" && !startDate) {
      return null;
    } else if (
      (status === "running" ||
        status === "capped" ||
        status === "maxed" ||
        status === "paused" ||
        status === "hold") &&
      startDate
    ) {
      return new Date(startDate).toLocaleDateString();
    } else if (
      (status === "running" ||
        status === "capped" ||
        status === "maxed" ||
        status === "paused" ||
        status === "hold") &&
      orderDate
    ) {
      return new Date(orderDate).toLocaleDateString();
    } else if (
      status === "running" ||
      status === "capped" ||
      status === "maxed" ||
      status === "paused" ||
      status === "hold"
    ) {
      return "Started";
    } else if (status === "fulfilled" && startDate) {
      return new Date(startDate).toLocaleDateString();
    } else if (status === "fulfilled" && orderDate) {
      return new Date(orderDate).toLocaleDateString();
    } else if (status === "fulfilled") {
      return "--";
    } else if (status === "pending" && startDate) {
      return new Date(startDate).toLocaleDateString();
    } else {
      return "--";
    }
  };

  useEffect(() => {
    const fetchTypesAsync = async () => {
      const typesList = await customFetch(`/v1/products/product-catalog`, {
        method: "GET",
      }).then((res) => res.json());
      setTypesList(typesList);
    };
    fetchTypesAsync();
  }, []);

  const getTypeName = (order) => {
    if (order.product_id) {
      const product = typesList.find((type) => type.id === order.product_id);
      return product ? product.name : "Unknown Product";
    } else {
      return "";
    }
  };

  const shouldShowToggle =
    order.order_status === "hold" ||
    order.order_status === "running" ||
    order.order_status === "paused";

  return (
    <Card sx={{ mb: 2, position: "relative" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Order ID: {order.id}</Typography>
            <Typography variant="body2">
              <b>Order Date:</b>{" "}
              {new Date(order.order_date).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">
              <b>Product:</b> {getTypeName(order)}
            </Typography>
            <Typography variant="body2">
              <b>Lead Count:</b> {order.lead_count - order.replacement_count} /{" "}
              {order.num_leads} (after {order.replacement_count} replaced)
            </Typography>
            <Typography variant="body2">
              <b>States:</b> {order.states.join(", ")}
            </Typography>
            {renderStartDate() && (
              <Typography variant="body2">
                <b>Start Date:</b> {renderStartDate()}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" mt={1}>
            {renderStartDate() === null && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleStartOrder(order.id)}
                sx={{ mr: 1 }}
              >
                Start Now
              </Button>
            )}
            <EditOrder order={order} />
          </Grid>
        </Grid>
        <Box
          sx={{ position: "absolute", top: 16, right: 16, textAlign: "center" }}
        >
          {shouldShowToggle ? (
            <>
              <Badge
                badgeContent={order.order_status}
                color="primary"
                sx={{ mb: 1 }}
              />
              <Tooltip title={statusDescriptions[order.order_status]} arrow>
                <Grid container direction="column" alignItems="center">
                  <Switch
                    checked={order.order_status === "running"}
                    onChange={(event) =>
                      handleStatusChange(
                        order.id,
                        event.target.checked ? "running" : "paused"
                      )
                    }
                  />
                  <Typography variant="body2">
                    {order.order_status === "running" ? "Pause" : "Start"}
                  </Typography>
                </Grid>
              </Tooltip>
            </>
          ) : (
            <Badge
              badgeContent={order.order_status}
              color="primary"
              sx={{ mb: 0, mr: 4 }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default function AgentOrders() {
  const currentUser = decode();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typesList, setTypesList] = useState([]);
  const [previousStates, setPreviousStates] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    orderId: null,
    newStatus: null,
    order: null,
  });
  const [gridKey, setGridKey] = useState(0);

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleExpand = (orderId) => {
    const orderIndex = orders.findIndex((order) => order.id === orderId);
    if (orderIndex !== -1) {
      const updatedOrders = [...orders];
      updatedOrders[orderIndex] = {
        ...updatedOrders[orderIndex],
        isExpanded: !updatedOrders[orderIndex].isExpanded,
      };
      setOrders(updatedOrders);
    }
  };

  const handleStatusChange = (orderId, newStatus) => {
    const order = orders.find((order) => order.id === orderId);
    setConfirmDialog({ open: true, orderId, newStatus, order });
  };

  const handleCloseDialog = () => {
    setConfirmDialog({
      open: false,
      orderId: null,
      newStatus: null,
      order: null,
    });
  };

  const handleStartOrder = async (orderId) => {
    try {
      const order = orders.find((ord) => ord.id === orderId);

      const body = {
        order_status: "running",
        daily_cap: order.daily_cap,
        start_date: new Date(),
        states: order.states,
      };

      const response = await customFetch(`/v1/orders/${orderId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setOrders((prevOrders) =>
          prevOrders.map((ord) =>
            ord.id === orderId
              ? { ...ord, start_date: new Date(), order_status: "running" }
              : ord
          )
        );

        getOrders();
        setGridKey((prevKey) => prevKey + 1);
      } else {
        console.error("Failed to start order");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmStatusChange = async () => {
    const { orderId, newStatus, order } = confirmDialog;
    setConfirmDialog({
      open: false,
      orderId: null,
      newStatus: null,
      order: null,
    });

    const updatedOrder = {
      order_status: newStatus,
      daily_cap: order.daily_cap,
      start_date: order.start_date || null,
      states: order.states,
    };

    try {
      const response = await customFetch(`/v1/orders/${orderId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedOrder),
      });

      if (response.ok) {
        setOrders((prevOrders) =>
          prevOrders.map((ord) =>
            ord.id === orderId ? { ...ord, order_status: newStatus } : ord
          )
        );
        getOrders();
        setGridKey((prevKey) => prevKey + 1);
      } else {
        console.error("Failed to update order status");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrders = async () => {
    try {
      setLoading(true);
      if (!currentUser) {
        window.location = "/login";
      } else if (currentUser.permissions === "suspended") {
        window.location = "/suspended";
      }
      const response = await customFetch(`/v1/orders/`, {
        method: "GET",
      });
      const jsonData = await response.json();
      const ordersWithExpandedFlag = jsonData.map((order) => ({
        ...order,
        lead_count: parseInt(order.lead_count, 10),
        replacement_count: parseInt(order.replacement_count, 10),
        order_date: new Date(order.order_date),
        isExpanded: false,
      }));
      setOrders(ordersWithExpandedFlag);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getLastStates = async () => {
    try {
      const response = await customFetch(
        `/v1/orders/last-states/${currentUser.tenant_user_id}`
      );
      const data = await response.json();
      if (data && data.length > 0 && data[0].states) {
        const prevStates = data[0].states;
        setPreviousStates(prevStates);
      } else {
        console.log("Invalid response from API");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleResubmit = async (order) => {
    try {
      const body = {
        order_id: order.id,
        quantity: order.num_leads,
        product_id: order.product_id,
        daily_cap: order.daily_cap,
        startDate: order.start_date ? order.start_date : null,
        states: order.states,
        successUrl: window.location.href,
      };
      const response = await customFetch(`/v1/orders/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        mode: "cors",
      });
      const data = await response.json();
      window.location = data.redirect;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrders();
    getLastStates();
  }, []);

  useEffect(() => {
    const fetchTypesAsync = async () => {
      const typesList = await customFetch(`/v1/products/product-catalog`, {
        method: "GET",
      }).then((res) => res.json());
      setTypesList(typesList);
    };
    fetchTypesAsync();
  }, []);

  const getTypeName = (order) => {
    if (order.product_id) {
      const product = typesList.find((type) => type.id === order.product_id);
      return product ? product.name : "Unknown Product";
    } else {
      return "";
    }
  };

  const statusDescriptions = {
    created: "Order has been created by the system",
    checkout: "Order was submitted and pending checkout",
    running: "Currently receiving leads",
    paused: "User has paused the order; leads not being delivered",
    capped: "Reached daily cap; may still receive extra leads",
    maxed: "Will not receive leads until the next day",
    hold: "Admin status for pausing an order",
    fulfilled: "Order has been completed",
    pending: "Order has been initiated",
    created: "Order has been created but not submitted",
  };

  const columns = [
    {
      field: "id",
      headerName: "Batch ID",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "order_status",
      headerName: "Order Status",
      flex: 1,
      renderCell: (params) => {
        const status = orderStatusList.find(
          (status) => status.value === params.row.order_status
        );
        const label = status ? status.label : params.row.order_status;
        const description = statusDescriptions[params.row.order_status] || "";
        return (
          <Tooltip title={description} arrow>
            <span>{label}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      flex: 1,
      type: "date",
    },
    {
      field: "ref_type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => {
        return <span>{getTypeName(params.row)}</span>;
      },
    },
    {
      field: "lead_count",
      headerName: "Lead Count",
      width: 200,
      sortable: false,
      filterable: false,
      valueFormatter: (value, row) => {
        const leadCount = row.lead_count || 0;
        const replacementCount = row.replacement_count || 0;
        const numLeads = row.num_leads || 0;
        return `${
          leadCount - replacementCount
        } / ${numLeads} (after ${replacementCount} replaced)`;
      },
    },
    {
      field: "daily_cap",
      headerName: "Daily Cap",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "start_date",
      headerName: "Start Date",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        const { row } = params;
        const status = row.order_status;
        const startDate = row.start_date;
        const orderDate = row.order_date;

        if (status === "pending") {
          return (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleStartOrder(row.id)}
            >
              Start Now
            </Button>
          );
        } else if (
          (status === "running" ||
            status === "capped" ||
            status === "maxed" ||
            status === "paused" ||
            status === "hold") &&
          startDate
        ) {
          return new Date(startDate).toLocaleDateString();
        } else if (
          (status === "running" ||
            status === "capped" ||
            status === "maxed" ||
            status === "paused" ||
            status === "hold") &&
          orderDate
        ) {
          return new Date(orderDate).toLocaleDateString();
        } else if (
          status === "running" ||
          status === "capped" ||
          status === "maxed" ||
          status === "paused" ||
          status === "hold"
        ) {
          return "Started";
        } else if (status === "fulfilled" && startDate) {
          return new Date(startDate).toLocaleDateString();
        } else if (status === "fulfilled" && orderDate) {
          return new Date(orderDate).toLocaleDateString();
        } else if (status === "fulfilled") {
          return "--";
        } else if (status === "pending" && startDate) {
          return new Date(startDate).toLocaleDateString();
        } else {
          return "--";
        }
      },
    },
    {
      field: "status_switch",
      headerName: "Order Active",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const row = params.row;
        const isRunningOrPaused =
          row.order_status === "running" ||
          row.order_status === "paused" ||
          row.order_status === "hold";
        const isRecentlyUpdated =
          row.last_updated &&
          Date.now() - new Date(row.last_updated).getTime() <
            2 * 60 * 60 * 1000;
        return (
          <Tooltip
            title={
              !isRunningOrPaused
                ? "Order must be in running or paused status."
                : isRecentlyUpdated
                ? "Order was updated less than 2 hours ago."
                : ""
            }
            arrow
          >
            <span>
              <Switch
                checked={row.order_status === "running"}
                onChange={(event) =>
                  handleStatusChange(
                    row.id,
                    event.target.checked ? "running" : "paused"
                  )
                }
                disabled={!isRunningOrPaused || isRecentlyUpdated}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "states",
      headerName: "States",
      width: 110,
      renderCell: (params) => {
        const row = params.row;
        const states = row.states || [];
        const orderStates = row.states
          ? row.states.join(", ")
          : "No states available";
        const stateLabel =
          row.states && row.states.length === 1 ? "state" : "states";
        return (
          <Tooltip title={orderStates} placement="left">
            <Button
              size="small"
              variant="outlined"
              disabled={states.length === 0}
            >
              {`${row.states ? row.states.length : 0} ${stateLabel}`}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "edit",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div>
            {row.order_status !== "checkout" && <EditOrder order={row} />}
            {row.order_status === "checkout" && (
              <Tooltip title="Resubmit Order">
                <IconButton color="primary" onClick={() => handleResubmit(row)}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        Orders
      </Typography>
      <CreateOrder
        currentUser={currentUser}
        typesList={typesList}
        previousStates={previousStates}
      />
      {/* <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isMobile && (
          <Card
            sx={{
              position: "fixed",
              top: 16+64,
              right: 24,
              width: 300,
              backgroundColor: "primary.main",
              color: "white",
              padding: 2,
              zIndex: 1,
            }}
          >
            <CardContent>
              <Typography variant="h6">Special Sale!</Typography>
              <Typography variant="body2">
                Orders will receive <b>10% extra leads</b> upon completion!
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box> */}

      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isMobile ? (
            <Grid container spacing={2}>
              {orders.map((order) => (
                <Grid item xs={12} key={order.id}>
                  <OrderCard
                    order={order}
                    handleStartOrder={handleStartOrder}
                    handleStatusChange={handleStatusChange}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ width: "100%" }}>
              <DataGridPro
                key={gridKey}
                rows={orders}
                loading={loading}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pagination
                autoHeight
                disableSelectionOnClick
                pageSizeOptions={[10, 25, 50]}
                rowsPerPageOptions={[10, 25, 50]}
                headerFilters
                slots={{
                  headerFilterMenu: null,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Dialog open={confirmDialog.open} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the status of order ID{" "}
            <b>{confirmDialog.orderId}</b> to <b>{confirmDialog.newStatus}</b>?{" "}
            <br />
            <br />
            You will not be able to update this order again for 2 hours. <br />
            Some leads may still be delivered while paused.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmStatusChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
