import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const AddGroupDialog = ({ open, onClose, onAddGroup }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [alwaysHandleDuplicates, setAlwaysHandleDuplicates] = useState(false);

  const handleAdd = () => {
    onAddGroup(name, description, alwaysHandleDuplicates);
    setName("");
    setDescription("");
    setAlwaysHandleDuplicates(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Group Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Switch
                checked={alwaysHandleDuplicates}
                onChange={(e) => setAlwaysHandleDuplicates(e.target.checked)}
              />
            }
            label="Always Handle Duplicates"
          />
          <Tooltip title="When enabled, all duplicate contacts will be marked with a duplicate tag. By default, contacts are only marked as duplicates if they are more than 30 days old in your CRM.">
            <InfoIcon color="action" sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupDialog;
