import React, { useState, useEffect } from "react";
import {
  useTheme,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CreateReplacement from "./components/CreateReplacement";
import DeleteReplacement from "./components/DeleteReplacement";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";

export default function AdminReplacements() {
  const currentUser = decode();
  const theme = useTheme();
  const [replacements, setReplacements] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentReplacement, setCurrentReplacement] = useState(null);
  const [adminNote, setAdminNote] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentReplacement(null); // Reset currentReplacement
    setAdminNote(""); // Reset adminNote
  };

  const handleStatusChange = (replacement, status) => {
    // Set the current replacement and its new status in state
    setCurrentReplacement({ ...replacement, replacement_status: status });
    setOpenDialog(true);
  };

  const handleSaveChanges = async () => {
    if (currentReplacement) {
      await updateReplacement(
        currentReplacement.id,
        currentReplacement.replacement_status,
        adminNote
      );
    }
    handleCloseDialog();
  };

  const updateReplacement = async (id, status, note) => {
    try {
      const body = {
        replacement_status: status,
        replacement_admin_note: note,
      };
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/replacements/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (response.ok) {
        getReplacements(); // Fetch updated data and update state
      } else {
        throw new Error("Failed to update replacement");
      }
    } catch (error) {
      console.error("Failed to update replacement:", error);
    }
  };

  useEffect(() => {
    getReplacements();
  }, []);

  const getReplacements = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/replacements`
      );
      const replacements = await response.json();
      setReplacements(replacements);
    } catch (error) {
      console.error("Failed to fetch replacements:", error);
    }
  };

  const calculateAge = (replacement) => {
    let birthDate = new Date(replacement.dob);
    let created = new Date(replacement.created);
    let age = created.getFullYear() - birthDate.getFullYear();

    if (
      created.getMonth() < birthDate.getMonth() ||
      (created.getMonth() === birthDate.getMonth() &&
        created.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleCountExpand = (countId) => {
    const countIndex = replacements.findIndex((rep) => rep.id === countId);
    if (countIndex !== -1) {
      const updatedCounts = [...replacements];
      updatedCounts[countIndex] = {
        ...updatedCounts[countIndex],
        isCountExpanded: !updatedCounts[countIndex].isCountExpanded,
      };
      setReplacements(updatedCounts);
    }
  };

  const handleReasonExpand = (countId) => {
    const reasonIndex = replacements.findIndex((rep) => rep.id === countId);
    if (reasonIndex !== -1) {
      const updatedReasons = [...replacements];
      updatedReasons[reasonIndex] = {
        ...updatedReasons[reasonIndex],
        isReasonExpanded: !updatedReasons[reasonIndex].isReasonExpanded,
      };
      setReplacements(updatedReasons);
    }
  };

  const statusArr = [
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
    { value: "requested", label: "Requested" },
  ];

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
          fontSize: {
            xs: "2rem", // Smaller font size on extra-small devices
            sm: "3.5rem", // Default h2 size for small devices and up
          },
        }}
      >
        Replacements
      </Typography>

      <CreateReplacement statusArr={statusArr} />

      {!isMobile ? (
        <table className="table table-striped mt-5 text-center">
          <thead>
            <tr>
              <th className="align-middle">Lead Name</th>
              <th className="align-middle">Lead Created</th>
              <th className="align-middle">Agent Name</th>
              <th className="align-middle">Leads on Order</th>
              <th className="align-middle">Reason</th>
              <th className="align-middle">More Info</th>
              <th className="align-middle" style={{ width: "15%" }}>
                Status
              </th>
              <th className="align-middle">Date of Birth</th>
              <th className="align-middle">Phone</th>
              <th className="align-middle">Actions</th>
            </tr>
          </thead>
          <tbody>
            {replacements.map((replacement) => {
              const countContent = replacement.isCountExpanded ? (
                <ul className="mb-0 list-unstyled">
                  <li key={`${replacement.id}-ordered`}>
                    {`Ordered: ${replacement.num_leads}`}
                  </li>
                  <li key={`${replacement.id}-delivered`}>
                    {`Delivered: ${replacement.lead_count ?? "0"}`}
                  </li>
                  <li key={`${replacement.id}-replaced`}>
                    {`Replacements: ${replacement.replaced_count ?? "0"}`}
                  </li>
                  <li key={`${replacement.id}-requested`}>
                    {`Requested: ${replacement.pending_count ?? "0"}`}
                  </li>
                  <li key={`${replacement.id}-approved`}>
                    {`Approved: ${replacement.approved_count ?? "0"}`}
                  </li>
                  <li key={`${replacement.id}-rejected`}>
                    {`Rejected: ${replacement.rejected_count ?? "0"}`}
                  </li>
                </ul>
              ) : (
                `${replacement.lead_count - replacement.replacement_count} / ${
                  replacement.num_leads
                }`
              );

              const reasonContent = replacement.isReasonExpanded ? (
                <ul className="mb-0 list-unstyled">
                  {replacement.replacement_reason === "age" && (
                    <li key={`${replacement.id}-age`}>{`Age: ${calculateAge(
                      replacement
                    )}`}</li>
                  )}
                  {replacement.replacement_reason === "phone" && (
                    <li
                      key={`${replacement.id}-phone`}
                    >{`Phone: ${replacement.phone}`}</li>
                  )}
                  {replacement.replacement_reason === "state" && (
                    <li
                      key={`${replacement.id}-state`}
                    >{`State: ${replacement.state}`}</li>
                  )}
                  {replacement.replacement_reason === "other" && (
                    <li key={`${replacement.id}-other`}>
                      {`${replacement.replacement_reason}`}
                    </li>
                  )}
                </ul>
              ) : (
                `${replacement.replacement_reason}`
              );
              return (
                <tr key={replacement.id}>
                  <td className="align-middle">{replacement.name}</td>
                  <td className="align-middle">
                    {new Date(replacement.created).toLocaleDateString("en-US", {
                      timezone: "America/New_York",
                    })}
                  </td>
                  <td className="align-middle">{`${replacement.first_name} ${replacement.last_name}`}</td>
                  <td className="align-middle">
                    <Button
                      variant="outline-dark"
                      onClick={() => handleCountExpand(replacement.id)}
                    >
                      {countContent}
                    </Button>
                  </td>
                  <td className="align-middle">
                    <Button
                      variant="outline-dark"
                      onClick={() => handleReasonExpand(replacement.id)}
                    >
                      {reasonContent}
                    </Button>
                  </td>
                  <td className="align-middle w-25">
                    {replacement.replacement_info}
                  </td>
                  <td className="align-middle">
                    <FormControl fullWidth size="small">
                      <Select
                        value={replacement.replacement_status}
                        onChange={(event) =>
                          handleStatusChange(replacement, event.target.value)
                        }
                        sx={{
                          minWidth: 200, // Suitable width for desktop
                          padding: "0px 0px", // Adequate padding for desktop readability
                        }}
                      >
                        {statusArr.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </td>
                  <td className="align-middle">
                    {new Date(replacement.dob).toLocaleDateString("en-US", {
                      timeZone: "America/New_York",
                    })}
                  </td>
                  <td className="align-middle">{replacement.phone}</td>
                  <td className="align-middle">
                    <DeleteReplacement replacement={replacement} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {replacements.map((replacement, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      {replacement.name} <span> </span>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                      >
                        ({replacement.first_name} {replacement.last_name})
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        DOB:{" "}
                        {new Date(replacement.dob).toLocaleDateString("en-US")}
                      </Typography>
                      {" - "}
                      <Link href={`tel:${replacement.phone}`}>
                        {replacement.phone}
                      </Link>
                      <FormControl size="small" fullWidth sx={{ marginTop: 1 }}>
                        {" "}
                        <Select
                          value={replacement.replacement_status}
                          onChange={(event) =>
                            handleStatusChange(replacement, event.target.value)
                          }
                          sx={{
                            minWidth: 240, // Ensures the select is not too small on mobile
                            fontSize: "0.8rem", // Smaller font size for mobile
                            padding: "0px 0px", // Reduces padding inside the select
                            lineHeight: 1.5, // Adjusts line height for text
                            marginTop: "2px", // Adds margin at the top inside the FormControl
                          }}
                        >
                          {statusArr.map((status) => (
                            <MenuItem
                              key={status.value}
                              value={status.value}
                              sx={{ minHeight: "auto", padding: "8px 10px" }}
                            >
                              {status.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Update Replacement Status</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={currentReplacement?.replacement_status || ""}
              onChange={(e) =>
                setCurrentReplacement({
                  ...currentReplacement,
                  replacement_status: e.target.value,
                })
              }
              label="Status"
            >
              {statusArr.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="dense"
            label="Admin Note"
            type="text"
            variant="outlined"
            value={adminNote}
            onChange={(e) => setAdminNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
