import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function DeleteReplacement({ replacement }) {
  const [open, setOpen] = useState(false);
  const currentUser = decode();
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const deleteReplacement = async (e) => {
    e.preventDefault();
    try {
      await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/replacements/${replacement.id}`, {
        method: "DELETE",
      });

      window.location = "/admin/replacements";
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {`Are you sure you want to delete the replacement request for ${replacement.name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteReplacement} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
