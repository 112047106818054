import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from '@mui/material';
import customFetch from '../../../../general/auth/customFetch.js';
import decode from '../../../../general/util/jwtDecode.js';

const CreateIntegration = ({ open, onClose, onCreateSuccess }) => {
  const [integrationTypes, setIntegrationTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [integrationName, setIntegrationName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = decode();

  useEffect(() => {
    fetchIntegrationTypes();
  }, []);

  const fetchIntegrationTypes = async () => {
    try {
      const response = await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/integrations/integration-types`);
      if (!response.ok) {
        throw new Error('Failed to fetch integration types');
      }
      const data = await response.json();
      setIntegrationTypes(data.integrationTypes);
    } catch (error) {
      console.error('Error fetching integration types:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await customFetch(`/v1/tenantadmin/${currentUser.tenant_id}/integrations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          integration_type_id: selectedType,
          name: integrationName,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create integration');
      }

      const newIntegration = await response.json();
      onCreateSuccess(newIntegration);
      onClose();
    } catch (error) {
      console.error('Error creating integration:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Integration</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="integration-type-label">Integration Type</InputLabel>
            <Select
              labelId="integration-type-label"
              label="Integration Type"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              required
            >
              {integrationTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="integration-name"
            label="Integration Name"
            name="integrationName"
            value={integrationName}
            onChange={(e) => setIntegrationName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          disabled={isLoading || !selectedType || !integrationName}
        >
          {isLoading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateIntegration;