import React from "react";
import { UpdateBranding } from "./UpdateBranding";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

const UpdateGroupBranding = ({ open, onClose, group, fetchGroups }) => {
  const currentUser = decode();

  const handleSubmit = async (formData) => {
    const response = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/groups/${group.id}/theme`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }

    await fetchGroups();
  };

  return (
    <UpdateBranding
      open={open}
      onClose={onClose}
      title="Update Group Branding"
      initialData={group}
      onSubmit={handleSubmit}
      submitButtonText="Update Group Branding"
    />
  );
};

export default UpdateGroupBranding;