import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../general/auth/customFetch";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const PartnerManagement = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({ name: "", description: "" });
  const [editingPartner, setEditingPartner] = useState(null);

  const fetchPartners = async () => {
    try {
      const response = await customFetch(`/v1/superadmin/partners`);
      const data = await response.json();
      setPartners(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching partners:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const handleOpen = (partner) => {
    if (partner) {
      setEditingPartner(partner);
      setForm({ name: partner.name, description: partner.description });
    } else {
      setEditingPartner(null);
      setForm({ name: "", description: "" });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({ name: "", description: "" });
    setEditingPartner(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async () => {
    if (editingPartner) {
      await updatePartner(editingPartner.id, form);
    } else {
      await createPartner(form);
    }
    handleClose();
    fetchPartners();
  };

  const createPartner = async (data) => {
    try {
      await customFetch(`/v1/superadmin/partners`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error creating partner:", error);
    }
  };

  const updatePartner = async (id, data) => {
    try {
      await customFetch(`/v1/superadmin/partners/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error updating partner:", error);
    }
  };

  const deletePartner = async (id) => {
    try {
      await customFetch(`/v1/superadmin/partners/${id}`, {
        method: "DELETE",
      });
      fetchPartners();
    } catch (error) {
      console.error("Error deleting partner:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpen(params.row)} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => deletePartner(params.row.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="content">
      <div style={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main", // Use the primary color from the theme
            marginBottom: 2, // Theme spacing unit
            textAlign: "center", // Center align text
          }}
        >
          Partner Management
        </Typography>
      </div>
      <Box sx={{ height: 600, width: "100%" }}>
        <Button variant="contained" onClick={() => handleOpen(null)}>
          Add Partner
        </Button>
        <DataGridPro
          rows={partners}
          columns={columns}
          pageSize={10}
          loading={loading}
          disableSelectionOnClick
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editingPartner ? "Edit Partner" : "Add Partner"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={form.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              value={form.description}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>
              {editingPartner ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default PartnerManagement;
