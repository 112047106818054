import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import customFetch from "../auth/customFetch.js";

export default function TopStatesDeliveredChart() {
  const [chartData, setChartData] = useState({
    xAxis: [],
    series: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await customFetch("/v1/dashboards/lead_statistics", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const xAxisDates = data.xAxis.map((dateStr) => new Date(dateStr));

        setChartData({
          xAxis: xAxisDates,
          series: data.series,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchChartData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
      <Card sx={{ maxWidth: 900, width: "100%" }}>
        <CardHeader
          title="Top 5 States for Delivered Leads (Last 90 Days)"
          titleTypographyProps={{ align: 'left', noWrap: true, style: { textOverflow: 'ellipsis', overflow: 'hidden' } }}

        />
        <CardContent>
          <LineChart
            xAxis={[
              {
                id: "Years",
                data: chartData.xAxis,
                scaleType: "time",
              },
            ]}
            series={chartData.series}
            height={400}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
