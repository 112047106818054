import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import decode from "../util/jwtDecode";
import { useBranding } from "../../BrandingContext";

export default function Auth() {
  const [isAuth, setIsAuth] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const theme = useTheme();
  const { logoFull } = useBranding();

  // Get the current domain without port number
  const domain = window.location.hostname;

  const getCodeFromURL = async () => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");

      if (!code) {
        setIsAuth(false);
        setError("Invalid authentication code");
        setLoading(false);
        return;
      }

      const response = await fetch(`/v1/auth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
          domain: domain,
        }),
      });

      if (!response.ok) {
        if (response.status === 403) {
          setIsAuth(false);
          setError(
            "Unable to authenticate. Please try again or contact support"
          );
        } else if (response.status === 400) {
          setIsAuth(false);
          setError("Invalid domain or authentication code");
        } else {
          setIsAuth(false);
          setError("An unexpected error occurred");
        }
        setLoading(false);
        return;
      }

      const data = await response.json();
      handleAuthentication(data);
      setLoading(false);
    } catch (error) {
      console.error("Authentication error:", error);
      setError("An error occurred during authentication. Please try again.");
      setIsAuth(false);
      setLoading(false);
    }
  };

  const handleAuthentication = (data) => {
    try {
      document.cookie = `accessToken=${data.token}; path=/; secure; SameSite=Lax; maxAge=3600;`;
      document.cookie = `refreshToken=${data.refresh}; path=/; secure; SameSite=Lax;`;
      const currentUser = decode();

      if (!currentUser) {
        throw new Error("Failed to decode user token");
      }

      if (currentUser.role === "Tenant Admin") {
        window.location = `/admin`;
      } else {
        window.location = `/`;
      }
    } catch (error) {
      console.error("Token handling error:", error);
      window.location = `/login`;
    }
  };

  useEffect(() => {
    getCodeFromURL();
  }, []);

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 1,
              }}
            >
              <img
                src={logoFull}
                alt="Company Logo"
                style={{
                  maxWidth: "200px",
                  height: "auto",
                  marginBottom: "16px",
                }}
              />
            </Box>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{ color: theme.palette.text.primary }}
            >
              {isAuth ? "Authenticating User" : "Unable to Authenticate"}
            </Typography>
            {error && (
              <Alert
                severity="error"
                sx={{
                  mt: 2,
                  "& .MuiAlert-message": {
                    width: "100%",
                    textAlign: "center",
                  },
                }}
              >
                {error}
              </Alert>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
