import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  Chip,
  Tooltip,
  DialogContentText,
  Alert,
  Snackbar,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";
import { LicenseInfo } from "@mui/x-license";
import { Copy, CheckCircle } from "lucide-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const AdminTenantUserSpreadsheets = () => {
  const [spreadsheets, setSpreadsheets] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [formValues, setFormValues] = useState({
    tenant_user_id: "",
    spreadsheet_id: "",
    spreadsheet_type: "",
    spreadsheet_tab: "",
    product_ids: [],
  });
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [spreadsheetToDelete, setSpreadsheetToDelete] = useState(null);
  const [showCopySuccess, setShowCopySuccess] = React.useState(false);
  const serviceAccountEmail = "root-station-345221@appspot.gserviceaccount.com";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(serviceAccountEmail);
      setShowCopySuccess(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const currentUser = decode();

  useEffect(() => {
    loadSpreadsheets();
    loadUsers();
    loadProducts();
  }, []);

  const handleOpenDeleteDialog = (spreadsheet) => {
    setSpreadsheetToDelete(spreadsheet);
    setDeleteDialogOpen(true);
  };

  const loadSpreadsheets = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/spreadsheets`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch spreadsheets");
      }
      const data = await response.json();
      setSpreadsheets(data);
    } catch (error) {
      console.error("Failed to load spreadsheets:", error);
    }
  };

  const loadUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Failed to load users:", error);
    }
  };

  const loadProducts = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Failed to load products:", error);
    }
  };

  const handleOpenDialog = (spreadsheet = null) => {
    setEditMode(!!spreadsheet);
    setSelectedSpreadsheet(spreadsheet);
    setFormValues(
      spreadsheet
        ? {
            tenant_user_id: spreadsheet.tenant_user_id,
            spreadsheet_id: spreadsheet.spreadsheet_id,
            spreadsheet_type: spreadsheet.spreadsheet_type,
            spreadsheet_tab: spreadsheet.spreadsheet_tab,
            product_ids: spreadsheet.product_ids || [],
          }
        : {
            tenant_user_id: "",
            spreadsheet_id: "",
            spreadsheet_type: "",
            spreadsheet_tab: "",
            product_ids: [],
          }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSpreadsheet(null);
    setFormValues({
      tenant_user_id: "",
      spreadsheet_id: "",
      spreadsheet_type: "",
      spreadsheet_tab: "",
      product_ids: [],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleProductChange = (event, newValue) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      product_ids: newValue.map((product) => product.id),
    }));
  };

  const formatUserOption = (user) =>
    `${user.id} - ${user.first_name} ${user.last_name} - ${user.email}`;

  const handleUserChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      tenant_user_id: newValue ? newValue.id : "",
    });
  };

  const handleSubmit = async () => {
    if (
      !formValues.tenant_user_id ||
      !formValues.spreadsheet_id ||
      !formValues.spreadsheet_type ||
      !formValues.spreadsheet_tab
    ) {
      setError("Please fill in all fields.");
      return;
    }
    try {
      setError(null);
      const url = editMode
        ? `/v1/tenantadmin/${currentUser.tenant_id}/spreadsheets/${selectedSpreadsheet.id}`
        : `/v1/tenantadmin/${currentUser.tenant_id}/spreadsheets`;
      const method = editMode ? "PUT" : "POST";
      const payload = {
        ...formValues,
        settings: null, // Send null for settings
      };
      const response = await customFetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Failed to save spreadsheet");
      }
      loadSpreadsheets();
      handleCloseDialog();
    } catch (error) {
      console.error("Failed to save spreadsheet:", error);
      setError("Failed to save spreadsheet. Please try again.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/spreadsheets/${spreadsheetToDelete.id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete spreadsheet");
      }

      loadSpreadsheets();
      setDeleteDialogOpen(false);
      setSpreadsheetToDelete(null);
    } catch (error) {
      console.error("Failed to delete spreadsheet:", error);
      // Optionally, you can set an error state here to display to the user
    }
  };

  const columns = [
    {
      field: "tenant_user_id",
      headerName: "User",
      flex: 0.8,
      renderCell: (params) => {
        const user = users.find((u) => u.id === params.row.tenant_user_id);
        return user ? `${user.first_name} ${user.last_name}` : "Unknown";
      },
    },
    { field: "spreadsheet_id", headerName: "Spreadsheet ID", flex: 1.4 },
    { field: "spreadsheet_type", headerName: "Type", flex: 0.6 },
    { field: "spreadsheet_tab", headerName: "Tab", flex: 0.6 },
    {
      field: "products",
      headerName: "Products",
      flex: 0.8,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const productNames = params.row.products
          ? params.row.products.map((p) => p.name).join(", ")
          : "None";
        const productCount = params.row.products
          ? params.row.products.length
          : 0;

        return (
          <Tooltip title={productNames}>
            <Chip label={`${productCount} Products`} />
          </Tooltip>
        );
      },
    },
    {
      field: "error",
      headerName: "Errors",
      flex: 0.8,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const { error_time, updated_time, error_log } = params.row;
        const updatedTime = new Date(updated_time);
        const errorTime = new Date(error_time);
        const isRecentError = errorTime >= updatedTime - 5000;
        return isRecentError && error_log ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              alert(`Error Log: ${error_log}`);
            }}
          >
            View Error
          </Button>
        ) : (
          "No Errors"
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleOpenDialog(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Tenant User Spreadsheets
      </Typography>

      <Typography variant="body1" paragraph>
        This page manages spreadsheets for tenant users. Each spreadsheet
        includes default fields: Created, Name, Phone, Email, DOB, City, State,
        ZIP, and Timezone. Any additional custom fields defined in the custom
        fields mappings will be appended to the end of these default fields in
        the spreadsheet.
      </Typography>

      <Alert
        severity="info"
        className="mb-4"
        action={
          <Tooltip title="Copy service account email">
            <IconButton
              aria-label="copy service account email"
              onClick={handleCopy}
              size="small"
              color="info"
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      >
        <Typography variant="body2">
          Important: Spreadsheets must be shared with our service account:
          <Box component="span" sx={{ mx: 1, fontWeight: "medium" }}>
            {serviceAccountEmail}
          </Box>
        </Typography>
      </Alert>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
        style={{ marginBottom: "1rem" }}
      >
        Add New Spreadsheet
      </Button>
      <DataGridPro
        columns={columns}
        rows={spreadsheets}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        headerFilters
        pageSizeOptions={[10, 25, 50]}
        pagination
        autoHeight
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {editMode ? "Edit Spreadsheet" : "Add New Spreadsheet"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => formatUserOption(option)}
                value={
                  users.find((u) => u.id === formValues.tenant_user_id) || null
                }
                onChange={handleUserChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tenant User"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
            <TextField
              id="spreadsheet-id"
              name="spreadsheet_id"
              label="Spreadsheet ID"
              variant="outlined"
              fullWidth
              value={formValues.spreadsheet_id}
              onChange={handleChange}
              required
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Spreadsheet Type</InputLabel>
              <Select
                label="Spreadsheet Type"
                name="spreadsheet_type"
                value={formValues.spreadsheet_type}
                onChange={handleChange}
                required
              >
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="excel" disabled>
                  Excel
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="spreadsheet-tab"
              name="spreadsheet_tab"
              label="Spreadsheet Tab"
              variant="outlined"
              fullWidth
              value={formValues.spreadsheet_tab}
              onChange={handleChange}
              required
            />
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                multiple
                options={products}
                getOptionLabel={(option) => `${option.id} - ${option.name}`}
                value={products.filter((p) =>
                  formValues.product_ids.includes(p.id)
                )}
                onChange={handleProductChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Products"
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={`${option.id} - ${option.name}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Box>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this spreadsheet? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showCopySuccess}
        autoHideDuration={3000}
        onClose={() => setShowCopySuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowCopySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
          icon={<CheckCircle />}
        >
          Service account email copied to clipboard!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminTenantUserSpreadsheets;
