import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditProductGroupDialog({
  open,
  onClose,
  group,
  onEditProductGroup,
}) {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentUser = decode();

  useEffect(() => {
    if (group) {
      setSelectedProducts(group.products.map((product) => product.id));
    }
  }, [group]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await customFetch(
          `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`,
          { method: "GET" }
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (open) {
      fetchProducts();
    }
  }, [currentUser.tenant_id, open]);

  const handleEdit = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${group.id}/products`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_ids: selectedProducts }),
        }
      );
      if (response.ok) {
        onEditProductGroup(selectedProducts);
        onClose();
      } else {
        const errorData = await response.json();
        console.error("Error assigning products:", errorData.error);
        // Handle error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error assigning products:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleToggle = (productId) => {
    const currentIndex = selectedProducts.indexOf(productId);
    const newSelectedProducts = [...selectedProducts];

    if (currentIndex === -1) {
      newSelectedProducts.push(productId);
    } else {
      newSelectedProducts.splice(currentIndex, 1);
    }

    setSelectedProducts(newSelectedProducts);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (product.description &&
        product.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Products to Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search Products"
          type="text"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
          {filteredProducts.map((product) => {
            const labelId = `checkbox-list-label-${product.id}`;
            return (
              <ListItem
                key={product.id}
                dense
                button
                onClick={() => handleToggle(product.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedProducts.includes(product.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={product.name}
                  secondary={product.description}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
