import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Typography } from '@mui/material';
import customFetch from '../../../general/auth/customFetch';
import decode from '../../../general/util/jwtDecode';

const StripeDataPage = () => {
  const currentUser = decode();
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/stripe_products`);
      const data = await response.json();
      setProducts(data);
    };

    const fetchPrices = async () => {
      const response = await customFetch(`/v1/partners/${currentUser.partner_id}/stripe_prices`);
      const data = await response.json();
      setPrices(data);
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchProducts();
      await fetchPrices();
      setLoading(false);
    };

    fetchData();
  }, []);

  const productColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'active', headerName: 'Active', flex: 1 },
    { field: 'livemode', headerName: 'Live Mode', flex: 1 },
    { field: 'created', headerName: 'Created', flex: 1 },
    { field: 'updated', headerName: 'Updated', flex: 1 },
  ];

  const priceColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'currency', headerName: 'Currency', flex: 1 },
    { field: 'unit_amount', headerName: 'Unit Amount', flex: 1 },
    { field: 'nickname', headerName: 'Nickname', flex: 1 },
    { field: 'active', headerName: 'Active', flex: 1 },
    { field: 'livemode', headerName: 'Live Mode', flex: 1 },
    { field: 'created', headerName: 'Created', flex: 1 },
    { field: 'updated', headerName: 'Updated', flex: 1 },
  ];

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Stripe Products
      </Typography>
      <DataGridPro
        rows={products}
        columns={productColumns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
      />
      <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
        Stripe Prices
      </Typography>
      <DataGridPro
        rows={prices}
        columns={priceColumns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        loading={loading}
      />
    </Box>
  );
};

export default StripeDataPage;
