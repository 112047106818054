import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  TextField,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function AssignLocationDialog({
  open,
  onClose,
  onAssignLocation,
  groupId,
  currentLocations,
}) {
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const currentUser = decode();

  useEffect(() => {
    if (open) {
      fetchAvailableLocations();
    }
  }, [open]);

  useEffect(() => {
    // Set initially selected locations based on currentLocations
    setSelectedLocations(
      currentLocations.map((location) => location.ghl_location_id)
    );
  }, [currentLocations]);

  const fetchAvailableLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`,
        { method: "GET" }
      );
      const data = await response.json();
      setAvailableLocations(data);
    } catch (error) {
      console.error("Error fetching available locations:", error);
    }
  };

  const handleToggle = (locationId) => {
    const currentIndex = selectedLocations.indexOf(locationId);
    const newSelectedLocations = [...selectedLocations];

    if (currentIndex === -1) {
      newSelectedLocations.push(locationId);
    } else {
      newSelectedLocations.splice(currentIndex, 1);
    }

    setSelectedLocations(newSelectedLocations);
  };

  const handleAssign = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/groups/${groupId}/locations`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ghl_location_ids: selectedLocations }),
        }
      );
      if (response.ok) {
        onAssignLocation(selectedLocations);
        onClose();
      } else {
        const errorData = await response.json();
        console.error("Error assigning locations:", errorData.error);
        // Handle error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error assigning locations:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const filteredLocations = availableLocations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign GHL Locations to Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search Locations"
          type="text"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
          {filteredLocations.map((location) => {
            const labelId = `checkbox-list-label-${location.ghl_location_id}`;
            return (
              <ListItem
                key={location.ghl_location_id}
                dense
                button
                onClick={() => handleToggle(location.ghl_location_id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedLocations.includes(
                      location.ghl_location_id
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={location.name}
                  secondary={`${location.address}, ${location.city}, ${location.state}`}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAssign} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
