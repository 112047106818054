import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";


const DeleteSchedule = ({ openDelete, handleDeleteClose, fetchSchedules, products, selectedProduct, selectedDeleteId, setSelectedDeleteId }) => {
  const currentUser = decode();
  const handleDeleteConfirm = async () => {
    try {
      await customFetch(`/v1/users/${currentUser.tenant_user_id}/products/${products[selectedProduct].id}/schedules/${selectedDeleteId}`, {
        method: "DELETE",
      });
      fetchSchedules(products[selectedProduct].id);
      handleDeleteClose();
    } catch (error) {
      console.error("Error deleting schedule", error);
    }
  };

  return (
    <Dialog open={openDelete} onClose={handleDeleteClose} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Are you sure you want to delete this schedule? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteClose} color="secondary">Cancel</Button>
        <Button onClick={handleDeleteConfirm} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSchedule;
