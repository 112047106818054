import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

export const LoomEmbed = ({ title }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
    >
      <Card sx={{ maxWidth: 900, width: "100%", height: 504 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Overrun Leads - Now available!!
          </Typography>
          <div
            style={{ 
              position: "relative",
              paddingBottom: "79.78227060653188%",
              height: 0,
            }}
          >
            <iframe
              src="https://www.loom.com/embed/84de3cdb6a2b4303b7b95a28f54a9eb4?sid=ac9c827f-e717-4f55-baf3-b331bbb113cf"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                maxHeight: 425,
              }}
            />
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};
