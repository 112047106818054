import React, { useState, useEffect } from "react";
import { Typography, Button, Tooltip, Box } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditOrder from "./components/EditOrder";
import DeleteOrder from "./components/DeleteOrder";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  GROUP_ADMIN_ROLE_NAME,
  TENANT_ADMIN_ROLE_NAME,
} from "../../../general/constants/constants";
import CreateTenantProductOrder from "./components/CreateTenantProductOrder";

export default function AdminOrders() {
  const currentUser = decode();
  const [orders, setOrders] = useState([]);
  const [tenantUsersList, setTenantUsersList] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    lead_count: false,
    formattedStartDate: false,
  });
  const [hasEligibleProducts, setHasEligibleProducts] = useState(false);

  const applyRunningFilter = () => {
    setFilterModel({
      items: [
        {
          field: "order_status",
          operator: "equals",
          value: "running",
        },
      ],
    });
  };

  function CustomToolbar({ applyRunningFilter }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Button
          onClick={applyRunningFilter}
          startIcon={<PlayCircleOutlineIcon />}
          color="primary"
        >
          Running Only
        </Button>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    if (
      currentUser &&
      currentUser.role !== TENANT_ADMIN_ROLE_NAME &&
      currentUser.role !== GROUP_ADMIN_ROLE_NAME
    ) {
      window.location = "/login";
      return;
    }
    await Promise.all([fetchTenantUsersAsync()]);
    await fetchProductCatalogAndOrders();
  };

  const fetchTenantUsersAsync = async () => {
    const response = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/tenant-users`
    );
    const list = await response.json();
    setTenantUsersList(list);
  };

  const fetchProductCatalogAndOrders = async () => {
    const productCatalogResponse = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/product-catalog`,
      { method: "GET" }
    );
    const productCatalogData = await productCatalogResponse.json();
    setProductCatalog(productCatalogData);

    // Check if there are any eligible products
    const eligibleProducts = productCatalogData.filter((product) =>
      [
        "tenant-ad-set",
        "tenant-campaign-state",
        "tenant-campaign-state-county",
      ].includes(product.product_key)
    );
    setHasEligibleProducts(eligibleProducts.length > 0);

    const ordersResponse = await customFetch(
      `/v1/tenantadmin/${currentUser.tenant_id}/orders`,
      { method: "GET" }
    );
    const ordersData = await ordersResponse.json();
    setOrders(
      ordersData.map((order) => {
        const product = productCatalogData.find(
          (p) => p.id === order.product_id
        );
        return {
          ...order,
          id: order.id,
          userName: `${order.first_name} ${order.last_name}`,
          formattedOrderDate: order.order_date
            ? new Date(order.order_date)
            : null,
          formattedStartDate: order.start_date
            ? new Date(order.start_date)
            : null,
          leadCount: `${order.lead_count - order.replacement_count} / ${
            order.num_leads
          } (after ${order.replacement_count} replaced)`,
          dailyCount: `${order.daily_count} / ${order.daily_cap}`,
          productName: product ? product.name : null,
          states: order.states || [], // Ensure states is always an array
        };
      })
    );
  };

  const updateOrderInState = (updatedOrder) => {
    // Find the product for the order
    const product = productCatalog.find(
      (p) => p.id === updatedOrder.product_id
    );

    // Format the order data to match grid requirements
    const formattedOrder = {
      ...updatedOrder,
      id: updatedOrder.id,
      userName: `${updatedOrder.first_name} ${updatedOrder.last_name}`,
      formattedOrderDate: updatedOrder.order_date
        ? new Date(updatedOrder.order_date)
        : null,
      formattedStartDate: updatedOrder.start_date
        ? new Date(updatedOrder.start_date)
        : null,
      leadCount: `${
        updatedOrder.lead_count - updatedOrder.replacement_count
      } / ${updatedOrder.num_leads} (after ${
        updatedOrder.replacement_count
      } replaced)`,
      dailyCount: `${updatedOrder.daily_count} / ${updatedOrder.daily_cap}`,
      productName: product ? product.name : null,
      states: updatedOrder.states || [], // Ensure states is always an array
    };

    setOrders((prevOrders) => {
      // Check if the order already exists
      const orderExists = prevOrders.some(
        (order) => order.id === updatedOrder.id
      );

      if (orderExists) {
        // Update existing order
        return prevOrders.map((order) =>
          order.id === updatedOrder.id ? formattedOrder : order
        );
      } else {
        // Add new order to the beginning of the array
        return [formattedOrder, ...prevOrders];
      }
    });
  };

  const removeOrderFromState = (orderId) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.id !== orderId)
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "Batch ID",
      align: "left",
      headerAlign: "left",
      type: "number",
      flex: 0.6,
      renderCell: (params) => <div>{params.value}</div>,
    },
    { field: "userName", headerName: "User Name", flex: 1.2 },
    {
      field: "formattedOrderDate",
      headerName: "Order Date",
      flex: 0.8,
      type: "date",
    },
    {
      field: "refTypeOrProductName",
      headerName: "Product",
      flex: 1,
      valueGetter: (params, row) => row.productName,
    },
    {
      field: "formattedStartDate",
      headerName: "Start Date",
      flex: 0.8,
      type: "date",
    },
    { field: "order_status", headerName: "Status", flex: 0.8 },
    {
      field: "lead_count",
      headerName: "Lead Count",
      align: "left",
      headerAlign: "left",
      type: "number",
      flex: 0.6,
    },
    {
      field: "leadCount",
      headerName: "Number of Leads",
      flex: 1.4,
      sortable: false,
      filterable: false,
    },
    {
      field: "dailyCount",
      headerName: "Daily Count",
      flex: 0.7,
      sortable: false,
      filterable: false,
    },
    {
      field: "states",
      headerName: "States",
      width: 115,
      renderCell: (params) => {
        const row = params.row;
        const states = row.states || []; // Ensure states is always an array
        const orderStates = states.join(", ");
        const stateLabel = states.length === 1 ? "state" : "states";
        return (
          <Tooltip title={orderStates} placement="left">
            <Button
              size="small"
              variant="outlined"
              disabled={states.length === 0}
            >
              {states.length > 0
                ? `${states.length} ${stateLabel}`
                : "No states"}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <EditOrder
              order={params.row}
              tenantUsersList={tenantUsersList}
              updateOrderInState={updateOrderInState}
            />
            <span> </span>
            <DeleteOrder
              order={params.row}
              removeOrderFromState={removeOrderFromState}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="content">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main", // Use the primary color from the theme
          marginBottom: 2, // Theme spacing unit
          textAlign: "center", // Center align text
        }}
      >
        Admin Orders
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        {hasEligibleProducts && (
          <CreateTenantProductOrder
            tenantUsersList={tenantUsersList}
            updateOrders={updateOrderInState}
            productCatalog={productCatalog}
          />
        )}
      </Box>
      <br></br>
      <br></br>
      <div style={{ height: "700px", width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DataGridPro
            rows={orders}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibilityModel,
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            pagination
            autoHeight
            disableSelectionOnClick
            headerFilters
            slots={{
              toolbar: () => (
                <CustomToolbar applyRunningFilter={applyRunningFilter} />
              ),
              headerFilterMenu: null,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
