import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

export default function EditMappingDialog({
  open,
  onClose,
  onEditMapping,
  mapping,
  mappingGroupId,
}) {
  const [tenantFields, setTenantFields] = useState([]);
  const [ghlFields, setGhlFields] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [tenantFieldId, setTenantFieldId] = useState("");
  const [ghlFieldId, setGhlFieldId] = useState("");
  const currentUser = decode();

  useEffect(() => {
    if (open && mapping) {
      fetchTenantFields();
      fetchGhlLocations();
      setTenantFieldId(mapping.tenant_custom_field_id);
      setGhlFieldId(mapping.ghl_custom_field_id);
    }
  }, [open, mapping]);

  const fetchTenantFields = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/tenant_custom_fields`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setTenantFields(data);
    } catch (error) {
      console.error("Failed to fetch tenant fields", error);
    }
  };

  const fetchGhlLocations = async () => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setLocations(data);
    } catch (error) {
      console.error("Failed to fetch GHL locations", error);
    }
  };

  const fetchGhlFields = async (locationId) => {
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/ghl-locations/${locationId}/customFields`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setGhlFields(data);
    } catch (error) {
      console.error("Failed to fetch GHL fields", error);
    }
  };

  const handleLocationChange = (event, newValue) => {
    setSelectedLocation(newValue);
    if (newValue) {
      fetchGhlFields(newValue.ghl_location_id);
    }
  };

  const handleSubmit = () => {
    if (tenantFieldId && ghlFieldId && mappingGroupId) {
      onEditMapping({
        id: mapping.id,
        tenant_custom_field_id: tenantFieldId,
        ghl_custom_field_id: ghlFieldId,
        mapping_group_id: mappingGroupId,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Field Mapping</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={locations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Select Location" margin="dense" />
          )}
          value={selectedLocation}
          onChange={handleLocationChange}
        />
        <TextField
          select
          label="Tenant Custom Field"
          fullWidth
          value={tenantFieldId}
          onChange={(e) => setTenantFieldId(e.target.value)}
          margin="dense"
          disabled={!selectedLocation}
        >
          {tenantFields.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="GHL Custom Field"
          fullWidth
          value={ghlFieldId}
          onChange={(e) => setGhlFieldId(e.target.value)}
          margin="dense"
          disabled={!selectedLocation}
        >
          {ghlFields.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedLocation}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
