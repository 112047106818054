import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import customFetch from "../auth/customFetch";
import { format, parseISO } from "date-fns"; // Add this import

const fetchLeadsData = async () => {
  const response = await customFetch("/v1/dashboards/leads-by-age-range");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const processLeadsData = (data) => {
  const months = [];
  const ageRanges = ["<50", "50-59", "60-69", "70-79", "80+"];
  const seriesData = ageRanges.map(() => []);

  data.forEach((item) => {
    const month = format(parseISO(item.month), "MMM"); // Update this line
    if (!months.includes(month)) {
      months.push(month);
    }

    const ageRangeIndex = ageRanges.indexOf(item.age_range);
    if (ageRangeIndex !== -1) {
      seriesData[ageRangeIndex].push(item.count);
    }
  });

  return {
    months,
    seriesData: ageRanges.map((ageRange, index) => ({
      label: ageRange,
      data: seriesData[index],
    })),
  };
};

const LeadsBarChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchLeadsData();
        const processedData = processLeadsData(result);
        setData(processedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}
    >
      <Card sx={{ maxWidth: 900, width: "100%" }}>
        <CardHeader
          titleTypographyProps={{
            align: "left",
            noWrap: true,
            style: { textOverflow: "ellipsis", overflow: "hidden" },
          }}
          title="Leads by Age Range (Last 90 days)"
        />
        <CardContent>
          <BarChart
            series={data.seriesData}
            height={400}
            xAxis={[{ data: data.months, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default LeadsBarChart;
