import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Chip,
  Box,
  FormHelperText,
} from "@mui/material";
import { useFetch } from "./useFetch";
import decode from "../../../general/util/jwtDecode";
import customFetch from "../../../general/auth/customFetch";

const CatalogForm = ({ open, onClose, catalogItem, onSubmit }) => {
  const currentUser = decode();
  const [formState, setFormState] = useState({
    name: "",
    tenant_id: "",
    campaign_id: "",
    ad_set_id: "",
    stripe_product_id: null,
    stripe_price_id: null,
    product_type_id: "",
    description: "",
    is_active: true,
    crm_tags: [],
    daily_cap: "",
    order_minimum: "",
    product_key: "",
  });
  const [tagInput, setTagInput] = useState("");
  const [adSets, setAdSets] = useState([]);
  const [errors, setErrors] = useState({});

  const { data: tenants } = useFetch(
    `/v1/partners/${currentUser.partner_id}/tenants`
  );
  const { data: campaigns } = useFetch(
    `/v1/partners/${currentUser.partner_id}/campaigns`
  );
  const { data: stripeProducts } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_products`
  );
  const { data: stripePrices } = useFetch(
    `/v1/partners/${currentUser.partner_id}/stripe_prices`
  );
  const { data: productTypes } = useFetch(`/v1/partners/product-types`);

  useEffect(() => {
    if (catalogItem) {
      setFormState(catalogItem);
    }
  }, [catalogItem]);

  useEffect(() => {
    const fetchAdSets = async () => {
      if (
        formState.campaign_id &&
        (formState.product_key === "tenant-ad-set" ||
          formState.product_key === "agent-adset")
      ) {
        const response = await customFetch(
          `/v1/partners/${currentUser.partner_id}/ad_sets/${formState.campaign_id}`
        );
        if (response.ok) {
          const adSetsData = await response.json();
          setAdSets(adSetsData);
        } else {
          setAdSets([]);
        }
      } else {
        setAdSets([]);
      }
    };

    fetchAdSets();
  }, [formState.campaign_id, formState.product_key, currentUser.partner_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "product_type_id") {
      const selectedProductType = productTypes.find(
        (type) => type.id === value
      );
      setFormState({
        ...formState,
        [name]: value,
        product_key: selectedProductType ? selectedProductType.product_key : "",
        campaign_id: "",
        ad_set_id: "",
      });
    } else if (name === "stripe_product_id") {
      setFormState({
        ...formState,
        [name]: value,
        stripe_price_id: "", // Reset price when product changes
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormState({ ...formState, [name]: checked });
  };

  const handleTagAdd = () => {
    if (tagInput && formState.crm_tags.length < 10) {
      setFormState({
        ...formState,
        crm_tags: [...formState.crm_tags, tagInput],
      });
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setFormState({
      ...formState,
      crm_tags: formState.crm_tags.filter((tag) => tag !== tagToDelete),
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formState.name) newErrors.name = "Name is required";
    if (!formState.tenant_id) newErrors.tenant_id = "Tenant is required";
    if (!formState.product_type_id)
      newErrors.product_type_id = "Product Type is required";

    if (formState.product_key === "tenant-ad-set" && !formState.ad_set_id) {
      newErrors.ad_set_id = "Ad Set is required for this product type";
    }

    if (
      (formState.product_key === "tenant-campaign-state" ||
        formState.product_key === "tenant-campaign-state-county") &&
      !formState.campaign_id
    ) {
      newErrors.campaign_id = "Campaign is required for this product type";
    }

    if (
      ["agent-lead", "agent-overrun", "agent-adset"].includes(
        formState.product_key
      )
    ) {
      if (!formState.campaign_id)
        newErrors.campaign_id = "Campaign is required for this product type";
      if (!formState.stripe_product_id)
        newErrors.stripe_product_id = "Stripe Product is required";
      if (!formState.stripe_price_id)
        newErrors.stripe_price_id = "Stripe Price is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formState);
    }
  };

  const selectedStripeProduct = stripeProducts?.find(
    (product) => product.id === formState.stripe_product_id
  );
  const filteredStripePrices = stripePrices?.filter(
    (price) => price.product === selectedStripeProduct?.stripe_id
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {catalogItem?.id ? "Edit Catalog Item" : "Add New Catalog Item"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <FormControl
            fullWidth
            margin="dense"
            error={!!errors.product_type_id}
          >
            <InputLabel>Product Type</InputLabel>
            <Select
              name="product_type_id"
              value={formState.product_type_id}
              onChange={handleChange}
              label="Product Type"
              required
            >
              {productTypes &&
                productTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.product_type_id && (
              <FormHelperText>{errors.product_type_id}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="dense" error={!!errors.tenant_id}>
            <InputLabel>Tenant</InputLabel>
            <Select
              name="tenant_id"
              label="Tenant"
              value={formState.tenant_id}
              onChange={handleChange}
              required
            >
              {tenants &&
                tenants.map((tenant) => (
                  <MenuItem key={tenant.id} value={tenant.id}>
                    {tenant.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.tenant_id && (
              <FormHelperText>{errors.tenant_id}</FormHelperText>
            )}
          </FormControl>
          {(formState.product_key === "tenant-campaign-state" ||
            formState.product_key === "tenant-campaign-state-county" ||
            formState.product_key === "tenant-ad-set" ||
            ["agent-lead", "agent-overrun", "agent-adset"].includes(
              formState.product_key
            )) && (
            <FormControl fullWidth margin="dense" error={!!errors.campaign_id}>
              <InputLabel>Campaign</InputLabel>
              <Select
                name="campaign_id"
                label="Campaign"
                value={formState.campaign_id}
                onChange={handleChange}
                required
              >
                {campaigns &&
                  campaigns.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign.id}>
                      {campaign.campaign_name}
                    </MenuItem>
                  ))}
              </Select>
              {errors.campaign_id && (
                <FormHelperText>{errors.campaign_id}</FormHelperText>
              )}
            </FormControl>
          )}
          {(formState.product_key === "tenant-ad-set" ||
            formState.product_key === "agent-adset") && (
            <FormControl fullWidth margin="dense" error={!!errors.ad_set_id}>
              <InputLabel>Ad Set</InputLabel>
              <Select
                name="ad_set_id"
                label="Ad Set"
                value={formState.ad_set_id}
                onChange={handleChange}
                required
              >
                {adSets &&
                  adSets.map((adSet) => (
                    <MenuItem key={adSet.id} value={adSet.id}>
                      {adSet.name}
                    </MenuItem>
                  ))}
              </Select>
              {errors.ad_set_id && (
                <FormHelperText>{errors.ad_set_id}</FormHelperText>
              )}
            </FormControl>
          )}
          {["agent-lead", "agent-overrun", "agent-adset"].includes(
            formState.product_key
          ) && (
            <>
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors.stripe_product_id}
              >
                <InputLabel>Stripe Product</InputLabel>
                <Select
                  name="stripe_product_id"
                  value={formState.stripe_product_id}
                  onChange={handleChange}
                  label="Stripe Product"
                  required
                >
                  {stripeProducts &&
                    stripeProducts.map((product) => (
                      <MenuItem key={product.id} value={product.id}>
                        {product.name} ({product.stripe_id})
                      </MenuItem>
                    ))}
                </Select>
                {errors.stripe_product_id && (
                  <FormHelperText>{errors.stripe_product_id}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={!!errors.stripe_price_id}
              >
                <InputLabel>Stripe Price</InputLabel>
                <Select
                  name="stripe_price_id"
                  value={formState.stripe_price_id}
                  onChange={handleChange}
                  label="Stripe Price"
                  required
                  disabled={!formState.stripe_product_id}
                >
                  {filteredStripePrices &&
                    filteredStripePrices.map((price) => (
                      <MenuItem key={price.id} value={price.id}>
                        {price.currency} - {price.unit_amount / 100} (
                        {price.stripe_price_id})
                      </MenuItem>
                    ))}
                </Select>
                {errors.stripe_price_id && (
                  <FormHelperText>{errors.stripe_price_id}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
          <TextField
            margin="dense"
            label="Daily Cap"
            name="daily_cap"
            value={formState.daily_cap}
            onChange={handleChange}
            fullWidth
            type="number"
          />
          <TextField
            margin="dense"
            label="Order Minimum"
            name="order_minimum"
            value={formState.order_minimum}
            onChange={handleChange}
            fullWidth
            type="number"
          />
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={formState.description}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.is_active}
                onChange={handleCheckboxChange}
                name="is_active"
                color="primary"
              />
            }
            label="Active"
          />
          <Box mt={2}>
            <TextField
              label="Add CRM Tag"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleTagAdd();
                }
              }}
              fullWidth
            />
            <Button
              onClick={handleTagAdd}
              disabled={!tagInput || formState.crm_tags.length >= 10}
              sx={{ mt: 1 }}
            >
              Add Tag
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" mt={2}>
            {formState.crm_tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleTagDelete(tag)}
                sx={{ margin: 0.5 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CatalogForm;
