import React, { useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useBranding } from "../../BrandingContext";

export default function Login() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const { logoFull } = useBranding();

  // Get the current domain without port number
  const domain = window.location.hostname;

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      await login();
      setShowAlert(true);
    } catch (error) {
      setError("Failed to authenticate");
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowAlert(false);
    setError("");
  };

  const login = async () => {
    const body = {
      email,
      domain,
    };

    try {
      const response = await fetch(`/v1/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const data = await response.json();
        if (response.status === 400) {
          setError("Invalid domain or email format");
        } else if (response.status === 403) {
          setError("Not authorized for this domain");
        } else if (response.status === 500) {
          setError(
            "Error logging in... Please try again later or contact support."
          );
        } else {
          setError(data.error || "An unknown error occurred");
        }
        console.error("Login error:", data);
        return;
      }

      // Successful request - no need to handle the response here
      // as we'll show the success message
    } catch (error) {
      console.error("Login error:", error);
      setError("Failed to connect to the server. Please try again later.");
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%", maxWidth: 400 }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 3,
              }}
            >
              <img
                src={logoFull}
                alt="Company Logo"
                style={{
                  maxWidth: "200px",
                  height: "auto",
                  marginBottom: "16px",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                }}
              >
                Sign in to your account
              </Typography>
            </Box>

            {error ? (
              <>
                <Alert severity="error">{error}</Alert>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </>
            ) : showAlert ? (
              <>
                <Alert severity="success">
                  If an account exists with this email, you will receive a link
                  to log in at {email}
                </Alert>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    id="email"
                    label="Enter your email to log in"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    margin="normal"
                    variant="outlined"
                    autoComplete="email"
                    inputProps={{
                      "aria-label": "Email address",
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={loading || !email}
                    sx={{ mt: 2 }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Sign In"}
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          New customer?{" "}
          <a href="/signup" style={{ color: theme.palette.primary.main }}>
            Get started here
          </a>
        </Typography>
      </Box>
    </Container>
  );
}
