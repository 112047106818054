import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import { statesList } from "../../../../general/util/statesList";
import { timezonesList } from "../../../../general/util/timezonesList";
import { timezoneData } from "../../../../general/util/timezoneData";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";
import { nextProductOrderUp } from "../../../../general/util/nextUp";

export default function CreateProductLead({ productsList }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [timezone, setTimezone] = useState("");
  const [zip, setZip] = useState("");
  const [product, setProduct] = useState("");
  const [order, setOrder] = useState("");
  const [ordersList, setOrdersList] = useState([]);
  const [location, setLocation] = useState({
    city: "",
    state: "",
    timezone: "",
  });
  const [show, setShow] = useState(false);
  const [customFields, setCustomFields] = useState([{ key: "", value: "" }]);
  const currentUser = decode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleStateChange = (event) => setState(event.target.value);
  const handleTimezoneChange = (event) => setTimezone(event.target.value);

  const createLead = async () => {
    try {
      const custom_fields = customFields.reduce((acc, field) => {
        if (field.key && field.value) {
          acc[field.key] = field.value;
        }
        return acc;
      }, {});

      let body = {
        name,
        email,
        phone,
        dob: dob.toISOString(),
        city,
        state,
        zip,
        timezone,
        custom_fields,
        product_id: parseInt(product),
        ref_order: parseInt(order),
      };
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/leads`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      window.location = "/admin/leads";
    } catch (error) {
      console.error(error);
    }
  };

  const handleCustomFieldChange = (index, field, value) => {
    const updatedFields = [...customFields];
    updatedFields[index][field] = value;
    setCustomFields(updatedFields);
  };

  const addCustomField = () => {
    setCustomFields([...customFields, { key: "", value: "" }]);
  };

  const removeCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const nextUpAsync = async () => {
      try {
        const orderList = await nextProductOrderUp(state, product);
        setOrdersList(orderList);
      } catch (error) {
        console.error(error);
      }
    };
    product && state && nextUpAsync();
  }, [state, product]);

  useEffect(() => {
    const setLocationAsync = () => {
      try {
        const locationObj = timezoneData.find((tz) => tz.zipcode === zip);
        locationObj && setLocation(locationObj);
      } catch (error) {
        console.error(error);
      }
    };
    zip && setLocationAsync();
  }, [zip]);

  useEffect(() => {
    const autoFillLocation = () => {
      try {
        setCity(location.city);
        setState(location.state);
        setTimezone(location.timezone);
      } catch (error) {
        console.error(error);
      }
    };
    autoFillLocation();
  }, [location]);

  return (
    <>
      <Button variant="contained" onClick={handleShow}>
        Create New Lead
      </Button>

      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Create New Lead</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            margin="dense"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={dob}
              onChange={(newDate) => setDob(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="dense" />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Zip Code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            margin="dense"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              value={state}
              label="State"
              onChange={handleStateChange}
            >
              {statesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="timezone-label">Timezone</InputLabel>
            <Select
              labelId="timezone-label"
              value={timezone}
              label="Timezone"
              onChange={handleTimezoneChange}
            >
              {timezonesList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {customFields.map((field, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Key"
                  value={field.key}
                  onChange={(e) =>
                    handleCustomFieldChange(index, "key", e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Value"
                  value={field.value}
                  onChange={(e) =>
                    handleCustomFieldChange(index, "value", e.target.value)
                  }
                  margin="dense"
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => removeCustomField(index)}
                  aria-label="delete"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addCustomField}
            sx={{ marginTop: 2 }}
          >
            Add Custom Field
          </Button>

          <Autocomplete
            fullWidth
            options={productsList}
            getOptionLabel={(option) => option.name}
            value={productsList.find((option) => option.id === product)}
            onChange={(event, newValue) => {
              setProduct(newValue ? newValue.id : "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Product" margin="dense" />
            )}
          />
          <Autocomplete
            fullWidth
            options={ordersList}
            getOptionLabel={(option) => option.label}
            value={ordersList.find((option) => option.value === order)}
            onChange={(event, newValue) => {
              setOrder(newValue ? newValue.value : "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Assigned Order" margin="dense" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={createLead} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
