import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Collapse,
  Alert,
  IconButton,
  Chip,
  Snackbar,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import customFetch from "../../../../general/auth/customFetch";

const steps = ["Select Ad Accounts", "Select Pages", "Review"];

export default function EditIntegration({
  open,
  onClose,
  integration,
  onUpdateSuccess,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [expandedAccounts, setExpandedAccounts] = useState({});
  const [expandedCampaigns, setExpandedCampaigns] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const fetchIntegrationDetails = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${integration.tenant_id}/integrations/${integration.id}/setup/facebook`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch integration details");
      }
      const data = await response.json();
      setPages(data.pages);
      setAdAccounts(data.adAccounts);
      setSelectedAdAccounts(data.selectedAdAccountIds || []);
      setSelectedPages(data.selectedPageIds || []);
    } catch (error) {
      console.error("Error fetching integration details:", error);
      setError("Failed to load integration details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinish = async () => {
    setIsSubmitting(true);
    setApiError(null); // Clear any previous errors
    try {
      const response = await customFetch(
        `/v1/tenantadmin/${integration.tenant_id}/integrations/${integration.id}/update/facebook`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            selectedPageIds: selectedPages,
            selectedAdAccountIds: selectedAdAccounts,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update integration");
      }

      setSnackbar({
        open: true,
        message: "Integration updated successfully",
        severity: "success",
      });

      if (onUpdateSuccess) {
        onUpdateSuccess();
      }

      onClose();
    } catch (error) {
      console.error("Error updating integration:", error);
      setApiError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    if (open && integration) {
      fetchIntegrationDetails();
    }
  }, [open, integration]);

  const handleNext = () => {
    setApiError(null);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setApiError(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setApiError(null);
    onClose();
  };

  const handleAdAccountToggle = (accountId) => {
    setSelectedAdAccounts((prev) =>
      prev.includes(accountId)
        ? prev.filter((id) => id !== accountId)
        : [...prev, accountId]
    );
  };

  const handlePageToggle = (pageId) => {
    setSelectedPages((prev) =>
      prev.includes(pageId)
        ? prev.filter((id) => id !== pageId)
        : [...prev, pageId]
    );
  };

  const toggleAccountExpansion = (accountId) => {
    setExpandedAccounts((prev) => ({
      ...prev,
      [accountId]: !prev[accountId],
    }));
  };

  const toggleCampaignExpansion = (campaignId) => {
    setExpandedCampaigns((prev) => ({
      ...prev,
      [campaignId]: !prev[campaignId],
    }));
  };

  const getAdAccountsUsingPage = (pageId) => {
    return adAccounts
      .filter((account) => account.uniquePageIds.includes(pageId))
      .map((account) => account.name);
  };

  const getMissingPages = () => {
    const selectedAccountPageIds = adAccounts
      .filter((account) => selectedAdAccounts.includes(account.id))
      .flatMap((account) => account.uniquePageIds);

    return [
      ...new Set(
        selectedAccountPageIds.filter(
          (pageId) => !selectedPages.includes(pageId)
        )
      ),
    ];
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <List>
            {adAccounts.map((account) => (
              <React.Fragment key={account.id}>
                <ListItem>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedAdAccounts.includes(account.id)}
                      onChange={() => handleAdAccountToggle(account.id)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={account.name}
                    secondary={`Account ID: ${account.account_id}`}
                  />
                  <IconButton
                    onClick={() => toggleAccountExpansion(account.id)}
                  >
                    {expandedAccounts[account.id] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </ListItem>
                <Collapse
                  in={expandedAccounts[account.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box ml={4}>
                    <Typography variant="subtitle2">Campaigns:</Typography>
                    <List>
                      {account.campaigns.map((campaign) => (
                        <React.Fragment key={campaign.id}>
                          <ListItem>
                            <ListItemText
                              primary={campaign.name}
                              secondary={`ID: ${campaign.id}`}
                            />
                            <IconButton
                              onClick={() =>
                                toggleCampaignExpansion(campaign.id)
                              }
                            >
                              {expandedCampaigns[campaign.id] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </ListItem>
                          <Collapse
                            in={expandedCampaigns[campaign.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box ml={4}>
                              <Typography variant="subtitle2">
                                Ad Sets:
                              </Typography>
                              <List>
                                {campaign.adSets.map((adSet) => (
                                  <ListItem key={adSet.id}>
                                    <ListItemText
                                      primary={adSet.name}
                                      secondary={`ID: ${adSet.id}`}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          </Collapse>
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        );
      case 1:
        return (
          <Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Select the pages you want to include in this integration. The
              chips next to each page name represent the associated ad accounts
              that use this page.
            </Typography>
            <List>
              {pages.map((page) => (
                <ListItem key={page.id}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedPages.includes(page.id)}
                      onChange={() => handlePageToggle(page.id)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={page.name}
                    secondary={`ID: ${page.id}`}
                  />
                  <Box>
                    {getAdAccountsUsingPage(page.id).map((accountName) => (
                      <Chip
                        key={accountName}
                        label={accountName}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                    ))}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        );
      case 2:
        const missingPages = getMissingPages();
        return (
          <Box>
            <Typography variant="h6">Selected Ad Accounts:</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {adAccounts
                .filter((account) => selectedAdAccounts.includes(account.id))
                .map((account) => (
                  <Chip key={account.id} label={account.name} />
                ))}
            </Box>
            <Typography variant="h6">Selected Pages:</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {pages
                .filter((page) => selectedPages.includes(page.id))
                .map((page) => (
                  <Chip key={page.id} label={page.name} />
                ))}
            </Box>
            {missingPages.length > 0 && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                <Typography variant="body2">
                  Warning: The following pages are used in your ad accounts but
                  are not selected or not available to your facebook user
                  account for this integration:
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                  {missingPages.map((pageId) => (
                    <Chip key={pageId} label={pageId} size="small" />
                  ))}
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Leads generated from these pages will not be available with
                  this integration. It is recommended to check your facebook
                  page permissions before continuing.
                </Typography>
              </Alert>
            )}
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  if (!integration) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <FacebookIcon fontSize="large" sx={{ mr: 1 }} />
            <Typography variant="h6">Edit Facebook Integration</Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            flex: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {apiError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {apiError}
            </Alert>
          )}
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stepper activeStep={activeStep} sx={{ py: 2, px: 1 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ flex: 1, overflow: "auto", mt: 2 }}>
                {getStepContent(activeStep)}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ p: 2, borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            onClick={
              activeStep === steps.length - 1 ? handleFinish : handleNext
            }
            disabled={isSubmitting}
          >
            {activeStep === steps.length - 1
              ? isSubmitting
                ? "Updating..."
                : "Finish"
              : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </>
  );
}
